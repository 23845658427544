import React from 'react';
import { withTranslation } from 'react-i18next';

const lngs = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Spanish' }
  };
  
class LanguageSelector extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            choiceOpen: false,
        };
    }
  
    render(){
        const { i18n } = this.props;
        const { choiceOpen } = this.state;

        if (choiceOpen) {
            return (
                <div className='lang-choice'>
                    <div 
                        className='lang-choice-item'>
                        Language
                    </div>
                    {Object.keys(lngs).map((lng) => (
                        <div 
                            key={lng} 
                            className='lang-choice-item' 
                            style={{ 
                                fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' 
                            }} 
                            onClick={() => {
                                i18n.changeLanguage(lng);
                                this.setState({choiceOpen: false});
                            }}>
                            {lngs[lng].nativeName}
                        </div>
                    ))}
                </div>
            );
        } else {
            let lang = i18n.language;
            if (lang && lang.includes("-")) {
                lang = lang.split("-")[0];
            } 
            return (
                <div 
                    className='lang-badge'
                    onClick={e=>{
                        this.setState({choiceOpen: true});
                    }}>
                    <h2 className='lang-badge-name'>{lang}</h2>
                </div>
            );
        }
    }
}

export default  withTranslation()(LanguageSelector);