import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AttachVideoEdit from 'components/edit/AttachVideoEdit';
import CategoryChoice from 'components/edit/CategoryChoice';
import CategoryTags from 'components/edit/CategoryTags';

import { 
    uploadVideoToStorage, 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class ClubChallengeEditView extends Component {
    constructor(props) {
        super(props);
        const clubChallenge = {};
        if (props.clubChallenge != null) {
            for (const [key, value] of Object.entries(props.clubChallenge)) {
                clubChallenge[key] = value;
            }
        }
        
        this.state = {
            clubChallenge: clubChallenge,
            progress: false,
            newImage: {},
            newVideo: {},
            videoProgress: 0,
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubChallenge} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                newFile: {},
                newVideo: {},
                videoProgress: 0,
                imageProgress: 0
            })
        }
        if ((prevProps.clubChallenge !== clubChallenge)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(clubChallenge)) {
                challengeVal[key] = value;
            }
            this.setState({
                clubChallenge: challengeVal
            })
        }
    }

    onSaveClick(clubChallenge, newImage, newVideo) {
        const {uid} = this.props;
        const objRef = this;
        if (clubChallenge.title == null || clubChallenge.title === '') {
            alert("You must input title");
        } else if (clubChallenge.description == null || clubChallenge.description === '') {
            alert("You must input description");
        } else if (clubChallenge.duration_limit == null || clubChallenge.duration_limit < 5) {
            alert("You must input valid duration limit");
        } else if ((clubChallenge.id == null || clubChallenge.id === '') && (newVideo == null || newVideo.name == null)) {
            alert("You must input video");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubChallenge.id == null || clubChallenge.id === '') {
                clubChallenge.id = generateId("/clubChallenge");
                clubChallenge.owner = uid != null ? uid : "free";
            }
            clubChallenge.timestamp = dbTimestamp();
            
            if (newVideo != null && newVideo.name != null) {
                this.saveChangeWithVideo(clubChallenge, newVideo, (clubChallenge, challengeVideo) => {
                    this.saveChangeWithImage(clubChallenge, newImage, (update) => {
                        alert("Your Change subimitted successfully.");
                        objRef.setState({
                            clubChallenge: update,
                            challengeVideo: challengeVideo,
                            progress: false
                        })
                    })
                })
            } else {
                this.saveChangeWithImage(clubChallenge, newImage, (update) => {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        clubChallenge: update,
                        progress: false
                    })
                })
            }
        }
    }

    saveChangeWithVideo(clubChallenge, newVideo, sucess) {
        const objRef = this;

        uploadVideoToStorage(clubChallenge.id, newVideo, 'ClubChallenge', url => {
            clubChallenge.videoUri = url;
    
            sucess(clubChallenge);
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({videoProgress: progress});
            } else {
                this.setState({videoProgress: 0});
            }
        }, error => {
            alert("Saving Video File Failed.\n" + error);
            objRef.setState({
                progress: false
            })
        })
    }

    saveChangeWithImage(clubChallenge, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubChallenge.id, 'ClubChallenge', newImage, (url) => {
                clubChallenge.thumbUri = url;
                objRef.saveChange(clubChallenge, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(clubChallenge, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    clubChallenge: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(clubChallenge, success, failure) {
        writeDb('/clubChallenge/', clubChallenge.id, clubChallenge, error => {
            if (error) {
                failure(error);
            } else {
                success(clubChallenge);
            }
        })
    }

    render() {
        const {categorys, onCloseClick} = this.props;
        const {clubChallenge, newImage, newVideo, progress, imageProgress, videoProgress} = this.state;

        let categoryChoice = clubChallenge.categoryId != null ? categorys[clubChallenge.categoryId] : {};
        if (categoryChoice == null) {
            categoryChoice = {};
        }
        
        let tagStrs = clubChallenge.tags != null ? clubChallenge.tags.split(',') : [];  
        let tags = [];
        for (const txt of tagStrs) {
            tags.push({id: txt, text: txt});
        }
        if (clubChallenge != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubChallenge.title = e.target.value;
                            this.setState({clubChallenge: clubChallenge});
                        }} value={clubChallenge.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubChallenge.description} onChange={e => {
                            clubChallenge.description = e.target.value;
                            this.setState({clubChallenge: clubChallenge});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <CategoryChoice
                            title="Club Challenge Category"
                            categorys={categorys}
                            categoryChoice={categoryChoice}
                            onItemChoice={(item) => {
                                clubChallenge.categoryId = item.id;
                                clubChallenge.categoryTitle = item.title;
                                this.setState({clubChallenge: clubChallenge});
                            }} />
                        <CategoryTags
                            tags={tags}
                            category={categoryChoice}
                            onTagUpdate={(update) => {
                                let tagStr = "";
                                if (update != null) {
                                    for (var i = 0; i < update.length; i ++) {
                                        tagStr += (tagStr === "" ? "" : ",") + update[i];
                                    }
                                }
                                if (update != null) {
                                    clubChallenge.tags = tagStr
                                } else {
                                    delete clubChallenge.tags
                                }
                                this.setState({clubChallenge: clubChallenge});
                            }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="duration_limit">Duration Limit (seconds)</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            clubChallenge.duration_limit = e.target.value | 0;
                            this.setState({clubChallenge: clubChallenge});
                        }} value={clubChallenge.duration_limit} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="points">Points</Label>
                        <Input type="number" name="text" id="points" onChange={e => {
                            clubChallenge.points = e.target.value | 0;
                            this.setState({clubChallenge: clubChallenge});
                        }} value={clubChallenge.points} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubChallenge.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    <AttachVideoEdit
                        videoUrl={clubChallenge.videoUri}
                        videoClass='default-player'
                        label='Challenge Video'
                        allowProgress={progress}
                        progress={videoProgress}
                        onChange={(file)=> {
                            this.setState({newVideo: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubChallenge, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubChallengeEditView);