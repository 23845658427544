import React, { Component } from 'react';
import { 
    Button,
    Modal, 
    ModalBody,
} from 'reactstrap';
import ClubChallengeHeader from 'components/detail/club/ClubChallengeHeader';
import ClubChallengeDetailsView from 'components/detail/club/ClubChallengeDetailsView';
import ClubChallengeEditView from 'components/edit/club/ClubChallengeEditView';
import { listenDb, listenDbQuery } from 'utils/API';

export default class AdminChallenges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            categorys: {},
            clubChallenges: {},
            clubChallengeChoice: {},
            editOpen: false,
            detailOpen: false
        };
    }

    componentDidMount() {
        listenDb("category", data => {
                var categorys = {};

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        categorys[key] = value;
                    }
                }

                this.setState({
                    categorys: categorys,
                });
            });
        listenDbQuery("clubChallenge", "owner", "free", data => {
                var clubChallenges = {};

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        if (value.owner === "free") {
                            clubChallenges[key] = value;
                        }
                    }
                }

                this.setState({
                    clubChallenges: clubChallenges,
                });
            });        
    }

    renderChallenges() {
        const { clubChallenges } = this.state;

        const result = [];

        for (const[, value] of Object.entries(clubChallenges)) {

            result.push(
                <ClubChallengeHeader 
                    clubChallenge={value}
                    onClickDetails={() => {
                        this.setState({
                            clubChallengeChoice: value,
                            detailOpen: true
                        })
                    }}
                    onClickEdit={() => {
                        this.setState({
                            clubChallengeChoice: value,
                            editOpen: true
                        })
                    }} />
            )
        }

        return result;
    }

    render() {
        const {categorys, detailOpen, editOpen, clubChallengeChoice} = this.state;

        return (
            <div className='wrap-content-parent' style={{height: '100vh'}}>
                <div className='wrap-content-fill-child main-page'>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                        Club Challenge Free Contents
                        </h3>
                        
                        <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            this.setState({clubChallengeChoice: {}, editOpen: true});
                        }}>+</Button>
                    </div>
                    {this.renderChallenges()}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubChallengeEditView 
                            clubChallenge={clubChallengeChoice} 
                            categorys={categorys} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <ClubChallengeDetailsView 
                            clubChallenge={clubChallengeChoice} 
                            categorys={categorys} 
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}