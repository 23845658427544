import React, { Component } from "react";
import {
    CardImg, Button, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { 
    FcBusinessman,
    FcVideoFile,
} from 'react-icons/fc';
import Countdown from 'react-countdown';

import ClubChallengeDetailsView from 'components/detail/club/ClubChallengeDetailsView';
import ReactStars from "react-rating-stars-component";
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';
import InfoBadgeFixed from 'components/detail/InfoBadgeFixed';

export class ClubBattleLandingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    checkSubmission(user, item, submissions) {
        for (const [, v] of Object.entries(submissions)) {
            if (v.challengeId === item.id && v.uid === user.uid) {
                return v;
            }
        }
        return null;
    }

    renderSubmission() {
        const { itemSubmit } = this.state;

        if (itemSubmit) {
            return (
                <Modal isOpen={true}>
                    <ModalBody>
                    <h2 style={{fontSize: 15}}>
                        Only available on Mobile app
                        </h2>
                        <p style={{fontSize: 15, margin: '10px 30px'}}>
                        Download the mobile app
                        </p>
                        <div style={{textAlign: 'center'}}>
                            <a style={{
                                    color: "white",
                                    margin: "10px auto"
                                }}
                                href='https://apps.apple.com/us/app/live/id1559363576'>
                                <img alt="ios" style={{display: 'block', color: 'white', width: 160, margin: '10px auto'}}
                                    src="https://firebasestorage.googleapis.com/v0/b/live-edb25.appspot.com/o/static%2Fic_app_store.png?alt=media&token=61f939e9-a67e-4fab-8a0c-3825716641ef" />
                            </a>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <a style={{
                                    color: "white",
                                    margin: "10px auto"
                                }}
                                href='https://play.google.com/store/apps/details?id=com.scrimit.live'>
                                <img alt="android" style={{display: 'block', color: 'white', width: 160, margin: '10px auto'}}
                                    src="https://firebasestorage.googleapis.com/v0/b/live-edb25.appspot.com/o/static%2Fic_play_store.png?alt=media&token=3af0612b-8412-434f-a324-9c4fae611368" />
                            </a>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-cancel" onClick={(e)=> {
                            this.setState({itemSubmit: null});
                        }}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        } else {
            return <div/>;
        }
    }

    renderContents() {
        const {user, categorys, clubBattle, clubChallenges, submissions} = this.props;
        const {challengeChocice} = this.state;

        const itemsArr = [];
        for (const item of Object.values(clubChallenges)) {
            if (clubBattle.challengeIds && Object.values(clubBattle.challengeIds).includes(item.id)) {
                itemsArr.push(item);
            }
        }
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : defaultImage;

            const submission = this.checkSubmission(user, item, submissions);
            
            let subView = <div/>;
            if (!user || !user.uid) {
                subView = (
                    <div>
                        <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                            {t('not_logged_in')}
                        </h4>
                    </div>
                )
            } else if (!submission) {
                subView = (
                    <div>
                        <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                            {t('no_submission')}
                        </h4>
                        <Button className="btn-submit" onClick={(e)=> {
                            this.setState({itemSubmit: item});
                        }}>Submit</Button>
                    </div>
                )
            } else {
                subView = (
                    <div>
                        <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                            {submission.rating > 0 ? submission.rating : t('no_review')}
                        </h4>
                        <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                            <ReactStars
                                count={5}
                                size={20}
                                value={submission.rating}
                                edit={false}
                                isHalf={true}
                                activeColor="#ffd700" />
                        </div>
                        <Button className="btn-submit" onClick={(e)=> {
                            this.setState({itemSubmit: item});
                        }}>Submit</Button>
                    </div>
                )
            }
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            challengeChocice: item,
                        })
                    }} />
            ))
        }

        if (challengeChocice) {
            return (
                <div>
                    <h2 className="org-landing-contents-subtitle">
                    {t('competitions')}
                    </h2>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('competitions')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                <Button className='btn-cancel' onClick={(e)=> {
                                    this.setState({detailBattle: null})
                                }}>
                                {t('close')}
                                </Button>
                            </div>
                        </div>
                        <ClubChallengeDetailsView 
                            categorys={categorys} 
                            clubChallenge={challengeChocice} 
                            onCloseClick={() => {
                                this.setState({challengeChocice: null})
                            }} />
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h2 className="org-landing-contents-subtitle">
                    {t('competitions')}
                    </h2>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('competitions')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                
                            </div>
                        </div>
                    {items.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    ) : (
                        <div>
                        {items}
                        </div>
                    )}
                    </div>
                </div>
            )
        }
    }

    render() {
        const {clubBattle} = this.props;
        
        let challengeImage = clubBattle.iconUri != null ? clubBattle.iconUri : defaultImage;
        const countdown = clubBattle.active && clubBattle.deadline > Date.now() ? clubBattle.deadline : Date.now();
        
        if (clubBattle != null) {
            return (
                <div>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {clubBattle.title}
                            </h4>
                            <div>
                                <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                                    {countdown > 0 ? "Ends In" : "Finished"}
                                </h4>
                                {countdown > 0 && (
                                <Countdown
                                    date={countdown} />
                                )}
                                <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                                    <FcBusinessman /> {clubBattle.members != null ? Object.values(clubBattle.members).length : 0 }
                                </div>
                                <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                                    <FcVideoFile /> {clubBattle.challengeIds != null ? Object.values(clubBattle.challengeIds).length : 0 }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {this.renderContents()}
                    {this.renderSubmission()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ClubBattleLandingView;