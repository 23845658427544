import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, ListGroupItem,
} from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import { MdEdit, MdClose } from 'react-icons/md';
import SubSeasonTeams from 'components/edit/organization/SubSeasonTeams';
import LocationBasedDeploy from 'components/detail/organization/LocationBasedDeploy';
import WeeklyBasedDeploy from 'components/detail/organization/WeeklyBasedDeploy';
import GameScheduleBadge from 'components/edit/organization/GameScheduleBadge';
import { checkMatches } from 'utils/Utils';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';
import defaultLocationImage from 'assets/img/icons/league_default.png';
import { generateId } from "utils/API";
import { ROUND_TYPE_LEAGUE, STATUS_FINISHED } from "utils/constants";

class LeagueEdit extends Component {
    constructor(props) {
        super(props);
        const subSeason = {};
        if (props.subSeason != null) {
            for (const [key, value] of Object.entries(props.subSeason)) {
                subSeason[key] = value;
            }
        }
        if (subSeason.deployType == null) {
            subSeason.deployType = 'weekBased';
        }

        const games = subSeason.games != null ? Object.values(subSeason.games) : [];
        const shouldOpen = !props.season || !props.season.teamIds || Object.values(props.season.teamIds) === 0;
        
        this.state = {
            pickTeams: shouldOpen,
            newImage: {},
            games: games,
            imageProgress: 0,
            locationTimes: {},
            doubleAllow: false,
            matchCount: -1
        }
    }
    
    componentDidUpdate(prevProps) {
        const {season, isOpen, subSeason} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            const shouldOpen = !season || !season.teamIds || Object.values(season.teamIds) === 0;
            
            this.setState({
                pickTeams: shouldOpen,
                newImage: {},
                locationTimes: {},
                imageProgress: 0,
                doubleAllow: false,
                matchCount: -1
            })
        }
        if ((prevProps.subSeason !== subSeason)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(subSeason)) {
                challengeVal[key] = value;
            }
            const games = subSeason.games != null ? Object.values(subSeason.games) : [];
            this.setState({
                games: games,
                doubleAllow: false,
                matchCount: -1
            })
        }
    }

    onUpdateGames(games) {
        const {season, subSeason} = this.props;

        let maxMatchDay = 0;
        if (games != null && games.length > 0) {
            subSeason.games = {};
            for (const game of games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
                if (game.matchDayIndex != null && game.matchDayIndex > maxMatchDay) {
                    maxMatchDay = game.matchDayIndex;
                }
            }
        }
        this.setState({games: games});
        
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
    }

    onUpdateValue() {
        const {season, subSeason} = this.props;
        const {games} = this.state;

        let maxMatchDay = 0;
        if (games != null && games.length > 0) {
            subSeason.games = {};
            for (const game of games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
                if (game.matchDayIndex != null && game.matchDayIndex > maxMatchDay) {
                    maxMatchDay = game.matchDayIndex;
                }
            }
        }
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
    }

    renderLeagueGames() {
        const {season, subSeason, division, locations, organization} = this.props;
        const {games} = this.state;
        if (games != null && games.length > 0) {
            return (
                <div style={{marginTop: 10, marginBottom: 20}} >
                    <h4 className='wrap-content-fill-child' style={{marginTop: 10, marginBottom: 10}}>
                    {t('location_games_deployment')} : {Object.values(locations).length}, Games: {games.length}
                    </h4>
                    <div className='divider' />
                    {this.renderLeagueGameItems()}
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                    <Button className='btn-cancel' onClick={ e => {
                        this.onUpdateGames([]);
                    }}>{t('clear_redeploy')}</Button>
                </div>
            );
        } else {
            if (subSeason.deployType === 'weekBased') {
                return (
                    <WeeklyBasedDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        division={division}
                        locations={locations}
                        onCompleteDeploy={(games, doubleAllow, matchCount)=>{
                            this.setState({doubleAllow: doubleAllow, matchCount: matchCount});
                            this.onUpdateGames(games);
                        }} />
                );
            } else {
                return (
                    <LocationBasedDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        division={division}
                        locations={locations}
                        onCompleteDeploy={(games)=>{
                            this.onUpdateGames(games);
                        }} />
                );
            }
        }
    }

    getTeamIcon(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].iconUri != null) {
            return teams[teamId].iconUri;
        }
        let teamData = organization.teamData != null ? organization.teamData[teamId] : {};
        if (teamData.thumbUri != null) {
            return teamData.thumbUri;
        }

        return defaultTeamImage;
    }

    getTeamTitle(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].title != null) {
            return teams[teamId].title;
        }
        let teamData = organization.teamData != null ? organization.teamData[teamId] : {};
        if (teamData.nickname != null) {
            return teamData.nickname;
        }

        return 'Unknown';
    }

    getLocation(locationId, locations) {
        const result = {title: 'Unknown', thumbUri: defaultLocationImage};
        if (locations[locationId] != null && locations[locationId].title != null) {
            result.title = locations[locationId].title;
        }
        if (locations[locationId] != null && locations[locationId].thumbUri != null) {
            result.thumbUri = locations[locationId].thumbUri;
        }

        return result;
    }

    renderLeagueGameItems() {
        const {subSeason, locations, teams, organization, division, season, users} = this.props;
        const {games} = this.state;
        
        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            {games.map((value, idx)=>(
                <ListGroupItem key={idx}>
                    <GameScheduleBadge
                        season={season}
                        subSeason={subSeason}
                        game={value}
                        teams={teams}
                        organization={organization}
                        division={division}
                        locations={locations}
                        users={users}
                        onHomeUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.homeTeam = update;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.onUpdateGames(games);
                        }}
                        onAwayUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.awayTeam = update;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.onUpdateGames(games);
                        }}
                        gameTimeUpdate={(start, end)=>{
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            if (start != null) {
                                value.start = start;
                            }
                            if (end != null) {
                                value.end = end;
                            }
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.onUpdateGames(games);
                        }}
                        locationUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.locationId = update;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.onUpdateGames(games);
                        }}
                        dayUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.matchDayIndex = update;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        refereeUpdate={(update, assists)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.refereeId = update;
                            value.referees = assists;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.onUpdateGames(games);
                        }}
                        removeClicked={()=> {
                            
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            games.splice(idx, 1);
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.onUpdateGames(games);
                        }}/>
                </ListGroupItem>
            ))}
        </div>
        )
    }

    renderTeams() {
        const {subSeason, teams} = this.props;
        const {pickTeams} = this.state;

        const teamCount = subSeason.teamIds != null ? Object.values(subSeason.teamIds).length : 0;
        const totalCount = teams != null ? Object.values(teams).length : 0;

        if (pickTeams) {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 30}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {t('teams')}: {teamCount} {t('picked')}, {totalCount} {t('total')}
                        </h4>
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                this.setState({pickTeams: false})
                            }}><MdClose/></Button>
                        </div>
                    </div>
                    <div className='divider' />
                    <SubSeasonTeams
                        teams={teams}
                        subSeason={subSeason}
                        onAdded={(teamId)=> {
                            if (subSeason.teamIds == null) {
                                subSeason.teamIds = {};
                            }
                            subSeason.teamIds[teamId] = teamId;
                            this.onUpdateValue()
                        }}
                        onRemoved={(teamId)=> {
                            if (subSeason.teamIds == null) {
                                subSeason.teamIds = {};
                            }
                            delete subSeason.teamIds[teamId]
                            this.onUpdateValue()
                        }} />
                    <div className='divider' style={{marginBottom: 20}} />
                </div>
            );
        } else {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 5}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {t('teams')}: {teamCount} {t('picked')}, {totalCount} {t('total')}
                        </h4>
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                this.setState({pickTeams: true})
                            }}><MdEdit/></Button>
                        </div>
                    </div>
                    <div className='divider' style={{marginBottom: 20}} />
                </div>
            )
        }
    }

    render() {
        const {season, subSeason, createMode} = this.props;
        
        if (subSeason != null) {
            return (
                <Form style={createMode ? {width: '100%'} : {}}>
                    {season.type !== ROUND_TYPE_LEAGUE && (
                    <FormGroup>
                        <Label for="duration_limit">{t('winner_team_count')}</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            subSeason.winnerCount = parseInt(e.target.value);
                            this.onUpdateValue()
                        }} value={subSeason.winnerCount} />
                    </FormGroup>
                    )}
                    <FormGroup>
                        <Label for="gender">{t('deploy_type')}</Label>
                        <Input type="select" name="gender" id="gender" value={subSeason.deployType} onChange={e => {
                            subSeason.deployType = e.target.value;
                            this.onUpdateValue()
                        }}>
                            <option value={null}></option>
                            <option value="weekBased">{t('weekly_based')}</option>
                            <option value="locationBased">{t('location_based')}</option>
                        </Input>
                    </FormGroup>
                    {this.renderTeams()}
                    {this.renderLeagueGames()}
                    
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = connect(mapState, actionCreators)(LeagueEdit);
export { connectedApp as LeagueEdit };
