import React, { Component } from "react";
import {
    Button, Input, Row, Col, CardImg
} from 'reactstrap';
import { t } from 'i18next';
import { MdWarning } from 'react-icons/md';
import { 
    RiDeleteBin2Line, RiCloseCircleLine
} from 'react-icons/ri';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import UserChoice from 'components/edit/UserChoice';
import MembersChoice from 'components/members/MembersChoice';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';
import defaultLocationImage from 'assets/img/icons/league_default.png';
import { ROUND_TYPE_GROUP, ROUND_TYPE_LEAGUE } from "utils/constants";

export class GameEditBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    getTeamIcon(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].iconUri != null) {
            return teams[teamId].iconUri;
        }
        let teamData = organization.teamData != null && organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        if (teamData.thumbUri != null) {
            return teamData.thumbUri;
        }

        return defaultTeamImage;
    }

    getTeamTitle(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].title != null) {
            return teams[teamId].title;
        }
        let teamData = organization.teamData != null && organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        if (teamData.nickname != null) {
            return teamData.nickname;
        }

        return 'Unknown';
    }

    getLocation(locationId, locations) {
        const result = {title: 'Unknown', thumbUri: defaultLocationImage};
        if (locations[locationId] != null && locations[locationId].title != null) {
            result.title = locations[locationId].title;
        }
        if (locations[locationId] != null && locations[locationId].thumbUri != null) {
            result.thumbUri = locations[locationId].thumbUri;
        }

        return result;
    }

    getMaxDayArray() {
        const {subSeason} = this.props;

        let result = [];
        if ((subSeason.type === ROUND_TYPE_GROUP || subSeason.type === ROUND_TYPE_LEAGUE) && subSeason.games) {
            for (const game of Object.values(subSeason.games)) {
                if (game.matchDayIndex != null && !result.includes(game.matchDayIndex)) {
                    result.push(game.matchDayIndex);
                }
            }
        }
        result.sort((a, b) => {
            return a - b;
        })

        return result;
    }

    render() {
        const {locations, game, teams, organization, group, season, division, 
            subSeason, users, onHomeUpdate, onAwayUpdate, 
            gameTimeUpdate, locationUpdate, dayUpdate, refereeUpdate, removeClicked, closeClicked} = this.props;

        const referees = {};
        for (const [key, value] of Object.entries(users)) {
            if (organization.refereeIds != null && Object.values(organization.refereeIds).includes(key)) {
                referees[key] = value;
            }
        }
        let teamIds = group != null ? group.teamIds : season.teamIds;
        let dayArr = this.getMaxDayArray();
        
        if (game != null) {
            return (
                <div>
                    <Row>
                        <Col className='game-badge-edit-line' xl={6} lg={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(game.homeTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.homeTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (onHomeUpdate != null) {
                                        onHomeUpdate(e.target.value)
                                    }
                                }}>
                                    {Object.values(teamIds).map((teamId,i)=>(
                                        <option key={teamId} value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                        </Col>
                        <Col className='game-badge-edit-line' xl={6} lg={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(game.awayTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.awayTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (onAwayUpdate != null) {
                                        onAwayUpdate(e.target.value)
                                    }
                                }}>
                                    {Object.values(teamIds).map((teamId,i)=>(
                                        <option key={teamId} value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                        </Col>
                        <Col className='game-badge-edit-line' xs={12}>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 'auto', marginBottom: 'auto'}}>
                                    {t('start')}
                                </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        value={game.start != null ? new Date(game.start) : new Date()}
                                        onChange={ val => {
                                            if (gameTimeUpdate != null) {
                                                gameTimeUpdate(val.valueOf(), game.end)
                                            }
                                        }} />
                                </div>
                            </div>
                        </Col>
                        <Col className='game-badge-edit-line' xs={12}>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 'auto', marginBottom: 'auto'}}>
                                    {t('end')}
                                </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        value={game.end != null ? new Date(game.end) : new Date()}
                                        onChange={ val => {
                                            if (gameTimeUpdate != null) {
                                                gameTimeUpdate(game.start, val.valueOf())
                                            }
                                        }} />
                                </div>
                            </div>
                        </Col>
                        <Col className='game-badge-edit-line' xl={dayArr.length > 0 ? 6 : 8} lg={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getLocation(game.locationId, locations).thumbUri}
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.locationId} className='wrap-content-fill-child' onChange={e=> {
                                    if (locationUpdate != null) {
                                        locationUpdate(e.target.value)
                                    }
                                }}>
                                    <option value={null}></option>
                                    {Object.values(division.locationIds != null ? division.locationIds : {}).map((locationId,i)=>(
                                        <option key={locationId} value={locationId}>{this.getLocation(locationId, locations).title}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                        </Col>
                        {dayArr.length > 0 && (
                        <Col className='game-badge-edit-line' xl={6} lg={12}>
                            <div className='wrap-content-parent'>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{height: 20, marginRight: 15, marginTop: 5}}>{t('day_index')} </div>
                                <Input type="select" name="select" id="homeChoice" value={game.matchDayIndex} className='wrap-content-fill-child' onChange={e=> {
                                    if (dayUpdate != null) {
                                        dayUpdate(e.target.value)
                                    }
                                }}>
                                    {dayArr.map((dayIndex,i)=>(
                                        <option key={dayIndex} value={dayIndex}>{dayIndex + 1}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                        </Col>
                        )}
                        <Col className='game-badge-edit-line' sm={8} xs={12}>
                            <div className='text-center' style={{marginTop: 5}}>
                                <div style={{fontSize: 12, textAlign: 'left'}}>{t('referee')}</div>
                                <UserChoice
                                    title={t('referee_choice')}
                                    users={referees}
                                    smallMode={true}
                                    emptyTitle={t('pick_referee')}
                                    userChoice={referees[game.refereeId]}
                                    onItemChoice={(item) => {
                                        if (refereeUpdate != null) {
                                            refereeUpdate(item.uid, game.referees)
                                        }
                                    }} />
                                <div style={{fontSize: 12, textAlign: 'left'}}>{t('assist_referee')}</div>
                                <MembersChoice
                                    noMembersTxt={t('no_assist_referee')}
                                    available={organization.refereeIds}
                                    members={game.referees}
                                    users={referees}
                                    onSaveClick={(members)=> {
                                        if (refereeUpdate != null) {
                                            refereeUpdate(game.refereeId, members)
                                        }
                                    }} />
                            </div>
                        </Col>
                        <Col className='game-badge-edit-line' sm={4} xs={12}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (removeClicked != null) {
                                            removeClicked()
                                        }
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                                {closeClicked && (
                                    <div className='wrap-content-wrap-child vertical-center-spacing'>
                                        <Button className="btn-cancel" onClick={e=> {
                                            if (closeClicked != null) {
                                                closeClicked()
                                            }
                                        }}><RiCloseCircleLine/></Button>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {game.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                    {game.refereeOverlap && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('referee_overlap')}
                        </div>
                    )}
                    {!game.valid && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {subSeason.matchCount != null && subSeason.matchCount !== -1 ? t('home_invalid_count') : t('home_away_duplicated')}
                        </div>
                    )}
                    {subSeason.matchCount != null && subSeason.matchCount !== -1 && game.awayValid === false && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('away_invalid_count')}
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default GameEditBadge;