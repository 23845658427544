import React, { Component } from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, Input } from 'reactstrap';
import OrganizerHeader from 'components/detail/admin/OrganizerHeader';
import { validateEmail, validateEmailStr } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';

export default class AdminInvites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            organizations: {},
            invites: {},
            users: {},
            teams: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf()
        };
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        invokeHttpsApi('inviteQuery-inviteDashboardQuery', {}, (data) => {
            const organizations = {};
            const users = {};
            const teams = {};
            const invites = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                if (data.invites != null && data.invites.organizer != null) {
                    for (const [key, value] of Object.entries(data.invites.organizer)) {
                        invites[key] = value;
                    }
                }
            }

            this.setState({
                organizations: organizations,
                teams: teams,
                invites: invites,
                users: users,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    checkEmail(email) {
        const {invites, organizations, searchStr, users} = this.state;

        if (!validateEmail(email)) {
            return -1;
        }

        for (const val of Object.values(invites)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                if (val.status === 'invited') {
                    return 0;
                } else if (val.status === 'registered') {
                    return 1;
                }
            }
        }
        for (const val of Object.values(organizations)) {
            const user = users[val.owner] ? users[val.owner] : {};
            if (!searchStr || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                return 2;
            }
        }
        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return 3;
            }
        }
        return 4;
    }

    getUid(email) {
        const {users} = this.state;

        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return val.uid;
            }
        }
        return null;
    }

    sendInvite(email) {
        var status = this.checkEmail(email)

        if (status === -1) {
            alert("This email address is invalid.")
            return;
        }
        
        if (status === 2) {
            alert("This email is already registered.")
            return;
        }
        
        if (status === 1) {
            alert("This email is already invited for creating organization.")
            return;
        }
        
        const data = {email: email};
        if (status === 3) {
            data.uid = this.getUid(email);
        }

        invokeHttpsApi('invite-inviteOrganizer', data, (data) => {
            this.loadContents();
            if (status === 3) {
                alert("Invite to create an organization email is sent. It will be sent every 2 days");
            } else {
                alert("Invite email is sent. It will be sent every 2 days");
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    renderInvite(invite) {
        const {users} = this.state;
        let user = {};
        if (users) {
            for (const val of Object.values(users)) {
                if (val.email === invite.email) {
                    user = val;
                }
            }
        }

        if (user.email) {
            return (
                <OrganizerHeader 
                    key={user.email}
                    email={user.email}
                    invite={invite} />
            )
        } else {
            return <div/>
        }
    }

    renderHeader(organization) {
        const {users} = this.state;
        const user = users[organization.owner] ? users[organization.owner] : {};

        if (user.email) {
            return (
                <OrganizerHeader 
                    key={user.email}
                    email={user.email}
                    organization={organization} />
            )
        } else {
            return <div/>
        }
    }

    render() {
        const {invites, organizations, editOpen, searchStr, users} = this.state;

        const invited = [];
        const pending = [];
        const orgs = [];

        for (const val of Object.values(invites)) {
            if (!searchStr || (val.email && val.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                if (val.status === 'invited') {
                    invited.push(val);
                } else if (val.status === 'registered') {
                    pending.push(val);
                }
            }
        }
        for (const val of Object.values(organizations)) {
            const user = users[val.owner] ? users[val.owner] : {};
            if (!searchStr || (user.email && user.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                orgs.push(val);
            }
        }
        orgs.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        return (
            <div>
                <div style={{marginTop: 10}} className='wrap-content-fill-child'>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <Input type="text" name="search" id="search" placeholder='Search by email ...' onChange={e => {
                                this.setState({searchStr: e.target.value});
                            }} value={searchStr} />
                        </div>                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.sendInvite(validateEmailStr(searchStr));
                        }}>{t('invite')}</Button>
                    </div>
                    
                    <div>
                        {invited.map((val) => (
                            <div key={val.email}>{this.renderInvite(val)}</div>
                        ))}
                    </div>
                    <div>
                        {pending.map((val) => (
                            <div key={val.email}>{this.renderInvite(val)}</div>
                        ))}
                    </div>
                    <div>
                        {orgs.map((val) => (
                            <div key={val.id}>{this.renderHeader(val)}</div>
                        ))}
                    </div>
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}