import React, { Component } from "react";
import teamIcon from 'assets/img/icons/club_icon_default.png';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
// import Pagination from 'react-responsive-pagination';
import {
    Button, Modal, ModalBody,
} from 'reactstrap';
import { t } from 'i18next';
import { simpleDate, simpleTime } from "utils/Utils";
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, SEASON_TYPE_LEAGUE } from "utils/constants";
import { filterActions } from "redux/_actions/filter.actions";
import { connect } from "react-redux";

class SambaGames extends Component {
    constructor(props) {
        super(props);
        
        let locationsChoice = {};
        if (props.locations != null) {
            for (const [key, value] of Object.entries(props.locations)) {
                if (value.organizationId === props.organization.id) {
                    locationsChoice[key] = value;
                }
            }
        }
        this.state = {
            gameChoice: {},
            locationChoice: {},
            locationsChoice: locationsChoice,
            editTeamData: false,
            editLocation: false,
            progress: false,
            detailOpen: false,
            editOpen: false,
            detailChoice: false,
            imageProgress: 0,
            page: 0,
            windowWidth: window.innerWidth,
        };
        window.addEventListener('resize', this.getDimensions); 
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidUpdate(prevProps) {
        const {locations, organization, seasons} = this.props;
        if (seasons !== prevProps.seasons) {
            let locationsChoice = {};
            if (locations != null) {
                for (const [key, value] of Object.entries(locations)) {
                    if (value.organizationId === organization.id) {
                        locationsChoice[key] = value;
                    }
                }
            }
            this.setState({
                locationsChoice: locationsChoice,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }

    renderGameBadge(game, width) {
        const { organization, divisions, seasons, teams } = this.props;
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        let actionStyle = {};
        if (actionBg && actionColor) {
            actionStyle = {
                backgroundColor: actionBg,
                color: actionColor,
            }
        } else if (actionBg) {
            actionStyle = {
                backgroundColor: actionBg,
            }
        } else if (actionColor) {
            actionStyle = {
                color: actionColor,
            }
        }
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : teamIcon;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : teamIcon;

        let title = "Season";
        let type = t('normal');
        if (seasons[game.seasonId]) {
            const season = seasons[game.seasonId];
            title = season.title;
            if (divisions[season.divisionId]) {
                title = divisions[season.divisionId].title + " - " + title;
            }
            if (season.type === SEASON_TYPE_LEAGUE) {
                type = t('league');
            }
            if (season.subSeasons && season.subSeasons[game.subSeasonId]) {
                let round = season.subSeasons[game.subSeasonId];
                if (round.type === ROUND_TYPE_GROUP) {
                    type = t('group_game');
                } else if (round.type === ROUND_TYPE_KNOCKOUT) {
                    type = t('knockout_game');
                }
            }
        }
        if (game.final) {
            type = t('final');
        } else if (game.semiFinal) {
            type = t('semi_final');
        }

        return (
            <div key={game.id} className="samba-game-badge" style={{width: width}}>
                <div className="samba-game-season">
                    <div className="samba-game-center">{title}</div> 
                </div>
                <div className="samba-game-type" style={actionStyle}>
                    <div className="samba-game-center">{type}</div> 
                </div>
                <div>
                    <div className="samba-game-date">{simpleDate(game.start)}</div>
                    <div className="samba-game-time">{simpleTime(game.start)}</div>
                </div>
                <div className="samba-game-item">
                    <img alt="home-icon" className="samba-game-icon" src={homeThumb} />
                    <div className="samba-game-team">{homeTeam.title}</div>
                    <div className="samba-game-score">{game.homeScore ? game.homeScore : 0}</div>
                </div>
                <div className="samba-game-item">
                    <img alt="home-icon" className="samba-game-icon" src={awayThumb} />
                    <div className="samba-game-team">{awayTeam.title}</div>
                    <div className="samba-game-score">{game.awayScore ? game.awayScore : 0}</div>
                </div>
            </div>
        )
    }

    renderGames() {
        const { organization, seasons, loading, filter } = this.props;
        const { locationChoice, page, windowWidth} = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if (value.organizationId === organization.id)  {
                if (!filter.seasonId || key === filter.seasonId)  {
                    seasonsChoice[key] = value;
                }
            }
        }

        let viewWidth = windowWidth;
        if (windowWidth >= 1400) {
            viewWidth = 1320;
        } else if (windowWidth >= 1200) {
            viewWidth = 1140;
        } else if (windowWidth >= 992) {
            viewWidth = 960;
        } else if (windowWidth >= 768) {
            viewWidth = 720;
        } else if (windowWidth >= 576) {
            viewWidth = 540;
        }
        if (document.getElementById("samba-game-row")) {
            viewWidth = document.getElementById("samba-game-row").offsetWidth;
        }
        const count = parseInt((viewWidth - 20) / 160);

        let games = [];

        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                if (!filter.teamId || game.homeTeam === filter.teamId || game.awayTeam === filter.teamId) {
                                    games.push(game)
                                }
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        //const pageCount = Math.ceil((games ? Object.values(games).length : 0) / count);
        
        for (const[, value] of Object.entries(games)) {
            if (value.organizationId === organization.id) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.start;            
            let t2 = b.start;

            return t1 - t2;
        })
        const now = new Date().valueOf();
        let pageChoice = page;
        if (page === 0) {
            var index = 0;
            for (const item of result) {
                if (item.start > now) {
                    break;
                }
                index ++;
            }
            pageChoice = parseInt(index / count) + 1;
        }

        let idx = (pageChoice - 1) * count;
        for (let i = 0; i < count; i ++) {
            if (result.length > i + idx) {
                list.push(
                    this.renderGameBadge(result[i + idx], (viewWidth - 20) / count)
                );
            }
        }

        return (
            <div>
                {list.length === 0 ? (
                    <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {loading ? "Loading SambaGames" : t('no_items_to_show')}
                    </div>
                ) : (
                    <div className="samba-game-row" id="samba-game-row">
                        {list}
                    </div>
                )}
                {/* 
                <div style={{height: 25}} />
                <Pagination
                    current={pageChoice}
                    total={pageCount}
                    onPageChange={(update) => {
                        this.setState({page: update})
                    }} />
                <div style={{height: 25}} />
                 */}
            </div>
        );
    }

    renderGameChoice() {
        const {organization, seasons, divisions, posts, users, teams, cards, locations, onChanged} = this.props;
        const {gameChoice} = this.state;

        return (
            <div className="text-center" style={{padding: "25px 0px", backgroundColor: '#eee'}}>
                <div className="container">
                    {gameChoice.id && (
                    <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({gameChoice: {}})
                    }}>Back To Games List</Button>
                    )}
                    <div style={{height: 25}} />
                    <GameDetails 
                        game={gameChoice}
                        organization={organization}
                        locations={locations}
                        divisions={divisions}
                        seasons={seasons}
                        cards={cards}
                        teams={teams}
                        posts={posts}
                        users={users} 
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }}
                        onClickClose={()=>{
                            this.setState({gameChoice: {}});
                        }} />
                    <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                    </div>
                    <div style={{height: 25}} />
                    
                </div>
            </div>
        );
    }
    
    render() {
        const {organization, seasons, divisions, posts, users, teams, cards, locations, onChanged} = this.props;
        const {gameChoice} = this.state;
        
        return (
            <div className="samba-row">
                {this.renderGames()}
                {gameChoice.id && (
                <Modal isOpen={true} style={{marginTop: 70}}>
                    <ModalBody>
                        <GameDetails 
                            game={gameChoice}
                            organization={organization}
                            locations={locations}
                            divisions={divisions}
                            seasons={seasons}
                            cards={cards}
                            teams={teams}
                            posts={posts}
                            users={users} 
                            onChanged={()=> {
                                if (onChanged != null) {
                                    onChanged();
                                }
                            }}
                            onClickClose={()=>{
                                this.setState({gameChoice: {}});
                            }} />
                    </ModalBody>
                </Modal>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    const { filter, dict } = state.filterData;
    return { isAdmin, loggingIn, user, filter, dict };
}

const actionCreators = {
    updateFilter: filterActions.updateFilter,
};

const connectedNavigation = connect(mapState, actionCreators)(SambaGames);
export { connectedNavigation as SambaGames };
