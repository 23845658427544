import React, { Component } from 'react';
import {TeamSlides} from 'components/landing/team/TeamSlides';
import { Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import Countdown from 'react-countdown';
import { 
    FcBusinessman,
    FcVideoFile,
} from 'react-icons/fc';
import { 
    GiWalkingBoot
} from 'react-icons/gi';
import { t } from 'i18next';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import defaultImage from 'assets/img/icons/video_default.png';
import InfoBadgeFixed from 'components/detail/InfoBadgeFixed';
import ClubBattleLandingView from 'components/detail/club/ClubBattleLandingView';
import ClubFieldLandingView from 'components/detail/club/ClubFieldLandingView';
import ClubPlanDetailsView from 'components/detail/club/ClubPlanDetailsView';
import { getOrgId } from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import { TeamEmpty } from 'components/landing/team/TeamEmpty';

class TeamTraining extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderFields() {
        const { user } = this.props;
        const { team, categorys, fields, tracks, trackSubmissions, detailField } = this.state;
        
        const itemsArr = Object.values(fields);
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : defaultImage;
            const countdown = item.active && item.deadline > Date.now() ? item.deadline : Date.now();
            const subView = (
                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                    <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                        {countdown > 0 ? "Ends In" : "Finished"}
                    </h4>
                    {countdown > 0 && (
                    <Countdown
                        date={countdown} />
                    )}
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcBusinessman /> {item.members != null ? Object.values(item.members).length : 0 }
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <GiWalkingBoot /> {item.trackIds != null ? Object.values(item.trackIds).length : 0 }
                    </div>
                </Col>
            )
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            detailField: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/3";

        if (detailField) {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('cardio_set')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('cardio_set')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <Button className='btn-cancel' onClick={(e)=> {
                                        this.setState({detailField: null})
                                    }}>
                                    {t('close')}
                                    </Button>
                                </div>
                            </div>
                            <ClubFieldLandingView
                                categorys={categorys}
                                user={user}
                                clubField={detailField}
                                clubTracks={tracks}
                                submissions={trackSubmissions} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('cardio_set')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('cardio_set')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                    {t('manage_contents')}
                                    </a>
                                </div>
                            </div>
                        {items.length === 0 ? (
                            <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_items_to_show')}
                            </div>
                        ) : (
                            <div>
                            {items}
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderBattles() {
        const { user } = this.props;
        const { team, battles, challenges, submissions, detailBattle } = this.state;
        
        const itemsArr = Object.values(battles);
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : defaultImage;
            const countdown = item.active && item.deadline > Date.now() ? item.deadline : Date.now();

            const subView = (
                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                    <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                        {countdown > 0 ? "Ends In" : "Finished"}
                    </h4>
                    {countdown > 0 && (
                    <Countdown
                        date={countdown} />
                    )}
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcBusinessman /> {item.members != null ? Object.values(item.members).length : 0 }
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcVideoFile /> {item.challengeIds != null ? Object.values(item.challengeIds).length : 0 }
                    </div>
                </Col>
            )
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            detailBattle: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/1";

        if (detailBattle) {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('competitions')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('competitions')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <Button className='btn-cancel' onClick={(e)=> {
                                        this.setState({detailBattle: null})
                                    }}>
                                    {t('close')}
                                    </Button>
                                </div>
                            </div>
                            <ClubBattleLandingView
                                user={user}
                                clubBattle={detailBattle}
                                clubChallenges={challenges}
                                submissions={submissions} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('competitions')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('competitions')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                    {t('manage_contents')}
                                    </a>
                                </div>
                            </div>
                        {items.length === 0 ? (
                            <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_items_to_show')}
                            </div>
                        ) : (
                            <div>
                            {items}
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderWorkouts() {
        const { user } = this.props;
        const { team, plans, workouts, detailWorkout } = this.state;
        
        const itemsArr = Object.values(plans);
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : defaultImage;
            
            const subView = (
                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcBusinessman /> {item.members != null ? Object.values(item.members).length : 0 }
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcVideoFile /> {item.workoutIds != null ? Object.values(item.workoutIds).length : 0 }
                    </div>
                </Col>
            )
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            detailWorkout: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/2";

        if (detailWorkout) {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('workouts')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('workouts')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <Button className='btn-cancel' onClick={(e)=> {
                                        this.setState({detailWorkout: null})
                                    }}>
                                    {t('close')}
                                    </Button>
                                </div>
                            </div>
                            <ClubPlanDetailsView
                                user={user}
                                clubPlan={detailWorkout}
                                clubWorkouts={workouts} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('workouts')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('workouts')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                    {t('manage_contents')}
                                    </a>
                                </div>
                            </div>
                        {items.length === 0 ? (
                            <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_items_to_show')}
                            </div>
                        ) : (
                            <div>
                            {items}
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const {team, loading} = this.state;
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
        if (!team.available) {
            return (
                <TeamEmpty team={team} />
            )
        }

        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }

        return (
            <div>
                <TeamSlides 
                    team={team}
                    loading={loading} />
                <div style={{height: 50}}/>
                <div style={{paddingTop: 20}}>
                {this.renderBattles()}
                {this.renderFields()}
                {this.renderWorkouts()}
                </div>
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamTraining));
export { connectedApp as TeamTraining };