import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class PromoCodeEdit extends Component {
    constructor(props) {
        super(props);
        const coupon = {};
        if (props.coupon != null) {
            if (props.coupon.id != null) {
                coupon.id = props.coupon.id;
            }
            coupon.name = props.coupon.name;
            coupon.percent_off = props.coupon.percent_off;
            coupon.duration = props.coupon.duration;
            coupon.duration_in_months = props.coupon.duration_in_months;
        }
        
        this.state = {
            coupon: coupon,
            progress: false,
        }
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, coupon} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
            })
        }
        if ((prevProps.coupon !== coupon)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(coupon)) {
                challengeVal[key] = value;
            }
            this.setState({
                coupon: challengeVal
            })
        }
    }

    render() {
        const {onEditClick, onCloseClick} = this.props;
        const {coupon, progress} = this.state;
        
        if (coupon != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="value">Coupon Name</Label>
                        <Input type="text" name="text" id="value" onChange={e => {
                            coupon.name = e.target.value;
                            this.setState({coupon: coupon});
                        }} value={coupon.name} />
                    </FormGroup>

                    {coupon.id == null && (
                        <div>
                            <FormGroup>
                                <Label for="duration_limit">Coupon Percentage (%)</Label>
                                <Input type="number" name="text" id="duration_limit" onChange={e => {
                                    coupon.percent_off = e.target.value | 0;
                                    this.setState({coupon: coupon});
                                }} value={coupon.percent_off} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="typeChoice">Coupon Type: {coupon.duration}</Label>
                                <Input type="select" name="select" id="typeChoice" onChange={e=> {
                                    coupon.duration = e.target.value;
                                    this.setState(coupon.duration !== 'repeating' ? {coupon: coupon, duration_in_months: undefined} : {coupon: coupon});
                                }}>
                                    <option value='forever'>Forever</option>
                                    <option value='once'>Once</option>
                                    <option value='repeating'>Repeating</option>
                                </Input>
                            </FormGroup>
                            {coupon.duration === 'repeating' && (
                                <FormGroup>
                                    <Label for="duration_limit">Coupon Percentage (%)</Label>
                                    <Input type="number" name="text" id="duration_limit" onChange={e => {
                                        coupon.duration_in_months = e.target.value | 0;
                                        this.setState({coupon: coupon});
                                    }} value={coupon.duration_in_months} />
                                </FormGroup>
                            )}
                        </div>
                    )}
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                if (onEditClick != null) {
                                    onEditClick(coupon);
                                }
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(PromoCodeEdit);