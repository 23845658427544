import React, { Component } from "react";
import ReactPlayer from 'react-player';
import ReactStars from "react-rating-stars-component";
import {
    Card, CardBody, CardImg, Button, Form, FormGroup, Spinner, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//import SegmentedPicker from 'react-segmented-picker';
import defaultImage from 'assets/img/icons/video_default.png';
import { writeDb } from "utils/API";

export class ClubChallengeReviewView extends Component {
    constructor(props) {
        super(props);
        
        const {submission} = props;

        const update = {};
        if (submission != null) {
            for (const [key, value] of Object.entries(submission)) {
                update[key] = value;
            }
        }
        this.state = {
            videoChoice: 'Challenge',
            submission: update,
            progress: false
        };
    }

    onSaveClick(submission) {
        const objRef = this;
        if (submission.rating == null || submission.rating <= 0) {
            alert("You must input valid rating");
        } else if (submission.feedback == null || submission.feedback === '') {
            alert("You must input feedback");
        } else {
            this.setState({progress: true});
            
            writeDb("/clubChallengeSubmission/" + submission.id, submission, error => {
                if (error) {
                    alert("Saving Review Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                } else {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        progress: false
                    })
                }
            })
        }
    }

    render() {
        const {onCloseClick, challenge, submission} = this.props;
        const {progress} = this.state;
        
        let challengeImage = challenge.thumbUri != null ? challenge.thumbUri : defaultImage;
        const {videoChoice} = this.state;
        
        if (challenge != null) {
            return (
                <Form>
                    <Card className="flex-row">
                        <CardImg
                            className="profile-thumb vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {challenge.title}
                            </h4>
                            <div>{challenge.description}</div>
                            <div>Points: {challenge.points}</div>
                        </CardBody>
                    </Card>
                    {challenge != null && challenge.videoUri != null && submission != null && submission.videoUri != null && (
                        {/* <SegmentedPicker
                            options={options}
                            selection={videoChoice === 'Challenge' ? 0 : 1}
                            onSelectionChange={(newSelection) => {
                                this.setState({videoChoice: options[newSelection]})
                            }}
                        /> */}
                    )}
                    {challenge.videoUri != null && challenge.videoUri !== '' && (
                    <div>
                        <ReactPlayer 
                            className="default-player"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={videoChoice === 'Challenge' ? challenge.videoUri : submission.videoUri}
                            width={'80%'}
                            height={'auto'} />

                    </div>
                    )}
                    <FormGroup>
                        <legend>Rating: {submission.rating}</legend>
                        <ReactStars
                            id="rating"
                            count={5}
                            onChange={(newRating) => {
                                submission.rating = newRating;
                                this.setState({submission: submission});
                            }}
                            size={24}
                            value={submission.rating}
                            isHalf={true}
                            activeColor="#ffd700" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="feedback">Feedback</Label>
                        <Input type="textarea" name="text" id="feedback" value={submission.feedback} onChange={e => {
                            submission.feedback = e.target.value;
                            this.setState({submission: submission});
                        }} />
                    </FormGroup>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(submission);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubChallengeReviewView);