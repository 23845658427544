import React, { Component } from "react";
import {
    Col, Row, CardImg
} from 'reactstrap';
import { t } from "i18next";
import {
    getEventOffset,
} from 'utils/Utils';
import EventRow from 'components/detail/organization/EventRow';

import defaultScoreImage from 'assets/img/icons/soccer-ball.png';
import defaultFoulImage from 'assets/img/icons/whistle.png';
import defaultSubImage from 'assets/img/icons/switch-player.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';

export default class GameHighlights extends Component {
    renderRound(round) {   
        const { users, teams, organization, catetory, game, curTime, refereeMode, onAddClick } = this.props;

        let items = [];
        const homeIcon = teams && teams[game.homeTeam] && teams[game.homeTeam].iconUri ? teams[game.homeTeam].iconUri : teamIcon;
        const awayIcon = teams && teams[game.awayTeam] && teams[game.awayTeam].iconUri ? teams[game.awayTeam].iconUri : teamIcon;

        let events = [];
        if (game.scores != null) {
            for (const val of Object.values(game.scores)) {
                if (val.roundId === round.id) {
                    if (!curTime || getEventOffset(game, catetory, val) <= curTime) {
                        events.push(val);
                    }
                }
            }
        }
        if (game.fouls != null) {
            for (const val of Object.values(game.fouls)) {
                if (val.roundId === round.id) {
                    if (!curTime || getEventOffset(game, catetory, val) <= curTime) {
                        events.push(val);
                    }
                }
            }
        }
        if (game.substitutes != null) {
            for (const val of Object.values(game.substitutes)) {
                if (val.roundId === round.id) {
                    if (!curTime || getEventOffset(game, catetory, val) <= curTime) {
                        events.push(val);
                    }
                }
            }
        }
        if (game.events != null) {
            for (const val of Object.values(game.events)) {
                if (val.roundId === round.id) {
                    if (!curTime || getEventOffset(game, catetory, val) <= curTime) {
                        events.push(val);
                    }
                }
            }
        }
        events.sort((a,b)=>{return a.roundTime - b.roundTime});

        
        for (const val of events) {
            let icon = defaultScoreImage;

            if (val.type === 'foul') {
                icon = defaultFoulImage;
            } else if (val.type === 'substitute') {
                icon = defaultSubImage;
            } else if (val.type === 'event') {
                icon = defaultFoulImage;
            }
            items.push(
            <div onClick={e=>{
                if (this.props.onItemClick) {
                    this.props.onItemClick(val);
                }
            }}>
                <EventRow 
                    game={game}
                    event={val} 
                    organization={organization} 
                    users={users} 
                    icon={icon} />
            </div>
            );
        }
            
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className='wrap-content-parent'>
                        <h3 className='stat-table-title wrap-content-fill-child'>
                            {round.title}  <span style={{fontWeight: 400}}>({round.status})</span>
                        </h3>
                        {refereeMode && (
                        <div className='wrap-content-wrap-child vertical-center'>
                            <CardImg
                                className="event-badge-icon"
                                src={homeIcon}
                                onClick={e=> {
                                    if (onAddClick) {
                                        onAddClick(game.homeTeam, round);
                                    }
                                }} />
                            <CardImg
                                className="event-badge-icon"
                                src={awayIcon}
                                onClick={e=> {
                                    if (onAddClick) {
                                        onAddClick(game.awayTeam, round);
                                    }
                                }} />
                        </div>
                        )}
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                    
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_events')}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    render() {
        const { users, teams, game, refereeMode } = this.props;
        const rounds = game.roundData != null ? Object.values(game.roundData) : [];
        rounds.sort((a, b) => {return a.number - b.number});

        const homeTeam = teams[game.homeTeam] != null ? teams[game.homeTeam] : {};
        const awayTeam = teams[game.awayTeam] != null ? teams[game.awayTeam] : {};
        
        const homePlayers = {};
        const awayPlayers = {};
        if (game.gameList != null && game.gameList[game.homeTeam] != null) {
            for (const uid of Object.values(game.gameList[game.homeTeam])) {
                if (users[uid] != null) {
                    homePlayers[uid] = users[uid]
                }
            }
        }
        if (game.benchList != null && game.benchList[game.homeTeam] != null) {
            for (const uid of Object.values(game.benchList[game.homeTeam])) {
                if (users[uid] != null) {
                    homePlayers[uid] = users[uid]
                }
            }
        }
        if (game.gameList != null && game.gameList[game.awayTeam] != null) {
            for (const uid of Object.values(game.gameList[game.awayTeam])) {
                if (users[uid] != null) {
                    awayPlayers[uid] = users[uid]
                }
            }
        }
        if (game.benchList != null && game.benchList[game.awayTeam] != null) {
            for (const uid of Object.values(game.benchList[game.awayTeam])) {
                if (users[uid] != null) {
                    awayPlayers[uid] = users[uid]
                }
            }
        }

        const winnerTitle = teams[game.winner] ? teams[game.winner].title : "";
        
        const homeMom = users[game.mom != null && game.mom[game.homeTeam] != null ? game.mom[game.homeTeam].uid : null];
        const awayMom = users[game.mom != null && game.mom[game.awayTeam] != null ? game.mom[game.awayTeam].uid : null];
        
        if (game != null) {
            return (
                <div>
                    {rounds.map((round,idx)=>(
                        this.renderRound(round)
                    ))}
                    {!refereeMode && (
                    <div>
                        <Row className='form-edit-row'>
                        {game.homeScore != null && game.awayScore != null && (
                            <h4 className='form-edit-subtitle'>
                            {game.homeScore} - {game.awayScore}
                            </h4>
                        )}
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('winner')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-info'>
                                    {winnerTitle}
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('mom_label')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                {homeMom && (
                                <div className='form-edit-info'>
                                    {homeTeam.title} : {homeMom.username}
                                </div>
                                )}
                                {awayMom && (
                                <div className='form-edit-info'>
                                    {awayTeam.title} : {awayMom.username}
                                </div>
                                )}
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('referee_feedback')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-info'>
                                    {game.feedback}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}
