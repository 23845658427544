import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class SecurityCodeEdit extends Component {
    constructor(props) {
        super(props);
        const code = {};
        if (props.code != null) {
            for (const [key, value] of Object.entries(props.code)) {
                code[key] = value;
            }
        }
        
        this.state = {
            code: code,
            progress: false,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, code} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
            })
        }
        if ((prevProps.code !== code)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(code)) {
                challengeVal[key] = value;
            }
            this.setState({
                code: challengeVal
            })
        }
    }

    onSaveClick(code) {
        const objRef = this;
        if (code.value == null || code.value === '') {
            alert("You must input value");
        } else {
            this.setState({progress: true, videoProgress: 0});
            if (code.id == null || code.id === '') {
                code.id = generateId("securityCode")
            }
            code.timestamp = dbTimestamp();
            
            this.saveChange(code, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    code: update,
                    progress: false
                })
            })
        }
    }

    saveChange(code, success, failure) {
        writeDb("/securityCode/" + code.id, error => {
            if (error) {
                failure(error);
            } else {
                success(code);
            }
        })
    }

    render() {
        const {onCloseClick} = this.props;
        const {code, progress} = this.state;
        
        if (code != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="value">Code Value</Label>
                        <Input type="text" name="text" id="value" onChange={e => {
                            code.value = e.target.value;
                            this.setState({code: code});
                        }} value={code.value} />
                    </FormGroup>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(code);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SecurityCodeEdit);