import React, { Component } from "react";
import {
    Card, CardImg, Button, Col, Row, Input
} from 'reactstrap';
import { t } from 'i18next';
import { 
    GiSoccerField,
} from 'react-icons/gi';
import { 
    IoIosListBox
} from 'react-icons/io';
import { 
    MdEdit
} from 'react-icons/md';
import { toDateTimeString } from 'utils/Utils';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import UserChoice from 'components/edit/UserChoice';
import MembersChoice from 'components/members/MembersChoice';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import defaultLocationImage from 'assets/img/icons/league_default.png';
import trophyImage from 'assets/img/icons/leagues_icon.png';

export class KnockoutGameBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            saveProgress: false,
        }
    }
    
    getLocation(locationId, locations) {
        const result = {title: 'Unknown', thumbUri: defaultLocationImage};
        if (locations[locationId] != null && locations[locationId].title != null) {
            result.title = locations[locationId].title;
        }
        if (locations[locationId] != null && locations[locationId].thumbUri != null) {
            result.thumbUri = locations[locationId].thumbUri;
        }

        return result;
    }

    render() {
        const { game, teams, organization, division, locations, users, onUpdate, onClickDetail, warningView, detailMode, allowEdit } = this.props;
        const { editMode } = this.state;
        const referees = {};
        for (const [key, value] of Object.entries(users)) {
            if (organization.refereeIds != null && Object.values(organization.refereeIds).includes(key)) {
                referees[key] = value;
            }
        }
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const timeStr = toDateTimeString(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        
        if (game != null) {
            return (
                <Card className="knockout-game-badge">
                    <Row>
                        <Col xs={4} className="text-center">
                            <CardImg
                                className="knockout-game-team-icon"
                                src={homeThumb} />
                            <div className='knockout-game-team-title'>
                            {homeTeam.title != null ? homeTeam.title : t('not_set')}
                            </div>
                        </Col>
                        <Col xs={4} className="text-center">
                            <div className='knockout-game-info'>
                                <b>{timeStr}</b>
                                {game.homeScore != null && game.awayScore != null ? (
                                    <p><b>{game.homeScore} - {game.awayScore}</b></p>
                                ) : (
                                    <p><b>x - x</b></p>
                                )}
                                <div className='knockout-game-info'>
                                    <GiSoccerField/> <b>{location.title != null ? location.title : t('not_set')}</b>
                                </div>
                            </div>
                        </Col>
                        <Col xs={4} className="text-center">
                            <CardImg
                                className="knockout-game-team-icon"
                                src={awayThumb} />
                            <div className='knockout-game-team-title'>
                            {awayTeam.title != null ? awayTeam.title : t('not_set')}
                            </div>
                        </Col>
                        <Col xs={12} className="game-badge-action">
                            {game.final && (
                                <div className='knockout-game-info'>
                                    <CardImg
                                        style={{width: 15, height: 15, marginRight: 5}}
                                        src={trophyImage} />
                                    <b>{game.gameIdx === 0 ? t('final') : t('final_3_4')}</b>
                                </div>
                            )}
                            {detailMode && (
                            <Button style={{margin: '0px 10px'}} className="btn-green" onClick={e => {
                                if (onClickDetail != null) {
                                    onClickDetail();
                                }
                            }}><IoIosListBox/> {t('detail')}</Button>
                            )}
                            {allowEdit && (
                            <Button style={{margin: '0px 10px'}} className="btn-submit" onClick={e => {
                                this.setState({editMode: !editMode})
                            }}><MdEdit/> {t('edit')}</Button>
                            )}
                        </Col>
                    </Row>
                    {editMode && (
                    <Row>
                        <Col xs={12}>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, fontSize: 10, marginRight: 15, marginTop: 25}}>{t('start')} </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        value={game.start != null ? new Date(game.start) : new Date()}
                                        onChange={ val => {
                                            game.start = val.valueOf();
                                            if (onUpdate != null) {
                                                onUpdate(game);
                                            }
                                        }} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getLocation(game.locationId, locations).thumbUri}
                                    style={{width: 25, height: 25, marginRight: 15, marginTop: 5}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.locationId} className='wrap-content-fill-child' onChange={e=> {
                                    game.locationId = e.target.value;
                                    if (onUpdate != null) {
                                        onUpdate(game);
                                    }
                                }}>
                                    <option key='null' value={null}></option>
                                    {Object.values(division.locationIds ? division.locationIds : {}).map((locationId,i)=>(
                                        <option key={locationId} value={locationId}>{this.getLocation(locationId, locations).title}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div style={{fontSize: 12, textAlign: 'left'}}>{t('referee')}</div>
                                <UserChoice
                                    title={t('referee_choice')}
                                    users={referees}
                                    smallMode={true}
                                    emptyTitle={t('pick_referee')}
                                    userChoice={referees[game.refereeId]}
                                    onItemChoice={(item) => {
                                        game.refereeId = item.uid;
                                        if (onUpdate != null) {
                                            onUpdate(game);
                                        }
                                    }} />
                                <div style={{fontSize: 12, textAlign: 'left'}}>{t('assist_referee')}</div>
                                <MembersChoice
                                    noMembersTxt={t('no_assist_referee')}
                                    available={organization.refereeIds}
                                    members={game.referees}
                                    users={referees}
                                    onSaveClick={(members)=> {
                                        game.referees = members;
                                        if (onUpdate != null) {
                                            onUpdate(game);
                                        }
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    )}
                    {warningView != null && (
                        <Row>{warningView}</Row>
                    )}
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default KnockoutGameBadge;