import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    GiRibbonMedal,
} from 'react-icons/gi';

import defaultImage from 'assets/img/icons/leagues_icon.png';

export class DivisionTrophyBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {trophy, onClickDetails, onClickEdit } = this.props;
        let thumbImage = trophy.thumbUri != null ? trophy.thumbUri : defaultImage;
        let playerCount = trophy.winnerIds == null ? 0 : Object.values(trophy.winnerIds).length;

        if (trophy != null) {
            return (
                <div className="info-badge-small">
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb info-badge-icon-small"
                            src={thumbImage} />
                        <CardBody className="info-badge-body-small">
                            <Row>
                                <Col nm={8} xs={12} sm={6} className="vertical-center">
                                    <h4 className='label label-default one-line-title'>
                                    {trophy.title}
                                    </h4>
                                    <div className='wrap-content-parent'>
                                        <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                        <GiRibbonMedal/> {playerCount}
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={4} xs={12} sm={6} className="vertical-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: -10}}>
                                            <Button className="btn-submit" onClick={e => {
                                                if (onClickEdit != null) {
                                                    onClickEdit()
                                                }
                                            }}>{t('edit')}</Button>
                                            <Button className="btn-cancel" onClick={e => {
                                                if (onClickDetails != null) {
                                                    onClickDetails()
                                                }
                                            }}>Details</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(DivisionTrophyBadge);