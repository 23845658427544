import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CategoryChoice from 'components/edit/CategoryChoice';
import defaultImage from 'assets/img/icons/playlist_icon.png';

import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";
import { GENDER_COED, GENDER_FEMALE, GENDER_MALE } from "utils/constants";

export class ProgramEdit extends Component {
    constructor(props) {
        super(props);
        const program = {};
        if (props.program != null) {
            for (const [key, value] of Object.entries(props.program)) {
                program[key] = value;
            }
        }
        
        this.state = {
            program: program,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, program} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.program !== program)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(program)) {
                challengeVal[key] = value;
            }
            this.setState({
                program: challengeVal
            })
        }
    }

    onSaveClick(program, newImage) {
        const {organization, onSaveDone} = this.props;
        const objRef = this;
        if (program.title == null || program.title === '') {
            alert("You must input title");
        } else if (program.description == null || program.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (program.id == null || program.id === '') {
                program.id = generateId("/program");
            }
            program.owner = organization.owner;
            program.organizationId = organization.id;
            program.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(program, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone();
                }
                objRef.setState({
                    program: update,
                    progress: false
                });
            })
        }
    }

    saveChangeWithImage(program, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(program.id, 'programThumb', newImage, (url) => {
                program.iconUri = url;
                objRef.saveChange(program, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(program, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(program, success, failure) {
        invokeHttpsApi('organizerQuery-submitProgramChange', {
            program: program,
        }, (data) => {
            if (data && data.success) {
                success(program);
            } else {
                failure(null);
            }
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    renderCategory() {
        const {categorys} = this.props;
        const {program} = this.state;
        let categoryChoice = program.category != null ? program.category : {};

        return (
            <FormGroup>
                <CategoryChoice
                    title="Club Challenge Category"
                    categorys={categorys}
                    categoryChoice={categoryChoice}
                    onItemChoice={(item) => {
                        if (program.category == null) {
                            program.category = {};
                        }
                        for (const [key, value] of Object.entries(item)) {
                            program.category[key] = value;
                        }
                        this.setState({program: program});
                    }} />
            </FormGroup>
        );
    }

    render() {
        const {categorys, onCloseClick, createMode} = this.props;
        const {program, newImage, newVideo, progress, imageProgress} = this.state;
        let catArr = categorys != null ? Object.values(categorys) : [];
        catArr.sort((a, b)=>{
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        let catId = program.category != null ? program.category.id : null;
        
        if (program != null) {
            return (
                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Program {!createMode ? t('edit') : t('create')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Image
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <HorizontalAttachImageEdit
                                imageUrl={program.iconUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Program Title
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="text" name="text" id="title" onChange={e => {
                                    program.title = e.target.value;
                                    this.setState({program: program});
                                }} value={program.title} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Program Description
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="text" id="description" value={program.description} onChange={e => {
                                    program.description = e.target.value;
                                    this.setState({program: program});
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Program Player Fee
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="playerFee" id="title" onChange={e => {
                                    program.playerFee = parseFloat(e.target.value);
                                    this.setState({program: program});
                                }} value={program.playerFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('gender')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="gender" id="gender" value={program.gender} onChange={e => {
                                    program.gender = e.target.value;
                                    this.setState({program: program});
                                }}>
                                    <option value={null}></option>
                                    <option value={GENDER_MALE}>{t('male')}</option>
                                    <option value={GENDER_FEMALE}>{t('female')}</option>
                                    <option value={GENDER_COED}>{t('coed')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('age_group')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="text" name="ageTitle" id="ageTitle" onChange={e => {
                                    program.ageTitle = e.target.value;
                                    this.setState({program: program});
                                }} value={program.ageTitle} />
                            </div>
                            <div className='form-edit-input'>
                                <div className='wrap-content-parent' style={{margin: '10px 0px'}}>
                                    <div className='form-edit-label'>
                                    {t('age_range')}
                                    </div>
                                    <Label for="ageTo" style={{margin: 'auto 10px'}}>From </Label>
                                    <Input type="number" name="ageFrom" style={{width: '60px'}} id="ageFrom" onChange={e => {
                                        program.ageFrom = e.target.value;
                                        this.setState({program: program});
                                    }} value={program.ageFrom} />
                                    <Label for="ageTo" style={{margin: 'auto 10px'}}>To </Label>
                                    <Input type="number" name="ageTo" style={{width: '60px'}} id="ageTo" onChange={e => {
                                        program.ageTo = e.target.value;
                                        this.setState({program: program});
                                    }} value={program.ageTo} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Category
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="gender" id="gender" value={catId} onChange={e => {
                                    const id = e.target.value;
                                    const item = categorys[id];
                                    if (item != null) {
                                        program.category = {};
                                        for (const [key, value] of Object.entries(item)) {
                                            program.category[key] = value;
                                        }
                                        this.setState({program: program});
                                    }
                                }}>
                                    <option key={0} value={null}></option>
                                    {catArr.map((val,idx)=>(
                                        <option key={idx} value={val.id}>{val.title}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Register Deadline
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <CustomDateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="MM/DD/YYYY hh:mm"
                                    value={program.regDeadline != null ? new Date(program.regDeadline) : new Date()}
                                    onChange={ val => {
                                        program.regDeadline = val.valueOf();
                                        this.setState({program: program});
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('start_date')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <CustomDateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="MM/DD/YYYY hh:mm"
                                    value={program.startTime != null ? new Date(program.startTime) : new Date()}
                                    onChange={ val => {
                                        program.startTime = val.valueOf();
                                        this.setState({program: program});
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('contact_email')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="text" id="contactEmail" value={program.contactEmail} onChange={e => {
                                    program.contactEmail = e.target.value;
                                    this.setState({program: program});
                                }} />
                            </div>
                        </Col>
                    </Row>
                    
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('contact_phone_number')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="text" id="contactPhone" value={program.contactPhone} onChange={e => {
                                    program.contactPhone = e.target.value;
                                    this.setState({program: program});
                                }} />
                            </div>
                        </Col>
                    </Row>

                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                    <Button className='btn-edit' onClick={e => {
                                        this.onSaveClick(program, newImage, newVideo);
                                    }}>{t('create')}</Button>
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={7}>
                                </Col>
                            </Row>
                        ) : (
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                    <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(program, newImage, newVideo);
                                    }}>{t('save')}</Button>
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={7}>
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </Col>
                            </Row>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ProgramEdit);