import React, { Component } from "react";
import {
    Card, CardImg, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    MdDelete,
} from 'react-icons/md';
import { 
    GiSoccerField,
    GiWhistle
} from 'react-icons/gi';
import { toTimeString } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';

export class SeasonGameBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const { game, teams, locations, users, onClickEdit, onClickDelete, onClickDetail, warningView, detailMode } = this.props;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toTimeString(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        
        if (game != null) {
            return (
                <Card className="game-badge">
                    <Row>
                        <Col nm={2} xs={2} sm={2} className="text-center">
                            <div className='game-badge-calendar'>
                                <div className='game-badge-day'>
                                    {day}
                                </div>
                                <div className='game-badge-month'>
                                    {month}
                                </div>
                            </div>
                        </Col>
                        <Col nm={8} xs={8} sm={8} className="text-center">
                            <Row>
                                <Col nm={5} xs={12} sm={5} className="text-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        {homeTeam.title}
                                        </div>
                                        <div className="wrap-content-wrap-child">
                                            <CardImg
                                                className="profile-thumb game-team-icon"
                                                src={homeThumb} />
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={2} xs={12} sm={2} className="text-center game-badge-time">
                                <b>{timeStr}</b>
                                {game.homeScore != null && game.awayScore != null && (
                                    <p><b>{game.homeScore} - {game.awayScore}</b></p>
                                )}
                                </Col>
                                <Col nm={5} xs={12} sm={5} className="text-center">
                                    <div className='wrap-content-parent'>
                                        <div className="wrap-content-wrap-child">
                                            <CardImg
                                                className="profile-thumb game-team-icon"
                                                src={awayThumb} />
                                        </div>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        {awayTeam.title}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col nm={6} xs={6} sm={6} className="text-center">
                                    <div>
                                        <div className="vertical-center-spacing">
                                            <GiSoccerField/>
                                        </div>
                                        <div className=' vertical-center-spacing'>
                                        {location.title}
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={6} xs={6} sm={6} className="text-center">
                                    <div>
                                        <div className="vertical-center-spacing">
                                            <GiWhistle/>
                                        </div>
                                        <div className=' vertical-center-spacing'>
                                        {referee.uid != null ? referee.username : t('no_referee')}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col nm={2} xs={2} sm={2} className="game-badge-action">
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                {detailMode ? (
                                <div className="wrap-content-wrap-child vertical-center-spacing">
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickDetail != null) {
                                            onClickDetail();
                                        }
                                    }}>{t('detail')}</Button>
                                </div>
                                ) : (
                                <div className="wrap-content-wrap-child vertical-center-spacing">
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickDelete != null) {
                                            onClickDelete();
                                        }
                                    }}><MdDelete/></Button>
                                </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    {warningView != null && (
                        <Row>{warningView}</Row>
                    )}
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SeasonGameBadge);