import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/security_icon.png';
import { writeDb } from "utils/API";

export class SecurityCodeBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {code, onClickEdit } = this.props;
        
        if (code != null) {
            return (
                <div>
                    <Card className="shadow-tile">
                        <CardBody>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    <CardImg
                                        className="profile-thumb"
                                        src={defaultImage}
                                        style={{ width: 25, height: 25, marginRight: 10 }}
                                        />
                                    <div className='vertical-center-spacing' style={{fontSize: 14, fontWeight: 800}}>{code.value}</div>
                                </div>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                    <Button className="btn-cancel" onClick={e => {
                                        if (window.confirm('Are you sure you want to delete this code?')) {
                                            writeDb("/securityCode/" + code.id, null);
                                        }
                                    }}>{t('delete')}</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation(SecurityCodeBadge);