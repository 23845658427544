import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';

import { getOrgData } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';

import logo200Image from 'assets/img/logo/live_192.png';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import CustomTabView from 'components/template/CustomTabView';
import { filterActions } from 'redux/_actions/filter.actions';
import { SambaPostHeader } from 'components/landing/organization/samba/news/SambaPostHeader';
import PostDetailView from 'components/detail/community/PostDetailView';

class OrgPosts extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            detailChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            mainTab: 0,
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const seasonId = this.props.router.params.seasonId;
            this.setState({id: id, seasonId: seasonId});
        }
        if (window.location.search && window.location.search.includes('payment_result=true')) {
            alert("Payment sent to owner sccuessfully. You will get into season once he accepts it.");
        } else if (window.location.search && window.location.search.includes('payment_result=false')) {
            alert("Payment not sent to owner sccuessfully. You should retry later.");
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let seasonId = null;
        if (this.props.router != null && this.props.router.params != null) {
            seasonId = this.props.router.params.seasonId;
        }
        const params = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizationLandingQuery', params, ((data)=>{

            const organizations = {};
            const organization = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const myTeams = {};
            const cards = {};
            const wallet = {};
            const seasonChoice = {};

            this.props.updateDict(data);
            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                if (organizations[id] != null) {
                    for (const [key, value] of Object.entries(organizations[id])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    if (value.organizationId === organization.id) {
                        seasons[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.myTeams)) {
                    myTeams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [, value] of Object.entries(data.wallet)) {
                    for (const [k, v] of Object.entries(value)) {
                        wallet[k] = v;
                    }
                }
                if (seasons[seasonId]) {
                    for (const [key, value] of Object.entries(seasons[seasonId])) {
                        seasonChoice[key] = value;
                    }
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                myTeams: myTeams,
                posts: posts,
                locations: locations,
                users: users,
                category: category,
                cards: cards,
                wallet: wallet,
                seasonChoice: seasonChoice,
                loading: false,
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderPosts() {
        const { organization, posts, users, user, loading, mainTab, searchStr} = this.state;
        
        const result = [];
        const list = [];
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id) {
                if (mainTab === 2 && (value.youtube || value.videoItem)) {
                    result.push(value);
                } else if (mainTab === 1 && value.gameId) {
                    result.push(value);
                } else if (mainTab === 0) {
                    result.push(value);
                }
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        for (let i = 0; i < 12; i ++) {
            if (result.length > i) {
                toShow.push(result[i]);
            }
        }
        
        for (const value of toShow) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: 0}}>
                    <SambaPostHeader
                        key={value.id}
                        organization={organization}
                        post={value}
                        users={users}
                        user={user}
                        onClickDetail={() => {
                            this.setState({detailChoice: value});
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center">
                
                <CustomTabView
                    value={mainTab}
                    actionBg={actionBg}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    onChange={(e,value) => {
                        this.setState({mainTab: value})
                    }}
                    items={[
                        {
                            label: t('news')
                        },
                        {
                            label: t('highlights')
                        },
                        {
                            label: t('videos')
                        },
                    ]} />

                {list.length === 0 ? (
                    <div style={{height: list.length === 0 ? 150 : 450, paddingTop: list.length === 0 ? 75 : 25, fontSize: 14, textAlign: 'center'}}>
                    {loading ? t('loading') : t('no_items_to_show')}
                    </div>
                ) : (
                    <div className="row" style={{padding: "25px 0px"}}>
                        {list}
                    </div>
                )}
                <div style={{height: 25}} />
            </div>
        );
    }
    
    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    render() {
        const {loading, organization, user, users, teams, detailChoice} = this.state;
        
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';

        if (loading || !organization || !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        
            return (
                <div>
                    {!sambaTheme && this.renderOrganizationHeader()}
                    <div className='main-page container'>
                        {this.renderPosts()}
                    </div>
                    {detailChoice.id && (
                    <div className='team-auth' onClick={e=>{
                        this.setState({detailChoice: {}})
                    }}>
                        <div className='team-auth-wrapper' onClick={e=>{
                            e.stopPropagation();
                        }}>
                        <div className="post-detail-modal">
                            <PostDetailView 
                                organization={organization}
                                post={detailChoice} 
                                user={user}
                                users={users}
                                teams={teams}
                                isOpen={true}
                                onCloseClick={() => {
                                    this.setState({detailChoice: {}})
                                }}
                                onLikeUpdate={(like, uid) => {
                                    if (uid) {
                                        if (!detailChoice.likeIds) {
                                            detailChoice.likeIds = {};
                                        }
                                        if (like) {
                                            detailChoice.likeIds[uid] = uid;
                                        } else {
                                            delete detailChoice.like[uid];
                                        }
                                        this.setState({detailChoice: detailChoice})
                                    }
                                }}
                                onCommentChange={(update) => {
                                    if (update) {
                                        if (!detailChoice.comments) {
                                            detailChoice.comments = {};
                                        }
                                        detailChoice.comments[update.id] = update;

                                        this.setState({detailChoice: detailChoice})
                                    }
                                }} />
                        </div>
                        </div>
                    </div>
                    )}
                </div>
            )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    updateDict: filterActions.updateDict,
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgPosts));
export { connectedPage as OrgPosts };
