import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Row, Col
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import MembersView from 'components/members/MembersView';

import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import { WEEK_TIME, MIN_TIME, getWeekStartDay, DAY_TIME, copyHour } from 'utils/Utils';
import { generateId, invokeHttpsApi } from "utils/API";

export class ClubScheduleEdit extends Component {
    constructor(props) {
        super(props);
        const clubSchedule = {};
        if (props.clubSchedule != null) {
            for (const [key, value] of Object.entries(props.clubSchedule)) {
                clubSchedule[key] = value;
            }
        }
        let duration = 15;
        if (clubSchedule.timestamp && clubSchedule.deadline) {
            duration = (clubSchedule.timestamp - clubSchedule.deadline) / MIN_TIME;
        }
        
        this.state = {
            clubSchedule: clubSchedule,
            duration: duration,
            repeating: {
                enabled: true,
                end: new Date().valueOf() + WEEK_TIME,
                sunday: false,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
            },
            progress: false,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubSchedule} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.clubSchedule !== clubSchedule)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(clubSchedule)) {
                challengeVal[key] = value;
            }
            this.setState({
                clubSchedule: challengeVal
            })
        }
    }

    onSaveClick(clubSchedule) {
        const {club, uid, onUpdate} = this.props;
        if (clubSchedule.title == null || clubSchedule.title === '') {
            alert("You must input title");
        } else if (clubSchedule.location == null || clubSchedule.location === '') {
            alert("You must input location");
        } else if (clubSchedule.deadline == null || clubSchedule.timestamp == null || 
            clubSchedule.timestamp <= 0 || clubSchedule.timestamp <= new Date().valueOf() || clubSchedule.deadline <= clubSchedule.timestamp ) {
            alert("You must input valid start time and duration.");
        } else {
            if (clubSchedule.everyone) {
                clubSchedule.members = {};
                for (const [k, v] of Object.entries(club.playerIds ? club.playerIds : {})) {
                    clubSchedule.members[k] = v;
                }
            }
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubSchedule.id == null || clubSchedule.id === '') {
                clubSchedule.owner = uid != null ? uid : "free";
                clubSchedule.clubId = club.id;
            }
            
            this.saveChange(this.getScheduleArray(clubSchedule), (update) => {
                alert("Your Change subimitted successfully.");
                if (onUpdate != null) {
                    onUpdate();
                }
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                if (onUpdate != null) {
                    onUpdate();
                }
            })
        }
    }

    getScheduleArray(clubSchedule) {
        const {repeating} = this.state;

        const result = [];
        if (repeating.enabled && !clubSchedule.id) {
            const start = clubSchedule.timestamp;
            const end = repeating.end;

            let startTime = getWeekStartDay(clubSchedule.timestamp);
            while (startTime < end + WEEK_TIME) {
                if (repeating.sunday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.monday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.tuesday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.wednesday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.thursday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.friday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.saturday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(clubSchedule, startTime))
                }
                startTime += DAY_TIME;
            }
        } else if (!clubSchedule.id) {
            clubSchedule.id = generateId("/clubSchedule");
            result.push(clubSchedule);
        } else {
            result.push(clubSchedule);
        }

        return result;
    }

    copySchedule(clubSchedule, time) {
        const { duration } = this.state;
        const result = {};
        for (const [k,v] of Object.entries(clubSchedule)) {
            result[k] = v;
        }
        result.id = generateId("/clubSchedule");
        result.timestamp = copyHour(time, clubSchedule.timestamp);
        result.deadline = duration * MIN_TIME + result.timestamp;
        
        return result;
    }

    saveChange(array, success, failure) {
        const {club} = this.props;
        const map = {};
        for (const item of array) {
            map[item.id] = item;
        }
        invokeHttpsApi('clubQuery-submitClubSchedule', {
            teamId: club.id,
            map: map
        }, (data) => {
            if (data.success) {
                success();
            } else {
                failure();
            }
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
            failure();
        });
    }

    render() {
        const {club, onCloseClick} = this.props;
        const {clubSchedule, repeating, duration, newImage, progress} = this.state;

        const start = clubSchedule.timestamp != null ? new Date(clubSchedule.timestamp) : new Date();
        
        if (clubSchedule != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubSchedule.title = e.target.value;
                            this.setState({clubSchedule: clubSchedule});
                        }} value={clubSchedule.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubSchedule.description} onChange={e => {
                            clubSchedule.description = e.target.value;
                            this.setState({clubLesson: clubSchedule});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="title">Location</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubSchedule.location = e.target.value;
                            this.setState({clubSchedule: clubSchedule});
                        }} value={clubSchedule.location} />
                    </FormGroup>
                    <FormGroup>
                        <Label style={{marginTop: 10}}>{t('everyone')}</Label>
                        <Switch
                            checked={clubSchedule.everyone}
                            onChange={e=>{
                                clubSchedule.everyone = e.target.checked;
                                this.setState({clubSchedule: clubSchedule});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </FormGroup>
                    {!clubSchedule.everyone && (
                    <FormGroup>
                        <div>Members: {clubSchedule.members != null ? Object.values(clubSchedule.members).length : 0}</div>
                        <div className='divider'/>
                        <MembersView
                            available={club.playerIds}
                            members={clubSchedule.members}
                            onSaveClick={(members)=> {
                                clubSchedule.members = {};
                                if (members != null) {
                                    for (const[key, value] of Object.entries(members)) {
                                        clubSchedule.members[key] = value;
                                    }
                                }
                                this.setState({clubSchedule: clubSchedule});
                            }} />
                    </FormGroup>
                    )}
                    <FormGroup>
                        <Label for="timestamp">{t('start_time')}</Label>
                        <CustomDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MM/DD/YYYY hh:mm"
                            value={start}
                            onChange={ val => {
                                clubSchedule.timestamp = val.valueOf();
                                clubSchedule.deadline = duration * MIN_TIME + clubSchedule.timestamp;
                                this.setState({clubSchedule: clubSchedule});
                            }} />
                    </FormGroup>
                    {!clubSchedule.id && repeating.enabled && (
                    <FormGroup>
                        <Label for="timestamp">{t('end_time')}</Label>
                        <CustomDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MM/DD/YYYY hh:mm"
                            value={repeating.end}
                            onChange={ val => {
                                repeating.end = val.valueOf();
                                this.setState({repeating: repeating});
                            }} />
                    </FormGroup>
                    )}
                    <FormGroup>
                        <Label for="duration_limit">{t('duration_min')}</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            const update = parseFloat(e.target.value);
                            this.setState({duration: update});
                            if (clubSchedule.timestamp != null) {
                                clubSchedule.deadline = update * MIN_TIME + clubSchedule.timestamp;
                                this.setState({clubSchedule: clubSchedule});
                            }
                        }} value={duration} />
                    </FormGroup>
                    {!clubSchedule.id && (
                    <FormGroup>
                        <Label style={{marginTop: 10}}>{t('repeat')}</Label>
                        <Switch
                            checked={repeating.enabled}
                            onChange={e=>{
                                repeating.enabled = e.target.checked;
                                this.setState({repeating: repeating});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </FormGroup>
                    )}
                    {!clubSchedule.id && repeating.enabled && (
                    <Row>
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('sunday')}</Label>
                            <Switch
                                checked={repeating.sunday}
                                onChange={e=>{
                                    repeating.sunday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('monday')}</Label>
                            <Switch
                                checked={repeating.monday}
                                onChange={e=>{
                                    repeating.monday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('tuesday')}</Label>
                            <Switch
                                checked={repeating.tuesday}
                                onChange={e=>{
                                    repeating.tuesday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('wednesday')}</Label>
                            <Switch
                                checked={repeating.wednesday}
                                onChange={e=>{
                                    repeating.wednesday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('thursday')}</Label>
                            <Switch
                                checked={repeating.thursday}
                                onChange={e=>{
                                    repeating.thursday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('friday')}</Label>
                            <Switch
                                checked={repeating.friday}
                                onChange={e=>{
                                    repeating.friday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('saturday')}</Label>
                            <Switch
                                checked={repeating.saturday}
                                onChange={e=>{
                                    repeating.saturday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                    </Row>
                    )}
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='btn-submit' onClick={e => {
                                this.onSaveClick(clubSchedule, newImage);
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubScheduleEdit);