import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Card, CardBody
} from 'reactstrap';

import AttachImageEdit from 'components/edit/AttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, invokeHttpsApi } from "utils/API";

export class VideoEdit extends Component {
    constructor(props) {
        super(props);
        const video = {};
        if (props.video != null) {
            for (const [key, value] of Object.entries(props.video)) {
                video[key] = value;
            }
        }

        this.state = {
            video: video,
            progress: false,
            landingPageData: {},
            newImage: {},
            imageProgress: 0,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, video} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        
        if ((prevProps.video !== video)) {
            const categoryVal = {};
            for (const [key, value] of Object.entries(video)) {
                categoryVal[key] = value;
            }
            this.setState({
                video: categoryVal,
            })
        }
    }

    onSaveClick(video, newImage) {
        const {team, onUpdate} = this.props;
        const objRef = this;
        if (video.link == null || video.link === '') {
            alert("You must input link");
            return;
        } else if (video.bg == null && newImage.name === null) {
            alert("You must input slide image");
            return;
        } else {
            this.setState({progress: true, imageProgress: 0});
            video.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(team, onUpdate, video, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    video: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(team, onUpdate, video, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(video.altText, 'team-landing-video-bg' + team.id, newImage, (url) => {
                video.bg = url;
                objRef.saveChange(team, onUpdate, video, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(team, onUpdate, video, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    video: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(team, onUpdate, video, success, failure) {
        const landingData = (team && team.landingData) ? team.landingData : {};

        landingData.video = {};
        
        for (const [key, value] of Object.entries(video)) {
            landingData.video[key] = value;
        }
        
        team.landingData = {};
        for (const [k,v] of Object.entries(landingData)) {
            team.landingData[k] = v;
        }

        invokeHttpsApi('clubQuery-saveClubEdit', {
            club: team
        }, (data) => {

            // Read result of the Cloud Function.
            if (onUpdate != null) {
                onUpdate(video);
            }
            success(video);
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    handleChange(update) {
        this.setState(update);
    }

    renderVideo() {
        const {video, newImage, progress, imageProgress} = this.state;
        
        if (video != null) {
            return (
                <Card style={{marginBottom: 15}}>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="link">Video Title</Label>
                                <Input type="textarea" name="text" id="title" value={video.title} onChange={e => {
                                    video.title = e.target.value;
                                    this.handleChange({video: video});
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="link">Youtube Link</Label>
                                <Input type="textarea" name="text" id="link" value={video.link} onChange={e => {
                                    video.link = e.target.value;
                                    this.handleChange({video: video});
                                }} />
                            </FormGroup>
                            <AttachImageEdit
                                imageUrl={video.bg}
                                imageClass='home-edit-thumb'
                                label='Slide Item Image'
                                maxHeight={150}
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.handleChange({newImage: file});
                                }} />
                            {progress ? (
                                <Spinner color="primary" />
                            ) : (
                                <div style={{textAlign: 'end'}}>
                                    <Button className='btn-submit' onClick={e => {
                                        this.onSaveClick(video, newImage);
                                    }}>Update</Button>
                                </div>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            );
        } else {
            return <div/>;
        }
    }

    render() {

        return (
            <div>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    Video Section
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />
                {this.renderVideo()}
            </div>
        );
    }
}

export default VideoEdit;