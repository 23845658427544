import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Row, Col, Modal, ModalBody
} from 'reactstrap';
import ReactStars from "react-rating-stars-component";
import UserBadge from 'components/detail/UserBadge';
import ClubChallengeReviewView from 'components/detail/club/ClubChallengeReviewView';
import { toDateTimeString } from 'utils/Utils';

import defaultImage from 'assets/img/icons/video_default.png';

export class ClubChallengeReviewBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {clubChallenge, submission, user } = this.props;
        const {detailOpen} = this.state;
        let dateStr = toDateTimeString(submission.timestamp);
        let challengeImage = clubChallenge.thumbUri != null ? clubChallenge.thumbUri : defaultImage;
        let status = 'In Review';
        let ratingStr = "0.0";
        let statusColor = '#F0D11F';
        if (submission.rating > 2) {
            status = 'Reviewed';
            ratingStr = '' + submission.rating;
            statusColor = '#6ec447';
        } else if (submission.rating <= 2 && submission.rating >= 0) {
            status = 'Reviewed';
            ratingStr = '' + submission.rating;
            statusColor = '#f87668';
        }
        let totalPoints = submission.rating === -1 ? 0 : (clubChallenge.points * submission.rating / 5);
        
        if (clubChallenge != null) {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 10}}>
                        <div className='wrap-content-fill-child vertical-center-spacing' style={{marginLeft: 20}}>
                            <Row>
                                <Col sm={4}>
                                    <UserBadge customClass='wrap-content-wrap-child' user={user}/>
                                </Col>
                                <Col sm={8}>
                                    <CardImg
                                        className="profile-thumb"
                                        src={challengeImage}
                                        style={{ width: 20, height: 20, marginRight: 10 }}
                                        />
                                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{clubChallenge.title}</div>
                                </Col>
                            </Row>
                        </div>

                        <div className='wrap-content-wrap-child' style={{marginLeft: 10, marginRight: 20, fontSize: 12, fontWeight: 1000}}>{dateStr}</div>
                    </div>
                    <div className="info-badge">
                        <Card className="flex-row shadow-tile">
                            <CardImg
                                className="profile-thumb info-badge-icon"
                                src={challengeImage} />
                            <CardBody className="info-badge-body review-body">
                                <div className='wrap-content-parent one-line-title' style={{marginTop: 5}}>
                                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{submission.feedback != null ? submission.feedback : "Not Reviewed Yet"}</div>
                                </div>
                                <Row style={{marginTop: 10, marginBottom: 10}}>
                                    <Col xs={12} sm={9}>
                                        <Row>
                                            <Col xs={4} sm={4}>
                                                <div className="review-info" style={{fontWeight: 1000, color: statusColor}}>
                                                    {status}
                                                </div>
                                                <div className="review-info">
                                                    CHALLENGE
                                                </div>
                                                <div className="review-info" style={{fontWeight: 1000}}>
                                                    STATUS
                                                </div>
                                                
                                            </Col>
                                            <Col xs={3} sm={3}>
                                                <div className="review-info" style={{fontWeight: 1000}}>
                                                    {totalPoints}
                                                </div>
                                                <div className="review-info">
                                                TOTAL
                                                </div>
                                                <div className="review-info" style={{fontWeight: 1000}}>
                                                    POINTS
                                                </div>
                                                
                                            </Col>
                                            <Col xs={5} sm={5}>
                                                <div className="review-info">
                                                    {ratingStr}
                                                </div>
                                                <div style={{marginLeft: 'auto', marginRight: 'auto', width: 100}}>
                                                    <ReactStars
                                                        count={5}
                                                        size={20}
                                                        value={submission.rating}
                                                        edit={false}
                                                        isHalf={true}
                                                        activeColor="#ffd700" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <div className='wrap-content-parent'>
                                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                            </div>
                                            <div className='wrap-content-wrap-child review-btn'>
                                                <Button className="btn-submit" onClick={e => {
                                                    this.setState({detailOpen: true})
                                                }}>Review</Button>
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                        <div style={{marginTop: 10}}/>
                    </div>
                    <Modal isOpen={detailOpen}>
                        <ModalBody>
                            <ClubChallengeReviewView 
                                challenge={clubChallenge} 
                                submission={submission} 
                                onCloseClick={() => {
                                    this.setState({detailOpen: false})
                                }} />
                        </ModalBody>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ClubChallengeReviewBadge;