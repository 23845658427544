import React, { Component } from "react";
import Avatar from 'components/detail/Avatar';
import { connect } from 'react-redux';

import { Card, CardBody, ListGroup, ListGroupItem, Button, FormGroup, Label, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

class ManagersEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managerIds: {},
            users: {},
            coachGroups: {},
            gradeGroups: {},
            groupChoice: {},
            searchStr: '',
            editOpen: false,
            followersPick: false,
        }
    }

    componentDidMount() {
        const {managerIds} = this.props;

        if (managerIds != null) {
            const update = {};
            for (const [key, value] of Object.entries(managerIds)) {
                update[key] = value;
            }
            this.setState({managerIds: update});
        }
    }

    componentDidUpdate(prevProps) {
        const {managerIds} = this.props;
        if (prevProps.managerIds !== managerIds) {
            if (managerIds != null) {
                const update = {};
                for (const [key, value] of Object.entries(managerIds)) {
                    update[key] = value;
                }
                this.setState({managerIds: update});
            } else {
                this.setState({managerIds: {}});
            }
        }
    }
    
    render() {
        const { users, available, onSaveClick, onCloseClick } = this.props;
        const { managerIds, searchStr } = this.state;

        const members = [];
        const pending = [];
        const choice = {};

        if (users != null && managerIds != null) {
            for (const [key, item] of Object.entries(users)){
                const inAvailable = available != null && Object.values(available).includes(key);
                
                if (item != null && inAvailable &&
                    (searchStr == null || searchStr === '' || item.username.toLowerCase().includes(searchStr.toLowerCase()))
                ) {
                    choice[key] = item;
                }
            }
        }

        const usersChoice = Object.values(choice);
        usersChoice.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        for (var i = 0; i < usersChoice.length; i ++) {
            const item = usersChoice[i];
            let itemType = Object.values(managerIds).includes(item.uid) ? "Added" : "Not Added";

            if (Object.values(managerIds).includes(item.uid)) {
                members.push(
                    <ListGroupItem key={item.uid}>
                        <div className='wrap-content-parent'>
                            <Avatar className='wrap-content-wrap-child vertical-center-spacing' src={item.photoUri} /> 
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                <div>{item.username}</div>
                                <div style={{fontSize: 12}}>{itemType}</div>
                            </div>
                            <Button className={Object.values(managerIds).includes(item.uid) ? 'btn-cancel' : 'btn-submit'} onClick={e=>{
                                if (Object.values(managerIds).includes(item.uid)) {
                                    delete managerIds[item.uid];
                                } else {
                                    managerIds[item.uid] = item.uid;
                                }
                                this.setState({managerIds: managerIds});
                            }}>{Object.values(managerIds).includes(item.uid) ? "Remove" : "Add"}</Button>
                        </div>
                    </ListGroupItem>
                )
            } else {
                pending.push(
                    <ListGroupItem key={item.uid}>
                        <div className='wrap-content-parent'>
                            <Avatar className='wrap-content-wrap-child vertical-center-spacing' src={item.photoUri} /> 
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                <div>{item.username}</div>
                                <div style={{fontSize: 12}}>{itemType}</div>
                            </div>
                            <Button className={Object.values(managerIds).includes(item.uid) ? 'btn-cancel' : 'btn-submit'} onClick={e=>{
                                if (Object.values(managerIds).includes(item.uid)) {
                                    delete managerIds[item.uid];
                                } else {
                                    managerIds[item.uid] = item.uid;
                                }
                                this.setState({managerIds: managerIds});
                            }}>{Object.values(managerIds).includes(item.uid) ? "Remove" : "Add"}</Button>
                        </div>
                    </ListGroupItem>
                )
            }
        }

        return (
            <Card>
                <CardBody>
                    <div>Managers</div>
                    <div style={{height: 10}}/>
                    <FormGroup>
                        <Input
                            type="search"
                            name="search"
                            id="member_search"
                            placeholder="Search by Username"
                            onChange={e=>{
                                this.setState({searchStr: e.target.value});
                            }} />
                    </FormGroup>
                    <ListGroup style={{maxHeight: 200, overflowY: 'scroll'}}>
                        {members}
                        {pending}
                    </ListGroup>
                    {Object.values(choice).length > 0 && (
                        <FormGroup>
                            <Label for="locationChoice" check>Visible Users: {
                                Object.values(choice).length
                            }</Label>
                            <div className='wrap-content-parent'>
                                <Button className='wrap-content-wrap-child btn-submit' onClick={e=>{
                                    for (const[key,] of Object.entries(choice)) {
                                        managerIds[key] = key;
                                    }
                                    this.setState({managerIds: managerIds});
                                }}>Add All</Button>
                                <Button className='wrap-content-wrap-child btn-cancel' onClick={e=>{
                                    for (const[key,] of Object.entries(choice)) {
                                        delete managerIds[key];
                                    }
                                    this.setState({managerIds: managerIds});
                                }}>Remove All</Button>
                            </div>
                        </FormGroup>
                    )}
                    <div style={{height: 10}}/>
                    <Button className='btn-edit' onClick={e=> {
                        if (onSaveClick != null) {
                            onSaveClick(managerIds);
                        }
                    }}>{t('save')}</Button>
                    <Button className='btn-cancel' onClick={e=> {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </CardBody>
            </Card>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}
  
const connectedApp = withTranslation()(connect(mapState)(ManagersEditView));
export { connectedApp as ManagersEditView };
