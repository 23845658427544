import React, { Component } from "react";
import {
    Button, Spinner, Input, Form
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import BingoNumbers from "components/fundraise/BingoNumbers";

import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';

import {
    BiDonateHeart
} from 'react-icons/bi';

import { 
    toDateTimeString 
} from 'utils/Utils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";
import { STATUS_FINISHED, STATUS_ONGOING } from "utils/constants";

export class BingoEdit extends Component {
    constructor(props) {
        super(props);
        const bingo = {};
        if (props.bingo != null) {
            for (const [key, value] of Object.entries(props.bingo)) {
                bingo[key] = value;
            }
        }
        
        this.state = {
            bingo: bingo,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, bingo} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.bingo !== bingo)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(bingo)) {
                challengeVal[key] = value;
            }
            this.setState({
                bingo: challengeVal
            })
        }
    }

    onSaveClick(bingo, newImage) {
        const {fundraise, onCloseClick, onChanged} = this.props;
        const objRef = this;
        if (bingo.title == null || bingo.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (bingo.id == null || bingo.id === '') {
                bingo.id = generateId('/fundraise/' + fundraise.id + '/bingo');
            }
            bingo.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(objRef, bingo, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
                if (onChanged != null) {
                    onChanged(bingo)
                }
            }, (error) => {
                alert("Submitting Change failed.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
            })
        }
    }

    saveChangeWithImage(objRef, bingo, newImage, success, failure) {
        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(bingo.id, 'bingo', newImage, (url) => {
                bingo.thumbUri = url;
                //success(bingo);
                objRef.saveChange(bingo, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(bingo, success, failure)
        }
    }

    saveChange(bingo, success, failure) {
        const {fundraise} = this.props;

        if (fundraise) {
            bingo.fundraiseId = fundraise.id
            bingo.owner = fundraise.owner;
            if (fundraise.managers) {
                bingo.managers = {};
                for (const [k,v] of Object.entries(fundraise.managers)) {
                    bingo.managers[k] = v;
                }
            }
        }
        if (!fundraise.bingo) {
            fundraise.bingo = {};
        }
        fundraise.bingo[bingo.id] = bingo;
        const data = {map: fundraise}
        
        invokeHttpsApi('fundraise-submitFundraise', data, (data) => {
            if (data && data.success) {
                success(bingo);
            } else {
                success(bingo);
            }
        }, (error) => {
            console.log('save error', error);
            failure(error);
        });
    }

    renderDonateItem(item) {
        const {users} = this.props;

        const price = parseFloat(item.price) / 100;

        let msg = "";
        if (item.uid && users[item.uid]) {
            msg = users[item.uid].username;
        }
        let timestamp = item.timestamp;
        if (!timestamp) {
            timestamp = 0;
        }

        return (
            <div className="wrap-content-parent" style={{margin: 0, padding: 5}}>
                <span className="wrap-content-wrap-child fund-table-info" style={{fontSize: 20, margin: '5px 15px'}}><BiDonateHeart/></span>
                <div className="wrap-content-fill-child">
                    <h4 className='fund-table-info'>
                    {msg} <span style={{color: 'blue'}}>{item.bingoStr ? "Number: " + item.bingoStr : ""}</span>
                    </h4>
                    <div className="fund-table-info">
                    <b style={{color: '#38be55', marginRight: 15}}>${price}</b> {toDateTimeString(timestamp)}
                    </div>
                </div>
            </div>
        );
    }

    renderDonates() {
        const {fundraise} = this.props;
        const {bingo} = this.state;
        let balance = 0;
        let paymentsArr = [];
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (item.bingoId === bingo.id) {
                    if (item.price && item.fee) {
                        balance += (parseFloat(item.price) - parseFloat(item.fee)) / 100;
                    } else if (item.price) {
                        balance += parseFloat(item.price) / 100;
                    }
                    paymentsArr.push(item);
                }
            }
        }

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        const income = [];
        
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            income.push(
                <div>{this.renderDonateItem(item)}</div>
            );
        }

        const items = [];
        for (const item of income) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div style={{margin: '5px 0px', borderRadius: 6, border: '1px solid rgba(0,0,0,0.2)'}}>
                <div className="wrap-content-parent fundraise-margin" style={{marginTop: 10}}>
                    <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24, marginBottom: 0, marginLeft: 10}}>${balance} </h1>
                    <div className="wrap-content-fill-child fund-table-info" style={{marginTop: 'auto'}}>{t('collected_of_goal')} ${fundraise.donateGoal} {t('goal')}</div>
                </div>
                <div className="divider"/>
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {createMode, onCloseClick} = this.props;
        const {bingo, indexChoice, newImage, imageProgress, progress} = this.state;

        let numArr = [];
        for (let i = 1; i < 50; i ++) {
            numArr.push(i);
        }
        
        if (bingo != null) {
            let numArr = bingo.numArr ? bingo.numArr.split(',') : [0,0,0,0,0,0,0];
    
            let numberArr = [];
            for (let i = 1; i < 50; i ++) {
                if (!numArr.includes(i)) {
                    numberArr.push(i);
                }
            }

            let descArr = [];
            if (!bingo.prizeDescriptions) {
                bingo.prizeDescriptions = {}
            }
            let prizeDescriptions = [];
            for (let i = 1; i < 7; i ++) {
                prizeDescriptions[i] = bingo.prizeDescriptions["match_" + i] ? bingo.prizeDescriptions["match_" + i] : null;
            }
            for (let i = 1; i < 7; i ++) {
                descArr.push(
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Prize Description for {i} match(s)
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="textarea" className='form-control' name="text" id="title" onChange={e => {
                                    bingo.prizeDescriptions["match_" + i] = e.target.value;
                                    this.setState({bingo: bingo});
                                }} value={prizeDescriptions[i]} />
                            </div>
                        </div>
                    </div>
                )
            }

            return (
                <Form className='form-edit'>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Title
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    bingo.title = e.target.value;
                                    this.setState({bingo: bingo});
                                }} value={bingo.title} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Image
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <HorizontalAttachImageEdit
                                imageUrl={bingo.thumbUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Description
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="textarea" className='form-control' name="text" id="title" onChange={e => {
                                    bingo.description = e.target.value;
                                    this.setState({bingo: bingo});
                                }} value={bingo.description} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        {t('bingo_price')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="number" step={0.01} className='form-control' name="text" id="title" onChange={e => {
                                    bingo.price = parseFloat(e.target.value);
                                    this.setState({bingo: bingo});
                                }} value={bingo.price} />
                            </div>
                        </div>
                    </div>
                    {bingo.status && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        {t('bingo_number')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <BingoNumbers
                                bingoStr={bingo.numArr}
                                indexChoice={indexChoice}
                                onIndexChange={i=> {
                                    this.setState({indexChoice: i});
                                }} />

                            {bingo.status === STATUS_ONGOING && (
                            <div className='form-edit-input'>
                                <Input type="select" className='form-control bingo-num-edit' name="text" id="title" onChange={e => {
                                    numArr[indexChoice] = parseInt(e.target.value);
                                    var update = ""
                                    for (let i = 0; i < 7; i ++) {
                                        update += (i === 0 ? "" : ",") + numArr[i];
                                    }
                                    bingo.numArr = update;
                                    this.setState({bingo: bingo});
                                }} value={numArr[indexChoice]}>
                                <option key={-1} value={-1}>-</option>
                                {numberArr.map((val,idx)=>(
                                <option key={idx} value={val}>{val}</option>
                                ))}
                                </Input>
                            </div>
                            )}
                        </div>
                    </div>
                    )}
                    {bingo.status === STATUS_ONGOING && descArr}
                    {bingo.id && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        {t('donates')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            {this.renderDonates()}
                        </div>
                    </div>
                    )}
                    {bingo.status === STATUS_ONGOING && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        {t('bingo_to_fundraise')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="number" step={0.01} className='form-control' name="text" id="title" onChange={e => {
                                    bingo.output = parseFloat(e.target.value);
                                    this.setState({bingo: bingo});
                                }} value={bingo.output} />
                            </div>
                        </div>
                    </div>
                    )}
                    {!bingo.status && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} />
                        <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                            <Button className='btn-edit' style={{margin: '0px auto'}} onClick={e => {
                                if (window.confirm(t('finish_registration_prompt'))) {
                                    bingo.status = STATUS_ONGOING;
                                    this.setState({bingo: bingo});
                                }
                            }}>{t('finish_registration')}</Button>
                        </div>
                    </div>
                    )}
                    {bingo.status === STATUS_ONGOING && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} />
                        <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                            <Button className='btn-edit' style={{margin: '0px auto'}} onClick={e => {
                                for (let i = 0; i < 7; i ++) {
                                    if (parseInt(numArr[i]) === 0) {
                                        alert('bingo_num_invalid');
                                        return;
                                    }
                                }
                                if (window.confirm(t('finish_bingo_prompt'))) {
                                    bingo.status = STATUS_FINISHED;
                                    this.setState({bingo: bingo});
                                }
                            }}>{t('finish_bingo')}</Button>
                        </div>
                    </div>
                    )}
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <div className='row' style={{textAlign: 'center'}}>
                                <button className='btn btn-secondary btn-edit' style={{margin: '0px auto'}} onClick={e => {
                                this.onSaveClick(bingo, newImage);
                                }}>{t('create')}</button>
                            </div>
                        ) : (
                            <div className='form-edit-row row'>
                                <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} >
                                    <button className='btn btn-secondary btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(bingo, newImage);
                                    }}>{t('save')}</button>
                                </div>
                                <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </div>
                            </div>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(BingoEdit);