import React, { Component } from 'react';
import {
    Col, Row, Modal, ModalBody
} from 'reactstrap';
import { t } from "i18next";

import SeasonTeamRow from 'components/detail/organization/SeasonTeamRow';
import {SeasonTeamDetails} from 'components/detail/organization/SeasonTeamDetails';
import { saveSeason } from 'utils/API';

export class SeasonTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: {},
            users: {},
            category: {},
            teamChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    handleChange(e, value) {
        this.setState({mainTab: value});
    }

    renderTeamPopup() {
        const {season, users, teams, locations, onSeasonUpdate, onUserUpdate, onChanged} = this.props;
        const {teamChoice} = this.state;
        
        return (
            <SeasonTeamDetails
                season={season}
                users={users} 
                teams={teams} 
                locations={locations} 
                teamChoice={teamChoice}
                onChanged={()=> {
                    if (onChanged) {
                        onChanged();
                    }
                }}
                onClickClose={()=> {
                    this.setState({teamChoice: {}})
                }}
                onSeasonUpdate={(update, teamUpdate)=> {
                    if (onSeasonUpdate) {
                        onSeasonUpdate(update);
                    }
                    if (teamUpdate) {
                        this.setState({teamChoice: teamUpdate})
                    }
                }}
                onUserUpdate={(user, team)=> {
                    if (onUserUpdate) {
                        onUserUpdate(user, team);
                    }
                }} />
        );
    }

    saveSeasonUpdate(season) {
        saveSeason(season, () => {
            this.setState({season: season})
        }, (error) => {
            this.setState({progress: false})
        })
    }

    renderContents() {
        const {organization, season, teams} = this.props;

        let members = [];
        let pending = [];
        let invited = [];
        let free = [];
        for (const [key, value] of Object.entries(teams)) {
            const isMember = season.teamIds != null && Object.values(season.teamIds).includes(key);
            const isPending = season.pendingIds != null && Object.values(season.pendingIds).includes(key);
            const isInvited = season.invitedIds != null && Object.values(season.invitedIds).includes(key);
            const isDirectPending = season.directPending != null && Object.values(season.directPending).includes(key);
            
            if (isMember) {
                members.push(
                    this.renderBadge(season, value)
                );
            }
            if (isPending) {
                pending.push(
                    this.renderBadge(season, value)
                );
            }
            if (isDirectPending) {
                pending.push(
                    this.renderBadge(season, value)
                );
            }
            if (isInvited) {
                invited.push(
                    this.renderBadge(season, value)
                );
            }
            if (!isMember && !isPending && !isInvited && !isDirectPending) {
                if (organization && organization.teamIds && Object.values(organization.teamIds).includes(key)) {
                    free.push(
                        this.renderBadge(season, value)
                    );
                }
            }
        }
        return (
        <Row>
            <Col lg={6} sm={12}>
            {this.renderTeamTable('Member Teams (' + members.length + ')', members)}
            {this.renderTeamTable('Invited Teams (' + invited.length + ')', invited)}
            </Col>
            <Col lg={6} sm={12}>
            {this.renderTeamTable('Pending Teams (' + pending.length + ')', pending)}
            {this.renderTeamTable('Avaliable Teams (' + free.length + ')', free)}
            </Col>
        </Row>
        );
    }

    renderTeamTable(title, items) {
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={5} xs={7} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('teams')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('players')}
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                            
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderBadge(season, team) {
        const {onChanged} = this.props;
        return (
            <div style={{marginTop: 10, marginBottom: 10}}>
                <SeasonTeamRow 
                    key={team.id}
                    team={team}
                    season={season}
                    allowDetail={true}
                    onClickDetail={()=> {
                        this.setState({teamChoice: team});
                    }}
                    onChanged={(update)=> {
                        if (onChanged != null) {
                            onChanged(update);
                        }
                    }} />
            </div>
        );
    }

    render() {
        const {teamChoice} = this.state;
        
        return (
            <div>
                <h3 style={{marginTop: 30}}>{t('teams')}</h3>
                
                {this.renderContents()}
                <Modal isOpen={teamChoice.id != null}>
                    <ModalBody>
                    {this.renderTeamPopup()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SeasonTeams
