import React, { Component } from "react";

export default class InviteHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        }
    }

    render() {
        const {title, defaultIcon, thumbUri, email, state, forTable, memberLabel} = this.props;
        
        var status = "Existing";
        var statusColor ="#38be55"
        if (state === 'invited') {
            status = "Invited";
            statusColor ="#3592dd";
        } else if (state === 'registered') {
            status = "Registered";
            statusColor ="#F0D11F";
        } else if (state === 'clubPending') {
            status = "Offline Invited";
            statusColor ="#EE000F";
        } else if (state === 'pending') {
            status = "Pending";
            statusColor ="#FF9900";
        } else if (state === 'member') {
            status = memberLabel ? memberLabel : "Member";
            statusColor ="#38be55";
        } else if (state === 'add') {
            status = memberLabel ? memberLabel : "To Add";
            statusColor ="#f83e55";
        }

        const icon = thumbUri ? thumbUri : defaultIcon;

        return (
            <div className={forTable ? "table-tile" : "shadow-tile"}>
                <div className='wrap-content-parent'>
                    <div className='wrap-content-wrap-child'>
                        <img src={icon} alt='logo' className='invite-icon'/>
                    </div>
                    <div className='wrap-content-fill-child'>
                        <h1 className="invite-title">
                            {title}
                        </h1>
                        <div className='wrap-content-parent'>
                            <h3 className="invite-email wrap-content-fill-child">
                                {email}
                            </h3>
                            <h3 className="invite-status wrap-content-wrap-child" style={{color: statusColor}}>
                                {status}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
