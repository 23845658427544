import React, { Component } from "react";
import {
    CardImg, Row, Col, Button, FormGroup, Input, Modal, ModalBody,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import {
    GiCardPlay
} from 'react-icons/gi'

import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import playersIcon from 'assets/img/icons/players_icon.png';
import starIcon from 'assets/img/icons/badge_flower_star.png';
import { getTeamPlayerStatisticsList } from 'utils/statistics';
import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import OrganizationPlayerCard from 'components/detail/organization/landing/OrganizationPlayerCard';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import Pagination from 'react-responsive-pagination';
import defaultBg from 'assets/img/icons/stadium-header.jpg';
import { STATUS_FINISHED } from "utils/constants";

const numInvalid = 999999999;

export class OrganizationTeamStatistics extends Component {
    constructor(props) {
        super(props);
        let locationsChoice = {};
        let seasonChoice = {};
        if (props.seasons != null) {
            for (const val of Object.values(props.seasons)) {
                if ((!props.organization || val.organizationId === props.organization.id) && val.teamIds != null && Object.values(val.teamIds).includes(props.team.id)) {
                    seasonChoice = val;
                    if (val.status !== STATUS_FINISHED) {
                        break;
                    }
                }
            }
        }
        if (props.locations != null) {
            for (const [key, value] of Object.entries(props.locations)) {
                if (!props.organization || value.organizationId === props.organization.id) {
                    locationsChoice[key] = value;
                }
            }
        }
        this.state = {
            locationsChoice: locationsChoice,
            seasonChoice: seasonChoice,
            page: 0,
            editOpen: false,
            saveProgress: false,
        }
    }
    
    renderRosterBadge(user, rank, num1, num2, num3) {
        let thumbImage = user && user.photoUri != null ? user.photoUri : playersIcon;

        return (
            <Row onClick={e=>{this.setState({playerChoice: user})}}>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank === numInvalid ? "-" : rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num1 ? num1 : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num2 ? num2 : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num3 ? num3 : 0}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
    
    renderBadge(user, rank, num1, num2) {
        let thumbImage = user && user.photoUri != null ? user.photoUri : playersIcon;

        return (
            <Row onClick={e=>{this.setState({playerChoice: user})}}>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={6} xs={6} sm={6} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num1 ? num1 : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num2 ? num2 : 0}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderScorers() {
        const {team, users} = this.props;
        const {seasonChoice} = this.state;

        let statistics = getTeamPlayerStatisticsList(seasonChoice, team != null ? team.id : null);
        let topScore = [];
        for (const val of Object.values(statistics)) {
            topScore.push(val);
        }
        topScore.sort((a,b)=> {
            return b.score - a.score
        });
        
        let items = [];
        let rank = 0;
        for (const item of topScore) {
            rank +=1 ;
            if (users[item.uid]) {
                items.push(
                    this.renderBadge(users[item.uid], rank, item.score, item.assist)
                );
            }
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                Top Scorers
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={6} xs={6} sm={6} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('score')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('assist')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderRoster() {
        const {team, users} = this.props;
        const {seasonChoice} = this.state;

        let teamData = seasonChoice && seasonChoice.teamData && seasonChoice.teamData[team.id] ? seasonChoice.teamData[team.id] : {};

        let statistics = getTeamPlayerStatisticsList(seasonChoice, team != null ? team.id : null);
        let roster = teamData.roaster ? teamData.roaster : {};
        let topScore = [];
        for (const val of Object.values(roster)) {
            if (users[val]) {

                let item = null;
                if (teamData.profileData && teamData.profileData[val]) {
                    item = teamData.profileData[val];
                    item.username = users[val].username;

                } else {
                    item = {
                        uid: val,
                        number: numInvalid,
                        photoUri: users[val].photoUri,
                        username: users[val].username,
                    };

                }
                if (statistics[val]) {
                    item.statistics = statistics[val];
                } else {
                    item.statistics = {
                        uid: val,
                        score: 0,
                        assist: 0,
                        foul: 0,
                        yellow: 0,
                        red: 0,
                        games: 0,
                    }
                }
                topScore.push(item);
            }
        }
        topScore.sort((a,b)=> {
            return b.num - a.num
        });
        
        let items = [];
        
        for (const item of topScore) {
            
            if (users[item.uid]) {
                items.push(
                    this.renderRosterBadge(item, item.number, item.statistics.games, item.statistics.score, item.statistics.assist)
                );
            }
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {t('roster')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Num
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('games')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('score')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('assist')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCoaches() {
        const {team, users} = this.props;
        const {seasonChoice} = this.state;

        let teamData = seasonChoice && seasonChoice.teamData && seasonChoice.teamData[team.id] ? seasonChoice.teamData[team.id] : {};

        let statistics = getTeamPlayerStatisticsList(seasonChoice, team != null ? team.id : null);
        let roster = teamData.stuff ? teamData.stuff : {};
        let topScore = [];
        for (const val of Object.values(roster)) {
            if (users[val]) {

                let item = null;
                if (teamData.profileData && teamData.profileData[val]) {
                    item = teamData.profileData[val];
                    item.username = users[val].username;

                } else {
                    item = {
                        uid: val,
                        number: numInvalid,
                        photoUri: users[val].photoUri,
                        username: users[val].username,
                    };

                }
                if (statistics[val]) {
                    item.statistics = statistics[val];
                } else {
                    item.statistics = {
                        uid: val,
                        score: 0,
                        assist: 0,
                        foul: 0,
                        yellow: 0,
                        red: 0,
                        games: 0,
                    }
                }
                topScore.push(item);
            }
        }
        topScore.sort((a,b)=> {
            return b.num - a.num
        });
        
        let items = [];
        
        for (const item of topScore) {
            
            if (users[item.uid]) {
                items.push(
                    this.renderRosterBadge(item, item.number, item.statistics.games, item.statistics.score, item.statistics.assist)
                );
            }
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                Coaches
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Num
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('games')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('score')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('assist')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderRedCards() {
        const {team, users} = this.props;
        const {seasonChoice} = this.state;

        let statistics = getTeamPlayerStatisticsList(seasonChoice, team != null ? team.id : null);
        let topScore = [];
        for (const val of Object.values(statistics)) {
            topScore.push(val);
        }
        topScore.sort((a,b)=> {
            return b.red - a.red
        });
        
        let items = [];
        let rank = 0;
        for (const item of topScore) {
            rank +=1 ;
            if (users[item.uid]) {
                items.push(
                    this.renderBadge(users[item.uid], rank, item.red, item.yellow)
                );
            }
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {t('top_red')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={6} xs={6} sm={6} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: 'red'}}><GiCardPlay/></span>
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: 'yellow'}}><GiCardPlay/></span>
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPlayerCard() {
        const {team, organization, organizations, seasons, divisions, posts, users, teams, cards, locations} = this.props;
        const {seasonChoice, playerChoice} = this.state;
        
        return (
            <OrganizationPlayerCard
                team={team}
                organization={organization}
                organizations={organizations}
                season={seasonChoice}
                divisions={divisions}
                posts={posts}
                users={users}
                teams={teams}
                cards={cards}
                seasons={seasons}
                locations={locations}
                player={playerChoice}
                onClickClose={() => {
                    this.setState({playerChoice: null})
                }} />
        )
    }

    renderCoachCard() {
        const {team} = this.props;
        const {seasonChoice, coachChoice} = this.state;
        let statistics = getTeamPlayerStatisticsList(seasonChoice, team != null ? team.id : null);
        let choice = statistics[coachChoice.uid] ? statistics[coachChoice.uid] : {
            uid: coachChoice.uid,
            score: 0,
            assist: 0,
            foul: 0,
            yellow: 0,
            red: 0,
            games: 0,
        }

        let thumbImage = coachChoice && coachChoice.photoUri != null ? coachChoice.photoUri : playersIcon;
        let teamData = seasonChoice && seasonChoice.teamData && seasonChoice.teamData[team.id] ? seasonChoice.teamData[team.id] : {};

        let item = null;
        if (teamData.profileData && teamData.profileData[coachChoice.uid]) {
            item = teamData.profileData[coachChoice.uid];
            item.username = coachChoice.username;

        } else {
            item = {
                uid: coachChoice.uid,
                number: numInvalid,
                photoUri: coachChoice.photoUri,
                username: coachChoice.username,
                bio: coachChoice.bio,
            };

        }
        return (
            <div className="shadow-tile-normal" style={{backgroundColor: 'rgb(238, 238, 238)'}}>
                <Row>
                    <Col className="text-center" sm={4} xs={12} style={{minHeight: 540, position: 'relative'}}>
                        <img alt='' src={defaultBg} className="org-player-card-bg-photo" />
                        <div className="org-player-card-bg-overlay">
                            <CardImg className="org-player-card-photo" src={thumbImage} />
                            <h2 style={{color: 'white'}}>{item.number && item.number !== numInvalid ? "No." + item.number + " " : ""} {item.username}</h2>
                            <div style={{color: 'white', fontSize: 14}}>{item.bio ? item.bio : t('no_bio')}</div>
                        </div>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Row className="statistics-badge">
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-number">{choice.games}</h3>
                                <h3 className="statistics-badge-title">{t('games_played')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-number">{choice.score}</h3>
                                <h3 className="statistics-badge-title">{t('scores_label')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                {/* <h3 className="statistics-badge-number"><GiCardPlay style={{color: 'red'}}/>{choice.red}</h3> */}
                                <h3 className="statistics-badge-number">
                                    <div style={{
                                        display: 'inline-block', 
                                        marginRight: 6,
                                        backgroundColor: 'red', 
                                        width: 16, 
                                        height: 19, 
                                        borderRadius: 4}}/>
                                    {choice.red}
                                </h3>
                                <h3 className="statistics-badge-title">{t('red_card')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                {/* <h3 className="statistics-badge-number"><GiCardPlay style={{color: 'yellow'}}/>{choice.yellow}</h3> */}
                                <h3 className="statistics-badge-number">
                                    <div style={{
                                        display: 'inline-block', 
                                        marginRight: 6,
                                        backgroundColor: 'yellow', 
                                        width: 16, 
                                        height: 19, 
                                        borderRadius: 4}}/>
                                    {choice.yellow}
                                </h3>
                                <h3 className="statistics-badge-title">{t('yellow_card')}</h3>
                            </Col>
                        </Row>
                        <div className="statistics-badge" style={{marginTop: 25}}>
                            <img alt='' src={starIcon} style={{width: 24, height: 24, margin: '10px 8px'}} />
                            <h3 style={{display: 'inline-block', color: 'white', fontSize: 20, margin: 'auto 8px'}}>{t('mom_label')}</h3>
                            <h3 style={{display: 'inline-block', color: 'white', fontSize: 20, margin: 'auto 8px'}}>{choice.mom}</h3>
                        </div>
                        
                        {this.renderGames(coachChoice.uid)}
                        <div className="text-center" style={{marginBottom: 20}}>
                            <Button className="btn-cancel" onClick={e=>{this.setState({coachChoice: null})}}>Close</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    renderGames(uid) {
        const { organization, organizations, seasons, divisions, posts, users, teams, cards, locations, loading } = this.props;
        const { gameChoice, locationChoice, locationsChoice, page} = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if (!organization || value.organizationId === organization.id)  {
                seasonsChoice[key] = value;
            }
        }

        let games = [];

        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                if ((game.scores && game.scores[uid]) || 
                                (game.fouls && game.fouls[uid]) || 
                                (game.events && game.events[uid]) || 
                                (game.gameList && game.gameList[game.homeTeam] && Object.values(game.gameList[game.homeTeam]).includes(uid)) || 
                                (game.gameList && game.gameList[game.awayTeam] && Object.values(game.gameList[game.awayTeam]).includes(uid))) {
                                    games.push(game)
                                }
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 3);
        
        for (const[, value] of Object.entries(games)) {
            if (!organization || value.organizationId === organization.id)  {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.start;            
            let t2 = b.start;

            return t1 - t2;
        })
        const now = new Date().valueOf();
        let pageChoice = page;
        if (page === 0) {
            var index = 0;
            for (const item of result) {
                if (item.start > now) {
                    break;
                }
                index ++;
            }
            pageChoice = parseInt(index / 3) + 1;
        }
        if (pageChoice === result.length / 3 + 1 && pageChoice > 0) {
            pageChoice -= 1;
        }

        let idx = pageChoice > 0 ? (pageChoice - 1) * 3 : 0;
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px", backgroundColor: '#eee'}}>
                <div className="container">
                    <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                        <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                                const id = e.target.value;
                                this.setState({locationChoice: id == null ? {} : locations[id]});
                            }}>
                            <option value={null}></option>
                            {Object.values(locationsChoice).map((location)=> (
                                <option value={location.id}>{location.title}</option>
                            )) }
                        </Input>
                    </FormGroup>
                    <div style={{height: 25}} />
                    {list.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                        {loading ? "Loading Games" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={pageChoice}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
                {gameChoice && (
                    <Modal isOpen={true} style={{marginTop: 70}}>
                        <ModalBody>
                            <GameDetails 
                                game={gameChoice}
                                organization={organization ? organization : organizations[gameChoice.organizationId]}
                                locations={locations}
                                divisions={divisions}
                                seasons={seasons}
                                cards={cards}
                                teams={teams}
                                posts={posts}
                                users={users} 
                                onChanged={()=> {
                                }}
                                onClickClose={()=>{
                                    this.setState({gameChoice: null});
                                }} />
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    }

    render() {
        const {organization, seasons, team} = this.props;
        const {seasonChoice, playerChoice, coachChoice} = this.state;
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if ((!organization || value.organizationId === organization.id) && value.teamIds != null && Object.values(value.teamIds).includes(team.id)) {
                seasonsChoice[key] = value;
            }
        }
        return (
            <div className="">
                <ItemsDrowpdownNav
                    choiceTitle={t('choose_season')}
                    title={t('seasons')}
                    dropdownItems={seasonsChoice}
                    editOpen={false}
                    detailOpen={true}
                    detailItem={seasonChoice}
                    onItemChoice={(update)=>{
                        this.setState({seasonChoice: update})
                    }} />
                {seasonChoice && seasonChoice.id && !playerChoice && (
                <Row style={{margin: 0}}>
                    <Col sm={12} xs={12} style={{padding: 0}}>
                        {this.renderRoster()}
                    </Col>
                    <Col sm={12} xs={12} style={{padding: 0}}>
                        {this.renderCoaches()}
                    </Col>
                    <Col sm={6} xs={12} style={{padding: 0}}>
                        {this.renderScorers()}
                    </Col>
                    <Col sm={6} xs={12} style={{padding: 0}}>
                        {this.renderRedCards()}
                    </Col>
                </Row>
                )}
                {seasonChoice && seasonChoice.id && playerChoice && (
                <Row style={{margin: 0}}>
                    <Col sm={12} xs={12} style={{padding: 0}}>
                        {this.renderPlayerCard()}
                    </Col>
                </Row>
                )}
                {seasonChoice && seasonChoice.id && coachChoice && (
                <Row style={{margin: 0}}>
                    <Col sm={12} xs={12} style={{padding: 0}}>
                        {this.renderCoachCard()}
                    </Col>
                </Row>
                )}
            </div>
        );
    }
}

export default withTranslation()(OrganizationTeamStatistics);