import React, { Component } from 'react';
import {
    Button, Col, Row, Input, Modal, ModalBody, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import Switch from '@mui/material/Switch';
import SeasonTeamRow from 'components/detail/organization/SeasonTeamRow';
import SeasonPlayerRow from 'components/detail/organization/SeasonPlayerRow';
import SubSeasonBadge from "components/detail/organization/SubSeasonBadge";

import CustomDateTimePicker from 'components/template/CustomDateTimePicker';
import ClubEditView from 'components/edit/club/ClubEditView';
import {SeasonOngoingView} from "components/detail/organization/SeasonOngoingView";
import {SeasonFinishedView} from "components/detail/organization/SeasonFinishedView";
import {SeasonTeamDetails} from "components/detail/organization/SeasonTeamDetails";
import {LeagueRound} from "components/edit/organization/LeagueRound";
import {GroupRound} from "components/edit/organization/GroupRound";
import {KnockoutRound} from "components/edit/organization/KnockoutRound";
import HorizontalAvatarList from 'components/members/HorizontalAvatarList';
import TableRowTinay from 'components/detail/header/TableRowTinay';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import SubInfoView from 'components/detail/SubInfoView';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import AttachItemEdit from 'components/edit/AttachItemEdit';
import { mainBlueColor } from 'style/styles';
import { toDateString } from 'utils/Utils';
import { 
    uploadFileToStorage, 
    getFileExtension 
} from 'utils/FirebaseUtils';
import { generateId, invokeHttpsApi, saveSeason } from 'utils/API';
import { getStorageUrl } from "utils/StorageAPI";
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, ROUND_TYPE_LEAGUE, SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT, STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from 'utils/constants';
import CustomTabView from 'components/template/CustomTabView';

export class SeasonOverview extends Component {
    constructor(props) {
        super(props);
        const season = {};
        if (props.season != null) {
            for (const [key,val] of Object.entries(props.season)) {
                season[key] = val;
            }
        }
        if (season.startTime == null) {
            season.startTime = new Date().valueOf()
        }
        this.state = {
            season: season,
            subSeasonChoice: {},
            teamChoice: {},
            detailOpen: false,
            editOpen: false,
            createTeam: false,
            mainTab: 0
        };
        this.saveSeasonChange = this.saveSeasonChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {season} = this.props;
        if (prevProps.season !== season) {
            const update = {};
            if (season != null) {
                for (const [key,val] of Object.entries(season)) {
                    update[key] = val;
                }
            }
            this.setState({
                season: update,
                subSeasonChoice: {},
                teamChoice: {},
                detailOpen: false,
                editOpen: false,
                mainTab: 0
            });
        } 
    }
    
    renderContents() {
        const {season} = this.state;

        switch (season.status) {
            case undefined:
            case null:
            case STATUS_REGISTER:
                return this.renderRegistration();
            case STATUS_SCHEDULE:
                return this.renderSchedule();
            case STATUS_ONGOING:
                return this.renderOngoing();
            case STATUS_FINISHED:
                return this.renderFinished();
            default:
                return this.renderRegistration();
        }
    }

    saveSeasonChange(update) {
        const {onChanged} = this.props;
        this.setState({progress: true});
        saveSeason(update, (season) => {
            alert('Season Update Saved Successfully');
            if (onChanged != null) {
                onChanged(update);
            }
            this.setState({
                progress: false,
                season: update,
            });
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    saveTeamChange() {
        const {onChanged} = this.props;
        /*const data = {
            seasonId: "-NYGmEO_sJbK7mmaxTv7",
            teamId: "-NW0jWM0zm_ufXG6Mgdn",
            toReplace: "-NW0joaCofLM6-fZjlin",
        }*/
        const data = {
            seasonId: "-NYGmY3FHc1XjS1p8r1_",
            toReplace: "-NW0jWM0zm_ufXG6Mgdn",
            teamId: "-NW0joaCofLM6-fZjlin",
        }
        this.setState({progress: true});
        invokeHttpsApi('clubQuery-submitSeasonTeamChange', data, (data) => {

            if (data && data.success) {
                alert('Season Update Saved Successfully');
                if (onChanged != null) {
                    onChanged(data.update);
                }
                this.setState({
                    progress: false,
                    season: data.update,
                });
            } else {
                alert('Season Update Saved Successfully');
                this.setState({progress: false});
            }
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderStatusTabs(index) {
        return (
            <div className='wrap-content-fill-child'>
                <CustomTabView
                    value={index}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    items={
                    [
                        {
                            label: t('registration')
                        },
                        {
                            label: t('schedule')
                        },
                        {
                            label: t('ongoing')
                        },
                        {
                            label: t('finihsed')
                        },
                    ]} />
            </div>
        )
    }

    renderRegistration() {
        const {season} = this.state;

        if (season.individualReg) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <div className='wrap-content-parent'>
                        <h3 className='wrap-content-wrap-child vertical-center' style={{fontSize: 18}}>
                            Season Status: 
                        </h3>
                        {this.renderStatusTabs(0)}
                        <div className='wrap-content-wrap-child vertical-center'>
                            <Button className="btn-submit" onClick={e => {
                                if (window.confirm('Are you sure you want to finish registration for this season?')) {
                                    season.status = STATUS_SCHEDULE;
                                    this.saveSeasonChange(season);
                                }
                            }}>Finish Registration</Button>
                        </div>
                        <div className='wrap-content-wrap-child vertical-center'>
                            <Button className="btn-submit" onClick={e => {
                                this.deleteSeason(season);
                            }}>Delete Season</Button>
                        </div>
                    </div>
                    {this.renderSeasonCountChange()}
                    {this.renderIndividualRegister()}
                    {this.renderPlayerInput()}
                </div>
            );
        } else {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <div className='wrap-content-parent'>
                        <h3 className='wrap-content-wrap-child vertical-center' style={{fontSize: 18}}>
                            Season Status: 
                        </h3>
                        {this.renderStatusTabs(0)}
                        <div className='wrap-content-wrap-child vertical-center'>
                        </div>
                        <div className='wrap-content-wrap-child vertical-center'>
                            <Button className="btn-submit" onClick={e => {
                                this.setState({createTeam: true})
                            }}>Create Team</Button>
                            <Button className="btn-submit" onClick={e => {
                                if (window.confirm('Are you sure you want to finish registration for this season?')) {
                                    season.status = STATUS_SCHEDULE;
                                    this.saveSeasonChange(season);
                                }
                            }}>Finish Registration</Button>
                        </div>
                    </div>
                    {this.renderSeasonCountChange()}
                    {this.renderTeams()}
                    {this.renderPlayers()}
                </div>
            );
        }
    }

    renderTeamPendingItem(key, team, season, item) {
        return (
            <TableRowSmall
                key={key}
                title={team.title}
                icon={team.iconUri ? team.iconUri : defaultImage}
                subView={
                <div style={{fontSize: 11}}>
                    <span>{t('method')}: <b>{item.method}</b></span>
                    <div>{t('note')}: {item.note}</div>
                </div>
                }
                detailLabel={"Accept"}
                editLabel={"Reject"}
                onClickDetails={()=> {
                    if (window.confirm('Are you sure you want to accept this request?')) {
                        if (!season.teamRegisters) {
                            season.teamRegisters = {}
                        }
                        season.teamRegisters[key] = item;
                        season.teamIds[key] = key;
                        delete season.teamPending[key];
                        this.setState({season: season})
                        this.saveUpdate(season);
                    }
                }}
                onClickEdit={()=> {
                    if (window.confirm('Are you sure you want to reject this request?')) {
                        delete season.teamPending[key];
                        this.setState({season: season})
                        this.saveUpdate(season);
                    }
                }}/>
        )
    }

    renderPendingItem(key, user, season, item) {
        return (
            <TableRowSmall
                key={key}
                title={user.username}
                icon={user.photoUri ? user.photoUri : playersIcon}
                subView={
                <div style={{fontSize: 11}}>
                    <span>{t('method')}: <b>{item.method}</b> Role: <b>{item.role}</b></span>
                    <div>{t('note')}: {item.note}</div>
                </div>
                }
                detailLabel={"Accept"}
                editLabel={"Reject"}
                onClickDetails={()=> {
                    if (window.confirm('Are you sure you want to accept this request?')) {
                        if (!season.registers) {
                            season.registers = {}
                        }
                        season.registers[key] = item;
                        delete season.pending[key];
                        this.setState({season: season})
                        this.saveUpdate(season);
                    }
                }}
                onClickEdit={()=> {
                    if (window.confirm('Are you sure you want to reject this request?')) {
                        delete season.pending[key];
                        this.setState({season: season})
                        this.saveUpdate(season);
                    }
                }}/>
        )
    }

    renderSeasonCountChange() {
        const {season} = this.state;
        const startTeams = season.startTeams != null ? season.startTeams : 0;
        const groupCount = season.groupCount != null ? season.groupCount : 0;
        const knockoutTeamCount = season.knockoutTeamCount != null ? season.knockoutTeamCount : 0;
        
        return (
            <div>
            <Row className='form-edit-row'>
                <Col className='form-edit-label' sm={5} xs={12}>
                Season Type
                </Col>
                <Col className='form-edit-entry' sm={7} xs={12}>
                    {<div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            //season.type = e.target.value;
                            //this.setState({season: season});
                        }} value={season.type}>
                            <option value={null}></option>
                            <option value={SEASON_TYPE_LEAGUE}>{t('league')}</option>
                            <option value={SEASON_TYPE_TOURNAMENT}>{t('tournament')}</option>
                            <option value={SEASON_TYPE_PLAYOFF}>{t('playoff')}</option>
                            <option value={SEASON_TYPE_CUSTOM}>{t('custom')}</option>
                        </Input>
                    </div>}
                </Col>
            </Row>
            {(season.type == null || season.type === SEASON_TYPE_LEAGUE) && (
            <div>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Start Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="number" name="text" id="title" onChange={e => {
                            season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={startTeams} />
                        </div>
                    </Col>
                </Row>
            </div>
            )}
            {season.type === SEASON_TYPE_TOURNAMENT && (
            <div>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Start Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="number" name="text" id="title" onChange={e => {
                            season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={startTeams} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Group Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            season.groupCount = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={groupCount}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={8}>8</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Knockout Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={knockoutTeamCount}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
            </div>
            )}
            {(season.type === SEASON_TYPE_PLAYOFF) && (
            <div>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Start Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="number" name="text" id="title" onChange={e => {
                            season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={startTeams} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Knockout Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={knockoutTeamCount}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
            </div>
            )}
            {(season.type === SEASON_TYPE_CUSTOM) && (
            <div>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('qualification_stage_type')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.qualification = e.target.value;
                                this.setState({season: season});
                            }} value={season.qualification}>
                                <option value={null}></option>
                                <option value={SEASON_TYPE_LEAGUE}>{t('league')}</option>
                                <option value={ROUND_TYPE_GROUP}>{t('group')}</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Start Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="number" name="text" id="title" onChange={e => {
                            season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={startTeams} />
                        </div>
                    </Col>
                </Row>
                {(season.qualification === ROUND_TYPE_GROUP) && (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Group Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            season.groupCount = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={groupCount}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={8}>8</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
                )}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Knockout Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                            this.setState({season: season});
                        }} value={knockoutTeamCount}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
            </div>
            )}
            </div>
        )
    }

    requestDirectAssign(user) {
        const {season} = this.state;
        if (!window.confirm(t('assign_direct_prompt'))) {
            return;
        }
        const {wallets} = this.props;

        let userId = user.uid;
        if (user.parentId) {
            userId = user.parentId;
        }
        if (user.owner) {
            userId = user.owner;
        }

        let wallet = null;
        for (const item of Object.values(wallets)) {
            if (item.uid === userId && item.organizationId === season.organizationId) {
                wallet = item;
            }
        }
        if (!wallet) {
            wallet = {
                id: generateId("/wallet"),
                uid: userId,
                organizationId: season.organizationId,
                balance: 0,
            }
        }
        
        this.setState({assignProgress: true});
        const price = season.enrollFee && season.enrollFee.playerFee ? season.enrollFee.playerFee : 0;
        
        const data = {
            register: {
                uid: userId,
                userId: user.uid,
                role: 'player',
                method: 'wallet',
                note: '',
                price: price,
            },
            seasonId: season.id,
            wallet: wallet,
        }
        if (user.parentId) {
            data.parentId = user.parentId;
        }
        if (user.owner) {
            data.owner = user.owner;
        }
        /*return;*/
        invokeHttpsApi('purchase-requestSeasonRegisterWallet', data, (data) => {
            if (data && data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.registers) {
                season.registers = {};
            }
            season.registers[user.uid] = {
                uid: userId,
                userId: user.uid,
                role: 'player',
                method: 'wallet',
                note: '',
            }
            if (this.props.onChanged != null) {
                this.props.onChanged(season);
            }
            this.setState({assignProgress: false});
        }, (error) => {
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, assignProgress: false});
        });
    }


    renderPlayerInput() {
        const {users} = this.props;
        const {season, searchStr, assignProgress} = this.state;

        let existing = [];
        if (season.registers) {
            for (const [, item] of Object.entries(season.registers)) {
                if (!existing.includes(item.uid)) {
                    existing.push(item.uid);
                }
            }
        }
        let itemsArr = [];
        for (const [k,v] of Object.entries(users)) {
            if (!existing.includes(k) && searchStr && searchStr.length > 0 && 
                ((v.username && v.username.toLowerCase().includes(searchStr.toLowerCase())) || 
                (v.email && v.email.toLowerCase().includes(searchStr.toLowerCase())))) {
                
                itemsArr.push(
                    <TableRowSmall
                        title={v.username}
                        icon={v.photoUri ? v.photoUri : playersIcon}
                        editLabel={t('assign_direct')}
                        progress={assignProgress}
                        onClickEdit={e=> {
                            this.requestDirectAssign(v);
                        }} />
                );
            }
        }

        return (
            <div className='shadow-tile-table'>
                <Row style={{margin: 0}}>
                    <h3 className='row-table-title'>
                    Direct Player Assign ({itemsArr.length / 2})
                    </h3>
                </Row>
                <div className='divider' />
                <div className='divider' />
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="search" id="search" placeholder='Search by email or username ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </div>
                </div>
                <div className='divider' />
                <div className='divider' />
                {itemsArr.length > 0 ? itemsArr : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Players
                    </div>
                )}
            </div>
        );
    }

    renderIndividualRegister() {
        const {users, teams} = this.props;
        const {season} = this.state;

        const teamArr = [];
        
        if (season.teamIds) {
            for (const teamId of Object.values(season.teamIds)) {
                const team = teams[teamId];
                const avatars = [];
                const avatarsStuff = [];
                const members = (season.teamData && season.teamData[teamId] && season.teamData[teamId].roster) ? season.teamData[teamId].roster : null;
                const stuff = (season.teamData && season.teamData[teamId] && season.teamData[teamId].stuff) ? season.teamData[teamId].stuff : null;
        
                if (users != null && members != null) {
                    for (const [key, ] of Object.entries(members)){
                        const item = users[key];
                        if (item != null) {
                            avatars.push(
                                {avatar: item.photoUri, name: item.username}
                            )
                        }
                    }
                }
        
                if (users != null && stuff != null) {
                    for (const [key, ] of Object.entries(stuff)){
                        const item = users[key];
                        if (item != null) {
                            avatarsStuff.push(
                                {avatar: item.photoUri, name: item.username}
                            )
                        }
                    }
                }
        
                if (team) {
                    const usersDiv = (
                        <div>
                            <div className='wrap-content-parent'>
                                <span className='wrap-content-child-wrap' style={{fontSize: 10, fontWeight: 900, margin: 'auto 5px'}}>Players</span>
                                <div className='wrap-content-child-fill'>
                                {avatars.length > 0 ? (
                                    <HorizontalAvatarList
                                        avatars={avatars}
                                        avatarProps={{ size: 25 }}
                                        />
                                ) : (
                                    <div style={{fontSize: 10, fontWeight: 900, margin: 'auto 5px'}}>No Roster Picked</div>
                                )}
                                </div>
                            </div>
                            <div className='wrap-content-parent'>
                                <span className='wrap-content-child-wrap' style={{fontSize: 10, fontWeight: 900, margin: 'auto 5px'}}>{t('staff')}</span>
                                <div className='wrap-content-child-fill'>
                                {avatarsStuff.length > 0 ? (
                                    <HorizontalAvatarList
                                        avatars={avatarsStuff}
                                        avatarProps={{ size: 25 }}
                                        />
                                ) : (
                                    <div style={{fontSize: 10, fontWeight: 900, margin: 'auto 5px'}}>No Staff Picked</div>
                                )}
                                </div>
                            </div>
                        </div>
                    )

                    let challengeImage = team.iconUri != null ? team.iconUri : defaultImage;
                    teamArr.push(
                        <TableRowTinay 
                            key={teamId}
                            title={team.title}
                            icon={challengeImage}
                            subView={usersDiv} />
                    );
                }
            }
        }

        const pendingArr = [];

        if (season.pending) {
            for (const [key, item] of Object.entries(season.pending)) {
                if (item.role && item.role !== 'player') {
                    continue;
                }
                const user = users[item.uid];

                pendingArr.push(
                    this.renderPendingItem(key, user, season, item)
                )
                pendingArr.push(
                    <div className='divider'/>
                )
            }
        }
        const playersArr = [];
        const managerArr = [];

        if (season.registers) {
            for (const [, item] of Object.entries(season.registers)) {
                const user = users[item.uid];

                const teamChoice = [];
                if (season.teamIds) {
                    for (const teamId of Object.values(season.teamIds)) {
                        const team = teams[teamId];
                        if (team) {
                            teamChoice.push(team);
                        }
                    }
                }

                const choiceView = (
                    <div>
                        <div className='wrap-content-parent' style={{marginRight: 15}}>
                            <div className='wrap-content-child-wrap' style={{fontSize: 11, width: 180, margin: 'auto 0px'}}>
                                <span>{t('method')}: <b>{item.method}</b></span>
                            </div>
                            <Input className='wrap-content-child-fill' type="select" name="backdrop" id="backdrop" style={{margin: '5px 0px'}} onChange={e => {
                                const teamId = e.target.value;
                                if (!item.role || item.role === 'player') {
                                    if (teamId) {
                                        if (!season.teamData) {
                                            season.teamData = {};
                                        }
                                        if (!season.teamData[teamId]) {
                                            const choice = teams[teamId];
                                            season.teamData[teamId] = {
                                                id: choice.id,
                                                nickname: choice.title,
                                                thumbUri: choice.iconUri,
                                            }
                                        }
                                        if (!season.teamData[teamId].roster) {
                                            season.teamData[teamId].roster = {}
                                        }
                                        season.teamData[teamId].roster[item.uid] = item.uid;
                                        for (const teamDataItem of Object.values(season.teamData)) {
                                            if (teamDataItem.id !== teamId && teamDataItem.roster) {
                                                delete teamDataItem.roster[item.uid];
                                            }
                                        }
                                    } else {
                                        for (const teamDataItem of Object.values(season.teamData)) {
                                            if (teamDataItem.id !== teamId && teamDataItem.roster) {
                                                delete teamDataItem.roster[item.uid];
                                            }
                                        }
                                    }
                                } else if (item.role === 'coach') {
                                    if (teamId) {
                                        if (!season.teamData) {
                                            season.teamData = {};
                                        }
                                        if (!season.teamData[teamId]) {
                                            const choice = teams[teamId];
                                            season.teamData[teamId] = {
                                                id: choice.id,
                                                nickname: choice.title,
                                                thumbUri: choice.iconUri,
                                            }
                                        }
                                        if (!season.teamData[teamId].stuff) {
                                            season.teamData[teamId].stuff = {}
                                        }
                                        season.teamData[teamId].stuff[item.uid] = item.uid;
                                        for (const teamDataItem of Object.values(season.teamData)) {
                                            if (teamDataItem.id !== teamId && teamDataItem.stuff) {
                                                delete teamDataItem.stuff[item.uid];
                                            }
                                        }
                                    } else {
                                        for (const teamDataItem of Object.values(season.teamData)) {
                                            if (teamDataItem.id !== teamId && teamDataItem.stuff) {
                                                delete teamDataItem.stuff[item.uid];
                                            }
                                        }
                                    }
                                }
                                season.registers[item.userId].teamId = teamId;

                                this.setState({season: season});
                                this.saveUpdate(season);
                            }} value={item.teamId}>
                                <option value={null}></option>
                                {teamChoice.map((val,idx)=>(
                                <option key={idx} value={val.id}>{val.title}</option>
                                ))}
                            </Input>
                        </div>
                        <div className='wrap-content-child-wrap' style={{fontSize: 11}}>
                            {t('note')}: {item.note}
                        </div>
                    </div>

                )

                const viewItem = (
                    <TableRowTinay
                        title={user.username}
                        icon={user.photoUri ? user.photoUri : playersIcon}
                        subView={choiceView}/>
                )
                if (item.role == null || item.role === 'player') {
                    playersArr.push(viewItem);
                    playersArr.push(
                        <div className='divider'/>
                    )
                } else {
                    managerArr.push(viewItem);
                    managerArr.push(
                        <div className='divider'/>
                    )
                }
            }
        }
        return (
            <Row>
                <Col sm={6} xs={12}>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Pending Requests ({pendingArr.length / 2})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {pendingArr.length > 0 ? pendingArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Players
                            </div>
                        )}
                    </div>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Players To Assign ({playersArr.length / 2})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {playersArr.length > 0 ? playersArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Players
                            </div>
                        )}
                    </div>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Staff Registering ({managerArr.length / 2})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {managerArr.length > 0 ? managerArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Players
                            </div>
                        )}
                    </div>
                    {this.renderPlayerInput()}
                </Col>
                <Col sm={6} xs={12}>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Registered Teams ({teamArr.length})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {teamArr.length > 0 ? teamArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_teams')}
                            </div>
                        )}
                    </div>
                    {this.renderInvdividualTeams()}
                </Col>
            </Row>
        )
    }

    renderInvdividualTeams() {
        const {organization, seasons, teams, onChanged} = this.props;
        const {season} = this.state;
        
        let directPending = [];
        let teamPending = [];
        let pending = [];
        let invited = [];
        let members = [];
        let free = [];
        for (const val of Object.values(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(val.id)) {
                members.push(val)
            } else if (season.teamPending && Object.keys(season.teamPending).includes(val.id)) {
                teamPending.push(val)
            } else if (season.directPending != null && Object.values(season.directPending).includes(val.id)) {
                directPending.push(val)
            } else if (season.pendingIds != null && Object.values(season.pendingIds).includes(val.id)) {
                pending.push(val)
            } else if (season.invitedIds != null && Object.values(season.invitedIds).includes(val.id)) {
                invited.push(val)
            } else if (organization && organization.teamIds && Object.values(organization.teamIds).includes(val.id)) {
                free.push(val)
            }
        }
        teamPending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        directPending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        pending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        invited.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        free.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        let items = [];
        for (const val of teamPending) {
            const item = season.teamPending[val.id];
            
            items.push(
                <div>{this.renderTeamPendingItem(val.id, val, season, item)}</div>
            )
            items.push(<div className='divider' />);
        }
        for (const val of directPending) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of pending) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of invited) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of free) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <h3 className='row-table-title'>
                        Teams To Register: {pending.length} pending,  {invited.length} invited
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={5} xs={7} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Players
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Seasons
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items}
                </div>
            </div>
        );
    }

    renderPlayers() {
        const {organization, profileData, users, teams} = this.props;
        const {season} = this.state;
        
        let items = [];
        if (season.teamData) {
            for (const teamData of Object.values(season.teamData)) {
                if (teamData.roaster) {
                    for (const uid of Object.values(teamData.roaster)) {
                        items.push(
                            <SeasonPlayerRow
                                organization={organization}
                                season={season}
                                uid={uid}
                                teamId={teamData.id}
                                profileData={profileData}
                                teams={teams}
                                users={users} />
                        )
                        items.push(<div className='divider' />);
                    }
                }
            }
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Team Roster Players: {items.length}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row>
                        <Col lg={7} xs={5} sm={7} className="vertical-center">
                            <h3 className='row-table-title'>
                                Player
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Profile
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Policy
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items}
                </div>
            </div>
        );
    }

    renderTeamPopup() {
        const {season, users, teams, locations, onChanged, onUserUpdate} = this.props;
        const {teamChoice} = this.state;
        
        return (
            <SeasonTeamDetails
                season={season}
                users={users} 
                teams={teams} 
                teamChoice={teamChoice}
                locations={locations}
                onChanged={()=> {
                    if (onChanged) {
                        onChanged();
                    }
                }}
                onClickClose={()=> {
                    this.setState({teamChoice: {}})
                }}
                onSeasonUpdate={(update, teamUpdate)=> {
                    if (onChanged) {
                        onChanged(update);
                    }
                    if (teamUpdate) {
                        this.setState({teamChoice: teamUpdate})
                    }
                }}
                onUserUpdate={(user, team)=> {
                    if (onUserUpdate) {
                        onUserUpdate(user, team);
                    }
                }} />
        );
    }

    renderTeams() {
        const {organization, seasons, teams, onChanged} = this.props;
        const {season} = this.state;
        
        let directPending = [];
        let pending = [];
        let invited = [];
        let members = [];
        let free = [];
        for (const val of Object.values(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(val.id)) {
                members.push(val)
            } else if (season.directPending != null && Object.values(season.directPending).includes(val.id)) {
                directPending.push(val)
            } else if (season.pendingIds != null && Object.values(season.pendingIds).includes(val.id)) {
                pending.push(val)
            } else if (season.invitedIds != null && Object.values(season.invitedIds).includes(val.id)) {
                invited.push(val)
            } else if (organization && organization.teamIds && Object.values(organization.teamIds).includes(val.id)) {
                free.push(val)
            }
        }
        directPending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        pending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        invited.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        free.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        let items = [];
        for (const val of directPending) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of pending) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of invited) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of members) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.setState({teamChoice: val, mainTab: 0})
                    }}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of free) {
            items.push(
                <SeasonTeamRow
                    season={season}
                    team={val}
                    seasons={seasons}
                    onChanged={onChanged} />
            )
            items.push(<div className='divider' />);
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {t('teams')}: {members.length} members,  {pending.length} pending,  {invited.length} invited
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={5} xs={7} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Players
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Seasons
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items}
                </div>
            </div>
        );
    }

    renderSchedule() {
        const {season} = this.state;

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='wrap-content-parent'>
                    <h3 className='wrap-content-wrap-child vertical-center' style={{fontSize: 18}}>
                        Season Status: 
                    </h3>
                    {this.renderStatusTabs(1)}
                    <div className='wrap-content-wrap-child vertical-center'>
                        <Button className="btn-submit" onClick={e => {
                            let gamesReady = true;
                            if (season.subSeasons != null) {
                                for (const val of Object.values(season.subSeasons)) {
                                    if ((val.games == null || Object.values(val.games).length <= 0) && val.type !== ROUND_TYPE_KNOCKOUT) {
                                        gamesReady = false;
                                    }
                                }
                            }
                            if (!gamesReady) {
                                alert('Games are not ready, You can not proceed');
                            } else if (window.confirm('Are you sure you want to finish scheduling for this season?')) {
                                season.status = STATUS_ONGOING;
                                this.saveSeasonChange(season);
                            }
                        }}>Finish scheduling</Button>
                    </div>
                </div>
                <div className='wrap-content-parent text-center' style={{margin: '5px 15px'}}>
                    <div 
                        className='wrap-content-wrap-child'
                        style={{height: 20, marginRight: 15, marginTop: 5}}>{t('start_date')} </div>
                    <div className='wrap-content-wrap-child'>
                        <CustomDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MM/DD/YYYY hh:mm"
                            value={season.startTime != null ? new Date(season.startTime) : new Date()}
                            onChange={ val => {
                                season.startTime = val.valueOf();
                                this.setState({season: season});
                            }} />
                    </div>
                </div>
                {this.renderEmptySeason()}
            </div>
        );
    }

    renderEmptySeason() {
        const {season} = this.state;
        
        switch (season.type) {
            case SEASON_TYPE_LEAGUE:
                return this.renderLeagueDeploy();
            case SEASON_TYPE_TOURNAMENT:
                return this.renderTournamentDeploy();
            case SEASON_TYPE_PLAYOFF:
                return this.renderPlayoffDeploy();
            case SEASON_TYPE_CUSTOM:
                return this.renderCustomDeploy();
            default:
                return this.renderLeagueDeploy();
        }
    }

    renderLeagueDeploy() {
        const {onChange, teams, locations, division, organization, users } = this.props;
        const {season} = this.state;
        
        const subSeasons = season.subSeasons != null ? season.subSeasons : {};
        let leagueRound = subSeasons.league;
        if (leagueRound == null) {
            leagueRound = {
                id: ROUND_TYPE_LEAGUE,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'League',
                type: ROUND_TYPE_LEAGUE,
            }
            if (season.subSeasons == null) {
                season.subSeasons = {};
            }
            season.subSeasons.league = leagueRound
            this.setState({season: season});
        }
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <LeagueRound
                season={season}
                teams={teamsChoice}
                organization={organization}
                division={division}
                locations={locations}
                subSeason={leagueRound}
                users={users}
                onSaveDone={(update)=> {
                    if (onChange != null) {
                        onChange();
                    }
                    season.subSeasons[update.id] = {};
                    for (const [key, value] of Object.entries(update)) {
                        season.subSeasons[update.id][key] = value;
                    }
                    this.setState({season: season, subSeasonChoice: update});
                }} />
        );
    }

    renderTournamentDeploy() {
        const {onChange, teams, locations, division, organization, users } = this.props;
        const {season} = this.state;
        
        const subSeasons = season.subSeasons != null ? season.subSeasons : {};
        let groupRound = subSeasons.group;
        let knockoutRound = subSeasons.knockout;
        if (groupRound == null) {
            groupRound = {
                id: ROUND_TYPE_GROUP,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'Group Round',
                type: ROUND_TYPE_GROUP,
                startTeams: season.startTeams,
                winnerCount: season.knockoutTeamCount,
                groupCount: season.groupCount,
            }
        }
        if (knockoutRound == null) {
            knockoutRound = {
                id: ROUND_TYPE_KNOCKOUT,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'Knockout Round',
                type: ROUND_TYPE_KNOCKOUT,
                startTeams: season.knockoutTeamCount,
            }
        }
        if (season.subSeasons == null) {
            season.subSeasons = {};
        }
        if (season.subSeasons.group !== groupRound || season.subSeasons.knockout !== knockoutRound) {
            season.subSeasons.group = groupRound
            season.subSeasons.knockout = knockoutRound
            this.setState({season: season});
        }
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <div>
                <GroupRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={groupRound}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
                <KnockoutRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={knockoutRound}
                    allowEdit={true}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            </div>
        );
    }

    renderPlayoffDeploy() {
        const {onChange, teams, locations, division, organization, users } = this.props;
        const {season} = this.state;
        
        const subSeasons = season.subSeasons != null ? season.subSeasons : {};
        let knockoutRound = subSeasons.knockout;
        let leagueRound = subSeasons.league;
        if (leagueRound == null) {
            leagueRound = {
                id: ROUND_TYPE_LEAGUE,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'League',
                type: ROUND_TYPE_LEAGUE,
            }
            if (season.subSeasons == null) {
                season.subSeasons = {};
            }
            season.subSeasons.league = leagueRound
            this.setState({season: season});
        }
        if (knockoutRound == null) {
            knockoutRound = {
                id: ROUND_TYPE_KNOCKOUT,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'Knockout Round',
                type: ROUND_TYPE_KNOCKOUT,
                startTeams: season.knockoutTeamCount,
            }
        }
        if (season.subSeasons == null) {
            season.subSeasons = {};
        }
        if (season.subSeasons.league !== leagueRound || season.subSeasons.knockout !== knockoutRound) {
            season.subSeasons.league = leagueRound
            season.subSeasons.knockout = knockoutRound
            this.setState({season: season});
        }
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <div>
                <LeagueRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={leagueRound}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
                <KnockoutRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={knockoutRound}
                    allowEdit={true}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            </div>
        );
    }

    renderCustomDeploy() {
        const {onChange, teams, locations, division, organization, users } = this.props;
        const {season} = this.state;
        
        const subSeasons = season.subSeasons != null ? season.subSeasons : {};
        let leagueRound = subSeasons.league;
        if (leagueRound == null && season.qualification === ROUND_TYPE_LEAGUE) {
            leagueRound = {
                id: ROUND_TYPE_LEAGUE,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'League',
                type: ROUND_TYPE_LEAGUE,
            }
            if (season.subSeasons == null) {
                season.subSeasons = {};
            }
            season.subSeasons.league = leagueRound
            this.setState({season: season});
        }
        let groupRound = subSeasons.group;
        let knockoutRound = subSeasons.knockout;
        if (groupRound == null && season.qualification === ROUND_TYPE_GROUP) {
            groupRound = {
                id: ROUND_TYPE_GROUP,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'Group Round',
                type: ROUND_TYPE_GROUP,
                startTeams: season.startTeams,
                winnerCount: season.knockoutTeamCount,
                groupCount: season.groupCount,
            }
        }
        if (knockoutRound == null) {
            knockoutRound = {
                id: ROUND_TYPE_KNOCKOUT,
                seasonId: season.id,
                owner: season.owner,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                title: 'Knockout Round',
                type: ROUND_TYPE_KNOCKOUT,
                startTeams: season.knockoutTeamCount,
            }
        }
        if (season.subSeasons == null) {
            season.subSeasons = {};
        }
        if (season.subSeasons.group !== groupRound || season.subSeasons.knockout !== knockoutRound) {
            if (season.qualification === ROUND_TYPE_GROUP) {
                season.subSeasons.group = groupRound;
            }
            season.subSeasons.knockout = knockoutRound
            this.setState({season: season});
        }
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <div>
            {season.qualification === ROUND_TYPE_LEAGUE && (
                <LeagueRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={leagueRound}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            )}
            {season.qualification === ROUND_TYPE_GROUP && (
                <GroupRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={groupRound}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            )}
                <KnockoutRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={knockoutRound}
                    allowEdit={true}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            </div>
        );
    }

    renderSubSeasons() {
        const {season, onChange, teams, locations, division, organization, users } = this.props;
        const {subSeasonChoice, editOpen} = this.state;

        const subSeasons = season.subSeasons != null ? season.subSeasons : {};
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        if (subSeasonChoice.id != null && editOpen) {
            return (
                <LeagueRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={subSeasonChoice}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            );
        } else {
            let items = [];
            for (const [key, value] of Object.entries(subSeasons)) {
                items.push(
                    <SubSeasonBadge
                        key={key}
                        season={season}
                        subSeason={value}
                        onClickEdit={()=> {
                            this.setState({subSeasonChoice: value, editOpen: true})
                        }} />
                )
            }
            return (
                items
            );
        }
    }
    
    renderOngoing() {
        const {organization, division, locations, users, teams, trophies} = this.props;
        const {season} = this.state;

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <SeasonOngoingView
                    organization={organization}
                    division={division}
                    locations={locations}
                    users={users}
                    season={season}
                    trophies={trophies}
                    subSeason={season.subSeasons.league}
                    teams={teams}
                    onSaveClicked={(update)=> {
                        this.setState({season: update})
                    }} />
            </div>
        );
    }

    renderFinished() {
        const {organization, division, locations, users, teams, trophies, onSaveClicked} = this.props;
        const {season} = this.state;

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <SeasonFinishedView
                    organization={organization}
                    division={division}
                    locations={locations}
                    users={users}
                    season={season}
                    trophies={trophies}
                    teams={teams}
                    onSaveClicked={(update)=> {
                        if (onSaveClicked != null) {
                            onSaveClicked(update);
                        }
                    }} />
            </div>
        );
    }

    uploadDocument(file, id, dir) {
        const {onChanged} = this.props;
        const {season} = this.state;
        
        this.setState({progress: true, fileProgress: 0});
        uploadFileToStorage(id, file, dir, url => {
            let data = {
                id: id,
                name: id + ".pdf",
                url: url,
                size: file.size
            }
            season.seasonDoc = data;
            
            saveSeason(season, (update) => {
                alert('Season Update Saved Successfully');
                if (onChanged != null) {
                    onChanged(season);
                }
                this.setState({season: season, progress: false, fileProgress: 0});
            }, (error) => {
                console.log('save error', error);
                alert(t('save_failed') + '\n' + error);
                this.setState({progress: false, fileProgress: 0});
            });
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({fileProgress: progress});
            } else {
                this.setState({fileProgress: 0});
            }
        }, error => {
            alert("Saving Attach File Failed.\n" + error);
            this.setState({progress: false, fileProgress: 0});
        });
    }

    generateSheet() {
        const {organization, division, locations} = this.props;
        const {season} = this.state;
        
        const id = season.id;
        const games = [];
        if (season.subSeasons) {
            for (const subSeason of Object.values(season.subSeasons)) {
                if (subSeason.games) {
                    for (const v of Object.values(subSeason.games)) {
                        games.push(v);
                    }
                }
            }
        } else {
            alert('No games on this season.');
            return;
        }
        
        this.setState({
            url: null,
            progress: true
        });
        
        games.sort((a,b)=> {
            return a.start - b.start;
        })

        invokeHttpsApi('gamePdf-generateGamesSheetPdf', {
            id: id,
            games: games,
            organization: organization,
            division: division,
            season: season,
            locations: locations,
        }, (result) => {

            if (result.succuess !== false) {
                getStorageUrl('pdf/' + id + '-sheet.pdf', (url)=> {
                    this.setState({
                        url: url
                    });
                })
            }
            
            this.setState({
                progress: false
            });
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderInfoView() {
        const {division } = this.props;
        const {season, progress, fileProgress, url} = this.state;
        let teamCount = division.teamIds == null ? 0 : Object.values(division.teamIds).length;
        let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
        let playerCount = 0;
        let gameCount = 0;
        let playerIds = [];
        if (season && season.teamData) {
            for (const teamData of Object.values(season.teamData)) {
                if (teamData.roaster) {
                    for (const uid of Object.values(teamData.roaster)) {
                        if (!playerIds.includes(uid)) {
                            playerIds.push(uid);
                        }
                    } 
                }
            }
        }
        playerCount = playerIds.length;
        if (season.subSeasons != null) {
            for (const value of Object.values(season.subSeasons)) {
                gameCount += value.games == null ? 0 : Object.values(value.games).length
            }
        }
        return (
        <div className='season-detail-header'>
            <div className='wrap-content-parent' style={{color: mainBlueColor}}>
                <div className='wrap-content-reduced'>
                    <h4 className='grid-item-subtitle'>
                    {t('sport')}:
                    </h4>
                    <div className="grid-item-subInfo">
                    {division.category != null ? division.category.title : 'Unknown'}
                    </div>
                </div>
                <div className='wrap-content-reduced'>
                    <h4 className='grid-item-subtitle'>
                    {t('gender')}:
                    </h4>
                    <div className="grid-item-subInfo">
                    {division.gender != null ? division.gender : 'None'}
                    </div>
                </div>
                <div className='wrap-content-reduced'>
                    <h4 className='grid-item-subtitle'>
                    {t('age')}:
                    </h4>
                    <div className="grid-item-subInfo">
                    {division.ageTitle != null ? division.ageTitle : 'None'}
                    </div>
                </div>
                <div className='wrap-content-reduced'>
                    <h4 className='grid-item-subtitle'>
                    Season Starts:
                    </h4>
                    <div className="grid-item-subInfo">
                    {toDateString(season.startTime)}
                    </div>
                </div>
                <div className='wrap-content-reduced'>
                    <h4 className='grid-item-subtitle'>
                    Registration Ends:
                    </h4>
                    <div className="grid-item-subInfo">
                    {toDateString(season.regDeadline)}
                    </div>
                </div>
                <div className='wrap-content-fill-child' />
                <div className='wrap-content-reduced'>
                    {progress ? (
                    <Spinner color="primary" />
                    ) : (
                    <Button className="btn-edit" onClick={e=> {
                        this.generateSheet();
                    }}>Generate Game Sheet</Button>
                    )}
                    {url && (
                    <a href={url} target="_blank" rel="noopener noreferrer">Open</a>
                    )}
                </div>
            </div>
            <div className='wrap-content-parent' style={{color: mainBlueColor}}>
                <SubInfoView title='Venues' normal={true} icon={venueIcon} number={locationCount} />
                <SubInfoView title='Teams' normal={true} icon={teamIcon} number={teamCount} />
                <SubInfoView title='Players' normal={true} icon={playersIcon} number={playerCount} />
                <SubInfoView title='Games' normal={true} icon={defaultImage} number={gameCount} />
                <div className='wrap-content-reduced'>
                    <AttachItemEdit
                        attachFile={season.seasonDoc}
                        allowProgress={progress}
                        label='Season Document'
                        progress={fileProgress}
                        onChange={(file)=> {
                            let extension = getFileExtension(file.name);
                            if (extension === "pdf") {
                                this.uploadDocument(file, "seasonDoc", "seasonDoc/" + season.id)
                            } else {
                                alert("Only Pdf file allowed")
                            }
                        }} />
                </div>
                <div className='wrap-content-fill-child vertical-center'>
                {t('hide_season_schedules')}
                </div>
                <div className='wrap-content-wrap-child vertical-center'>
                    <Switch
                        checked={season.hideSchedule}
                        onChange={e=>{
                            season.hideSchedule = e.target.checked;
                            this.setState({season: season});
                        }}
                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                </div>
                <div className='wrap-content-wrap-child vertical-center'>
                    {progress ? (
                    <Spinner color="primary" />
                    ) : (
                    <Button className="btn-edit" onClick={e=> {
                        this.saveSeasonChange(season);
                    }}>{t('save_change')}</Button>
                    )}
                </div>
            </div>
        </div>
        );
    }

    render() {
        const {user, onChanged} = this.props;
        const {season, teamChoice, createTeam} = this.state;
        
        return (
            <div>    
                {this.renderInfoView()}
                
                {this.renderContents()}
                <Modal isOpen={teamChoice.id != null}>
                    <ModalBody>
                    {this.renderTeamPopup()}
                    </ModalBody>
                </Modal>
                <Modal isOpen={createTeam}>
                    <ModalBody>
                        <ClubEditView 
                            uid={user.uid}
                            squad={{}} 
                            organizationId={season.organizationId}
                            divisionId={season.divisionId}
                            seasonId={season.id}
                            onCloseClick={() => {
                                this.setState({createTeam: false})
                            }}
                            onChanged={(squad)=>{
                                if (onChanged) {
                                    onChanged();
                                }
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
    
    saveUpdate(season) {
        const {onChanged} = this.props;
        saveSeason(season, () => {
            this.setState({season: season})
            if (onChanged != null) {
                onChanged(season);
            }
        }, (error) => {
            this.setState({progress: false})
        })
    }

    deleteSeason(season) {
        const {onSeasonDeleted} = this.props;
        if (!window.confirm("If you proceed all data related season will be deleted. It can cause your organization data invalid. Are you sure you want to delete?")) {
            return;
        }
        invokeHttpsApi('organizerQuery-submitSeasonDelete', {season: season}, (data) => {
            // Read result of the Cloud Function.
            
            if (onSeasonDeleted != null) {
                onSeasonDeleted();
            }
        }, (error) => {
            console.log('saveContents error', error);
            this.setState({progress: false})
        });
    }

}

export default withTranslation()(SeasonOverview)
