import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { withRouter } from 'utils/Routher';

import { LandingNavigation } from 'components/landing/globe/LandingNavigation';
import LanguageSelector from 'components/LanguageSelector';

class MainLanding extends PureComponent {
    
    render() {
        
        return (
            <div>
                <LandingNavigation />
                <Outlet/>
                <LanguageSelector />
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (MainLanding));
export { connectedPage as MainLanding };
