import React, { Component } from "react";
import {
    Button, Spinner, ListGroupItem, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import GameEditBadge from 'components/edit/organization/GameEditBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import {GameEdit} from 'components/detail/organization/GameEdit';
import { MdEdit } from 'react-icons/md';

import { checkMatches } from 'utils/Utils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";
import { getStorageUrl } from "utils/StorageAPI";
import { STATUS_FINISHED } from "utils/constants";

class GroupRoundGames extends Component {
    constructor(props) {
        super(props);
        const subSeason = {};
        if (props.subSeason != null) {
            for (const [key, value] of Object.entries(props.subSeason)) {
                subSeason[key] = value;
            }
        }

        const games = subSeason.games != null ? Object.values(subSeason.games) : [];
        
        this.state = {
            subSeason: subSeason,
            pickTeams: false,
            newImage: {},
            deployType: 'weekBased',
            games: games,
            imageProgress: 0,
            locationTimes: {},
        }
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, subSeason} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            
            this.setState({
                pickTeams: false,
                newImage: {},
                locationTimes: {},
                imageProgress: 0
            })
        }
        if ((prevProps.subSeason !== subSeason)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(subSeason)) {
                challengeVal[key] = value;
            }
            const games = subSeason.games != null ? Object.values(subSeason.games) : [];
            this.setState({
                subSeason: challengeVal,
                games: games,
            })
        }
    }

    renderGroups() {
        const {subSeason} = this.state;
        let groups = subSeason.groups != null ? Object.values(subSeason.groups) : [];
        groups.sort((a,b)=> {
            return a.index - b.index;
        })
        
        return (
        <Row style={{marginTop: 20, marginBottom: 30, marginLeft: 0, marginRight: 0}}>
            {groups.map((value, idx)=>(
                <Col key={idx} lg={6} xs={12}>
                    {this.renderGroupGames(value)}
                </Col>
            ))}
        </Row>
        )
    }

    renderGroupGames(group) {
        const {games} = this.state;

        let groupGames = [];
        for (const item of games) {
            if (item.groupId === group.id) {
                groupGames.push(item);
            }
        }
        
        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <h5 className='season-round-subtitle'>Group {(group.index + 1).toString()}</h5>
            {groupGames.map((value, idx)=>(
                <ListGroupItem key={idx}>
                    {this.renderGameSchedule(group, value, idx)}
                </ListGroupItem>
            ))}
        </div>
        )
    }

    renderGameSchedule(group, value, idx) {
        const {locations, teams, posts, organization, division, season, cards, users, detailMode, onChanged} = this.props;
        const {subSeason, games, gameChoice, refereeGame} = this.state;
        
        if (refereeGame && refereeGame.id === value.id) {
            return (
                <GameEdit 
                    game={refereeGame}
                    organization={organization}
                    locations={locations}
                    division={division}
                    season={season}
                    teams={teams}
                    posts={posts}
                    users={users} 
                    onChanged={()=> {
                        if (onChanged != null) {
                            onChanged();
                        }
                    }}
                    saveGame={(game)=> {
                        if (this.props.saveGame) {
                            this.props.saveGame(game);
                        }
                    }} 
                    onClickClose={()=>{
                        this.setState({refereeGame: {}});
                    }} />
            )
        } else if (gameChoice && gameChoice.id === value.id) {
            if (detailMode) {
                return (
                    <GameDetails 
                        game={gameChoice}
                        organization={organization}
                        locations={locations}
                        division={division}
                        season={season}
                        cards={cards}
                        teams={teams}
                        posts={posts}
                        users={users} 
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }}
                        onClickClose={()=>{
                            this.setState({gameChoice: {}});
                        }} />
                )
            } else {
                return (
                    <GameEditBadge
                        season={season}
                        subSeason={subSeason}
                        game={value}
                        teams={teams}
                        organization={organization}
                        division={division}
                        group={group}
                        locations={locations}
                        users={users}
                        onHomeUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.homeTeam = update;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        onAwayUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.awayTeam = update;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        gameTimeUpdate={(start, end)=>{
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            if (start != null) {
                                value.start = start;
                            }
                            if (end != null) {
                                value.end = end;
                            }
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        locationUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.locationId = update;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        dayUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.matchDayIndex = update;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        refereeUpdate={(update, assists)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.refereeId = update;
                            value.referees = assists;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        removeClicked={()=> {
                            
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            games.splice(idx, 1);
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        closeClicked={()=> {
                            this.setState({gameChoice: null});
                        }}/>
                )
            }

        } else {
            return (
                <OrgGameBadge
                    game={value}
                    teams={teams}
                    organization={organization}
                    wideMode={false}
                    locations={locations}
                    detailMode={detailMode}
                    users={users}
                    onClickTeam={this.props.onClickTeam}
                    onClickDetail={e=>{
                        this.setState({gameChoice: value})
                    }} 
                    onClickReferee={e=>{
                        this.setState({refereeGame: value})
                    }} />
            )
        }
    }

    onSaveClick(subSeason) {
        const {season, onSaveDone} = this.props;
        const {games, doubleAllow, matchCount} = this.state;
        
        this.setState({progress: true, videoProgress: 0, imageProgress: 0});
        if (subSeason.id == null || subSeason.id === '') {
            subSeason.id = generateId('/season/' + season.id + '/subSeasons');
        }
        subSeason.owner = season.owner;
        subSeason.organizationId = season.organizationId;
        subSeason.divisionId = season.divisionId;
        subSeason.seasonId = season.id;
        
        subSeason.timestamp = dbTimestamp();

        if (games != null && games.length > 0) {
            subSeason.games = {};
            for (const game of games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
            }
        }
        subSeason.doubleAllow = doubleAllow;
        subSeason.matchCount = matchCount;
        
        const data = {subSeason: subSeason}
        invokeHttpsApi('clubQuery-saveSubSeason', data, (data) => {
            if (data.success) {
                alert(t('saved_successfully'));
            } else {
                alert(t('save_failed'));
            }
            if (onSaveDone) {
                onSaveDone(subSeason);
            }
            this.setState({progress: false});
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    generateSheet() {
        const {organization, division, season, locations} = this.props;
        const {subSeason} = this.state;
        
        this.setState({
            url: null,
            progress: true
        });
        const seasonChoice = season;
        const id = season.id + '-' + subSeason.id;
        const games = [];
        if (subSeason.games) {
            for (const v of Object.values(subSeason.games)) {
                games.push(v);
            }
        }
        games.sort((a,b)=> {
            return a.start - b.start;
        })

        invokeHttpsApi('gamePdf-generateGamesSheetPdf', {
            id: id,
            games: games,
            organization: organization,
            division: division,
            season: seasonChoice,
            locations: locations,
        }, (data) => {

            if (data && data.succuess !== false) {
                getStorageUrl('pdf/' + id + '-sheet.pdf', (url)=> {
                    this.setState({
                        url: url
                    });
                })
            }
            
            this.setState({
                progress: false
            });
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    render() {
        const {organization, user, onCloseClick, createMode, onClickEdit} = this.props;
        const {progress, subSeason, url} = this.state;

        if (subSeason != null) {
            return (
                <div className='shadow-tile' style={{padding: '30px 20px'}}>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {subSeason.title}
                        </h4>
                        {user && organization && user.uid === organization.owner && (
                        <div className='wrap-content-wrap-child'>
                            {progress ? (
                                <Spinner color="primary" />
                            ) : (
                                <Button className="btn-edit" onClick={e=> {
                                    this.generateSheet();
                                }}>{t('generate')}</Button>
                            )}
                            {url && (
                            <a href={url} target="_blank" rel="noopener noreferrer">Open</a>
                            )}
                        </div>
                        )}
                        {onClickEdit && (
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                onClickEdit()
                            }}><MdEdit/></Button>
                        </div>
                        )}
                    </div>
                    {this.renderGroups()}
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(subSeason);
                                }}>{t('create')}</Button>
                            </div>
                        ) : (
                            <div style={{textAlign: 'center'}}>
                            <Button className='btn-edit' onClick={e => {
                                this.onSaveClick(subSeason);
                            }}>{t('save')}</Button>
                            {onCloseClick && (
                                <Button className='btn-edit' onClick={e => {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                }}>{t('close')}</Button>
                            )}
                            </div>
                        )}
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { primarySubscription, organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, primarySubscription, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(GroupRoundGames));
export { connectedApp as GroupRoundGames };
