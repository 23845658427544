import React, { Component } from 'react';
import { Button, Input, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { cartActions } from 'redux/_actions';
import { STRIPE_PUBLISHABLE_KEY, getOrgDomain, renderHtmlString, sortByTitle } from 'utils/Utils';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { MdCheckBox } from 'react-icons/md';
import { generateId } from 'utils/API';

import { loadStripe } from '@stripe/stripe-js';

const STEP_TEAM = 0
const STEP_POLICY = 2
const STEP_CHECKOUT = 3

export default class TeamEnrollOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: STEP_TEAM,
            teamIdChoice: null,
            progress: false,
        };
    }
    
    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        
    }

    renderChildChoice() {
        const {season, user, teams} = this.props;
        const {teamIdChoice} = this.state;

        let existing = [];
        if (season && season.teamRegisters) {
            existing = Object.keys(season.teamRegisters);
        }

        let childArr = [];
        for (const item of Object.values(teams)) {
            if (user && user.uid === item.owner && !existing.includes(item.id)) {
                childArr.push(item);
            }
        }
        sortByTitle(childArr);
        
        let items = [];
        for (const item of childArr) {
            items.push(
                <div className='enroll-list-item' onClick={e => {
                    this.setState({teamIdChoice: item.id});
                }}>
                    <img className='enroll-list-icon' alt='thumb' src={item.iconUri ? item.iconUri : defaultImage} />
                    <div className='enroll-list-info'>{item.title}</div>
                    {item.id === teamIdChoice && <div className='enroll-list-sub-info'><MdCheckBox/></div>}
                </div>
            )
        }

        return (
            <div className='enroll-overlay-wrapper'>
                <h3 className='enroll-overlay-title'>
                    {t('team_enroll_header_01')}
                </h3>
                <div className='enroll-overlay-text'>
                    {t('team_enroll_text_01')}
                </div>
                <div className='enroll-overlay-contents'>
                    {items.length > 0 ? items : (
                    <div className='text-center'>No More Teams to register</div>
                    )}
                </div>
                <div className='enroll-overlay-actions'>
                    <Button className='btn-blue' onClick={e => {
                        if (teamIdChoice) {
                            this.setState({currentStep: STEP_POLICY})
                        }
                    }}>
                        {t('confirm')}
                    </Button>
                    <Button className='btn-cancel' onClick={e => {
                        if (this.props.onCloseClick) {
                            this.props.onCloseClick();
                        }
                    }}>
                        {t('close')}
                    </Button>
                </div>
            </div>
        )
    }

    renderPolicy() {
        const {organization} = this.props;
        const {progress} = this.state;
        return (
            <div className='enroll-overlay-wrapper'>
                <h3 className='enroll-overlay-title'>
                    {t('team_enroll_header_03')}
                </h3>
                <div className='enroll-overlay-contents'>
                    {renderHtmlString(organization.policy)}
                </div>
                {progress ? 
                <div className='enroll-overlay-actions'>
                    <Spinner color='primary' /> 
                </div>  :
                <div className='enroll-overlay-actions'>
                    <Button className='btn-blue' onClick={e => {
                        this.setState({currentStep: STEP_CHECKOUT})
                    }}>
                        {t('agree')}
                    </Button>
                    <Button className='btn-cancel' onClick={e => {
                        if (this.props.onCloseClick) {
                            this.props.onCloseClick();
                        }
                    }}>
                        {t('close')}
                    </Button>
                </div>
                }
            </div>
        )
    }

    renderCheckout() {
        const {organization, season, checkoutProgress, checkoutCartItems, addToCart} = this.props;
        const {note} = this.state;

        let price = 0;
        let url = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        url += getOrgDomain(organization);
        
        if (season) {
            price = season.enrollFee && season.enrollFee.teamFee ? season.enrollFee.teamFee : 0;
            url += '/championships/' + season.id
        }
        
        return (
            <div className='enroll-overlay-wrapper'>
                <h3 className='enroll-overlay-title'>
                    {t('team_enroll_header_04')}
                </h3>
                <div className='enroll-overlay-text'>
                    {t('team_enroll_text_04')}
                </div>
                <div className='enroll-overlay-contents'>
                    <div className='wrap-content-parent'>
                        <div className='reg-price-name'>
                        {t('team_fee')}:
                        </div>
                        <h4 className='reg-price-label'>
                        $ {price}
                        </h4>
                    </div>
                    <div className='form-edit-input'>
                        <Input type="textarea" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                            this.setState({note: e.target.value});
                        }} value={note ? note : ""} />
                    </div>
                    <div className='enroll-overlay-text'>
                        {t('team_enroll_text_checkout')}
                    </div>
                    
                </div>
                {checkoutProgress ? 
                <div className='enroll-overlay-actions'>
                    <Spinner color='primary' /> 
                </div> : 
                <div className='enroll-overlay-actions'>
                    <Button className='btn-green' style={{marginRight: 10}} onClick={e => {
                        const item = this.getEnrollCart();
                        const dict = {};
                        dict[item.id] = item;
                        checkoutCartItems(organization.id, dict, note, price, url, (async (session) => {
                            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
                            stripe.redirectToCheckout({ sessionId: session.id });
                        }), (() => {
                            alert("Requesting Checkout Session Failed.")
                        }))
                    }}>
                        {t('checkout_now')}
                    </Button>
                    
                    <Button className='btn-blue' onClick={e => {
                        addToCart(this.getEnrollCart());
                    }}>
                        {t('add_to_cart')}
                    </Button>
                    <Button className='btn-cancel' onClick={e => {
                        if (this.props.onCloseClick) {
                            this.props.onCloseClick();
                        }
                    }}>
                        {t('close')}
                    </Button>
                </div>
                }
            </div>
        )
    }

    getEnrollCart() {
        const {season, user} = this.props;
        const {teamIdChoice, note} = this.state;

        const result = {
            uid: user.uid,
            teamId: teamIdChoice,
            note: note,
            role: "player",
        };
        result.id = generateId("/order/newPath/dict");
        if (season) {
            result.price = season.enrollFee && season.enrollFee.teamFee ? season.enrollFee.teamFee : 0;
            result.seasonId = season.id;
        }
        
        return result;
    }

    render() {
        const {user} = this.props;
        const {currentStep} = this.state;
        
        if (!user) {
            return <div/>
        }

        return (
            <div className='enroll-overlay'>
                {currentStep === STEP_TEAM && this.renderChildChoice()}
                {currentStep === STEP_POLICY && this.renderPolicy()}
                {currentStep === STEP_CHECKOUT && this.renderCheckout()}
            </div>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { checkoutProgress, cart, session } = state.cartData;
    return { user, checkoutProgress, cart, session };
}

const actionCreators = {
    checkoutCartItems: cartActions.checkoutCartItems,
    addToCart: cartActions.addToCart,
    removeFromCart: cartActions.removeFromCart
};

const connected = connect(mapState, actionCreators)(TeamEnrollOverlay);
export { connected as TeamEnrollOverlay };
