import { t } from "i18next";
import React, { Component } from "react";
import mobileImage from 'assets/img/icons/imagem_default.jpg'
import playIcon from 'assets/img/icons/play_coral.png'

export class Tutorials extends Component {
    render() {
        return (
            <div className="main-tutorial">
                <h2>{t('tutorial_title')}</h2>
                <p>{t('tutorial_text')}</p>
                <div className="container">
                    {this.props.data ? this.props.data.map((d, i) => (
                        <div key={i} className="main-tutorial-item">
                            <img alt="mobile-phone" src={mobileImage}/>
                            <div class="main-video-link" onClick={e => {
                                if (this.props.onPlayTapped) {
                                    this.props.onPlayTapped("https://www.youtube.com/embed/oI90sfKe0Zg")
                                }
                            }} data-fancybox="">
                                <img alt="play" src={playIcon} />
                            </div>
                        </div>
                        ))
                    : "loading"}
                </div>
            </div>
        );
    }
}

export default Tutorials;
