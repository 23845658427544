import React, { Component } from "react";
import PostDetailView from 'components/detail/community/PostDetailView';
import {
    Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import { SambaHeaderPost } from "./news/SambaHeaderPost";
import { SambaLinePost } from "./news/SambaLinePost";
import { SambaPostHeader } from "./news/SambaPostHeader";


export class SambaNews extends Component {
    constructor(props) {
        super(props);
        
        let locationsChoice = {};
        if (props.locations != null) {
            for (const [key, value] of Object.entries(props.locations)) {
                if (value.organizationId === props.organization.id) {
                    locationsChoice[key] = value;
                }
            }
        }
        this.state = {
            detailChoice: {},
            locationChoice: {},
            locationsChoice: locationsChoice,
            editTeamData: false,
            editLocation: false,
            progress: false,
            detailOpen: false,
            editOpen: false,
            imageProgress: 0,
            page: 1,
            windowWidth: window.innerWidth,
        };
    }

    componentDidUpdate(prevProps) {
        const {locations, organization, seasons} = this.props;
        if (seasons !== prevProps.seasons) {
            let locationsChoice = {};
            if (locations != null) {
                for (const [key, value] of Object.entries(locations)) {
                    if (value.organizationId === organization.id) {
                        locationsChoice[key] = value;
                    }
                }
            }
            this.setState({
                locationsChoice: locationsChoice,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }

    renderHighlights() {
        const { organization, posts, users, user, loading } = this.props;
        const { searchStr } = this.state;
        
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        let actionStyle = {};
        if (actionBg && actionColor) {
            actionStyle = {
                backgroundColor: actionBg,
                color: actionColor,
            }
        } else if (actionBg) {
            actionStyle = {
                backgroundColor: actionBg,
            }
        } else if (actionColor) {
            actionStyle = {
                color: actionColor,
            }
        }

        const result = [];
        const list = [];
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        let header = null;
        for (let i = 0; i < 4; i ++) {
            if (result.length > i) {
                if (i === 0) {
                    header = result[i];
                } else {
                    toShow.push(result[i]);
                }
            }
        }
        
        for (const value of toShow) {
            list.push(
                <SambaLinePost 
                    key={value.id}
                    organization={organization}
                    post={value}
                    users={users}
                    user={user}
                    onClickDetail={() => {
                        this.setState({detailChoice: value});
                    }} />
            );
        }

        return (
            <div id="posts" className="text-center">
                <div className="samba-header-section-title" style={actionStyle}>
                    {t('news')}
                </div>
                {list.length === 0 ? (
                    <div style={{height: list.length === 0 ? 150 : 450, paddingTop: list.length === 0 ? 75 : 25, fontSize: 14, textAlign: 'center'}}>
                    {loading ? "Loading SambaNews" : t('no_items_to_show')}
                    </div>
                ) : (
                    <Row style={{margin: 0, backgroundColor: 'white'}}>
                        <Col sm={12} lg={5} style={{padding: 0}}>
                            <SambaHeaderPost 
                                key={header.id}
                                organization={organization}
                                post={header}
                                users={users}
                                user={user}
                                onClickDetail={() => {
                                    this.setState({detailChoice: header});
                                }} />
                        </Col>
                        <Col sm={12} lg={7} style={{padding: 0}}>
                        {list}
                        </Col>
                    </Row>
                )}
                <div style={{height: 25}} />
            </div>
        );
    }
    
    renderPosts() {
        const { organization, posts, users, user, loading } = this.props;
        const { searchStr} = this.state;
        
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        let actionStyle = {};
        if (actionBg && actionColor) {
            actionStyle = {
                backgroundColor: actionBg,
                color: actionColor,
            }
        } else if (actionBg) {
            actionStyle = {
                backgroundColor: actionBg,
            }
        } else if (actionColor) {
            actionStyle = {
                color: actionColor,
            }
        }

        const result = [];
        const list = [];
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id && (value.youtube || value.videoItem)) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        for (let i = 0; i < 3; i ++) {
            if (result.length > i) {
                toShow.push(result[i]);
            }
        }
        
        for (const value of toShow) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: 0}}>
                    <SambaPostHeader 
                        key={value.id}
                        organization={organization}
                        post={value}
                        users={users}
                        user={user}
                        onClickDetail={() => {
                            this.setState({detailChoice: value});
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center">
                <div className="samba-header-section-title" style={actionStyle}>
                    {t('videos')}
                </div>
                {list.length === 0 ? (
                    <div style={{height: list.length === 0 ? 150 : 450, paddingTop: list.length === 0 ? 75 : 25, fontSize: 14, textAlign: 'center'}}>
                    {loading ? t('loading') : t('no_items_to_show')}
                    </div>
                ) : (
                    <div className="row" style={{margin: 0, backgroundColor: 'white'}}>
                        {list}
                    </div>
                )}
                <div style={{height: 25}} />
            </div>
        );
    }
    
    render() {
        const {organization, users, teams, user} = this.props;
        const {detailChoice} = this.state;
        
        return (
            <div style={{margin: 0}}>
                {this.renderHighlights()}
                {this.renderPosts()}
                {detailChoice.id && (
                <div className='team-auth' onClick={e=>{
                    this.setState({detailChoice: {}})
                }}>
                    <div className='team-auth-wrapper' onClick={e=>{
                        e.stopPropagation();
                    }}>
                        <div className="post-detail-modal">
                            <PostDetailView 
                                organization={organization}
                                post={detailChoice} 
                                user={user}
                                users={users}
                                teams={teams}
                                isOpen={true}
                                onCloseClick={() => {
                                    this.setState({detailChoice: {}})
                                }}
                                onLikeUpdate={(like, uid) => {
                                    if (uid) {
                                        if (!detailChoice.likeIds) {
                                            detailChoice.likeIds = {};
                                        }
                                        if (like) {
                                            detailChoice.likeIds[uid] = uid;
                                        } else {
                                            delete detailChoice.like[uid];
                                        }
                                        this.setState({detailChoice: detailChoice})
                                    }
                                }}
                                onCommentChange={(update) => {
                                    if (update) {
                                        if (!detailChoice.comments) {
                                            detailChoice.comments = {};
                                        }
                                        detailChoice.comments[update.id] = update;

                                        this.setState({detailChoice: detailChoice})
                                    }
                                }} />
                        </div>
                    </div>
                </div>
                )}
            </div>
        );
    }
}
export default SambaNews;