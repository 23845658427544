import React, { Component } from "react";
import {
    CardImg, Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/video_default.png';

export class ClubLessonHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {clubLesson, onClickDetails, onClickEdit, onClickToggle, uid } = this.props;
        let challengeImage = clubLesson.thumbUri != null ? clubLesson.thumbUri : defaultImage;
        let allowEdit = (uid == null && clubLesson.owner === 'free') || uid === clubLesson.owner;
        let available = clubLesson.available == null || clubLesson.available;
        
        if (clubLesson != null) {
            return (
                <div key={clubLesson.id} className="info-badge-small">
                    <div className="divider"/>
                    <div className="wrap-content-parent">
                        <CardImg
                            className="wrap-content-wrap-child info-badge-icon-small"
                            src={challengeImage} />
                        <div className="wrap-content-fill-child info-badge-body">
                            <h4 className='label label-default one-line-title' style={{marginTop: 5}}>
                            {clubLesson.title}
                            </h4>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    Category: <div className="info-badge-info">{clubLesson.categoryTitle}</div>, 
                                    Tags: <div className="info-badge-info">{clubLesson.tags}</div>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                    {uid != null && (
                                    <Button className="btn-submit" onClick={e => {
                                        if (onClickToggle != null) {
                                            onClickToggle();
                                        }
                                    }}>{clubLesson.owner === uid ? (available ? "Available" : "Unavailable") : "Shared"}</Button>
                                    )}
                                    <Button className="btn-submit" onClick={e => {
                                        if (onClickDetails != null) {
                                            onClickDetails();
                                        }
                                    }}>Details</Button>
                                    {allowEdit && (
                                        <Button className="btn-edit" onClick={e => {
                                            if (onClickEdit != null) {
                                                onClickEdit();
                                            }
                                        }}>{t('edit')}</Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubLessonHeader);