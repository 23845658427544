import React, { Component } from "react";
import {
    Card, CardImg, Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';
import { 
    GiSoccerField,
    GiWhistle,
} from 'react-icons/gi';
import { 
    IoIosListBox
} from 'react-icons/io';
import { toSimpleTime } from 'utils/Utils';
import { getOrgDomain } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';

export class GameBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    render() {
        const { organization, game, teams, locations, users, onClickDetail, wideMode } = this.props;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toSimpleTime(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        
        if (!wideMode) {
            return (
                <Card className="game-badge" style={{fontSize: 10}}>
                <Row>
                    <Col lg={5} xs={4} sm={4} className="text-center" onClick={e=>{
                        if (homeTeam.id) {
                            window.location = `${getOrgDomain(organization)}/teams/${homeTeam.id}`;
                        }
                    }}>
                        <CardImg
                            className="profile-thumb game-team-icon"
                            src={homeThumb} />
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                        {homeTeam.title}
                        </div>
                    </Col>
                    <Col lg={2} xs={4} sm={4} className="text-center game-badge-time" style={{padding: 0}}>
                    <b>{timeStr}</b>
                    {game.homeScore != null && game.awayScore != null && (
                        <p><b>{game.homeScore !== -1 ? game.homeScore : "_"} - {game.awayScore !== -1 ? game.awayScore : "_"}</b></p>
                    )}
                    </Col>
                    <Col lg={5} xs={4} sm={4} className="text-center"onClick={e=>{
                        if (awayTeam.id) {
                            window.location = `${getOrgDomain(organization)}/teams/${awayTeam.id}`;
                        }
                    }}>
                        <CardImg
                            className="profile-thumb game-team-icon"
                            src={awayThumb} />
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                        {awayTeam.title}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5} xs={4} sm={4} className="text-center">
                        <div>
                            <div className="vertical-center-spacing">
                                <GiSoccerField/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {location.title}
                            </div>
                        </div>
                    </Col>
                    <Col lg={2} xs={4} sm={4} className="text-center" style={{padding: 0, color: 'black'}}>
                    <div className='game-badge-calendar' style={{margin: '0px auto'}}>
                        <div className='game-badge-day'>
                            {day}
                        </div>
                        <div className='game-badge-month'>
                            {month}
                        </div>
                    </div>
                    </Col>
                    <Col lg={5} xs={4} sm={4} className="text-center">
                        <div>
                            <div className="vertical-center-spacing">
                                <GiWhistle/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {referee.uid != null ? referee.username : t('no_referee')}
                            </div>
                        </div>
                    </Col>
                </Row>
                {onClickDetail != null && (
                    <Row>
                        <Button style={{margin: '0px auto'}} className="btn-green" onClick={e => {
                            if (onClickDetail != null) {
                                onClickDetail();
                            }
                        }}><IoIosListBox/> {t('detail')}</Button>
                    </Row>
                )}
                </Card>
            );
        } else {
            return (
                <Card className="game-badge">
                    <Row>
                        <Col lg={2} xs={4} sm={4} className="text-center">
                            <div className='game-badge-calendar'>
                                <div className='game-badge-day'>
                                    {day}
                                </div>
                                <div className='game-badge-month'>
                                    {month}
                                </div>
                            </div>
                        </Col>
                        <Col nm={8} xs={8} sm={8} className="text-center">
                            <Row>
                                <Col nm={5} xs={4} sm={5} className="text-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        {homeTeam.title}
                                        </div>
                                        <div className="wrap-content-wrap-child">
                                            <CardImg
                                                className="profile-thumb game-team-icon"
                                                src={homeThumb} />
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={2} xs={4} sm={2} className="text-center game-badge-time">
                                <b>{timeStr}</b>
                                {game.homeScore != null && game.awayScore != null && (
                                    <p><b>{game.homeScore} - {game.awayScore}</b></p>
                                )}
                                </Col>
                                <Col nm={5} xs={4} sm={5} className="text-center">
                                    <div className='wrap-content-parent'>
                                        <div className="wrap-content-wrap-child">
                                            <CardImg
                                                className="profile-thumb game-team-icon"
                                                src={awayThumb} />
                                        </div>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        {awayTeam.title}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col nm={6} xs={6} sm={6} className="text-center">
                                    <div>
                                        <div className="vertical-center-spacing">
                                            <GiSoccerField/>
                                        </div>
                                        <div className=' vertical-center-spacing'>
                                        {location.title}
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={6} xs={6} sm={6} className="text-center">
                                    <div>
                                        <div className="vertical-center-spacing">
                                            <GiWhistle/>
                                        </div>
                                        <div className=' vertical-center-spacing'>
                                        {referee.uid != null ? referee.username : t('no_referee')}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} xs={4} sm={4} className="game-badge-action">
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="wrap-content-wrap-child vertical-center-spacing">
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {onClickDetail != null && (
                        <Row>
                            <Button style={{margin: '0px auto'}} className="btn-green" onClick={e => {
                                if (onClickDetail != null) {
                                    onClickDetail();
                                }
                            }}><IoIosListBox/> {t('detail')}</Button>
                        </Row>
                    )}
                </Card>
            );
        }
    }
}

export default GameBadge;