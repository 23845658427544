import React, { Component } from "react";
import { Card, CardImg } from 'reactstrap';

export class InfoView extends Component {
    render() {
        const {title, icon, number, secNumber} = this.props;
        
        return (
            <div className='shadow-tile' style={{display: 'inline-block'}}>
                <Card>
                    <div style={{ width: 140, padding: '5px 20px' }}>
                        <div className='wrap-content-parent'>
                            <CardImg 
                                className='wrap-content-wrap-child'
                                src={icon}
                                style={{width: 25, height: 25, marginRight: 15}} />
                            <div 
                                className='wrap-content-fill-child vertical-center-spacing' 
                                style={{fontSize: 20, fontWeight: 1000, color: 'black'}}>
                                {number}
                                {secNumber ? (
                                    "/" + secNumber.toString()
                                ) : ("")}
                            </div>
                        </div>
                            <div 
                                style={{width: '100%', textAlign: 'center', fontSize: 8}}>
                                {title}
                            </div>
                    </div>
                </Card>
            </div>
        );
    }
}

export default InfoView;