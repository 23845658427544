import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import { CardImg } from 'reactstrap';

import { BlueNavHeader } from 'components/landing/organization/BlueNavHeader';
import { NavHeader } from 'components/landing/organization/NavHeader';
import { aboutUs } from 'utils/landing';
import { getOrgData } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import LoadingView from 'components/detail/loading/LoadingView';
import { withRouter } from 'utils/Routher';

class OrgAbout extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            organization: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const seasonId = this.props.router.params.seasonId;
            this.setState({id: id, seasonId: seasonId});
        }
        if (window.location.search && window.location.search.includes('payment_result=true')) {
            alert("Payment sent to owner sccuessfully. You will get into season once he accepts it.");
        } else if (window.location.search && window.location.search.includes('payment_result=false')) {
            alert("Payment not sent to owner sccuessfully. You should retry later.");
        }
        this.loadContents();
    }

    loadContents() {
        const queryData = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizerEventsQuery', queryData, ((data)=>{

            const organization = {};
            const organizations = {};
            const users = {};
            const teams = {};
            const locations = {};
            const schedules = {};
            const orgSchedules = {};
            const covidForm = {};
            const playerForms = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (key === queryData.organizationId || value.customDomain === queryData.customDomain) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.schedules)) {
                    schedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.orgSchedules)) {
                    orgSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.covidForm)) {
                    covidForm[key] = value;
                }
                for (const [key, value] of Object.entries(data.playerForms)) {
                    playerForms[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                teams: teams,
                locations: locations,
                users: users,
                schedules: schedules,
                orgSchedules: orgSchedules,
                covidForm: covidForm,
                playerForms: playerForms,
                loading: false,
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderContents() {
        const { organization } = this.state;
        const landingData = (organization && organization.landingData) ? organization.landingData : {};
        const aboutData = landingData.aboutUs ? landingData.aboutUs : aboutUs;

        const itemsArr = Object.values(aboutData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];

        const bgClass = organization.landingData && organization.landingData.theme === 'blue' ? 'org-landing-contents-bg-blue' : 'org-landing-contents-bg';
        for (const item of itemsArr) {
            items.push((
                <div>
                    <h4 className="org-landing-contents-subtitle">
                    {item.subtitle}
                    </h4>
                    {item.bg != null && item.bg !== '' && (
                    <div className="post-media-wrapper">
                    <div className="post-media-child">
                        <CardImg 
                            className='task-image'
                            src={item.bg} />
                    </div>
                    </div>
                    )}
                    <div className="org-landing-contents-text">
                    {item.text}
                    </div>
                </div>
            ))
        }

        return (
            <div className='container'>
                <div className={bgClass}>
                    <h2 className="org-landing-contents-title">
                    {aboutData.title}
                    </h2>
                    {items}
                </div>
            </div>
        )
    }

    render() {
        const {organization, loading} = this.state;
        
        const theme = organization.landingData && organization.landingData.theme ? organization.landingData.theme : null;
        
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        
        return (
            <div>
                {(!theme || theme === 'main') && (
                <NavHeader 
                    organization={organization} 
                    data={{}} />
                )}
                {theme === 'blue' && (
                <BlueNavHeader 
                    organization={organization}
                    loading={loading} />
                )}
                <div style={{paddingTop: 20, backgroundColor: 'white'}}>
                    {this.renderContents()}
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgAbout));
export { connectedPage as OrgAbout };
