import React, { Component } from "react";
import { Button } from 'reactstrap';
import { t } from 'i18next';

export class SubNavigation extends Component {
    render() {
        const {title, icon, onClickAdd} = this.props;

        return (
            <div className='items-navigation' style={{marginLeft: 5, marginRight: 5}}>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <div className='detail-nav-btn'>
                        {icon && (
                            <div className='items-navigation-count'>{icon}</div>
                        )}
                        <h3 className='items-navigation-title'>
                        {title}
                        </h3>
                    </div>
                </div>
                
                {onClickAdd && (
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickAdd != null) {
                        onClickAdd();
                    }
                }}>{t('create')}</Button>
                )}
            </div>
        );
    }
}

export default SubNavigation;