import React, { Component } from "react";
import {
    Row,
    Input, 
    CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import userImage from 'assets/img/icons/player_photo_default.png';
import InviteHeader from 'components/detail/invite/InviteHeader';
import { GoogleMap, LoadScript, Marker, } from '@react-google-maps/api';
import defaultImage from 'assets/img/icons/video_default.png';
import { 
    validateEmail, validateEmailStr
} from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";

const containerStyle = {
    width: '100%',
    height: '400px'
};
  
export class LocationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            windowWidth: window.innerWidth,
        };
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    renderInvite() {
        const { invites, location, users } = this.props
        const { inviteSearch } = this.state

        const invited = [];
        const pending = [];
        const players = [];
        const clubPending = [];
        const add = [];

        for (const val of Object.values(invites)) {
            if (val.locationId !== location.id) {
                continue
            }
            if (!inviteSearch || (val.email && val.email.toLowerCase().includes(inviteSearch.toLowerCase()))) {
                if (val.status === 'invited') {
                    invited.push(val);
                } else if (val.status === 'registered') {
                    pending.push(val);
                }
            }
        }
        if (location.managerIds) {
            for (const val of Object.values(location.managerIds)) {
                const user = users[val] ? users[val] : {};
                if (!inviteSearch || (user.email && user.email.toLowerCase().includes(inviteSearch.toLowerCase()))) {
                    players.push(user);
                }
            }
        }
        if (location.managerInvited) {
            for (const val of Object.values(location.managerInvited)) {
                const user = users[val] ? users[val] : {};
                if (!inviteSearch || (user.email && user.email.toLowerCase().includes(inviteSearch.toLowerCase()))) {
                    clubPending.push(user);
                }
            }
        }
        for (const user of Object.values(users)) {
            if (inviteSearch != null && inviteSearch !== "" && user.email != null && user.email.toLowerCase().includes(inviteSearch.toLowerCase()) && user.parentId == null) {
                add.push(user);
            }
        }
        players.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
    
        clubPending.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        add.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

    
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title' style={{marginTop: 10, marginBottom: 10}}>
                Invites: {invited.length + pending.length}, Managers: {players.length}
                </h3>
                <div className='wrap-content-parent'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="inviteSearch" id="inviteSearch" placeholder='Search by email ...' onChange={e => {
                            this.setState({inviteSearch: e.target.value});
                        }} value={inviteSearch} />
                    </div>                        
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.sendInvite(validateEmailStr(inviteSearch));
                    }}>{t('invite')}</Button>
                </div>
                <div className='shadow-tile' style={{margin: '10px 0px'}}>
                    <Row style={{margin: 0}}>
                        <h3 className='row-table-title' style={{margin: 0, padding: '10px 15px'}}>
                            Manager Invites
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <div>
                        {invited.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title="No user for this email yet"
                                defaultIcon={userImage}
                                email={val.email}
                                state="invited" />
                        ))}
                    </div>
                    <div>
                        {pending.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title="No user for this email yet"
                                defaultIcon={userImage}
                                email={val.email}
                                state="registered" />
                        ))}
                    </div>
                    <div>
                        {clubPending.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title={val.username}
                                defaultIcon={userImage}
                                thumbUri={val.photoUri}
                                email={val.email}
                                state="clubPending" />
                        ))}
                    </div>
                    <div>
                        {players.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title={val.username}
                                defaultIcon={userImage}
                                thumbUri={val.photoUri}
                                email={val.email}
                                memberLabel="Manager"
                                state="member" />
                        ))}
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                </div>
            </div>
        );
    }

    checkEmail(email) {
        const {invites, location, users} = this.props

        if (!validateEmail(email)) {
            return -1;
        }

        for (const val of Object.values(invites)) {
            if (val.locationId !== location.id) {
                continue
            }
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                if (val.status === 'invited') {
                    return 0;
                } else if (val.status === 'registered') {
                    return 1;
                }
            }
        }
        if (location.managerIds) {
            for (const val of Object.values(location.managerIds)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 2;
                }
            }
        }
        if (location.refereePendingIds) {
            for (const val of Object.values(location.refereePendingIds)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 5;
                }
            }
        }
        if (location.managerInvited) {
            for (const val of Object.values(location.managerInvited)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 6;
                }
            }
        }
        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return 3;
            }
        }
        return 4;
    }

    getUid(email) {
        const {users} = this.props

        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return val.uid;
            }
        }
        return null;
    }

    sendInvite(email) {
        const { onChanged, organization, location } = this.props
        var status = this.checkEmail(email)

        if (status === -1) {
            alert("This email address is invalid.")
            return;
        }
        
        if (status === 2) {
            alert("This email is already registered.")
            return;
        }
        
        if (status === 5) {
            alert("This email is already pending.")
            return;
        }
        
        if (status === 6) {
            alert("This email is already invited on offline mode.")
            return;
        }
        
        if (status === 1) {
            alert("This email is already invited for creating location.")
            return;
        }
        
        const data = {email: email, organizationId: organization.id, locationId: location.id, locationTitle: location.title};
        if (status === 3) {
            data.uid = this.getUid(email);
        }

        invokeHttpsApi('invite-inviteLocationManager', data, (data) => {
            if (onChanged) {
                onChanged()
            }
            if (status === 3) {
                alert("An invitation email to resiger and be a locations manager has been sent. It will be sent every 2 days");
            } else {
                alert("An invitation email to be a locations manager has been sent. It will be sent every 2 days");
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    render() {
        const {location, onCloseClick} = this.props;
        
        let challengeImage = location.thumbUri != null ? location.thumbUri : defaultImage;
        const startPoint = {lat: Number(location.lat), lng: Number(location.lng)};
        
        if (location != null) {
            return (
                <div>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60, marginRight: 20 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {location.title}
                            </h4>
                            <div>{location.description}</div>
                        </div>
                    </div>
                    <div style={{margin: '10px 0px 30px'}}>
                        <h4 className='label label-default' style={{marginTop: 20}}>
                        Place Info
                        </h4>
                        {location.formatted_address != null && (
                            <div className='wrap-content-parent'>
                                <CardImg
                                    className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                                    src={location.placeIcon}
                                    style={{ width: 20, height: 20, marginRight: 15 }}
                                    />
                                <div className="wrap-content-fill-child vertical-center-spacing">
                                    Address: {location.formatted_address}
                                </div>
                            </div>
                        )}
                        {location.lat != null && location.lng != null && (
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={startPoint}
                                    zoom={15}
                                    >
                                    <Marker position={startPoint} />
                                </GoogleMap>
                            </LoadScript>
                        )}
                    </div>
                    <div style={{margin: '10px 0px'}}>
                        <h4 style={{fontSize: 20}}>Managers: {location.managerIds != null ? Object.values(location.managerIds).length : 0}</h4>
                        <div className='divider' style={{margin: '10px 0px'}}/>
                        {this.renderInvite()}
                    </div>
                    <Button className='btn-cancel' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>

                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(LocationDetail);