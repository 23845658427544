import React, { Component } from "react";
import { getFileExtension } from 'utils/FirebaseUtils';
import ReactPlayer from 'react-player';
import {
    FormGroup, FormText, Input, Label, Progress
} from 'reactstrap';

export class AttachVideoEdit extends Component {
    render() {
        const {videoUrl, videoClass, label, allowProgress, progress, onChange} = this.props;
        
        return (
            <FormGroup>
                {videoUrl != null && videoUrl !== '' && (
                <div>
                    <ReactPlayer 
                        className={videoClass}
                        playing 
                        stopOnUnmount 
                        controls 
                        url={videoUrl}
                        width={'70%'}
                        height={'auto'} />

                </div>
                )}
                <Label for="imageFile">{label != null ? label : 'Content Video'}</Label>
                <Input type="file" name="file" id="imageFile" onChange={e => {                                
                    if (onChange != null && e.target.files != null && e.target.files.length > 0) {
                        let file = e.target.files[0];
                        let extension = getFileExtension(file.name);
                        if (extension === 'mp4' || extension === 'mov') {
                            onChange(file);
                        } else {
                            alert("Only mp4 or mov files are allowed here. This file will be ignored.");
                        }
                    }
                }} />
                <FormText color="muted">
                You can {videoUrl != null && videoUrl !== '' ? 'update the current' : 'upload a new'} video 
                </FormText>
                {allowProgress && (
                    <Progress className='progress-style-file' color="warning" value={progress} />
                )}
            </FormGroup>
        );
    }
}

export default AttachVideoEdit;