import { InviteForm } from 'components/auth/InviteForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { withRouter } from 'utils/Routher';

class InvitePlayerPage extends React.Component {
    handleLogoClick = () => {
      this.props.router.navigate('/');
    };  

    render() {        
        const email = this.props.router.params.email;
        return (
            <Row
                style={{
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 0
                }}>
                <Col md={6} lg={4}>
                    <Card body>
                        <InviteForm
                            email={email}
                            type='player'
                            onLogoClick={this.handleLogoClick}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default withRouter(InvitePlayerPage);
