import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import ManagersView from 'components/members/ManagersView';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

import AttachImageEdit from 'components/edit/AttachImageEdit';

import { dataActions } from 'redux/_actions';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";

class LocationEdit extends Component {
    constructor(props) {
        super(props);
        const location = {};
        if (props.location != null) {
            for (const [key, value] of Object.entries(props.location)) {
                location[key] = value;
            }
        }
        
        this.state = {
            location: location,
            progress: false,
            newImage: {},
            placeChoice: {},
            imageProgress: 0
        }
        this.autocomplete = null

        this.onLoad = this.onLoad.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, location} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.location !== location)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(location)) {
                challengeVal[key] = value;
            }
            this.setState({
                location: challengeVal
            })
        }
    }

    onSaveClick(location, newImage) {
        const {organization, onSaveDone} = this.props;
        const {placeChoice} = this.state;
        if (location.title == null || location.title === '') {
            alert("You must input title");
        } else if (location.description == null || location.description === '') {
            alert("You must input description");
        } else if (location.place_id == null && (placeChoice == null || placeChoice.place_id == null)) {
            alert("You must input correct place");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (location.id == null || location.id === '') {
                location.id = generateId("/location");
            }
            location.owner = organization.owner;
            location.organizationId = organization.id;
            location.timestamp = dbTimestamp();

            if (placeChoice.place_id != null) {
                location.place_id = placeChoice.place_id;
                if (placeChoice.formatted_address != null) {
                    location.formatted_address = placeChoice.formatted_address;
                }
                if (placeChoice.formatted_phone_number != null) {
                    location.formatted_phone_number = placeChoice.formatted_phone_number;
                }
                if (placeChoice.icon != null) {
                    location.placeIcon = placeChoice.icon;
                }
                if (placeChoice.name != null) {
                    location.placeName = placeChoice.name;
                }
                location.lat = placeChoice.geometry.location.lat();
                location.lng = placeChoice.geometry.location.lng();
            }

            this.saveChangeWithImage(location, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone(location);
                }
                this.setState({
                    progress: false
                });
            })
        }
    }

    saveChangeWithImage(location, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(location.id, 'location', newImage, (url) => {
                location.thumbUri = url;
                objRef.saveChange(location, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(location, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(location, success, failure) {
        invokeHttpsApi('clubQuery-saveLocationChange', {
            location: location,
        }, (data) => {
            if (data && data.success) {
                success(location);
            } else {
                failure(null);
            }
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    onLoad (autocomplete) {
        this.autocomplete = autocomplete
    }

    onPlaceChanged () {
        if (this.autocomplete !== null) {
            const place = this.autocomplete.getPlace();
            if (place != null) {
                this.setState({placeChoice: place});
            }
        } else {
            this.setState({placeChoice: {}});
            console.log('Autocomplete is not loaded yet!')
        }
    }

    render() {
        const {onCloseClick, createMode} = this.props;
        const {location, newImage, newVideo, progress, imageProgress} = this.state;
        const locationTxt = location.formatted_address != null ? location.formatted_address : "Input place name for location";
        
        if (location != null) {
            return (
                <Form style={createMode ? {width: '100%'} : {}}>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            location.title = e.target.value;
                            this.setState({location: location});
                        }} value={location.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={location.description} onChange={e => {
                            location.description = e.target.value;
                            this.setState({location: location});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="title">{t('address')}</Label>
                        <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            libraries={["places"]} >
                            <Autocomplete
                                onLoad={this.onLoad}
                                onPlaceChanged={this.onPlaceChanged} >
                                <input
                                type="text"
                                placeholder={locationTxt}
                                className="location-input"
                                />
                            </Autocomplete>
                        </LoadScript>
                    </FormGroup>
                    <div style={{margin: '10px 0px'}}>
                        <h4 style={{fontSize: 20}}>{t('managers')}: {location.managerIds != null ? Object.values(location.managerIds).length : 0}</h4>
                        <div className='divider' style={{margin: '10px 0px'}}/>
                        <ManagersView
                            available={{}}
                            managerIds={location.managerIds}
                            noMembersTxt="No Manager Assigned"
                            onSaveClick={(managerIds)=> {
                                location.managerIds = {};
                                if (managerIds != null) {
                                    for (const[key, value] of Object.entries(managerIds)) {
                                        location.managerIds[key] = value;
                                    }
                                }
                                this.setState({location: location});
                            }} />
                    </div>
                    
                    <AttachImageEdit
                        imageUrl={location.thumbUri}
                        imageClass='edit-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(location, newImage, newVideo);
                                }}>{t('create')}</Button>
                                <Button className='btn-cancel' onClick={e => {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                }}>{t('close')}</Button>
                            </div>
                        ) : (
                            <div>
                            <Button className='btn-edit' onClick={e => {
                                this.onSaveClick(location, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                            </div>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(LocationEdit));
export { connectedApp as LocationEdit };
