import React, { Component } from 'react';
import {TeamNav} from 'components/landing/team/TeamNav';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {
    Modal, ModalBody, Button, Row, Col, Input, CardImg
} from 'reactstrap';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import { WalletPanel } from 'components/wallet/WalletPanel';
import { getTeamDomain, toDateTimeString } from 'utils/Utils';

import LoadingScreen from 'components/detail/loading/LoadingScreen';

import { 
    FcManager,
} from 'react-icons/fc';

import teamIcon from 'assets/img/icons/club_icon_default.png';
import playerIcon from 'assets/img/icons/player_photo_default.png';

import {ClubMembershipsView} from 'components/membership/ClubMembershipsView';
import ClubEditView from 'components/edit/club/ClubEditView';

import { getOrgId, replaceUrl } from 'utils/Utils';
import ProfileEdit from 'components/edit/ProfileEdit';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';
import { invokeHttpsApi } from 'utils/API';
import CustomTabView from 'components/template/CustomTabView';
import { withRouter } from 'utils/Routher';

class MyAccountPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            seasonChoice: {},
            clubs: {},
            users: {},
            invites: {},
            managerInvites: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            wallets: {},
            walletPayments: {},
            mainTab: 0,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            familyOpened: false,
            detailMode: 'list',
            balanceInput: 0,
            balanceType: 'direct'
        }
    }
    
    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        const params = {};
        const organizationId = getOrgId();
        if (organizationId) {
            params.organizationId = organizationId;
        }
        this.setState({
            loading: true,
        })
        invokeHttpsApi('clubQuery-teamLandingCoachQuery', params, ((data)=>{
            
            const organizations = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};
            const clubSchedules = {};
            const wallets = {};
            const walletPayments = {};

            if (data && data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallets)) {
                    wallets[key] = value;
                }
                for (const [key, value] of Object.entries(data.walletPayments)) {
                    walletPayments[key] = value;
                }
            }

            this.setState({
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
                clubSchedules: clubSchedules,
                wallets: wallets,
                walletPayments: walletPayments,
            })
        }), ((error)=> {
            alert(t('request_failed') + '\n' + error);
            this.setState({loading: false});
        }));
    }

    getMyTeams() {
        const {user} = this.props;
        const {teams} = this.state;
        const uid = user ? user.uid : null;

        const mine = {};
        for (const [key, value] of Object.entries(teams)) {
            if (value.owner === uid || (value.managers && Object.values(value.managers).includes(uid))) {
                mine[key] = value;
            }
        }
        return mine;
    }

    getUserIds() {
        const {user} = this.props;
        const {users} = this.state;
        let uids = [];
        if (user) {
            uids.push(user.uid)
            for (const val of Object.values(users)) {
                if (val.parentId === user.uid && !uids.includes(val.uid)) {
                    uids.push(val.uid)
                }
            }
        }
        return uids;
    }

    checkUids(team) {
        const uids = this.getUserIds();
        for (const uid of uids) {
            if (team.playerIds && Object.values(team.playerIds).includes(uid)) {
                return true;
            }
        }
        return false;
    }

    getMemberTeams() {
        const {user, child} = this.props;
        const {teams} = this.state;
        const choice = child ? child : user;
        const uid = choice ? choice.uid : null;

        const mine = {};
        for (const [key, value] of Object.entries(teams)) {
            if (value.playerIds && Object.values(value.playerIds).includes(uid)) {
                mine[key] = value;
            }
        }
        return mine;
    }

    toggleTeamPremium(team, update) {
        this.setState({toggleProgress: true});
        invokeHttpsApi('membership-toggleTeamPremium', {
            teamId: team.id,
            update: update,
        }, (data) => {
            if (data && data.success && data.team && data.team.id) {
                if (data.team.available && (data.teamCountLimit === -1 || data.teamCountLimit <= data.curPremiumCount)) {
                    alert(t('submited_successfully'));
                } else if (!update) {
                    alert(t('submited_successfully'));
                } else {
                    alert(t('submit_failed'));
                }
                this.setState({team: data.team, toggleProgress: false});
            } else {
                alert(t('submit_failed'));
                this.setState({toggleProgress: false});
            }
        }, (error) => {
            console.log('save error', error);
            alert(t('submit_failed'));
            this.setState({toggleProgress: false});
        });
    }

    submitDeleteUser(team, update) {
        this.setState({toggleProgress: true});
        const params = {};
        invokeHttpsApi('userQuery-submitDeleteAccount', params, ((data)=>{
            if (data && data.success) {
                window.location = "/";
            }
            this.setState({toggling: false});
        }), ((error)=>{
            console.log('saveContents error', error);
            this.setState({toggling: false});
        }))
    }

    renderTeamList() {
        const {user, child} = this.props;
        const {toggleProgress, users} = this.state;
        const teams = this.getMyTeams();
        const memberTeams = this.getMemberTeams();

        const owned = [];
        const managing = [];
        const members = [];
        const choice = child ? child : user;
        const uid = choice ? choice.uid : null;

        for (const[, item] of Object.entries(teams)) {
            const thumbImage = item.iconUri != null ? item.iconUri : teamIcon;
            if (item.owner === user.uid) {
                owned.push(
                    <TableRowSmall 
                        key={item.id}
                        title={item.title}
                        icon={thumbImage}
                        progress={toggleProgress}
                        detailLabel={t(item.available ? "premium" : "freemium")}
                        onClickDetails={e=> {
                            this.toggleTeamPremium(item, !item.available)
                        }}
                        editLabel={t('details')}
                        onClickEdit={() => {
                            window.location = getTeamDomain(item) + "/coach"
                        }} />
                );
            } else {
                
                managing.push(
                    <TableRowSmall 
                        key={item.id}
                        title={item.title}
                        icon={thumbImage}
                        progress={toggleProgress}
                        detailLabel={t(item.available ? "premium" : "freemium")}
                        onClickDetails={e=> {
                            this.toggleTeamPremium(item, !item.available)
                        }}
                        editLabel={t('details')}
                        onClickEdit={() => {
                            window.location = getTeamDomain(item) + "/coach"
                        }} />
                );
            }
        }

        for (const[, item] of Object.entries(memberTeams)) {
            const thumbImage = item.iconUri != null ? item.iconUri : teamIcon;
            members.push(
                <TableRowSmall 
                    key={item.id}
                    title={item.title}
                    icon={thumbImage}
                    editLabel={t('details')}
                    onClickEdit={() => {
                        window.location = getTeamDomain(item) + "/account"
                    }} />
            );
        }

        return (
            <Row style={{margin: 0}}>
                <Col sm={6} xs={12}>
                    <div className='shadow-tile-normal'>
                        <div className='wrap-content-parent' style={{background: 'white', padding: '12px 10px'}}>
                            <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                                <div className='items-navigation-count'><FcManager/></div>
                                <h3 className='items-navigation-title'>
                                {t('teams')}
                                </h3>
                                <div className='items-navigation-count'>| {members.length} {t('member_teams')}</div>
                                <CardImg
                                    style={{width: 24, height: 24, marginLeft: 10, marginRight: 10}}
                                    src={users[uid] && users[uid].photoUri ? users[uid].photoUri : playerIcon} />
                                <Input className='wrap-content-wrap-child' style={{width: 'inherit', display: 'inline'}} type="select" onChange={e=> {
                                    const update = e.target.value;
                                    if (user.uid === update) {
                                        this.props.setChild(null);
                                    } else if (users[update]) {
                                        this.props.setChild(users[update]);
                                    }
                                }} value={uid}>
                                    {this.getUserIds().map((userId, idx)=>(
                                        <option key={idx} value={userId}>
                                            {users[userId] ? users[userId].username : ""}
                                        </option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className='divider' />
                        <div className='divider' />
                        
                        {members.length > 0 ? (
                        <div>
                            {members}
                        </div>
                        ) : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                        )}
                    </div>
                </Col>
                <Col sm={6} xs={12}>
                    <div className='shadow-tile-normal'>
                        <div className='wrap-content-parent' style={{background: 'white', padding: '12px 10px'}}>
                            <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                                <div className='items-navigation-count'><FcManager/></div>
                                <h3 className='items-navigation-title'>
                                {t('teams')}
                                </h3>
                                <div className='items-navigation-count'>| {owned.length} {t('owned_teams')}</div>
                            </div>
                            <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                this.setState({editTeam: {}})
                            }}>{ t('create')}</Button>
                        </div>
                        <div className='divider' />
                        <div className='divider' />
                        
                        {owned.length > 0 ? (
                        <div>
                            {owned}
                        </div>
                        ) : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                        )}
                    </div>
                    
                    <div className='shadow-tile-normal'>
                        <div className='wrap-content-parent' style={{background: 'white', padding: '12px 10px'}}>
                            <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                                <div className='items-navigation-count'><FcManager/></div>
                                <h3 className='items-navigation-title'>
                                {t('teams')}
                                </h3>
                                <div className='items-navigation-count'>| {managing.length} {t('managing_teams')}</div>
                            </div>
                        </div>
                        <div className='divider' />
                        <div className='divider' />
                        
                        {managing.length > 0 ? (
                        <div>
                            {managing}
                        </div>
                        ) : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }

    renderProfile() {
        const {user, editUser} = this.props;
        
        if (!editUser) {
            return <div />;
        }
        return (
            <Modal isOpen={true}>
                <ModalBody>
                    <ProfileEdit 
                        user={user}
                        profile={user}
                        onSaveSuccess={() => {
                            this.setState({editUser: false});
                            this.props.checkUser();
                        }}
                        onCloseClick={() => {
                            this.setState({editUser: false});
                        }} />
                </ModalBody>
            </Modal>
        )
    }

    deletePendingPayment(item) {
        
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-deleteWalletPending', {
            walletPayment: item
        }, async (data) => {
            if (data.success) {
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
                this.loadTransactions();
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    renderWalletItem(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.price) / 100);
        const subtitle = "+";

        let user = item.uid && users[item.uid] ? item.uid && users[item.uid] : {};
        let userIcon = user.photoUri ? user.photoUri : playerIcon;

        return (
            <Row style={{margin: 0, height: 48}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={2} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {subtitle} {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <img src={userIcon} className="wrap-content-wrap-child" style={{width: 24, height: 24, margin: 'auto 0px', borderRadius: 12, backgroundColor: 'white'}} alt="icon" />
                        
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col sm={4} className="vertical-center" style={{padding: 0}}>
                    {item.success ? (
                        <div className='wrap-content-parent'>
                            <h4 className='wallet-table-info wrap-content-fill-child'>
                            {item.type === 'direct' ? t('paid_online') : t('paid_in_person')}
                            </h4>
                        </div>
                    ) : (
                        <div className='wrap-content-parent'>
                            <h4 className='wallet-table-info wrap-content-fill-child'>
                            {t('pending_payment')}
                            </h4>
                            <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                this.deletePendingPayment(item, false);
                            }}>{t('delete')}</Button>
                        </div>
                    )}
                </Col>
            </Row>
        );
    }

    renderWalletDetail(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.balance) / 100);

        let user = item.uid && users[item.uid] ? item.uid && users[item.uid] : {};
        let userIcon = user.photoUri ? user.photoUri : playerIcon;

        return (
            <Row style={{margin: 0}}>
                <Col sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <img src={userIcon} className="wrap-content-wrap-child" style={{width: 24, height: 24, margin: 'auto 0px', borderRadius: 12, backgroundColor: 'white'}} alt="icon" />
                        
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col sm={2} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {price}
                    </h4>
                </Col>
                <Col sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        
                        </h4>
                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                            this.setState({walletChoice: item});            
                        }}>{t('detail')}</Button>
                    </div>
                </Col>
            </Row>
        );
    }

    renderWallets() {
        const {organizations, users, walletChoice, wallets, walletPayments} = this.state;

        const walletArr = Object.values(wallets);
        walletArr.sort((o1, o2)=> {
            const a = users[o1.uid] ? users[o1.uid] : {};
            const b = users[o2.uid] ? users[o2.uid] : {};
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        const paymentsArr = Object.values(walletPayments);

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        const pending = [];
        const waiting = [];
        const success = [];
        const walletItems = [];

        for (const item of paymentsArr) {
            if (item.success) {
                success.push(
                    <div>{this.renderWalletItem(item)}</div>
                );
            } else if (item.type !== 'direct')  {
                pending.push(
                    <div>{this.renderWalletItem(item)}</div>
                );
            }
        }

        for (const item of walletArr) {
            walletItems.push(
                <div>{this.renderWalletDetail(item)}</div>
            );
            walletItems.push(<div className='divider' />);
        }

        const items = [];
        for (const item of pending) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of waiting) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of success) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <Row style={{margin: 0}}>
                <Col sm={6} xs={12}>
                    <div className='wallet-info-tile'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                            
                            <h3 className='items-navigation-title'>
                            Wallets
                            </h3>
                        </div>
                        <Row style={{margin: 0}}>
                            <Col sm={5} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                {t('user')}
                                </h4>
                            </Col>
                            <Col lg={2} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Balance
                                </h4>
                            </Col>
                            <Col sm={5} className="vertical-center" style={{padding: 0}}>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {walletChoice ? (
                            <WalletPanel
                                organization={organizations[walletChoice.organizationId]}
                                wallet={walletChoice}
                                walletPayments={walletPayments}
                                users={users}
                                onChange={(wallet, payment) => {
                                    wallets[walletChoice.id] = wallet;
                                    if (payment) {
                                        walletPayments[payment.id] = payment;
                                    }
                                    this.setState({wallets: wallets, walletPayments: walletPayments, walletChoice: wallet});            
                                }}
                                onCloseClick={() => {
                                    this.setState({walletChoice: null});            
                                }} />
                        ) : (
                            <div>
                            {walletItems.length > 0 ? walletItems : (
                                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                No Wallets To Show
                                </div>
                            )}
                            </div>
                        )}
                    </div>
                </Col>
                <Col sm={6} xs={12}>
                    <div className='wallet-info-tile'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                            
                            <h3 className='items-navigation-title'>
                            Wallet Transactions
                            </h3>
                        </div>
                        <Row style={{margin: 0}}>
                            <Col sm={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Date
                                </h4>
                            </Col>
                            <Col sm={2} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Money
                                </h4>
                            </Col>
                            <Col lg={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                {t('user')}
                                </h4>
                            </Col>
                            <Col sm={4} className="vertical-center" style={{padding: 0}}>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Transactions To Show
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        )
    }

    renderContents() {
        const {user} = this.props;
        const {mainTab} = this.state;
        const productId = this.props.router && this.props.router.params ? this.props.router.params.productId : null;
        if (!user) {
            return <div/>;
        }
        if (productId) {
            return (
                <ClubMembershipsView
                    productId={productId} />
            );
        }
        
        switch (mainTab) {
            case 0:
                return (
                    <div>{this.renderTeamList()}</div>
                );
            case 1:
                return (
                    <ClubMembershipsView />
                );
            case 2:
                return (
                    <div>{this.renderWallets()}</div>
                );
            default:
                return (
                    <div />
                );
        }
    }

    render() {
        const {user} = this.props;
        const {mainTab, loading, editTeam, editUser} = this.state;
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
        const teams = this.getMyTeams();
        const icon = user && user.photoUri ? user.photoUri : playerIcon;
        const uid = this.props.router && this.props.router.params ? this.props.router.params.uid : null;
        const productId = this.props.router && this.props.router.params ? this.props.router.params.uid : null;
        
        return (
            <div className='blue-landing'>
                
                <TeamNav 
                    location='coach' 
                    team={{}} 
                    authMsg={!user || (user.uid !== uid && uid) ? t('coach_membership_relogin') : null} />
                <div className='landing-dashboard'>
                    <div className='landing-dashboard-header' style={{marginTop: 20, marginBottom: 20}}>
                        <img alt='team' className='home-header-icon' src={icon} />
                        <div className="wrap-content-fill-child">
                            <h1 className="home-header-title-small">
                                {user && user.username ? user.username : t('profile')}
                                <span></span>
                            </h1>
                            <div className='wrap-content-parent'>
                                <h3 className="home-header-sub-title" style={{display: 'inline-block'}}>
                                    <FcManager/> {Object.values(teams).length}
                                    <div style={{display: 'inline-block', width: 20}}></div>
                                    <Button
                                        className="btn btn-team-main btn-lg page-scroll"
                                        onClick={e=>{
                                            this.setState({editUser: true});
                                        }}
                                    >{t('edit_profile')}</Button>
                                    <div style={{display: 'inline-block', width: 20}}></div>
                                    {user && user.username && (
                                    <Button
                                        className="btn btn-team-main btn-lg page-scroll"
                                        onClick={e=>{
                                            if (window.confirm(t('delete_profile_prompt'))) {
                                                this.submitDeleteUser();
                                            }
                                        }}
                                    >{t('delete_profile')}</Button>
                                    )}
                                </h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className='landing-dashboard-contents'>
                        {!uid && !productId && (
                        <CustomTabView
                            value={mainTab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="scrollable"
                            onChange={(e, value)=> {
                                this.setState({mainTab: value})
                                if (value !== 0) {
                                    replaceUrl(`/my_account`)
                                }
                            }}
                            aria-label="disabled tabs example"
                            items={[
                                {
                                    label: t('my_teams')
                                },
                                {
                                    label: t('membership')
                                },
                                {
                                    label: t('wallets')
                                },
                            ]} />
                        )}
                        <div className='landing-dashboard-contents-wrapper'>
                            <div className='landing-dashboard-contents-scroll'>  
                                {user && this.renderContents()}
                            </div>
                        </div>
                    </div>
                    {user && editTeam && (
                    <Modal isOpen={editTeam != null}>
                        <ModalBody>
                            <ClubEditView 
                                uid={user.uid}
                                squad={editTeam} 
                                onCloseClick={() => {
                                    this.setState({editTeam: null})
                                }}
                                onChanged={(squad)=>{
                                    teams[squad.id] = squad;
                                    this.setState({teams: teams});
                                }} />
                        </ModalBody>
                    </Modal>
                    )}
                    {user && editUser && (
                    <Modal isOpen={true}>
                        <ModalBody>
                            <ProfileEdit 
                                user={user}
                                profile={user}
                                onSaveSuccess={() => {
                                    this.setState({editUser: false});
                                    this.props.checkUser();
                                }}
                                onCloseClick={() => {
                                    this.setState({editUser: false});
                                }} />
                        </ModalBody>
                    </Modal>
                    )}
                </div>
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { primarySubscription } = state.subscriptions;
    const { loggingIn, user, child } = state.authentication;
    return { alert, loggingIn, user, child, primarySubscription };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(MyAccountPage));
export { connectedApp as MyAccountPage };
