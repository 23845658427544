import React, { Component } from "react";
import ServiceItemEdit from 'components/edit/club/landing/ServiceItemEdit';
import SlideTextEdit from 'components/edit/club/landing/SlideTextEdit';
import { homeServices, homeServiceText } from 'utils/landing';
import { Button, } from 'reactstrap';

export class ServicesEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            services: {},
            businesses: {},
            isOpen: false
        }
    }

    componentDidMount() {
        const {team} = this.props;
        const services = {};
        
        if (team && team.landingData && team.landingData.services) {
            for (const[key, value] of Object.entries(team.landingData.services)) {
                services[key] = value;
            }
        }

        this.setState({
            services: services,
        });
    }

    componentDidUpdate(prevProps) {
        const {team} = this.props;
        if (team !== prevProps.team) {
            const services = {};
            
            if (team && team.landingData && team.landingData.services) {
                for (const[key, value] of Object.entries(team.landingData.services)) {
                    services[key] = value;
                }
            }

            this.setState({
                services: services,
            });
        }
    }

    renderTextInfo(serviceText) {
        const {team, onUpdate} = this.props;
        return (
            <SlideTextEdit
                team={team}
                serviceText={serviceText} 
                onUpdate={(serviceText) => {       
                    const landingData = (team && team.landingData) ? team.landingData : {};

                    if (!landingData.services) {
                        landingData.services = {};
                    }
                    landingData.services.serviceText = {};

                    for (const [key, value] of Object.entries(serviceText)) {
                        landingData.services.serviceText[key] = value;
                    }
                    if (onUpdate) {
                        onUpdate(landingData);
                    }     
                }} />
        );
    }
    
    renderServiceItem(serviceItem) {
        const {team, onUpdate} = this.props;
        const {isOpen} = this.state;
        return (
            <ServiceItemEdit
                team={team}
                isOpen={isOpen}
                serviceItem={serviceItem} 
                onUpdate={(serviceItem) => {          
                    const landingData = (team && team.landingData) ? team.landingData : {};

                    if (!landingData.services) {
                        landingData.services = {};
                    }
                    landingData.services[serviceItem.id] = {};

                    for (const [key, value] of Object.entries(serviceItem)) {
                        landingData.services[serviceItem.id][key] = value;
                    }
                    if (onUpdate) {
                        onUpdate(landingData);
                    }
                }} />
        );
    }

    render() {
        const {services, businesses} = this.state;

        const service1 = services.service1 != null ? services.service1 : homeServices[0];
        const service2 = services.service2 != null ? services.service2 : homeServices[1];
        const service3 = services.service3 != null ? services.service3 : homeServices[2];

        const serviceText = services.serviceText != null && services.serviceText != null ? services.serviceText : homeServiceText;

        const businessArr = Object.values(businesses);
        businessArr.sort(function(a, b) {return a.id - b.id});
        
        return (
            <div>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    Services Section
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />
                <div>
                    <h4 style={{fontSize: 15, marginTop: 15}}>
                    Services
                    </h4>
                    {this.renderServiceItem(service1)}
                    {this.renderServiceItem(service2)}
                    {this.renderServiceItem(service3)}
                    {this.renderTextInfo(serviceText)}
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                        Business Ads
                        </h4>
                        
                        <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            for (const [,value] of Object.entries(businesses)) {
                                if (value.img2 == null || value.link == null) {
                                    alert("You should fill out image and link for existing one first");
                                    return;
                                }
                            }
                            const newValue = {};
                            const lastId = businessArr[businessArr.length - 1].id;
                            const lastIndex = parseInt(lastId.substring(8));
                            console.log('lastIndex', lastIndex);
                            newValue.id = 'business' + (lastIndex + 1);
                            businesses[newValue.id] = newValue;
                            this.setState({businesses: businesses});
                        }}>+</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServicesEdit;