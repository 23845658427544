import React, { Component } from "react";
import {
    Button, Spinner, Input, Form, Row, Col, Modal, ModalBody
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';

import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';
import { UserSearch } from 'components/detail/user/UserSearch';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import HorizontalAvatarList from "components/members/HorizontalAvatarList";
import { UsersEdit } from "components/detail/user/UsersEdit";
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";

export class FundraiseEdit extends Component {
    constructor(props) {
        super(props);
        const fundraise = {};
        const usersPicked = [];
        if (props.fundraise != null) {
            for (const [key, value] of Object.entries(props.fundraise)) {
                fundraise[key] = value;
            }
            if (fundraise.userIds && props.users) {
                for (const uid of Object.values(fundraise.userIds)) {
                    if (props.users[uid]) {
                        usersPicked.push(props.users[uid]);
                    }
                }
            }
        }
        
        this.state = {
            fundraise: fundraise,
            progress: false,
            usersPicked: usersPicked,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, fundraise} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.fundraise !== fundraise)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(fundraise)) {
                challengeVal[key] = value;
            }
            this.setState({
                fundraise: challengeVal
            })
        }
    }

    onSaveClick(fundraise, newImage) {
        const {onCloseClick, onChanged} = this.props;
        const {usersPicked} = this.state;
        const objRef = this;
        if (fundraise.title == null || fundraise.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (fundraise.id == null || fundraise.id === '') {
                fundraise.id = generateId("/fundraise");
            }
            fundraise.timestamp = dbTimestamp();
            if (usersPicked) {
                fundraise.userIds = {};
                for (const val of usersPicked) {
                    fundraise.userIds[val.uid] = val.uid;
                }
            }
            
            this.saveChangeWithImage(objRef, fundraise, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
                if (onChanged != null) {
                    onChanged(fundraise)
                }
            }, (error) => {
                alert("Submitting Change failed.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
            })
        }
    }

    saveChangeWithImage(objRef, fundraise, newImage, success, failure) {
        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(fundraise.id, 'fundraise', newImage, (url) => {
                fundraise.thumbUri = url;
                //success(fundraise);
                objRef.saveChange(fundraise, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(fundraise, success, failure)
        }
    }

    saveChange(fundraise, success, failure) {
        const {organization} = this.props;

        if (organization) {
            fundraise.organizationId = organization.id
            fundraise.owner = organization.owner;
            if (organization.managers) {
                fundraise.managers = {};
                for (const [k,v] of Object.entries(organization.managers)) {
                    fundraise.managers[k] = v;
                }
            }
        }
        
        const data = {map: fundraise}
        invokeHttpsApi('fundraise-submitFundraise', data, (data) => {
            if (data && data.success) {
                success(fundraise);
            } else {
                failure();
            }
        }, (error) => {
            console.log('save error', error);
            failure(error);
        });
    }

    render() {
        const {createMode, onCloseClick, onChanged} = this.props;
        const {fundraise, newImage, imageProgress, progress, usersPicked, removeOpen, usersOpen} = this.state;

        const avatars = [];

        if (usersPicked) {
            for (const value of usersPicked){
                avatars.push(
                    {avatar: value.photoUri, name: value.username}
                )
            }
        }

        let numArr = [];
        for (let i = 1; i < 50; i ++) {
            numArr.push(i);
        }
        
        if (fundraise != null) {
            return (
                <Form className='form-edit'>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Title
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    fundraise.title = e.target.value;
                                    this.setState({fundraise: fundraise});
                                }} value={fundraise.title} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Image
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <HorizontalAttachImageEdit
                                imageUrl={fundraise.thumbUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Description
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="textarea" className='form-control' name="text" id="title" onChange={e => {
                                    fundraise.description = e.target.value;
                                    this.setState({fundraise: fundraise});
                                }} value={fundraise.description} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Total Goal Price
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="number" step={0.01} className='form-control' name="text" id="title" onChange={e => {
                                    fundraise.donateGoal = parseFloat(e.target.value);
                                    this.setState({fundraise: fundraise});
                                }} value={fundraise.donateGoal} />
                            </div>
                        </div>
                    </div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('users')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                        {avatars.length > 0 && (
                            <HorizontalAvatarList
                                avatars={avatars}
                                avatarProps={{ size: 50 }}
                                />
                        )}
                            <div>
                                <Button
                                    className='btn-submit'
                                    onClick={(e)=> {
                                        this.setState({usersOpen: true});
                                    }}>{t('add')}</Button>
                                <Button
                                    className='btn-submit'
                                    onClick={(e)=> {
                                        this.setState({removeOpen: true});
                                    }}>{t('remove')}</Button>
                            </div>
                        </Col>
                    </Row>
                    
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <div className='row' style={{textAlign: 'center'}}>
                                <button className='btn btn-secondary btn-edit' style={{margin: '0px auto'}} onClick={e => {
                                this.onSaveClick(fundraise, newImage);
                                }}>{t('create')}</button>
                            </div>
                        ) : (
                            <div className='form-edit-row row'>
                                <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} >
                                    <button className='btn btn-secondary btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(fundraise, newImage);
                                    }}>{t('save')}</button>
                                </div>
                                <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </div>
                            </div>
                        )}
                        </div>
                    )}
                    {usersOpen && (
                    <Modal isOpen={true}>
                        <ModalBody>
                            <UserSearch
                                picked={usersPicked}
                                onCloseClick={(uids, users) => {
                                    if (uids) {
                                        let update = [];
                                        for (const uid of uids) {
                                            if (users[uid]) {
                                                update.push(users[uid]);
                                            }
                                        }
                                        if (onChanged != null) {
                                            onChanged(fundraise, update)
                                        }
                                        this.setState({usersPicked: update})
                                    }
                                    this.setState({usersOpen: false})
                                }} />
                        </ModalBody>
                    </Modal>
                    )}
                    {removeOpen && (
                    <Modal isOpen={true}>
                        <ModalBody>
                            <UsersEdit
                                usersPicked={usersPicked}
                                onCloseClick={(update) => {
                                    if (update) {
                                        this.setState({usersPicked: update})
                                    }
                                    this.setState({removeOpen: false})
                                }} />
                        </ModalBody>
                    </Modal>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(FundraiseEdit);