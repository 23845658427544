import React, { Component } from 'react';
import { Button, Input, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { cartActions } from 'redux/_actions';
import { STRIPE_PUBLISHABLE_KEY, getOrgDomain, renderHtmlString, sortByUsername } from 'utils/Utils';
import { t } from 'i18next';
import userImage from 'assets/img/icons/player_photo_default.png';
import { MdCheckBox } from 'react-icons/md';
import OrganizationProfileEdit from 'components/edit/organization/OrganizationProfileEdit';
import { generateId, invokeHttpsApi } from 'utils/API';

import { loadStripe } from '@stripe/stripe-js';

const STEP_CHILD = 0
const STEP_REGISTER = 1
const STEP_POLICY = 2
const STEP_CHECKOUT = 3

export default class PlayerEnrollOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: STEP_CHILD,
            uidChoicce: null,
            progress: false,
        };
    }
    
    componentDidMount() {
        
    }

    componentDidUpdate(prevProps) {
        
    }

    getProfile(choice) {
        const {organization, profileData} = this.props;
        if (!choice) {
            return {};
        }
        let myProfile = {
            uid: choice.uid,
            parentId: choice.parentId,
            playerName: choice.username,
            email: choice.email,
            photoUri: choice.photoUri,
        };
        myProfile.organizationId = organization.id;
        myProfile.owner = organization.owner;
        for (const val of Object.values(profileData)) {
            if (val.organizationId === organization.id && val.uid === choice.uid) {
                myProfile = val;
            }
        }

        return myProfile;
    }

    renderChildChoice() {
        const {season, program, user, users} = this.props;
        const {uidChoicce} = this.state;

        let existing = [];
        if (season && season.registers) {
            existing = Object.keys(season.registers);
        }
        if (program && program.registers) {
            existing = Object.keys(program.registers);
        }

        let childArr = [];
        for (const item of Object.values(users)) {
            if (user && user.uid === item.parentId && !existing.includes(item.uid)) {
                childArr.push(item);
            }
        }
        sortByUsername(childArr);
        childArr.push(user);
        let items = [];
        for (const item of childArr) {
            items.push(
                <div className='enroll-list-item' onClick={e => {
                    this.setState({uidChoicce: item.uid});
                }}>
                    <img className='enroll-list-icon' alt='thumb' src={item.photoUri ? item.photoUri : userImage} />
                    <div className='enroll-list-info'>{item.username}</div>
                    {item.uid === uidChoicce && <div className='enroll-list-sub-info'><MdCheckBox/></div>}
                </div>
            )
        }

        return (
            <div className='enroll-overlay-wrapper'>
                <h3 className='enroll-overlay-title'>
                    {t('enroll_header_01')}
                </h3>
                <div className='enroll-overlay-text'>
                    {t('enroll_text_01')}
                </div>
                <div className='enroll-overlay-contents'>
                    {items.length > 0 ? items : (
                    <div className='text-center'>No More Users to register</div>
                    )}
                </div>
                <div className='enroll-overlay-actions'>
                    <Button className='btn-blue' onClick={e => {
                        if (uidChoicce) {
                            this.setState({currentStep: STEP_REGISTER})
                        }
                    }}>
                        {t('confirm')}
                    </Button>
                    <Button className='btn-cancel' onClick={e => {
                        if (this.props.onCloseClick) {
                            this.props.onCloseClick();
                        }
                    }}>
                        {t('close')}
                    </Button>
                </div>
            </div>
        )
    }

    renderRegister() {
        const {organization, users} = this.props;
        const {uidChoicce} = this.state;
        const choice = users[uidChoicce]
        const myProfile =this.getProfile(choice);
        
        return (
            <div className='enroll-overlay-wrapper'>
                <div className='enroll-overlay-contents'>
                    <OrganizationProfileEdit
                        organization={organization}
                        user={choice}
                        profileData={myProfile}
                        onSaveDone={()=>{
                            if (organization.policy) {
                                this.setState({currentStep: STEP_POLICY})
                            } else {
                                this.setState({currentStep: STEP_CHECKOUT})
                            }
                        }}
                        closeLabel={t('skip')}
                        onCloseClick={()=>{
                            if (organization.policy) {
                                this.setState({currentStep: STEP_POLICY})
                            } else {
                                this.setState({currentStep: STEP_CHECKOUT})
                            }
                        }} />
                </div>
            </div>
        )
    }

    renderPolicy() {
        const {organization, season} = this.props;
        const {uidChoicce, progress} = this.state;
        return (
            <div className='enroll-overlay-wrapper'>
                <h3 className='enroll-overlay-title'>
                    {t('enroll_header_03')}
                </h3>
                <div className='enroll-overlay-contents'>
                    {renderHtmlString(organization.policy)}
                </div>
                {progress ? 
                <div className='enroll-overlay-actions'>
                    <Spinner color='primary' /> 
                </div>  :
                <div className='enroll-overlay-actions'>
                    <Button className='btn-blue' onClick={e => {
                        if (season) {
                            this.setState({progress: true})
                            invokeHttpsApi('userQuery-submitSeasonAgree', {
                                agree: true,
                                seasonId: season.id,
                                userId: uidChoicce,
                            }, (data) => {
                                this.setState({progress: false, currentStep: STEP_CHECKOUT})
                            }, (error) => {
                                console.log('saveContents error', error);
                                this.setState({progress: false})
                            });
                        } else {
                            this.setState({currentStep: STEP_CHECKOUT})
                        }
                    }}>
                        {t('agree')}
                    </Button>
                    <Button className='btn-cancel' onClick={e => {
                        if (this.props.onCloseClick) {
                            this.props.onCloseClick();
                        }
                    }}>
                        {t('close')}
                    </Button>
                </div>
                }
            </div>
        )
    }

    renderCheckout() {
        const {organization, season, program, checkoutProgress, checkoutCartItems, addToCart} = this.props;
        const {note} = this.state;

        let price = 0;
        let url = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        url += getOrgDomain(organization);
        
        if (season) {
            price = season.enrollFee && season.enrollFee.playerFee ? season.enrollFee.playerFee : 0;
            url += '/championships/' + season.id
        } else if (program) {
            price = program.playerFee ? program.playerFee : 0;
            url += '/programs/' + program.id
        }
        
        return (
            <div className='enroll-overlay-wrapper'>
                <h3 className='enroll-overlay-title'>
                    {t('enroll_header_04')}
                </h3>
                <div className='enroll-overlay-text'>
                    {t('enroll_text_04')}
                </div>
                <div className='enroll-overlay-contents'>
                    <div className='wrap-content-parent'>
                        <div className='reg-price-name'>
                        {t('player_fee')}:
                        </div>
                        <h4 className='reg-price-label'>
                        $ {price}
                        </h4>
                    </div>
                    <div className='form-edit-input'>
                        <Input type="textarea" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                            this.setState({note: e.target.value});
                        }} value={note ? note : ""} />
                    </div>
                    <div className='enroll-overlay-text'>
                        {t('enroll_text_checkout')}
                    </div>
                    
                </div>
                {checkoutProgress ? 
                <div className='enroll-overlay-actions'>
                    <Spinner color='primary' /> 
                </div> : 
                <div className='enroll-overlay-actions'>
                    <Button className='btn-green' style={{marginRight: 10}} onClick={e => {
                        const item = this.getEnrollCart();
                        const dict = {};
                        dict[item.id] = item;
                        checkoutCartItems(organization.id, dict, note, price, url, (async (session) => {
                            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
                            stripe.redirectToCheckout({ sessionId: session.id });
                        }), (() => {
                            alert("Requesting Checkout Session Failed.")
                        }))
                    }}>
                        {t('checkout_now')}
                    </Button>
                    
                    <Button className='btn-blue' onClick={e => {
                        addToCart(this.getEnrollCart());
                    }}>
                        {t('add_to_cart')}
                    </Button>
                    <Button className='btn-cancel' onClick={e => {
                        if (this.props.onCloseClick) {
                            this.props.onCloseClick();
                        }
                    }}>
                        {t('close')}
                    </Button>
                </div>
                }
            </div>
        )
    }

    getEnrollCart() {
        const {season, program, user} = this.props;
        const {uidChoicce, note} = this.state;

        const result = {
            uid: user.uid,
            userId: uidChoicce,
            note: note,
            role: "player",
        };
        result.id = generateId("/order/newPath/dict");
        if (season) {
            result.price = season.enrollFee && season.enrollFee.playerFee ? season.enrollFee.playerFee : 0;
            result.seasonId = season.id;
        } else if (program) {
            result.price = program.playerFee ? program.playerFee : 0;
            result.programId = program.id;
        }
        
        return result;
    }

    render() {
        const {user} = this.props;
        const {currentStep} = this.state;
        
        if (!user) {
            return <div/>
        }

        return (
            <div className='enroll-overlay'>
                {currentStep === STEP_CHILD && this.renderChildChoice()}
                {currentStep === STEP_REGISTER && this.renderRegister()}
                {currentStep === STEP_POLICY && this.renderPolicy()}
                {currentStep === STEP_CHECKOUT && this.renderCheckout()}
            </div>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { checkoutProgress, cart, session } = state.cartData;
    return { user, checkoutProgress, cart, session };
}

const actionCreators = {
    checkoutCartItems: cartActions.checkoutCartItems,
    addToCart: cartActions.addToCart,
    removeFromCart: cartActions.removeFromCart
};

const connected = connect(mapState, actionCreators)(PlayerEnrollOverlay);
export { connected as PlayerEnrollOverlay };
