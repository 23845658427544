import { t } from "i18next";
import React, { Component } from "react";
import { LiaRunningSolid } from "react-icons/lia";
import { TbSoccerField, TbTournament } from "react-icons/tb";
import { Button } from "reactstrap";
import {OrgOverlay} from 'components/landing/globe/OrgOverlay';

export class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgOverlay: false,
        };
    }

    render() {
        const {orgOverlay} = this.state;

        return (
            <div className="main-services">
                <div className="container">
                    <div className="main-services-item">
                        <div className="main-services-title">
                            <div className="main-services-icon">
                                <TbSoccerField />
                            </div>
                            <div className="main-services-number">
                                01.
                            </div>
                        </div>
                        <div className="main-services-subtitle">
                            {t('main_service_01')}
                        </div>
                        <div className="main-services-text">
                            {t('main_service_text_01')}
                        </div>
                        <Button className="btn-coral" onClick={e=> {
                            this.setState({orgOverlay: true})
                        }}>
                            {t('main_service_action_01')}
                        </Button>
                    </div>
                    <div className="main-services-item">
                        <div className="main-services-title">
                            <div className="main-services-icon">
                                <TbTournament />
                            </div>
                            <div className="main-services-number">
                                02.
                            </div>
                        </div>
                        <div className="main-services-subtitle">
                            {t('main_service_02')}
                        </div>
                        <div className="main-services-text">
                            {t('main_service_text_02')}
                        </div>
                        <Button className="btn-coral" onClick={e=> {
                            this.setState({orgOverlay: true})
                        }}>
                            {t('main_service_action_02')}
                        </Button>
                    </div>
                    <div className="main-services-item">
                        <div className="main-services-title">
                            <div className="main-services-icon">
                                <LiaRunningSolid />
                            </div>
                            <div className="main-services-number">
                                03.
                            </div>
                        </div>
                        <div className="main-services-subtitle">
                            {t('main_service_03')}
                        </div>
                        <div className="main-services-text">
                            {t('main_service_text_03')}
                        </div>
                        <Button className="btn-coral">
                            {t('main_service_action_03')}
                        </Button>
                    </div>
                </div>
                <OrgOverlay isOpened={orgOverlay} onOpenedUpdate={update => {
                    this.setState({orgOverlay: update})
                }} />
            </div>
        );
    }
}

export default Services;
