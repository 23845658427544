import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from "@mui/material/styles";
import { mainTabStyle } from 'style/styles';

const StyledTab = styled(Tab)({
    "&.Mui-selected": {
        color: "black",
        fontWeight: 600,
    }
});
  
  
class CustomTabView extends Component {

    render() {
        const { actionBg, indicatorColor, textColor, variant, items, value, onChange } = this.props;

        return (
            <Box sx={mainTabStyle}>
                <Tabs
                    value={value}
                    indicatorColor={indicatorColor}
                    textColor={textColor}
                    variant={variant}
                    onChange={onChange}
                    TabIndicatorProps={actionBg ? {
                        style: {backgroundColor: actionBg, height: 3}
                    } : {}} >

                    {items.map((v,i)=> (
                    <StyledTab key={i} icon={v.icon} label={v.label} />
                    ))}
                </Tabs>
            </Box>
        );
    }
}

export default CustomTabView;
