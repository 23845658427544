import React, { Component } from 'react';
import ClubBattleHeader from 'components/detail/club/ClubBattleHeader';
import ClubBattleDetailView from 'components/detail/club/ClubBattleDetailView';
import ClubBattleEdit from 'components/edit/club/ClubBattleEdit';
import LoadingView from 'components/detail/loading/LoadingView';

import { 
    Button,
    Modal, 
    ModalBody,
} from 'reactstrap';
import { invokeHttpsApi } from 'utils/API';

export class ClubBattlesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            users: {},
            clubBattles: {},
            clubBattleChoice: {},
            clubChallenges: {},
            detailOpen: false,
            editOpen: false,
        };
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        var { clubBattleChoice } = this.state;
        this.setState({
            loading: true,
        })
        invokeHttpsApi('clubQuery-clubCompetitionsQuery', {coachMode: true}, (data) => {

            const clubBattles = {};
            const clubChallenges = {};
            const clubChallengeSubmissions = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.clubBattles)) {
                    clubBattles[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubChallenges)) {
                    clubChallenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubChallengeSubmissions)) {
                    clubChallengeSubmissions[key] = value;
                }
            }
            if (clubBattleChoice && clubBattleChoice.id != null) {
                clubBattleChoice = clubBattles[clubBattleChoice.id];
            }

            this.setState({
                clubBattles: clubBattles,
                clubChallenges: clubChallenges,
                clubChallengeSubmissions: clubChallengeSubmissions,
                clubBattleChoice: clubBattleChoice,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    renderContents() {
        const { uid, club, user } = this.props;
        const { clubBattles, clubChallenges, detailOpen, clubBattleChoice } = this.state;

        if (detailOpen && clubBattleChoice.id != null) {
            return (
                <ClubBattleDetailView
                    user={user}
                    clubBattle={clubBattleChoice}
                    clubChallenges={clubChallenges} />
                )
        } else {
            const result = [];
    
            for (const[key, value] of Object.entries(clubBattles)) {
        
                if (value.clubId === club.id) {
                    result.push(
                        <ClubBattleHeader 
                            key={key}
                            uid={uid}
                            clubBattle={value}
                            onClickDetails={() => {
                                this.setState({
                                    clubBattleChoice: value,
                                    detailOpen: true
                                })
                            }}
                            onClickEdit={() => {
                                this.setState({
                                    clubBattleChoice: value,
                                    editOpen: true
                                })
                            }} />
                    );
                }
            }
    
            return result;
        }
    }

    render() {
        const { club, uid } = this.props;
        const {clubBattleChoice, clubChallenges, loading, editOpen, detailOpen} = this.state;
        if (loading) {
            return (
                <LoadingView />
            );
        }
        return (
            <div>
                <div className='wrap-content-parent' style={{marginTop: 20}}>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <button className='detail-nav-btn' onClick={e=> {
                            this.setState({clubBattleChoice: {}, detailOpen: false})
                        }}>
                            <h3 style={{display: 'inline-block', fontSize: 16}}>
                            Competitions
                            </h3>
                        </button>
                        {detailOpen && clubBattleChoice.id !== null && (
                            <div style={{display: 'inline-block'}}>
                                <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                                <button className='detail-nav-btn'>
                                    <h3 style={{display: 'inline-block', fontSize: 16}}>
                                    {clubBattleChoice.title}
                                    </h3>
                                </button>
                            </div>
                        )}
                    </div>
                    
                    <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            this.setState({clubBattleChoice: {}, editOpen: true});
                    }}>+</Button>
                </div>
                {this.renderContents()}
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubBattleEdit 
                            uid={uid}
                            club={club}
                            clubBattle={clubBattleChoice} 
                            clubChallenges={clubChallenges}
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ClubBattlesView;