import React, { Component } from "react";
import { Input, FormGroup } from 'reactstrap';
import { MdEdit } from 'react-icons/md';

export class RequiredOption extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false
        };
    }

    componentDidUpdate(prevProps) {
        const {value} = this.props;
        if (value !== prevProps.value) {
            this.setState({edit: false});
        }
    }

    render() {
        const {value, onChanged} = this.props;
        const {edit} = this.state;
        
        return (
            <div>
            {edit ? (
                <FormGroup>
                    <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                        if (onChanged != null) {
                            onChanged(e.target.value);
                        }
                    }}>
                        <option value="null"></option>
                        <option value="Required">Required</option>
                        <option value="Optional">Optional</option>
                    </Input>
                </FormGroup>
            ) : (
                <div className='form-control' onClick={e=>{
                    this.setState({edit: true})
                }}>
                    {value} <MdEdit/>
                </div>
            )}
            </div>
        );
    }
}

export default RequiredOption;