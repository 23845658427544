import React, { Component } from "react";
import ReactPlayer from 'react-player';
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';

export class ClubTrackDetailsView extends Component {
    render() {
        const {onCloseClick, clubTrack} = this.props;
        
        let challengeImage = clubTrack.thumbUri != null ? clubTrack.thumbUri : defaultImage;
        let timeMode = clubTrack.timeMode != null ? clubTrack.timeMode : false;
        
        if (clubTrack != null) {
            return (
                <div>
                    <Card>
                        <div className='wrap-content-parent detail-header'>
                            <CardImg
                                className="profile-thumb vertical-center-spacing"
                                src={challengeImage}
                                style={{ width: 60, height: 60 }}
                                />
                            <div className="wrap-content-fill-child vertical-center-spacing">
                                <h4 className='label label-default'>
                                {clubTrack.title}
                                </h4>
                            </div>
                        </div>
                        <CardBody>
                            <div>{clubTrack.description}</div>
                            {timeMode ? (
                                <div className='wrap-content-fill-child vertical-center-spacing one-line-div'>
                                Duration Limit:<div className="info-badge-info">{clubTrack.duration_limit / 60} min</div>
                                </div>
                            ) : (
                                <div className='wrap-content-fill-child vertical-center-spacing one-line-div'>
                                Distance Limit:<div className="info-badge-info">{clubTrack.distance / 1000} km</div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                    {clubTrack.videoUri != null && clubTrack.videoUri !== '' && (
                    <div>
                        <ReactPlayer 
                            className="default-player"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={clubTrack.videoUri}
                            width={'80%'}
                            height={'auto'} />

                    </div>
                    )}
                    <Button className='modal-button' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubTrackDetailsView);