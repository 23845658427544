import React, { Component } from "react";
import Avatar from 'components/detail/Avatar';
import { connect } from 'react-redux';

import { Card, CardBody, ListGroup, ListGroupItem, Button, FormGroup, Label, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import teamImage from 'assets/img/icons/club_icon_default.png';

class TeamsEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamIds: {},
            clubs: {},
            coachGroups: {},
            gradeGroups: {},
            groupChoice: {},
            searchStr: '',
            editOpen: false,
            followersPick: false,
        }
    }

    componentDidMount() {
        const {teamIds} = this.props;

        if (teamIds != null) {
            const update = {};
            for (const [key, value] of Object.entries(teamIds)) {
                update[key] = value;
            }
            this.setState({teamIds: update});
        }
    }

    componentDidUpdate(prevProps) {
        const {teamIds} = this.props;
        if (prevProps.teamIds !== teamIds) {
            if (teamIds != null) {
                const update = {};
                for (const [key, value] of Object.entries(teamIds)) {
                    update[key] = value;
                }
                this.setState({teamIds: update});
            } else {
                this.setState({teamIds: {}});
            }
        }
    }
    
    render() {
        const { clubs, available, onSaveClick, onCloseClick } = this.props;
        const { teamIds, searchStr } = this.state;

        const items = [];
        const choice = {};

        if (clubs != null && teamIds != null) {
            for (const [key, item] of Object.entries(clubs)){
                const inAvailable = available != null && Object.values(available).includes(key);
                
                if (item != null && inAvailable &&
                    (searchStr == null || searchStr === '' || item.title.toLowerCase().includes(searchStr.toLowerCase()))
                ) {
                    choice[key] = item;
                }
            }
        }

        const clubsChoice = Object.values(choice);
        clubsChoice.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        for (var i = 0; i < clubsChoice.length; i ++) {
            const item = clubsChoice[i];
            let itemType = Object.values(teamIds).includes(item.id) ? "Added" : "Not Added";

            items.push(
                <ListGroupItem key={item.id}>
                    <div className='wrap-content-parent'>
                        <Avatar className='wrap-content-wrap-child vertical-center-spacing' src={item.iconUri != null ? item.iconUri : teamImage} /> 
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div>{item.title}</div>
                            <div style={{fontSize: 12}}>{itemType}</div>
                        </div>
                        <Button className={Object.values(teamIds).includes(item.id) ? 'btn-cancel' : 'btn-submit'} onClick={e=>{
                            if (Object.values(teamIds).includes(item.id)) {
                                delete teamIds[item.id];
                            } else {
                                teamIds[item.id] = item.id;
                            }
                            this.setState({teamIds: teamIds});
                        }}>{Object.values(teamIds).includes(item.id) ? "Remove" : "Add"}</Button>
                    </div>
                </ListGroupItem>
            )
        }

        return (
            <Card>
                <CardBody>
                    <div>{t('teams')}</div>
                    <div style={{height: 10}}/>
                    <FormGroup>
                        <Input
                            type="search"
                            name="search"
                            id="member_search"
                            placeholder={t('search_by_team')}
                            onChange={e=>{
                                this.setState({searchStr: e.target.value});
                            }} />
                    </FormGroup>
                    <ListGroup>
                        {items}
                    </ListGroup>
                    {Object.values(choice).length > 0 && (
                        <FormGroup>
                            <Label for="locationChoice" check>{t('visible_teams')}: {
                                Object.values(choice).length
                            }</Label>
                            <div className='wrap-content-parent'>
                                <Button className='wrap-content-wrap-child btn-submit' onClick={e=>{
                                    for (const[key,] of Object.entries(choice)) {
                                        teamIds[key] = key;
                                    }
                                    this.setState({teamIds: teamIds});
                                }}>Add All</Button>
                                <Button className='wrap-content-wrap-child btn-cancel' onClick={e=>{
                                    for (const[key,] of Object.entries(choice)) {
                                        delete teamIds[key];
                                    }
                                    this.setState({teamIds: teamIds});
                                }}>Remove All</Button>
                            </div>
                        </FormGroup>
                    )}
                    <div style={{height: 10}}/>
                    <Button className='btn-edit' onClick={e=> {
                        if (onSaveClick != null) {
                            onSaveClick(teamIds);
                        }
                    }}>{t('save')}</Button>
                    <Button className='btn-cancel' onClick={e=> {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </CardBody>
            </Card>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}
  
const connectedApp = withTranslation()(connect(mapState)(TeamsEditView));
export { connectedApp as TeamsEditView };
