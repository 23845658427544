import React, { Component } from 'react';
import LoadingView from 'components/detail/loading/LoadingView';
import { connect } from 'react-redux';
import InfoView from 'components/detail/InfoView';
import {OrganizationPopUp} from 'components/membership/OrganizationPopUp';
import {GameDetailBadge} from 'components/edit/organization/GameDetailBadge';
import {GameEdit} from 'components/detail/organization/GameEdit';
import OrganizationPaymentBadge from 'components/detail/organization/OrganizationPaymentBadge';
import { 
    CardImg,
    Row,
    Col,
    Modal, 
    ModalBody,
    Spinner,
    Button,
} from 'reactstrap';
import { t } from 'i18next';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import userIcon from 'assets/img/icons/player_photo_default.png';
import appIcon from 'assets/img/logo/live_192.png';
import { getOrgDomain, toDateTimeString } from 'utils/Utils';

import OrganizationEdit from 'components/edit/organization/OrganizationEdit';
import { userActions } from 'redux/_actions';
  
import venueIcon from 'assets/img/icons/venue_icon.png';
import leaguesIcon from 'assets/img/icons/leagues_icon.png';
import stuffIcon from 'assets/img/icons/staff_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import OrganizationBadge from 'components/detail/organization/OrganizationBadge';
import OrganizationTeamRow from 'components/detail/organization/OrganizationTeamRow';
import OrganizationUserRow from 'components/detail/organization/OrganizationUserRow';
import OrganizationSeasonRow from 'components/detail/organization/OrganizationSeasonRow';
import instruction_header from 'assets/img/icons/instruction_header.png';
import FaqHeader from 'components/detail/faq/FaqHeader';
import FaqDetails from 'components/detail/faq/FaqDetails';
import { toTimeString, getDayStart, DAY_TIME } from 'utils/Utils';
import instruction_1 from 'assets/img/icons/instruction_1.png';
import instruction_2 from 'assets/img/icons/instruction_2.png';
import instruction_3 from 'assets/img/icons/instruction_3.png';
import instruction_4 from 'assets/img/icons/instruction_4.png';
import instruction_5 from 'assets/img/icons/instruction_5.png';
import instruction_6 from 'assets/img/icons/instruction_6.png';
import instruction_7 from 'assets/img/icons/instruction_7.png';
import instruction_8 from 'assets/img/icons/instruction_8.png';
import instruction_9 from 'assets/img/icons/instruction_9.png';
import { 
    GiSoccerField,
    GiWhistle
} from 'react-icons/gi';
import { 
    FaWpforms
} from 'react-icons/fa';
import { invokeHttpsApi } from 'utils/API';
import { getStorageUrl } from 'utils/StorageAPI';
import { withRouter } from 'utils/Routher';
import { STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER } from 'utils/constants';

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        this.setState({ loading: true });
        invokeHttpsApi('organizerQuery-organizerDashboardQuery', {}, (data) => {

            const organizations = {};
            const divisions = {};
            const refereeIds = [];
            const managerIds = [];
            const seasons = {};
            const profileData = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const stripeUser = {};
            const faq = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const value of data.refereeIds) {
                    refereeIds.push(value);
                }
                for (const value of data.managerIds) {
                    managerIds.push(value);
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.stripeUser)) {
                    stripeUser[key] = value;
                }
                for (const [key, value] of Object.entries(data.faq)) {
                    faq[key] = value;
                }
            }

            this.setState({
                organizations: organizations,
                divisions: divisions,
                refereeIds: refereeIds,
                managerIds: managerIds,
                seasons: seasons,
                profileData: profileData,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                stripeUser: stripeUser,
                faq: faq,
                loading: false,
                membershipPopup: true,
            })
            this.loadTransactions(users);
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({ loading: false });
        });
    }

    async loadTransactions(users) {
        const { user } = this.props;
        
        const data = {}
        if (user.stripeAccountId) {
            data.stripeAccountId = user.stripeAccountId
        }
        
        this.setState({progress: true});
        invokeHttpsApi('purchase-queryOrganizationTransactions', data, (data) => {

            const income = {};
            const customers = {};
            const seasonPayments = {};
            const walletPayments = {};
            const fundraisePayments = {};
            
            if (data.success) {
                if (data.income) {
                    for (const [k,v] of Object.entries(data.income)) {
                        income[k] = v;
                    }
                }
                if (data.seasonPayments) {
                    for (const [k,v] of Object.entries(data.seasonPayments)) {
                        seasonPayments[k] = v;
                    }
                }
                if (data.walletPayments) {
                    for (const [k,v] of Object.entries(data.walletPayments)) {
                        walletPayments[k] = v;
                    }
                }
                if (data.fundraisePayments) {
                    for (const [k,v] of Object.entries(data.fundraisePayments)) {
                        fundraisePayments[k] = v;
                    }
                }
                if (data.customers) {
                    for (const [k,v] of Object.entries(data.customers)) {
                        customers[k] = v;
                    }
                }
                if (data.users) {
                    for (const [key, value] of Object.entries(data.users)) {
                        users[key] = value;
                    }
                }
            }

            this.setState({
                income: income, 
                customers: customers, 
                seasonPayments: seasonPayments, 
                walletPayments: walletPayments, 
                fundraisePayments: fundraisePayments, 
                users: users,
                progress: false
            });
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderPaymentSettings() {
        const {user} = this.props;
        const {organizations, stripeUser} = this.state;
        
        let organization = Object.values(organizations).length > 0 ? Object.values(organizations)[0] : {};
        
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        
        return (
        <div>
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <div className='detail-nav-btn'>
                        <div className='items-navigation-count'><FaWpforms/></div>
                        <h3 className='items-navigation-title'>
                        Setup
                        </h3>
                        <div className='items-navigation-count'>| Organization Payment Method</div>
                    </div>
                </div>
                
            </div>
            <OrganizationPaymentBadge
                organization={organization}
                user={user}
                stripeUser={stripeUser}
                onChanged={(update)=> {
                    organizations[update.id] = update;
                    this.setState({organizations: organizations});
                }} />
        </div>
        );
    }

    renderInstructions() {
        return (
            <div>
                <div className='shadow-tile instruction-wrapper' style={{marginLeft: 0, marginRight: 0}}>
                    <img className='instruction-icon' alt='icon' src={instruction_header}/>
                    <div className='instruction-title'>Getting Started</div>
                </div>

                <Row style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 10,
                }}>
                    <Col xs={4} onClick={() => {
                        this.setState({faqOpen: true, faqExpaned: {}})
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_1}/>
                            <div className='instruction-title'>How it works</div>
                        </div>
                    </Col>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/teams')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_2}/>
                            <div className='instruction-title'>Invite a team</div>
                        </div>
                    </Col>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/locations')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_3}/>
                            <div className='instruction-title'>Manage Venues</div>
                        </div>
                    </Col>
                </Row>
                <Row style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 10,
                }}>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/player')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_4}/>
                            <div className='instruction-title'>Manage Players</div>
                        </div>
                    </Col>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/referee')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_5}/>
                            <div className='instruction-title'>Add a referee</div>
                        </div>
                    </Col>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/schedules')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_6}/>
                            <div className='instruction-title'>Create event</div>
                        </div>
                    </Col>
                </Row>
                <Row style={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 10,
                }}>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/divisions')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_7}/>
                            <div className='instruction-title'>Create new division</div>
                        </div>
                    </Col>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/locations')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_8}/>
                            <div className='instruction-title'>Create new venue</div>
                        </div>
                    </Col>
                    <Col xs={4} onClick={() => {
                        this.props.router.navigate('/organizer/register/attendance')
                    }}>
                        <div className='instruction-tile instruction-wrapper' style={{padding: '5px 10px'}}>
                            <img className='instruction-icon' alt='icon' src={instruction_9}/>
                            <div className='instruction-title'>View attendance</div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    renderGames() {
        return (
            <div>
                <h3 className='row-table-title' style={{fontSize: 18, marginTop: 50}}>
                    Games
                </h3>
                <div className='divider' />
                <div className='divider' />
                {this.renderTodayGames()}
                {this.renderPendingGames()}
                {this.renderReschedulingGames()}
            </div>
        )
    }

    renderFaq() {
        const {faq, faqOpen, faqExpaned} = this.state;

        if (faqOpen && faq) {
            let list = Object.values(faq);
            list.sort((a,b) => {
                return a.index - b.index;
            })

            let items = [];
            for (const item of list) {
                if (faqExpaned && faqExpaned[item.id]) {
                    items.push(
                        <FaqDetails onClose={e=> {
                            faqExpaned[item.id] = false;
                            this.setState({
                                faqExpaned: faqExpaned,
                            })
                        }} faq={item} />
                    )
                } else {
                    items.push(
                        <FaqHeader onClickDetails={e=> {
                            faqExpaned[item.id] = true;
                            this.setState({
                                faqExpaned: faqExpaned,
                            })
                        }} faq={item} />
                    )
                }
            }

            return (
                <Modal isOpen={true}>
                    <ModalBody>
                        <h2>Frequently Asked Questions</h2>
                        {items}
                        <div className="faq-detail-input" style={{paddingTop: 5}}>
                            <div className="wrap-content-parent">
                                <div className="wrap-content-fill-child"/>
                                <Button 
                                    className='btn-submit faq-comment-btn wrap-content-wrap-child' 
                                    onClick={e => {
                                        this.setState({faqOpen: false, faqExpaned: {}})
                                    }}>{t('close')}</Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            )

        } else {
            return <div />
        }
    }

    renderTodayGames() {
        const {organizations, seasons} = this.state;
        
        let organization = Object.values(organizations)[0];
        let now = new Date().valueOf();
        let games = [];
        const dayStart = getDayStart(now)
        
        for (const season of Object.values(seasons)) {
            if (season.organizationId !== organization.id) {
                continue;
            }
            if (season.subSeasons) {
                for (const subSeason of Object.values(season.subSeasons)) {
                    if (subSeason.games) {
                        for (const game of Object.values(subSeason.games)) {
                            if (game.start != null && game.start > dayStart && game.start < dayStart + DAY_TIME) {
                                games.push(game)
                            }
                        }
                    }
                }
            }
        }
        games.sort(function(a, b) {
            if (a.start === b.start) 
                return 0;

            return a.start > b.start ? -1 : 1;
        });
        
        return (
            <div>
                {this.renderGameItems("Today's Games", games)}
            </div>
        );
    }

    renderPendingGames() {
        const {organizations, seasons} = this.state;
        
        let organization = Object.values(organizations)[0];
        let now = new Date().valueOf();
        let games = [];
        
        for (const season of Object.values(seasons)) {
            if (season.organizationId !== organization.id) {
                continue;
            }
            if (season.subSeasons) {
                for (const subSeason of Object.values(season.subSeasons)) {
                    if (subSeason.games) {
                        for (const game of Object.values(subSeason.games)) {
                            if (game.start != null && game.start < now && (game.homeScore == null || game.awayScore == null)) {
                                games.push(game)
                            }
                        }
                    }
                }
            }
        }
        games.sort(function(a, b) {
            if (a.start === b.start) 
                return 0;

            return a.start > b.start ? -1 : 1;
        });
        
        return (
            <div>
                {this.renderGameItems("Pending Games", games)}
            </div>
        );
    }

    renderReschedulingGames() {
        const {organizations, seasons} = this.state;
        
        let organization = Object.values(organizations)[0];
        let games = [];
        
        for (const season of Object.values(seasons)) {
            if (season.organizationId !== organization.id) {
                continue;
            }
            if (season.subSeasons) {
                for (const subSeason of Object.values(season.subSeasons)) {
                    if (subSeason.games) {
                        for (const game of Object.values(subSeason.games)) {
                            if (game.status === 'canceled' || game.status === 'delayed') {
                                games.push(game)
                            }
                        }
                    }
                }
            }
        }
        games.sort(function(a, b) {
            if (a.start === b.start) 
                return 0;

            return a.start > b.start ? -1 : 1;
        });
        
        return (
            <div>
                {this.renderGameItems("Rescheduling Games", games)}
            </div>
        );
    }

    generateSheet(games, id) {
        const {organizations, locations} = this.state;
        let organization = Object.values(organizations)[0];
        
        if (games.length <= 0) {
            alert("No today's games present.");
            return;
        }
        
        this.setState({
            url: null,
            progress: true
        });
        
        games.sort((a,b)=> {
            return a.start - b.start;
        })

        invokeHttpsApi('gamePdf-generateOrgGamesSheetPdf', {
            id: id,
            games: games,
            organization: organization,
            locations: locations,
        }, (data) => {

            if (data.succuess !== false) {
                getStorageUrl('pdf/' + id + '-sheet.pdf', (url)=> {
                    this.setState({
                        url: url
                    });
                })
            }
            
            this.setState({
                progress: false
            });
        })
        .catch((error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderGameItems(title, games) {
        const {organizations, progress, url} = this.state;
        let organization = Object.values(organizations)[0];
        
        let items = [];
        for (const val of games) {
            if (items.length > 10) {
                break;
            }
            items.push(
                <div key={val.id}>
                {this.renderGameItem(val)}
                </div>
            )
            items.push(<div className='divider' />);
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title'>
                    {title}: {games.length}
                </h3>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <Col xs={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Home
                            </h3>
                        </Col>
                        <Col xs={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Score
                            </h3>
                        </Col>
                        <Col xs={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Away
                            </h3>
                        </Col>
                        <Col xs={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Info
                            </h3>
                        </Col>
                        <Col xs={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Status
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    )}
                </div>
                {title === "Today's Games" && (
                    <div className='wrap-content-parent'>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        Today's {t('game_sheet_doc')}
                        </h4>
                        <div className='wrap-content-wrap-child'>
                            {progress ? (
                                <Spinner color="primary" />
                            ) : (
                                <Button className="btn-edit" onClick={e=> {
                                    this.generateSheet(games, organization.id);
                                }}>{t('generate')}</Button>
                            )}
                            {url && (
                            <a href={url} target="_blank" rel="noopener noreferrer">Open</a>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderGameItem(game) {
        const { teams, locations, users } = this.state;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : teamIcon;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : teamIcon;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toTimeString(game.start);
        
        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        let status = game.status ? game.status : 'scheduled'
        
        return (
            <Row style={{margin: 0}}>
                <Col xs={3} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={homeThumb} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {homeTeam.title}
                        </h4>
                    </div>
                </Col>
                <Col xs={1} className="vertical-center" style={{padding: 0}}>
                    <div style={{textAlign: 'center', color: '#333', textSize: 8}}>
                        {game.homeScore != null && game.awayScore != null && game.homeScore !== -1 && game.awayScore !== -1 ? (
                            <div><b>{game.homeScore} - {game.awayScore}</b></div>
                        ) : (<div><b># - #</b></div>)}
                    </div>
                </Col>
                <Col xs={3} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <h4 className='wrap-content-fill-child row-badge-title' style={{textAlign: 'right'}}>
                        {awayTeam.title}
                        </h4>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={awayThumb} />
                    </div>
                </Col>
                <Col xs={3} className="vertical-center" style={{padding: 0}}>
                    <div style={{textAlign: 'center', color: '#333', textSize: 8}}>
                        <div>{timeStr}, {day}/{month}</div>
                        <div>
                            <div className="vertical-center-spacing">
                                <GiSoccerField/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {location.title}
                            </div>
                            <div className="vertical-center-spacing">
                                <GiWhistle/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {referee.uid != null ? referee.username : t('no_referee')} - 
                            <b style={{textTransform: 'uppercase'}}>{status}</b>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} className="vertical-center" style={{padding: 0}}>
                    <div style={{textAlign: 'right', color: '#333', textSize: 8}}>
                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                            this.setState({gameChoice: game})
                        }}>{t('view')}</Button>
                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                            this.setState({refereeGame: game})
                        }}>{t('referee')}</Button>
                    </div>
                </Col>
            </Row>
        );
    }

    renderSeasons() {
        const {organizations, divisions, seasons} = this.state;
        let organization = Object.values(organizations)[0];
        let register = [];
        let finished = [];
        let ongoing = [];
        for (const val of Object.values(seasons)) {
            if (organization.id === val.organizationId && (val.status == null || val.status === STATUS_REGISTER)) {
                register.push(val)
            }
            if (organization.id === val.organizationId && val.status === STATUS_FINISHED) {
                finished.push(val)
            }
            if (organization.id === val.organizationId && val.status === STATUS_ONGOING) {
                ongoing.push(val)
            }
        }
        register.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        finished.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        ongoing.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        let items = [];
        for (const val of register) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationSeasonRow
                    organization={organization}
                    divisions={divisions}
                    key={val.id}
                    season={val}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/divisions/' + val.divisionId);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of ongoing) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationSeasonRow
                    organization={organization}
                    divisions={divisions}
                    key={val.id}
                    season={val}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/divisions/' + val.divisionId);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of finished) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationSeasonRow
                    organization={organization}
                    divisions={divisions}
                    key={val.id}
                    season={val}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/divisions/' + val.divisionId);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title'>
                    Seasons: {register.length} in register,  {ongoing.length} ongoing,  {finished.length} finished
                </h3>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Division
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Season
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Games
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('teams')}
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Start
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Status
                            </h3>
                        </Col>
                        <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTeams() {
        const {organizations, teams, seasons} = this.state;
        let organization = Object.values(organizations)[0];
        let pending = [];
        let invited = [];
        let members = [];
        for (const val of Object.values(teams)) {
            if (organization.pendingIds != null && Object.values(organization.pendingIds).includes(val.id)) {
                pending.push(val)
            }
            if (organization.invitedIds != null && Object.values(organization.invitedIds).includes(val.id)) {
                invited.push(val)
            }
            if (organization.teamIds != null && Object.values(organization.teamIds).includes(val.id)) {
                members.push(val)
            }
        }
        pending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        invited.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        let items = [];
        for (const val of pending) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationTeamRow
                    organization={organization}
                    key={val.id}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of invited) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationTeamRow
                    organization={organization}
                    key={val.id}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        /*for (const val of members) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationTeamRow
                    organization={organization}
                    key={val.id}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/teams/' + val.id);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }*/

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title'>
                    Teams Needed Actions: {pending.length} pending,  {invited.length} invited, members: {members.length}
                </h3>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <Col lg={5} xs={7} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Players
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                Seasons
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPlayers() {
        const {organizations, teams, users, profileData} = this.state;
        let organization = Object.values(organizations)[0];
        let pending = [];
        let denied = [];
        let members = [];
        for (const val of Object.values(profileData)) {
            if (val.verified == null) {
                pending.push(val)
            }
            if (val.verified === false) {
                denied.push(val)
            }
            if (val.verified === true) {
                members.push(val)
            }
        }
        pending.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        denied.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        members.sort(function(a, b) {
            return b.playerName - a.playerName;
        });

        let items = [];
        for (const val of pending) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationUserRow
                    organization={organization}
                    profileData={val}
                    teams={teams}
                    users={users}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/player/' + val.id);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of denied) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationUserRow
                    organization={organization}
                    profileData={val}
                    teams={teams}
                    users={users}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/player/' + val.id);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        /*for (const val of members) {
            if (items.length >= 10) {
                break;
            }
            items.push(
                <OrganizationUserRow
                    organization={organization}
                    profileData={val}
                    teams={teams}
                    users={users}
                    onClickDetail={()=>{
                        this.props.router.navigate('/organizer/register/player/' + val.id);
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }*/

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title'>
                    Player Profiles Pending: {pending.length} pending,  {denied.length} denied, members: {members.length}
                </h3>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <Col lg={4} xs={5} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                Player
                            </h3>
                        </Col>
                        <Col lg={4} xs={5} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Eligibility
                            </h3>
                        </Col>
                        <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTransactions() {
        const {income} = this.state;

        const incomeArr = [];
        if (income) {
            for (const item of Object.values(income)) {
                incomeArr.push(item);
            }
        }
        incomeArr.sort((a, b) => {
            return a.created - b.created;
        })

        const items = [];
        for (const item of incomeArr) {
            items.push(<div key={item.id}>{this.renderTransactionItem(item)}</div>)
        }

        return (
            <div>
                <div className='shadow-tile-table'>
                    <h3 className='row-table-title'>
                        Online Payments for Season Payments: {items.length}
                    </h3>
                    <Row style={{margin: 0}}>
                        <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Payment
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Date
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {income ? t('no_items_to_show') : "Not Loaded Payments"}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderTransactionItem(transaction) {
        const {seasons, divisions, seasonPayments, fundraisePayments, users} = this.state;

        let user = {};
        let season = {};
        let division = {};
        let seasonPayment = null;
        let subView = <div/>;
        let subView2 = <div/>;

        for (const item of Object.values(seasonPayments)) {
            if (item.id === transaction.id) {
                seasonPayment = item;
                season = seasons[item.seasonId];
                if (season) {
                    division = divisions[season.divisionId];
                }
                user = users[item.userId ? item.userId : item.uid];
                break;
            }
        }
        let walletPayment = null;
        for (const item of Object.values(fundraisePayments)) {
            if (item.id === transaction.id) {
                walletPayment = item;
                user = users[item.userId ? item.userId : item.uid];
                break;
            }
        }
        const userThumb = user.photoUri ? user.photoUri : userIcon;
        const seasonThumb = division.thumbUri ? division.thumbUri : appIcon;
        const seasonTitle = season.title + " - " + division.title;

        if (seasonPayment) {
            subView = (
                <div className='wrap-content-parent'>
                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                    <h4 className='label label-default one-line-title'>
                    {seasonPayment.role && seasonPayment.role.toUpperCase()}
                    </h4>
                    </div>
                    <img className="wrap-content-wrap-child" alt='season-icon' src={seasonThumb} style={{width: 20, height: 20, borderRadius: 10, marginRight: 5}}/>
                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                    <div className='label label-default one-line-title'>
                    {seasonTitle}
                    </div>
                    </div>
                </div>
            );
            subView2 = (
                <div className='wrap-content-parent'>
                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                    <div className='label label-default one-line-title'>
                    {toDateTimeString(transaction.created * 1000)}
                    </div>
                    </div>
                </div>
            );
        }
        if (walletPayment) {
            subView = (
                <div className='wrap-content-parent'>
                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                    <h4 className='label label-default one-line-title'>
                    {walletPayment.type && walletPayment.type.toUpperCase()}
                    </h4>
                    </div>
                    <img className="wrap-content-wrap-child" alt='season-icon' src={seasonThumb} style={{width: 20, height: 20, borderRadius: 10, marginRight: 5}}/>
                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                    <div className='label label-default one-line-title'>
                    {t('wallet_payment')}
                    </div>
                    </div>
                </div>
            );
            subView2 = (
                <div className='wrap-content-parent'>
                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                    <div className='label label-default one-line-title'>
                    {toDateTimeString(transaction.created * 1000)}
                    </div>
                    </div>
                </div>
            );
        }
        return (
            <TableRowSmall 
                key={transaction.id}
                title={user.username + '$' + (transaction.amount / 100) + " / App Fee: $" + (transaction.application_fee_amount / 100)}
                icon={userThumb}
                subView={subView}
                subView2={subView2} />
        );
    }

    renderContents() {
        const {user} = this.props;
        const {organizations, divisions, seasons, refereeIds, profileData} = this.state;
        if (Object.values(organizations).length > 0) {
            let organization = Object.values(organizations)[0];
            const locationCount  = organization.locationIds != null ? Object.values(organization.locationIds).length : 0;
            const teamCount  = organization.teamIds != null ? Object.values(organization.teamIds).length : 0;
            let verifiedCount = 0;
            let playerCount = Object.values(profileData).length;
            for (const value of Object.values(profileData)) {
                verifiedCount += (value.verified === true ? 1 : 0);
            }
    
            if (user == null) {
                return <div/>
            }

    
            return (
            <div>
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <OrganizationBadge 
                        organization={organization}
                        uid={user.uid}
                        onClickEdit={()=> this.setState({editOrganization: true})}
                        onClickView={()=> {
                            const homeLink = getOrgDomain(organization);
                            this.props.router.navigate(homeLink);
                        }}
                        onSaveDone={()=> {
                            this.loadContents()
                        }} />
                </div>
                <div style={{marginTop: 10}}>
                    <div style={{textAlign: 'center'}}>
                        <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                            <InfoView title='DIVISIONS' icon={leaguesIcon} number={Object.values(divisions).length} />
                        </div>
                        <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                            <InfoView title='SEASONS' icon={leaguesIcon} number={Object.values(seasons).length} />
                        </div>
                        <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                            <InfoView title='VENUES' icon={venueIcon} number={locationCount} />
                        </div>
                        <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                            <InfoView title='TEAMS' icon={teamIcon} number={teamCount} />
                        </div>
                        <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                            <InfoView title='REFEREES' icon={stuffIcon} number={refereeIds.length} />
                        </div>
                        <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                            <InfoView title='PLAYERS' icon={playersIcon} number={verifiedCount} secNumber={playerCount} />
                        </div>
                    </div>
                </div>
                {this.renderInstructions()}
                {this.renderGames()}
                {this.renderSeasons()}
                {this.renderPaymentSettings()}
                {this.renderTransactions()}

                <Row style={{
                    margin: 0,
                }}>
                    <Col xl={6} lg={12}>
                        {this.renderTeams()}
                    </Col>
                    <Col xl={6} lg={12}>
                        {this.renderPlayers()}
                    </Col>
                </Row>
            </div>
            );
        } else {
            return (
            <div>
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <OrganizationEdit 
                        createMode={true} 
                        uid={user.uid}
                        onSaveDone={()=> {
                            this.loadContents()
                        }} />
                </div>
            </div>
            );
        }
    }

    render() {
        const {history, organizationSubscription, freeOrganization} = this.props;
        const {organizations, seasons, divisions, locations, users, teams, posts, editOpen, membershipPopup, editOrganization, loading, gameChoice, refereeGame} = this.state;

        let organization = Object.values(organizations)[0]
        const showPopup = freeOrganization && !organizationSubscription;

        return (
            <div>
                
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                        {showPopup && (
                        <Modal isOpen={showPopup && membershipPopup}>
                            <ModalBody style={{padding: 0}}>
                                <OrganizationPopUp
                                    history={history}
                                    countDown={freeOrganization.current_period_end}
                                    onClickClose={e=> {
                                        this.setState({membershipPopup: false})
                                    }} />
                            </ModalBody>
                        </Modal>
                        )}
                        <Modal isOpen={editOpen}>
                            <ModalBody>
                            
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={gameChoice != null}>
                            <ModalBody>
                            {gameChoice && (
                                <GameDetailBadge
                                    game={gameChoice}
                                    organization={organization}
                                    seasons={seasons}
                                    divisions={divisions}
                                    locations={locations}
                                    users={users}
                                    teams={teams}
                                    posts={posts}
                                    onChanged={(game)=>  {
                                        seasons[game.seasonId].subSeasons[game.subSeasonId].games[game.id] = game;
                                        this.setState({seasons: seasons});
                                    }}
                                    onClickClose={() => {
                                        this.setState({gameChoice: null})
                                    }} />
                            )}
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={refereeGame != null}>
                            <ModalBody>
                            {refereeGame && (
                                <GameEdit 
                                    game={refereeGame}
                                    organization={organization}
                                    locations={locations}
                                    division={divisions[refereeGame.divisionId]}
                                    season={seasons[refereeGame.seasonId]}
                                    teams={teams}
                                    posts={posts}
                                    users={users} 
                                    onChanged={(game)=>  {
                                        seasons[game.seasonId].subSeasons[game.subSeasonId].games[game.id] = game;
                                        this.setState({seasons: seasons});
                                    }}
                                    onClickClose={()=>{
                                        this.setState({refereeGame: null});
                                    }} />
                            )}
                            </ModalBody>
                        </Modal>
                        <Modal isOpen={editOrganization}>
                            <ModalBody>
                                <OrganizationEdit 
                                    organization={organization}
                                    isOpen={editOrganization}
                                    onSaveDone={()=> {
                                        this.loadContents()
                                        this.setState({editOrganization: false})
                                    }}
                                    onSaveSuccess={() => {
                                        this.setState({editOrganization: false})
                                        this.loadContents();
                                    }}
                                    onCloseClick={() => {
                                        this.setState({editOrganization: false})
                                    }} />
                            </ModalBody>
                        </Modal>
                        {this.renderFaq()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(DashboardPage));
export { connectedApp as DashboardPage };
