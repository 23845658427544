import { filterConstants } from '../_constants';

let seasonId = localStorage.getItem('seasonId');
let divisionId = localStorage.getItem('divisionId');
let teamId = localStorage.getItem('teamId');
const initialState = {
    filter: {
        seasonId: seasonId === 'null' ? null : seasonId,
        divisionId: divisionId === 'null' ? null : divisionId,
        teamId: teamId === 'null' ? null : teamId,
    },
    dict: {},
};

const getDictUpdate = (original, update) => {
    const dict = {};
    for (const [key, val] of Object.entries(original)) {
        dict[key] = val;
    }
    if (!dict.organizations) {
        dict.organizations = {};
    }
    if (update.organizations) {
        for (const [key, val] of Object.entries(update.organizations)) {
            dict.organizations[key] = val;
        }
    }
    if (!dict.following) {
        dict.following = {};
    }
    if (update.following) {
        for (const [key, val] of Object.entries(update.following)) {
            dict.following[key] = val;
        }
    }
    if (!dict.divisions) {
        dict.divisions = {};
    }
    if (update.divisions) {
        for (const [key, val] of Object.entries(update.divisions)) {
            dict.divisions[key] = val;
        }
    }
    if (!dict.seasons) {
        dict.seasons = {};
    }
    if (update.seasons) {
        for (const [key, val] of Object.entries(update.seasons)) {
            dict.seasons[key] = val;
        }
    }
    if (!dict.users) {
        dict.users = {};
    }
    if (update.users) {
        for (const [key, val] of Object.entries(update.users)) {
            dict.users[key] = val;
        }
    }
    if (!dict.profileData) {
        dict.profileData = {};
    }
    if (update.profileData) {
        for (const [key, val] of Object.entries(update.profileData)) {
            dict.profileData[key] = val;
        }
    }
    if (!dict.profileData) {
        dict.profileData = {};
    }
    if (update.profileData) {
        for (const [key, val] of Object.entries(update.profileData)) {
            dict.profileData[key] = val;
        }
    }
    if (!dict.teams) {
        dict.teams = {};
    }
    if (update.teams) {
        for (const [key, val] of Object.entries(update.teams)) {
            dict.teams[key] = val;
        }
    }
    if (!dict.locations) {
        dict.locations = {};
    }
    if (update.locations) {
        for (const [key, val] of Object.entries(update.locations)) {
            dict.locations[key] = val;
        }
    }
    if (!dict.category) {
        dict.category = {};
    }
    if (update.category) {
        for (const [key, val] of Object.entries(update.category)) {
            dict.category[key] = val;
        }
    }
    if (!dict.posts) {
        dict.posts = {};
    }
    if (update.posts) {
        for (const [key, val] of Object.entries(update.posts)) {
            dict.posts[key] = val;
        }
    }
    if (!dict.cards) {
        dict.cards = {};
    }
    if (update.cards) {
        for (const [key, val] of Object.entries(update.cards)) {
            dict.cards[key] = val;
        }
    }
    if (!dict.fundraises) {
        dict.fundraises = {};
    }
    if (update.fundraises) {
        for (const [key, val] of Object.entries(update.fundraises)) {
            dict.fundraises[key] = val;
        }
    }
    if (!dict.fundraisePayments) {
        dict.fundraisePayments = {};
    }
    if (update.fundraisePayments) {
        for (const [key, val] of Object.entries(update.fundraisePayments)) {
            dict.fundraisePayments[key] = val;
        }
    }
    return dict;
}

export function filterData(state = initialState, action) {
    switch (action.type) {
        case filterConstants.UPDATE_FILTER:
            return {
                ...state, 
                filter: {
                    seasonId: action.filter.seasonId,
                    divisionId: action.filter.divisionId,
                    teamId: action.filter.teamId,
                    status: action.filter.status,
                },
            };
        case filterConstants.UPDATE_DICT:
            return {
                ...state, 
                dict: getDictUpdate(state.dict, action.dict),
            };
        case filterConstants.CLEAR_DICT:
            return {
                ...state, 
                dict: {},
            };
        default:
            return state
    }
}