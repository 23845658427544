import React, { Component } from "react";
import { Button } from "reactstrap";

export class ContactUs extends Component {
    
    render() {
        return (
            <div>
                <div className="main-contacts">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="section-title">
                                        <h2>Get In Touch</h2>
                                        <p>
                                            Please fill out the form below to send us an email and we
                                            will get back to you as soon as possible.
                                        </p>
                                    </div>
                                    <form name="sentMessage" id="contactForm" noValidate>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        required="required"
                                                    />
                                                    <p className="help-block text-danger"></p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        required="required"
                                                    />
                                                    <p className="help-block text-danger"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <textarea
                                                name="message"
                                                id="message"
                                                className="form-control"
                                                rows="4"
                                                placeholder="Message"
                                                required
                                            ></textarea>
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div id="success"></div>
                                        <Button type="submit" className="btn-coral">
                                            Send Message
                                        </Button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-3 offset-md-1 contact-info">
                                <div className="contact-item">
                                    <h3>ContactUs Info</h3>
                                    <p>
                                        <span>
                                            <i className="fa fa-map-marker"></i> Address
                                        </span>
                                        {this.props.data ? this.props.data.address : "loading"}
                                    </p>
                                </div>
                                <div className="contact-item">
                                    <p>
                                        <span>
                                            <i className="fa fa-envelope-o"></i> Email
                                        </span>{" "}
                                        {this.props.data ? this.props.data.email : "loading"}
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="social">
                                        <ul>
                                            <li>
                                                <a
                                                    href={this.props.data ? this.props.data.facebook : "/"}
                                                >
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href={this.props.data ? this.props.data.instagram : "/"}>
                                                    <i className="fa fa-instagram"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    <div className="container text-center">
                        <p>
                            &copy; copyright 2021. All Rights Reserved.
                            <a href="/terms-of-services" rel="nofollow">
                            Live! Inc
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUs;
