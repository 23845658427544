import React, { Component } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PromoCodeHeader from 'components/security/PromoCodeHeader';
import PromoCodeEdit from 'components/security/PromoCodeEdit';

import { 
    STRIPE_SECRET_KEY,
} from 'utils/Utils';

export default class AdminPromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coupon: {},
            codeChoice: {},
            promoCodes: [],
            coupons: [],
            customers: [],
            editOpen: false,
            detailOpen: false
        };
    }

    componentDidMount() {
        this.loadCoupons();
        this.saveCode = this.saveCode.bind(this);
    }

    loadCoupons() {
        let headers = {"Content-Type": "application/json"};
        headers["Authorization"] = `Bearer ${STRIPE_SECRET_KEY}`;
        fetch("https://api.stripe.com/v1/coupons", {
            headers,
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({coupons: result.data})
            }
        )
        fetch("https://api.stripe.com/v1/promotion_codes", {
            headers,
        })
        .then(res => res.json())
        .then(
            (result) => {
                const promoCodes = [];
                if (result.data != null) {
                    for (const value of result.data) {
                        if (value.active) {
                            promoCodes.push(value)
                        }
                    }
                }
                this.setState({promoCodes: promoCodes})
            }
        )
        fetch("https://api.stripe.com/v1/customers", {
            headers,
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({customers: result.data})
            }
        )
    }

    saveCode(coupon) {
        if (coupon.name == null || coupon.name === '') {
            alert('You must input name of coupon');
            return;
        }
        if (coupon.duration === 'repeating' && coupon.duration_in_months == null) {
            alert('You must input duration in months for repeating coupoon');
            return;
        }
        if (coupon.duration === 'repeating' && (coupon.duration_in_months <= 0 || coupon.duration_in_months > 10)) {
            alert('You must input duration in months for repeating coupoon range of 0 to 10 months');
            return;
        }
        if (coupon.id != null) {
            this.editCode(coupon);
        } else {
            this.createCode(coupon);
        }
    }

    createCode(coupon) {
        let headers = {"Content-Type": "application/x-www-form-urlencoded"};
        headers["Authorization"] = `Bearer ${STRIPE_SECRET_KEY}`;

        let data = {};
        data.name = coupon.name;
        data.percent_off = coupon.percent_off;
        data.duration = coupon.duration;
        if (coupon.duration_in_months != null) {
            data.duration_in_months = coupon.duration_in_months;
        }

        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://api.stripe.com/v1/coupons", {
            method: 'POST', headers: headers, body: formBody
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.createPromoCode(result.id);
            }
        )
    }

    createPromoCode(id) {
        let headers = {"Content-Type": "application/x-www-form-urlencoded"};
        headers["Authorization"] = `Bearer ${STRIPE_SECRET_KEY}`;

        let data = {};
        data.coupon = id;

        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://api.stripe.com/v1/promotion_codes", {
            method: 'POST', headers: headers, body: formBody
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.loadCoupons();
            }
        )
    }

    editCode(coupon) {
        let headers = {"Content-Type": "application/x-www-form-urlencoded"};
        headers["Authorization"] = `Bearer ${STRIPE_SECRET_KEY}`;

        let data = {};
        data.name = coupon.name;

        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        fetch("https://api.stripe.com/v1/coupons/" + coupon.id, {
            method: 'POST', headers: headers, body: formBody
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.loadCoupons();
            }
        )
    }

    deleteCode(coupon) {
        let headers = {"Content-Type": "application/x-www-form-urlencoded"};
        headers["Authorization"] = `Bearer ${STRIPE_SECRET_KEY}`;

        fetch("https://api.stripe.com/v1/coupons/" + coupon.id, {
            method: 'DELETE', headers: headers
        })
        .then(res => res.json())
        .then(
            (result) => {
                this.loadCoupons();
            }
        )
    }

    render() {
        const {promoCodes, codeChoice, editOpen} = this.state;

        return (
            <div className='main-page' style={{height: '100vh'}}>
                
                <div style={{marginTop: 10}} className='wrap-content-fill-child'>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <div className='wrap-content-fill-child'>
                            <h3 className='label label-default title-action-main vertical-center-spacing'>
                            Active Promo Codes
                            </h3>
                        </div>
                        <Button onClick={e=>{
                            this.setState({
                                codeChoice: {},
                                editOpen: true
                            })
                        }}>+</Button>
                    </div>
                    <div>
                        {promoCodes.map((code) => (
                            <PromoCodeHeader 
                                key={code.code}
                                code={code}
                                onClickDelete={() => {
                                    if (window.confirm('This will delete Coupon of this promo code and deactivate this code from action. Are you sure you want to delete this coupon?')) {
                                        this.deleteCode(code.coupon);
                                    }
                                }}
                                onClickEdit={() => {
                                    this.setState({
                                        codeChoice: code,
                                        editOpen: true
                                    })
                                }} />
                        ))}
                    </div>
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <PromoCodeEdit 
                            coupon={codeChoice.coupon != null ? codeChoice.coupon : {duration: 'forever'}} 
                            isOpen={editOpen}
                            onEditClick={(coupon) => {
                                this.saveCode(coupon);
                                this.setState({editOpen: false})
                            }}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}