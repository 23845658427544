import React, { Component } from "react";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import {
    Button,
} from 'reactstrap';
import { t } from 'i18next';

class RegisterPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            wallet: {},
            walletPayments: {},
            users: {},
            balanceInput: 0,
            balanceType: 'direct'
        }
        this.timer = 0;
        this.countDown = this.countDown.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(this.countDown, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    countDown() {
        this.setState({curTime: (new Date().valueOf())})
    }
    
    render() {
        const {user, loading, refreshTime, onClickRegister, onClickLogin } = this.props;
        const {curTime} = this.state;

        if (loading) {
            return <div/>
        }
        if (curTime - refreshTime > 15000) {
            return <div/>
        }
        if (user != null) {
            return (
                <div className="org-register-layout text-center">
                    <h4 className="org-register-title">
                        {t('org_register_title')}
                    </h4>
                    <Button className="btn-cancel" style={{marginBottom: 15}} onClick={e=> {
                        if (onClickRegister) {
                            onClickRegister();
                        }
                    }}>{t('register')}</Button>
                </div>
            );
        } else {
            return (
                <div className="org-register-layout text-center">
                    <h4 className="org-register-title">
                        {t('org_login_title')}
                    </h4>
                    <Button className="btn-cancel" style={{marginBottom: 15}} onClick={e=> {
                        if (onClickLogin) {
                            onClickLogin();
                        }
                    }}>{t('login')}</Button>
                </div>
            );
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = connect(mapState, actionCreators)(RegisterPrompt);
export { connectedNavigation as RegisterPrompt };
