import React, { Component } from "react";
import {
    CardImg,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import defaultImage from 'assets/img/icons/video_default.png';
import ClubLessonDetailsView from 'components/detail/club/ClubLessonDetailsView';

export class ClubWorkoutDetailsView extends Component {
    constructor(props) {
        super(props);

        const {clubWorkout, clubLessons} = props;

        const lessonId = clubWorkout.lessonIds != null ? Object.values(clubWorkout.lessonIds)[0] : "";
        let lessonChoice = clubLessons[lessonId];
        if (lessonChoice == null) {
            lessonChoice = {};
        }

        this.state = {
            value: 0,
            lessonChoice: lessonChoice
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, value) {
        this.setState({value: value});
    }

    renderContents() {
        const {clubWorkout, clubLessons, onCloseClick} = this.props;
        const {lessonChoice} = this.state;

        const items = [];

        for (const [key, value] of Object.entries(clubLessons)) {
            if (clubWorkout.lessonIds != null && Object.values(clubWorkout.lessonIds).includes(key)) {
                items.push(
                    <option value={key}>{value.title}</option>
                );
            }
        }

        return (
            <div>
                <FormGroup>
                    <Label for="typeChoice">Select Lesson: {lessonChoice.title}</Label>
                    <Input type="select" name="select" id="typeChoice" onChange={e=> {
                        let lessonChoice = clubLessons[e.target.value];
                        if (lessonChoice == null) {
                            lessonChoice = {};
                        }
                        this.setState({lessonChoice: lessonChoice});
                    }}>
                        {items}
                    </Input>
                </FormGroup>
                <ClubLessonDetailsView
                    categorys={{}} 
                    clubLesson={lessonChoice} 
                    onCloseClick={() => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }} />
            </div>
        );
    }

    render() {
        const {clubWorkout} = this.props;
        
        let challengeImage = clubWorkout.iconUri != null ? clubWorkout.iconUri : defaultImage;
        
        if (clubWorkout != null) {
            return (
                <div>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {clubWorkout.title}
                            </h4>
                            <div>Lessons: {clubWorkout.lessonIds != null ? Object.values(clubWorkout.lessonIds).length : 0}</div>
                        </div>
                    </div>
                    
                    {this.renderContents()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ClubWorkoutDetailsView;