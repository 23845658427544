import React, { Component } from "react";

import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import {GroupRoundGames} from "components/detail/organization/games/GroupRoundGames";
import {LeagueRoundGames} from "components/detail/organization/games/LeagueRoundGames";
import {KnockoutRoundGames} from "components/detail/organization/games/KnockoutRoundGames";
import { getSeasonRoundsArr, checkProfileData } from 'utils/Utils';
import { mainRedColor } from 'style/styles';
import { 
    MdError,
    MdWarning,
} from 'react-icons/md';
import { t } from "i18next";
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, ROUND_TYPE_LEAGUE, STATUS_ONGOING } from "utils/constants";

export class OrganizationSeasonGames extends Component {
    constructor(props) {
        super(props);
        const seasonChoice = props.seasonChoice;
        
        let choice = null;
        for (const item of Object.values(getSeasonRoundsArr(seasonChoice))) {
            if (item.status === STATUS_ONGOING) {
                choice = item;
                break;
            }
        }
        if (choice == null && getSeasonRoundsArr(seasonChoice).length > 0) {
            choice = getSeasonRoundsArr(seasonChoice)[0];
        }
        if (choice == null) {
            choice = {};
        }
        this.state = {
            gameChoice: {},
            gamesChoice: choice,
            matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1},
            editTeamData: false,
            editLocation: false,
            progress: false,
            imageProgress: 0,
            windowWidth: window.innerWidth,
        };
    }
    
    componentDidUpdate(prevProps) {
        const {seasonChoice} = this.props;
        if (seasonChoice !== prevProps.seasonChoice) {
            
            let choice = null;
            for (const item of Object.values(getSeasonRoundsArr(seasonChoice))) {
                if (item.status === STATUS_ONGOING) {
                    choice = item;
                    break;
                }
            }
            if (choice == null && getSeasonRoundsArr(seasonChoice).length > 0) {
                choice = getSeasonRoundsArr(seasonChoice)[0];
            }
            if (choice == null) {
                choice = {};
            }
            this.setState({
                gameChoice: {},
                gamesChoice: choice,
                matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1},
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }

    renderGameWarning(game) {
        const {divisions, seasonChoice, profileData, users, teams} = this.props;
        const division = divisions[seasonChoice.divisionId];

        if (game.gameList == null || Object.values(game.gameList).length < 2) {
            let str =' ' + t('for_both_of_teams')
            if (game.gameList != null) {
                let homeReady = game.gameList[game.homeTeam] != null;
                let awayReady = game.gameList[game.awayTeam] != null;
                if (homeReady) {
                    str = teams[game.awayTeam] != null ? 'for ' + teams[game.awayTeam].title : ' for away team.';
                }
                if (awayReady) {
                    str = teams[game.homeTeam] != null ? 'for ' + teams[game.homeTeam].title : ' for home team.';
                }
            }
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdError/> </span>
                    {t('player_list_not_ready')} {str}
                </div>
            )
        }
        let homeValid = true;
        for (const [key,] of Object.entries(game.gameList[game.homeTeam])) {
            const user = users[key];
            const team = teams[game.homeTeam];
            if (user == null || checkProfileData(division, user, team, profileData) !== 'verified') {
                homeValid = false;
                break;
            }
        }
        let awayValid = true;
        for (const [key,] of Object.entries(game.gameList[game.awayTeam])) {
            const user = users[key];
            const team = teams[game.awayTeam];
            if (user == null || checkProfileData(division, user, team, profileData) !== 'verified') {
                awayValid = false;
                break;
            }
        }
        if (homeValid && awayValid) {
            return <div/>;
        } else {
            let str =' ' + t('for_both_of_teams')
            if (homeValid) {
                str = teams[game.awayTeam] != null ? 'for ' + teams[game.awayTeam].title : ' for away team.';
            }
            if (awayValid) {
                str = teams[game.homeTeam] != null ? 'for ' + teams[game.homeTeam].title : ' for home team.';
            }
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdWarning/> </span>
                    {t('player_list_not_verified')} {str}
                </div>
            )
        }
    }

    renderGames() {
        const {divisions, posts, organization, teams, users, locations, seasonChoice, cards} = this.props;
        const {gamesChoice, editOpen, detailOpen, matchDayChoice} = this.state;
        const division = divisions[seasonChoice.divisionId];
        
        let gameArr = [];
        if (gamesChoice.games != null) {
            for (const item of Object.values(gamesChoice.games)) {
                if (matchDayChoice.value === -1 || matchDayChoice.value === item.matchDayIndex) {
                    gameArr.push(item);
                }
            }
        }
        let gameDays = {};
        if (gamesChoice.matchDayCount != null) {
            const val = {id: t('all_matchdays'), title: t('all_matchdays'), value: -1}
            gameDays[t('all_matchdays')] = val;
            for (var i = 0; i < gamesChoice.matchDayCount; i ++) {
                const title = t('matchday') + " " +(i + 1).toString();
                const day = {id: title, title: title, value: i}
                gameDays[title] = day;
            }
        }

        gameArr.sort(function(a, b) {
            return a.start - b.start;
        })
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (seasonChoice.teamIds != null && Object.values(seasonChoice.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        let roundContent = (<div/>)
        if (gamesChoice.type === ROUND_TYPE_KNOCKOUT) {
            roundContent = (
                <KnockoutRoundGames
                    season={seasonChoice}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    gameDays={gameDays}
                    users={users}
                    posts={posts}
                    cards={cards}
                    detailMode={true} />
            )
        } else if (gamesChoice.type === ROUND_TYPE_GROUP) {
            roundContent = (
                <GroupRoundGames
                    season={seasonChoice}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    users={users}
                    posts={posts}
                    cards={cards}
                    detailMode={true} />
            )
        } else if (gamesChoice.type === ROUND_TYPE_LEAGUE) {
            roundContent = (
                <LeagueRoundGames
                    season={seasonChoice}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    gameDays={gameDays}
                    users={users}
                    posts={posts}
                    cards={cards}
                    detailMode={true} />
            )
        }

        return (
        <div className="container" style={{padding: 0}}>
            <div style={{marginTop: 20}}>
                <ItemsDrowpdownNav
                    choiceTitle={t('choose_round')}
                    title={t('season_rounds')}
                    dropDownArray={getSeasonRoundsArr(seasonChoice)}
                    editOpen={editOpen}
                    detailOpen={detailOpen}
                    detailItem={gamesChoice}
                    onItemChoice={(gamesChoice)=>{
                        this.setState({gamesChoice: gamesChoice, detailOpen: false, editOpen: false})
                    }}
                    onClickBack={()=>{
                        this.setState({gamesChoice: {}, detailOpen: false, editOpen: false})
                    }} />
            </div>
            {roundContent}
        </div>);
    }

    render() {
        return (
            <div style={{padding: 0, marginTop: 10}}>
                {this.renderGames()}
            </div>
        );
    }
}

export default OrganizationSeasonGames;