import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker, } from '@react-google-maps/api';
import {
    Card, 
} from 'reactstrap';

const containerStyle = {
    width: '100%',
    height: '400px'
};
  
export class ProgramLocationsView extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            progress: false
        };
    }

    render() {
        const {program, locations} = this.props;
        
        let arr = [];
        for (const val of Object.values(locations)) {
            if (program.locationIds != null && Object.values(program.locationIds).includes(val.id)) {
                arr.push({
                    title: val.title,
                    point: {lat: val.lat, lng: val.lng}
                })
            }
        }
        let center = arr.length > 0 ? arr[0].point : {lat: 43.179387, lng: -79.248433};
        
        return (
            <Card className="shadow-tile-i" style={{padding: '10px 20px'}}>
                <div className='wrap-content-parent' style={{padding: '10px 0px'}}>
                    <h3 className='wrap-content-wrap-child' style={{fontSize: 16, marginRight: 15}}>Description:</h3>
                    <div className='wrap-content-fill-child' style={{fontSize: 14}}>{program.description}</div>
                </div>
                <div className='divider'/>
                <div className='wrap-content-parent' style={{padding: '10px 0px'}}>
                    <h3 className='wrap-content-wrap-child' style={{fontSize: 14}}>Division Locations:</h3>
                    <div className='wrap-content-fill-child'>{arr.length === 0 ? "None" : ""}</div>
                </div>
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        >
                        {arr.map((val,idx)=>(
                            <Marker key={idx} title={val.title} position={val.point} >
                            </Marker>
                        ))}
                    </GoogleMap>
                </LoadScript>
            </Card>
        );
    }
}

export default ProgramLocationsView;