import { AuthForm, STATE_LOGIN, STATE_SIGNUP } from 'components/auth/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { withRouter } from 'utils/Routher';

class AuthPage extends React.Component {
    handleAuthState = authState => {
        if (authState === STATE_LOGIN) {
            this.props.router.navigate('/login');
        } else {
            this.props.router.navigate('/signup');
        }
    };

    handleLogoClick = () => {
        this.props.router.navigate('/');
    };

    render() {
        const forMembership= this.props.router.location.pathname.includes('membership_login');
        const forSignUp= this.props.router.location.pathname.includes('/signup');
        return (
            <Row
                style={{
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 0
                }}>
                <Col md={6} lg={4}>
                    <Card body>
                        <AuthForm
                            forMembership={forMembership}
                            authState={forSignUp ? STATE_SIGNUP : STATE_LOGIN}
                            onChangeAuthState={this.handleAuthState}
                            onLogoClick={this.handleLogoClick}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default withRouter(AuthPage);
