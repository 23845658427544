import React, { Component } from 'react';
import ClubScheduleHeader from 'components/detail/club/ClubScheduleHeader';
import ClubScheduleEdit from 'components/edit/club/ClubScheduleEdit';
import ClubScheduleDetailView from 'components/detail/club/ClubScheduleDetailView';
import { GrSchedules } from 'react-icons/gr';

import { 
    Button,
    FormGroup,
    Input,
    Modal, 
    ModalBody,
} from 'reactstrap';
import { t } from 'i18next';
import { listenDbQuery } from 'utils/API';

export class ClubSchedulesView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            users: {},
            clubSchedules: {},
            detailChoice: {},
            clubChallenges: {},
            detailOpen: false,
            schedulePast: false,
            editOpen: false,
        };
    }

    componentDidMount() {
        const { club } = this.props;
        listenDbQuery('/clubSchedule', "clubId", club.id, data => {
            var clubSchedules = {};
            var {detailChoice} = this.state;

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    clubSchedules[key] = value;
                }
            }
            if (detailChoice.id != null) {
                detailChoice = clubSchedules[detailChoice.id];
            }

            this.setState({
                clubSchedules: clubSchedules,
                detailChoice: detailChoice
            });
        });
    }

    renderContents() {
        const { uid, club } = this.props;
        const { clubSchedules } = this.state;

        const past = [];
        const pastArr = [];
        const now = new Date().valueOf();
    
        for (const[, value] of Object.entries(clubSchedules)) {
    
            if (value.clubId === club.id) {
                if (value.timestamp < now) {
                    past.push(value);
                }
            }
        }
        past.sort((a,b)=>{
            return b.timestamp - a.timestamp;
        })
        for (const item of past) {
            pastArr.push(
                <ClubScheduleHeader 
                    key={item.id}
                    uid={uid}
                    club={club}
                    schedule={item}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: item,
                            detailOpen: true
                        })
                    }}
                    onClickEdit={() => {
                        this.setState({
                            detailChoice: item,
                            editOpen: true
                        })
                    }} />
            );
        }

        return pastArr;
    }

    renderUpcomingContents() {
        const { uid, club } = this.props;
        const { clubSchedules } = this.state;

        const upcoming = [];
        const upcomingArr = [];
        const now = new Date().valueOf();
    
        for (const[, value] of Object.entries(clubSchedules)) {
    
            if (value.clubId === club.id) {
                if (value.timestamp > now) {
                    upcoming.push(value);
                }
            }
        }
        upcoming.sort((a,b)=>{
            return a.timestamp - b.timestamp;
        })
        for (const item of upcoming) {
            upcomingArr.push(
                <ClubScheduleHeader 
                    key={item.id}
                    uid={uid}
                    club={club}
                    schedule={item}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: item,
                            detailOpen: true
                        })
                    }}
                    onClickEdit={() => {
                        this.setState({
                            detailChoice: item,
                            editOpen: true
                        })
                    }} />
            );
        }

        return upcomingArr;
    }

    render() {
        const { club, uid, users } = this.props;
        const { detailChoice, editOpen, detailOpen, schedulePast } = this.state;

        let items = [];
        if (schedulePast === true) {
            items = this.renderContents();
        } else {
            items = this.renderUpcomingContents();
        }
        return (
            <div>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><GrSchedules/></div>
                            <h3 className='items-navigation-title'>
                            {t('schedules')}
                            </h3>
                            <div className='items-navigation-count'>| {items.length} {t('total')}</div>
                        </div>
                    </div>
                    
                    <FormGroup style={{margin: '5px 10px', maxWidth: 360}}>
                        <Input type="select" name="select" value={schedulePast} id="challengeChoice" onChange={e=>{
                                const update = e.target.value === true || e.target.value === "true";
                                this.setState({schedulePast: update});
                            }}>
                            <option value={false}>{t('upcoming_schedules')}</option>
                            <option value={true}>{t('past_schedules')}</option>
                        </Input>
                    </FormGroup>
                    {!detailChoice && !detailChoice.id && (
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({detailChoice: {
                            everyone: true,
                        }, editOpen: true});
                    }}>{t('create')}</Button>
                    )}
                    {detailChoice && (
                    <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({detailChoice: {}, editSchedule: false, detailSchedule: false})
                    }}>{t('back')}</Button>
                    )}
                </div>
                {items}
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <ClubScheduleDetailView 
                            uid={uid}
                            club={club}
                            clubSchedule={detailChoice} 
                            users={users}
                            isOpen={detailOpen}
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubScheduleEdit 
                            uid={uid}
                            club={club}
                            clubSchedule={detailChoice} 
                            isOpen={editOpen}
                            onUpdate={() => {
                                if (this.props.onUpdate) {
                                    this.props.onUpdate();
                                }
                            }}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ClubSchedulesView;