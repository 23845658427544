import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Button, Form, Spinner, FormGroup, Label, Input, Modal, ModalBody, Progress
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AttachVideoEdit from 'components/edit/AttachVideoEdit';
import GalleryItem from 'components/edit/community/GalleryItem';
import { PostCategory } from 'components/edit/community/PostCategory';
import { MdAdd } from 'react-icons/md';
import { GrMultiple } from 'react-icons/gr';
import { FcImageFile } from 'react-icons/fc';

import { dataActions } from 'redux/_actions';

import { checkYoutubeUrl } from 'utils/Utils';
import { uploadImageFileToStorage, uploadVideoToStorage } from 'utils/FirebaseUtils';
import { generateId } from "utils/API";
import { dbTimestamp } from "utils/API";
import { invokeHttpsApi } from "utils/API";
import CustomRichTextEditor from "components/template/CustomRichTextEditor";

class PostEdit extends Component {
    constructor(props) {
        super(props);
        const post = {
            type: 'photo',
            body: ""
        };
        if (props.post != null) {
            for (const [key, value] of Object.entries(props.post)) {
                post[key] = value;
            }
        }
        const {organization, team, game} = props;

        if (organization) {
            post.owner = organization.owner;
            post.organizationId = organization.id;
        }
        if (team) {
            post.owner = team.owner;
            post.teamId = team.id;
        }
        if (game) {
            post.gameId = game.id;
            post.divisionId = game.divisionId;
            post.seasonId = game.seasonId;
            post.subSeasonId = game.subSeasonId;
            post.organizationId = game.organizationId;
        }
        
        this.state = {
            post: post,
            progress: false,
            pickCategory: false,
            newImage: {},
            newVideo: {},
            addFiles: [],
            placeChoice: {},
            imageProgress: 0,
            videoProgress: 0,
            body: null
        }

        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, post, progress, onSaveDone} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.post !== post)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(post)) {
                challengeVal[key] = value;
            }
            this.setState({
                post: challengeVal
            })
        }
        if ((prevProps.progress !== progress && progress === false)) {
            if (onSaveDone != null) {
                onSaveDone();
            }
        }
    }

    onSaveClick(post, newImage, newVideo) {
        const {user} = this.props;
        
        if (post.title == null || post.title === '') {
            alert("You must input title");
        } else if (post.body == null || post.body === '') {
            alert("You must input post body");
        } else if (post.type === 'photo' && (post.photoItem == null || post.photoItem.url == null) && newImage.name == null) {
            alert("You must choose photo file to upload");
        } else if (post.type === 'video' && (post.videoItem == null || post.videoItem.url == null) && newVideo.name == null) {
            alert("You must choose video file to upload");
        } else if (post.type === 'youtube' && !checkYoutubeUrl(post.youtube)) {
            alert("You must input correct youtube url");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (post.id == null || post.id === '') {
                post.id = generateId("/post");
            }
            post.uid = user.uid;
            post.timestamp = dbTimestamp();

            this.setState({
                progress: true
            })
            if (post.type === 'photo' && newImage.name) {
                post.photoItem = {name: newImage.name}
                uploadImageFileToStorage(post.id, 'post/' + post.id + '/photoItem', newImage, (url) => {
                    post.photoItem.url = url 
                    this.savePost(post)
                }, (state, progress) => {
                    if (state === 'running') {
                        this.setState({imageProgress: progress});
                    } else {
                        this.setState({imageProgress: 0});
                    }
                }, error => {
                    alert("Saving Image File Failed.\n" + error);
                    this.setState({
                        progress: false
                    })
                })
            } else if (post.type === 'video' && newVideo.name) {  
                post.videoItem = {name: newVideo.name}
                uploadVideoToStorage(post.id, 'post/' + post.id + '/videoItem', newVideo, (url) => {
                    post.videoItem.url = url 
                    this.savePost(post)
                }, (state, progress) => {
                    if (state === 'running') {
                        this.setState({videoProgress: progress});
                    } else {
                        this.setState({videoProgress: 0});
                    }
                }, error => {
                    alert("Saving Image File Failed.\n" + error);
                    this.setState({
                        progress: false
                    })
                })
            } else if (post.type === 'youtube') {     
                this.savePost(post);
            } else {   
                this.savePost(post);
            }
        }
    }

    savePostGallery(post, item) {
        const {onChanged} = this.props;
        invokeHttpsApi('community-submitPostGalleryItem', {post: post, galleryItem: item}, (data) => {
            if (data && data.result) {
                this.setState({progress: false, post: data.result});
            } else {
                this.setState({progress: false});
            }
            if (onChanged != null) {
                onChanged();
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    savePost(post) {
        const {onChanged} = this.props;
        invokeHttpsApi('community-submitPost', {post: post}, (data) => {
            // Read result of the Cloud Function.
            this.setState({progress: false, post: post})
            if (onChanged != null) {
                onChanged();
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    renderGalleryItems() {
        const {post, galleryId} = this.state;

        let galleryArr = [];
        var count = 0;
        if (post.galleryItem) {
            count = Object.values(post.galleryItem).length;
            for (const item of Object.values(post.galleryItem)) {
                galleryArr.push(
                    <GalleryItem
                        path={'/post/' + post.id}
                        galleryItem={item}
                        editMode={item.id === galleryId}
                        onImageUpdate={update=> {
                            this.savePostGallery(post, update);
                        }}
                        onChange={update=> {
                            post.galleryItem[update.id] = update;
                            this.setState({post: post});
                        }} />
                )
                galleryArr.push(<div className='divider' />);
            }
        }

        return (
            <FormGroup>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className="wrap-content-parent" style={{margin: 0}}>
                        <div className="wrap-content-fill-child">
                            <h3 className='row-table-title'>
                                GalleryItems ({count})
                            </h3>
                        </div>
                        <div className="wrap-content-wrap-child">
                            <Button className="btn-cancel" onClick={(e) => {
                                if (!post.galleryItem) {
                                    post.galleryItem = {};
                                }
                                const galleryItem = {
                                    id: generateId('/post/' + post.id + '/galleryItem'),
                                    title: '',
                                    text: ''
                                }
                                post.galleryItem[galleryItem.id] = galleryItem;
                                this.setState({post: post, galleryId: galleryItem.id});
                            }}><MdAdd/></Button>
                            <Button className="btn-cancel" onClick={(e) => {
                                this.setState({addPhotos: true});
                            }}><GrMultiple/></Button>
                        </div>
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                    {galleryArr.length > 0 ? galleryArr : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        No Gallery Added yet
                        </div>
                    )}
                </div>
            </FormGroup>
        )
    }

    renderAddPhotos() {
        const {addPhotos, addFiles, progress, imageProgress} = this.state;

        if (addPhotos) {
            return (
                <Modal isOpen={true}>
                    <ModalBody>
                        <h2 className="course-title">{t('add_gallery_photos')}</h2>
                        <Input type="file" name="file" id="imageFile" accept=".png,.jpg,.jpeg" multiple={true} onChange={e => {       
                            let update = [];

                            for (const file of e.target.files) {
                                update.push(file);
                            }
                            this.setState({addFiles: update});
                        }} />
                        <Progress className='progress-style-file' color="warning" value={imageProgress} />
                        <h4 className="course-title">{t('image_files')} ({addFiles.length})</h4>
                        {addFiles.length > 0 ? addFiles.map((file,idx)=> (
                            <div>
                                <div key={idx} className="wrap-content-parent" style={{fontSize: 12}}>
                                    <span style={{fontSize: 14, marginRight: 10}}><FcImageFile /></span> {file.name} 
                                </div>
                                <div className="divider" />
                            </div>
                        )) : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                {t('no_data')}
                            </div>
                        )}
                        <div style={{height: 20}} />
                        {progress ? (
                        <div className="wrap-content-parent">
                            <div className="wrap-content-fill-child" />
                            <Spinner color="primary" />
                        </div>
                        ) : (
                        <div className="wrap-content-parent">
                            <div className="wrap-content-fill-child" />
                            {addFiles.length > 0 && (
                            <Button className="btn-submit" onClick={e=> {
                                this.submitGalleryFiles();
                            }}>{t('submit')}</Button>
                            )}
                            <Button className="btn-cancel" onClick={e=> {
                                this.setState({addPhotos: false});
                            }}>{t('close')}</Button>
                        </div>
                        )}
                    </ModalBody>
                </Modal>
            )
        } else {
            return <div/>
        }
    }

    submitGalleryFiles() {
        const { post, addFiles} = this.state;

        let files = [];
        for (const item of addFiles) {
            files.push(item);
        }

        if (files.length > 0) {
            const path = "/post/" + post.id + "/galleryItem";
            this.setState({progress: true});

            this.uploadImageFile(path, files, post);
        }
    }

    uploadImageFile(path, addFiles, post) {
        let item = {
            id: generateId(path),
            title: '',
            text: '',
            timestamp: new Date().valueOf()
        }
        uploadImageFileToStorage(item.id, path, addFiles[0], (url) => {
            if (!post.galleryItem) {
                post.galleryItem = {};
            }
            item.imageUrl = url;
            post.galleryItem[item.id] = item;
            addFiles.shift();

            if (addFiles.length > 0) {
                this.uploadImageFile(path, addFiles, post)
            } else {
                this.savePost(post);
            }
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({imageProgress: progress});
            } else {
                this.setState({imageProgress: 0});
            }
        }, error => {
            alert("Saving Image File Failed.\n" + error);
            this.setState({
                progress: false
            });
        })
    }

    render() {
        const {organization, onCloseClick, createMode} = this.props;
        const {post, pickCategory, progress, newImage, newVideo, imageProgress, videoProgress} = this.state;
        
        if (post != null) {
            return (
                <Form style={createMode ? {width: '100%'} : {}}>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            post.title = e.target.value;
                            this.setState({post: post});
                        }} value={post.title} />
                    </FormGroup>
                    <FormGroup>
                        {post.gameId && (
                        <div className="post_category">{t('category')}: {t('highlights')}</div>
                        )}
                        {post.teamId && (
                        <div className="post_category">{t('category')}: {t('team_post')}</div>
                        )}
                        {!post.gameId && post.organizationId && (
                        <div className="post_category">{t('category')}: {t('organization_post')}</div>
                        )}
                        <Button className='btn-cancel' onClick={e => {
                            this.setState({pickCategory: true});
                        }}>{t('change_category')}</Button>
                    </FormGroup>
                    <FormGroup>
                        <Label for="typeChoice">Type</Label>
                        <Input 
                            type="select" 
                            name="typeChoice" 
                            value={post.type} 
                            id="typeChoice" 
                            style={{marginTop: 20}}
                            onChange={e=> {
                                post.type = e.target.value;
                                this.setState({post: post});
                            }}>
                            <option key="photo" value="photo">Photo</option>
                            <option key="video" value="video">Video</option>
                            <option key="youtube" value="youtube">Youtube</option>
                        </Input>
                    </FormGroup>
                    {post.type === 'photo' && (
                        <AttachImageEdit
                            imageUrl={post.photoItem != null ? post.photoItem.url : null}
                            imageClass='task-image'
                            label='Thumb Image'
                            allowProgress={progress}
                            progress={imageProgress}
                            onChange={(file)=> {
                                this.setState({newImage: file});
                            }} />
                    )}
                    {post.type === 'video' && (
                        <AttachVideoEdit
                            imageUrl={post.videoItem != null ? post.videoItem.url : null}
                            videoClass='default-player'
                            label='Challenge Video'
                            allowProgress={progress}
                            progress={videoProgress}
                            onChange={(file)=> {
                                this.setState({newVideo: file});
                            }} />
                    )}
                    {post.type === 'youtube' && (
                        <FormGroup>
                            <Label for="youtube">Youtube Link</Label>
                            <Input type="text" name="youtube" id="youtube" onChange={e => {
                                post.youtube = e.target.value;
                                this.setState({post: post});
                            }} value={post.youtube} />
                        </FormGroup>
                    )}
                    <CustomRichTextEditor
                        placeholderText='Edit Your Content Here!'
                        value={post.body}
                        onChange={(update)=> {
                            post.body = update
                            this.setState({post: post})
                        }} />
                    
                    <div style={{marginTop: 20}}/>
                    {post.id && (
                        <div>{this.renderGalleryItems()}</div>
                    )}

                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(post, newImage, newVideo);
                                }}>{t('create')}</Button>
                            </div>
                        ) : (
                            <div>
                            <Button className='btn-edit' onClick={e => {
                                this.onSaveClick(post, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                            </div>
                        )}
                        </div>
                    )}
                    {this.renderAddPhotos()}
                    {pickCategory && (
                    <Modal isOpen={true}>
                        <ModalBody>
                            <PostCategory
                                organization={organization}
                                onCloseClick={(organization, game, team) => {
                                    if (organization) {
                                        post.owner = organization.owner;
                                        post.organizationId = organization.id;
                                    }
                                    if (team) {
                                        post.owner = team.owner;
                                        post.teamId = team.id;
                                    }
                                    if (game) {
                                        post.gameId = game.id;
                                        post.divisionId = game.divisionId;
                                        post.seasonId = game.seasonId;
                                        post.subSeasonId = game.subSeasonId;
                                        post.organizationId = game.organizationId;
                                    }
                                    
                                    this.setState({post: post, pickCategory: false});
                                }} />
                        </ModalBody>
                    </Modal>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { loggingIn, user, progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(PostEdit));
export { connectedApp as PostEdit };
