import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import OrganizationEventHeader from 'components/detail/organization/landing/OrganizationEventHeader';
import { AuthForm, STATE_LOGIN, STATE_SIGNUP } from 'components/auth/AuthForm';
import UserBadge from 'components/detail/UserBadge';
import checkIcon from 'assets/img/icons/attendance_going.png';
import uploadIcon from 'assets/img/icons/ic_upload_btn.png';
import SurveyView from 'components/detail/survey/SurveyView';
import { 
    Row,
    Col, Input, Button,
} from 'reactstrap';
import { t } from 'i18next';
import { Switch } from '@mui/material';
import { getDayStart, DAY_TIME, getOrgDomain, getOrgData } from 'utils/Utils';
import LoadingView from 'components/detail/loading/LoadingView';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import logo200Image from 'assets/img/logo/live_192.png';
import { invokeHttpsApi } from 'utils/API';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import { withRouter } from 'utils/Routher';

class OrgEvents extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            seasonChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            mainTab: 0,
        }
    }

    componentDidMount() {
        if (this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        const queryData = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizerEventsQuery', queryData, ((data)=>{

            const organization = {};
            const organizations = {};
            const users = {};
            const teams = {};
            const locations = {};
            const schedules = {};
            const orgSchedules = {};
            const covidForm = {};
            const playerForms = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (key === queryData.organizationId || value.customDomain === queryData.customDomain) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.schedules)) {
                    schedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.orgSchedules)) {
                    orgSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.covidForm)) {
                    covidForm[key] = value;
                }
                for (const [key, value] of Object.entries(data.playerForms)) {
                    playerForms[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                teams: teams,
                locations: locations,
                users: users,
                schedules: schedules,
                orgSchedules: orgSchedules,
                covidForm: covidForm,
                playerForms: playerForms,
                loading: false,
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    submitRequest(id, organizationId, verified) {
        
        invokeHttpsApi('organizerQuery-requestEventQuery', {
            id: id,
            organizationId: organizationId,
            verified: verified
        }, ((data)=>{
            this.loadContents();
        }), ((error)=> {
            console.log('loadContents error', error);
        }));
    }

    handleAuthState = authState => {
        if (authState === STATE_LOGIN) {
            this.setState({forSignUp: false})
        } else {
            this.setState({forSignUp: true})
        }
    };

    renderStatus(form) {
        let title = "Good";
        const survey = form.survey;
        
        if (survey != null && form.value > survey.goodValue) {
            title = "Good";
        } else if (survey != null && form.value > survey.yellowValue) {
            title = t('normal');
        } else if (survey != null && form.value > survey.warningValue) {
            title = "Warning";
        } else if (survey != null && form.value > survey.redValue) {
            title = "Danger";
        } else if (survey != null && form.value > -1) {
            title = "Critical";
        }

        let color = "#277e26";
        if (survey != null && form.value > survey.goodValue) {
            color = "#277e26";
        } else if (survey != null && form.value > survey.yellowValue) {
            color = "#3d76bf";
        } else if (survey != null && form.value > survey.warningValue) {
            color = "#F0D11F";
        } else if (survey != null && form.value > survey.redValue) {
            color = "#a6080e";
        } else if (survey != null && form.value > -1) {
            color = "#ce080e";
        }

        return <div style={{display: 'inline-block', fontWeight: 800, fontSize: 10, color: color}}>{title}</div>
    }

    renderTodayForm() {
        const { user } = this.props;
        const { covidForm, playerForms, dateChoice, detailMode } = this.state;
        
        const dayStart = getDayStart(dateChoice);
        let todayForm = {};
        
        for (const[, value] of Object.entries(playerForms)) {
            if (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME) {
                todayForm = value;
            }
        }
        const icon = todayForm.id != null ? checkIcon : uploadIcon;
        
        return (
            <div className='container'>
                <div className='membership-header' style={{margin: '20px 0px'}}>
                    <div className='wrap-content-wrap-child' style={{marginTop: 8, marginLeft: 10}}>
                        <img className='membership-icon' src={icon} alt="icon" />
                    </div>
                    <div className='wrap-content-fill-child' style={{marginTop: 5}}>
                        <h4 className='membership-header-title'>
                            Today Covid Form
                        </h4>
                    </div>
                    <div className='wrap-content-wrap-child' style={{marginTop: 5}}>
                        {todayForm != null ? (
                            this.renderStatus(todayForm)
                        ) : (
                        <h4 className='membership-header-title'>
                        </h4>
                        )}
                    </div>
                    <div className='wrap-content-wrap-child' style={{marginTop: 5}}>
                    {detailMode !== 'form' && (
                        <Button className='btn-submit' onClick={e=> {
                            this.setState({detailMode: 'form'})
                        }}>Upload</Button>
                    )}
                    {detailMode === 'form' && (
                        <Button className='btn-cancel' onClick={e=> {
                            this.setState({detailMode: 'list'})
                        }}>{t('back')}</Button>
                    )}
                    </div>
                </div>
                {detailMode === 'form' && todayForm.survey != null && (
                <SurveyView 
                    survey={todayForm.survey}
                    uid={user.uid}
                    submission={todayForm}
                    user={user}
                    editable={true} />
                )}
                {detailMode === 'form' && todayForm.survey == null && (
                <SurveyView 
                    survey={covidForm}
                    uid={user.uid}
                    user={user}
                    editable={true} />
                )}
            </div>
        )
    }

    renderContents() {
        const { user } = this.props;
        const { organization, forSignUp } = this.state;

        if (user == null || user.uid == null) {
            const homeLink = getOrgDomain(organization);
            var link = homeLink + '/events';
            return (
                <div className='shadow-tile container' style={{margin: '10px auto'}}>
                    <AuthForm
                        forMembership={false}
                        authState={forSignUp ? STATE_SIGNUP : STATE_LOGIN}
                        onChangeAuthState={this.handleAuthState}
                        onLogoClick={this.handleLogoClick}
                        redirectLink={link}
                    />
                </div>
            )
        } else {
            return (
                <div className='container'>
                    <div className='membership-header' style={{margin: '0px 10px'}}>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                Current User: <UserBadge customClass='wrap-content-wrap-child' user={user}/>
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 10}}>
                                <Button className='btn-cancel' onClick={e=> {
                                    this.props.logout();
                                }}>Logout</Button>
                            </div>
                        </div>
                    </div>
                    {this.renderTodayForm()}
                    {this.renderEvents()}
                </div>
            )
        }
    }

    renderEvents() {
        const { user } = this.props;
        const { organization, locations, locationChoice, dateFilter, dateChoice, schedules, orgSchedules, searchStr, detailMode } = this.state;

        if (detailMode !== 'list') {
            return <div/>
        }

        const result = [];
        const list = [];
        const dayStart = getDayStart(dateChoice);
    
        for (const[, value] of Object.entries(schedules)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            let location = locations[value.location];
            if (location != null && (locationChoice.id == null || location.id === locationChoice.id)) {
                if (value.organizationId === organization.id) {
                    if (!dateFilter || (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME)) {
                        result.push(value);
                    }
                }
            }
    
        }

        for (const[, value] of Object.entries(orgSchedules)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            let location = locations[value.location];
            if (location != null && (locationChoice.id == null || location.id === locationChoice.id)) {
                if (value.organizationId === organization.id) {
                    if (!dateFilter || (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME)) {
                        result.push(value);
                    }
                }
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t1 - t2;
        })
        
        for (const value of result) {
            list.push(
                <OrganizationEventHeader 
                    key={value.id}
                    user={user}
                    organization={organization}
                    locations={locations}
                    schedule={value}
                    onClickRequest={() => {
                        this.submitRequest(value.id, value.organizationId, true);
                    }}
                    onClickCancel={() => {
                        this.submitRequest(value.id, value.organizationId, false);
                    }} />
            );
        }

        return (
            <div>
                <h3 className='row-header-title-big'>
                    {dateFilter ? t('today_event') : t('all_events')}
                </h3>

                <Row>
                    <Col sm={6} xs={12}>
                        <Input 
                            type="select" 
                            name="select" 
                            value={locationChoice.id} 
                            id="typeChoice" 
                            style={{marginTop: 20}}
                            onChange={e=> {
                                let update = locations[e.target.value];
                                this.setState({locationChoice: update != null ? update : {}});
                            }}>
                            <option key="null" value={null}>No Location Choice ...</option>
                            {Object.values(locations).map((val,idx)=>(
                                <option key={idx} value={val.id}>{val.title}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col style={{margin: 'auto 0px'}} sm={4} xs={8}>
                        <div className='items-navigation'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                <Input type="text" name="search" id="search" placeholder='Search by Schedule Title ...' onChange={e => {
                                    this.setState({searchStr: e.target.value});
                                }} value={searchStr} />
                            </div>                        
                        </div>
                    </Col>
                    <Col sm={2} xs={4} style={{marginTop: 10}}>
                        <Switch
                            checked={dateFilter}
                            onChange={e=>{
                                this.setState({dateFilter: e.target.checked});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        <div style={{display: 'inline-block', fontSize: 12, marginRight: 20}}>For Today</div>
                    </Col>
                </Row>

                {list}
            </div>
        );
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    render() {
        const {organization, loading} = this.state;
        
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';

        if (loading || !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        
        return (
            <div>
                {!sambaTheme && this.renderOrganizationHeader()}
                <div style={{marginTop: 20}} />
                <div className='org-landing-contents-bg-blue'>
                    {this.renderContents()}
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgEvents));
export { connectedPage as OrgEvents };
