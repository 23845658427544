import React, { Component } from "react";
import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import Pagination from 'react-responsive-pagination';
import {
    FormGroup, Input, Button, Modal, ModalBody
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class Games extends Component {
    constructor(props) {
        super(props);
        
        let locationsChoice = {};
        if (props.locations != null) {
            for (const [key, value] of Object.entries(props.locations)) {
                if (value.organizationId === props.organization.id) {
                    locationsChoice[key] = value;
                }
            }
        }
        this.state = {
            gameChoice: {},
            locationChoice: {},
            locationsChoice: locationsChoice,
            editTeamData: false,
            editLocation: false,
            progress: false,
            detailOpen: false,
            editOpen: false,
            detailChoice: false,
            imageProgress: 0,
            page: 0,
            windowWidth: window.innerWidth,
        };
    }

    componentDidUpdate(prevProps) {
        const {locations, organization, seasons} = this.props;
        if (seasons !== prevProps.seasons) {
            let locationsChoice = {};
            if (locations != null) {
                for (const [key, value] of Object.entries(locations)) {
                    if (value.organizationId === organization.id) {
                        locationsChoice[key] = value;
                    }
                }
            }
            this.setState({
                locationsChoice: locationsChoice,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }

    renderGames() {
        const { organization, seasons, teams, users, locations, loading } = this.props;
        const { locationChoice, locationsChoice, page} = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if (value.organizationId === organization.id)  {
                seasonsChoice[key] = value;
            }
        }

        let games = [];

        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                games.push(game)
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 3);
        
        for (const[, value] of Object.entries(games)) {
            if (value.organizationId === organization.id) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.start;            
            let t2 = b.start;

            return t1 - t2;
        })
        const now = new Date().valueOf();
        let pageChoice = page;
        if (page === 0) {
            var index = 0;
            for (const item of result) {
                if (item.start > now) {
                    break;
                }
                index ++;
            }
            pageChoice = parseInt(index / 3) + 1;
        }

        let idx = (pageChoice - 1) * 3;
        if (result.length > idx) {
            const value = result[idx ++];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }
        if (result.length > idx) {
            const value = result[idx ++];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }
        if (result.length > idx) {
            const value = result[idx ++];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px", backgroundColor: '#eee'}}>
                <div className="container">
                    <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                        <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                                const id = e.target.value;
                                this.setState({locationChoice: id == null ? {} : locations[id]});
                            }}>
                            <option value={null}></option>
                            {Object.values(locationsChoice).map((location)=> (
                                <option value={location.id}>{location.title}</option>
                            )) }
                        </Input>
                    </FormGroup>
                    <div style={{height: 25}} />
                    {list.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                        {loading ? "Loading Games" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={pageChoice}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
            </div>
        );
    }

    renderGameChoice() {
        const {organization, seasons, divisions, posts, users, teams, cards, locations, onChanged} = this.props;
        const {gameChoice} = this.state;

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px", backgroundColor: '#eee'}}>
                <div className="container">
                    {gameChoice.id && (
                    <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({gameChoice: {}})
                    }}>Back To Games List</Button>
                    )}
                    <div style={{height: 25}} />
                    <GameDetails 
                        game={gameChoice}
                        organization={organization}
                        locations={locations}
                        divisions={divisions}
                        seasons={seasons}
                        cards={cards}
                        teams={teams}
                        posts={posts}
                        users={users} 
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }}
                        onClickClose={()=>{
                            this.setState({gameChoice: {}});
                        }} />
                    <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                    </div>
                    <div style={{height: 25}} />
                    
                </div>
            </div>
        );
    }
    
    render() {
        const {organization, seasons, divisions, posts, users, teams, cards, locations, onChanged} = this.props;
        const {gameChoice} = this.state;
        
        return (
            <div style={{margin: 0}}>
            {this.renderGames()}
            {gameChoice.id && (
                <Modal isOpen={true} style={{marginTop: 70}}>
                    <ModalBody>
                        <GameDetails 
                            game={gameChoice}
                            organization={organization}
                            locations={locations}
                            divisions={divisions}
                            seasons={seasons}
                            cards={cards}
                            teams={teams}
                            posts={posts}
                            users={users} 
                            onChanged={()=> {
                                if (onChanged != null) {
                                    onChanged();
                                }
                            }}
                            onClickClose={()=>{
                                this.setState({gameChoice: {}});
                            }} />
                    </ModalBody>
                </Modal>
            )}
            </div>
        );
    }
}

export default withTranslation()(Games);