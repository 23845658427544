import React, { Component } from "react";
import { t } from 'i18next';
import { loadStripe } from '@stripe/stripe-js';
import {
    Button, Input, Spinner, Col, Row
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import teamImage from 'assets/img/icons/club_icon_default.png';
import fundImage from 'assets/img/icons/fundraise.png';

import { STRIPE_PUBLISHABLE_KEY, getOrgDomain, renderHtmlString, replaceUrl, sortByTitle } from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";
import { withRouter } from "utils/Routher";
import { FcCloseUpMode, FcEngineering } from "react-icons/fc";

class CoachSeasonRegister extends Component {

    constructor(props) {
        super(props)
        this.state = {
            step: 1,
            progress: false,
            checked: {},
            imageProgress: 0
        }
    }

    componentDidMount() {
        const { myTeams } = this.props;
        const searchParams = this.props.router.searchParams;
        const teamId = searchParams.get("teamId");
        const paymentSuccess = searchParams.get("payment_result");
        if (myTeams[teamId]) {
            this.setState({teamChoice: myTeams[teamId], step: 4, paymentSuccess: paymentSuccess})
        }
    }

    requestTeamChargeRegister(season, team, methodChoice, note, price) {
        const {organization} = this.props;
        if (!season.enrollFee || !season.enrollFee.teamFee) {
            alert(t("season_fee_no_registered_prompt"));
            return;
        }
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!team || !team.id || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        
        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            teamId: team.id,
            seasonId: season.id,
            price: price,
            returnURL: pref + '/championships/' + season.id + '?teamId=' + team.id
        }
        if (note) {
            data.note = note;
        } else {
            data.note = '';
        }
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-createSeasonPaymentIntentTeam', data, async (data) => {

            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

            if (data.success && data.session) {
                stripe.redirectToCheckout({ sessionId: data.session.id });

                if (!season.teamPending) {
                    season.teamPending = {};
                }
                season.teamPending[team.id] = data.pending;
                this.props.onSeasonUpdate(season);

                this.setState({
                    checkoutProgress: false,
                    progress: false,
                    step: 4,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    requestTeamRegisterWallet(season, team, methodChoice, note, price) {
        const {wallet} = this.props;
        if (!season.enrollFee || !season.enrollFee.teamFee) {
            alert(t("season_fee_no_registered_prompt"));
            return;
        }
        if (!wallet.balance || (parseFloat(wallet.balance) / 100) < price) {
            alert("Wallet balance is $" + wallet.balance + ". Not sufficent.");
            return;
        }
        if (!team || !team.id || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        
        const data = {
            register: {
                teamId: team.id,
                note: note,
                method: 'wallet',
                price: price
            },
            seasonId: season.id,
        }
        data.wallet = wallet;
        invokeHttpsApi('purchase-requestSeasonTeamRegisterWallet', data, (data) => {

            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                    step: 4,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.teamPending) {
                season.teamPending = {};
            }
            season.teamPending[team.id] = {
                teamId: team.id,
                note: note,
                method: methodChoice,
            }
            this.props.onSeasonUpdate(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    requestTeamRegister(season, team, methodChoice, note) {
        if (!team || !team.id || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        
        const data = {
            register: {
                teamId: team.id,
                note: note,
                method: methodChoice,
            },
            seasonId: season.id,
        }
        invokeHttpsApi('clubQuery-requestSeasonTeamRegister', data, (data) => {

            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                    step: 4,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.teamPending) {
                season.teamPending = {};
            }
            season.teamPending[team.id] = {
                teamId: team.id,
                note: note,
                method: methodChoice,
            }
            this.props.onSeasonUpdate(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-create-step-header">
            <div className="season-create-step-num">
                {num}
            </div>
            <div className="season-create-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderStepOne() {
        const { season, divisions, user, onSeasonUpdate, onCloseClick } = this.props;
        const { checked, progress } = this.state;
        const division = divisions[season.divisionId];

        let items = [];
        let allCheck = true;
        for (const [key, value] of Object.entries(division.policy ? division.policy : {})) {
            if (!checked[key]) {
                checked[key] = {};
            }
            if (!checked[key][user.uid]) {
                allCheck = false;
            }
            items.push(value);
        }


        return (
            <div className="overlay-modal">
                {this.renderStepTitle(1, t('privacy_policy'))}
                
                <div className="register-overlay-contents">
                {items.length > 0 ? items.map((value, idx)=>(
                    <div key={idx}>
                        <h2 className="post-detail-title">
                        {value.title}
                        </h2>
                        <div className='app'>
                            {renderHtmlString(value.body ? value.body : "")}
                        </div>
                        <div className='player-form-item'>
                            <div className='player-form-title'>
                                {t('ack_agree')}
                            </div>
                            <div className='player-form-value form-setting-control'>
                                <Switch
                                    checked={checked[value.id] && Object.values(checked[value.id]).includes(user.uid)}
                                    onChange={e=>{
                                        const update = e.target.checked;
                                        if (update) {
                                            checked[value.id][user.uid] = user.uid;
                                        } else {
                                            delete checked[value.id][user.uid]
                                        }
                                        this.setState({checked: checked});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </div>
                    </div>
                    )) : (
                    <div className="register-overlay-contents-middle">
                    {t('no_division_policy_messsage')}
                    </div>
                )}
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                        <Button className='btn-blue' onClick={e => {
                            if (items.length === 0) {
                                this.setState({step: 2});
                                return;
                            }
                            if (!allCheck) {
                                alert(t("division_policy_check_prompt"));
                                return;
                            }
                            this.setState({progress: true})
                            invokeHttpsApi('userQuery-submitSeasonAgree', {
                                agree: true,
                                seasonId: season.id,
                                userId: user.uid,
                            }, (data) => {
                                // Read result of the Cloud Function.
                                if (!season.policyFlag) {
                                    season.policyFlag = {};
                                }
                                season.policyFlag[user.uid] = true;
                                
                                onSeasonUpdate(season);
                                this.setState({step: 2, progress: false})
                            }, (error) => {
                                console.log('saveContents error', error);
                                this.setState({progress: false})
                            });
                        }}>{t(items.length > 0 ? 'agree' : 'skip')}</Button>
                        )}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t(items.length > 0 ? 'decline' : 'close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepTwo() {
        const { myTeams, onCloseClick } = this.props;
        const { teamChoice } = this.state;
        const teamsChoice = [];
        for (const team of Object.values(myTeams)) {
            teamsChoice.push(team)
        }
        sortByTitle(teamsChoice)

        return (
            <div className="overlay-modal">
                {this.renderStepTitle(2, t('choose_team_to_register'))}
                <div className="register-overlay-contents">
                    <div className="register-overlay-contents-middle">
                        <p className="register-overlay-text">
                            {t('team_register_message')}
                        </p>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('team_choice')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="text" id="title" onChange={e => {
                                    if (myTeams[e.target.value]) {
                                        this.setState({teamChoice: myTeams[e.target.value]});
                                    } else {
                                        this.setState({teamChoice: null});
                                    }
                                }} value={teamChoice && myTeams[teamChoice.id] ? teamChoice.id : ""}>
                                    <option value=""></option>
                                    {teamsChoice.map((v,i) => (
                                    <option value={v.id} key={i}>{v.title} {v.category ? ` - ${v.category.title}` : ""}</option>
                                    ))}
                                </Input>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 1})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!teamChoice) {
                                alert(t("no_team_choice_prompt"));
                                return;
                            }
                            this.setState({step: 3})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepThree() {
        const { organization, season, onCloseClick } = this.props;
        const { teamChoice, checkoutProgress } = this.state;
        var {teamMethods, teamNotes} = this.state;

        if (!teamMethods) {
            teamMethods = {};
        }

        if (!teamNotes) {
            teamNotes = {};
        }
        const teamMethod = teamMethods[teamChoice.id] ? teamMethods[teamChoice.id] : 'direct';
        const teamNote = teamNotes[teamChoice.id] ? teamNotes[teamChoice.id] : null;

        let main = <div />;
        let enroll = null;
        const teamIcon = teamChoice.iconUri ? teamChoice.iconUri : teamImage;
        let enrollFee = undefined;
        if (season.enrollFee && season.enrollFee.teamFee) {
            enrollFee = season.enrollFee.teamFee;
        }

        if (season.teamRegisters && Object.keys(season.teamRegisters).includes(teamChoice.id)) {
            let register = season.teamRegisters[teamChoice.id];
            
            main = (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('enrolled')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                            <h3 style={{fontSize: 15, marginRight: 10}}>
                            {t('enrolled')}
                            </h3>
                            <div className='wrap-content-fill-child reg-info-label'>
                                <span>{t('method')}: <b>{register.method}</b> Role: <b>{register.role}</b></span>
                                <div>{t('note')}: {register.note}</div>
                            </div>
                        </div>
                        </div>
                    </Col>
                </Row>
            )
        } else if (season.teamIds && Object.values(season.teamIds).includes(teamChoice.id)) {
            main = (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('enrolled')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                            {t('assigned_team_directly')}
                        </div>
                        </div>
                    </Col>
                </Row>
            )
        } else if (season.teamPending && Object.keys(season.teamPending).includes(teamChoice.id)) {
            let register = season.teamPending[teamChoice.id];
            
            if (register.method === 'direct') {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestTeamChargeRegister(season, teamChoice, register.method, register.note, enrollFee)
                    }}>
                    {t('pay_on_stripe')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                    </Button>
                )
            } else if (register.method === 'wallet') {
                enroll = (<Button className='btn-blue' onClick={e=> {
                    this.requestTeamRegisterWallet(season, teamChoice, register.method, register.note, enrollFee)
                }}>
                {t('pay_on_wallet')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                </Button>)
            } else {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        if (window.confirm('Are you sure you want to change enroll request?')) {
                            this.requestTeamRegister(season, teamChoice, register.method, register.note);
                        }
                    }}>
                    {t('update_request')}:
                    </Button>
                )
            }
            
            main = (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('payment_method')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" 
                                    value={register.method ? register.method : ""} id="challengeChoice" 
                                    onChange={e=>{
                                        season.teamPending[teamChoice.id].method = e.target.value === "" ? "cash" : e.target.value;
                                        
                                        this.props.onSeasonUpdate(season);
                                    }}>
                                    {!season.blockOfflinePayment && (
                                    <option value="">{t('pay_in_person')}:</option>
                                    )}
                                    {!season.blockOfflinePayment && (
                                    <option value="wallet">{t('pay_on_wallet')}:</option>
                                    )}
                                    {organization.stripeAccountId && (
                                    <option value="direct">{t('pay_on_stripe')}:</option>
                                    )}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    {register.method !== 'direct' && register.method !== 'wallet' && (
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" value={register.method ? register.method : ""} id="challengeChoice" onChange={e=>{
                                        season.teamPending[teamChoice.id].method = e.target.value !== "" ? e.target.value : null;
                                        
                                        this.props.onSeasonUpdate(season);
                                    }}>
                                    <option value="">{t('choose_payment_method')}:</option>
                                    <option value="cash">{t('cash')}</option>
                                    <option value="online">{t('online')}</option>
                                    <option value="eTransfer">{t('e_transfer')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>)}
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            {register.method === 'direct' ? (
                                <div className="reg-method-info-label">{t('reg_pay_wallet')}</div>
                            ) : (register.method === 'wallet' ? (
                                <div className="reg-method-info-label">{t('reg_pay_wallet')}</div>
                            ) : (
                                <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                            ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('note')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                    season.teamPending[teamChoice.id].note = e.target.value;
                                        this.props.onSeasonUpdate(season);
                                }} value={register.note ? register.note : ""} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )

        } else {
            if (teamMethod === 'direct') {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestTeamChargeRegister(season, teamChoice, teamMethod, teamNote, enrollFee)
                    }}>
                    {t('pay_on_stripe')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                    </Button>
                )
            } else if (teamMethod === 'wallet') {
                enroll = (<Button className='btn-blue' onClick={e=> {
                    this.requestTeamRegisterWallet(season, teamChoice, teamMethod, teamNote, enrollFee)
                }}>
                {t('pay_on_wallet')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                </Button>)
            } else {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestTeamRegister(season, teamChoice, teamMethod, teamNote)
                    }}>
                    {t('enroll')}
                    </Button>
                )
            }
            
            main = (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('payment_method')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" value={teamMethod ? teamMethod : ""} id="challengeChoice" onChange={e=>{
                                        const update = e.target.value === "" ? "cash" : e.target.value;
                                        teamMethods[teamChoice.id] = update;
                                        this.setState({teamMethods: teamMethods});
                                    }}>
                                    {!season.blockOfflinePayment && (
                                    <option value="">{t('pay_in_person')}:</option>
                                    )}
                                    {!season.blockOfflinePayment && (
                                    <option value="wallet">{t('pay_on_wallet')}:</option>
                                    )}
                                    {organization.stripeAccountId && (
                                    <option value="direct">{t('pay_on_stripe')}:</option>
                                    )}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    {teamMethod !== 'direct' && (
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" value={teamMethod ? teamMethod : ""} id="challengeChoice" onChange={e=>{
                                        const update = e.target.value === "" ? null : e.target.value;
                                        teamMethods[teamChoice.id] = update;
                                        this.setState({teamMethods: teamMethods});
                                    }}>
                                    <option value="">{t('choose_payment_method')}:</option>
                                    <option value="cash">{t('cash')}</option>
                                    <option value="online">{t('online')}</option>
                                    <option value="eTransfer">{t('e_transfer')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>)}
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            {teamMethod === 'direct' ? (
                                <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                            ) : (teamMethod === 'wallet' ? (
                                <div className="reg-method-info-label">{t('reg_pay_wallet')}</div>
                            ) : (
                                <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                            ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('note')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                        const update = e.target.value;
                                        teamNotes[teamChoice.id] = update;
                                    this.setState({teamNotes: teamNotes});
                                }} value={teamNote ? teamNote : ""} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )

        }
        return (
            <div className="overlay-modal">
                {this.renderStepTitle(3, t('register_team_to_season'))}
                <div className="register-overlay-contents">
                    <div className="register-overlay-contents-middle">
                        <p className="register-overlay-text">
                            {t('register_team_to_season_message_01')}
                        </p>
                        <p className="register-overlay-text">
                            {t('register_team_to_season_message_02')}
                        </p>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('team')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                                    <img className='reg-icon-team' alt="logo" src={teamIcon} />
                                    <div className='wrap-content-fill-child'>
                                    {teamChoice.title}
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('team_fee')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                                    <img className='reg-icon-team' alt="logo" src={fundImage} />
                                    <div className='wrap-content-fill-child'>
                                    {season.enrollFee && season.enrollFee.teamFee ? "$ " + season.enrollFee.teamFee : t('season_fee_no_registered')}
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Row>
                        {main}
                    </div>
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 2})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {enroll && !checkoutProgress && enroll}
                        {enroll && checkoutProgress && <Spinner color="primary" />}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepFour() {
        const { organization, season, onCloseClick } = this.props;
        const { teamChoice, paymentSuccess } = this.state;

        const pending = season.teamPending && teamChoice && season.teamPending[teamChoice.id] ? season.teamPending[teamChoice.id] : {};
        let success;
        let messageId;
        if (!pending.intentId) {
            messageId = "register_team_to_season_requested_offline";
            success = true;
        } else if (paymentSuccess === true) {
            messageId = "register_team_to_season_requested_direct";
            success = true;
        } else {
            messageId = "register_team_to_season_failed_direct";
            success = false;
        }

        return (
            <div className="overlay-modal">
                {this.renderStepTitle(4, t('register_team_to_season_result'))}
                <div className="register-overlay-contents">
                    <div className="register-overlay-contents-middle">
                        <p className="register-overlay-icon">
                            {success ? <FcCloseUpMode /> : <FcEngineering />}
                        </p>
                        <p className="register-overlay-text">
                            {t(messageId)}
                        </p>
                    </div>
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 3})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.props.router.setSearchParams( params => {
                                params.set("teamId", null);
                                params.set("payment_result", null);
                                return params;
                            })
                            replaceUrl(`${getOrgDomain(organization)}/championships/${season.id}`);
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const { step } = this.state;

        return (
            <div className="overlay-wrapper">
                {step === 1 && this.renderStepOne()}
                {step === 2 && this.renderStepTwo()}
                {step === 3 && this.renderStepThree()}
                {step === 4 && this.renderStepFour()}
            </div>
        )
    }
}

const connectedRouter = withRouter(CoachSeasonRegister)
export { connectedRouter as CoachSeasonRegister}