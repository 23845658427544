import React, { Component } from 'react';
import {TeamSlides} from 'components/landing/team/TeamSlides';
import { Button, FormGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import Pagination from 'react-responsive-pagination';
import { t } from 'i18next';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import ClubScheduleHeader from 'components/detail/club/ClubScheduleHeader';
import ClubScheduleDetailView from 'components/detail/club/ClubScheduleDetailView';
import { getOrgId } from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import { TeamEmpty } from 'components/landing/team/TeamEmpty';

class TeamSchedules extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            page: 0,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const clubSchedules = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                clubSchedules: clubSchedules,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderGames() {
        const { team, organizations, divisions, seasons, teams, users, locations, gameChoice, locationChoice, page, cards, posts} = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            seasonsChoice[key] = value;
        }

        let games = [];

        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if (game.homeTeam === team.id || game.awayTeam === team.id) {
                                if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                    games.push(game)
                                }
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 3);
        
        for (const[, value] of Object.entries(games)) {
            result.push(value);
        }

        result.sort(function(a, b) {
            let t1 = a.start;            
            let t2 = b.start;

            return t1 - t2;
        })
        const now = new Date().valueOf();
        let pageChoice = page;
        if (page === 0) {
            var index = 0;
            for (const item of result) {
                if (item.start > now) {
                    break;
                }
                index ++;
            }
            pageChoice = parseInt(index / 3) + 1;
        }

        let idx = (pageChoice - 1) * 3;
        if (result.length > idx) {
            const value = result[idx ++];
            const organization = value && value.organizationId ? organizations[value.organizationId] : {};
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const organization = value && value.organizationId ? organizations[value.organizationId] : {};
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const organization = value && value.organizationId ? organizations[value.organizationId] : {};
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }

        if (gameChoice) {
            const organization = gameChoice.organizationId ? organizations[gameChoice.organizationId] : {};
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('games')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            {/* <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                this.setState({gameChoice: null})
                            }}>Back To Games List</Button> */}
                            <div style={{height: 25}} />
                            <GameDetails 
                                game={gameChoice}
                                organization={organization}
                                locations={locations}
                                divisions={divisions}
                                seasons={seasons}
                                cards={cards}
                                teams={teams}
                                posts={posts}
                                users={users} 
                                onChanged={()=> {
                                    this.loadContents();
                                }}
                                onClickClose={()=>{
                                    this.setState({gameChoice: null});
                                }} />
                            <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                            </div>
                            <div style={{height: 25}} />
                    
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('games')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                                <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                                        const id = e.target.value;
                                        this.setState({locationChoice: id == null ? {} : locations[id]});
                                    }}>
                                    <option value={null}></option>
                                    {Object.values(locations).map((location)=> (
                                        <option value={location.id}>{location.title}</option>
                                    )) }
                                </Input>
                            </FormGroup>
                            <div style={{height: 25}} />
                            {list.length === 0 ? (
                                <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                                {t('no_items_to_show')}
                                </div>
                            ) : (
                                <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                                    {list}
                                </div>
                            )}
                            <div style={{height: 25}} />
                            <Pagination
                                current={pageChoice}
                                total={pageCount}
                                onPageChange={(update) => {
                                    this.setState({page: update})
                                }} />
                            <div style={{height: 25}} />
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderSchedules() {
        const { user } = this.props;
        const { team, users, clubSchedules, detailSchdedule } = this.state;
        
        const itemsArr = Object.values(clubSchedules);
        itemsArr.sort((a,b)=>{
            return b.timestamp - a.timestamp;
        })
        

        const items = [];

        for (const item of itemsArr) {            
            items.push((
                <ClubScheduleHeader 
                    key={item.id}
                    uid={user ? user.uid : null}
                    club={team}
                    schedule={item}
                    onClickDetail={() => {
                        this.setState({
                            detailSchdedule: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/5";

        if (detailSchdedule) {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('schedules')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('schedules')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <Button className='btn-cancel' onClick={(e)=> {
                                        this.setState({detailSchdedule: null})
                                    }}>
                                    {t('close')}
                                    </Button>
                                </div>
                            </div>
                            <ClubScheduleDetailView 
                                uid={user ? user.uid : null}
                                club={team}
                                clubSchedule={detailSchdedule}
                                users={users} />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('schedules')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                                <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                                {t('schedules')}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                    <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                    {t('manage_contents')}
                                    </a>
                                </div>
                            </div>
                        {items.length === 0 ? (
                            <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_items_to_show')}
                            </div>
                        ) : (
                            <div>
                            {items}
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const {team, loading} = this.state;
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
        
        if (!team.available) {
            return (
                <TeamEmpty team={team} />
            )
        }
        
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }

        return (
            <div>
                <TeamSlides 
                    team={team}
                    loading={loading} />
                <div style={{height: 50}}/>
                <div style={{paddingTop: 20}}>
                {this.renderGames()}
                {this.renderSchedules()}
                </div>
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamSchedules));
export { connectedApp as TeamSchedules };