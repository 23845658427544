import React, { Component } from "react";
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import {
    Button, Card, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import { PolicyEdit } from 'components/detail/organization/program/PolicyEdit';
import { renderHtmlString } from 'utils/Utils';

import { 
    MdPolicy
} from 'react-icons/md';
import { saveDivision } from "utils/API";
import { generateId } from "utils/API";

class DivisionPolicy extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            progress: false,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    onSaveClick(choice) {
        const { division } = this.props;
        if (choice.title && choice.body) {
            if (!choice.id) {
                choice.id = generateId('/division/' + division.id + '/policy');
            }
            
            if (!division.policy) {
                division.policy = {};
            }
            division.policy[choice.id] = choice;

            this.saveUpdate(division);
        } else {
            alert('You should fill out all issues');
        }
    }
    
    saveUpdate(division) {
        const {onChanged} = this.props;
        this.setState({progress: true})
        saveDivision(division, () => {
            alert(t('saved_successfully'))
            if (onChanged != null) {
                onChanged(division);
            }
            this.setState({progress: false})
        }, (error) => {
            console.log(error);
            alert(t('save_failed'))
        });
    }

    renderPolicyEdit() {
        const { policyChoice, progress } = this.state;

        return (
            <PolicyEdit
                policy={policyChoice}
                progress={progress}
                onClickSave={(update)=> {
                    this.onSaveClick(update);
                }}
                onCloseClick={()=> {
                    this.setState({policyChoice: null});
                }} />
        );
    }
    
    renderPolicyHeader(item) {

        return (
            <Card className="flex-row shadow-tile">
                <Row className='season-badge'>
                    <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                        <div className='wrap-content-parent' style={{marginLeft: 20}}>
                            <h4 className='wrap-content-wrap-child one-line-title' style={{marginRight: 15}}>
                            {item.title}
                            </h4>
                        </div>
                    </Col>
                    <Col nm={4} xs={12} sm={6}>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                <Button className="btn-submit" onClick={e => {
                                    this.setState({policyChoice: item, editMode: false});
                                }}>Details</Button>
                                <Button className="btn-edit" onClick={e => {
                                    this.setState({policyChoice: item, editMode: true});
                                }}>{t('edit')}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    }
    
    renderPolicyDetail() {
        const { policyChoice } = this.state;

        return (
            <div>
                <h2 className="post-detail-title">
                {policyChoice.title}
                </h2>
                <div className='app'>
                    {renderHtmlString(policyChoice.body ? policyChoice.body : "")}
                </div>
            </div>
        );
    }
    
    render() {
        const {division} = this.props;
        const {policyChoice, editMode} = this.state;
        
        let policyArr = [];
        if (division != null && division.policy != null) {
            for (const val of Object.values(division.policy)) {
                policyArr.push(val);
            }
        }

        return (
            <div style={{marginTop: 10}}>
                <ItemsNavigation
                    title="Privacy Policy"
                    icon={<MdPolicy/>}
                    detailOpen={policyChoice && !editMode}
                    editOpen={policyChoice && editMode}
                    totalCount={policyArr.length}
                    detailItem={policyChoice}
                    onClickBack={()=>{
                        this.setState({policyChoice: null, editMode: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({policyChoice: {}, editMode: true});
                    }} />

                {editMode && policyChoice != null && (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    {this.renderPolicyEdit()}
                </div>
                )}
                {!editMode && policyChoice != null && (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    {this.renderPolicyDetail()}
                </div>
                )}
                {!policyChoice && (
                <div style={{marginTop: 30}}>
                    {policyArr.map((value, idx)=>(
                        <div key={idx}>
                            {this.renderPolicyHeader(value)}
                        </div>
                    ))}
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(DivisionPolicy));
export { connectedApp as DivisionPolicy };
