import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';
import imagesIcon from 'assets/img/icons/images.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultBg from 'assets/img/bg/header-parallax-no-filter.jpg'
import playIcon from 'assets/img/icons/play_coral.png'
import youtubeIcon from 'assets/img/icons/youtube_icon.png'

import { timeAgo, renderHtmlString, getYoutubeThumbnail } from 'utils/Utils';

import {
    MdAccessTime,
} from 'react-icons/md';

import {
    GiHearts,
} from 'react-icons/gi';

import {
    FaComments,
} from 'react-icons/fa';

export class SambaPostHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }

    renderImageCountOverlay() {
        const {post} = this.props;

        const imageCount = post.galleryItem ? Object.values(post.galleryItem).length : 0;

        if (imageCount > 0) {
            return (
                <h3 style={{
                    position: 'absolute', 
                    top: 5, 
                    left: 5, 
                    borderRadius: 5, 
                    backgroundColor: 'rgba(0,0,0,.5)',
                    fontSize: 12,
                    padding: '2px 4px',
                    color: 'white'}}>
                    <img alt='icon' src={imagesIcon} style={{width: 20, height: 20}} /> {imageCount}
                </h3>
            )
        } else {
            return <div/>;
        }
    }
    
    renderGameInfo() {
        const {post} = this.props;

        if (post.gameInfo) {
            return (
                <div className="samba-post-game-info">
                    <img 
                        alt='home' className="samba-post-game-info-icon"
                        src={post.gameInfo.homeIcon ? post.gameInfo.homeIcon : teamIcon} /> 
                    <h3 className="samba-post-game-info-label">
                        {post.gameInfo.homeScore ? post.gameInfo.homeScore : 0} - {post.gameInfo.awayScore ? post.gameInfo.awayScore : 0}
                    </h3>
                    <img 
                        alt='away' className="samba-post-game-info-icon"
                        src={post.gameInfo.awayIcon ? post.gameInfo.awayIcon : teamIcon} /> 
                </div>
            )
        } else {
            return <div/>;
        }
    }
    
    render() {
        const {user, post, organization, onClickDetail } = this.props;
        
        const from = timeAgo(post.timestamp);
        const uid = user != null ? user.uid : null;
        const likeIds = post.likeIds != null ? Object.values(post.likeIds) : [];
        const comments = post.comments != null ? Object.values(post.comments) : [];

        let thumbnail = defaultBg;
        if (post.type === 'photo' && post.photoItem != null && post.photoItem.url != null && post.photoItem.url !== '') {
            thumbnail = post.photoItem.url;
        } else if (post.type === 'youtube' && (post.youtube || post.youtubeId)) {
            thumbnail = getYoutubeThumbnail(post);
        }
        const badgeColor = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : "#fa616a";
        let badgeText = null;
        if (post.gameId) {
            badgeText = t('highlights');
        } else if (post.teamId) {
            badgeText = t('team_post');
        } else if (!post.gameId && post.organizationId) {
            badgeText = t('organization_post');
        }
        
        if (post != null) {
            return (
                <div className='samba-post-item' onClick={(e) => {
                    if (onClickDetail) {
                        onClickDetail();
                    }
                }}>
                    <div style={{overflow: 'hidden'}}>
                        <div className="samba-post-media-normal-wrapper">
                            <div className="samba-post-media-child">
                                <CardImg 
                                    className='samba-post-thumb'
                                    src={thumbnail} />
                            </div>
                            <div className="samba-post-thumb-overlay" >
                                {post.type === 'video' && <img alt="video" src={playIcon} className="samba-post-thumb-overlay-icon" /> }
                                {post.type === 'youtube' && <img alt="video" src={youtubeIcon} className="samba-post-thumb-overlay-icon" /> }
                            </div>
                            {this.renderImageCountOverlay()}
                        </div>
                    </div>
                    <div className="samba-post-contents-normal-wrapper">
                        <div className="samba-badge-wrapper">
                            {badgeText && (
                            <div className="samba-post-badge" style={{backgroundColor: badgeColor}}>{badgeText}</div>
                            )}
                            <div className="wrap-content-fill-child" />
                            {this.renderGameInfo()}
                        </div>
                        <h4 className='samba-post-item-title'>
                        {post.title}
                        </h4>
                        <div className='post-item-footer'>
                            <div className='post-item-footer-section'>
                                <span style={likeIds.includes(uid) ? {color: 'red'} : {}}><GiHearts/></span> {likeIds.length}
                            </div>
                            <div className='post-item-footer-section'>
                                <FaComments/> {comments.length}
                            </div>
                            <div className='post-item-footer-section'>
                                <MdAccessTime/> {from}
                            </div>
                            <div className='post-item-footer-section'>
                                
                            </div>
                        </div>
                        <div className='samba-post-item-info-html'>
                            {renderHtmlString(post.body ? post.body : "")}
                        </div>
                        <div className='post-item-footer'>
                            <button className='detail-nav-btn post-item-footer-section' 
                                style={{color: '#444', textAlign: 'left', fontWeight: 800, fontSize: 12, textDecoration: 'underline'}}  onClick={(e) => {
                                if (onClickDetail) {
                                    onClickDetail();
                                }
                            }}>
                                {t('read_more')}
                            </button>
                            <div className='post-item-footer-section'>
                            </div>
                            <div className='post-item-footer-section'>
                            </div>
                            <div className='post-item-footer-section'>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SambaPostHeader);