import React, { Component } from "react";
import {
    CardImg, Col, Row, Modal, ModalBody
} from 'reactstrap';
import { t } from 'i18next';
import { 
    GiSoccerField,
    GiWhistle,
} from 'react-icons/gi';

import { toDateString, toSimpleTime } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import ItemsNavigation from "components/detail/ItemsNavigation";
import { GrArticle } from "react-icons/gr";
import { GameDetails } from "../GameDetails";

export class GamesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    renderGameLine(game) {
        const { teams, locations, users, wideMode } = this.props;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const timeStr = toSimpleTime(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        if (wideMode) {
            return (
                <Row className="samba-game-line" onClick={e=>{this.setState({gameChoice: game})}}>
                    <Col className="samba-game-line-cell hidden-xs" lg={2} sm={3} xs={12}>
                        {toDateString(game.start)} / {timeStr}
                    </Col>
                    <Col className="samba-game-line-info" lg={8} sm={6} xs={12}>
                        <div className="samba-game-line-main">
                            <div className="samba-game-line-team">{homeTeam.title}</div>
                            <CardImg
                                className="samba-game-line-icon"
                                src={homeThumb} />
                            <div className="samba-game-line-score">
                                {game.homeScore && game.homeScore !== -1 ? game.homeScore : "0"} - {game.awayScore && game.awayScore !== -1 ? game.awayScore : "0"}
                            </div>
                            <CardImg
                                className="samba-game-line-icon"
                                src={awayThumb} />
                            <div className="samba-game-line-team">{awayTeam.title}</div>
                        </div>
                    </Col>
                    <Col className="samba-game-line-cell" lg={2} sm={3} xs={12}>
                    <GiWhistle/> {referee.username ? referee.username : t('no_referee')} / <GiSoccerField/> {location.title ? location.title : t('no_location')}
                    </Col>
                </Row>
            )
        }

        return (
            <Row className="samba-game-line" onClick={e=>{this.setState({gameChoice: game})}}>
                <Col className="samba-game-line-cell" xs={6}>
                    {toDateString(game.start)}
                </Col>
                <Col className="samba-game-line-cell" xs={6}>
                    {timeStr}
                </Col>
                <Col className="samba-game-line-info" xs={12}>
                    <div className="samba-game-line-main">
                        <div className="samba-game-line-team">{homeTeam.title}</div>
                        <CardImg
                            className="samba-game-line-icon"
                            src={homeThumb} />
                        <div className="samba-game-line-score">
                            {game.homeScore && game.homeScore !== -1 ? game.homeScore : "0"} - {game.awayScore && game.awayScore !== -1 ? game.awayScore : "0"}
                        </div>
                        <CardImg
                            className="samba-game-line-icon"
                            src={awayThumb} />
                        <div className="samba-game-line-team">{awayTeam.title}</div>
                    </div>
                </Col>
                <Col className="samba-game-line-cell" xs={12}>
                <GiSoccerField/> {referee.username ? referee.username : t('no_referee')} / <GiSoccerField/> {location.title ? location.title : t('no_location')}
                </Col>
            </Row>
        )
    }
    
    render() {
        const { title, games, wideMode, organization, organizations, seasons, divisions, cards, posts, teams, locations, users } = this.props;
        const { gameChoice } = this.state;
        
        let items = [];
        
        for (const value of games) {
            items.push(this.renderGameLine(value));
            items.push(
                <div className='divider' />
            )
        }
        
        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={title ? title : t('games')}
                        icon={<GrArticle/>}
                        detailOpen={gameChoice != null}
                        totalCount={games.length}
                        detailItem={gameChoice}
                        onClickBack={()=>{
                            this.setState({gameChoice: null});
                        }} />
                </div>

                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        {wideMode && 
                        <Row className="samba-game-line-header">
                            <Col className="samba-game-line-cell" lg={2} sm={3} xs={0}>
                                {t('date_time')}
                            </Col>
                            <Col className="samba-game-line-info" lg={8} sm={6} xs={12}>
                                {t('match')}
                            </Col>
                            <Col className="samba-game-line-cell" lg={2} sm={3} xs={0}>
                                {t('location_referee')}
                            </Col>
                        </Row>
                        }
                        
                        <div className='divider' />
                        <div className='divider' />
                        <div>
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                {gameChoice && (
                    <Modal isOpen={true} style={{marginTop: 70}}>
                        <ModalBody>
                            <GameDetails 
                                game={gameChoice}
                                organization={organization ? organization : organizations[gameChoice.organizationId]}
                                locations={locations}
                                divisions={divisions}
                                seasons={seasons}
                                cards={cards}
                                teams={teams}
                                posts={posts}
                                users={users} 
                                onChanged={()=> {
                                }}
                                onClickClose={()=>{
                                    this.setState({gameChoice: null});
                                }} />
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    }

}

export default GamesTable;