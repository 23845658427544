import React, { Component } from "react";

import avatarIcon from 'assets/img/icons/icone-whatsapp-cliente.png'
import mobileDivider from 'assets/img/icons/mockup-celular-duvidas.png'
import { t } from "i18next";
import { GrMail, GrSkype } from "react-icons/gr";
import { FaTelegram, FaWhatsapp } from "react-icons/fa";


export class TalkToUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connected: 1500,
            downloads: 25000,
            developmentHours: 3000,
        }
    }

    render() {
        return (
            <div className="main-talk">
                <div className="container">
                    <div className="avatar">
                        <img alt="avatar" src={avatarIcon} />
                    </div>
                    <div class="info">
                        <h6>{t('talk_to_us_subtitle')}</h6>
                        <h3>{t('talk_to_us_title')}</h3>
                        <div class="desc">{t('talk_to_us_text')}</div>
                        <div class="botoes">
                            <a href="https://api.whatsapp.com/send/?phone=5582987465165&amp;text&amp;app_absent=0" target="noreferrer _blank" class="whatsapp">
                                <FaWhatsapp /> {t('talk_to_us_whatsapp')}
                            </a>
                            <span>{t('talk_to_us_also')}</span>
                            <a target="noreferrer _blank" href="mailto:contato@copafacil.com" class="also">
                                <GrMail />
                            </a>

                            <a target="noreferrer _blank" href="https://t.me/copafacil" class="also">
                                <FaTelegram />
                            </a>
                            <a target="noreferrer _blank" href="https://join.skype.com/invite/DJJ2cWyr4NyO" class="also">
                                <GrSkype />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="mobile-divder">
                    <img alt="mobile" src={mobileDivider} />
                </div>
            </div>
        );
    }
}

export default TalkToUs;
