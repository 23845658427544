import logoImage from 'assets/img/logo/live_192.png';
import React, { Component } from "react";
import { Spinner, CardImg, Button } from "reactstrap";
import { t } from "i18next";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { MdMenu, MdClose, MdWarning } from 'react-icons/md';
import { AuthForm, STATE_LOGIN, STATE_SIGNUP } from 'components/auth/AuthForm';
import {TeamToDo} from 'components/landing/team/TeamToDo';
import userIcon from 'assets/img/icons/player_photo_default.png';
import { 
    FaUsers
} from 'react-icons/fa';
import { getLogo, getTeamDomain, getTeamLocation, getTitle } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';

class TeamNav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerTransparent: window.pageYOffset < 60,
            windowWidth: window.innerWidth,
            menuOpen: false,
            authState: STATE_LOGIN
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        const { redirect, router } = this.props;
        this.props.setAuthRedirect(redirect ? redirect : router.location.pathname);
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { team, user, loggingIn, redirect, router } = this.props;
        if (user && user.uid && loggingIn === false && prevProps.loggingIn === true) {
            this.setState({showAuth: false});
            
            const needToJoin = user && (!team.playerIds || !Object.values(team.playerIds).includes(user.uid));
            if (needToJoin) {
                this.setState({showJoin: true});
            }
        }
        this.props.setAuthRedirect(redirect ? redirect : router.location.pathname);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateDimensions() {
        this.setState({windowWidth: window.innerWidth});
    }

    handleScroll(event) {
        let scrollTop = window.pageYOffset;
    
        this.setState({
            headerTransparent: scrollTop < 60
        });
    }

    renderAuthForm() {
        const { team, authMsg, authEmail } = this.props;
        const { authState } = this.state;
        const icon = team.iconUri != null ? team.iconUri : logoImage;

        return (
            <div className='team-auth' onClick={e=>{
                this.setState({showAuth: false})
            }}>

                <div className='team-auth-wrapper' onClick={e=>{
                    e.stopPropagation();
                    this.setState({showAuth: true})
                }}>
                    <AuthForm
                        showLogo={true}
                        logoIcon={icon}
                        authEmail={authEmail}
                        redirectTeam={team}
                        authState={authEmail ? STATE_SIGNUP : authState}
                        logoTitle={team.title}
                        logoView={authMsg ? authMsg : "Welcome to " + team.title}
                        onChangeAuthState={(e)=> {
                            this.setState({authState: e})
                        }} />
                </div>
            </div>
        )
    }

    renderFamilyItem(item) {
        const { team, user, child } = this.props;
        const userThumb = item.photoUri ? item.photoUri : userIcon;
        const userId = child ? child.uid : (user ? user.uid : null)
        const needToJoin = user && (!team.playerIds || !Object.values(team.playerIds).includes(item.uid));

        return (
            <div className='wrap-content-parent' style={{textAlign: 'left', margin: '5px 0px'}}>
                <CardImg
                    className="wrap-content-wrap-child row-badge-icon"
                    src={userThumb} />
                <h4 className='wrap-content-wrap-child row-badge-title'>
                {item.username}
                </h4>
                <div className='wrap-content-fill-child row-badge-info'>
                {needToJoin ? t('need_to_join') : t ('member')}
                </div>
                <Button className="grid-edit-btn" style={{fontSize: 12, height: 36}} onClick={e => {
                    if (this.props.setChild) {
                        this.props.setChild(item.uid === user.uid ? null : item);
                    }
                }}>{item.uid === userId ? t('picked') : t('pick')}</Button>
            </div>
        );
    }

    renderJoin() {
        const {team, users, user, child} = this.props;
        const {toggling, codeInput} = this.state;
        if (!user || !user.uid) {
            return <div/>
        }

        const childArr = [];

        if (users) {
            for (const item of Object.values(users)) {
                if (item.parentId === user.uid) {
                    childArr.push(item);
                }
            }
        }
        childArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        const items = [];
        if (user) {
            items.push(<div>{this.renderFamilyItem(user, true)}</div>)
            items.push(<div className='divider'/>)
        }
        for (const item of childArr) {
            items.push(<div>{this.renderFamilyItem(item, true)}</div>)
            items.push(<div className='divider'/>)
        }

        const choice = child ? child : user;
        const icon = team.iconUri != null ? team.iconUri : logoImage;

        const userThumb = choice.photoUri ? choice.photoUri : userIcon;
        const needToJoin = user && (!team.playerIds || !Object.values(team.playerIds).includes(choice.uid));
        const pending = user && team.pending && Object.values(team.pending).includes(choice.uid);
        const isMember = user && team.playerIds && Object.values(team.playerIds).includes(choice.uid);
        
        return (
            <div className='team-auth' onClick={e=>{
                this.setState({showJoin: false})
            }}>

                <div className='team-auth-wrapper' onClick={e=>{
                    e.stopPropagation();
                }}>
                    
                    <div className="text-center pb-4">
                        <img
                            src={icon}
                            className="rounded"
                            style={{ width: 60, height: 60, cursor: 'pointer' }}
                            alt="logo"
                        />
                        <h3 className='text-center'>{team.title}</h3>
                        {!pending && needToJoin && (<div className='text-center'>{t('join_team_prompt')}</div>)}
                        {pending && (<div className='text-center'>{t('join_team_pending_prompt')}</div>)}
                    </div>

                    {!pending && needToJoin && team.code && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Code to join
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({codeInput: e.target.value});
                                }} value={codeInput} />
                            </div>
                            {codeInput && team.code !== codeInput && (
                            <div style={{color: 'red', fontSize: 10}}> <MdWarning/> {t('invalid_team_code')} </div>
                            )}
                        </div>
                    </div>
                    )}
                    <div className='shadow-tile-table'>
                        
                        <div className='wrap-content-parent' style={{background: 'white', padding: '5px 10px'}}>
                            <h3>
                            <FaUsers/>
                            <CardImg
                                style={{width: 24, height: 24, marginLeft: 10, marginRight: 10}}
                                src={userThumb} />
                            {choice.username}
                            </h3>
                        </div>
                        <div className='divider'/>
                        <div className='divider'/>
                        {items}
                    </div>
                    {toggling ? 
                    <Spinner color="primary" /> : 
                    <div className='text-center'>
                        {!pending && needToJoin && (
                        <Button className={"btn-submit"} onClick={e=> {
                            this.submitJoin();
                        }}>
                            {t('join')}
                        </Button>
                        )}
                        {pending && (
                        <Button className={"btn-submit"} onClick={e=> {
                            this.submitJoin("cancel");
                        }}>
                            {t('cancel_request')}
                        </Button>
                        )}
                        {isMember && (
                        <Button className={"btn-submit"} onClick={e=> {
                            this.submitJoin("leave");
                        }}>
                            {t('leave')}
                        </Button>
                        )}
                        <Button className={"btn-cancel"} onClick={e=> {
                            this.setState({showJoin: false});
                        }}>
                            {t('close')}
                        </Button>
                    </div>
                    }
                </div>
            </div>
        )
    }

    submitJoin(action) {
        const {team, user, child, onTeamUpdate} = this.props;
        const {codeInput} = this.props;

        const params = {
            userId: child ? child.uid : user.uid,
            teamId: team.id,
            code: codeInput ? codeInput : "",
        }
        if (action) {
            params.action = action;
        }

        this.setState({toggling: true});
        invokeHttpsApi('userQuery-requestTeamPlayer', params, ((data)=>{
            if (data && data.success && data.team) {
                if (onTeamUpdate) {
                    onTeamUpdate(data.team);
                }
            }
            this.setState({toggling: false});
        }), ((error)=>{
            console.log('saveContents error', error);
            this.setState({toggling: false});
        }))
    }

    render() {
        const {team, authMsg, blockTransparent, user, child} = this.props;
        const {showAuth, showJoin, headerTransparent, windowWidth, menuOpen, toggling} = this.state;
        const homeLink = getTeamDomain(team);
        const location = getTeamLocation(this.props);
        
        //const trainingLink = homeLink + '/training';
        const communityLink = homeLink + '/community';
        const organizationsLink = homeLink + '/organizations';
        const schedulesLink = homeLink + '/schedules';
        const membersLink = homeLink + '/members';
        const aboutLink = homeLink + '/about';
        const contactLink = homeLink + '/contact';
        const accountLink = homeLink + '/account';
        const coachLink = homeLink + '/coach';
        
        const headerClasses = (!blockTransparent && headerTransparent ? "home-nav-title-inverse" : "home-nav-title");
        
        const icon = team.iconUri != null ? team.iconUri : logoImage;
        const actionBg = team && team.landingData && team.landingData.actionBg ? team.landingData.actionBg : null;
        const actionColor = team.landingData && team.landingData.actionColor ? team.landingData.actionColor : null;

        const choice = child ? child : user;
        const isContents = location === 'training' || location === 'schedules' || 
            location === 'community' || location === 'organizations' || location === 'members';
        const isMore = location === 'about' || location === 'contact';
        const isAccount = location === 'dashboard';

        const contentsClass = "dropdown-toggle " + (isContents ? "org-a-active" : "org-a");
        const moreClass = "dropdown-toggle " + (isMore ? "org-a-active" : "org-a");
        const accountClass = "dropdown-toggle " + (isAccount ? "org-a-active" : "org-a");
        let title = "Contents"
        if (location === 'training') {
            title = t('training');
        } else if (location === 'schedules') {
            title = t('schedules');
        } else if (location === 'community') {
            title = t('community');
        } else if (location === 'organizations') {
            title = t('organizations');
        } else if (location === 'members') {
            title = t('members');
        }
        let moreTitle = t('more');
        if (location === 'about') {
            moreTitle = t('about_us');
        } else if (location === 'contact') {
            moreTitle = t('contact_us');
        }

        const uid = child ? child.uid : (user ? user.uid : null);
        const userThumb = choice && choice.photoUri ? choice.photoUri : userIcon;
        var showAccount = team.owner === uid || 
            (team.playerIds && Object.values(team.playerIds).includes(uid)) || 
            (team.managers && Object.values(team.managers).includes(uid));
        const userId = user ? user.uid : null;
        var showCoach = team.owner === userId || 
            (team.managers && Object.values(team.managers).includes(userId));
        const needToJoin = user && (!team.playerIds || !Object.values(team.playerIds).includes(uid));
        const pending = user && team.pending && Object.values(team.pending).includes(uid);

        if (!team.id) {
            return (
                <div>
                    <div className='org-landing-header'>
                        <a className='org-landing-icon' href="/">
                            <img src={getLogo()} className="home-logo" alt='logo'/>
                            <div className={headerClasses}>{getTitle()} </div>
                        </a>
                        <ul className='nav org-landing-links'>
                            {(!user || !user.username) && (
                            <li className="org-landing-li">
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_LOGIN});
                                }}>
                                    {t('login')}
                                </button>
                            </li>
                            )}
                            <li className="org-landing-li">
                            {user != null ? (
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.props.logout();
                                }}>
                                    Logout
                                </button>
                            ) : (
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_SIGNUP});
                                }}>
                                    {t('sign_up')}
                                </button>
                            )}
                            </li>
                        </ul>
                    </div>
                    {(authMsg || showAuth) && (
                        <div>{this.renderAuthForm()}</div>
                    )}
                    {showJoin && (
                        <div>{this.renderJoin()}</div>
                    )}
                </div>
            );
        }
        if (!team.available) {
            if (windowWidth > 768)  {
                return (
                    <div>
                        <div className='org-landing-header'>
                            <a className='org-landing-icon' href={homeLink}>
                                <img src={icon} className="home-logo" alt='logo'/>
                                <div className={headerClasses}>{team.title} </div>
                            </a>
                            <ul className='nav org-landing-links'>
                                {choice && (
                                <li className='org-landing-li'>
                                    <div className="dropdown">
                                        <button 
                                        className={accountClass} 
                                        data-bs-toggle="dropdown" 
                                        style={isAccount && actionBg ? {borderColor: actionBg} : {}}
                                        href={accountLink}>
                                            <CardImg
                                            style={{width: 24, height: 24, marginRight: 10}}
                                            src={userThumb} />
                                            {choice.username}
                                        </button>
                                        <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">
                                            {showAccount && (
                                            <li className="dropdown-li">
                                                <a 
                                                href={accountLink} 
                                                style={location === 'dashboard' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                                className={location === 'dashboard' ? "org-a-active" : "org-a"}>
                                                    {t('my_account')}
                                                </a>
                                            </li>
                                            )}
                                            <li className="dropdown-li">
                                                <a 
                                                href='/my_account' 
                                                className="org-a">
                                                    {t('my_profile')}
                                                </a>
                                            </li>
                                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                                this.props.logout();
                                            }}>
                                                Logout
                                            </button>
                                        </ul>
                                    </div>   
                                </li>
                                )}
                                {needToJoin ? (
                                <li className="org-landing-li">
                                    <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                        this.setState({showJoin: true});
                                    }}>
                                        {pending ? t('requested_to_join') : t('join')}
                                    </button>
                                </li>
                                ) : (
                                <li className="org-landing-li">
                                    <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                        this.setState({showJoin: true});
                                    }}>
                                        {t('leave')}
                                    </button>
                                </li>
                                )}
                                {showCoach && (
                                <li className="org-landing-li">
                                    <a 
                                    href={coachLink} 
                                    style={location === 'coach' && actionBg ? {borderColor: actionBg} : {}}
                                    className={location === 'coach' ? "org-a-active" : "org-a"}>
                                        {t('coach')}
                                    </a>
                                </li>
                                )}
                                {(!user || !user.username) && (
                                <li className="org-landing-li">
                                    <button className="auth-btn" style={actionBg ? {backgroundColor: actionBg} : {}} onClick={e=> {
                                        this.setState({showAuth: true, authState: STATE_LOGIN});
                                    }}>
                                        {t('login')}
                                    </button>
                                </li>
                                )}
                                {(!user || !user.username) && (
                                <li className="org-landing-li">
                                    <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                        this.setState({showAuth: true, authState: STATE_SIGNUP});
                                    }}>
                                        {t('sign_up')}
                                    </button>
                                </li>
                                )}
                            </ul>
                        </div>
                        {(authMsg || showAuth) && (
                            <div>{this.renderAuthForm()}</div>
                        )}
                        {showJoin && (
                            <div>{this.renderJoin()}</div>
                        )}
                        {user && team && (
                            <TeamToDo
                                user={user}
                                team={team}
                                onClickCoach={()=> {
                                    this.props.router.navigate(coachLink)
                                }}
                                onClickPlayer={()=> {
                                    this.props.router.navigate(accountLink)
                                }} />
                        )}
                    </div>
                );
            } else {
                return (
                    <div style={{position: 'relative'}}>
                        <div className='org-landing-header'>
                            <a className='org-landing-links' href={homeLink}>
                                <img src={icon} className="home-logo" alt='logo'/>
                                <div className={headerClasses}>{team.title} </div>
                            </a>
                            <div className='org-landing-menu-toggle' onClick={(e)=> {
                                this.setState({menuOpen: !menuOpen})
                            }}>{menuOpen ? <MdClose/> : <MdMenu/> }</div>
                        </div>
                        {menuOpen && (
                        <ul className="org-landing-dropdown-menu" role="menu" aria-labelledby="dLabel">
                            {showAccount && (
                            <li className="dropdown-li">
                                <a 
                                href={accountLink} 
                                style={location === 'dashboard' && actionBg ? {borderColor: actionBg} : {}}
                                className={location === 'dashboard' ? "org-a-active" : "org-a"}>
                                {t('my_account')}
                                </a>
                            </li>
                            )}
                            {showCoach && (
                            <li className="dropdown-li">
                                <a 
                                href={coachLink} 
                                style={location === 'coach' && actionBg ? {borderColor: actionBg} : {}}
                                className={location === 'coach' ? "org-a-active" : "org-a"}>
                                {t('coach')}
                                </a>
                            </li>
                            )}
                            
                            {needToJoin ? (
                            <li className="org-landing-li">
                                {toggling ? 
                                <Spinner color="primary" /> : 
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showJoin: true});
                                }}>
                                    {pending ? t('requested_to_join') : t('join')}
                                </button>
                                }
                            </li>
                            ) : (
                            <li className="org-landing-li">
                                {toggling ? 
                                <Spinner color="primary" /> : 
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showJoin: true});
                                }}>
                                {t('leave')}
                                </button>
                                }
                            </li>
                            )}
                            {(!user || !user.username) && (
                                <li className="dropdown-li">
                                    <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                        this.setState({showAuth: true, authState: STATE_LOGIN});
                                    }}>
                                        {t('login')}
                                    </button>
                                </li>
                            )}     
                            <li className="dropdown-li">
                            {user != null ? (
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.props.logout();
                                }}>
                                    Logout
                                </button>
                            ) : (
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_SIGNUP});
                                }}>
                                    {t('sign_up')}
                                </button>
                            )}
                            </li>
                        </ul>
                        )}
                        {(authMsg || showAuth) && (
                            <div>{this.renderAuthForm()}</div>
                        )}
                        {showJoin && (
                            <div>{this.renderJoin()}</div>
                        )}
                        {user && team && (
                            <TeamToDo
                                user={user}
                                team={team}
                                onClickCoach={()=> {
                                    this.props.router.navigate(coachLink)
                                }}
                                onClickPlayer={()=> {
                                    this.props.router.navigate(accountLink)
                                }} />
                        )}
                    </div>
                );
            }
        }
        if (windowWidth > 768)  {
            return (
                <div>
                    <div className='org-landing-header'>
                        <a className='org-landing-icon' href={homeLink}>
                            <img src={icon} className="home-logo" alt='logo'/>
                            <div className={headerClasses}>{team.title} </div>
                        </a>
                        <ul className='nav org-landing-links'>
                            <li className='org-landing-li'>
                                <div className="dropdown">
                                    <button 
                                    className={contentsClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isContents && actionBg ? {borderColor: actionBg} : {}}
                                    href={schedulesLink}>
                                        {title}
                                    </button>
                                    <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">       
                                        {/* <li className="dropdown-li">
                                            <a 
                                            href={trainingLink} 
                                            style={location === 'training' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'training' ? "org-a-active" : "org-a"}>
                                                {t('training')}
                                            </a>
                                        </li> */}
                                        <li className="dropdown-li">
                                            <a 
                                            href={schedulesLink} 
                                            style={location === 'schedules' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'schedules' ? "org-a-active" : "org-a"}>
                                                {t('schedules')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={communityLink} 
                                            style={location === 'community' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'community' ? "org-a-active" : "org-a"}>
                                                {t('community')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={organizationsLink} 
                                            style={location === 'organizations' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'organizations' ? "org-a-active" : "org-a"}>
                                                {t('organizations')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={membersLink} 
                                            style={location === 'members' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'members' ? "org-a-active" : "org-a"}>
                                                {t('members')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>   
                            </li>
                            <li className='org-landing-li'>
                                <div className="dropdown">
                                    <button 
                                    className={moreClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isMore && actionBg ? {borderColor: actionBg} : {}}
                                    href={aboutLink}>
                                        {moreTitle}
                                    </button>
                                    <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">       
                                        <li className="dropdown-li">
                                            <a 
                                            href={aboutLink} 
                                            style={location === 'about' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'about' ? "org-a-active" : "org-a"}>
                                                {t('about_us')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={contactLink} 
                                            style={location === 'contact' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'contact' ? "org-a-active" : "org-a"}>
                                                {t('contact_us')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>   
                            </li>
                            {choice && (
                            <li className='org-landing-li'>
                                <div className="dropdown">
                                    <button 
                                    className={accountClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isAccount && actionBg ? {borderColor: actionBg} : {}}
                                    href={accountLink}>
                                        <CardImg
                                        style={{width: 24, height: 24, marginRight: 10}}
                                        src={userThumb} />
                                        {choice.username}
                                    </button>
                                    <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">
                                        {showAccount && (
                                        <li className="dropdown-li">
                                            <a 
                                            href={accountLink} 
                                            style={location === 'dashboard' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'dashboard' ? "org-a-active" : "org-a"}>
                                                {t('my_account')}
                                            </a>
                                        </li>
                                        )}
                                        <li className="dropdown-li">
                                            <a 
                                            href='/my_account' 
                                            className="org-a">
                                                {t('my_profile')}
                                            </a>
                                        </li>
                                        <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                            this.props.logout();
                                        }}>
                                            Logout
                                        </button>
                                    </ul>
                                </div>   
                            </li>
                            )}
                            {needToJoin ? (
                            <li className="org-landing-li">
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showJoin: true});
                                }}>
                                    {pending ? t('requested_to_join') : t('join')}
                                </button>
                            </li>
                            ): (
                            <li className="org-landing-li">
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showJoin: true});
                                }}>
                                    {t('leave')}
                                </button>
                            </li>
                            )}
                            {showCoach && (
                            <li className="org-landing-li">
                                <a 
                                href={coachLink} 
                                style={location === 'coach' && actionBg ? {borderColor: actionBg} : {}}
                                className={location === 'coach' ? "org-a-active" : "org-a"}>
                                    {t('coach')}
                                </a>
                            </li>
                            )}
                            {(!user || !user.username) && (
                            <li className="org-landing-li">
                                <button className="auth-btn" style={actionBg ? {backgroundColor: actionBg} : {}} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_LOGIN});
                                }}>
                                    {t('login')}
                                </button>
                            </li>
                            )}
                            {(!user || !user.username) && (
                            <li className="org-landing-li">
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_SIGNUP});
                                }}>
                                    {t('sign_up')}
                                </button>
                            </li>
                            )}
                        </ul>
                    </div>
                    {(authMsg || showAuth) && (
                        <div>{this.renderAuthForm()}</div>
                    )}
                    {showJoin && (
                        <div>{this.renderJoin()}</div>
                    )}
                    {user && team && (
                        <TeamToDo
                            user={user}
                            team={team}
                            onClickCoach={()=> {
                                this.props.router.navigate(coachLink)
                            }}
                            onClickPlayer={()=> {
                                this.props.router.navigate(accountLink)
                            }} />
                    )}
                </div>
            );
        } else {
            return (
                <div style={{position: 'relative'}}>
                    <div className='org-landing-header'>
                        <a className='org-landing-links' href={homeLink}>
                            <img src={icon} className="home-logo" alt='logo'/>
                            <div className={headerClasses}>{team.title} </div>
                        </a>
                        <div className='org-landing-menu-toggle' onClick={(e)=> {
                            this.setState({menuOpen: !menuOpen})
                        }}>{menuOpen ? <MdClose/> : <MdMenu/> }</div>
                    </div>
                    {menuOpen && (
                    <ul className="org-landing-dropdown-menu" role="menu" aria-labelledby="dLabel">
                        {/* <li className="dropdown-li">
                            <a 
                            href={trainingLink} 
                            style={location === 'training' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'training' ? "org-a-active" : "org-a"}>
                                {t('training')}
                            </a>
                        </li> */}
                        <li className="dropdown-li">
                            <a 
                            href={schedulesLink} 
                            style={location === 'schedules' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'schedules' ? "org-a-active" : "org-a"}>
                                {t('schedules')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={membersLink} 
                            style={location === 'members' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'members' ? "org-a-active" : "org-a"}>
                                {t('members')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={communityLink} 
                            style={location === 'community' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'community' ? "org-a-active" : "org-a"}>
                                {t('community')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={aboutLink} 
                            style={location === 'about' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'about' ? "org-a-active" : "org-a"}>
                                About Us
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={contactLink} 
                            style={location === 'contact' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'contact' ? "org-a-active" : "org-a"}>
                                {t('contact_us')}
                            </a>
                        </li>
                        {showAccount && (
                        <li className="dropdown-li">
                            <a 
                            href={accountLink} 
                            style={location === 'dashboard' && actionBg ? {borderColor: actionBg} : {}}
                            className={location === 'dashboard' ? "org-a-active" : "org-a"}>
                            {t('my_account')}
                            </a>
                        </li>
                        )}
                        {showCoach && (
                        <li className="dropdown-li">
                            <a 
                            href={coachLink} 
                            style={location === 'coach' && actionBg ? {borderColor: actionBg} : {}}
                            className={location === 'coach' ? "org-a-active" : "org-a"}>
                            {t('coach')}
                            </a>
                        </li>
                        )}
                        {needToJoin ? (
                        <li className="org-landing-li">
                            {toggling ? 
                            <Spinner color="primary" /> : 
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                this.setState({showJoin: true});
                            }}>
                                {pending ? t('requested_to_join') : t('join')}
                            </button>
                            }
                        </li>
                        ) : (
                        <li className="org-landing-li">
                            {toggling ? 
                            <Spinner color="primary" /> : 
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                this.setState({showJoin: true});
                            }}>
                                {t('leave')}
                            </button>
                            }
                        </li>
                        )}
                        {(!user || !user.username) && (
                            <li className="dropdown-li">
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_LOGIN});
                                }}>
                                    {t('login')}
                                </button>
                            </li>
                        )}     
                        <li className="dropdown-li">
                        {user != null ? (
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                this.props.logout();
                            }}>
                                Logout
                            </button>
                        ) : (
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                this.setState({showAuth: true, authState: STATE_SIGNUP});
                            }}>
                                {t('sign_up')}
                            </button>
                        )}
                        </li>
                    </ul>
                    )}
                    {(authMsg || showAuth) && (
                        <div>{this.renderAuthForm()}</div>
                    )}
                    {showJoin && (
                        <div>{this.renderJoin()}</div>
                    )}
                    {user && team && (
                        <TeamToDo
                            user={user}
                            team={team}
                            onClickCoach={()=> {
                                this.props.router.navigate(coachLink)
                            }}
                            onClickPlayer={()=> {
                                this.props.router.navigate(accountLink)
                            }} />
                    )}
                </div>
            );
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, child, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, child, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = withRouter(connect(mapState, actionCreators)(TeamNav));
export { connectedNavigation as TeamNav };
