import React, { Component } from "react";
import {
    Button, Row, Col, CardImg, Input, Spinner,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import teamIcon from 'assets/img/icons/club_icon_default.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import { checkMatches, checkMatchesVaild, sortByTitle } from 'utils/Utils';
import WeeklyBasedDeploy from "components/detail/organization/WeeklyBasedDeploy";
import LocationBasedDeploy from "components/detail/organization/LocationBasedDeploy";
import GameSetupBadge from "components/edit/organization/setup/GameSetupBadge";
import { SEASON_TYPE_PLAYOFF, STATUS_FINISHED } from "utils/constants";
import { saveSeason } from "utils/API";

class LeagueRoundSetup extends Component {
    constructor(props) {
        super(props);
        const subSeason = {};
        if (props.subSeason != null) {
            for (const [key, value] of Object.entries(props.subSeason)) {
                subSeason[key] = value;
            }
        }
        if (subSeason.deployType == null) {
            subSeason.deployType = 'weekBased';
        }

        const games = subSeason.games != null ? Object.values(subSeason.games) : [];
        const shouldOpen = !props.season || !props.season.teamIds || Object.values(props.season.teamIds) === 0;
        
        this.state = {
            subSeason: subSeason,
            pickTeams: shouldOpen,
            newImage: {},
            games: games,
            step: 0,
            imageProgress: 0,
            locationTimes: {},
            doubleAllow: false,
            matchCount: -1
        }
    }
    
    componentDidUpdate(prevProps) {
        const {season, isOpen, subSeason} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            const shouldOpen = !season || !season.teamIds || Object.values(season.teamIds) === 0;
            
            this.setState({
                pickTeams: shouldOpen,
                newImage: {},
                locationTimes: {},
                imageProgress: 0,
                doubleAllow: false,
                matchCount: -1
            })
        }
        if ((prevProps.subSeason !== subSeason)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(subSeason)) {
                challengeVal[key] = value;
            }
            const games = subSeason.games != null ? Object.values(subSeason.games) : [];
            this.setState({
                subSeason: challengeVal,
                games: games,
                doubleAllow: false,
                matchCount: -1
            })
        }
    }

    renderTeamItem(team, status) {
        const { onTeamChoice } = this.props;
        const { subSeason } = this.state;
        let thumbImage = team.iconUri != null ? team.iconUri : teamIcon;
        let playerCount = team.playerIds == null ? 0 : Object.values(team.playerIds).length;

        
        return (
            <Row key={team.id}>
                <Col lg={5} xs={8} sm={5} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon-small"
                            src={playersIcon} />
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {playerCount}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {status === 0 && t('member')}
                        {status === 1 && t('season')}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={12} sm={3} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                        </div>
                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                            {status === 0 && (
                                <div className='wrap-content-parent'>
                                <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                    if (onTeamChoice) {
                                        onTeamChoice(team);
                                    }
                                }}>{t('view')}</Button>
                                <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                    if (subSeason.teamIds && subSeason.teamIds[team.id]) {
                                        delete subSeason.teamIds[team.id];
                                    }
                                    
                                    this.setState({subSeason: subSeason});
                                }}>{t('remove')}</Button>
                                </div>
                            )}
                            {status === 1 && (
                                <div className='wrap-content-parent'>
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        if (subSeason.pendingIds && subSeason.pendingIds[team.id]) {
                                            delete subSeason.pendingIds[team.id];
                                        }
                                        if (!subSeason.teamIds) {
                                            subSeason.teamIds = {};
                                        }
                                        subSeason.teamIds[team.id] = team.id;
                                        this.setState({subSeason: subSeason});
                                    }}>{t('add')}</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-setup-step-header">
            <div className="season-setup-step-num">
                {num}
            </div>
            <div className="season-setup-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderRoundTeams() {
        const { season, teams, onCloseClick, onClickBack } = this.props;
        const { subSeason } = this.state;
        
        let roundTeams = [];
        let seasonTeams = [];
        
        if (subSeason.teamIds) {
            for (const key of Object.values(subSeason.teamIds)) {
                const team = teams[key];
                if (team) {
                    roundTeams.push(team);
                }
            }
        }
        
        if (season.teamIds) {
            for (const key of Object.values(season.teamIds)) {
                const team = teams[key];
                if (team && !roundTeams.includes(team)) {
                    seasonTeams.push(team);
                }
            }
        }
        
        sortByTitle(roundTeams);
        sortByTitle(seasonTeams);

        let items = [];
        for (const val of roundTeams) {
            items.push(
                this.renderTeamItem(val, 0)
            )
            items.push(<div className='divider' />);
        }
        for (const val of seasonTeams) {
            items.push(
                this.renderTeamItem(val, 1)
            )
            items.push(<div className='divider' />);
        }
        
        return (
            <div>
                {this.renderStepTitle("A", t('season_schedule_round_teams'))}
                
                <div className="overlay-limited-height">
                    <div className='shadow-tile'>
                        <Row style={{backgroundColor: '#19447F'}}>
                            <Col xs={12} className="vertical-center">
                                <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                    {t('teams')}: {roundTeams.length} members
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        <Row style={{backgroundColor: '#85CEEB'}}>
                            <Col lg={5} xs={7} sm={5} className="vertical-center">
                                <h3 className='row-table-title'>
                                    {t('team')}
                                </h3>
                            </Col>
                            <Col lg={2} xs={3} sm={2} className="vertical-center">
                                <h3 className='row-table-title'>
                                    {t('players')}
                                </h3>
                            </Col>
                            <Col lg={1} xs={2} sm={2} className="vertical-center">
                                <h3 className='row-table-title'>
                                    {t('status')}
                                </h3>
                            </Col>
                            <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items}
                    </div>
                </div>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        {onClickBack && (
                        <Button className='btn-cancel-blue' onClick={onClickBack}>{t('back')}</Button>
                        )}
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!subSeason.teamIds || Object.values(subSeason.teamIds).length < 2) {
                                alert(t("no_required_team_prompt"));
                                return;
                            }
                            
                            this.setState({step: 1});
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderGeneralSetup() {
        const { season, onCloseClick } = this.props;
        const { subSeason } = this.state;
        
        return (
            <div>
                {this.renderStepTitle("B", t('season_schedule_legaue_general'))}
                
                {season.type !== SEASON_TYPE_PLAYOFF && (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('winner_team_count')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="text" name="text" id="winerCount" onChange={e => {
                            subSeason.winnerCount = parseInt(e.target.value);
                            this.setState({subSeason: subSeason});
                        }} value={subSeason.winnerCount} />
                        </div>
                    </Col>
                </Row>
                )}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('deploy_type')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="text" id="deployType" onChange={e => {
                            subSeason.deployType = e.target.value;
                            this.setState({subSeason: subSeason});
                        }} value={subSeason.deployType}>
                            <option value={null}></option>
                            <option value="weekBased">{t('weekly_based')}</option>
                            <option value="locationBased">{t('location_based')}</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 0})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!subSeason.winnerCount || subSeason.winnerCount < 1) {
                                alert(t("no_round_info_prompt"));
                                return;
                            }
                            
                            this.setState({step: 2});
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderDeploySetup() {
        const { organization, season, division, locations, onCloseClick } = this.props;
        const { subSeason, games } = this.state;

        return (
            <div>
                {this.renderStepTitle("C", t('season_schedule_legaue_general'))}
                
                <div className="overlay-limited-height" style={{padding: '0px 20px'}}>
                    {(subSeason.deployType === 'weekBased') && (
                    <WeeklyBasedDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        division={division}
                        locations={locations}
                        onCompleteDeploy={(games, doubleAllow, matchCount)=>{
                            subSeason.doubleAllow = doubleAllow;
                            subSeason.matchCount = matchCount;
                            this.setState({games: games, subSeason: subSeason, step: 3});
                        }} />
                    )}
                    {(subSeason.deployType !== 'weekBased') && (
                    <LocationBasedDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        division={division}
                        locations={locations}
                        onCompleteDeploy={(games, doubleAllow, matchCount)=>{
                            subSeason.doubleAllow = doubleAllow;
                            subSeason.matchCount = matchCount;
                            this.setState({games: games, subSeason: subSeason, step: 3});
                        }} />
                    )}
                </div>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 1, games: null})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!games || games.length < 1) {
                                alert(t("no_round_game_prompt"));
                                return;
                            }
                            
                            this.setState({step: 3});
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderGamesVerify() {
        const { organization, season, division, teams, users, locations, onCloseClick, onSeasonUpdate } = this.props;
        const { subSeason, games, progress } = this.state;

        return (
            <div>
                {this.renderStepTitle("D", t('season_schedule_legaue_verify'))}
                
                <div className="overlay-limited-height" style={{padding: '0px 20px'}}>
                {games.map((value, idx)=>(
                    <GameSetupBadge
                        season={season}
                        subSeason={subSeason}
                        game={value}
                        teams={teams}
                        organization={organization}
                        division={division}
                        locations={locations}
                        users={users}
                        onHomeUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.homeTeam = update === "" ? null : update;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.setState({games: games});
                        }}
                        onAwayUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.awayTeam = update === "" ? null : update;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.setState({games: games});
                        }}
                        gameTimeUpdate={(start, end)=>{
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            if (start != null) {
                                value.start = start;
                            }
                            if (end != null) {
                                value.end = end;
                            }
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.setState({games: games});
                        }}
                        locationUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.locationId = update;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.setState({games: games});
                        }}
                        dayUpdate={(update)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.matchDayIndex = update;
                            checkMatches(games, subSeason.doubleAllow);
                            this.setState({games: games});
                        }}
                        refereeUpdate={(update, assists)=> {
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            value.refereeId = update;
                            value.referees = assists;
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.setState({games: games});
                        }}
                        removeClicked={()=> {
                            
                            if (value.status != null && value.status === STATUS_FINISHED) {
                                alert(t('game_change_not_allowed'))
                                return;
                            }
                            games.splice(idx, 1);
                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                            this.setState({games: games});
                        }}/>
                ))}
                </div>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 1, games: null})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {progress ? (
                        <Spinner />
                        ) : (
                        <Button className='btn-blue' onClick={e => {
                            if (!games || games.length < 1) {
                                alert(t("no_round_game_prompt"));
                                return;
                            }
                            if (!checkMatchesVaild(games, subSeason.doubleAllow, subSeason.matchCount)) {
                                alert(t("no_round_game_proper"));
                                return;
                            }

                            subSeason.games = {};
                            for (const game of games) {
                                subSeason.games[game.id] = game;
                            }
                            if (!season.subSeasons) {
                                season.subSeasons = {};
                            }
                            season.subSeasons[subSeason.id] = subSeason;

                            this.setState({progress: true});
                            saveSeason(season, (update) => {
                                this.setState({progress: false, subSeason: subSeason});
                            }, (error) => {
                                this.setState({progress: false})
                            })
                            
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('save')}</Button>
                        )}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                if (!season.subSeasons) {
                                    season.subSeasons = {};
                                }
                                season.subSeasons[subSeason.id] = subSeason;
                                onSeasonUpdate(season);
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const {step} = this.state;
        
        return (
            <div>
                <Row className='form-edit-row'>
                    <h4 className="text-center">{t("setup_league_round")}</h4>
                </Row>
                {step === 0 && this.renderRoundTeams()}
                {step === 1 && this.renderGeneralSetup()}
                {step === 2 && this.renderDeploySetup()}
                {step === 3 && this.renderGamesVerify()}
            </div>
        )
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(LeagueRoundSetup));
export { connectedApp as LeagueRoundSetup };
