export const SEASON_TYPE_LEAGUE = "league";
export const SEASON_TYPE_TOURNAMENT = "tournament";
export const SEASON_TYPE_PLAYOFF = "playoff";
export const SEASON_TYPE_CUSTOM = "custom";

export const ROUND_TYPE_LEAGUE = "league";
export const ROUND_TYPE_GROUP = "group";
export const ROUND_TYPE_KNOCKOUT = "knockout";

export const STATUS_REGISTER = "register";
export const STATUS_ONGOING = "ongoing";
export const STATUS_SCHEDULE = "schedule";
export const STATUS_FINISHED = "finished";
export const STATUS_PENDING = "pending";

export const GENDER_MALE = "Male";
export const GENDER_FEMALE = "Female";
export const GENDER_COED = "Coed";

export const devThreshold = 1714674124000;
export const POSITION_GOALKEEPER = "goalkeeper";
export const POSITION_DEFENDER = "defender";
export const POSITION_MIDFIELDER = "midfielder";
export const POSITION_FORWARD = "forward";

export const POSITION_ARRAY = [
  POSITION_GOALKEEPER,
  POSITION_DEFENDER,
  POSITION_MIDFIELDER,
  POSITION_FORWARD,
];

export const getShortName = (name) => {
  if (name.split(" ").length > 1) {
    let str = "";
    for (const word of name.split(" ")) {
      str += word.substring(0, 1).toUpperCase();
    }
    return str;
  } else if (name) {
    return name.substring(0, 2).toUpperCase();
  }
  return name;
}

export const NOTIFICATION_SYSTEM_STYLE = {
    NotificationItem: {
      DefaultStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
  
        borderRadius: '4px',
        fontSize: '14px',
      },
  
      success: {
        borderTop: 0,
        backgroundColor: '#45b649',
        WebkitBoxShadow: 0,
        MozBoxShadow: 0,
        boxShadow: 0,
      },
  
      error: {
        borderTop: 0,
        backgroundColor: '#f85032',
        WebkitBoxShadow: 0,
        MozBoxShadow: 0,
        boxShadow: 0,
      },
  
      warning: {
        borderTop: 0,
        backgroundColor: '#ffd700',
        WebkitBoxShadow: 0,
        MozBoxShadow: 0,
        boxShadow: 0,
      },
  
      info: {
        borderTop: 0,
        background: 'linear-gradient(to right, #6a82fb, #fc5c7d)',
        WebkitBoxShadow: 0,
        MozBoxShadow: 0,
        boxShadow: 0,
      },
    },
  
    Title: {
      DefaultStyle: {
        margin: 0,
        padding: 0,
        paddingRight: 5,
        color: '#fff',
        display: 'inline-flex',
        fontSize: 20,
        fontWeight: 'bold',
        // left: '15px',
        // position: 'absolute',
        // top: '50%',
      },
    },
  
    MessageWrapper: {
      DefaultStyle: {
        display: 'block',
        color: '#fff',
        width: '100%',
      },
    },
  
    Dismiss: {
      DefaultStyle: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'inherit',
        fontSize: 20,
        color: '#f2f2f2',
        position: 'relative',
        margin: 0,
        padding: 0,
        background: 'none',
        borderRadius: 0,
        opacity: 1,
        width: 20,
        height: 20,
        textAlign: 'initial',
        float: 'none',
        top: 'unset',
        right: 'unset',
        lineHeight: 'inherit',
      },
    },
  
    Action: {
      DefaultStyle: {
        background: '#fff',
        borderRadius: '2px',
        padding: '6px 20px',
        fontWeight: 'bold',
        margin: '10px 0 0 0',
        border: 0,
      },
  
      success: {
        backgroundColor: '#45b649',
        color: '#fff',
      },
  
      error: {
        backgroundColor: '#f85032',
        color: '#fff',
      },
  
      warning: {
        backgroundColor: '#ffd700',
        color: '#fff',
      },
  
      info: {
        backgroundColor: '#00c9ff',
        color: '#fff',
      },
    },
  
    ActionWrapper: {
      DefaultStyle: {
        margin: 0,
        padding: 0,
      },
    },
  };
  
export const SURVEY_TYPE_ADMIN = 0;
export const SURVEY_TYPE_TEACHERS = 1;
export const SURVEY_TYPE_STUDENTS = 2;
export const SURVEY_TYPE_GROUP = 3;

export const QUESTION_TYPE_OPTION = 0;
export const QUESTION_TYPE_CHECK = 1;
export const QUESTION_TYPE_TEXT = 2;
export const QUESTION_TYPE_RATING = 3;
export const GRADE_PARAMS = [
    {value: 'jk', title: 'Kindergarten Junior'},
    {value: 'sk', title: 'Kindergarten Senior'},
    {value: 'g1', title: 'Grade 1'},
    {value: 'g2', title: 'Grade 2'},
    {value: 'g3', title: 'Grade 3'},
    {value: 'g4', title: 'Grade 4'},
    {value: 'g5', title: 'Grade 5'},
    {value: 'g6', title: 'Grade 6'},
    {value: 'g7', title: 'Grade 7'},
    {value: 'g8', title: 'Grade 8'},
    {value: 'g9', title: 'Grade 9'},
    {value: 'g10', title: 'Grade 10'},
    {value: 'g11', title: 'Grade 11'},
    {value: 'g12', title: 'Grade 12'},
];