import React, { Component } from "react";
import {
    Card, CardBody, Button, Row, Col, CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import UserBadge from 'components/detail/UserBadge';
//import attCanceled from 'assets/img/icons/attendance_canceled.png';
import attGoing from 'assets/img/icons/attendance_going.png';
import attMaybe from 'assets/img/icons/attendance_maybe.png';
import attNotGoing from 'assets/img/icons/attendance_not_going.png';
import attPending from 'assets/img/icons/attendance_pending.png';

import { toTimeString } from 'utils/Utils';

import {
    MdAccessTime,
    MdLocationOn
} from 'react-icons/md';

export class ScheduleDetail extends Component {
    renderMembers() {
        const {users, schedule} = this.props;

        let result = [];
        if (schedule.members != null) {
            let availability = schedule.availability != null ? schedule.availability : {};
            for (const [key,] of Object.entries(schedule.members)) {
                let member = users[key];
                if (member != null) {

                    result.push(
                        this.renderMember(member, availability[key])
                    )
                }
            }
        }

        return result;
    }

    renderMember(member, availablity) {
        var icon;
        var title;
        switch (availablity) {
            case 0:
                icon = attPending;
                title = "Pending";
                break;
            case 1:
                icon = attMaybe;
                title = "Maybe";
                break;
            case 2:
                icon = attGoing;
                title = "Going";
                break;
            case 3:
                icon = attNotGoing;
                title = "Not Coming";
                break;
            default:
                icon = attPending;
                title = "Pending";
                break;
        }

        return(
            <Row>
                <Col sm={8} xs={8}>
                    <UserBadge customClass='wrap-content-wrap-child' user={member}/>
                </Col>
                <Col sm={4} xs={4}>
                    <CardImg
                        className="profile-thumb"
                        src={icon}
                        style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{title}</div>
                </Col>
            </Row>
        )
    }

    render() {
        const {onCloseClick, schedule} = this.props;
        
        const date = new Date(schedule.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();

        const from = toTimeString(schedule.timestamp);
        const to = toTimeString(schedule.deadline);
        
        if (schedule != null) {
            return (
                <div className="shadow-tile">
                    <Card className="flex-row shadow-tile">
                        <div style={{
                            width: 90, 
                            height: 90, 
                            marginLeft: 20, 
                            marginTop: 15, 
                            borderRadius: 10,
                            backgroundColor: '#F6F7FB'}}>
                            <div style={{
                                fontSize: 40,
                                fontWeight: 1000,
                                textAlign: 'center',
                                color: '#788492',
                            }}>
                                {day}
                            </div>
                            <div style={{
                                fontSize: 20,
                                fontWeight: 600,
                                textAlign: 'center',
                                color: '#B7BFC8',
                                marginTop: -5
                            }}>
                                {month}
                            </div>
                        </div>
                        <CardBody>
                            <h4 className='label label-default'>
                            {schedule.title}
                            </h4>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            <MdAccessTime/> {from} {" ~ "} {to} <MdLocationOn/> {"Health Room"}
                            </div>
                            <div className="challenge-progress wrap-content-wrap-child">
                                Program Schedule
                            </div>
                            <div className='wrap-content-parent' style={{marginTop: 5}}>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child">
                                    <Button className="btn-submit" onClick={e => {
                                        this.setState({detailOpen: true})
                                    }}>Details</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    <div style={{margin: '10px 20px'}}>
                        {this.renderMembers()}
                        <div className="divider" style={{margin: '10px 0px'}}/>
                        <Button className='btn-cancel' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ScheduleDetail);