import { OrganizationMembershipPrompt } from "components/membership/OrganizationMembershipPrompt";
import { t } from "i18next";
import React, { Component } from "react";

export class Plans extends Component {
    render() {
        return (
            <div className="main-plans">
                <div className="container">
                    <h2>{t('plans_title')}</h2>
                    <p>{t('plans_text')}</p>
                    <OrganizationMembershipPrompt forLeague={true} />
                </div>
            </div>
        );
    }
}

export default Plans;
