import React from 'react';
import { initializeApp } from 'firebase/app';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { store } from './redux/_helpers';
import 'trans/i18n';

var firebaseConfig = {
    apiKey: "AIzaSyCjAPXX086Ns4WcFddLUzSwXpohtjBj55E",
    authDomain: "live-edb25.firebaseapp.com",
    databaseURL: "https://live-edb25-default-rtdb.firebaseio.com/",
    projectId: "live-edb25",
    storageBucket: "live-edb25.appspot.com",
    /*messagingSenderId: "65211879809",
    appId: "1:65211879909:web:3ae38ef1cdcb2e01fe5f0c",
    measurementId: "G-8GSGZQ44ST"*/
};
  
initializeApp(firebaseConfig);
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
  