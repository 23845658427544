import React, { Component } from 'react';
import {TeamSlides} from 'components/landing/team/TeamSlides';
import { CardImg, Row, Col, Input, Spinner, Button } from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import { contact } from 'utils/landing';
import { GoogleMap, LoadScript, Marker, } from '@react-google-maps/api';
import { getOrgId } from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import { TeamEmpty } from 'components/landing/team/TeamEmpty';

class TeamContact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            teams: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    renderContents() {
        const { team, mailText, mailAddress, progress } = this.state;
        const landingData = (team && team.landingData) ? team.landingData : {};
        const contactData = landingData.contact ? landingData.contact : contact;

        const itemsArr = Object.values(contactData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];

        const bgClass = 'org-landing-contents-bg-blue';
        for (const item of itemsArr) {
            items.push((
                <div>
                    <h4 className="org-landing-contents-subtitle">
                    {item.subtitle}
                    </h4>
                    {item.bg != null && item.bg !== '' && (
                    <div className="post-media-wrapper">
                    <div className="post-media-child">
                        <CardImg 
                            className='task-image'
                            src={item.bg} />
                    </div>
                    </div>
                    )}
                    <div className="org-landing-contents-text">
                    {item.text}
                    </div>
                </div>
            ))
        }

        return (
            <div className={bgClass}>
                <Row className='contact-us-wrapper'>
                    <Col sm={4} xs={12}>
                        <h3 className="org-landing-contents-info-title" style={{marginTop: 40}}>
                        {t('contact_email')}
                        </h3>
                        <div className="org-landing-contents-info-text">
                        {contactData.email}
                        </div>
                        <h3 className="org-landing-contents-info-title">
                        {t('contact_phone_number')}
                        </h3>
                        <div className="org-landing-contents-info-text">
                        {contactData.phone}
                        </div>
                        {contactData.address && contactData.address.lat && contactData.address.lng && (
                        <div>
                            <h3 className="org-landing-contents-info-title">
                            Contact Address
                            </h3>
                            <div className="org-landing-contents-info-text">
                            {contactData.address.placeName}
                            </div>
                            <div className="org-landing-contents-text">
                            {contactData.address.formatted_address}
                            </div>
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                                <GoogleMap
                                    mapContainerStyle={{
                                        width: '100%',
                                        height: '150px'
                                    }}
                                    center={{lat: contactData.address.lat, lng: contactData.address.lng}}
                                    zoom={15}
                                    >
                                    <Marker title={contactData.address.placeName} position={{lat: contactData.address.lat, lng: contactData.address.lng}} >
                                    </Marker>
                                </GoogleMap>
                            </LoadScript>
                        </div>
                        )}
                        <h3 className="org-landing-contents-info-title" style={{marginTop: 40}}>
                        {t('email_address')}
                        </h3>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                this.setState({mailAddress: e.target.value});
                            }} value={mailAddress} />
                        </div>
                        <h3 className="org-landing-contents-info-title" style={{marginTop: 20}}>
                        {t('email_text')}
                        </h3>
                        <div className='form-setting-input' style={{marginBottom: 20}}>
                            <Input type="textarea" name="text" id="customDomain" onChange={e => {
                                this.setState({mailText: e.target.value});
                            }} value={mailText} />
                        </div>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <div style={{textAlign: 'end'}}>
                                <Button className='btn-submit' onClick={e => {
                                    if (!mailAddress || mailAddress.length === 0) {
                                        alert("You should input reply email address");
                                        return;
                                    }
                                    if (!mailText || mailText.length === 0) {
                                        alert("You should input reply email text to send");
                                        return;
                                    }
                                    if (!contactData.email || contactData.email.length === 0) {
                                        alert("Sorry. Contact email is not ready yet.");
                                        return;
                                    }
                                    this.sendEmail(contactData.email, mailAddress, mailText);
                                }}>Send</Button>
                            </div>
                        )}
                    </Col>
                    <Col sm={8} xs={12}>
                        <h2 className="org-landing-contents-title">
                        {contactData.title}
                        </h2>
                        {items}
                    </Col>
                </Row>
            </div>
        )
    }

    sendEmail(to, reply, text) {
        this.setState({progress: true});
        invokeHttpsApi('invite-sendContactEmail', {
            to: to,
            reply: reply,
            text: text,
        }, (data) => {
            if (data && data.success) {
                alert("Sent Email Successfully.");
                
                this.setState({progress: false});
            } else {
                alert("Sending Email failed. Please type valid one.");
                this.setState({progress: false});
            }
        }, (error) => {
            console.log('error', error);
            alert(error);
            this.setState({progress: false});
        });
    }

    render() {
        const {team, loading} = this.state;
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
        
        if (!team.available) {
            return (
                <TeamEmpty team={team}/>
            )
        }
        
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }

        return (
            <div>
                <TeamSlides 
                    team={team}
                    loading={loading} />
                <div style={{height: 50}}/>
                <div style={{paddingTop: 20}}>
                {this.renderContents()}
                </div>
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamContact));
export { connectedApp as TeamContact };