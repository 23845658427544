import React, { Component } from 'react';
import { t } from "i18next";
import { connect } from 'react-redux';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { userActions } from 'redux/_actions';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import LoadingView from 'components/detail/loading/LoadingView';
import { 
    Row, Col, Input, Button, Spinner
} from 'reactstrap';

import { toDateTimeString, toSimpleTime } from 'utils/Utils';
import { MdAccessTime } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';
import { generateId, invokeHttpsApi } from 'utils/API';
import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';
import { uploadImageFileToStorage } from 'utils/FirebaseUtils';
import { STATUS_FINISHED, STATUS_ONGOING, STATUS_PENDING } from 'utils/constants';

class OrganizationSupportView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            contacts: {},
            teams: {},
            newImage: {},
            imageProgress: 0,
            progress: false,
        };
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        const {organization} = this.props;

        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-loadContacts', {
            organizationId: organization ? organization.id : null
        }, ((data) => {

            const contacts = {};

            if (data && data.success) {
                for (const [key, value] of Object.entries(data.contacts)) {
                    contacts[key] = value;
                }
            }

            this.setState({
                contacts: contacts,
                loading: false,
            })
        }), ((error) => {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
            })
        }))
    }

    submitChangeWithImage(item, newImage) {
        this.setState({progress: true, imageProgress: 0});
        if (!item.id) {
            item.id = generateId("/contact");
        }
        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(item.id, 'contact', newImage, (url) => {
                item.thumbUri = url;
                
                this.submitChange(item);
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                this.setState({progress: false});
            })
        } else {
            this.submitChange(item)
        }
    }

    submitChange(item) {
        const {contacts} = this.state;

        item.timestamp = new Date().valueOf();

        this.setState({progress: true});
        invokeHttpsApi('organizerQuery-submitContact', {
            contact: item,
        }, ((data) => {

            if (data && data.success) {
                contacts[item.id] = item;
                
                this.setState({contacts: contacts, progress: false, newImage: {}});
            } else {
                this.setState({progress: false, newImage: {}});
            }
        }), ((error) => {
            console.log('loadContents error', error);
            this.setState({progress: false});
        }));
    }

    renderContactItem(item) {
        const date = new Date(item.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        
        const from = toSimpleTime(item.timestamp);
        const replied = item.replied ? toDateTimeString(item.replied) : null;
        
        return (
            <div className="shadow-tile wrap-content-parent" 
                style={{
                    marginLeft: 0, 
                    marginRight: 0,
                    }}>
                <div className="date-header wrap-content-wrap-child">
                    <div className="date-month">
                        {day}
                    </div>
                    <div className="date-day">
                        {month}
                    </div>
                </div>
                <div className='wrap-content-fill-child' style={{padding: '5px 10px'}}>
                    <Row>
                        <Col nm={8} xs={12} sm={6} className="info-col">
                            <div className='label label-default'>
                            {item.text}
                            </div>
                            <MdAccessTime/> {from}
                            {item.reply && (
                            <div className='label label-default' style={{textAlign: 'end'}}>
                            {item.reply}
                            </div>
                            )}
                            {replied && (
                            <div style={{textAlign: 'end'}}><MdAccessTime/> {replied}</div>
                            )}
                        </Col>
                        <Col nm={4} xs={12} sm={6} className="info-col">
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child'>
                                </div>
                                <h4 className="wrap-content-wrap-child">
                                    {item.status}
                                </h4>
                            </div>
                            <div className='wrap-content-parent' style={{marginTop: 5}}>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child">
                                    <Button className="btn-edit" onClick={e => {
                                        this.setState({toEdit: item});
                                    }}>{t('edit')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
    
    renderEdit(item) {
        const {newImage, imageProgress, progress} = this.state;
        
        if (item != null) {
            return (
                <div className='shadow-tile-fixed-center container' style={{backgroundColor: 'white'}}>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Contact Text
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="textarea" className='form-control' name="text" id="title" onChange={e => {
                                    item.text = e.target.value;
                                    this.setState({toEdit: item});
                                }} value={item.text} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Attatched Image
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <HorizontalAttachImageEdit
                                imageUrl={item.thumbUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Attatched Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </div>
                    </div>
                    
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div className='form-edit-row row'>
                            <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} >
                                <Button className='btn-submit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                    this.submitChangeWithImage(item, newImage);
                                }}>{t('submit')}</Button>
                            </div>
                            <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                                <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                    this.setState({toEdit: null});
                                }}>{t('close')}</Button>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }

    renderContents() {
        const { organization, user } = this.props;
        const { contacts, toEdit, statusChoice, searchStr } = this.state;
        
        const result = [];
        const list = [];
        
        if (user == null) {
            return <div/>
        }
        
        for (const[, value] of Object.entries(contacts)) {
            if (searchStr != null && searchStr.length > 0 && !value.text.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (!statusChoice || value.status === statusChoice) {
                result.push(value);
            }
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t1 - t2;
        })
        
        for (const value of result) {
            list.push(
                this.renderContactItem(value)
            );
        }

        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('support_contacts')}
                        icon={<BiSupport/>}
                        detailOpen={false}
                        editOpen={toEdit != null}
                        totalCount={list.length}
                        detailItem={toEdit ? toEdit: {}}
                        onClickAdd={()=> {
                            this.setState({toEdit: {
                                organizationId: organization ? organization.id : null
                            }})
                        }}
                        onClickBack={()=> {
                            this.setState({toEdit: null})
                        }} />
                </div>
                <Row className='items-navigation'>
                    <Col lg={9} sm={7} xs={12}>
                        <Input type="text" name="search" id="search" placeholder='Search ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </Col>
                    <Col lg = {3} sm={5} xs={12}>
                        <Input 
                            type="select" 
                            name="select" 
                            value={statusChoice} 
                            id="typeChoice"
                            onChange={e=> {
                                this.setState({statusChoice: e.target.value});
                            }}>
                            <option key="null" value={null}></option>
                            <option value={STATUS_PENDING}>{t('pending')}</option>
                            <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                            <option value={STATUS_FINISHED}>{t('finished')}</option>
                        </Input>
                    </Col>
                </Row>
                {toEdit ? this.renderEdit(toEdit) : list}
            </div>
        );
    }

    render() {
        const {loading} = this.state;

        return (
            <div>
                
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = connect(mapState, actionCreators)(OrganizationSupportView);
export { connectedApp as OrganizationSupportView };
