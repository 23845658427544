import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';

import LanguageSelector from 'components/LanguageSelector';
import { getOrgData, getOrgDomain } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import LoadingScreen from 'components/detail/loading/LoadingScreen';
import LoadingView from 'components/detail/loading/LoadingView';
import JsonData from 'components/landing/data/data.json';
import { BlueNavHeader } from 'components/landing/organization/BlueNavHeader';
import { FundraiseLanding } from 'components/fundraise/FundraiseLanding';
import { BlueGames } from 'components/landing/organization/BlueGames';
import { Services } from 'components/landing/organization/Services';
import { OrgCount } from 'components/landing/organization/OrgCount';
import { BlueServices } from 'components/landing/organization/BlueServices';
import { BluePosts } from 'components/landing/organization/BluePosts';
import { BlueVideo } from 'components/landing/blue/BlueVideo';
import { RegisterPrompt } from 'components/landing/organization/RegisterPrompt';
import { EmptyScreen } from 'components/detail/loading/EmptyScreen';
import { SambaGames } from 'components/landing/organization/samba/SambaGames';
import { Col, Row } from 'reactstrap';
import { SambaSlides } from 'components/landing/organization/samba/SambaSlides';
// import { SambaAds } from 'components/landing/organization/samba/SambaAds';
import { SambaServices } from 'components/landing/organization/samba/SambaServices';
import { SambaNews } from 'components/landing/organization/samba/SambaNews';
import { SambaRanking } from 'components/landing/organization/samba/SambaRanking';
import { OrganizationWallet } from 'components/wallet/OrganizationWallet';
import { OrganizationCart } from 'components/detail/payment/OrganizationCart';
import { NavHeader } from 'components/landing/organization/NavHeader';
import { Sponsers } from 'components/landing/organization/Sponsers';
import { Posts } from 'components/landing/organization/Posts';
import { Games } from 'components/landing/organization/Games';
import { Video } from 'components/landing/organization/Video';
//import fundBg from 'assets/img/bg/background_1920-11.jpg';
import { filterActions } from 'redux/_actions/filter.actions';

class OrgHome extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            following: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            posts: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            refreshTime: 0,
        }
    }

    getlandingPageData() {
        this.setState({landingPageData : JsonData})
    }

    componentDidMount() {
        if (this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.getlandingPageData();
        this.loadContents();
    }

    loadContents() {
        let id = '';
        const orgData = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizationLandingQuery', orgData, ((data)=>{
            
            const organizations = {};
            const organization = {};
            const following = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const fundraises = {};
            const fundraisePayments = {};

            if (data.success) {
                this.props.updateDict(data);
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                if (organizations[id] != null) {
                    for (const [key, value] of Object.entries(organizations[id])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.following)) {
                    following[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraises)) {
                    fundraises[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraisePayments)) {
                    fundraisePayments[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                following: following,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                fundraises: fundraises,
                fundraisePayments: fundraisePayments,
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderSamba() {
        const {user} = this.props;
        const {organization, /*fundraises, fundraisePayments,*/ users, teams, locations, posts, seasons, divisions, loading, cards, /*profileData*/} = this.state;
        

        if (loading && !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        if (!this.state.landingPageData.About) {
            return (
                <SmallLoadingView />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        
        return (
            <div className='container' style={{padding: 0}}>
                
                <Row className='samba-row'>
                    <Col className='samba-col-l' lg={8} sm={6} xs={12}>
                        <SambaSlides
                            organization={organization}
                            loading={loading} />
                            
                    </Col>
                    <Col className='samba-col-r' lg={4} sm={6} xs={12}>
                        <SambaServices
                            organization={organization}
                            loading={loading} />

                        <div style={{marginTop: 20}} />
                        {/*
                        <OrgCount 
                            organization={organization}
                            profileData={profileData}
                            seasons={seasons} /> */}
                    </Col>
                </Row>
                
                <SambaGames 
                    organization={organization}
                    loading={loading}
                    user={user}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
{/* 
                {fundraises && Object.values(fundraises).length > 0 && (
                <div style={{backgroundImage: fundBg}}>
                    <FundraiseLanding 
                        organization={organization}
                        loading={loading}
                        user={user}
                        fundraises={fundraises}
                        fundraisePayments={fundraisePayments}
                        onChanged={()=> {
                            this.loadContents();
                        }} />
                </div>
                )}
                 */}
                <div style={{height: 20}} />

                <Row className='samba-row'>
                    <Col className='samba-col-l' lg={8} sm={6} xs={12}>
                        <SambaNews 
                            loading={loading}
                            organization={organization}
                            user={user}
                            users={users}
                            seasons={seasons}
                            divisions={divisions}
                            teams={teams}
                            posts={posts}
                            locations={locations}
                            onChanged={()=> {
                                this.loadContents();
                            }} />
                    </Col>
                    <Col className='samba-col-r' lg={4} sm={6} xs={12}>
                        <SambaRanking
                            organization={organization}
                            seasons={seasons}
                            teams={teams} />
                    </Col>
                </Row>
                <Sponsers 
                    showTitle={true}
                    organization={organization} />
            </div>
        );
    }

    renderBlue() {
        const {user} = this.props;
        const {organization, fundraises, fundraisePayments, users, teams, locations, posts, seasons, divisions, loading, cards, profileData, refreshTime} = this.state;
        
        const homeLink = getOrgDomain(organization);
        if (loading && !organization.id) {
            return (
                <LoadingScreen />
            )
        }
        if (!this.state.landingPageData.About) {
            return (
                <LoadingScreen />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        
        return (
            <div>
                <BlueNavHeader 
                    organization={organization}
                    loading={loading} />
                <OrgCount 
                    organization={organization}
                    profileData={profileData}
                    seasons={seasons} />
                {fundraises && (
                <FundraiseLanding 
                    organization={organization}
                    loading={loading}
                    user={user}
                    fundraises={fundraises}
                    fundraisePayments={fundraisePayments}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                )}
                <BlueGames 
                    organization={organization}
                    loading={loading}
                    user={user}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                <BlueServices 
                    loading={loading}
                    organization={organization} />
                <BluePosts 
                    loading={loading}
                    organization={organization}
                    user={user}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                <BlueVideo 
                    loading={loading}
                    video={organization && organization.landingData && organization.landingData.video ? organization.landingData.video : null} />
                <div id="footer" style={{backgroundColor: '#3684bb', color: 'white'}}>
                    <Sponsers 
                        showTitle={true}
                        organization={organization} />
                    <div className="container text-center">
                        <p style={{color: '#ddd'}}>
                        &copy; {t('copyright_text')}
                        <a href="/terms-of-services" rel="nofollow" style={{color: 'white'}}>
                            Live! Inc
                        </a>
                        </p>
                    </div>
                </div>
                <RegisterPrompt
                    loadin={loading} 
                    refreshTime={refreshTime}
                    onClickRegister={e=> {
                        this.props.router.navigate(homeLink + '/account');
                    }}
                    onClickLogin={e=> {
                        this.props.setAuthRedirect(homeLink);
                        this.props.router.navigate('/login')
                    }} />
            </div>
        );
    }

    renderDefault() {
        const {user} = this.props;
        const {organization, fundraises, fundraisePayments, users, teams, locations, posts, seasons, divisions, loading, cards, profileData} = this.state;
        
        
        if (loading && !organization.id) {
            return (
                <LoadingScreen />
            )
        }
        if (!this.state.landingPageData.About) {
            return (
                <LoadingScreen />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        
        return (
            <div>
                <NavHeader 
                    organization={organization}
                    loading={loading} />
                <OrgCount 
                    organization={organization}
                    profileData={profileData}
                    seasons={seasons} />
                {fundraises && (
                <FundraiseLanding 
                    organization={organization}
                    loading={loading}
                    user={user}
                    fundraises={fundraises}
                    fundraisePayments={fundraisePayments}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                )}
                <Games 
                    organization={organization}
                    loading={loading}
                    user={user}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                <Services 
                    loading={loading}
                    organization={organization} />
                <Posts 
                    loading={loading}
                    organization={organization}
                    user={user}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                <Video 
                    loading={loading}
                    organization={organization} />
                <div id="footer">
                <Sponsers 
                    showTitle={false}
                    organization={organization} />
                <div className="container text-center">
                    <p>
                    &copy; {t('copyright_text')}
                    <a href="/terms-of-services" rel="nofollow">
                        Live! Inc
                    </a>
                    </p>
                </div>
                </div>
            </div>
        );
    }

    renderContents() {
        const {organization} = this.state;
        if (organization.landingData && organization.landingData.theme === 'blue') {
            return (
                <div>
                    {this.renderBlue()}
                </div>
            )
        } else if (organization.landingData && organization.landingData.theme === 'samba') {
            return (
                <div>
                    {this.renderSamba()}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderDefault()}
                </div>
            )
        }
    }

    render() {
        const {loading, organization, refreshTime} = this.state;
        const homeLink = getOrgDomain(organization);

        if (loading || !organization.id) {
            return (
                <LoadingView />
            )
        }
        
        return (
            <div>
                {this.renderContents()}
                <RegisterPrompt
                    loadin={loading} 
                    refreshTime={refreshTime}
                    onClickRegister={e=> {
                        this.props.router.navigate(homeLink + '/account');
                    }}
                    onClickLogin={e=> {
                        this.props.setAuthRedirect(homeLink);
                        this.props.router.navigate('/login')
                    }} />
                <OrganizationWallet
                    organization={organization} />
                <OrganizationCart
                    organization={organization} />
                <LanguageSelector />
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    updateDict: filterActions.updateDict,
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgHome));
export { connectedPage as OrgHome };
