import React, { Component } from 'react';

import DivisionTrophyEdit from 'components/edit/organization/DivisionTrophyEdit';
import DivisionTrophyBadge from 'components/detail/organization/DivisionTrophyBadge';
import DivisionTrophyDetail from 'components/detail/organization/DivisionTrophyDetail';
import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import { 
    GiTrophyCup,
} from 'react-icons/gi';

export class DivisionTrophy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trophyChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }

    renderTrophies() {
        const {division, trophies} = this.props;

        let items = [];
        let members = [];
        for (const [, value] of Object.entries(trophies)) {
            if (value.divisionId === division.id) {
                members.push(value);
            }
        }
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        
        for (const value of members) {
            items.push(
                this.renderBadge(division, value)
            );
        }
        
        return (
        <div>
            {items}
        </div>
        );
    }

    renderBadge(division, trophy) {
        
        return (
            <div style={{marginTop: 10, marginBottom: 10}}>
                <DivisionTrophyBadge 
                    key={trophy.id}
                    trophy={trophy}
                    division={division}
                    allowDetail={true}
                    onClickDetails={()=> {
                        this.setState({trophyChoice: trophy, detailOpen: true, editOpen: false})
                    }}
                    onClickEdit={()=> {
                        this.setState({trophyChoice: trophy, detailOpen: false, editOpen: true})
                    }} />
            </div>
        );
    }

    renderContents() {
        const {organization, division, seasons, teams, onSeasonChoice, onChanged} = this.props;
        const {trophyChoice, detailOpen, editOpen} = this.state;

        if (trophyChoice.id != null && detailOpen) {
            return (
                <DivisionTrophyDetail
                    organization={organization}
                    division={division}
                    trophy={trophyChoice}
                    seasons={seasons}
                    teams={teams}
                    onSeasonChoice={(season)=>{
                        if (onSeasonChoice != null) {
                            onSeasonChoice(season);
                        }
                    }}
                    onCloseEdit={()=>{
                        this.setState({trophyChoice: trophyChoice, detailOpen: false, editOpen: true})
                    }}
                    onCloseClick={()=>{
                        this.setState({trophyChoice: {}, detailOpen: false, editOpen: false})
                    }} />
            );
        } else if (trophyChoice != null && editOpen) {
            return (
                <DivisionTrophyEdit
                    organization={organization}
                    division={division}
                    trophy={trophyChoice}
                    onSaveDone={()=>{
                        if (onChanged != null) {
                            onChanged();
                        }
                    }}
                    onCloseClick={()=>{
                        this.setState({trophyChoice: {}, detailOpen: false, editOpen: false})
                    }} />
            );
        } else {
            return (
                <div>
                    {this.renderTrophies()}
                </div>
            )
        }
    }
    
    render() {
        const {division, trophies} = this.props;
        const {trophyChoice, detailOpen, editOpen} = this.state;
        
        let members = [];
        for (const [, value] of Object.entries(trophies)) {
            if (value.divisionId === division.id) {
                members.push(value);
            }
        }

        return (
            <div>
                <ItemsDrowpdownNav
                        title="Division Trophies"
                        choiceTitle="Choose a Trophy"
                        createTitle="Create New Trophy"
                        icon={<GiTrophyCup/>}
                        dropDownArray={members}
                        editOpen={editOpen}
                        detailOpen={detailOpen}
                        detailItem={trophyChoice}
                        hideNoChoice={true}
                        onItemChoice={(value)=>{
                            this.setState({trophyChoice: value, detailOpen: true, editOpen: false})
                        }}
                        onClickAdd={()=>{
                            this.setState({trophyChoice: {}, editOpen: true, detailOpen: false})
                        }}
                        onClickBack={()=>{
                            this.setState({trophyChoice: {}, detailOpen: false, editOpen: false})
                        }} />

                {this.renderContents()}
            </div>
        );
    }
}

export default DivisionTrophy;
