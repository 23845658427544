import React, { Component } from "react";

import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import {
    Button, Spinner, Row, Col, CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import defaultPlayerImage from 'assets/img/icons/player_photo_default.png';
import defaultTrophyImage from 'assets/img/icons/leagues_icon.png';
import {GiMedal, GiRunningShoe} from 'react-icons/gi';
import { dbTimestamp } from "utils/API";
import { SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT } from "utils/constants";
import CustomTabView from "components/template/CustomTabView";

class SeasonFinishedView extends Component {
    constructor(props) {
        super(props);
        const season = {};
        if (props.season != null) {
            for (const [key,val] of Object.entries(props.season)) {
                season[key] = val;
            }
        }
        this.state = {
            season: season,
            rankingData: [],
            groupRankings: {},
            editOngoing: false,
        }
    }

    componentDidUpdate(prevProps) {
        const {season} = this.props;
        if (prevProps.season !== season) {
            const update = {};
            if (season != null) {
                for (const [key,val] of Object.entries(season)) {
                    update[key] = val;
                }
            }
            this.setState({
                season: update,
                detailOpen: false,
                editOpen: false,
                mainTab: 0
            });
        } 
    }
    
    renderStanding() {
        const {teams, trophies} = this.props;
        const {season} = this.state;
        const trophy = trophies[season.trophyId];
        const winner = teams[season.winner] ? teams[season.winner] : {};
        let thumbImage = winner.iconUri != null ? winner.iconUri : defaultImage;
        let trophyImage = trophy && trophy.thumbUri != null ? trophy.thumbUri : defaultTrophyImage;

        let items = [];
        if (season.ranking != null) {
            let ranking = Object.values(season.ranking)
            ranking.sort((a,b)=> {
                return a.ranking - b.ranking
            })
            for (const rank of ranking) {
                const team = teams[rank.id];
                if (team != null) {
                    items.push(
                        this.renderStandingBadge(team, rank.ranking + 1)
                    )
                }
            }
        }    

        return (
            <div className='shadow-tile'>
                <Row style={{backgroundColor: '#19447F'}}>
                    <Col xs={12} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={trophyImage} />
                            <h3 className='wrap-content-wrap-child row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {t('winner')}: 
                            </h3>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title' style={{color: 'white'}}>
                            {winner.title}
                            </h4>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                <Row>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            #
                        </h3>
                    </Col>
                    <Col lg={5} xs={5} sm={5} className="vertical-center">
                        <h3 className='row-table-title'>
                            Team
                        </h3>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                    {season.type === SEASON_TYPE_LEAGUE && (
                        <h3 className='row-table-title'>
                            Pt
                        </h3>
                    )}
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            W
                        </h3>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            L
                        </h3>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            G
                        </h3>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            GD
                        </h3>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            Cup
                        </h3>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_data')}
                    </div>
                )}
            </div>
        )
    }

    renderStandingBadge(team, rank) {
        const {trophies} = this.props;
        const {season} = this.state;
        let item = {};
        if (season.type === SEASON_TYPE_LEAGUE) {
            item = season.subSeasons.league.pointTable[team.id] != null ? season.subSeasons.league.pointTable[team.id] : {};
        } else if (season.type === SEASON_TYPE_TOURNAMENT) {
            item = season.subSeasons.knockout.pointTable[team.id] != null ? season.subSeasons.knockout.pointTable[team.id] : {};
        } else if (season.type === SEASON_TYPE_PLAYOFF) {
            item = season.subSeasons.knockout.pointTable[team.id] != null ? season.subSeasons.knockout.pointTable[team.id] : {};
        } else if (season.type === SEASON_TYPE_CUSTOM) {
            item = season.subSeasons.knockout.pointTable[team.id] != null ? season.subSeasons.knockout.pointTable[team.id] : {};
        }
        const trophy = trophies[season.trophyId];
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;
        let trophyImage = trophy && trophy.thumbUri != null ? trophy.thumbUri : defaultTrophyImage;

        return (
            <Row onClick={e=> {
                if (season.type === SEASON_TYPE_LEAGUE) {
                    season.winner = team.id;
                    season.winnerDate = dbTimestamp();
                    this.setState({season: season})
                }
            }}>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                    {season.type === SEASON_TYPE_LEAGUE && (
                        <h3 className='row-table-title'>
                        {item.points}
                        </h3>
                    )}
                    </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.win}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.loss}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.scores}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.scoreDiff}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                {season.winner === item.id && (
                    <CardImg
                        className="wrap-content-wrap-child row-badge-icon"
                        src={trophyImage} />
                )}
                </Col>
            </Row>
        );
    }

    getPlayerData(season) {
        const {users} = this.props;
        
        let result = {};
        if (season.subSeasons != null) {
            for (const subSeason of Object.values(season.subSeasons)) {
                if (subSeason.games != null) {
                    for (const game of Object.values(subSeason.games)) {
                        if (game.scores != null) {
                            for (const score of Object.values(game.scores)) {
                                let user = users[score.uid] != null ? users[score.uid] : {};
                                let item = result[score.uid] != null ? result[score.uid] : {uid: score.uid, score: 0, assist: 0, mom: 0};
                                item.user = user;
                                item.score += 1;
                                item.teamId = score.teamId;
                                result[item.uid] = item;
                                if (score.assist != null) {
                                    let user = users[score.assist] != null ? users[score.assist] : {};
                                    let assist = result[score.assist] != null ? result[score.assist] : {uid: score.assist, score: 0, assist: 0, mom: 0};
                                    assist.user = user;
                                    assist.assist += 1;
                                    assist.teamId = score.teamId;
                                    result[assist.uid] = assist;
                                }
                            }
                        }
                        if (game.mom != null) {
                            for (const momItem of Object.values(game.mom)) {
                                let user = users[momItem.uid] != null ? users[momItem.uid] : {};
                                let mom = result[momItem.uid] != null ? result[momItem.uid] : {uid: momItem.uid, score: 0, assist: 0, mom: 0};
                                mom.user = user;
                                mom.mom += 1;
                                mom.teamId = momItem.teamId;
                                result[mom.uid] = mom;
                            }
                        }
                    }
                }
            }
        }
        return Object.values(result);
    }
    
    renderTopPlayer() {
        const {users} = this.props;
        const {season} = this.state;
        let pointItems = this.getPlayerData(season);
        pointItems.sort((x,y)=>{
            const a = x.user;
            const b = y.user;
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        pointItems.sort((a,b)=> {
            return b.score - a.score;
        })
        pointItems.sort((a,b)=> {
            return b.mom - a.mom;
        })

        let items = [];
        let rank = 0;
        for (const val of pointItems) {
            rank ++;
            items.push(
                this.renderTopPlayerBadge(val, rank)
            )
            items.push(<div className='divider' />);
            if (rank === 10) {
                break;
            }
        }
        const toShow = users[season.mos]
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <div className='wrap-content-parent'>
                                <h3 className='wrap-content-wrap-child row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                    Man of Season
                                </h3>
                                {toShow != null && (
                                    <div className='wrap-content-parent'>
                                        <CardImg
                                            className="wrap-content-wrap-child row-badge-icon"
                                            src={toShow.photoUri != null ? toShow.photoUri : defaultPlayerImage} />
                                        <h4 className='wrap-content-fill-child row-badge-title' style={{color: 'white'}}>
                                        {toShow.username}
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                Player
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                MOM
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                G
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                A
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                MOS
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTopPlayerBadge(item, rank) {        
        const {teams} = this.props;
        const {season} = this.state;
        const team = teams[item.teamId];
        let thumbImage = item.user.photoUri != null ? item.user.photoUri : defaultPlayerImage;

        return (
            <Row key={item.uid} onClick={e=> {
                season.mos = item.uid;
                this.setState({season: season})
            }}>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {item.user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                {team != null && (
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={team.iconUri != null ? team.iconUri : defaultImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                )}
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.mom}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.score}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.assist}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                {item.uid === season.mos && (
                    <h3 className='row-table-title' style={{color: '#FF8E53'}}>
                        <GiMedal/>
                    </h3>
                )}
                </Col>
            </Row>
        );
    }

    renderTopScore() {
        const {users} = this.props;
        const {season} = this.state;
        let pointItems = this.getPlayerData(season);
        pointItems.sort((x,y)=>{
            const a = x.user;
            const b = y.user;
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        pointItems.sort((a,b)=> {
            return b.score - a.score;
        })

        let items = [];
        let rank = 0;
        for (const val of pointItems) {
            rank ++;
            items.push(
                this.renderTopScoreBadge(val, rank)
            )
            items.push(<div className='divider' />);
            if (rank === 10) {
                break;
            }
        }
        const toShow = users[season.goldenShoot]
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <div className='wrap-content-parent'>
                                <h3 className='wrap-content-wrap-child row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                    Man of Top Scores
                                </h3>
                                {toShow != null && (
                                    <div className='wrap-content-parent'>
                                        <CardImg
                                            className="wrap-content-wrap-child row-badge-icon"
                                            src={toShow.photoUri != null ? toShow.photoUri : defaultPlayerImage} />
                                        <h4 className='wrap-content-fill-child row-badge-title' style={{color: 'white'}}>
                                        {toShow.username}
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                Player
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                G
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                A
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                MOM
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                GS
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTopScoreBadge(item, rank) {        
        const {teams} = this.props;
        const {season} = this.state;
        const team = teams[item.teamId];
        let thumbImage = item.user.photoUri != null ? item.user.photoUri : defaultPlayerImage;

        return (
            <Row onClick={e=> {
                season.goldenShoot = item.uid;
                this.setState({season: season})
            }}>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {item.user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                {team != null && (
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={team.iconUri != null ? team.iconUri : defaultImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                )}
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.score}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.assist}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.mom}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                {item.uid === season.goldenShoot && (
                    <h3 className='row-table-title' style={{color: '#FF8E53'}}>
                        <GiRunningShoe/>
                    </h3>
                )}
                </Col>
            </Row>
        );
    }

    render() {
        const {progress} = this.props;
        const {season} = this.state;
        
        return (
            <div>
                <div className='wrap-content-parent'>
                    <h3 className='wrap-content-wrap-child vertical-center' style={{fontSize: 18}}>
                        Season Status: 
                    </h3>
                    <div className='wrap-content-fill-child'>
                        <CustomTabView
                            value={3}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            items={
                            [
                                {
                                    label: t('registration')
                                },
                                {
                                    label: t('schedule')
                                },
                                {
                                    label: t('ongoing')
                                },
                                {
                                    label: t('finihsed')
                                },
                            ]} />
                    </div>
                    <div className='wrap-content-wrap-child vertical-center'>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <Button className="btn-submit" onClick={e => {
                                if (season.mos == null) {
                                    alert('You have to pick man of season');
                                } else if (season.goldenShoot == null) {
                                    alert('You have to pick man of golden shoot.');
                                } else if (season.winner == null) {
                                    alert('You have to pick winner team.');
                                } else if (window.confirm('Are you sure you want to finish this season?')) {
                                    const path = '/season/' + season.id;
                                    
                                    this.props.saveChange(season, path);
                                }
                            }}>Save Result</Button>
                        )}
                    </div>
                </div>
                {this.renderStanding()}
                {this.renderTopPlayer()}
                {this.renderTopScore()}
            </div>
        );
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(SeasonFinishedView));
export { connectedApp as SeasonFinishedView };
