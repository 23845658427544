import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import Countdown from 'react-countdown';
import { 
    AiOutlineUser
} from 'react-icons/ai';

import defaultImage from 'assets/img/icons/video_default.png';

export class ClubFieldHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {clubField, onClickDetails, onClickEdit, onClickToggle, uid } = this.props;
        let trackImage = clubField.thumbUri != null ? clubField.thumbUri : defaultImage;
        let allowEdit = (uid == null && clubField.owner === 'free') || uid === clubField.owner;
        let available = clubField.available == null || clubField.available;
        let countdown = clubField.active && clubField.deadline > Date.now() ? clubField.deadline : Date.now();
        
        if (clubField != null) {
            return (
                <div className="info-badge">
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb info-badge-icon"
                            src={trackImage} />
                        <CardBody className="info-badge-body">
                            <h4 className='label label-default one-line-title'>
                            {clubField.title}
                            </h4>
                            <div className="one-line-div">{clubField.description}</div>
                            <Row>
                                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                                    <div style={{ display: 'inline-block', marginRight: 10 }}>
                                        Ends In
                                    </div>
                                    <Countdown
                                        date={countdown} />
                                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                                        <AiOutlineUser /> {clubField.members != null ? Object.values(clubField.members).length : 0 }
                                    </div>
                                </Col>
                                <Col nm={4} xs={12} sm={6}>
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                            {uid != null && (
                                            <Button className="btn-submit" onClick={e => {
                                                if (onClickToggle != null) {
                                                    onClickToggle();
                                                }
                                            }}>{clubField.owner === uid ? (available ? "Available" : "Unavailable") : "Shared"}</Button>
                                            )}
                                            <Button className="btn-submit" onClick={e => {
                                                if (onClickDetails != null) {
                                                    onClickDetails();
                                                }
                                            }}>Details</Button>
                                            {allowEdit && (
                                                <Button className="btn-edit" onClick={e => {
                                                    if (onClickEdit != null) {
                                                        onClickEdit();
                                                    }
                                                }}>{t('edit')}</Button>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubFieldHeader);