import React, { Component } from "react";
import {
    Button, Spinner
} from 'reactstrap';
import { t } from 'i18next';
import { invokeHttpsApi } from "utils/API";

export class OrganizationPaymentBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setupLink: {},
            dashboardLink: {},
            editOpen: false,
            progress: false,
        }
    }
    
    requestStripeAccountLink() {
        const { organization, user } = this.props;
        
        this.setState({progress: true});
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        const data = {
            email: user.email,
            organizationId: organization.id,
            returnURL: pref + '/organizer/settings'
        }
        if (user.stripeAccountId) {
            data.stripeAccountId = user.stripeAccountId
        }
        
        invokeHttpsApi('payment-createConnectedAccount', data, (data) => {
            if (data.dict && data.dict.link && data.dict.link.url) {
                window.location = data.dict.link.url
            }
            this.setState({progress: false});
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderOverlay() {
        const {showDashboard, dashboardLink, setupLink} = this.state;
        if (!showDashboard) {
            return <div/>;
        }
        return (
            <div className="org-register-layout text-center">
                <h4 className="org-register-title">
                    {t('org_link_title')}
                </h4>
                <div className="text-center">
                    {setupLink && setupLink.url && (
                    <Button className="btn-submit" style={{marginBottom: 15}} onClick={e=> {
                        window.open(setupLink.url, '_blank', 'noreferrer');
                    }}>{t('setup')}</Button>
                    )}
                    {dashboardLink && dashboardLink.url && (
                    <Button className="btn-submit" style={{marginBottom: 15}} onClick={e=> {
                        window.open(dashboardLink.url, '_blank', 'noreferrer');
                    }}>{t('dashboard')}</Button>
                    )}
                    <Button className="btn-cancel" style={{marginBottom: 15}} onClick={e=> {
                        this.setState({showDashboard: false});
                    }}>{t('close')}</Button>
                </div>
            </div>
        );
    }

    render() {
        const {organization, stripeUser} = this.props;
        const {setupLink, dashboardLink, progress} = this.state;

        let msg = 'You need to create stripe account.';
        if (!stripeUser || !stripeUser.id) {
            msg = 'You need to create and setup stripe account.';
        } else if (!stripeUser.external_accounts || !stripeUser.external_accounts.data || stripeUser.external_accounts.data.lenth <= 0) {
            msg = 'You need to finish adding payment method to your stripe account.';
        } else {
            msg = 'You can update stripe account settings.'
        }
        
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        let setup = <div/>;
        if (progress) {
            setup = (
                <Spinner className='' />
            )
        } else {
            setup = (
                <Button
                    className='btn-green'
                    onClick={e=>{
                        this.requestStripeAccountLink();
                    }}>{t('setup_account')}</Button>
            )
        }
        return (
            <div className='form-setting-wrapper'>
                <div className='form-setting-title'>
                    <b>Stripe Account Setup </b> | {msg}
                </div>
                <div className='form-setting-control'>
                    {setup}
                    {setupLink && setupLink.url && (
                    <Button className="btn-green" style={{marginBottom: 15}} onClick={e=> {
                        window.open(setupLink.url, '_blank', 'noreferrer');
                    }}>{t('setup')}</Button>
                    )}
                    {dashboardLink && dashboardLink.url && (
                    <Button className="btn-green" style={{marginBottom: 15}} onClick={e=> {
                        window.open(dashboardLink.url, '_blank', 'noreferrer');
                    }}>{t('dashboard')}</Button>
                    )}
                </div>
                {this.renderOverlay()}
            </div>
        );
    }
}

export default OrganizationPaymentBadge;