import React, { Component } from "react";
import Countdown from 'react-countdown';
import { 
    MdCardMembership
} from 'react-icons/md';
import { connect } from 'react-redux';
import { invokeHttpsApi } from "utils/API";
import { withRouter } from "utils/Routher";
import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID, LEAGUE_SUBSCRIPTION_PRODUCT_ID, LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID, toDateTimeString
} from 'utils/Utils';


class OrganizationMembershipBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {},
            editOpen: false,
            locationSubscription: {},
            locationPrices: {},
            leagueSubscription: {},
            leaguePrices: {},
            locationAndLeagueSubscription: {},
            locationAndLeaguePrices: {},
            activeSubscriptions: {},
            leagueStatus: {},
            locationStatus: {},
            locationAndLeagueStatus: {},
        }
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        this.setState({loading: true})
        invokeHttpsApi("membership-loadMembershipInfo", {}, (data) => {
            const locationSubscription = {};
            const locationPrices = {};
            const locationAndLeagueSubscription = {};
            const locationAndLeaguePrices = {};
            const leagueSubscription = [];
            const leaguePrices = [];
            const activeSubscriptions = {};
            var leagueStatus = {};
            var locationStatus = {};
            var locationAndLeagueStatus = {};
        
            if (data.success) {
                for (const [key, value] of Object.entries(data.locationSubscription)) {
                    locationSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationPrices)) {
                    if (value.active === true) {
                        locationPrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueSubscription)) {
                    locationAndLeagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeaguePrices)) {
                    if (value.active === true) {
                        locationAndLeaguePrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.leagueSubscription)) {
                    leagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.leaguePrices)) {
                    if (value.active === true) {
                        leaguePrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.activeSubscriptions)) {
                    activeSubscriptions[key] = value;
                }
                for (const [key, value] of Object.entries(data.leagueStatus)) {
                    leagueStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationStatus)) {
                    locationStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueStatus)) {
                    locationAndLeagueStatus[key] = value;
                }
            }

            this.setState({
                locationSubscription: locationSubscription,
                locationPrices: locationPrices,
                locationAndLeagueSubscription: locationAndLeagueSubscription,
                locationAndLeaguePrices: locationAndLeaguePrices,
                leagueSubscription: leagueSubscription,
                leaguePrices: leaguePrices,
                activeSubscriptions: activeSubscriptions,
                leagueStatus: leagueStatus,
                locationStatus: locationStatus,
                locationAndLeagueStatus: locationAndLeagueStatus,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    getMembershipPlan() {
        const { organizationSubscription } = this.props;
        const { locationSubscription, leagueSubscription, locationAndLeagueSubscription } = this.state;

        if (organizationSubscription != null && organizationSubscription.product != null) {
            if (organizationSubscription.product.id === LOCATION_SUBSCRIPTION_PRODUCT_ID && locationSubscription.name != null) {
                return locationSubscription;
            }
            if (organizationSubscription.product.id === LEAGUE_SUBSCRIPTION_PRODUCT_ID && leagueSubscription.name != null) {
                return leagueSubscription;
            }
            if (organizationSubscription.product.id === LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID && locationAndLeagueSubscription.name != null) {
                return locationAndLeagueSubscription;
            }
        }
        return null;
    }

    activateFreeMembership() {
        const {user} = this.props;
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        if (window.confirm('Are you sure you want to activate free organization membership?')) {
            invokeHttpsApi("membership-purchaseFreeOrgSubscription", {}, (data) => {
                if (data != null && data.success === true) {
                    alert('Free Membership with full organization membership is activated. It provides full features with 14 days.')
                }
            }, error => {

            });
        }
    }

    render() {
        const { organizationSubscription, freeOrganization } = this.props;
        const membership = this.getMembershipPlan();
        
        if (membership != null) {
            if (organizationSubscription.status === 'trialing') {
                return (
                    <div className='membership-header'>
                        <div className='membership-header-icon'>
                            <div className='membership-header-count'><MdCardMembership/></div>
                        </div>
                        <div className='membership-header-info'>
                            <h3 className='membership-header-title'>
                            Your Membership
                            </h3>
                            <div style={{marginRight: 20}}>
                                <div className='membership-header-membership-title'>
                                {membership.name}
                                </div>
                                <div className='membership-header-membership-sub-info'>
                                Subscribed on {toDateTimeString(organizationSubscription.created.seconds * 1000)}
                                </div>
                            </div>
                            <div className='membership-header-membership-count'>
                                <Countdown date={organizationSubscription.trial_end.seconds * 1000}/>
                                <div style={{fontSize: 7}}>
                                DAY HUR  MIN  SEC
                                </div>
                            </div>
                        </div>
                        <div className='membership-header-btn'>
                            <h3 className='membership-header-title'>
                            Need more features?
                            </h3>
                            <div className='membership-header-membership-change' onClick={e => {
                                this.props.router.navigate('/organizer/membership')
                            }}>
                            Change Plan
                            </div>                       
                        </div>
                    </div>
                )
            }
            if (organizationSubscription.status === 'active') {
                return (
                    <div className='membership-header'>
                        <div className='membership-header-icon'>
                            <div className='membership-header-count'><MdCardMembership/></div>
                        </div>
                        <div className='membership-header-info'>
                            <div className='wrap-content-parent'>
                                <h3 className='membership-header-title'>
                                Your Membership
                                </h3>
                                <div style={{marginRight: 20}}>
                                    <div className='membership-header-membership-title'>
                                    {membership.name}
                                    </div>
                                    <div className='membership-header-membership-sub-info'>
                                    Subscribed on {toDateTimeString(organizationSubscription.created.seconds * 1000)}
                                    </div>
                                </div>
                                <div className='membership-header-membership-count'>
                                    <Countdown date={organizationSubscription.current_period_end.seconds * 1000}/>
                                    <div style={{fontSize: 7}}>
                                    DAY HUR  MIN  SEC
                                    </div>
                                </div>              
                            </div>
                            <div className='membership-header-membership-desc'>
                            {membership.description}
                            </div>
                        </div>
                        <div className='membership-header-btn'>
                            <h3 className='membership-header-title'>
                            Need more features?
                            </h3>
                            <div className='membership-header-membership-change' onClick={e => {
                                this.props.router.navigate('/organizer/membership')
                            }}>
                            Change Plan
                            </div>                       
                        </div>
                    </div>
                )
            }
        }
        if (freeOrganization != null && freeOrganization.current_period_end > new Date().valueOf()) {
            return (
                <div className='membership-header'>
                    <div className='membership-header-icon'>
                        <div className='membership-header-count'><MdCardMembership/></div>
                    </div>
                    <div className='membership-header-info'>
                        <div className='wrap-content-parent'>
                            <h3 className='membership-header-title'>
                            Your Membership
                            </h3>
                            <div style={{marginRight: 20}}>
                                <div className='membership-header-membership-title'>
                                Free Trial Membership
                                </div>
                                <div className='membership-header-membership-sub-info'>
                                Activated on {toDateTimeString(freeOrganization.current_period_start)}
                                </div>
                            </div>
                            <div className='membership-header-membership-count'>
                                <Countdown date={freeOrganization.current_period_end}/>
                                <div style={{fontSize: 7}}>
                                DAY HUR  MIN  SEC
                                </div>
                            </div>              
                        </div>
                        <div className='membership-header-membership-desc'>
                        You can enjoy full features 14 days.
                        </div>
                    </div>
                    <div className='membership-header-btn'>
                        <h3 className='membership-header-title'>
                        Need more features?
                        </h3>
                        <div className='membership-header-membership-change' onClick={e => {
                            this.props.router.navigate('/organizer/membership')
                        }}>
                        Change Plan
                        </div>                       
                    </div>
                </div>
            )
        }
        if (freeOrganization == null) {
            return (
                <div className='membership-header'>
                    <div className='membership-header-info'>
                        <div className='wrap-content-parent'>
                            <h3 className='membership-header-title'>
                            Free Trial Available
                            </h3>
                            <div style={{marginRight: 20}}>
                                <div className='membership-header-membership-title' onClick={e=> {
                                    this.activateFreeMembership();
                                }}>
                                Activate
                                </div>
                                <div className='membership-header-membership-sub-info'>
                                </div>
                            </div>
                        </div>
                        <div className='membership-header-membership-desc'>
                        You can enjoy full features 14 days.
                        </div>
                    </div>
                    <div className='membership-header-btn'>
                        <h3 className='membership-header-title'>
                        Need more features?
                        </h3>
                        <div className='membership-header-membership-change' onClick={e => {
                            this.props.router.navigate('/organizer/membership')
                        }}>
                        Change Plan
                        </div>                       
                    </div>
                </div>
            )
        }
        return <div/>
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { isAdmin, loggingIn, user, organizationSubscription, freeOrganization };
}

const connectedNavigation = withRouter(connect(mapState)(OrganizationMembershipBadge));
export { connectedNavigation as OrganizationMembershipBadge };
