import React, { Component } from 'react';
import ClubTrackReviewBadge from 'components/detail/club/ClubTrackReviewBadge';
import { listenDb } from 'utils/API';

export class ClubTrackReviewCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            users: {},
            submissions: {},
            clubTracks: {},
            clubTrackChoice: {},
            editOpen: false,
            detailOpen: false,
            addOpen: false,
            pickOpen: false,
        };
    }

    componentDidMount() {
        const { uid, clubId } = this.props;
        listenDb('/user', data => {
            var users = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    users[key] = value;
                }
            }

            this.setState({
                users: users,
            });
        });
        listenDb('/clubTrackSubmission', data => {
            var submissions = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (clubId != null && value.clubId === clubId) {
                        submissions[key] = value;
                    } else if (clubId == null && uid != null && (value.coachId === uid || 
                        (value.managers != null && Object.values(value.managers).includes(uid)))) {
                        submissions[key] = value;
                    }
                }
            }

            this.setState({
                submissions: submissions,
            });
        });
        listenDb('/clubTrack', data => {
            var clubTracks = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (uid != null && (value.owner === uid || 
                        (value.managers != null && Object.values(value.managers).includes(uid)))) {
                        clubTracks[key] = value;
                    }
                }
            }

            this.setState({
                clubTracks: clubTracks,
            });
        });
    }

    renderContents() {
        const { uid } = this.props;
        const { users, clubTracks, submissions } = this.state;
        const result = [];

        for (const[key, value] of Object.entries(submissions)) {
    
            const track = clubTracks[value.trackId];
            const user = users[value.uid];
            if (track != null && user != null) {
                result.push(
                    <ClubTrackReviewBadge 
                        key={key}
                        user={user}
                        uid={uid}
                        clubTrack={track}
                        submission={value} />
                )
            }
        }

        return result;
    }

    render() {
        return (
            <div>
                <div className='wrap-content-parent' style={{marginBottom: 20}}>
                    
                </div>
                {this.renderContents()}
            </div>
        );
    }
}

export default ClubTrackReviewCollection;