import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { withRouter } from 'utils/Routher';

import {BlueNavigation} from 'components/landing/organization/BlueNavigation';
import {Navigation} from 'components/landing/organization/Navigation';
import LanguageSelector from 'components/LanguageSelector';
import { getOrgData } from 'utils/Utils';
import { listenDb, listenDbQuery } from 'utils/API';
import LoadingScreen from 'components/detail/loading/LoadingScreen';
import { SambaHeader } from 'components/landing/organization/samba/SambaHeader';
import { t } from 'i18next';

class OrgLanding extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            organization: {},
            targetUser: {},
            loading: true,
        }
    }

    componentDidMount() {
        const org = getOrgData(this.props);
        if (org && org.organizationId) {
            listenDb("/organization/" + org.organizationId, ((data)=> {
                
                const organization = {};
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        organization[key] = value;
                    }
                }
    
                this.setState({organization: organization, loading: false});
            }))
        } else if (org && org.customDomain) {
            listenDbQuery("/organization", "customDomain", org.customDomain, ((data)=> {
                const organization = {};
                if (data) {
                    for (const v of Object.values(data)) {
                        for (const [key, value] of Object.entries(v)) {
                            organization[key] = value;
                        }
                    }
                }
    
                this.setState({organization: organization, loading: false});
            }))
        }
        if (this.props.router.params.uid) {
            listenDb("/user/" + this.props.router.params.uid, ((data)=> {
                
                const targetUser = {};
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        targetUser[key] = value;
                    }
                }
    
                this.setState({targetUser: targetUser});
            }))
        }
    }

    renderSamba(authMsg) {
        const {organization} = this.state;
        let id = '';
        if (this.props.router && this.props.router.params) {
            id = this.props.router.params.id;
        }
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        let actionStyle = {};
        if (actionBg && actionColor) {
            actionStyle = {
                backgroundColor: actionBg,
                color: actionColor,
            }
        } else if (actionBg) {
            actionStyle = {
                backgroundColor: actionBg,
            }
        } else if (actionColor) {
            actionStyle = {
                color: actionColor,
            }
        }

        return (
            <div className='samba-landing'>
                <SambaHeader
                    location='home' 
                    organization={organization} 
                    loading={false}
                    authMsg={authMsg}
                    itemId={id} />

                <Outlet/>
                <div className="samba-footer" style={actionStyle}>
                    <p style={{color: '#ddd'}}>
                        &copy; {t('copyright_text')}
                        <a href="/terms-of-services" rel="nofollow" style={{color: 'white'}}>
                            Live! Inc
                        </a>
                    </p>
                </div>
            </div>
        );
    }

    renderBlue(authMsg) {
        const {organization} = this.state;
        let id = '';
        if (this.props.router && this.props.router.params) {
            id = this.props.router.params.id;
        }
        return (
            <div className='blue-landing' style={organization.landingData.blueUrl ? {backgroundImage: 'none'} : {}}>
                {organization.landingData.blueUrl && (
                <img style={{position: 'fixed', top: 0, width: '100%', height: '100%', objectFit: 'cover'}} alt="custom" src={organization.landingData.blueUrl} />
                )}
                {organization.landingData.blueUrl && (
                <div style={{position: 'fixed', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(22,22,22,0.3)'}} />
                )}
                <BlueNavigation 
                    location='home' 
                    organization={organization} 
                    loading={false}
                    authMsg={authMsg}
                    itemId={id} />
                <div className='blue-contents-wrapper'>
                    <Outlet/>
                </div>
            </div>
        );
    }

    renderDefault(authMsg) {
        const {organization} = this.state;
        let id = '';
        if (this.props.router && this.props.router.params) {
            id = this.props.router.params.id;
        }
        return (
            <div>
                <Navigation 
                    location='home' 
                    organization={organization} 
                    loading={false}
                    authMsg={authMsg}
                    itemId={id} />
                <Outlet/>
            </div>
        );
    }

    renderContents() {
        const {user} = this.props;
        const {organization, targetUser} = this.state;
        const userId = this.props.router.params.uid;
        let authMsg = null;
        if (userId && !user) {
            authMsg = t("user_login_needed").replace("{user}", targetUser.username ? targetUser.username : userId);
        } else if (userId && targetUser.uid && user.uid !== userId && user.uid !== targetUser.parentId) {
            authMsg = t("user_login_needed").replace("{user}", targetUser.username ? targetUser.username : userId);
        }

        if (organization.landingData && organization.landingData.theme === 'blue') {
            return (
                <div>
                    {this.renderBlue(authMsg)}
                </div>
            )
        } else if (organization.landingData && organization.landingData.theme === 'samba') {
            return (
                <div>
                    {this.renderSamba(authMsg)}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderDefault(authMsg)}
                </div>
            )
        }
    }

    render() {
        const {loading, organization} = this.state;

        if (loading || !organization.id) {
            return (
                <LoadingScreen />
            )
        }
        
        return (
            <div>
                {this.renderContents()}
                <LanguageSelector />
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgLanding));
export { connectedPage as OrgLanding };
