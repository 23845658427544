import React, { Component } from "react";
import { t } from 'i18next';
import { loadStripe } from '@stripe/stripe-js';
import {
    Button, Input, Spinner, Col, Row
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import playerImage from 'assets/img/icons/player_photo_default.png';
import fundImage from 'assets/img/icons/fundraise.png';

import { STRIPE_PUBLISHABLE_KEY, getOrgDomain, renderHtmlString, replaceUrl, sortByUsername } from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";
import { withRouter } from "utils/Routher";
import { FcCloseUpMode, FcEngineering } from "react-icons/fc";

class PlayerSeasonRegister extends Component {

    constructor(props) {
        super(props)
        this.state = {
            step: 1,
            progress: false,
            checked: {},
            imageProgress: 0
        }
    }

    componentDidMount() {
        const { users } = this.props;
        const searchParams = this.props.router.searchParams;
        const playerId = searchParams.get("playerId");
        const paymentSuccess = searchParams.get("payment_result");
        if (users[playerId]) {
            this.setState({playerChoice: users[playerId], step: 4, paymentSuccess: paymentSuccess})
        }
    }

    requestDirectChargeRegister(season, user, methodChoice, roleChoice, note, price) {
        const {organization} = this.props;
        if (!price) {
            alert(t("season_fee_no_registered_prompt"));
            return;
        }
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!user || !user.uid || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        if (!user || !user.uid || !roleChoice) {
            alert(t('choose_method_role'));
            return;
        }

        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            parentId: user.parentId ? user.parentId : user.uid,
            userId: user.uid,
            role: roleChoice,
            seasonId: season.id,
            price: price,
            returnURL: pref + '/championships/' + season.id + '?playerId=' + user.uid
        }
        if (note) {
            data.note = note;
        } else {
            data.note = '';
        }
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-createSeasonPaymentIntent', data, async (data) => {

            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

            if (data.success && data.session) {
                stripe.redirectToCheckout({ sessionId: data.session.id });

                if (!season.pending) {
                    season.pending = {};
                }
                season.pending[user.uid] = data.pending;
                this.props.onSeasonChoice(season);

                this.setState({
                    checkoutProgress: true,
                    progress: false,
                    step: 4,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: true});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    requestWalletRegister(season, user, methodChoice, roleChoice, note, price) {
        const {wallet} = this.props;
        if (!price) {
            alert(t("season_fee_no_registered_prompt"));
            return;
        }
        if (!wallet.balance || (parseFloat(wallet.balance) / 100) < price) {
            alert("Wallet balance is $" + wallet.balance + ". Not sufficent.");
            return;
        }
        if (!user || !user.uid || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        if (!user || !user.uid || !roleChoice) {
            alert(t('choose_method_role'));
            return;
        }
        
        let userId = user.uid;
        if (user.parentId) {
            userId = user.parentId;
        }
        if (user.owner) {
            userId = user.owner;
        }

        const data = {
            register: {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
                price: price,
            },
            parentId: user.parentId,
            owner: user.owner,
            seasonId: season.id,
            wallet: wallet,
        }
        invokeHttpsApi('purchase-requestSeasonRegisterWallet', data, (data) => {

            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                    step: 4,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.registers) {
                season.registers = {};
            }
            season.registers[user.uid] = {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
            }
            this.props.onSeasonChoice(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    requestRegister(season, user, methodChoice, roleChoice, note) {
        if (!user || !user.uid || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        if (!user || !user.uid || !roleChoice) {
            alert(t('choose_method_role'));
            return;
        }
        let userId = user.uid;
        if (user.parentId) {
            userId = user.parentId;
        }
        if (user.owner) {
            userId = user.owner;
        }
        
        const data = {
            register: {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
            },
            parentId: user.parentId ? user.parentId : user.uid,
            seasonId: season.id,
        }
        invokeHttpsApi('clubQuery-requestSeasonRegister', data, (data) => {

            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                    step: 4,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.pending) {
                season.pending = {};
            }
            season.pending[user.uid] = {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
            }
            this.props.onSeasonChoice(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-create-step-header">
            <div className="season-create-step-num">
                {num}
            </div>
            <div className="season-create-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderStepOne() {
        const { season, divisions, user, onSeasonUpdate, onCloseClick } = this.props;
        const { checked, progress } = this.state;
        const division = divisions[season.divisionId];

        let items = [];
        let allCheck = true;
        for (const [key, value] of Object.entries(division.policy ? division.policy : {})) {
            if (!checked[key]) {
                checked[key] = {};
            }
            if (!checked[key][user.uid]) {
                allCheck = false;
            }
            items.push(value);
        }


        return (
            <div className="overlay-modal">
                {this.renderStepTitle(1, t('privacy_policy'))}
                
                <div className="register-overlay-contents">
                {items.length > 0 ? items.map((value, idx)=>(
                    <div key={idx}>
                        <h2 className="post-detail-title">
                        {value.title}
                        </h2>
                        <div className='app'>
                            {renderHtmlString(value.body ? value.body : "")}
                        </div>
                        <div className='player-form-item'>
                            <div className='player-form-title'>
                                {t('ack_agree')}
                            </div>
                            <div className='player-form-value form-setting-control'>
                                <Switch
                                    checked={checked[value.id] && Object.values(checked[value.id]).includes(user.uid)}
                                    onChange={e=>{
                                        const update = e.target.checked;
                                        if (update) {
                                            checked[value.id][user.uid] = user.uid;
                                        } else {
                                            delete checked[value.id][user.uid]
                                        }
                                        this.setState({checked: checked});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </div>
                    </div>
                    )) : (
                    <div className="register-overlay-contents-middle">
                    {t('no_division_policy_messsage')}
                    </div>
                )}
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                        <Button className='btn-blue' onClick={e => {
                            if (items.length === 0) {
                                this.setState({step: 2});
                                return;
                            }
                            if (!allCheck) {
                                alert(t("division_policy_check_prompt"));
                                return;
                            }
                            this.setState({progress: true})
                            invokeHttpsApi('userQuery-submitSeasonAgree', {
                                agree: true,
                                seasonId: season.id,
                                userId: user.uid,
                            }, (data) => {
                                // Read result of the Cloud Function.
                                if (!season.policyFlag) {
                                    season.policyFlag = {};
                                }
                                season.policyFlag[user.uid] = true;
                                
                                onSeasonUpdate(season);
                                this.setState({step: 2, progress: false})
                            }, (error) => {
                                console.log('saveContents error', error);
                                this.setState({progress: false})
                            });
                        }}>{t(items.length > 0 ? 'agree' : 'skip')}</Button>
                        )}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t(items.length > 0 ? 'decline' : 'close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepTwo() {
        const { user, users, onCloseClick } = this.props;
        const { playerChoice } = this.state;
        const usersChoice = [];
        for (const item of Object.values(users)) {
            if (item.parentId === user.uid || item.owner === user.uid) {
                usersChoice.push(item)
            }
        }
        sortByUsername(usersChoice)

        return (
            <div className="overlay-modal">
                {this.renderStepTitle(2, t('choose_player_to_register'))}
                <div className="register-overlay-contents">
                    <div className="register-overlay-contents-middle">
                        <p className="register-overlay-text">
                            {t('player_register_message')}
                        </p>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('player_choice')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="text" id="title" onChange={e => {
                                    if (users[e.target.value]) {
                                        this.setState({playerChoice: users[e.target.value]});
                                    } else {
                                        this.setState({playerChoice: null});
                                    }
                                }} value={playerChoice && users[playerChoice.uid] ? playerChoice.uid : ""}>
                                    <option value=""></option>
                                    <option value={user.uid}>{t('myself')}: {user.username}</option>
                                    {usersChoice.map((v,i) => (
                                    <option value={v.uid} key={i}>{v.username} {v.email}</option>
                                    ))}
                                </Input>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 1})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!playerChoice) {
                                alert(t("no_player_choice_prompt"));
                                return;
                            }
                            this.setState({step: 3})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepThree() {
        const { organization, season, onCloseClick } = this.props;
        const { playerChoice, checkoutProgress } = this.state;
        var {playerMethods, playerNotes, playerRoles} = this.state;

        if (!playerMethods) {
            playerMethods = {};
        }

        if (!playerNotes) {
            playerNotes = {};
        }
        if (!playerRoles) {
            playerRoles = {};
        }
        const playerMethod = playerMethods[playerChoice.uid] ? playerMethods[playerChoice.uid] : 'direct';
        const playerNote = playerNotes[playerChoice.uid] ? playerNotes[playerChoice.uid] : null;
        const playerRole = playerRoles[playerChoice.uid] ? playerRoles[playerChoice.uid] : null;

        let main = <div />;
        let enroll = null;
        const playerIcon = playerChoice.photoUri ? playerChoice.photoUri : playerImage;
        let enrollFee = undefined;
        let enrollFee2 = undefined;
        if (season.enrollFee && season.enrollFee.playerFee) {
            enrollFee = season.enrollFee.playerFee;
        }
        if (season.enrollFee && season.enrollFee.managerFee) {
            enrollFee2 = season.enrollFee.managerFee;
        }

        if (season.registers && Object.keys(season.registers).includes(playerChoice.uid)) {
            let register = season.registers[playerChoice.uid];
            
            main = (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('enrolled')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                            <h3 style={{fontSize: 15, marginRight: 10}}>
                            {t('enrolled')}
                            </h3>
                            <div className='wrap-content-fill-child reg-info-label'>
                                <span>{t('method')}: <b>{register.method}</b> Role: <b>{register.role}</b></span>
                                <div>{t('note')}: {register.note}</div>
                            </div>
                        </div>
                        </div>
                    </Col>
                </Row>
            )
        } else if (season.pending && Object.keys(season.pending).includes(playerChoice.uid)) {
            let register = season.pending[playerChoice.uid];
            
            if (register.method === 'direct' && register.role === 'player') {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestDirectChargeRegister(season, playerChoice, register.method, register.role, register.note, enrollFee)
                    }}>
                    {t('pay_on_stripe')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                    </Button>
                )
            } else if (register.method === 'direct' && register.role === 'manager') {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestDirectChargeRegister(season, playerChoice, register.method, register.role, register.note, enrollFee2)
                    }}>
                    {t('pay_on_stripe')}:( ${enrollFee2 ? enrollFee2 : ""} {t('enroll')})
                    </Button>
                )
            } else if (register.method === 'wallet' && register.role === 'player') {
                enroll = (<Button className='btn-blue' onClick={e=> {
                    this.requestWalletRegister(season, playerChoice, register.method, register.role, register.note, enrollFee)
                }}>
                {t('pay_on_wallet')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                </Button>)
            } else if (register.method === 'wallet' && register.role === 'manager') {
                enroll = (<Button className='btn-blue' onClick={e=> {
                    this.requestWalletRegister(season, playerChoice, register.method, register.role, register.note, enrollFee2)
                }}>
                {t('pay_on_wallet')}:( ${enrollFee2 ? enrollFee2 : ""} {t('enroll')})
                </Button>)
            } else {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        if (window.confirm('Are you sure you want to change enroll request?')) {
                            this.requestRegister(season, playerChoice, register.method, register.role, register.note);
                        }
                    }}>
                    {t('update_request')}:
                    </Button>
                )
            }
            
            main = (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('enroll_type')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" 
                                value={register.role ? register.role : ""} id="challengeChoice" 
                                onChange={e=>{
                                        season.pending[playerChoice.uid].role = e.target.value === "" ? null : e.target.value;
                                        
                                        this.props.onSeasonUpdate(season);
                                    }}>
                                    <option value="">{t('choose_enroll_type')}:</option>
                                    <option value="player">{t('player')}</option>
                                    <option value="manager">{t('staff')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('payment_method')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" 
                                    value={register.method === "" ? "" : register.method} id="challengeChoice" 
                                    onChange={e=>{
                                        season.pending[playerChoice.uid].method = e.target.value === "" ? null : e.target.value;
                                        
                                        this.props.onSeasonUpdate(season);
                                    }}>
                                    <option value="">{t('pay_in_person')}:</option>
                                    <option value="wallet">{t('pay_on_wallet')}:</option>
                                    {organization.stripeAccountId && (
                                    <option value="direct">{t('pay_on_stripe')}:</option>
                                    )}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    {register.method !== 'direct' && register.method !== 'wallet' && (
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" value={register.method ? register.method : ""} id="challengeChoice" onChange={e=>{
                                        season.pending[playerChoice.uid].method = e.target.value !== "" ? e.target.value : null;
                                        
                                        this.props.onSeasonUpdate(season);
                                    }}>
                                    <option value="">{t('choose_payment_method')}:</option>
                                    <option value="cash">{t('cash')}</option>
                                    <option value="online">{t('online')}</option>
                                    <option value="eTransfer">{t('e_transfer')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>)}
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            {register.method === 'direct' ? (
                                <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                            ) : (register.method === 'wallet' ? (
                                <div className="reg-method-info-label">{t('reg_pay_wallet')}</div>
                            ) : (
                                <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                            ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('note')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                    season.pending[playerChoice.uid].note = e.target.value;
                                        this.props.onSeasonUpdate(season);
                                }} value={register.note ? register.note : ""} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )

        } else {
            if (playerMethod === 'direct' && playerRole === 'player') {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestDirectChargeRegister(season, playerChoice, playerMethod, playerRole, playerNote, enrollFee)
                    }}>
                    {t('pay_on_stripe')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                    </Button>
                )
            } else if (playerMethod === 'direct' && playerRole === 'manager') {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestDirectChargeRegister(season, playerChoice, playerMethod, playerRole, playerNote, enrollFee2)
                    }}>
                    {t('pay_on_stripe')}:( ${enrollFee2 ? enrollFee2 : ""} {t('enroll')})
                    </Button>
                )
            } else if (playerMethod === 'wallet' && playerRole === 'player') {
                enroll = (<Button className='btn-blue' onClick={e=> {
                    this.requestWalletRegister(season, playerChoice, playerMethod, playerRole, playerNote, enrollFee)
                }}>
                {t('pay_on_wallet')}:( ${enrollFee ? enrollFee : ""} {t('enroll')})
                </Button>)
            } else if (playerMethod === 'wallet' && playerRole === 'manager') {
                enroll = (<Button className='btn-blue' onClick={e=> {
                    this.requestWalletRegister(season, playerChoice, playerMethod, playerRole, playerNote, enrollFee2)
                }}>
                {t('pay_on_wallet')}:( ${enrollFee2 ? enrollFee2 : ""} {t('enroll')})
                </Button>)
            } else {
                enroll = (
                    <Button className='btn-blue' onClick={e=> {
                        this.requestRegister(season, playerChoice, playerMethod, playerRole, playerNote)
                    }}>
                    {t('enroll')}
                    </Button>
                )
            }
            
            main = (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('enroll_type')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" 
                                    value={playerRole ? playerRole : ""} id="challengeChoice" 
                                    onChange={e=>{
                                        const update = e.target.value === "" ? null : e.target.value;
                                        playerRoles[playerChoice.uid] = update;
                                        this.setState({playerRoles: playerRoles});
                                    }}>
                                    <option value="">{t('choose_enroll_type')}:</option>
                                    <option value="player">{t('player')}</option>
                                    <option value="manager">{t('staff')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('payment_method')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" value={playerMethod ? playerMethod : ""} id="challengeChoice" onChange={e=>{
                                        const update = e.target.value === "" ? "cash" : e.target.value;
                                        playerMethods[playerChoice.uid] = update;
                                        this.setState({playerMethods: playerMethods});
                                    }}>
                                    {!season.blockOfflinePayment && (
                                    <option value="">{t('pay_in_person')}:</option>
                                    )}
                                    {!season.blockOfflinePayment && (
                                    <option value="wallet">{t('pay_on_wallet')}:</option>
                                    )}
                                    {organization.stripeAccountId && (
                                    <option value="direct">{t('pay_on_stripe')}:</option>
                                    )}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    {playerMethod !== 'direct' && playerMethod !== 'wallet' && (
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="select" value={playerMethod ? playerMethod : ""} id="challengeChoice" onChange={e=>{
                                        const update = e.target.value === "" ? null : e.target.value;
                                        playerMethods[playerChoice.uid] = update;
                                        this.setState({playerMethods: playerMethods});
                                    }}>
                                    <option value="">{t('choose_payment_method')}:</option>
                                    <option value="cash">{t('cash')}</option>
                                    <option value="online">{t('online')}</option>
                                    <option value="eTransfer">{t('e_transfer')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>)}
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            {playerMethod === 'direct' ? (
                                <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                            ) : (playerMethod === 'wallet' ? (
                                <div className="reg-method-info-label">{t('reg_pay_wallet')}</div>
                            ) : (
                                <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                            ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('note')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                        const update = e.target.value;
                                        playerNotes[playerChoice.uid] = update;
                                    this.setState({playerNotes: playerNotes});
                                }} value={playerNote ? playerNote : ""} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )

        }
        return (
            <div className="overlay-modal">
                {this.renderStepTitle(3, t('register_player_to_season'))}
                <div className="register-overlay-contents">
                    <div className="register-overlay-contents-middle">
                        <p className="register-overlay-text">
                            {t('register_player_to_season_message_01')}
                        </p>
                        <p className="register-overlay-text">
                            {t('register_player_to_season_message_02')}
                        </p>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('player_choice')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                                    <img className='reg-icon-team' alt="logo" src={playerIcon} />
                                    <div className='wrap-content-fill-child'>
                                    {playerChoice.username}
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('player_fee')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10, textAlign: 'left'}}>
                                    <img className='reg-icon-team' alt="logo" src={fundImage} />
                                    <div className='wrap-content-fill-child'>
                                    {season.enrollFee && season.enrollFee.playerFee ? "$ " + season.enrollFee.playerFee : t('season_fee_no_registered')}
                                    </div>
                                </div>
                                </div>
                            </Col>
                        </Row>
                        {main}
                    </div>
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 2})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {enroll && !checkoutProgress && enroll}
                        {enroll && checkoutProgress && <Spinner color="primary" />}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepFour() {
        const { organization, season, onCloseClick } = this.props;
        const { playerChoice, paymentSuccess } = this.state;

        const pending = season.pending && playerChoice && season.pending[playerChoice.uid] ? season.pending[playerChoice.uid] : {};
        const success = !pending.intentId || paymentSuccess;
        let messageId;
        if (!pending.intentId) {
            messageId = "register_player_to_season_requested_offline";
        } else if (success) {
            messageId = "register_player_to_season_requested_direct";
        } else {
            messageId = "register_player_to_season_failed_direct";
        }

        return (
            <div className="overlay-modal">
                {this.renderStepTitle(4, t('register_player_to_season_result'))}
                <div className="register-overlay-contents">
                    <div className="register-overlay-contents-middle">
                        <p className="register-overlay-icon">
                            {success ? <FcCloseUpMode /> : <FcEngineering />}
                        </p>
                        <p className="register-overlay-text">
                            {t(messageId)}
                        </p>
                    </div>
                </div>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 3})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.props.router.setSearchParams( params => {
                                params.set("playerId", null);
                                params.set("payment_result", null);
                                return params;
                            })
                            replaceUrl(`${getOrgDomain(organization)}/championships/${season.id}`);
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        const { step } = this.state;

        return (
            <div className="overlay-wrapper">
                {step === 1 && this.renderStepOne()}
                {step === 2 && this.renderStepTwo()}
                {step === 3 && this.renderStepThree()}
                {step === 4 && this.renderStepFour()}
            </div>
        )
    }
}

const connectedRouter = withRouter(PlayerSeasonRegister)
export { connectedRouter as PlayerSeasonRegister}