import { cartConstants } from '../_constants';

export function cartData(state = {
    cart: {},
    checkoutProgress: false,
}, action) {
    switch (action.type) {
        case cartConstants.CHECKOUT_SESSION_REQUEST:
            return {
                ...state, 
                checkoutProgress: true,
            };
        case cartConstants.CHECKOUT_SESSION_SUCCESS:
            return {
                ...state, 
                session: action.session,
                checkoutProgress: false,
            };
        case cartConstants.CHECKOUT_SESSION_FAILURE:
            return { 
                ...state, 
                error: action.error,
                checkoutProgress: false,
            };
        case cartConstants.CART_ADD:
            const item = action.data;
            for (const value of Object.values(state.cart)) {
                if (item.seasonId && item.teamId && item.seasonId === value.seasonId && item.teamId === value.teamId) {
                    delete state.cart[value.id];
                } else if (item.seasonId && item.userId && item.seasonId === value.seasonId && item.userId === value.userId) {
                    delete state.cart[value.id];
                } else if (item.programId && item.userId && item.programId === value.programId && item.userId === value.userId) {
                    delete state.cart[value.id];
                }
            }
            state.cart[item.id] = item;
            return {
                ...state, 
                cart: state.cart
            };
        case cartConstants.CART_REMOVE:
            delete state.cart[action.item.id];
            return {
                ...state, 
                cart: state.cart
            };
        default:
            return state
    }
}