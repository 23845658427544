import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
// import LanguageSelector from 'components/LanguageSelector';

class MainPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            faqs: {},
            faqsChoice: {},
            faqsItems: [],
            faqChoice: {},
            editOpen: false,
            detailOpen: false
        };
    }

    componentDidMount() {
        
    }

    render() {
        
        return (
            <div>
                <Outlet/>
                {/* <LanguageSelector/> */}
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = connect(mapState, actionCreators) (MainPage);
export { connectedPage as MainPage };
