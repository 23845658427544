import { t } from "i18next";
import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";

export class Reviews extends Component {
    render() {
        return (
            <div className="main-reviews">
                <div className="container">
                    <h6>{t('reviews')}</h6>
                    <h2>{t('review_title')}</h2>
                    <p>{t('review_text')}</p>
                    <div className="row">
                        {this.props.data
                            ? this.props.data.map((d, i) => (
                                    <div key={`${d.name}-${i}`} className="col-md-6">
                                        <div className="main-review-item">
                                            <div className="main-review-item-content">
                                                <div className="main-review-item-meta">
                                                    <p>"{d.text}"</p>
                                                    <div className="wrap-content-parent">
                                                        <div className="stars">
                                                            <ReactStars
                                                                st
                                                                count={5}
                                                                size={24}
                                                                value={d.stars}
                                                                edit={false}
                                                                isHalf={true}
                                                                activeColor="#ff9222" />
                                                        </div>
                                                        <span>{d.name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            : "loading"}
                    </div>
                </div>
            </div>
        );
    }
}

export default Reviews;
