import React, { Component } from "react";
import {
    Button, Spinner, Input, Form
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';

import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';

import {
    BiDonateHeart
} from 'react-icons/bi';

import { 
    toDateTimeString 
} from 'utils/Utils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";

export class ProductEdit extends Component {
    constructor(props) {
        super(props);
        const product = {};
        if (props.product != null) {
            for (const [key, value] of Object.entries(props.product)) {
                product[key] = value;
            }
        }
        
        this.state = {
            product: product,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, product} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.product !== product)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(product)) {
                challengeVal[key] = value;
            }
            this.setState({
                product: challengeVal
            })
        }
    }

    onSaveClick(product, newImage) {
        const {fundraise, onCloseClick, onChanged} = this.props;
        const objRef = this;
        if (product.title == null || product.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (product.id == null || product.id === '') {
                product.id = generateId("/fundraise/" + fundraise.id  + "/product/");
            }
            product.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(objRef, product, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
                if (onChanged != null) {
                    onChanged(product)
                }
            }, (error) => {
                alert("Submitting Change failed.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
            })
        }
    }

    saveChangeWithImage(objRef, product, newImage, success, failure) {
        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(product.id, 'product', newImage, (url) => {
                product.thumbUri = url;
                //success(product);
                objRef.saveChange(product, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(product, success, failure)
        }
    }

    saveChange(product, success, failure) {
        const {fundraise} = this.props;

        if (fundraise) {
            product.fundraiseId = fundraise.id
            product.owner = fundraise.owner;
            if (fundraise.managers) {
                product.managers = {};
                for (const [k,v] of Object.entries(fundraise.managers)) {
                    product.managers[k] = v;
                }
            }
        }
        if (!fundraise.product) {
            fundraise.product = {};
        }
        fundraise.product[product.id] = product;
        const data = {map: fundraise}
        
        invokeHttpsApi('fundraise-submitFundraise', data, (data) => {
            if (data && data.success) {
                success(product);
            } else {
                success(product);
            }
        }, (error) => {
            console.log('save error', error);
            failure(error);
        });
    }

    renderDonateItem(item) {
        const {users} = this.props;

        const price = parseFloat(item.price) / 100;

        let msg = "";
        if (item.uid && users[item.uid]) {
            msg = users[item.uid].username;
        }
        let timestamp = item.timestamp;
        if (!timestamp) {
            timestamp = 0;
        }

        return (
            <div className="wrap-content-parent" style={{margin: 0, padding: 5}}>
                <span className="wrap-content-wrap-child fund-table-info" style={{fontSize: 20, margin: '5px 15px'}}><BiDonateHeart/></span>
                <div className="wrap-content-fill-child">
                    <h4 className='fund-table-info'>
                    {msg} {/*<span style={{color: 'blue'}}>{item.productStr ? "Number: " + item.productStr : ""}</span> */}
                    </h4>
                    <div className="fund-table-info">
                    <b style={{color: '#38be55', marginRight: 15}}>${price}</b> {toDateTimeString(timestamp)}
                    </div>
                </div>
            </div>
        );
    }

    renderDonates() {
        const {fundraise} = this.props;
        const {product} = this.state;
        let balance = 0;
        let paymentsArr = [];
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (item.productId === product.id) {
                    if (item.price && item.fee) {
                        balance += (parseFloat(item.price) - parseFloat(item.fee)) / 100;
                    } else if (item.price) {
                        balance += parseFloat(item.price) / 100;
                    }
                    paymentsArr.push(item);
                }
            }
        }

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        const income = [];
        
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            income.push(
                <div>{this.renderDonateItem(item)}</div>
            );
        }

        const items = [];
        for (const item of income) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div style={{margin: '5px 0px', borderRadius: 6, border: '1px solid rgba(0,0,0,0.2)'}}>
                <div className="wrap-content-parent fundraise-margin" style={{marginTop: 10}}>
                    <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24, marginBottom: 0, marginLeft: 10}}>${balance} </h1>
                    <div className="wrap-content-fill-child fund-table-info" style={{marginTop: 'auto'}}>{t('collected_of_goal')} ${fundraise.donateGoal} {t('goal')}</div>
                </div>
                <div className="divider"/>
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {createMode, onCloseClick} = this.props;
        const {product, newImage, imageProgress, progress} = this.state;

        let numArr = [];
        for (let i = 1; i < 50; i ++) {
            numArr.push(i);
        }
        
        if (product != null) {
            
            return (
                <Form className='form-edit'>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Title
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    product.title = e.target.value;
                                    this.setState({product: product});
                                }} value={product.title} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Image
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <HorizontalAttachImageEdit
                                imageUrl={product.thumbUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Description
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="textarea" className='form-control' name="text" id="title" onChange={e => {
                                    product.description = e.target.value;
                                    this.setState({product: product});
                                }} value={product.description} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        {t('product_price')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="number" step={0.01} className='form-control' name="text" id="title" onChange={e => {
                                    product.price = parseFloat(e.target.value);
                                    this.setState({product: product});
                                }} value={product.price} />
                            </div>
                        </div>
                    </div>
                    
                    {product.id && (
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        {t('donates')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            {this.renderDonates()}
                        </div>
                    </div>
                    )}
                    
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <div className='row' style={{textAlign: 'center'}}>
                                <button className='btn btn-secondary btn-edit' style={{margin: '0px auto'}} onClick={e => {
                                this.onSaveClick(product, newImage);
                                }}>{t('create')}</button>
                            </div>
                        ) : (
                            <div className='form-edit-row row'>
                                <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} >
                                    <button className='btn btn-secondary btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(product, newImage);
                                    }}>{t('save')}</button>
                                </div>
                                <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </div>
                            </div>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ProductEdit);