import React, { Component } from 'react';

import userImage from 'assets/img/icons/player_photo_default.png';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import ProfileEdit from 'components/edit/ProfileEdit';
import { Button, Input, Row, Col, Modal, ModalBody } from 'reactstrap';
import { t } from 'i18next';
import { 
    FaUsers
} from 'react-icons/fa';
import ItemsNavigation from 'components/detail/ItemsNavigation';

import { getCurrentPlan, validateEmail, validateEmailStr } from 'utils/Utils';
import { generateId, invokeHttpsApi } from 'utils/API';

export class ClubMembersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            value: 0,
            playerChoice: {},
        };
    }

    componentDidMount() {
    }

    checkEmail(email) {
        const {invites, club, users} = this.props;

        if (!validateEmail(email)) {
            return -1;
        }

        for (const val of Object.values(invites)) {
            if (val.owner) {
                continue
            }
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                if (val.status === 'invited') {
                    return 0;
                } else if (val.status === 'registered') {
                    return 1;
                }
            }
        }
        if (club.playerIds) {
            for (const val of Object.values(club.playerIds)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 2;
                }
            }
        }
        if (club.pending) {
            for (const val of Object.values(club.pending)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 5;
                }
            }
        }
        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return 3;
            }
        }
        return 4;
    }

    getUid(email) {
        const {users} = this.props;

        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return val.uid;
            }
        }
        return null;
    }

    saveChange(team) {
        const { onChanged } = this.props;
        
        invokeHttpsApi('clubQuery-saveClubEdit', {
            club: team
        }, (data) => {

            // Read result of the Cloud Function.
            if (onChanged) {
                onChanged();
            }
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
        });
    }

    renderInvite() {
        const { invites, club, users } = this.props;
        const { searchStr } = this.state;

        const invited = [];
        const players = [];
        const clubPending = [];
        const others = [];

        for (const val of Object.values(invites)) {
            if (val.owner) {
                continue
            }
            if (!searchStr || searchStr.length <= 0 || (val.email && val.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                if (val.uid && users[val.uid]) {
                    val.user = users[val.user];
                }
                invited.push(val);
            }
        }
        for (const [k,user] of Object.entries(users)) {
            if (searchStr && searchStr.length > 0 && user && user.email && user.email.toLowerCase().includes(searchStr.toLowerCase())) {
                if (club.playerIds && Object.values(club.playerIds).includes(k)) {
                    players.push(user);
                } else if (club.pending && Object.values(club.pending).includes(k)) {
                    clubPending.push(user);
                } else if (others.length < 10) {
                    others.push(user);
                }
            }
        }
    
        players.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
    
        clubPending.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
    
        others.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        const items = [];
        for (const val of Object.values(invites)) {
            items.push(
                <TableRowSmall
                    key={val.email}
                    title={(
                        <h3 className='row-badge-title' style={{fontSize: 12}}>
                            {val.email} {val.user ? val.user.username : t('no_user_for_email')} <span  style={{color: '#3592dd'}}>{t('invited')}</span>
                        </h3>
                    )}
                    icon={val.user && val.user.photoUri ? val.user.photoUri : userImage} />
            )
        }
        for (const val of Object.values(clubPending)) {
            items.push(
                <TableRowSmall
                    key={val.uid}
                    title={(
                        <h3 className='row-badge-title' style={{fontSize: 12}}>
                            {val.email} {val.username} <span  style={{color: '#EE000F'}}>{t('offline_invited')}</span>
                        </h3>
                    )}
                    icon={val.photoUri ? val.photoUri : userImage}
                    detailLabel={t('pending')}
                    onClickDetails={e=> {
                        
                    }} />
            )
        }
        for (const val of Object.values(players)) {
            items.push(
                <TableRowSmall
                    key={val.uid}
                    title={(
                        <h3 className='row-badge-title' style={{fontSize: 12}}>
                            {val.email} {val.username} <span  style={{color: '#38be55'}}>{t('member')}</span>
                        </h3>
                    )}
                    icon={val.photoUri ? val.photoUri : userImage} />
            )
        }
        for (const val of Object.values(others)) {
            items.push(
                <TableRowSmall
                    key={val.uid}
                    title={(
                        <h3 className='row-badge-title' style={{fontSize: 12}}>
                            {val.email} {val.username}
                        </h3>
                    )}
                    icon={val.photoUri ? val.photoUri : userImage}
                    detailLabel={t('invite')}
                    onClickDetails={e=> {
                        if (window.confirm(t('send_email_invite_prompt'))) {
                            this.sendInvite(val.email);
                        }
                    }} />
            )
        }

        return (
            <div className='shadow-tile-normal'>
                <div className='wrap-content-parent' style={{background: 'white', padding: '12px 10px'}}>
                    <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                        <div className='items-navigation-count'><FaUsers/></div>
                        <h3 className='items-navigation-title'>
                        {t('invites')}
                        </h3>
                        <div className='items-navigation-count'>| {items.length} {t('users')}</div>
                    </div>
                    
                </div>
                <div className='divider' />
                <div className='divider' />
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="search" id="search" placeholder='Search by email ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </div>
                </div>
                <div className='divider' />
                <div className='divider' />
                
                {items.length > 0 ? (
                    <div style={{marginTop: 10}} className='wrap-content-fill-child'>
                        {items}
                    </div>
                ) : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.sendInvite(validateEmailStr(searchStr));
                        }}>{t('invite')}</Button>
                    </div>
                )}
            </div>
        );
    }

    sendInvite(email) {
        const { club, onChanged } = this.props;
        var status = this.checkEmail(email)

        if (status === -1) {
            alert("This email address is invalid.")
            return;
        }
        
        if (status === 2) {
            alert("This email is already registered.")
            return;
        }
        
        if (status === 5) {
            alert("This email is already pending.")
            return;
        }
        
        if (status === 1) {
            alert("This email is already invited for creating organization.")
            return;
        }
        
        const data = {email: email, teamId: club.id, teamTitle: club.title};
        if (status === 3) {
            data.uid = this.getUid(email);
        }

        invokeHttpsApi('invite-invitePlayer', data, (data) => {
            if (onChanged) {
                onChanged()
            }
            if (status === 3) {
                alert(t('invite_register_email_sent'));
            } else {
                alert(t('invite_email_sent'));
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    renderMembers() {
        const { club, primarySubscription, users, user, onClickView } = this.props;
        const curConetntCount = club.playerIds != null ? Object.values(club.playerIds).length : 0;
        const contentLimit = getCurrentPlan(primarySubscription).playerLimit;

        const members = [];
        const toAdd = [];
        const uid = user && user.uid ? user.uid : "null";
    
        for (const[key, item] of Object.entries(users)) {
    
            if (club.playerIds != null && Object.values(club.playerIds).includes(item.uid)) {
                
                const thumbImage = item.photoUri != null ? item.photoUri : userImage;
                const editable = item.owner === club.owner && (uid === club.owner || (club.managers && Object.values(club.managers).includes(uid)));
                members.push(
                    <TableRowSmall 
                        key={item.uid}
                        title={item.username}
                        icon={thumbImage}
                        detailLabel={t('remove')}
                        onClickDetails={e=> {
                            if (window.confirm('Are you sure you want to remove this one from your team?')) {
                                delete club.playerIds[key];
                                this.saveChange(club);
                            }
                        }}
                        onClickView={e=> {
                            if (onClickView) {
                                onClickView(item);
                            }
                        }}
                        editLabel={t('edit')}
                        onClickEdit={editable ? () => {
                            this.setState({playerChoice: item})
                        } : null} />
                );
            } else if (club.pending != null && Object.values(club.pending).includes(item.uid)) {
                const thumbImage = item.photoUri != null ? item.photoUri : userImage;
                toAdd.push(
                    <TableRowSmall 
                        key={key}
                        title={item.username}
                        icon={thumbImage}
                        detailLabel={t('accept')}
                        onClickDetails={() => {
                            if (contentLimit !== -1 && curConetntCount >= contentLimit) {
                                alert("Your team reached team member count limit. Upgrade your membership to unlock it.");
                            } else {
                                delete club.pending[key];
                                if (!club.playerIds) {
                                    club.playerIds = {};
                                }
                                club.playerIds[key] = key;
                                this.saveChange(club);
                            }
                        }}
                        editLabel={t('reject')}
                        onClickEdit={() => {
                            delete club.pending[key];                            
                            this.saveChange(club);
                        }} />
                );
            }
        }

        return (
            <div className='shadow-tile-normal'>
                <div className='wrap-content-parent' style={{background: 'white', padding: '12px 10px'}}>
                    <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                        <div className='items-navigation-count'><FaUsers/></div>
                        <h3 className='items-navigation-title'>
                        {t('members')}
                        </h3>
                        <div className='items-navigation-count'>| {toAdd.length + members.length} {t('users')}</div>
                    </div>
                </div>
                <div className='divider' />
                <div className='divider' />
                
                {toAdd.length + members.length > 0 ? (
                <div>
                    {toAdd}
                    {members}
                </div>
                ) : (
                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                {t('no_data')}
                </div>
                )}
            </div>
        );
    }

    render() {
        const { primarySubscription, user, users, club, onChanged } = this.props;
        const { playerChoice } = this.state;
        
        let curConetntCount = club.playerIds != null ? Object.values(club.playerIds).length : 0;
        let contentLimit = getCurrentPlan(primarySubscription).playerLimit;
        const uid = user ? user.uid : "";
        const editable = club.owner === uid || (club.managers && Object.values(club.managers).includes(uid))

        return (
            <div>
                <div style={{marginTop: 20}}>
                <ItemsNavigation
                    title={t('members') + " (" + t('limit') + ": " + (contentLimit === -1 ? "Unlimited" : contentLimit) + ")"}
                    icon={<FaUsers/>}
                    totalCount={curConetntCount}
                    detailItem={{}}
                    onClickAdd={editable ? ()=>{
                        this.setState({playerChoice: {
                            uid:  generateId("/user"),
                            owner: club.owner,
                            email: users[club.owner] ? users[club.owner].email : null,
                            newUser: true,
                        }});
                    } : null} />
                </div>
                <Row style={{margin: 0}}>
                    <Col xs={12} sm={6}>
                        {this.renderMembers()}
                    </Col>
                    <Col xs={12} sm={6}>
                        {this.renderInvite()}
                    </Col>
                </Row>
                <Modal isOpen = {playerChoice.uid}>
                    <ModalBody>
                        <ProfileEdit 
                            user={user}
                            profile={playerChoice}
                            teamId={club.id}
                            onSaveSuccess={() => {
                                this.setState({playerChoice: {}})
                                if (onChanged) {
                                    onChanged();
                                }
                            }}
                            onCloseClick={() => {
                                this.setState({playerChoice: {}})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ClubMembersView;