import { authHeader } from '../_helpers';
import { getDatabase, ref, child, get, set } from "firebase/database";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";

export const userService = {
    checkLogin,
    login,
    logout,
    register,
    sendResetEmail,
    getAll,
    getById,
    update,
    delete: _delete
};

function checkLogin(userId) {

    if (userId != null) {
        const dbRef = ref(getDatabase());
        let adminUid = null;
        return get(child(dbRef, 'adminUid'))
        .then((snapshot) => {
            adminUid = snapshot.val();

            return get(child(dbRef, `user/${userId}`))
        })
        .then((snapshot) => {
            const user = snapshot.val();
            if (user != null && user.uid === adminUid) {
                user.isAdmin = true;
            } else {
                user.isAdmin = false;
            }

            return Promise.resolve(user);
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;

            console.log(errorCode, errorMessage);
            alert(errorMessage);
            signOut(getAuth());

            return Promise.reject(error);
        });
    } else {
        signOut(getAuth());
        return Promise.reject(null);
    }
}

function login(username, password) {
    
    return signInWithEmailAndPassword(getAuth(), username, password)
    .then((userCredential) => {
        const userId = userCredential && userCredential.user ? userCredential.user.uid : null;

        if (userId) {
            
            const dbRef = ref(getDatabase());
            let adminUid = null;
            return get(child(dbRef, 'adminUid'))
            .then((snapshot) => {
                adminUid = snapshot.val();

                return get(child(dbRef, `user/${userId}`))
            })
            .then((snapshot) => {
                const user = snapshot.val();
                if (user != null && user.uid === adminUid) {
                    user.isAdmin = true;
                } else {
                    user.isAdmin = false;
                }
    
                return Promise.resolve(user);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;

                console.log(errorCode, errorMessage);
                alert(errorMessage);
                signOut(getAuth());

                return Promise.reject(error);
            });
        } else {
            signOut(getAuth());
            return Promise.reject(null);
        }
    })
    .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        console.log(errorCode, errorMessage);
        alert(errorMessage);
        signOut(getAuth());

        return Promise.reject(error);
    });
}

function logout() {
    signOut(getAuth());
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    
    return createUserWithEmailAndPassword(getAuth(), user.email, user.password)
    .then((userCredential) => {
        const userId = userCredential && userCredential.user ? userCredential.user.uid : null;
        const toRegister = {
            uid: userId,
            email: user.email,
            type: user.type,
            username: user.username,
        };

        if (userId) {
            
            const dbRef = ref(getDatabase());
            return set(child(dbRef, `user/${userId}`), toRegister)
            .then((snapshot) => {
                return Promise.resolve(toRegister);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;

                console.log(errorCode, errorMessage);
                alert(errorMessage);
                signOut(getAuth());

                return Promise.reject(error);
            });
        } else {
            signOut(getAuth());
            return Promise.reject(null);
        }
    })
    .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        console.log(errorCode, errorMessage);
        alert(errorMessage);
        signOut(getAuth());

        return Promise.reject(error);
    });
}

function sendResetEmail(email) {
    
    return sendPasswordResetEmail(getAuth(), email)
    .then(() => {
        return Promise.resolve({
            success: true
        })
    })
    .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;

        console.log(errorCode, errorMessage);
        alert(errorMessage);

        return Promise.reject(error);
    });
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}