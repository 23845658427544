import React, { Component } from "react";
import { 
    Button, 
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
 } from 'reactstrap';
 import { t } from 'i18next';

export class ItemsDrowpdownNav extends Component {
    constructor(props) {
        super(props)
        this.state={
            dropdown: false
        }
    }

    render() {
        const {title, subtitle, choiceTitle, createTitle, icon, 
            detailItem, dropdownItems, dropDownArray, onItemChoice, 
            onCreateChoice, detailOpen, editMode, editOpen, onClickBack, 
            onClickAdd, hideNoChoice, hideItems} = this.props;
        const {dropdown} = this.state;

        const showDetailOption = (detailOpen || editOpen) && (detailItem.id != null);
        const array = dropDownArray ? dropDownArray : (dropdownItems ? Object.values(dropdownItems) : [])
        
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <div className='detail-nav-btn'>
                        {icon && (
                            <div className='items-navigation-count'>{icon}</div>
                        )}
                        <h3 className='items-navigation-title'>
                        {title}
                        </h3>
                        <div className='items-navigation-count'>| {array.length} {subtitle != null ? subtitle : 'Total'}</div>
                    </div>
                    {!hideItems && (!hideNoChoice || (detailItem != null && detailItem.id != null)) && (
                    <div style={{display: 'inline-block'}}>
                        <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                        <div className='detail-nav-btn'>
                        <Dropdown addonType="prepend" isOpen={dropdown} toggle={()=>{
                            this.setState({dropdown: !dropdown})
                        }}>
                            <Button outline>{detailItem.title}</Button>
                            <DropdownToggle split outline />
                            <DropdownMenu>
                                <DropdownItem header>{choiceTitle != null ? choiceTitle : 'Choose Items'}</DropdownItem>
                                {array.map((value, idx)=>(
                                    <DropdownItem key={idx} onClick={e=>{
                                        if (onItemChoice != null) {
                                            onItemChoice(value);
                                        }
                                    }}>{value.title}</DropdownItem>
                                ))
                                }
                                <DropdownItem divider />
                                {onCreateChoice != null && (
                                    <DropdownItem onClick={e=>{
                                        if (onCreateChoice != null) {
                                            onCreateChoice();
                                        }
                                    }}>{createTitle ? createTitle : "Create a New One"}</DropdownItem>
                                )}
                                <DropdownItem onClick={e=>{
                                    this.setState({dropdown: false})
                                }}>{t('cancel')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        </div>
                    </div>
                    )}
                </div>
                
                {onClickAdd && !showDetailOption && (
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickAdd != null) {
                        onClickAdd();
                    }
                }}>{editMode ? t('edit') : t('create')}</Button>
                )}
                {(detailOpen || editOpen) && (detailItem.id || hideItems) && onClickBack != null && (
                <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickBack != null) {
                        onClickBack();
                    }
                }}>{t('back')}</Button>
                )}
            </div>
        );
    }
}

export default ItemsDrowpdownNav;