import React, { Component } from "react";
import {
    CardImg, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/player_photo_default.png';

export class TopPlayer extends Component {
    render() {
        const {users, uid, number, title, subtitle} = this.props;
        
        const player = users != null ? users[uid] : null;
        
        if (player != null) {
            const playerIcon = player.photoUri != null ? player.photoUri : defaultImage;
            return (
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <div className='top-player-row'>
                        <div className='top-player-info-fill text-center'>
                            <CardImg
                                className="top-player-icon"
                                src={playerIcon} />
                            <div className="top-player-username">
                            {player.username}
                            </div>
                        </div>
                        <div className='top-player-info-fill text-center'>
                            <div className="top-player-main">
                            {number}
                            </div>
                            <div className="top-player-subInfo">
                            {subtitle}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <div className='top-player-row'>
                        <div style={{width: '100%', height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default withTranslation()(TopPlayer);