import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, ListGroup, ListGroupItem, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { MdAddCircle, MdRemoveCircle, MdRefresh } from 'react-icons/md';
import { generateId } from "utils/API";

export class SurveyOptionQuestionEdit extends Component {
    constructor(props) {
        super(props);
        const question = {};
        if (props.question != null) {
            for (const [key, value] of Object.entries(props.question)) {
                question[key] = value;
            }
        }

        this.state = {
            question: question,
            progress: false
        }
    }
    
    componentDidUpdate(prevProps) {
        const {question} = this.props;
        if ((prevProps.question !== question)) {
            const update = {};
            if (question != null) {
                for (const [key, value] of Object.entries(question)) {
                    update[key] = value;
                }
            }

            this.setState({
                question: update,
                progress: false
            })
        }
    }

    renderOptions() {
        const {survey} = this.props;
        const {question} = this.state;
        const items = [];
        
        if (question != null && question.options) {
            for (const [key, value] of Object.entries(question.options)) {
                items.push(
                    <ListGroupItem key={key}>
                        <Row>
                            <Col sm={8}>
                                <Input type="text" name="text" id="text" onChange={e => {
                                    question.options[key].text = e.target.value;
                                    this.setState({question: question});
                                }} value={value.text} />
                            </Col>
                            <Col sm={3}>
                                <Input type="number" name="value" id="value" onChange={e => {
                                    question.options[key].value = e.target.value;
                                    this.setState({question: question});
                                }} value={value.value} />
                            </Col>
                            <Col sm={1}>
                                <MdRemoveCircle onClick={e=>{
                                    if (window.confirm("Are you sure to want to remove this option?")) {
                                        delete question.options[key];
                                        this.setState({question: question});
                                    }
                                }} />
                            </Col>
                        </Row>
                    </ListGroupItem>
                );
            }

        }
        
        return (
            <FormGroup>
                <div className='wrap-content-parent'>
                    <div className='wrap-content-fill-child' style={{margin: 'auto 5px'}}>
                    Options
                    </div>
                    <div className='wrap-content-wrap-child' style={{fontSize: 35}}>
                        <MdAddCircle onClick={e=>{
                            const id = generateId('/survey/' + (survey.id != null ? survey.id : 'tmp')
                                + '/questions/' + (question.id != null ? question.id : 'tmp') + '/options');

                            const update = {
                                id: id,
                                text: '',
                                value: 0
                            }
                            if (question.options == null) {
                                question.options = {};
                            }
                            question.options[id] = update;
                            this.setState({question: question});
                        }}/>
                        <MdRefresh onClick={e=>{
                            const update = {};
                            if (this.props.question != null) {
                                for (const [key, value] of Object.entries(this.props.question)) {
                                    update[key] = value;
                                }
                            }

                            this.setState({
                                question: update,
                                progress: false
                            })
                        }}/>
                    </div>
                </div>
                <ListGroup id="options">
                    {items}
                </ListGroup>
            </FormGroup>
        );

    }

    render() {
        const {onSaveClick, onCloseClick} = this.props;
        const {question} = this.state;
        
        if (question != null) {
            return (
                <Form>
                    <h4 className='label label-default'>
                        Single Choice Question
                    </h4>
                    <FormGroup>
                        <Label for="text">Text</Label>
                        <Input type="text" name="text" id="text" onChange={e => {
                            question.text = e.target.value;
                            this.setState({question: question});
                        }} value={question.text} />
                    </FormGroup>
                    {this.renderOptions()}
                    <FormGroup>
                        <Button className='modal-button btn-submit' onClick={e => {
                                let completed = true;
                                if (question.text != null && question.text !== '' && question.options != null) {
                                    for (const[, value] of Object.entries(question.options)) {
                                        if (value.text == null || value.text === '' || value.value == null || value.value < 0 || value.value > 100) {
                                            completed = false;
                                        }
                                    }
                                } else {
                                    completed = false;
                                }
                                if (completed) {
                                    if (onSaveClick != null) {
                                        onSaveClick(question);
                                    }
                                } else {
                                    alert("You must fill out all fields");
                                }
                        }}>{t('save')}</Button>
                        <Button className='modal-button btn-cancel' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </FormGroup>
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SurveyOptionQuestionEdit);