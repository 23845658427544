import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state, 
        isAdmin: action.user.isAdmin,
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state, 
        isAdmin: action.user.isAdmin,
        loggedIn: true,
        loggingIn: false,
        user: action.user,
      };
    case userConstants.CHECK_USER:
      return {
        ...state, 
        isAdmin: action.user.isAdmin,
        loggedIn: true,
        user: action.user
      };
    case userConstants.CHECK_USER_FAILURE:
      return {
        ...state, 
        loggedIn: false,
        user: null
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state, 
        loggingIn: false,
        user: null
      };
    case userConstants.RESET_INIT:
      return {
        ...state, 
        resetSuccess: null,
      };
    case userConstants.RESET_REQUEST:
      return {
        ...state, 
        loggingIn: true,
      };
    case userConstants.RESET_SUCCESS:
      return {
        ...state, 
        loggingIn: false,
        resetSuccess: true,
      };
    case userConstants.RESET_FAILURE:
      return {
        ...state, 
        loggingIn: false,
        resetSuccess: false,
        user: null
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.REDIRECT_TO:
      return {
        ...state, 
        redirectTo: action.redirectTo, 
      };
    case userConstants.CHILD_CHOICE:
      return {
        ...state, 
        child: action.child, 
      };
    default:
      return state
  }
}