import Avatar from 'components/detail/Avatar';
import React, { Fragment } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'utils/propTypes';

const HorizontalAvatarList = ({
    tag: Tag,
    avatars,    
    teamMode,
    avatarProps,
    reversed,
    ...restProps
}) => {
    let leng = reversed ? avatars.length + 1 : 1;
    const count = reversed ? () => leng-- : () => leng++;

    return (
        <Tag className="d-flex align-items-center" {...restProps} style={{width: '100%', overflow: 'hidden'}}>
            {avatars &&
                avatars.map(({ avatar, name }, i) => {
                    const index = count();
                    const isFirstItem = i === 0;

                    return (
                        <Fragment key={index}>
                            <Avatar
                                {...avatarProps}
                                id={`HorizontalAvatarList-avatar-${index}`}
                                src={avatar}
                                teamMode={teamMode}
                                style={{
                                    zIndex: index,
                                    border: '1px solid #777',
                                    backgroundColor: '#fff',
                                    marginLeft: !isFirstItem && -20,
                                    marginBottom: '5px',
                                    marginTop:'5px'
                                }}
                            />

                            {!!name && (
                                <UncontrolledTooltip
                                    delay={{ show: 0, hide: 0 }}
                                    target={`HorizontalAvatarList-avatar-${index}`}>
                                    {name}
                                </UncontrolledTooltip>
                            )}
                        </Fragment>
                    );
                })}
        </Tag>
    );
};

HorizontalAvatarList.propTypes = {
    tag: PropTypes.node,
    avatars: PropTypes.arrayOf(
        PropTypes.shape({
            avatar: PropTypes.string.isRequired,
            name: PropTypes.string,
        })
    ).isRequired,
    avatarProps: PropTypes.object,
    reversed: PropTypes.bool,
};

HorizontalAvatarList.defaultProps = {
    tag: 'div',
    avatars: [],
    avatarProps: {},
    reversed: false,
};

export default HorizontalAvatarList;
