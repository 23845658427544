import React, { Component } from "react";
import { homeServices } from 'utils/landing';

export class TeamServices extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: {},
            businesses: {},
            activeIndex: 0,
            animating: false,
        }
    }

    componentDidMount() {
        const {team} = this.props;
        const services = {};
        
        if (team && team.landingData && team.landingData.services) {
            for (const[key, value] of Object.entries(team.landingData.services)) {
                services[key] = value;
            }
        }

        this.setState({
            services: services,
        });
    }

    componentDidUpdate(prevProps) {
        const {team} = this.props;
        if (team !== prevProps.team) {
            const services = {};
            
            if (team && team.landingData && team.landingData.services) {
                for (const[key, value] of Object.entries(team.landingData.services)) {
                    services[key] = value;
                }
            }

            this.setState({
                services: services,
            });
        }
    }

    renderServiceItem(d) {
        return (
            <div key={`${d.name}`} className="col-md-4" style={{padding: 0, margin: 0}}>
                <div className="home-service-wrapper">
                    <img alt="" className="home-service-image"/>
                    <div className="home-service-overlay" style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
                        <h3 className="home-service-title">
                        {d.name}
                        </h3>
                        <ul>
                            {d ? d.items.map((d, i) => <li className='home-service-texts' key={`${d}-${i}`}>{d}</li>) : 'loading'}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    renderServiceItemInverse(d) {
        return (
            <div key={`${d.name}`} className="col-md-4" style={{padding: 0, margin: 0}}>
                <div className="home-service-wrapper-inverse">
                    <img alt="" className="home-service-image"/>
                    <div className="home-service-overlay-inverse">
                        <h3 className="home-service-title-inverse">
                        {d.name}
                        </h3>
                        <ul>
                            {d ? d.items.map((d, i) => <li className='home-service-texts-inverse' key={`${d}-${i}`}>{d}</li>) : 'loading'}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {services} = this.state;
        const service1 = services.service1 != null ? services.service1 : homeServices[0];
        const service2 = services.service2 != null ? services.service2 : homeServices[1];
        const service3 = services.service3 != null ? services.service3 : homeServices[2];

        return (
            <div className="text-center">
                <div className="row" style={{margin: 0}}>
                    {this.renderServiceItem(service1)}
                    {this.renderServiceItemInverse(service2)}
                    {this.renderServiceItem(service3)}
                </div>
            </div>
        );
    }
}

export default TeamServices;
