import React, { Component } from "react";
import { connect } from 'react-redux';
import { FacebookPlayer } from 'components/video/FacebookPlayer';
import GameBadge from 'components/detail/organization/landing/GameBadge';
import GameHighlights from 'components/detail/organization/landing/GameHighlights';
import GameLineUp from 'components/detail/organization/landing/GameLineUp';
import {GameNewsFeed} from 'components/detail/organization/landing/GameNewsFeed';
import * as YoutubePlayer from 'react-player/youtube';
import {
    Button, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { userActions } from 'redux/_actions';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';
import { invokeHttpsApi } from "utils/API";
import { getStorageUrl } from "utils/StorageAPI";
import { devThreshold } from "utils/constants";

class GameDetails extends Component {
    constructor(props) {
        super(props);
        this.timer = 0;
        const game = {};
        const division = {};
        const season = {};

        if (props.game != null) {
            for (const [key, value] of Object.entries(props.game)) {
                game[key] = value;
            }
        }
        if (props.divisions != null && props.divisions[game.divisionId] != null) {
            for (const [key, value] of Object.entries(props.divisions[game.divisionId])) {
                division[key] = value;
            }
        }
        if (props.season) {
            for (const [key, value] of Object.entries(props.season)) {
                season[key] = value;
            }
        } else if (props.seasons != null && props.seasons[game.seasonId] != null) {
            for (const [key, value] of Object.entries(props.seasons[game.seasonId])) {
                season[key] = value;
            }
        }
        if (game.roundData == null && division.category != null && division.category.roundData != null) {
            game.roundData = {}
            for (const [key, value] of Object.entries(division.category.roundData)) {
                game.roundData[key] = value;
            }
        }
        const homeData = season.teamData != null && season.teamData[game.homeTeam] ? season.teamData[game.homeTeam] : {};
        const awayData = season.teamData != null && season.teamData[game.awayTeam] ? season.teamData[game.awayTeam] : {};
        const homeGameList = homeData.gameList != null ? homeData.gameList : {};
        const awayGameList = awayData.gameList != null ? awayData.gameList : {};
        const homeBenchList = homeData.benchList != null ? homeData.benchList : {};
        const awayBenchList = awayData.benchList != null ? awayData.benchList : {};

        if (game.gameList == null) {
            game.gameList = {};
        }
        if (game.benchList == null) {
            game.benchList = {};
        }
        if (!game.gameList[game.homeTeam]) {
            game.gameList[game.homeTeam] = homeGameList;
        }
        if (!game.gameList[game.awayTeam]) {
            game.gameList[game.awayTeam] = awayGameList;
        }
        if (!game.benchList[game.homeTeam]) {
            game.benchList[game.homeTeam] = homeBenchList;
        }
        if (!game.benchList[game.awayTeam]) {
            game.benchList[game.awayTeam] = awayBenchList;
        }
        if (game.homeScore == null || game.awayScore == null || game.winner == null) {
            this.updateGameScores(game);
        }
        
        this.state = {
            game: game,
            division: division,
            progress: false,
            roundChoiceId: null,
            newImage: {},
            imageProgress: 0,
            mainTab: 0,
            editOpen: false,
            config: new Date().valueOf() > devThreshold ? game.season.config : {},
            saveProgress: false,
        }
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, game} = this.props;
        
        if ((prevProps.isOpen === false && isOpen === true)) {
            const update = {};
            for (const [key, value] of Object.entries(game)) {
                update[key] = value;
            }
            this.setState({
                game: update,
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }

        if ((prevProps.game !== game)) {
            const update = {};
            for (const [key, value] of Object.entries(game)) {
                update[key] = value;
            }
            this.setState({
                game: update,
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
    }

    updateGameScores(game) {
        if (game.roundData == null) {
            return;
        }
        let rounds = Object.values(game.roundData);
        rounds.sort((a, b)=> {
            return a.number - b.number;
        })
        let homeScore = 0;
        let awayScore = 0;
        if (game.scores != null) {
            for (const val of Object.values(game.scores)) {
                if (val.teamId === game.homeTeam) {
                    homeScore += val.number;
                }
                if (val.teamId === game.awayTeam) {
                    awayScore += val.number;
                }
            }
        }
        game.homeScore = homeScore;
        game.awayScore = awayScore;
        if (homeScore > awayScore) {
            game.winner = game.homeTeam;
        } else if (homeScore < awayScore) {
            game.winner = game.awayTeam;
        } else {
            game.winner = 'draw';
        }
    }

    renderHeader() {
        const { organization, teams, locations } = this.props;
        const { game, } = this.state;

        return (
            <GameBadge
                game={game}
                teams={teams}
                organization={organization}
                wideMode={false}
                locations={locations}
                detailMode={true}/>
        );
    }

    renderResult() {
        const { users, teams } = this.props;
        const { game } = this.state;
        
        if (game != null) {
            return (
                <GameHighlights
                    users={users}
                    teams={teams}
                    game={game} />
            );
        } else {
            return <div/>;
        }
    }

    renderLineUp() {
        const { seasons, season, cards, users, teams } = this.props;
        const {game, division} = this.state;
        const seasonChoice = season ? season : seasons[game.seasonId];
        
        if (game != null) {
            return (
                <GameLineUp
                    season={seasonChoice}
                    division={division}
                    cards={cards}
                    users={users}
                    teams={teams}
                    game={game} />
            );
        } else {
            return <div/>;
        }
    }

    renderNewsFeed() {
        const { users, teams, posts, onChanged } = this.props;
        const { game } = this.state;
        
        if (game != null) {
            return (
                <GameNewsFeed
                    users={users}
                    teams={teams}
                    game={game}
                    posts={posts}
                    onChanged={() => {
                        if (onChanged) {
                            onChanged()
                        }
                    }}  />
            );
        } else {
            return <div/>;
        }
    }
    
    generateSheet() {
        const {organization, seasons, season, locations, teams} = this.props;
        const {game, division} = this.state;
        
        this.setState({
            url: null,
            progress: true
        });
        const seasonChoice = season ? season : seasons[game.seasonId];
        invokeHttpsApi('gamePdf-generateSheetHtml', {
            game: game,
            organization: organization,
            division: division,
            season: seasonChoice,
            location: locations[game.locationId],
            homeTeam: teams[game.homeTeam] ? teams[game.homeTeam] : {},
            awayTeam: teams[game.awayTeam] ? teams[game.awayTeam] : {}
        }, (data) => {

            if (data.succuess !== false) {
                getStorageUrl('pdf/' + game.id + '-sheet.pdf', (url)=> {
                    this.setState({
                        url: url
                    });
                })
            }
            
            this.setState({
                progress: false
            });
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderSheet() {
        const { organization, user } = this.props;
        const {progress, url} = this.state;

        if (!user || !user.uid !== organization.owner) {
            return <div/>
        }
        return (
            <div style={{marginBottom: 20}}>
                <div className='wrap-content-parent' style={{marginBottom: 5}}>
                    <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                    {t('game_sheet_doc')}
                    </h4>
                    <div className='wrap-content-wrap-child'>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <Button className="btn-edit" onClick={e=> {
                                this.generateSheet();
                            }}>{t('generate')}</Button>
                        )}
                        {url && (
                        <a href={url} target="_blank" rel="noopener noreferrer">Open</a>
                        )}
                    </div>
                </div>
                <div className="divider"/>
            </div>
        )
    }
    render() {
        const {onClickClose} = this.props;
        const {game, mainTab} = this.state;
        const view = document.getElementById('youtube-view')
        const width = view ? view.clientWidth : -1;        

        return (
            <div>
                {this.renderHeader()}
                {game.youtubeId && (
                    <div className="post-media-wrapper" style={{marginLeft: 10}} id='youtube-view'>
                    <div className="post-media-child">
                        <YoutubePlayer 
                            className='post-video-player'
                            width="100%"
                            height="100%"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={'https://www.youtube.com/watch?v=' + game.youtubeId} />
                    </div>
                    </div>
                )}
                {game.facebook && (
                    <div className="post-media-wrapper" style={{marginLeft: 10}} id='youtube-view'>
                    <div className="post-media-child">
                        <FacebookPlayer  
                            className='post-video-player'
                            url={game.facebook}
                            width={width === -1 ? 350 : width - 20}
                            autoplay={true}
                            onPlayerReady={(player)=> {
                                this.setState({player: player})
                            }}
                            startedPlaying={(player)=> {
                                
                            }}
                            finishedPlaying={(player)=> {
                                
                            }}
                            paused={(player)=> {
                                
                            }}
                            error={(player)=> {
                                
                            }} />
                    </div>
                    </div>
                )}
                {this.renderSheet()}
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={mainTab}
                            onChange={(e,val)=> {
                                this.setState({mainTab: val})
                            }}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            textColor="secondary" >

                            <Tab label={t('lineup')} />
                            <Tab label={t('highlights')} />
                            <Tab label={t('news_feed')} />
                        </Tabs>
                    </Box>
                </div>
                {mainTab === 0 && (
                    <div>
                    {this.renderLineUp()}
                    </div>
                )}
                {mainTab === 1 && (
                    <div>
                    {this.renderResult()}
                    </div>
                )}
                {mainTab === 2 && (
                    <div>
                    {this.renderNewsFeed()}
                    </div>
                )}
                {onClickClose && (
                <div className="text-center">
                    <Button className="btn-cancel" onClick={(e) => {
                        onClickClose()
                    }}>{t('close')}</Button>
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { primarySubscription, organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, primarySubscription, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(GameDetails));
export { connectedApp as GameDetails };
