import React, { Component } from "react";
import {
    Row, Col, Card, Button, CardImg
} from 'reactstrap';
import { t } from 'i18next';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { getSeasonStatusDict, toDateString } from 'utils/Utils';
import SubInfoView from 'components/detail/SubInfoView';
import lineupImage from 'assets/img/icons/lineup.png';
import gameImage from 'assets/img/icons/league_default.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import AttachItemEdit from 'components/edit/AttachItemEdit';
import ClubSeasonDetail from 'components/detail/organization/club/ClubSeasonDetail';
import { 
    uploadFileToStorage, 
    getFileExtension 
} from 'utils/FirebaseUtils';
import { invokeHttpsApi, saveSeason } from "utils/API";
import { STATUS_REGISTER } from "utils/constants";

const containerStyle = {
    width: '100%',
    height: '400px'
};

export class SeasonDetailOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameDetail: false,
            gameChoice: {},
            progress: false,
            fileProgress: 0,
            mainTab: 0,
            windowWidth: window.innerWidth,
        };
    }

    uploadDocument(file, id, dir) {
        const {onChanged, season, team} = this.props;
        
        this.setState({progress: true, fileProgress: 0});
        uploadFileToStorage(id, file, dir, url => {
            let data = {
                id: id,
                name: id + ".pdf",
                url: url,
                size: file.size
            }
            const teamData = season.teamData && season.teamData[team.id] ? season.teamData[team.id] : {};
            if (!season.teamData) {
                season.teamData = {};
            }
            if (!season.teamData[team.id]) {
                season.teamData[team.id] = {};
            }
            season.teamData[team.id][id] = data;
            
            invokeHttpsApi('clubQuery-saveSeasonTeamDataChange', {
                seasonId: season.id,
                teamId: team.id,
                teamData: teamData,
            }, (data) => {
                if (data && data.success) {
                    if (onChanged != null) {
                        onChanged();
                    }
                }
                this.setState({progress: false, fileProgress: 0});
            }, (error) => {
                console.log('saveContents error', error);
                this.setState({progress: false, fileProgress: 0});
            });
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({fileProgress: progress});
            } else {
                this.setState({fileProgress: 0});
            }
        }, error => {
            alert("Saving Attach File Failed.\n" + error);
            this.setState({progress: false, fileProgress: 0});
        });
    }

    renderMemberDetail() {
        const {organization, division, season, profileData, users, team, onChanged} = this.props;
        const {progress, fileProgress} = this.state;
        const isMember = season.teamIds != null && Object.values(season.teamIds).includes(team.id);
        const isPending = season.pendingIds != null && Object.values(season.pendingIds).includes(team.id);
        const isInvited = season.invitedIds != null && Object.values(season.invitedIds).includes(team.id);
        const isDirectPending = season.directPending != null && Object.values(season.directPending).includes(team.id);
        const isDivisionMember = organization.teamIds != null && Object.values(organization.teamIds).includes(team.id);

        if (isMember) {
            const teamData = season.teamData && season.teamData[team.id] ? season.teamData[team.id] : {};
            return (
                <div>
                    <div className="wrap-content-parent">
                        <div className='wrap-content-wrap-child'>
                            <h4 className='settings-title' style={{minWidth: 150}}>
                            {t('roster_doc')}
                            </h4>
                            <div className="wrap-content-fill-child  vertical-center-spacing">
                                <AttachItemEdit
                                    attachFile={teamData.rosterDoc}
                                    allowProgress={progress}
                                    label={t('roster_doc')}
                                    progress={fileProgress}
                                    onChange={(file)=> {
                                        let extension = getFileExtension(file.name);
                                        if (extension === "pdf") {
                                            this.uploadDocument(file, "rosterDoc", "season/" + season.id + "/teamData/" + team.id)
                                        } else {
                                            alert("Only Pdf file allowed")
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className='wrap-content-wrap-child'>
                            <h4 className='settings-title' style={{minWidth: 150}}>
                            Travel Doc
                            </h4>
                            <div className="wrap-content-fill-child vertical-center-spacing">
                                <AttachItemEdit
                                    attachFile={teamData.travelDoc}
                                    allowProgress={progress}
                                    label={t('roster_doc')}
                                    progress={fileProgress}
                                    onChange={(file)=> {
                                        let extension = getFileExtension(file.name);
                                        if (extension === "pdf") {
                                            this.uploadDocument(file, "travelDoc", "season/" + season.id + "/teamData/" + team.id)
                                        } else {
                                            alert("Only Pdf file allowed")
                                        }
                                    }} />
                            </div>
                        </div>
                        <div className='wrap-content-wrap-child'>
                            <h4 className='settings-title' style={{minWidth: 150}}>
                            {t('game_sheet_doc')}
                            </h4>
                            <div className="wrap-content-fill-child vertical-center-spacing">
                                <AttachItemEdit
                                    attachFile={teamData.sheetDoc}
                                    allowProgress={progress}
                                    label={t('roster_doc')}
                                    progress={fileProgress}
                                    onChange={(file)=> {
                                        let extension = getFileExtension(file.name);
                                        if (extension === "pdf") {
                                            this.uploadDocument(file, "sheetDoc", "season/" + season.id + "/teamData/" + team.id)
                                        } else {
                                            alert("Only Pdf file allowed")
                                        }
                                    }} />
                            </div>
                        </div>
                    </div>
                    <ClubSeasonDetail 
                        hideHeader={true}
                        team={team}
                        organization={organization}
                        division={division}
                        season={season}
                        profileData={profileData}
                        users={users}
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }} />
                </div>
            )
        } else if (isPending) {
            return (
                <div className='grid-item-row'>
                    <Button className="btn-cancel grid-item-info-fill" onClick={e => {
                        if (season.pendingIds && season.pendingIds[team.id]) {
                            delete season.pendingIds[team.id];
                        }
                        saveSeason(season, () => {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }, (error) => {
                            console.log(error);
                        })
                    }}>{t("cancel_request")}</Button>
                </div>
            )            
        } else if (isInvited) {
            return (
                <div className='grid-item-row'>
                    <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                        let now = new Date().valueOf();
                        if (season.status != null && season.status !== STATUS_REGISTER) {
                            alert('Registration is finished. You can not change status anymore.')
                            return;
                        }
                        if (season.regDeadline != null && now > season.regDeadline) {
                            alert('Registration deadline is exceeded. You can not change status anymore.')
                            return;
                        }
                        if (season.invitedIds && season.invitedIds[team.id]) {
                            delete season.invitedIds[team.id];
                        }
                        if (!season.teamIds) {
                            season.teamIds = {};
                        }
                        season.teamIds[team.id] = team.id;
                        saveSeason(season, () => {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }, (error) => {
                            console.log(error);
                        })
                    }}>{t("accept_invite")}</Button>
                    <Button className="btn-cancel grid-item-info-fill" onClick={e => {
                        if (season.invitedIds && season.invitedIds[team.id]) {
                            delete season.invitedIds[team.id];
                        }
                        
                        saveSeason(season, () => {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }, (error) => {
                            console.log(error);
                        })
                    }}>{t("reject_invite")}</Button>
                </div>
            )
        } else if (isPending) {
            
        } else if (isDirectPending) {
            <Button className="btn-cancel grid-item-info-fill" onClick={e => {
                if (season.directPending && season.directPending[team.id]) {
                    delete season.directPending[team.id];
                }
                
                saveSeason(season, () => {
                    if (onChanged != null) {
                        onChanged();
                    }
                }, (error) => {
                    console.log(error);
                })
            }}>{t("cancel_request")}</Button>
        } else {
            return (
                <Button className="grid-action-red grid-item-info-fill" onClick={e => {
                    let now = new Date().valueOf();
                    if (season.status != null && season.status !== STATUS_REGISTER) {
                        alert('Registration is finished. You can not change status anymore.')
                        return;
                    }
                    if (season.regDeadline != null && now > season.regDeadline) {
                        alert('Registration deadline is exceeded. You can not change status anymore.')
                        return;
                    }
                    if (isDivisionMember) {
                        if (!season.pendingIds) {
                            season.pendingIds = {};
                        }
                        season.pendingIds[team.id] = team.id;
                    } else {
                        if (!season.directPending) {
                            season.directPending = {};
                        }
                        season.directPending[team.id] = team.id;
                    }
                    saveSeason(season, () => {
                        if (onChanged != null) {
                            onChanged();
                        }
                    }, (error) => {
                        console.log(error);
                    })
                }}>{isDivisionMember ? t('register') : t('register_join')}</Button>
            )
        }
    }

    render() {
        const {division, season, profileData, locations} = this.props;
        let dict = getSeasonStatusDict(season);
        const str = dict.str;
        const bgColor = dict.bgColor;
        const fColor = dict.fColor;

        let arr = [];
        for (const val of Object.values(locations)) {
            if (division.locationIds != null && Object.values(division.locationIds).includes(val.id)) {
                arr.push({
                    title: val.title,
                    point: {lat: val.lat, lng: val.lng}
                })
            }
        }
        let teamCount = division.teamIds == null ? 0 : Object.values(division.teamIds).length;
        let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
        let playerCount = 0;
        let gameCount = 0;
        if (profileData != null) {
            for (const value of Object.values(profileData)) {
                if (value.teamId != null && division.teamIds != null 
                    && Object.values(division.teamIds).includes(value.teamId) 
                    && value.organizationId === division.organizationId) {
                    playerCount ++;
                }
            }
        }
        if (season.subSeasons != null) {
            for (const value of Object.values(season.subSeasons)) {
                gameCount += value.games == null ? 0 : Object.values(value.games).length
            }
        }
        const playerLimit = division != null && division.category != null && division.category.playerCount != null ? division.category.playerCount : 10;
        
        return (
            <div>
                <Row>
                    <Col sm={4} xs={12}>
                        <Card style={{padding: '10px 20px'}}>
                            <h3 className=''>{season.title}</h3>
                            <div className='' style={{textTransform: 'uppercase', fontSize: 14, fontWeight: 500}}>{season.type}-{division.gender}</div>
                            <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('sport')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.category != null ? division.category.title : 'Unknown'}
                                    </div>
                                </div>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('age')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.ageTitle != null ? division.ageTitle : 'None'}
                                    </div>
                                </div>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    Status:
                                    </h4>
                                    <h4 className='grid-item-subInfo' 
                                    style={{ 
                                        flex: '0 0 auto', 
                                        textTransform: 'uppercase', 
                                        backgroundColor: bgColor, 
                                        padding: '5px 10px' , 
                                        color: fColor}}>
                                    {str}
                                    </h4>
                                </div>
                            </Row>
                            <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                                <div className='wrap-content-parent'>
                                    <CardImg
                                        className="row-badge-icon-small"
                                        src={gameImage} />
                                    <h4 className='grid-item-subtitle' style={{margin: 'auto 10px'}}>
                                    {t('start_date')}
                                    </h4>
                                    <h4 className='grid-item-subtitle' style={{ display: 'inline', backgroundColor: '#D3E0F1', padding: '2px 10px' , color: '#19447f'}}>
                                    {toDateString(season.startTime)}
                                    </h4>
                                </div>
                                <div className='wrap-content-parent'>
                                    <CardImg
                                        className="row-badge-icon-small"
                                        src={gameImage} />
                                    <h4 className='grid-item-subtitle' style={{margin: 'auto 10px'}}>
                                    {t('reg_date')}
                                    </h4>
                                    <h4 className='grid-item-subtitle' style={{ display: 'inline', backgroundColor: '#FFD2D2', padding: '2px 10px', color: '#FF6E6E'}}>
                                    {toDateString(season.regDeadline)}
                                    </h4>
                                </div>
                            </Row>
                            <div className='text-center'>
                                <SubInfoView fixed title='Teams' icon={teamIcon} number={teamCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Players' icon={playersIcon} number={playerCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Format' icon={lineupImage} subText={playerLimit.toString() + ' v ' + playerLimit.toString()} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Venues' icon={venueIcon} number={locationCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Games' icon={gameImage} number={gameCount} />                            
                            </div>
                            <div className='wrap-content-parent' style={{padding: '10px 0px'}}>
                                <h3 className='wrap-content-wrap-child' style={{fontSize: 14}}>Description:</h3>
                                <div className='wrap-content-fill-child' style={{fontSize: 14}}>{division.description}</div>
                            </div>
                            <div className='divider'/>
                            <div className='wrap-content-parent' style={{padding: '10px 0px'}}>
                                <h3 className='wrap-content-wrap-child' style={{fontSize: 14}}>Division Locations:</h3>
                                <div className='wrap-content-fill-child'>{arr.length === 0 ? "None" : ""}</div>
                            </div>
                            {arr.length > 0 && (
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={arr[0].point}
                                    zoom={15}
                                    >
                                    {arr.map((val,idx)=>(
                                        <Marker key={idx} title={val.title} position={val.point} >
                                        </Marker>
                                    ))}
                                </GoogleMap>
                            </LoadScript>
                            )}
                        </Card>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Card style={{padding: '25px 30px'}}>
                            {this.renderMemberDetail()}
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SeasonDetailOverview;