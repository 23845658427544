import { writeDb } from 'utils/API';
import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';

export const dataService = {
    saveChangeWithImage,
    saveChange,
};

function saveChangeWithImage(dataChange, dataPath, newImage, imageCategory, imageId, imageAttr, updated) {

    return new Promise((resolve, reject) => {
        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(imageId, imageCategory, newImage, (url) => {
                dataChange[imageAttr] = url;
                if (updated != null) {
                    updated(dataChange);
                }
                writeDb(dataPath, dataChange, error => {
                    if (error) {
                        reject(error);
                    } else {
                        const result = {};
                        result.data = {};
                        result.type = "success";
                        if (dataChange != null) {
                            for (const [key, value] of Object.entries(dataChange)) {
                                result.data[key] = value;
                            }
                        }
                        resolve(result);
                    }
                })
            }, (state, progress) => {
                const result = {};
                result.imageProgress = state === 'running' ? progress : 0;
                result.type = "imageProgress";
                resolve(result);
            }, error => {
                reject(error);
            })
        } else {
            writeDb(dataPath, dataChange, error => {
                if (error) {
                    reject(error);
                } else {
                    const result = {};
                    result.data = {};
                    result.type = "success";
                    if (dataChange != null) {
                        for (const [key, value] of Object.entries(dataChange)) {
                            result.data[key] = value;
                        }
                    }
                    resolve(result);
                }
            })
        }
    })
}

function saveChange(dataChange, dataPath) {
    
    return new Promise((resolve, reject) => {
        writeDb(dataPath, dataChange, error => {
            if (error) {
                return reject(error);
            } else {
                const result = {};
                result.data = {};
                result.type = "success";
                if (dataChange != null) {
                    for (const [key, value] of Object.entries(dataChange)) {
                        result.data[key] = value;
                    }
                }
                resolve(result);
            }
        })
    })
}
