import React, { Component } from "react";
import ReactPlayer from 'react-player';
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';

export class ClubChallengeDetailsView extends Component {
    render() {
        const {onCloseClick, clubChallenge} = this.props;
        
        let challengeImage = clubChallenge.thumbUri != null ? clubChallenge.thumbUri : defaultImage;
        
        if (clubChallenge != null) {
            return (
                <div>
                    <Card>
                        <div className='wrap-content-parent detail-header'>
                            <CardImg
                                className="profile-thumb vertical-center-spacing"
                                src={challengeImage}
                                style={{ width: 60, height: 60 }}
                                />
                            <div className="wrap-content-fill-child vertical-center-spacing">
                                <h4 className='label label-default'>
                                {clubChallenge.title}
                                </h4>
                            </div>
                        </div>
                        <CardBody>
                            <div>{clubChallenge.description}</div>
                            <div>Points: {clubChallenge.points}</div>
                            <div>Category: {clubChallenge.categoryTitle}</div>
                            <div>Tags: {clubChallenge.tags}</div>
                        </CardBody>
                    </Card>
                    {clubChallenge.videoUri != null && clubChallenge.videoUri !== '' && (
                    <div>
                        <ReactPlayer 
                            className="default-player"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={clubChallenge.videoUri}
                            width={'80%'}
                            height={'auto'} />

                    </div>
                    )}
                    <Button className='modal-button' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubChallengeDetailsView);