import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FcTreeStructure } from 'react-icons/fc';
import DivisionSetupView from 'components/edit/organization/setup/DivisionSetupView';
import { userActions } from 'redux/_actions';
import { Row, Col, Button } from 'reactstrap';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import ItemsNavigation from 'components/detail/ItemsNavigation';
  
import DivisionBadge from 'components/detail/organization/DivisionBadge';
import DivisionDetail from 'components/detail/organization/DivisionDetail';
import LoadingView from 'components/detail/loading/LoadingView';
import { 
    LEAGUE_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    replaceUrl,
    getDivisionDomain,
} from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';
import { SeasonCreateView } from 'components/edit/organization/setup/SeasonCreateView';
import { SeasonScheduleView } from 'components/edit/organization/setup/SeasonScheduleView';
import { SEASON_TYPE_CUSTOM } from 'utils/constants';

class DivisionsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisions: {},
            organizations: {},
            seasons: {},
            trophies: {},
            users: {},
            teams: {},
            locations: {},
            category: {},
            divisionChoice: {},
            wallets: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        if (this.props.user) {
            this.loadContents(id);
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        const id = this.props.router.params.id;
        if (!prevUid && uid) {
            this.loadContents(id);
        }
    }

    loadContents(id) {
        const {divisionChoice} = this.state;
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-divisionsQuery', {}, (data) => {
            const divisions = {};
            const organizations = {};
            let organization = {};
            const seasons = {};
            const trophies = {};
            const profileData = {};
            const users = {};
            const teams = {};
            const locations = {};
            const category = {};
            const cards = {};
            const wallets = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.trophies)) {
                    trophies[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallet)) {
                    wallets[key] = value;
                }
            }
            let choiceId = id != null ? id : divisionChoice.id;
            let update = divisions[choiceId];
            if (update != null) {
                for (const [key, value] of Object.entries(update)) {
                    divisionChoice[key] = value;
                }
            }

            this.setState({
                divisions: divisions,
                organizations: organizations,
                organization: organization,
                seasons: seasons,
                teams: teams,
                trophies: trophies,
                locations: locations,
                profileData: profileData,
                divisionChoice: divisionChoice,
                users: users,
                category: category,
                cards: cards,
                wallets: wallets,
                loading: false,
                editOpen: Object.values(divisions).length === 0,
                detailOpen: divisionChoice.id != null
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({lodaing: false});
        });
    }

    renderClubSchedules() {

    }

    renderContents() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {organization, divisions, teams, seasons, cards, trophies, wallets, users, 
            seasonCreate, seasonSetup, locations, profileData, divisionChoice, category, detailOpen, editOpen} = this.state;
        const seasonId = this.props.router.params.seasonId;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LEAGUE_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }

        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        if (seasonCreate) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <SeasonCreateView 
                        divisions={divisions}
                        organization={organization}
                        season={seasonCreate}
                        locations={locations}
                        teams={teams}
                        trophies={trophies}
                        uid={user.uid}
                        onSeasonUpdate={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                seasonChoice: update
                            })
                        }}
                        onCloseClick={()=> {
                            this.setState({seasonCreate: null})
                        }}
                        onContinue={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                scheduling: true,
                            })
                        }}
                        onSaveDone={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                            })
                            this.props.router.navigate(`/organizer/divisions/${update.divisionId}/${update.id}`);
                        }} />
                </div>
            );
        } else if (seasonSetup) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <SeasonScheduleView 
                        divisions={divisions}
                        organization={organization}
                        season={seasonSetup}
                        locations={locations}
                        teams={teams}
                        users={users}
                        trophies={trophies}
                        uid={user.uid}
                        onSeasonUpdate={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                seasonChoice: update
                            })
                        }}
                        onCloseClick={()=> {
                            this.setState({seasonSetup: null})
                        }}
                        onContinue={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                scheduling: true,
                            })
                        }}
                        onSaveDone={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                            })
                            this.props.router.navigate(`/organizer/divisions/${update.divisionId}/${update.id}`);
                        }}
                        onUserUpdate={(update, team)=> {
                            if (update) {
                                users[update.uid] = update;
                            }
                            if (team) {
                                teams[team.id] = team;
                            }
                            this.setState({users: users, teams: teams});
                        }} />
                </div>
            );
        } else if (Object.values(divisions).length === 0 || editOpen) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <DivisionSetupView 
                        division={divisionChoice}
                        organization={organization}
                        categorys={category}
                        createMode={Object.values(divisions).length === 0 || !divisionChoice.id} 
                        uid={user.uid}
                        onDivisionUpdate={update => {
                            if (update.id) {
                                divisions[update.id] = update;
                            }
                            this.setState({divisions: divisions, divisionChoice: update});
                        }}
                        onCloseClick={()=> {
                            this.setState({divisionChoice: {}, editOpen: false});
                        }}
                        onSaveDone={(update)=> {
                            if (update.id) {
                                divisions[update.id] = update;
                            }
                            this.setState({divisions: divisions, editOpen: false});
                        }} />
                </div>
            );
        } else if (detailOpen) {
            return (
                <DivisionDetail 
                    division={divisionChoice}
                    seasonId={seasonId}
                    organization={organization}
                    teams={teams}
                    trophies={trophies}
                    locations={locations}
                    profileData={profileData}
                    seasons={seasons}
                    wallets={wallets}
                    createMode={Object.values(divisions).length === 0} 
                    uid={user.uid}
                    user={user}
                    users={users}
                    cards={cards}
                    onPressReferee={() => {
                        this.props.router.navigate('/organizer/register/referee')
                    }}
                    onChanged={(season)=> {
                        if (season == null) {
                            this.loadContents()
                        } else {
                            for (const [key, value] of Object.entries(season)) {
                                seasons[season.id][key] = value;
                            }
                            this.setState({seasons: seasons});
                        }
                    }}
                    onDivisionChanged={(division)=> {
                        if (division == null) {
                            this.loadContents()
                        } else {
                            for (const [key, value] of Object.entries(division)) {
                                divisions[division.id][key] = value;
                            }
                            this.setState({division: division, divisionChoice: division});
                        }
                    }}
                    onSetup={(season)=> {
                        this.setState({seasonSetup: season})
                    }} 
                    onSaveDone={()=> {
                        this.loadContents()
                    }} 
                    onClickEdit={()=> {
                        this.setState({
                            editOpen: true,
                            divisionChoice: divisionChoice})
                    }}
                    onUserUpdate={(update, team)=> {
                        if (update) {
                            users[update.uid] = update;
                        }
                        if (team) {
                            teams[team.id] = team;
                        }
                        this.setState({users: users, teams: teams});
                    }} />
            );
        } else {
            let items = [];
            for (const [key, value] of Object.entries(divisions)) {
                items.push(
                    <Col xl={4} lg={6} xs={12} key={key} className='grid-item-wrapper'>
                        <DivisionBadge 
                            key={key}
                            division={value}
                            seasons={seasons}
                            uid={user.uid}
                            profileData={profileData}
                            allowDetail={true}
                            onClickDetails={()=> {
                                this.setState(
                                {
                                    detailOpen: true,
                                    divisionChoice: value
                                })
                                replaceUrl(getDivisionDomain(organization, value.id));
                            }}
                            onClickEdit={()=> this.setState(
                                {
                                    editOpen: true,
                                    divisionChoice: value
                                })}
                            onSaveDone={()=> {
                                this.loadContents()
                            }} />
                    </Col>
                );
            }
            return (
            <div>
                <div className="text-center">
                    <Button className="btn-blue" onClick={e=> {
                        this.setState({seasonCreate: {
                            type: SEASON_TYPE_CUSTOM,
                        }})
                    }}>
                        {t('create_season')}
                    </Button>
                </div>
                <Row style={{margin: 0}}> 
                {items}                   
                </Row>
            </div>
            );
        }
    }

    render() {
        const {user} = this.props;
        const {organization, loading} = this.state;
        const {divisions, divisionChoice, detailOpen, editOpen} = this.state;
        
        let curConetntCount = 0;
        for (const[, value] of Object.entries(divisions)) {
            if (value.owner === user.uid) {
                curConetntCount += 1;
            }
        }

        if (user == null) {
            return <div/>
        }

        return (
            <div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        <ItemsNavigation
                            title="Divisions"
                            icon={<FcTreeStructure/>}
                            detailOpen={detailOpen}
                            editOpen={editOpen}
                            totalCount={curConetntCount}
                            detailItem={divisionChoice}
                            onClickBack={()=>{
                                this.setState({divisionChoice: {}, detailOpen: false, editOpen: false})
                            }}
                            onClickAdd={()=>{
                                this.setState({divisionChoice: {
                                    organizationId: organization.id,
                                    owner: organization.owner,
                                    yellowNumber: 4,
                                    redSanctionGames: 2,
                                    winPoint: 3,
                                    tiePoint: 1,
                                    lossPoint: 0,
                                    roundResetYellow: true,
                                }, editOpen: true});
                            }} />
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(DivisionsPage));
export { connectedApp as DivisionsPage };
