import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Col, Row, Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultLeauge from 'assets/img/icons/trial_icon.png';
import defaultPlayer from 'assets/img/icons/player_photo_default.png';

export class TrialChallengeReviewFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trialsOpen: false,
            trialChallengesOpen: false,
            usersOpen: false,
            saveProgress: false,
            filterOpen: false,
        }
    }

    renderTrialChoice() {
        const { trials, uidChoice, onFilterChoice } = this.props;

        if (trials != null && Object.values(trials).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onFilterChoice != null) {
                        onFilterChoice({}, uidChoice)
                    }
                    this.setState({trialsOpen: false});
                }}>All Playlists</ListGroupItem>
            )
            for (const[key, value] of Object.entries(trials)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onFilterChoice != null) {
                            onFilterChoice(value, uidChoice)
                        }
                        this.setState({trialsOpen: false});
                    }}>{value.title}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Event Present</div>
        }
    }
    
    renderUserChoice() {
        const { users, submissions, trialChoice, onFilterChoice } = this.props;

        const trialChallengeUsers = {};
        if (submissions != null) {
            for (const[, value] of Object.entries(submissions)) {
                if (users[value.uid] != null) {
                    trialChallengeUsers[value.uid] = users[value.uid];
                }
            }
        }

        if (trialChallengeUsers != null && Object.values(trialChallengeUsers).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onFilterChoice != null) {
                        onFilterChoice(trialChoice, null)
                    }
                    this.setState({usersOpen: false});
                }}>All Users</ListGroupItem>
            )
            for (const[key, value] of Object.entries(trialChallengeUsers)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onFilterChoice != null) {
                            onFilterChoice(trialChoice, value.uid)
                        }
                        this.setState({usersOpen: false});
                    }}>{value.username}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Users Present</div>
        }
    }
    
    render() {
        const { trials, users, trialChoice, submissions, uidChoice, globalMode } = this.props;
        const { trialsOpen, usersOpen, filterOpen } = this.state;
        const userChoice = users[uidChoice];

        let trialImage = trialChoice != null && trialChoice.iconUri != null ? trialChoice.iconUri : defaultLeauge;
        let userImage = userChoice != null && userChoice.photoUri != null ? trialChoice.photoUri : defaultPlayer;
        
        if (trials != null) {
            return (
                <div>
                    <h3 style={{fontSize: 12}}>Review Filter</h3>
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <Row>
                                {globalMode && (
                                    <Col sm={4}>
                                        <CardImg
                                            className="profile-thumb vertical-center-spacing"
                                            src={trialImage}
                                            style={{ width: 25, height: 25, marginLeft:5, marginRight: 10 }}
                                            />
                                        
                                        <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>
                                        {trialChoice.id != null ? trialChoice.title : 'No Event Choice'}
                                        </div>
                                        
                                    </Col>
                                )}
                                <Col sm={4}>
                                    <CardImg
                                        className="profile-thumb vertical-center-spacing"
                                        src={userImage}
                                        style={{ width: 25, height: 25, marginLeft:5, marginRight: 10 }}
                                        />
                                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>
                                    {userChoice != null && userChoice.uid != null ? userChoice.username : 'No User Choice'}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Button className='wrap-content-wrap-child' onClick={e => {
                            this.setState({filterOpen: true})
                        }}>Change Filter</Button>
                    </div>
                    <Modal isOpen={filterOpen}>
                        <ModalBody>
                            {globalMode && (
                                <Card style={{marginBottom: 20}}>
                                    <CardBody>
                                        <div className='wrap-content-parent'>
                                            <div className='wrap-content-fill-child'>
                                                <div style={{fontSize: 12}}>
                                                    Event Filter: {Object.values(trials).length} Playlists
                                                </div>
                                                {trialChoice.id != null ? (
                                                    <div className='wrap-content-parent'>
                                                        <CardImg
                                                            className="profile-thumb flex-row wrap-content-wrap-child vertical-center-spacing"
                                                            src={trialImage}
                                                            style={{ width: 25, height: 25 }}
                                                            />
                                                        <h3 className='wrap-content-fill-child vertical-center-spacing'>{trialChoice.title}</h3>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        No Event Choice
                                                    </div>
                                                )}
                                            </div>
                                            <Button className='wrap-content-wrap-child' onClick={e => {
                                                this.setState({trialsOpen: true})
                                            }}>Pick Event</Button>

                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                            <Card style={{marginBottom: 20}}>
                                <CardBody>
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child'>
                                            <div style={{fontSize: 12}}>
                                                Users Filter: {Object.values(submissions).length} User
                                            </div>
                                            {userChoice != null && userChoice.uid != null ? (
                                                <div className='wrap-content-parent'>
                                                    <CardImg
                                                        className="profile-thumb flex-row wrap-content-wrap-child vertical-center-spacing"
                                                        src={userImage}
                                                        style={{ width: 25, height: 25 }}
                                                        />
                                                    <h3 className='wrap-content-fill-child vertical-center-spacing'>{userChoice.username}</h3>
                                                </div>
                                            ) : (
                                                <div>
                                                    No User Choice
                                                </div>
                                            )}
                                        </div>
                                        <Button className='wrap-content-wrap-child' onClick={e => {
                                            this.setState({usersOpen: true})
                                        }}>Pick User</Button>

                                    </div>
                                </CardBody>
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({filterOpen: false})
                            }}>{t('close')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={trialsOpen}>
                        <ModalBody>
                            {this.renderTrialChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({trialsOpen: false})
                            }}>{t('cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={usersOpen}>
                        <ModalBody>
                            {this.renderUserChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({usersOpen: false})
                            }}>{t('cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TrialChallengeReviewFilter);