import React, { Component } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {TeamNav} from 'components/landing/team/TeamNav';
import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import { Outlet } from 'react-router-dom';
import { listenDb, listenDbQuery } from 'utils/API';
import { withRouter } from 'utils/Routher';

class TeamLanding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            team: {},
            loading: true,
        }
    }

    componentDidMount() {
        let id = null;
        let customDomain = null;
        let userId = null;
        let targetId = null;
        //let email = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
            userId = this.props.router.params.userId;
            targetId = this.props.router.params.targetId;
            //email = this.props.router.params.email;
        }
        
        if (id) {
            listenDb("/squad/" + id, ((data)=> {
                
                const team = {};
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        team[key] = value;
                    }
                }
    
                this.setState({team: team, loading: false});
            }))
        } else if (customDomain) {
            listenDbQuery("/squad", "customDomain", customDomain, ((data)=> {
                const team = {};
                if (data) {
                    for (const v of Object.values(data)) {
                        for (const [key, value] of Object.entries(v)) {
                            team[key] = value;
                        }
                    }
                }
    
                this.setState({team: team, loading: false});
            }))
        }
        if (userId) {
            listenDb("/user/" + userId, ((data)=> {
                
                const user = {};
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        user[key] = value;
                    }
                }
    
                this.setState({assignUser: user});
            }))
        }
        if (targetId) {
            listenDb("/user/" + targetId, ((data)=> {
                
                const user = {};
                if (data) {
                    for (const [key, value] of Object.entries(data)) {
                        user[key] = value;
                    }
                }
    
                this.setState({targetUser: user});
            }))
        }
    }

    render() {
        const { user } = this.props;
        const { team, loading, assignUser, targetUser } = this.state;
        let id = '';
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
        }

        const users = {};
        if (user) {
            users[user.uid] = user;
        }
        if (assignUser) {
            users[assignUser.uid] = assignUser;
        }
        if (targetUser) {
            users[targetUser.uid] = targetUser;
        }
        
        if (loading && !team.id) {
            return (
                <LoadingScreen />
            )
        }
        
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const uid = user ? user.uid : null;
        const mail = user ? user.email : "";
        const userId = this.props.router.params.userId;
        const targetId = this.props.router.params.targetId;
        const email = this.props.router.params.email;

        let message = null;
        if (this.props.router.location.pathname.includes('assignManager/') || this.props.router.location.pathname.includes('assignManagerEmail/')) {

            if (userId && uid !== userId && users[userId]) {
                message = (
                    <div className='text-center'>
                        <p>{t('coach_merge_msg')}</p>
                        <p>{t('change_login_prompt') + users[userId].email}</p>
                    </div>
                )
            } else if (email && email.toLowerCase() !== mail.toLowerCase()) {
                message = (
                    <div className='text-center'>
                        <p>{t('coach_merge_msg')}</p>
                        <p>{t('change_login_prompt') + email}</p>
                    </div>
                )
            }
        } else if (this.props.router.location.pathname.includes('assignPlayer/') || this.props.router.location.pathname.includes('assignPlayerEmail/')) {

            if (userId && uid !== userId && users[userId]) {
                message = (
                    <div className='text-center'>
                        <p>{t('player_merge_msg')}</p>
                        <p>{t('change_login_prompt') + users[userId].email}</p>
                    </div>
                )
            } else if (email && email.toLowerCase() !== mail.toLowerCase()) {
                message = (
                    <div className='text-center'>
                        <p>{t('player_merge_msg')}</p>
                        <p>{t('change_login_prompt') + email}</p>
                    </div>
                )
            }
        } else if (targetId && users[targetId]) {
            let forChild = this.props.router.location.pathname.includes('assignChild/') || this.props.router.location.pathname.includes('assignEmailChild/');

            if (userId && uid !== userId && users[userId]) {
                message = (
                    <div className='text-center'>
                        <p>{t(forChild ? 'merge_child_profile_msg' : 'merge_user_profile_msg')}</p>
                        <p>{t('change_login_prompt') + users[userId].email}</p>
                    </div>
                )
            } else if (email && email.toLowerCase() !== mail.toLowerCase()) {
                message = (
                    <div className='text-center'>
                        <p>{t(forChild ? 'merge_child_profile_msg' : 'merge_user_profile_msg')}</p>
                        <p>{t('change_login_prompt') + email}</p>
                    </div>
                )
            }
        }
        
        return (
            <div className='blue-landing' style={team.landingData && team.landingData.blueUrl ? {backgroundImage: 'none'} : {}}>
                {team.landingData && team.landingData.blueUrl && (
                <img style={{position: 'fixed', top: 0, width: '100%', height: '100%', objectFit: 'cover'}} alt="custom" src={team.landingData.blueUrl} />
                )}
                {team.landingData && team.landingData.blueUrl && (
                <div style={{position: 'fixed', top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(22,22,22,0.3)'}} />
                )}
                <TeamNav 
                    team={team} 
                    users={users} 
                    history={this.props.router}
                    loading={loading}
                    authMsg={message}
                    authEmail={email}
                    itemId={id}
                    onTeamUpdate={update => {this.setState({team: update})}} />
                <div className='blue-contents-wrapper'>
                    <Outlet />
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    setChild: userActions.setChild,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamLanding));
export { connectedApp as TeamLanding };
