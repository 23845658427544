import React, { Component } from 'react';

import { Button, Col, Row, Modal, ModalBody, Spinner, Input, CardImg } from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import EmptyScreen from 'components/detail/loading/EmptyScreen';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import ProfileEdit from 'components/edit/ProfileEdit';

import { 
    FcManager,
} from 'react-icons/fc';

import { 
    FaUsers
} from 'react-icons/fa';
import { 
    RiMoneyDollarBoxFill
} from 'react-icons/ri';
import { loadStripe } from '@stripe/stripe-js';
import { 
    getOrgData,
    getOrgDomain,
    sortByNumber,
    sortByNumberInverse,
    sortByTitle,
    STRIPE_PUBLISHABLE_KEY,
    toDateTimeString
} from 'utils/Utils';

import { 
    GrArticle,
    GrTrophy
} from 'react-icons/gr';

import userIcon from 'assets/img/icons/player_photo_default.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';
import appIcon from 'assets/img/logo/live_192.png';
import {OrganizationDivisionOverview} from 'components/detail/organization/landing/OrganizationDivisionOverview';
import OganizationDivisionGames from 'components/detail/organization/landing/OganizationDivisionGames';
import OrganizationDivisionStanding from 'components/detail/organization/landing/OrganizationDivisionStanding';
import OrganizationDivisionStatistics from 'components/detail/organization/landing/OrganizationDivisionStatistics';
import OrganizationTeamStandings from 'components/detail/organization/landing/OrganizationTeamStandings';
import OrganizationTeamStatistics from 'components/detail/organization/landing/OrganizationTeamStatistics';
import OrganizationPlayerCard from 'components/detail/organization/landing/OrganizationPlayerCard';

import {ProgramLandingOverview} from 'components/detail/organization/program/ProgramLandingOverview';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import { SambaPostHeader } from 'components/landing/organization/samba/news/SambaPostHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import {PostEdit} from 'components/edit/community/PostEdit';
import { generateId, invokeHttpsApi } from 'utils/API';
import CustomTabView from 'components/template/CustomTabView';
import LoadingView from 'components/detail/loading/LoadingView';
import { withRouter } from 'utils/Routher';
import { GENDER_COED, GENDER_FEMALE, GENDER_MALE, STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from 'utils/constants';
import { OrganizationProfileEdit } from 'components/edit/organization/OrganizationProfileEdit';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import { SambaOrgTeamSquad } from 'components/detail/organization/landing/samba/SambaOrgTeamSquad';
import GamesTable from 'components/detail/organization/landing/samba/GamesTable';
import { filterActions } from 'redux/_actions/filter.actions';

class OrgAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organization: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            mainTab: 0,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list',
            balanceInput: 0,
            balanceType: 'direct',
            seasonStatus: STATUS_REGISTER,
        }
    }
    
    componentDidMount() {
        if (this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        const data = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizationLandingQuery', data, (data) => {
            const organizations = {};
            const organization = {};
            const divisions = {};
            const seasons = {};
            const programs = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const myTeams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const wallet = {};
            const walletPayments = {};

            if (data.success) {
                this.props.updateDict(data);
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                if (organizations[id] != null) {
                    for (const [key, value] of Object.entries(organizations[id])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.programs)) {
                    programs[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.myTeams)) {
                    myTeams[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallet)) {
                    wallet[key] = value;
                }
                for (const [key, value] of Object.entries(data.walletPayments)) {
                    walletPayments[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                programs: programs,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                myTeams: myTeams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                refreshTime: new Date().valueOf(),
                wallet: wallet,
                walletPayments: walletPayments,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    getSeasonsGames(seasons, checkTeamId) {
        let result = [];
        for (const value of Object.values(seasons)) {
            for (const game of this.getSeasonGames(value, checkTeamId)) {
                result.push(game);
            }
        }
        return result;
    }

    getSeasonGames(season, checkTeamId) {
        let result = [];
        if (season.subSeasons) {
            for (const subSeason of Object.values(season.subSeasons)) {
                if (subSeason.games) {
                    for (const game of Object.values(subSeason.games)) {
                        if (checkTeamId(game.homeTeam) || checkTeamId(game.awayTeam)) {
                            result.push(game);
                        }
                    }
                }
            }
        }
        return result;
    }

    renderUpcomingGames(wideMode) {
        const {user, child, filter} = this.props;
        const {organization, users, teams, locations, posts, seasons, divisions, cards} = this.state;

        let upcoming = [];
        const season = filter && filter.seasonId && seasons[filter.seasonId] ? seasons[filter.seasonId] : null;

        const checkTeamId = (teamId) => {
            return true
        };

        let games = season ? this.getSeasonGames(season, checkTeamId) : this.getSeasonsGames(seasons, checkTeamId);

        const now = new Date().valueOf();

        sortByNumber(games, 'start');
        for (const item of games) {
            if (item.start && now < item.start) {
                upcoming.push(item);
            }
        }

        return (
            <div>
                <GamesTable 
                    title={t('upcoming_games')}
                    organization={organization}
                    wideMode={wideMode}
                    games={upcoming}
                    user={user}
                    child={child}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
            </div>
        )
    }

    renderPastGames(wideMode) {
        const {user, child, filter} = this.props;
        const {organization, users, teams, locations, posts, seasons, divisions, cards} = this.state;

        let past = [];
        const season = filter && filter.seasonId && seasons[filter.seasonId] ? seasons[filter.seasonId] : null;

        const checkTeamId = (teamId) => {
            return true
        };

        let games = season ? this.getSeasonGames(season, checkTeamId) : this.getSeasonsGames(seasons, checkTeamId);

        const now = new Date().valueOf();

        sortByNumberInverse(games, 'start');
        for (const item of games) {
            if (item.start && now > item.start) {
                past.push(item);
            }
        }
        
        return (
            <div>
                <GamesTable 
                    title={t('past_games')}
                    organization={organization}
                    wideMode={wideMode}
                    games={past}
                    user={user}
                    child={child}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
            </div>
        )
    }

    renderSchedules() {
        return (
            <div>
            {this.renderUpcomingGames(true)}
            {this.renderPastGames(true)}
            </div>
        )
    }

    renderProfile() {
        const {user, child} = this.props;

        const choice = child ? child : user;

        const myProfile = this.getProfile(choice);
        
        return (
            <div style={{marginBottom: 20}}>
                <div className='items-navigation' style={{marginTop: 20}}>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <CardImg
                                className="row-badge-icon-small" style={{margin: 'auto 10px'}}
                                src={myProfile.verified != null ? (myProfile.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                            <h3 className='items-navigation-title'>
                            {t('player_profile')}
                            </h3>
                        </div>
                    </div>
                    
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({profileEdit: true})
                    }}>{t('setup')}</Button>
                </div>
                
            </div>
        );
    }

    renderSeasons() {
        const { user, child } = this.props;
        const { organization, divisions, seasons, category, seasonChoice, seasonStatus, seasonCategory, seasonGender } = this.state;

        const choice = child ? child : user;

        let items = [];
        let seasonArr = [];
        let mineArr = [];
        
        for (const [, value] of Object.entries(seasons)) {
            let division = divisions[value.divisionId];
            if (division == null) {
                division = {};
            }
            
            if ((seasonGender == null || seasonGender === division.gender) && 
                (seasonStatus == null || value.status === seasonStatus || (seasonStatus === STATUS_REGISTER && value.status == null)) && 
                (seasonCategory == null || division.category.id === seasonCategory)) {
                seasonArr.push(value);
            }
            if (value.registers && Object.keys(value.registers).includes(choice.uid) && !mineArr.includes(value)) {
                mineArr.push(value);
            } else if (value.teamData) {
                for (const teamData of Object.values(value.teamData)) {
                    if (teamData.roaster && Object.keys(teamData.roaster).includes(choice.uid) && !mineArr.includes(value)) {
                        mineArr.push(value)
                    }
                }
            }
        }
        seasonArr.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        mineArr.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        
        for (const value of seasonArr) {
            let division = divisions[value.divisionId];
            if (division == null) {
                division = {};
            }
            const icon = division.thumbUri ? division.thumbUri : appIcon;
            const sport = division.category && division.category.title ? division.category.title : "Unknown"

            items.push(
                <Row style={{padding: '4px 10px'}} onClick={e=> {
                    this.setState({seasonTab: 0, seasonChoice: value})
                }}>
                    <Col sm={3} xs={6}>
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.title}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={icon} style={{display: 'inline', width: 24, height: 24, marginRight: 10}} />
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {division.title}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        {sport} - {division.gender}
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={teamIcon} style={{width: 24, height: 24, marginRight: 5}}/> 
                        {value.teamIds ? Object.values(value.teamIds).length : 0}
                        <FcManager/> {value.managers ? Object.values(value.managers).length : 0}
                        {value.playerFee ? (t('player_fee') + " $" + value.playerFee) : ""}
                    </Col>
                </Row>
            );
            items.push(
                <div className='divider' />
            )
        }
        
        let mine = [];
        for (const value of mineArr) {
            let division = divisions[value.divisionId];
            if (division == null) {
                division = {};
            }
            const icon = division.thumbUri ? division.thumbUri : appIcon;
            const sport = division.category && division.category.title ? division.category.title : "Unknown"

            mine.push(
                <Row style={{padding: '4px 10px'}} onClick={e=> {
                    let path = `${getOrgDomain(organization)}/championships/${value.id}`

                    this.props.router.navigate(path);
                }}>
                    <Col sm={3} xs={6}>
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.title}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={icon} style={{display: 'inline', width: 24, height: 24, marginRight: 10}} />
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {division.title}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        {sport} - {division.gender}
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={teamIcon} style={{width: 24, height: 24, marginRight: 5}}/> 
                        {value.teamIds ? Object.values(value.teamIds).length : 0}
                        <FcManager/> {value.managers ? Object.values(value.managers).length : 0}
                        {value.playerFee ? (t('player_fee') + " $" + value.playerFee) : ""}
                    </Col>
                </Row>
            );
            mine.push(
                <div className='divider' />
            )
        }
        
        return (
            <div style={{marginBottom: 20}}>
                
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('seasons')}
                        icon={<GrArticle/>}
                        detailOpen={seasonChoice != null}
                        totalCount={items.length}
                        detailItem={seasonChoice}
                        onClickBack={()=>{
                            this.setState({seasonChoice: null});
                        }} />
                </div>

                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <Row style={{padding: '4px 10px'}}>
                            <Col sm={3} xs={6}>
                                <h4 className='one-line-title' style={{margin: 'auto 0px'}}>
                                {t('seasons')}
                                </h4>
                            </Col>
                            <Col sm={3} xs={6}>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({seasonStatus: e.target.value});
                                }} value={seasonStatus}>
                                    <option value={null}>{t('select_all')}</option>
                                    <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                    <option value={STATUS_SCHEDULE}>{t('schedule')}</option>
                                    <option value={STATUS_FINISHED}>{t('finished')}</option>
                                    <option value={STATUS_REGISTER}>{t('register')}</option>
                                </select>
                            </Col>
                            <Col sm={3} xs={6}>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({seasonCategory: e.target.value});
                                }} value={seasonCategory}>
                                    <option value={null}>Select All</option>
                                    {Object.values(category).map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.title}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm={3} xs={6}>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({seasonGender: e.target.value});
                                }} value={seasonGender}>
                                    <option value={null}>{t('select_all')}</option>
                                    <option value={GENDER_MALE}>{t('male')}</option>
                                    <option value={GENDER_FEMALE}>{t('female')}</option>
                                    <option value={GENDER_COED}>{t('coed')}</option>
                                </select>
                            </Col>
                        </Row>
                        
                        <div className='divider' />
                        <div className='divider' />
                        {seasonChoice ? (
                            <div>
                                {this.renderSeasonDetails()}
                            </div>
                        ) : (
                            <div>
                            {items.length > 0 ? items : (
                                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                {t('no_data')}
                                </div>
                            )}
                            </div>
                        )}
                    </div>
                </div>

                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <Row style={{padding: '4px 10px'}}>
                            <Col sm={3} xs={6}>
                                <h4 className='one-line-title' style={{margin: 'auto 0px'}}>
                                {t('my_seasons')}
                                </h4>
                            </Col>
                            <Col sm={3} xs={6}>
                                
                            </Col>
                            <Col sm={3} xs={6}>
                                
                            </Col>
                            <Col sm={3} xs={6}>
                                
                            </Col>
                        </Row>
                        
                        <div className='divider' />
                        <div className='divider' />
                        {mine.length > 0 ? mine : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderSeasonDetails() {
        const { user, history } = this.props;
        const {organization, divisions, seasons, seasonChoice, myTeams, cards, users, posts, teams, profileData, locations, wallet, seasonTab } = this.state;
        const divisionChoice = divisions[seasonChoice.divisionId] ? divisions[seasonChoice.divisionId] : {};

        return (
            <div>
                {seasonChoice && seasonChoice.status != null && seasonChoice.status !== STATUS_REGISTER && (
                <CustomTabView 
                    value={seasonTab}
                    onChange={(e, value)=> {
                        this.setState({seasonTab: value})
                    }}
                    variant="scrollable"
                    indicatorColor="secondary"
                    textColor="secondary"
                    items={[
                        {
                            label: t('overview')
                        },
                        {
                            label: t('games')
                        },
                        {
                            label: t('standings')
                        },
                        {
                            label: t('statistics')
                        },
                    ]} />
                )}
                {seasonTab === 0 && (
                <OrganizationDivisionOverview
                    organization={organization}
                    seasons={seasons}
                    users={users}
                    posts={posts}
                    user={user}
                    history={history}
                    teams={teams}
                    myTeams={myTeams}
                    profileData={profileData}
                    locations={locations}
                    division={divisionChoice}
                    divisions={divisions}
                    seasonId={seasonChoice.id}
                    wallet={wallet}
                    seasonChoice={seasonChoice}
                    onSeasonChoice={(update)=> {
                        this.setState({seasonChoice: update})
                    }}
                    onChanged={e=> {
                        this.loadContents();
                    }} />
                )}
                {seasonTab === 1 && (
                <OganizationDivisionGames
                    organization={organization}
                    seasons={seasons}
                    divisions={divisions}
                    posts={posts}
                    users={users}
                    teams={teams}
                    locations={locations}
                    division={divisionChoice}
                    seasonId={seasonChoice.id}
                    cards={cards}
                    seasonChoice={seasonChoice}
                    onSeasonChoice={(update)=> {
                        this.setState({seasonChoice: update})
                    }} />
                )}
                {seasonTab === 2 && (
                <OrganizationDivisionStanding
                    organization={organization}
                    teams={teams}
                    locations={locations}
                    seasons={seasons}
                    division={divisionChoice}
                    seasonId={seasonChoice.id}
                    seasonChoice={seasonChoice}
                    onSeasonChoice={(update)=> {
                        this.setState({seasonChoice: update})
                    }}
                    divisions={divisions} />
                )}
                {seasonTab === 3 && (
                <OrganizationDivisionStatistics
                    organization={organization}
                    seasons={seasons}
                    users={users}
                    division={divisionChoice}
                    seasonId={seasonChoice.id}
                    seasonChoice={seasonChoice}
                    onSeasonChoice={(update)=> {
                        this.setState({seasonChoice: update})
                    }}
                    divisions={divisions} />
                )}
            </div>
        )
    }

    renderPrograms() {
        const {user, child} = this.props;
        const { organization, category, programs, programChoice, programStatus, programCategory, programGender } = this.state;

        const choice = child ? child : user;

        let items = [];
        let programArr = [];
        let mineArr = [];
        
        if (!programs) {
            return <div/>;
        }
        for (const [, value] of Object.entries(programs)) {

            if ((programGender == null || programGender === value.gender) && 
                (programStatus == null || value.status === programStatus || (programStatus === STATUS_REGISTER && value.status == null)) && 
                (programCategory == null || value.category.id === programCategory)) {
                programArr.push(value);
            }
            
            if (value.registers && Object.keys(value.registers).includes(choice.uid) && !mineArr.includes(value)) {
                mineArr.push(value);
            }
        }
        programArr.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        mineArr.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        
        for (const value of programArr) {
            
            const icon = value.thumbUri ? value.thumbUri : appIcon;
            const sport = value.category && value.category.title ? value.category.title : "Unknown"

            items.push(
                <Row style={{padding: '4px 10px'}} onClick={e=> {
                    this.setState({programChoice: value})
                }}>
                    <Col sm={3} xs={6}>
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.title}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={icon} style={{display: 'inline', width: 24, height: 24, marginRight: 10}} />
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.ageTitle} {value.ageFrom} {value.ageTo}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        {sport} - {value.gender}
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={teamIcon} style={{width: 24, height: 24, marginRight: 5}}/> 
                        {value.teamIds ? Object.values(value.teamIds).length : 0}
                        <FcManager/> {value.managers ? Object.values(value.managers).length : 0}
                        {value.playerFee ? (t('player_fee') + " $" + value.playerFee) : ""}
                    </Col>
                </Row>
            );
            items.push(
                <div className='divider' />
            )
        }
        
        let mine = [];
        for (const value of mineArr) {
            
            const icon = value.thumbUri ? value.thumbUri : appIcon;
            const sport = value.category && value.category.title ? value.category.title : "Unknown"

            mine.push(
                <Row style={{padding: '4px 10px'}} onClick={e=> {
                    let path = `${getOrgDomain(organization)}/programs/${value.id}`

                    this.props.router.navigate(path);
                }}>
                    <Col sm={3} xs={6}>
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.title}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={icon} style={{display: 'inline', width: 24, height: 24, marginRight: 10}} />
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.ageTitle} {value.ageFrom} {value.ageTo}
                        </h4>
                    </Col>
                    <Col sm={3} xs={6}>
                        {sport} - {value.gender}
                    </Col>
                    <Col sm={3} xs={6}>
                        <img alt="" src={teamIcon} style={{width: 24, height: 24, marginRight: 5}}/> 
                        {value.teamIds ? Object.values(value.teamIds).length : 0}
                        <FcManager/> {value.managers ? Object.values(value.managers).length : 0}
                        {value.playerFee ? (t('player_fee') + " $" + value.playerFee) : ""}
                    </Col>
                </Row>
            );
            mine.push(
                <div className='divider' />
            )
        }
        
        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('programs')}
                        icon={<GrArticle/>}
                        detailOpen={programChoice != null}
                        totalCount={items.length}
                        detailItem={programChoice}
                        onClickBack={()=>{
                            this.setState({programChoice: null});
                        }} />
                </div>

                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <Row style={{padding: '4px 10px'}}>
                            <Col sm={3} xs={6}>
                                <h4 className='one-line-title' style={{margin: 'auto 0px'}}>
                                {t('programs')}
                                </h4>
                            </Col>
                            <Col sm={3} xs={6}>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({programStatus: e.target.value});
                                }} value={programStatus}>
                                    <option value={null}>{t('select_all')}</option>
                                    <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                    <option value={STATUS_SCHEDULE}>{t('schedule')}</option>
                                    <option value={STATUS_FINISHED}>{t('finished')}</option>
                                    <option value={STATUS_REGISTER}>{t('register')}</option>
                                </select>
                            </Col>
                            <Col sm={3} xs={6}>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({programCategory: e.target.value});
                                }} value={programCategory}>
                                    <option value={null}>Select All</option>
                                    {Object.values(category).map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.title}</option>
                                    ))}
                                </select>
                            </Col>
                            <Col sm={3} xs={6}>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({programGender: e.target.value});
                                }} value={programGender}>
                                    <option value={null}>{t('select_all')}</option>
                                    <option value={GENDER_MALE}>{t('male')}</option>
                                    <option value={GENDER_FEMALE}>{t('female')}</option>
                                    <option value={GENDER_COED}>{t('coed')}</option>
                                </select>
                            </Col>
                        </Row>
                        
                        <div className='divider' />
                        <div className='divider' />
                        {programChoice ? (
                            <div>
                                {this.renderProgramDetails()}
                            </div>
                        ) : (
                            <div>
                            {items.length > 0 ? items : (
                                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                {t('no_data')}
                                </div>
                            )}
                            </div>
                        )}
                    </div>
                </div>

                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <Row style={{padding: '4px 10px'}}>
                            <Col sm={3} xs={6}>
                                <h4 className='one-line-title' style={{margin: 'auto 0px'}}>
                                {t('my_programs')}
                                </h4>
                            </Col>
                            <Col sm={3} xs={6}>
                                
                            </Col>
                            <Col sm={3} xs={6}>
                                
                            </Col>
                            <Col sm={3} xs={6}>
                                
                            </Col>
                        </Row>
                        
                        <div className='divider' />
                        <div className='divider' />
                        {mine.length > 0 ? mine : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderProgramDetails() {
        const {user, history} = this.props;
        const {organization, profileData, programChoice, schedules, users, locations} = this.state;

        return (
            <ProgramLandingOverview
                organization={organization}
                program={programChoice}
                schedules={schedules}
                uid={user ? user.uid : null}
                profileData={profileData}
                users={users}
                locations={locations}
                history={history}
                onProgramChoice={(update)=> {
                    this.setState({programChoice: update})
                }}
                onChanged={e=> {
                    this.loadContents();
                }} />
        );
    }

    getTeamsToSetup() {
        const {user, filter} = this.props;
        const {organization, seasons, teams} = this.state;

        let result = [];
        const season = filter && filter.seasonId && seasons[filter.seasonId] ? seasons[filter.seasonId] : null;
        const teamIds = season && season.teamIds ? Object.values(season.teamIds) : (organization && organization.teamIds ? Object.values(organization.teamIds) : null);
        for (const team of Object.values(teams)) {
            if (user && team.owner === user.uid) {
                if (!teamIds || teamIds.includes(team.id)) {
                    result.push(team);
                }
            }
        }
        sortByTitle(result);

        return result;
    }

    renderMyTeams() {
        const { filter } = this.props;
        const { teamChoice, seasons } = this.state;
        const season = filter && filter.seasonId && seasons[filter.seasonId] ? seasons[filter.seasonId] : null;

        let items = [];
        const myTeams = this.getTeamsToSetup();
        
        for (const value of myTeams) {
            
            const icon = value.iconUri ? value.iconUri : teamIcon;
            const teamData = season && season.teamData && season.teamData[value.id] ? season.teamData[value.id] : {};

            items.push(
                <Row style={{padding: '4px 10px'}} onClick={e=> {
                    this.setState({teamChoice: value})
                }}>
                    <Col sm={7} xs={6}>
                        <img alt="" src={icon} style={{display: 'inline', width: 24, height: 24, marginRight: 10}} />
                        <h4 style={{display: 'inline', margin: 'auto 0px'}}>
                        {value.title}
                        </h4>
                    </Col>
                    <Col sm={5} xs={6}>
                        <div className='wrap-content-parent'>
                            <h4 className='wrap-content-fill-child text-center'>
                            {teamData && teamData.gameList ? Object.values(teamData.gameList) : 0} /
                            {teamData && teamData.benchList ? Object.values(teamData.benchList) : 0}
                            </h4>
                            <Button className='btn-cancel' onClick={e=> {
                                this.setState({teamChoice: value});
                            }}>{t('setup')}</Button>
                        </div>
                    </Col>
                </Row>
            );
            items.push(
                <div className='divider' />
            )
        }
        
        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('teams_to_setup')}
                        icon={<GrArticle/>}
                        detailOpen={teamChoice != null}
                        totalCount={myTeams.length}
                        detailItem={teamChoice}
                        onClickBack={()=>{
                            this.setState({teamChoice: null});
                        }} />
                </div>

                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <Row style={{padding: '4px 10px'}}>
                            <Col sm={7} xs={6}>
                                <h4 className='text-center' style={{margin: 'auto 0px'}}>
                                {t('teams')}
                                </h4>
                            </Col>
                            <Col sm={5} xs={6}>
                                <h4 className='text-center' style={{margin: 'auto 0px'}}>
                                {t('lineup')} / {t('substitute')}
                                </h4>
                            </Col>
                        </Row>
                        
                        <div className='divider' />
                        <div className='divider' />
                        <div>
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDashboard() {
        const {organization, seasonChoice, teamChoice, programChoice, programs, seasons, divisions, teams, users, posts, cards, locations} = this.state;

        if (teamChoice) {
            return (

            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('teams_to_setup')}
                        icon={<GrArticle/>}
                        detailOpen={teamChoice != null}
                        totalCount={this.getTeamsToSetup().length}
                        detailItem={teamChoice}
                        onClickBack={()=>{
                            this.setState({teamChoice: null});
                        }} />
                </div>
                <SambaOrgTeamSquad
                    organization={organization}
                    divisions={divisions}
                    seasons={seasons}
                    team={teamChoice}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onTeamUpdate={(update) => {
                        teams[update.id] = update;
                        this.setState({teams: teams});
                    }} />
            </div>
            )
        }

        if (seasonChoice) {
            return (

            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('seasons')}
                        icon={<GrArticle/>}
                        detailOpen={seasonChoice != null}
                        totalCount={seasons ? Object.values(seasons).length : 0}
                        detailItem={seasonChoice}
                        onClickBack={()=>{
                            this.setState({seasonChoice: null});
                        }} />
                </div>
                {this.renderSeasonDetails()}
            </div>
            )
        }

        if (programChoice) {
            return (

            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('programs')}
                        icon={<GrArticle/>}
                        detailOpen={programChoice != null}
                        totalCount={programs ? Object.values(programs).length : 0}
                        detailItem={programChoice}
                        onClickBack={()=>{
                            this.setState({programChoice: null});
                        }} />
                </div>
                {this.renderProgramDetails()}
            </div>
            )
        }
        return (
            <Row style={{margin: 0}}>
                <Col xs={12} sm={6}>
                    {this.renderUpcomingGames(false)}
                    {this.renderMyTeams()}
                </Col>
                <Col xs={12} sm={6}>
                    {this.renderProfile()}
                    {/* {this.renderPrograms()} */}
                    {this.renderSeasons()}
                </Col>
            </Row>
        )
    }

    renderTeamDataSetup() {

    }

    renderStanding() {
        const {organization, seasons, divisions, team, teams, users, cards, posts, locations} = this.state;

        return (
            <div style={{marginTop: 20}}>
                
                <OrganizationTeamStandings
                    organization={organization}
                    divisions={divisions}
                    seasons={seasons}
                    team={team}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations} />
            </div>
        );
    }

    renderStatistics() {
        const {organization, seasons, divisions, team, teams, users, cards, posts, locations} = this.state;

        return (
            <div style={{marginTop: 20}}>
                <OrganizationTeamStatistics
                    organization={organization}
                    divisions={divisions}
                    seasons={seasons}
                    team={team}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations} />
            </div>
        );
    }

    renderOthers() {
        return (
            <Row style={{margin: 0}}>
                <Col sm={8} xs={12}>
                {this.renderFamily()}
                </Col>
                <Col sm={4} xs={12}>
                {this.renderPosts()}
                </Col>
            </Row>
        )
    }

    renderMembers() {
        const {playerChoice} = this.state;

        if (playerChoice) {

            return (
                <div>
                    {this.renderPlayerCard()}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderPlayers()}
                    {this.renderCoaches()}
                </div>
            )
        }
    }

    renderTrophy() {
        let items = [];
        
        return (
            <div style={{marginTop: 20}}>
                <div className='shadow-tile' style={{marginBottom: 20}}>
                    <Row style={{backgroundColor: 'white'}}>
                        <Col xs={12}>
                            <h3 className='stat-table-title'>
                                <GrTrophy/> {t('trophy')}
                            </h3>
                        </Col>
                    </Row>
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPosts() {
        const { organization, teams, posts, users, user, detailChoice, editOpen, detailOpen, searchStr} = this.state;
        
        const result = [];
        const list = [];

        const uid = user ? user.uid : "";
        const editable = organization.owner === uid || (organization.managers && Object.values(organization.managers).includes(uid))
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })
        
        for (const value of result) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: 0}}>
                    <SambaPostHeader 
                        key={value.id}
                        organization={organization}
                        post={value}
                        users={users}
                        user={user}
                        onClickEdit={uid === value.uid ? ()=>{
                            this.setState({
                                detailChoice: value,
                                detailOpen: false,
                                editOpen: true
                            })
                        } : null}
                        onClickDetail={() => {
                            this.setState({
                                detailChoice: value,
                                detailOpen: true
                            })
                        }} />
                </div>
            );
        }

        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('news_feed')}
                        icon={<GrArticle/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={list.length}
                        detailItem={detailChoice}
                        onClickAdd={editable ? ()=>{
                            this.setState({detailChoice: {}, editOpen: true, detailOpen: false});
                        } : null}
                        onClickBack={()=>{
                            this.setState({detailChoice: {}, editOpen: false, detailOpen: false});
                        }} />
                </div>

                <div style={{margin: '0px 10px'}}>
                {!editOpen && !detailOpen && (
                <div className="row">
                    {list}
                </div>
                )}

                {detailOpen && detailChoice.id != null && (
                    <PostDetailView 
                        post={detailChoice} 
                        user={user}
                        users={users}
                        teams={teams}
                        isOpen={detailOpen}
                        onCloseClick={() => {
                            this.setState({detailOpen: false})
                        }} />
                )}
                {editOpen && (
                    <PostEdit 
                        uid={user.uid}
                        organization={organization}
                        teams={teams}
                        post={detailChoice} 
                        isOpen={editOpen}
                        onChanged={() => {
                            this.loadContents()
                        }} 
                        onCloseClick={() => {
                            this.setState({editOpen: false})
                        }} />
                )}
                </div>
            </div>
        );
    }

    renderFamilyChoice() {
        const {user, child} = this.props;
        const {users, familyOpened} = this.state;
        if (!user || !user.uid) {
            return <div/>
        }

        const childArr = [];

        for (const item of Object.values(users)) {
            if (item.parentId === user.uid) {
                childArr.push(item);
            }
        }
        childArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        const items = [];
        if (user) {
            items.push(<div>{this.renderFamilyItem(user, true)}</div>)
        }
        for (const item of childArr) {
            items.push(<div>{this.renderFamilyItem(item, true)}</div>)
        }

        const choice = child ? child : user;

        if (!familyOpened) {
            const userThumb = choice.photoUri ? choice.photoUri : userIcon;
            return (
                <div className='wrap-content-parent' onClick={e=> {
                    this.setState({familyOpened: true});
                }}>
                    <CardImg
                        style={{width: 24, height: 24, marginLeft: 10, marginRight: 10}}
                        src={userThumb} />
                    <h3 style={{fontSize: 22, marginBottom: 0, color: 'white'}}>
                    {choice.username}
                    </h3>
                </div>
            )
        } else {
            return (
                <div style={{position: 'absolute', zIndex: 9999999999999, top: 20, left: 30}}>
                    <div className='shadow-tile-table'>
                        <div className='wrap-content-parent' style={{background: 'white', padding: '5px 10px'}}>
                            <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                                <div className='items-navigation-count'><FaUsers/></div>
                                <h3 className='items-navigation-title'>
                                My Family
                                </h3>
                                <div className='items-navigation-count'>| {items.length} Children</div>
                            </div>
                            <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                this.setState({familyOpened: false});
                            }}>{t('close')}</Button>
                        </div>
                        <div className='divider' />
                        <div className='divider' />
                        
                        {items.length > 0 ? (
                            <div style={{backgroundColor: 'white'}}>{items}</div>
                        ) : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Children Added
                            </div>
                        )}
                    </div>
                </div>
            )
        }
    }

    renderFamily() {
        const {user, child} = this.props;
        const {team, organization, organizations, seasons, divisions, posts, users, userEdit, teams, cards, locations} = this.state;
        if (!user || !user.uid) {
            return <div/>
        }

        const childArr = [];

        for (const item of Object.values(users)) {
            if (item.parentId === user.uid) {
                childArr.push(item);
            }
        }
        childArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        const items = [];
        if (user) {
            items.push(<div>{this.renderFamilyItem(user)}</div>)
        }
        for (const item of childArr) {
            items.push(<div>{this.renderFamilyItem(item)}</div>)
        }

        return (
            <div>
                <div className='shadow-tile-table'>
                    <div className='wrap-content-parent' style={{background: 'white', padding: '5px 10px'}}>
                        <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                            <div className='items-navigation-count'><FaUsers/></div>
                            <h3 className='items-navigation-title'>
                            My Family
                            </h3>
                            <div className='items-navigation-count'>| {items.length} Children</div>
                        </div>
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            const child = {};
                            child.uid = generateId("/user");
                            child.parentId = user.uid;
                            this.setState({userEdit: child});
                        }}>{t('create')}</Button>
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                    
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        No Children Added
                        </div>
                    )}
                </div>
                {userEdit && (
                <Modal isOpen = {userEdit}>
                    <ModalBody>
                        <ProfileEdit 
                            user={user}
                            profile={userEdit}
                            onSaveSuccess={() => {
                                this.setState({userEdit: null})
                            }}
                            onCloseClick={() => {
                                this.setState({userEdit: null})
                            }} />
                    </ModalBody>
                </Modal>
                )}
                <OrganizationPlayerCard
                    team={team}
                    organization={organization}
                    organizations={organizations}
                    divisions={divisions}
                    posts={posts}
                    users={users}
                    teams={teams}
                    cards={cards}
                    seasons={seasons}
                    locations={locations}
                    player={child ? child : user} />
            </div>
        )
    }

    renderFamilyItem(item, small) {
        const { user, child } = this.props;
        const userThumb = item.photoUri ? item.photoUri : userIcon;
        const userId = child ? child.uid : (user ? user.uid : null)

        if (small) {
            return (
                <TableRowSmall 
                    key={item.uid}
                    title={item.username}
                    icon={userThumb}
                    onClickDetails={e=> {
                        if (this.props.setChild) {
                            this.props.setChild(item.uid === user.uid ? null : item);
                        }
                    }}
                    detailLabel={item.uid === userId ? t('picked') : t('pick')} />
            );
        } else {
            return (
                <TableRowSmall 
                    key={item.uid}
                    title={item.username}
                    icon={userThumb}
                    onClickDetails={e=> {
                        if (this.props.setChild) {
                            this.props.setChild(item.uid === user.uid ? null : item);
                        }
                    }}
                    detailLabel={item.uid === userId ? t('picked') : t('pick')}
                    onClickEdit={e=> {
                        this.setState({userEdit: item});
                    }} />
            );
        }
    }

    submitPayment() {
        const {user} = this.props;
        const {organization, wallet, balanceInput, balanceType} = this.state;
        if (!balanceInput || balanceInput <= 0) {
            alert("You should input valid balance amount");
            return;
        }

        if (!wallet.id) {
            wallet.id = generateId("/wallet");
            wallet.organizationId = organization.id;
            wallet.uid = user.uid;
            wallet.balance = 0;
        }

        const data = {
            wallet: wallet,
            uid: user.uid,
            organizationId: organization.id,
            balance: balanceInput,
            type: balanceType,
            returnURL: window.location.href
        }

        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-submitWalletTransaction', data, async (data) => {
            if (data.success && data.session) {
                const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

                stripe.redirectToCheckout({ sessionId: data.session.id });

                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else if (data.success) {
                this.loadContents();
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    renderTransactionItem(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.price) / 100);
        const subtitle = "+";

        let msg = "Waiting";
        if (item.staffId && users[item.staffId]) {
            msg = users[item.staffId].username;
        } else if (!item.success && item.type !== 'direct') {
            msg = "Pending";
        } else if (item.success && item.type === 'direct') {
            msg = "Paid";
        }

        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#38be55'}}>
                    {subtitle} {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'direct' ? "Online" : "In Person"}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {msg}
                    </h4>
                </Col>
            </Row>
        );
    }

    renderOutcomeTransactionItem(item) {
        const price = "$" + (parseFloat(item.price) / 100);
        
        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#EE000F'}}>
                    {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'wallet' ? "Wallent Payment" : "Unknown"}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.title}
                    </h4>
                </Col>
            </Row>
        );
    }

    renderTransactions() {
        const {loading, balanceInput, balanceType, checkoutProgress, wallet, organization, walletPayments} = this.state;

        let balance = parseFloat(wallet.balance ? wallet.balance : 0) / 100;
        
        const paymentsArr = Object.values(walletPayments);

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        const pending = [];
        const waiting = [];
        const success = [];
        const outcome = [];
        const transactionsArr = wallet.transactions ? Object.values(wallet.transactions) : [];
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        transactionsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            if (item.success) {
                success.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else if (item.type !== 'direct')  {
                pending.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else {
                waiting.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            }
        }

        for (const item of transactionsArr) {
            if (item.amount < 0) {
                outcome.push(
                    <div>{this.renderOutcomeTransactionItem(item)}</div>
                );
            }
        }

        const items = [];
        for (const item of pending) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of waiting) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of success) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of outcome) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div>
                <div className="wrap-content-parent" style={{marginTop: 10}}>
                    <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24, marginTop: 12, marginRight: 5}}>{organization.title} - Balance:</h1>
                    {loading ? (
                    <Spinner color="primary" />
                    ) : (
                    <h2 className="wrap-content-fill-child" style={{color: "black", fontSize: 36}}>{balance > 0 ? ("$" + balance) : ("-$" + Math.abs(balance))}</h2>
                    )}
                </div>
                <div className="wallet-title-bar" style={{marginTop: 10}}>
                    <Input type="number" name="money" id="money" className="wrap-content-fill-child" onChange={e => {
                        this.setState({balanceInput: e.target.value});
                    }} value={balanceInput} />
                    <Input type="select" name="type" id="type" className="wrap-content-fill-child" onChange={e => {
                        this.setState({balanceType: e.target.value});
                    }} value={balanceType}>
                        {organization.stripeAccountId && (
                        <option value="direct">Direct</option>
                        )}
                        <option value="cash">Pay In Person</option>
                    </Input>
                    {checkoutProgress ? (
                        <Spinner color="grow" />
                    ) : (
                        <Button className="btn-submit wrap-content-wrap-child" style={{marginRight: 0}} onClick={e=> {
                            this.submitPayment(organization);
                        }}>
                            Submit
                        </Button>
                    )}
                </div>
                <div className="wallet-transactions-list">
                    <div className='wallet-info-tile'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                            
                            <h3 className='items-navigation-title'>
                            Transactions
                            </h3>
                        </div>
                        <Row style={{margin: 0}}>
                            <Col sm={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Date
                                </h4>
                            </Col>
                            <Col sm={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Money
                                </h4>
                            </Col>
                            <Col lg={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Type
                                </h4>
                            </Col>
                            <Col sm={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Status/Staff
                                </h4>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    
    getUid() {
        const {user, child} = this.props;

        return child ? child.uid : (user ? user.uid : null);
    }

    getProfile(choice) {
        const {organization, profileData} = this.state;
        if (!choice) {
            return {};
        }
        let myProfile = {
            uid: choice.uid,
            parentId: choice.parentId,
            playerName: choice.username,
            photoUri: choice.photoUri,
        };
        myProfile.organizationId = organization.id;
        myProfile.owner = organization.owner;
        for (const val of Object.values(profileData)) {
            if (val.organizationId === organization.id && val.uid === choice.uid) {
                myProfile = val;
            }
        }

        return myProfile;
    }

    render() {
        const {user, child} = this.props;
        const {organization, mainTab, loading, profileEdit} = this.state;
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        let icon = organization.thumbUri != null ? organization.thumbUri : appIcon;
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const choice = child ? child : user;
        let userThumb = user.photoUri != null ? user.photoUri : userIcon;
        const myProfile =this.getProfile(choice);
        
        return (
            <div className={sambaTheme ? "" : 'landing-dashboard'}>
                {!sambaTheme ? 
                <div className='landing-dashboard-header'>
                    <img alt='team' className='home-header-icon' src={icon} />
                    <div className="wrap-content-fill-child">
                        <h1 className="home-header-title-small">
                            {organization.title}
                            <span></span>
                        </h1>
                        <div className='wrap-content-parent'>
                            <h3 className="home-header-sub-title" style={{display: 'inline-block'}}>
                                <img alt="" src={teamIcon} style={{width: 24, height: 24, marginRight: 5}}/> {organization.teamIds ? Object.values(organization.teamIds).length : 0}
                                <FcManager/> {organization.managers ? Object.values(organization.managers).length : 0}
                            </h3>
                            <div style={{display: 'inline-block'}}>{this.renderFamilyChoice()}</div>
                        </div>
                    </div>
                </div>
                :
                <div className="samba-team-header">
                    <div className='container wrap-content-parent'>
                        <img alt='team' className='home-header-icon' src={userThumb} />
                        <div className="wrap-content-fill-child">
                            <h1 className="home-header-title-small">
                                {user.username}
                                <span></span>
                            </h1>
                            <div className='wrap-content-parent'>
                                
                                <div style={{display: 'inline-block'}}>{this.renderFamilyChoice()}</div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                
                {user ? (
                <div className={sambaTheme ? "container" : 'landing-dashboard-contents'}>
                    
                    <CustomTabView 
                        value={mainTab}
                        actionBg={actionBg}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        onChange={(e, value)=> {
                            this.setState({mainTab: value})
                        }}
                        aria-label="disabled tabs example"
                        items={[
                            {
                                label: t('dashboard')
                            },
                            {
                                label: t('wallet')
                            },
                            {
                                label: t('schedules')
                            },
                            {
                                label: t('news')
                            },
                            {
                                label: t('profile')
                            },
                        ]} />
                    <div className={sambaTheme ? "" : 'landing-dashboard-contents-wrapper'}>
                        {mainTab === 0 && (
                        <div className={sambaTheme ? "" : 'landing-dashboard-contents-scroll'}>  
                            {this.renderDashboard()}
                        </div>
                        )}
                        {mainTab === 1 && (
                        <div className={sambaTheme ? "" : 'landing-dashboard-contents-scroll'}>  
                            {this.renderTransactions()}
                        </div>
                        )}
                        {mainTab === 2 && (
                        <div className={sambaTheme ? "" : 'landing-dashboard-contents-scroll'}>  
                            {this.renderSchedules()}
                        </div>
                        )}
                        {mainTab === 3 && (
                        <div className={sambaTheme ? "" : 'landing-dashboard-contents-scroll'}>  
                            {this.renderPosts()}
                        </div>
                        )}
                        {mainTab === 4 && (
                        <div className={sambaTheme ? "" : 'landing-dashboard-contents-scroll'}>  
                            {this.renderFamily()}
                        </div>
                        )}
                        
                    </div>
                </div>
                ) : (
                <div className='landing-dashboard-contents'>
                    <div className='text-center'>
                        <p>{t('login_needed')}</p>
                    </div>
                </div>
                )}
                <Modal isOpen={profileEdit}>
                    <ModalBody style={{marginTop: 30}}>
                        <OrganizationProfileEdit
                            organization={organization}
                            user={choice}
                            profileData={myProfile}
                            onSaveDone={()=>{
                                this.loadContents();
                            }}
                            onCloseClick={()=>{
                                this.setState({profileEdit: false});
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user, child } = state.authentication;
    const { filter, dict } = state.filterData;
    return { alert, loggingIn, user, child, filter, dict };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
    updateDict: filterActions.updateDict,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(OrgAccount));
export { connectedApp as OrgAccount };