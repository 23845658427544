import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import userImage from 'assets/img/icons/player_photo_default.png';

import { userActions } from '../../redux/_actions';
import { t } from 'i18next';
import { getLogo } from 'utils/Utils';
import { withRouter } from 'utils/Routher';

class AuthForm extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        //this.props.logout();

        this.state = {
            userType: 'coach',
            submitted: false,
            progress: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    get isLogin() {
        return this.props.authState === STATE_LOGIN;
    }

    get isSignup() {
        return this.props.authState === STATE_SIGNUP;
    }

    get isForgot() {
        return this.props.authState === STATE_FORGOT;
    }

    changeAuthState = authState => event => {
        event.preventDefault();

        this.props.onChangeAuthState(authState);
    };

    handleSubmit(e) {
        const {redirectLink, redirectTo, redirectTeam} = this.props;
        e.preventDefault();

        this.setState({ submitted: true });
        if (this.isSignup) {
            const { username, password, confirmPassword, userType } = this.state;
            const email = this.props.authEmail ? this.props.authEmail : this.state.email;
            if (username == null || username === '') {
                alert(t('username_empty'));
                return;
            }
            if (email == null || email === '') {
                alert(t('email_empty'));
                return;
            }
            if (password == null || password === '') {
                alert(t('password_empty'));
                return;
            }
            if (password !== confirmPassword) {
                alert(t('password_not_match'));
                return;
            }
            const user = {
                username: username,
                password: password,
                email: email,
                type: userType,
                goldenCoin: 0
            }

            this.setState({ progress: true });
            this.props.register(user, this.props.router.navigate);

        } else if (this.isForgot) {
            const { email } = this.state;
            this.props.sendResetEmail(email);
        } else {
            const { email, password } = this.state;
            if (email && password) {
                this.props.login(email, password, this.props.router.navigate, false, redirectTo ? redirectTo : redirectLink, redirectTeam);
            }
        }
    }

    renderButtonText() {
        const { buttonText } = this.props;

        if (!buttonText && this.isLogin) {
            return t('login');
        }

        if (!buttonText && this.isSignup) {
            return t('sign_up');
        }

        if (!buttonText && this.isForgot) {
            return t('forgot_password');
        }

        return buttonText;
    }

    render() {
        const {
            user,
            showLogo,
            children,
            onLogoClick, 
            logoIcon, 
            logoTitle, 
            logoView, 
            authEmail, 
        } = this.props;

        const { username, email, password, confirmPassword, submitted, userType } = this.state;

        if (user && user.uid) {
            const icon = user.photoUri ? user.photoUri : userImage;

            return (
                <Form className='auth-wrapper'>
                    <div className="text-center pb-4">
                        <img
                            src={icon}
                            className="rounded-circle"
                            style={{ width: 60, height: 60, cursor: 'pointer', border: '1px solid rgb(0, 0, 0)' }}
                            alt="logo"
                        />
                    </div>
                    <h3 className='quick-setup-side-header-title text-center' style={{marginBottom: 40}}>
                        {user.username}
                    </h3>
                    <div className='text-center'>
                        <Button
                            className="btn-cancel"
                            onClick={(e)=> {
                                this.props.logout();
                            }}>
                            {t('logout')}
                        </Button>
                    </div>

                </Form>
            )
        }

        return (
            <Form className='auth-wrapper' onSubmit={this.handleSubmit}>
                {showLogo && (
                    <div className="text-center pb-4">
                        <img
                            src={logoIcon ? logoIcon: getLogo()}
                            className="rounded"
                            style={{ width: 60, height: 60, cursor: 'pointer' }}
                            alt="logo"
                            onClick={onLogoClick}
                        />
                        {logoTitle && (
                            <h3 className='text-center'>{logoTitle}</h3>
                        )}
                        {logoView && (
                            <div className='text-center'>{logoView}</div>
                        )}
                    </div>
                )}
                {this.isSignup && (
                <FormGroup>
                    <Label for='full_name'>{t('full_name')}</Label>
                    <Input 
                        name="username" 
                        placeholder={t('your_name')}
                        type="text" 
                        value={username} 
                        onChange={this.handleChange} />
                    {submitted && !username &&
                        <div className="help-block">{t('username_empty')}</div>
                    }
                </FormGroup>
                )}
                <FormGroup>
                    <Label for='email'>{t('email')}</Label>
                    <Input 
                        name="email" 
                        placeholder='your@email.com'
                        type="email" 
                        value={(!email || email === '') && authEmail ? authEmail : email}
                        onChange={this.handleChange} />
                    {submitted && !email && !authEmail && 
                        <div className="help-block">{t('email_empty')}</div>
                    }
                </FormGroup>
                {!this.isForgot && (
                <FormGroup>
                    <Label name="password">{t('password')}</Label>
                    <Input 
                        name="password" 
                        placeholder={t('password')}
                        type="password" 
                        value={password} 
                        autoComplete="on"
                        onChange={this.handleChange} />
                    {submitted && !password &&
                        <div className="help-block">{t('password_empty')}</div>
                    }
                </FormGroup>
                )}
                {this.isSignup && (
                    <FormGroup>
                        <Label for="confirmPassword">{t('confirm_password')}</Label>
                        <Input 
                            name="confirmPassword" 
                            placeholder={t('confirm_your_password')}
                            type="password" 
                            autoComplete="on"
                            value={confirmPassword} 
                            onChange={this.handleChange} />
                    </FormGroup>
                )}
                {this.isSignup && (
                    <FormGroup>
                        <p>What do you want to work for here?</p>
                        <Input type="select" name="backdrop" id="backdrop" value={userType} onChange={e => {
                            this.setState({userType: e.target.value})
                        }}>
                            <option value="coach">{t('coach')}</option>
                            <option value="organizer">{t('organizer')}</option>
                            <option value="player">{t('player')}</option>
                            <option value="user">{t('user')}</option>
                        </Input>
                    </FormGroup>
                )}
                {/* <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
                    </Label>
                </FormGroup> */}
                <hr />
                {this.props.loggingIn || this.props.registering ? (
                    <div className='text-center'>
                        <Spinner color="primary" />
                    </div>
                ) : (
                    <div className='text-center'>
                        <Button
                            className="btn-submit"
                            onClick={this.handleSubmit}>
                            {this.renderButtonText()}
                        </Button>
                    </div>
                ) }

                {this.props.onChangeAuthState && (
                <div className="text-center pt-1">
                    <h6>or</h6>
                    <h6>
                        {!this.isLogin && (
                            <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                                {t('login')}
                            </a>
                        )}
                    </h6>
                    <h6>
                        {!this.isSignup && (
                            <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                                {t('sign_up')}
                            </a>
                        )}
                    </h6>                    
                    <h6>
                        {!this.isForgot && (
                            <a href="#signup" onClick={this.changeAuthState(STATE_FORGOT)}>
                                {t('forgot_password')}
                            </a>
                        )}
                    </h6>
                </div>
                )}

                {children}
            </Form>
        );
    }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';
export const STATE_FORGOT = 'FORGOT';

AuthForm.propTypes = {
    authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP, STATE_FORGOT]).isRequired,
    showLogo: PropTypes.bool,
    onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
    authState: 'LOGIN',
    showLogo: true,
    onLogoClick: () => {},
};

function mapState(state) {
    const { loggingIn, user, redirectTo } = state.authentication;
    const { registering } = state.registration;
    return { loggingIn, user, registering, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    register: userActions.register,
    logout: userActions.logout,
    sendResetEmail: userActions.sendResetEmail,
};

const connectedLoginPage = withRouter(connect(mapState, actionCreators)(AuthForm));
export { connectedLoginPage as AuthForm };