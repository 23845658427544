import React, { Component } from "react";
import {
    Button, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    Spinner, 
    Modal, 
    ModalBody,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AttachFileEdit from 'components/edit/AttachFileEdit';
import CustomDatePicker from "components/template/CustomDatePicker";

import { 
    uploadNewAttachFile, 
    updateAttachFile,
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";

export class OrganizationProfileEdit extends Component {
    constructor(props) {
        super(props);
        const organization = {};
        const healthDoc = {};
        const profileData = {};
        let teamChoice = {};
        if (props.organization != null) {
            for (const [key, value] of Object.entries(props.organization)) {
                organization[key] = value;
            }
        }
        if (props.healthDoc != null) {
            for (const [key, value] of Object.entries(props.healthDoc)) {
                healthDoc[key] = value;
            }
        }
        if (props.profileData != null) {
            for (const [key, value] of Object.entries(props.profileData)) {
                profileData[key] = value;
            }
        }
        
        this.state = {
            organization: organization,
            progress: false,
            pickTeam: false,
            teamChoice: teamChoice,
            profileData: profileData,
            newImage: {},
            healthDoc: healthDoc,
            newFile: {},
            imageProgress: 0,
            fileProgress: 0,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, organization, healthDoc} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                newFile: {},
                healthDoc: {},
                imageProgress: 0,
                fileProgress: 0,
            })
        }
        if ((prevProps.organization !== organization)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(organization)) {
                challengeVal[key] = value;
            }
            this.setState({
                organization: challengeVal
            })
        }
        if ((prevProps.healthDoc !== healthDoc)) {
            const update = {};
            for (const [key, value] of Object.entries(healthDoc)) {
                update[key] = value;
            }
            this.setState({
                healthDoc: update
            })
        }
    }

    onSaveClick(profileData, newImage, newFile) {
        const {organization, user, onSaveDone} = this.props;
        
        const objRef = this;
        
        if (organization.formSetting != null) {
            for (const [key, value] of Object.entries(organization.formSetting)) {
                if (value.switch && value.option === 'Required' && profileData[key] == null) {
                    alert("You must input valid data for " + key);
                    return;
                }
            }
        } else {
            alert("Profile Form Setting for this organization is not ready.");
            return;
        }
        
        this.setState({progress: true, fileProgress: 0, imageProgress: 0});
        if (profileData.id == null || profileData.id === '') {
            profileData.id = generateId("/profileData");
            profileData.uid = user.uid;
            profileData.organizationId = organization.id;
            profileData.owner = organization.owner;
        }
        profileData.timestamp = dbTimestamp();
        
        if (newFile != null && newFile.name != null) {
            this.saveChangeWithAttach(profileData, newFile, (profileData, healthDoc) => {
                this.saveChangeWithImage(profileData, newImage, (update) => {
                    alert("Your Change subimitted successfully.");
                    if (onSaveDone != null) {
                        onSaveDone();
                    }
                    objRef.setState({
                        organization: update,
                        progress: false
                    });
                })
            })
        } else {
            this.saveChangeWithImage(profileData, newImage, (update) => {
                this.saveChangeWithImage(profileData, newImage, (update) => {
                    alert("Your Change subimitted successfully.");
                    if (onSaveDone != null) {
                        onSaveDone();
                    }
                    objRef.setState({
                        organization: update,
                        progress: false
                    });
                })
            })
        }
    }

    saveChangeWithAttach(profileData, newFile, sucess) {
        const {healthDoc} = this.state;
        const {onCloseClick} = this.props;

        if (healthDoc == null || healthDoc.id == null) {
            uploadNewAttachFile(newFile, 'healthDoc', (update) => {
                profileData.healthDoc = update.id;
                sucess(profileData, healthDoc);
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({fileProgress: progress});
                } else {
                    this.setState({fileProgress: 0});
                }
            }, error => {
                alert("Saving Attach File Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            });
        } else {
            updateAttachFile(healthDoc, 'healthDoc', newFile, (update) => {
                profileData.healthDoc = update.id;
                sucess(profileData, healthDoc);
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({fileProgress: progress});
                } else {
                    this.setState({fileProgress: 0});
                }
            }, error => {
                alert("Saving Attach File Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            });
        }
    }

    saveChangeWithImage(profileData, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(profileData.id, 'ProfileData', newImage, (url) => {
                profileData.playerIDAttatch = url;
                objRef.saveChange(profileData, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(profileData, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(profileData, success, failure) {
        invokeHttpsApi("organizerQuery-submitProfileData", {
            "profileData": profileData
        }, (data) => {
            if (data && data.success) {
                success(profileData);
            } else {
                failure(null);
            }
        }, (error) => {
            failure(null);
        });
    }

    render() {
        const {organization, teams, user, onCloseClick, closeLabel, createMode} = this.props;
        const {profileData, healthDoc, newImage, newFile, progress, imageProgress, fileProgress, pickTeam} = this.state;
        
        const birthday = profileData.birthday != null ? new Date(profileData.birthday) : new Date();
        const relatedTeams = [];
        if (teams != null) {
            for (const [key, value] of Object.entries(teams)) {
                if (organization.teamIds != null && Object.values(organization.teamIds).includes(key) && 
                    value.playerIds != null && Object.values(value.playerIds).includes(user.uid)) {
                    relatedTeams.push(value);
                }
            }
        }
        
        if (organization != null) {
            let formSetting = organization.formSetting != null ? organization.formSetting : {};
            return (
                <Form style={createMode ? {width: '100%'} : {}}>
                    <h3 for="title" style={{marginBottom: 25}}>Player Registration Form</h3>
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                    <Form inline>
                        <FormGroup>
                            <Label for="playerName" style={{marginRight: 15}}>Name (required)</Label>
                            <Input type="text" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                profileData.playerName = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.playerName} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="playerLastName" style={{marginRight: 15}}>Last Name (required)</Label>
                            <Input type="text" name="playerLastName" id="playerLastName" style={{marginRight: 5}} onChange={e => {
                                profileData.playerLastName = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.playerLastName} />
                        </FormGroup>
                    </Form>
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                        <FormGroup>
                            <Label for="email">Email (required)</Label>
                            <Input type="text" name="email" id="email" onChange={e => {
                                profileData.email = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.email} />
                            <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                        </FormGroup>
                        <FormGroup>
                            <CustomDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label={"Day of Birth (required)"}
                                format="MM/DD/YYYY"
                                value={birthday}
                                onChange={ date => {
                                    profileData.birthday = date.valueOf();
                                    this.setState({profileData: profileData});
                                }} />
                        </FormGroup>
                    {formSetting.mobile != null && formSetting.mobile.switch && (
                        <FormGroup>
                            <Label for="mobile">Mobile ({formSetting.mobile.option})</Label>
                            <Input type="text" name="mobile" id="mobile" onChange={e => {
                                profileData.mobile = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.mobile} />
                        </FormGroup>
                    )}
                    {formSetting.healthDoc != null && formSetting.healthDoc.switch && (
                        <FormGroup>
                            <AttachFileEdit
                                attachFile={healthDoc}
                                allowProgress={progress}
                                label='Health Doc'
                                progress={fileProgress}
                                onChange={(file)=> {
                                    this.setState({newFile: file});
                                }} />
                        </FormGroup>
                    )}
                    {formSetting.playerIDNumber != null && formSetting.playerIDNumber.switch && (
                        <FormGroup>
                            <Label for="playerIDNumber">Mobile ({formSetting.playerIDNumber.option})</Label>
                            <Input type="text" name="playerIDNumber" id="playerIDNumber" onChange={e => {
                                profileData.playerIDNumber = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.playerIDNumber} />
                        </FormGroup>
                    )}
                    {formSetting.playerIDAttatch != null && formSetting.playerIDAttatch.switch && (
                        <FormGroup>
                            <AttachImageEdit
                                imageUrl={organization.playerIDAttatch}
                                imageClass='task-image'
                                label='Player ID Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </FormGroup>
                    )}
                    {formSetting.position != null && formSetting.position.switch && (
                        <FormGroup>
                            <Label for="position">Mobile ({formSetting.position.option})</Label>
                            <Input type="text" name="position" id="position" onChange={e => {
                                profileData.position = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.position} />
                        </FormGroup>
                    )}
                    {formSetting.jersey != null && formSetting.jersey.switch && (
                        <FormGroup>
                            <Label for="jersey">Mobile ({formSetting.jersey.option})</Label>
                            <Input type="text" name="jersey" id="jersey" onChange={e => {
                                profileData.jersey = e.target.value;
                                this.setState({profileData: profileData});
                            }} value={profileData.jersey} />
                        </FormGroup>
                    )}
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(profileData, newImage, newFile);
                                }}>{t('create')}</Button>
                            </div>
                        ) : (
                            <div>
                            <Button className='btn-blue' onClick={e => {
                                this.onSaveClick(profileData, newImage, newFile);
                            }}>{t('save')}</Button>
                            {onCloseClick && (
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{closeLabel ? closeLabel : t('close')}</Button>
                            )}
                            </div>
                        )}
                        </div>
                    )}
                    <Modal isOpen={pickTeam}>
                        <ModalBody>
                            <ListGroup>
                                {relatedTeams.map((item, index) => (
                                    <ListGroupItem key={index} onClick={e => {
                                        this.setState({teamChoice: item, pickTeam: false});
                                    }}>{item.title}</ListGroupItem>
                                ))}
                                <ListGroupItem key='cancel' onClick={e => {
                                    this.setState({teamChoice: {}, pickTeam: false});
                                }}>{t('cancel')}</ListGroupItem>
                            </ListGroup>
                        </ModalBody>
                    </Modal>
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(OrganizationProfileEdit);