import React, { Component } from "react";
import { Progress } from 'reactstrap';
import { 
    FaFile, 
    FaFileAlt,
    FaFileArchive, 
    FaFileAudio, 
    FaFileCode, 
    FaFileCsv, 
    FaFileExcel, 
    FaFileImage, 
    FaFilePdf,
    FaFilePowerpoint,
    FaFileVideo,
    FaFileWord,
} from 'react-icons/fa';

import { getFileExtension } from 'utils/FirebaseUtils';

export class AttachItemView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSize: 16
        }
    }

    renderFileIcon() {
        const { attachFile } = this.props;
        const { fontSize } = this.state;
        const extension = getFileExtension(attachFile.name);

        if (extension === 'zip' || extension === 'rar') {
            return <FaFileArchive size={fontSize} />;
        } else if (extension === 'mp3' || extension === 'wav' || extension === 'wma' || extension === 'aac' || extension === 'midi') {
            return <FaFileAudio size={fontSize} />;
        } else if (extension === 'java' || extension === 'swift' || extension === 'c' || extension === 'cc' || extension === 'xml' || extension === 'm' || extension === 'py' || extension === 'css') {
            return <FaFileCode size={fontSize} />;
        } else if (extension === 'csv') {
            return <FaFileCsv size={fontSize} />;
        } else if (extension === 'xlsx' || extension === 'xls') {
            return <FaFileExcel size={fontSize} />;
        } else if (extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'webp' || extension === 'gif') {
            return <FaFileImage size={fontSize} />;
        } else if (extension === 'pdf') {
            return <FaFilePdf size={fontSize} />;
        } else if (extension === 'pptx' || extension === 'ppt') {
            return <FaFilePowerpoint size={fontSize} />;
        } else if (extension === 'docx' || extension === 'doc') {
            return <FaFileWord size={fontSize} />;
        } else if (extension === 'txt') {
            return <FaFileAlt size={16} />;
        } else if (extension === 'mp4' || extension === 'mov' || extension === 'avi' || extension === 'wmv' || extension === 'qt') {
            return <FaFileVideo size={fontSize} />;
        } else {
            return <FaFile size={fontSize} />;
        }
    }
    
    render() {
        const { label, attachFile, allowProgress, progress } = this.props;
        const { fontSize } = this.state;
        
        if (attachFile != null && attachFile.id != null) {
            return (
                <div className='attach-wrapper'>
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-wrap-child' style={{marginRight: 10}}>
                        {this.renderFileIcon()}
                        </div>
                        <a 
                            className='wrap-content-fill-child' 
                            style={{marginRight: 10, fontSize: {fontSize}}}
                            href={attachFile.url} target="_blank" rel="noopener noreferrer" >
                        {label ? label : attachFile.name}
                        </a> 
                    </div>
                    {allowProgress && (
                        <Progress className='progress-style-file' color="warning" value={progress} />
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default AttachItemView;