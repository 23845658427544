import slide1 from 'assets/img/slides/1.jpg';
import slide2 from 'assets/img/slides/2.jpg';
import slide3 from 'assets/img/slides/3.png';

import img1 from 'assets/img/slides/service_1.jpg';
import img2 from 'assets/img/slides/service_2.jpg';
import img3 from 'assets/img/slides/service_3.png';
import img2_1 from 'assets/img/slides/service_2_1.jpg';
import img2_2 from 'assets/img/slides/service_2_2.jpg';
import img2_3 from 'assets/img/slides/service_2_3.jpg';

import img3_1 from 'assets/img/bg/background_1920-10.jpg';

export const homeSlides = [
    {
        src: slide1,
        title: 'PERSEVERANCE AND PASSION ARE KEYS TO SUCCESS',
        btnText: 'READ MORE',
        altText: 'Slide1',
        caption: 'Follow us to get the latest news!'
    },
    {
        src: slide2,
        title: 'We Approach Every Game Like it’s the Finals',
        btnText: 'READ MORE',
        altText: 'Slide2',
        caption: 'Tactical training is priority for a successful season!'
    },
    {
        src: slide3,
        title: 'The Championship is just a Goal Away',
        btnText: 'READ MORE',
        altText: 'Slide3',
        caption: 'Find out when our next event is by clicking link below!',
    }
];

export const homeHeaderText = {
    main: {
        title1: 'Mulholland-Designs is a full service design and home renovation company specializing in large scale renovations serving Niagara on the Lake and the Niagara Region.',
        title2: 'With over 20 years of experience in construction, Mulholland-Designs have built homes, designed additions and carried out major renovations.',
    },
    description: {
        text: 'Whether you need a major renovation, a new kitchen or bathroom, our skilled team of project managers, designers and builders will work with you to take your vision and turn it into reality. A home renovation can be a very stressful and expensive experience if not planned and carried out professionally. Selecting the contractor is a major part of the experience. Mulholland-Designs have gained a reputation of unparalleled quality and customer service throughout the Niagara Region … and for good reason.',
        sign: 'We listen. We offer options. We deliver.',
    }
};

export const homeServices = [
    {
        "id": "service1",
        "icon": "fa fa-futbol-o",
        "name": "Custom Leagues",
        "index": 0,
        "items": [
            "Recreational & Competitive leagues all year round!",
            "U5/6 | U7/8 | U9/10 | U11/12 | U13/14 | U15/17"
        ],
        img1: img1,
        img2: img2_1,
        link: 'http://www.thermapan.com'
    },
    {
        "id": "service2",
        "icon": "fa fa-mobile",
        "name": "Soccer Schools",
        "index": 1,
        "items": [
            "Start training in our soccer schools, we will move each player through our soccer pathways tailored for each players level.",
            "U5/6 | U7/8 | U9/10 | U11/12 | U13/14 | U15/17"
        ],
        img1: img2,
        img2: img2_2,
        link: 'https://www.weyerhaeuser.com/woodproducts/osb-panels/osb/edge-gold-subfloor-panels/'
    },
    {
        "id": "service3",
        "icon": "fa fa-users",
        "name": "Camps & Clinics",
        "index": 2,
        "items": [
            "Take part in our soccer specific camps and clinics.",
            "Shooting | Dribbling | Passing | Ball Control"
        ],
        img1: img3,
        img2: img2_3,
        link: 'https://www.weyerhaeuser.com/woodproducts/engineered-lumber/'
    }
];

export const homeServiceText = 'Your level of communication and openness gained our trust immediately and increased over the project. You would definitely be our builder of choice. – John L.';

export const homeChoices = [
    {
        "id": "choice1",
        "icon": "fa fa-wrench",
        "name": "20+ YEARS EXPERIENCE",
        "text": "With an experienced team of professionals and access to licensed trades, our focused approach is one of our key strengths."
    },
    {
        "id": "choice2",
        "icon": "fa fa-university",
        "name": "QUALITY SERVICE",
        "text": "From the moment we start a project to the final finishing touches, service quality is our number one priority."
    },
    {
        "id": "choice3",
        "icon": "fa fa-thumbs-up",
        "name": "SATISFACTION GUARANTEED",
        "text": "Our commitment to our customers. No job is complete until our customers are 100% satisfied with the finished product."
    }
];

export const homeChoiceText = '" Every time I come home, I just love it more and more. – Matt D. "';

export const homeVideo = {
    "id": "service1",
    "icon": "fa fa-futbol-o",
    "name": "Video",
    "title": "Video Title",
    bg: img3_1,
    link: 'https://www.youtube.com/watch?v=oI90sfKe0Zg'
};

export const aboutUs = {
    title: "About Us",
    items: {
        test: 
        {
            id: 'test',
            subtitle: "We are great to see you.",
            text: "We are great to see you.",
            bg: img3_1,
        }
    }
};

export const contact = {
    title: "Contact Us",
    email: "example@spport.com",
    items: {
        test: 
        {
            id: 'test',
            subtitle: "We are great to see you.",
            text: "We are great to see you.",
            bg: img3_1,
        }
    }
};
