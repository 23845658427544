import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { writeDb } from "utils/API";

export class SurveyTextQuestionEdit extends Component {
    constructor(props) {
        super(props);
        const question = {};
        if (props.question != null) {
            for (const [key, value] of Object.entries(props.question)) {
                question[key] = value;
            }
        }

        this.state = {
            question: question,
            progress: false
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {question} = this.props;
        if ((prevProps.question !== question)) {
            const update = {};
            if (question != null) {
                for (const [key, value] of Object.entries(question)) {
                    update[key] = value;
                }
            }

            this.setState({
                question: update,
                progress: false
            })
        }
    }

    onSaveClick() {
        const {survey, onCloseClick} = this.props;
        const {question} = this.state;

        writeDb('/survey/' + survey.id + '/questions/' + question.id, question, error=> {
            if (error) {
                alert("Survey Question update failed.\n" + error);
            } else {
                alert("Survey Question update success.");
            }
            if (onCloseClick != null) {
                onCloseClick();
            }
        });

    }

    render() {
        const {survey, onCloseClick, onSaveClick} = this.props;
        const {question} = this.state;
        
        if (question != null) {
            return (
                <Form>
                    <h4 className='label label-default'>
                        Text Question
                    </h4>
                    <FormGroup>
                        <Label for="text">Text</Label>
                        <Input type="text" name="text" id="text" onChange={e => {
                            question.text = e.target.value;
                            this.setState({question: question});
                        }} value={question.text} />
                    </FormGroup>
                    <FormGroup>
                        <Button className='modal-button' onClick={e => {
                            if (question.text != null && question.text !== '') {
                                    if (onSaveClick != null) {
                                        onSaveClick(question);
                                    } else if (survey.id != null) {
                                        this.onSaveClick();
                                    } 
                            } else {
                                alert("You must fill out all fields");
                            }
                        }}>{t('save')}</Button>
                        <Button className='modal-button' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </FormGroup>
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SurveyTextQuestionEdit);