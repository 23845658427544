import React, { Component } from "react";

export class Sponsers extends Component {

    render() {
        const {organization, showTitle} = this.props;
        const sponsers = organization && organization.landingData && organization.landingData.sponsers ? organization.landingData.sponsers : null;

        let items = sponsers ? Object.values(sponsers) : [];

        let list = [];

        for (const item of items) {
            list.push(
                <a key={list.length} href={item.link} className='wrap-content-wrap-child'>
                    <img className='wrap-content-wrap-child' alt="icon" style={{width: 80, height: 60, objectFit: 'cover', border: '1px solid #222', borderRadius: 4}} src={item.url} />
                </a>
            )
        }

        if (sponsers) {
            return (
            <div style={{marginBottom: 15}}>
                {showTitle && (
                    <h3 className="text-center" style={{color: 'white'}}>Sponsers</h3>
                )}
                <div className="text-center">
                    {list}
                </div>
            </div>
            )
        } else {
            return <div/>;
        }
    }
}

export default Sponsers;
