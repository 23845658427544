import React, { Component } from "react";
import {
    CardImg, Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/trial_icon.png';

export class CategoryChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    renderItemChoice() {
        const { categorys, onItemChoice } = this.props;

        if (categorys != null && Object.values(categorys).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onItemChoice != null) {
                        onItemChoice({})
                    }
                    this.setState({editOpen: false});
                }}>All Categories</ListGroupItem>
            )
            for (const[key, value] of Object.entries(categorys)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onItemChoice != null) {
                            onItemChoice(value)
                        }
                        this.setState({editOpen: false});
                    }}>{value.title}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Category Present</div>
        }
    }
    
    render() {
        const { title, categorys, categoryChoice } = this.props;
        const { editOpen } = this.state;
        let challengeImage = categoryChoice != null && categoryChoice.thumbUri != null ? categoryChoice.thumbUri : defaultImage;
        
        if (categorys != null) {
            return (
                <div style={{marginBottom: 30}}>
                    <div>
                        <div>
                            <div style={{fontSize: 12, marginBottom: 5}}>
                                {title}: {Object.values(categorys).length} Categories
                            </div>
                            {categoryChoice.id != null ? (
                                <div className='wrap-content-parent'>
                                    <CardImg
                                        className="profile-thumb"
                                        src={challengeImage}
                                        style={{ width: 25, height: 25, marginRight: 10 }}
                                        />
                                    <h3 className='wrap-content-fill-child' style={{marginBottom: 0}}>{categoryChoice.title}</h3>
                                </div>
                            ) : (
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    No Category Choice
                                </div>
                            )}
                        </div>
                        <div className='wrap-content-parent'>
                            <div className=' wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <Button className='wrap-content-wrap-child btn-pick' onClick={e => {
                                this.setState({editOpen: true})
                            }}>Change</Button>

                        </div>

                    </div>
                    <Modal isOpen={editOpen}>
                        <ModalBody>
                            {this.renderItemChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn-pick" onClick={e => {
                                this.setState({editOpen: false})
                            }}>{t('close')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(CategoryChoice);