import React, { Component } from "react";
import {
    Button, Form, Input, Spinner, Col, Row, Label
} from 'reactstrap';
import { t } from 'i18next';

import { generateId, saveDivision } from "utils/API";
import { withRouter } from "utils/Routher";
import { sortByTitle } from "utils/Utils";
import { GENDER_COED, GENDER_FEMALE, GENDER_MALE } from "utils/constants";
import HorizontalAttachImageEdit from "components/edit/HorizontalAttachImageEdit";
import defaultImage from 'assets/img/icons/playlist_icon.png';
import { uploadImageFileToStorage } from "utils/FirebaseUtils";

export class DivisionSetupView extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            step: 1,
            progress: false,
            divisionChoice: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    onSaveClick(division) {
        const { organization, onSaveDone } = this.props;
        const { newImage } = this.state;
        const objRef = this;
        
        if (division.title == null || division.title === '') {
            alert(t("no_title_prompt"));
        } else if (division.description == null || division.description === '') {
            alert(t("no_description_prompt"));
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (division.id == null || division.id === '') {
                division.id = generateId("/division");
            }
            division.owner = organization.owner;
            division.organizationId = organization.id;
            division.timestamp = new Date().valueOf();
            
            this.saveChangeWithImage(division, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone(division);
                }
                objRef.setState({
                    division: update,
                    progress: false
                });
            })
        }
    }

    saveChangeWithImage(division, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(division.id, 'divisionThumb', newImage, (url) => {
                division.thumbUri = url;
                objRef.saveChange(division, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(division, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(division, success, failure) {
        saveDivision(division, success, failure);
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-create-step-header">
            <div className="season-create-step-num">
                {num}
            </div>
            <div className="season-create-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderStepOne() {
        const { division, onDivisionUpdate, onCloseClick, hideClose } = this.props;
        const { progress, imageProgress } = this.state;

        return (
            <div>
                {this.renderStepTitle(1, t('division_create_step_1'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('division_title')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="text" name="text" id="title" onChange={e => {
                            division.title = e.target.value;
                            onDivisionUpdate(division);
                        }} value={division.title} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('thumbnail')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <HorizontalAttachImageEdit
                            imageUrl={division.thumbUri}
                            defaultImage={defaultImage}
                            imageWidth={80}
                            imageHeight={60}
                            imageClass='task-image'
                            label='Thumb Image'
                            allowProgress={progress}
                            progress={imageProgress}
                            onChange={(file)=> {
                                this.setState({newImage: file});
                            }} />
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('description')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="textarea" name="text" id="description" onChange={e => {
                            division.description = e.target.value;
                            onDivisionUpdate(division);
                        }} value={division.description} />
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!division.title || division.title === "") {
                                alert(t("no_title_prompt"));
                                return;
                            }
                            if (!division.description) {
                                alert(t("no_description_prompt"));
                                return;
                            }
                            this.setState({step: 2})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        {!hideClose && (
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepTwo() {
        const { division, categorys, onDivisionUpdate, onCloseClick, hideClose } = this.props;
        
        let catId = division.category != null ? division.category.id : null;
        let catArr = categorys != null ? Object.values(categorys) : [];
        sortByTitle(catArr);

        return (
            <div>
                {this.renderStepTitle(2, t('division_create_step_2'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('category')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="select" name="category" id="category" value={catId} onChange={e => {
                                const id = e.target.value;
                                const item = categorys[id];
                                if (item != null) {
                                    division.category = {};
                                    for (const [key, value] of Object.entries(item)) {
                                        division.category[key] = value;
                                    }
                                    onDivisionUpdate(division);
                                }
                            }}>
                                <option key={0} value={null}></option>
                                {catArr.map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.title}</option>
                                ))}
                            </Input>
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 1})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!division.category) {
                                alert(t("no_category_prompt"));
                                return;
                            }
                            this.setState({step: 3})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        {!hideClose && (
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepThree() {
        const { division, onDivisionUpdate, onCloseClick, hideClose } = this.props;

        return (
            <div>
                {this.renderStepTitle(3, t('division_create_step_3'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('gender')}
                    </Col>
                    <Col sm={7} xs={12} className='form-edit-entry'>
                        <div className='form-edit-input'>
                            <Input type="select" name="gender" id="gender" value={division.gender} onChange={e => {
                                division.gender = e.target.value;
                                onDivisionUpdate(division);
                            }}>
                                <option value={null}></option>
                                <option value={GENDER_MALE}>{t('male')}</option>
                                <option value={GENDER_FEMALE}>{t('female')}</option>
                                <option value={GENDER_COED}>{t('coed')}</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('age_group')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="text" name="ageTitle" id="ageTitle" onChange={e => {
                                division.ageTitle = e.target.value;
                                onDivisionUpdate(division);
                            }} value={division.ageTitle} />
                        </div>
                        <div className='form-edit-input'>
                            <div className='wrap-content-parent' style={{margin: '10px 0px'}}>
                                <div className='form-edit-label'>
                                {t('age_range')}
                                </div>
                                <Label for="ageTo" style={{margin: 'auto 10px'}}>From </Label>
                                <Input type="number" name="ageFrom" style={{width: '60px'}} id="ageFrom" onChange={e => {
                                    division.ageFrom = e.target.value;
                                    onDivisionUpdate(division);
                                }} value={division.ageFrom} />
                                <Label for="ageTo" style={{margin: 'auto 10px'}}>To </Label>
                                <Input type="number" name="ageTo" style={{width: '60px'}} id="ageTo" onChange={e => {
                                    division.ageTo = e.target.value;
                                    onDivisionUpdate(division);
                                }} value={division.ageTo} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 2})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            this.setState({step: 4})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        {!hideClose && (
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepFour() {
        const { division, onDivisionUpdate, onCloseClick, hideClose } = this.props;
        const { progress } = this.state;
        
        return (
            <div>
                {this.renderStepTitle(4, t('division_create_step_4'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('contact_email')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="text" name="text" id="contactEmail" value={division.contactEmail} onChange={e => {
                                division.contactEmail = e.target.value;
                                onDivisionUpdate(division);
                            }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('contact_phone_number')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="text" name="contactPhone" id="contactPhone" value={division.contactPhone} onChange={e => {
                                division.contactPhone = e.target.value;
                                onDivisionUpdate(division);
                            }} />
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 3})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {progress ? (
                        <Spinner color="primary" />
                        ) : (
                        <Button className='btn-blue' onClick={e => {
                            this.onSaveClick(division)
                        }}>{t('save')}</Button>
                        )}
                        <div className="wrap-content-fill-child"/>
                        {!hideClose && (
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onDivisionUpdate(division);
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }

    renderContents() {
        const { step } = this.state;

        return (
            <div>
                {step === 1 && this.renderStepOne()}
                {step === 2 && this.renderStepTwo()}
                {step === 3 && this.renderStepThree()}
                {step === 4 && this.renderStepFour()}
            </div>
        )
    }

    render() {        
        return (
            <Form className='form-edit'>
                <Row className='form-edit-row'>
                    <h3 className="text-center">{t("setup_division")}</h3>
                </Row>
                {this.renderContents()}
            </Form>
        );
    }
}

export default withRouter(DivisionSetupView);