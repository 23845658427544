import React, { Component } from "react";
import {
    Card, CardImg, Button, Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import { 
    MdEdit
} from 'react-icons/md';

import SubInfoView from 'components/detail/SubInfoView';
import defaultImage from 'assets/img/icons/shield_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import ReactReadMoreReadLess from "react-read-more-read-less";

export class DivisionBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {division, seasons, onClickDetails, onClickEdit, allowDetail, blockEdit } = this.props;
        let challengeImage = division.thumbUri != null ? division.thumbUri : defaultImage;
        let teamCount = division.teamIds == null ? 0 : Object.values(division.teamIds).length;
        let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
        let playerCount = 0;
        
        let playerIds = [];
        for (const season of Object.values(seasons)) {
            if (season && season.divisionId === division.id && season.teamData) {
                for (const teamData of Object.values(season.teamData)) {
                    if (teamData.roaster) {
                        for (const uid of Object.values(teamData.roaster)) {
                            if (!playerIds.includes(uid)) {
                                playerIds.push(uid);
                            }
                        } 
                    }
                }
            }
        }
        playerCount = playerIds.length;
        
        if (division != null) {
            return (
                <div key={division.id}>
                    <Card className="shadow-tile grid-badge">
                        <div className='grid-item-row'>
                            <CardImg
                                className="grid-item-icon"
                                src={challengeImage} />
                            <div className='grid-item-info-fill'>
                                <h4 className='grid-item-title'>
                                {division.title}
                                </h4>
                                <div className="grid-item-info">
                                    <ReactReadMoreReadLess
                                        charLimit={170}
                                        readMoreText={"More  ▼"}
                                        readLessText={"Less  ▲"}
                                    >
                                        {division.description ? division.description : ""}
                                    </ReactReadMoreReadLess>
                                </div>
                            </div>
                            <div className='grid-item-info-wrap'>
                            {!blockEdit && (
                                <Button className="grid-edit-btn" onClick={e => {
                                    if (onClickEdit != null) {
                                        onClickEdit();
                                    }
                                }}><MdEdit/></Button>
                            )}
                            </div>
                        </div>
                        <div className='grid-item-row'>
                            <h4 className='grid-item-title' style={{marginBottom: 0}}>
                            Overview: 
                            </h4>
                        </div>
                        <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('sport')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.category != null ? division.category.title : 'Unknown'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Venues' icon={venueIcon} number={locationCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('gender')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.gender != null ? division.gender : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Teams' icon={teamIcon} number={teamCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('age')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.ageTitle != null ? division.ageTitle : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Players' icon={playersIcon} number={playerCount} />
                                </div>
                            </Col>
                        </Row>
                        {allowDetail && (
                            <Button className="grid-action-btn" onClick={e => {
                                if (onClickDetails != null) {
                                    onClickDetails();
                                }
                            }}>{t('view')}</Button>
                        )}
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default DivisionBadge;