import React, { PureComponent } from 'react';
import { Editor } from 'react-bootstrap-editor';
import { htmlToText } from 'html-to-text';
  
class CustomRichTextEditor extends PureComponent {

    render() {
        const { placeholderText, label, description, value, onChange } = this.props;
        
        return (
            <Editor
                name={label}
                placeholder={placeholderText}
                defaultValue={value}
                onChange={(update) => {
                    const text = update ? htmlToText(update) : "";
                    if (onChange) {
                        onChange(update, text)
                    }
                }}
                isBottomOptions
                description={description} />
        );
    }
}

export default CustomRichTextEditor;