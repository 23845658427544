import React, { Component } from 'react';
import { Button, Spinner } from 'reactstrap';

import CustomRichTextEditor from 'components/template/CustomRichTextEditor';
import { invokeHttpsApi, listenDb } from 'utils/API';
import { t } from 'i18next';

export default class AdminPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            policy: {},
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        listenDb('policy', data => {
            this.setState({policy: data});
        })
    }

    render() {
        const {policy, progress} = this.state;
        
        return (
            <div style={{height: '70vh', margin: '0px 15px'}}>
                <h3>{t('policy')}</h3>
                <div style={{height: '80%', margin: '10px 0px', overflowY: 'auto', overflowX: 'hidden'}}>
                {policy.body && (
                <CustomRichTextEditor
                    placeholderText='Edit Your Content Here!'
                    value={policy.body}
                    onChange={(update)=> {
                        policy.body = update
                        this.setState({policy: policy})
                    }} />
                )}
                </div>
                {progress ? (
                <Spinner color="primary" />
                ) : (
                <Button className='btn-blue' onClick={e=> {
                    invokeHttpsApi("admin-submitPolicyUpdate", {
                        body: policy.body
                    }, ((data) => {

                        if (data && data.success) {
                            alert(t('saved_successfully'));
                            
                            this.setState({progress: false});
                        } else {
                            alert(t('saved_successfully'));
                            this.setState({progress: false});
                        }
                    }), ((error) => {
                        console.log('loadContents error', error);
                        alert(t('save_failed'));
                        this.setState({progress: false});
                    }));
                }}>{t('save')}</Button>
                )}
            </div>
        );
    }
}