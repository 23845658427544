import React, { Component } from 'react';
import { ListGroup } from 'reactstrap';
import ChallengeReviewBadge from 'components/detail/ChallengeReviewBadge';
import ChallengeReviewFilter from 'components/detail/ChallengeReviewFilter';
import TrialSubmissionBadge from 'components/detail/trial/TrialSubmissionBadge';
import TrialChallengeReviewFilter from 'components/detail/trial/TrialChallengeReviewFilter';

import CustomTabView from 'components/template/CustomTabView';
import { listenDb, listenDbQuery } from 'utils/API';
import { t } from 'i18next';

export default class AdminReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            leagues: {},
            challenges: {},
            submissions: {},
            users: {},
            submissionsChoice: {},
            leagueChoice: {},
            challengeChoice: {},
            submissionChoice: {},
            trials: {},
            trialChallenges: {},
            trialChallengeVideos: {},
            trialChallengeSubmissions: {},
            trialSubmissions: {},
            trialSubmissionsChoice: {},
            trialChoice: {},
            trialChallengeChoice: {},
            trialSubmissionChoice: {},
            uidChoice: null,
            editOpen: false,
            detailOpen: false
        };
        this.onFilterChoice = this.onFilterChoice.bind(this);
    }

    componentDidMount() {
        listenDb("challenges", data => {
                var challenges = {};

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        challenges[key] = value;
                    }
                }

                this.setState({
                    challenges: challenges,
                });
            });
        listenDb("submissions", data => {
                var submissions = {};
                var submissionsChoice = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        if (value.status === 'review') {
                            submissions[key] = value;
                            submissionsChoice[key] = value;
                        }
                    }
                }

                this.setState({
                    submissions: submissions,
                    submissionsChoice: submissionsChoice,
                });
            });
        listenDb("leagues", data => {
                var leagues = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        leagues[key] = value;
                    }
                }

                this.setState({
                    leagues: leagues,
                });
            });        
        listenDb('user', data => {
                var users = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        users[key] = value;
                    }
                }

                this.setState({
                    users: users,
                });
            });        
        listenDb('trial_challenges', data => {
                var trialChallenges = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        trialChallenges[key] = value;
                    }
                }

                this.setState({
                    trialChallenges: trialChallenges,
                });
            });        
        listenDb('trial_submissions', data => {
                var trialSubmissions = {};
                var trialSubmissionsChoice = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        trialSubmissions[key] = value;
                        trialSubmissionsChoice[key] = value;
                    }
                }

                this.setState({
                    trialSubmissions: trialSubmissions,
                    trialSubmissionsChoice: trialSubmissionsChoice,
                });
            });        
        listenDb('trials', data => {
                var trials = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        trials[key] = value;
                    }
                }

                this.setState({
                    trials: trials,
                });
            });
        listenDbQuery('videos', 'type', 'TrialChallenge', data => {
                var trialChallengeVideos = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        trialChallengeVideos[key] = value;
                    }
                }

                this.setState({
                    trialChallengeVideos: trialChallengeVideos,
                });
            });       
        listenDb('trial_challenge_submissions', data => {
                var trialChallengeSubmissions = {};
                

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        trialChallengeSubmissions[key] = value;
                    }
                }

                this.setState({
                    trialChallengeSubmissions: trialChallengeSubmissions,
                });
            });         
    }

    onFilterChoice(leagueChoice, challengeChoice, uidChoice) {
        const { submissions } = this.state;
        const submissionsChoice = {};

        for (const[key, value] of Object.entries(submissions)) {
            if (leagueChoice == null || leagueChoice.id == null || value.leagueId === leagueChoice.id) {
                if (challengeChoice == null || challengeChoice.id == null || value.challengeId === challengeChoice.id) {
                    if (uidChoice == null || value.uid === uidChoice) {
                        submissionsChoice[key] = value;
                    }
                }
            }
        }
                
        this.setState({
            submissionsChoice: submissionsChoice,
        });
    }

    renderSubmissions() {
        const { challenges, users, submissionsChoice } = this.state;

        const result = [];

        for (const[key, value] of Object.entries(submissionsChoice)) {
            const challenge = challenges[value.challengeId];
            const user = users[value.uid];

            if (challenge != null && user != null) {
                result.push(
                    <ChallengeReviewBadge
                        key={key}
                        challenge={challenge}
                        submission={value}
                        user={user} />
                )
            }
        }

        return result;
    }

    onFilterTrialChoice(trialChoice, uidChoice) {
        const { trialSubmissions } = this.state;
        const trialSubmissionsChoice = {};

        for (const[key, value] of Object.entries(trialSubmissions)) {
            if (trialChoice == null || trialChoice.id == null || value.trialId === trialChoice.id) {
                if (uidChoice == null || value.uid === uidChoice) {
                    trialSubmissionsChoice[key] = value;
                }
            }
        }
                
        this.setState({
            trialSubmissionsChoice: trialSubmissionsChoice,
        });
    }

    renderTrialSubmissions() {
        const { trials, trialChallenges, trialChallengeVideos, trialChallengeSubmissions, users, trialSubmissionsChoice } = this.state;

        const result = [];

        for (const[key, value] of Object.entries(trialSubmissionsChoice)) {
            const trial = trials[value.trialId];
            const user = users[value.uid];

            if (trial != null && user != null) {
                result.push(
                    <TrialSubmissionBadge
                        key={key}
                        trial={trial}
                        submission={value}
                        challenges={trialChallenges}
                        challengeSubmissions={trialChallengeSubmissions}
                        challengeVideos={trialChallengeVideos}
                        user={user} />
                )
            }
        }

        return result;
    }

    renderLeagueReviews() {
        const { leagues, challenges, submissions, users, leagueChoice, challengeChoice, uidChoice } = this.state;
        
        return (
            <div>
                <ChallengeReviewFilter
                    leagues={leagues}
                    challenges={challenges}
                    users={users}
                    leagueChoice={leagueChoice}
                    challengeChoice={challengeChoice}
                    uidChoice={uidChoice}
                    globalMode={true}
                    submissions={submissions}
                    onFilterChoice={(league, challenge, uid) => {
                        this.setState({
                            leagueChoice: league,
                            challengeChoice: challenge,
                            uidChoice: uid,
                        });
                        this.onFilterChoice(league, challenge, uid);
                    }} />
                <ListGroup>
                    {this.renderSubmissions()}
                </ListGroup>
            </div>
        );
    }

    renderTrialReviews() {
        const { trials, trialChallenges, trialSubmissions, users, trialChoice, uidChoice } = this.state;
        
        return (
            <div>
                <TrialChallengeReviewFilter
                    trials={trials}
                    trialChallenges={trialChallenges}
                    users={users}
                    trialChoice={trialChoice}
                    uidChoice={uidChoice}
                    submissions={trialSubmissions}
                    globalMode={true}
                    onFilterChoice={(trial, uid) => {
                        this.setState({
                            trialChoice: trial,
                            uidChoice: uid,
                        });
                        this.onFilterChoice(trial, uid);
                    }} />
                <ListGroup>
                    {this.renderSubmissions()}
                </ListGroup>
            </div>
        );
    }

    render() {
        const {value} = this.state;

        return (
            <div className='main-page' style={{height: '100vh'}}>
                <div style={{marginTop: 10}} className='wrap-content-fill-child'>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                        Admin Contents
                        </h3>
                        
                    </div>
                </div>
                <CustomTabView
                    value={value}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="scrollable"
                    onChange={(e, value)=> {
                        this.setState({value: value})
                    }}
                    aria-label="disabled tabs example"
                    items={[
                        {
                            label: t('Playlist')
                        },
                        {
                            label: t('Trial')
                        },
                    ]} />
                {value === 0 ? this.renderLeagueReviews() : this.renderTrialReviews()}
            </div>
        );
    }
}