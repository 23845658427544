import React, { Component } from 'react'
import JsonData from 'components/landing/data/data.json';
import {ContactUs} from 'components/landing/globe/ContactUs';

export class TermsPage extends Component {
    state = {
        landingPageData: {},
    }
    getlandingPageData() {
        this.setState({landingPageData : JsonData})
    }
  
    componentDidMount() {
        this.getlandingPageData();
    }
  
    render() {
        if (this.state.landingPageData.About != null) {
            return (
                <div className='main-info-area'>
                    <div className='main-info-area-bg'/>
                    <div className='main-info-area-content'>
                        <div className='container info'>
                        <h1>Terms and Conditions of Service</h1>

                        <br/>
                        <br/>

                        <h3>1.  Your Acceptance</h3>

                        <br/>
                        <br/>
                        <p>
                        Live! Inc. and its affiliates and subsidiaries (collectively referred to throughout as “<b>Live</b>”, “<b>we</b>” or “<b>us</b>”) offer a variety of products and services that help connect players and coaches to enhance players’ skills and monitor their progress, including without limitation websites, applications, software, content and all other current and future digital products and service offerings that we make available (collectively, the “<b>Services</b>”)
                        </p>

                        <br/>
                        <br/>

                        <h3>2.  Use of Services</h3>

                        <br/>
                        <br/>
                        <h4>(a)  Eligible Users</h4>
                        <p>
                        You must be at least 18 years of age, or the age of majority in your province, territory or country, to access and use the Services. Individuals under the age limit may not access or use the Services unless under the direct supervision and consent of a parent or guardian who is over the age limit.
                        </p>
                        
                        <h4>(b)  Accounts and Security</h4>
                        <p>
                        To access and use certain Services, you may need to create an account and register with Live!. When creating a Live! account, you agree to provide us with accurate and complete information as prompted during the account creation and registration process and to keep such information up to date.
                        </p>
                        <p>
                        You acknowledge and agree that you are responsible for maintaining the confidentiality of any and all actions that take place while using your account.
                        </p>
                        <p>
                        While Live! endeavours to protect the security of your account and your personal information, but we do not guarantee that security measures put in place will not be circumvented by unauthorized third parties. You agree to promptly notify Live! of any actual or suspected loss, left or unauthorized use of your account or account password. Live! is not responsible for any loss that results from unauthorized use of your account, with or without your knowledge.
                        </p>

                        <h4>(c)  Service Updates, Changes and Limitations</h4>
                        <p>
                        Live! may, as it sees fit, update or change the Services from time to time.  Live! reserves the right to make any changes, updates, modifications, corrections and/or additions to the Services (“<b>Updates</b>”) without prior notice to you.  Live! may also impose limitations or restrictions on certain Services or your access to part or all of the Services without prior notice to you.
                        </p>
                        <p>
                        Certain Updates may require you to install upgrades or updates in order for proper operation of the Services. These Updates may include updated versions of the applications for such Services. In certain cases, Live! will provide these Updates by means of an automatic upgrade of the applications used on your device.  You expressly consent to any such automatic Updates.
                        </p>
                        <p>
                        You agree that the Terms will apply to any and all Updates to the Services.
                        </p>
                        <p>
                        You acknowledge and agree that Live! has no obligation to provide any Updates or to continue to provide or enable any particular feature or functionality of any Service.
                        </p>

                        <h4>(d)  Monitoring, Termination or Suspension of Services</h4>
                        <p>
                        Live! reserves the right to change, suspend or discontinue any or all of the Services at any time, including the availability of any product, feature, database, or content. Live! may deactivate, terminate or suspend your account any time if: (1) Live! determines, in its sole discretion, that you are or have been in violation of the Terms; (2) Live! determines, in its sole discretion, that you have created risk or possible legal exposure for Live! or other users of the Service; (3) in response to requests by law enforcement or other government agencies; (4) upon discontinuance or material modification of any Services; or (5) due to unexpected technical issues or problems. Live! will endeavor to notify you of any such deactivation, termination or suspension.
                        </p>
                        <p>
                        Live! reserves the right, but has no obligation, to monitor any accounts and/or activities conducted through or in any way related to the Services, including the use and access of the Services or related data or content by any users.
                        </p>

                        <h4>(e)  Personal and Non-Commercial Use Only, Except where Permitted</h4>
                        <p>
                        Except where expressly permitted by Live!, the Services are intended only for your personal, non-commercial use.  You may not use the Services, or any content or information provided through the Services, for any commercial purposes.  If you seek to make commercial use of the Services, or any content or information provided through the Services,  you must enter into an agreement with Live! in advance.
                        </p>

                        <h4>(f)  Use in Compliance with Laws, Rules and Regulations</h4>
                        <p>
                        You agree to use the Services, including all features and functionalities associated therewith, in accordance with all applicable laws, rules and regulations, or other restrictions on use of the service or content therein.  Further, you agree not to: (1) archive, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, or use (except as expressly authorized in these Terms) content and information contained on or obtained from or through the Services; (2) circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the Services;  (3) use any robot, spider, scraper or other automated means to access the Services; (4) decompile, reverse engineer or disassemble any software or other products or processes accessible through the Service; (5) insert any code or product or manipulate the content of the Service in any way; (6) use any data mining, data gathering or extraction method on or in relation to the Services; and/or (7) upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Services, including any software viruses or any other computer code, files or programs.
                        </p>
                        <p>
                        Live! may terminate or restrict your use of the Services if you violate these Terms or are engaged in illegal or fraudulent use of the Services.
                        </p>

                        <h4>(g)   Consent to Receive Communications</h4>
                        <p>
                        Live! may send you periodic communications regarding the operations of our Services.  You consent to receiving these communications while you continue to use our Services.
                        </p>
                        <p>
                        Live! may also send you periodic communications regarding promotions and other offers or materials that we believe may be of interest to you. You can opt out of receiving these communications while you continue to use our Services by changing your preferences in your account.
                        </p>
                        <p>
                        In using the Services and engaging with the other users of the Services, you may receive electronic communications, including messages, from other users.  You can manage these communications by changing your preferences in your account.
                        </p>


                        <br/>
                        <br/>

                        <h3>3.  Ownership and Use of Content</h3>

                        <br/>
                        <br/>
                        <h4>(a)  Definitions</h4>
                        <p>
                        For the purposes of these Terms:
                        </p>
                        <p>
                        “<b>Content</b>” means any creative expression and includes, without limitation, video, audio, photographs, images, illustrations, animations, logos, tools, text, ideas, communications, replies, “likes,” comments, information, data, software, scripts, executable files, graphics, maps, routes, geo-data, workouts and workout data, training plans, sleep activity, annotations, nutrition information, recipes, interactive features, designs, copyrights, trademarks, patents, sounds, applications and any intellectual property therein, any of which may be generated, provided, or otherwise made accessible on or through the Services.
                        </p>
                        <p>
                        “<b>User-Generated Content</b>” means any Content that you or any user of the Services submits, transfers, or otherwise provides to or through the use of the Services.
                        </p>
                        <p>
                        “<b>Live! Content</b>” means any Content that is not User-Generated Content.
                        </p>
                        <p>
                        For clarity, Content includes, without limitation, both User-Generated Content and Live! Content.
                        </p>
                        
                        <h4>(b)  Ownership</h4>
                        <p>
                        Any and all copyright, trademarks, design rights, patents and other intellectual property rights (registered and unregistered) in and on the Services and Live! Content belongs to Live! and/or its partners or applicable third parties. Each user of the Services retains ownership, responsibility for, and/or other applicable rights in the User-Generated Content that they create.
                        </p>
                        <p>
                        You agree that you will respect the intellectual property rights of Live! and other users.
                        </p>
                        <p>
                        Except as expressly provided in the Terms, nothing in the Terms grants you a right or license to use any Live! Content, including any content owned or controlled by any of our partners or other third parties.
                        </p>

                        <h4>(c)  License of Live! Content</h4>
                        <p>
                        You acknowledge and agree that the Services, any necessary software or applications used in connection with the Services, and the Live! Content contains proprietary and confidential information that is protected by applicable intellectual property and other laws.
                        </p>
                        <p>
                        Live! grants you a limited, revocable, personal, non-transferable, and non-exclusive right and license to access and use the Services and Live! Content, provided that you do not (and do not allow any third party to) copy, modify, create a derivative work from, reverse engineer, sell, assign, sublicense, grant a security interest in, or otherwise transfer any right in the Live! Content or Services to anyone else. This license is subject to the Terms, including without limitation the following:
                        </p>
                        <p>
                        (i)  <b>Prohibited Uses.</b> Except as expressly permitted by applicable law or authorized by Live!, you agree not to:  (1) archive, reproduce, distribute, modify, display, perform, publish, license, create derivative works from, offer for sale, loan or use the Services, the Services' software or applications, or any Live! Content; (2) circumvent, remove, alter, deactivate, degrade or thwart any of the content protections in the Services, the Services' software or applications, or any Live! Content; (3) use any robot, spider, scraper or other automated means to access the Services' software or applications, or any Live! Content; (4) decompile, reverse engineer or disassemble any software or other products or processes accessible through the Services, the Services' software or applications, or any Live! Content; (5) insert any code or product or manipulate the content of the Services, the Services' software or applications, or any Live! Content in any way; (6) use any data mining, data gathering or extraction method with or in relation to the Services, the Services' software or applications, or any Live! Content; (7) upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy or limit the functionality of the Services, the Services' software or applications, or any Live! Content, including without limitation any software viruses or any other computer code, files or programs; and/or (8) download, copy, or save any Live! Content, except as expressly permitted by the functionality of the Services or solely for personal use or your records.
                        </p>
                        <p>
                        (ii)  <b>Personal, Non-Commercial Use.</b> Except where expressly permitted by Live!, you agree to use the Services, the Services’ software or applications, and the Live! Content only for your personal, non-commercial use.
                        </p>
                        <p>
                        (iii)  <b>Compliance with Laws.</b> You agree to use the Services, the Services’ software or applications, and the Live! Content only in accordance with all applicable laws, rules and regulations, or other restrictions on use of the Services, the Services’ software or applications, and the Live! Content.
                        </p>

                        <h4>(d)  License of User-Generated Content</h4>
                        <p>
                        By submitting User-Generated Content to Live! through the Services, you grant Live! a non-exclusive, irrevocable, royalty-free, freely transferable, sublicensable, worldwide right and license to use, host, store, cache, reproduce, publish, display (publicly or otherwise), perform (publicly or otherwise), distribute, transmit, modify, adapt (including, without limitation, in order to conform it to the requirements of any networks, devices, services, or media through which the Services are available), commercialize, create derivative works of, and otherwise exploit such User-Generated Content in connection with any and all Services. You also agree to waive any moral rights to your User-Generated Content with respect to any of the aforementioned rights.
                        </p>
                        <p>
                        By submitting User-Generated Content to Live! through the Services, you represent and warrant that you have all of the necessary rights to grant Live! a license, described above for any User-Generated Content you submit in connection with the Services.
                        </p>
                        <p>
                        You acknowledge and agree that: (a) Live! has the right to arrange the posting of User-Generated Content in any way we desire and to be accessed and used by other users of the Services; (b) Live! has no obligation to provide you with any credit when using your User-Generated Content, but if Live! chooses to provide you with credit, the size and placement of the credit is at our sole discretion; and (c) you are not entitled to any compensation or other payment from us in connection with the use of your User-Generated Content.
                        </p>
                        <p>
                        The rights you grant in this license are for the limited purposes of allowing Live! to operate the Services in accordance with their functionality, improve the Services, develop new Services, and to allow other users to use the Services in accordance with their intended function. Notwithstanding the above, Live! will not make use of any of your User-Generated Content in a manner that is inconsistent with Live!’s Privacy Policy.
                        </p>
                        <p>
                        Live! reserves the right to monitor, remove or modify User-Generated Content for any reason and at any time, including User-Generated Content that we believe violates these Terms.
                        </p>

                        <h4>(e)  Access to User-Generated Content</h4>
                        <p>
                        As part of the Services, you may be granted access to the User-Generated Content of others.
                        </p>
                        <p>
                        You agree that you will respect the intellectual property rights of others. You may access the User-Generated Content for your information and personal use solely as intended through the provided functionality of the Services and as permitted under these Terms. You shall not download any User-Generated Content unless express permitted by Live!. You shall not copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any User-Generated Content for any other purposes without the prior written consent of Live! or the respective licensors of the User-Generated Content. Live! and its licensors reserve all rights not expressly granted in and to the Services and the User-Generated Content.
                        </p>
                        <p>
                        Live! does not warrant or guarantee any of the User-Generated Content. Any User-Generated Content provided through the Services is provided to you as is.
                        </p>

                        <h4>(f)  Content Retention</h4>
                        <p>
                        Following the termination of your account, or if you remove any User-Generated Content from the Services, Live! may retain your User-Generated Content for a commercially reasonable period of time for backup, archival, or audit purposes, or as otherwise required or permitted by law. Furthermore, Live! and users of the Services may retain and continue to use, store, display, reproduce, share, modify, create derivative works, perform, and distribute any of your User-Generated Content that otherwise has been stored or shared through the Services.
                        </p>

                        <h4>(g)    Accuracy and Reliance on Content</h4>
                        <p>
                        Live! makes no representations or warranties as to the accuracy, reliability, completeness or timeliness of any Content available through the Services. Live! has no obligation to update any such Content.
                        </p>
                        <p>
                        In addition, all User-Generated Content, including advice, statements, or other information, made available through the Services are not produced by Live!. Any User-Generated Content available in connection with the Services, whether publicly posted or privately transmitted, is the sole responsibility of the user from whom such User-Generated Content originated. Live! does not endorse any opinions, recommendations, workouts, drills, exercises or the like contained in any User-Generated Content.  All information is provided “as is” without any representation, warranty or condition as to its accuracy, reliability, completeness or usefulness.
                        </p>


                        <br/>
                        <br/>

                        <h3>4.  Trade-marks</h3>

                        <br/>
                        <br/>

                        <p>
                        Live! owns or licenses all LIVE! trademarks, service marks, branding, logos, and other similar assets (the “<b>Live! Trademarks</b>”). You are not permitted to copy, imitate, modify, display or otherwise use the Live! Trademarks, in whole or in part, for purposes other than personal use or through the Services without Live!’s prior written approval.
                        </p>


                        <br/>
                        <br/>

                        <h3>5.   Intellectual Property Infringement</h3>

                        <br/>
                        <br/>

                        <p>
                        If you believe that User-Generated Content or Live! Content infringes intellectual property rights under Canadian or other national law, including without limitation copyright, trademarks, patents or industrial designs, please notify us immediately using the contact information provided herein. It is our policy to investigate all allegations of intellectual property infringement brought to our attention.
                        </p>


                        <br/>
                        <br/>

                        <h3>6.   Community Interactions and Guidelines</h3>

                        <br/>
                        <br/>

                        <p>
                        Live! may, but does not always and has no obligation to, monitor community features of the Services. Ultimately, you are responsible for your interactions with other users of the Services and for any User-Generated Content that you post, including the transmission, accuracy and completeness of the User-Generated Content. As certain portions of the Services are often public, you understand that your User-Generated Content may be made and remain public.
                        </p>
                        <p>
                        You are solely responsible for your interactions and involvement with other users of the Services, whether online or in person, including but not limited to comments, challenges, and friendly competition. Live! assumes no responsibility or liability for any loss or damage resulting from any interaction with other users who employ the Services, individuals you meet through the Services, or individuals who find you because of the Content posted on, by or through the Services.  You agree to take reasonable precautions in all interactions with any users or individuals you interact with in connection with the Services. Live! is under no obligation to become involved in any disputes between its users, but may do so at its own discretion.
                        </p>
                        <p>
                        In using the Services, you agree to abide by the rules regarding the posting of User-Generated Content and the use of the Services (the “<b>Community Guidelines</b>”). If you violate the Community Guidelines, Live! reserves the right to take action, including without limitation, terminating or suspending your access to the Services, removing your User-Generated Content, taking legal action against you or disclosing information to law enforcement authorities.
                        </p>
                        <p>
                        You acknowledge and understand that when you access or otherwise use the Services, you may be exposed to User-Generated Content from a variety of sources, and that Live! is not responsible for the accuracy, usefulness, safety or intellectual property rights of or relating to such User-Generated Content.  You also acknowledge and understand that you may be exposed to User-Generated Content shared by other users that may be offensive, indecent, or otherwise objectionable.
                        </p>
                        <p>
                        If you know or suspect that any Content submitted to the Services or any actions of users violate the Community Guidelines,  or if you know or suspect that someone is misusing your User-Generated Content, please report such instances to Live!. However, Live! is under no obligation, and shall not be liable for, any injury or harm to you resulting from objectionable User-Generated content or another user’s failure to comply with the Community Guidelines.
                        </p>
                        <p>
                        Live! reserves the right to monitor all uses of the Services for any violations of the Community Guidelines, but does not guarantee that all users of the Services will comply with the Community Guidelines or the Terms. Live! reserves the right to enforce, or not enforce, these Community Guidelines in our sole discretion.  The Community Guidelines do not create any duty or contractual obligation for Live! to act in any particular manner.
                        </p>


                        <br/>
                        <br/>

                        <h3>7.   Third Party Services and Content</h3>

                        <br/>
                        <br/>

                        <p>
                        The Services may link to, interact with or be available with third-party services or products.  As a result of your use of the Services, you may be able to access certain third-party links, applications, websites, content, advertisements, services, promotions, special offers, or other events, activities or products  (“<b>Third Party Content</b>”) that are not owned or controlled by Live!. Live! does not endorse or assume any responsibility for the use or access of any such Third Party Content and we do not guarantee that any Third Party Content will function with the Services or will be error-free. If you access, use or obtain Third Party Content, you do so at your own risk. Live! disclaims any liability for any Third Party Content and will not be liable for any act, error or omission of any Third Party Content.
                        </p>
                        <p>
                        You also acknowledge that access and use of such Third Party Content may be subject to different terms and privacy policies than those set out in the Terms.
                        </p>
                        <p>
                        You acknowledge that the Services are designed to enable use and access of Content through various devices.  The software or applications for the Services may vary by device and medium, and functionalities and features may also differ between devices.  You acknowledge that the use of the Services may require third party software or hardware that is subject to third party licenses.  Live! does not guarantee that the third party applications will be compatible with, or available on, your device.
                        </p>


                        <br/>
                        <br/>

                        <h3>8.   Paid Services</h3>

                        <br/>
                        <br/>

                        <p>
                        Live! offers certain aspects of the Services only to users who have registered and paid a subscription fee (the “<b>Premium Services</b>”). The Premium Services provide you access to certain enhanced products, services, features and functionality.
                        </p>
                        <p>
                        By signing up for the Premium Services, you agree to be bound by the Terms and any additional terms and conditions that are provided here.
                        </p>
                        <p>
                        By signing up for and using the Premium Services, you agree to pay any fees or other incurred charges that apply to the Premium Services, including without limitation the subscription fees.
                        </p>
                        <p>
                        When you sign up for the Premium Services, you must designate and provide information about your preferred payment method (“<b>Payment Method</b>”). You represent and warrant that this information is complete and accurate, and you agree that you are responsible for keeping such information up to date. You expressly authorize Live! to collect from you the appropriate fees and any other incurred charges for the Premium Services through the designated Payment Method. All fees and charges for the Premium Services will be billed to the Payment method.
                        </p>
                        <p>
                        The fees and other incurred charges for the Premium Services will be billed automatically to the Payment Method on a monthly and/or annually in accordance with the payment terms you have selected with your account. Unless otherwise stated, the fees and other incurred charges for the Premium Services will be billed to you and will auto-renew until you elect to cancel your access to the Premium Services.
                        </p>
                        <p>
                        Live! reserves the right to disable or terminate your access to the Premium Services, without prior notice to you, if you fail to pay the fees or charges due for your access and use of the Premium Services.
                        </p>
                        <p>
                        Cancellation of the Premium Service will take effect upon notice to Live! of cancellation.
                        </p>
                        <p>
                        All purchases of Premium Services are final and non-refundable. No refunds will be provided for any termination or cancellation of your Premium Services, except at Live!’s sole discretion and in accordance with the terms applicable to each Premium Service.  If you no longer wish to subscribe to your Premium Services, it is your responsibility to cancel your Premium Service in due time, regardless of whether or not you actively use the Premium Services.
                        </p>
                        <p>
                        To the maximum extent permitted by applicable laws, Live! reserves the right to change its prices for Premium Services at any time. Live! will provide reasonable notice of any such pricing changes.
                        </p>


                        <br/>
                        <br/>

                        <h3>9.   Physical Activities and Assumption of Risk</h3>

                        <br/>
                        <br/>

                        <p>
                        You acknowledge and agree that you should consult with your healthcare provider(s) and consider the associated risks before using the Services in connection with any physical activity, wellness or fitness program. You also acknowledge and agree that the Services, and any Content available through the Services, are not intended and should not be used for medical purposes or relied upon as any medical information or advice.
                        </p>
                        <p>
                        By using the Services, you agree, represent and warrant that you have received consent from your physician and healthcare provider(s) to participate in any physical activities, wellness and fitness programs, workouts, exercises, drills or any other related physical activities made available for you in connection with the Services. Any participation in the activities available through the Services is at your own risk.  If you choose to participate in these activities, you do so of your own free will and accord, knowingly and voluntarily assuming all risks associated with such activities. Activities available through the Services may pose risks even to those who are currently in good health.
                        </p>
                        <p>
                        You acknowledge and agree that Live! does not carry out, and are not responsible for, any physical inspection, supervision, preparation, execution or conduct of any activities related to or accessed or discovered via the Services.
                        </p>
                        <p>
                        You expressly agree that your physical activities, which may result from or may generate User-Generated Content you post or seek to post, or may have accessed, on or via the Services may carry certain inherent and significant risks of property damage, bodily injury, or death and that you voluntarily assume all known and unknown risks associated with these activities.
                        </p>
                        <p>
                        Except as otherwise set out in these Terms, and to the maximum extent permitted by applicable law, you expressly acknowledge and agree that Live! is not responsible or liable, either directly or indirectly, for any injuries or damages that are sustained from or as a result of your physical activities or your use of, or inability to use, any Services or features of the Services, including any Content, Third Party Content, or any activities that you access or discover through the Services.
                        </p>
                        

                        <br/>
                        <br/>

                        <h3>10.   Privacy</h3>

                        <br/>
                        <br/>

                        <p>
                        By using the Services, you consent to the collection, use and sharing of personal information, including the transfer of such personal information to Canada and/or other countries for storage, processing, and use by Live! in accordance with the Privacy Policy.
                        </p>
                        

                        <br/>
                        <br/>

                        <h3>11.   Changes to the Terms</h3>

                        <br/>
                        <br/>

                        <p>
                        Live! reserves the right, from time to time and in its sole discretion, to change or modify any of these Terms. Live! will notify you prior to these new Terms taking effect and may provide notice to you through the Services and/or by email where practicable.
                        </p>
                        

                        <br/>
                        <br/>

                        <h3>12.   No Warranties</h3>

                        <br/>
                        <br/>

                        <p>
                        EXCEPT WHERE PROHIBITED BY LAW, LIVE! EXPRESSLY DISCLAIMS ALL WARRANTIES, REPRESENTATIONS AND GUARANTEES OF ANY KIND, WHETHER ORAL OR WRITTEN, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT TO THE FULLEST EXTENT PERMISSIBLE UNDER THE LAW. THE SERVICES AND ALL CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” WITH ALL FAULTS BASIS.
                        </p>
                        <p>
                        Without limiting the foregoing, you understand that, to the maximum extent permitted by applicable law, Live! makes no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any of the Services, any Content or any Third Party Content. To the maximum extent permitted by applicable law, Live! does not warrant that (i) the Services will meet your requirements, (ii) the operation of the Services will be uninterrupted, virus- or error-free or free from other harmful elements, or (iii) that any errors will be corrected. Any oral or written advice provided by Live! or its agents does not and will not create any warranty.
                        </p>
                        <p>
                        To the maximum extent permitted by applicable law, Scimit also makes no representations or warranties of any kind with respect to any of the Content. User-Generated Content, in particular, is provided by and is solely the responsibility of the users providing that Content. No advice or information, whether oral or written, obtained from other users or through the Services, will create any warranty not expressly made herein. You therefore expressly acknowledge and agree that use of the Services is at your sole risk and that the entire risk as to satisfactory quality, performance, accuracy and effort is with you.
                        </p>
                        

                        <br/>
                        <br/>

                        <h3>13.   Limitation of Liability</h3>

                        <br/>
                        <br/>

                        <p>
                        To the maximum extent permitted by applicable law, under no circumstances shall Live!, its parent, subsidiaries and affiliates, and any of their officers, directors, employees or agents, be liable to you or any third party for any direct, indirect, incidental, special, reliance, exemplary, punitive, or consequential damages of any kind whatsoever, including but not limited to: (i) loss of profits, revenue, data, use, goodwill, or other intangible losses; (ii) damages relating to errors, mistakes or inaccuracies of any Content; (iii) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the Services; (iv) damages relating to your access to, use of, or inability to access or use the Services; (v) damages relating to any conduct or content of any third party or user using the Services, or any interaction with any third parties or users of the Services, including without limitation, defamatory, offensive or illegal conduct or content; (vi) and/or damages in any manner relating to any Third Party Content  accessed or discovered via the Services; (vii) damages as a result of any interruption or cessation of the Services; and/or (viii) any bugs, viruses, Trojan horses, or the like, which may be transmitted to or through the Services by any third party.
                        </p>
                        <p>
                        Without limiting the foregoing, to the maximum extent permitted by applicable law, this limitation of liability applies to all claims, whether based on warranty, contract, tort, or any other legal theory, whether or not Live! has been informed of the possibility of such damage, and further where a remedy set forth herein is found to have failed its essential purpose. To the maximum extent permitted by applicable law, the total liability of Live!, for any claim under these Terms, including for any implied warranties, is limited to the amount you paid us to use the applicable Services in the past twelve months.
                        </p>
                        <p>
                        To the extent permitted by applicable law, you acknowledge and agree that Live! offers the Services and sets the Services’ prices in reliance upon the warranty disclaimers, releases, and limitations of liability set forth in the Terms. To the extent permitted by applicable law, you also acknowledge and agree that these warranty disclaimers, releases, and limitations of liability reflect a reasonable and fair allocation of risk between you and Live! and that these warranty disclaimers, releases, and limitations of liability form an essential basis of the bargain between you and Live!.
                        </p>


                        <br/>
                        <br/>

                        <h3>14.   Indemnification</h3>

                        <br/>
                        <br/>

                        <p>
                        To the maximum extent permitted by applicable law, you agree to indemnify and hold Live!, its parent, subsidiaries and affiliates, and any of their officers, directors, employees or agents, harmless from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from or our of: (i) any User-Generated Content you access or share through the Services; (ii) your use of the Services, (iii) any physical activities in connection with the Services; (iv) your connection to the Services, (v) your violation of these Terms, (vi) your use or misuse of any personal information of another user of the Services, and/or (vii) any violation of the rights of any other person or entity by you. Live! reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us under the Terms, and you agree to cooperate with our defense of these claims.
                        </p>


                        <br/>
                        <br/>

                        <h3>15.   Governing Law and Venue</h3>

                        <br/>
                        <br/>

                        <p>
                        You agree that the Services shall be deemed to have been provided solely in Ontario, Canada. These Terms shall be governed by and construed in accordance with the laws of the Province of Ontario and the laws of Canada, where applicable, without regard to its conflict of law principles.
                        </p>
                        <p>
                        To the maximum extent permitted by applicable law, you agree that any claim or dispute between you and Live! that arises from or under, in whole or in part, the Services or these Terms shall be decided exclusively by a court of competent jurisdictions located in Ontario, Canada.
                        </p>
                        

                        <br/>
                        <br/>

                        <h3>16.  General</h3>

                        <br/>
                        <br/>
                        
                        <h4>(a)  Entire Agreement</h4>
                        <p>
                        The Terms constitute the entire agreement between you and Live! with respect to your use of the Services and supersedes all prior agreements, arrangements and understandings, written or oral.
                        </p>
                        
                        <h4>(b)  Relationship of the Parties</h4>
                        <p>
                        No joint venture, partnership, employment, or agency relationship exists between you and Live! as a result of the Terms or your use of the Services.
                        </p>
                        
                        <h4>(c)  No Waiver</h4>
                        <p>
                        Live!’s failure to exercise or enforce, or delay in exercising or enforcing, any right or provision of the Terms does not constitute a waiver of such right or provision.
                        </p>

                        <h4>(d)  Severability</h4>
                        <p>
                        If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.
                        </p>

                        <h4>(e)  Survival</h4>
                        <p>
                        In the event that our relationship or these Terms terminate, certain provisions of these Terms must survive and continue in full force and effect subsequent to termination in order to give proper effect to the intent and purpose of these Terms, including without limitation, Section 3 (Ownership and Use of Content), Section 9 (Physical Activities and Assumption of Risk), Section 12 (No Warranties), Section 13 (Limitations of Liability) and Section 14 (Indemnification).
                        </p>

                        <h4>(f)  Assignment</h4>
                        <p>
                        You may not assign, delegate, or otherwise transfer your account or your obligations under these Terms without our prior written consent. We have the right, in our sole discretion, to transfer or assign all or any part of our rights under these Terms and will have the right to delegate or use third party contractors to fulfill our duties and obligations under these Terms and in connection with the Services.
                        </p>

                        <h4>(g)   Notices</h4>
                        <p>
                        Live! will provide you with notices under these Terms by email, regular mail, or notices or links displayed in connection with the Services, as Live! determines to be reasonable for the circumstances. You agree that these means of notification constitute acceptable forms of notice to you.
                        </p>
                        <p>
                        Live! is not responsible for your failure to receive notice if email is quarantined by your email security system (e.g., “junk” or “spam” folder) or if you fail to update your email address or other contact information. Notice will be considered received forty-eight hours after it is sent if transmitted via email or regular mail. In the event that notice is provided via links displayed in connection with the Services, then it will be considered received twenty-four hours after it is first displayed.
                        </p>
                        <p>
                        Any notices required to be given to Live! under these Terms, or any other feedback, questions or comments, should be directed to:
                        </p>

                    </div>
                    <ContactUs data={this.state.landingPageData.Contact} />
                </div>
            </div>
            )
        } else {
            return <div/>
        }
    }
}

export default TermsPage;
