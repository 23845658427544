import React, { Component } from "react";
import {
    Button, Form, FormGroup, Input, Spinner, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CategoryChoice from 'components/edit/CategoryChoice';
import defaultImage from 'assets/img/icons/playlist_icon.png';

import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class DivisionTrophyEdit extends Component {
    constructor(props) {
        super(props);
        const trophy = {};
        if (props.trophy != null) {
            for (const [key, value] of Object.entries(props.trophy)) {
                trophy[key] = value;
            }
        }
        if (trophy.gender == null) {
            trophy.gender = 'Male';
        }
        
        this.state = {
            trophy: trophy,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, trophy} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.trophy !== trophy)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(trophy)) {
                challengeVal[key] = value;
            }
            this.setState({
                trophy: challengeVal
            })
        }
    }

    onSaveClick(trophy, newImage) {
        const {organization, division, onSaveDone} = this.props;
        const objRef = this;
        if (trophy.title == null || trophy.title === '') {
            alert("You must input title");
        } else if (trophy.description == null || trophy.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (trophy.id == null || trophy.id === '') {
                trophy.id = generateId("/trophy");
            }
            trophy.owner = organization.owner;
            trophy.organizationId = organization.id;
            trophy.divisionId = division.id;
            trophy.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(trophy, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone();
                }
                objRef.setState({
                    trophy: update,
                    progress: false
                });
            })
        }
    }

    saveChangeWithImage(trophy, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(trophy.id, 'trophyThumb', newImage, (url) => {
                trophy.thumbUri = url;
                objRef.saveChange(trophy, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(trophy, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(trophy, success, failure) {
        writeDb('/trophy/' + trophy.id, trophy, error => {
            if (error) {
                failure(error);
            } else {
                success(trophy);
            }
        })
    }

    renderCategory() {
        const {categorys} = this.props;
        const {trophy} = this.state;
        let categoryChoice = trophy.category != null ? trophy.category : {};

        return (
            <FormGroup>
                <CategoryChoice
                    title="Club Challenge Category"
                    categorys={categorys}
                    categoryChoice={categoryChoice}
                    onItemChoice={(item) => {
                        if (trophy.category == null) {
                            trophy.category = {};
                        }
                        for (const [key, value] of Object.entries(item)) {
                            trophy.category[key] = value;
                        }
                        this.setState({trophy: trophy});
                    }} />
            </FormGroup>
        );
    }

    render() {
        const {onCloseClick, createMode} = this.props;
        const {trophy, newImage, newVideo, progress, imageProgress} = this.state;
        
        if (trophy != null) {
            return (
                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Trophy Edit
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Image
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <HorizontalAttachImageEdit
                                imageUrl={trophy.thumbUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Trophy Title
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="text" name="text" id="title" onChange={e => {
                                    trophy.title = e.target.value;
                                    this.setState({trophy: trophy});
                                }} value={trophy.title} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Trophy Description
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="textarea" name="text" id="description" value={trophy.description} onChange={e => {
                                    trophy.description = e.target.value;
                                    this.setState({trophy: trophy});
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('gender')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="gender" id="gender" value={trophy.gender} onChange={e => {
                                    trophy.gender = e.target.value;
                                    this.setState({trophy: trophy});
                                }}>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Coed">Coed</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <Row style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(trophy, newImage, newVideo);
                                }}>{t('create')}</Button>
                            </Row>
                        ) : (
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                    <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(trophy, newImage, newVideo);
                                    }}>{t('save')}</Button>
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={7}>
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </Col>
                            </Row>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(DivisionTrophyEdit);