import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';

export class AddContentBadge extends Component {

    render() {
        const {title, subtitle, thumbUri, onClickAdd, onClickRemove, defaultImage, toAdd } = this.props;
        let challengeImage = thumbUri != null ? thumbUri : defaultImage;
        
        return (
            <div>
                <Card className="flex-row shadow-tile">
                    <CardImg
                        className="profile-thumb"
                        src={challengeImage}
                        style={{ width: 90 }}
                        />
                    <CardBody>
                        <h4 className='label label-default'>
                        {title}
                        </h4>
                        <p>{subtitle}</p>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="challenge-progress wrap-content-wrap-child">
                                <Button className="btn-submit" onClick={e => {
                                    if (toAdd) {
                                        if (onClickAdd != null) {
                                            onClickAdd();
                                        }
                                    } else {
                                        if (onClickRemove != null) {
                                            onClickRemove();
                                        }
                                    }
                                }}>{toAdd ? "Add" : "Remove"}</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div style={{marginTop: 10}}/>
            </div>
        )
    }
}

export default AddContentBadge;