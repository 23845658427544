import React, { Component } from "react";
import {
    Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';

import { toSimpleTime } from 'utils/Utils';

import {
    MdAccessTime,
    MdLocationOn
} from 'react-icons/md';
import { STATUS_FINISHED } from "utils/constants";

export class OrganizationEventHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {locations, schedule, user, onClickRequest, onClickCancel } = this.props;
        const location = locations[schedule.location] != null ? locations[schedule.location] : {};
        const uid = user != null ? user.uid : null;
        
        const icon = location.thumbUri != null ? location.thumbUri : defaultImage;

        const date = new Date(schedule.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        
        const from = toSimpleTime(schedule.timestamp);
        const to = toSimpleTime(schedule.deadline);

        let state = 'raw';
        if (schedule.deadline < new Date().valueOf()) {
            state = STATUS_FINISHED;
        } else if (schedule.fieldVerificationList != null && Object.keys(schedule.fieldVerificationList).includes(uid)) {
            if (schedule.fieldVerificationList[uid].verified) {
                state = 'verified';
            } else {
                state = 'rejected';
            }
        } else if (schedule.requested != null && Object.keys(schedule.requested).includes(uid)) {
            state = 'requested';
        }
        
        
        if (schedule != null) {
            return (
                <div>
                    <div className="shadow-tile wrap-content-parent">
                        <div className="date-header wrap-content-wrap-child">
                            <div className="date-month">
                                {day}
                            </div>
                            <div className="date-day">
                                {month}
                            </div>
                        </div>
                        <div className='wrap-content-fill-child' style={{padding: '5px 10px'}}>
                            <Row>
                                <Col nm={8} xs={12} sm={6} className="info-col">
                                <h4 className='label label-default one-line-title'>
                                {schedule.title}
                                </h4>
                                <MdAccessTime/> {from} {" ~ "} {to} <MdLocationOn/> {location.formatted_address}
                                </Col>
                                <Col nm={4} xs={12} sm={6} className="info-col">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child'>
                                        </div>
                                        <div className="wrap-content-wrap-child">
                                            <img alt="icon" src={icon} style={{width: 20, height: 20, marginRight: 10}} />
                                            {location.title}
                                        </div>
                                    </div>
                                    <div className='wrap-content-parent' style={{marginTop: 5}}>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="challenge-progress wrap-content-wrap-child">
                                            {state === STATUS_FINISHED && (
                                            <Button className="btn-pick" onClick={e => {
                                            }}>{t(STATUS_FINISHED)}</Button>
                                            )}
                                            {user != null && state === 'raw' && (
                                            <Button className="btn-submit" onClick={e => {
                                                if (onClickRequest != null) {
                                                    onClickRequest();
                                                }
                                            }}>{t('request')}</Button>
                                            )}
                                            {user != null && state === 'requested' && (
                                            <Button className="btn-cancel" onClick={e => {
                                                if (onClickCancel != null) {
                                                    onClickCancel();
                                                }
                                            }}>{t('cancel')}</Button>
                                            )}
                                            {user != null && state === 'verified' && (
                                            <Button className="btn-submit" onClick={e => {
                                            }}>{t('verified')}</Button>
                                            )}
                                            {user != null && state === 'rejected' && (
                                            <Button className="btn-cancel" onClick={e => {
                                            }}>{t('rejected')}</Button>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default OrganizationEventHeader;