import React, { Component } from "react";
import {
    Button,
    Spinner,
    Row,
    Col,
    Input
} from 'reactstrap';
import { t } from 'i18next';
import {
    MdClose,
} from 'react-icons/md';
import {
    RiMoneyDollarBoxFill,
} from 'react-icons/ri';
import { 
    toDateTimeString
} from 'utils/Utils';

import walletIcon from 'assets/img/icons/ic_wallet.png';
import { invokeHttpsApi } from "utils/API";

export default class WalletDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            users: {},
            balanceInput: 0,
        }
    }

    renderTransactionItem(item) {
        const {users} = this.state;

        const price = parseFloat(item.amount) / 100;

        let msg = "Waiting";
        if (item.staffId && users[item.staffId]) {
            msg = users[item.staffId].username;
        } else if (!item.success && item.type !== 'direct') {
            msg = "Pending";
        } else if (item.success && item.type === 'direct') {
            msg = "Paid";
        } else if (price < 0 && item.title) {
            msg = item.title
        }

        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: price > 0 ? '#38be55' : '#EE000F'}}>
                    {price > 0 ? "$" + price.toString() : "-$" + (-price).toString()}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'direct' ? "Online" : (item.type === 'wallet' ? "Wallet Outcome" : "In Person")}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {msg}
                    </h4>
                </Col>
            </Row>
        );
    }

    submitPayment() {
        const {wallet, onChange} = this.props;
        const {balanceTitle, balanceInput, balanceType} = this.state;
        
        const data = {
            wallet: wallet,
            price: balanceInput,
            type: balanceType ? balanceType : "wallet",
            title: balanceTitle
        }

        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-addWalletTransaction', data, ((data)=> {
            if (data && data.success) {
                onChange(data.wallet, data.payment);
                this.setState({checkoutProgress: false});
            } else {
                alert(t('request_failed'));
                this.setState({checkoutProgress: false});
            }
        }), ((error)=> {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({checkoutProgress: false});
        }))
    }

    renderTransactions() {
        const {wallet} = this.props;

        const paymentsArr = wallet.transactions ? Object.values(wallet.transactions) : [];

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        const income = [];
        const outcome = [];
        
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            if (item.price > 0) {
                income.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else {
                outcome.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            }
        }

        const items = [];
        for (const item of income) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of outcome) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div className='wallet-info-tile'>
                <div className='detail-nav-btn'>
                    <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                    
                    <h3 className='items-navigation-title'>
                    Transactions
                    </h3>
                </div>
                <Row style={{margin: 0}}>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Date
                        </h4>
                    </Col>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Money
                        </h4>
                    </Col>
                    <Col lg={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Type
                        </h4>
                    </Col>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Status/Staff
                        </h4>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Transactions To Show
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {wallet, user, onCloseClick} = this.props;
        const {checkoutProgress, balanceTitle, balanceInput, balanceType} = this.state;
        
        let balance = parseFloat(wallet.balance ? wallet.balance : 0) / 100;
        return (
            <div>
                <div className="wallet-title-bar">
                    <img className="wallet-title-icon" src={walletIcon} alt="icon" />
                    <h3 className="wallet-title">{user ? user.username : t('my_wallet')}</h3>
                    <Button className="btn-submit wallet-title-action" onClick={e=> {
                        if (onCloseClick) {
                            onCloseClick();
                        }
                    }}>
                        <MdClose />
                    </Button>
                </div>
                <div className="wrap-content-parent wallet-margin" style={{marginTop: 10}}>
                    <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24}}>Balance:</h1>
                    <h1 className="wrap-content-fill-child" style={{color: "#EEB825", fontSize: 24}}>${balance}</h1>
                </div>
                
                <div className="wallet-title-bar" style={{marginTop: 10}}>
                    <Input type="select" name="type" id="type" className="wrap-content-fill-child" onChange={e => {
                        this.setState({balanceType: e.target.value});
                    }} value={balanceType}>
                        <option value={null}>{t('invoice')}</option>
                        <option value="cash">{t('payment')} - {t('cash')}</option>
                        <option value="online">{t('payment')} - {t('online')}</option>
                        <option value="eTransfer">{t('payment')} - {t('e_transfer')}</option>
                        <option value="card">{t('payment')} - {t('card')}</option>
                    </Input>
                    {balanceType == null && (
                    <Input name="title" id="title" placeholder="Payment Title" className="wrap-content-fill-child" onChange={e => {
                        this.setState({balanceTitle: e.target.value});
                    }} value={balanceTitle} />
                    )}
                    <Input type="number" name="money" id="money" className="wrap-content-fill-child" onChange={e => {
                        this.setState({balanceInput: e.target.value});
                    }} value={balanceInput} />
                    {checkoutProgress ? (
                        <Spinner color="grow" />
                    ) : (
                        <Button className="btn-submit wrap-content-wrap-child" style={{marginRight: 0}} onClick={e=> {
                            this.submitPayment();
                        }}>
                            Submit
                        </Button>
                    )}
                </div>
                <div className="wallet-transactions-list">
                    {this.renderTransactions()}
                </div>
            </div>
        );
    }
}