import React, { Component } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
  
class CustomDateTimePicker extends Component {

    render() {
        const { format, label, value, onChange } = this.props;

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker
                        margin="normal"
                        format={format}
                        label={label}
                        value={dayjs(value)}
                        onChange={update => {
                            onChange(update.toDate())
                        }}/>
                </DemoContainer>
            </LocalizationProvider>
        );
    }
}

export default CustomDateTimePicker;
