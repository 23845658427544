import React, { Component } from "react";
import { Form, Card, CardBody, Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import SurveyCheckQuestion from './SurveyCheckQuestion';
import SurveyOptionQuestion from './SurveyOptionQuestion';
import SurveyTextQuestion from './SurveyTextQuestion';
import SurveyRatingQuestion from './SurveyRatingQuestion';
import SurveyEditView from 'components/edit/survey/SurveyEditView';

import {
    QUESTION_TYPE_OPTION,
    QUESTION_TYPE_CHECK,
    QUESTION_TYPE_TEXT,
    QUESTION_TYPE_RATING,
} from 'utils/constants';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class SurveyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submission: {}
        }
        this.submitSubmission = this.submitSubmission.bind(this);
    }

    componentDidMount() {
        const {submission} = this.props;
        var update = {};
        if (submission != null) {
            for (const[key, value] of Object.entries(submission)) {
                update[key] = value;
            }
        }
        this.setState({submission: update});
    }

    componentDidUpdate(prevProps) {
        const {submission} = this.props;
        if (prevProps.submission !== submission) {
            var update = {};
            if (submission != null) {
                for (const[key, value] of Object.entries(submission)) {
                    update[key] = value;
                }
            }
            this.setState({submission: update});
        }
    }

    getQuestion(survey, qSubmission) {
        if (survey != null && survey.questions != null) {
            for (const [, value] of Object.entries(survey.questions)) {
                if (value.id === qSubmission.questionId) {
                    return value;
                }
            }
        }
        return null;
    }

    getQuestionSubmission(submission, questionId) {
        if (submission != null && submission.questions != null) {
            for (const [, value] of Object.entries(submission.questions)) {
                if (value.questionId === questionId) {
                    return value;
                }
            }
        }
        return null;
    }

    getOption(question, id) {
        if (question != null && question.options != null) {
            for (const [, value] of Object.entries(question.options)) {
                if (value.id === id) {
                    return value;
                }
            }
        }
        return null;
    }

    createNewQuestionSubmission(submission, question) {
        if (submission.id == null) {
            submission.id = generateId('surveySubmission');
        }
        let id = generateId('/survey/' + submission.id + '/questions');
        let data = {
            id: id,
            submissionId: submission.id,
            type: question.type,
            questionId: question.id,
            uid: submission.uid,
            value: 0,
        }
        return data;
    }
    
    createNewSubmission() {
        const {survey, uid} = this.props;
        let id = generateId('surveySubmission');
        let data = {
            id: id,
            surveyId: survey.id,
            timestamp: dbTimestamp(),
            survey: survey,
            uid: uid,
            status: 0,
            value: 0,
        }
        return data;
    }

    checkSubmissionReady() {
        const {submission} = this.state;
        if (submission.questions == null) {
            return false;
        }
        
        for (const [, item] of Object.entries(submission.questions)) {
            let type = item.type;
            if (type === QUESTION_TYPE_OPTION && item.choiceId == null) {
                return false;
            }
            if (type === QUESTION_TYPE_CHECK && (item.checkedIds == null || Object.values(item.checkedIds).length === 0)) {
                return false;
            }
            if (type === QUESTION_TYPE_RATING && (item.rating == null || item.rating === -1)) {
                return false;
            }
            if (type === QUESTION_TYPE_TEXT && item.text == null) {
                return false;
            }
        }
        return true;
    }

    prepareSubmissionForReady() {
        const {survey} = this.props;
        let total = 0;
        let count = 0;
        const {submission} = this.state;
        if (submission.questions == null) {
            submission.questions = {};
        }
        
        for (const [, item] of Object.entries(submission.questions)) {
            const type = item.type;
            if (type === QUESTION_TYPE_OPTION) {
                let question = this.getQuestion(survey, item);
                if (question != null) {
                    let option = this.getOption(question, item.choiceId);
                    item.value = option != null ? parseInt(option.value) : 0;
                }
                total += item.value;
            }
            if (type === QUESTION_TYPE_CHECK) {
                let question = this.getQuestion(survey, item);
                if (question != null && item.checkedIds != null) {
                    for (const [,checked] of Object.entries(item.checkedIds)) {
                        let option = this.getOption(question, checked);
                        item.value += option != null ? parseInt(option.value) : 0;
                    }
                }
                total += item.value;
            }
            if (type === QUESTION_TYPE_RATING) {
                let question = this.getQuestion(survey, item);
                if (question != null) {
                    item.value = item.rating !== -1 ? ((item.rating * 20) | 0) : 0;
                }
                total += item.value;
            }
            if (type === QUESTION_TYPE_TEXT) {
                //let question = this.getQuestion(survey, item);

                total += item.text != null ? item.value : 0;
            }
            count ++;

        }

        submission.value = count > 0 ? ((total / count)) | 0 : 0;
        this.setState({submission: submission});
        return submission;
    }

    submitSubmission() {
        if (this.checkSubmissionReady()) {
            let submission = this.prepareSubmissionForReady();
            submission.timestamp = dbTimestamp();
            submission.status = 1;
            writeDb('/surveySubmission/' + submission.id, submission, error => {
                if (error) {
                    alert("Saving Submission failed. Please Retry later");
                } else {
                    alert("Submitted Successfully");
                    this.setState({submission: submission});
                }
            })
        } else {
            alert("Your Submission is not ready yet. Please be sure fill out all fields.");
        }
    }
    
    render() {
        const {survey, editOpen, onCloseEdit, user, uid, editable, onCloseClick} = this.props;
        
        let {submission} = this.state;
        const questions = [];
        const isReview = editable != null ? !editable : (submission != null && submission.uid !== uid);

        if (submission == null || submission.id == null) {
            submission = this.createNewSubmission();
        }
        
        if (survey.questions != null) {
            
            for (const [key, value] of Object.entries(survey.questions)) {
                let questionSubmission = this.getQuestionSubmission(submission, key);
                
                switch (value.type) {
                    case QUESTION_TYPE_OPTION:
                        questions.push(
                            <SurveyOptionQuestion 
                                key={key}
                                isReview={isReview}
                                question={value} 
                                submission={questionSubmission}
                                onChange={(choiceId) => {
                                    if (questionSubmission == null) {
                                        questionSubmission = this.createNewQuestionSubmission(submission, value);
                                        if (submission.questions == null) {
                                            submission.questions = {};
                                        }
                                        submission.questions[questionSubmission.id] = questionSubmission;
                                    }
                                    submission.questions[questionSubmission.id].choiceId = choiceId;
                                    this.setState({submission: submission});
                                }} />
                        );
                        questions.push(
                            <div className='survey-question-divider'/>);
                        break;
                    case QUESTION_TYPE_CHECK:
                        questions.push(
                            <SurveyCheckQuestion 
                                key={key}
                                isReview={isReview}
                                question={value} 
                                submission={questionSubmission}
                                onChange={(checkedIds) => {
                                    if (questionSubmission == null) {
                                        questionSubmission = this.createNewQuestionSubmission(submission, value);
                                        if (submission.questions == null) {
                                            submission.questions = {};
                                        }
                                        submission.questions[questionSubmission.id] = questionSubmission;
                                    }
                                    submission.questions[questionSubmission.id].checkedIds = checkedIds;
                                    this.setState({submission: submission});
                                }} />
                        );
                        questions.push(
                            <div className='survey-question-divider'/>);
                        break;
                    case QUESTION_TYPE_TEXT:
                        questions.push(
                            <SurveyTextQuestion 
                                key={key}
                                isReview={isReview}
                                question={value} 
                                submission={questionSubmission}
                                onChange={(text) => {
                                    if (questionSubmission == null) {
                                        questionSubmission = this.createNewQuestionSubmission(submission, value);
                                        if (submission.questions == null) {
                                            submission.questions = {};
                                        }
                                        submission.questions[questionSubmission.id] = questionSubmission;
                                    }
                                    submission.questions[questionSubmission.id].text = text;
                                    this.setState({submission: submission});
                                }} />
                        );
                        questions.push(
                            <div className='survey-question-divider'/>);
                        break;
                    case QUESTION_TYPE_RATING:
                        questions.push(
                            <SurveyRatingQuestion 
                                key={key}
                                isReview={isReview}
                                question={value} 
                                submission={questionSubmission}
                                onChange={(rating) => {
                                    if (questionSubmission == null) {
                                        questionSubmission = this.createNewQuestionSubmission(submission, value);
                                        if (submission.questions == null) {
                                            submission.questions = {};
                                        }
                                        submission.questions[questionSubmission.id] = questionSubmission;
                                    }
                                    submission.questions[questionSubmission.id].rating = rating;
                                    this.setState({submission: submission});
                                }} />
                        );
                        questions.push(
                            <div className='survey-question-divider'/>);
                        break;
                    default:
                        break;
                }

            }
        }
        
        
        if (survey != null && survey.id != null) {
            return (
                <Card>
                    <CardBody>
                        <Form>
                            <h3 className='survey-title'>{survey.title}</h3>
                            <div className='survey-text'>{survey.text}</div>                            
                            <div className='survey-question-divider'/>
                            {questions}
                            <div>
                            {!isReview && (
                                <Button className='btn-submit' onClick={e=> {
                                    this.submitSubmission();
                                }}>Submit</Button>
                            )}
                            {onCloseClick && (
                                <Button className='btn-submit' onClick={e=> {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                }}>{t('close')}</Button>
                            )}
                            </div>
                        </Form>
                    </CardBody>
                    {survey.providerId === uid && (
                        
                        <Modal isOpen={editOpen}>
                            <ModalBody>
                                <SurveyEditView 
                                    survey={survey} 
                                    isOpen={editOpen}
                                    user={user}
                                    onCloseClick={() => {
                                        if (onCloseEdit != null) {
                                            onCloseEdit();
                                        }
                                    }} />
                            </ModalBody>
                        </Modal>
                    )}
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SurveyView);