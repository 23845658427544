import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/video_default.png';

export class TutorialHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {tutorial, onClickDetails, onClickEdit } = this.props;
        let tutorialImage = tutorial.thumbUri != null ? tutorial.thumbUri : defaultImage;
        
        if (tutorial != null) {
            return (
                <div>
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb"
                            src={tutorialImage}
                            style={{ width: 150, height: 150 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {tutorial.title}
                            </h4>
                            <p>{tutorial.description}</p>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="tutorial-progress wrap-content-wrap-child">
                                    <Button className="btn-submit" onClick={e => {
                                        if (onClickDetails != null) {
                                            onClickDetails();
                                        }
                                    }}>Details</Button>
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TutorialHeader);