import React, { Component } from "react";
import {
    Card, CardImg, Button, Row, Col, Spinner
} from 'reactstrap';
import { t } from 'i18next';
import { 
    MdEdit
} from 'react-icons/md';

import SubInfoView from 'components/detail/SubInfoView';
import defaultImage from 'assets/img/icons/shield_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/games_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { STATUS_FINISHED, STATUS_ONGOING } from "utils/constants";

export class ProgramBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    renderStatus() {
        const { program, onStatusUpdatePrompt, progress } = this.props;

        switch (program.status) {
            case STATUS_FINISHED:
                return (
                    <div className='wrap-content-parent'>
                        <h3 className='wrap-content-fill-child row-table-title' style={{textAlign: 'left', fontSize: 18}}>
                        Program Status: <div style={{display: 'inline-block', color: '#00ee00'}}>{t('finished')}</div>
                        </h3>
                        <div className='wrap-content-wrap-child vertical-center'>
                        {t('finished')}
                        </div>
                        <div className='wrap-content-wrap-child vertical-center'>
                            
                        </div>
                    </div>
                );
            case STATUS_ONGOING:
                return (
                    <div className='wrap-content-parent'>
                        <h3 className='wrap-content-fill-child row-table-title' style={{textAlign: 'left', fontSize: 18}}>
                            Program Status: <div style={{display: 'inline-block', color: '#00ee00'}}>ONGOING</div>
                        </h3>
                        <div className='wrap-content-wrap-child vertical-center'>
                            Step: 2/3
                        </div>
                        <div className='wrap-content-wrap-child vertical-center'>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <Button className="btn-submit" onClick={e => {
                                if (window.confirm('Are you sure you want to finish this program?')) {
                                    program.status = STATUS_FINISHED;
                                    if (onStatusUpdatePrompt) {
                                        onStatusUpdatePrompt(program);
                                    }
                                }
                            }}>Finish Program</Button>
                        )}
                        </div>
                    </div>
                );
            default:
                return (
                    <div className='wrap-content-parent'>
                        <h3 className='wrap-content-fill-child row-table-title' style={{textAlign: 'left', fontSize: 18}}>
                            Program Status: <div style={{display: 'inline-block', color: '#ee0000'}}>REGISTRATION</div>
                        </h3>
                        <div className='wrap-content-wrap-child vertical-center'>
                            Step: 1/3
                        </div>
                        <div className='wrap-content-wrap-child vertical-center'>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <Button className="btn-submit" onClick={e => {
                                if (window.confirm('Are you sure you want to finish registration for this program?')) {
                                    program.status = STATUS_ONGOING;
                                    if (onStatusUpdatePrompt) {
                                        onStatusUpdatePrompt(program);
                                    }
                                }
                            }}>Finish Registration</Button>
                        )}
                        </div>
                    </div>
                );
        }
    }
    
    render() {
        const {program, schedules, onClickDetails, onClickEdit, allowDetail, blockEdit, showStatus } = this.props;
        const challengeImage = program.iconUri != null ? program.iconUri : defaultImage;
        const playerCount = program.playerIds == null ? 0 : Object.values(program.playerIds).length;
        const managerCount = program.managers == null ? 0 : Object.values(program.managers).length;
        
        let scheduleIds = [];
        for (const schedule of Object.values(schedules)) {
            if (schedule && schedule.programId === program.id) {
                if (!scheduleIds.includes(schedule.id)) {
                    scheduleIds.push(schedule.id);
                }
            }
        }
        const scheduleCount = scheduleIds.length;
        
        if (program != null) {
            return (
                <div key={program.id}>
                    <Card className="shadow-tile grid-badge">
                        <div className='grid-item-row'>
                            <CardImg
                                className="grid-item-icon"
                                src={challengeImage} />
                            <div className='grid-item-info-fill'>
                                <h4 className='grid-item-title'>
                                {program.title}
                                </h4>
                                <div className="grid-item-info">
                                    <ReactReadMoreReadLess
                                        charLimit={170}
                                        readMoreText={"More  ▼"}
                                        readLessText={"Less  ▲"}
                                    >
                                        {program.description ? program.description : ""}
                                    </ReactReadMoreReadLess>
                                </div>
                            </div>
                            <div className='grid-item-info-wrap'>
                            {!blockEdit && (
                                <Button className="grid-edit-btn" onClick={e => {
                                    if (onClickEdit != null) {
                                        onClickEdit();
                                    }
                                }}><MdEdit/></Button>
                            )}
                            {showStatus && (
                                this.renderStatus()
                            )}
                            </div>
                        </div>
                        <div className='grid-item-row'>
                            <h4 className='grid-item-title' style={{marginBottom: 0}}>
                            Overview: 
                            </h4>
                        </div>
                        <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('sport')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {program.category != null ? program.category.title : 'Unknown'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Schedules' icon={venueIcon} number={scheduleCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('gender')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {program.gender != null ? program.gender : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Coaches' icon={teamIcon} number={managerCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('age')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {program.ageTitle != null ? program.ageTitle : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Players' icon={playersIcon} number={playerCount} />
                                </div>
                            </Col>
                        </Row>
                        {allowDetail && (
                            <Button className="grid-action-btn" onClick={e => {
                                if (onClickDetails != null) {
                                    onClickDetails();
                                }
                            }}>{t('view')}</Button>
                        )}
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ProgramBadge;