import React, { Component } from "react";
import {
    Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
    comma: 188,
    enter: 13,
};
  
const delimiters = [KeyCodes.comma, KeyCodes.enter];

export class CategoryTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
    }
    
    renderItemChoice() {
        const { tags, category, onTagUpdate } = this.props;

        if (category != null && category.tags != null && Object.values(category.tags).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onTagUpdate != null) {
                        onTagUpdate(null)
                    }
                    this.setState({editOpen: false});
                }}>Clear Tags</ListGroupItem>
            )
            let strs = category.tags != null ? category.tags.split(',') : [];    
            let curStrs = [];    
            if (tags != null) {
                for (const tag of tags) {
                    curStrs.push(tag.text);
                }
            }
            for (const value of strs) {
                if (!curStrs.includes(value)) {
                    items.push(
                        <ListGroupItem key={value} onClick={e => {
                            curStrs.push(value)
                            if (onTagUpdate != null) {
                                onTagUpdate(curStrs)
                            }
                        }}>{value}</ListGroupItem>
                    )
                }
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Category Present</div>
        }
    }
    
    handleDelete(i) {
        const { tags, onTagUpdate } = this.props;
        
        const updated = tags.filter((tag, index) => index !== i);
        const tagsUpdate = [];
        if (updated != null) {
            for (const tag of updated) {
                tagsUpdate.push(tag.text);
            }
        }
        if (onTagUpdate != null) {
            onTagUpdate(tagsUpdate)
        }
    }

    handleAddition(tag) {
        
    }

    handleDrag(tag, currPos, newPos) {
        const { tags, onTagUpdate } = this.props;
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        const tagsUpdate = [];
        if (newTags != null) {
            for (const tag of newTags) {
                tagsUpdate.push(tag.text);
            }
        }
        if (onTagUpdate != null) {
            onTagUpdate(tagsUpdate);
        }
    }

    render() {
        const { tags } = this.props;
        const { editOpen } = this.state;
        
        return (
            <div style={{marginBottom: 30}}>
                <div>
                    <ReactTags 
                        classNames={{
                            tagInput: 'ReactTags__tagInput_hidden',
                        }}
                        tags={tags}
                        allowDeleteFromEmptyInput={false}
                        handleDelete={this.handleDelete}
                        handleAddition={this.handleAddition}
                        handleDrag={this.handleDrag}
                        delimiters={delimiters} />
                    <div className='wrap-content-parent'>
                        <div className=' wrap-content-fill-child vertical-center-spacing'>
                        </div>
                        <Button className='wrap-content-wrap-child btn-pick' onClick={e => {
                            this.setState({editOpen: true})
                        }}>Change</Button>
                    </div>

                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        {this.renderItemChoice()}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-pick" onClick={e => {
                            this.setState({editOpen: false})
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(CategoryTags);