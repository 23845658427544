import React, { Component } from "react";
import {
    CardImg, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    GiSoccerField,
    GiWhistle,
} from 'react-icons/gi';

import { toSimpleTime } from 'utils/Utils';
import { getOrgDomain } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';

export class OrgGameBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    render() {
        const { organization, highlight, game, teams, locations, users, detailMode, onClickDetail, onClickReferee, showBg, onClickTeam } = this.props;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const date = new Date(game.start ? game.start : 0);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toSimpleTime(game.start);

        let location = locations && locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        
        return (
            <div style={{backgroundColor: showBg ? 'rgba(0,0,0,0.3)' : 'none', padding: '10px 0px', fontSize: 10}}>
                <Row>
                    <Col lg={5} xs={4} sm={4} className="text-center col-no-padding" onClick={e=>{
                        if (onClickTeam) {
                            if (homeTeam.id) {
                                onClickTeam(homeTeam);
                            }
                        } else if (homeTeam.id) {
                            window.location = `${getOrgDomain(organization)}/teams/${homeTeam.id}`;
                        }
                    }}>
                        <CardImg
                            className="org-game-team-icon"
                            src={homeThumb} />
                        <div className={highlight ? 'org-game-team-title-highlight' : 'org-game-team-title'}>
                        {homeTeam.title ? homeTeam.title : t('unknown')}
                        </div>
                    </Col>
                    <Col lg={2} xs={4} sm={4} className="text-center game-badge-time col-no-padding" style={{padding: 0}}>
                    <div className='game-badge-calendar' style={{margin: '0px auto'}}>
                        <div className='game-badge-day'>
                            {day}
                        </div>
                        <div className='game-badge-month'>
                            {month}
                        </div>
                    </div>
                    <b style={{color: highlight ? 'white' : 'black'}}>{timeStr}</b>
                    {game.homeScore != null && game.awayScore != null && (
                        <p style={{color: highlight ? 'white' : 'black'}}><b>{game.homeScore !== -1 ? game.homeScore : "_"} - {game.awayScore !== -1 ? game.awayScore : "_"}</b></p>
                    )}
                    </Col>
                    <Col lg={5} xs={4} sm={4} className="text-center col-no-padding" onClick={e=>{
                        if (onClickTeam) {
                            if (awayTeam.id) {
                                onClickTeam(awayTeam);
                            }
                        } else if (awayTeam.id) {
                            window.location = `${getOrgDomain(organization)}/teams/${awayTeam.id}`;
                        }
                    }}>
                        <CardImg
                            className="org-game-team-icon"
                            src={awayThumb} />
                        <div className={highlight ? 'org-game-team-title-highlight' : 'org-game-team-title'}>
                        {awayTeam.title ? awayTeam.title : t('unknown')}
                        </div>
                    </Col>
                </Row>
                <div className="text-center" style={{color: highlight ? 'white' : 'black'}}>
                    <div className="vertical-center-spacing">
                        <GiSoccerField/>
                    </div>
                    <div className=' vertical-center-spacing'>
                    {location.title}
                    </div>
                    <div className="vertical-center-spacing">
                        <GiWhistle/>
                    </div>
                    <div className=' vertical-center-spacing'>
                    {referee.uid != null ? referee.username : t('no_referee')}
                    </div>
                    {onClickDetail != null && (
                        <button className='detail-nav-btn vertical-center-spacing' style={{color: highlight ? '#ddd' : '#333', fontWeight: 800, fontSize: 12, marginTop: 10, textDecoration: 'underline'}} onClick={e => {
                                if (onClickDetail != null) {
                                    onClickDetail();
                                }
                            }}>
                        {detailMode ? t('details') : t('edit')}
                        </button>
                    )}
                    {onClickReferee != null && !detailMode && (
                        <button className='detail-nav-btn vertical-center-spacing' style={{color: '#333', fontWeight: 800, fontSize: 12, marginTop: 10, textDecoration: 'underline'}} onClick={e => {
                                if (onClickReferee != null) {
                                    onClickReferee();
                                }
                            }}>
                        {t('referee')}
                        </button>
                    )}
                </div>
            </div>
        );
    }
}

export default withTranslation()(OrgGameBadge);