import React, { Component } from "react";
import {
    Col, Row, CardImg
} from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import KnockoutGameBadge from 'components/edit/organization/KnockoutGameBadge';
import { Tree, TreeNode } from 'react-organizational-chart';
import { buildEmptySchedule } from 'utils/statistics';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { dbTimestamp, generateId } from "utils/API";
import { STATUS_FINISHED } from "utils/constants";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class KnockoutRoundEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rankingData: [],
            pickTeams: false,
            newImage: {},
            deployType: 'weekBased',
            imageProgress: 0,
            locationTimes: {},
            gameTree: {},
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        const {season, subSeason} = this.props;
        const update = {};
        if (subSeason != null) {
            for (const [key, value] of Object.entries(subSeason)) {
                update[key] = value;
            }
        }

        const games = update.games != null ? Object.values(update.games) : [];
        
        const teamIdsArr = this.initRanking();
        const data = buildEmptySchedule(update, games, season, teamIdsArr);
        if (data.games != null && data.games.length > 0) {
            subSeason.games = {};
            for (const game of data.games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
            }
        }
        if (data.rounds != null) {
            subSeason.rounds = data.rounds;
        }
        
        this.setState({
            subSeason: subSeason,
            gameTree: data.gameTree,
            rounds: data.rounds,
            games: data.games,
            pickTeams: false,
            newImage: {},
            deployType: 'weekBased',
            imageProgress: 0,
            locationTimes: {},
        })
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, season, subSeason, startTeams} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            
            this.setState({
                progress: false,
                pickTeams: false,
                newImage: {},
                locationTimes: {},
                imageProgress: 0
            })
        }
        if (prevProps.subSeason !== subSeason || prevProps.startTeams !== startTeams) {
            const update = {};
            for (const [key, value] of Object.entries(subSeason)) {
                update[key] = value;
            }
            const teamIdsArr = this.initRanking();
            const data = buildEmptySchedule(update, [], season, teamIdsArr);
            this.setState({
                subSeason: update,
                gameTree: data.gameTree,
                rounds: data.rounds,
                games: data.games,
            })
        }
    }

    onUpdateGames(games) {
        const {season, subSeason} = this.props;
        const {rounds} = this.state;

        subSeason.owner = season.owner;
        subSeason.organizationId = season.organizationId;
        subSeason.divisionId = season.divisionId;
        subSeason.seasonId = season.id;
        
        subSeason.timestamp = dbTimestamp();

        if (games != null && games.length > 0) {
            subSeason.games = {};
            for (const game of games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
            }
        }
        if (rounds != null) {
            subSeason.rounds = rounds;
        }
        this.setState({games: games});
        
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
    }

    onUpdateValue() {
        const {season, subSeason} = this.props;
        const {games, rounds} = this.state;

        subSeason.owner = season.owner;
        subSeason.organizationId = season.organizationId;
        subSeason.divisionId = season.divisionId;
        subSeason.seasonId = season.id;
        
        subSeason.timestamp = dbTimestamp;

        if (games != null && games.length > 0) {
            subSeason.games = {};
            for (const game of games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
            }
        }
        if (rounds != null) {
            subSeason.rounds = rounds;
        }
        
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
    }

    renderRoundGames() {
        const {gameTree} = this.state;
        
        if (gameTree != null && Object.values(gameTree).length > 0) {
            return (
                <div style={{overflowX: 'auto', padding: '20px 0px'}}>
                    <div style={{display: 'inline', margin: '0px auto'}}>
                    {this.renderKnockoutGame(gameTree)}
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }

    renderKnockoutGame(item) {
        const {games} = this.state;
        if (item.children != null) {
            if (item.attributes.nextId == null) {
                let items = [];
                items.push(this.renderKnockoutGame(item.children[0]));
                items.push(this.renderKnockoutGame(item.children[1]));

                let final = null;
                let final34 = null;
                for (const item of games) {
                    if (item.final && item.gameIdx === 0) {
                        final = item;
                    }
                    if (item.final && item.gameIdx === 1) {
                        final34 = item;
                    }
                }
                return (
                    <Tree 
                        lineWidth={'2px'}
                        lineColor={'green'}
                        lineBorderRadius={'10px'}
                        label={
                            <div>
                                {final != null && final34 != null ? (
                                    <div>
                                    {this.renderGameBade(final)}
                                    {this.renderGameBade(final34)}
                                    </div>
                                ) : (
                                    <div>
                                    {this.renderGameBade(item.attributes)}
                                    </div>
                                )}
                            </div>
                        }>
                        {items}
                    </Tree>
                )
            } else {
                let items = [];
                items.push(this.renderKnockoutGame(item.children[0]));
                items.push(this.renderKnockoutGame(item.children[1]));
                return (
                    <TreeNode 
                        label={
                            this.renderGameBade(item.attributes)
                        }>
                        {items}
                    </TreeNode>
                )
            }
        } else {
            return (
            <TreeNode label={
                this.renderGameBade(item.attributes)
            } />
            );
        }
    }

    renderGameBade(game) {
        const {allowEdit, teams, locations, organization, users, division} = this.props;
        const {games} = this.state;

        return (
            <KnockoutGameBadge
                game={game}
                division={division}
                organization={organization}
                teams={teams}
                users={users}
                allowEdit={allowEdit}
                locations={locations}
                onUpdate={(update)=> {
                    for (const item of games) {
                        if (item.id === update.id) {
                            for (const [key, val] of Object.entries(update)) {
                                item[key] = val;
                            }
                            break;
                        }
                    }
                    this.onUpdateGames(games);
                }} />
        )
    }

    initRanking() {
        const {season} = this.props;

        const rankingData = [];
        if (season && season.teamIds) {
            
            let ranking = 0;
            for (const id of Object.values(season.teamIds)) {
                rankingData.push({id: id, points: 0, index: ranking ++, ranking: ranking});
            }
        }
        
        this.setState({
            rankingData: rankingData
        });

        return rankingData;
    }
    
    onDragEnd(result) {
        const {season, subSeason} = this.props;
        const {games} = this.state;
        // dropped outside the list
        if (season.status === STATUS_FINISHED) {
            alert('You can not change ranking of finished season');
            return;
        }
        
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.rankingData,
            result.source.index,
            result.destination.index
        );
        let ranking = 0;
        for (const item of items) {
            item.ranking = ranking ++;
        }

        const data = buildEmptySchedule(subSeason, games, season, items);
        if (games != null && games.length > 0) {
            subSeason.games = {};
            for (const game of games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
            }
        }
        if (data.rounds != null) {
            subSeason.rounds = data.rounds;
        }
        
        
        this.setState({
            gameTree: data.gameTree,
            rounds: data.rounds,
            games: data.games,
            rankingData: items
        })
        
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
    }

    renderKnockoutTeams() {
        const {subSeason} = this.props;
        const {rankingData} = this.state;
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {t('knockout_start_teams')} / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={4} xs={3} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={8} xs={9} sm={8} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'transparent',
                                        border: '1px solid rgb(87, 87, 87,0.15)',
                                        boxShadow: '0 0 5px rgba(0,0,0,0.15)',
                                        padding: '0px 0px',
                                    }}
                                >
                                {rankingData.map((ranking, index) => (
                                    <Draggable key={ranking.id} draggableId={ranking.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style=
                                                {{
                                                    userSelect: 'none',
                                                    padding: 2,
                                                    margin: '0 5px',
                                                    background: snapshot.isDragging ? 'lightgreen' : 'transparent',
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                {this.renderBadge(ranking)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }

    renderBadge(item) {
        const {subSeason, teams} = this.props;
        const team = teams[item.id] != null ? teams[item.id] : {};
        
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;

        let title = "Free Team";
        let color = "transparent";
        let startTeams = subSeason.startTeams ? subSeason.startTeams : 0;
        if (item.ranking < startTeams) {
            title = " on Game " + parseInt(item.ranking / 2);
            if (item.ranking % 2 === 0) {
                title = "Home " + title;
            } else {
                title = "Away " + title;
            }
            color = "white";
        }


        return (
            <div>
                <Row style={{backgroundColor: color}}>
                    <Col lg={4} xs={3} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {title}
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} xs={9} sm={8} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {team.title}
                            </h4>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
            </div>
        );
    }

    render() {
        const {subSeason} = this.props;

        if (subSeason != null) {
            return (
                <div className='shadow-tile' style={{padding: '30px 20px'}}>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {subSeason.title}
                        </h4>
                        <h5 className='season-round-subtitle wrap-content-wrap-child'>
                            {t('season_winners')}: {subSeason.winnerCount}
                        </h5>
                    </div>
                    {this.renderKnockoutTeams()}
                    {this.renderRoundGames()}
                    
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = connect(mapState, actionCreators)(KnockoutRoundEdit);
export { connectedApp as KnockoutRoundEdit };
