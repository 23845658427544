import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import { CardImg, Input, Spinner, Button } from 'reactstrap';
import { t } from 'i18next';

import { BlueNavHeader } from 'components/landing/organization/BlueNavHeader';
import { NavHeader } from 'components/landing/organization/NavHeader';
import { contact } from 'utils/landing';
import { getOrgData } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import LoadingView from 'components/detail/loading/LoadingView';
import { withRouter } from 'utils/Routher';
import { GoogleMap, LoadScript, Marker, } from '@react-google-maps/api';
import { MdEmail, MdLocationPin, MdPhone } from 'react-icons/md';

class OrgContact extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            organization: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const seasonId = this.props.router.params.seasonId;
            this.setState({id: id, seasonId: seasonId});
        }
        if (window.location.search && window.location.search.includes('payment_result=true')) {
            alert("Payment sent to owner sccuessfully. You will get into season once he accepts it.");
        } else if (window.location.search && window.location.search.includes('payment_result=false')) {
            alert("Payment not sent to owner sccuessfully. You should retry later.");
        }
        this.loadContents();
    }

    loadContents() {
        const queryData = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizerEventsQuery', queryData, ((data)=>{

            const organization = {};
            const organizations = {};
            const users = {};
            const teams = {};
            const locations = {};
            const schedules = {};
            const orgSchedules = {};
            const covidForm = {};
            const playerForms = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (key === queryData.organizationId || value.customDomain === queryData.customDomain) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.schedules)) {
                    schedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.orgSchedules)) {
                    orgSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.covidForm)) {
                    covidForm[key] = value;
                }
                for (const [key, value] of Object.entries(data.playerForms)) {
                    playerForms[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                teams: teams,
                locations: locations,
                users: users,
                schedules: schedules,
                orgSchedules: orgSchedules,
                covidForm: covidForm,
                playerForms: playerForms,
                loading: false,
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderContents() {
        const { organization, mailText, mailAddress, progress } = this.state;
        const landingData = (organization && organization.landingData) ? organization.landingData : {};
        const contactData = landingData.contact ? landingData.contact : contact;

        const itemsArr = Object.values(contactData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];

        const bgClass = organization.landingData && organization.landingData.theme === 'blue' ? 'org-landing-contents-bg-blue' : 'org-landing-contents-bg';
        for (const item of itemsArr) {
            items.push((
                <div>
                    <h4 className="org-landing-contents-subtitle">
                    {item.subtitle}
                    </h4>
                    {item.bg != null && item.bg !== '' && (
                    <div className="post-media-wrapper">
                    <div className="post-media-child">
                        <CardImg 
                            className='task-image'
                            src={item.bg} />
                    </div>
                    </div>
                    )}
                    <div className="org-landing-contents-text">
                    {item.text}
                    </div>
                </div>
            ))
        }

        return (
            <div className={bgClass}>
                <div className='container'>
                    
                    <h2 className="org-landing-contents-title">
                    {contactData.title}
                    </h2>
                    {items}

                    <div className='org-contact-wrapper'>
                        <div className='org-contact-item'>
                            <div className='org-contact-item-title'>
                                <MdEmail /> {t('email')}
                            </div>
                            <div className='org-contact-item-text'>
                                {contactData.email}
                            </div>
                        </div>
                        <div className='org-contact-item'>
                            <div className='org-contact-item-title'>
                                <MdPhone/> {t('phone')}
                            </div>
                            <div className='org-contact-item-text'>
                                {contactData.phone}
                            </div>
                        </div>
                        {contactData.address && contactData.address.lat && contactData.address.lng && (
                        <div className='org-contact-item'>
                            <div className='org-contact-item-title'>
                                <MdLocationPin/> {t('address')}
                            </div>
                            <div style={{flex: 1}}>
                                <div className='org-contact-item-text'>{contactData.address.placeName}</div>
                                <div style={{height: 15}} />
                                <div className="org-landing-contents-text">
                                {contactData.address.formatted_address}
                                </div>
                                <LoadScript
                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '150px'
                                        }}
                                        center={{lat: contactData.address.lat, lng: contactData.address.lng}}
                                        zoom={15}
                                        >
                                        <Marker title={contactData.address.placeName} position={{lat: contactData.address.lat, lng: contactData.address.lng}} >
                                        </Marker>
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </div>
                        )}

                    </div>

                    
                    <div className='org-contact-wrapper'>
                        <h3 className="org-landing-contents-info-title" style={{marginTop: 40}}>
                        Reply Address
                        </h3>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                this.setState({mailAddress: e.target.value});
                            }} value={mailAddress} />
                        </div>
                        <h3 className="org-landing-contents-info-title" style={{marginTop: 20}}>
                        Email Text
                        </h3>
                        <div className='form-setting-input' style={{marginBottom: 20}}>
                            <Input type="textarea" name="text" id="customDomain" onChange={e => {
                                this.setState({mailText: e.target.value});
                            }} value={mailText} />
                        </div>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <div style={{textAlign: 'end'}}>
                                <Button className='btn-submit' onClick={e => {
                                    if (!mailAddress || mailAddress.length === 0) {
                                        alert("You should input reply email address");
                                        return;
                                    }
                                    if (!mailText || mailText.length === 0) {
                                        alert("You should input reply email text to send");
                                        return;
                                    }
                                    if (!contactData.email || contactData.email.length === 0) {
                                        alert("Sorry. Contact email is not ready yet.");
                                        return;
                                    }
                                    this.sendEmail(contactData.email, mailAddress, mailText);
                                }}>Send</Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    sendEmail(to, reply, text) {
        this.setState({progress: true});
        invokeHttpsApi('invite-sendContactEmail', {
            to: to,
            reply: reply,
            text: text,
        }, ((data)=>{
            if (data && data.success) {
                alert("Sent Email Successfully.");
                
                this.setState({progress: false});
            } else {
                alert("Sending Email failed. Please type valid one.");
                this.setState({progress: false});
            }
        }), ((error)=> {
            console.log('loadContents error', error);
            alert(error);
            this.setState({progress: false});
        }));
    }

    render() {
        const {organization, loading} = this.state;
        
        const theme = organization.landingData && organization.landingData.theme ? organization.landingData.theme : null;
        
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        
        return (
            <div>
                {(!theme || theme === 'main') && (
                <NavHeader 
                    organization={organization} 
                    data={{}} />
                )}
                {theme === 'blue' && (
                <BlueNavHeader 
                    organization={organization}
                    loading={loading} />
                )}
                <div style={{paddingTop: 20, backgroundColor: 'white'}}>
                    {this.renderContents()}
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgContact));
export { connectedPage as OrgContact };
