import React, { Component } from "react";
import {
    Button
} from 'reactstrap';
import { t } from 'i18next';

import { getWeekStartDay, shuffle, MIN_TIME, WEEK_TIME, checkMatches, initGameLineUp } from 'utils/Utils';
import SubSeasonDeploy from 'components/edit/organization/SubSeasonDeploy';

export class LocationBasedDeploy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
            locationTimes: {},
        }
        this.deployLeagueGames = this.deployLeagueGames.bind(this);
    }
    
    getTeamIds(excludeId) {
        const homeTeams = [];
        const {subSeason} = this.props;
        if (subSeason != null && subSeason.teamIds != null) {
            for (const [key, ] of Object.entries(subSeason.teamIds)) {
                if (excludeId !== key) {
                    homeTeams.push(key);
                }
            }
        }
        return homeTeams;
    }

    checkGamesAndAdd(games, home, away) {
        const {subSeason, season} = this.props;
        
        let includes = false;
        for (const game of games) {
            if (game.homeTeam === home && game.awayTeam === away) {
                includes = true;
                break;
            }
            if (game.homeTeam === away && game.awayTeam === home) {
                includes = true;
                break;
            }
        }
        if (!includes) {
            games.push({
                homeTeam: home,
                awayTeam: away,
                divisionId: season.divisionId,
                organizationId: season.organizationId,
                seasonId: season.id,
                subSeasonId: subSeason.id,
            })
        }
    }

    getAvailableTeamMatches() {
        const games = [];
        let homeTeams = this.getTeamIds();
        const teamCount = homeTeams.length;
        const estimateCount = teamCount * (teamCount - 1) / 2;
        let limit = 0;
        while (homeTeams.length > 0 && games.length < estimateCount) {
            const homeIdx = Math.trunc(Math.random() * homeTeams.length);
            let awayTeams = this.getTeamIds(homeTeams[homeIdx]);
            while (homeTeams.length > 0 && awayTeams.length > 0) {
                const awayIdx = Math.trunc(Math.random() * awayTeams.length);

                this.checkGamesAndAdd(games, homeTeams[homeIdx], awayTeams[awayIdx]);
                if (awayTeams.length > 1) {
                    awayTeams.splice(awayIdx, 1);
                } else {
                    awayTeams = [];
                }
            }
            if (homeTeams.length > 1) {
                homeTeams.splice(homeIdx, 1);
            } else {
                homeTeams = [];
            }
            if (limit > 60) {
                break;
            }
        }
        
        return games;
    }

    getAvailableReservations(locationTimes, division, targetCount) {
        const reservations = [];
        const timeLimit = division.category.timeLimit * MIN_TIME;
        const availableTimeData = {};
        let maxTimeCount = 0; 
        let startOfWeek = getWeekStartDay(new Date().valueOf());
        for (const [locationId, timeData] of Object.entries(locationTimes)) {
            const newVal = [];
            for (const [, value] of Object.entries(timeData)) {
                const count = Math.trunc((value.offsetEnd - value.offset) / timeLimit);
                if (count > 0) {
                    for (let i = 0; i < count; i ++) {
                        newVal.push({
                            locationId: locationId,
                            start: value.offset + timeLimit * i + startOfWeek,
                            end: value.offset + timeLimit * (i + 1) + startOfWeek - MIN_TIME,
                            picked: false
                        })
                    }
                }
            }
            availableTimeData[locationId] = newVal;
            if (newVal.length > maxTimeCount) {
                maxTimeCount = newVal.length;
            }
        }
        for (let i = 0; i < maxTimeCount; i ++) {
            for (const [, timeData] of Object.entries(availableTimeData)) {
                if (timeData.length > i) {
                    reservations.push(timeData[i]);
                }
            }
        }

        let duplicateTimes = Math.trunc(targetCount / reservations.length) + 1;
        const result = [];
        for (let i = 0; i < duplicateTimes; i ++) {
            for (let j = 0; j < reservations.length; j ++) {
                const item = reservations[j];
                result.push({
                    locationId: item.locationId,
                    start: item.start + i * WEEK_TIME,
                    end: item.end + i * WEEK_TIME,
                    picked: false
                })
            }
        }
        
        return result;
    }

    getReservation(homeId, reservations) {
        const {organization} = this.props;
        let homeLocationId = null;
        if (organization.teamData != null && organization.teamData[homeId] != null && organization.teamData[homeId].locationId != null) {
            homeLocationId = organization.teamData[homeId].locationId;
        }
        for (let i = 0; i < reservations.length; i ++) {
            const item = reservations[i];
            if (homeLocationId != null && homeLocationId === item.locationId && !item.picked) {
                item.picked = true;
                return item;
            }
        }
        for (let i = 0; i < reservations.length; i ++) {
            const item = reservations[i];
            if (!item.picked) {
                item.picked = true;
                return item;
            }
        }
        return null;
    }

    deployLeagueGames() {
        const {season, division, onCompleteDeploy} = this.props;
        const {locationTimes} = this.state;
        if (!season.teamIds || Object.values(season.teamIds).length === 0) {
            alert("You should pick teams to attend this season.");
            return;
        }

        const games = shuffle(this.getAvailableTeamMatches());

        const reservations = this.getAvailableReservations(locationTimes, division, games.length);
        
        for (let i = 0; i < games.length; i ++) {
            const item = games[i];
            const reservation = this.getReservation(item.homeTeam, reservations);
            if (reservation != null) {
                item.locationId = reservation.locationId;
                item.start = reservation.start;
                item.end = reservation.end;
            }
            initGameLineUp(item, season);
        }
        checkMatches(games);

        if (onCompleteDeploy != null) {
            onCompleteDeploy(games);
        }
    }

    renderLeagueGameDeploy() {
        const {subSeason, locations} = this.props;
        const {locationTimes} = this.state;

        return (
            Object.values(locations).map((value,i)=>(
                <SubSeasonDeploy
                    subSeason={subSeason}
                    location={value}
                    onUpdateTime={(val)=>{
                        locationTimes[value.id] = {};
                        for (const [key, update] of Object.entries(val)) {
                            locationTimes[value.id][key] = update;
                        }
                        this.setState({locationTimes: locationTimes});
                    }} />
            ))
        );
    }

    render() {
        const {locations} = this.props;
        
        return (
            <div style={{marginTop: 10, marginBottom: 20}} >
                <h4 className='wrap-content-fill-child' style={{marginTop: 10, marginBottom: 10}}>
                {t('location_games_deployment')} : {Object.values(locations).length}
                </h4>
                <div className='divider' />
                {this.renderLeagueGameDeploy()}
                <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                <Button className='btn-submit' onClick={ e => {
                    this.deployLeagueGames();
                }}>{t('deploy')}</Button>
            </div>
        );
    }
}

export default LocationBasedDeploy;