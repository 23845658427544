import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import { getDivisionDomain, getOrgData } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { FcTreeStructure } from 'react-icons/fc';
import DivisionSetupView from 'components/edit/organization/setup/DivisionSetupView';
import { Row, Col } from 'reactstrap';
import ItemsNavigation from 'components/detail/ItemsNavigation';
  
import DivisionBadge from 'components/detail/organization/DivisionBadge';
import DivisionDetail from 'components/detail/organization/DivisionDetail';
import logo200Image from 'assets/img/logo/live_192.png';
import { 
    replaceUrl,
} from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import LoadingView from 'components/detail/loading/LoadingView';
import { t } from 'i18next';

class OrgManager extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            organization: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }

    componentDidMount() {
        const id = this.props.router.params.id;
        this.loadContents(id);
    }

    loadContents(id) {
        const {divisionChoice} = this.state;
        this.setState({lodaing: true});
        
        const queryData = getOrgData(this.props);
        invokeHttpsApi('managerQuery-divisionsQuery', queryData, ((data)=>{

            const organization = {};
            const organizations = {};
            const divisions = {};
            const seasons = {};
            const trophies = {};
            const profileData = {};
            const users = {};
            const teams = {};
            const locations = {};
            const category = {};
            const cards = {};
            const wallets = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (key === queryData.organizationId || value.customDomain === queryData.customDomain) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.trophies)) {
                    trophies[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallet)) {
                    wallets[key] = value;
                }
            }
            let choiceId = id != null ? id : divisionChoice.id;
            let update = divisions[choiceId];
            if (update != null) {
                for (const [key, value] of Object.entries(update)) {
                    divisionChoice[key] = value;
                }
            }

            this.setState({
                divisions: divisions,
                organizations: organizations,
                organization: organization,
                seasons: seasons,
                teams: teams,
                trophies: trophies,
                locations: locations,
                profileData: profileData,
                divisionChoice: divisionChoice,
                users: users,
                category: category,
                cards: cards,
                wallets: wallets,
                loading: false,
                detailOpen: divisionChoice.id != null
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    renderContents() {
        const {user} = this.props;
        const {organization, divisions, teams, seasons, cards, trophies, wallets, users, locations, profileData, divisionChoice, category, detailOpen, editOpen} = this.state;
        const seasonId = this.props.router.params.seasonId;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        let curConetntCount = 0;
        for (const[, value] of Object.entries(divisions)) {
            if (value.owner === user.uid) {
                curConetntCount += 1;
            }
        }
        
        if (Object.values(divisions).length === 0 || editOpen) {
            return (
            <div>
                <ItemsNavigation
                    title="Divisions"
                    icon={<FcTreeStructure/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={divisionChoice}
                    onClickBack={()=>{
                        this.setState({divisionChoice: {}, detailOpen: false, editOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({divisionChoice: {}, editOpen: true});
                    }} />
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <DivisionSetupView 
                        division={divisionChoice}
                        organization={organization}
                        categorys={category}
                        createMode={Object.values(divisions).length === 0 || !divisionChoice.id} 
                        uid={user.uid}
                        onDivisionUpdate={update => {
                            if (update.id) {
                                divisions[update.id] = update;
                            }
                            this.setState({divisions: divisions, divisionChoice: update});
                        }}
                        onCloseClick={()=> {
                            this.setState({divisionChoice: {}, editOpen: false});
                        }}
                        onSaveDone={(update)=> {
                            if (update.id) {
                                divisions[update.id] = update;
                            }
                            this.setState({divisions: divisions, editOpen: false});
                        }} />
                </div>
            </div>
            );
        } else if (detailOpen) {
            return (
            <div>
                <ItemsNavigation
                    title="Divisions"
                    icon={<FcTreeStructure/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={divisionChoice}
                    onClickBack={()=>{
                        this.setState({divisionChoice: {}, detailOpen: false, editOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({divisionChoice: {}, editOpen: true});
                    }} />
                <DivisionDetail 
                    division={divisionChoice}
                    seasonId={seasonId}
                    organization={organization}
                    teams={teams}
                    trophies={trophies}
                    locations={locations}
                    profileData={profileData}
                    seasons={seasons}
                    wallets={wallets}
                    createMode={Object.values(divisions).length === 0} 
                    uid={user.uid}
                    user={user}
                    users={users}
                    cards={cards}
                    onPressReferee={() => {
                        this.props.router.navigate('/organizer/register/referee')
                    }}
                    onChanged={(season)=> {
                        if (season == null) {
                            this.loadContents()
                        } else {
                            for (const [key, value] of Object.entries(season)) {
                                seasons[season.id][key] = value;
                            }
                            this.setState({seasons: seasons});
                        }
                    }}
                    onDivisionChanged={(division)=> {
                        if (division == null) {
                            this.loadContents()
                        } else {
                            for (const [key, value] of Object.entries(division)) {
                                divisions[division.id][key] = value;
                            }
                            this.setState({division: division, divisionChoice: division});
                        }
                    }}
                    onSaveDone={()=> {
                        this.loadContents()
                    }} 
                    onClickEdit={()=> {
                        this.setState({
                            editOpen: true,
                            divisionChoice: divisionChoice})
                    }}
                    onUserUpdate={(update, team)=> {
                        if (update) {
                            users[update.uid] = update;
                        }
                        if (team) {
                            teams[team.id] = team;
                        }
                    }} />
            </div>
            );
        } else {
            let items = [];
            for (const [key, value] of Object.entries(divisions)) {
                items.push(
                    <Col xl={4} lg={6} xs={12} key={key} className='grid-item-wrapper'>
                        <DivisionBadge 
                            key={key}
                            division={value}
                            seasons={seasons}
                            uid={user.uid}
                            profileData={profileData}
                            allowDetail={true}
                            onClickDetails={()=> {
                                this.setState(
                                {
                                    detailOpen: true,
                                    divisionChoice: value
                                })
                                replaceUrl(getDivisionDomain(organization, value.id));
                            }}
                            onClickEdit={()=> this.setState(
                                {
                                    editOpen: true,
                                    divisionChoice: value
                                })}
                            onSaveDone={()=> {
                                this.loadContents()
                            }} />
                    </Col>
                );
            }
            return (
            <div>
                <ItemsNavigation
                    title="Divisions"
                    icon={<FcTreeStructure/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={divisionChoice}
                    onClickBack={()=>{
                        this.setState({divisionChoice: {}, detailOpen: false, editOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({divisionChoice: {}, editOpen: true});
                    }} />
                <Row> 
                {items}                   
                </Row>
            </div>
            );
        }
    }

    render() {
        const {organization, loading} = this.state;
        
        const theme = organization.landingData && organization.landingData.theme ? organization.landingData.theme : null;
        
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        
        return (
            <div>
                {theme !== 'samba' && this.renderOrganizationHeader()}
                <div style={{paddingTop: 20, backgroundColor: 'white'}}>
                    {this.renderContents()}
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgManager));
export { connectedPage as OrgManager };
