import React, { Component } from 'react';
import InfoBadgeFixed from 'components/detail/InfoBadgeFixed';
import { Button, Col, Row, CardImg, Modal, ModalBody, Spinner, Input, FormGroup } from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import { TeamSeasons } from 'components/landing/team/TeamSeasons';
import Pagination from 'react-responsive-pagination';
import ProfileEdit from 'components/edit/ProfileEdit';

import { 
    FcConferenceCall,
    FcManager,
    FcBusinessman,
    FcVideoFile,
} from 'react-icons/fc';
import { 
    GiWalkingBoot
} from 'react-icons/gi';
import { 
    FaUsers
} from 'react-icons/fa';
import { 
    RiMoneyDollarBoxFill
} from 'react-icons/ri';
import { loadStripe } from '@stripe/stripe-js';
import { 
    getOrgId,
    STRIPE_PUBLISHABLE_KEY,
    toDateTimeString
} from 'utils/Utils';

import { 
    GrArticle,
    GrTrophy
} from 'react-icons/gr';

import userIcon from 'assets/img/icons/player_photo_default.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';
import videoIcon from 'assets/img/icons/video_default.png';
import Countdown from 'react-countdown';
import OrganizationPlayerCard from 'components/detail/organization/landing/OrganizationPlayerCard';
import OrganizationTeamStandings from 'components/detail/organization/landing/OrganizationTeamStandings';
import OrganizationTeamStatistics from 'components/detail/organization/landing/OrganizationTeamStatistics';
import ClubBattleLandingView from 'components/detail/club/ClubBattleLandingView';
import ClubFieldLandingView from 'components/detail/club/ClubFieldLandingView';
import ClubPlanDetailsView from 'components/detail/club/ClubPlanDetailsView';
import ClubScheduleHeader from 'components/detail/club/ClubScheduleHeader';
import ClubScheduleDetailView from 'components/detail/club/ClubScheduleDetailView';
import ClubMessageRoom from 'components/chat/ClubMessageRoom';
import ClubChatView from 'components/chat/ClubChatView';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import PostHeader from 'components/detail/community/PostHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import {PostEdit} from 'components/edit/community/PostEdit';

import { MdCalendarToday } from 'react-icons/md';
import { withRouter } from 'utils/Routher';
import { generateId, invokeHttpsApi } from 'utils/API';
import CustomTabView from 'components/template/CustomTabView';

class TeamAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            mainTab: 0,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            gamePast: false,
            schedulePast: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            familyOpened: false,
            detailMode: 'list',
            page: 1,
            upcomingPage: 1,
            balanceInput: 0,
            balanceType: 'direct'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        const seasonId = this.props.router.params ? this.props.router.params.seasonId : null;
        if (seasonId) {
            this.setState({mainTab: 1})
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};
            const clubSchedules = {};
            const wallets = {};
            const walletPayments = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallets)) {
                    wallets[key] = value;
                }
                for (const [key, value] of Object.entries(data.walletPayments)) {
                    walletPayments[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
                clubSchedules: clubSchedules,
                wallets: wallets,
                walletPayments: walletPayments,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderFields() {
        const { user } = this.props;
        const { team, categorys, fields, tracks, trackSubmissions, detailField } = this.state;
        
        const itemsArr = Object.values(fields);
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : videoIcon;
            const countdown = item.active && item.deadline > Date.now() ? item.deadline : Date.now();
            const subView = (
                <div className="info-col one-line-div">
                    <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                        {countdown > 0 ? "Ends In" : "Finished"}
                    </h4>
                    {countdown > 0 && (
                    <Countdown
                        date={countdown} />
                    )}
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcBusinessman /> {item.members != null ? Object.values(item.members).length : 0 }
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <GiWalkingBoot /> {item.trackIds != null ? Object.values(item.trackIds).length : 0 }
                    </div>
                </div>
            )
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            detailField: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/3";

        if (detailField) {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('cardio_set')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                <Button className='btn-cancel' onClick={(e)=> {
                                    this.setState({detailField: null})
                                }}>
                                {t('close')}
                                </Button>
                            </div>
                        </div>
                        <ClubFieldLandingView
                            categorys={categorys}
                            user={user}
                            clubField={detailField}
                            clubTracks={tracks}
                            submissions={trackSubmissions} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('cardio_set')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                {user && team.owner === user.uid && (
                                <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                {t('manage_contents')}
                                </a>
                                )}
                            </div>
                        </div>
                    {items.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    ) : (
                        <div>
                        {items}
                        </div>
                    )}
                    </div>
                </div>
            )
        }
    }

    renderBattles() {
        const { user } = this.props;
        const { team, battles, challenges, submissions, detailBattle } = this.state;
        
        const itemsArr = Object.values(battles);
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : videoIcon;
            const countdown = item.active && item.deadline > Date.now() ? item.deadline : Date.now();

            const subView = (
                <div className="info-col one-line-div">
                    <h4 style={{ display: 'inline-block', fontSize: 10, marginRight: 10 }}>
                        {countdown > 0 ? "Ends In" : "Finished"}
                    </h4>
                    {countdown > 0 && (
                    <Countdown
                        date={countdown} />
                    )}
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcBusinessman /> {item.members != null ? Object.values(item.members).length : 0 }
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcVideoFile /> {item.challengeIds != null ? Object.values(item.challengeIds).length : 0 }
                    </div>
                </div>
            )
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            detailBattle: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/1";

        if (detailBattle) {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('competitions')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                <Button className='btn-cancel' onClick={(e)=> {
                                    this.setState({detailBattle: null})
                                }}>
                                {t('close')}
                                </Button>
                            </div>
                        </div>
                        <ClubBattleLandingView
                            user={user}
                            clubBattle={detailBattle}
                            clubChallenges={challenges}
                            submissions={submissions} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('competitions')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                {user && team.owner === user.uid && (
                                <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                {t('manage_contents')}
                                </a>
                                )}
                            </div>
                        </div>
                    {items.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    ) : (
                        <div>
                        {items}
                        </div>
                    )}
                    </div>
                </div>
            )
        }
    }

    renderWorkouts() {
        const { user } = this.props;
        const { team, plans, workouts, detailWorkout } = this.state;
        
        const itemsArr = Object.values(plans);
        itemsArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        const items = [];

        for (const item of itemsArr) {
            const title = item.title;
            const icon = item.thumbUri != null ? item.thumbUri : videoIcon;
            
            const subView = (
                <div className="info-col one-line-div">
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcBusinessman /> {item.members != null ? Object.values(item.members).length : 0 }
                    </div>
                    <div style={{ display: 'inline-block', marginLeft: 10, marginRight: 10 }}>
                        <FcVideoFile /> {item.workoutIds != null ? Object.values(item.workoutIds).length : 0 }
                    </div>
                </div>
            )
            items.push((
                <InfoBadgeFixed 
                    key={item.id}
                    title={title}
                    icon={icon}
                    subView={subView}
                    onClickDetails={() => {
                        this.setState({
                            detailWorkout: item,
                        })
                    }} />
            ))
        }
        const caochLink = "/coach/clubs/" + team.id + "/2";

        if (detailWorkout) {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('workouts')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                <Button className='btn-cancel' onClick={(e)=> {
                                    this.setState({detailWorkout: null})
                                }}>
                                {t('close')}
                                </Button>
                            </div>
                        </div>
                        <ClubPlanDetailsView
                            user={user}
                            clubPlan={detailWorkout}
                            clubWorkouts={workouts} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('workouts')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                {user && team.owner === user.uid && (
                                <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                {t('manage_contents')}
                                </a>
                                )}
                            </div>
                        </div>
                    {items.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_items_to_show')}
                        </div>
                    ) : (
                        <div>
                        {items}
                        </div>
                    )}
                    </div>
                </div>
            )
        }
    }

    renderGames() {
        const { organizations, divisions, seasons, teams, users, locations, gameChoice, cards, posts, gamePast} = this.state;
        
        if (gameChoice) {
            const organization = gameChoice.organizationId ? organizations[gameChoice.organizationId] : {};
            return (
                <div className='container'>
                    <div className='org-landing-contents-bg-blue'>
                        <h2 className="org-landing-contents-subtitle">
                        {t('games')}
                        </h2>
                        <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                            {/* <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                this.setState({gameChoice: null})
                            }}>Back To Games List</Button> */}
                            <div style={{height: 25}} />
                            <GameDetails 
                                game={gameChoice}
                                organization={organization}
                                locations={locations}
                                divisions={divisions}
                                seasons={seasons}
                                cards={cards}
                                teams={teams}
                                posts={posts}
                                users={users} 
                                onChanged={()=> {
                                    this.loadContents();
                                }}
                                onClickClose={()=>{
                                    this.setState({gameChoice: null});
                                }} />
                            <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                            </div>
                            <div style={{height: 25}} />
                    
                        </div>
                    </div>
                </div>
            )
        }
        
        return (
            <div className='account-table'>
                <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                    <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                        <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                        {t('games')}
                        </h4>
                        <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                            <Input type="select" name="select" value={gamePast} id="challengeChoice" onChange={e=>{
                                    const update = e.target.value === true || e.target.value === "true";
                                    this.setState({gamePast: update});
                                }}>
                                <option value={false}>{t('upcoming_games')}</option>
                                <option value={true}>{t('past_games')}</option>
                            </Input>
                        </FormGroup>
                    </div>
                    
                    {gamePast ? this.renderPastGames() : this.renderUpcomingGames()}
                </div>
            </div>
        )
    }

    renderPastGames() {
        const { team, organizations, seasons, teams, users, locations, locationChoice, page } = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            seasonsChoice[key] = value;
        }

        let games = [];

        const now = new Date().valueOf();
        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if ((game.homeTeam === team.id || game.awayTeam === team.id) && game.start < now) {
                                if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                    games.push(game)
                                }
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 10);
        
        for (const[, value] of Object.entries(games)) {
            result.push(value);
        }

        result.sort(function(a, b) {
            let t1 = a.start;
            let t2 = b.start;

            return t2 - t1;
        })
        let idx = (page - 1) * 10;
        for (let i = 0; i < 10; i ++) {
            if (result.length > idx) {
                const value = result[idx ++];
                const organization = value && value.organizationId ? organizations[value.organizationId] : {};
                list.push(
                    <div key={`${value.id}`}>
                        <OrgGameBadge
                            game={value}
                            teams={teams}
                            organization={organization}
                            wideMode={false}
                            locations={locations}
                            users={users}
                            detailMode={true}
                            onClickDetail={e=>{
                                this.setState({gameChoice: value})
                            }} />
                    </div>
                );
                list.push(<div className='divider' />)
            }
        }
        
        return (
            <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                    <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                            const id = e.target.value;
                            this.setState({locationChoice: id == null ? {} : locations[id]});
                        }}>
                        <option value={null}></option>
                        {Object.values(locations).map((location)=> (
                            <option value={location.id}>{location.title}</option>
                        )) }
                    </Input>
                </FormGroup>
                <div style={{height: 25}} />
                {list.length === 0 ? (
                    <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                    {t('no_items_to_show')}
                    </div>
                ) : (
                    <div style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                        {list}
                    </div>
                )}
                <div style={{height: 25}} />
                <Pagination
                    current={page}
                    total={pageCount}
                    onPageChange={(update) => {
                        this.setState({page: update})
                    }} />
                <div style={{height: 25}} />
            </div>
        )
    }

    renderUpcomingGames() {
        const { team, organizations, seasons, teams, users, locations, locationChoice, upcomingPage } = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            seasonsChoice[key] = value;
        }

        let games = [];

        const now = new Date().valueOf();
        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if ((game.homeTeam === team.id || game.awayTeam === team.id) && game.start < now) {
                                if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                    games.push(game)
                                }
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 10);
        
        for (const[, value] of Object.entries(games)) {
            result.push(value);
        }

        result.sort(function(a, b) {
            let t1 = a.start;
            let t2 = b.start;

            return t1 - t2;
        })
        let idx = (upcomingPage - 1) * 10;
        for (let i = 0; i < 10; i ++) {
            if (result.length > idx) {
                const value = result[idx ++];
                const organization = value && value.organizationId ? organizations[value.organizationId] : {};
                list.push(
                    <div key={`${value.id}`}>
                        <OrgGameBadge
                            game={value}
                            teams={teams}
                            organization={organization}
                            wideMode={false}
                            locations={locations}
                            users={users}
                            detailMode={true}
                            onClickDetail={e=>{
                                this.setState({gameChoice: value})
                            }} />
                    </div>
                );
                list.push(<div className='divider' />)
            }
        }
        
        return (
            <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                    <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                            const id = e.target.value;
                            this.setState({locationChoice: id == null ? {} : locations[id]});
                        }}>
                        <option value={null}></option>
                        {Object.values(locations).map((location)=> (
                            <option value={location.id}>{location.title}</option>
                        )) }
                    </Input>
                </FormGroup>
                <div style={{height: 25}} />
                {list.length === 0 ? (
                    <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                    {t('no_items_to_show')}
                    </div>
                ) : (
                    <div style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                        {list}
                    </div>
                )}
                <div style={{height: 25}} />
                <Pagination
                    current={upcomingPage}
                    total={pageCount}
                    onPageChange={(update) => {
                        this.setState({upcomingPage: update})
                    }} />
                <div style={{height: 25}} />
            </div>
        )
    }

    renderSchedules() {
        const { user } = this.props;
        const { team, users, schedulePast, detailSchdedule } = this.state;
        
        const caochLink = "/coach/clubs/" + team.id + "/5";

        if (detailSchdedule) {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('schedules')}
                            </h4>
                            <div className="wrap-content-wrap-child" style={{marginRight: 0}}>
                                <Button className='btn-cancel' onClick={(e)=> {
                                    this.setState({detailSchdedule: null})
                                }}>
                                {t('close')}
                                </Button>
                            </div>
                        </div>
                        <ClubScheduleDetailView 
                            uid={user ? user.uid : null}
                            club={team}
                            clubSchedule={detailSchdedule}
                            users={users} />
                    </div>
                </div>
            )
        } else {
            return (
                <div className='account-table'>
                    <div className='shadow-tile-fixed' style={{backgroundColor:  'white'}}>
                        <div className='wrap-content-parent' style={{padding: '4px 10px'}}>
                            <h4 className='wrap-content-fill-child one-line-title' style={{margin: 'auto 0px'}}>
                            {t('schedules')}
                            </h4>
                            <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                                <Input type="select" name="select" value={schedulePast} id="challengeChoice" onChange={e=>{
                                        const update = e.target.value === true || e.target.value === "true";
                                        this.setState({schedulePast: update});
                                    }}>
                                    <option value={false}>{t('upcoming_schedules')}</option>
                                    <option value={true}>{t('past_schedules')}</option>
                                </Input>
                            </FormGroup>
                            <div className="wrap-content-wrap-child" style={{margin: '5px 10px'}}>
                                <a className='btn-cancel btn btn-secondary' href={caochLink} target="_blank" rel="noopener noreferrer">
                                {t('manage_contents')}
                                </a>
                            </div>
                        </div>
                    {schedulePast ? this.renderPastSchedules() : this.renderUpcomingSchedules()}
                    </div>
                </div>
            )
        }
    }

    renderPastSchedules() {
        const { user } = this.props;
        const { team, clubSchedules } = this.state;
        
        const itemsArr = [];
        const now = new Date().valueOf();
        if (clubSchedules) {
            for (const item of Object.values(clubSchedules)) {
                if (now > item.timestamp && team.id === item.clubId) {itemsArr.push(item)};
            }
        }
        itemsArr.sort((a,b)=>{
            return b.timestamp - a.timestamp;
        })

        const items = [];

        for (const item of itemsArr) {            
            items.push((
                <ClubScheduleHeader 
                    key={item.id}
                    uid={user ? user.uid : null}
                    club={team}
                    schedule={item}
                    onClickDetail={() => {
                        this.setState({
                            detailSchdedule: item,
                        })
                    }} />
            ))
        }

        if (items.length === 0) {
            return (
                <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                {t('no_items_to_show')}
                </div>
            )
        }
        return (
            <div>
            {items}
            </div>
        )
    }

    renderUpcomingSchedules() {
        const { user } = this.props;
        const { team, clubSchedules } = this.state;
        
        const itemsArr = [];
        const now = new Date().valueOf();
        if (clubSchedules) {
            for (const item of Object.values(clubSchedules)) {
                if (now < item.timestamp && team.id === item.clubId) {itemsArr.push(item)};
            }
        }
        itemsArr.sort((a,b)=>{
            return a.timestamp - b.timestamp;
        })

        const items = [];

        for (const item of itemsArr) {            
            items.push((
                <ClubScheduleHeader 
                    key={item.id}
                    uid={user ? user.uid : null}
                    club={team}
                    schedule={item}
                    onClickDetail={() => {
                        this.setState({
                            detailSchdedule: item,
                        })
                    }} />
            ))
        }

        if (items.length === 0) {
            return (
                <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                {t('no_items_to_show')}
                </div>
            )
        }
        return (
            <div>
            {items}
            </div>
        )
    }

    renderGeneral() {
        const { user } = this.props;
        const { team, teams, users, clubSchedules, seasons, organizations, locations } = this.state;
        
        const itemsArr = Object.values(clubSchedules);
        itemsArr.sort((a,b)=>{
            return b.timestamp - a.timestamp;
        })
        
        let games = [];

        for (const [, value] of Object.entries(seasons)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if (game.homeTeam === team.id || game.awayTeam === team.id) {
                                games.push(game)
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })

        let upSchedule = null;
        let now = new Date().valueOf();
        for (const item of itemsArr) {
            if (item.timestamp && item.timestamp > now) {
                upSchedule = item;
            } else if (item.timestamp && item.timestamp < now) {
                break;
            }
        }
        let upGame = null;
        for (const item of games) {
            if (item.start && item.start > now) {
                upGame = item;
            } else if (item.start && item.start < now) {
                break;
            }
        }
        const organization = upGame && upGame.organizationId ? organizations[upGame.organizationId] : {};
        

        return (
            <Row style={{margin: 0}}>
                <Col sm={8} xs={12} style={{padding: 0}}>
                    {this.renderMembers()}
                </Col>
                <Col sm={4} xs={12} style={{padding: 0}}>
                    <div className='shadow-tile-normal' style={{marginBottom: 20}}>
                        <Row style={{backgroundColor: 'white'}}>
                            <Col xs={12}>
                                <h3 className='stat-table-title'>
                                    <MdCalendarToday/> {t('upcoming_schedule')}
                                </h3>
                            </Col>
                        </Row>
                        {upSchedule ? (
                            <ClubScheduleHeader 
                                key={upSchedule.id}
                                uid={user ? user.uid : null}
                                club={team}
                                schedule={upSchedule}
                                onClickDetail={() => {
                                    this.setState({
                                        detailSchdedule: upSchedule,
                                        mainTab: 4
                                    })
                                }} />
                            ) : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                    <div className='shadow-tile-normal' style={{marginBottom: 20}}>
                        <Row style={{backgroundColor: 'white'}}>
                            <Col xs={12}>
                                <h3 className='stat-table-title'>
                                    <MdCalendarToday/> {t('upcoming_game')}
                                </h3>
                            </Col>
                        </Row>
                        {upGame ? (
                            <OrgGameBadge
                                game={upGame}
                                teams={teams}
                                organization={organization}
                                wideMode={false}
                                locations={locations}
                                users={users}
                                detailMode={true}
                                onClickDetail={e=>{
                                    this.setState({gameChoice: upGame, mainTab: 4})
                                }} />
                            ) : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                    {this.renderTrophy()}
                    {this.renderPosts()}
                    <div style={{display: 'none'}}>
                        <ClubMessageRoom
                            uid={user.uid}
                            user={user}
                            users={users}
                            club={team} />
                    </div>
                </Col>
            </Row>
        )
    }

    renderSeasons() {
        const { user } = this.props;
        const {organizations, seasons, divisions, profileData, teams, cards, locations, seasonChoice, category, team, users} = this.state;
        const seasonId = this.props.router.params ? this.props.router.params.seasonId : null;

        return (
            <TeamSeasons
                uid={user ? user.uid : null}
                user={user}
                playerMode={true}
                team={team}
                organizations={organizations}
                seasons={seasons}
                seasonChoice={seasonChoice}
                divisions={divisions}
                profileData={profileData}
                teams={teams}
                users={users}
                category={category}
                cards={cards}
                seasonId={seasonId}
                locations={locations} />
        )
    }

    renderStanding() {
        const {organizations, seasons, divisions, team, teams, users, cards, posts, locations} = this.state;

        return (
            <div style={{marginTop: 20}}>
                
                <OrganizationTeamStandings
                    organizations={organizations}
                    divisions={divisions}
                    seasons={seasons}
                    team={team}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations} />
            </div>
        );
    }

    renderStatistics() {
        const {organizations, seasons, divisions, team, teams, users, cards, posts, locations} = this.state;

        return (
            <div style={{marginTop: 20}}>
                <OrganizationTeamStatistics
                    organizations={organizations}
                    divisions={divisions}
                    seasons={seasons}
                    team={team}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations} />
            </div>
        );
    }

    renderPlayers() {
        const {team, users, clubSchedules, battles, fields, plans} = this.state;
        
        let topScore = [];
        for (const val of Object.values(team.playerIds ? team.playerIds : {})) {
            if (users[val]) {

                let item = users[val];
                item.statistics = {
                    uid: val,
                    schedules: 0,
                    battles: 0,
                    fields: 0,
                    plans: 0,
                    training: 0,
                }
                
                for (const item of Object.values(clubSchedules)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.schedules += 1;
                        item.training += 1;
                    }
                }
                
                for (const item of Object.values(battles)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.battles += 1;
                        item.training += 1;
                    }
                }
                
                for (const item of Object.values(fields)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.fields += 1;
                        item.training += 1;
                    }
                }
                
                for (const item of Object.values(plans)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.plans += 1;
                        item.training += 1;
                    }
                }

                topScore.push(item);
            }
        }
        topScore.sort((a,b)=> {
            return b.schedules - a.schedules
        });
        
        let items = [];
        
        for (const item of topScore) {
            
            let thumbImage = item && item.photoUri != null ? item.photoUri : userIcon;
            items.push(
                <Row onClick={e=>{this.setState({playerChoice: item})}}>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {item.username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.schedules}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.training}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        return (
            <div style={{marginTop: 20, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: 'white'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {t('players')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('player')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('schedules')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('training')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCoaches() {
        const {team, users, clubSchedules, battles, fields, plans} = this.state;
        
        let topScore = [];
        for (const val of Object.values(team.managers ? team.managers : {})) {
            if (users[val]) {

                let item = users[val];
                item.statistics = {
                    uid: val,
                    schedules: 0,
                    training: 0,
                    battles: 0,
                    fields: 0,
                    plans: 0,
                }
                
                for (const item of Object.values(clubSchedules)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.schedules += 1;
                    }
                }
                
                for (const item of Object.values(battles)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.training += 1;
                        item.battles += 1;
                    }
                }
                
                for (const item of Object.values(fields)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.training += 1;
                        item.fields += 1;
                    }
                }
                
                for (const item of Object.values(plans)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.training += 1;
                        item.plans += 1;
                    }
                }

                topScore.push(item);
            }
        }
        topScore.sort((a,b)=> {
            return b.schedules - a.schedules
        });
        
        let items = [];
        
        for (const item of topScore) {
            
            let thumbImage = item && item.photoUri != null ? item.photoUri : userIcon;
            items.push(
                <Row onClick={e=>{this.setState({coachChoice: item})}}>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {item.username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.schedules}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.training}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: 'white'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {t('coaches')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('coach')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('schedules')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('training')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPlayerCard() {
        const {team, playerChoice, organization, organizations, seasons, divisions, posts, users, teams, cards, locations} = this.state;
        
        return (
            <OrganizationPlayerCard
                team={team}
                organization={organization}
                organizations={organizations}
                divisions={divisions}
                posts={posts}
                users={users}
                teams={teams}
                cards={cards}
                seasons={seasons}
                locations={locations}
                player={playerChoice} 
                onClickClose={() => {
                    this.setState({playerChoice: null})
                }} />
        )
    }

    renderMembers() {
        const {playerChoice} = this.state;

        if (playerChoice) {

            return (
                <div>
                    {this.renderPlayerCard()}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderPlayers()}
                    {this.renderCoaches()}
                </div>
            )
        }
    }

    renderTrophy() {
        let items = [];
        
        return (
            <div style={{marginTop: 20}}>
                <div className='shadow-tile' style={{marginBottom: 20}}>
                    <Row style={{backgroundColor: 'white'}}>
                        <Col xs={12}>
                            <h3 className='stat-table-title'>
                                <GrTrophy/> {t('trophy')}
                            </h3>
                        </Col>
                    </Row>
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPosts() {
        const { team, teams, posts, users, user, detailChoice, editOpen, detailOpen, searchStr} = this.state;
        
        const result = [];
        const list = [];

        const uid = user ? user.uid : "";
        const editable = team.owner === uid || (team.managers && Object.values(team.managers).includes(uid))
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.teamId === team.id) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })
        
        for (const value of result) {
            list.push(
                <PostHeader 
                    key={value.id}
                    post={value}
                    users={users}
                    user={user}
                    onClickEdit={uid === value.uid ? ()=>{
                        this.setState({
                            detailChoice: value,
                            detailOpen: false,
                            editOpen: true
                        })
                    } : null}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: value,
                            detailOpen: true
                        })
                    }} />
            );
        }

        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('news_feed')}
                        icon={<GrArticle/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={list.length}
                        detailItem={detailChoice}
                        onClickAdd={editable ? ()=>{
                            this.setState({detailChoice: {}, editOpen: true, detailOpen: false});
                        } : null}
                        onClickBack={()=>{
                            this.setState({detailChoice: {}, editOpen: false, detailOpen: false});
                        }} />
                </div>

                <div style={{margin: '0px 10px'}}>
                {!editOpen && !detailOpen && (
                <div>
                    {list}
                </div>
                )}

                {detailOpen && detailChoice.id != null && (
                    <PostDetailView 
                        post={detailChoice} 
                        user={user}
                        users={users}
                        teams={teams}
                        isOpen={detailOpen}
                        onCloseClick={() => {
                            this.setState({detailOpen: false})
                        }} />
                )}
                {editOpen && (
                    <PostEdit 
                        uid={user.uid}
                        team={team}
                        teams={teams}
                        post={detailChoice} 
                        isOpen={editOpen}
                        onChanged={() => {
                            this.loadContents()
                        }} 
                        onCloseClick={() => {
                            this.setState({editOpen: false})
                        }} />
                )}
                </div>
            </div>
        );
    }

    renderFamilyChoice() {
        const {user, child} = this.props;
        const {users, familyOpened} = this.state;
        if (!user || !user.uid) {
            return <div/>
        }

        const childArr = [];

        for (const item of Object.values(users)) {
            if (item.parentId === user.uid) {
                childArr.push(item);
            }
        }
        childArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        const items = [];
        if (user) {
            items.push(<div>{this.renderFamilyItem(user, true)}</div>)
        }
        for (const item of childArr) {
            items.push(<div>{this.renderFamilyItem(item, true)}</div>)
        }

        const choice = child ? child : user;

        if (!familyOpened) {
            const userThumb = choice.photoUri ? choice.photoUri : userIcon;
            return (
                <div className='wrap-content-parent' onClick={e=> {
                    this.setState({familyOpened: true});
                }}>
                    <CardImg
                        style={{width: 24, height: 24, marginLeft: 10, marginRight: 10}}
                        src={userThumb} />
                    <h3 style={{fontSize: 22, marginBottom: 0, color: 'white'}}>
                    {choice.username}
                    </h3>
                </div>
            )
        } else {
            return (
                <div style={{position: 'absolute', zIndex: 9999999999999, top: 20, left: 30}}>
                    <div className='shadow-tile-table'>
                        <div className='wrap-content-parent' style={{background: 'white', padding: '5px 10px'}}>
                            <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                                <div className='items-navigation-count'><FaUsers/></div>
                                <h3 className='items-navigation-title'>
                                My Family
                                </h3>
                                <div className='items-navigation-count'>| {items.length} Children</div>
                            </div>
                            <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                this.setState({familyOpened: false});
                            }}>{t('close')}</Button>
                        </div>
                        <div className='divider' />
                        <div className='divider' />
                        
                        {items.length > 0 ? (
                            <div style={{backgroundColor: 'white'}}>{items}</div>
                        ) : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Children Added
                            </div>
                        )}
                    </div>
                </div>
            )
        }
    }

    renderFamily() {
        const {user, child} = this.props;
        const {team, organization, organizations, seasons, divisions, posts, users, userEdit, teams, cards, locations} = this.state;
        if (!user || !user.uid) {
            return <div/>
        }

        const childArr = [];

        for (const item of Object.values(users)) {
            if (item.parentId === user.uid) {
                childArr.push(item);
            }
        }
        childArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        const items = [];
        if (user) {
            items.push(<div>{this.renderFamilyItem(user)}</div>)
            items.push(<div className='divider' />)
        }
        for (const item of childArr) {
            items.push(<div>{this.renderFamilyItem(item)}</div>)
            items.push(<div className='divider' />)
        }

        return (
            <div>
                <Row style={{margin: 0}}>
                    <Col lg={8} xs={12}>
                        <OrganizationPlayerCard
                            team={team}
                            organization={organization}
                            organizations={organizations}
                            divisions={divisions}
                            posts={posts}
                            users={users}
                            teams={teams}
                            cards={cards}
                            seasons={seasons}
                            locations={locations}
                            hideClose={true}
                            player={child ? child : user} />
                    </Col>
                    <Col lg={4} xs={12}>
                        <div className='shadow-tile-table'>
                            <div className='wrap-content-parent' style={{background: 'white', padding: '5px 10px'}}>
                                <div className='detail-nav-btn wrap-content-fill-child vertical-center-spacing'>
                                    <div className='items-navigation-count'><FaUsers/></div>
                                    <h3 className='items-navigation-title'>
                                    My Family
                                    </h3>
                                    <div className='items-navigation-count'>| {items.length} Children</div>
                                </div>
                                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                    const child = {};
                                    child.uid = generateId("/user");
                                    child.parentId = user.uid;
                                    this.setState({userEdit: child});
                                }}>{t('create')}</Button>
                            </div>
                            <div className='divider' />
                            <div className='divider' />
                            
                            {items.length > 0 ? items : (
                                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                No Children Added
                                </div>
                            )}
                        </div>
                    </Col>
                </Row>
                {userEdit && (
                <Modal isOpen = {userEdit}>
                    <ModalBody>
                        <ProfileEdit 
                            user={user}
                            profile={userEdit}
                            onSaveSuccess={() => {
                                this.setState({userEdit: null})
                            }}
                            onCloseClick={() => {
                                this.setState({userEdit: null})
                            }} />
                    </ModalBody>
                </Modal>
                )}
            </div>
        )
    }

    renderFamilyItem(item, small) {
        const { user, child } = this.props;
        const userThumb = item.photoUri ? item.photoUri : userIcon;
        const userId = child ? child.uid : (user ? user.uid : null)

        if (small) {
            return (
                <TableRowSmall 
                    key={item.uid}
                    title={item.username}
                    icon={userThumb}
                    onClickDetails={e=> {
                        if (this.props.setChild) {
                            this.props.setChild(item.uid === user.uid ? null : item);
                        }
                    }}
                    detailLabel={item.uid === userId ? t('picked') : t('pick')} />
            );
        } else {
            return (
                <TableRowSmall 
                    key={item.uid}
                    title={item.username}
                    icon={userThumb}
                    onClickDetails={e=> {
                        if (this.props.setChild) {
                            this.props.setChild(item.uid === user.uid ? null : item);
                        }
                    }}
                    detailLabel={item.uid === userId ? t('picked') : t('pick')}
                    onClickEdit={e=> {
                        this.setState({userEdit: item});
                    }} />
            );
        }
    }

    submitPayment(organization) {
        const {user} = this.props;
        const {wallet, balanceInput, balanceType} = this.state;
        if (!balanceInput || balanceInput <= 0) {
            alert("You should input valid balance amount");
            return;
        }

        if (!wallet.id) {
            wallet.id = generateId("/wallet");;
            wallet.organizationId = organization.id;
            wallet.uid = user.uid;
            wallet.balance = 0;
        }

        const data = {
            wallet: wallet,
            uid: user.uid,
            organizationId: organization.id,
            balance: balanceInput,
            type: balanceType,
            returnURL: window.location.href
        }

        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-submitWalletTransaction', data, async (data) => {
            if (data.success && data.session) {
                const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

                stripe.redirectToCheckout({ sessionId: data.session.id });

                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else if (data.success) {
                this.loadContents();
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    renderTransactionItem(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.price) / 100);
        const subtitle = "+";

        let msg = "Waiting";
        if (item.staffId && users[item.staffId]) {
            msg = users[item.staffId].username;
        } else if (!item.success && item.type !== 'direct') {
            msg = "Pending";
        } else if (item.success && item.type === 'direct') {
            msg = "Paid";
        }

        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#38be55'}}>
                    {subtitle} {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'direct' ? "Online" : "In Person"}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {msg}
                    </h4>
                </Col>
            </Row>
        );
    }

    renderOutcomeTransactionItem(item) {
        const price = "$" + (parseFloat(item.price) / 100);
        
        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#EE000F'}}>
                    {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'wallet' ? "Wallent Payment" : "Unknown"}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.title}
                    </h4>
                </Col>
            </Row>
        );
    }

    renderTransactions() {
        const {loading, balanceInput, balanceType, checkoutProgress, wallets, organizations} = this.state;

        let itemArr = [];
        if (wallets && organizations) {
            for (const wallet of Object.values(wallets)) {
                let balance = parseFloat(wallet.balance ? wallet.balance : 0) / 100;
                let organization = organizations[wallet.organizationId]

                if (!organization) {
                    continue;
                }
                itemArr.push(
                    <div>
                        <div className="wrap-content-parent" style={{marginTop: 10}}>
                            <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24, marginTop: 12, marginRight: 5}}>{organization.title} - Balance:</h1>
                            {loading ? (
                            <Spinner color="primary" />
                            ) : (
                            <h2 className="wrap-content-fill-child" style={{color: "black", fontSize: 36}}>{balance > 0 ? ("$" + balance) : ("-$" + Math.abs(balance))}</h2>
                            )}
                        </div>
                        <div className="wallet-title-bar" style={{marginTop: 10}}>
                            <Input type="number" name="money" id="money" className="wrap-content-fill-child" onChange={e => {
                                this.setState({balanceInput: e.target.value});
                            }} value={balanceInput} />
                            <Input type="select" name="type" id="type" className="wrap-content-fill-child" onChange={e => {
                                this.setState({balanceType: e.target.value});
                            }} value={balanceType}>
                                <option value="direct">Direct</option>
                                <option value="cash">Pay In Person</option>
                            </Input>
                            {checkoutProgress ? (
                                <Spinner color="grow" />
                            ) : (
                                <Button className="btn-submit wrap-content-wrap-child" style={{marginRight: 0}} onClick={e=> {
                                    this.submitPayment(organization);
                                }}>
                                    Submit
                                </Button>
                            )}
                        </div>
                        <div className="wallet-transactions-list">
                            {this.renderWallet(wallet)}
                        </div>
                    </div>
                )
            }
        }

        return (
            <div>
                {itemArr}
            </div>
        )
    }

    renderWallet(wallet) {
        const {walletPayments} = this.state;

        const paymentsArr = Object.values(walletPayments);

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        const pending = [];
        const waiting = [];
        const success = [];
        const outcome = [];
        const transactionsArr = wallet.transactions ? Object.values(wallet.transactions) : [];
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        transactionsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            if (item.success) {
                success.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else if (item.type !== 'direct')  {
                pending.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else {
                waiting.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            }
        }

        for (const item of transactionsArr) {
            if (item.amount < 0) {
                outcome.push(
                    <div>{this.renderOutcomeTransactionItem(item)}</div>
                );
            }
        }

        const items = [];
        for (const item of pending) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of waiting) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of success) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of outcome) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div className='wallet-info-tile'>
                <div className='detail-nav-btn'>
                    <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                    
                    <h3 className='items-navigation-title'>
                    Transactions
                    </h3>
                </div>
                <Row style={{margin: 0}}>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Date
                        </h4>
                    </Col>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Money
                        </h4>
                    </Col>
                    <Col lg={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Type
                        </h4>
                    </Col>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Status/Staff
                        </h4>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Transactions To Show
                    </div>
                )}
            </div>
        )
    }

    getUid() {
        const {user, child} = this.props;

        return child ? child.uid : (user ? user.uid : null);
    }

    render() {
        const {user} = this.props;
        const {team, users, mainTab, loading} = this.state;
        if (loading && !team.id) {
            return (
                <LoadingScreen />
            )
        }
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        
        let icon = team.iconUri != null ? team.iconUri : teamIcon;

        if (!user || 
            ((!team.managers || !Object.values(team.managers).includes(user.uid)) && 
            (!team.playerIds || !Object.values(team.playerIds).includes(this.getUid())) && 
            team.owner !== user.uid)
        ) {
            return (
                <div>
                    {this.renderEmptyTeam('dashboard')}
                </div>
            )
        }

        return (
            <div className='landing-dashboard'>
                <div className='landing-dashboard-header' style={{marginTop: 20, marginBottom: 20}}>
                    <img alt='team' className='home-header-icon' src={icon} />
                    <div className="wrap-content-fill-child">
                        <h1 className="home-header-title-small">
                            {team.title}
                            <span></span>
                        </h1>
                        <div className='wrap-content-parent'>
                            <h3 className="home-header-sub-title" style={{display: 'inline-block'}}>
                                <FcConferenceCall/> {team.playerIds ? Object.values(team.playerIds).length : 0}
                                <FcManager/> {team.managers ? Object.values(team.managers).length : 0}
                            </h3>
                            <div style={{display: 'inline-block'}}>{this.renderFamilyChoice()}</div>
                        </div>
                    </div>
                </div>
                
                <div className='landing-dashboard-contents'>
                    
                    <CustomTabView
                        value={mainTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        onChange={(e, value)=> {
                            this.setState({mainTab: value})
                        }}
                        aria-label="disabled tabs example"
                        items={!team.available ? [
                            { label: t('general') },
                            { label: t('seasons') },
                            { label: t('standing') },
                            { label: t('schedules') },
                            { label: t('profile_settings') },
                        ] : [
                            { label: t('general') },
                            { label: t('seasons') },
                            { label: t('training') },
                            { label: t('standing') },
                            { label: t('schedules') },
                            { label: t('profile_settings') },
                        ]} />
                    <div className='landing-dashboard-contents-wrapper'>
                        {mainTab === 0 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderGeneral()}
                        </div>
                        )}
                        {mainTab === 1 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderSeasons()}
                        </div>
                        )}
                        {team.available && mainTab === 2 && (
                        <Row className='landing-dashboard-contents-scroll' style={{margin: 0}}>
                            <Col lg={4} sm={6} xs={12} style={{padding: 0}}>
                            {this.renderBattles()}
                            </Col>
                            <Col lg={4} sm={6} xs={12} style={{padding: 0}}>
                            {this.renderFields()}
                            </Col>
                            <Col lg={4} sm={6} xs={12} style={{padding: 0}}>
                            {this.renderWorkouts()}
                            </Col>
                        </Row>
                        )}
                        {((!team.available && mainTab === 2) || (team.available && mainTab === 3)) && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderStanding()}
                        </div>
                        )}
                        {((!team.available && mainTab === 3) || (team.available && mainTab === 4)) && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderStatistics()}
                        </div>
                        )}
                        {((!team.available && mainTab === 4) || (team.available && mainTab === 5)) && (
                        <div className='landing-dashboard-contents-scroll'>  
                            <Row style={{margin: 0}}>
                                <Col sm={6} xs={12}>
                                    {this.renderGames()}
                                </Col>
                                <Col sm={6} xs={12}>
                                    {this.renderSchedules()}
                                </Col>
                            </Row>
                        </div>
                        )}
                        {/* {mainTab === 6 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderTransactions()}
                        </div>
                        )} */}
                        {((!team.available && mainTab === 5) || (team.available && mainTab === 6)) && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderFamily()}
                        </div>
                        )}
                        
                    </div>
                </div>
                <ClubChatView
                    uid={user ? user.uid : null}
                    user={user}
                    users={users}
                    club={team} />
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user, child } = state.authentication;
    return { alert, loggingIn, user, child };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamAccount));
export { connectedApp as TeamAccount };