import { filterConstants } from '../_constants';
import { filterService } from '../_services';
import { alertActions } from './';

export const filterActions = {
    updateFilter,
    updateDict,
};

function updateFilter(filter) {
    return dispatch => {
        
        filterService.updateFilter(filter)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failed(null));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(filter) { return { type: filterConstants.UPDATE_FILTER, filter } }
    function failed() { return { type: filterConstants.UPDATE_ERROR } }
}

function updateDict(dict) {
    return dispatch => {
        
        filterService.updateDict(dict)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failed(null));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function success(filter) { return { type: filterConstants.UPDATE_DICT, dict } }
    function failed() { return { type: filterConstants.UPDATE_ERROR } }
}
