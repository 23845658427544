import React, { Component } from "react";
import {
    CardImg, Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import { saveSeason } from "utils/API";

export class SeasonTeamRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {season, team, seasons, onChanged, onClickDetail } = this.props;
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;
        let playerCount = team.playerIds == null ? 0 : Object.values(team.playerIds).length;
        let seasonCount = 0;
        if (seasons) {
            for (const value of Object.values(seasons)) {
                if (value.teamIds != null && Object.values(value.teamIds).includes(team.id)) {
                    seasonCount += 1;
                } 
            }
        }
        const isMember = season.teamIds != null && Object.values(season.teamIds).includes(team.id);
        const isDirectPending = season.directPending != null && Object.values(season.directPending).includes(team.id);
        const isPending = season.pendingIds != null && Object.values(season.pendingIds).includes(team.id);
        const isInvited = season.invitedIds != null && Object.values(season.invitedIds).includes(team.id);
        
        if (season != null) {
            return (
                <Row key={season.id}>
                    <Col lg={5} xs={8} sm={5} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {team.title}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon-small"
                                src={playersIcon} />
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {playerCount}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={2} className="vertical-center">
                        <div className='wrap-content-parent'>
                        {seasons && (
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {seasonCount}
                            </div>
                        )}
                        </div>
                    </Col>
                    <Col lg={4} xs={12} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                {isMember && (
                                    <div className='wrap-content-parent'>
                                    {onClickDetail && (
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        if (onClickDetail != null) {
                                            onClickDetail();
                                        }
                                    }}>{t('view')}</Button>
                                    )}
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        if (season.teamIds && season.teamIds[team.id]) {
                                            delete season.teamIds[team.id];
                                        }
                                        
                                        saveSeason(season, () => {
                                            if (onChanged != null) {
                                                onChanged(season);
                                            }
                                        }, (error) => {
                                            console.log(error);
                                        })
                                    }}>{t('remove')}</Button>
                                    </div>
                                )}
                                {isPending && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            if (season.pendingIds && season.pendingIds[team.id]) {
                                                delete season.pendingIds[team.id];
                                            }
                                            if (!season.teamIds) {
                                                season.teamIds = {};
                                            }
                                            season.teamIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('accept')}</Button>
                                        <Button className="btn-cancel" style={{fontSize: 12}} onClick={e => {
                                            if (season.pendingIds && season.pendingIds[team.id]) {
                                                delete season.pendingIds[team.id];
                                            }
                                            
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('reject')}</Button>
                                    </div>
                                )}
                                {isDirectPending === true && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            if (season.directPending && season.directPending[team.id]) {
                                                delete season.directPending[team.id];
                                            }
                                            if (!season.teamIds) {
                                                season.teamIds = {};
                                            }
                                            season.teamIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('accept_direct_pending')}</Button>
                                        <Button className="btn-cancel" style={{fontSize: 12}} onClick={e => {
                                            if (season.directPending && season.directPending[team.id]) {
                                                delete season.directPending[team.id];
                                            }
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('reject')}</Button>
                                    </div>
                                )}
                                {isInvited && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            if (season.invitedIds && season.invitedIds[team.id]) {
                                                delete season.invitedIds[team.id];
                                            }
                                            if (!season.teamIds) {
                                                season.teamIds = {};
                                            }
                                            season.teamIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('add')}</Button>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            if (season.invitedIds && season.invitedIds[team.id]) {
                                                delete season.invitedIds[team.id];
                                            }
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('cancel_invite')}</Button>
                                    </div>
                                )}
                                {!isInvited && !isMember && !isPending && !isDirectPending && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            if (!season.invitedIds) {
                                                season.invitedIds = {};
                                            }
                                            season.invitedIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('invite')}</Button>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            if (!season.teamIds) {
                                                season.teamIds = {};
                                            }
                                            season.teamIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged(season);
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t('add')}</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default SeasonTeamRow;