import React, { Component } from 'react';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import { connect } from 'react-redux';

import { 
    MdCardMembership
} from 'react-icons/md';
import { 
    Button, 
} from 'reactstrap';

import OrganizationEdit from 'components/edit/organization/OrganizationEdit';
import { userActions } from 'redux/_actions';

import boxesImg from 'assets/img/icons/gift_boxes.png'
import { invokeHttpsApi } from 'utils/API';
import { t } from 'i18next';
import { withRouter } from 'utils/Routher';

class OrganizationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    activateFreeMembership() {
        const {user} = this.props;
        
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        if (window.confirm('Are you sure you want to activate free organization membership?')) {
            invokeHttpsApi('membership-purchaseFreeOrgSubscription', {}, (data) => {
                alert('Free Membership with full organization membership is activated. It provides full features with 14 days.')
                this.props.router.navigate('/organizer')
            }, (error) => {
                alert(t('request_failed'));
            });
        }
    }

    render() {
        const {user, creatMode, organizationSubscription, freeOrganization, onSaveDone} = this.props;
        
        const now = new Date().valueOf();
        if (!user) {
            return <div/>
        }
        if (!creatMode) {
            if (organizationSubscription != null || (freeOrganization != null && freeOrganization.current_period_end > now)) {
                return (
                <div className='container' style={{marginTop: 20}}>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><MdCardMembership/></div>
                                <h3 className='items-navigation-title'>
                                You have purchased membership, 
                                you can cancel subscription any time inside these 3 trial days.
                                </h3>
                            </div>
                        </div>                        
                    </div>
                    
                    <Button className ='btn-submit' onClick={this.props.router.navigate('/organizer')}>Next</Button>
                </div>
                );
            }

            if (freeOrganization != null) {
                return (
                    <OrganizationMembershipPrompt 
                        forLeague={true}/>
                );
            } else {
                return (
                <div>
                    <div className='free-popup-bg'>
                        <img alt='icon' src={boxesImg} className='popup-icon' />
                        <h3 className='popup-title'>Welcome Offer</h3>
                        <h4 className='popup-subtitle'>Try our platform for free for 14 days</h4>
                        <div className='popup-description'>Test our premium features for leagues software and locations management.</div>
                        <Button className='btn-pick popup-btn' onClick={ e => {
                            this.activateFreeMembership();
                        }}> Activate</Button>
                        <div className='popup-description'>No payment required to activate this offer </div>
                        {/* <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.props.router.navigate('/organizer')
                        }}>Skip</Button> */}                    
                    </div>
                </div>
                );
            }
        } else {
            return (
            <div>
                <div className='shadow-tile-fixed' style={{margin: '40px 120px', padding: '50px 20px'}}>
                    <h3 className='text-center' style={{marginBottom: 20}}>{t('create_organization')}</h3>
                    <OrganizationEdit 
                        createMode={true} 
                        uid={user.uid}
                        onSaveDone={onSaveDone} />
                </div>
            </div>
            );
        }
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(OrganizationCreate));
export { connectedApp as OrganizationCreate };
