import logoImage from 'assets/img/logo/live_192.png';
import React, { Component } from "react";
import { t } from "i18next";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { MdMenu, MdClose } from 'react-icons/md';
import { OrganizationWallet } from 'components/wallet/OrganizationWallet';
import { OrganizationCart } from 'components/detail/payment/OrganizationCart';
import { AuthForm, STATE_LOGIN, STATE_SIGNUP } from 'components/auth/AuthForm';
import { getOrgDomain, getOrgLocation } from 'utils/Utils';
import userIcon from 'assets/img/icons/player_photo_default.png';
import { CardImg } from 'reactstrap';
import { withRouter } from 'utils/Routher';

class BlueNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerTransparent: window.pageYOffset < 60,
            windowWidth: window.innerWidth,
            menuOpen: false,
            authState: STATE_LOGIN
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateDimensions() {
        this.setState({windowWidth: window.innerWidth});
    }

    handleScroll(event) {
        let scrollTop = window.pageYOffset;
    
        this.setState({
            headerTransparent: scrollTop < 60
        });
    }

    renderAuthForm() {
        const { organization, authMsg, authEmail } = this.props;
        const { authState } = this.state;
        const icon = organization.thumbUri != null ? organization.thumbUri : logoImage;

        return (
            <div className='team-auth' onClick={e=>{
                this.setState({showAuth: false})
            }}>

                <div className='team-auth-wrapper' onClick={e=>{
                    e.stopPropagation();
                    this.setState({showAuth: true})
                }}>
                    <AuthForm
                        showLogo={true}
                        logoIcon={icon}
                        authEmail={authEmail}
                        authState={authEmail ? STATE_SIGNUP : authState}
                        logoTitle={organization.title}
                        logoView={authMsg ? authMsg : "Welcome to " + organization.title}
                        onChangeAuthState={(e)=> {
                            this.setState({authState: e})
                        }} />
                </div>
            </div>
        )
    }

    render() {
        const {organization, authMsg, blockTransparent, user, child} = this.props;
        const {showAuth, headerTransparent, windowWidth, menuOpen} = this.state;
        const homeLink = getOrgDomain(organization);
        const location = getOrgLocation(this.props);
        const servicesLink = homeLink + '/services';
        const championsLink = homeLink + '/championships';
        const accountLink = homeLink + '/account';
        const eventsLink = homeLink + '/events';
        const programsLink = homeLink + '/programs';
        const teamsLink = homeLink + '/teams';
        const fundLink = homeLink + '/fund';
        const aboutLink = homeLink + '/about';
        const contactLink = homeLink + '/contact';
        const managerLink = homeLink + '/manager';
        
        const headerClasses = (!blockTransparent && headerTransparent ? "home-nav-title-inverse" : "home-nav-title");
        
        const icon = organization.thumbUri != null ? organization.thumbUri : logoImage;
        const actionBg = organization && organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        const isContents = location === 'champions' || location === 'program' || location === 'events' || location === 'teams' || location === 'fundraises';
        const isMore = location === 'about' || location === 'contact';
        const isAccount = location === 'account';

        const contentsClass = "dropdown-toggle " + (isContents ? "org-a-active" : "org-a");
        const moreClass = "dropdown-toggle " + (isMore ? "org-a-active" : "org-a");
        const accountClass = "dropdown-toggle " + (isAccount ? "org-a-active" : "org-a");
        
        const choice = child ? child : user;

        const userThumb = choice && choice.photoUri ? choice.photoUri : userIcon;
        let title = t('contents');
        if (location === 'champions') {
            title = t('championships');
        } else if (location === 'services') {
            title = t('services');
        } else if (location === 'program') {
            title = t('programs');
        } else if (location === 'events') {
            title = t('events');
        } else if (location === 'teams') {
            title = t('teams');
        } else if (location === 'fundraises') {
            title = t('fundraises');
        }
        let moreTitle = t('more');
        if (location === 'about') {
            moreTitle = t('about_us');
        } else if (location === 'contact') {
            moreTitle = t('contact_us');
        }

        if (windowWidth > 768)  {
            return (
                <div>
                    <div className='org-landing-header'>
                        <a className='org-landing-icon' href={homeLink}>
                            <img src={icon} className="home-logo" alt='logo'/>
                            <div className={headerClasses}>{organization.title} </div>
                        </a>
                        <ul className='nav org-landing-links'>
                            <li className='org-landing-li'>
                                <div className="dropdown">
                                    <button 
                                    className={contentsClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isContents && actionBg ? {borderColor: actionBg} : {}}
                                    href={championsLink}>
                                        {title}
                                    </button>
                                    <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">
                                        <li className="dropdown-li">
                                            <a 
                                            href={championsLink} 
                                            style={location === 'champions' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'champions' ? "org-a-active" : "org-a"}>
                                                {t('championships')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={programsLink} 
                                            style={location === 'program' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'program' ? "org-a-active" : "org-a"}>
                                                {t('programs')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={eventsLink} 
                                            style={location === 'events' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'events' ? "org-a-active" : "org-a"}>
                                                {t('events')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={teamsLink} 
                                            style={location === 'teams' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'teams' ? "org-a-active" : "org-a"}>
                                                {t('teams')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={fundLink} 
                                            style={location === 'fundraises' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'fundraises' ? "org-a-active" : "org-a"}>
                                                {t('fundraises')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>   
                            </li>
                            {choice && (
                            <li className="org-landing-li">
                                <a 
                                href={servicesLink} 
                                className={location === 'services' ? "org-a-active" : "org-a"}>
                                    {t('registration')}
                                </a>
                            </li>
                            )}
                            <li className='org-landing-li'>
                                <div className="dropdown">
                                    <button 
                                    className={moreClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isMore && actionBg ? {borderColor: actionBg} : {}}
                                    href={aboutLink}>
                                        {moreTitle}
                                    </button>
                                    <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">       
                                        <li className="dropdown-li">
                                            <a 
                                            href={aboutLink} 
                                            style={location === 'about' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'about' ? "org-a-active" : "org-a"}>
                                                {t('about_us')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href={contactLink} 
                                            style={location === 'contact' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'contact' ? "org-a-active" : "org-a"}>
                                                {t('contact_us')}
                                            </a>
                                        </li>
                                    </ul>
                                </div>   
                            </li>
                            {user && organization && organization.managers && Object.values(organization.managers).includes(user.uid) && (
                            <li className='org-landing-li'>
                                <a 
                                href={managerLink} 
                                style={location === 'manager' && actionBg ? {borderColor: actionBg} : {}}
                                className={location === 'manager' ? "org-a-active" : "org-a"}>
                                {t('manager')}
                                </a>
                            </li>
                            )}
                            {choice && (
                            <li className='org-landing-li'>
                                <div className="dropdown">
                                    <button 
                                    className={accountClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isAccount && actionBg ? {borderColor: actionBg} : {}}
                                    href={accountLink}>
                                        <CardImg
                                        style={{width: 24, height: 24, marginRight: 10}}
                                        src={userThumb} />
                                        {choice.username}
                                    </button>
                                    <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel">       
                                        <li className="dropdown-li">
                                            <a 
                                            href={accountLink} 
                                            style={location === 'account' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                            className={location === 'account' ? "org-a-active" : "org-a"}>
                                                {t('my_account')}
                                            </a>
                                        </li>
                                        <li className="dropdown-li">
                                            <a 
                                            href='/my_account' 
                                            className="org-a">
                                                {t('my_profile')}
                                            </a>
                                        </li>
                                        {user && user.uid === organization.owner && (
                                        <li className="dropdown-li">
                                            <a 
                                            href='/organizer' 
                                            className="org-a">
                                                {t('dashboard')}
                                            </a>
                                        </li>
                                        )}
                                        <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                            this.props.logout();
                                        }}>
                                            Logout
                                        </button>
                                    </ul>
                                </div>   
                            </li>
                            )}
                            {(!user || !user.username) && (
                                <li className="org-landing-li">
                                    <button className="auth-btn" style={actionBg ? {backgroundColor: actionBg} : {}} onClick={e=> {
                                        this.setState({showAuth: true, authState: STATE_LOGIN});
                                    }}>
                                        {t('login')}
                                    </button>
                                </li>
                            )}     
                            <li className="org-landing-li">
                            {(!user || !user.username) && (
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_SIGNUP});
                                }}>
                                    {t('sign_up')}
                                </button>
                            )}
                            </li>
                        </ul>
                    </div>
                    {(authMsg || showAuth) && (
                        <div>{this.renderAuthForm()}</div>
                    )}
                    <OrganizationWallet
                        organization={organization} />
                    <OrganizationCart
                        organization={organization} />
                </div>
            );
        } else {
            return (
                <div style={{position: 'relative'}}>
                    <div className='org-landing-header'>
                        <a className='org-landing-links' href={homeLink}>
                            <img src={icon} className="home-logo" alt='logo'/>
                            <div className={headerClasses}>{organization.title} </div>
                        </a>
                        <div className='org-landing-menu-toggle' onClick={(e)=> {
                            this.setState({menuOpen: !menuOpen})
                        }}>{menuOpen ? <MdClose/> : <MdMenu/> }</div>
                    </div>
                    {menuOpen && (
                    <ul className="org-landing-dropdown-menu" role="menu" aria-labelledby="dLabel">
                        <li className="dropdown-li">
                            <a 
                            href={servicesLink} 
                            style={location === 'services' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'services' ? "org-a-active" : "org-a"}>
                                {t('registration')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={championsLink} 
                            style={location === 'champions' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'champions' ? "org-a-active" : "org-a"}>
                                {t('championships')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={accountLink} 
                            style={location === 'register' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'register' ? "org-a-active" : "org-a"}>
                                {t('register')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={programsLink} 
                            style={location === 'program' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'program' ? "org-a-active" : "org-a"}>
                                {t('programs')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={eventsLink} 
                            style={location === 'events' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'events' ? "org-a-active" : "org-a"}>
                                {t('events')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={teamsLink} 
                            style={location === 'teams' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'teams' ? "org-a-active" : "org-a"}>
                                {t('teams')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={fundLink} 
                            style={location === 'fundraises' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'fundraises' ? "org-a-active" : "org-a"}>
                                {t('fundraises')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={aboutLink} 
                            style={location === 'about' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'about' ? "org-a-active" : "org-a"}>
                                {t('about_us')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={contactLink} 
                            style={location === 'contact' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'contact' ? "org-a-active" : "org-a"}>
                                {t('contact_us')}
                            </a>
                        </li>
                        {user && (
                        <li className="dropdown-li">
                            <a 
                            href={accountLink} 
                            style={location === 'account' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            className={location === 'account' ? "org-a-active" : "org-a"}>
                                {t('my_account')}
                            </a>
                        </li>
                        )}
                        {user && (
                        <li className="dropdown-li">
                            <a href='/my_account' className="org-a">
                            {t('my_profile')}
                            </a>
                        </li>
                        )}
                        {user && user.uid === organization.owner && (
                        <li className="dropdown-li">
                            <a href='/organizer' className={location === 'dashboard' ? "org-a-active" : "org-a"}>
                            {t('dashboard')}
                            </a>
                        </li>
                        )}
                        {(!user || !user.username) && (
                            <li className="dropdown-li">
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.setState({showAuth: true, authState: STATE_LOGIN});
                                }}>
                                    {t('login')}
                                </button>
                            </li>
                        )}     
                        <li className="dropdown-li">
                        {user != null ? (
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                if (location === 'account') {
                                    window.location = homeLink;
                                }
                                this.props.logout();
                            }}>
                                {t('logout')}
                            </button>
                        ) : (
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                this.setState({showAuth: true, authState: STATE_SIGNUP});
                            }}>
                                {t('sign_up')}
                            </button>
                        )}
                        </li>
                    </ul>
                    )}
                    {(authMsg || showAuth) && (
                        <div>{this.renderAuthForm()}</div>
                    )}
                    <OrganizationWallet
                        organization={organization} />
                    <OrganizationCart
                        organization={organization} />
                </div>
            );
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, child, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, child, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = withRouter(connect(mapState, actionCreators)(BlueNavigation));
export { connectedNavigation as BlueNavigation };
