import React, { Component } from "react";
import { getFileExtension } from 'utils/FirebaseUtils';
import {
    Row, Col, Input, CardImg, Progress
} from 'reactstrap';
import placeholder from 'assets/img/icons/placeholder.png';
import { uploadImageFileToStorage } from 'utils/FirebaseUtils';

export class GalleryItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            progress: false,
            imageProgress: 0
        };
    }

    uploadImage(newImage) {
        const {galleryItem, path, onImageUpdate} = this.props;
        
        this.setState({
            progress: true,
            imageProgress: 0
        })
        uploadImageFileToStorage(galleryItem.id, path + '/galleryItem', newImage, (url) => {
            galleryItem.imageUrl = url 
            onImageUpdate(galleryItem)
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({imageProgress: progress});
            } else {
                this.setState({imageProgress: 0});
            }
        }, error => {
            alert("Saving Image File Failed.\n" + error);
            this.setState({
                progress: false
            })
        })
    }

    render() {
        const {galleryItem, editMode, onChange} = this.props;
        const {progress, imageProgress} = this.state;
        
        if (!editMode) {
            return (
                <Row style={{padding: 0, margin: 0}}>
                    <Col xs={3}>
                        <CardImg 
                            className="gallery-icon"
                            src={galleryItem.imageUrl != null && galleryItem.imageUrl !== '' ? galleryItem.imageUrl : placeholder} />
                    </Col>
                    <Col xs={9}>
                        <h4 className="wrap-content-wrap-child" style={{fontSize: 12}}>
                        {galleryItem.title}
                        </h4>
                        <div className="wrap-content-wrap-child" style={{fontSize: 12}}>
                        {galleryItem.text}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row style={{padding: 0, margin: 0}}>
                    <Col xs={3}>
                        <CardImg 
                            className="gallery-icon"
                            src={galleryItem.imageUrl != null && galleryItem.imageUrl !== '' ? galleryItem.imageUrl : placeholder} />
                    </Col>
                    <Col xs={9}>
                        <Input 
                            type="text" name="title" id="title" 
                            placeholder="Gallery item title" 
                            style={{margin: '3px 5px'}}
                            value={galleryItem.title} onChange={e => {  
                                galleryItem.title = e.target.value;
                                if (onChange) {
                                    onChange(galleryItem);
                                }
                        }} />
                        <Input 
                            type="text" name="text" id="text" 
                            placeholder="Gallery item text" 
                            style={{margin: '3px 5px'}}
                            value={galleryItem.text} onChange={e => {  
                                galleryItem.text = e.target.value;
                                if (onChange) {
                                    onChange(galleryItem);
                                }
                        }} />
                        <div style={{margin: '5px 10px'}}>
                            <h4 style={{fontSize: 12}}>
                            Gallery Image File:
                            </h4>
                            <Input type="file" name="file" id="imageFile" onChange={e => {                                
                                if (onChange != null && e.target.files != null && e.target.files.length > 0) {
                                    let file = e.target.files[0];
                                    let extension = getFileExtension(file.name);
                                    if (extension === 'png' || extension === 'jpg') {
                                        this.uploadImage(file);
                                    } else {
                                        alert("Only jpg or png files are allowed here. This file will be ignored.");
                                    }
                                }
                            }} />
                            {progress && (
                                <Progress className='progress-style-file' color="warning" value={imageProgress} />
                            )}
                        </div>
                    </Col>
                </Row>
            );
        }
    }
}

export default GalleryItem;