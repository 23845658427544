import React, { Component } from "react";
import { CardImg } from 'reactstrap';

export class SubInfoView extends Component {
    render() {
        const {title, icon, number, secNumber, normal, subText, fixed} = this.props;
        
        return (
            <div className={!fixed ? 'info-wrapper' : 'info-wrapper-fixed'} style={normal ? {width: 120, marginRight: 15, display: 'inline-block', padding: '5px 20px'} : 
                (!fixed ? {width: '100%', display: 'inline-block', padding: '5px 20px'} : {display: 'inline-block', padding: '5px 20px'})}>
                <div className='wrap-content-parent'>
                    <CardImg 
                        className='wrap-content-wrap-child'
                        src={icon}
                        style={{width: 25, height: 25, marginRight: 10}} />
                    <div 
                        className='wrap-content-fill-child vertical-center-spacing' 
                        style={{fontSize: 20, fontWeight: 1000, color: 'black', textAlign: 'center'}}>
                        {number}
                        {secNumber ? (
                            "/" + secNumber.toString()
                        ) : ("")}
                        {subText}
                    </div>
                </div>
                <div 
                    style={{width: '100%', textAlign: 'center', fontSize: 10}}>
                    {title}
                </div>
            </div>
        );
    }
}

export default SubInfoView;