import React, { Component } from "react";
import { connect } from 'react-redux';
import { t } from "i18next";

import { invokeHttpsApi, listenDb, listenDbQuery } from "utils/API";
import { withRouter } from "utils/Routher";

import { AuthForm, STATE_LOGIN } from 'components/auth/AuthForm';
import { LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID, LOCATION_SUBSCRIPTION_PRODUCT_ID, getLogo, getTitle, renderHtmlString } from "utils/Utils";
import { OrganizationCreate } from "components/edit/OrganizationCreate";
import { DivisionSetupView } from "components/edit/organization/setup/DivisionSetupView";
import { SeasonCreateView } from "components/edit/organization/setup/SeasonCreateView";
import { SeasonScheduleView } from "components/edit/organization/setup/SeasonScheduleView";
import SmallLoadingView from "components/detail/loading/SmallLoadingView";
import { Button, Col, Row, Spinner } from "reactstrap";
import { OrganizationMembershipPrompt } from "components/membership/OrganizationMembershipPrompt";

const FREE_ORG_AVAILABLE = 0;
const FREE_ORG_EXPIRED = 1;
const FREE_ORG_ONGOING = 2;

class OrgOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            loading: true,
            landingPageData: {},
            organization: {},
            policy: {},
            organizations: {},
            divisionChoice: {},
            seasonCreate: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            scheduling: false,
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            hideMore: true
        }
    }

    componentDidMount() {
        this.loadContents();
        listenDb("/policy", (data) => {
            const policy = {};
            if (data) {
                for (const [k,v] of Object.entries(data)) {
                    policy[k] = v;
                }
            }
            this.setState({policy: policy});
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.loadContents();
        }
    }

    loadContents() {
        const {user} = this.props;
        if (!user) {
            return;
        }
        listenDbQuery("/organization", "owner", user.uid, (data) => {
            const organization = {};
            if (data) {
                for (const value of Object.values(data)) {
                    for (const [k,v] of Object.entries(value)) {
                        organization[k] = v;
                    }
                }
            }
            this.setState({organization: organization});
        })

        this.setState({loading: true})
        invokeHttpsApi('organizerQuery-organizationLandingQuery', {}, (data) => {
            const organizations = {};
            const organization = {};
            const divisions = {};
            const divisionChoice = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const trophies = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (user && user.uid === value.owner) {
                        for (const [k, v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    if (organization && value.organizationId === organization.id) {
                        divisions[key] = value;
                        for (const [k, v] of Object.entries(value)) {
                            divisionChoice[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    if (organization && value.organizationId === organization.id) {
                        seasons[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                divisionChoice: divisionChoice,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                trophies: trophies,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    renderAuthForm() {
        const icon = getLogo();

        return (
            <AuthForm
                showLogo={true}
                logoIcon={icon}
                authState={STATE_LOGIN}
                logoTitle={t('org_overlay_login_prompt') + getTitle()}
                logoView={t('org_overlay_login_message')}
                onChangeAuthState={(e)=> {
                    this.setState({authState: e})
                }} />
        )
    }

    renderCreateOrganization() {
        return <OrganizationCreate creatMode={true} />
    }

    checkFreeExpired() {
        const { freeOrganization } = this.props;
        const now = new Date().valueOf();
        
        if (freeOrganization == null) {
            return FREE_ORG_AVAILABLE;
        }
        if (freeOrganization.current_period_end < now) {
            return FREE_ORG_EXPIRED;
        }

        return FREE_ORG_ONGOING;
    }

    checkExpired() {
        const { organizationSubscription } = this.props;
        
        let expired = false;
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID))) {
            expired = true;
        }

        return expired;
    }

    activateFreeMembership() {
        const {user} = this.props;
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        this.setState({progress: true});
        if (window.confirm('Are you sure you want to activate free organization membership?')) {
            invokeHttpsApi("membership-purchaseFreeOrgSubscription", {}, (data) => {
                if (data != null && data.success === true) {
                    alert('Free Membership with full organization membership is activated. It provides full features with 14 days.')
                }
                this.setState({progress: false});
            }, error => {
                this.setState({progress: false});
            });
        }
    }

    renderFreeMembership() {
        const {onOpenedUpdate} = this.props;
        const {progress} = this.state;

        return (
            <div>
                <div className="season-create-step-header">
                    <div className="season-create-step-title">
                        {t('purchase_free_org_membership_text')}
                    </div>
                </div>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        {progress ? (<Spinner color="primary" />) : (
                        <Button className='btn-blue' onClick={e => {
                            this.props.router.navigate('/organizer/season_pay')
                        }}>{t('season_pay')}</Button>
                        )}
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            this.activateFreeMembership();
                        }}>{t('activate')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onOpenedUpdate != null) {
                                onOpenedUpdate(false);
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderMembershipPlans() {
        const {onOpenedUpdate} = this.props;
        const {progress} = this.state;

        return (
            <div>
                <div className="season-create-step-header">
                    <div className="season-create-step-title">
                        {t('purchase_org_membership_text')}
                    </div>
                </div>
                <OrganizationMembershipPrompt forOverlay={true} forLeague={true} />
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        {progress ? (<Spinner color="primary" />) : (
                        <Button className='btn-blue' onClick={e => {
                            this.props.router.navigate('/organizer/season_pay')
                        }}>{t('season_pay')}</Button>
                        )}
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            this.props.router.navigate('/organizer/membership')
                        }}>{t('details')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onOpenedUpdate != null) {
                                onOpenedUpdate(false);
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderPolicy() {
        const {onOpenedUpdate} = this.props;
        const {progress, policy, organization} = this.state;
        
        if (policy.body && organization.id) {
            return (
                <div className="main-overlay-policy-wrapper">
                    <div className="season-create-step-header">
                        <div className="season-create-step-num">
                            {1}
                        </div>
                        <div className="season-create-step-title">
                            {t('privacy_policy')}
                        </div>
                    </div>
                    <div className="main-overlay-policy">
                        {renderHtmlString(policy.body)}
                    </div>
                    <Row className='form-edit-row text-center'>
                        <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                            
                        </Col>
                        <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                            {progress ? (<Spinner color="primary" />) : (
                            <Button className='btn-blue' onClick={e => {
                                this.setState({progress: true});
                                invokeHttpsApi('organizerQuery-submitOrganizationPolicy', {
                                    organizationId: organization.id
                                }, (data) => {
                                    if (data.dict && data.dict.link && data.dict.link.url) {
                                        window.location = data.dict.link.url
                                    }
                                    this.setState({progress: false});
                                }, (error) => {
                                    console.log('Request error', error);
                                    alert(t('request_failed') + '\n' + error);
                                    this.setState({progress: false});
                                });
                            }}>{t('agree')}</Button>
                            )}
                            <div className="wrap-content-fill-child"/>
                            <Button className='btn-cancel-blue' onClick={e => {
                                if (onOpenedUpdate != null) {
                                    onOpenedUpdate(false);
                                }
                            }}>{t('close')}</Button>
                        </Col>
                    </Row>
                </div>
            );
        }
        return <div />;
    }

    requestStripeAccountLink() {
        const { user } = this.props;
        const { organization } = this.state;
        
        this.setState({progress: true});
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        const data = {
            email: user.email,
            organizationId: organization.id,
            returnURL: pref
        }
        if (user.stripeAccountId) {
            data.stripeAccountId = user.stripeAccountId
        }
        
        invokeHttpsApi('payment-createConnectedAccount', data, (data) => {
            if (data.dict && data.dict.link && data.dict.link.url) {
                window.location = data.dict.link.url
            }
            this.setState({progress: false});
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderStripeSetup() {
        const {onOpenedUpdate} = this.props;
        const {progress, policy, organization} = this.state;
        
        if (policy.body && organization.id) {
            return (
                <div>
                    <div className="season-create-step-header">
                        <div className="season-create-step-num">
                            {2}
                        </div>
                        <div className="season-create-step-title">
                            {t('payment_setup')}
                        </div>
                    </div>
                    <div style={{height: 200}}>
                        {t('payment_setup_message')}
                    </div>
                    <Row className='form-edit-row text-center'>
                        <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                            
                        </Col>
                        <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                            {progress ? (<Spinner color="primary" />) : (
                            <Button className='btn-blue' onClick={e => {
                                this.requestStripeAccountLink()
                            }}>{t('setup_account')}</Button>
                            )}
                            <div className="wrap-content-fill-child"/>
                            <Button className='btn-cancel-blue' onClick={e => {
                                if (onOpenedUpdate != null) {
                                    onOpenedUpdate(false);
                                }
                            }}>{t('close')}</Button>
                        </Col>
                    </Row>
                </div>
            );
        }
        return <div />;
    }

    renderSetupSeason() {
        const {user, onOpenedUpdate} = this.props;
        const {loading, organization, divisions, seasons, teams, locations, scheduling, users,
            divisionChoice, seasonCreate, trophies, category} = this.state;

        if (loading) {
            return <SmallLoadingView height="100%" />;
        } else if (Object.values(divisions).length === 0) {
            return (
                <DivisionSetupView 
                    division={divisionChoice}
                    organization={organization}
                    categorys={category}
                    hideClose={true}
                    onDivisionUpdate={update => {
                        if (update.id) {
                            divisions[update.id] = update;
                        }
                        this.setState({divisions: divisions, divisionChoice: update});
                    }}
                    onCloseClick={()=> {
                        this.setState({divisionChoice: {}});
                    }}
                    onSaveDone={(update)=> {
                        if (update.id) {
                            divisions[update.id] = update;
                        }
                        this.setState({divisions: divisions});
                    }} />
            )
        } else if (scheduling) {
            return (
                <SeasonScheduleView 
                    divisions={divisions}
                    organization={organization}
                    season={seasonCreate}
                    locations={locations}
                    teams={teams}
                    users={users}
                    trophies={trophies}
                    uid={user.uid}
                    onSeasonUpdate={(update)=> {
                        seasons[update.id] = update;

                        this.setState({
                            seasons: seasons,
                            seasonChoice: update
                        })
                    }}
                    onCloseClick={()=> {
                        if (onOpenedUpdate) {
                            onOpenedUpdate(false);
                        }
                    }}
                    onContinue={(update)=> {
                        seasons[update.id] = update;

                        this.setState({
                            seasons: seasons,
                            scheduling: true,
                        })
                    }}
                    onSaveDone={(update)=> {
                        seasons[update.id] = update;

                        this.setState({
                            seasons: seasons,
                        })
                        this.props.router.navigate(`/organizer/divisions/${update.divisionId}/${update.id}`);
                    }}
                    onUserUpdate={(update, team)=> {
                        if (update) {
                            users[update.uid] = update;
                        }
                        if (team) {
                            teams[team.id] = team;
                        }
                        this.setState({users: users, teams: teams});
                    }} />
            )
        } else {
            return (
                <SeasonCreateView 
                    divisions={divisions}
                    organization={organization}
                    season={seasonCreate}
                    locations={locations}
                    teams={teams}
                    trophies={trophies}
                    uid={user.uid}
                    onSeasonUpdate={(update)=> {
                        seasons[update.id] = update;

                        this.setState({
                            seasons: seasons,
                            seasonChoice: update
                        })
                    }}
                    onCloseClick={()=> {
                        if (onOpenedUpdate) {
                            onOpenedUpdate(false);
                        }
                    }}
                    onContinue={(update)=> {
                        seasons[update.id] = update;

                        this.setState({
                            seasons: seasons,
                            scheduling: true,
                        })
                    }}
                    onSaveDone={(update)=> {
                        seasons[update.id] = update;

                        this.setState({
                            seasons: seasons,
                        })
                        this.props.router.navigate(`/organizer/divisions/${update.divisionId}/${update.id}`);
                    }} />
            )
        }
    }

    render() {
        const { user, isOpened, onOpenedUpdate } = this.props;
        const { policy, organization } = this.state;

        if (!isOpened) {
            return <div />;
        }

        let content = <div/>;
        if (!user || !user.uid) {
            content = this.renderAuthForm();
        } else if (!organization || !organization.id) {
            content = this.renderCreateOrganization();
        } else if (!this.checkExpired() || this.checkFreeExpired() === FREE_ORG_ONGOING) {
            if (!policy.organizationIds || !policy.organizationIds[organization.id]) {
                content = this.renderPolicy();
            } else if (!organization.stripeAccountId) {
                content = this.renderStripeSetup();
            } else {
                content = this.renderSetupSeason();
            }
        } else if (this.checkFreeExpired() === FREE_ORG_AVAILABLE) {
            content = this.renderFreeMembership();
        } else {
            content = this.renderMembershipPlans();
        }

        return (
            <div className='main-overlay' onClick={e=>{
                if (onOpenedUpdate) {
                    onOpenedUpdate(false);
                }
            }}>

                <div className='main-overlay-wrapper' onClick={e=>{
                    e.stopPropagation();
                }}>
                    {content}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { organizationSubscription, freeOrganization, isAdmin, loggingIn, user };
  }
  
const connected = withRouter(connect(mapState)(OrgOverlay));
export { connected as OrgOverlay };
  