import { dataConstants } from '../_constants';
import { dataService } from '../_services';
import { alertActions } from './';

export const dataActions = {
    saveChangeWithImage,
    saveChange,
};

function saveChangeWithImage(dataChange, dataPath, newImage, imageCategory, imageId, imageAttr, updated) {
    return dispatch => {
        dispatch(request());

        dataService.saveChangeWithImage(dataChange, dataPath, newImage, imageCategory, imageId, imageAttr, updated)
            .then(
                result => { 
                    if (result.type === "imageProgress") {
                        dispatch(imagePrgress(result.imageProgress));
                    } else {
                        dispatch(success(result.data));
                    }
                },
                error => {
                    dispatch(failed(null));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: dataConstants.UPDATE_DATA_REQUEST} }
    function success(data) { return { type: dataConstants.UPDATE_SUCCESS, data } }
    function imagePrgress(progress) { return { type: dataConstants.UPDATE_IMAGE_PROGRESS, progress } }
    function failed() { return { type: dataConstants.UPDATE_FAILURE } }
}

function saveChange(dataChange, dataPath) {
    return dispatch => {
        dispatch(request());

        dataService.saveChange(dataChange, dataPath)
            .then(
                result => { 
                    dispatch(success(result.data));
                },
                error => {
                    dispatch(failed(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: dataConstants.UPDATE_DATA_REQUEST} }
    function success(data) { return { type: dataConstants.UPDATE_SUCCESS, data } }
    function failed() { return { type: dataConstants.UPDATE_FAILURE } }
}
