import React, { Component } from "react";

import {
    IoCloudDownloadOutline
} from 'react-icons/io5';

import {
    FiUsers
} from 'react-icons/fi';

import {
    BiTimeFive
} from 'react-icons/bi';


export class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connected: 1500,
            downloads: 25000,
            developmentHours: 3000,
        }
    }

    render() {
        const {connected, downloads, developmentHours} = this.state;
        return (
            <div className="main-statistics">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-xl-4 col-lg-4 downloads-subInfo">
                            <div className="main-statistics-icon">
                                <FiUsers/>
                            </div>
                            <h3 className="main-statistics-subtitle">
                                {connected}
                            </h3>
                            <div className="main-statistics-description">
                            People Connected
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-4 col-lg-4 downloads-subInfo">
                            <div className="main-statistics-icon">
                                <IoCloudDownloadOutline/>
                            </div>
                            <h3 className="main-statistics-subtitle">
                                {downloads}
                            </h3>
                            <div className="main-statistics-description">
                            Downloads
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-4 col-lg-4 downloads-subInfo">
                            <div className="main-statistics-icon">
                                <BiTimeFive/>
                            </div>
                            <h3 className="main-statistics-subtitle">
                                {developmentHours}
                            </h3>
                            <div className="main-statistics-description">
                            Development Hours
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Statistics;
