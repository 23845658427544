import React, { Component } from "react";
import {
    Button, Row, Col, Input, Spinner,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import { checkMatches, sortByNumber } from 'utils/Utils';
import GameSetupBadge from "components/edit/organization/setup/GameSetupBadge";
import { ROUND_TYPE_KNOCKOUT, STATUS_FINISHED } from "utils/constants";
import { generateId, saveSeason } from "utils/API";
import { buildEmptySchedule, getSeasonFinalRoundReady } from "utils/statistics";

class KnockoutRoundSetup extends Component {
    constructor(props) {
        super(props);
        const {season, subSeason} = this.props;
        
        const update = {};
        if (subSeason != null) {
            for (const [key, value] of Object.entries(subSeason)) {
                update[key] = value;
            }
        }
        const games = update.games != null ? Object.values(update.games) : [];
        
        const teamIdsArr = this.initRanking(subSeason);
        const data = buildEmptySchedule(update, games, season, teamIdsArr);
        if (data.games != null && data.games.length > 0) {
            checkMatches(data.games, false);
            subSeason.games = {};
            for (const game of data.games) {
                if (!game.id) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                }
                subSeason.games[game.id] = game;
            }
        }
        if (data.rounds != null) {
            subSeason.rounds = data.rounds;
        }
        
        this.state = {
            step: 0,
            subSeason: subSeason,
            gameTree: data.gameTree,
            rounds: data.rounds,
            games: data.games,
            pickTeams: false,
            newImage: {},
            deployType: 'weekBased',
            imageProgress: 0,
            locationTimes: {},
        }
        if (this.props.onValueUpdate) {this.props.onValueUpdate(subSeason)};
        this.initRanking = this.initRanking.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, season, subSeason, startTeams} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            
            this.setState({
                progress: false,
                pickTeams: false,
                newImage: {},
                locationTimes: {},
                imageProgress: 0
            })
        }
        if (prevProps.subSeason !== subSeason || prevProps.startTeams !== startTeams) {
            this.reinitSeasonGames(season, subSeason);
        }
    }

    initRanking(subSeason) {
        const {season} = this.props;

        const rankingData = [];
        if (season && season.teamIds) {
            const finalReady = getSeasonFinalRoundReady(season);
            
            if (subSeason.id !== ROUND_TYPE_KNOCKOUT || finalReady) {
                let ranking = 0;
                for (const id of Object.values(season.teamIds)) {
                    rankingData.push({id: id, points: 0, index: ranking ++, ranking: ranking});
                }
            }
        }
        
        this.setState({
            rankingData: rankingData
        });

        return rankingData;
    }
    
    reinitSeasonGames(season, subSeason) {
        const update = {};
        for (const [key, value] of Object.entries(subSeason)) {
            update[key] = value;
        }
        const games = subSeason.games != null ? Object.values(subSeason.games) : [];
        const teamIdsArr = this.initRanking(subSeason);
        const data = buildEmptySchedule(update, games, season, teamIdsArr);
        checkMatches(data.games, false);
        update.games = {};
        for (const game of data.games) {
            if (!game.id) {
                game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
            }
            update.games[game.id] = game;
        }
        this.setState({
            subSeason: update,
            gameTree: data.gameTree,
            rounds: data.rounds,
            games: data.games,
        })
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-setup-step-header">
            <div className="season-setup-step-num">
                {num}
            </div>
            <div className="season-setup-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderGeneralSetup() {
        const { season, onSeasonUpdate, onCloseClick } = this.props;
        const { subSeason } = this.state;
        
        return (
            <div>
                {this.renderStepTitle("A", t('season_schedule_knockout_general'))}
                
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('knockout_team_count')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                            subSeason.startTeams = season.knockoutTeamCount;
                            season.subSeasons[subSeason.id].startTeams = season.knockoutTeamCount;
                            onSeasonUpdate(season);
                            this.reinitSeasonGames(season, subSeason);
                            this.setState({subSeason: subSeason});
                        }} value={season.knockoutTeamCount}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
                
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!season.knockoutTeamCount || season.knockoutTeamCount < 2) {
                                alert(t("no_round_info_prompt"));
                                return;
                            }
                            
                            this.setState({step: 1});
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderDeploySetup() {
        const { season, onCloseClick, onSeasonUpdate } = this.props;
        const { subSeason, rounds, progress } = this.state;

        let roundArr = [];
        for (const round of Object.values(rounds)) {
            roundArr.push(round);
        }
        sortByNumber(roundArr, "index");

        return (
            <div>
                {this.renderStepTitle("B", t('season_schedule_knockout_time'))}
                
                <div className="overlay-limited-height" style={{padding: '0px 20px'}}>
                {roundArr.map((value, idx)=>(
                    this.renderGamesRound(value)
                ))}
                </div>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 0})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        {progress ? (
                        <Spinner />
                        ) : (
                        <Button className='btn-blue' onClick={e => {
                            if (!season.subSeasons) {
                                season.subSeasons = {};
                            }
                            season.subSeasons[subSeason.id] = subSeason;

                            this.setState({progress: true});
                            saveSeason(season, (update) => {
                                this.setState({progress: false, subSeason: subSeason});
                            }, (error) => {
                                this.setState({progress: false})
                            })
                            
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('save')}</Button>
                        )}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                if (!season.subSeasons) {
                                    season.subSeasons = {};
                                }
                                season.subSeasons[subSeason.id] = subSeason;
                                onSeasonUpdate(season);
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderGamesRound(round) {
        const { subSeason } = this.state;

        let status = 0;
        let games = [];
        if (round.gameIds) {
            for (const gameId of Object.values(round.gameIds)) {
                const game = subSeason.games[gameId];
                if (subSeason.games && game) {
                    if (round.teamCount === 2 && game.gameIdx === 0) {
                        status = 2;
                    } else if (round.teamCount === 2 && game.gameIdx === 1) {
                        status = 1;
                    }
                    games.push(subSeason.games[gameId]);
                }
            }
        }

        let title = round.teamCount + " vs " + round.teamCount;
        if (status === 1) {
            title = t('final');
        } else if (status === 2) {
            title = t('final_3_4');
        }

        return (
            <div key={round.id}>
                <h3>{title}</h3>
                {games.map((value, idx)=>(
                    this.renderGame(value)
                ))}
            </div>
        )
    }

    onGameUpdate(update) {
        const { subSeason } = this.state;

        subSeason.games[update.id] = update;
        checkMatches(Object.values(subSeason.games), false);
        this.setState({subSeason: subSeason});
    }

    renderGame(value) {
        const { organization, season, division, teams, users, locations } = this.props;
        const { subSeason } = this.state;

        return (
            <GameSetupBadge
                season={season}
                subSeason={subSeason}
                game={value}
                teams={teams}
                organization={organization}
                division={division}
                locations={locations}
                users={users}
                onHomeUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.homeTeam = update === "" ? null : update;
                    this.onGameUpdate(value);
                }}
                onAwayUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.awayTeam = update === "" ? null : update;
                    this.onGameUpdate(value);
                }}
                gameTimeUpdate={(start, end)=>{
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    if (start != null) {
                        value.start = start;
                    }
                    if (end != null) {
                        value.end = end;
                    }
                    this.onGameUpdate(value);
                }}
                locationUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.locationId = update;
                    this.onGameUpdate(value);
                }}
                dayUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.matchDayIndex = update;
                    this.onGameUpdate(value);
                }}
                refereeUpdate={(update, assists)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.refereeId = update;
                    value.referees = assists;
                    this.onGameUpdate(value);
                }}
                removeClicked={()=> {
                    
                }}/>
        )
    }

    render() {
        const { step } = this.state;
        
        return (
            <div>
                <Row className='form-edit-row'>
                    <h4 className="text-center">{t("setup_group_round")}</h4>
                </Row>
                {step === 0 && this.renderGeneralSetup()}
                {step === 1 && this.renderDeploySetup()}
            </div>
        )
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(KnockoutRoundSetup));
export { connectedApp as KnockoutRoundSetup };
