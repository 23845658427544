export const filterService = {
    updateFilter,
    updateDict,
};

function updateFilter(filter) {

    return new Promise((resolve, reject) => {
        localStorage.setItem("seasonId", filter ? filter.seasonId : null);
        localStorage.setItem("divisionId", filter ? filter.divisionId : null);
        localStorage.setItem("teamId", filter ? filter.teamId : null);
        resolve(filter);
    })
}

function updateDict(dict) {

    return new Promise((resolve, reject) => {
        resolve(dict);
    })
}
