import React, { Component } from "react";
import { connect } from 'react-redux';
import defaultBg from 'assets/img/bg/samba_bg.png';
import defaultLogo from 'assets/img/icons/logo.png';
import {SambaNavigation} from 'components/landing/organization/samba/SambaNavigation';
import logoImage from 'assets/img/logo/live_192.png';
import { AuthForm, STATE_LOGIN, STATE_SIGNUP } from 'components/auth/AuthForm';
import { filterActions } from "redux/_actions/filter.actions";
import { t } from "i18next";
import { Input, Button } from "reactstrap";
import { sortByTitle } from "utils/Utils";
import { STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from 'utils/constants';
import teamIcon from 'assets/img/icons/club_icon_default.png';

class SambaHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerTransparent: window.pageYOffset < 60,
            windowWidth: window.innerWidth,
            menuOpen: false,
            authState: STATE_LOGIN
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateDimensions() {
        this.setState({windowWidth: window.innerWidth});
    }
    
    handleScroll(event) {
        let scrollTop = window.pageYOffset;
    
        this.setState({
            headerTransparent: scrollTop < 60
        });
    }

    renderAuthForm() {
        const { organization, authMsg, authEmail } = this.props;
        const { authState } = this.state;
        const icon = organization.thumbUri != null ? organization.thumbUri : logoImage;

        return (
            <div className='team-auth' onClick={e=>{
                this.setState({showAuth: false})
            }}>

                <div className='team-auth-wrapper' onClick={e=>{
                    e.stopPropagation();
                    this.setState({showAuth: true})
                }}>
                    <AuthForm
                        showLogo={true}
                        logoIcon={icon}
                        authEmail={authEmail}
                        authState={authEmail ? STATE_SIGNUP : authState}
                        logoTitle={organization.title}
                        logoView={authMsg ? authMsg : "Welcome to " + organization.title}
                        onChangeAuthState={(e)=> {
                            this.setState({authState: e})
                        }} />
                </div>
            </div>
        )
    }

    render() {
        const { organization, authMsg, location, history, loading, itemId, filter, dict } = this.props;
        const { showAuth, menuOpen } = this.state;

        const bg = organization.landingData && organization.landingData.sambaUrl ? organization.landingData.sambaUrl : defaultBg;
        const logo = organization.thumbUri ? organization.thumbUri : defaultLogo;

        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        let actionStyle = {};
        if (actionBg && actionColor) {
            actionStyle = {
                backgroundColor: actionBg,
                color: actionColor,
            }
        } else if (actionBg) {
            actionStyle = {
                backgroundColor: actionBg,
            }
        } else if (actionColor) {
            actionStyle = {
                color: actionColor,
            }
        }
        let seasons = dict.seasons ? Object.values(dict.seasons) : [];
        let divisionArr = dict.divisions ? Object.values(dict.divisions) : [];
        let seasonArr = [];
        for (const val of seasons) {
            if (/*(!filter.divisionId || filter.divisionId === val.divisionId) || */
                (!filter.status || val.status === filter.status || (filter.status === STATUS_REGISTER && val.status == null))) {
                seasonArr.push(val);
            }
        }
        sortByTitle(seasonArr);
        sortByTitle( divisionArr);
        let teamArr = [];
        let seasonChoice = filter.seasonId && dict.seasons && dict.seasons[filter.seasonId] ? dict.seasons[filter.seasonId] : null;
        if (dict.teams) {
            for (const item of Object.values(dict.teams)) {
                if (!filter.seasonId || !seasonChoice || (seasonChoice.teamIds && Object.values(seasonChoice.teamIds).includes(item.id))) {
                    teamArr.push(item);
                }
            }
        }
        sortByTitle(teamArr);

        return (
            <div className="samba-header">
                <div className="samba-fixed-header">
                    <div className="samba-header-actions" style={actionStyle}>
                        <div className="container text-center">
                            <div className="wrap-content-parent">
                                <div className="wrap-content-fill-child" />
                                <div className='samba-header-filter-text'>
                                {t('season_filter_prompt')}
                                </div>
                                <div className='samba-header-filter-input' onClick={e => {this.setState({menuOpen: true})}}>
                                    {seasonChoice ? seasonChoice.title : t('all')}
                                </div>
                                <div className="wrap-content-fill-child" />
                            </div>
                        </div>
                    </div>
                    <div className="samba-header-teams">
                        <div className="samba-header-teams-title">
                            {t('teams')}
                        </div>

                        <div className="samba-header-team-layout">
                            {teamArr.map((val,idx) => (
                                <img 
                                    key={idx} 
                                    className="samba-header-team-item" 
                                    src={val.iconUri ? val.iconUri : teamIcon} 
                                    alt={val.title} 
                                    style={val.id === filter.teamId ? {borderBottom: '2px solid #FF9222'} : {}}
                                    onClick={e => {
                                        if (filter.teamId === val.id) {
                                            filter.teamId = null;
                                        } else {
                                            filter.teamId = val.id; 
                                        }
                                        this.props.updateFilter(filter)
                                    }} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="samba-fixed-header-placeholder" />
                {menuOpen && !showAuth && (
                <div className="team-auth" onClick={e=>{
                        this.setState({menuOpen: false})
                    }}>
    
                    <div className="team-auth-wrapper" onClick={e=>{
                        e.stopPropagation();
                    }}>
                        <h4>{t('season_filter_prompt')}</h4>
                        {/* <div className='form-edit-row row'>
                            <div className='form-edit-label col-12 col-sm-5'>
                            {t('division')}
                            </div>
                            <div className='form-edit-entry col-12 col-sm-7'>
                                <Input type="select" value={filter.divisionId ? filter.divisionId : ""} onChange={e => {
                                    filter.divisionId = e.target.value === "" ? null : e.target.value;
                                    filter.seasonId = null;
                                    this.props.updateFilter(filter);
                                }}>
                                    <option value="">{t('all')}</option>
                                    {divisionArr.map((val,idx) => (
                                    <option key={idx} value={val.id}>{val.title}</option>
                                    ))}
                                </Input>
                            </div>
                        </div> */}
                        <div className='form-edit-row row'>
                            <div className='form-edit-label col-12 col-sm-5'>
                            {t('status')}
                            </div>
                            <div className='form-edit-entry col-12 col-sm-7'>
                                <Input type="select" value={filter.status ? filter.status : ""} onChange={e => {
                                    filter.status = e.target.value === "" ? null : e.target.value;
                                    this.props.updateFilter(filter);
                                }}>
                                    <option value="">{t('all')}</option>
                                    <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                    <option value={STATUS_SCHEDULE}>{t('schedule')}</option>
                                    <option value={STATUS_FINISHED}>{t('finished')}</option>
                                    <option value={STATUS_REGISTER}>{t('register')}</option>
                                </Input>
                            </div>
                        </div>
                        <h3>{t('championships')}</h3>
                        <div className="samba-seasons-list">
                            <div className={!filter.seasonId ? "samba-season-item-selected" : "samba-season-item"} onClick={e=> {
                                filter.seasonId = null;
                                filter.teamId = null;
                                this.props.updateFilter(filter);
                            }}>
                            {t('all')}
                            </div>
                            {seasonArr.map((val,idx)=>(
                            <div key={idx} className={filter.seasonId === val.id ? "samba-season-item-selected" : "samba-season-item"} onClick={e=> {
                                filter.seasonId = val.id;
                                filter.teamId = null;
                                this.props.updateFilter(filter);
                            }}>
                            {val.title + (dict.divisions && dict.divisions[val.divisionId] ? " / " + dict.divisions[val.divisionId].title : "")}
                            </div>
                            ))}
                        </div>
                    <div className='text-center'>
                        <Button className={"btn-cancel"} onClick={e=> {
                            this.setState({menuOpen: false});
                        }}>
                            {t('close')}
                        </Button>
                    </div>
                    </div>
                </div>
                )}
                <div className="samba-header-main">
                    <img alt="samba-bg" className="samba-header-image" src={bg} />
                    <div className="samba-header-logo-wrapper container">
                        <img alt="samba-logo" className="samba-header-logo" src={logo} />
                    </div>
                    <div className="samba-nav container">
                        
                        <SambaNavigation
                            location={location} 
                            organization={organization} 
                            history={history}
                            loading={loading}
                            itemId={itemId}
                            onAuthUpdate={(auth, state)=> {
                                this.setState({showAuth: auth, authState: state});
                            }} />
                    </div>
                </div>
                {(authMsg || showAuth) && (
                    <div>{this.renderAuthForm()}</div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    const { filter, dict } = state.filterData;
    return { isAdmin, loggingIn, user, filter, dict };
}

const actionCreators = {
    updateFilter: filterActions.updateFilter,
};

const connectedNavigation = connect(mapState, actionCreators)(SambaHeader);
export { connectedNavigation as SambaHeader };
  
