import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';

import { getOrgData, getOrgDomain, replaceUrl } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import {OrganizationSeasonOverview} from 'components/detail/organization/landing/OrganizationSeasonOverview';
import OrganizationSeasonGames from 'components/detail/organization/landing/OrganizationSeasonGames';
import OrganizationSeasonStanding from 'components/detail/organization/landing/OrganizationSeasonStanding';
import OrganizationSeasonStatistics from 'components/detail/organization/landing/OrganizationSeasonStatistics';
import SeasonBadge from 'components/detail/organization/landing/SeasonBadge';
import { 
    FormGroup,
    Input,
    Row,
    Col,
} from 'reactstrap';

import logo200Image from 'assets/img/logo/live_192.png';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import CustomTabView from 'components/template/CustomTabView';
import { GENDER_COED, GENDER_FEMALE, GENDER_MALE, STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from 'utils/constants';
import { filterActions } from 'redux/_actions/filter.actions';

class OrgSeasons extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            seasonChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            statusChoice: STATUS_ONGOING,
            mainTab: 0,
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const seasonId = this.props.router.params.seasonId;
            this.setState({id: id, seasonId: seasonId});
        }
        if (window.location.search && window.location.search.includes('payment_result=true')) {
            alert("Payment sent to owner sccuessfully. You will get into season once he accepts it.");
        } else if (window.location.search && window.location.search.includes('payment_result=false')) {
            alert("Payment not sent to owner sccuessfully. You should retry later.");
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let seasonId = null;
        if (this.props.router != null && this.props.router.params != null) {
            seasonId = this.props.router.params.seasonId;
        }
        const params = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizationLandingQuery', params, ((data)=>{

            const organizations = {};
            const organization = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const myTeams = {};
            const cards = {};
            const wallet = {};
            const seasonChoice = {};

            if (data.success) {
                this.props.updateDict(data);
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                if (organizations[id] != null) {
                    for (const [key, value] of Object.entries(organizations[id])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    if (value.organizationId === organization.id) {
                        seasons[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.myTeams)) {
                    myTeams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [, value] of Object.entries(data.wallet)) {
                    for (const [k, v] of Object.entries(value)) {
                        wallet[k] = v;
                    }
                }
                if (seasons[seasonId]) {
                    for (const [key, value] of Object.entries(seasons[seasonId])) {
                        seasonChoice[key] = value;
                    }
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                myTeams: myTeams,
                posts: posts,
                locations: locations,
                users: users,
                category: category,
                cards: cards,
                wallet: wallet,
                seasonChoice: seasonChoice,
                loading: false,
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderDetails() {
        const {user, history} = this.props;
        const {organization, mainTab, wallet, seasonChoice, seasons, divisions, posts, users, teams, myTeams, profileData, locations, cards} = this.state;
        const seasonId = this.props.router.params.seasonId;

        switch (mainTab) {
            case 0:
                return (
                    <OrganizationSeasonOverview
                        organization={organization}
                        seasons={seasons}
                        users={users}
                        posts={posts}
                        user={user}
                        history={history}
                        myTeams={myTeams}
                        teams={teams}
                        profileData={profileData}
                        locations={locations}
                        divisions={divisions}
                        seasonId={seasonId}
                        wallet={wallet}
                        seasonChoice={seasonChoice}
                        onSeasonChoice={(update)=> {
                            this.setState({seasonChoice: update})
                        }}
                        onChanged={e=> {
                            this.loadContents();
                        }} />
                );
            case 1:
                return (
                    <OrganizationSeasonGames
                        organization={organization}
                        seasons={seasons}
                        divisions={divisions}
                        posts={posts}
                        users={users}
                        teams={teams}
                        locations={locations}
                        seasonId={seasonId}
                        cards={cards}
                        seasonChoice={seasonChoice} />
                );
            case 2:
                return (
                    <OrganizationSeasonStanding
                        organization={organization}
                        teams={teams}
                        locations={locations}
                        seasons={seasons}
                        seasonId={seasonId}
                        seasonChoice={seasonChoice}
                        divisions={divisions} />
                );
            case 3:
                return (
                    <OrganizationSeasonStatistics
                        organization={organization}
                        seasons={seasons}
                        users={users}
                        seasonId={seasonId}
                        seasonChoice={seasonChoice}
                        divisions={divisions} />
                );
            default:
                return <div/>;
        }
    }

    renderSeasonBadge(season) {
        const {organizations, organization, divisions, profileData } = this.state;
        
        return (
            <Col lg={4} sm={6} xs={12} key={season.id} className='grid-item-wrapper'>
                <SeasonBadge 
                    key={season.id}
                    season={season}
                    divisions={divisions}
                    organizations={organizations}
                    profileData={profileData}
                    allowDetail={true}
                    onClickDetails={(division)=> {
                        replaceUrl(`${getOrgDomain(organization)}/championships/${season.id}`);
                        this.setState({seasonChoice: season});
                    }}
                    onChanged={()=> {
                        this.loadContents()
                    }} />
            </Col>
        );
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    renderHeaderTab() {
        const {organization, divisions, seasonChoice, mainTab, seasons} = this.state;
        
        const divisionChoice = divisions[seasonChoice.divisionId];
        const icon = divisionChoice.thumbUri != null ? divisionChoice.thumbUri : logo200Image;
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if (value.organizationId === organization.id)  {
                seasonsChoice[key] = value;
            }
        }

        return (
            <Row style={{margin: 0, padding: 0}}>
                <Col lg={3} sm={3} xs={12} className='championship-header'>
                    <img src={icon} className='championship-header-icon wrap-content-wrap-child' alt="logo" />
                    <FormGroup className='wrap-content-fill-child' style={{margin: '5px 10px 5px 0px'}}>
                        <Input type="select" name="select" value={seasonChoice.id} id="challengeChoice" onChange={e=>{
                                const id = e.target.value;
                                const season = seasons[id] ? seasons[id] : {};
                                const path = season.id ? `/championships/${season.id}` : '/championships';
                                replaceUrl(`${getOrgDomain(organization)}${path}`);
                                this.setState({seasonChoice: season})
                            }}>
                            <option key='null' value={null}>{t('championships')}</option>
                            {Object.values(seasonsChoice).map((season)=> (
                                <option key={season.id} value={season.id}>{season.title} / {divisions[season.divisionId].title}</option>
                            )) }
                        </Input>
                    </FormGroup>
                </Col>
                <Col lg={9} sm={9} xs={12} className='championship-nav'>
                    <CustomTabView
                        value={mainTab}
                        variant="scrollable"
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={(e,value) => {
                            this.setState({mainTab: value})
                        }}
                        items={seasonChoice && seasonChoice.status != null && seasonChoice.status !== STATUS_REGISTER ? [
                            {
                                label: t('overview')
                            },
                            {
                                label: t('games')
                            },
                            {
                                label: t('standings')
                            },
                            {
                                label: t('statistics')
                            },
                        ] : [
                            {
                                label: t('overview')
                            },
                        ]} />
                </Col>
            </Row>
        );
    }

    render() {
        const {loading, organization, seasonChoice, seasons, divisions, gender, statusChoice, category, categoryChoice} = this.state;
        const blueTheme = organization.landingData && organization.landingData.theme === 'blue';
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';

        if (loading || !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        
        if (seasonChoice.id != null) {
            return (
                <div>
                    {!sambaTheme && this.renderOrganizationHeader()}
                    <div className='main-page'>
                        {seasonChoice.id && (
                        <div>{this.renderHeaderTab()}</div>
                        )}
                        <div className='champions-divider'/>
                        {this.renderDetails()}
                    </div>
                </div>
            )
        } else {
            let arr = Object.values(seasons);
            arr.sort((a,b)=> {
                return a.startTime - b.startTime;
            })
            let items = [];
            for (const val of arr) {
                let division = divisions[val.divisionId];
                if (division == null) {
                    division = {};
                }
                if ((gender == null || gender === 'Select All' || gender === division.gender) && 
                    (statusChoice == null || statusChoice === 'Select All' || val.status === statusChoice || (statusChoice === STATUS_REGISTER && val.status == null)) && 
                    (categoryChoice == null || categoryChoice === 'Select All' || division.category.id === categoryChoice)) {
                    items.push(
                        this.renderSeasonBadge(val)
                    )
                }
            }
            return (
                <div>
                    {!sambaTheme && this.renderOrganizationHeader()}
                    <div className='main-page container'>
                        <h3 className='row-header-title-big'>
                        {t('championships')}
                        </h3>
                        <Row>
                            <Col sm={4} xs={12}>
                                <div className='form-edit-row wrap-content-parent'>
                                    <div className='form-edit-label wrap-content-wrap-child' style={blueTheme ? {color: 'white'} : {}}>
                                    Category
                                    </div>
                                    <div className='wrap-content-fill-child'>
                                        <select className='form-control' name="text" id="title" onChange={e => {
                                            this.setState({categoryChoice: e.target.value});
                                        }} value={categoryChoice}>
                                            <option value={null}>Select All</option>
                                            {Object.values(category).map((val,idx)=>(
                                            <option key={idx} value={val.id}>{val.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} xs={12}>
                                <div className='form-edit-row wrap-content-parent'>
                                    <div className='form-edit-label wrap-content-wrap-child' style={blueTheme ? {color: 'white'} : {}}>
                                    {t('gender')}
                                    </div>
                                    <div className='wrap-content-fill-child'>
                                        <select className='form-control' name="text" id="title" onChange={e => {
                                            this.setState({gender: e.target.value});
                                        }} value={gender}>
                                            <option value={null}>{t('select_all')}</option>
                                            <option value={GENDER_MALE}>{t('male')}</option>
                                            <option value={GENDER_FEMALE}>{t('female')}</option>
                                            <option value={GENDER_COED}>{t('coed')}</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} xs={12}>
                                <div className='form-edit-row wrap-content-parent'>
                                    <div className='form-edit-label wrap-content-wrap-child' style={blueTheme ? {color: 'white'} : {}}>
                                    Status
                                    </div>
                                    <div className='wrap-content-fill-child'>
                                        <select className='form-control' name="text" id="title" onChange={e => {
                                            this.setState({statusChoice: e.target.value});
                                        }} value={statusChoice}>
                                            <option value={null}>{t('select_all')}</option>
                                            <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                            <option value={STATUS_SCHEDULE}>{t('schedule')}</option>
                                            <option value={STATUS_FINISHED}>{t('finished')}</option>
                                            <option value={STATUS_REGISTER}>{t('register')}</option>
                                        </select>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className='divider'/>
                        <Row>
                        {items}
                        </Row>
                    </div>
                </div>
            )
        }
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    updateDict: filterActions.updateDict,
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgSeasons));
export { connectedPage as OrgSeasons };
