import React, { Component } from "react";
import {
    CardImg, Col, Row
} from 'reactstrap';

export class TableRowTinay extends Component {
    
    render() {
        const {title, subView, icon } = this.props;
        
        if (title != null) {
            return (
                <Row style={{margin: 0}}>
                    <Col xs={4} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={icon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {title}
                            </h4>
                        </div>
                    </Col>
                    <Col xs={8} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                        {subView}
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default TableRowTinay;