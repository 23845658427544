import React, { Component } from "react";
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import {
    CardBody, CardImg, Button, Form, Label, Input, Spinner, ListGroupItem, Row, Col
} from 'reactstrap';
import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';
import RequiredOption from 'components/detail/RequiredOption';
import defaultImage from 'assets/img/icons/video_default.png';
import defaultScoreImage from 'assets/img/icons/score_icon.png';
import defaultFoulImage from 'assets/img/icons/whistle.png';
import defaultEventImage from 'assets/img/icons/event_seat.png';
import defaultRoundImage from 'assets/img/icons/round_icon.png';
import SubNavigation from 'components/detail/SubNavigation';
import DivisionCong from 'components/edit/organization/DivisionCong';
import { 
    RiDeleteBin2Line
} from 'react-icons/ri';
import { 
    FcTimeline
} from 'react-icons/fc';
import { 
    GiRuleBook
} from 'react-icons/gi';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

class DivisionRules extends Component {
    constructor(props) {
        super(props);
        const category = {};
        if (props.category != null) {
            for (const [key, value] of Object.entries(props.category)) {
                category[key] = value;
            }
        }

        let tags = [];
        let strs = category.tags != null ? category.tags.split(',') : [];

        for (var i = 0; i < strs.length; i ++) {
            tags.push({id: strs[i], text: strs[i]});
        }
        
        this.state = {
            category: category,
            progress: false,
            newImage: {},
            imageProgress: 0,
            tags: tags,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.saveScorePhotoUpdate = this.saveScorePhotoUpdate.bind(this);
        this.saveFoulPhotoUpdate = this.saveFoulPhotoUpdate.bind(this);
        this.saveEventPhotoUpdate = this.saveEventPhotoUpdate.bind(this);
        this.saveRoundPhotoUpdate = this.saveRoundPhotoUpdate.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, category} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        let tags = [];
        let strs = category.tags != null ? category.tags.split(',') : [];

        for (var i = 0; i < strs.length; i ++) {
            tags.push({id: strs[i], text: strs[i]});
        }
        
        if ((prevProps.category !== category)) {
            const categoryVal = {};
            for (const [key, value] of Object.entries(category)) {
                categoryVal[key] = value;
            }
            this.setState({
                category: categoryVal,
                tags: tags,
            })
        }
    }

    onSaveClick(category, newImage, tags) {
        const objRef = this;
        if (category.title == null || category.title === '') {
            alert("You must input title");
        } else if (category.description == null || category.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, imageProgress: 0});
            if (category.id == null || category.id === '') {
                category.id = generateId("/category");
            }
            category.timestamp = dbTimestamp();
            
            let tagStr = "";
            for (var i = 0; i < tags.length; i ++) {
                tagStr += (tagStr === "" ? "" : ",") + tags[i].text;
            }
            category.tags = tagStr;
            
            this.saveChangeWithImage(category, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    category: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(category, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(category.id, 'category', newImage, (url) => {
                alert('Division Rules updated successfully.')
                category.thumbUri = url;
                objRef.saveChange(category, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(category, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    category: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(category, success, failure) {
        writeDb('/category/' + category.id, category, error => {
            if (error) {
                failure(error);
            } else {
                success(category);
            }
        })
    }

    handleDelete(i) {
        const { tags } = this.state;
        this.setState({
         tags: tags.filter((tag, index) => index !== i),
        });
    }

    handleAddition(tag) {
        this.setState(state => ({ tags: [...state.tags, tag] }));
    }

    handleDrag(tag, currPos, newPos) {
        const tags = [...this.state.tags];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        this.setState({ tags: newTags });
    }

    saveScorePhotoUpdate(scoreItem, newImage) {
        const {division, onChanged} = this.props;
        const {category} = this.state;
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            const path = 'division/' + division.id + '/category/scoreData/' + scoreItem.id;
            
            this.props.saveChangeWithImage(scoreItem, path
                , newImage, 'scoreData', scoreItem.id, 'thumbUri', (update)=> {
                    alert('Division Rules updated successfully.')
                    if (category.scoreData == null) {
                        category.scoreData = {};
                    }
                    category.scoreData[scoreItem.id] = update;
                    try {
                        if (onChanged != null) {
                            onChanged();
                        }
                        objRef.setState({
                            category: category
                        })
                    } catch (err) {
                        console.log(err);
                    }
                });
        }
    }
    
    saveFoulPhotoUpdate(value, newImage) {
        const {division, onChanged} = this.props;
        const {category} = this.state;
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            const path = 'division/' + division.id + '/category/foulData/' + value.id;
            
            this.props.saveChangeWithImage(value, path
                , newImage, 'foulData', value.id, 'thumbUri', (update)=> {
                    alert('Division Rules updated successfully.')
                    if (category.foulData == null) {
                        category.foulData = {};
                    }
                    category.foulData[value.id] = update;
                    try {
                        if (onChanged != null) {
                            onChanged();
                        }
                        objRef.setState({
                            category: category
                        })
                    } catch (err) {
                        console.log(err);
                    }
                });
        }
    }
    
    saveEventPhotoUpdate(value, newImage) {
        const {division, onChanged} = this.props;
        const {category} = this.state;
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            const path = 'division/' + division.id + '/category/eventData/' + value.id;
            
            this.props.saveChangeWithImage(value, path
                , newImage, 'eventData', value.id, 'thumbUri', (update)=> {
                    alert('Division Rules updated successfully.')
                    if (category.eventData == null) {
                        category.eventData = {};
                    }
                    category.eventData[value.id] = update;
                    try {
                        if (onChanged != null) {
                            onChanged();
                        }
                        objRef.setState({
                            category: category
                        })
                    } catch (err) {
                        console.log(err);
                    }
                });
        }
    }
    
    saveRoundPhotoUpdate(value, newImage) {
        const {division, onChanged} = this.props;
        const {category} = this.state;
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            const path = 'division/' + division.id + '/category/roundData/' + value.id;
            
            this.props.saveChangeWithImage(value, path
                , newImage, 'roundData', value.id, 'thumbUri', (update)=> {
                    alert('Division Rules updated successfully.')
                    if (category.roundData == null) {
                        category.roundData = {};
                    }
                    category.roundData[value.id] = update;
                    try {
                        if (onChanged != null) {
                            onChanged();
                        }
                        objRef.setState({
                            category: category
                        })
                    } catch (err) {
                        console.log(err);
                    }
                });
        }
    }
    
    renderScores() {
        const { category, progress, imageProgress } = this.state;

        const scoreData = category.scoreData != null ? category.scoreData : {};

        let items = [];

        for (const [key, value] of Object.entries(scoreData)) {
            items.push(
                <ListGroupItem key={key}>
                    <Row>
                        <Col sm={4}>
                            <HorizontalAttachImageEdit
                                imageUrl={value.thumbUri}
                                defaultImage={defaultScoreImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.saveScorePhotoUpdate(value, file);
                                }} />
                        </Col>
                        <Col sm={5}>
                            <Label>Score Title</Label>
                            <Input type="text" name="text" id="text" onChange={e => {
                                category.scoreData[key].title = e.target.value;
                                this.setState({category: category});
                            }} value={value.title} />
                        </Col>
                        <Col sm={2}>
                            <Label>Score Number</Label>
                            <Input type="number" name="text" id="text" onChange={e => {
                                category.scoreData[key].number = parseInt(e.target.value);
                                this.setState({category: category});
                            }} value={value.number} />
                        </Col>
                        <Col sm={1}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (window.confirm("Are you sure you want to remove this rule item?")) {
                                            delete category.scoreData[value.id]
                                        }
                                        this.setState({category: category});
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <SubNavigation
                title="Score rules"
                onClickAdd={()=>{
                    const newItem = {};
                    newItem.id = generateId('/category/' + category.id + '/scoreData');
                    newItem.title = 'Score';
                    newItem.number = 1;
                    newItem.type = 'score';
                    if (category.scoreData == null) {
                        category.scoreData = {};
                    }
                    category.scoreData[newItem.id] = newItem;
                    this.setState({category: category});
                }} />
            {items}
        </div>
        );
    }

    renderFouls() {
        const { category, progress, imageProgress } = this.state;

        const foulData = category.foulData != null ? category.foulData : {};

        let items = [];

        for (const [key, value] of Object.entries(foulData)) {
            items.push(
                <ListGroupItem key={key}>
                    <Row>
                        <Col sm={4}>
                            <HorizontalAttachImageEdit
                                imageUrl={value.thumbUri}
                                defaultImage={defaultFoulImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.saveFoulPhotoUpdate(value, file);
                                }} />
                        </Col>
                        <Col sm={5}>
                            <Label>Foul Title</Label>
                            <Input type="text" name="text" id="text" onChange={e => {
                                category.foulData[key].title = e.target.value;
                                this.setState({category: category});
                            }} value={value.title} />
                        </Col>
                        <Col sm={2}>
                            <Label>Foul Number</Label>
                            <Input type="number" name="text" id="text" onChange={e => {
                                category.foulData[key].number = parseInt(e.target.value);
                                this.setState({category: category});
                            }} value={value.number} />
                        </Col>
                        <Col sm={1}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (window.confirm("Are you sure you want to remove this rule item?")) {
                                            delete category.foulData[value.id]
                                        }
                                        this.setState({category: category});
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <SubNavigation
                title="Foul rules"
                onClickAdd={()=>{
                    const newItem = {};
                    newItem.id = generateId('/category/' + category.id + '/foulData');
                    newItem.title = 'Foul';
                    newItem.number = 1;
                    newItem.type = 'foul';
                    if (category.foulData == null) {
                        category.foulData = {};
                    }
                    category.foulData[newItem.id] = newItem;
                    this.setState({category: category});
                }} />
            {items}
        </div>
        );
    }

    renderEvents() {
        const { category, progress, imageProgress } = this.state;

        const eventData = category.eventData != null ? category.eventData : {};

        let items = [];

        for (const [key, value] of Object.entries(eventData)) {
            items.push(
                <ListGroupItem key={key}>
                    <Row>
                        <Col sm={4}>
                            <HorizontalAttachImageEdit
                                imageUrl={value.thumbUri}
                                defaultImage={defaultEventImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.saveEventPhotoUpdate(value, file);
                                }} />
                        </Col>
                        <Col sm={5}>
                            <Label>Event Title</Label>
                            <Input type="text" name="text" id="text" onChange={e => {
                                category.eventData[key].title = e.target.value;
                                this.setState({category: category});
                            }} value={value.title} />
                        </Col>
                        <Col sm={2}>
                            <Label>Event Number</Label>
                            <Input type="number" name="text" id="text" onChange={e => {
                                category.eventData[key].number = parseInt(e.target.value);
                                this.setState({category: category});
                            }} value={value.number} />
                        </Col>
                        <Col sm={1}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (window.confirm("Are you sure you want to remove this rule item?")) {
                                            delete category.eventData[value.id]
                                        }
                                        this.setState({category: category});
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <SubNavigation
                title="Event rules"
                onClickAdd={()=>{
                    const newItem = {};
                    newItem.id = generateId('/category/' + category.id + '/eventData');
                    newItem.title = 'Event';
                    newItem.number = 1;
                    newItem.type = 'event';
                    if (category.eventData == null) {
                        category.eventData = {};
                    }
                    category.eventData[newItem.id] = newItem;
                    this.setState({category: category});
                }} />
            {items}
        </div>
        );
    }

    renderRounds() {
        const { category, progress, imageProgress } = this.state;

        const roundData = category.roundData != null ? category.roundData : {};

        let items = [];

        for (const [key, value] of Object.entries(roundData)) {
            items.push(
                <ListGroupItem key={key}>
                    <Row>
                        <Col sm={4}>
                            <HorizontalAttachImageEdit
                                imageUrl={value.thumbUri}
                                defaultImage={defaultRoundImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.saveRoundPhotoUpdate(value, file);
                                }} />
                        </Col>
                        <Col sm={2}>
                            <Label>Round Title</Label>
                            <Input type="text" name="text" id="text" onChange={e => {
                                category.roundData[key].title = e.target.value;
                                this.setState({category: category});
                            }} value={value.title} />
                        </Col>
                        <Col sm={1}>
                            <Label>Number</Label>
                            <Input type="number" name="text" id="text" onChange={e => {
                                category.roundData[key].number = parseInt(e.target.value);
                                this.setState({category: category});
                            }} value={value.number} />
                        </Col>
                        <Col sm={2}>
                            <Label>Round Time(Min)</Label>
                            <Input type="number" name="text" id="text" onChange={e => {
                                category.roundData[key].time = parseFloat(e.target.value);
                                this.setState({category: category});
                            }} value={value.time} />
                        </Col>
                        <Col sm={2}>
                            <div>Option: {value.option}</div>
                            <RequiredOption 
                                value={value.option}
                                onChanged={update=>{
                                    category.roundData[key].option = update;
                                    this.setState({category: category});
                                }}/>
                        </Col>
                        <Col sm={1}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (window.confirm("Are you sure you want to remove this round?")) {
                                            delete category.roundData[value.id]
                                        }
                                        this.setState({category: category});
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        }

        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <SubNavigation
                title="GAME TIMES CONFIGURATION"
                icon={<FcTimeline/>}
                onClickAdd={()=>{
                    const newItem = {};
                    newItem.id = generateId('/category/' + category.id + '/roundData');
                    newItem.title = 'Round';
                    newItem.number = 1;
                    newItem.type = 'round';
                    if (category.roundData == null) {
                        category.roundData = {};
                    }
                    category.roundData[newItem.id] = newItem;
                    this.setState({category: category});
                }} />
            {items}
        </div>
        );
    }

    render() {
        const {division, organization, uid, onChanged, onChangeClick} = this.props;
        const {category, progress} = this.state;
        let categoryImage = category.thumbUri != null ? category.thumbUri : defaultImage;
        
        if (category != null) {
            return (
                <div>
                    <SubNavigation
                        title="DIVISION RULES"
                        icon={<GiRuleBook color='#04f'/>} />
                    <div className='shadow-tile' style={{backgroundColor: 'white', marginTop: 20}}>
                        <div className="wrap-content-parent">
                            <CardImg
                                className="profile-thumb vertical-center-spacing"
                                src={categoryImage}
                                style={{ width: 60, height: 60, marginLeft: 10, marginRight: 10 }}
                                />
                            <CardBody>
                                <h4 className='label label-default'>
                                {category.title}
                                </h4>
                            </CardBody>
                        </div>
                        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }} className='category-info'>
                        Our templates will give you a default setup for your division rules, such as game times, scoring system, sanctions and yellow cards.<b/>
                        You can edit all these sections below to meet your league/tournament needs.
                        </div>
                    </div>
                    <Row>
                        <Col sm={6}>
                            <SubNavigation
                                title="General rules" />
                            <Form className='form-edit'>
                                <Row className='form-edit-row'>
                                    <Col className='form-edit-label' sm={5} xs={12}>
                                    Player Count
                                    </Col>
                                    <Col className='form-edit-entry' sm={7} xs={12}>
                                        <div className='form-edit-input'>
                                            <Input type="number" name="text" id="title" onChange={e => {
                                                category.playerCount = parseInt(e.target.value);
                                                this.setState({category: category});
                                            }} value={category.playerCount} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='form-edit-row'>
                                    <Col className='form-edit-label' sm={5} xs={12}>
                                    Substitutes Count
                                    </Col>
                                    <Col className='form-edit-entry' sm={7} xs={12}>
                                        <div className='form-edit-input'>
                                            <Input type="number" name="text" id="title" onChange={e => {
                                                category.benchCount = parseInt(e.target.value);
                                                this.setState({category: category});
                                            }} value={category.benchCount} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='form-edit-row'>
                                    <Col className='form-edit-label' sm={5} xs={12}>
                                    Time Limit(Min)
                                    </Col>
                                    <Col className='form-edit-entry' sm={7} xs={12}>
                                        <div className='form-edit-input'>
                                            <Input type="number" name="text" id="title" onChange={e => {
                                                category.timeLimit = parseFloat(e.target.value);
                                                this.setState({category: category});
                                            }} value={category.timeLimit} />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col sm={6}>
                            <DivisionCong
                                division={division}
                                organization={organization}
                                uid={uid}
                                onCloseClick={()=> {
                                    this.setState({editOpen: false});
                                }}
                                onSaveDone={()=> {
                                    if (onChanged != null) {
                                        onChanged();
                                    }
                                }} />
                        </Col>
                    </Row>
                    {this.renderScores()}
                    {this.renderFouls()}
                    {this.renderEvents()}
                    {this.renderRounds()}
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div className='text-center'>
                            <Button className='btn-submit' onClick={e => {
                                if (onChangeClick != null) {
                                    onChangeClick(category);
                                }
                            }}>Update Rules</Button>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = connect(mapState, actionCreators)(DivisionRules);
export { connectedApp as DivisionRules };
