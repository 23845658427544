import React, { Component } from "react";
import AttachItemView from 'components/detail/AttachItemView';
import {
    FormGroup, Label, Input, Progress
} from 'reactstrap';

export class AttachItemEdit extends Component {
    render() {
        const {attachFile, allowProgress, label, progress, onChange} = this.props;
        
        return (
            <FormGroup>
                {attachFile && attachFile.url != null && attachFile.url !== '' && (
                    <AttachItemView attachFile={attachFile} />
                )}
                <Label for="videoFile">{label != null ? label : 'Attach File'}</Label>
                <Input type="file" name="file" id="videoFile" onChange={e => {                            
                    if (onChange != null && e.target.files != null && e.target.files.length > 0) {
                        onChange(e.target.files[0]);
                    }
                }} />
                {allowProgress && (
                    <Progress className='progress-style-file' color="warning" value={progress} />
                )}
            </FormGroup>
        );
    }
}

export default AttachItemEdit;