import React, { Component } from "react";
import {
    Card, CardBody, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class FaqHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {faq, onClickDetails, onClickEdit } = this.props;
        
        if (faq != null) {
            return (
                <div>
                    <Card className="flex-row shadow-tile">
                        <CardBody>
                            <div className='wrap-content-parent'>
                                <h4 className='label label-default wrap-content-fill-child vertical-center-spacing'>
                                {faq.title}
                                </h4>
                                <div className="faq-progress wrap-content-wrap-child">
                                    <Button className="btn-submit" onClick={e => {
                                        if (onClickDetails != null) {
                                            onClickDetails();
                                        }
                                    }}>Details</Button>
                                    {onClickEdit && (
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                    )}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(FaqHeader);