import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';
import { t } from 'i18next';

import { toDateString, toSimpleTime } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import { getShortName } from "utils/constants";

export class SambaGameBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    render() {
        const { seasons, divisions, game, teams, locations, users, onClickDetail } = this.props;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const season = seasons && seasons[game.seasonId] ? seasons[game.seasonId] : {};
        const seeasonTitle = season.title ? season.title : "-";
        const division = divisions && divisions[season.divisionId] ? divisions[season.divisionId] : {};
        const divisionTitle = division.title ? division.title : "-";
        const timeStr = toSimpleTime(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        
        return (
            <div className="samba-game-badge" onClick={e => {
                if (onClickDetail != null) {
                    onClickDetail();
                }
            }}>
                <div className="samba-game-badge-date">
                    <div className="wrap-content-fill-child">{toDateString(game.start)}</div>
                    <div className="wrap-content-wrap-child">{timeStr}</div>
                </div>
                <div className="samba-game-badge-main">
                    <div className="samba-game-badge-team">{getShortName(homeTeam.title)}</div>
                    <CardImg
                        className="samba-game-badge-icon"
                        src={homeThumb} />
                    <div className="samba-game-badge-score">
                        {game.homeScore && game.homeScore !== -1 ? game.homeScore : "0"} - {game.awayScore && game.awayScore !== -1 ? game.awayScore : "0"}
                    </div>
                    <CardImg
                        className="samba-game-badge-icon"
                        src={awayThumb} />
                    <div className="samba-game-badge-team">{getShortName(awayTeam.title)}</div>
                </div>
                <div className="samba-game-badge-tag">#{location.title ? location.title : t('no_location')}</div>
                <div className="samba-game-badge-class">{seeasonTitle + "/" + divisionTitle}</div>
            </div>
        );
    }
}

export default SambaGameBadge;