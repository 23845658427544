const grid = 1;
export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    for (var i = 0; i < result.length; i ++) {
        result[i].league.index = i;
    }

    return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? "lightgrey" : "transparent",

    ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "transparent",
    padding: grid,
    width: '100%'
});
