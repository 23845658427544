import React, { Component } from 'react';
import { 
    Row,
    Col,
    CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/player_photo_default.png';

export class SeasonGoals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: {},
            users: {},
            category: {},
            divisionChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }

    getScoresArr(subSeason) {
        const {users} = this.props;
        
        let result = {};
        if (subSeason.games != null) {
            for (const game of Object.values(subSeason.games)) {
                if (game.scores != null) {
                    for (const score of Object.values(game.scores)) {
                        let user = users[score.uid] != null ? users[score.uid] : {};
                        let item = result[score.uid] != null ? result[score.uid] : {uid: score.uid, score: 0, assist: 0};
                        item.user = user;
                        item.score += 1;
                        result[score.uid] = item;
                    }
                }
            }
            for (const game of Object.values(subSeason.games)) {
                if (game.scores != null) {
                    for (const score of Object.values(game.scores)) {
                        if (score.assist) {
                            let user = users[score.assist] != null ? users[score.assist] : {};
                            let item = result[score.assist] != null ? result[score.assist] : {uid: score.assist, score: 0, assist: 0};
                            item.user = user;
                            item.assist += 1;
                            result[score.assist] = item;
                        }
                    }
                }
            }
        }
        return Object.values(result);
    }
    
    renderContents() {
        const {season} = this.props;

        let items = [];
        for (const value of Object.values(season.subSeasons != null ? season.subSeasons : {})) {
            
            items.push(
                this.renderItems(value)
            );
        }
        return (
        <div>
            {items}
        </div>
        );
    }

    renderItems(subSeason) {        
        let pointItems = this.getScoresArr(subSeason);
        pointItems.sort((x,y)=>{
            const a = x.user;
            const b = y.user;
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        pointItems.sort((a,b)=> {
            return b.score - a.score;
        })

        let items = [];
        let rank = 0;
        for (const val of pointItems) {
            rank ++;
            items.push(
                this.renderBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Cards / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('goal')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('assist')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderBadge(item, rank) {        
        let thumbImage = item.user.photoUri != null ? item.user.photoUri : defaultImage;

        return (
            <Row>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {item.user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.score}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.assist}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    render() {
        const {hideTitle, season} = this.props;
        
        return (
            <div>
            {!hideTitle && (
                <h3 style={{marginTop: 30}}>{season.title} Cards</h3>
            )}
                {this.renderContents()}
            </div>
        );
    }
}

export default withTranslation()(SeasonGoals)
