import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';

import { 
    getProfileData,
    getTimeString,
} from 'utils/Utils';
import {
    HiSwitchHorizontal,
    HiBookmark
} from 'react-icons/hi';

export class EventRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, event, icon, users, game} = this.props;
        let user = getProfileData(organization, users, event.uid, event.teamId);
        let secUser = getProfileData(organization, users, event.secUid, event.teamId);
        
        if (event != null) {
            if (event.teamId === game.awayTeam) {
                return (
                    <div style={{borderBottom: '1px solid rgba(0,0,0,0.1)'}}>
                    <div className="wrap-content-parent">
                        <div className="wrap-content-fill-child"/>
                        <div className="wrap-content-wrap-child">
                        {(event.type === "event" || event.type === "score" || event.type === "foul") && (
                            <div className='event-info-group'>
                                <div className="event-info-text"><b>{getTimeString(event.roundTime)}</b></div>
                                <div className="event-info-text"><b>{user.number}</b> {user.username}</div>
                                <CardImg
                                    className="event-info-icon"
                                    src={user.icon} />
                                <h4 className='event-badge-title'>
                                {event.title}
                                </h4>
                                <CardImg
                                    className="event-badge-icon"
                                    src={icon} />
                                {event.level && event.level !== 'normal' && (
                                <span className="event-info-text" style={{color: event.level === 'yellow' ? '#f0cb5b' : '#BE4534'}}>
                                    <HiBookmark/>
                                </span>
                                )}
                            </div>
                        )}
                        {(event.type === "substitute") && (
                            <div className='event-info-group'>
                                <div className="event-info-text">{event.roundTitle}:<b>{getTimeString(event.roundTime)}</b></div>
                                <div className="event-info-text"><b>{secUser.number}</b> {secUser.username}</div>
                                <b className="event-info-text"><HiSwitchHorizontal/></b>
                                <div className="event-info-text"><b>{user.number}</b> {user.username}</div>
                                <h4 className='event-badge-title'>
                                {event.title}
                                </h4>
                                <CardImg
                                    className="event-badge-icon"
                                    src={icon} />
                            </div>
                        )}
                        </div>
                    </div>
                    </div>
                );
            } else {
                return (
                    <div style={{borderBottom: '1px solid rgba(0,0,0,0.1)'}}>
                    {(event.type === "event" || event.type === "score" || event.type === "foul") && (
                        <div className='event-info-group'>
                            {event.level && event.level !== 'normal' && (
                            <span className="event-info-text" style={{color: event.level === 'yellow' ? '#f0cb5b' : '#BE4534'}}>
                                <HiBookmark/>
                            </span>
                            )}
                            <CardImg
                                className="event-badge-icon"
                                src={icon} />
                            <h4 className='event-badge-title'>
                            {event.title}
                            </h4>
                            
                            <CardImg
                                className="event-info-icon"
                                src={user.icon} />
                            <div className="event-info-text"><b>{user.number}</b> {user.username}</div>
                            <div className="event-info-text"><b>{getTimeString(event.roundTime)}</b></div>
                            
                        </div>
                    )}
                    {(event.type === "substitute") && (
                        <div className='event-info-group'>
                            <CardImg
                                className="event-badge-icon"
                                src={icon} />
                            <h4 className='event-badge-title'>
                            {event.title}
                            </h4>
                            <div className="event-info-text"><b>{user.number}</b> {user.username}</div>
                            <b className="event-info-text"><HiSwitchHorizontal/></b>
                            <div className="event-info-text"><b>{secUser.number}</b> {secUser.username}</div>
                            <div className="event-info-text">{event.roundTitle}:<b>{getTimeString(event.roundTime)}</b></div>
                        </div>
                    )}
                    </div>
                );
            }
        } else {
            return <div/>;
        }
    }
}

export default EventRow;