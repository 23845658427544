import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { CountryDropdown, } from 'react-country-region-selector';
import CustomDatePicker from "components/template/CustomDatePicker";
import AttachImageEdit from 'components/edit/AttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, invokeHttpsApi } from "utils/API";

export class ProfileEdit extends Component {
    constructor(props) {
        super(props);
        const profile = {};
        if (props.profile != null) {
            for (const [key, value] of Object.entries(props.profile)) {
                profile[key] = value;
            }
        }
        
        this.state = {
            profile: profile,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, profile} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.profile !== profile)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(profile)) {
                challengeVal[key] = value;
            }
            this.setState({
                profile: challengeVal
            })
        }
    }

    onSaveClick(profile, newImage) {
        const objRef = this;
        if (profile.username == null || profile.username === '') {
            alert("You must input username");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            profile.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(profile, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    profile: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(profile, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(profile.uid, 'user', newImage, (url) => {
                profile.photoUri = url;
                objRef.saveChange(profile, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(profile, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    profile: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(profile, success, failure) {
        const {onSaveSuccess, teamId} = this.props;
        if (teamId && profile.owner) {
            delete profile.newUser;
            invokeHttpsApi('userQuery-submitTeamPlayer', {
                user: profile,
                teamId: teamId,
            }, (data) => {
                if (data && data.success) {
                    if (onSaveSuccess != null) {
                        onSaveSuccess();
                    }
                    success(profile);
                } else {
                    failure(null);
                }
            }, (error) => {
                console.log('saveContents error', error);
                failure(error);
            });
        } else if (!teamId && profile.owner) {
            delete profile.newUser;
            invokeHttpsApi('userQuery-submitOwnedPlayer', {
                user: profile,
                teamId: teamId,
            }, (data) => {
                if (data && data.success) {
                    if (onSaveSuccess != null) {
                        onSaveSuccess();
                    }
                    success(profile);
                } else {
                    failure(null);
                }
            }, (error) => {
                console.log('saveContents error', error);
                failure(error);
            });
        } else {
            invokeHttpsApi('clubQuery-saveUserChange', {
                user: profile,
            }, (data) => {
                if (data && data.success) {
                    if (onSaveSuccess != null) {
                        onSaveSuccess();
                    }
                    success(profile);
                } else {
                    failure(null);
                }
            }, (error) => {
                console.log('saveContents error', error);
                failure(error);
            });
        }
    }

    submitTeamPlayerInvite(profile, email, targetId) {
        const {onSaveSuccess, teamId} = this.props;
        this.setState({inviteProgress: true});
        const data = {
            user: profile,
            teamId: teamId,
        }
        if (email) {
            data.email = email;
        }
        if (targetId) {
            data.targetId = targetId;
        }
        invokeHttpsApi('invite-submitTeamPlayerInvite', data, (data) => {
            if (data && data.success) {
                if (onSaveSuccess != null) {
                    onSaveSuccess();
                }
                alert("Sent invite successfully.");
            } else {
                alert("Sending invite failed");
            }
            this.setState({inviteProgress: false});
        }, (error) => {
            console.log('saveContents error', error);
            alert("Sending invite failed\n" + error);
            this.setState({inviteProgress: false});
        });
    }

    submitTeamChildInvite(profile, email, targetId) {
        const {onSaveSuccess, teamId} = this.props;
        this.setState({inviteProgress: true});
        const data = {
            user: profile,
            teamId: teamId,
        }
        if (email) {
            data.email = email;
        }
        if (targetId) {
            data.targetId = targetId;
        }
        invokeHttpsApi('invite-submitTeamChildInvite', data, (data) => {
            if (data && data.success) {
                if (onSaveSuccess != null) {
                    onSaveSuccess();
                }
                alert("Sent invite successfully.");
            } else {
                alert("Sending invite failed");
            }
            this.setState({inviteProgress: false});
        }, (error) => {
            console.log('saveContents error', error);
            alert("Sending invite failed\n" + error);
            this.setState({inviteProgress: false});
        });
    }

    render() {
        const {onCloseClick, teamId} = this.props;
        const {profile, inviteEmail, newImage, imageProgress, inviteProgress, progress} = this.state;
        
        if (profile != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="username">Full Name</Label>
                        <Input type="text" name="text" id="username" onChange={e => {
                            profile.username = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.username} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="username">Location</Label>
                        <Input type="text" name="text" id="username" onChange={e => {
                            profile.location = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.location} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="country" style={{maginTop: 2, marginRight: 8}}>{t('country')}</Label>
                        <CountryDropdown
                            value={profile.country}
                            onChange={(val) => {
                                profile.country = val;
                                this.setState({profile: profile});
                            }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="username">{t('bio')}</Label>
                        <Input type="textarea" name="text" id="username" onChange={e => {
                            profile.bio = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.bio} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="username">{t('phone_number')}</Label>
                        <Input type="text" name="text" id="username" onChange={e => {
                            profile.phoneNumber = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.phoneNumber} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="gender">{t('gender')}</Label>
                        <Input type="select" name="text" id="username" onChange={e => {
                            profile.gender = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.gender}>
                            <option value={null}>{t('no_choice')}</option>
                            <option value="male">{t('male')}</option>
                            <option value="female">{t('female')}</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="weight">{t('weight')}</Label>
                        <Input type="number" name="text" id="username" onChange={e => {
                            profile.weight = parseInt(e.target.value);
                            this.setState({profile: profile});
                        }} value={profile.weight} />
                    </FormGroup>
                    <FormGroup>
                        <CustomDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label={t('birthday')}
                            format="MM/DD/YYYY"
                            value={profile.birthday ? new Date(profile.birthday) : new Date()}
                            onChange={ date => {
                                profile.birthday = date.valueOf();
                                this.setState({profile: profile});
                            }} />
                    </FormGroup>
                    {teamId && !profile.newUser && (
                    <div>
                        <FormGroup>
                            <Label for="invite_email">{t('invite_email')}</Label>
                            <Input type="text" name="text" id="username" onChange={e => {
                                this.setState({inviteEmail: e.target.value});
                            }} value={inviteEmail} />
                        </FormGroup>
                        {inviteProgress ? (
                            <Spinner color="primary" />
                        ) : (
                            <div>
                                <Button className='btn-submit' onClick={e => {
                                    this.submitTeamPlayerInvite(profile, inviteEmail);
                                }}>{t('invite_email_assign')}</Button>
                                <Button className='btn-submit' onClick={e => {
                                    this.submitTeamChildInvite(profile, inviteEmail);
                                }}>{t('invite_email_child')}</Button>
                            </div>
                        )}
                    </div>
                    )}
                    <AttachImageEdit
                        imageUrl={profile.photoUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='btn-submit' onClick={e => {
                                this.onSaveClick(profile, newImage);
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ProfileEdit);