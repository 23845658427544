import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, invokeHttpsApi } from "utils/API";

export class TeamPlayerEdit extends Component {
    constructor(props) {
        super(props);
        const profile = {};
        if (props.profile != null) {
            for (const [key, value] of Object.entries(props.profile)) {
                profile[key] = value;
            }
        }
        
        this.state = {
            profile: profile,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, profile} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.profile !== profile)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(profile)) {
                challengeVal[key] = value;
            }
            this.setState({
                profile: challengeVal
            })
        }
    }

    onSaveClick(profile, newImage) {
        const objRef = this;
        if (profile.username == null || profile.username === '') {
            alert("You must input username");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            profile.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(profile, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    profile: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(profile, newImage, success) {
        const {onSavePrompt} = this.props;
        const objRef = this;
        if (onSavePrompt != null) {
            onSavePrompt(profile);
        }

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(profile.uid, 'user', newImage, (url) => {
                profile.photoUri = url;
                objRef.saveChange(profile, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(profile, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    profile: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(profile, success, failure) {
        const {seasonId, teamId, onSaveSuccess} = this.props;
        invokeHttpsApi('userQuery-submitTeamPlayer', {
            user: profile,
            teamId: teamId,
            seasonId: seasonId,
        }, (data) => {
            if (data && data.success) {
                if (onSaveSuccess != null) {
                    onSaveSuccess();
                }
                success(profile);
            } else {
                failure(null);
            }
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    render() {
        const {onCloseClick} = this.props;
        const {profile, newImage, imageProgress, progress} = this.state;
        
        if (profile != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="username">Full Name</Label>
                        <Input type="text" name="text" id="username" onChange={e => {
                            profile.username = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.username} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="username">Location</Label>
                        <Input type="text" name="text" id="username" onChange={e => {
                            profile.location = e.target.value;
                            this.setState({profile: profile});
                        }} value={profile.location} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={profile.photoUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(profile, newImage);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TeamPlayerEdit);