import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/video_thumb.png';

export class VideoBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {video, onClickDetails, onClickEdit } = this.props;
        
        if (video != null) {
            return (
                <div>
                    <Card className="shadow-tile">
                        <CardBody>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    <CardImg
                                        className="profile-thumb"
                                        src={defaultImage}
                                        style={{ width: 25, height: 25, marginRight: 10 }}
                                        />
                                    <div className='vertical-center-spacing' style={{fontSize: 14, fontWeight: 800}}>{video.title}</div>
                                </div>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <div className='vertical-center-spacing' style={{width: 120, fontSize: 14, fontWeight: 800}}>{video.type}</div>
                                    <Button className="btn-submit" onClick={e => {
                                        if (onClickDetails != null) {
                                            onClickDetails();
                                        }
                                    }}>Details</Button>
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(VideoBadge);