import React, { Component } from 'react';
import { 
    Row,
    Col,
} from 'reactstrap';
import { t } from 'i18next';

import { connect } from 'react-redux';
import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import {SeasonTeamDetails} from 'components/landing/team/SeasonTeamDetails';
import SeasonDivisionBadge from 'components/detail/organization/SeasonDivisionBadge';
import { 
    AiOutlineTeam,
} from 'react-icons/ai';
import { getTeamDomain, replaceUrl } from 'utils/Utils';
import { GENDER_COED, GENDER_FEMALE, GENDER_MALE, STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from 'utils/constants';

class TeamSeasons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            categorys: {},
            organizations: {},
            organizationChoice: {},
            seasonChoice: {},
            divisionChoice: {},
            teams: {},
            teamChoice: {},
            divisions: {},
            seasons: {},
            locations: {},
            users: {},
            editOpen: false,
            detailOpen: false,
            addOpen: false,
            pickTeam: false,
            editDivision: false,
            loading: true,
            tab: 1,
            mainTab: 0
        };
    }

    componentDidMount() {
        const {seasons, seasonId} = this.props;
        if (seasons && seasonId && seasons[seasonId]) {
            const update = {};
            for (const [k, v] of Object.entries(seasons[seasonId])) {
                update[k] = v;
            }
            this.setState({seasonChoice: update});
        }
    }

    render() {
        const {organizations, seasons, divisions, playerMode, team, 
            profileData, teams, users, cards, locations, user, child} = this.props;
        const {seasonChoice, detailOpen} = this.state;

        let members = {};
        for (const [key, value] of Object.entries(seasons)) {
            const isMember = value.teamIds != null && Object.values(value.teamIds).includes(team.id);

            if (isMember) {
                members[key] = value;
            }
        }
        if (seasonChoice.id) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <ItemsDrowpdownNav
                        title="Organization Seasons"
                        choiceTitle={t('choose_season')}
                        icon={<AiOutlineTeam/>}
                        dropdownItems={members}
                        editOpen={false}
                        detailOpen={detailOpen}
                        detailItem={seasonChoice}
                        onItemChoice={(value)=>{
                            this.setState({seasonChoice: value})
                            if (playerMode) {
                                replaceUrl(`${getTeamDomain(team)}/account/${value.id}`)
                            } else {
                                replaceUrl(`${getTeamDomain(team)}/coach/${value.id}`)
                            }
                        }}
                        onClickBack={()=>{
                            this.setState({seasonChoice: {}, detailOpen: false, editOpen: false})
                            if (playerMode) {
                                replaceUrl(`${getTeamDomain(team)}account`)
                            } else {
                                replaceUrl(`${getTeamDomain(team)}/coach`)
                            }
                        }} />

                    <SeasonTeamDetails 
                        organizations={organizations}
                        key={seasonChoice.id}
                        team={team}
                        playerMode={playerMode}
                        divisions={divisions}
                        teams={teams}
                        users={users}
                        user={child && child.uid ? child : user}
                        season={seasonChoice}
                        profileData={profileData}
                        locations={locations}
                        cards={cards}
                        allowDetail={true}
                        onCloseClick={()=> {
                            this.setState({seasonChoice: {}, detailOpen: false})
                        }}
                        onChanged={(update)=> {
                            if (update) {
                                this.setState({seasonChoice: update})
                            } else {
                                this.loadContents()
                            }
                        }} />
                </div>
            );
        } else {
            return this.renderSeasons();
        }
    }

    renderSeasons() {
        const { team, divisions, seasons, category, playerMode } = this.props;
        const { gender, statusChoice, categoryChoice } = this.state;

        let items = [];
        let members = [];
        let pending = [];
        let others = [];
        let invited = [];
        let direct = [];
        for (const [, value] of Object.entries(seasons)) {
            let division = divisions[value.divisionId];
            if (division == null) {
                division = {};
            }
            let seachPassed = false;
            if ((gender == null || gender === 'Select All' || gender === division.gender) && 
                (statusChoice == null || statusChoice === 'Select All' || value.status === statusChoice || (statusChoice === STATUS_REGISTER && value.status == null)) && 
                (categoryChoice == null || categoryChoice === 'Select All' || division.category.id === categoryChoice)) {
                seachPassed = true;
            }
            if (!seachPassed) {
                continue;
            }
            const isMember = value.teamIds != null && Object.values(value.teamIds).includes(team.id);
            const isPending = value.pendingIds != null && Object.values(value.pendingIds).includes(team.id);
            const isInvited = value.invitedIds != null && Object.values(value.invitedIds).includes(team.id);
            
            if (isMember) {
                members.push(value);
            }
            if (isPending) {
                pending.push(value);
            }
            if (isInvited) {
                invited.push(value);
            }
            if (!isMember && !isPending && !isInvited) {
                const division = divisions[value.divisionId];
                if (division != null && division.teamIds != null && Object.values(division.teamIds).includes(team.id)) {
                    others.push(value);
                } else {
                    direct.push(value);
                }
            }
        }
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        pending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        invited.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        others.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        direct.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        for (const value of members) {
            items.push(
                this.renderSeasonBadge(value, team)
            );
        }
        if (!playerMode) {
            for (const value of pending) {
                items.push(
                    this.renderSeasonBadge(value, team)
                );
            }
            for (const value of invited) {
                items.push(
                    this.renderSeasonBadge(value, team)
                );
            }
        }
        let directItems = [];
        for (const value of others) {
            directItems.push(
                this.renderSeasonBadge(value, team)
            );
        }
        for (const value of direct) {
            directItems.push(
                this.renderSeasonBadge(value, team)
            );
        }

        if (playerMode) {
            return (
                <div>
                    <Row style={{margin: 0}}>
                        <Col sm={4} xs={12}>
                            <div className='form-edit-row wrap-content-parent'>
                                <div className='form-edit-label wrap-content-wrap-child'>
                                Category
                                </div>
                                <div className='wrap-content-fill-child'>
                                    <select className='form-control' name="text" id="title" onChange={e => {
                                        this.setState({categoryChoice: e.target.value});
                                    }} value={categoryChoice}>
                                        <option value={null}>Select All</option>
                                        {Object.values(category).map((val,idx)=>(
                                        <option key={idx} value={val.id}>{val.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} xs={12}>
                            <div className='form-edit-row wrap-content-parent'>
                                <div className='form-edit-label wrap-content-wrap-child'>
                                {t('gender')}
                                </div>
                                <div className='wrap-content-fill-child'>
                                    <select className='form-control' name="text" id="title" onChange={e => {
                                        this.setState({gender: e.target.value});
                                    }} value={gender}>
                                        <option value={null}>{t('select_all')}</option>
                                        <option value={GENDER_MALE}>{t('male')}</option>
                                        <option value={GENDER_FEMALE}>{t('female')}</option>
                                        <option value={GENDER_COED}>{t('coed')}</option>
                                    </select>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4} xs={12}>
                            <div className='form-edit-row wrap-content-parent'>
                                <div className='form-edit-label wrap-content-wrap-child'>
                                Status
                                </div>
                                <div className='wrap-content-fill-child'>
                                    <select className='form-control' name="text" id="title" onChange={e => {
                                        this.setState({statusChoice: e.target.value});
                                    }} value={statusChoice}>
                                        <option value={null}>{t('select_all')}</option>
                                        <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                        <option value={STATUS_SCHEDULE}>{t('schedule')}</option>
                                        <option value={STATUS_FINISHED}>{t('finished')}</option>
                                        <option value={STATUS_REGISTER}>{t('register')}</option>
                                    </select>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div style={{marginTop: 20, marginBottom: 20}}>
                        <ItemsNavigation
                            title={t('seasons')}
                            totalCount={members.length} />
                        <Row style={{margin: 0}}>
                            {items}
                        </Row>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <Row style={{margin: 0}}>
                    <Col sm={4} xs={12}>
                        <div className='form-edit-row wrap-content-parent'>
                            <div className='form-edit-label wrap-content-wrap-child'>
                            Category
                            </div>
                            <div className='wrap-content-fill-child'>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({categoryChoice: e.target.value});
                                }} value={categoryChoice}>
                                    <option value={null}>Select All</option>
                                    {Object.values(category).map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4} xs={12}>
                        <div className='form-edit-row wrap-content-parent'>
                            <div className='form-edit-label wrap-content-wrap-child'>
                            {t('gender')}
                            </div>
                            <div className='wrap-content-fill-child'>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({gender: e.target.value});
                                }} value={gender}>
                                    <option value={null}>{t('select_all')}</option>
                                    <option value={GENDER_MALE}>{t('male')}</option>
                                    <option value={GENDER_FEMALE}>{t('female')}</option>
                                    <option value={GENDER_COED}>{t('coed')}</option>
                                </select>
                            </div>
                        </div>
                    </Col>
                    <Col sm={4} xs={12}>
                        <div className='form-edit-row wrap-content-parent'>
                            <div className='form-edit-label wrap-content-wrap-child'>
                            Status
                            </div>
                            <div className='wrap-content-fill-child'>
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({statusChoice: e.target.value});
                                }} value={statusChoice}>
                                    <option value={null}>{t('select_all')}</option>
                                    <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                    <option value={STATUS_SCHEDULE}>{t('schedule')}</option>
                                    <option value={STATUS_FINISHED}>{t('finished')}</option>
                                    <option value={STATUS_REGISTER}>{t('register')}</option>
                                </select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <ItemsNavigation
                        title={t('member')}
                        subtitle={t('verified')}
                        totalCount={invited.length + " invited" + pending.length + " pending / " + members.length} />
                    <Row style={{margin: 0}}>
                        {items}
                    </Row>
                </div>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <ItemsNavigation
                        title={t('other')}
                        totalCount={directItems.length} />
                    <Row style={{margin: 0}}>
                        {directItems}
                    </Row>
                </div>
            </div>
        );
    }

    renderSeasonBadge(season, team) {
        const {organizations, divisions, profileData, playerMode } = this.props;
        return (
            <Col xl={4} lg={6} xs={12} key={season.id} className='grid-item-wrapper'>
            <SeasonDivisionBadge 
                key={team.id}
                team={team}
                season={season}
                divisions={divisions}
                organizations={organizations}
                profileData={profileData}
                allowDetail={true}
                onClickDetails={()=> {
                    this.setState({seasonChoice: season, detailOpen: true})
                    if (playerMode) {
                        replaceUrl(`${getTeamDomain(team)}/account/${season.id}`)
                    } else {
                        replaceUrl(`${getTeamDomain(team)}/coach/${season.id}`)
                    }
                }}
                onChanged={()=> {
                    this.loadContents()
                }} />
            </Col>
        );
    }
    
}

function mapState(state) {
    const { alert } = state;
    const { primarySubscription } = state.subscriptions;
    const { loggingIn, user, child } = state.authentication;
    return { alert, loggingIn, user, child, primarySubscription };
}

const connectedApp = connect(mapState)(TeamSeasons);
export { connectedApp as TeamSeasons };
