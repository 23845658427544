import { invokeHttpsApi } from 'utils/API';

export const cartService = {
    checkoutCartItems,
    addToCart,
    removeFromCart,
};

function checkoutCartItems(organizationId, items, note, price, url, sucess, failure) {

    return new Promise((resolve, reject) => {
        let prams = {
            organizationId: organizationId,
            dict: items,
            note: note,
            price: price,
            returnURL: url,
        };
        invokeHttpsApi("payment-createCheckoutSession", prams, (data) => {
            if (data && data.dict && data.dict.session) {
                sucess(data.dict.session);
                resolve(data.dict.session);
            } else {
                failure();
                reject("Internal Error");
            }
        }, (error) => {
            console.log("Query failed", error);
            failure();
            reject(error);
        })
    })
}

function addToCart(item) {
    
    return new Promise((resolve) => {
        resolve(item);
    })
}

function removeFromCart(item) {
    
    return new Promise((resolve) => {
        resolve(item);
    })
}
