import React, { Component } from "react";
import {
    CardImg, Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import { writeDb } from "utils/API";

export class OrganizationTeamRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, team, seasons, onChanged, onClickDetail, forView } = this.props;
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;
        let playerCount = team.playerIds == null ? 0 : Object.values(team.playerIds).length;
        let seasonCount = 0;
        for (const value of Object.values(seasons)) {
            if (value.teamIds != null && Object.values(value.teamIds).includes(team.id)) {
                seasonCount += 1;
            } 
        }
        const isMember = organization.teamIds != null && Object.values(organization.teamIds).includes(team.id);
        const isPending = organization.pendingIds != null && Object.values(organization.pendingIds).includes(team.id);
        const isInvited = organization.invitedIds != null && Object.values(organization.invitedIds).includes(team.id);
        
        if (organization != null) {
            return (
                <Row style={{margin: 0}} onClick={e => {
                    if (onClickDetail != null) {
                        onClickDetail();
                    }
                }}>
                    <Col lg={5} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {team.title}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon-small"
                                src={playersIcon} />
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {playerCount}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {seasonCount}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                {isMember && (
                                    <div className='wrap-content-parent'>
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        if (onClickDetail != null) {
                                            onClickDetail();
                                        }
                                    }}>{t('view')}</Button>
                                    {!forView && (
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        writeDb('/organization/' + organization.id + '/teamIds/' + team.id, null, error => {
                                            if (error) {
                                                console.log(error);
                                            } else if (onChanged != null) {
                                                onChanged();
                                            }
                                        })
                                    }}>{t('remove')}</Button>
                                    )}
                                    </div>
                                )}
                                {isPending && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                            writeDb('/organization/' + organization.id + '/teamIds/' + team.id, team.id, error => {
                                                if (error) {
                                                    console.log(error);
                                                } else {
                                                    writeDb('/organization/' + organization.id + '/pendingIds/' + team.id, null);
                                                    if (onChanged != null) {
                                                        onChanged();
                                                    }
                                                }
                                            })
                                        }}>{t('accept')}</Button>
                                        <Button className="btn-cancel" style={{fontSize: 12}} onClick={e => {
                                            writeDb('/organization/' + organization.id + '/pendingIds/' + team.id, null, error => {
                                                if (error) {
                                                    console.log(error);
                                                } else if (onChanged != null) {
                                                    onChanged();
                                                }
                                            })
                                        }}>{t('reject')}</Button>
                                    </div>
                                )}
                                {isInvited && (
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        writeDb('/organization/' + organization.id + '/invitedIds/' + team.id, null, error => {
                                            if (error) {
                                                console.log(error);
                                            } else if (onChanged != null) {
                                                onChanged();
                                            }
                                        })
                                    }}>{t('cancel_invite')}</Button>
                                )}
                                {!isInvited && !isMember && !isPending && (
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        writeDb('/organization/' + organization.id + '/invitedIds/' + team.id, team.id, error => {
                                            if (error) {
                                                console.log(error);
                                            } else if (onChanged != null) {
                                                onChanged();
                                            }
                                        })
                                    }}>{t('invite')}</Button>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default OrganizationTeamRow;