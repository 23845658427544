export const mainTabStyle = {
	margin: '5px 10px',
	boxShadow: '0 0 5px rgba(0,0,0,0.25)',
	border: '1px solid divider',
	backgroundColor: 'white',
}

export const smallTabBoxStyle = {
	margin: '5px 10px',
	boxShadow: '0 0 5px rgba(0,0,0,0.25)',
	border: '1px solid divider',
	backgroundColor: 'white',
	minHeight: '48px',
	height: '48px',
}

export const smallTabStyle = {
	minHeight: '48px',
	height: '48px',
}

export const smallTabItemStyle = {
	minHeight: '48px',
	height: '48px',
}

export const mainRedColor = '#E24646';
export const mainBlueColor = '#3D5C97';
export const mainGreenColor = '#409C40';
export const lightGreenColor = '#34BB87';
export const heavyYellowColor = '#EEB825';