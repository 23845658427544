import React, { Component } from "react";
import BluePostHeader from 'components/detail/community/BluePostHeader';
import BlueHighlightHeader from 'components/detail/community/BlueHighlightHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import Pagination from 'react-responsive-pagination';
import {
    Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { t } from 'i18next';
import { getOrgDomain } from "utils/Utils";


export class BluePosts extends Component {
    constructor(props) {
        super(props);
        
        let locationsChoice = {};
        if (props.locations != null) {
            for (const [key, value] of Object.entries(props.locations)) {
                if (value.organizationId === props.organization.id) {
                    locationsChoice[key] = value;
                }
            }
        }
        this.state = {
            detailChoice: {},
            locationChoice: {},
            locationsChoice: locationsChoice,
            editTeamData: false,
            editLocation: false,
            progress: false,
            detailOpen: false,
            editOpen: false,
            imageProgress: 0,
            page: 1,
            windowWidth: window.innerWidth,
        };
    }

    componentDidUpdate(prevProps) {
        const {locations, organization, seasons} = this.props;
        if (seasons !== prevProps.seasons) {
            let locationsChoice = {};
            if (locations != null) {
                for (const [key, value] of Object.entries(locations)) {
                    if (value.organizationId === organization.id) {
                        locationsChoice[key] = value;
                    }
                }
            }
            this.setState({
                locationsChoice: locationsChoice,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }

    renderHighlights() {
        const { organization, posts, users, user, loading } = this.props;
        const { searchStr, page} = this.state;
        const homeLink = getOrgDomain(organization);
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((posts ? Object.values(posts).length : 0) / 3);
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id && value.gameId) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        let idx = (page - 1) * 3;
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        
        for (const value of toShow) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: '0 10px'}}>
                    <BlueHighlightHeader 
                        key={value.id}
                        organization={organization}
                        post={value}
                        users={users}
                        user={user}
                        onClickDetail={() => {
                            this.props.router.navigate(homeLink + '/post/' + value.id);
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px"}}>
                <h1 className="blue-section-title">Highlights</h1>
                <div className="container">
                    {list.length === 0 ? (
                        <div style={{height: loading ? 150 : 450, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {loading ? "Loading BluePosts" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px"}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={page}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
            </div>
        );
    }
    
    renderPosts() {
        const { organization, posts, users, user, loading } = this.props;
        const { searchStr, page} = this.state;
        const homeLink = getOrgDomain(organization);
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((posts ? Object.values(posts).length : 0) / 3);
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id && !value.gameId) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        let idx = (page - 1) * 3;
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        
        for (const value of toShow) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: '0 10px'}}>
                    <BluePostHeader 
                        key={value.id}
                        organization={organization}
                        post={value}
                        users={users}
                        user={user}
                        onClickDetail={() => {
                            this.props.router.navigate(homeLink + '/post/' + value.id);
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px"}}>
                <h1 className="blue-section-title">News</h1>
                <div className="container">
                    {list.length === 0 ? (
                        <div style={{height: loading ? 150 : 450, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {loading ? "Loading BluePosts" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px"}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={page}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
            </div>
        );
    }
    
    render() {
        const {organization, users, teams, user} = this.props;
        const {detailChoice} = this.state;
        
        return (
            <div style={{margin: 0}}>
            {this.renderHighlights()}
            {this.renderPosts()}
            {detailChoice.id && (
                <Modal isOpen={true} style={{marginTop: 70}}>
                    <ModalBody>
                        <div className="post-detail-modal">
                            <PostDetailView 
                                organization={organization}
                                post={detailChoice} 
                                user={user}
                                users={users}
                                teams={teams}
                                isOpen={true}
                                onCloseClick={() => {
                                    this.setState({detailChoice: {}})
                                }}
                                onLikeUpdate={(like, uid) => {
                                    if (uid) {
                                        if (!detailChoice.likeIds) {
                                            detailChoice.likeIds = {};
                                        }
                                        if (like) {
                                            detailChoice.likeIds[uid] = uid;
                                        } else {
                                            delete detailChoice.like[uid];
                                        }
                                        this.setState({detailChoice: detailChoice})
                                    }
                                }}
                                onCommentChange={(update) => {
                                    if (update) {
                                        if (!detailChoice.comments) {
                                            detailChoice.comments = {};
                                        }
                                        detailChoice.comments[update.id] = update;

                                        this.setState({detailChoice: detailChoice})
                                    }
                                }} />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            )}
            </div>
        );
    }
}
export default BluePosts;