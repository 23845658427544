import React, { Component } from "react";
import AttachFileView from 'components/detail/AttachFileView';
import {
    FormGroup, Label, FormText, Input, Progress
} from 'reactstrap';

export class AttachFileEdit extends Component {
    render() {
        const {attachFile, allowProgress, label, progress, onChange} = this.props;
        
        return (
            <FormGroup>
                {attachFile.fileUri != null && attachFile.fileUri !== '' && (
                    <AttachFileView attachFile={attachFile} />
                )}
                <Label for="videoFile">{label != null ? label : 'Attach File'}</Label>
                <Input type="file" name="file" id="videoFile" onChange={e => {                            
                    if (onChange != null && e.target.files != null && e.target.files.length > 0) {
                        onChange(e.target.files[0]);
                    }
                }} />
                <FormText color="muted">
                You can {attachFile.fileUri != null && attachFile.fileUri !== '' ? 'update the current' : 'upload a new'} attach file
                </FormText>
                {allowProgress && (
                    <Progress className='progress-style-file' color="warning" value={progress} />
                )}
            </FormGroup>
        );
    }
}

export default AttachFileEdit;