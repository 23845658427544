import React, { Component } from 'react';
import { 
    Button,
    Modal, 
    ModalBody,
} from 'reactstrap';
import ClubLessonHeader from 'components/detail/club/ClubLessonHeader';
import ClubLessonDetailsView from 'components/detail/club/ClubLessonDetailsView';
import ClubLessonEditView from 'components/edit/club/ClubLessonEditView';
import { listenDb, listenDbQuery } from 'utils/API';

export default class AdminLessons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            categorys: {},
            clubLessons: {},
            clubLessonChoice: {},
            editOpen: false,
            detailOpen: false
        };
    }

    componentDidMount() {
        listenDb("category", data => {
                var categorys = {};

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        categorys[key] = value;
                    }
                }

                this.setState({
                    categorys: categorys,
                });
            });
        listenDbQuery("clubLesson", "owner", "free", data => {
                var clubLessons = {};
                
                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        if (value.owner === "free") {
                            clubLessons[key] = value;
                        }
                    }
                }

                this.setState({
                    clubLessons: clubLessons,
                });
            });        
    }

    renderLessons() {
        const { clubLessons } = this.state;

        const result = [];

        for (const[, value] of Object.entries(clubLessons)) {

            result.push(
                <ClubLessonHeader 
                    clubLesson={value}
                    onClickDetails={() => {
                        this.setState({
                            clubLessonChoice: value,
                            detailOpen: true
                        })
                    }}
                    onClickEdit={() => {
                        this.setState({
                            clubLessonChoice: value,
                            editOpen: true
                        })
                    }} />
            )
        }

        return result;
    }

    render() {
        const {categorys, detailOpen, editOpen, clubLessonChoice} = this.state;

        return (
            <div className='wrap-content-parent' style={{height: '100vh'}}>
                <div className='wrap-content-fill-child main-page'>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                        Club Lesson Free Contents
                        </h3>
                        
                        <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            this.setState({clubLessonChoice: {}, editOpen: true});
                        }}>+</Button>
                    </div>
                    {this.renderLessons()}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubLessonEditView 
                            categorys={categorys} 
                            clubLesson={clubLessonChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <ClubLessonDetailsView 
                            categorys={categorys} 
                            clubLesson={clubLessonChoice} 
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}