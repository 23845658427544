import React, { Component } from 'react';
import { CardImg, Button, Modal, ModalBody } from 'reactstrap';
import VideoBadge from 'components/detail/VideoBadge';
import VideoDetailView from 'components/detail/VideoDetailView';
import VideoEditView from 'components/detail/VideoEditView';

import homeIcon from 'assets/img/icons/home_icon.png';
import { listenDb } from 'utils/API';

export default class AdminVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: {},
            videoChoice: {},
            editOpen: false,
            detailOpen: false
        };
    }

    componentDidMount() {
        listenDb("videos", data => {
                const videos = {};

                if (data != null) {
                    for (const [key, value] of Object.entries(data)) {
                        videos[key] = value;
                    }
    
                }
                
                this.setState({
                    videos: videos,
                });
            });        
    }

    render() {
        const {videos, videoChoice, editOpen, detailOpen} = this.state;

        return (
            <div className='wrap-content-parent' style={{height: '100vh'}}>
                <div className='wrap-content-fill-child main-page'>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <div className='wrap-content-fill-child'>
                            <div className='wrap-content-parent'>
                                <CardImg
                                    className="profile-thumb"
                                    src={homeIcon}
                                    style={{ width: 30, height: 30, marginRight: 10 }}
                                    />
                                <h3 className='label label-default title-action-main wrap-content-fill-child'>
                                Upload Videos
                                </h3>
                            </div>
                        </div>
                        <Button onClick={e=>{
                            this.setState({
                                videoChoice: {},
                                editOpen: true
                            })
                        }}>+</Button>
                    </div>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <h4 className='wrap-content-wrap-child'>Tip:</h4>
                        <div className='wrap-content-fill-child'>
                        On this page you will upload all the videos that will be used in playlists, events and promotional video of events. All video files
                        must be MP4 format in order to workout on the platform.
                        </div>
                    </div>
                    <div>
                        {Object.values(videos).map((video) => (
                            <VideoBadge 
                                video={video}
                                onClickDetails={() => {
                                    this.setState({
                                        videoChoice: video,
                                        detailOpen: true
                                    })
                                }}
                                onClickEdit={() => {
                                    this.setState({
                                        videoChoice: video,
                                        editOpen: true
                                    })
                                }} />
                        ))}
                    </div>
                </div>
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <VideoDetailView
                            video={videoChoice} 
                            isOpen={detailOpen}
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <VideoEditView 
                            video={videoChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}