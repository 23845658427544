import React, { Component } from "react";
import ReactPlayer from 'react-player';
import {
    Card, CardBody, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class VideoDetailView extends Component {
    render() {
        const {onCloseClick, video} = this.props;
        
        if (video != null) {
            return (
                <div>
                    <Card>
                        <CardBody>
                            <h4 className='label label-default'>
                            {video.title}
                            </h4>
                            <div>{video.type}</div>
                        </CardBody>
                    </Card>
                    {video.videoUri != null && video.videoUri !== '' && (
                    <div>
                        <ReactPlayer 
                            className="default-player"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={video.videoUri}
                            width={'80%'}
                            height={'auto'} />

                    </div>
                    )}
                    <Button className='modal-button' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(VideoDetailView);