import React, { Component } from "react";
import {
    CardImg, Button
} from 'reactstrap';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import CommentHeader from 'components/detail/community/CommentHeader';
import ReactPlayer from 'react-player';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import * as YoutubePlayer from 'react-player/youtube';

import { toDateTimeString, renderHtmlString } from 'utils/Utils';

import {
    MdAccessTime,
} from 'react-icons/md';

import {
    GiHearts,
} from 'react-icons/gi';

import {
    FaComments,
} from 'react-icons/fa';
import { invokeHttpsApi } from "utils/API";
import CustomRichTextEditor from "components/template/CustomRichTextEditor";

export class PostDetailView extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            detailOpen: false,
            saveProgress: false,
            activeIndex: 0,
            animating: false,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
    }

    next() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === this.getGallerySlides().length - 1 ? 0 : activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === 0 ? this.getGallerySlides().length - 1 : activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(nextIndex) {
        const {animating} = this.state;
        if (animating) {
            return;
        }
        this.setState({activeIndex: nextIndex});
    }

    getGallerySlides() {
        const {post} = this.props;
        const gallery = post.galleryItem ? Object.values(post.galleryItem) : [];

        gallery.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        let result = [];
        for (const item of gallery) {
            result.push(
                {
                    src: item.imageUrl,
                    title: item.title,
                    caption: item.text,
                    id: item.id
                }
            )
        }
        
        return result;
    }

    renderGallery() {
        const {activeIndex} = this.state;
        const slides = this.getGallerySlides();
        
        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}>
                <CarouselIndicators items={this.getGallerySlides()} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides.map((item) => (
                    <CarouselItem
                        onExiting={() => this.setState({animating: true})}
                        onExited={() => this.setState({animating: false})}
                        key={item.src} >
                        <img src={item.src} alt={item.altText} className='post-gallery-nav-slide-img' />
                        <div className="post-gallery-cover">
                            <div className="post-gallery-wrapper">
                                {item.title && (
                                    <h1 className="post-gallery-header-title">
                                        {item.title}
                                        <span></span>
                                    </h1>
                                )}
                                {item.caption && (
                                    <h3 className="post-gallery-header-sub-title">
                                        {item.caption}
                                        <span></span>
                                    </h3>
                                )}
                            </div>
                        </div>
                    </CarouselItem>
                ))}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
        )
    }

    renderComments() {
        const {users, user, post } = this.props;
        
        const comments = post.comments ? post.comments : {};
        
        const uid = user ? user.uid : "";
        const result = [];
        const list = [];

        for (const[, value] of Object.entries(comments)) {
            if (value.postId === post.id) {
                result.push(value);
            }    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })
        
        for (const value of result) {
            list.push(
                <CommentHeader 
                    key={value.id}
                    comment={value}
                    users={users}
                    user={user}
                    onClickEdit={uid === value.uid ? ()=>{
                        this.setState({
                            detailChoice: value,
                            detailOpen: false,
                            editOpen: true
                        })
                    } : null}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: value,
                            detailOpen: true
                        })
                    }} />
            );
        }

        return (
            <div style={{marginBottom: 20}}>

                <div>
                    {list}
                </div>
            </div>
        );
    }
    
    saveComment(comment) {
        const {user, post, onCommentChange} = this.props;

        comment.uid = user.uid;
        comment.postId = post.id;

        this.setState({progress: true})
        invokeHttpsApi('community-submitPostComment', {comment: comment}, (data) => {

            if (data.success && data.result) {
                if (onCommentChange != null) {
                    onCommentChange(data.result);
                }
            }
            this.setState({progress: false})
        }, (error) => {
            console.log('save error', error);
            this.setState({progress: false})
        });
    }

    savePostLike() {
        const {user, post, onLikeUpdate} = this.props;
        let like = post && post.likeIds && Object.values(post.likeIds).includes(user.uid);

        this.setState({progress: true})
        invokeHttpsApi('community-likePost', {
            postId: post.id,
            like: !like
        }, (data) => {
            if (data.success && data.like) {
                if (onLikeUpdate != null) {
                    onLikeUpdate(data.like, user.uid);
                }
            }
            this.setState({progress: false})
        }, (error) => {
            console.log('save error', error);
            this.setState({progress: false})
        });
    }

    render() {
        const { users, user, post, onCloseClick } = this.props;
        const {commentChoice} = this.state;
        const creator = users[post.uid] != null ? users[post.uid] : {};
        
        const icon = creator.photoUri != null ? creator.photoUri : defaultImage;
        const uid = user != null ? user.uid : null;
        const likeIds = post.likeIds != null ? Object.values(post.likeIds) : [];
        const comments = post.comments != null ? Object.values(post.comments) : [];
        
        if (post != null) {
            return (
                <div className="post-detail-wrapper">
                    <div className="post-detail-header">
                        <div className="container">
                            <div style={{marginTop: 10}}/>
                            <div className='post-item-wrapper'>
                                <img className='post-item-icon' src={icon} alt='icon' />
                                <div className='post-item-info'>
                                    <h4 className='post-item-username'>
                                    {creator.username}
                                    </h4>
                                    <div className='post-item-date'>
                                    <MdAccessTime/> {toDateTimeString(post.timestamp)}
                                    </div>
                                </div>
                            </div>
                            <div style={{marginTop: 10}}/>
                            <h2 className="post-detail-title">
                            {post.title}
                            </h2>
                            <div className='post-item-footer'>
                                <div className='post-item-footer-section' onClick={e => {
                                    this.savePostLike();
                                }}>
                                    <span style={likeIds.includes(uid) ? {color: 'red'} : {}}><GiHearts/></span> {likeIds.length}
                                </div>
                                <div className='post-item-footer-section' onClick={e => {
                                    this.setState({commentChoice: {}})
                                }}>
                                    <FaComments/> {comments.length}
                                </div>
                                <div className='post-item-footer-section'>
                                </div>
                                <div className='post-item-footer-section'>
                                </div>
                                <div className='post-item-footer-section'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id='detail-header' className="container post-content-wrapper">
                        {post.type === 'photo' && post.photoItem != null && post.photoItem.url != null && post.photoItem.url !== '' && (
                        <div className="post-media-wrapper">
                        <div className="post-media-child">
                            <CardImg 
                                className='task-image'
                                src={post.photoItem.url} />
                        </div>
                        </div>
                        )}
                        {post.type === 'video' && post.videoItem != null && post.videoItem.url != null && post.videoItem.url !== '' && (
                        <div className="post-media-wrapper">
                        <div className="post-media-child">
                            <ReactPlayer 
                                className='post-video-player'
                                width="100%"
                                height="100%"
                                playing 
                                stopOnUnmount 
                                controls 
                                url={post.videoItem.url} />
                        </div>
                        </div>
                        )}
                        {post.type === 'youtube' && post.youtube != null && post.youtube !== '' && (
                        <div className="post-media-wrapper">
                        <div className="post-media-child">
                            <YoutubePlayer 
                                width="100%"
                                height="100%"
                                playing 
                                stopOnUnmount 
                                controls 
                                url={post.youtube} />
                        </div>
                        </div>
                        )}
                        <div style={{marginTop: 20}}/>
                        <div className='app'>
                            {renderHtmlString(post.body ? post.body : "")}
                        </div>
                        {this.getGallerySlides().length > 0 && (
                        <div className="app">
                            {this.renderGallery()}
                        </div>
                        )}
                        <div className='app'>
                            {this.renderComments()}
                        </div>
                        <div style={{height: 50}}/>
                    </div>
                    {user && user.uid && commentChoice ? (
                        <div className="post-detail-input">
                            <CustomRichTextEditor
                                className='post-comment-editor'
                                placeholderText='Add Comment ...'
                                value={commentChoice.body}
                                onChange={(update)=> {
                                    commentChoice.body = update
                                    this.setState({commentChoice: commentChoice})
                                }} />
                            
                            <div className="wrap-content-parent">
                                <div className="wrap-content-fill-child"/>
                                <Button className='btn-submit post-comment-btn wrap-content-wrap-child' onClick={e => {
                                    this.saveComment(commentChoice)
                                }}>Comment</Button>
                                <Button className='btn-submit post-comment-btn wrap-content-wrap-child' onClick={e => {
                                    this.setState({commentChoice: null})
                                }}>{t('close')}</Button>
                            </div>
                        </div>
                    ) : (
                        <div className="post-detail-input" style={{paddingTop: 5}}>
                            <div className="wrap-content-parent">
                                <div className="wrap-content-fill-child"/>
                                <Button className='btn-submit post-comment-btn wrap-content-wrap-child' onClick={e => {
                                    if (onCloseClick) {
                                        onCloseClick();
                                    }
                                }}>{t('close')}</Button>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(PostDetailView);