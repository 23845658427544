import React, { Component } from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, Row, Col, Spinner } from 'reactstrap';
import { FREE_ORG_SUBSCRIPTION_PRODUCT_ID, LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID, LEAGUE_SUBSCRIPTION_PRODUCT_ID, LOCATION_SUBSCRIPTION_PRODUCT_ID, getLogo, getMembershipPlan, sortByTitle, toDateString, validateEmail } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { TableRowSmall } from 'components/detail/header/TableRowSmall';
import CustomDatePicker from 'components/template/CustomDatePicker';
import { Switch } from '@mui/material';

export default class AdminOrganizers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            organizations: {},
            invites: {},
            users: {},
            teams: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf()
        };
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        invokeHttpsApi('inviteQuery-inviteDashboardQuery', {}, (data) => {
            const organizations = {};
            const organizerSubscriptions = {};
            const locationSubscription = {};
            const leagueSubscription = {};
            const locationAndLeagueSubscription = {};
            const users = {};
            const teams = {};
            const invites = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizerSubscriptions)) {
                    organizerSubscriptions[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationSubscription)) {
                    locationSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.leagueSubscription)) {
                    leagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueSubscription)) {
                    locationAndLeagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                if (data.invites != null && data.invites.organizer != null) {
                    for (const [key, value] of Object.entries(data.invites.organizer)) {
                        invites[key] = value;
                    }
                }
            }

            this.setState({
                organizations: organizations,
                organizerSubscriptions: organizerSubscriptions,
                locationSubscription: locationSubscription,
                leagueSubscription: leagueSubscription,
                locationAndLeagueSubscription: locationAndLeagueSubscription,
                teams: teams,
                invites: invites,
                users: users,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    checkEmail(email) {
        const {invites, organizations, searchStr, users} = this.state;

        if (!validateEmail(email)) {
            return -1;
        }

        for (const val of Object.values(invites)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                if (val.status === 'invited') {
                    return 0;
                } else if (val.status === 'registered') {
                    return 1;
                }
            }
        }
        for (const val of Object.values(organizations)) {
            const user = users[val.owner] ? users[val.owner] : {};
            if (!searchStr || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                return 2;
            }
        }
        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return 3;
            }
        }
        return 4;
    }

    getUid(email) {
        const {users} = this.state;

        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return val.uid;
            }
        }
        return null;
    }

    sendInvite(email) {
        var status = this.checkEmail(email)

        if (status === -1) {
            alert("This email address is invalid.")
            return;
        }
        
        if (status === 2) {
            alert("This email is already registered.")
            return;
        }
        
        if (status === 1) {
            alert("This email is already invited for creating organization.")
            return;
        }
        
        const data = {email: email};
        if (status === 3) {
            data.uid = this.getUid(email);
        }

        invokeHttpsApi('invite-inviteOrganizer', data, (data) => {
            this.loadContents();
            if (status === 3) {
                alert("Invite to create an organization email is sent. It will be sent every 2 days");
            } else {
                alert("Invite email is sent. It will be sent every 2 days");
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    getInvite(item) {
        return null;
    }

    getSubscriptionStatus(item) {
        const { organizerSubscriptions } = this.state;
        const data = organizerSubscriptions[item.id].subscriptions;
        let main = null;
        let free = null;
        for (const v of Object.values(data)) {
            if (v.productId === FREE_ORG_SUBSCRIPTION_PRODUCT_ID) {
                free = v;
            } else {
                if (v.productId === LEAGUE_SUBSCRIPTION_PRODUCT_ID || 
                    v.productId === LOCATION_SUBSCRIPTION_PRODUCT_ID|| 
                    v.productId === LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID) {
                        main = v;
                }
            }
        }
        return {
            main: main,
            free: free
        }
    }

    getSubscriptionDate(item) {
        const { locationSubscription, leagueSubscription, locationAndLeagueSubscription } = this.state;
        const status = this.getSubscriptionStatus(item);

        let title = t('no_membership')
        let dateStr = ""
        const membership = getMembershipPlan(status.main, [
            locationSubscription,
            leagueSubscription,
            locationAndLeagueSubscription
        ]);
        
        if (membership && status.main && status.main.status === 'trialing') {
            title = membership.name;
            dateStr = (
                <div style={{display: 'inline'}}>
                    <b style={{marginRight: 10}}>{t('trial_until')}</b>
                    <b style={{color:'#0075FF'}}>
                        {toDateString(status.main.trial_end._seconds * 1000)}
                    </b>
                </div>
            )
        } else if (membership && status.main && status.main.status === 'active') {
            title = membership.name;
            dateStr = (
                <div style={{display: 'inline'}}>
                    <b style={{marginRight: 10}}>{t('renew_within')}</b>
                    <b style={{color:'#5075FF'}}>
                        {toDateString(status.main.trial_end._seconds * 1000)}
                    </b>
                </div>
            )
        } else if (status.free && status.free.current_period_end) {
            title = t('free_trial');
            const ended = status.free.current_period_end < new Date().valueOf();
            dateStr = (
                <div style={{display: 'inline'}}>
                    <b style={{marginRight: 10}}>{t(ended ? 'expired' : 'ends_in')}</b>
                    <b style={{color: ended ? '#D64C4D' : '#38be55'}}>
                        {toDateString(status.free.current_period_end)}
                    </b>
                </div>
            )
        }

        return {
            title: title,
            dateStr: dateStr
        }
    }

    renderTable() {
        const { organizations, users, loading } = this.state;

        let items = [];
        let mainPlan = [];
        let freePlan = [];
        let expiredPlan = [];
        let nonPlan = [];

        for (const item of Object.values(organizations)) {
            const status = this.getSubscriptionStatus(item);
            if (status.main) {
                mainPlan.push(item);
            } else if (status.free && status.free.current_period_end < new Date().valueOf()) {
                freePlan.push(item);
            } else if (status.free && status.free.current_period_end > new Date().valueOf()) {
                expiredPlan.push(item);
            } else {
                nonPlan.push(item);
            }
        }

        sortByTitle(mainPlan)
        sortByTitle(freePlan)
        sortByTitle(expiredPlan)
        sortByTitle(nonPlan)

        let totalItems = [];
        totalItems = totalItems.concat(mainPlan);
        totalItems = totalItems.concat(expiredPlan);
        totalItems = totalItems.concat(freePlan);
        totalItems = totalItems.concat(nonPlan);

        for (const item of totalItems) {
            const user = users[item.owner];
            const subDate = this.getSubscriptionDate(item);

            items.push(
                <TableRowSmall 
                    key={item.id}
                    title={item.title}
                    icon={item.thumbUri ? item.thumbUri : getLogo()}
                    subView={
                        <div className='wrap-content-parent'>
                            <h4 className='wrap-content-wrap-child' style={{fontSize: 15}}>
                            {user ? user.email : "No Email"}
                            </h4>
                            <div className='wrap-content-fill-child' style={{fontSize: 15}}>
                            <b>{subDate.title}:</b> {subDate.dateStr}
                            {item.hideFromLanding && (<span style={{color: "blue", marginLeft: 10}}>Hiding</span>)}
                            {item.featured && (<span style={{color: "green", marginLeft: 10}}>Featured</span>)}
                            </div>
                        </div>
                    }
                    onClickDetails={() => {
                        this.setState({orgChoice: item})
                    }} />
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Organization
                            </h3>
                        </Col>
                        <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Status
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {!loading && items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {loading ? <Spinner/> : t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    updateFreeDate() {
        const { orgChoice, freeDateVal } = this.state;
        if (orgChoice && freeDateVal && freeDateVal > 0) {
            this.setState({progress: true});
            invokeHttpsApi("admin-changOrganizationFreeDate", {
                userId: orgChoice.owner,
                update: freeDateVal
            }, (data) => {
                if (data && data.success) {
                    alert(t('request_success'));
                } else {
                    alert(t('request_failed'));
                }
                this.setState({progress: false});
                this.loadContents();
            }, (error) => {
                alert(t('request_failed\n' + error));
                this.setState({progress: false});
            })
        }

    }

    saveUpdate() {
        const { orgChoice, organizations } = this.state;
        if (orgChoice) {
            this.setState({progress: true});
            invokeHttpsApi("admin-submitOrganizationUpdate", {
                organization: orgChoice
            }, (data) => {
                if (data && data.success) {
                    alert(t('request_success'));
                } else {
                    alert(t('request_failed'));
                }
                organizations[orgChoice.id] = orgChoice;
                this.setState({progress: false, organizations: organizations});
            }, (error) => {
                alert(t('request_failed\n' + error));
                this.setState({progress: false});
            })
        }
    }

    deleteOrganization() {
        const { orgChoice } = this.state;
        if (orgChoice && window.confirm("Are you sure you want to delete this organiation? All data related to it will be lost.")) {
            this.setState({progress: true});
            invokeHttpsApi("admin-deleteOrganization", {
                targetId: orgChoice.id,
            }, (data) => {
                if (data && data.success) {
                    alert(t('request_success'));
                } else {
                    alert(t('request_failed'));
                }
                this.setState({progress: false});
                this.loadContents();
            }, (error) => {
                alert(t('request_failed\n' + error));
                this.setState({progress: false});
            })
        }

    }

    renderDetail() {
        const { orgChoice, freeDateVal, progress } = this.state;

        const status = this.getSubscriptionStatus(orgChoice);
        const subDate = this.getSubscriptionDate(orgChoice);
        const freeDate = freeDateVal ? freeDateVal : (
            status.free ? status.free.current_period_end : 0
        )
        
        if (orgChoice) {
            return (
                <div className='shadow-tile-fixed'>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Title
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    orgChoice.title = e.target.value;
                                    this.setState({orgChoice: orgChoice});
                                }} value={orgChoice.title} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Current Status
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <div className='wrap-content-fill-child' style={{fontSize: 15}}>
                                <b>{subDate.title}:</b> {subDate.dateStr}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Free Membership End Date
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <CustomDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Date picker dialog"
                                    format="MM/DD/YYYY"
                                    value={freeDate}
                                    onChange={ date => {
                                        this.setState({freeDateVal: date.valueOf()});
                                    }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Featured
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Switch
                                    checked={orgChoice.featured}
                                    onChange={e=>{
                                        orgChoice.featured = e.target.checked;
                                        this.setState({orgChoice: orgChoice});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Hide From Landing
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Switch
                                    checked={orgChoice.hideFromLanding}
                                    onChange={e=>{
                                        orgChoice.hideFromLanding = e.target.checked;
                                        this.setState({orgChoice: orgChoice});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </div>
                    </div>
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div className='form-edit-row row'>
                            <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} >
                                <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                    this.updateFreeDate();
                                }}>{t('update')}</Button>
                                <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                    this.saveUpdate();
                                }}>{t('save')}</Button>
                            </div>
                            <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                                <Button className='btn-edit' style={{minWidth: 120, marginRight: 10}} onClick={e => {
                                    this.deleteOrganization();
                                }}>{t('delete')}</Button>
                                <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                    this.setState({orgChoice: null, freeDateVal: null})
                                }}>{t('close')}</Button>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }

    render() {
        const {invites, organizations, orgChoice, editOpen, searchStr, users} = this.state;

        const invited = [];
        const pending = [];
        const orgs = [];

        for (const val of Object.values(invites)) {
            if (!searchStr || (val.email && val.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                if (val.status === 'invited') {
                    invited.push(val);
                } else if (val.status === 'registered') {
                    pending.push(val);
                }
            }
        }
        for (const val of Object.values(organizations)) {
            const user = users[val.owner] ? users[val.owner] : {};
            if (!searchStr || (user.email && user.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                orgs.push(val);
            }
        }
        orgs.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        return (
            <div>
                <div style={{marginTop: 10}} className='wrap-content-fill-child'>
                    
                    {orgChoice ? this.renderDetail() : this.renderTable()}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}