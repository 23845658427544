import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Button, Form, Spinner, FormGroup, Label, Input,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { dataActions } from 'redux/_actions';
import CustomRichTextEditor from "components/template/CustomRichTextEditor";

class PolicyEdit extends Component {
    constructor(props) {
        super(props);
        const policy = {};
        if (props.policy != null) {
            for (const [key, value] of Object.entries(props.policy)) {
                policy[key] = value;
            }
        }
        
        this.state = {
            policy: policy,
            progress: false,
            pickCategory: false,
            newImage: {},
            newVideo: {},
            addFiles: [],
            placeChoice: {},
            imageProgress: 0,
            videoProgress: 0,
            body: null
        }
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, policy, progress, onSaveDone} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.policy !== policy)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(policy)) {
                challengeVal[key] = value;
            }
            this.setState({
                policy: challengeVal
            })
        }
        if ((prevProps.progress !== progress && progress === false)) {
            if (onSaveDone != null) {
                onSaveDone();
            }
        }
    }

    
    render() {
        const {progress, onCloseClick, onClickSave} = this.props;
        const {policy} = this.state;
        
        if (policy != null) {
            return (
                <Form className="shadow-tile-white">
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            policy.title = e.target.value;
                            this.setState({policy: policy});
                        }} value={policy.title} />
                    </FormGroup>
                    
                    <CustomRichTextEditor
                        placeholderText='Edit Your Content Here!'
                        value={policy.body}
                        onChange={(update)=> {
                            policy.body = update
                            this.setState({policy: policy})
                        }} />
                    
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='btn-edit' onClick={e => {
                                if (onClickSave) {
                                    onClickSave(policy);
                                }
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(PolicyEdit));
export { connectedApp as PolicyEdit };
