import { SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT, STATUS_FINISHED } from "utils/constants";
import { DAY_TIME, HOUR_TIME } from "utils/Utils";

export const getPlayerStatisticsList = (seasons, divisionId, organizationId , teamId, divisions)=> {

    let result = {};
    for (const [, value] of Object.entries(seasons)) {
        let division = divisions ? divisions[value.divisionId] : null;
        if (value.subSeasons && ((!organizationId && !divisionId) || value.organizationId === organizationId || value.divisionId === divisionId)) {
            for (const [, val] of Object.entries(value.subSeasons)) {
                if (val.games != null)  {
                    for (const game of Object.values(val.games)) {
                        if (game.scores != null)  {
                            for (const item of Object.values(game.scores)) {
                                if (teamId == null || item.teamId === teamId) {
                                    if (!Object.keys(result).includes(item.uid)) {
                                        result[item.uid] = {
                                            uid: item.uid,
                                            score: 0,
                                            assist: 0,
                                            foul: 0,
                                            yellow: 0,
                                            red: 0,
                                        }
                                    }
                                    if (item.assist != null && !Object.keys(result).includes(item.assist)) {
                                        result[item.assist] = {
                                            uid: item.assist,
                                            score: 0,
                                            assist: 0,
                                            foul: 0,
                                            yellow: 0,
                                            red: 0,
                                        }
                                    }
                                    let number = item.number;
                                    if (!number && division && division.category && division.category.scoreData) {
                                        for (const score of Object.values(division.category.scoreData)) {
                                            if (score.number) {
                                                number = score.number;
                                            }
                                        }
                                    }
                                    if (!number || number === -1) {
                                        number = 1;
                                    }
                                    result[item.uid].score += number;
                                    if (item.assist != null) {
                                        result[item.assist].assist += number;
                                    }
                                }
                            }
                        }
                        if (game.fouls != null)  {
                            for (const item of Object.values(game.fouls)) {
                                if (teamId == null || item.teamId === teamId) {
                                    if (!Object.keys(result).includes(item.uid)) {
                                        result[item.uid] = {
                                            uid: item.uid,
                                            score: 0,
                                            assist: 0,
                                            foul: 0,
                                            yellow: 0,
                                            red: 0,
                                        }
                                    }
                                    result[item.uid].foul += 1;
                                    if (item.level === 'red') {
                                        result[item.uid].red += 1;
                                    }
                                    if (item.level === 'yellow') {
                                        result[item.uid].yellow += 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return result;
}
export const getTeamPlayerStatisticsList = (season, teamId)=> {

    let result = {};
    let gameCount = {};
    if (season && season.subSeasons) {
        for (const [, val] of Object.entries(season.subSeasons)) {
            if (val.games != null)  {
                for (const game of Object.values(val.games)) {
                    if (game.scores != null)  {
                        for (const item of Object.values(game.scores)) {
                            if (!teamId || item.teamId === teamId) {
                                if (!gameCount[item.uid]) {
                                    gameCount[item.uid] = [];
                                }
                                if (!gameCount[item.uid].includes(game.id)) {
                                    gameCount[item.uid].push(game.id);
                                }
                                if (!Object.keys(result).includes(item.uid)) {
                                    result[item.uid] = {
                                        uid: item.uid,
                                        score: 0,
                                        assist: 0,
                                        foul: 0,
                                        yellow: 0,
                                        red: 0,
                                        mom: 0,
                                    }
                                }
                                if (item.assist != null && !Object.keys(result).includes(item.assist)) {
                                    result[item.assist] = {
                                        uid: item.assist,
                                        score: 0,
                                        assist: 0,
                                        foul: 0,
                                        yellow: 0,
                                        red: 0,
                                        mom: 0,
                                    }
                                }
                                result[item.uid].score += item.number;
                                if (item.assist != null) {
                                    result[item.assist].assist += item.number;
                                }
                            }
                        }
                    }
                    if (game.mom != null)  {
                        for (const [tId, uid] of Object.entries(game.mom)) {
                            if (!teamId || teamId === tId) {
                                if (!gameCount[uid]) {
                                    gameCount[uid] = [];
                                }
                                if (!gameCount[uid].includes(game.id)) {
                                    gameCount[uid].push(game.id);
                                }
                                if (!Object.keys(result).includes(uid)) {
                                    result[uid] = {
                                        uid: uid,
                                        score: 0,
                                        assist: 0,
                                        foul: 0,
                                        yellow: 0,
                                        red: 0,
                                        mom: 0,
                                    }
                                }
                                
                                result[uid].mom += 1;
                            }
                        }
                    }
                    if (game.fouls != null)  {
                        for (const item of Object.values(game.fouls)) {
                            if (!gameCount[item.uid]) {
                                gameCount[item.uid] = [];
                            }
                            if (!gameCount[item.uid].includes(game.id)) {
                                gameCount[item.uid].push(game.id);
                            }
                            if (!teamId || item.teamId === teamId) {
                                if (!Object.keys(result).includes(item.uid)) {
                                    result[item.uid] = {
                                        uid: item.uid,
                                        score: 0,
                                        assist: 0,
                                        foul: 0,
                                        yellow: 0,
                                        red: 0,
                                        mom: 0,
                                    }
                                }
                                result[item.uid].foul += 1;
                                if (item.level === 'red') {
                                    result[item.uid].red += 1;
                                }
                                if (item.level === 'yellow') {
                                    result[item.uid].yellow += 1;
                                }
                            }
                        }
                    }
                    if (game.gameList) {
                        for (const team of Object.values(game.gameList)) {
                            for (const uid of Object.values(team)) {
                                if (!gameCount[uid]) {
                                    gameCount[uid] = [];
                                }
                                if (!gameCount[uid].includes(game.id)) {
                                    gameCount[uid].push(game.id);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    for (const [uid, value] of Object.entries(result)) {
        if (gameCount[uid]) {
            value.games = gameCount[uid].length;
        } else {
            value.games = 0;
        }
    }
    return result;
}
export const getSeasonRoundArray = (season) => {
    let subSeasonArr = [];
    if (season.type === SEASON_TYPE_LEAGUE) {
        if (season.subSeasons && season.subSeasons.league) {
            subSeasonArr.push(season.subSeasons.league);
        }
    } else if (season.type === SEASON_TYPE_PLAYOFF) {
        if (season.subSeasons && season.subSeasons.league) {
            subSeasonArr.push(season.subSeasons.league);
        }
        if (season.subSeasons && season.subSeasons.knockout) {
            subSeasonArr.push(season.subSeasons.knockout);
        }
    } else if (season.type === SEASON_TYPE_TOURNAMENT) {
        if (season.subSeasons && season.subSeasons.group) {
            subSeasonArr.push(season.subSeasons.group);
        }
        if (season.subSeasons && season.subSeasons.knockout) {
            subSeasonArr.push(season.subSeasons.knockout);
        }
    } else {
        if (season.qualification && season.subSeasons && season.subSeasons[season.qualification]) {
            subSeasonArr.push(season.subSeasons[season.qualification]);
        }
        if (season.subSeasons && season.subSeasons.knockout) {
            subSeasonArr.push(season.subSeasons.knockout);
        }
        if (season.subSeasons && season.subSeasons.subSeasons) {
            for (const round of Object.values(season.subSeasons)) {
                if (!subSeasonArr.includes(round)) {
                    subSeasonArr.push(round);
                }
            }
        }
    }
    return subSeasonArr;
}
export const getSeasonFinalRoundReady = (season) => {
    if (season.type === SEASON_TYPE_LEAGUE) {
        if (season.subSeasons && season.subSeasons.league && season.subSeasons.league.status === STATUS_FINISHED) {
            return true;
        }
    } else if (season.type === SEASON_TYPE_PLAYOFF) {
        if (season.subSeasons && season.subSeasons.league && season.subSeasons.league.status === STATUS_FINISHED) {
            return true;
        }
    } else if (season.type === SEASON_TYPE_TOURNAMENT) {
        if (season.subSeasons && season.subSeasons.group && season.subSeasons.group.status === STATUS_FINISHED) {
            return true;
        }
    } else if (season.qualification && season.subSeasons && season.subSeasons[season.qualification] && season.subSeasons[season.qualification].status === STATUS_FINISHED) {
        return true;
    }
    return false;
}
export const buildEmptySchedule = (subSeason, games, season, teamIdArr) => {

    let finalId = null;
    let final34Id = null;
    if (games.length === 0) {
        let teamCount = subSeason.startTeams;
        let levels = [];
        let now = season.endTime ? season.endTime - 2 * DAY_TIME : new Date().valueOf();
        while (teamCount >= 2) {
            let lvlGames = [];
            for(var i = 0; i < teamCount / 2; i ++) {
                const item = {
                    id: 'game_' + (teamCount / 2).toString() + "_" + i.toString(),
                    divisionId: season.divisionId,
                    organizationId: season.organizationId,
                    seasonId: season.id,
                    subSeasonId: subSeason.id,
                    start: now,
                    roundIdx: teamCount / 2,
                    gameIdx: i, 
                    final: teamCount === 2,
                    semiFinal: teamCount === 4,
                }
                now += 2 * HOUR_TIME;
                if (teamCount === subSeason.startTeams && teamIdArr && (i * 2) < teamIdArr.length) {
                    item.homeTeam = teamIdArr[i * 2].id;
                }
                if (teamCount === subSeason.startTeams && teamIdArr && (i * 2 + 1) < teamIdArr.length) {
                    item.awayTeam = teamIdArr[i * 2 + 1].id;
                }
                lvlGames.push(item);
                games.push(item);
                if (teamCount === 2) {
                    finalId = item.id;
                }
            }
            if (teamCount === 2) {
                const item = {
                    id: 'game_' + (teamCount / 2).toString() + "_1",
                    divisionId: season.divisionId,
                    organizationId: season.organizationId,
                    seasonId: season.id,
                    subSeasonId: subSeason.id,
                    start: now,
                    roundIdx: teamCount / 2,
                    gameIdx: 1, 
                    final: true,
                    semiFinal: false,
                }
                lvlGames.push(item);
                games.push(item);
                final34Id = item.id;
            }
            teamCount /= 2;
            levels.push(lvlGames);
        }
        let nodes = [];
        let rounds = {};
        for (i = levels.length - 1; i >= 0; i --) {
            const idx = levels.length - 1 - i;
            const finalRound = levels[idx][0].final;
            nodes[i] = [];
            let round = {
                id: 'round_' + idx.toString(),
                index: idx,
                gameIds: {},
                teamCount: levels[idx].length
            };
            for (var j = 0; j < levels[idx].length; j ++) {
                let item = levels[idx][j];
                round.gameIds[item.id] = item.id;
                if (!finalRound || j !== 1) {
                    nodes[i].push({
                        name: item.id,
                        attributes: item
                    });
                }
            }
            rounds[round.id] = round
        }
        for (i = 0; i < nodes.length - 1; i ++) {
            for (j = 0; j < nodes[i].length; j ++) {
                nodes[i][j].children = [nodes[i + 1][j * 2], nodes[i + 1][j * 2 + 1]];
                if (nodes[i + 1][j * 2].attributes.semiFinal) {
                    nodes[i + 1][j * 2].attributes.nextId = finalId;
                    nodes[i + 1][j * 2].attributes.loserGoId = final34Id;
                } else {
                    nodes[i + 1][j * 2].attributes.nextId = nodes[i][j].attributes.id;
                }
                if (nodes[i + 1][j * 2 + 1].attributes.semiFinal) {
                    nodes[i + 1][j * 2 + 1].attributes.nextId = finalId;
                    nodes[i + 1][j * 2 + 1].attributes.loserGoId = final34Id;
                } else {
                    nodes[i + 1][j * 2 + 1].attributes.nextId = nodes[i][j].attributes.id;
                }
            }
            
        }
        
        return({gameTree: nodes.length > 0 && nodes[0] && nodes[0].length > 0 ? nodes[0][0] : {}, rounds: rounds, games: games});
    } else {
        games.sort((a,b)=>{
            if (a.id === b.id) 
                return 0;

            return a.id > b.id ? -1 : 1;
        })
        let levels = [];
        let curIdx = -1;
        let items = [];

        var idx = 0;
        for (i = 0; i < games.length; i ++) {
            if (games[i].roundIdx !== curIdx) {
                items = [];
                levels.push(items);
                curIdx = games[i].roundIdx;
            }
            items.push(games[i]);
            if (games[i].final && games[i].gameIdx === 0) {                
                finalId = games[i].id;
            }
            if (games[i].final && games[i].gameIdx === 1) {                
                final34Id = games[i].id;
            }
            if (games[i].roundIdx === subSeason.startTeams / 2) {
                if (teamIdArr && (idx * 2) < teamIdArr.length) {
                    games[i].homeTeam = teamIdArr[idx * 2].id;
                }
                if (teamIdArr && (idx * 2 + 1) < teamIdArr.length) {
                    games[i].awayTeam = teamIdArr[idx * 2 + 1].id;
                }
                idx ++;
            }
        }
        let nodes = [];
        let rounds = {};
        for (i = levels.length - 1; i >= 0; i --) {
            const idx = levels.length - 1 - i;
            const finalRound = levels[idx][0].final;
            nodes[i] = [];
            let round = {
                id: 'round_' + idx.toString(),
                index: idx,
                gameIds: {},
                teamCount: levels[idx].length

            };
            for (j = 0; j < levels[idx].length; j ++) {
                let item = levels[idx][j];
                round.gameIds[item.id] = item.id;
                if (!finalRound || j !== 1) {
                    nodes[i].push({
                        name: item.id,
                        attributes: item
                    });
                }
            }
            rounds[round.id] = round
        }
        for (i = 0; i < nodes.length - 1; i ++) {
            for (j = 0; j < nodes[i].length; j ++) {
                nodes[i][j].children = [nodes[i + 1][j * 2], nodes[i + 1][j * 2 + 1]];
                if (nodes[i + 1][j * 2].attributes.semiFinal) {
                    nodes[i + 1][j * 2].attributes.nextId = finalId;
                    nodes[i + 1][j * 2].attributes.loserGoId = final34Id;
                } else {
                    nodes[i + 1][j * 2].attributes.nextId = nodes[i][j].attributes.id;
                }
                if (nodes[i + 1][j * 2 + 1].attributes.semiFinal) {
                    nodes[i + 1][j * 2 + 1].attributes.nextId = finalId;
                    nodes[i + 1][j * 2 + 1].attributes.loserGoId = final34Id;
                } else {
                    nodes[i + 1][j * 2 + 1].attributes.nextId = nodes[i][j].attributes.id;
                }
            }
            
        }
        
        return({gameTree: nodes[0][0], rounds: rounds, games: games});
    }
}
export const getPlayerSubSeasonStatisticsList = (val, teamId, divisions)=> {

    let result = {};
    let division = divisions ? divisions[val.divisionId] : null;
    if (val.games != null)  {
        for (const game of Object.values(val.games)) {
            if (game.scores != null)  {
                for (const item of Object.values(game.scores)) {
                    if (teamId == null || item.teamId === teamId) {
                        if (!Object.keys(result).includes(item.uid)) {
                            result[item.uid] = {
                                uid: item.uid,
                                score: 0,
                                assist: 0,
                                foul: 0,
                                yellow: 0,
                                red: 0,
                            }
                        }
                        if (item.assist != null && !Object.keys(result).includes(item.assist)) {
                            result[item.assist] = {
                                uid: item.assist,
                                score: 0,
                                assist: 0,
                                foul: 0,
                                yellow: 0,
                                red: 0,
                            }
                        }
                        let number = item.number;
                        if (!number && division && division.category && division.category.scoreData) {
                            for (const score of Object.values(division.category.scoreData)) {
                                if (score.number) {
                                    number = score.number;
                                }
                            }
                        }
                        if (!number || number === -1) {
                            number = 1;
                        }
                        result[item.uid].score += number;
                        if (item.assist != null) {
                            result[item.assist].assist += number;
                        }
                    }
                }
            }
            if (game.fouls != null)  {
                for (const item of Object.values(game.fouls)) {
                    if (teamId == null || item.teamId === teamId) {
                        if (!Object.keys(result).includes(item.uid)) {
                            result[item.uid] = {
                                uid: item.uid,
                                score: 0,
                                assist: 0,
                                foul: 0,
                                yellow: 0,
                                red: 0,
                            }
                        }
                        result[item.uid].foul += 1;
                        if (item.level === 'red') {
                            result[item.uid].red += 1;
                        }
                        if (item.level === 'yellow') {
                            result[item.uid].yellow += 1;
                        }
                    }
                }
            }
        }
    }
    return result;
}