import React, { Component } from "react";
import { t } from "i18next";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { MdMenu, MdClose } from 'react-icons/md';

import { getLogo, getTitle } from 'utils/Utils';
import { withRouter } from 'utils/Routher';

class LandingNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            headerTransparent: window.pageYOffset < 60,
            windowWidth: window.innerWidth,
            menuOpen: false,
        }
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        const { redirect, router } = this.props;
        this.props.setAuthRedirect(redirect ? redirect : router.location.pathname);
        window.addEventListener('resize', this.updateDimensions);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        const { team, user, loggingIn, redirect, router } = this.props;
        if (user && user.uid && loggingIn === false && prevProps.loggingIn === true) {
            this.setState({showAuth: false});
            
            const needToJoin = user && (!team || !team.playerIds || !Object.values(team.playerIds).includes(user.uid));
            if (needToJoin) {
                this.setState({showJoin: true});
            }
        }
        this.props.setAuthRedirect(redirect ? redirect : router.location.pathname);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('scroll', this.handleScroll);
    }

    updateDimensions() {
        this.setState({windowWidth: window.innerWidth});
    }

    handleScroll(event) {
        let scrollTop = window.pageYOffset;
    
        this.setState({
            headerTransparent: scrollTop < 60
        });
    }

    render() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {windowWidth, menuOpen} = this.state;

        const curLink = this.props.router.location.pathname;
        
        let location = "home";
        if (curLink === "/terms-of-services") {
            location = "terms";
        } else if (curLink === "/privacy-policy") {
            location = "policy";
        }
        
        //const trainingLink = '/training';
        const termsLink = '/terms-of-services';
        const policyLink = '/privacy-policy';
        const homeLink = '/';
        const dashbaordLink = !organizationSubscription && !freeOrganization ? 'organizer/create' : '/organizer'
        
        const icon = getLogo();

        const homeClass = location === "home" ? "org-a-active" : "org-a";
        const termsClass = location === "terms" ? "org-a-active" : "org-a";
        const policyClass = location === "policy" ? "org-a-active" : "org-a";
        
        if (windowWidth > 768)  {
            return (
                <div className='main-landing-nav-wrapper'>
                    <div className="main-landing-header container">
                        <a className='org-landing-icon' href={homeLink}>
                            <img src={icon} className="home-logo" style={{marginLeft: 10}} alt='logo'/>
                            <div className="home-nav-title-inverse">{getTitle()} </div>
                        </a>
                        <ul className='nav org-landing-links'>
                            <li className="org-landing-li">
                                <a 
                                href={homeLink} 
                                className={homeClass}>
                                    {t('home')}
                                </a>
                            </li>
                            <li className="org-landing-li">
                                <a 
                                href={termsLink} 
                                className={termsClass}>
                                    {t('terms')}
                                </a>
                            </li>
                            <li className="org-landing-li">
                                <a 
                                href={policyLink} 
                                className={policyClass}>
                                    {t('policy')}
                                </a>
                            </li>
                            {user && user.isAdmin && (
                            <li className="org-landing-li">
                                <a 
                                href="/admin" 
                                className="org-a">
                                    {t('admin')}
                                </a>
                            </li>
                            )}
                            {user && user.username ? (
                            <li className="org-landing-li">
                                <a 
                                href={dashbaordLink} 
                                className="org-a">
                                    {t('dashboard')}
                                </a>
                            </li>
                            ) : (
                            <li className="org-landing-li">
                                <a 
                                href="/login" 
                                className="org-a">
                                    {t('login')}
                                </a>
                            </li>
                            )}
                            
                            <li className="org-landing-li">
                            {user && user.username ? (
                                <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                    this.props.logout();
                                }}>
                                    {t('logout')}
                                </button>
                            ) : (
                                <a 
                                href="/signup" 
                                className="org-a">
                                    {t('sign_up')}
                                </a>
                            )}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='main-landing-nav-floating'>
                    <div className='main-landing-header'>
                        <a className='org-landing-links' href={homeLink}>
                            <img src={icon} className="home-logo" style={{marginLeft: 10}} alt='logo'/>
                            <div className="home-nav-title-inverse">{getTitle()} </div>
                        </a>
                        <div className='org-landing-menu-toggle' onClick={(e)=> {
                            this.setState({menuOpen: !menuOpen})
                        }}>{menuOpen ? <MdClose/> : <MdMenu/> }</div>
                    </div>
                    {menuOpen && (
                    <ul className="main-landing-dropdown-menu" role="menu" aria-labelledby="dLabel">
                        <li className="dropdown-li">
                            <a 
                            href={homeLink} 
                            className={homeClass}>
                                {t('home')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={termsLink} 
                            className={termsClass}>
                                {t('terms')}
                            </a>
                        </li>
                        <li className="dropdown-li">
                            <a 
                            href={policyLink} 
                            className={policyClass}>
                                {t('policy')}
                            </a>
                        </li>
                        {user && user.isAdmin && (
                        <li className="dropdown-li">
                            <a 
                            href="/admin" 
                            className="org-a">
                                {t('admin')}
                            </a>
                        </li>
                        )}
                        {user && user.username ? (
                        <li className="dropdown-li">
                            <a 
                            href={dashbaordLink} 
                            className="org-a">
                                {t('dashboard')}
                            </a>
                        </li>
                        ) : (
                            <li className="dropdown-li">
                            <a 
                            href="/login" 
                            className="org-a">
                                {t('login')}
                            </a>
                        </li>
                        )}
                        
                        <li className="dropdown-li">
                        {user != null ? (
                            <button style={{background: 'transparent', border: 'none'}} className={""} onClick={e=> {
                                this.props.logout();
                            }}>
                                {t('logout')}
                            </button>
                        ) : (
                            <a 
                            href="/signup" 
                            className="org-a">
                                {t('sign_up')}
                            </a>
                        )}
                        </li>
                    </ul>
                    )}
                </div>
            );
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, child, redirectTo } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { isAdmin, loggingIn, user, child, redirectTo, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = withRouter(connect(mapState, actionCreators)(LandingNavigation));
export { connectedNavigation as LandingNavigation };
