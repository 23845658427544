import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/security_icon.png';

export class PromoCodeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {code, onClickEdit, onClickDelete } = this.props;
        
        if (code != null) {
            return (
                <div>
                    <Card className="shadow-tile">
                        <CardBody>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    <CardImg
                                        className="profile-thumb"
                                        src={defaultImage}
                                        style={{ width: 25, height: 25, marginRight: 10 }}
                                        />
                                    <div className='vertical-center-spacing' style={{fontSize: 14, fontWeight: 800}}>{code.code}</div>
                                </div>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-submit" onClick={e => {
                                        if (onClickDelete != null) {
                                            onClickDelete();
                                        }
                                    }}>{t('delete')}</Button>
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                </div>
                            </div>
                            <div className="one-line-div">{code.coupon.name}</div>
                            <Row>
                                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                                    Duration :
                                    <div className="info-badge-info">
                                    {code.coupon.duration} {code.coupon.duration_in_months != null ? "during " + code.coupon.duration_in_months + " months" : ""}
                                    </div>
                                    Percent Off :
                                    <div className="info-badge-info">
                                    {code.coupon.percent_off} %
                                    </div>
                                </Col>
                                <Col nm={4} xs={12} sm={6}>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default PromoCodeHeader;