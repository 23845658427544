import React, { Component } from "react";
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class ItemsNavigation extends Component {
    render() {
        const {title, subtitle, subTxt, icon, totalCount, detailItem, detailOpen, editMode, editOpen, blockEdit, customLabel,
            onClickBack, onClickEdit, onClickAdd, onClickImport, onClickSkip, onClickCustom, style} = this.props;

        const showDetailOption = (detailOpen || editOpen) && detailItem.id !== null;
        
        return (
            <div className='items-navigation' style={style}>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <div className='detail-nav-btn'>
                        {icon && (
                            <div className='items-navigation-count'>{icon}</div>
                        )}
                        <h3 className='items-navigation-title'>
                        {title}
                        </h3>
                        {subTxt != null ? (
                        <div className='items-navigation-count'>| {subTxt}</div>
                        ) : (
                        <div className='items-navigation-count'>| {totalCount} {subtitle != null ? subtitle : 'Total'}</div>
                        )}
                    </div>
                    {showDetailOption && (
                        <div style={{display: 'inline-block'}}>
                            <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'>
                                {detailItem.title}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                
                {onClickAdd && !blockEdit && !showDetailOption && (
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickAdd != null) {
                        onClickAdd();
                    }
                }}>{editMode ? t('edit') : t('create')}</Button>
                )}
                {onClickSkip && (
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickSkip != null) {
                        onClickSkip();
                    }
                }}>Skip</Button>
                )}
                {onClickImport && (
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickImport != null) {
                        onClickImport();
                    }
                }}>Import</Button>
                )}
                {onClickEdit && (
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickEdit != null) {
                        onClickEdit();
                    }
                }}>{t('edit')}</Button>
                )}
                {showDetailOption && (
                <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickBack != null) {
                        onClickBack();
                    }
                }}>{t('back')}</Button>
                )}
                {onClickCustom && (
                <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onClickCustom != null) {
                        onClickCustom();
                    }
                }}>{customLabel}</Button>
                )}
            </div>
        );
    }
}

export default withTranslation()(ItemsNavigation);