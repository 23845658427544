import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class InfoBadgeSmall extends Component {
    
    render() {
        const {title, subtitle, icon, onClickDetails, onClickEdit, allowDetail, detailLabel, editLabel, detailView } = this.props;
        
        if (title != null) {
            return (
                <div className="info-badge-small">
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb info-badge-icon-small"
                            src={icon} />
                        <CardBody className="info-badge-body-small">
                            <Row>
                                <Col xs={12} sm={8} className="info-col one-line-div">
                                    <h4 className='label label-default one-line-title'>
                                    {title}
                                    </h4>
                                    <div className='wrap-content-parent'>
                                        <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                        {subtitle}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={4} className="vertical-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: -10}}>
                                            {allowDetail && onClickDetails && (
                                                <Button className="btn-submit" onClick={e => {
                                                    if (onClickDetails != null) {
                                                        onClickDetails();
                                                    }
                                                }}>{detailLabel ? detailLabel : t('details')}</Button>
                                            )}
                                            {onClickEdit && (
                                            <Button className="btn-edit" onClick={e => {
                                                if (onClickEdit != null) {
                                                    onClickEdit();
                                                }
                                            }}>{editLabel ? editLabel : t('edit')}</Button>
                                            )}
                                            {detailView && detailView}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(InfoBadgeSmall);