import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AddContentBadge from 'components/detail/AddContentBadge';
import MembersView from 'components/members/MembersView';
import defaultImage from 'assets/img/icons/video_default.png';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import Switch from '@mui/material/Switch';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';

export class ClubBattleEdit extends Component {
    constructor(props) {
        super(props);
        const clubBattle = {};
        if (props.clubBattle != null) {
            for (const [key, value] of Object.entries(props.clubBattle)) {
                clubBattle[key] = value;
            }
        }
        
        this.state = {
            clubBattle: clubBattle,
            progress: false,
            newImage: {},
            imageProgress: 0,
            value: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubBattle} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.clubBattle !== clubBattle)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(clubBattle)) {
                challengeVal[key] = value;
            }
            this.setState({
                clubBattle: challengeVal
            })
        }
    }

    onSaveClick(clubBattle, newImage) {
        const {club, uid, onCloseClick} = this.props;
        if (clubBattle.title == null || clubBattle.title === '') {
            alert("You must input title");
        } else if (clubBattle.description == null || clubBattle.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubBattle.id == null || clubBattle.id === '') {
                clubBattle.id = generateId("/clubBattle");
                clubBattle.owner = uid != null ? uid : "free";
                clubBattle.clubId = club.id;
                clubBattle.active = true;
            }
            clubBattle.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(clubBattle, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick();
                }
            });
        }
    }

    saveChangeWithImage(clubBattle, newImage, success) {
        const objRef = this;
        const {onCloseClick} = this.props;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubBattle.id, 'clubBattle', newImage, (url) => {
                clubBattle.thumbUri = url;
                objRef.saveChange(clubBattle, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    if (onCloseClick != null) {
                        onCloseClick();
                    }
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        } else {
            this.saveChange(clubBattle, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        }
    }

    saveChange(clubBattle, success, failure) {
        writeDb('/clubBattle/' + clubBattle.id, clubBattle, error => {
            if (error) {
                failure(error);
            } else {
                success(clubBattle);
            }
        })
    }

    renderContents() {
        const { clubChallenges } = this.props;
        const { clubBattle, value } = this.state;

        const result = [];
    
        for (const[key, item] of Object.entries(clubChallenges)) {
    
            if ((value === 0 && clubBattle.challengeIds != null && Object.values(clubBattle.challengeIds).includes(item.id)) ||
            (value === 1 && (clubBattle.challengeIds == null || !Object.values(clubBattle.challengeIds).includes(item.id)))) {
                result.push(
                    <AddContentBadge 
                        key={key}
                        title={item.title}
                        thumbUri={item.thumbUri}
                        defaultImage={defaultImage}
                        toAdd={value === 1}
                        onClickAdd={() => {
                            if (!clubBattle.challengeIds) {
                                clubBattle.challengeIds = {};
                            }
                            clubBattle.challengeIds[key] = key;
                            this.setState({clubBattle: clubBattle});
                        }}
                        onClickRemove={() => {
                            if (!clubBattle.challengeIds) {
                                clubBattle.challengeIds = {};
                            }
                            delete clubBattle.challengeIds[key];
                            this.setState({clubBattle: clubBattle});
                        }} />
                );
            }
        }

        return result;
    }

    render() {
        const {club, onCloseClick} = this.props;
        const {clubBattle, newImage, imageProgress, progress, value} = this.state;
        
        const deadline = clubBattle.deadline != null ? new Date(clubBattle.deadline) : new Date();
        
        if (clubBattle != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubBattle.title = e.target.value;
                            this.setState({clubBattle: clubBattle});
                        }} value={clubBattle.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubBattle.description} onChange={e => {
                            clubBattle.description = e.target.value;
                            this.setState({clubBattle: clubBattle});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <div>Members: {clubBattle.members != null ? Object.values(clubBattle.members).length : 0}</div>
                        <div className='divider'/>
                        <MembersView
                            available={club.playerIds}
                            members={clubBattle.members}
                            onSaveClick={(members)=> {
                                clubBattle.members = {};
                                if (members != null) {
                                    for (const[key, value] of Object.entries(members)) {
                                        clubBattle.members[key] = value;
                                    }
                                }
                                this.setState({clubBattle: clubBattle});
                            }} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubBattle.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    <FormGroup>
                        <Label for="duration_limit">Deadline</Label>
                        <CustomDateTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Date Time picker"
                            value={deadline}
                            onChange={ date => {
                                clubBattle.deadline = date.valueOf();
                                this.setState({clubBattle: clubBattle});
                            }} />
                    </FormGroup>
                    <div className='player-form-item'>
                        <div className='player-form-title'>
                            {t('active')}
                        </div>
                        <div className='player-form-value form-setting-control'>
                            <Switch
                                checked={clubBattle.active}
                                onChange={e=>{
                                    clubBattle.active = e.target.checked;
                                    this.setState({clubBattle: clubBattle});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </div>
                    </div>
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={value}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                            onChange={(e, value) => {
                                this.setState({value: value});
                            }} >

                            <Tab label="Challenges" />
                            <Tab label="To Add" />
                        </Tabs>
                    </Box>
                    {this.renderContents()}

                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubBattle, newImage);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubBattleEdit);