import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import { FcTreeStructure } from 'react-icons/fc';
import DivisionSetupView from 'components/edit/organization/setup/DivisionSetupView';
import { cartActions } from 'redux/_actions';
import { userActions } from 'redux/_actions';
import { Card, Row, Col, Button, Spinner } from 'reactstrap';
import ItemsNavigation from 'components/detail/ItemsNavigation';

import LoadingView from 'components/detail/loading/LoadingView';
import { 
    STRIPE_PUBLISHABLE_KEY, 
    replaceUrl, 
} from 'utils/Utils';
import { generateId, invokeHttpsApi, listenDb } from 'utils/API';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';
import { SeasonCreateView } from 'components/edit/organization/setup/SeasonCreateView';
import { SeasonScheduleView } from 'components/edit/organization/setup/SeasonScheduleView';
import SeasonBadge from 'components/detail/organization/landing/SeasonBadge';
import {SeasonDetail} from 'components/detail/organization/SeasonDetail';
import { SEASON_TYPE_CUSTOM } from 'utils/constants';
import {
    RiAddCircleFill
} from 'react-icons/ri';
import {
    GiHourglass
} from 'react-icons/gi';

class SeasonPayPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisions: {},
            organizations: {},
            seasons: {},
            trophies: {},
            users: {},
            teams: {},
            locations: {},
            category: {},
            divisionChoice: {},
            wallets: {},
            editOpen: false,
            loading: true,
        };
        this.reloadRef = null;
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        if (this.props.user) {
            this.loadContents(id);
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        const seasonId = this.props.router.params.seasonId;
        if (!prevUid && uid) {
            this.loadContents(seasonId);
        }
    }

    loadContents(seasonId) {
        const {seasonChoice} = this.state;
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-seasonsQuery', {}, (data) => {
            const divisions = {};
            const organization = {};
            const seasons = {};
            const trophies = {};
            const profileData = {};
            const users = {};
            const teams = {};
            const locations = {};
            const category = {};
            const cards = {};
            const purchaseDict = {};

            if (data.success) {
                const dict = data.dict;
                for (const [key, value] of Object.entries(dict.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(dict.organization)) {
                    organization[key] = value;
                }
                for (const [key, value] of Object.entries(dict.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(dict.trophies)) {
                    trophies[key] = value;
                }
                for (const [key, value] of Object.entries(dict.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(dict.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(dict.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(dict.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(dict.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(dict.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(dict.purchaseDict)) {
                    purchaseDict[key] = value;
                }
            }
            let choiceId = seasonId;
            if (seasonChoice) {
                choiceId = seasonChoice.id;
            }
            let update = seasons[choiceId];
            if (update != null) {
                for (const [key, value] of Object.entries(update)) {
                    seasonChoice[key] = value;
                }
            }
            if (this.reloadRef) {
                this.reloadRef();
                this.reloadRef = null;
            }
            if (organization.id) {
                this.reloadRef = listenDb("/organizationPurchase/" + organization.id, (data) => {
                    if (data) {
                        for (const [k,v] of Object.entries(data)) {
                            purchaseDict[k] = v;
                        }
                    }
                })
            }

            this.setState({
                divisions: divisions,
                organization: organization,
                seasons: seasons,
                teams: teams,
                trophies: trophies,
                locations: locations,
                profileData: profileData,
                seasonChoice: seasonChoice,
                users: users,
                category: category,
                cards: cards,
                purchaseDict: purchaseDict,
                loading: false,
                editOpen: Object.values(divisions).length === 0,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({lodaing: false});
        });
    }

    renderBuyTicket() {
        const {user, checkoutProgress, checkoutCartItems} = this.props;
        const {organization} = this.state;
        const price = 48;
        
        return (
            <Col lg={4} sm={6} xs={12} key={0} className='grid-item-wrapper'>
                <Card className="shadow-tile grid-badge" style={{height: 'inherit'}}>
                    <div className='grid-item-row'>
                        <span style={{
                            margin: 'auto',
                            color: '#3063FF',
                            fontSize: 64
                        }}><RiAddCircleFill /></span>
                    </div>
                    <div className='grid-item-text-center' style={{
                        color: '#fa616a',
                        fontSize: 32
                    }}>
                        ${price}
                    </div>
                    <div className='grid-item-text-center'>
                        {t('buy_season_ticket_description')}
                    </div>
                    {checkoutProgress ? (
                    <div className='grid-item-text-center'>
                        <Spinner color="primary" />
                    </div>
                    ) : (
                    <div className='grid-item-row'>
                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                            const id = generateId("/organizationPurchase/" + organization.id + "/seasonTickets/");
                            const item = {
                                id: id,
                                ticketId: id,
                                organizationId: organization.id,
                                price: price,
                                uid: user.uid,
                            };
                            const dict = {};
                            dict[item.id] = item;
                            checkoutCartItems(null, dict, '', price, window.location.origin + "/organizer/season_pay", (async (session) => {
                                const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
                                stripe.redirectToCheckout({ sessionId: session.id });
                            }), (() => {
                                alert("Requesting Checkout Session Failed.")
                            }))
                        }}>{t('buy_season_ticket')}</Button>
                    </div>
                    )}
                </Card>
            </Col>
        );
    }

    renderTicket(ticket) {
        const {seasons} = this.state;

        if (seasons[ticket.seasonId]) {
            return this.renderSeasonHeader(seasons[ticket.seasonId]);
        }
        
        return (
            <Col lg={4} sm={6} xs={12} key={ticket.id} className='grid-item-wrapper'>
                <Card className="shadow-tile grid-badge" style={{height: 'inherit'}}>
                    <div className='grid-item-row'>
                        <span style={{
                            margin: 'auto',
                            color: '#fa616a',
                            fontSize: 64
                        }}><GiHourglass /></span>
                    </div>
                    <div className='grid-item-text-center' style={{
                        color: '#fa616a',
                        fontSize: 32
                    }}>
                        {t('available_ticket')}
                    </div>
                    <div className='grid-item-text-center'>
                        {t('setup_new_season_description')}
                    </div>
                    <div className='grid-item-row'>
                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                            this.setState({
                                seasonCreate: {
                                    type: SEASON_TYPE_CUSTOM,
                                    ticketId: ticket.id,
                                },
                                ticketChoice: ticket,
                                editingSeason: true,
                            })
                        }}>{t('setup_new_season')}</Button>
                    </div>
                </Card>
            </Col>
        );
    }

    renderSeasonHeader(season) {
        const {divisions} = this.state;
        
        return (
            <Col lg={4} sm={6} xs={12} key={season.id} className='grid-item-wrapper'>
                <SeasonBadge
                    key={season.id}
                    season={season}
                    divisions={divisions}
                    allowDetail={true}
                    onClickEdit={()=> {
                        this.setState({seasonSetup: season})
                    }}
                    onClickDetails={()=> {
                        this.setState({seasonChoice: season})
                        replaceUrl('/organizer/season_pay/' + season.id);
                    }} />
            </Col>
        );
    }

    renderSeasonSetup() {
        const {user} = this.props;
        const {organization, divisions, teams, seasons, trophies, users, 
            seasonCreate, seasonSetup, locations, divisionChoice, category, editOpen} = this.state;
        //const seasonId = this.props.router.params.seasonId;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        if (Object.values(divisions).length === 0 || editOpen) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <DivisionSetupView 
                        division={divisionChoice}
                        organization={organization}
                        categorys={category}
                        createMode={Object.values(divisions).length === 0 || !divisionChoice.id} 
                        uid={user.uid}
                        onDivisionUpdate={update => {
                            if (update.id) {
                                divisions[update.id] = update;
                            }
                            this.setState({divisions: divisions, divisionChoice: update});
                        }}
                        onCloseClick={()=> {
                            this.setState({divisionChoice: {}, editOpen: false});
                        }}
                        onSaveDone={(update)=> {
                            if (update.id) {
                                divisions[update.id] = update;
                            }
                            this.setState({divisions: divisions, editOpen: false});
                        }} />
                </div>
            );
        } else if (seasonCreate) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <SeasonCreateView 
                        divisions={divisions}
                        organization={organization}
                        season={seasonCreate}
                        locations={locations}
                        teams={teams}
                        trophies={trophies}
                        uid={user.uid}
                        onSeasonUpdate={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                seasonChoice: update
                            })
                        }}
                        onCloseClick={()=> {
                            this.setState({seasonCreate: null})
                        }}
                        onContinue={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                scheduling: true,
                            })
                        }}
                        onSaveDone={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                seasonCreate: null,
                                seasonSetup: update,
                            })
                            this.props.router.navigate(`/organizer/divisions/${update.divisionId}/${update.id}`);
                        }} />
                </div>
            );
        } else if (seasonSetup) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <SeasonScheduleView 
                        divisions={divisions}
                        organization={organization}
                        season={seasonSetup}
                        locations={locations}
                        teams={teams}
                        users={users}
                        trophies={trophies}
                        uid={user.uid}
                        onSeasonUpdate={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                seasonChoice: update
                            })
                        }}
                        onCloseClick={()=> {
                            this.setState({seasonSetup: null})
                        }}
                        onContinue={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                                scheduling: true,
                            })
                        }}
                        onSaveDone={(update)=> {
                            seasons[update.id] = update;

                            this.setState({
                                seasons: seasons,
                            })
                            this.props.router.navigate(`/organizer/divisions/${update.divisionId}/${update.id}`);
                        }}
                        onUserUpdate={(update, team)=> {
                            if (update) {
                                users[update.uid] = update;
                            }
                            if (team) {
                                teams[team.id] = team;
                            }
                            this.setState({users: users, teams: teams});
                        }} />
                </div>
            );
        }
        return <div />;
    }

    renderContents() {
        const {user} = this.props;
        const {organization, divisions, teams, seasons, cards, trophies, wallets, users, 
            ticketChoice, editingSeason, locations, profileData, seasonChoice, purchaseDict} = this.state;
        //const seasonId = this.props.router.params.seasonId;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        if (ticketChoice && editingSeason) {
            return this.renderSeasonSetup();
        } else if (seasonChoice) {
            return (
                <SeasonDetail
                    season={seasonChoice}
                    division={divisions[seasonChoice.divisionId]}
                    organization={organization}
                    teams={teams}
                    trophies={trophies}
                    locations={locations}
                    profileData={profileData}
                    seasons={seasons}
                    uid={user.uid}
                    user={user}
                    users={users}
                    wallets={wallets}
                    cards={cards}
                    onSeasonDeleted={(update)=> {
                        if (update && update.id && seasons) {
                            for (const item of Object.values(seasons)) {
                                if (item.id !== update.id) {
                                    this.setState({seasonChoice: item});
                                    break;
                                }
                            }
                        } else {
                            this.setState({seasonChoice: {}, configMode: true});
                        }
                    }}
                    onChanged={(update)=> {
                        this.setState({seasonChoice: update})
                    }}
                    onPickSeason={(update)=>{
                        this.setState({seasonChoice: update})
                        replaceUrl('/organizer/season_pay/' + seasonChoice.id);
                    }}
                    onSaveDone={()=> {
                        this.loadContents();
                    }} 
                    onUserUpdate={(update, team)=> {
                        if (update) {
                            users[update.uid] = update;
                        }
                        if (team) {
                            teams[team.id] = team;
                        }
                        this.setState({users: users, teams: teams});
                    }}
                />
            );
        } else {
            let items = [];
            if (purchaseDict && purchaseDict.seasonTickets)
            for (const ticket of Object.values(purchaseDict.seasonTickets)) {
                items.push(
                    this.renderTicket(ticket)
                );
            }
            return (
            <div>
                
                <Row style={{margin: 0}}> 
                {this.renderBuyTicket()}
                {items}                   
                </Row>
            </div>
            );
        }
    }

    render() {
        const {user} = this.props;
        const {loading, divisions, seasonChoice, editOpen} = this.state;
        
        let curConetntCount = 0;
        for (const[, value] of Object.entries(divisions)) {
            if (value.owner === user.uid) {
                curConetntCount += 1;
            }
        }

        if (user == null) {
            return <div/>
        }

        return (
            <div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        <ItemsNavigation
                            title={t('season_pay')}
                            icon={<FcTreeStructure/>}
                            detailOpen={seasonChoice}
                            editOpen={editOpen}
                            totalCount={curConetntCount}
                            detailItem={seasonChoice}
                            onClickBack={()=>{
                                this.setState({seasonChoice: null})
                            }} />
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    const { checkoutProgress, cart, session } = state.cartData;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization, checkoutProgress, cart, session };
}

const actionCreators = {
    checkoutCartItems: cartActions.checkoutCartItems,
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(SeasonPayPage));
export { connectedApp as SeasonPayPage };
