export const dataConstants = {
    UPDATE_DATA_REQUEST: 'UPDATE_DATA_REQUEST',
    UPDATE_DATA_IMAGE_REQUEST: 'UPDATE_DATA_IMAGE_REQUEST',
    UPDATE_DATA_VIDEO_REQUEST: 'UPDATE_DATA_IMAGE_REQUEST',

    UPDATE_SUCCESS: 'DATA_UPDATE_SUCCESS',
    UPDATE_IMAGE_PROGRESS: 'UPDATE_IMAGE_PROGRESS',
    UPDATE_VIDEO_PROGRESS: 'UPDATE_VIDEO_PROGRESS',
    UPDATE_FAILURE: 'DATA_UPDATE_FAILURE',
    
};
