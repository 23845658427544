import React, { Component } from 'react';
import { 
    Row,
    Col,
    CardImg,
    Button,
    Input,
    Spinner,
    Form,
    Modal,
    ModalBody
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import {
    HiBookmark,
    HiCheckCircle,
    HiPlusCircle,
} from 'react-icons/hi';
import {
    RiCloseCircleFill
} from 'react-icons/ri';
import CustomDateTimePicker from 'components/template/CustomDateTimePicker';

import { toDateString, toSimpleDateTimeString, getSanctionGames } from 'utils/Utils';

import defaultImage from 'assets/img/icons/player_photo_default.png';
import teamImage from 'assets/img/icons/club_icon_default.png';
import { generateId, invokeHttpsApi } from 'utils/API';

export class SeasonSanctions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: {},
            users: {},
            category: {},
            divisionChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }

    getCardsArr() {    
        const {season, cards, users} = this.props;

        let result = [];
        if (cards) {
            for (const foul of Object.values(cards)) {
                if (foul.seasonId !== season.id) {
                    continue;
                }
                let user = users[foul.uid] != null ? users[foul.uid] : {};
                
                foul.user = user;

                if (season.subSeasons && season.subSeasons[foul.subSeasonId] &&  
                    season.subSeasons[foul.subSeasonId].games &&  season.subSeasons[foul.subSeasonId].games[foul.gameId]) {
                
                    foul.game = season.subSeasons[foul.subSeasonId].games[foul.gameId];
                }
                
                result.push(foul);
            }
        }
        result.sort((a, b)=> {
            return b.timestamp - a.timestamp;
        })
        return result;
    }
    
    getAvailableCardsArr() {    
        const {season, cards, users} = this.props;
        const {sanctionChoice} = this.state;

        let result = [];
        if (cards) {
            for (const foul of Object.values(cards)) {
                if (foul.seasonId !== season.id || foul.uid !== sanctionChoice.uid || foul.teamId !== sanctionChoice.teamId) {
                    continue;
                }
                if (foul.sanctionId && foul.sanctionId !== sanctionChoice.id) {
                    continue;
                }
                let user = users[foul.uid] != null ? users[foul.uid] : {};
                
                foul.user = user;

                if (season.subSeasons && season.subSeasons[foul.subSeasonId] &&  
                    season.subSeasons[foul.subSeasonId].games &&  season.subSeasons[foul.subSeasonId].games[foul.gameId]) {
                
                    foul.game = season.subSeasons[foul.subSeasonId].games[foul.gameId];
                }
                
                result.push(foul);
            }
        }
        result.sort((a, b)=> {
            return b.timestamp - a.timestamp;
        })
        return result;
    }
    
    onSaveClick(sanction) {
        const {season, onChanged} = this.props;
        if (!sanction.type) {
            alert('You should pick valid sanction type.')
            return
        }
        if (sanction.type === 'games' && (!sanction.gameCount || sanction.gameCount <= 0)) {
            alert('You should pick input a valid suspend game count.')
            return
        }
        if (sanction.type === 'duration' && (!sanction.startTime || !sanction.endTime || sanction.startTime <= 0 || sanction.endTime <= 0 || sanction.endTime <= sanction.startTime)) {
            alert('You should pick input a valid suspend game duration.')
            return
        }
        
        if (!sanction.id) {
            sanction.id = generateId('/season/' + season.id + '/sanctions');
        }
        sanction.timestamp = new Date().valueOf();

        this.setState({progress: true});
        invokeHttpsApi('organizerQuery-submitSeasonSanction', {sanction: sanction}, (data) => {
            
            this.setState({progress: false})
            if (onChanged != null) {
                onChanged();
            }
        }, (error) => {
            console.log('saveContents error', error);
            this.setState({progress: false})
        });
    }

    renderCards() {
        const {season} = this.props;

        let arr = this.getCardsArr();
        
        arr.sort((a,b)=> {
            return b.timestamp - a.timestamp;
        })

        let items = [];
        let rank = 0;
        for (const val of arr) {
            rank ++;
            items.push(
                this.renderCardBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Cards / {season.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={5} xs={5} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                Game
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Card
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Sanction
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCardBadge(item, rank) {
        const {season, division, teams} = this.props;
        let homeTeam = item.game ? teams[item.game.homeTeam] : {};
        let awayTeam = item.game ? teams[item.game.awayTeam] : {};
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : teamImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : teamImage;
        
        const timeStr = toSimpleDateTimeString(item && item.timestamp ? item.timestamp : item.game.start);


        let thumbImage = item.user.photoUri != null ? item.user.photoUri : defaultImage;

        let eventTitle = division && division.category && 
                division.category.foulData &&
                division.category.foulData[item.eventTypeId].title ? 
                division.category.foulData[item.eventTypeId].title : "Foul";

        return (
            <Row>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {item.user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center">
                {item.game ? (
                    <Row>
                        <Col lg={4} xs={4} sm={4} className="text-center col-no-padding">
                            <CardImg
                                className="sanction-game-team-icon"
                                src={homeThumb} />
                            <div className='sanction-game-team-title'>
                            {homeTeam.title}
                            </div>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="text-center game-badge-time col-no-padding" style={{padding: 0, margin: 0}}>
                            <p style={{margin: 0}}>
                                <b>{item.game && item.game.homeScore && item.game.homeScore !== -1 ? item.game.homeScore : "_"} - 
                                {item.game && item.game.homeScore && item.game.awayScore !== -1 ? item.game.awayScore : "_"}
                                </b>
                            </p>
                            <b>{timeStr}</b>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="text-center col-no-padding">
                            <CardImg
                                className="sanction-game-team-icon"
                                src={awayThumb} />
                            <div className='sanction-game-team-title'>
                            {awayTeam.title}
                            </div>
                        </Col>
                    </Row>
                ) : (<div />)}
                    <p style={{margin: 0, textAlign: 'left'}}>Reason: <b>{eventTitle}</b></p>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.level === 'yellow' ? 
                        <span style={{color: '#f0cb5b', fontSize: 24}}><HiBookmark/></span> : 
                        <span style={{color: '#BE4534', fontSize: 24}}><HiBookmark/></span>}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center" onClick={(e) => {
                    if (item.sanctionId) {
                        this.setState({sanctionChoice: season.sanctions[item.sanctionId]})
                    } else {
                        const update = {
                            uid: item.uid,
                            seasonId: season.id,
                            teamId: item.teamId,
                            cardIds: {},
                            foulId: item.foulId,
                            gameId: item.gameId,
                            type: 'games',
                            gameCount: 1,
                        }

                        update.cardIds[item.id] = item.id
                        this.setState({sanctionChoice: update})
                    }
                }}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.sanctionId ? 
                        <span style={{color: '#4CAF50', fontSize: 24}}><HiCheckCircle/></span> : 
                        <span style={{color: '#BE4534', fontSize: 24}}><HiPlusCircle/></span>}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderAvailableCards() {
        let arr = this.getAvailableCardsArr();
        
        arr.sort((a,b)=> {
            return b.timestamp - a.timestamp;
        })

        let items = [];
        let rank = 0;
        for (const val of arr) {
            rank ++;
            items.push(
                this.renderCardTinyBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Cards for this sanction.
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={5} xs={5} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                Game
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                            
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                            
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCardTinyBadge(item) {
        const {division, teams} = this.props;
        const {sanctionChoice} = this.state;
        let homeTeam = item.game ? teams[item.game.homeTeam] : {};
        let awayTeam = item.game ? teams[item.game.awayTeam] : {};
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : teamImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : teamImage;
        
        const timeStr = toDateString(item && item.timestamp ? item.timestamp : item.game.start);


        let thumbImage = item.user.photoUri != null ? item.user.photoUri : defaultImage;
        const added = sanctionChoice.cardIds && Object.values(sanctionChoice.cardIds).includes(item.id);
        let eventTitle = division && division.category && 
                division.category.foulData &&
                division.category.foulData[item.eventTypeId].title ? 
                division.category.foulData[item.eventTypeId].title : "Foul";

        return (
            <Row onClick={(e) => {
                if (!sanctionChoice.cardIds) {
                    sanctionChoice.cardIds = {};
                }
                if (Object.values(sanctionChoice.cardIds).includes(item.id)) {
                    delete sanctionChoice.cardIds[item.id];
                } else {
                    sanctionChoice.cardIds[item.id] = item.id;
                }
                this.setState({sanctionChoice: sanctionChoice})
            }}>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {item.user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center">
                {item.game ? (
                    <Row>
                        <Col lg={4} xs={4} sm={4} className="text-center col-no-padding">
                            <CardImg
                                className="sanction-game-team-icon"
                                src={homeThumb} />
                            <div className='sanction-game-team-title'>
                            {homeTeam.title}
                            </div>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="text-center game-badge-time col-no-padding" style={{padding: 0, margin: 0}}>
                            <p style={{margin: 0}}>
                                <b>{item.game && item.game.homeScore && item.game.homeScore !== -1 ? item.game.homeScore : "_"} - 
                                {item.game && item.game.homeScore && item.game.awayScore !== -1 ? item.game.awayScore : "_"}
                                </b>
                            </p>
                            <b>{timeStr}</b>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="text-center col-no-padding">
                            <CardImg
                                className="sanction-game-team-icon"
                                src={awayThumb} />
                            <div className='sanction-game-team-title'>
                            {awayTeam.title}
                            </div>
                        </Col>
                    </Row>
                ) : (<div />)}
                    <p style={{margin: 0, textAlign: 'left'}}>Reason: <b>{eventTitle}</b></p>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.level === 'yellow' ? 
                        <span style={{color: '#f0cb5b', fontSize: 24}}><HiBookmark/></span> : 
                        <span style={{color: '#BE4534', fontSize: 24}}><HiBookmark/></span>}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {added ? 
                        <span style={{color: '#4CAF50', fontSize: 24}}><HiCheckCircle/></span> : 
                        <span style={{color: '#BE4534', fontSize: 24}}><RiCloseCircleFill/></span>}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderSanctions() {
        const {season} = this.props;

        let arr = [];
        if (season.sanctions) {
            arr = Object.values(season.sanctions);
            
            arr.sort((a,b)=> {
                return b.timestamp - a.timestamp;
            })
        }
        

        let items = [];
        let rank = 0;
        for (const val of arr) {
            rank ++;
            items.push(
                this.renderSanctionBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Sanctions / {season.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                Sanction
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderSanctionBadge(item, rank) {
        const {teams, users} = this.props;
        const user = users[item.uid] ? users[item.uid] : {};
        const team = teams[item.teamId] ? teams[item.teamId] : {};
        let thumbImage = user.photoUri != null ? user.photoUri : defaultImage;
        let teamThumb = team.iconUri != null ? team.iconUri : teamImage;

        let desc = "";
        if (item.type === 'duration') {
            desc = "Suspended within " + toDateString(item.startTime) + "~" + toDateString(item.endTime);
        } else {
            desc = "Next " + (item.gameCount).toString() + " Games"
        }

        return (
            <Row onClick={(e) => {
                this.setState({sanctionChoice: item})
            }}>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={teamThumb} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {desc}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderPeoplePending() {
        const {season, cards} = this.props;
        const pending = {};
        for (const card of Object.values(cards)) {
            if (card.seasonId !== season.id) {
                continue;
            }
            if (card.sanctionId) {
                continue;
            }
            if (!pending[card.uid]) {
                pending[card.uid] = {
                    uid: card.uid,
                    teamId: card.teamId,
                    foulId: card.foulId,
                    gameId: card.gameId,
                    cardIds: {},
                    yellow: 0,
                    red: 0,
                };
            }
            if (card.level === 'yellow') {
                pending[card.uid].yellow += 1;
            }
            if (card.level === 'red') {
                pending[card.uid].red += 1;
            }
            pending[card.uid].cardIds[card.id] = card.id;
        }

        let arr = [];
        arr = Object.values(pending);
        
        arr.sort((a,b)=> {
            return b.yellow - a.yellow;
        })
        

        let items = [];
        let rank = 0;
        for (const val of arr) {
            rank ++;
            items.push(
                this.renderPendingBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Cards Pending Sanctions / {season.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: '#f0cb5b', fontSize: 24}}><HiBookmark/></span>
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: '#BE4534', fontSize: 24}}><HiBookmark/></span>
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPendingBadge(item, rank) {
        const {season, teams, users} = this.props;
        const user = users[item.uid] ? users[item.uid] : {};
        const team = teams[item.teamId] ? teams[item.teamId] : {};
        let thumbImage = user.photoUri != null ? user.photoUri : defaultImage;
        let teamThumb = team.iconUri != null ? team.iconUri : teamImage;

        return (
            <Row onClick={(e) => {
                const update = {
                    uid: item.uid,
                    seasonId: season.id,
                    teamId: item.teamId,
                    cardIds: item.cardIds,
                    foulId: item.foulId,
                    gameId: item.gameId,
                    type: 'games',
                    gameCount: 1,
                }

                update.cardIds[item.id] = item.id
                this.setState({sanctionChoice: update})
                this.setState({sanctionChoice: item})
            }}>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={4} xs={4} sm={4} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={teamThumb} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.yellow}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.red}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderSactionEdit() {
        const {progress, sanctionChoice} = this.state;
        if (!sanctionChoice) {
            return <div/>;
        }
        const type = sanctionChoice.type ? sanctionChoice.type : null;
        
        return (
            <Form className='form-edit'>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Sanction Edit
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                    </Col>
                </Row>
                {this.renderAvailableCards()}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Sanction Type
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                sanctionChoice.type = e.target.value;
                                this.setState({sanctionChoice: sanctionChoice});
                            }} value={type}>
                                <option value={null}></option>
                                <option value='games'>Next X Games</option>
                                <option value='duration'>Suspended Duration</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
                {type === "games" && (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Game Count to be Suspended
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="number" name="number" id="title" onChange={e => {
                                sanctionChoice.gameCount = parseInt(e.target.value);
                                this.setState({sanctionChoice: sanctionChoice});
                            }} value={sanctionChoice.gameCount} />
                        </div>
                    </Col>
                </Row>
                )}
                {type === "duration" && (
                <div>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('start_date')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <CustomDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/DD/YYYY hh:mm"
                                value={sanctionChoice.startTime != null ? new Date(sanctionChoice.startTime) : new Date()}
                                onChange={ val => {
                                    sanctionChoice.startTime = val.valueOf();
                                    this.setState({sanctionChoice: sanctionChoice});
                                }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('end_date')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <CustomDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/DD/YYYY hh:mm"
                                value={sanctionChoice.endTime != null ? new Date(sanctionChoice.endTime) : new Date()}
                                onChange={ val => {
                                    sanctionChoice.endTime = val.valueOf();
                                    this.setState({sanctionChoice: sanctionChoice});
                                }} />
                        </div>
                    </Col>
                </Row>
                </div>
                )}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Sanction Feedback
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="textarea" name="feedback" id="title" onChange={e => {
                                sanctionChoice.feedback = e.target.value;
                                this.setState({sanctionChoice: sanctionChoice});
                            }} value={sanctionChoice.feedback} />
                        </div>
                    </Col>
                </Row>
                {this.renderSuspendedGames()}
                {progress ? (
                    <div>
                        <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                            <Spinner color="primary" />
                        </div>
                    </div>
                ) : (
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                            <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                this.onSaveClick(sanctionChoice);
                            }}>{sanctionChoice.id ? "Save" : "Create"}</Button>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={7}>
                            <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                this.setState({sanctionChoice: null})
                            }}>{t('close')}</Button>
                        </Col>
                    </Row>
                )}
            </Form>
        );
    }

    renderSuspendedGames() {
        const {season, cards, teams} = this.props;
        const {sanctionChoice} = this.state;

        const arr = getSanctionGames(season, cards, sanctionChoice);
        
        let items = [];
        
        for (const game of arr) {
            
            let homeTeam = game ? teams[game.homeTeam] : {};
            let awayTeam = game ? teams[game.awayTeam] : {};
            if (homeTeam == null) {
                homeTeam = {}
            }
            if (awayTeam == null) {
                awayTeam = {}
            }
            let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : teamImage;
            let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : teamImage;
            
            const timeStr = toDateString(game.start);

            items.push(
                <Row>
                    <Col lg={4} xs={4} sm={4} className="text-center col-no-padding">
                        <CardImg
                            className="sanction-game-team-icon"
                            src={homeThumb} />
                        <div className='sanction-game-team-title'>
                        {homeTeam.title}
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="text-center game-badge-time col-no-padding" style={{padding: 0, margin: 0}}>
                        <p style={{margin: 0}}>
                            <b>{game && game.homeScore && game.homeScore !== -1 ? game.homeScore : "_"} - 
                            {game && game.homeScore && game.awayScore !== -1 ? game.awayScore : "_"}
                            </b>
                        </p>
                        <b>{timeStr}</b>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="text-center col-no-padding">
                        <CardImg
                            className="sanction-game-team-icon"
                            src={awayThumb} />
                        <div className='sanction-game-team-title'>
                        {awayTeam.title}
                        </div>
                    </Col>
                </Row>
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Games to be suspended
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const {hideTitle, season} = this.props;
        const {sanctionChoice} = this.state;
        
        return (
            <div>
            {!hideTitle && (
                <h3 style={{marginTop: 30}}>{season.title} Sanctions</h3>
            )}
                <Row>
                    <Col lg={6} sm={12}>
                    {this.renderSanctions()}
                    </Col>
                    <Col lg={6} sm={12}>
                    {this.renderPeoplePending()}
                    </Col>
                </Row>
                {this.renderCards()}
                <Modal isOpen={sanctionChoice != null}>
                    <ModalBody>
                    {this.renderSactionEdit()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(SeasonSanctions)
