import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import OrganizationMembershipView from 'components/membership/OrganizationMembershipView';
import { connect } from 'react-redux';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';

import { 
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID, 
    ORG_MEMBERSHIP_MONTHLY_PRICE, 
    ORG_MEMBERSHIP_YEARLY_PRICE, 
    CUR_ORG_MEMBERSHIP_DISCOUNT
} from 'utils/Utils';
import { 
    MdCardMembership
} from 'react-icons/md';
import { invokeHttpsApi } from 'utils/API';

class OrganizationMembershipPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            locationSubscription: {},
            locationPrices: {},
            leagueSubscription: {},
            leaguePrices: {},
            locationAndLeagueSubscription: {},
            locationAndLeaguePrices: {},
            activeSubscriptions: {},
            leagueStatus: {},
            locationStatus: {},
            locationAndLeagueStatus: {},
            progress: false,
        };
    }
    
    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.loadContents();
        }
    }

    loadContents() {
        this.setState({loading: true})
        invokeHttpsApi('membership-loadMembershipInfo', {}, (data) => {
            const locationSubscription = {};
            const locationPrices = {};
            const locationAndLeagueSubscription = {};
            const locationAndLeaguePrices = {};
            const leagueSubscription = [];
            const leaguePrices = [];
            const activeSubscriptions = {};
            var leagueStatus = {};
            var locationStatus = {};
            var locationAndLeagueStatus = {};
        
            if (data.success) {
                for (const [key, value] of Object.entries(data.locationSubscription)) {
                    locationSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationPrices)) {
                    if (value.active === true) {
                        locationPrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueSubscription)) {
                    locationAndLeagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeaguePrices)) {
                    if (value.active === true) {
                        locationAndLeaguePrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.leagueSubscription)) {
                    leagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.leaguePrices)) {
                    if (value.active === true) {
                        leaguePrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.activeSubscriptions)) {
                    activeSubscriptions[key] = value;
                }
                for (const [key, value] of Object.entries(data.leagueStatus)) {
                    leagueStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationStatus)) {
                    locationStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueStatus)) {
                    locationAndLeagueStatus[key] = value;
                }
            }

            this.setState({
                locationSubscription: locationSubscription,
                locationPrices: locationPrices,
                locationAndLeagueSubscription: locationAndLeagueSubscription,
                locationAndLeaguePrices: locationAndLeaguePrices,
                leagueSubscription: leagueSubscription,
                leaguePrices: leaguePrices,
                activeSubscriptions: activeSubscriptions,
                leagueStatus: leagueStatus,
                locationStatus: locationStatus,
                locationAndLeagueStatus: locationAndLeagueStatus,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    async subscribeProduct(prices, priceId) {
        const {user} = this.props;
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        const {leagueStatus, locationStatus, locationAndLeagueStatus,} = this.state;
        if (leagueStatus.stripeLink != null || locationStatus.stripeLink != null || locationAndLeagueStatus.stripeLink != null) {
            alert("You are already subscribed to other membership. You should cancel it first before you try this one.");
            return;
        }
        const selectedPrice = prices[priceId];

        if (selectedPrice != null) {
            this.setState({progress: true});
            invokeHttpsApi("membership-generateSubscriptionSession", {
                priceId: priceId,
                successUrl: window.location.origin + '/organizer/membership',
                cancelUrl: window.location.origin + '/organizer/membership',
            }, (data) => {
                if (data && data.sessionId) {
                    invokeHttpsApi("membership-getSubscriptionUrl", {
                        sessionId: data.sessionId,
                    }, (data) => {
                        if (data && data.url) {
                            window.location.assign(data.url);
                        }
                        this.setState({progress: false});
                    }, (error) => {
                        console.log("Function error", error)
                        this.setState({progress: false});
                    })
                }
            }, (error) => {
                console.log("Function error", error)
                this.setState({progress: false});
            })

        }
        
    }

    cancelSubscriptions() {
        const {user} = this.props;
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        if (window.confirm('Are you sure you want to cancel this subscription?')) {
            invokeHttpsApi('clubQuery-cancelOrganizationSubscription', {}, (data) => {
                this.loadContents();
            }, (error) => {
                
            });
        }
    }

    renderMonthly() {
        const {locationAndLeagueSubscription, locationAndLeaguePrices, locationAndLeagueStatus, progress} = this.state;
        const price = locationAndLeaguePrices[ORG_MEMBERSHIP_MONTHLY_PRICE];
        if (locationAndLeagueSubscription.name != null) {
            return (
                <OrganizationMembershipView
                    mainColor='#ED1C22'
                    key={LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID}
                    membership={locationAndLeagueSubscription}
                    progress={progress}
                    discount={CUR_ORG_MEMBERSHIP_DISCOUNT.discount}
                    code={CUR_ORG_MEMBERSHIP_DISCOUNT.code}
                    price={price}
                    forOverlay={this.props.forOverlay}
                    priceId={ORG_MEMBERSHIP_MONTHLY_PRICE}
                    prices={locationAndLeaguePrices}
                    subscribedStatus={locationAndLeagueStatus}
                    onSubscribe={(priceId)=> {
                        this.subscribeProduct(locationAndLeaguePrices, priceId);
                    }}
                    onCancelClicked={e=> {
                        this.cancelSubscriptions();
                    }} />
            );
        } else {
            return (
                <div/>
            );
        }
    }

    renderYearly() {
        const {locationAndLeagueSubscription, locationAndLeaguePrices, locationAndLeagueStatus, progress} = this.state;
        const price = locationAndLeaguePrices[ORG_MEMBERSHIP_YEARLY_PRICE];
        if (locationAndLeagueSubscription.name != null) {
            return (
                <OrganizationMembershipView
                    title="Special Offer"
                    mainColor='#ED1C22'
                    key={LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID}
                    membership={locationAndLeagueSubscription}
                    progress={progress}
                    discount={CUR_ORG_MEMBERSHIP_DISCOUNT.discount}
                    code={CUR_ORG_MEMBERSHIP_DISCOUNT.code}
                    price={price}
                    forOverlay={this.props.forOverlay}
                    priceId={ORG_MEMBERSHIP_YEARLY_PRICE}
                    prices={locationAndLeaguePrices}
                    subscribedStatus={locationAndLeagueStatus}
                    onSubscribe={(priceId)=> {
                        this.subscribeProduct(locationAndLeaguePrices, priceId);
                    }}
                    onCancelClicked={e=> {
                        this.cancelSubscriptions();
                    }} />
            );
        } else {
            return (
                <div/>
            );
        }
    }

    render() {
        const { forOverlay, forLeague } = this.props;
        const { loading } = this.state;

        return (
            <div>
                {loading ? (
                    <SmallLoadingView />
                ) : (
                <div className={forOverlay ? '' : 'container'} style={{marginTop: 20}}>
                    {CUR_ORG_MEMBERSHIP_DISCOUNT.description != null && (
                    <h3 className='label label-default title-action-main wrap-content-fill-child' style={{textAlign: 'center'}}>
                    {CUR_ORG_MEMBERSHIP_DISCOUNT.description}
                    </h3>
                    )}
                    {CUR_ORG_MEMBERSHIP_DISCOUNT.description != null && (
                    <h3 className='label label-default title-action-main wrap-content-fill-child' style={{textAlign: 'center'}}>
                    Discount Code: {CUR_ORG_MEMBERSHIP_DISCOUNT.code}
                    </h3>
                    )}
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><MdCardMembership/></div>
                                <h3 className='items-navigation-title'>
                                {forLeague === true ? 
                                "You need to purcahse league membership to use this feature" :
                                "You need to purcahse location membership to use this feature"
                                }, 
                                you can cancel subscription any time inside these 3 trial days.
                                </h3>
                            </div>
                        </div>                        
                    </div>
                    <Row>
                        <Col sm={6}>
                            {this.renderMonthly()}
                        </Col>
                        <Col sm={6}>
                            {this.renderYearly()}
                        </Col>
                    </Row>
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const connectedApp = connect(mapState)(OrganizationMembershipPrompt);
export { connectedApp as OrganizationMembershipPrompt };
