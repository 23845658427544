import React, { Component } from "react";
import {
    Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import { 
    MdError,
} from 'react-icons/md';
import CoachGameBadge from "components/detail/organization/CoachGameBadge";
import { ClubGameView } from "components/detail/organization/club/ClubGameView";
import { checkProfileData, toDateTimeString } from 'utils/Utils';
import { mainRedColor } from 'style/styles';
import SeasonStanding from "components/detail/organization/SeasonStanding";
import SeasonCards from "components/detail/organization/SeasonCards";
import SeasonSanctions from "components/detail/organization/SeasonSanctions";
import SeasonDetailOverview from "components/detail/organization/SeasonDetailOverview";
import SeasonPlayerCard from "components/detail/organization/SeasonPlayerCard";
import logo200Image from 'assets/img/logo/live_192.png';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';


export class SeasonTeamDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gameDetail: false,
            gameChoice: {},
            progress: false,
            imageProgress: 0,
            mainTab: 0,
            windowWidth: window.innerWidth,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, value) {
        this.setState({mainTab: value});
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    renderGameWarning(game) {
        const {divisions, season, profileData, users, team} = this.props;

        let division = divisions[season.divisionId];
        if (division == null) {
            division = {};
        }
        if (game.gameList == null || game.gameList[team.id] == null) {
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdError/> </span>
                    {t('player_list_needed')}
                </div>
            )
        }
        for (const [key,] of Object.entries(game.gameList[team.id])) {
            const user = users[key];
            if (user == null || checkProfileData(division, user, team, profileData) !== 'verified') {
                return (
                    <div className='club-member-status'>
                        <span style={{color: mainRedColor, marginRight: 10}}><MdError/> </span>
                        {t('players_waiting_verified')}
                    </div>
                )
            }
        }
        return <div/>;
    }

    renderGames() {
        const {organizations, divisions, season, teams, profileData, locations, users, team} = this.props;
        const {gameDetail, gameChoice} = this.state;
        let organization = this.props.organization != null ? this.props.organization : organizations[season.organizationId];
        let division = divisions[season.divisionId];
        let gameArr = [];
        if (season.subSeasons != null) {
            for (const [, subSeason] of Object.entries(season.subSeasons)) {
                if (subSeason.games != null) {
                    for (const [, game] of Object.entries(subSeason.games)) {
                        if (game.homeTeam === team.id || game.awayTeam === team.id) {
                            gameArr.push(game);
                        }
                    }
                }
            }
        }
        gameArr.sort(function(a, b) {
            return a.start - b.start;
        })
        
        if (gameDetail && gameChoice.id != null) {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 30}}>
                        <button className='detail-nav-btn' onClick={e=>{
                            this.setState({gameChoice: {}, gameDetail: false})
                        }}>
                            <h3 style={{display: 'inline-block', fontSize: 16}}>
                            {t('games')} ({gameArr.length})
                            </h3>
                        </button>
                        {gameChoice.id !== null && gameDetail && (
                            <div style={{display: 'inline-block'}}>
                                <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                                <button className='detail-nav-btn' onClick={e=> {
                                    this.setState({gameDetail: false})
                                }}>
                                    <h3 style={{display: 'inline-block', fontSize: 16}}>
                                    {t('game_on')} {toDateTimeString(gameChoice.start)}
                                    </h3>
                                </button>
                            </div>
                        )}
                    </div>
                    <ClubGameView 
                        organization={organization}
                        game={gameChoice}
                        division={division}
                        locations={locations}
                        teams={teams}
                        profileData={profileData}
                        team={team}
                        users={users} />
                </div>
            );
        } else {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 30}}>
                        <button className='detail-nav-btn' onClick={e=>{
                            this.setState({gameChoice: {}, gameDetail: false})
                        }}>
                            <h3 style={{display: 'inline-block', fontSize: 16}}>
                            {t('games')} ({gameArr.length})
                            </h3>
                        </button>
                        {gameChoice.id !== null && gameDetail && (
                            <div style={{display: 'inline-block'}}>
                                <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                                <button className='detail-nav-btn' onClick={e=> {
                                    this.setState({gameDetail: false})
                                }}>
                                    <h3 style={{display: 'inline-block', fontSize: 16}}>
                                    {gameChoice.title}
                                    </h3>
                                </button>
                            </div>
                        )}
                    </div>
                    {gameArr.map((game,idx)=>(
                        <div key={idx}>
                            <CoachGameBadge
                                division={division}
                                season={season}
                                game={game}
                                team={team}
                                teams={teams}
                                locations={locations}
                                users={users}
                                warningView={this.renderGameWarning(game)}
                                detailMode={true}
                                onClickDetail={()=>{
                                    this.setState({gameChoice: game, gameDetail: true})
                                }} />
                        </div>
                    ))}
                </div>
            );
        }
    }

    renderHeader() {
        const {divisions, season, team} = this.props;
        const {mainTab} = this.state;
        let division = divisions[season.divisionId];
        const icon = division.thumbUri != null ? division.thumbUri : logo200Image;
        let member = false;
        if (team != null && season.teamIds != null && Object.values(season.teamIds).includes(team.id)) {
            member = true;
        }
        
        return (
        <div style={{marginBottom: 30}}>
            <div style={{marginTop: 10}}/>
            {member && (
            <Row style={{margin: 0, padding: 0}}>
                <Col lg={3} sm={3} xs={12} className='championship-header MuiPaper-root MuiPaper-elevation1' style={{height: 48}}>
                    <img src={icon} className='championship-header-icon wrap-content-wrap-child' alt="logo" />
                    <div className='wrap-content-fill-child' style={{margin: '5px 10px 5px 0px', fontSize: 12}}>
                        <h3 style={{display: 'block', fontSize: 14, margin:'4px 2px 2px'}}>{season.title}</h3>
                        <div style={{display: 'block', fontSize: 12, margin:'0px 2px'}}>{division.title}</div>
                    </div>
                </Col>
                <Col lg={9} sm={9} xs={12} className='championship-nav'>
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={mainTab}
                            onChange={this.handleChange}
                            variant="scrollable"
                            indicatorColor="secondary"
                            textColor="secondary" >

                            <Tab label={t('overview')} />
                            <Tab label={t('games')} />
                            <Tab label={t('standings')} />
                            <Tab label={t('cards')} />
                            <Tab label={t('sanctions')} />
                        </Tabs>
                    </Box>
                </Col>
            </Row>
            )}
        </div>
        )
    }

    renderContents() {
        const {organizations, season, teams, users, divisions, locations, cards} = this.props;
        const {mainTab} = this.state;
        const division = divisions[season.divisionId];
        const organization = organizations[season.organizationId];

        switch (mainTab) {
            case 0:
                return this.renderOverview();
            case 1:
                return this.renderGames();
            case 2:
                return (
                    <SeasonStanding
                        season={season}
                        organization={organization}
                        teams={teams}
                        users={users}
                        locations={locations}
                        division={division} />
                )
            case 3:
                return (
                    <SeasonCards
                        season={season}
                        teams={teams}
                        users={users} />
                )
            case 4:
                return (
                    <SeasonSanctions
                        season={season}
                        teams={teams}
                        users={users}
                        cards={cards}
                        division={division} />
                )
            default:
                return <div/>;
        }

    }

    renderOverview() {
        const {organizations, divisions, season, seasons, locations, team, profileData, teams, user, users, onChanged, playerMode } = this.props;
        let division = divisions[season.divisionId];
        let organization = this.props.organization != null ? this.props.organization : organizations[season.organizationId];

        if (playerMode) {
            return (
                <SeasonPlayerCard
                    organization={organization}
                    division={division}
                    locations={locations}
                    season={season}
                    team={team}
                    teams={teams}
                    users={users}
                    profileData={profileData}
                    player={user}
                    onChanged={(update)=>{
                        if (onChanged != null) {
                            onChanged(update);
                        }
                    }} />
            );
        }
        return (
            <SeasonDetailOverview
                organization={organization}
                division={division}
                locations={locations}
                season={season}
                seasons={seasons}
                team={team}
                teams={teams}
                users={users}
                profileData={profileData}
                onChanged={(update)=>{
                    if (onChanged != null) {
                        onChanged(update);
                    }
                }} />
        )
    }

    render() {
        const {season, team} = this.props;
        let games = [];
        if (season.subSeasons != null) {
            for (const [, subSeason] of Object.entries(season.subSeasons)) {
                if (subSeason.games != null) {
                    for (const [, game] of Object.entries(subSeason.games)) {
                        if (game.homeTeam === team.id || game.awayTeam === team.id) {
                            games.push(game);
                        }
                    }
                }
            }
        }
        games.sort(function(a, b) {
            return a.start - b.start;
        })

        if (season != null) {
            return (
                <div>
                    {this.renderHeader()}
                    {this.renderContents()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default SeasonTeamDetails;