import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Card, CardBody
} from 'reactstrap';
import { dbTimestamp, invokeHttpsApi } from "utils/API";

export class SlideTextEdit extends Component {
    constructor(props) {
        super(props);
        const slideText = {};
        if (props.slideText != null) {
            for (const [key, value] of Object.entries(props.slideText)) {
                slideText[key] = value;
            }
        }

        this.state = {
            slideText: slideText,
            progress: false,
            landingPageData: {},
            newImage: {},
            imageProgress: 0,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange(update) {
        this.setState(update);
    }

    componentDidUpdate(prevProps) {
        const {isOpen, slideText} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        
        if ((prevProps.slideText !== slideText)) {
            const categoryVal = {};
            for (const [key, value] of Object.entries(slideText)) {
                categoryVal[key] = value;
            }
            this.setState({
                slideText: categoryVal,
            })
        }
    }

    onSaveClick(slideText) {
        const {team, onUpdate} = this.props;
        const objRef = this;
        if (slideText.main.title1 == null || slideText.main.title1 === '') {
            alert("You must input title 1");
            return;
        } else if (slideText.main.title2 == null || slideText.main.title2 === '') {
            alert("You must input title 2");
            return;
        } else if (slideText.description.text == null || slideText.description.text === '') {
            alert("You must input description text");
            return;
        } else if (slideText.description.sign == null || slideText.description.sign === '') {
            alert("You must input description sign");
            return;
        } else {
            this.setState({progress: true, imageProgress: 0});
            slideText.timestamp = dbTimestamp();
            
            this.saveChange(team, onUpdate, slideText, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    slideText: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(team, onUpdate, slideText, success, failure) {

        const landingData = (team && team.landingData) ? team.landingData : {};

        landingData.slideText = {};
        
        for (const [key, value] of Object.entries(slideText)) {
            landingData.slideText[key] = value;
        }

        team.landingData = {};
        for (const [k,v] of Object.entries(landingData)) {
            team.landingData[k] = v;
        }

        invokeHttpsApi('clubQuery-saveClubEdit', {
            club: team
        }, (data) => {

            // Read result of the Cloud Function.
            if (onUpdate != null) {
                onUpdate(slideText);
            }
            success(slideText);
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    render() {
        const {slideText, newImage, progress} = this.state;
        
        if (slideText.main != null) {
            return (
                <Card style={{marginBottom: 15}}>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="title1">Slide Main Text 1</Label>
                                <Input type="textarea" name="text" id="title1" value={slideText.main.title1} onChange={e => {
                                    slideText.main.title1 = e.target.value;
                                    this.handleChange({slideText: slideText});
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="title2">Slide Main Text 2</Label>
                                <Input type="textarea" name="text" id="title2" value={slideText.main.title2} onChange={e => {
                                    slideText.main.title2 = e.target.value;
                                    this.handleChange({slideText: slideText});
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="descriptionText">Slide Description Text</Label>
                                <Input type="textarea" name="descriptionText" id="title2" value={slideText.description.text} onChange={e => {
                                    slideText.description.text = e.target.value;
                                    this.handleChange({slideText: slideText});
                                }} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="descriptionText">Slide Description Sign</Label>
                                <Input type="textarea" name="descriptionText" id="title2" value={slideText.description.sign} onChange={e => {
                                    slideText.description.sign = e.target.value;
                                    this.handleChange({slideText: slideText});
                                }} />
                            </FormGroup>
                            {progress ? (
                                <Spinner color="primary" />
                            ) : (
                                <div style={{textAlign: 'end'}}>
                                    <Button className='btn-submit' onClick={e => {
                                        this.onSaveClick(slideText, newImage);
                                    }}>Update</Button>
                                </div>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default SlideTextEdit;