import React, { Component } from "react";

import {
    Card, CardImg, Row, Col, Button, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { t } from 'i18next';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import SubInfoView from 'components/detail/SubInfoView';
import lineupImage from 'assets/img/icons/lineup.png';
import gameImage from 'assets/img/icons/league_default.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import starIcon from 'assets/img/icons/badge_flower_star.png';
import { getTeamPlayerStatisticsList } from 'utils/statistics';
import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import Pagination from 'react-responsive-pagination';
import defaultBg from 'assets/img/icons/stadium-header.jpg';
import { toSimpleDate, toDateString, getSeasonStatusDict } from 'utils/Utils';

const numInvalid = 999999999;

const containerStyle = {
    width: '100%',
    height: '400px'
};


export default class SeasonPlayerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        }
    }

    renderGames(uid) {
        const { organization, organizations, season, divisions, posts, users, teams, cards, locations, loading } = this.props;
        const { gameChoice, locationChoice, page} = this.state;
        
        let games = [];

        if (season.subSeasons != null) {
            for (const [, val] of Object.entries(season.subSeasons)) {
                if (val.games != null)  {
                    for (const game of Object.values(val.games)) {
                        if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                            if ((game.scores && game.scores[uid]) || 
                            (game.fouls && game.fouls[uid]) || 
                            (game.events && game.events[uid]) || 
                            (game.gameList && game.gameList[game.homeTeam] && Object.values(game.gameList[game.homeTeam]).includes(uid)) || 
                            (game.gameList && game.gameList[game.awayTeam] && Object.values(game.gameList[game.awayTeam]).includes(uid))) {
                                games.push(game)
                            }
                        }
                    }
                }
            }
        }
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 3);
        
        for (const[, value] of Object.entries(games)) {
            if (!organization || value.organizationId === organization.id)  {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.start;            
            let t2 = b.start;

            return t1 - t2;
        })
        const now = new Date().valueOf();
        let pageChoice = page;
        if (page === 0) {
            var index = 0;
            for (const item of result) {
                if (item.start > now) {
                    break;
                }
                index ++;
            }
            pageChoice = parseInt(index / 3) + 1;
        }
        if (pageChoice === result.length / 3 + 1 && pageChoice > 0) {
            pageChoice -= 1;
        }

        let idx = pageChoice > 0 ? (pageChoice - 1) * 3 : 0;
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px", backgroundColor: '#eee'}}>
                <div className="container">
                    <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                        <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                                const id = e.target.value;
                                this.setState({locationChoice: id == null ? {} : locations[id]});
                            }}>
                            <option value={null}></option>
                            {Object.values(locations).map((location)=> (
                                <option value={location.id}>{location.title}</option>
                            )) }
                        </Input>
                    </FormGroup>
                    <div style={{height: 25}} />
                    {list.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                        {loading ? "Loading Games" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={pageChoice}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
                {gameChoice && (
                    <Modal isOpen={true} style={{marginTop: 70}}>
                        <ModalBody>
                            <GameDetails 
                                game={gameChoice}
                                organization={organization ? organization : organizations[gameChoice.organizationId]}
                                locations={locations}
                                divisions={divisions}
                                season={season}
                                cards={cards}
                                teams={teams}
                                posts={posts}
                                users={users} 
                                onChanged={()=> {
                                }}
                                onClickClose={()=>{
                                    this.setState({gameChoice: null});
                                }} />
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    }

    renderCard() {
        const {team, season, seasons, player, onClickClose} = this.props;
        
        let choice = {
            uid: player.uid,
            score: 0,
            assist: 0,
            foul: 0,
            yellow: 0,
            red: 0,
            games: 0,
        }
        if (season) {
            let statistics = getTeamPlayerStatisticsList(season, team != null ? team.id : null);

            if (statistics[player.uid]) {
                choice.score += statistics[player.uid].score;
                choice.assist += statistics[player.uid].assist;
                choice.foul += statistics[player.uid].foul;
                choice.yellow += statistics[player.uid].yellow;
                choice.red += statistics[player.uid].red;
                choice.games += statistics[player.uid].games;
            }
        } else if (seasons) {
            for (const val of Object.values(seasons)) {
                let statistics = getTeamPlayerStatisticsList(val, team != null ? team.id : null);
    
                if (statistics[player.uid]) {
                    choice.score += statistics[player.uid].score;
                    choice.assist += statistics[player.uid].assist;
                    choice.foul += statistics[player.uid].foul;
                    choice.yellow += statistics[player.uid].yellow;
                    choice.red += statistics[player.uid].red;
                    choice.games += statistics[player.uid].games;
                }
            }
        }

        let thumbImage = player && player.photoUri != null ? player.photoUri : playersIcon;
        let teamData = season && season.teamData && season.teamData[team.id] ? season.teamData[team.id] : {};

        let item = null;
        if (teamData.profileData && teamData.profileData[player.uid]) {
            item = teamData.profileData[player.uid];
            item.username = player.username;
            item.photoUri = player.photoUri;
            item.email = player.email;
            item.bio = player.bio;
            item.phoneNumber = player.phoneNumber;
            item.gender = player.gender;
            item.country = player.country;
            item.weight = player.weight;
            item.birthday = player.birthday;

        } else {
            item = {
                uid: player.uid,
                number: numInvalid,
                photoUri: player.photoUri,
                username: player.username,
                bio: player.bio,
                email: player.email,
                phoneNumber: player.phoneNumber,
                gender: player.gender,
                country: player.country,
                weight: player.weight,
                birthday: player.birthday,
            };

        }
        return (
            <div className="shadow-tile-normal" style={{backgroundColor: 'rgb(238, 238, 238)'}}>
                <Row>
                    <Col className="text-center" sm={4} xs={12} style={{minHeight: 540, position: 'relative'}}>
                        <img alt='' src={defaultBg} className="org-player-card-bg-photo" />
                        <div className="org-player-card-bg-overlay">
                            <CardImg className="org-player-card-photo" src={thumbImage} />
                            <h2 style={{color: 'white'}}>{item.number && item.number !== numInvalid ? "No." + item.number + " " : ""} {item.username}</h2>
                            <div style={{color: 'white', fontSize: 14}}>{item.bio ? item.bio : t('no_bio')}</div>
                        </div>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Row className="statistics-badge">
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-number">{choice.games}</h3>
                                <h3 className="statistics-badge-title">{t('games_played')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-number">{choice.score}</h3>
                                <h3 className="statistics-badge-title">{t('scores_label')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                {/* <h3 className="statistics-badge-number"><GiCardPlay style={{color: 'red'}}/>{choice.red}</h3> */}
                                <h3 className="statistics-badge-number">
                                    <div style={{
                                        display: 'inline-block', 
                                        marginRight: 6,
                                        backgroundColor: 'red', 
                                        width: 16, 
                                        height: 19, 
                                        borderRadius: 4}}/>
                                    {choice.red}
                                </h3>
                                <h3 className="statistics-badge-title">{t('red_card')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                {/* <h3 className="statistics-badge-number"><GiCardPlay style={{color: 'yellow'}}/>{choice.yellow}</h3> */}
                                <h3 className="statistics-badge-number">
                                    <div style={{
                                        display: 'inline-block', 
                                        marginRight: 6,
                                        backgroundColor: 'yellow', 
                                        width: 16, 
                                        height: 19, 
                                        borderRadius: 4}}/>
                                    {choice.yellow}
                                </h3>
                                <h3 className="statistics-badge-title">{t('yellow_card')}</h3>
                            </Col>
                        </Row>
                        
                        <Row className="statistics-badge">
                            <Col xs={6}>
                                <h3 className="statistics-badge-info">{item.email}</h3>
                            </Col>
                            <Col xs={6}>
                                <h3 className="statistics-badge-info">{item.phoneNumber}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-info">{item.gender ? item.gender : t('no_gender')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-info">{item.country ? item.country : t('no_country')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-info">{item.weight ? item.weight : t('no_weight')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-info">{item.birthday && item.birthday > 0 ? toSimpleDate(item.birthday) : t('no_birthday')}</h3>
                            </Col>
                        </Row>
                        <div className="statistics-badge" style={{marginTop: 25}}>
                            <img alt='' src={starIcon} style={{width: 24, height: 24, margin: '10px 8px'}} />
                            <h3 style={{display: 'inline-block', color: 'white', fontSize: 20, margin: 'auto 8px'}}>{t('mom_label')}</h3>
                            <h3 style={{display: 'inline-block', color: 'white', fontSize: 20, margin: 'auto 8px'}}>{choice.mom}</h3>
                        </div>
                        
                        {this.renderGames(player.uid)}
                        <div className="text-center" style={{marginBottom: 20}}>
                            <Button className="btn-cancel" onClick={e=>{
                                if (onClickClose) {
                                    onClickClose();
                                }
                            }}>Close</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    
    render() {
        const {division, season, profileData, locations} = this.props;
        let dict = getSeasonStatusDict(season);
        const str = dict.str;
        const bgColor = dict.bgColor;
        const fColor = dict.fColor;

        let arr = [];
        for (const val of Object.values(locations)) {
            if (division.locationIds != null && Object.values(division.locationIds).includes(val.id)) {
                arr.push({
                    title: val.title,
                    point: {lat: val.lat, lng: val.lng}
                })
            }
        }
        let teamCount = division.teamIds == null ? 0 : Object.values(division.teamIds).length;
        let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
        let playerCount = 0;
        let gameCount = 0;
        if (profileData != null) {
            for (const value of Object.values(profileData)) {
                if (value.teamId != null && division.teamIds != null 
                    && Object.values(division.teamIds).includes(value.teamId) 
                    && value.organizationId === division.organizationId) {
                    playerCount ++;
                }
            }
        }
        if (season.subSeasons != null) {
            for (const value of Object.values(season.subSeasons)) {
                gameCount += value.games == null ? 0 : Object.values(value.games).length
            }
        }
        const playerLimit = division != null && division.category != null && division.category.playerCount != null ? division.category.playerCount : 10;
        
        return (
            <div>
                <Row>
                    <Col sm={4} xs={12}>
                        <Card style={{padding: '10px 20px'}}>
                            <h3 className=''>{season.title}</h3>
                            <div className='' style={{textTransform: 'uppercase', fontSize: 14, fontWeight: 500}}>{season.type}-{division.gender}</div>
                            <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('sport')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.category != null ? division.category.title : 'Unknown'}
                                    </div>
                                </div>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('age')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.ageTitle != null ? division.ageTitle : 'None'}
                                    </div>
                                </div>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    Status:
                                    </h4>
                                    <h4 className='grid-item-subInfo' 
                                    style={{ 
                                        flex: '0 0 auto', 
                                        textTransform: 'uppercase', 
                                        backgroundColor: bgColor, 
                                        padding: '5px 10px' , 
                                        color: fColor}}>
                                    {str}
                                    </h4>
                                </div>
                            </Row>
                            <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                                <div className='wrap-content-parent'>
                                    <CardImg
                                        className="row-badge-icon-small"
                                        src={gameImage} />
                                    <h4 className='grid-item-subtitle' style={{margin: 'auto 10px'}}>
                                    {t('start_date')}
                                    </h4>
                                    <h4 className='grid-item-subtitle' style={{ display: 'inline', backgroundColor: '#D3E0F1', padding: '2px 10px' , color: '#19447f'}}>
                                    {toDateString(season.startTime)}
                                    </h4>
                                </div>
                                <div className='wrap-content-parent'>
                                    <CardImg
                                        className="row-badge-icon-small"
                                        src={gameImage} />
                                    <h4 className='grid-item-subtitle' style={{margin: 'auto 10px'}}>
                                    {t('reg_date')}
                                    </h4>
                                    <h4 className='grid-item-subtitle' style={{ display: 'inline', backgroundColor: '#FFD2D2', padding: '2px 10px', color: '#FF6E6E'}}>
                                    {toDateString(season.regDeadline)}
                                    </h4>
                                </div>
                            </Row>
                            <div className='text-center'>
                                <SubInfoView fixed title='Teams' icon={teamIcon} number={teamCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Players' icon={playersIcon} number={playerCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Format' icon={lineupImage} subText={playerLimit.toString() + ' v ' + playerLimit.toString()} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Venues' icon={venueIcon} number={locationCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Games' icon={gameImage} number={gameCount} />                            
                            </div>
                            <div className='wrap-content-parent' style={{padding: '10px 0px'}}>
                                <h3 className='wrap-content-wrap-child' style={{fontSize: 14}}>Description:</h3>
                                <div className='wrap-content-fill-child' style={{fontSize: 14}}>{division.description}</div>
                            </div>
                            <div className='divider'/>
                            <div className='wrap-content-parent' style={{padding: '10px 0px'}}>
                                <h3 className='wrap-content-wrap-child' style={{fontSize: 14}}>Division Locations:</h3>
                                <div className='wrap-content-fill-child'>{arr.length === 0 ? "None" : ""}</div>
                            </div>
                            {arr.length > 0 && (
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={arr[0].point}
                                    zoom={15}
                                    >
                                    {arr.map((val,idx)=>(
                                        <Marker key={idx} title={val.title} position={val.point} >
                                        </Marker>
                                    ))}
                                </GoogleMap>
                            </LoadScript>
                            )}
                        </Card>
                    </Col>
                    <Col sm={8} xs={12}>
                        {this.renderCard()}
                    </Col>
                </Row>
            </div>
        );
    }
}
