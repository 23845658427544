import React, { Component } from "react";
import {
    Button, Form, Input, Spinner, Col, Row
} from 'reactstrap';
import { t } from 'i18next';

import Switch from '@mui/material/Switch';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";

import { dbTimestamp, generateId, saveSeason } from "utils/API";
import { withRouter } from "utils/Routher";
import { sortByTitle } from "utils/Utils";
import { ROUND_TYPE_GROUP, ROUND_TYPE_LEAGUE, SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT, STATUS_REGISTER } from "utils/constants";

export class SeasonCreateView extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            step: 1,
            progress: false,
            divisionChoice: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    onSaveClick(season, toScheduling) {
        const {organization, divisions, onSaveDone, onContinue} = this.props;
        const divisionChoice = divisions[season.divisionId];
        
        if (season.title == null || season.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (season.id == null || season.id === '') {
                season.id = generateId("/season");
            }
            season.owner = organization.owner;
            season.organizationId = organization.id;
            season.divisionId = divisionChoice.id;
            season.status = STATUS_REGISTER;
            season.category = {};
            if (divisionChoice.category != null) {
                for (const [key, value] of Object.entries(divisionChoice.category)) {
                    season.category[key] = value;
                }
            }
            season.timestamp = dbTimestamp();
            
            this.saveChange(season, (update) => {
                alert("Your Change subimitted successfully.");
                if (toScheduling && onSaveDone != null) {
                    onSaveDone(update);
                }
                if (toScheduling && onContinue != null) {
                    onContinue(update);
                }
                this.setState({
                    progress: false
                });
            }, (error) => {
                alert(t("request_failed"));
            })
        }
    }
    
    saveChange(season, success, failure) {
        saveSeason(season, success, failure);
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-create-step-header">
            <div className="season-create-step-num">
                {num}
            </div>
            <div className="season-create-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderStepOne() {
        const { season, divisions, onSeasonUpdate, onCloseClick } = this.props;
        let divisionArr = [];
        for (const item of Object.values(divisions)) {
            divisionArr.push(item);
        }
        sortByTitle(divisionArr);

        return (
            <div>
                {this.renderStepTitle(1, t('season_create_step_1'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('season_title')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="text" name="text" id="title" onChange={e => {
                            season.title = e.target.value;
                            onSeasonUpdate(season);
                        }} value={season.title} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('division_choice')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="text" id="title" onChange={e => {
                            season.divisionId = e.target.value;
                            onSeasonUpdate(season);
                        }} value={season.divisionId}>
                            <option value={null}></option>
                            {divisionArr.map((v,i) => (
                            <option value={v.id} key={i}>{v.title} {v.category ? ` - ${v.category.title}` : ""}</option>
                            ))}
                        </Input>
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!season.title || season.title === "") {
                                alert(t("no_title_prompt"));
                                return;
                            }
                            if (!season.divisionId) {
                                alert(t("no_division_prompt"));
                                return;
                            }
                            this.setState({step: 2})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepTwo() {
        const { season, onSeasonUpdate, onCloseClick } = this.props;
        const now = new Date();

        return (
            <div>
                {this.renderStepTitle(2, t('season_create_step_2'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('start_date')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <CustomDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/DD/YYYY hh:mm"
                                value={season.startTime != null ? new Date(season.startTime) : now}
                                onChange={ val => {
                                    season.startTime = val.valueOf();
                                    onSeasonUpdate(season);
                                }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('reg_date')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <CustomDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/DD/YYYY hh:mm"
                                value={season.regDeadline != null ? new Date(season.regDeadline) : now}
                                onChange={ val => {
                                    season.regDeadline = val.valueOf();
                                    onSeasonUpdate(season);
                                }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('end_date')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <CustomDateTimePicker
                                margin="normal"
                                id="date-picker-dialog"
                                format="MM/DD/YYYY hh:mm"
                                value={season.endTime != null ? new Date(season.endTime) : now}
                                onChange={ val => {
                                    season.endTime = val.valueOf();
                                    onSeasonUpdate(season);
                                }} />
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 1})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!season.startTime || season.startTime === 0) {
                                alert(t("no_start_time_prompt"));
                                return;
                            }
                            if (!season.regDeadline || season.regDeadline === 0) {
                                alert(t("no_register_time_prompt"));
                                return;
                            }
                            if (!season.endTime || season.endTime < season.startTime) {
                                alert(t("no_end_time_prompt"));
                                return;
                            }
                            this.setState({step: 3})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepThree() {
        const { season, onSeasonUpdate, onCloseClick } = this.props;
        const enrollFee = season.enrollFee ? season.enrollFee : {}

        return (
            <div>
                {this.renderStepTitle(3, t('season_create_step_3'))}
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('individual_register_switch')}
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={season.individualReg}
                                onChange={e=>{
                                    season.individualReg = e.target.checked;
                                    onSeasonUpdate(season);
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('block_offline_payment')}
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={season.blockOfflinePayment ? season.blockOfflinePayment : true}
                                onChange={e=>{
                                    season.blockOfflinePayment = e.target.checked;
                                    onSeasonUpdate(season);
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('team_register_fee')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!season.enrollFee) {
                                        season.enrollFee = {}
                                    }
                                    season.enrollFee.teamFee = parseInt(e.target.value);
                                    onSeasonUpdate(season);
                                }} value={enrollFee.teamFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('player_register_fee')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!season.enrollFee) {
                                        season.enrollFee = {}
                                    }
                                    season.enrollFee.playerFee = parseInt(e.target.value);
                                    onSeasonUpdate(season);
                                }} value={enrollFee.playerFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('staff_register_fee')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!season.enrollFee) {
                                        season.enrollFee = {}
                                    }
                                    season.enrollFee.managerFee = parseInt(e.target.value);
                                    onSeasonUpdate(season);
                                }} value={enrollFee.managerFee} />
                            </div>
                        </Col>
                    </Row>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 2})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            this.setState({step: 4})
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepFour() {
        const { season, onSeasonUpdate, onCloseClick } = this.props;
        
        const startTeams = season.startTeams != null ? season.startTeams : 0;
        const groupCount = season.groupCount != null ? season.groupCount : 0;
        const knockoutTeamCount = season.knockoutTeamCount != null ? season.knockoutTeamCount : 0;

        return (
            <div>
                {this.renderStepTitle(4, t('season_create_step_4'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('season_type')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.type = e.target.value;
                                onSeasonUpdate(season);
                            }} value={season.type}>
                                <option value={null}></option>
                                <option value={SEASON_TYPE_LEAGUE}>{t('league')}</option>
                                <option value={SEASON_TYPE_TOURNAMENT}>{t('tournament')}</option>
                                <option value={SEASON_TYPE_PLAYOFF}>{t('playoff')}</option>
                                <option value={SEASON_TYPE_CUSTOM}>{t('custom')}</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
                {(season.type == null || season.type === SEASON_TYPE_LEAGUE) && (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('start_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="number" name="text" id="title" onChange={e => {
                                season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={startTeams} />
                            </div>
                        </Col>
                    </Row>
                </div>
                )}
                {season.type === SEASON_TYPE_TOURNAMENT && (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('start_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="number" name="text" id="title" onChange={e => {
                                season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={startTeams} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('group_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.groupCount = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={groupCount}>
                                <option value={null}></option>
                                <option value={2}>2</option>
                                <option value={4}>4</option>
                                <option value={8}>8</option>
                            </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('knockout_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={knockoutTeamCount}>
                                <option value={null}></option>
                                <option value={2}>2</option>
                                <option value={4}>4</option>
                                <option value={8}>8</option>
                                <option value={16}>16</option>
                                <option value={32}>32</option>
                            </Input>
                            </div>
                        </Col>
                    </Row>
                </div>
                )}
                {(season.type === SEASON_TYPE_PLAYOFF) && (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('start_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="number" name="text" id="title" onChange={e => {
                                season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={startTeams} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('knockout_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={knockoutTeamCount}>
                                <option value={null}></option>
                                <option value={2}>2</option>
                                <option value={4}>4</option>
                                <option value={8}>8</option>
                                <option value={16}>16</option>
                                <option value={32}>32</option>
                            </Input>
                            </div>
                        </Col>
                    </Row>
                </div>
                )}
                {(season.type === SEASON_TYPE_CUSTOM) && (
                <div>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('qualification_stage_type')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.qualification = e.target.value;
                                    onSeasonUpdate(season);
                                }} value={season.qualification}>
                                    <option value={null}></option>
                                    <option value={ROUND_TYPE_LEAGUE}>{t('league')}</option>
                                    <option value={ROUND_TYPE_GROUP}>{t('group')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('start_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="number" name="text" id="title" onChange={e => {
                                season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={startTeams} />
                            </div>
                        </Col>
                    </Row>
                    {(season.qualification === ROUND_TYPE_GROUP) && (
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('group_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.groupCount = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={groupCount}>
                                <option value={null}></option>
                                <option value={2}>2</option>
                                <option value={4}>4</option>
                                <option value={8}>8</option>
                            </Input>
                            </div>
                        </Col>
                    </Row>
                    )}
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('knockout_team_count')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                                onSeasonUpdate(season);
                            }} value={knockoutTeamCount}>
                                <option value={null}></option>
                                <option value={2}>2</option>
                                <option value={4}>4</option>
                                <option value={8}>8</option>
                                <option value={16}>16</option>
                                <option value={32}>32</option>
                            </Input>
                            </div>
                        </Col>
                    </Row>
                </div>
                )}
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 3})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (season.type === SEASON_TYPE_LEAGUE && (season.startTeams == null || season.startTeams < 3)) {
                                alert("You must input valid team count to start a league season");
                            } else if (season.type === SEASON_TYPE_TOURNAMENT && season.startTeams == null) {
                                alert("You must input valid team count to start a tournament season");
                            } else if (season.type === SEASON_TYPE_TOURNAMENT && season.groupCount == null) {
                                alert("You must input valid group count to start a tournament season");
                            } else if (season.type === SEASON_TYPE_TOURNAMENT && season.knockoutTeamCount == null) {
                                alert("You must input valid team count to start a knockout round");
                            } else if (season.type === SEASON_TYPE_TOURNAMENT && season.startTeams / season.groupCount < 3) {
                                alert("Start Team Count must be larger than group count * 3.");
                            } else if (season.type === SEASON_TYPE_TOURNAMENT && season.startTeams < season.knockoutTeamCount) {
                                alert("Start Team Count must be larger than knockout team count");
                            } else if (season.type === SEASON_TYPE_PLAYOFF && (season.startTeams == null || season.startTeams < 3)) {
                                alert("You must input valid team count to start a playoff season");
                            } else if (season.type === SEASON_TYPE_PLAYOFF && season.knockoutTeamCount == null) {
                                alert("You must input valid team count to start a knockout round");
                            } else if (season.type === SEASON_TYPE_PLAYOFF && season.startTeams < season.knockoutTeamCount) {
                                alert("Start Team Count must be larger than knockout team count");
                            } else if (season.type === SEASON_TYPE_CUSTOM && (season.startTeams == null || season.startTeams < 3)) {
                                alert("You must input valid team count to start a qualification round");
                            } else if (season.type === SEASON_TYPE_CUSTOM && season.knockoutTeamCount == null) {
                                alert("You must input valid team count to start a knockout round");
                            } else if (season.type === SEASON_TYPE_CUSTOM && !season.qualification) {
                                alert("You must input qualification type for custom season.");
                            } else if (season.type === SEASON_TYPE_CUSTOM && season.qualification === ROUND_TYPE_GROUP && season.startTeams / season.groupCount < 3) {
                                alert("Start Team Count must be larger than group count * 3.");
                            } else if (season.type === SEASON_TYPE_CUSTOM && season.startTeams < season.knockoutTeamCount) {
                                alert("Start Team Count must be larger than knockout team count");
                            } else {
                                this.setState({step: 5})
                            }
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderStepFive() {
        const { season, trophies, onSeasonUpdate, onCloseClick } = this.props;
        const { progress } = this.state;
        let trophyArr = [];
        for (const item of Object.values(trophies)) {
            trophyArr.push(item);
        }
        sortByTitle(trophyArr);
        
        return (
            <div>
                {this.renderStepTitle(5, t('season_create_step_5'))}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('skip_profile_check')}
                    </Col>
                    <Col sm={7} xs={12} className='form-edit-entry'>
                        <Switch
                            checked={season.skipProfileCheck}
                            onChange={e=>{
                                season.skipProfileCheck = e.target.checked;
                                onSeasonUpdate(season);
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('trophy_choice')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                season.trophyId = e.target.value;
                                onSeasonUpdate(season);
                            }} value={season.trophyId}>
                                <option value={null}></option>
                                {trophyArr.map((val,idx)=>(
                                <option key={idx} value={val.id}>{val.title}</option>
                                ))}
                            </Input>
                        </div>
                    </Col>
                </Row>
                <div style={{height: 30}}/>
                {progress ? (
                <div>
                    <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                        <Spinner color="primary" />
                    </div>
                </div>
                ) : (
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        <Button className='btn-cancel-blue' onClick={e => {
                            this.setState({step: 4})
                        }}>{t('back')}</Button>
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            this.onSaveClick(season)
                        }}>{t('save_and_finish')}</Button>
                        {!season.individualReg && (<Button className='btn-blue' onClick={e => {
                            this.onSaveClick(season, true)
                        }}>{t('continue_to_scheduling')}</Button>)}
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>)}
            </div>
        )
    }

    renderContents() {
        const { step } = this.state;

        return (
            <div>
                {step === 1 && this.renderStepOne()}
                {step === 2 && this.renderStepTwo()}
                {step === 3 && this.renderStepThree()}
                {step === 4 && this.renderStepFour()}
                {step === 5 && this.renderStepFive()}
            </div>
        )
    }

    render() {        
        return (
            <Form className='form-edit'>
                <Row className='form-edit-row'>
                    <h3 className="text-center">{t("create_season")}</h3>
                </Row>
                {this.renderContents()}
            </Form>
        );
    }
}

export default withRouter(SeasonCreateView);