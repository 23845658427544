import React, { Component } from "react";
import {
    CardImg, Button, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { MdWarning, MdError, MdCheck } from 'react-icons/md';
import AttachFileView from 'components/detail/AttachFileView';
import Switch from '@mui/material/Switch';
import { toDateString } from 'utils/Utils';

import AvatarBadge from 'components/detail/AvatarBadge';
import defaultImage from 'assets/img/icons/player_photo_default.png';
import teamImage from 'assets/img/icons/club_icon_default.png';
import { invokeHttpsApi } from "utils/API";

export class PlayerFormDetail extends Component {
    constructor(props) {
        super(props);
        const profileData = {};
        if (props.profileData != null) {
            for (const [key, value] of Object.entries(props.profileData)) {
                profileData[key] = value;
            }
        }
        this.state = {
            value: 0,
            progress: false,
            profileData: profileData,
            windowWidth: window.innerWidth,
        };
        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {profileData} = this.props;
        if (prevProps.profileData !== profileData) {
            const update = {};
            if (profileData != null) {
                for (const [key, value] of Object.entries(profileData)) {
                    update[key] = value;
                }
            }
            this.setState({profileData: update});
        }
    }

    onSaveClicked() {
        const {onSaveDone} = this.props;
        const {profileData} = this.state;
        this.setState({progress: true});
        invokeHttpsApi('organizerQuery-submitProfileData', {profileData: profileData}, (data) => {
            
            this.setState({progress: false})
            if (onSaveDone != null) {
                onSaveDone();
            }
        }, (error) => {
            console.log('saveContents error', error);
            this.setState({progress: false})
        });
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    renderTeamChoice() {
        const {teams} = this.props;
        const {profileData} = this.state;
        const teamChoice = teams[profileData.teamId];

        const user = {};
        if (teamChoice.title != null) {
            user.username = teamChoice.title;
            user.photoUri = teamChoice.iconUri;
        } else {
            user.username = 'Pick Team to register on organization.'
        }
        if (user.photoUri == null) {
            user.photoUri = teamImage;
        }
        return (
        <div>
            <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 30}}>
                <AvatarBadge customClass='wrap-content-fill-child' user={user} textColor='#666' showDetail={false}/>
            </div>
        </div>
        );
    }

    render() {
        const {organization, healthDocs, users, onCloseClick} = this.props;
        const {profileData, progress} = this.state;
        
        const user = users[profileData.uid];
        let thumbImage = user != null && user.photoUri != null ? user.photoUri : defaultImage;
        const formSetting = organization.formSetting != null ? organization.formSetting : {};
        const healthDoc = healthDocs != null ? healthDocs[profileData.healthDoc] : null;
        
        if (profileData != null) {
            return (
                <div>
                    <div className='shadow-tile wrap-content-parent' style={{padding: '10px 20px'}}>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={thumbImage}
                            style={{ width: 60, height: 60, marginRight: 20 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {profileData.playerName} {profileData.playerLastName}
                            </h4>
                        </div>
                        <div className="wrap-content-wrap-child vertical-center-spacing">
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    {profileData.verified != null ? (profileData.verified ? "Verified" : "Denied") : "Need To Check"}
                                </div>
                                <div className='player-form-value form-setting-control'>
                                    <Switch
                                        checked={profileData.verified}
                                        onChange={e=>{
                                            profileData.verified = e.target.checked;
                                            this.setState({profileData: profileData});
                                        }}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='main-page shadow-tile'>
                    {formSetting.playerName != null && formSetting.playerName.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Name:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.playerName}
                                </div>
                                {profileData.playerName != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerName.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.playerLastName != null && formSetting.playerLastName.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Last Name:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.playerLastName}
                                </div>
                                {profileData.playerLastName != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerLastName.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.email != null && formSetting.email.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Email:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.email}
                                </div>
                                {profileData.email != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.email.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.birthday != null && formSetting.birthday.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Day of Birth:
                                </div>
                                <div className='player-form-value'>
                                    {toDateString(profileData.birthday)}
                                </div>
                                {profileData.birthday != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.birthday.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.mobile != null && formSetting.mobile.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Mobile:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.mobile}
                                </div>
                                {profileData.mobile != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.mobile.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.healthDoc != null && formSetting.healthDoc.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Position:
                                </div>
                                <div className='player-form-value'>
                                    <AttachFileView attachFile={healthDoc} />
                                </div>
                                {healthDoc != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.healthDoc.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.playerIDNumber != null && formSetting.playerIDNumber.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    ID Number:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.playerIDNumber}
                                </div>
                                {profileData.playerIDNumber != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerIDNumber.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.playerIDAttatch != null && formSetting.playerIDAttatch.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Position:
                                </div>
                                <div className='player-form-value'>
                                    <img className='player-form-image' src={profileData.playerIDAttatch} alt="iD" />
                                </div>
                                {profileData.playerIDAttatch != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerIDAttatch.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.position != null && formSetting.position.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Position:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.position}
                                </div>
                                {profileData.position != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.position.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.jersey != null && formSetting.jersey.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Jersey:
                                </div>
                                <div className='player-form-value'>
                                    {profileData.jersey}
                                </div>
                                {profileData.jersey != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.jersey.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div className='wrap-content-parent'>
                            <Button className='btn-submit' onClick={e => {
                                this.onSaveClicked();
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}

                    </div>
                    
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(PlayerFormDetail);