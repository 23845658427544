import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';

import { getOrgDomain } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import SeasonBadge from 'components/detail/organization/landing/SeasonBadge';
import ProgramBadge from 'components/detail/organization/ProgramBadge';
import { 
    Row,
    Col,
} from 'reactstrap';

import logo200Image from 'assets/img/logo/live_192.png';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import { STATUS_ONGOING, STATUS_REGISTER } from 'utils/constants';
import { filterActions } from 'redux/_actions/filter.actions';

class OrgServices extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            seasonChoice: {},
            programChoice: {},
            fundraiseChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            statusChoice: STATUS_ONGOING,
            mainTab: 0,
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const seasonId = this.props.router.params.seasonId;
            this.setState({id: id, seasonId: seasonId});
        }
        if (window.location.search && window.location.search.includes('payment_result=true')) {
            alert("Payment sent to owner sccuessfully. You will get into season once he accepts it.");
        } else if (window.location.search && window.location.search.includes('payment_result=false')) {
            alert("Payment not sent to owner sccuessfully. You should retry later.");
        }
        this.loadContents();
    }

    loadContents() {
        this.setState({loading: true});
        
        invokeHttpsApi('playerQuery-seasonsQuery', {organizationId: "-N0C576bf8NdboQ0abnd"}, ((data)=>{
            
            const programs = {};
            const organization = {};
            const programPayments = {};
            const profileData = {};
            const users = {};
            const programSchedules = {};
            const locations = {};
            const category = {};
            const cards = {};
            const clubs = {};
            const divisions = {};
            const seasons = {};
            const posts = {};
            const fundraises = {};
            const fundraisePayments = {};

            if (data.success) {
                this.props.updateDict(data);
                for (const [key, value] of Object.entries(data.programs)) {
                    programs[key] = value;
                }
                for (const [key, value] of Object.entries(data.organization)) {
                    organization[key] = value;
                }
                for (const [key, value] of Object.entries(data.programPayments)) {
                    programPayments[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.programSchedules)) {
                    programSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubs)) {
                    clubs[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraises)) {
                    fundraises[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraisePayments)) {
                    fundraisePayments[key] = value;
                }
            }
            
            this.setState({
                programs: programs,
                clubs: clubs,
                organization: organization,
                programPayments: programPayments,
                schedules: programSchedules,
                locations: locations,
                profileData: profileData,
                divisions: divisions,
                seasons: seasons,
                users: users,
                cards: cards,
                category: category,
                fundraises: fundraises,
                fundraisePayments: fundraisePayments,
                loading: false,
                posts: posts
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderSeasonBadge(season) {
        const {organization, divisions, profileData } = this.state;
        const organizations = {};
        organizations[organization.id] = organization;
        
        return (
            <Col lg={4} sm={6} xs={12} key={season.id} className='grid-item-wrapper'>
                <SeasonBadge 
                    key={season.id}
                    season={season}
                    divisions={divisions}
                    organizations={organizations}
                    profileData={profileData}
                    allowDetail={true}
                    onClickDetails={(division)=> {
                        window.location = `${getOrgDomain(organization)}/championships/${season.id}`
                    }}
                    onChanged={()=> {
                        this.loadContents()
                    }} />
            </Col>
        );
    }

    renderProgramBadge(program) {
        const {user} = this.props;
        const {organization, profileData, schedules } = this.state;
        const organizations = {};
        organizations[organization.id] = organization;
        
        return (
            <Col xl={4} lg={6} xs={12} key={program.id} className='grid-item-wrapper'>
                <ProgramBadge
                    key={program.id}
                    program={program}
                    schedules={schedules}
                    uid={user ? user.uid : null}
                    profileData={profileData}
                    allowDetail={true}
                    blockEdit={true}
                    onClickDetails={()=> {
                        window.location = `${getOrgDomain(organization)}/programs/${program.id}`
                    }}
                    onSaveDone={()=> {
                        this.loadContents()
                    }} />
            </Col>
        );
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    render() {
        const {loading, organization, programs, seasons} = this.state;
        
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';

        if (loading || !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        
        let items = [];
        for (const val of Object.values(seasons)) {
            if (!val.status || val.status === STATUS_REGISTER) {
                val.type = "season";
                items.push(val);
            }
        }
        
        for (const val of Object.values(programs)) {
            if (!val.status || val.status === STATUS_REGISTER) {
                val.type = "program";
                items.push(val);
            }
        }
        items.sort((a,b)=> {
            return a.startTime - b.startTime;
        })

        let arr = [];
        for (const item of items) {
            if (item.type === "season") {
                arr.push(this.renderSeasonBadge(item));
            } else {
                arr.push(this.renderProgramBadge(item));
            }
        }
        return (
            <div>
                {!sambaTheme && this.renderOrganizationHeader()}
                <div className='main-page container'>
                    <h3 className='row-header-title-big'>
                    {t('registration')}
                    </h3>
                    <Row>
                        
                    </Row>
                    <div className='divider'/>
                    <Row>
                    {arr}
                    </Row>
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    updateDict: filterActions.updateDict,
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgServices));
export { connectedPage as OrgServices };
