import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/video_default.png';

export class ClubTrackHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {clubTrack, onClickDetails, onClickEdit, uid } = this.props;
        let challengeImage = clubTrack.thumbUri != null ? clubTrack.thumbUri : defaultImage;
        let allowEdit = (uid == null && clubTrack.owner === 'free') || uid === clubTrack.owner;
        let timeMode = clubTrack.timeMode != null ? clubTrack.timeMode : false;
        
        if (clubTrack != null) {
            return (
                <div className="info-badge">
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb info-badge-icon"
                            src={challengeImage} />
                        <CardBody className="info-badge-body">
                            <h4 className='label label-default one-line-title'>
                            {clubTrack.title}
                            </h4>
                            <div className="one-line-div">{clubTrack.description}</div>
                            <Row>
                            {timeMode ? (
                                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                                Duration Limit:<div className="info-badge-info">{clubTrack.duration_limit / 60} min</div>
                                </Col>
                            ) : (
                                <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                                Distance Limit:<div className="info-badge-info">{clubTrack.distance / 1000} km</div>
                                </Col>
                            )}
                                <Col nm={4} xs={12} sm={6}>
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                            <Button className="btn-submit" onClick={e => {
                                                if (onClickDetails != null) {
                                                    onClickDetails();
                                                }
                                            }}>Details</Button>
                                            {allowEdit && (
                                                <Button className="btn-edit" onClick={e => {
                                                    if (onClickEdit != null) {
                                                        onClickEdit();
                                                    }
                                                }}>{t('edit')}</Button>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubTrackHeader);