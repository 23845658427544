import React, { PureComponent } from 'react';
import { t } from "i18next";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { SideMenu } from 'components/SideMenu';
import { withRouter } from 'utils/Routher';

import { 
    PiStorefrontFill,
    PiTreeStructureFill,
} from 'react-icons/pi';
import { 
    MdDashboard,
    MdGolfCourse,
    MdSettings,
    MdSubscriptions
} from 'react-icons/md';
import { 
    RiTeamFill,
    RiMoneyDollarBoxFill,
    RiSettings2Fill,
    RiCommunityFill,
    RiMailAddFill,
} from 'react-icons/ri';
import { LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID, LEAGUE_SUBSCRIPTION_PRODUCT_ID, LOCATION_SUBSCRIPTION_PRODUCT_ID, getLogo, getTitle } from 'utils/Utils';
import { invokeHttpsApi, listenDbQuery } from 'utils/API';
import { IoMdMenu } from 'react-icons/io';
import { OrganizationMembershipPrompt } from 'components/membership/OrganizationMembershipPrompt';
import { OrganizationMembershipBadge } from 'components/membership/OrganizationMembershipBadge';
import { OrganizationCreate } from 'components/edit/OrganizationCreate';

const floatingThres = 875;

class OrganizerDashboard extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            organization: {},
            locationSubscription: {},
            locationPrices: {},
            leagueSubscription: {},
            leaguePrices: {},
            locationAndLeagueSubscription: {},
            locationAndLeaguePrices: {},
            activeSubscriptions: {},
            leagueStatus: {},
            locationStatus: {},
            locationAndLeagueStatus: {},
            isOpened: false,
            windowWidth: window.innerWidth,
        };
        window.addEventListener('resize', this.getDimensions); 
    }

    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        const {user} = this.props;
        this.setState({loading: true});
        listenDbQuery("/organization", "owner", user.uid, (data) => {
            const organization = {};
            if (data) {
                for (const value of Object.values(data)) {
                    for (const [k,v] of Object.entries(value)) {
                        organization[k] = v;
                    }
                }
            }
            this.setState({organization: organization, loading: false});
        })
        invokeHttpsApi("membership-loadMembershipInfo", {}, (data) => {
            const locationSubscription = {};
            const locationPrices = {};
            const locationAndLeagueSubscription = {};
            const locationAndLeaguePrices = {};
            const leagueSubscription = {};
            const leaguePrices = [];
            const activeSubscriptions = {};
            var leagueStatus = {};
            var locationStatus = {};
            var locationAndLeagueStatus = {};
        
            if (data.success) {
                for (const [key, value] of Object.entries(data.locationSubscription)) {
                    locationSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationPrices)) {
                    if (value.active === true) {
                        locationPrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueSubscription)) {
                    locationAndLeagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeaguePrices)) {
                    if (value.active === true) {
                        locationAndLeaguePrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.leagueSubscription)) {
                    leagueSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.leaguePrices)) {
                    if (value.active === true) {
                        leaguePrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.activeSubscriptions)) {
                    activeSubscriptions[key] = value;
                }
                for (const [key, value] of Object.entries(data.leagueStatus)) {
                    leagueStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationStatus)) {
                    locationStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationAndLeagueStatus)) {
                    locationAndLeagueStatus[key] = value;
                }
            }

            this.setState({
                locationSubscription: locationSubscription,
                locationPrices: locationPrices,
                locationAndLeagueSubscription: locationAndLeagueSubscription,
                locationAndLeaguePrices: locationAndLeaguePrices,
                leagueSubscription: leagueSubscription,
                leaguePrices: leaguePrices,
                activeSubscriptions: activeSubscriptions,
                leagueStatus: leagueStatus,
                locationStatus: locationStatus,
                locationAndLeagueStatus: locationAndLeagueStatus,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    getMembershipPlan() {
        const { organizationSubscription } = this.props;
        const { locationSubscription, leagueSubscription, locationAndLeagueSubscription } = this.state;

        if (organizationSubscription != null && organizationSubscription.product != null) {
            if (organizationSubscription.product.id === LOCATION_SUBSCRIPTION_PRODUCT_ID && locationSubscription.name != null) {
                return locationSubscription;
            }
            if (organizationSubscription.product.id === LEAGUE_SUBSCRIPTION_PRODUCT_ID && leagueSubscription.name != null) {
                return leagueSubscription;
            }
            if (organizationSubscription.product.id === LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID && locationAndLeagueSubscription.name != null) {
                return locationAndLeagueSubscription;
            }
        }
        return null;
    }

    renderSideMenu() {
        const { organizationSubscription, freeOrganization } = this.props;
        const { windowWidth, isOpened } = this.state;

        const mainMenuItem = 
        {
            icon: <MdDashboard />,
            title: t('dashboard'),
            link: "/organizer"
        }
    

        const membershipItem = 
        {
            icon: <MdSubscriptions />,
            title: t('membership'),
            link: "/membership",
            curPlan: this.getMembershipPlan(),
            main: organizationSubscription,
            free: freeOrganization,
        }

        const freeItems = [
            {
                icon: <PiStorefrontFill />,
                title: t('season_pay'),
                link: "/season_pay"
            },
            {
                icon: <RiMoneyDollarBoxFill />,
                title: t('finance'),
                link: "/finance"
            },
        ];
    
        const menuItems = [
            {
                icon: <PiStorefrontFill />,
                title: t('season_pay'),
                link: "/season_pay"
            },
            {
                icon: <PiTreeStructureFill />,
                title: t('divisions'),
                link: "/divisions"
            },
            {
                icon: <MdGolfCourse />,
                title: t('programs'),
                link: "/programs"
            },
            {
                icon: <RiTeamFill />,
                title: t('members'),
                link: "/register",
                children: [
                    {
                        title: t('teams'),
                        link: "/teams"
                    },
                    {
                        title: t('player'),
                        link: "/player"
                    },
                    {
                        title: t('referee'),
                        link: "/referee"
                    },
                    {
                        title: t('locations'),
                        link: "/locations"
                    },
                    {
                        title: t('schedules'),
                        link: "/schedules"
                    },
                    {
                        title: t('claims'),
                        link: "/claims"
                    },
                    {
                        title: t('attendance'),
                        link: "/attendance"
                    },
                ]
            },
            {
                icon: <RiMoneyDollarBoxFill />,
                title: t('finance'),
                link: "/finance"
            },
            {
                icon: <RiCommunityFill />,
                title: t('community'),
                link: "/community"
            },
            {
                icon: <RiMailAddFill />,
                title: t('mail_box'),
                link: "/mailbox"
            },
            {
                icon: <RiSettings2Fill />,
                title: t('settings'),
                link: "/settings"
            },
        ]
    
        return (
            <SideMenu
                icon={getLogo()}
                mainItem={mainMenuItem}
                menuItems={this.checkExpired() ? freeItems : menuItems}
                membershipItem={membershipItem}
                title={getTitle()}
                floating={windowWidth < floatingThres}
                isOpened={isOpened}
                onClose={e=> {
                    this.setState({isOpened: false});
                }} />
        );
    }

    renderHeader() {
        const { user } = this.props;
        const { windowWidth, organization } = this.state;

        return (
            <div className='organizer-header'>
                {windowWidth < floatingThres && (
                <div className='organizer-header-settings'
                    onClick={e=> {
                        this.setState({isOpened: true});
                    }} >
                    <IoMdMenu />
                </div>
                )}
                {organization.id && (
                <img 
                    className='organizer-header-icon' 
                    src={organization.thumbUri ? organization.thumbUri : getLogo()} 
                    alt="logo" />)}
                <div className='organizer-header-title'>
                    {organization.title}
                </div>
                <div className='organizer-header-settings'
                    onClick={e=> {
                        this.props.router.navigate("/organizer/settings");
                    }} >
                    <MdSettings />
                </div>
                <img 
                    className='organizer-header-user' 
                    src={user && user.photoUri ? user.photoUri : getLogo()} 
                    alt="user"
                    onClick={e=> {
                        this.props.router.navigate("/my_account");
                    }} />
            </div>
        )
    }

    checkExpired() {
        const { organizationSubscription, freeOrganization } = this.props;
        const now = new Date().valueOf();
        let expired = false;
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            expired = true;
        }

        return expired;
    }

    renderOutlet() {
        const { user, freeOrganization } = this.props;
        const { organization, loading } = this.state;
        
        const expired = this.checkExpired();
        
        const path = this.props.router.location.pathname;
        if (loading || !user) {
            return <div />
        }
        if (!loading && !organization.id) {
            return <OrganizationCreate creatMode={true} />
        }
        if (expired && !path.includes('season_pay')) {
            return (
                <div>
                    {!freeOrganization && <OrganizationMembershipBadge/>}
                    <OrganizationMembershipPrompt forLeague={true}/>
                </div>
            )
        }
        return <Outlet />
    }

    render() {
        
        return (
            <div className='wrap-content-parent-fixed'>
                <div className='wrap-content-wrap-child-fixed'>
                    {this.renderSideMenu()}
                </div>
                <div className='wrap-content-fill-child-fixed' style={{paddingLeft: 10, paddingRight: 10}}>
                    {this.renderHeader()}
                    {this.renderOutlet()}
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrganizerDashboard));
export { connectedPage as OrganizerDashboard };
