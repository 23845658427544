import React, { Component } from "react";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import {
    Button,
    Input,
    Spinner,
    Row,
    Col
} from 'reactstrap';
import { t } from 'i18next';
import {
    MdClose,
} from 'react-icons/md';
import {
    RiMoneyDollarBoxFill,
} from 'react-icons/ri';
import { loadStripe } from '@stripe/stripe-js';
import { 
    STRIPE_PUBLISHABLE_KEY,
    toDateTimeString
} from 'utils/Utils';

import walletIcon from 'assets/img/icons/ic_wallet.png';
import { generateId, invokeHttpsApi } from "utils/API";

class OrganizationWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            wallet: {},
            walletPayments: {},
            users: {},
            balanceInput: 0,
            balanceType: 'direct'
        }
    }

    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props;

        const prevUid = prevProps.user && prevProps.user.uid ? prevProps.user.uid : null;
        const uid = user ? user.uid : null;

        if ((!prevUid && uid) || (prevUid && uid && prevUid !== uid)) {
            this.loadContents();
        }
    }

    loadContents() {
        const {user, organization} = this.props;
        this.setState({loading: true});
        
        invokeHttpsApi("purchase-loadOrganizationWallet", {
            organizationId: organization ? organization.id : null
        }, (data) => {
            const wallet = {
                uid: user ? user.uid : null,
                organizationId: organization.id,
                balance: 0
            };
            const walletPayments = {};
            const users = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.wallet)) {
                    wallet[key] = value;
                }
                for (const [key, value] of Object.entries(data.walletPayments)) {
                    walletPayments[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
            }

            this.setState({
                wallet: wallet,
                walletPayments: walletPayments,
                users: users,
                loading: false
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({
                loading: false
            })
        });
    }

    submitPayment() {
        const {user, organization} = this.props;
        const {wallet, balanceInput, balanceType} = this.state;
        if (!balanceInput || balanceInput <= 0) {
            alert("You should input valid balance amount");
            return;
        }

        if (!wallet.id) {
            wallet.id = generateId("wallet");
            wallet.organizationId = organization.id;
            wallet.uid = user.uid;
            wallet.balance = 0;
        }

        const data = {
            wallet: wallet,
            uid: user.uid,
            organizationId: organization.id,
            balance: balanceInput,
            type: balanceType,
            returnURL: window.location.href
        }

        this.setState({checkoutProgress: true});
        invokeHttpsApi("purchase-submitWalletTransaction", data, async (data) => {
            if (data.success && data.session) {
                const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

                stripe.redirectToCheckout({ sessionId: data.session.id });

                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else if (data.success) {
                this.loadContents();
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    renderTransactionItem(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.price) / 100);
        const subtitle = "+";

        let msg = "Waiting";
        if (item.staffId && users[item.staffId]) {
            msg = users[item.staffId].username;
        } else if (!item.success && item.type === 'direct') {
            msg = "Pending";
        } else if (item.success && item.type === 'direct') {
            msg = "Paid";
        } else {
            msg = "Paid";
        }

        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#38be55'}}>
                    {subtitle} {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'direct' ? "Online" : "In Person"}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {msg}
                    </h4>
                </Col>
            </Row>
        );
    }

    renderOutcomeTransactionItem(item) {
        const price = "$" + (parseFloat(item.price) / 100);
        
        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#EE000F'}}>
                    {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.type === 'wallet' ? "Wallent Payment" : "Unknown"}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {item.title}
                    </h4>
                </Col>
            </Row>
        );
    }

    renderTransactions() {
        const {wallet, walletPayments} = this.state;

        const paymentsArr = Object.values(walletPayments);

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        const pending = [];
        const waiting = [];
        const success = [];
        const outcome = [];
        const transactionsArr = wallet.transactions ? Object.values(wallet.transactions) : [];
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        transactionsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            if (item.success) {
                success.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else if (item.type !== 'direct')  {
                pending.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            } else {
                waiting.push(
                    <div>{this.renderTransactionItem(item)}</div>
                );
            }
        }

        for (const item of transactionsArr) {
            if (item.amount < 0) {
                outcome.push(
                    <div>{this.renderOutcomeTransactionItem(item)}</div>
                );
            }
        }

        const items = [];
        for (const item of pending) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of waiting) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of success) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of outcome) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div className='wallet-info-tile'>
                <div className='detail-nav-btn'>
                    <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                    
                    <h3 className='items-navigation-title'>
                    Transactions
                    </h3>
                </div>
                <Row style={{margin: 0}}>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Date
                        </h4>
                    </Col>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Money
                        </h4>
                    </Col>
                    <Col lg={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Type
                        </h4>
                    </Col>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Status/Staff
                        </h4>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Transactions To Show
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {user, organization} = this.props;
        const {loading, checkoutProgress, opened, wallet, balanceInput, balanceType} = this.state;
        
        if (user != null) {
            let balance = parseFloat(wallet.balance ? wallet.balance : 0) / 100;
            if (opened) {
                return (
                    <div className="wallet-tile">
                        <div className="wallet-title-bar">
                            <img className="wallet-title-icon" src={walletIcon} alt="icon" />
                            <h3 className="wallet-title">{t('my_wallet')}</h3>
                            <Button className="btn-submit wallet-title-action" onClick={e=> {
                                this.setState({opened: false})
                            }}>
                                <MdClose />
                            </Button>
                        </div>
                        <div className="wrap-content-parent wallet-margin" style={{marginTop: 10}}>
                            <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24}}>Balance:</h1>
                            {loading ? (
                            <Spinner color="primary" />
                            ) : (
                            <h1 className="wrap-content-fill-child" style={{color: "#EEB825", fontSize: 24}}>${balance}</h1>
                            )}
                        </div>
                        <div className="wallet-title-bar" style={{marginTop: 10}}>
                            <Input type="number" name="money" id="money" className="wrap-content-fill-child" onChange={e => {
                                this.setState({balanceInput: e.target.value});
                            }} value={balanceInput} />
                            <Input type="select" name="type" id="type" className="wrap-content-fill-child" onChange={e => {
                                this.setState({balanceType: e.target.value});
                            }} value={balanceType}>
                                {organization.stripeAccountId && (
                                <option value="direct">Direct</option>
                                )}
                                <option value="cash">Pay In Person</option>
                            </Input>
                            {checkoutProgress ? (
                                <Spinner color="grow" />
                            ) : (
                                <Button className="btn-submit wrap-content-wrap-child" style={{marginRight: 0}} onClick={e=> {
                                    this.submitPayment();
                                }}>
                                    Submit
                                </Button>
                            )}
                        </div>
                        <div className="wallet-transactions-list">
                            {this.renderTransactions()}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="wallet-layout">
                        <img className="wallet-icon" src={walletIcon} alt="icon"  onClick={e=> {
                            this.setState({opened: true})
                        }}/>    
                    </div>
                );
            }
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = connect(mapState, actionCreators)(OrganizationWallet);
export { connectedNavigation as OrganizationWallet };
