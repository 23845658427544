import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';
import defaultImage from 'assets/img/icons/club_icon_default.png';

export class GroupTeamBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    render() {
        const {team} = this.props;

        let thumImage = team != null && team.iconUri != null ? team.iconUri : defaultImage;
        
        if (team != null) {
            return (
                <div className='group-team-badge'>
                    <CardImg
                        className="group-team-icon"
                        src={thumImage} />
                    <h4 className='group-team-title'>{team.title}</h4>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default GroupTeamBadge;