import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import { 
    GiSoccerKick
} from 'react-icons/gi';
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import locationImage from 'assets/img/icons/venue_icon.png';
import LoadingView from 'components/detail/loading/LoadingView';
import attGoing from 'assets/img/icons/attendance_going.png';
import formIcon from 'assets/img/icons/ic_covid_form.png';
import SurveyView from 'components/detail/survey/SurveyView';
import attNotGoing from 'assets/img/icons/attendance_not_going.png';
import Avatar from 'components/detail/Avatar';
import { 
    Modal, 
    ModalBody, Row, Col, CardImg, Card, Input, CardBody, Button
} from 'reactstrap';
import { t } from 'i18next';
import Switch from '@mui/material/Switch';
import CustomDatePicker from 'components/template/CustomDatePicker';
import { toDateTimeString, toDateString, getDayStart, DAY_TIME } from 'utils/Utils';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';

import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
} from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';

class AttendancePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            organization: {},
            organizations: {},
            locations: {},
            users: {},
            seasons: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            locationChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            dateFilter: false,
            dateChoice: new Date().valueOf()
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        const {user} = this.props;
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizerDashboardQuery', {}, (data) => {
            const organization = {};
            const organizations = {};
            const seasons = {};
            const users = {};
            const teams = {};
            const locations = {};
            const orgSchedules = {};
            const schedules = {};
            const games = {};
            const covidForm = {};
            const playerForms = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (user != null && value.owner === user.uid) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                    if (value.subSeasons != null) {
                        for (const subSeason of Object.values(value.subSeasons)) {
                            if (subSeason.games !== undefined) {
                                for (const game of Object.values(subSeason.games)) {
                                    games[game.id] = game;
                                }
                            }
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.orgSchedules)) {
                    orgSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.schedules)) {
                    schedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.covidForm)) {
                    covidForm[key] = value;
                }
                for (const [key, value] of Object.entries(data.playerForms)) {
                    playerForms[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                teams: teams,
                locations: locations,
                users: users,
                orgSchedules: orgSchedules,
                games: games,
                schedules: schedules,
                covidForm: covidForm,
                playerForms: playerForms,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    getTodayForm(playerForms, time) {
        const dayStart = getDayStart(time)
        let target = null
        for (const val of Object.values(playerForms)) {
            if (val.timestamp >= dayStart && val.timestamp < dayStart + DAY_TIME) {
                target = val
            }
        }

        return target
    }

    renderStatus(form) {
        let title = "Good";
        const survey = form.survey;
        
        if (survey != null && form.value > survey.goodValue) {
            title = "Good";
        } else if (survey != null && form.value > survey.yellowValue) {
            title = t('normal');
        } else if (survey != null && form.value > survey.warningValue) {
            title = "Warning";
        } else if (survey != null && form.value > survey.redValue) {
            title = "Danger";
        } else if (survey != null && form.value > -1) {
            title = "Critical";
        }

        let color = "#277e26";
        if (survey != null && form.value > survey.goodValue) {
            color = "#277e26";
        } else if (survey != null && form.value > survey.yellowValue) {
            color = "#3d76bf";
        } else if (survey != null && form.value > survey.warningValue) {
            color = "#F0D11F";
        } else if (survey != null && form.value > survey.redValue) {
            color = "#a6080e";
        } else if (survey != null && form.value > -1) {
            color = "#ce080e";
        }

        return <div style={{display: 'inline-block', fontWeight: 800, fontSize: 10, color: color}}>{title}</div>
    }

    renderMember(member, availablity) {
        const {users, playerForms} = this.state;
        var title;
        switch (availablity) {
            case 0:
                title = "Spectator";
                break;
            case 1:
                title = "Member";
                break;
            case 2:
                title = t('staff');
                break;
            case 3:
                title = "Location Staff";
                break;
            default:
                title = "Spectator";
                break;
        }
        var icon = availablity.verified ? attGoing : attNotGoing;
        const checker = users[availablity.fieldStuffId] != null ? users[availablity.fieldStuffId] : {username: 'Unknown'}
        const locationIcon = member.locationIcon != null ? member.locationIcon : locationImage;

        let form = this.getTodayForm(playerForms, availablity.timestamp);

        return(
            
            <Card key={availablity.id} className="flex-row shadow-tile">
                <Avatar src={member.photoUri} 
                    style={{
                        width: 45,
                        height: 45,
                        margin: '10px 0px 10px 15px',
                        border: '3px solid #fff',
                    }} 
                    className='wrap-content-wrap-child vertical-center-spacing' />
                <CardBody style={{padding: '5px 10px'}}>
                    <Row style={{padding: '4px 10px'}}>
                        <Col sm={8} xs={8}>
                            <h4 className='label label-default' style={{fontSize: 14, marginBottom: 2}}>
                                {member.username}
                            </h4>
                            <div style={{fontSize: 14}}>
                                <img alt="icon" src={locationIcon} style={{width: 20, height: 20, marginRight: 10}} />
                                {availablity.location}
                                , <b>{availablity.title}</b>
                             </div>
                        </Col>
                        <Col sm={3} xs={3}>
                            <CardImg
                                className="profile-thumb"
                                src={icon}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                />
                            <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{title}</div>
                            <div style={{fontSize: 14}}>Checked by {checker.username} on {toDateTimeString(availablity.timestamp)}</div>
                        </Col>
                        {form != null && (
                        <Col sm={1} xs={1} onClick={e=> {
                            this.setState({
                                openForm: true,
                                formChoice: form
                            })
                        }}>
                            <CardImg
                                className="profile-thumb"
                                src={formIcon}
                                style={{ display: 'inline-block', width: 20, height: 20, marginRight: 10 }}
                                />
                            {this.renderStatus(form)}
                        </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        )
    }

    renderFilter() {
        const {searchStr} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Input type="text" name="search" id="search" placeholder='Search by User Name ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>                        
            </div>
        )
    }

    renderContents() {
        const { user, organizationSubscription, freeOrganization} = this.props;
        const { locations, locationChoice, dateFilter, dateChoice, schedules, orgSchedules, games, teams, users , detailChoice, editOpen, detailOpen, searchStr, 
            openForm, formChoice } = this.state;

        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        const attendanceList = [];
        const dayStart = getDayStart(dateChoice);

        for (const value of Object.values(schedules)) {
            let location = locations[value.location];
            if (location != null && (locationChoice.id == null || location.id === locationChoice.id)) {
                if (value.fieldVerificationList != null) {
                    for (const item of Object.values(value.fieldVerificationList)) {
                        if (!dateFilter || (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME)) {
                            attendanceList.push(
                                {
                                    uid: item.uid,
                                    fieldStuffId: item.fieldStuffId,
                                    type: item.type,
                                    timestamp: item.timestamp,
                                    verified: item.verified,
                                    location: location.title,
                                    locationIcon: location.thumbUri,
                                    title: value.title + " on " + toDateString(value.timestamp),
                                }
                            )
                        }
                    }
                }
            }
        }
        
        for (const value of Object.values(orgSchedules)) {
            let location = locations[value.location];
            if (location != null && (locationChoice.id == null || location.id === locationChoice.id)) {
                if (value.fieldVerificationList != null) {
                    for (const item of Object.values(value.fieldVerificationList)) {
                        if (!dateFilter || (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME)) {
                            attendanceList.push(
                                {
                                    uid: item.uid,
                                    fieldStuffId: item.fieldStuffId,
                                    type: item.type,
                                    timestamp: item.timestamp,
                                    verified: item.verified,
                                    location: location.title,
                                    locationIcon: location.thumbUri,
                                    title: value.title + " on " + toDateString(value.timestamp),
                                }
                            )
                        }
                    }
                }
            }
        }
        
        for (const value of Object.values(games)) {
            let location = locations[value.locationId];

            let homeTeam = teams[value.homeTeam];
            let awayTeam = teams[value.awayTeam];

            let title = "Game between " + (homeTeam != null ? homeTeam.title : "Home") + " vs " +  + 
                (awayTeam != null ? awayTeam.title : "Away")+ " on " + toDateString(value.start)
            if (location != null && (locationChoice.id == null || location.id === locationChoice.id)) {
                if (value.fieldVerificationList != null) {
                    for (const item of Object.values(value.fieldVerificationList)) {
                        if (!dateFilter || (value.start >= dayStart && value.start < dayStart + DAY_TIME)) {
                            attendanceList.push(
                                {
                                    uid: item.uid,
                                    fieldStuffId: item.fieldStuffId,
                                    type: item.type,
                                    timestamp: item.timestamp,
                                    verified: item.verified,
                                    location: location.title,
                                    locationIcon: location.thumbUri,
                                    title: title,
                                }
                            )
                        }
                    }
                }
            }
        }
        
        attendanceList.sort(function(a, b) {
            let t1 = a.start;
            if (t1 == null) {
                t1 = a.timestamp;
            }
            if (t1 == null) {
                t1 = 0;
            }
            let t2 = b.start;
            if (t2 == null) {
                t2 = b.timestamp;
            }
            if (t2 == null) {
                t2 = 0;
            }

            return t2 - t1;
        })
        
        const result = [];
    
        if (user == null) {
            return <div/>
        }
        
        for (const value of attendanceList) {
            let member = users[value.uid];
            if (member != null) {
                if (searchStr != null && searchStr.length > 0 && !member.username.toLowerCase().includes(searchStr.toLowerCase())) {
                    continue;
                }
                result.push(
                    this.renderMember(member, value)
                )
            }
        }
        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title="Organization Attendance"
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={attendanceList.length}
                        detailItem={detailChoice}
                        onClickBack={()=>{
                            this.setState({profileChoice: {}, detailOpen: false, editOpen: false})
                        }} />
                </div>

                {this.renderFilter()}

                <Row>
                    <Col sm={8}>
                        <Input 
                            type="select" 
                            name="select" 
                            value={locationChoice.id} 
                            id="typeChoice" 
                            style={{marginTop: 20}}
                            onChange={e=> {
                                let update = locations[e.target.value];
                                this.setState({locationChoice: update != null ? update : {}});
                            }}>
                            <option key="null" value={null}></option>
                            {Object.values(locations).map((val,idx)=>(
                                <option key={idx} value={val.id}>{val.title}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col style={{margin: 'auto 0px'}} sm={4}>
                        <Switch
                            checked={dateFilter}
                            onChange={e=>{
                                this.setState({dateFilter: e.target.checked});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        <div style={{display: 'inline-block', fontSize: 10, marginRight: 20}}>Show by Date</div>
                        <div style={{display: 'inline-block'}}>
                            <CustomDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date picker dialog"
                                format="MM/DD/YYYY"
                                value={dateChoice}
                                onChange={ date => {
                                    this.setState({dateChoice: date.valueOf()});
                                }} />
                        </div>
                    </Col>
                </Row>

                {openForm === true && formChoice != null ? (
                    <div>
                    
                        <div className='items-navigation'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                <div className='detail-nav-btn'>
                                    <h3 className='items-navigation-title'>
                                    {t('user_attendance_day')}
                                    </h3>
                                </div>
                            </div>
                            
                            <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                                this.setState({formChoice: null, openForm: false})
                            }}>{t('back')}</Button>
                        </div>

                        <SurveyView 
                            survey={formChoice.survey}
                            uid={user.uid}
                            submission={formChoice}
                            user={user}
                            editable={false} />
                    </div>
                ) : (
                    <div>
                    {result}
                    </div>
                )}
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                    
                    </ModalBody>
                </Modal>
            </div>
        );
    }
    
    render() {
        const {loading} = this.state;

        return (
            <div>
                <div style={{marginTop: 20}}/>
                
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(AttendancePage));
export { connectedApp as AttendancePage };
