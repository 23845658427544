import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FcTreeStructure } from 'react-icons/fc';
import ProgramEdit from 'components/edit/organization/ProgramEdit';
import { userActions } from 'redux/_actions';
import { Row, Col } from 'reactstrap';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import ItemsNavigation from 'components/detail/ItemsNavigation';
  
import ProgramBadge from 'components/detail/organization/ProgramBadge';
import ProgramDetails from 'components/detail/organization/ProgramDetails';
import LoadingView from 'components/detail/loading/LoadingView';
import { 
    LEAGUE_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    replaceUrl,
    getProgramDomain,
} from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';
import { STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER } from 'utils/constants';
import CustomTabView from 'components/template/CustomTabView';

class ProgramsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programs: {},
            schedules: {},
            organizations: {},
            programPayments: {},
            trophies: {},
            users: {},
            teams: {},
            locations: {},
            category: {},
            programChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            filter: 1,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        if (this.props.user) {
            this.loadContents(id);
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        const id = this.props.router.params.id;
        if (!prevUid && uid) {
            this.loadContents(id);
        }
    }

    loadContents(id) {
        const {programChoice} = this.state;
        invokeHttpsApi('organizerQuery-programsQuery', {}, (data) => {

            const programs = {};
            const organizations = {};
            let organization = {};
            const programPayments = {};
            const profileData = {};
            const users = {};
            const programSchedules = {};
            const locations = {};
            const category = {};
            const cards = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.programs)) {
                    programs[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.programPayments)) {
                    programPayments[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.programSchedules)) {
                    programSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }
            let choiceId = id != null ? id : programChoice.id;
            let update = programs[choiceId];
            if (update != null) {
                for (const [key, value] of Object.entries(update)) {
                    programChoice[key] = value;
                }
            }

            this.setState({
                programs: programs,
                organizations: organizations,
                organization: organization,
                programPayments: programPayments,
                schedules: programSchedules,
                locations: locations,
                profileData: profileData,
                programChoice: programChoice,
                users: users,
                category: category,
                cards: cards,
                loading: false,
                detailOpen: programChoice.id != null
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    renderClubSchedules() {

    }

    renderContents() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {organization, programs, teams, schedules, cards, trophies, users, locations, profileData, programChoice, category, detailOpen, editOpen, filter} = this.state;

        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        let curConetntCount = 0;
        for (const[, value] of Object.entries(programs)) {
            if (value.owner === user.uid) {
                curConetntCount += 1;
            }
        }
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LEAGUE_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        const toShow = {};
        for (const [k,v] of Object.entries(programs)) {
            if (filter === 0 && (v.status === undefined || v.status === STATUS_REGISTER)) {
                toShow[k] = v;
            } else if (filter === 1 && v.status === STATUS_ONGOING) {
                toShow[k] = v;
            } else if (filter === 2 && v.status === STATUS_FINISHED) {
                toShow[k] = v;
            }
        }

        if (Object.values(programs).length === 0 || editOpen) {
            return (
            <div>
                <ItemsNavigation
                    title="Programs"
                    icon={<FcTreeStructure/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={programChoice}
                    onClickBack={()=>{
                        this.setState({programChoice: {}, detailOpen: false, editOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({programChoice: {}, editOpen: true});
                    }} />
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <ProgramEdit 
                        program={programChoice}
                        organization={organization}
                        categorys={category}
                        createMode={Object.values(programs).length === 0 || !programChoice.id} 
                        uid={user.uid}
                        onCloseClick={()=> {
                            this.setState({editOpen: false});
                        }}
                        onSaveDone={()=> {
                            this.setState({editOpen: false});
                            this.loadContents()
                        }} />
                </div>
            </div>
            );
        } else if (detailOpen) {
            return (
            <div>
                <ItemsNavigation
                    title="Programs"
                    icon={<FcTreeStructure/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={programChoice}
                    onClickBack={()=>{
                        this.setState({programChoice: {}, detailOpen: false, editOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({programChoice: {}, editOpen: true});
                    }} />
                <ProgramDetails 
                    program={programChoice}
                    organization={organization}
                    teams={teams}
                    trophies={trophies}
                    locations={locations}
                    profileData={profileData}
                    schedules={schedules}
                    createMode={Object.values(programs).length === 0} 
                    uid={user.uid}
                    user={user}
                    users={users}
                    cards={cards}
                    onPressReferee={() => {
                        this.props.router.navigate('/organizer/register/referee')
                    }}
                    onChanged={(update)=> {
                        if (update == null) {
                            this.loadContents()
                        } else {
                            const item = {};
                            for (const [key, value] of Object.entries(update)) {
                                item[key] = value;
                            }
                            programs[update.id] = update;
                            this.setState({programChoice: item, programs: programs});
                        }
                    }}
                    onSaveDone={()=> {
                        this.loadContents()
                    }} 
                    onClickEdit={()=> {
                        this.setState({
                            editOpen: true,
                            programChoice: programChoice})
                    }} />
            </div>
            );
        } else {
            let items = [];
            for (const [key, value] of Object.entries(toShow)) {
                items.push(
                    <Col xl={4} lg={6} xs={12} key={key} className='grid-item-wrapper'>
                        <ProgramBadge 
                            key={key}
                            program={value}
                            schedules={schedules}
                            uid={user.uid}
                            profileData={profileData}
                            allowDetail={true}
                            onClickDetails={()=> {
                                this.setState(
                                {
                                    detailOpen: true,
                                    programChoice: value
                                })
                                replaceUrl(getProgramDomain(organization, value.id))
                            }}
                            onClickEdit={()=> this.setState(
                                {
                                    editOpen: true,
                                    programChoice: value
                                })}
                            onSaveDone={()=> {
                                this.loadContents()
                            }} />
                    </Col>
                );
            }
            return (
            <div>
                <ItemsNavigation
                    title="Programs"
                    icon={<FcTreeStructure/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={programChoice}
                    onClickBack={()=>{
                        this.setState({programChoice: {}, detailOpen: false, editOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({programChoice: {}, editOpen: true});
                    }} />
                <CustomTabView
                    value={filter}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, value)=> {
                        this.setState({filter: value})
                    }}
                    items={
                    [
                        {
                            label: t('registration')
                        },
                        {
                            label: t('ongoing')
                        },
                        {
                            label: t('finihsed')
                        },
                    ]} />

                <Row> 
                {items}                   
                </Row>
            </div>
            );
        }
    }

    render() {
        const {user} = this.props;
        const {loading} = this.state;

        if (user == null) {
            return <div/>
        }

        return (
            <div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(ProgramsPage));
export { connectedApp as ProgramsPage };
