import React, { Component } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  
class CustomDatePicker extends Component {

    render() {
        const { format, label, value, onChange } = this.props;

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker
                        margin="normal"
                        format={format ? format: "MMM/DD/YYYY"}
                        label={label}
                        value={dayjs(value)}
                        onChange={update => {
                            onChange(update.toDate())
                        }}/>
                </DemoContainer>
            </LocalizationProvider>
        );
    }
}

export default CustomDatePicker;
