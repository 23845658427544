import React, { Component } from "react";
import {
    Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';

import { toSimpleTime } from 'utils/Utils';

import {
    MdAccessTime,
    MdLocationOn
} from 'react-icons/md';

export class OrganizationScheduleHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {locations, schedule, onClickDetail, onClickEdit } = this.props;
        const location = locations[schedule.location] != null ? locations[schedule.location] : {};
        
        const icon = location.thumbUri != null ? location.thumbUri : defaultImage;

        const date = new Date(schedule.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        
        const from = toSimpleTime(schedule.timestamp);
        const to = toSimpleTime(schedule.deadline);
        
        
        if (schedule != null) {
            return (
                <div>
                <div className="shadow-tile wrap-content-parent">
                    <div className="date-header wrap-content-wrap-child">
                        <div className="date-month">
                            {day}
                        </div>
                        <div className="date-day">
                            {month}
                        </div>
                    </div>
                    <div className='wrap-content-fill-child' style={{padding: '5px 10px'}}>
                        <Row>
                            <Col nm={8} xs={12} sm={6} className="info-col">
                                <h4 className='label label-default one-line-title'>
                                {schedule.title}
                                </h4>
                                <MdAccessTime/> {from} {" ~ "} {to} <MdLocationOn/> {location.formatted_address}
                                </Col>
                                <Col nm={4} xs={12} sm={6} className="info-col">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child'>
                                        </div>
                                        <div className="wrap-content-wrap-child">
                                            <img alt="icon" src={icon} style={{width: 20, height: 20, marginRight: 10}} />
                                            {location.title}
                                        </div>
                                    </div>
                                    <div className='wrap-content-parent' style={{marginTop: 5}}>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="challenge-progress wrap-content-wrap-child">
                                            <Button className="btn-submit" onClick={e => {
                                                if (onClickDetail != null) {
                                                    onClickDetail();
                                                }
                                            }}>Details</Button>
                                            <Button className="btn-edit" onClick={e => {
                                                if (onClickEdit != null) {
                                                    onClickEdit();
                                                }
                                            }}>{t('edit')}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(OrganizationScheduleHeader);