import React, { Component } from "react";
import ReactPlayer from 'react-player';
import {
    Card, CardBody, CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';

export class TutorialDetails extends Component {
    render() {
        const {onCloseClick, tutorial} = this.props;
        
        let tutorialImage = tutorial.thumbUri != null ? tutorial.thumbUri : defaultImage;
        
        if (tutorial != null) {
            return (
                <div>
                    <Card className="flex-row">
                        <CardImg
                            className="profile-thumb vertical-center-spacing"
                            src={tutorialImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {tutorial.title}
                            </h4>
                            <div>{tutorial.description}</div>
                        </CardBody>
                    </Card>
                    {tutorial.videoUri != null && tutorial.videoUri !== '' && (
                    <div>
                        <ReactPlayer 
                            className="default-player"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={tutorial.videoUri}
                            width={'80%'}
                            height={'auto'} />

                    </div>
                    )}
                    <Button className='modal-button' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TutorialDetails);