
import defaultUserImage from 'assets/img/icons/player_photo_default.png';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';
import { mainRedColor, heavyYellowColor, mainGreenColor } from 'style/styles';
import { 
    MdError,
    MdWarning,
    MdCheck
} from 'react-icons/md';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import nonIcon from 'assets/img/icons/attendance_canceled.png';
import parse from 'html-react-parser';
import dateFormat from "dateformat";
import liveLogo from 'assets/img/logo/live_192.png';
import canadaLogo from 'assets/img/logo/canada_192.png';
import eaglesLogo from 'assets/img/logo/eagles_192.png';
import ivyLogo from 'assets/img/logo/ivy_192.png';
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, ROUND_TYPE_LEAGUE, SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT, STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from './constants';
import { t } from 'i18next';

export const renderHtmlString = (html) => {
    return parse(html);
}


export const WEEK_TIME = 7 * 24 * 3600 * 1000;
export const DAY_TIME = 24 * 3600 * 1000;
export const HOUR_TIME = 3600 * 1000;
export const MIN_TIME = 60 * 1000;
export const YEAR_TIME = DAY_TIME * 365;
// export const STRIPE_PUBLISHABLE_KEY = "pk_live_51J3jqZBI7zj2hxGzTyV0k6JRWUCkRxZ1PVyhbG6ZliNQ5DOZrxOKGFGcyyox9Xt50fwDdAm0CKbgUsZq46irwmpw00rVhfeNyT";
export const STRIPE_PUBLISHABLE_KEY = "pk_test_51J3jqZBI7zj2hxGzTCw21ozuHYQtCUyJOTRBWNTxSs8n4tU3H1mP6cBnAmIOnZNtpOO3MkyZYMLkUXK8NtGdjMEP00TOOY7FPd";
// export const STRIPE_SECRET_KEY = "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";
export const STRIPE_SECRET_KEY = "sk_test_51J3jqZBI7zj2hxGzJ7d1hssS7cfDDhKNT35NiqToAWL0A7oma3ckvHK40fN446AkVS26WRwRYkTQS6xFu1RGjl6000s4nsaoYI";
export const BASIC_SUBSCRIPTION_PRODUCT_ID = "prod_JkWU2nB7uxX9K3";//"prod_JjTYa5lGtQBZyr";
export const BASIC_PACKAGE = {
    id: BASIC_SUBSCRIPTION_PRODUCT_ID,
    challengeLimit: 25,
    lessonLimit: 25,
    teamLimit: 1,
    playerLimit: 50,
    level: 1,
};
export const PLUS_SUBSCRIPTION_PRODUCT_ID = "prod_JkWWAugAQ1aIqc";//"prod_Jjy1cQKEe0hf5h";
export const PLUS_PACKAGE = {
    id: PLUS_SUBSCRIPTION_PRODUCT_ID,
    challengeLimit: 50,
    lessonLimit: 50,
    teamLimit: 3,
    playerLimit: -1,
    level: 2,
};
export const PREMIUM_SUBSCRIPTION_PRODUCT_ID = "prod_JhdGK9VlH34ADA";//"prod_JiUarD98Rikr81";
export const PREMIUM_PACKAGE = {
    id: PREMIUM_SUBSCRIPTION_PRODUCT_ID,
    challengeLimit: -1,
    lessonLimit: -1,
    teamLimit: -1,
    playerLimit: -1,
    level: 3,
};
export const FREE_PACKAGE = {
    id: "free",
    challengeLimit: 0,
    lessonLimit: 0,
    teamLimit: -1,
    playerLimit: 15,
    level: -1,
};
export const LEAGUE_SUBSCRIPTION_PRODUCT_ID = "prod_KquxPT5FP88bpG";
export const LOCATION_SUBSCRIPTION_PRODUCT_ID = "prod_Kqv7hQf42X70cU";
export const LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID = "prod_Kqv0HfY5B8uRUC";
export const ORG_MEMBERSHIP_MONTHLY_PRICE = "price_1NDXIZBI7zj2hxGzaybmKgOl";
export const ORG_MEMBERSHIP_YEARLY_PRICE = "price_1NDXItBI7zj2hxGz94r8twDS";
export const FREE_ORG_SUBSCRIPTION_PRODUCT_ID = "sub_free_org";
export const CUR_ORG_MEMBERSHIP_DISCOUNT = {}
// {
//     code: "DUQ8EMK2",
//     discount: 70,
//     description: "We offer you 30 percent off discount for 1st year of your subscription. This discount code is valid until 28st of Feb."
// }

export const validateEmailStr = (email) => {
    if (email) {
        return email.trim().toLowerCase()
    }
    return null;
};

export const validateEmail = (email) => {
    return String(email)
    .toLowerCase()
    .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const timeValue = (str) => {
    try {
        const parts = str.splite(":");
        return parts[0] * HOUR_TIME + parts[1] * MIN_TIME;
    } catch (ex) {
        console.log("Parse time failed", ex);
    }
    return -1;
}

export const get2Decimal = (val) => {
    return (Math.floor(100 * val) / 100);
}

export const getCurrentPlan = (subscription) => {
    if (subscription != null && subscription.product != null && subscription.product.id === BASIC_SUBSCRIPTION_PRODUCT_ID) {
        return BASIC_PACKAGE;
    }
    if (subscription != null && subscription.product != null && subscription.product.id === PLUS_SUBSCRIPTION_PRODUCT_ID) {
        return PLUS_PACKAGE;
    }
    if (subscription != null && subscription.product != null && subscription.product.id === PREMIUM_SUBSCRIPTION_PRODUCT_ID) {
        return PREMIUM_PACKAGE;
    }
    return FREE_PACKAGE;
}

export const weekDateString = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return dateFormat(date, "dddd, mmm dS");;
    }
    return "Invalid Date";
}

export const weekViewString = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return dateFormat(date, "mmm dS yyyy");;
    }
    return "Invalid Date";
}

export const getWeekNumber = (timestamp) => {
    let d = new Date(timestamp);
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    return [d.getUTCFullYear(), weekNo];
}

export const simpleDate = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return dateFormat(date, "mm/dd");;
    }
    return "--/--";
}

export const simpleTime = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return date.toLocaleString('en-US', { hour: 'numeric', hour12: true });;
    }
    return "--:-- am";
}

export const toSimpleDate = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return dateFormat(date, "mm/dd/yyyy");
    }
    return "Invalid Date";
}

export const toDateString = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return date.toLocaleDateString();
    }
    return "Invalid Date";
}

export const getSeasonRoundsArr = (season) => {
    let result = [];
    if (season && season.subSeasons)  {
        if (season.subSeasons.league && season.subSeasons.league.status !== STATUS_FINISHED) {
            result.push(season.subSeasons.league);
        }
        if (season.subSeasons.group && season.subSeasons.group.status !== STATUS_FINISHED) {
            result.push(season.subSeasons.group);
        }
        if (season.subSeasons.knockout && season.subSeasons.knockout.status !== STATUS_FINISHED) {
            result.push(season.subSeasons.knockout);
        }
        if (season.subSeasons.league && !result.includes(season.subSeasons.league)) {
            result.push(season.subSeasons.league);
        }
        if (season.subSeasons.group && !result.includes(season.subSeasons.group)) {
            result.push(season.subSeasons.group);
        }
        if (season.subSeasons.knockout && !result.includes(season.subSeasons.knockout)) {
            result.push(season.subSeasons.knockout);
        }
        for (const subSeason of Object.values(season.subSeasons)) {
            if (!result.includes(subSeason)) {
                result.push(subSeason);
            }
        }
    }
    return result;
}

export const factorial = n => !(n > 1) ? 1 : factorial(n - 1) * n;

export const shuffle = array=>{
    var currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

export const getRemainingTime = (timestamp) => {
    if (timestamp != null) {
        let seconds = (timestamp - new Date().valueOf()) / 1000;
        seconds -= (seconds % 1);

        let dayStr = parseInt((seconds - (seconds % 3600))/ 3600 / 24);
        let hourStr = parseInt((seconds - (seconds % 3600) - dayStr * 3600 * 24)/ 3600);
        let minStr = parseInt(((seconds - (seconds % 60))/ 60) % 60);
        
        if (dayStr > 0) {
            return dayStr + ' days ' + hourStr + ' hrs ';
        } else if (hourStr > 0) {
            return hourStr + ' hrs ' + minStr + ' mins';
        } else {
            return minStr + ' mins';
        }
    }
    return "Invalid Time";
}

export const getDurationString = (timestamp) => {
    if (timestamp != null) {
        let seconds = timestamp / 1000;
        seconds -= (seconds % 1);

        let secStr = seconds % 60;
        let minStr = ((seconds - (seconds % 60))/ 60) % 60;
        let hourStr = (seconds - (seconds % 3600))/ 3600;
        
        if (hourStr > 0) {
            return hourStr + ' hr ' + minStr + ' min ' + secStr + ' sec '
        } else {
            return minStr + ' min ' + secStr + ' sec '
        }
    }
    return "Invalid Time";
}

export const getTimeString = (timestamp) => {
    if (timestamp != null) {
        let seconds = timestamp / 1000;
        seconds -= (seconds % 1);

        let secStr = seconds % 60;
        let minStr = ((seconds - (seconds % 60))/ 60) % 60;
        let hourStr = (seconds - (seconds % 3600))/ 3600;
        
        if (hourStr > 0) {
            return format2digits(hourStr) + ':' + format2digits(minStr) + ':' + format2digits(secStr)
        } else {
            return format2digits(minStr) + ':' + format2digits(secStr)
        }
    }
    return "Invalid Time";
}

export const format2digits = (num, len = 2) => {
    return `${num}`.padStart(len, '0');
}

export const getEventOffset = (game, category, event) => {
    if (event.liveOffset && event.liveOffset >= 0) {
        return event.liveOffset
    }
    let roundArr = [];
    let roundData = game.roundData ? game.roundData : category.roundData;
    let roundChoice = {};
    for (const value of Object.values(roundData)) {
        if (event.roundId === value.id) {
            roundChoice = value;
        }
        roundArr.push(value);
    }
    const roundIdx = roundChoice && roundChoice.number ? roundChoice.number : 0;

    roundArr.sort(function(a, b) {
        return a.number - b.number;
    })
    let offset = 0;
    for (const round of roundArr) {
        offset += round.number < roundIdx ? (round.elapsed > 0 ? round.elapsed : (parseInt(round.time) * MIN_TIME)) : 0;
    }

    return offset + (event.roundTime ? event.roundTime : 0);
}

export const getOffsetRound = (game, category, time) => {
    
    let roundArr = [];
    let roundData = game.roundData ? game.roundData : category.roundData;
    for (const value of Object.values(roundData)) {
        roundArr.push(value);
    }
    let offset = 0;
    for (const round of roundArr) {
        offset += round.elapsed > 0 ? round.elapsed : (parseInt(round.time) * MIN_TIME);
        if (offset > time) {
            return round;
        }
    }

    return roundArr[0];
}

export const getScoreNumber = (category, score) => {
    if (score.number && score.number > 0) {
        return score.number;
    }
    if (category && category.scoreData) {
        for (const val of Object.values(category.scoreData)) {
            if (val.id === score.eventTypeId && val.number) {
                return val.number;
            }
        }
    }

    return 0;
}

export const getOrgProfileData = (organization, uid, profileData) => {
    if (profileData != null) {
        for (const val of Object.values(profileData)) {
            if (val.uid === uid && val.organizationId === organization.id) {
                return val;
            }
        }
    }
    return null;
}

export const getProfileData = (organization, users, uid, teamId, profileData) => {
    const result = {};
    if (uid != null) {
        const clubData = organization != null && 
        organization.teamData && 
        organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
    
        const userProfile = clubData.profileData != null && clubData.profileData[uid] ? clubData.profileData[uid] : {};
        let user = users[uid];
        if (!user) {
            user = {};
        }
        result.uid = uid;
        result.username = userProfile.nickname != null ? userProfile.nickname : user.username;
        result.icon = userProfile.photoUri != null ? userProfile.photoUri : (user.photoUri != null ? user.photoUri : defaultUserImage);
        result.number = '-';    
        if (userProfile.number != null) {
            result.number = userProfile.number;
        }
        result.birthday = -1;  
        result.position = '-';
        if (profileData != null) {
            for (const val of Object.values(profileData)) {
                if (val.uid === uid && val.organizationId === organization.id) {
                    if (val.birthday != null) {
                        result.birthday = val.birthday;
                    }
                    if (val.position != null) {
                        result.position = val.position;
                    }
                    result.verified = val.verified;
                }
            }
        }
    }

    return result;
}

export const getPlayerData = (organization, season, users, uid, teamId, profileData) => {
    const result = {};
    if (uid != null) {
        let clubData = season && 
        season.teamData && 
        season.teamData[teamId] != null ? season.teamData[teamId] : {};
        if (Object.values(clubData).length === 0) {
            clubData = organization && 
                organization.teamData && 
                organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        }
    
        const userProfile = clubData.profileData != null && clubData.profileData[uid] ? clubData.profileData[uid] : {};
        const user = users[uid];
        result.uid = uid;
        result.username = userProfile.nickname != null ? userProfile.nickname : user.username;
        result.icon = userProfile.photoUri != null ? userProfile.photoUri : (user.photoUri != null ? user.photoUri : defaultUserImage);
        result.number = '-';    
        if (userProfile.number != null) {
            result.number = userProfile.number;
        }
        result.birthday = -1;  
        result.position = '-';
        if (profileData != null) {
            for (const val of Object.values(profileData)) {
                if (val.uid === uid && val.organizationId === organization.id) {
                    if (val.birthday != null) {
                        result.birthday = val.birthday;
                    }
                    if (val.position != null) {
                        result.position = val.position;
                    }
                    result.verified = val.verified;
                }
            }
        }
    }

    return result;
}

export const getRefereeData = (organization, users, uid, teamId, refereeData) => {
    const result = {};
    if (uid != null) {
        const clubData = organization != null && 
        organization.teamData && 
        organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
    
        const userProfile = clubData.refereeData != null && clubData.refereeData[uid] ? clubData.refereeData[uid] : {};
        const user = users[uid];
        result.uid = uid;
        result.username = userProfile.nickname != null ? userProfile.nickname : user.username;
        result.icon = userProfile.photoUri != null ? userProfile.photoUri : (user.photoUri != null ? user.photoUri : defaultUserImage);
        result.number = '-';    
        if (userProfile.number != null) {
            result.number = userProfile.number;
        }
        result.birthday = -1;  
        result.position = '-';
        if (refereeData != null) {
            for (const val of Object.values(refereeData)) {
                if (val.uid === uid && val.teamId === teamId) {
                    if (val.birthday != null) {
                        result.birthday = val.birthday;
                    }
                    if (val.position != null) {
                        result.position = val.position;
                    }
                    result.verified = val.verified;
                }
            }
        }
    }

    return result;
}

export const getTeamData = (organization, teams, teamId) => {
    const result = {};

    const team = teams[teamId];
    const clubData = organization != null && 
    organization.teamData && 
    organization.teamData[teamId] != null ? organization.teamData[teamId] : {};

    result.title = clubData.nickname != null ? clubData.nickname : team.title;
    result.icon = clubData.thumbUri != null ? clubData.thumbUri : (team.iconUri != null ? team.iconUri : defaultTeamImage);

    return result;
}

export const checkGameList = (season, game, uids) => {
    const result = getGameUserList(season, game);

    for (const uid of result) {
        if (uids.includes(uid)) {
            return true;
        }
    }

    return false;
}

export const getGameUserList = (season, game) => {
    const result = [];

    const homeData = season != null && 
    season.teamData && 
    season.teamData[game.homeTeam] != null ? season.teamData[game.homeTeam] : {};
    const awayData = season != null && 
    season.teamData && 
    season.teamData[game.awayTeam] != null ? season.teamData[game.awayTeam] : {};

    if (game && game.gameList && game.gameList[game.homeTeam]) {
        for (const uid of Object.values(game.gameList[game.homeTeam])) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    } else if (homeData && homeData.gameList) {
        for (const uid of Object.values(homeData.gameList)) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    }
    if (game && game.gameList && game.gameList[game.awayTeam]) {
        for (const uid of Object.values(game.gameList[game.awayTeam])) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    } else if (awayData && awayData.gameList) {
        for (const uid of Object.values(awayData.gameList)) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    }
    if (game && game.benchList && game.benchList[game.homeTeam]) {
        for (const uid of Object.values(game.benchList[game.homeTeam])) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    } else if (homeData && homeData.benchList) {
        for (const uid of Object.values(homeData.benchList)) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    }
    if (game && game.benchList && game.benchList[game.awayTeam]) {
        for (const uid of Object.values(game.benchList[game.awayTeam])) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    } else if (awayData && awayData.benchList) {
        for (const uid of Object.values(awayData.benchList)) {
            if (!result.includes(uid)) {
                result.push(uid);
            }
        }
    }

    return result;
}
export const toTimeString = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return date.toLocaleTimeString();
    }
    return "Invalid Time";
}

export const timeAgo = (prevDate) => {
    const diff = new Date().valueOf() - prevDate;
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
        case diff < minute:
            const seconds = Math.round(diff / 1000);
             return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
        case diff < hour:
            return Math.round(diff / minute) + ' minutes ago';
        case diff < day:
            return Math.round(diff / hour) + ' hours ago';
        case diff < month:
            return Math.round(diff / day) + ' days ago';
        case diff < year:
            return Math.round(diff / month) + ' months ago';
        case diff > year:
            return Math.round(diff / year) + ' years ago';
        default:
            return "";
    }
};

export const toSimpleTime = (timestamp) => {
    if (timestamp != null) {
        let date = new Date(timestamp);
        return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    }
    return "Invalid Time";
}

export const toDateTimeString = (timestamp) => {
    if (timestamp == null) {
        return 'Invalid';
    }
    return toDateString(timestamp) + " " + toTimeString(timestamp);
}

export const toSimpleDateTimeString = (timestamp) => {
    if (timestamp == null) {
        return 'Invalid';
    }
    return toDateString(timestamp) + " " + toSimpleTime(timestamp);
}

export const getPlaybackUrl = (playbackId) => {
    if (playbackId == null) {
        return null;
    }
    return "https://stream.mux.com/" + playbackId + ".m3u8";
}

export const getWeekStartDayTime = (timestamp, firstDayOfWeekIndex = 0) => {
    let dateObject = new Date(timestamp);
    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(0,0,0,0);

    let result = firstDayOfWeek.valueOf();

    return result;
    
}

export const getWeekStartDay = (timestamp, firstDayOfWeekIndex = 0) => {
    let dateObject = new Date(timestamp);
    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    //firstDayOfWeek.setHours(0,0,0,0);

    return firstDayOfWeek.valueOf();
    
}

export const copyHour = (timestamp, toCopy) => {
    let dateObject = new Date(timestamp);
    let copyObject = new Date(toCopy);
    
    let offset = dateObject.getTimezoneOffset();
    let copyOffset = copyObject.getTimezoneOffset();

    return timestamp + (offset - copyOffset) * 60000;
    
}

export const getDayStart = (timestamp) => {
    let dateObject = new Date(timestamp);
    dateObject.setHours(0,0,0,0);

    return dateObject.valueOf();
}

export const sortByTitle = (array) => {
    array.sort(function(a, b) {
        if (a.title === b.title) 
            return 0;

        return a.title < b.title ? -1 : 1;
    });
}

export const sortByNumber = (array, field) => {
    array.sort(function(a, b) {
        let t1 = a[field] ? a[field] : 0;            
        let t2 = b[field] ? b[field] : 0;

        return t1 - t2;
    })
}

export const sortByNumberInverse = (array, field) => {
    array.sort(function(a, b) {
        let t1 = a[field] ? a[field] : 0;            
        let t2 = b[field] ? b[field] : 0;

        return t2 - t1;
    })
}

export const removeFromArr = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
}

export const removeArrIdx = (array, index) => {
    if (index > -1) {
        array.splice(index, 1);
    }
}

export const getLocationRecords = (submission) => {
    let result = [];
    if (submission.records != null) {
        let recordStrs = submission.records.split(',');
        for (let i = 0; i < recordStrs.length; i ++) {
            let str = recordStrs[i];
            let dataStrs = str.split(';');
            result.push({
                start_lat: dataStrs.length > 0 ? parseFloat(dataStrs[0]) : 0,
                start_lng: dataStrs.length > 1 ? parseFloat(dataStrs[1]) : 0,
                start_alt: dataStrs.length > 2 ? parseFloat(dataStrs[2]) : 0,
                end_lat: dataStrs.length > 3 ? parseFloat(dataStrs[3]) : 0,
                end_lng: dataStrs.length > 4 ? parseFloat(dataStrs[4]) : 0,
                end_alt: dataStrs.length > 5 ? parseFloat(dataStrs[5]) : 0,
                distance: dataStrs.length > 6 ? parseFloat(dataStrs[6]) : 0,
                timestamp: dataStrs.length > 7 ? parseInt(dataStrs[7]) : 0,
                timeDiff: dataStrs.length > 8 ? parseInt(dataStrs[8]) : 0,
                index: dataStrs.length > 9 ? parseInt(dataStrs[9]) : 0,
            });
        }
    }

    return result;
}

export const getSubSeasonTable = (division, subSeason) => {
    const pointTable = {};
    const winPoint = division.winPoint !== undefined ? division.winPoint : 3;
    const lossPoint = division.lossPoint !== undefined ? division.lossPoint : 0;
    const tiePoint = division.tiePoint !== undefined ? division.tiePoint : 0;
    const scoreData = division.category && division.category.scoreData ? division.category.scoreData : {};

    if (subSeason.teamIds !== undefined && subSeason.games !== undefined) {
        for (const teamId of Object.values(subSeason.teamIds)) {
            const teamItem = {
                id: teamId, 
                homeWin: 0, 
                homeLoss: 0, 
                awayWin: 0, 
                awayLoss: 0, 
                win: 0,
                loss: 0,
                draw: 0, 
                points: 0, 
                scores: 0, 
                antiScore: 0, 
                scoreDiff: 0,
                gamePlayed: 0,
            };
            for (const game of Object.values(subSeason.games)) {
                if (game.homeTeam === teamId || game.awayTeam === teamId) {
                    if (game.homeTeam === teamId) {
                        teamItem.homeWin += (game.winner === game.homeTeam ? 1 : 0);
                        teamItem.homeLoss += (game.winner === game.awayTeam ? 1 : 0);
                        teamItem.gamePlayed += (game.winner ? 1 : 0);
                    }
                    if (game.awayTeam === teamId) {
                        teamItem.awayLoss += (game.winner === game.homeTeam ? 1 : 0);
                        teamItem.awayWin += (game.winner === game.awayTeam ? 1 : 0);
                        teamItem.gamePlayed += (game.winner ? 1 : 0);
                    }
                    teamItem.draw += (game.winner === 'draw' ? 1 : 0);
                    if (game.scores !== undefined && game.scores !== null) {
                        for (const score of Object.values(game.scores)) {
                            let scoreVal = 1;
                            if (score.number) {
                                scoreVal = score.number;
                            }
                            if (!score.number && scoreData[game.eventTypeId] && scoreData[game.eventTypeId].number) {
                                scoreVal =  scoreData[game.eventTypeId].number;
                            }
                            if (score.teamId === teamId) {
                                teamItem.scores += scoreVal;
                            } else {
                                teamItem.antiScore += scoreVal;
                            }
                        }
                    }
                }
            }
            teamItem.win = teamItem.homeWin + teamItem.awayWin;
            teamItem.loss = teamItem.homeLoss + teamItem.awayLoss;
            teamItem.scoreDiff = teamItem.scores - teamItem.antiScore;

            teamItem.points = teamItem.win * winPoint + teamItem.draw * tiePoint + teamItem.loss * lossPoint;

            pointTable[teamId] = teamItem;
        }
    }
    
    return pointTable;
}

export const checkProfileData = (division, user, team, profileData) => {
    if (profileData != null) {
        for (const [, value] of Object.entries(profileData)) {
            if (value.uid === user.uid && division.organizationId === value.organizationId) {
                return value.verified != null ? (value.verified ? "verified" : "denied") : "nonReview";
            }
        }
    }
    return "noData";
}

export const renderRegisterStatus = (division, user, team, profileData, skipValid) => {
    const status = checkProfileData(division, user, team, profileData);

    switch (status) {
        case "denied":
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdError/> </span>
                    This Player's registration is denined by organization.
                </div>
            )
        case "diffTeam":
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdError/> </span>
                    This Player is registered as other team's player on this organization.
                </div>
            )
        case "nonReview":
            return (
                <div className='club-member-status'>
                    <span style={{color: heavyYellowColor, marginRight: 10}}><MdWarning/> </span>
                    This Player's registration is not reviewed by organization.
                </div>
            )
        case "noData":
            return (
                <div className='club-member-status'>
                    <span style={{color: heavyYellowColor, marginRight: 10}}><MdWarning/> </span>
                    This Player should provide registration form.
                </div>
            )
        default:
            if (skipValid) {
                return (
                    <div/>
                )
            } else {
                return (
                    <div className='club-member-status'>
                        <span style={{color: mainGreenColor, marginRight: 10}}><MdCheck/> </span>
                        This Player is ready.
                    </div>
                )
            }
    }
}

export const getRegisterStatusIcon = (status) => {
    switch (status) {
        case "verified":
            return verifiedIcon;
        case "denied":
            return deniedIcon;
        case "diffTeam":
            return nonIcon;
        case "nonReview":
            return pendingIcon;
        case "noData":
            return nonIcon;
        default:
            return verifiedIcon;
    }
}

export const checkMatches = (games, allowDouble, matchCount) => {
    for(let i = 0; i < games.length; i ++) {
        games[i].valid = true;
        games[i].overlapped = false;
        games[i].refereeOverlap = false;
    }
    for(let i = 0; i < games.length; i ++) {
        for (let j = i; j < games.length; j ++) {
            if (i !== j && games[j].locationId === games[i].locationId && games[j].start != null && games[i].start != null && (
                (games[j].start >= games[i].start && games[j].start <= games[i].end) ||
                (games[j].end >= games[i].start && games[j].end <= games[i].end)
                )) {
                games[j].overlapped = true;
                games[i].overlapped = true;
            }
            if (i !== j && games[j].refereeId === games[i].refereeId && games[j].refereeId != null &&
                games[j].start != null && games[i].start != null && (
                (games[j].start >= games[i].start && games[j].start <= games[i].end) ||
                (games[j].end >= games[i].start && games[j].end <= games[i].end)
                )) {
                games[j].refereeOverlap = true;
                games[i].refereeOverlap = true;
            }
            if (matchCount == null || matchCount === -1 ) {
                if (allowDouble) {
                    if (i !== j && games[j].homeTeam === games[i].homeTeam && games[j].awayTeam === games[i].awayTeam && games[j].homeTeam != null && games[j].awayTeam != null) {
                        games[j].valid = false;
                        games[i].valid = false;
                    }
                } else {
                    if (i !== j && 
                        (
                            (games[j].homeTeam === games[i].homeTeam && games[j].awayTeam === games[i].awayTeam && games[j].homeTeam != null && games[j].awayTeam != null) || 
                            (games[j].homeTeam === games[i].awayTeam && games[j].awayTeam === games[i].homeTeam && games[j].homeTeam != null && games[j].awayTeam != null)
                        )
                    ) {
                        games[j].valid = false;
                        games[i].valid = false;
                    }
                }
            }
        }
        if (matchCount != null && matchCount !== -1 ) {
            var count = 0;
            var awayCount = 0;
            for (let j = 0; j < games.length; j ++) {
                if (games[j].homeTeam === games[i].homeTeam || games[j].awayTeam === games[i].homeTeam) {
                    count += 1;
                }
                if (games[j].awayTeam === games[i].awayTeam || games[j].homeTeam === games[i].awayTeam) {
                    awayCount += 1;
                }
            }
            games[i].valid = count === matchCount;
            games[i].awayValid = awayCount === matchCount;
        }
    }
}

export const checkMatchesVaild = (games, allowDouble, matchCount) => {
    checkMatches(games, allowDouble, matchCount)
    for(let i = 0; i < games.length; i ++) {
        if (!games[i].valid || games[i].overlapped || games[i].refereeOverlap) {
            return false;
        }
    }
    return true;
}

export const initGameLineUp = (game, division) => {
    game.gameList = {};
    game.benchList = {};
    const homeData = division.teamData != null && division.teamData[game.homeTeam] ? division.teamData[game.homeTeam] : {};
    const awayData = division.teamData != null && division.teamData[game.awayTeam] ? division.teamData[game.awayTeam] : {};
    const homeGameList = homeData.gameList != null ? homeData.gameList : {};
    const awayGameList = awayData.gameList != null ? awayData.gameList : {};
    const homeBenchList = homeData.benchList != null ? homeData.benchList : {};
    const awayBenchList = awayData.benchList != null ? awayData.benchList : {};
    game.gameList[game.homeTeam] = homeGameList;
    game.gameList[game.awayTeam] = awayGameList;
    game.benchList[game.homeTeam] = homeBenchList;
    game.benchList[game.awayTeam] = awayBenchList;
}

export const getSortedArray = (array)=> {
    const toCopy = [];
    if (array != null) {
        for (var i = 0; i < array.length; i ++) {
            toCopy.push(array[i]);
        }
    }
    toCopy.sort(function(a, b) {return a - b});
    return toCopy;
}

export const checkYoutubeUrl = (url) => {
    if (url == null) {
        return false
    }
    if (url.startsWith('https://www.youtube.com/')) {
        return true
    }
    if (url.startsWith('https://www.youtu.be')) {
        return true
    }
    if (url.startsWith('https://youtube.com/')) {
        return true
    }
    if (url.startsWith('https://youtu.be')) {
        return true
    }
    return false
}

export const getSanctionGames = (season, cards, sanction) => {
    if (!season.subSeasons) {
        return [];
    }
    let subSeasons = [];
    if (season.type === SEASON_TYPE_LEAGUE) {
        subSeasons.push(season.subSeasons.league);
    } else if (season.type === SEASON_TYPE_TOURNAMENT) {
        subSeasons.push(season.subSeasons.group);
        subSeasons.push(season.subSeasons.knockout);
    } else if (season.type === SEASON_TYPE_PLAYOFF) {
        subSeasons.push(season.subSeasons.league);
        subSeasons.push(season.subSeasons.knockout);
    } else if (season.type === SEASON_TYPE_CUSTOM) {
        if (season.subSeasons.league) {
            subSeasons.push(season.subSeasons.league);
        }
        if (season.subSeasons.group) {
            subSeasons.push(season.subSeasons.group);
        }
        if (season.subSeasons.knockout) {
            subSeasons.push(season.subSeasons.knockout);
        }
        for (const subSeason of Object.values(season.subSeasons)) {
            if (subSeason.id !== ROUND_TYPE_LEAGUE && subSeason.id !== ROUND_TYPE_GROUP && subSeason.id !== ROUND_TYPE_KNOCKOUT) {
                subSeasons.push(subSeason);
            }
        }
    }

    let totalGames = [];

    for (const subSeason of subSeasons) {
        let games = subSeason.games ? Object.values(subSeason.games) : [];
        games.sort((a,b) => {
            return a.start - b.start;
        })
        for (const game of games) {
            if (game.homeTeam === sanction.teamId || game.awayTeam === sanction.teamId) {
                totalGames.push(game);
            }
        }
    }

    let result = [];
    if (sanction.type === "duration") {
        for (const game of totalGames) {
            if (sanction.startTime < game.start && game.start <= sanction.endTime) {
                result.push(game);
            }
        }
    } else {
        let lastGameIdx = -1;
        let cardIds = sanction.cardIds ? Object.values(sanction.cardIds) : [];
        let gameCount = sanction.gameCount ? sanction.gameCount : 0;
        let idx = 0;
        
        for (const game of totalGames) {
            for (const cardId of cardIds) {
                if (cards[cardId] && cards[cardId].gameId === game.id) {
                    lastGameIdx = idx;
                }
            }
            idx ++;
        }
        
        idx = 0;
        for (const game of totalGames) {
            if (lastGameIdx > -1 && idx > lastGameIdx && gameCount > result.length) {
                result.push(game);
            }
            idx ++;
        }
    }
    return result;
}

export const getSeasonStatusDict = (season) => {
    let str = "Register";
    let bgColor = '#FFD2D2';
    let fColor = '#FF6E6E';
    switch (season.status) {
        case null:
        case STATUS_REGISTER:
            str=t('register');
            bgColor='#FFD2D2';
            fColor='#FF6E6E';
            break;
        case STATUS_SCHEDULE:
            str=t('schedule');
            bgColor='#D0F5CC';
            fColor='#40D42E';
            break;
        case STATUS_ONGOING:
            str=t('ongoing');
            bgColor='#D0F5CC';
            fColor='#40D42E';
            break;
        case STATUS_FINISHED:
            str=t('finished');
            bgColor='#D3E0F1';
            fColor='#19447f';
            break;
        default:
            break;
    }

    return {
        str: str,
        bgColor: bgColor,
        fColor: fColor,
    }
}

export const getSanctionGameIds = (season, cards, uid, teamId) => {
    if (!season.subSeasons) {
        return [];
    }
    let subSeasons = [];
    if (season.type === SEASON_TYPE_LEAGUE) {
        subSeasons.push(season.subSeasons.league);
    } else if (season.type === SEASON_TYPE_TOURNAMENT) {
        subSeasons.push(season.subSeasons.group);
        subSeasons.push(season.subSeasons.knockout);
    } else if (season.type === SEASON_TYPE_PLAYOFF) {
        subSeasons.push(season.subSeasons.league);
        subSeasons.push(season.subSeasons.knockout);
    } else if (season.type === SEASON_TYPE_CUSTOM) {
        if (season.subSeasons.league) {
            subSeasons.push(season.subSeasons.league);
        }
        if (season.subSeasons.group) {
            subSeasons.push(season.subSeasons.group);
        }
        if (season.subSeasons.knockout) {
            subSeasons.push(season.subSeasons.knockout);
        }
        for (const subSeason of Object.values(season.subSeasons)) {
            if (subSeason.id !== ROUND_TYPE_LEAGUE && subSeason.id !== ROUND_TYPE_GROUP && subSeason.id !== ROUND_TYPE_KNOCKOUT) {
                subSeasons.push(subSeason);
            }
        }
    }

    let totalGames = [];

    for (const subSeason of subSeasons) {
        let games = subSeason.games ? Object.values(subSeason.games) : [];
        games.sort((a,b) => {
            return a.start - b.start;
        })
        for (const game of games) {
            if (game.homeTeam === teamId || game.awayTeam === teamId) {
                totalGames.push(game);
            }
        }
    }
    let gameIds = [];
    
    if (season.sanctions) {
        for (const sanction of Object.values(season.sanctions)) {
            if (sanction.teamId !== teamId || sanction.uid !== uid) {
                continue;
            }
            let result = [];
            if (sanction.type === "duration") {
                for (const game of totalGames) {
                    if (sanction.startTime < game.start && game.start <= sanction.endTime) {
                        if (!result.includes(game.id)) {result.push(game.id);}
                    }
                }
            } else {
                let lastGameIdx = -1;
                let cardIds = sanction.cardIds ? Object.values(sanction.cardIds) : [];
                let gameCount = sanction.gameCount ? sanction.gameCount : 0;
                let idx = 0;
                
                for (const game of totalGames) {
                    for (const cardId of cardIds) {
                        if (cards[cardId] && cards[cardId].gameId === game.id) {
                            lastGameIdx = idx;
                        }
                    }
                    idx ++;
                }
                
                idx = 0;
                for (const game of totalGames) {
                    if (lastGameIdx > -1 && idx > lastGameIdx && gameCount > result.length) {
                        if (!result.includes(game.id)) {result.push(game.id);}
                    }
                    idx ++;
                }
            }

            for (const id of result) {
                if (!gameIds.includes(id)) {gameIds.push(id);}
            }
        }
    }

    return gameIds;
}


export const getTitle = () => {
    const domain = window.location.origin;
    let title = "Live Teams";

    if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com")) {
        title = "Liga Siete Canada";
    }

    if (domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca")) {
        title = "Eagles F.C.";
    }
    if (domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
        title = "IVY LEAGUES";
    }
    
    return title;
}

export const getLogo = () => {
    const domain = window.location.origin;

    if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com")) {
        return canadaLogo;
    }

    if (domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca")) {
        return eaglesLogo;
    }
    if (domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
        return ivyLogo;
    }
    
    return liveLogo;
}

export const replaceUrl = (path) => {
    const domain = window.location.origin;
    let title = "Live Teams";

    if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com")) {
        title = "Liga Siete Canada";
    }

    if (domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca")) {
        title = "Eagles F.C.";
    }
    if (domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
        title = "IVY LEAGUES";
    }
    
    window.history.replaceState(null, title, path);
}

export const getOrgId = () => {
    const domain = window.location.origin;

    if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com")) {
        return "-Mf-n2lPLUKNoK2IY1u4";
    }

    if (domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca")) {
        return "-NIcKViTwLyPjpY49nrF";
    }

    if (domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
        return "-N0C576bf8NdboQ0abnd";
    }
    
    return null;
}

export const getTeamDomain = (team, path) => {
    
    let url = team.customDomain ? ('/club/' + team.customDomain) : ('/team/' + team.id);

    if (path) {
        url += path;
    }

    return url;
}

export const getOrgLocation = (props) => {
    const path = props.router.location.pathname;
    
    let location = 'home';
    if (path && path.includes('/services')) {
        location = 'services';
    } else if (path && path.includes('/championships')) {
        location = 'champions';
    } else if (path && path.includes('/programs')) {
        location = 'program';
    } else if (path && path.includes('/events')) {
        location = 'events';
    } else if (path && path.includes('/teams')) {
        location = 'teams';
    } else if (path && path.includes('/fund')) {
        location = 'fundraises';
    } else if (path && path.includes('/about')) {
        location = 'about';
    } else if (path && path.includes('/contact')) {
        location = 'contact';
    } else if (path && path.includes('/account')) {
        location = 'account';
    } else if (path && path.includes('/manager')) {
        location = 'manager';
    } else if (path && path.includes('/news')) {
        location = 'news';
    }

    return location;
}

export const getTeamLocation = (props) => {
    const path = props.router.location.pathname;
    
    let location = 'home';
    if (path && path.includes('/training')) {
        location = 'training';
    } else if (path && path.includes('/schedules')) {
        location = 'schedules';
    } else if (path && path.includes('/community')) {
        location = 'community';
    } else if (path && path.includes('/organizations')) {
        location = 'organizations';
    } else if (path && path.includes('/members')) {
        location = 'members';
    } else if (path && path.includes('/about')) {
        location = 'about';
    } else if (path && path.includes('/contact')) {
        location = 'contact';
    } else if (path && path.includes('/account')) {
        location = 'dashboard';
    } else if (path && path.includes('/coach')) {
        location = 'coach';
    }

    return location;
}

export const getOrgDomain = (organization) => {
    const domain = window.location.origin;

    if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com")) {
        return "";
    }

    if (domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca")) {
        return "";
    }
    

    if (domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
        return "";
    }
    
    return organization.customDomain ? ('/org/' + organization.customDomain) : ('/organization/' + organization.id);
}

export const getDivisionDomain = (organization, divisionId, seasonId) => {
    const pathname = window.location.pathname;
    const forDashboard = pathname && pathname.startsWith("/organizer");
    let path = "";
    if (!forDashboard && organization) {
        const home = getOrgDomain(organization);
        path = `${home}/manager/${divisionId}`;
    } else {
        path = `/organizer/divisions/${divisionId}`;
    }
    if (seasonId) {
        return `${path}/${seasonId}`;
    } else {
        return path;
    }
}

export const getProgramDomain = (organization, programId) => {
    const pathname = window.location.pathname;
    const forDashboard = pathname && pathname.startsWith("/organizer");
    
    if (!forDashboard && organization) {
        const home = getOrgDomain(organization);
        return `${home}/manager/programs/${programId}`;
    } else {
        return `/organizer/programs/${programId}`;
    }
}

export const getOrgData = (props) => {
    const domain = window.location.origin;
    if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com")) {
        return {organizationId: "-Mf-n2lPLUKNoK2IY1u4"};
    }
    if (domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca")) {
        return {organizationId: "-NIcKViTwLyPjpY49nrF"};
    }
    if (domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
        return {organizationId: "-N0C576bf8NdboQ0abnd"};
    }
    
    let id = '';
    let customDomain = null;
    if (props.router != null && props.router.params != null) {
        id = props.router.params.id;
        customDomain = props.router.params.customDomain;
    }
    return !customDomain ? {organizationId: id} : {customDomain: customDomain};
}

export const sortByUsername = (array) => {
    array.sort((a, b) => {
        if (a.username === b.username) 
            return 0;

        return a.username < b.username ? -1 : 1;
    });
}

export const getMembershipPlan = (subscription, memberships) => {

    if (subscription != null && subscription.product != null) {
        for (const item of memberships) {
            if (subscription.product.id === item.id && item.name != null) {
                return item;
            }
        }
    }
    return null;
}

export const getYoutubeId = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length === 11)? match[7] : false;
}

export const getYoutubeThumbnail = (post) => {
    if (!post.youtubeId && !post.youtube) {
        return null;
    }
    const youtubeId = post.youtubeId ? post.youtubeId : getYoutubeId(post.youtube);
    return "https://img.youtube.com/vi/"+youtubeId+"/mqdefault.jpg"; 
}

export const getSeasonPoints = (season, teamId) => {
    let result = 0;
    if (season && season.subSeasons)  {
        for (const subSeason of Object.values(season.subSeasons)) {
            if (subSeason.games) {
                for (const game of Object.values(subSeason.games)) {
                    if (game.winner === teamId) {
                        result += 3;
                    } else if ((game.homeTeam === teamId || game.awayTeam === teamId) && game.homeScore === game.awayScore) {
                        result += 1;
                    }
                }
            }
        }
    }
    return result;
}

export const getOrganizationPoints = (organization, seasons, filter, teamId) => {
    let result = 0;
    if (seasons) {
        for (const season of Object.values(seasons)) {
            if (organization && organization.id === season.organizationId) {
                if (!filter || !filter.seasonId || filter.seasonId === season.id) {
                    result += getSeasonPoints(season, teamId);
                }
            }
        }
    }
    return result;
}

export const getSeasonTeamStatistics = (team, season) => {
    let gameCount = 0;
    let played = 0;
    let goals = 0;
    let assists = 0;
    let wins = 0;
    let ties = 0;
    let losses = 0;
    let yellow = 0;
    let red = 0;

    if (season.subSeasons != null) {
        for (const sub of Object.values(season.subSeasons)) {
            if (sub.games != null) {
                for (const game of Object.values(sub.games)) {
                    if (game.homeTeam === team.id || game.awayTeam === team.id) {
                        gameCount += 1;
                        if (game.winner === team.id) {
                            played ++;
                            wins += game.winner === team.id ? 1 : 0;
                        } else if (game.winner === 'draw') {
                            played ++;
                            ties += game.winner === 'draw' ? 1 : 0;
                        } else {
                            played ++;
                            losses += game.winner === team.id ? 1 : 0;
                        }
                        if (game.scores) {
                            for (const item of Object.values(game.scores)) {
                                if (item.teamId === team.id) {
                                    goals += item.number ? item.number : 1;
                                    if (item.assist) {
                                        assists += item.number ? item.number : 1;
                                    }
                                }
                            }
                        }
                        if (game.fouls) {
                            for (const item of Object.values(game.fouls)) {
                                if (item.teamId === team.id) {
                                    if (item.level === "yellow") {
                                        yellow += 1;
                                    } else if (item.level === "red") {
                                        red += 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    return {
        id: team.id,
        title: team.title,
        gameCount: gameCount,
        played: played,
        goals: goals,
        assists: assists,
        wins: wins,
        ties: ties,
        losses: losses,
        yellow: yellow,
        red: red,
    }
}

export const getOrganizationTeamStatistics = (organization, seasons, filter, team) => {
    const result = {
        id: team.id,
        title: team.title,
        gameCount: 0,
        played: 0,
        goals: 0,
        assists: 0,
        wins: 0,
        ties: 0,
        losses: 0,
        yellow: 0,
        red: 0,
    };
    if (seasons) {
        for (const season of Object.values(seasons)) {
            if (organization && organization.id === season.organizationId) {
                if (!filter || !filter.seasonId || filter.seasonId === season.id) {
                    let stats = getSeasonTeamStatistics(team, season);
                    
                    result.gameCount += stats.gameCount;
                    result.played += stats.played;
                    result.goals += stats.goals;
                    result.assists += stats.assists;
                    result.wins += stats.wins;
                    result.ties += stats.ties;
                    result.losses += stats.losses;
                    result.yellow += stats.yellow;
                    result.red += stats.red;
                }
            }
        }
    }
    return result;
}
