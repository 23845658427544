import React, { Component } from "react";
import {
    Card, CardBody, Button, Row, Col, CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import UserBadge from 'components/detail/UserBadge';
//import attCanceled from 'assets/img/icons/attendance_canceled.png';
import attGoing from 'assets/img/icons/attendance_going.png';
import attMaybe from 'assets/img/icons/attendance_maybe.png';
import attNotGoing from 'assets/img/icons/attendance_not_going.png';
import attPending from 'assets/img/icons/attendance_pending.png';

import { toTimeString } from 'utils/Utils';

import {
    MdAccessTime,
    MdLocationOn
} from 'react-icons/md';

export class ClubScheduleDetailView extends Component {
    renderMembers() {
        const {users, clubSchedule} = this.props;

        let result = [];
        if (clubSchedule.members != null) {
            let availability = clubSchedule.availability != null ? clubSchedule.availability : {};
            for (const [key,] of Object.entries(clubSchedule.members)) {
                let member = users[key];
                if (member != null) {

                    result.push(
                        this.renderMember(member, availability[key])
                    )
                }
            }
        }

        return result;
    }

    renderMember(member, availablity) {
        var icon;
        var title;
        switch (availablity) {
            case 0:
                icon = attPending;
                title = "Pending";
                break;
            case 1:
                icon = attMaybe;
                title = "Maybe";
                break;
            case 2:
                icon = attGoing;
                title = "Going";
                break;
            case 3:
                icon = attNotGoing;
                title = "Not Coming";
                break;
            default:
                icon = attPending;
                title = "Pending";
                break;
        }

        return(
            <Row>
                <Col sm={8} xs={8}>
                    <UserBadge customClass='wrap-content-wrap-child' user={member}/>
                </Col>
                <Col sm={4} xs={4}>
                    <CardImg
                        className="profile-thumb"
                        src={icon}
                        style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{title}</div>
                </Col>
            </Row>
        )
    }

    render() {
        const {onCloseClick, club, clubSchedule} = this.props;
        const icon = club.thumbUri != null ? club.thumbUri : defaultImage;
        
        const date = new Date(clubSchedule.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();

        const from = toTimeString(clubSchedule.timestamp);
        const to = toTimeString(clubSchedule.deadline);
        
        if (clubSchedule != null) {
            return (
                <div>
                    <Card className="flex-row shadow-tile">
                        <div style={{
                            width: 90, 
                            height: 90, 
                            marginLeft: 20, 
                            marginTop: 15, 
                            borderRadius: 10,
                            backgroundColor: '#F6F7FB'}}>
                            <div style={{
                                fontSize: 40,
                                fontWeight: 1000,
                                textAlign: 'center',
                                color: '#788492',
                            }}>
                                {day}
                            </div>
                            <div style={{
                                fontSize: 20,
                                fontWeight: 600,
                                textAlign: 'center',
                                color: '#B7BFC8',
                                marginTop: -5
                            }}>
                                {month}
                            </div>
                        </div>
                        <CardBody>
                            <h4 className='label label-default'>
                            {clubSchedule.title}
                            </h4>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            <MdAccessTime/> {from} {" ~ "} {to} <MdLocationOn/> {"Health Room"}
                            </div>
                            <div className="challenge-progress wrap-content-wrap-child">
                                <img alt="icon" src={icon} style={{width: 20, height: 20, marginRight: 10}} />
                                {club.title}
                            </div>
                        </CardBody>
                    </Card>

                    <div style={{marginTop: 10, marginBottom: 10}}>
                        {this.renderMembers()}
                    </div>
                    {onCloseClick && (
                    <Button className='btn-cancel' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubScheduleDetailView);