import React, { Component } from "react";
import {
    CardImg, Button, Col, Row
} from 'reactstrap';
import {
    getProfileData,
} from 'utils/Utils';

import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';

export class OrganizationUserRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, profileData, users, onClickDetail } = this.props;
        const userData = getProfileData(organization, users, profileData.uid, profileData.teamId);
        
        if (organization != null) {
            return (
                <Row>
                    <Col lg={8} xs={7} sm={8} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={userData.icon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {profileData.playerName}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="row-badge-icon-small" style={{margin: 'auto'}}
                                src={profileData.verified != null ? (profileData.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                        </div>
                    </Col>
                    <Col lg={3} xs={3} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                    if (onClickDetail != null) {
                                        onClickDetail();
                                    }
                                }}>
                                View
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default OrganizationUserRow;