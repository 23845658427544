import React, { Component } from 'react';
import { CardImg, Button, Modal, ModalBody, Input, Spinner } from 'reactstrap';
import SecurityCodeBadge from 'components/detail/SecurityCodeBadge';
import SecurityCodeEdit from 'components/detail/SecurityCodeEdit';

import homeIcon from 'assets/img/icons/home_icon.png';
import { invokeHttpsApi, readDb } from 'utils/API';

export default class AdminSecurity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            securityCode: {},
            editOpen: false,
            progress: false,
            detailOpen: false
        };
    }

    componentDidMount() {
        readDb('securityCode', ((data)=>{
            var securityCode = {};
            if (data != null) {
                for (const [key, value] of Object.entries(data)) {
                    securityCode[key] = value;
                }

            }
            
            this.setState({
                securityCode: securityCode,
            });
        }))
    }

    submitUserTransfer() {
        const {userId, targetId} = this.state;

        if (userId && targetId) {

            this.setState({progress: true});
            invokeHttpsApi('admin-changeUserOwnership', {
                replaceUid: userId,
                targetId: targetId,
            }, ((data)=> {
                if (data && data.success) {
                    alert("Transfer success.");
                    this.setState({progress: false});
                } else {
                    alert("Transfer failed");
                    this.setState({progress: false});
                }
            }), ((error)=> {
                console.log('saveContents error', error);
                alert(error);
                this.setState({domainProgress: false});
            }))

            /*
            this.setState({progress: true});
            invokeHttpsApi('admin-changeOrganizationId', {
                replaceId: userId,
                targetId: targetId,
            }, ((data)=> {
                if (data && data.success) {
                    alert("Transfer success.");
                    this.setState({progress: false});
                } else {
                    alert("Transfer failed");
                    this.setState({progress: false});
                }
            }), ((error)=> {
                console.log('saveContents error', error);
                alert(error);
                this.setState({domainProgress: false});
            }))*/
        } else {
            alert('You should input valid user ids.');
        }
    }

    checkUserNumbers() {
        this.setState({progress: true});
        invokeHttpsApi('admin-checkUserNumbers', {}, ((data)=> {
            if (data && data.success) {
                alert("Transfer success.");
                this.setState({progress: false});
            } else {
                alert("Transfer failed");
                this.setState({progress: false});
            }
        }), ((error)=> {
            console.log('saveContents error', error);
            alert(error);
            this.setState({domainProgress: false});
        }))
    }

    render() {
        const {securityCode, codeChoice, editOpen, userId, targetId, progress} = this.state;

        return (
            <div>
                <div className='wrap-content-fill-child main-page'>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <div className='wrap-content-fill-child'>
                            <div className='wrap-content-parent'>
                                <CardImg
                                    className="profile-thumb"
                                    src={homeIcon}
                                    style={{ width: 30, height: 30, marginRight: 10 }}
                                    />
                                <h3 className='label label-default title-action-main wrap-content-fill-child'>
                                Security Code
                                </h3>
                            </div>
                        </div>
                        <Button onClick={e=>{
                            this.setState({
                                codeChoice: {},
                                editOpen: true
                            })
                        }}>+</Button>
                    </div>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <h4 className='wrap-content-wrap-child'>Tip:</h4>
                        <div className='wrap-content-fill-child'>
                        On this page you will upload all the securityCode that will be used in playlists, events and promotional video of events. All video files
                        must be MP4 format in order to workout on the platform.
                        </div>
                    </div>
                    <div>
                        {Object.values(securityCode).map((code) => (
                            <SecurityCodeBadge 
                                code={code}
                                onClickDelete={() => {
                                    this.setState({
                                        codeChoice: code,
                                        detailOpen: true
                                    })
                                }}
                                onClickEdit={() => {
                                    this.setState({
                                        codeChoice: code,
                                        editOpen: true
                                    })
                                }} />
                        ))}
                    </div>
                </div>
                <div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>User Id </b> | User to get rid of
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                this.setState({userId: e.target.value});
                            }} value={userId} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Target Id </b> | User to transfer
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                this.setState({targetId: e.target.value});
                            }} value={targetId} />
                        </div>
                    </div>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                            
                        </h4>
                        
                        {progress ? (
                            <Spinner size="nm" color="primary" />
                        ) : (
                            <div style={{textAlign: 'end'}}>
                                <Button className='btn-submit' onClick={e => {
                                    this.submitUserTransfer();
                                }}>Transfer</Button>
                            </div>
                        )}
                    </div>
                    {/* <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                            
                        </h4>
                        
                        {progress ? (
                            <Spinner size="nm" color="primary" />
                        ) : (
                            <div style={{textAlign: 'end'}}>
                                <Button className='btn-submit' onClick={e => {
                                    this.checkUserNumbers();
                                }}>Generate User Numbers</Button>
                            </div>
                        )}
                    </div> */}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <SecurityCodeEdit 
                            video={codeChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}