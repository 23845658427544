export const translationEn = {
    "app_name": "Live",
    "home": "Home",
    "organizations": "Organizations",
    "pricing": "Pricing",
    "features": "Features",
    "admin": "Admin",
    "dashboard": "Dashboard",
    "view": "View",
    "login": "Login",
    "logout": "Logout",
    "sign_up": "Sign Up",
    "player_app_download_prompt": "You should download mobile app to be a player on this platform, do you wnat to go to download?",
    "username_empty": "Username is empty.",
    "email_empty": "Email is empty.",
    "password_empty": "Password is empty.",
    "password_not_match": "Password does not match.",
    "remove": "Remove",
    "coach": "Coach",
    "organizer": "Organizer",
    "players": "Players",
    "player": "Player",
    "staff": "Staff",
    "user": "User",
    "foul": "Foul",
    "age": "Age",
    "wins": "Wins",
    "ties": "Ties",
    "losses": "Losses",
    "position": "Position",
    "elig": "Elig",
    "no_data": "No Data",
    "full_name": "Full Name",
    "your_name": "Your Name",
    "email": "Email",
    "password": "Password",
    "confirm_password": "Confirm Password",
    "confirm_your_password": "Confirm your password",
    "agree_policy": "Agree the terms and policy",
    "team_chat": "Team Chat",
    "chat_room": "Chat Room",
    "input_message_hint": "Input Message To Send",
    "send": "Send",
    "read_more": "Read More",
    "add": "Add",
    "delete": "Delete",
    "edit": "Edit",
    "detail": "Detail",
    "details": "Details",
    "create": "Create",
    "close": "Close",
    "summary": "Summary",
    "lineups": "Lineups",
    "lineup": "Lineup",
    "members": "Members",
    "substitute": "Substitute",
    "roster": "Roster",
    "to_roster": "To Roster",
    "to_lineup": "To Lineup",
    "to_sub": "To Sub",
    "available_players": "Available Players",
    "not_available_players": "Not Available Players",
    "challenges": "Challenges",
    "review": "Review",
    "blocked_landing_message": "Organization Blocked Landing Page",
    "game_members": "Game Members",
    "substitutes_members": "Substitutes Members",
    "players_ready": "Players Ready",
    "other_players": "Other Players",
    "pick_as_player": "Pick As Player",
    "pick_as_substitute": "Pick As Substitute",
    "remove_from_game": "Remove from game list",
    "remove_from_substitute": "Remove from substitutes",
    "division_default_lineup": "Division Default Lineup",
    "starting_lineup": "Starting Lineup",
    "season_history": "Season History",
    "division_name": "Division name",
    "season": "Season",
    "standings": "Standings",
    "statistics": "Statistics",
    "sanctions": "Sanctions",
    "cards": "Cards",
    "instructions": "Instructions",
    "overview": "Overview",
    "game_on": "Game on",
    "games": "Games",
    "pos": "Pos",
    "reg": "Reg",
    "status": "Status",
    "goals": "Goals",
    "generate": "Generate",
    "top_socorer": "Top Socorer",
    "assists": "Assists",
    "final": "Final",
    "final_3_4": "Final 3rd-4th",
    "not_set": "Not Set",
    "top_assist": "Top Assist",
    "average_rating": "Average Rating",
    "no_teams": "No Teams",
    "no_teams_selected": "No Teams Selected",
    "player_number": "Player Number",
    "player_name": "Player Name",
    "home_location": "Home Location",
    "no_home_location": "No Home Location",
    "teams_registered": "Teams Registered",
    "players_registered": "Players Registered",
    "match_settings": "Match Settings",
    "team_settings": "Team Settings",
    "player_profile_data": "Player Profile Data",
    "completed": "Completed",
    "player_list_needed": "You have to fill out player list for this game.",
    "players_waiting_verified": "You have players not verified by organization on player list.",
    "game_change_not_allowed": "You can not change schedule of this game.",
    "saved_successfully": "Saved Successfully",
    "save_failed": "Save failed. Try Again Later",
    "request_failed": "Request failed. Try Again Later.",
    "request_success": "Requested Successfully",
    "no_referee": "No Referee",
    "referee": "Referee",
    "pick_referee": "Pick Referee",
    "referee_choice": "Referee Choice",
    "game_sheet_doc": "Game Sheet Doc",
    "game_info_edit": "Game Information Edit",
    "highlights": "Highlights",
    "team_post": "Team Post",
    "organization_post": "Organization Post",
    "news_feed": "News Feed",
    "end": "End",
    "start": "Start",
    "day_index": "Day Index",
    "assist_referee": "Assist Referees",
    "no_assist_referee": "No Assist Referees",
    "timeline_overlap": "Timeline is overlapped.",
    "referee_overlap": "Referee schedule is overlapped.",
    "home_invalid_count": "Home Team has not valid match count",
    "away_invalid_count": "Away Team has not valid match count",
    "home_away_duplicated": "You have duplicated home and away matches.",
    "event_type": "Event Type",
    "player_choice": "Player Choice",
    "pick_event_type": "You should pick event type",
    "pick_player_prompt": "You should pick player",
    "score_type": "Score Type",
    "pick_score_type": "You should pick score type",
    "foul_type": "Foul Type",
    "pick_foul_type": "You should pick foul type",
    "pick_foul_level": "You should pick foul level",
    "yellow_card": "Yellow Card",
    "red_card": "Red Card",
    "substitute_choice": "Substitute Choice",
    "sunday": "Sunday",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "deploy": "Deploy",
    "group_games_deployment": "Group Games Deployment",
    "week_games_deployment": "Weekly based Games Deployment",
    "location_games_deployment": "Games Deployment, Division Locations",
    "double_game_allow": "Allow Double Games for every team.",
    "teams": "Teams",
    "pick": "Pick",
    "picked": "Picked",
    "total": "Total",
    "season_winners": "Season Winners",
    "clear_redeploy": "Clear & Redeploy",
    "round_games": "Round Games",
    "knockout_start_teams": "Knockout Start Teams",
    "league_settings": "Your league season is under ongoing status. You can edit final ranking of the league teams here.",
    "tournament_settings": "Your tournament season is under ongoing status. You can edit group rankings and knockout game start teams here.",
    "playoff_settings": "Your playoff season is under ongoing status. You can edit league rankings and knockout game start teams here.",
    "custom_default_settings": "Your custom season is under ongoing status. You can edit ranking of qualification round and knockout game start teams here.",
    "custom_deploy_settings": "Here we list down custom rounds you added. You can either redeploy existing custom round or create a new one",
    "weekly_based": "Weekly based",
    "location_based": "Location Based",
    "winner_team_count": "Winner Team Count",
    "deploy_type": "Deploy Type",
    "address": "Address",
    "managers": "Managers",
    "title": "Title",
    "description": "Description",
    "no_events": "No Events",
    "mom_label": "Player of the Match",
    "referee_feedback": "Referee Feedback",
    "winner": "Winner",
    "winner_description": "Winner Description",
    "lost_description": "Lost Description",
    "seasons": "Seasons",
    "import": "Import",
    "locations": "Locations",
    "schedules": "Schedules",
    "attendance": "Attendance",
    "player_list_not_ready": "Player List for this game not ready",
    "player_list_not_verified": "Non verified players on player list",
    "for_both_of_teams": "for both of teams",
    "matchday": "Matchday",
    "all_matchdays": "All Matchdays",
    "choose_round": "Choose a Round",
    "season_rounds": "Season Rounds",
    "visible_teams": "Visible Teams",
    "search_by_team": "Search by team title",
    "choose_season": "Choose a Season",
    "go_login": "Go To Login",
    "team_fee": "Team Registration Fee",
    "reg_until": "Registration until",
    "team_reg_section": "Team registration section",
    "create_team": "Create Your Team",
    "player_reg_section": "Player registration section",
    "player_fee": "Player Registration Fee",
    "staff_fee": "Staff Registration Fee",
    "roster_reg": "Roster Registration",
    "method": "Method",
    "note": "Note",
    "pay_on_stripe": "Pay Direct On Stripe",
    "pay_on_wallet": "Pay on Wallet",
    "update_request": "Update Request",
    "pay_in_person": "Pay in Person",
    "choose_payment_method": "Choose Payment Method",
    "cash": "Cash",
    "online": "Online",
    "e_transfer": "eTransfer",
    "assigned_team_directly": "Assigned as Team Member by season owner directly",
    "enrolled": "Enrolled",
    "view_profile": "View Profile",
    "submit_profile": "Submit Profile",
    "show_policy": "Show Policy",
    "ack_policy": "Acknowlegde Policy",
    "enroll": "Enroll",
    "choose_enroll_type": "Choose Enroll Type",
    "ack_agree": "Acknowlegded and Agreed",
    "reg_pay_card": "You can pay now with credit card and will be added as soon as payment is completed.",
    "reg_pay_wallet": "You can pay now with money from your wallet balance.",
    "reg_pending_organizer": "Your registration will be submitted and pending till approved by organizer.",
    "team_reg_text_1": "1- By Registering you will be added as a team member of the organization.",
    "team_reg_text_2": "2- Once accepted provide roster and game detault lineup under settings tab.",
    "team_reg_text_3": "3- Every player must submit registration form to be eligible.",
    "trophy": "Trophy",
    "privacy_policy": "Privacy Policy",
    "agree": "Agree",
    "decline": "Decline",
    "choose_method_prompt": "You should choose method",
    "choose_method_role": "You should choose role",
    "top_red": "Red Cards Ranking",
    "user_type_column": "User Type Column",
    "user_attendance_day": "User Form on Attendance Day",
    "user_to_assign": "User To Assign",
    "cancel": "Cancel",
    "request": "Request",
    "finished": "Finished",
    "verified": "Verified",
    "rejected": "Rejected",
    "championships": "Championships",
    "registers": "Registers",
    "all_events": "All Events",
    "today_event": "Today's Events",
    "upcoming_events": "Upcoming Events",
    "back": "Back",
    "general": "General",
    "standing": "Standing",
    "score": "Score",
    "assist": "Assist",
    "referee_forms": "Referee Forms",
    "reg_date": "Registration Date",
    "start_date": "Start Date",
    "end_date": "End Date",
    "gender": "Gender",
    "soccer": "Soccer",
    "sport": "Sport",
    "age_group": "Age Group",
    "age_range": "Age Range",

    // 22nd Nov
    "my_teams": "My Teams",
    "membership": "Membership",
    "managing": "Managing",
    "library": "Library",
    "tracks": "Tracks",
    "events": "Events",
    "setup": "Setup",
    "go_to_dashboard": "Go To Dashboard",
    "contact_us_items": "Contact Us Items",
    "contact_us": "Contact Us",
    "contact_us_title": "Contact Us Section Title",
    "training": "Training",
    "community": "Community",
    "cardio_set": "Cardio Set",
    "no_items_to_show": "No Items To Show",
    "competitions": "Competitions",
    "workout": "Workout",
    "workouts": "Workouts",
    "manage_contents": "Manage contents",
    "not_logged_in": "Not Logged In",
    "no_submission": "No Submission",
    "no_review": "No Review",
    "active": "Active",
    "landing_page": "Landing Page",
    "official_site": "Official Site",
    "email_address": "Email address",
    "email_text": "Email text",
    "copyright_text": "Copyright 2023. All Rights Reserved.",
    "games_played": "Games Played",
    "scores": "Scores",
    "player_card": "Player Card",
    "no_bio": "No Biography",
    "scores_label": "Goals/Points",
    "programs": "Programs",
    "org_register_title": "Would you like to register?",
    "org_login_title": "Would you like to login?",
    "register": "Register",
    "my_account": "My Account",
    "my_family": "My Family",
    "team_not_allowed": "This page is not allowed.",
    "change_login_prompt": "You need to login to correct user credential with email ",
    "merge_user_profile_msg": "You were invited to assign this team originated user profile's data on this platform to your own profile.",
    "merge_child_profile_msg": "You were invited to assign this team originated user profile's data on this platform to as your child.",
    "user_to_assign_as_child": "User to assign as your child",
    "merge_confirm_prompt": "Are you sure you want to accept this invite to following user?",
    "accept_invite": "Accept Invite",
    "accept_merge": "Merge Profile with",
    "accept_merge_child": "Accept as a new child",
    "accpet_invite_prompt": "Are you sure you want to accept this invite?",
    "coach_merge_msg": "You were invited to be assigned as a coach of this team",
    "player_merge_msg": "You were invited to be assigned as a player of this team",
    "coaches": "Coaches",
    "requested_to_join": "Requested to join",
    "join": "Join",
    "bio": "Biography",
    "country": "Country",
    "phone_number": "Phone Number",
    "female": "Female",
    "male": "Male",
    "coed": "Coed",
    "no_choice": "No Choice",
    "weight": "Weight (kg)",
    "birthday": "Day of Birth",
    "profile_settings": "Profile Settings",
    "no_gender": "No Gender Set",
    "no_country": "No Country Set",
    "no_weight": "No Weight Set",
    "no_birthday": "No Birthday Set",
    "schedule": "Schedule",
    "settings": "Settings",
    "to_do_title": "To do List",
    "to_do_team_pending": "Team Requests Pending",
    "to_do_team_today_schedule": "Today's schedule",
    "to_do_team_training": "Team Training",
    "to_do_team_profile": "Profiles to upload",
    "roster_doc": "Roster Doc",
    "document": "Document",
    "no_results": "No Results",
    "limit": "Limit",
    "no_user_for_email": "No user for this email yet",
    "offline_invited": "Offline Invited",
    "member": "Member",
    "users": "Users",
    "save": "Save",
    "accept": "Accept",
    "reject": "Reject",
    "invites": "Invites",
    "team": "Team",
    "invite_email": "Invite Email",
    "invite_email_assign": "Send Invite to assign",
    "invite_email_child": "Send Invite to parent",
    "roster_email": "Send roster email",
    "roster_email_text": "You have been rostered to {team_title} on {season_title} season.\nTo Check details of season, please visit following link",
    "email_sent": "Email sent successfully",
    "email_sending_failed": "Sending email failed.",
    "forgot_password": "Forgot password",
    "forgot_password_success": "Sent password reset email.",
    "about_us": "About Us",
    "invite": "Invite",
    "send_email_invite_prompt": "Are you sure you want to send an invite email to this one?",
    "to_coach": "To Coach",
    "no_profile_prompt": "This player did not submitted profile data. On this season only players has profile data can be added to roster and lineup",
    "premium": "Premium",
    "freemium": "Freemium",
    "toggle_team_premium_prompt": "Are you sure you want to toggle premium status?",
    "club_membership_prompt": "You should purchase coach membership.",
    "submited_successfully": "Change Submitted Successfully",
    "submit_failed": "Submiting change failed. Try Again Later",
    "duration_min": "Duration (min)",
    "repeat": "Repeat",
    "everyone": "Everyone",
    "start_time": "Start Time",
    "end_time": "End Time",
    "invite_register_email_sent": "An invitation email to resiger and join this team has been sent.",
    "invite_email_sent": "An invitation email to join this team has been sent.",
    "reject_invite": "Reject Request",
    "coach_panel": "Coach Panel",
    "owned_teams": "Owned teams",
    "managing_teams": "Managing teams",
    "fundraises": "Fundraisers",
    "fundraise": "Fundraiser",
    "donate": "Donate",
    "collected": "Collected",
    "goal": "Goal",
    "donated": "Donated",
    "bingo": "Bingo",
    "price": "Price",
    "go_to": "Go to",
    "invalid_price_prompt": "You should input valid price.",
    "invalid_bingo_prompt": "You should fill out bingo numbers",
    "collected_of_goal": "raised of",
    "donations": "Donations",
    "price_to_donate": "Enter your donation",
    "donate_now": "Donate Now",
    "register_donate": "Register Donate in Cash",
    "donate_min_price_prompt": "Minimum amount of donation is $10",
    "no_donate": "No Donation Yet",
    "see_all": "Show all",
    "see_less": "Show less",
    "matching": "Matching",
    "your_number": "Your Number",
    "games_products": "Games and products",
    "match": "Match",
    "username": "Username",
    "number": "Number",
    "donates": "Donates",
    "invalid_bingo_num": "You should fill out all numbers.",
    "bingo_num_summary": "You should press number button and pick one of the numbers to fill out all numbers.",
    "finish_registration": "Finish Registration",
    "finish_registration_prompt": "Finishing registration will block any more users donate the bingo and start choosing the bingo number. Are you sure you want to continue to next step?",
    "bingo_number": "Bingo Number",
    "bingo_details": "Bingo Details",
    "bingo_num_invalid": "You should fill out all bingo numbers",
    "finish_bingo_prompt": "Are you sure you want to finish bingo?",
    "finish_bingo": "Finish Bingo Game",
    "bingo_price": "Bingo Price",
    "bingo_to_fundraise": "Fundraise Money",
    "fund_waiting_to_accept": "Donate in cash pending",
    "leaderboard": "Leaderboard",
    "joined": "Joined",
    "donate_request_success": "Your request for donate has been submitted successfully. Fundraiser will acknowledge your request.",
    "donate_requested": "In Cash Donation Pending",
    "bingo_ongoing_summary": "Bingo Draw is ongoing. Current numbers progressed is as below",
    "bingo_finished_summary": "All Bingo numbers are decided. You can ceck your numbers and matches",
    "your_matches": "Your matches",
    "no_matches": "You don't have any matches for this bingo game.",
    "product_price": "Product Price",
    "product": "Product",
    "product_details": "Product Details",
    "purchased_product": "Purchased product",
    "login_needed": "You need to login first.",
    "donate_sponser_switch": "I am a sponsor!",
    "spnser_info": "Sponsor Fields",
    "spnser_info_needed": "You must fill out fields for sponsor information",
    "name": "Name",
    "quantity": "Quantity",
    "redirect_to_checkout": "Redirecting To Checkout Session ...",
    "my_page": "Home Page",
    "wallets": "Wallets",
    "edit_profile": "Edit Profile",
    "assign_direct": "Assign Directly",
    "assign_direct_prompt": "Are you sure you want to assign this player directly? Assign Fee will go from player's wallet and balance can be negative if the player has empty wallet.",
    "pick_post_category": "Pick Post Category",
    "change_category": "Change Category",
    "phone": "Phone",
    "my_seasons": "My Seasons",
    "my_programs": "My Programs",
    "category": "Category",
    "contents": "Contents",
    "more": "More +",
    "add_gallery_photos": "Add gallery photos",
    "image_files": "Image files",
    "submit": "Submit",
    "user_choice": "User Choice",
    "birthday_start": "Birthday Start",
    "birthday_end": "Birthday End",
    "my_profile": "My Profile",
    "compose_email": "Compose ",
    "ok": "Ok",
    "join_team_prompt": "You can input join code of team to join or request to join without it.",
    "join_team_pending_prompt": "You already requested to join with this user. You need to wait for team owner's approval",
    "member_teams": "Teams you are member of",
    "contacts": "Contacts",
    "upcoming_game": "Upcoming Game",
    "no_upcoming_game": "No Upcoming Game",
    "upcoming_schedule": "Upcoming Schedule",
    "existing_wallet_parent": "This user's wallet is existing as owner's wallet of ",
    "no_wallet": "No wallet existing",
    "org_link_title": "Would you like to visit stripe account pages?",
    "my_wallet": "My Wallet",
    "invoice": "Invoice",
    "payment": "Payment",
    "card": "Card",
    "featured_players": "Players Supporting",
    "need_to_join": "Needs to join",
    "cancel_request": "Cancel Request",
    "leave": "Leave",
    "invalid_team_code": "The code you inputed is not matching. This request will have to be accepted by the owner of team.",
    "upcoming_games": "Upcoming games",
    "past_games": "Past games",
    "upcoming_schedules": "Upcoming schedules",
    "past_schedules": "Past schedules",
    "pending_payment": "Pending Payment",
    "paid_online": "Paid Online",
    "paid_in_person": "Paid In Person",
    "coach_membership_relogin": "You should login as the credential of user same as on your mobile app.",
    "profile": "Profile",
    "delete_profile": "Delete Account",
    "delete_profile_prompt": "Are you sure you want to delete your account",
    "normal": "Normal",
    "blue": "Blue",
    "samba": "Samba",
    "affiliated": "Affiliated",
    "ighlights": "Highlights",
    "news": "News",
    "group_game": "Group Game",
    "knockout_game": "Knockout Game",
    "league": "League",
    "playoff": "Playoff",
    "tournament": "Tournament",
    "custom": "Custom",
    "knockout": "Knockout",
    "group": "Group",
    "draw": "Draw",
    "select_all": "Select All",
    "semi_final": "Semi Final",
    "claims": "Claims",
    "pending": "Pending",
    "ongoing": "Ongoing",
    "support_contacts": "Support Contacts",
    "reply": "Reply",
    "manager": "Manager",
    "pick_to_division": "Pick To Division",
    "remove_from_division": "Remove From Division",
    "view_details": "View Details",
    "register_now": "Register Now",
    "register_join": "REGISTER & JOIN",
    "accept_direct_pending": "Accept Direct Pending",
    "cancel_invite": "Cancel Invite",
    "registered": "Registered",
    "request_to_join": "Request to Join",
    "terms": "Terms",
    "policy": "Policy",
    "mobile": "Phone Number",
    "hide_season_schedules": "Hide Season Schedules",
    "organizer_panel": "Organizer Panel",
    "divisions": "Divisions",
    "finance": "Finance",
    "mail_box": "Mail Box",
    "change_plan": "Change Plan",
    "modify": "Modify",
    "free_trial": "Free Trial",
    "no_membership": "No Active Plan",
    "no_membership_prompt": "Activate a Plan",
    "trial_until": "Trial until ",
    "renew_within": "Renewing in ",
    "ends_in": "Ends in",
    "promo_codes": "Promo Codes",
    "shared_contents": "Shared Contents",
    "admin_contents": "Admin Contents",
    "admin_panel": "Admin Panel",
    "security": "Security",
    "update": "Update",
    "expired": "Expired",
    "next": "Next",
    "no_organization_prompt": "You should create Organization first",
    "create_season": "Create a season",
    "setup_season": "Setup of season",
    "season_title": "Season Title",
    "division_choice": "Division Choice",
    "no_title_prompt": "You must input title",
    "no_description_prompt": "You must input description",
    "no_division_prompt": "You must choose division",
    "no_start_time_prompt": "You must input valid start time",
    "no_register_time_prompt": "You must input valid registration deadline",
    "no_end_time_prompt": "You must input valid end date",
    "individual_register_switch": "Individual Register Swich",
    "block_offline_payment": "Block In-Person Payment",
    "team_register_fee": "Team Register Fee",
    "player_register_fee": "Player Register Fee",
    "staff_register_fee": "Staff Register Fee",
    "season_type": "Season Type",
    "start_team_count": "Start Team Count",
    "group_count": "Group Count",
    "knockout_team_count": "Knockout Team Count",
    "qualification_stage_type": "Qualification Stage Type",
    "trophy_choice": "Trophy Choice",
    "skip_profile_check": "Skip Profile Check",
    "season_create_step_1": "Input your season title and choose division to be hosted.",
    "season_create_step_2": "Input your date and time needed for season setup.",
    "season_create_step_3": "Input whether your season prompts individual register or not, and fee for team and personals.",
    "season_create_step_4": "Input your season type and critical information for season scheduling.",
    "season_create_step_5": "Input your season trophy type and policy check mandatory flag.",
    "season_schedule_step_1": "Your season is in registration status. Please pick the teams you want to include from division and organization member list.",
    "season_schedule_step_2": "Your season is in scheduling status. Please organize game schedules by proper type of round of your season.",
    "season_schedule_round_teams": "Pick teams to attend to this round.",
    "season_schedule_legaue_general": "You should input how many teams will be considered as winners in this league, and choose the single match day flow would be based on weekly or per location.",
    "season_schedule_legaue_verify": "Here are game schedules deployed. You can configure home and away team, start time, end time, location, referee of each game",
    "season_schedule_knockout_general": "You should verify knockout round team count to start with.",
    "season_schedule_knockout_time": "You should check and verify game start times of knockout round games.",
    "no_required_team_prompt": "You should pick required number of teams for this round.",
    "no_round_game_prompt": "You should finish setting up games for this round.",
    "no_round_info_prompt": "You should fill out required inputs to proceed to next step.",
    "no_round_game_proper": "Your game set has some errors check the issues and get rid of those.",
    "setup_league_round": "Setup of League Round",
    "setup_group_round": "Setup of Group Round",
    "save_and_finish": "Save & Close",
    "continue_to_scheduling": "Continue",
    "division": "Division",
    "organization": "Organization",
    "referees": "Referees",
    "finish": "Finish",
    "round_type": "Round Type",
    "add_custom_round": "Add Custom Round",
    "save_custom_round": "Save Custom Round",
    "custom_round_type": "Custom Round Type",
    "invalid_round_type": "You should choose type of the round first.",
    "setup_division": "Setup of Division",
    "division_title": "Division title",
    "thumbnail": "Thumbnail",
    "division_create_step_1": "Input your division title, thumbnail and description.",
    "division_create_step_2": "Input your what kind of sports your division is hosting. It will decide specific rules of score, foul, game points and etc. You can customize your division rules after your division is created.",
    "division_create_step_3": "Input your division's gender and age config. It repregents your division members.",
    "division_create_step_4": "Input your division's contact information.",
    "no_category_prompt": "You should pick your category to continue.",
    "contact_email": "Contact Email",
    "contact_phone_number": "Contact Phone Number",
    "season_quick_setup": "Season Quick Setup",
    "season_quick_setup_note": "After creating your competition you will be able to see it live on a uniqueue URL.",
    "season_quick_setup_prompt": "You need to login to perform season setup",
    "create_organization": "Create Your Organization",
    "setup_organization": "Setup of Organization",
    "organization_create_step_1": "Input your organization title, thumbnail and description.",
    "organization_create_step_2": "You can purcahse full membership or activate your free trial plan.",
    "activate": "Activate",
    "purchase": "Purchase",
    "player_profile": "Player Profile",
    "user_login_needed": "You need to login with user {user}.",
    "unknown": "Unknown",
    "division_policy_check_prompt": "You need to scroll down to end of policy and agree to it to proceed.",
    "no_division_policy_messsage": "This division has not defined policy yet.",
    "skip": "Skip",
    "choose_team_to_register": "Choose your team to register",
    "team_register_message": "Pick one of your teams to register on this season",
    "team_choice": "Team Choice",
    "no_team_choice_prompt": "You have to pick your team to proceed",
    "register_team_to_season": "Register your team to Season",
    "payment_method": "Payment Method",
    "register_team_to_season_message_01": "Now you can register your team to your season.", 
    "register_team_to_season_message_02": "Please select payment method you agreed with season owner and input your note to owner.",
    "register_team_to_season_result": "Team register attempt finished",
    "register_team_to_season_requested_offline": "Your team register for offline payment is requested. Now you need to wait until the season owner verifies your request.",
    "register_team_to_season_requested_direct": "Your team register is requested with online payment. You can be member of season once the online payment is finished.",
    "register_team_to_season_failed_direct": "Your team register with online payment is not finished. Please try again later.",
    "season_fee_no_registered": "Season team fee setting is not ready yet",
    "season_fee_no_registered_prompt": "Season team fee setting is not ready yet. Please try enroll later.",
    "choose_player_to_register": "Choose your player to register",
    "player_register_message": "Pick one of your players to register on this season",
    "no_player_choice_prompt": "You have to pick your player to proceed",
    "register_player_to_season": "Register your player to Season",
    "register_player_to_season_message_01": "Now you can register your player to your season.", 
    "register_player_to_season_message_02": "Please select payment method you agreed with season owner and input your note to owner.",
    "register_player_to_season_result": "Player register attempt finished",
    "register_player_to_season_requested_offline": "Your player register for offline payment is requested. Now you need to wait until the season owner verifies your request.",
    "register_player_to_season_requested_direct": "Your player register is requested with online payment. You can be member of season once the online payment is finished.",
    "register_player_to_season_failed_direct": "Your player register with online payment is not finished. Please try again later.",
    "enroll_type": "Enroll type",
    "myself": "Myself",
    "register_player_to_program": "Register your player to Program",
    "register_player_to_program_message_01": "Now you can register your player to your program.", 
    "register_player_to_program_message_02": "Please select payment method you agreed with program owner and input your note to owner.",
    "register_player_to_program_result": "Player register attempt finished",
    "register_player_to_program_requested_offline": "Your player register for offline payment is requested. Now you need to wait until the program owner verifies your request.",
    "register_player_to_program_requested_direct": "Your player register is requested with online payment. You can be member of program once the online payment is finished.",
    "register_player_to_program_failed_direct": "Your player register with online payment is not finished. Please try again later.",
    "default_rounds": "Default Rounds",
    "custom_rounds": "Custom Rounds",
    "redeploy": "Redeploy",
    "save_change": "Save Change",
    "support": "Support",
    "enroll_header_01": "Player To Enroll",
    "enroll_text_01": "Pick your child or yourself whom you want to enroll with.",
    "enroll_header_02": "Registration",
    "enroll_text_02": "Submit your personal information for this organization.",
    "enroll_header_03": "Privacy Policy",
    "enroll_header_04": "Enroll Setup",
    "enroll_text_04": "Please fill out your comment for enrollment if you have any",
    "enroll_text_checkout": "You can either add this item to cart or checkout it now",
    "confirm": "Confirm",
    "checkout_now": "Checkout Now",
    "add_to_cart": "Add to cart",
    "enroll_now": "Enroll Now",
    "team_enroll_header_01": "Team To Enroll",
    "team_enroll_text_01": "Pick your team you want to enroll with.",
    "team_enroll_header_02": "Registration",
    "team_enroll_text_02": "Submit your personal information for this organization.",
    "team_enroll_header_03": "Privacy Policy",
    "team_enroll_header_04": "Enroll Setup",
    "team_enroll_text_04": "Please fill out your comment for enrollment if you have any",
    "services": "Services",
    "player_reg_program_label": "Player Registration To Program",
    "player_reg_season_label": "Player Registration To Season",
    "team_reg_season_label": "Team Registration To Season",
    "checkout_session_faild": "Requesting Checkout Session Failed.",
    "my_cart": "My Cart",
    "no_cart_items": "No Cart Items To Show",
    "registration": "Registration",
    "season_pay": "Buy Seasons",
    "season_ticket": "Season Ticket",
    "buy_ticket": "Buy Ticket",
    "season_tickets": "Season Tickets",
    "buy_season_ticket": "Buy New Ticket",
    "buy_season_ticket_description": "You can buy a chance to edit and schedule a single season without the full membership.",
    "setup_new_season": "Setup new season",
    "setup_new_season_description": "You have a ticket to create, setup, schedule a season of games from your teams.",
    "available_ticket": "Available ticket",
    "main_service_01": "Sports Organization",
    "main_service_action_01": "Get Started",
    "main_service_text_01": "Live provides you an interface to organize your teams and seasons into great seasons of games with rich types and good flow",
    "main_service_02": "Your Tournament",
    "main_service_action_02": "Create a Tournament",
    "main_service_text_02": "You can create your season of games with your teams with just a couple of minutes, Try to creating it now.",
    "main_service_03": "Sports Training",
    "main_service_action_03": "Get Started",
    "main_service_text_03": "Live supports your sports club training and coaching by providing you to interface to create workout, challenge, and schedules",
    "main_feature_title": "Everything to organize your championship!",
    "main_feature_text": "Our main objective is to help organizers manage their championships, allowing them to publish tables, match results, photos and videos so that participants and spectators can follow and interact with the results.",
    "main_feature_text_01": "Create Seasons",
    "main_feature_text_02": "Game Schedules",
    "main_feature_text_03": "Manage Teams",
    "main_feature_text_04": "Manage Championships",
    "main_feature_text_05": "Website Template",
    "main_feature_text_06": "Statistics",
    "reviews": "Reviews",
    "review_title": "OUR CUSTOMERS APPROVE!",
    "review_text": "Championship organizers from around the world use Live for different sports",
    "plans_title": "OUR PLANS",
    "plans_text": "We have plans just right for your budget!",
    "talk_to_us_subtitle": "Have questions?",
    "talk_to_us_title": "Talk to our team!",
    "talk_to_us_text": "We will respond as quickly as possible",
    "talk_to_us_whatsapp": "Chat on Whatsapp",
    "talk_to_us_also": "We also have",
    "tutorial_title": "SYSTEM TUTORIALS",
    "tutorial_text": "These videos will help you get started using Live",
    "org_overlay_login_prompt": "Welcome to ",
    "org_overlay_login_message": "Please login to your account to try the organization setup",
    "purchase_free_org_membership_text": "Free Trial version of Organizer Membership is available. You can enjoy full features 14 days.",
    "purchase_org_membership_text": "Organizer Plans",
    "payment_setup": "Setup Your Stripe Payment",
    "payment_setup_message": "You do not have your stripe account setup yet, you need that to be setup in order to receive seaosn fee and program fee from users.",
    "setup_account": "Setup Account",
    "tutorial": "Tutorial",
    "videos": "Videos",
    "trials": "Trials",
    "lessons": "Lessons",
    "reivew": "Reivew",
    "season_filter_prompt": "Select your competition to filter the content below",
    "all": "All",
    "team_season_statistics": "Team statistics in season ",
    "team_organization_statistics": "Team statistics in organization ",
    "matches_played": "Matches Played",
    "to_play": "To Play",
    "suspended": "Suspended",
    "goalkeeper": "Goalkeeper",
    "defender": "Defender",
    "midfielder": "Midfielder",
    "forward": "Forward",
    "squad": "Squad",
    "teams_to_setup": "Teams to setup",
    "date_time": "Date & Time",
    "location_referee": "Referee / Location",
    "_": "_",
}