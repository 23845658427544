import React, { Component } from 'react'
import {HomeHeader} from 'components/landing/globe/HomeHeader';
import {Services} from 'components/landing/globe/Services';
import {MainFeature} from 'components/landing/globe/MainFeature';
import {Reviews} from 'components/landing/globe/Reviews';
import {Organizations} from 'components/landing/globe/Organizations';
import {Statistics} from 'components/landing/globe/Statistics';
import {Plans} from 'components/landing/globe/Plans';
import {TalkToUs} from 'components/landing/globe/TalkToUs';
import {Tutorials} from 'components/landing/globe/Tutorials';
import {ContactUs} from 'components/landing/globe/ContactUs';
import JsonData from 'components/landing/data/data.json';
import { MdClose } from 'react-icons/md';

export class Home extends Component {
    state = {
        landingPageData: {},
    }
    getlandingPageData() {
        this.setState({landingPageData : JsonData})
    }

    componentDidMount() {
        this.getlandingPageData();
    }

    render() {
        const {videoLink} = this.state;
        
        if (this.state.landingPageData.About != null) {
            return (
                <div>
                    <HomeHeader data={this.state.landingPageData.Header} />
                    <Services />
                    <MainFeature onPlayTapped={(link)=> {
                        this.setState({videoLink: link})
                    }} />
                    <Statistics />
                    <Organizations />
                    <Plans />
                    <TalkToUs />
                    <Reviews data={this.state.landingPageData.Testimonials} />
                    <Tutorials data={this.state.landingPageData.Tutorials} onPlayTapped={(link)=> {
                        this.setState({videoLink: link})
                    }} />
                    <ContactUs data={this.state.landingPageData.Contact} />
                    {videoLink && (
                    <div className='main-player-overlay'>
                        <div className='container'>
                            <div className='close' onClick={e=> {
                                this.setState({videoLink: null});
                            }}>
                                <div style={{flex: '1 1 auto'}} />
                                <div className='button'><MdClose /></div>
                            </div>
                            <iframe title="Video" src={videoLink} frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                    )}
                </div>
            )
        } else {
            return <div/>
        }
    }
}

export default Home;
