import React, { Component } from "react";
import {
    Card, CardImg, Button, Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import { 
    MdEdit
} from 'react-icons/md';

import SubInfoView from 'components/detail/SubInfoView';
import defaultImage from 'assets/img/icons/shield_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';

import { getSeasonStatusDict, toDateString } from 'utils/Utils';
import { saveSeason } from "utils/API";
import { STATUS_REGISTER } from "utils/constants";

export class SeasonDivisionBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {season, team, divisions, organizations, onClickEditDivision, onClickDetails, onChanged } = this.props;
        const division = divisions[season.divisionId];
        const isMember = season.teamIds != null && Object.values(season.teamIds).includes(team.id);
        const isPending = season.pendingIds != null && Object.values(season.pendingIds).includes(team.id);
        const isInvited = season.invitedIds != null && Object.values(season.invitedIds).includes(team.id);
        const isDirectPending = season.directPending != null && Object.values(season.directPending).includes(team.id);
        
        if (division != null) {
            const organization = organizations[season.organizationId];
            const isDivisionMember = organization.teamIds != null && Object.values(organization.teamIds).includes(team.id);
            let divisionIcon = division.thumbUri != null ? division.thumbUri : defaultImage;
            let teamCount = season.teamIds == null ? 0 : Object.values(season.teamIds).length;
            let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
            let playerCount = 0;
            let playerIds = [];
            if (season && season.teamData) {
                for (const teamData of Object.values(season.teamData)) {
                    if (teamData.roaster) {
                        for (const uid of Object.values(teamData.roaster)) {
                            if (!playerIds.includes(uid)) {
                                playerIds.push(uid);
                            }
                        } 
                    }
                }
            }
            playerCount = playerIds.length;
            let orgThumb = organization != null && organization.thumbUri != null ? organization.thumbUri : defaultImage
            let dict = getSeasonStatusDict(season);
            const str = dict.str;
            const bgColor = dict.bgColor;
            const fColor = dict.fColor;

            return (
                <div className="">
                    <Card className="shadow-tile grid-badge">
                        <h4 className='grid-item-subInfo' 
                            style={{ 
                                display: 'inline', 
                                position: 'absolute', 
                                top: 0, 
                                right: 0, 
                                backgroundColor: bgColor, 
                                padding: '5px 20px', 
                                marginRight: -5,
                                color: fColor}}>
                        {str}
                        </h4>
                        <div className='grid-item-row'>
                            <CardImg
                                className="grid-item-icon"
                                src={divisionIcon} />
                            <div className='grid-item-info-fill'>
                                <h4 className='grid-item-title'>
                                {season.title} / {division.title}
                                </h4>
                                <div className="grid-item-info">{division.description}</div>
                            </div>
                        </div>
                        {organization != null && (
                        <div className='grid-item-row'>
                            <CardImg
                                className="grid-item-icon-small"
                                src={orgThumb} />
                            <div className='grid-item-info-fill'>
                                <div className="grid-item-info one-line-div">{organization.title}</div>
                            </div>
                            {onClickEditDivision != null && (
                                <div className='grid-item-info-wrap'>
                                    <Button className="grid-edit-btn" onClick={e => {
                                        if (onClickEditDivision != null) {
                                            onClickEditDivision();
                                        }
                                    }}><MdEdit/></Button>
                                </div>
                            )}
                        </div>
                        )}
                        <Row>
                            <Col xs={6} className='text-center'>
                                <h4 className='grid-item-subtitle' style={{margin: '5px 0px'}}>
                                {t('reg_date')}
                                </h4>
                                <h4 className='grid-item-subInfo' style={{ display: 'inline', backgroundColor: '#FFD2D2', padding: '2px 10px', color: '#FF6E6E'}}>
                                {toDateString(season.regDeadline)}
                                </h4>
                            </Col>
                            <Col xs={6} className='text-center'>
                                <h4 className='grid-item-subtitle' style={{margin: '5px 0px'}}>
                                {t('start_date')}
                                </h4>
                                <h4 className='grid-item-subInfo' style={{ display: 'inline', backgroundColor: '#D3E0F1', padding: '2px 10px' , color: '#19447f'}}>
                                {toDateString(season.startTime)}
                                </h4>
                            </Col>
                        </Row>
                        <div className='grid-item-row'>
                            <h4 className='grid-item-title' style={{marginBottom: 0}}>
                            Overview: 
                            </h4>
                        </div>
                        <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('sport')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {t('soccer')}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Venues' icon={venueIcon} number={locationCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('gender')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.gender != null ? division.gender : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Teams' icon={teamIcon} number={teamCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('age')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.ageTitle != null ? division.ageTitle : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Players' icon={playersIcon} number={playerCount} />
                                </div>
                            </Col>
                        </Row>
                        {isDivisionMember ? (
                            <div>
                                {isMember && (
                                    <div className='grid-item-row'>
                                        <Button className="grid-action-green grid-item-info-fill" onClick={e => {
                                            alert("You have to ask season owner to remove you from team list.")
                                        }}>{t("registered")}</Button>
                                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                            if (onClickDetails != null) {
                                                onClickDetails();
                                            }
                                        }}>{t("view_details")}</Button>
                                    </div>
                                )}
                                {isPending && (
                                    <div className='grid-item-row'>
                                        <Button className="btn-cancel grid-item-info-fill" onClick={e => {
                                            if (season.pendingIds && season.pendingIds[team.id]) {
                                                delete season.pendingIds[team.id];
                                            }
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged();
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t("cancel_request")}</Button>
                                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                            if (onClickDetails != null) {
                                                onClickDetails();
                                            }
                                        }}>{t("view_details")}</Button>
                                    </div>
                                )}
                                {isInvited && (
                                    <div className='grid-item-row'>
                                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                            let now = new Date().valueOf();
                                            if (season.status != null && season.status !== STATUS_REGISTER) {
                                                alert('Registration is finished. You can not change status anymore.')
                                                return;
                                            }
                                            if (season.regDeadline != null && now > season.regDeadline) {
                                                alert('Registration deadline is exceeded. You can not change status anymore.')
                                                return;
                                            }
                                            if (season.invitedIds && season.invitedIds[team.id]) {
                                                delete season.invitedIds[team.id];
                                            }
                                            if (!season.teamIds) {
                                                season.teamIds = {};
                                            }
                                            season.teamIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged();
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t("accept_invite")}</Button>
                                        <Button className="btn-cancel grid-item-info-fill" onClick={e => {
                                            if (season.invitedIds && season.invitedIds[team.id]) {
                                                delete season.invitedIds[team.id];
                                            }
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged();
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t("reject_invite")}</Button>
                                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                            if (onClickDetails != null) {
                                                onClickDetails();
                                            }
                                        }}>{t("view_details")}</Button>
                                    </div>
                                )}
                                {!isInvited && !isMember && !isPending && (
                                    <div className='grid-item-row'>
                                        <Button className="grid-action-red grid-item-info-fill" onClick={e => {
                                            let now = new Date().valueOf();
                                            if (season.status != null && season.status !== STATUS_REGISTER) {
                                                alert('Registration is finished. You can not change status anymore.')
                                                return;
                                            }
                                            if (season.regDeadline != null && now > season.regDeadline) {
                                                alert('Registration deadline is exceeded. You can not change status anymore.')
                                                return;
                                            }
                                            if (!season.pendingIds) {
                                                season.pendingIds = {};
                                            }
                                            season.pendingIds[team.id] = team.id;
                                            saveSeason(season, () => {
                                                if (onChanged != null) {
                                                    onChanged();
                                                }
                                            }, (error) => {
                                                console.log(error);
                                            })
                                        }}>{t("register_now")}</Button>
                                        <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                            if (onClickDetails != null) {
                                                onClickDetails();
                                            }
                                        }}>{t("view_details")}</Button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className='grid-item-row'>
                            {!isDirectPending ? (
                                <Button className="grid-action-red grid-item-info-fill" onClick={e => {
                                    let now = new Date().valueOf();
                                    if (season.status != null && season.status !== STATUS_REGISTER) {
                                        alert('Registration is finished. You can not change status anymore.')
                                        return;
                                    }
                                    if (season.regDeadline != null && now > season.regDeadline) {
                                        alert('Registration deadline is exceeded. You can not change status anymore.')
                                        return;
                                    }
                                    if (!season.directPending) {
                                        season.directPending = {};
                                    }
                                    season.directPending[team.id] = team.id;
                                    saveSeason(season, () => {
                                        if (onChanged != null) {
                                            onChanged();
                                        }
                                    }, (error) => {
                                        console.log(error);
                                    })
                                }}>{t('register_join')}</Button>
                            ) : (
                                <Button className="btn-cancel grid-item-info-fill" onClick={e => {
                                    if (season.directPending && season.directPending[team.id]) {
                                        delete season.directPending[team.id];
                                    }
                                    saveSeason(season, () => {
                                        if (onChanged != null) {
                                            onChanged();
                                        }
                                    }, (error) => {
                                        console.log(error);
                                    })
                                }}>{t("cancel_request")}</Button>
                            )}
                                <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                    if (onClickDetails != null) {
                                        onClickDetails();
                                    }
                                }}>{t("view_details")}</Button>
                            </div>
                        )}
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default SeasonDivisionBadge;