import React, { Component } from 'react'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/src/jquery';
import 'font-awesome/css/font-awesome.min.css';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import './style/general.css';
import './style/style.css';
import './style/ridley-style.css';
import './style/organization.css';
import './style/community.css';
import './style/instructions.css';
import './style/course.css';
import './style/library.css';
import './style/chat.css';
import './style/landing.css';
import './style/survey.css';
import './style/popup.css';
import './style/org-landing.css';
import './style/org-membership.css';
import './style/game-overlay.css';
import './style/wallet.css';
import './style/blue-landing.css';
import './style/side-menu.css';
import './style/samba-landing.css';
import './style/payment.css';
import './style/main-landing.css';
import './style/main-landing-overlay.css';

import { MainPage } from 'pages/MainPage';
import { MainLanding } from 'pages/landing/MainLanding';

import AuthPage from './pages/auth/AuthPage';
import InvitePage from 'pages/auth/InvitePage';

import { connect } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { history } from './redux/_helpers';
import { alertActions, userActions, coachActions } from './redux/_actions';

import InvitePlayerPage from 'pages/auth/InvitePlayerPage';
import InviteRefereePage from 'pages/auth/InviteRefereePage';
import InviteCoachPage from 'pages/auth/InviteCoachPage';
import InviteTeamManagerPage from 'pages/auth/InviteTeamManagerPage';
import ImportInvitePage from 'pages/auth/ImportInvitePage';
import { OrganizerDashboard } from 'pages/organizer/OrganizerDashboard';
import { DashboardPage } from 'pages/organizer/DashboardPage';
import { DivisionsPage } from 'pages/organizer/DivisionsPage';
import { SeasonPayPage } from 'pages/organizer/SeasonPayPage';
import { ProgramsPage } from 'pages/organizer/ProgramsPage';
import { FinancePage } from 'pages/organizer/FinancePage';
import { TeamsPage } from 'pages/organizer/register/TeamsPage';
import { PlayersPage } from 'pages/organizer/register/PlayersPage';
import { RefereesPage } from 'pages/organizer/register/RefereesPage';
import { AttendancePage } from 'pages/organizer/register/AttendancePage';
import { ClaimsPage } from 'pages/organizer/register/ClaimsPage';
import { LocationsPage } from 'pages/organizer/register/LocationsPage';
import { SchedulesPage } from 'pages/organizer/register/SchedulesPage';
import { ImportsPage } from 'pages/organizer/register/ImportsPage';
import { SettingsPage } from 'pages/organizer/SettingsPage';
import { MailBox } from 'pages/organizer/MailBox';
import { CommunityPage } from 'pages/organizer/CommunityPage';
import { MembershipPage } from 'pages/organizer/MembershipPage';
import Home from 'pages/landing/Home';
import TermsPage from 'pages/landing/TermsPage';
import PolicyPage from 'pages/landing/PolicyPage';
import { OrgHome } from 'pages/organization/landing/OrgHome';
import { OrgLanding } from 'pages/organization/OrgLanding';
import { OrgSeasons } from 'pages/organization/landing/OrgSeasons';
import { OrgServices } from 'pages/organization/landing/OrgServices';
import { OrgPosts } from 'pages/organization/landing/OrgPosts';
import { OrgTeams } from 'pages/organization/landing/OrgTeams';
import { OrgFundraises } from 'pages/organization/landing/OrgFundraises';
import { OrgEvents } from 'pages/organization/landing/OrgEvents';
import { OrgPrograms } from 'pages/organization/landing/OrgPrograms';
import { OrgAbout } from 'pages/organization/landing/OrgAbout';
import { OrgContact } from 'pages/organization/landing/OrgContact';
import { OrgAccount } from 'pages/organization/landing/OrgAccount';
import { OrgManager } from 'pages/organization/landing/OrgManager';
import { MyAccountPage } from 'pages/MyAccountPage';
import { TeamHome } from 'pages/team/landing/TeamHome';
import { TeamLanding } from 'pages/team/TeamLanding';
import { TeamAbout } from 'pages/team/landing/TeamAbout';
import { TeamContact } from 'pages/team/landing/TeamContact';
import { TeamCommunity } from 'pages/team/landing/TeamCommunity';
import { TeamOrganizations } from 'pages/team/landing/TeamOrganizations';
import { TeamTraining } from 'pages/team/landing/TeamTraining';
import { TeamSchedules } from 'pages/team/landing/TeamSchedules';
import { TeamMembers } from 'pages/team/landing/TeamMembers';
import { TeamCoach } from 'pages/team/landing/TeamCoach';
import { TeamAccount } from 'pages/team/landing/TeamAccount';
import { AdminPage } from 'pages/admin/AdminPage';
import AdminInvites from 'pages/admin/AdminInvites';
import AdminOrganizers from 'pages/admin/AdminOrganizers';
import AdminSecurity from 'pages/admin/AdminSecurity';
import AdminPromo from 'pages/admin/AdminPromo';
import AdminVideos from 'pages/admin/contents/AdminVideos';
import AdminLessons from 'pages/admin/contents/AdminLessons';
import AdminChallenges from 'pages/admin/contents/AdminChallenges';
import AdminReview from 'pages/admin/contents/AdminReview';
import AdminPolicy from 'pages/admin/contents/AdminPolicy';
import {AdminTutorial} from 'pages/admin/contents/AdminTutorial';

const darboardItem = 
{
    path: "organizer",
    element: <OrganizerDashboard />,
    children: [
        {
            path: "", element: <DashboardPage />
        },
        {
            path: "season_pay", element: <SeasonPayPage />
        },
        {
            path: "season_pay/:seasonId", element: <SeasonPayPage />
        },
        {
            path: "divisions", element: <DivisionsPage />
        },
        {
            path: "divisions/:id", element: <DivisionsPage />
        },
        {
            path: "divisions/:id/:seasonId", element: <DivisionsPage />
        },
        {
            path: "programs", element: <ProgramsPage />
        },
        {
            path: "programs/:id", element: <ProgramsPage />
        },
        {
            path: "finance", element: <FinancePage />
        },
        {
            path: "settings", element: <SettingsPage />
        },
        {
            path: "community", element: <CommunityPage />
        },
        {
            path: "mailbox", element: <MailBox />
        },
        {
            path: "membership", element: <MembershipPage />
        },
        {
            path: "register/teams", element: <TeamsPage />
        },
        {
            path: "register/teams/:id", element: <TeamsPage />
        },
        {
            path: "register/player", element: <PlayersPage />
        },
        {
            path: "register/player/:id", element: <PlayersPage />
        },
        {
            path: "register/referee", element: <RefereesPage />
        },
        {
            path: "register/referee/:id", element: <RefereesPage />
        },
        {
            path: "register/imports", element: <ImportsPage />
        },
        {
            path: "register/imports/:id", element: <ImportsPage />
        },
        {
            path: "register/locations", element: <LocationsPage />
        },
        {
            path: "register/schedules", element: <SchedulesPage />
        },
        {
            path: "register/attendance", element: <AttendancePage />
        },
        {
            path: "register/claims", element: <ClaimsPage />
        },
    ]
}

const adminItem = 
{
    path: "admin",
    element: <AdminPage />,
    children: [
        {
            path: "", element: <AdminOrganizers />
        },
        {
            path: "invite", element: <AdminInvites />
        },
        {
            path: "security", element: <AdminSecurity />
        },
        {
            path: "codes", element: <AdminPromo />
        },
        {
            path: "contents/tutorial", element: <AdminTutorial />
        },
        {
            path: "contents/videos", element: <AdminVideos />
        },
        {
            path: "contents/lessons", element: <AdminLessons />
        },
        {
            path: "contents/challenges", element: <AdminChallenges />
        },
        {
            path: "contents/reivew", element: <AdminReview />
        },
        {
            path: "contents/policy", element: <AdminPolicy />
        },
    ]
}

const mainLandingChildren = [
    {
        path: "", element: <Home />
    },
    {
        path: "terms-of-services", element: <TermsPage />
    },
    {
        path: "privacy-policy", element: <PolicyPage />
    },
]

const orgLandingChildren = [
    {
        path: "", element: <OrgHome />
    },
    {
        path: "services", element: <OrgServices />
    },
    {
        path: "news", element: <OrgPosts />
    },
    {
        path: "championships", element: <OrgSeasons />
    },
    {
        path: "championships/:seasonId", element: <OrgSeasons />
    },
    {
        path: "teams", element: <OrgTeams />
    },
    {
        path: "teams/:teamId", element: <OrgTeams />
    },
    {
        path: "fund", element: <OrgFundraises />
    },
    {
        path: "fund/:fundId", element: <OrgFundraises />
    },
    {
        path: "programs", element: <OrgPrograms />
    },
    {
        path: "programs/:programId", element: <OrgPrograms />
    },
    {
        path: "events", element: <OrgEvents />
    },
    {
        path: "about", element: <OrgAbout />
    },
    {
        path: "contact", element: <OrgContact />
    },
    {
        path: "account", element: <OrgAccount />
    },
    {
        path: "account/:uid", element: <OrgAccount />
    },
    {
        path: "manager", element: <OrgManager />
    },
    {
        path: "manager/:id", element: <OrgManager />
    },
    {
        path: "manager/:id/:seasonId", element: <OrgManager />
    },
]

const teamChildren = [
    {
        path: "", element: <TeamHome />
    },
    {
        path: "assign/:userId/:targetId", element: <TeamHome />
    },
    {
        path: "assignChild/:userId/:targetId", element: <TeamHome />
    },
    {
        path: "assignEmail/:email/:targetId", element: <TeamHome />
    },
    {
        path: "assignEmailChild/:email/:targetId", element: <TeamHome />
    },
    {
        path: "assignManager/:userId", element: <TeamHome />
    },
    {
        path: "assignManagerEmail/:email", element: <TeamHome />
    },
    {
        path: "assignPlayer/:userId", element: <TeamHome />
    },
    {
        path: "assignPlayerEmail/:email", element: <TeamHome />
    },
    {
        path: "about", element: <TeamAbout />
    },
    {
        path: "contact", element: <TeamContact />
    },
    {
        path: "community", element: <TeamCommunity />
    },
    {
        path: "organizations", element: <TeamOrganizations />
    },
    {
        path: "training", element: <TeamTraining />
    },
    {
        path: "schedules", element: <TeamSchedules />
    },
    {
        path: "members", element: <TeamMembers />
    },
    {
        path: "members/:memberId", element: <TeamMembers />
    },
    {
        path: "coach", element: <TeamCoach />
    },
    {
        path: "coach/:seasonId", element: <TeamCoach />
    },
    {
        path: "account", element: <TeamAccount />
    },
    {
        path: "account/:seasonId", element: <TeamAccount />
    },
]

const generalRoutes = [
    {
        path: "login", element: <AuthPage />
    },
    {
        path: "signup", element: <AuthPage />
    },
    {
        path: "invite/organizer/:email", element: <InvitePage />
    },
    {
        path: "invite/player/:email", element: <InvitePlayerPage />
    },
    {
        path: "invite/referee/:email", element: <InviteRefereePage />
    },
    {
        path: "invite/coach/:email", element: <InviteCoachPage />
    },
    {
        path: "invite/teamManager/:email", element: <InviteTeamManagerPage />
    },
    {
        path: "invite/import/:email", element: <ImportInvitePage />
    },
    adminItem,
    darboardItem,
    {
        path: "team/:id",
        element: <TeamLanding />,
        children: teamChildren
    },
    {
        path: "club/:customDomain",
        element: <TeamLanding />,
        children: teamChildren
    },
    {
        path: "my_account",
        element: <MyAccountPage />,
    },
    {
        path: "my_account/subscribe/:uid/:productId",
        element: <MyAccountPage />,
    },
]

const router = createBrowserRouter([
    {
        path: "/", element: <MainPage />,
        children: generalRoutes.concat([
            {
                path: "",
                element: <MainLanding />,
                children: mainLandingChildren
            },
            {
                path: "organization/:id",
                element: <OrgLanding />,
                children: orgLandingChildren
            },
            {
                path: "org/:customDomain",
                element: <OrgLanding />,
                children: orgLandingChildren
            },
        ])
    },
]);

const brandedRouter = createBrowserRouter([
    {
        path: "/", element: <MainPage />,
        children: generalRoutes.concat([
            {
                path: "",
                element: <OrgLanding />,
                children: orgLandingChildren
            },
        ])
    },
]);

class App extends Component {
    state = {};
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
            this.props.clearAlerts();
        });
    }
	
    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const userId = user.uid;
                this.props.checkLogin(userId);
                this.props.checkMembership(userId);
            }
        });
    }

    render() {
        let routes = router;
        const domain = window.location.origin;
        if (domain.includes("live-edb25-org-test") || domain.includes("ligasietecanada.com") || 
            domain.includes("live-edb25-eagles-fc") || domain.includes("eaglesfc.ca") ||
            domain.includes("live-edb25-ivy") || domain.includes("ivyfc.ca")) {
            routes = brandedRouter;
        }
        
        return (
            <RouterProvider router={routes} />
		);
	}
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    clearAlerts: alertActions.clear,
    checkLogin: userActions.checkLogin,
    checkMembership: coachActions.checkMembership,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
