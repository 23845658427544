import React, { Component } from "react";
import { homeVideo } from 'utils/landing';
import * as YoutubePlayer from 'react-player/youtube';

export class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            video: {},
            businesses: {},
            activeIndex: 0,
            animating: false,
        }
    }

    componentDidMount() {
        const {organization} = this.props;
        const video = {};
        
        if (organization && organization.landingData && organization.landingData.video) {
            for (const[key, value] of Object.entries(organization.landingData.video)) {
                video[key] = value;
            }
        }

        this.setState({
            video: video,
        });
    }

    componentDidUpdate(prevProps) {
        const {organization} = this.props;
        if (organization !== prevProps.organization) {
            const video = {};
            
            if (organization && organization.landingData && organization.landingData.video) {
                for (const[key, value] of Object.entries(organization.landingData.video)) {
                    video[key] = value;
                }
            }

            this.setState({
                video: video,
            });
        }
    }

    render() {
        const {video} = this.state;
        const bg = video.bg != null ? video.bg : homeVideo.bg;
        const link = video.link != null ? video.link : homeVideo.link;

        const view = document.getElementById('video-header')
        const width = view ? view.clientWidth : -1;
        const height = width !== -1 ? (width / 16 * 9) : 360;
        
        return (
            <div className="home-video-wrapper" style={{height: height + 100}}>
                <img src={bg} alt="background" className="home-video-image" style={{height: height + 100}}/>
                <div className="home-video-overlay">
                    <YoutubePlayer 
                        id='video-header'
                        className='home-video-player'
                        width="100%"
                        height={height}
                        stopOnUnmount 
                        controls 
                        url={link} />
                </div>
            </div>
        );
    }
}

export default Video;
