import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Row,
    Col,
    Button,
} from 'reactstrap';
import OrganizationBadge from 'components/landing/OrganizationBadge';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import { invokeHttpsApi } from "utils/API";
import { withRouter } from "utils/Routher";
import { sortByTitle } from "utils/Utils";

class Organizations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            loading: true,
            landingPageData: {},
            organization: {},
            organizations: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            hideMore: true
        }
    }

    componentDidMount() {
        if (this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = null;
        if (this.props.router.params != null) {
            id = this.props.router.params.id;
        }
        invokeHttpsApi('organizerQuery-organizationLandingQuery', {}, (data) => {
            const organizations = {};
            const organization = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                if (organizations[id] != null) {
                    for (const [key, value] of Object.entries(organizations[id])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    render() {
        const {showAll} = this.props;
        const {loading, organizations, seasons, profileData, hideMore} = this.state;
        let featured = [];
        let others = [];
        let values = [];
        for (const item of Object.values(organizations)) {
            if (!item.hideFromLanding && item.featured) {
                featured.push(item);
            } else if (!item.hideFromLanding) {
                others.push(item);
            }
        }
        sortByTitle(featured);
        sortByTitle(others);
        for (const item of featured) {
            values.push(item);
        }
        for (const item of others) {
            values.push(item);
        }
        
        let toShow = [];
        for (const item of values) {
            if (showAll || !hideMore || toShow.length < 3) {
                toShow.push(item);
            }
        }
        
        return (
            <div className='text-center container'>
                <div className="section-title" style={{marginTop: 30}}>
                    <h2>Organizations</h2>
                </div>
                {loading ? (
                    <SmallLoadingView />
                ) : (
                    <Row className='text-center' style={{margin: '10px 0px', justifyContent: 'center'}}>
                    {toShow.map((val,i)=>(
                        <Col sm={4} xs={12} key={i} style={{minWidth: 370}}>
                            <OrganizationBadge
                                organization={val}
                                profileData={profileData}
                                seasons={seasons} />
                        </Col>
                    ))}
                    </Row>
                )}
                {!showAll && (
                    <Button
                        className="btn-coral"
                        onClick={e=> {
                            this.setState({hideMore: !hideMore})
                        }}
                    >
                        {!hideMore ? "Less" : "View More"}
                    </Button>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    return { isAdmin, loggingIn, user };
  }
  
  const connectedNavigation = withRouter(connect(mapState)(Organizations));
  export { connectedNavigation as Organizations };
  
