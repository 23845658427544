import React, { Component } from "react";
import Avatar from 'components/detail/Avatar';
import { connect } from 'react-redux';

import { Card, CardBody, ListGroup, ListGroupItem, Button, FormGroup, Label, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

class MembersEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: {},
            users: {},
            coachGroups: {},
            gradeGroups: {},
            groupChoice: {},
            searchStr: '',
            editOpen: false,
            followersPick: false,
        }
    }

    componentDidMount() {
        const {members} = this.props;

        if (members != null) {
            const update = {};
            for (const [key, value] of Object.entries(members)) {
                update[key] = value;
            }
            this.setState({members: update});
        }
    }

    componentDidUpdate(prevProps) {
        const {members} = this.props;
        if (prevProps.members !== members) {
            if (members != null) {
                const update = {};
                for (const [key, value] of Object.entries(members)) {
                    update[key] = value;
                }
                this.setState({members: update});
            } else {
                this.setState({members: {}});
            }
        }
    }
    
    render() {
        const { users, available, onSaveClick, onCloseClick } = this.props;
        const { members, searchStr } = this.state;

        const items = [];
        const choice = {};

        if (users != null && members != null) {
            for (const [key, item] of Object.entries(users)){
                const inAvailable = available != null && Object.values(available).includes(key);
                
                if (item != null && inAvailable &&
                    (searchStr == null || searchStr === '' || item.username.toLowerCase().includes(searchStr.toLowerCase()))
                ) {
                    choice[key] = item;
                }
            }
        }

        const usersChoice = Object.values(choice);
        usersChoice.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        for (var i = 0; i < usersChoice.length; i ++) {
            const item = usersChoice[i];
            let itemType = Object.values(members).includes(item.uid) ? "Added" : "Not Added";

            items.push(
                <ListGroupItem key={item.uid}>
                    <div className='wrap-content-parent'>
                        <Avatar className='wrap-content-wrap-child vertical-center-spacing' src={item.photoUri} /> 
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div>{item.username}</div>
                            <div style={{fontSize: 12}}>{itemType}</div>
                        </div>
                        <Button className={Object.values(members).includes(item.uid) ? 'btn-cancel' : 'btn-submit'} onClick={e=>{
                            if (Object.values(members).includes(item.uid)) {
                                delete members[item.uid];
                            } else {
                                members[item.uid] = item.uid;
                            }
                            this.setState({members: members});
                        }}>{Object.values(members).includes(item.uid) ? "Remove" : "Add"}</Button>
                    </div>
                </ListGroupItem>
            )
        }

        return (
            <Card>
                <CardBody>
                    <div>Members</div>
                    <div style={{height: 10}}/>
                    <FormGroup>
                        <Input
                            type="search"
                            name="search"
                            id="member_search"
                            placeholder="Search by Username"
                            onChange={e=>{
                                this.setState({searchStr: e.target.value});
                            }} />
                    </FormGroup>
                    <ListGroup style={{maxHeight: 400, overflowY: 'scroll'}}>
                        {items}
                    </ListGroup>
                    {Object.values(choice).length > 0 && (
                        <FormGroup>
                            <Label for="locationChoice" check>Visible Users: {
                                Object.values(choice).length
                            }</Label>
                            <div className='wrap-content-parent'>
                                <Button className='wrap-content-wrap-child btn-submit' onClick={e=>{
                                    for (const[key,] of Object.entries(choice)) {
                                        members[key] = key;
                                    }
                                    this.setState({members: members});
                                }}>Add All</Button>
                                <Button className='wrap-content-wrap-child btn-cancel' onClick={e=>{
                                    for (const[key,] of Object.entries(choice)) {
                                        delete members[key];
                                    }
                                    this.setState({members: members});
                                }}>Remove All</Button>
                            </div>
                        </FormGroup>
                    )}
                    <div style={{height: 10}}/>
                    <Button className='btn-edit' onClick={e=> {
                        if (onSaveClick != null) {
                            onSaveClick(members);
                        }
                    }}>{t('save')}</Button>
                    <Button className='btn-cancel' onClick={e=> {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </CardBody>
            </Card>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    return { loggingIn, user };
}
  
const connectedApp = withTranslation()(connect(mapState)(MembersEditView));
export { connectedApp as MembersEditView };
