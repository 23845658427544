import React, { Component } from "react";
import { connect } from 'react-redux';
import { 
    GrArticle,
} from 'react-icons/gr';
import { t } from "i18next";
import ItemsNavigation from 'components/detail/ItemsNavigation';
import PostHeader from 'components/detail/community/PostHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import {PostEdit} from 'components/edit/community/PostEdit';
import { devThreshold } from "utils/constants";

class GameNewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailChoice: {},
            progress: false,
            roundChoiceId: null,
            newImage: {},
            imageProgress: 0,
            mainTab: 0,
            editOpen: false,
            config: new Date().valueOf() > devThreshold ? props.game.season.config : {},
            saveProgress: false,
        }
    }

    renderPosts() {
        const { organization, game, teams, posts, users, user, onChanged } = this.props;
        const { detailChoice, editOpen, detailOpen, searchStr} = this.state;
        
        const result = [];
        const list = [];

        const uid = user ? user.uid : "";
        const homeTeam = teams[game.homeTeam] != null ? teams[game.homeTeam] : {};
        const awayTeam = teams[game.awayTeam] != null ? teams[game.awayTeam] : {};

        let editable = false;
        if (game.refereeId === uid) {
            editable = true;
        } else if (homeTeam.owner === uid || (homeTeam.managers && Object.values(homeTeam.managers).includes(uid))) {
            editable = true;
        } else if (awayTeam.owner === uid || (awayTeam.managers && Object.values(awayTeam.managers).includes(uid))) {
            editable = true;
        } 
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.gameId === game.id) {
                result.push(value);
            }    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })
        
        for (const value of result) {
            list.push(
                <PostHeader 
                    key={value.id}
                    organization={organization}
                    post={value}
                    users={users}
                    user={user}
                    onClickEdit={uid === value.uid ? ()=>{
                        this.setState({
                            detailChoice: value,
                            detailOpen: false,
                            editOpen: true
                        })
                    } : null}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: value,
                            detailOpen: true
                        })
                    }} />
            );
        }

        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('news_feed')}
                        icon={<GrArticle/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={list.length}
                        detailItem={detailChoice}
                        onClickAdd={editable ? ()=>{
                            this.setState({detailChoice: {}, editOpen: true, detailOpen: false});
                        } : null}
                        onClickBack={()=>{
                            this.setState({detailChoice: {}, editOpen: false, detailOpen: false});
                        }} />
                </div>

                <div style={{margin: '0px 10px'}}>
                {!editOpen && !detailOpen && (
                <div>
                    {list}
                </div>
                )}

                {detailOpen && detailChoice.id != null && (
                    <PostDetailView 
                        organization={organization}
                        post={detailChoice} 
                        user={user}
                        users={users}
                        teams={teams}
                        isOpen={detailOpen}
                        onCloseClick={() => {
                            this.setState({detailOpen: false})
                        }} />
                )}
                {editOpen && (
                    <PostEdit 
                        uid={user.uid}
                        organization={organization}
                        game={game}
                        teams={teams}
                        post={detailChoice} 
                        isOpen={editOpen}
                        onChanged={() => {
                            if (onChanged) {
                                onChanged()
                            }
                        }} 
                        onCloseClick={() => {
                            this.setState({editOpen: false})
                        }} />
                )}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderPosts()}
            </div>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
}

const connectedApp = connect(mapState)(GameNewsFeed);
export { connectedApp as GameNewsFeed };
