import React, { Component } from "react";
import {
    Button, CardImg, Row, Col, Spinner
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { getSubSeasonTable } from 'utils/Utils';
import { STATUS_FINISHED } from "utils/constants";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export class LeagueRoundOngoing extends Component {
    constructor(props) {
        super(props);
        const subSeason = props.subSeason;
        const division = props.division;
        const teams = props.subSeason;
        const rankingData = [];
        if (subSeason != null && subSeason.teamIds != null) {
            let pointTable = getSubSeasonTable(division, subSeason);
            let pointItems = Object.values(pointTable);
            pointItems.sort((x,y)=>{
                const a = teams[x.id] != null ? teams[x.id] : {};
                const b = teams[y.id] != null ? teams[y.id] : {};
                if (a.title === b.title) 
                    return 0;
    
                return a.title < b.title ? -1 : 1;
            })
            pointItems.sort((a,b)=> {
                return b.points - a.points;
            })
            let ranking = 0;
            for (const item of pointItems) {
                rankingData.push({id: item.id, points: item.points, ranking: ranking ++});
            }
        }
        if (props.onSaveClicked != null) {
            props.onSaveClicked(rankingData);
        }
        this.state = {
            editOpen: false,
            doubleAllow: false,
            saveProgress: false,
            rankingData: rankingData
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.initRanking = this.initRanking.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {subSeason} = this.props;

        if (subSeason !== prevProps.subSeason) {
            this.initRanking();
        }
    }

    initRanking() {
        const {subSeason, division, teams, onSaveClicked} = this.props;

        const rankingData = [];
        if (subSeason != null && subSeason.teamIds != null) {
            let pointTable = getSubSeasonTable(division, subSeason);
            let pointItems = Object.values(pointTable);
            pointItems.sort((x,y)=>{
                const a = teams[x.id] != null ? teams[x.id] : {};
                const b = teams[y.id] != null ? teams[y.id] : {};
                if (a.title === b.title) 
                    return 0;
    
                return a.title < b.title ? -1 : 1;
            })
            pointItems.sort((a,b)=> {
                return b.points - a.points;
            })
            let ranking = 0;
            for (const item of pointItems) {
                rankingData.push({id: item.id, points: item.points, ranking: ranking ++});
            }
        }
        if (onSaveClicked != null) {
            onSaveClicked(rankingData);
        }
        this.setState({
            editOpen: false,
            doubleAllow: false,
            saveProgress: false,
            rankingData: rankingData
        });
    }
    
    onDragEnd(result) {
        const {season, editOngoing, onSaveClicked} = this.props;
        // dropped outside the list
        if (season.status === STATUS_FINISHED) {
            alert('You can not change ranking of finished season');
            return;
        }
        if (!editOngoing) {
            alert('You have to activate edit mode');
            return;
        }
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.rankingData,
            result.source.index,
            result.destination.index
        );
        let ranking = 0;
        for (const item of items) {
            item.ranking = ranking ++;
        }

        if (onSaveClicked != null) {
            onSaveClicked(items);
        }
        this.setState({
            rankingData: items
        });
    }

    renderRankingTable() {
        const {subSeason} = this.props;
        const {rankingData} = this.state;
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Ranking / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={5} xs={5} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Pts
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                W
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                L
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                G
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                GD
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                GP
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'transparent',
                                        border: '1px solid rgb(87, 87, 87,0.15)',
                                        boxShadow: '0 0 5px rgba(0,0,0,0.15)',
                                        padding: '0px 0px',
                                    }}
                                >
                                {rankingData.map((ranking, index) => (
                                    <Draggable key={ranking.id} draggableId={ranking.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style=
                                                {{
                                                    userSelect: 'none',
                                                    padding: 2,
                                                    margin: '0 5px',
                                                    background: snapshot.isDragging ? 'lightgreen' : 'transparent',
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                {this.renderBadge(ranking)}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        );
    }

    renderBadge(item) {
        const {division, subSeason, teams} = this.props;
        const team = teams[item.id] != null ? teams[item.id] : {};
        let pointTable = getSubSeasonTable(division, subSeason);
        const val = pointTable[item.id] != null ? pointTable[item.id] : {};

        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;

        return (
            <div>
                <Row>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.ranking + 1}
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} xs={5} sm={5} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {team.title}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {val.points}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {val.win}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {val.loss}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {val.scores}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {val.scoreDiff}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {val.gamePlayed}
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
            </div>
        );
    }

    render() {
        const {editOngoing, progress, onSaveClicked} = this.props;
        const {rankingData} = this.state;
        
        return (
            <div style={{marginTop: 10, marginBottom: 20}} >
                {this.renderRankingTable()}
                
                <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                {editOngoing && (
                    <div>
                        {progress ? (
                            <div>
                                <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                    <Spinner color="primary" />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className='text-center row-badge-info' style={{marginBottom: 10}}>
                                    You can edit League Round's ranking and go further.
                                </div>
                                <div className='text-center'>
                                    <Button className='btn-submit' onClick={ e => {
                                        if (onSaveClicked != null) {
                                            onSaveClicked(rankingData);
                                        }
                                    }}>Submit Ranking</Button>
                                    <Button className='btn-cancel' onClick={ e => {
                                        this.initRanking();
                                    }}>Reset Ranking</Button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default LeagueRoundOngoing;