import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AddContentBadge from 'components/detail/AddContentBadge';
import defaultImage from 'assets/img/icons/video_default.png';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';

export class ClubWorkoutEditView extends Component {
    constructor(props) {
        super(props);
        const clubWorkout = {};
        if (props.clubWorkout != null) {
            for (const [key, value] of Object.entries(props.clubWorkout)) {
                clubWorkout[key] = value;
            }
        }
        
        this.state = {
            clubWorkout: clubWorkout,
            progress: false,
            newImage: {},
            imageProgress: 0,
            value: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubWorkout} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.clubWorkout !== clubWorkout)) {
            const lessonVal = {};
            for (const [key, value] of Object.entries(clubWorkout)) {
                lessonVal[key] = value;
            }
            this.setState({
                clubWorkout: lessonVal
            })
        }
    }

    onSaveClick(clubWorkout, newImage) {
        const {uid, clubPlan} = this.props;
        const objRef = this;
        if (clubWorkout.title == null || clubWorkout.title === '') {
            alert("You must input title");
        } else if (clubWorkout.description == null || clubWorkout.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubWorkout.id == null || clubWorkout.id === '') {
                clubWorkout.id = generateId("/clubWorkout");
                clubWorkout.owner = uid != null ? uid : "free";
            }
            clubWorkout.planId = clubPlan != null ? clubPlan.id : '';
            clubWorkout.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(clubWorkout, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    clubWorkout: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(clubWorkout, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubWorkout.id, 'clubWorkout', newImage, (url) => {
                clubWorkout.thumbUri = url;
                objRef.saveChange(clubWorkout, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(clubWorkout, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    clubWorkout: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(clubWorkout, success, failure) {
        writeDb('/clubWorkout/' + clubWorkout.id, clubWorkout, error => {
            if (error) {
                failure(error);
            } else {
                success(clubWorkout);
            }
        })
    }

    renderContents() {
        const { clubLessons } = this.props;
        const { clubWorkout, value } = this.state;

        const result = [];
    
        for (const[key, item] of Object.entries(clubLessons)) {
    
            if ((value === 0 && clubWorkout.lessonIds != null && Object.values(clubWorkout.lessonIds).includes(item.id)) ||
            (value === 1 && (clubWorkout.lessonIds == null || !Object.values(clubWorkout.lessonIds).includes(item.id)))) {
                result.push(
                    <AddContentBadge 
                        key={key}
                        title={item.title}
                        thumbUri={item.thumbUri}
                        defaultImage={defaultImage}
                        toAdd={value === 1}
                        onClickAdd={() => {
                            if (clubWorkout.lessonIds == null) {
                                clubWorkout.lessonIds = {}
                            }
                            clubWorkout.lessonIds[key] = key;
                            this.setState({clubWorkout: clubWorkout});
                        }}
                        onClickRemove={() => {
                            if (clubWorkout.lessonIds != null) {
                                delete clubWorkout.lessonIds[key];
                                this.setState({clubWorkout: clubWorkout});
                            }
                        }} />
                );
            }
        }

        return result;
    }

    render() {
        const {onCloseClick} = this.props;
        const {clubWorkout, newImage, imageProgress, progress, value} = this.state;
        
        if (clubWorkout != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubWorkout.title = e.target.value;
                            this.setState({clubWorkout: clubWorkout});
                        }} value={clubWorkout.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubWorkout.description} onChange={e => {
                            clubWorkout.description = e.target.value;
                            this.setState({clubWorkout: clubWorkout});
                        }} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubWorkout.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={value}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                            onChange={(e, value) => {
                                this.setState({value: value});
                            }} >

                            <Tab label="Lessons" />
                            <Tab label="To Add" />
                        </Tabs>
                    </Box>
                    {this.renderContents()}

                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubWorkout, newImage);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubWorkoutEditView);