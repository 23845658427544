import React, { Component } from "react";
import {
    CardImg, Button, Col, Row
} from 'reactstrap';
import {
    toDateString,
} from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import teamsImage from 'assets/img/icons/shield_icon.png';
import gameImage from 'assets/img/icons/games_icon.png';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import ongoingIcon from 'assets/img/icons/attendance_ongoing.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import { STATUS_FINISHED, STATUS_REGISTER } from "utils/constants";

export class OrganizationSeasonRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {divisions, season, onClickDetail } = this.props;
        const division = divisions[season.divisionId];
        const thumbUri = division.thumbUri != null ? division.thumbUri : defaultImage;
        const pendingCount = 0;
        const teamCount = season.teamIds != null ? Object.values(season.teamIds).length : 0;
        
        if (division != null) {
            return (
                <Row>
                    <Col lg={3} xs={3} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbUri} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {division.title}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center">
                        <h4 className='row-badge-title text-center'>
                        {season != null && season.title != null ? season.title : 'Invalid'}
                        </h4>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center text-center">
                        <div className='wrap-content-parent' style={{margin: 'auto'}}>
                            <CardImg
                                className="row-badge-icon-small"
                                src={gameImage} />
                            <h4 className='wrap-content-wrap-child row-badge-title'>
                            {pendingCount}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent' style={{margin: 'auto'}}>
                            <CardImg
                                className="row-badge-icon-small"
                                src={teamsImage} />
                            <h4 className='wrap-content-wrap-child row-badge-title'>
                            {teamCount}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='row-badge-title text-center'>
                        {toDateString(season.startTime)}
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="row-badge-icon-small" style={{margin: 'auto'}}
                                src={season.status == null || season.status === STATUS_REGISTER ? pendingIcon : (season.status === STATUS_FINISHED ? verifiedIcon : ongoingIcon)} />
                        </div>
                    </Col>
                    <Col lg={3} xs={12} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                    if (onClickDetail != null) {
                                        onClickDetail();
                                    }
                                }}>
                                View
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default OrganizationSeasonRow;