import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import MembersView from 'components/members/MembersView';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class ClubPlanEdit extends Component {
    constructor(props) {
        super(props);
        const clubPlan = {};
        if (props.clubPlan != null) {
            for (const [key, value] of Object.entries(props.clubPlan)) {
                clubPlan[key] = value;
            }
        }
        
        this.state = {
            clubPlan: clubPlan,
            progress: false,
            newImage: {},
            imageProgress: 0,
            value: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubPlan} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.clubPlan !== clubPlan)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(clubPlan)) {
                challengeVal[key] = value;
            }
            this.setState({
                clubPlan: challengeVal
            })
        }
    }

    onSaveClick(clubPlan, newImage) {
        const {uid, onCloseClick} = this.props;
        if (clubPlan.title == null || clubPlan.title === '') {
            alert("You must input title");
        } else if (clubPlan.description == null || clubPlan.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubPlan.id == null || clubPlan.id === '') {
                clubPlan.id = generateId("/clubPlan");
                clubPlan.owner = uid != null ? uid : "free";
            }
            clubPlan.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(clubPlan, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        }
    }

    saveChangeWithImage(clubPlan, newImage, success) {
        const objRef = this;
        const {onCloseClick} = this.props;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubPlan.id, 'clubPlan', newImage, (url) => {
                clubPlan.thumbUri = url;
                objRef.saveChange(clubPlan, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    if (onCloseClick != null) {
                        onCloseClick();
                    }
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        } else {
            this.saveChange(clubPlan, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        }
    }

    saveChange(clubPlan, success, failure) {
        writeDb('/clubPlan/' + clubPlan.id, clubPlan, error => {
            if (error) {
                failure(error);
            } else {
                success(clubPlan);
            }
        })
    }

    render() {
        const {club, onCloseClick} = this.props;
        const {clubPlan, newImage, imageProgress, progress} = this.state;
        
        if (clubPlan != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubPlan.title = e.target.value;
                            this.setState({clubPlan: clubPlan});
                        }} value={clubPlan.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubPlan.description} onChange={e => {
                            clubPlan.description = e.target.value;
                            this.setState({clubPlan: clubPlan});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <div>Members: {clubPlan.members != null ? Object.values(clubPlan.members).length : 0}</div>
                        <div className='divider'/>
                        <MembersView
                            available={club.playerIds}
                            members={clubPlan.members}
                            onSaveClick={(members)=> {
                                clubPlan.members = {};
                                if (members != null) {
                                    for (const[key, value] of Object.entries(members)) {
                                        clubPlan.members[key] = value;
                                    }
                                }
                                this.setState({clubPlan: clubPlan});
                            }} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubPlan.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubPlan, newImage);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubPlanEdit);