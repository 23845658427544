import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
    Row,
    Col,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ListGroup,
    ListGroupItem,
    Button,
    CardImg
} from 'reactstrap';

import { t } from 'i18next';

import { userActions } from 'redux/_actions';
import { 
    GiSoccerKick
} from 'react-icons/gi';

import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import defaultClubIcon from 'assets/img/icons/club_icon_default.png';
import playerIcon from 'assets/img/icons/player_photo_default.png';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import PlayerFormDetail from 'components/detail/organization/PlayerFormDetail';
import LoadingView from 'components/detail/loading/LoadingView';

import { 
    LEAGUE_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    getOrgProfileData,
} from 'utils/Utils';
import ProfileEdit from 'components/edit/ProfileEdit';
import { withRouter } from 'utils/Routher';
import { generateId, invokeHttpsApi } from 'utils/API';


class PlayersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: {},
            organizations: {},
            organization: {},
            profileData: {},
            teams: {},
            teamsToPick: {},
            users: {},
            category: {},
            profileChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            searchStr: null,
            teamFilter: null,
            statusFilter: null,
            pickTeam: false,
            pickStatus: false
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        if (this.props.user) {
            this.loadContents(id);
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        const id = this.props.router.params.id;
        if (!prevUid && uid) {
            this.loadContents(id);
        }
    }

    loadContents(id) {
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizationContentsQuery', {}, (data) => {
            const locations = {};
            const organizations = {};
            let organization = {};
            const profileData = {};
            const profileChoice = {};
            let detailOpen = false;
            const teams = {};
            const teamsToPick = {};
            const users = {};
            const seasons = {};
            const category = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                    if (organization != null && organization.teamIds != null && Object.values(organization.teamIds).includes(key)) {
                        teamsToPick[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }
            if (id != null && profileData[id] != null) {
                for (const [key, value] of Object.entries(profileData[id])) {
                    profileChoice[key] = value;
                }
                detailOpen = true;
            }

            this.setState({
                locations: locations,
                organizations: organizations,
                organization: organization,
                profileData: profileData,
                profileChoice: profileChoice,
                detailOpen: detailOpen,
                teams: teams,
                teamsToPick: teamsToPick,
                users: users,
                seasons: seasons,
                category: category,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderFilter() {
        const {searchStr, teamFilter, statusFilter} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Row>
                        <Col md={4} sm={12} xs={12}>
                            <Input type="text" name="search" id="search" placeholder='Search by Player Name ...' onChange={e => {
                                this.setState({searchStr: e.target.value});
                            }} value={searchStr} />
                        </Col>
                        <Col md={4} sm={6} xs={12} >
                            <div style={{marginTop: 8}} onClick={e=> {
                                this.setState({pickTeam: true})
                            }}>
                                <img 
                                src={teamFilter != null && teamFilter.iconUri != null ? teamFilter.iconUri : defaultClubIcon} 
                                className='items-navigation-icon' alt="logo" />
                                <h3 className='items-navigation-title'>
                                {teamFilter != null ? teamFilter.title : "All Teams"}
                                </h3>
                            </div>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                            <div style={{marginTop: 8}} onClick={e=> {
                                this.setState({pickStatus: true})
                            }}>
                                <h3 className='items-navigation-title'>
                                {statusFilter != null ? statusFilter.toUpperCase() : "All Status"}
                                </h3>
                            </div>
                        </Col>
                    </Row>
                </div>                        
            </div>
        )
    }

    renderPlayerItem(uid, forRoster) {
        const {user} = this.props;
        const {organization, profileData, users} = this.state;

        const profile = getOrgProfileData(organization, uid, profileData);

        if (users[uid]) {
            const userData = users[uid]
            return (
                <div>
                    <Row>
                        <Col lg={8} xs={7} sm={8} className="vertical-center">
                            <div className='wrap-content-parent'>
                                <CardImg
                                    className="wrap-content-wrap-child row-badge-icon"
                                    src={userData.photoUri ? userData.photoUri : playerIcon} />
                                <h4 className='wrap-content-fill-child row-badge-title'>
                                {userData.username}
                                </h4>
                            </div>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <div className='wrap-content-parent'>
                                <CardImg
                                    className="row-badge-icon-small" style={{margin: 'auto'}}
                                    src={profile ? (profile.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                            </div>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                    {user.uid === userData.owner && (
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        this.setState({editUser: userData})
                                    }}>
                                    {t('edit')}
                                    </Button>
                                    )}
                                    {profile && (
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        this.setState({detailOpen: true, profileChoice: profile})
                                    }}>
                                    {t('view')}
                                    </Button>
                                    )}
                                    {forRoster && (
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        
                                    }}>
                                    {t('roster')}
                                    </Button>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='divider' />
                </div>
            )
        } else {
            return <div/>
        }
    }

    renderPlayers() {
        const {user} = this.props;
        const {seasons, profileData, users, searchStr, teamFilter, statusFilter} = this.state;
        
        let pending = [];
        let denied = [];
        let members = [];
        for (const val of Object.values(profileData)) {
            if (searchStr != null && searchStr.length > 0 && !val.playerName.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (teamFilter != null && teamFilter.id !== val.teamId) {
                continue;
            }
            if (val.verified == null) {
                if (statusFilter != null && statusFilter !== 'pending') {
                    continue;
                }
                pending.push(val)
            }
            if (val.verified === false) {
                if (statusFilter != null && statusFilter !== 'denied') {
                    continue;
                }
                denied.push(val)
            }
            if (val.verified === true) {
                if (statusFilter != null && statusFilter !== 'member') {
                    continue;
                }
                members.push(val)
            }
        }
        pending.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        denied.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        members.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        let profileIds = [];

        let toAdd = [];
        for (const item of pending) {
            profileIds.push(item.uid);
            toAdd.push(item);
        }
        for (const item of denied) {
            profileIds.push(item.uid);
            toAdd.push(item);
        }
        for (const item of members) {
            profileIds.push(item.uid);
            toAdd.push(item);
        }
        let registerIds = [];
        let registerUsers = [];
        for (const season of Object.values(seasons)) {
            if (season.registers) {
                for (const item of Object.values(season.registers)) {
                    if (!profileIds.includes(item.uid) && !registerIds.includes(item.uid)) {
                        registerIds.push(item.uid);
                        registerUsers.push(users[item.uid]);
                    }
                }
            }
            if (season.teamData) {
                for (const teamData of Object.values(season.teamData)) {
                    if (teamData.roster) {
                        for (const uid of Object.values(teamData.roster)) {
                            if (!profileIds.includes(uid) && !registerIds.includes(uid)) {
                                registerIds.push(uid);
                                registerUsers.push(users[uid]);
                            }
                        }
                    }
                }
            }
        }
        registerUsers.sort(function(a, b) {
            return b.username - a.username;
        });
        let owned = [];
        for (const item of Object.values(users)) {
            if (!profileIds.includes(item.uid) && !registerIds.includes(item.uid) && item.owner === user.uid) {
                owned.push(item);
            }
        }
        owned.sort(function(a, b) {
            return b.username - a.username;
        });
        let items = [];
        for (const val of toAdd) {
            items.push(
                this.renderPlayerItem(val.uid)
            )
        }

        for (const item of registerUsers) {

            items.push(
                this.renderPlayerItem(item.uid, true)
            )
        }
        for (const item of Object.values(owned)) {
            items.push(
                this.renderPlayerItem(item.uid)
            )
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title' style={{marginTop: 30, marginBottom: 30}}>
                </h3>
                {this.renderFilter()}
                <div className='shadow-tile'>
                    <Row>
                        <Col lg={8} xs={10} sm={8} className="vertical-center">
                            <h3 className='row-table-title'>
                                Player
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Eligibility
                            </h3>
                        </Col>
                        <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items}
                </div>
            </div>
        );
    }

    renderContents() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {organization, editUser, users, teams, teamsToPick, pickTeam, pickStatus, profileChoice, detailOpen} = this.state;
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LEAGUE_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }

        if (detailOpen) {
            return (
                <PlayerFormDetail 
                    profileData={profileChoice}
                    organization={organization}
                    uid={user.uid}
                    users={users}
                    teams={teams}
                    onCloseClick={()=>{
                        this.setState({detailOpen: false, profileChoice: {}})
                    }}
                    onSaveDone={()=> {
                        this.loadContents()
                    }} />
            );
        } else {
            return (
            <div>
                {this.renderPlayers()}
                <Modal isOpen={pickTeam}>
                    <ModalBody>
                        <ListGroup>
                            <ListGroupItem onClick={e=> {
                                this.setState({pickTeam: false, teamFilter: null})
                            }}>All Teams</ListGroupItem>
                            {Object.values(teamsToPick).map((value, idx)=>(
                                <ListGroupItem onClick={e=> {
                                    this.setState({pickTeam: false, teamFilter: value})
                                }}>{value.title}</ListGroupItem>
                            ))
                            }
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-cancel" onClick={e => {
                            this.setState({pickTeam: false})
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
                {editUser && (
                <Modal isOpen={true}>
                    <ModalBody>
                        <ProfileEdit 
                            profile={editUser}
                            isOpen={true}
                            onSaveSuccess={() => {
                                this.setState({editUser: null});
                            }}
                            onCloseClick={() => {
                                this.setState({editUser: null});
                            }} />
                    </ModalBody>
                </Modal>
                )}
                <Modal isOpen={pickStatus}>
                    <ModalBody>
                        <ListGroup>
                            <ListGroupItem onClick={e=> {
                                this.setState({pickStatus: false, statusFilter: null})
                            }}>All Status</ListGroupItem>
                            <ListGroupItem onClick={e=> {
                                this.setState({pickStatus: false, statusFilter: 'member'})
                            }}>Members</ListGroupItem>
                            <ListGroupItem onClick={e=> {
                                this.setState({pickStatus: false, statusFilter: 'denied'})
                            }}>{t('rejected')}</ListGroupItem>
                            <ListGroupItem onClick={e=> {
                                this.setState({pickStatus: false, statusFilter: 'pending'})
                            }}>Pending</ListGroupItem>
                        </ListGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-cancel" onClick={e => {
                            this.setState({pickStatus: false})
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
            );
        }
    }

    render() {
        const {user} = this.props;
        const {organization, detailOpen, profileChoice, profileData, loading} = this.state;

        if (user == null) {
            return <div/>
        }
        const curConetntCount = Object.values(profileData).length;
        const choice = {};
        if (profileChoice.uid != null) {
            choice.id = profileChoice.uid;
            choice.title = profileChoice.username;
        }

        return (
            <div>
                
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title="Player Forms"
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={false}
                        totalCount={curConetntCount}
                        detailItem={choice}
                        onClickAdd={() => {
                            this.setState({editUser: {
                                uid: generateId("/user"),
                                owner: user.uid,
                                organizationId: organization.id,
                            }})
                        }}
                        onClickBack={()=>{
                            this.setState({profileChoice: {}, detailOpen: false})
                        }} />
                </div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(PlayersPage));
export { connectedApp as PlayersPage };
