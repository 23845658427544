import React, { Component } from "react";
import {
    CardImg, Button, Row, Input, Spinner
} from 'reactstrap';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { MdWarning, MdCheck, MdDelete, MdEdit, MdAdd, MdRefresh } from 'react-icons/md';

import userImage from 'assets/img/icons/player_photo_default.png';
import teamImage from 'assets/img/icons/club_icon_default.png';
import AttachItemView from 'components/detail/AttachItemView';
import TeamPlayerEdit from 'components/edit/TeamPlayerEdit';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';
import { generateId, invokeHttpsApi, saveSeason } from "utils/API";
import { SeasonTeamImport } from "components/detail/organization/SeasonTeamImport";
import { sortByUsername, toDateString } from "utils/Utils";
import { OrgGameBadge } from "./landing/OrgGameBadge";

class SeasonTeamDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainTab: 0,
            editOpen: false,
            saveProgress: false,
            changed: false
        }
    }

    saveTeamData(season, teamData) {
        invokeHttpsApi('clubQuery-saveSeasonTeamDataChange', {
            seasonId: season.id,
            teamId: teamData.id,
            teamData: teamData,
        }, (data) => {
            if (data && data.success) {
                alert(t('saved_successfully'))
            } else {
                alert(t('save_failed'));
            }
            this.setState({progress: false, fileProgress: 0});
        }, (error) => {
            console.log('saveContents error', error);
            alert(t('save_failed'));
            this.setState({progress: false, fileProgress: 0});
        });
    }

    saveTeamGameData(seasonId, teamId, gameUpdate) {
        invokeHttpsApi('clubQuery-saveSeasonGameLineupDataChange', {
            seasonId: seasonId,
            teamId: teamId,
            gameId: gameUpdate.id,
            gameUpdate: gameUpdate,
        }, (data) => {
            if (data && data.success) {
                alert(t('saved_successfully'))
            } else {
                alert(t('save_failed'));
            }
            this.setState({progress: false, fileProgress: 0});
        }, (error) => {
            console.log('saveContents error', error);
            alert(t('save_failed'));
            this.setState({progress: false, fileProgress: 0});
        });
    }

    renderPlayer() {
        const {season, teamChoice, users} = this.props;
        const {userEdit} = this.state;
        
        let playerList = [];
        for (const uid of Object.values(teamChoice.playerIds ? teamChoice.playerIds : {})) {
            if (users[uid]) {
                playerList.push(users[uid]);
            }
        }
        sortByUsername(playerList);

        let playerItems = [];
        for (const val of playerList) {
            playerItems.push(
               <div> {this.renderPlayerItem(val)}</div>
            )
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <div className="wrap-content-fill-child">
                            <h3 className='row-table-title'>
                                Players
                            </h3>
                        </div>
                        <div className="wrap-content-wrap-child">
                            <Button className="btn-cancel" onClick={(e) => {
                                
                                this.setState({userEdit: {
                                    uid: generateId("/user"),
                                    owner: teamChoice.owner,
                                }});
                            }}><MdAdd/></Button>
                        </div>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {userEdit ? (
                        <TeamPlayerEdit 
                            profile={userEdit}
                            seasonId={season.id}
                            teamId={teamChoice.id}
                            onSaveSuccess={() => {
                                alert("Saving user update success.");
                            }}
                            onSavePrompt={(profile) => {
                                if (!teamChoice.playerIds) {
                                    teamChoice.playerIds = {};
                                }
                                teamChoice.playerIds[profile.uid] = profile.uid;
                                
                                if (this.props.onUserUpdate) {
                                    this.props.onUserUpdate(profile, teamChoice);
                                }
                                this.setState({userEdit: profile});
                            }}
                            onCloseClick={() => {
                                this.setState({userEdit: null})
                            }} />
                    ) : (
                        <div>
                        {playerItems.length > 0 ? playerItems : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Users In Player List
                            </div>
                        )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderGames() {
        const {season, teamChoice, teams, organization, locations, users} = this.props;
        let list = [];
        let games = [];

        if (season.subSeasons != null) {
            for (const [, val] of Object.entries(season.subSeasons)) {
                if (val.games != null)  {
                    for (const game of Object.values(val.games)) {
                        if (game.homeTeam === teamChoice.id || game.awayTeam === teamChoice.id) {
                            games.push(game);
                        }
                    }
                }
            }
        }

        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        for (const game of games) {
            list.push(
                <div key={game.id}>
                    <OrgGameBadge
                        game={game}
                        teams={teams}
                        organization={organization}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true} />
                </div>
            )
        }
        
        return (
            <div style={{maxHeight: 500, overflowY: 'auto', overflowX: 'hidden', margin: '15px 0px'}}>
                {list}
            </div>
        );
    }

    renderRoster() {
        const {season, teamChoice, users} = this.props;
        const {rosterAdd, staffAdd} = this.state;
        const teamData = season.teamData && season.teamData[teamChoice.id] ? season.teamData[teamChoice.id] : {};

        let rosterList = [];
        let rosterToAdd = [];
        for (const uid of Object.values(teamChoice.playerIds ? teamChoice.playerIds : {})) {
            if (!teamData.roaster || !Object.values(teamData.roaster).includes(uid)) {
                if (users[uid]) {
                    rosterToAdd.push(users[uid]);
                }
            }
        }
        let staffToAdd = [];
        for (const uid of Object.values(teamChoice.managers ? teamChoice.managers : {})) {
            if (!teamData.stuff || !Object.values(teamData.stuff).includes(uid)) {
                if (users[uid]) {
                    staffToAdd.push(users[uid]);
                }
            }
        }
        if (teamData.roaster) {
            for (const uid of Object.values(teamData.roaster)) {
                if (users && users[uid]) {
                    rosterList.push(users[uid]);
                }
            }
        }
        let stuffList = [];
        if (teamData.stuff) {
            for (const uid of Object.values(teamData.stuff)) {
                if (users && users[uid]) {
                    stuffList.push(users[uid]);
                }
            }
        }
        sortByUsername(rosterToAdd);
        sortByUsername(staffToAdd);
        sortByUsername(rosterList);
        sortByUsername(stuffList);

        let rosterItems = [];
        for (const val of rosterList) {
            rosterItems.push(
               <div> {this.renderRosterItem(val)}</div>
            )
        }
        let stuffItems = [];
        for (const val of stuffList) {
            stuffItems.push(
                <div> {this.renderRosterItem(val, true)}</div>
            )
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                            {t('roster')}
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <div className="wrap-content-parent" style={{backgroundColor: '#FFFFFF', margin: '10px 10px'}}>
                        <div className="wrap-content-fill-child">
                            <Input id="user" type="select" value={rosterAdd}  onChange={e=> {
                                this.setState({rosterAdd: e.target.value});
                            }}>
                                <option value={null}>User to add to roster</option>
                                {rosterToAdd.map((user)=> (
                                    <option value={user.uid}>{user.username}</option>
                                ))}
                            </Input>
                        </div>
                        <div className="wrap-content-wrap-child">
                            <Button className="btn-submit" onClick={(e) => {
                                if (!rosterAdd) {
                                    return;
                                }
                                if (!season.teamData) {
                                    season.teamData = {};
                                }
                                if (!season.teamData[teamChoice.id]) {
                                    season.teamData[teamChoice.id] = {
                                        id: teamChoice.id,
                                        nickname: teamChoice.title,
                                        roaster: {},
                                    };
                                }
                                if (!season.teamData[teamChoice.id].roaster) {
                                    season.teamData[teamChoice.id].roaster = {};
                                }
                                season.teamData[teamChoice.id].roaster[rosterAdd] = rosterAdd;
                                if (this.props.onSeasonUpdate) {
                                    this.props.onSeasonUpdate(season)
                                }
                                this.setState({changed: true});
                            }}>{t('add')}</Button>
                        </div>
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                    {rosterItems.length > 0 ? rosterItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        No Users In Roster
                        </div>
                    )}
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                            Staff
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <div className="wrap-content-parent" style={{backgroundColor: '#FFFFFF', margin: '10px 10px'}}>
                        <div className="wrap-content-fill-child">
                            <Input id="user" type="select" value={staffAdd}  onChange={e=> {
                                this.setState({staffAdd: e.target.value});
                            }}>
                                <option value={null}>User to add to staff</option>
                                {staffToAdd.map((user)=> (
                                    <option value={user.uid}>{user.username}</option>
                                ))}
                            </Input>
                        </div>
                        <div className="wrap-content-wrap-child">
                            <Button className="btn-submit" onClick={(e) => {
                                if (!staffToAdd) {
                                    return;
                                }
                                if (!season.teamData) {
                                    season.teamData = {};
                                }
                                if (!season.teamData[teamChoice.id]) {
                                    season.teamData[teamChoice.id] = {
                                        id: teamChoice.id,
                                        nickname: teamChoice.title,
                                        stuff: {},
                                    };
                                }
                                if (!season.teamData[teamChoice.id].stuff) {
                                    season.teamData[teamChoice.id].stuff = {};
                                }
                                season.teamData[teamChoice.id].stuff[staffToAdd] = staffToAdd;
                                if (this.props.onSeasonUpdate) {
                                    this.props.onSeasonUpdate(season)
                                }
                                this.setState({changed: true});
                            }}>{t('add')}</Button>
                        </div>
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                    {stuffItems.length > 0 ? stuffItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        No Users In Staff
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderLineUp() {
        const {season, teamChoice, users, teams} = this.props;
        const {lineupAdd, benchAdd, lineUpGameId} = this.state;
        const teamData = season.teamData && season.teamData[teamChoice.id] ? season.teamData[teamChoice.id] : {};
        let lineupDict = teamData.gameList ? teamData.gameList : {};
        let benchDict = teamData.benchList ? teamData.benchList : {};
        let gameDict = {};
        let gameArr = [];
        if (season.subSeasons) {
            for (const subSeason of Object.values(season.subSeasons)) {
                if (subSeason.games) {
                    for (const game of Object.values(subSeason.games)) {
                        if (teamChoice.id === game.homeTeam || teamChoice.id === game.awayTeam) {
                            let oppTitle = null;
                            if (teamChoice.id === game.homeTeam && teams[game.awayTeam]) {
                                oppTitle = teams[game.awayTeam].title;
                            }
                            if (teamChoice.id === game.awayTeam && teams[game.homeTeam]) {
                                oppTitle = teams[game.homeTeam].title;
                            }
                            game.title = "Game of " + subSeason.title + " round on " + toDateString(game.start);
                            if (oppTitle) {
                                game.title += " with team " + oppTitle;
                            }
                            gameDict[game.id] = game;
                            gameArr.push(game);
                        }
                    }
                }
            }
        }
        gameArr.sort((a,b) => {
            return a.start - b.start;
        });
        if (lineUpGameId && gameDict[lineUpGameId]) {
            if (gameDict[lineUpGameId].gameList && gameDict[lineUpGameId].gameList[teamChoice.id]) {
                lineupDict = {};
                for (const uid of Object.values(gameDict[lineUpGameId].gameList[teamChoice.id])) {
                    lineupDict[uid] = uid;
                }
            }
            if (gameDict[lineUpGameId].benchList && gameDict[lineUpGameId].benchList[teamChoice.id]) {
                benchDict = {};
                for (const uid of Object.values(gameDict[lineUpGameId].benchList[teamChoice.id])) {
                    benchDict[uid] = uid;
                }
            }
        }

        let gameList = [];
        let lineupToAdd = [];
        
        for (const uid of Object.values(teamData.roaster ? teamData.roaster : {})) {
            if (!Object.values(lineupDict).includes(uid)) {
                if (users[uid]) {
                    lineupToAdd.push(users[uid]);
                }
            }
        }
        let benchToAdd = [];
        for (const uid of Object.values(teamData.roaster ? teamData.roaster : {})) {
            if (!Object.values(benchDict).includes(uid)) {
                if (users[uid]) {
                    benchToAdd.push(users[uid]);
                }
            }
        }
        for (const uid of Object.values(lineupDict)) {
            if (users && users[uid]) {
                gameList.push(users[uid]);
            }
        }
        let benchList = [];
        for (const uid of Object.values(benchDict)) {
            if (users && users[uid]) {
                benchList.push(users[uid]);
            }
        }
        sortByUsername(lineupToAdd);
        sortByUsername(benchToAdd);
        sortByUsername(gameList);
        sortByUsername(benchList);

        let gameItems = [];
        for (const val of gameList) {
            gameItems.push(
               <div> {this.renderLineupItem(val, true, gameDict, lineUpGameId)}</div>
            )
        }
        let benchItems = [];
        for (const val of benchList) {
            benchItems.push(
                <div> {this.renderLineupItem(val, false, gameDict, lineUpGameId)}</div>
            )
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <div className='divider' />
                    <div className="wrap-content-parent" style={{backgroundColor: '#FFFFFF', margin: '10px 10px'}}>
                        <div className="wrap-content-fill-child">
                            <Input id="user" type="select" value={lineUpGameId}  onChange={e=> {
                                const update = e.target.value;
                                this.setState({lineUpGameId: update === t('season') ? null : update});
                            }}>
                                <option value={null}>{t('season')}</option>
                                {gameArr.map((game)=> (
                                <option value={game.id}>{game.title}</option>
                                ))}
                            </Input>
                        </div>
                    </div>
                    <div className="wrap-content-parent" style={{backgroundColor: '#FFFFFF', margin: '10px 10px'}}>
                        <div className="wrap-content-fill-child">
                            <Input id="user" type="select" value={lineupAdd}  onChange={e=> {
                                this.setState({lineupAdd: e.target.value !== "" ? e.target.value : null});
                            }}>
                                <option value="">User to add to Lineup</option>
                                {lineupToAdd.map((user)=> (
                                    <option value={user.uid}>{user.username}</option>
                                ))}
                            </Input>
                        </div>
                        <div className="wrap-content-wrap-child">
                            <Button className="btn-submit" onClick={(e) => {
                                if (!lineupAdd) {
                                    return;
                                }
                                if (lineUpGameId) {
                                    const game = gameDict[lineUpGameId];

                                    if (!season.subSeasons[game.subSeasonId].games[game.id].gameList) {
                                        season.subSeasons[game.subSeasonId].games[game.id].gameList = {};
                                    }
                                    if (!season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id]) {
                                        season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id] = {};
                                    }
                                    season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id][lineupAdd] = lineupAdd;
                                } else {
                                    if (!season.teamData) {
                                        season.teamData = {};
                                    }
                                    if (!season.teamData[teamChoice.id]) {
                                        season.teamData[teamChoice.id] = {
                                            id: teamChoice.id,
                                            nickname: teamChoice.title,
                                            gameList: {},
                                        };
                                    }
                                    if (!season.teamData[teamChoice.id].gameList) {
                                        season.teamData[teamChoice.id].gameList = {};
                                    }
                                    season.teamData[teamChoice.id].gameList[lineupAdd] = lineupAdd;
                                }
                                if (this.props.onSeasonUpdate) {
                                    this.props.onSeasonUpdate(season)
                                }
                                this.setState({changed: true, lineupAdd: null});
                            }}>{t('add')}</Button>
                            {lineUpGameId && (
                            <Button className="btn-submit" onClick={(e) => {
                                const game = gameDict[lineUpGameId];

                                if (!season.subSeasons[game.subSeasonId].games[game.id].gameList) {
                                    season.subSeasons[game.subSeasonId].games[game.id].gameList = {};
                                }
                                if (!season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id]) {
                                    season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id] = {};
                                }
                                if (teamData.roaster) {
                                    for (const uid of Object.values(teamData.roaster)) {
                                        season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id][uid] = uid;
                                    }
                                }
                                if (this.props.onSeasonUpdate) {
                                    this.props.onSeasonUpdate(season)
                                }
                                this.setState({changed: true});
                            }}><MdRefresh /> {t('roster')}</Button>
                            )}
                            {lineUpGameId && (
                            <Button className="btn-submit" onClick={(e) => {
                                const game = gameDict[lineUpGameId];

                                if (!season.subSeasons[game.subSeasonId].games[game.id].gameList) {
                                    season.subSeasons[game.subSeasonId].games[game.id].gameList = {};
                                }
                                if (!season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id]) {
                                    season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id] = {};
                                }
                                if (teamData.gameList) {
                                    for (const uid of Object.values(teamData.gameList)) {
                                        season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id][uid] = uid;
                                    }
                                }
                                if (this.props.onSeasonUpdate) {
                                    this.props.onSeasonUpdate(season)
                                }
                                this.setState({changed: true});
                            }}><MdRefresh /> {t('lineup')}</Button>
                            )}
                        </div>
                    </div>
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                        {t('lineup')}
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {gameItems.length > 0 ? gameItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                        {t('substitute')}
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className="wrap-content-parent" style={{backgroundColor: '#FFFFFF', margin: '10px 10px'}}>
                        <div className="wrap-content-fill-child">
                            <Input id="user" type="select" value={benchAdd}  onChange={e=> {
                                this.setState({benchAdd: e.target.value});
                            }}>
                                <option value={null}>User to add to Substitute</option>
                                {benchToAdd.map((user)=> (
                                    <option value={user.uid}>{user.username}</option>
                                ))}
                            </Input>
                        </div>
                        <div className="wrap-content-wrap-child">
                            <Button className="btn-submit" onClick={(e) => {
                                if (!benchAdd) {
                                    return;
                                }
                                if (lineUpGameId) {
                                    const game = gameDict[lineUpGameId];

                                    if (!season.subSeasons[game.subSeasonId].games[game.id].benchList) {
                                        season.subSeasons[game.subSeasonId].games[game.id].benchList = {};
                                    }
                                    if (!season.subSeasons[game.subSeasonId].games[game.id].benchList[teamChoice.id]) {
                                        season.subSeasons[game.subSeasonId].games[game.id].benchList[teamChoice.id] = {};
                                    }
                                    season.subSeasons[game.subSeasonId].games[game.id].benchList[teamChoice.id][benchAdd] = benchAdd;
                                } else {
                                    if (!season.teamData) {
                                        season.teamData = {};
                                    }
                                    if (!season.teamData[teamChoice.id]) {
                                        season.teamData[teamChoice.id] = {
                                            id: teamChoice.id,
                                            nickname: teamChoice.title,
                                            benchList: {},
                                        };
                                    }
                                    if (!season.teamData[teamChoice.id].benchList) {
                                        season.teamData[teamChoice.id].benchList = {};
                                    }
                                    season.teamData[teamChoice.id].benchList[benchAdd] = benchAdd;
                                }
                                if (this.props.onSeasonUpdate) {
                                    this.props.onSeasonUpdate(season)
                                }
                                this.setState({changed: true, benchAdd: null});
                            }}>{t('add')}</Button>
                        </div>
                    </div>
                    <div className='divider' />
                    {benchItems.length > 0 ? benchItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderImport() {
        const {season, teamChoice, onChanged} = this.props;

        return (
            <SeasonTeamImport
                season={season}
                team={teamChoice}
                onChanged={()=> {
                    if (onChanged) {
                        onChanged();
                    }
                }} />
        )
    }

    renderLineupItem(item, forLineUp, gameDict, lineUpGameId) {
        const {season, teamChoice} = this.props;
        let thumbImage = item.photoUri != null ? item.photoUri : userImage;

        return (
            <Row>
                <div className='wrap-content-parent' style={{padding: '0px 10px'}}>
                    <CardImg
                        className="wrap-content-wrap-child row-badge-icon"
                        src={thumbImage} />
                    <h4 className='wrap-content-fill-child row-badge-title'>
                    {item.username}
                    </h4>
                    <div className="wrap-content-wrap-child">
                        <Button className="btn-cancel" style={{marginTop: 5}} onClick={e=> {
                            if (lineUpGameId) {
                                const game = gameDict[lineUpGameId];
                                if (forLineUp) {
                                    delete season.subSeasons[game.subSeasonId].games[game.id].gameList[teamChoice.id][item.uid];
                                } else {
                                    delete season.subSeasons[game.subSeasonId].games[game.id].benchList[teamChoice.id][item.uid];
                                }
                            } else {
                                if (forLineUp) {
                                    delete season.teamData[teamChoice.id].gameList[item.uid];
                                } else {
                                    delete season.teamData[teamChoice.id].benchList[item.uid];
                                }
                            }
                            if (this.props.onSeasonUpdate) {
                                this.props.onSeasonUpdate(season)
                            }
                            this.setState({changed: true});
                        }}><MdDelete/></Button>
                    </div>
                </div>
            </Row>
        );
    }

    renderPlayerItem(item) {
        const {season, teamChoice} = this.props;
        let thumbImage = item.photoUri != null ? item.photoUri : userImage;
        const teamData = season.teamData && season.teamData[teamChoice.id] ? season.teamData[teamChoice.id] : {};
        const playerData = teamData.profileData && teamData.profileData[item.uid] ? teamData.profileData[item.uid] : {
            uid: item.uid,
            nickname: item.nickname,
            photoUri: item.photoUri,
        };

        return (
            <Row>
                <div className='wrap-content-parent' style={{padding: '0px 10px'}}>
                    <CardImg
                        className="wrap-content-wrap-child row-badge-icon"
                        src={thumbImage} />
                    <h4 className='wrap-content-fill-child row-badge-title'>
                    {item.username}
                    </h4>
                    <div className="wrap-content-wrap-child" style={{width: 80}}>
                        <Input id="user" type="number" value={playerData.number}  onChange={e=> {
                            playerData.number = parseInt(e.target.value)
                            if (!season.teamData) {
                                season.teamData = {};
                            }
                            if (!season.teamData.profileData) {
                                season.teamData.profileData = {};
                            }
                            season.teamData.profileData[item.uid] = playerData;
                            if (this.props.onSeasonUpdate) {
                                this.props.onSeasonUpdate(season, teamChoice)
                            }
                            this.setState({changed: true});
                        }} />
                    </div>
                    {item.owner && (
                    <div className="wrap-content-wrap-child">
                        <Button className="btn-cancel" style={{marginTop: 5}} onClick={e=> {  
                            this.setState({userEdit: item});
                        }}><MdEdit/></Button>
                        <Button className="btn-cancel" style={{marginTop: 5}} onClick={e=> {
                            if (!window.confirm("Are you sure you want to remove this player? It will delete user info also so can cause some crirical issue.")) {
                                return;
                            }
                            this.setState({progress: true});
                            invokeHttpsApi('clubQuery-removeSeasonTeamPlayer', {
                                seasonId: season.id,
                                teamId: teamChoice.id,
                                playerId: item.uid,
                            }, (result) => {
                                if (result && result.data && result.data.success) {
                                    if (season.teamData && season.teamData[teamChoice.id].playerIds) {
                                        delete season.teamData[teamChoice.id].playerIds[item.uid];
                                    }
                                    if (season.teamData && season.teamData[teamChoice.id].gameList) {
                                        delete season.teamData[teamChoice.id].gameList[item.uid];
                                    }
                                    if (season.teamData && season.teamData[teamChoice.id].benchList) {
                                        delete season.teamData[teamChoice.id].benchList[item.uid];
                                    }
                                    delete teamChoice.playerIds[item.uid];
                                    
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season, teamChoice)
                                    }
                                }
                                this.setState({progress: false});
                            }, (error) => {
                                console.log('saveContents error', error);
                                this.setState({progress: false});
                            });
                            this.setState({changed: true});
                        }}><MdDelete/></Button>
                    </div>
                    )}
                </div>
            </Row>
        );
    }

    renderRosterItem(item, forStaff) {
        const {season, teamChoice} = this.props;
        let thumbImage = item.photoUri != null ? item.photoUri : userImage;

        return (
            <Row>
                <div className='wrap-content-parent' style={{padding: '0px 10px'}}>
                    <CardImg
                        className="wrap-content-wrap-child row-badge-icon"
                        src={thumbImage} />
                    <h4 className='wrap-content-fill-child row-badge-title'>
                    {item.username}
                    </h4>
                    <div className="wrap-content-wrap-child">
                        <Button className="btn-cancel" style={{marginTop: 5}} onClick={e=> {
                            if (forStaff) {
                                delete season.teamData[teamChoice.id].staff[item.uid];
                            } else {
                                delete season.teamData[teamChoice.id].roaster[item.uid];
                            }
                            if (this.props.onSeasonUpdate) {
                                this.props.onSeasonUpdate(season)
                            }
                            this.setState({changed: true});
                        }}><MdDelete/></Button>
                    </div>
                </div>
            </Row>
        );
    }

    render() {
        const {user, teamChoice, season, onClickClose} = this.props;
        const {mainTab, changed, progress, lineUpGameId} = this.state;
        const thumbImage = teamChoice.iconUri ? teamChoice.iconUri : teamImage;
        const teamData = season.teamData && season.teamData[teamChoice.id] ? season.teamData[teamChoice.id] : {};
        const allowPlayerEdit = season.owner === user.uid || season.individualReg;
        let gameDict = {};
        if (season.subSeasons) {
            for (const subSeason of Object.values(season.subSeasons)) {
                if (subSeason.games) {
                    for (const game of Object.values(subSeason.games)) {
                        if (teamChoice.id === game.homeTeam || teamChoice.id === game.awayTeam) {
                            gameDict[game.id] = game;
                        }
                    }
                }
            }
        }
        
        return (
            <div>
                <div className='wrap-content-parent' style={{padding: '0px 10px'}}>
                    <CardImg
                        className="wrap-content-wrap-child row-badge-icon"
                        src={thumbImage} />
                    <h4 className='wrap-content-fill-child row-badge-title'>
                    {teamChoice.title}
                    </h4>
                </div>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={mainTab}
                            onChange={(e,val)=> {
                                this.setState({mainTab: val})
                            }}
                            variant="scrollable"
                            indicatorColor="secondary"
                            textColor="secondary" >

                            <Tab label={t('general')} />
                            <Tab label={t('roster')} />
                            <Tab label={t('lineup')} />
                            {allowPlayerEdit && (
                            <Tab label={t('import')} />
                            )}
                        </Tabs>
                    </Box>
                </div>
                {mainTab === 0 && (
                    <div>
                        {teamData.rosterDoc && (
                        <div className='player-form-item'>
                            <div className='player-form-title'>
                                {t('roster_doc')}:
                            </div>
                            <div className='player-form-value'>
                                <AttachItemView attachFile={teamData.rosterDoc} />
                            </div>
                            {teamData.rosterDoc.verified ? (
                                <div className='player-form-status-filled' onClick={e=> {
                                    season.teamData[teamChoice.id].rosterDoc.verified = false;
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season)
                                    }
                                    this.saveSeasonUpdate(season);
                                }}>
                                    <MdCheck/> Verified
                                </div>
                            ) : (
                                <div className='player-form-status-warning' onClick={e=> {
                                    season.teamData[teamChoice.id].rosterDoc.verified = true;
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season)
                                    }
                                    this.saveSeasonUpdate(season);
                                }}>
                                    <MdWarning/> Not Verified
                                </div>
                            )}
                        </div>
                        )}
                        {teamData.travelDoc && (
                        <div className='player-form-item'>
                            <div className='player-form-title'>
                                Travel Doc:
                            </div>
                            <div className='player-form-value'>
                                <AttachItemView attachFile={teamData.travelDoc} />
                            </div>
                            {teamData.travelDoc.verified ? (
                                <div className='player-form-status-filled' onClick={e=> {
                                    season.teamData[teamChoice.id].travelDoc.verified = false;
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season)
                                    }
                                    this.saveSeasonUpdate(season);
                                }}>
                                    <MdCheck/> Verified
                                </div>
                            ) : (
                                <div className='player-form-status-warning' onClick={e=> {
                                    season.teamData[teamChoice.id].travelDoc.verified = true;
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season)
                                    }
                                    this.saveSeasonUpdate(season);
                                }}>
                                    <MdWarning/> Not Verified
                                </div>
                            )}
                        </div>
                        )}
                        {teamData.sheetDoc && (
                        <div className='player-form-item'>
                            <div className='player-form-title'>
                                {t('game_sheet_doc')}:
                            </div>
                            <div className='player-form-value'>
                                <AttachItemView attachFile={teamData.sheetDoc} />
                            </div>
                            {teamData.sheetDoc.verified ? (
                                <div className='player-form-status-filled' onClick={e=> {
                                    season.teamData[teamChoice.id].sheetDoc.verified = false;
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season)
                                    }
                                    this.saveSeasonUpdate(season);
                                }}>
                                    <MdCheck/> Verified
                                </div>
                            ) : (
                                <div className='player-form-status-warning' onClick={e=> {
                                    season.teamData[teamChoice.id].sheetDoc.verified = true;
                                    if (this.props.onSeasonUpdate) {
                                        this.props.onSeasonUpdate(season)
                                    }
                                    this.saveSeasonUpdate(season);
                                }}>
                                    <MdWarning/> Not Verified
                                </div>
                            )}
                        </div>
                        )}
                        {this.renderGames()}
                    </div>
                )}
                {mainTab === 1 && allowPlayerEdit && (
                    <div>
                    {this.renderPlayer()}
                    </div>
                )}
                {mainTab === 1 && (
                    <div>
                    {this.renderRoster()}
                    </div>
                )}
                {mainTab === 2 && (
                    <div>
                    {this.renderLineUp()}
                    </div>
                )}
                {mainTab === 3 && (
                    <div>
                    {this.renderImport()}
                    </div>
                )}
                
                <div className="text-center">
                    {changed && progress && (
                        <Spinner color="primary" />
                    )}
                    {changed && !progress && (
                    <Button className="btn-cancel" onClick={(e) => {
                        if (user.uid === season.owner) {
                            this.setState({progress: true});
                            saveSeason(season, () => {
                                alert(t('saved_successfully'))
                                this.setState({progress: false});
                            }, (error) => {
                                alert(t('save_failed'));
                                this.setState({progress: false});
                            })
                        } else {
                            const teamData = season.teamData && season.teamData[teamChoice.id] ? season.teamData[teamChoice.id] : null;
    
                            if (!lineUpGameId && teamData) {
                                this.setState({progress: true});
                                this.saveTeamData(season, teamData);
                            } else if (lineUpGameId && gameDict[lineUpGameId]) {
                                this.setState({progress: true});
                                this.saveTeamGameData(season.id, teamChoice.id, gameDict[lineUpGameId]);
                            }
                        }
                    }}>{t('save')}</Button>
                    )}
                    <Button className="btn-cancel" onClick={(e) => {
                        if (onClickClose) {
                            onClickClose();
                        }
                    }}>{t('close')}</Button>
                </div>
            </div>
        );
    }

}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(SeasonTeamDetails));
export { connectedApp as SeasonTeamDetails };