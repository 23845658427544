import React, { Component } from "react";
import {
    Button, Row, Input, Col, CardImg
} from 'reactstrap';
import { t } from 'i18next';
import Switch from '@mui/material/Switch';
import teamIcon from 'assets/img/icons/club_icon_default.png';

import { getWeekStartDayTime, copyHour, shuffle, removeArrIdx, MIN_TIME, DAY_TIME, WEEK_TIME, checkMatches, initGameLineUp, getDayStart, removeFromArr, sortByTitle } from 'utils/Utils';

import {
    MdRemove, MdWarning
} from 'react-icons/md';

import CustomTimePicker from "components/template/CustomTimePicker";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export class GroupSetupDeploy extends Component {
    constructor(props) {
        super(props);
        const locationTimeSets = {};
        for (const val of props.groupTeams) {
            locationTimeSets[val.id] = [];
            
            const locationTimeSet = [];
            if (props.subSeason != null && props.subSeason.teamIds != null) {
                let groupTeams = 0;
                if (props.groupTeams != null) {
                    for (const item of props.groupTeams) {
                        if (item.teams.length > groupTeams) {
                            groupTeams = item.teams.length;
                        }
                    }
                }
                const slotCount = Math.ceil(groupTeams / 2);
                for (let i = 0; i < slotCount; i ++) {
                    locationTimeSet.push(
                        {week: 0, start: 0, locationId: null}
                    );
                }
            }
            this.calcTimes(locationTimeSet);
            this.checklocationTimes(locationTimeSet);

            for (const item of locationTimeSet) {
                locationTimeSets[val.id].push(item);
            }
        }

        this.state = {
            editOpen: false,
            doubleAllow: false,
            saveProgress: false,
            locationTimeSets: locationTimeSets
        }
        this.deployGames = this.deployGames.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {groupTeams} = this.props;
        const {locationTimeSets} = this.state;

        if (groupTeams != null && prevProps.groupTeams != null) {
            var changed = false;
            const setUpdate = {};
            if (groupTeams != null) {
                let groupTeamCount = 0;
                for (const item of groupTeams) {
                    if (item.teams.length > groupTeamCount) {
                        groupTeamCount = item.teams.length;
                    }
                }
                const slotCount = Math.ceil(groupTeamCount / 2);
                for (const item of groupTeams) {
                    const locationTimeSet = locationTimeSets[item.id];
                    const update = [];
                    for (let i = 0; i < slotCount; i ++) {
                        update.push(
                            {week: 0, start: 0, locationId: null}
                        );
                    }
                    this.calcTimes(update);
                    this.checklocationTimes(update);
                    setUpdate[item.id] = update;
                    if (locationTimeSet == null || locationTimeSet.length !== update.length) {
                        changed = true;
                    }
                }
            }
            if (changed) {
                this.setState({
                    locationTimeSets: setUpdate,
                })
            }
        }
    }
    
    getTeamIds(group, excludeId) {
        const homeTeams = [];
        if (group != null && group.teams != null) {
            for (const team of group.teams) {
                if (excludeId !== team.id) {
                    homeTeams.push(team.id);
                }
            }
        }
        return homeTeams;
    }

    deployGames() {
        const {subSeason, groupTeams, season, division, onCompleteDeploy, onGroupEdit} = this.props;
        const {doubleAllow, locationTimeSets} = this.state;
        let totalTeams = subSeason.teamIds != null ? Object.values(subSeason.teamIds).length : 0;
        let groupCount = groupTeams != null ? groupTeams.length : 0;
        let groupLimit = parseInt(Math.ceil(totalTeams / groupCount));
        let groupTeamCount = 0;
        if (groupTeams != null) {
            for (const item of groupTeams) {
                if (item.teams.length > groupTeamCount) {
                    groupTeamCount = item.teams.length;
                }
            }
        }
        if (groupLimit < groupTeamCount) {
            alert(`Every Group can have ${groupLimit} teams at most`);
            return;
        }

        let timeLimit = division != null && division.category != null && division.category.timeLimit != null ? division.category.timeLimit : 60;
        const total = [];
        for (const group of groupTeams) {
            const games = [];
            const locationTimeSet = locationTimeSets[group.id];
            let original = this.getTeamIds(group);
            const teamCount = original.length;
            for (var i = 0; i < groupTeamCount - teamCount; i ++) {
                original.push('None');
            }
            if (groupTeamCount % 2 === 1) {
                original.push('None');
            }
            original = shuffle(original);
            original = shuffle(original);
            original = shuffle(original);
    
            let homeTeams = [];
            let awayTeams = [];
    
            for (let i = 0; i < original.length / 2; i ++) {
                homeTeams.push(original[i]);
                awayTeams.push(original[i + original.length / 2]);
            }
    
            var offsetTimeSet = [];
            for (const val of locationTimeSet) {
                offsetTimeSet.push({
                    locationId: val.locationId,
                    offset: val.offset,
                    offsetEnd: val.offsetEnd,
                    start: val.start,
                    week: val.week,
                    overlapped: val.overlapped,
                });
            }
            offsetTimeSet.sort((a, b) => {
                return a.offset - b.offset;
            });
    
            let startOffset = season.startTime - getWeekStartDayTime(season.startTime);
    
            var idx = 0;
            for (i = 0; i < offsetTimeSet.length; i++) {
    
                if (offsetTimeSet[i].offset > startOffset) {
                    idx = i;
                    break;
                }
            }
            for (i = 0; i < offsetTimeSet.length; i++) {
    
                if (i < idx) {
                    offsetTimeSet[i].offset += (WEEK_TIME)
                    offsetTimeSet[i].offsetEnd += (WEEK_TIME)
                }
            }
    
            offsetTimeSet.sort((a, b) => {
                return a.offset - b.offset;
            });
    
            for (let j = 0; j < original.length - 1; j ++) {
                let startWeek = getWeekStartDayTime(season.startTime) + WEEK_TIME * (j);
        
                for (let i = 0; i < original.length / 2; i ++) {
                    let gameStart = copyHour(offsetTimeSet[i].offset + startWeek, startWeek - WEEK_TIME * (j))
                    games.push({
                        homeTeam: homeTeams[i],
                        awayTeam: awayTeams[i],
                        start: gameStart,
                        end: gameStart + timeLimit * MIN_TIME,
                        locationId: offsetTimeSet[i].locationId,
                        groupId: group.id,
                        groupIdx: group.index,
                        divisionId: season.divisionId,
                        organizationId: season.organizationId,
                        seasonId: season.id,
                        subSeasonId: subSeason.id,
                        matchDayIndex: j
                    })
                }
        
                let update = [];
                let homeUpdate = [];
                let awayUpdate = [];
        
                for (let i = 0; i < original.length / 2; i ++) {
                    if (i !== 0) {
                        update.push(homeTeams[i])
                    }
                }
        
                for (let i = 0; i < original.length / 2; i ++) {
                    update.push(awayTeams[awayTeams.length - i - 1])
                }
                let arr = [];
                arr.push(update[update.length - 1]);
                for (let i = 0; i < update.length - 1; i ++) {
                    arr.push(update[i]);
                }
                homeUpdate.push(homeTeams[0]);
                for (let i = 0; i < update.length; i ++) {
                    if (i < homeTeams.length - 1) {
                        homeUpdate.push(arr[i]);
                    } else {
                        let idx = update.length + homeTeams.length - i - 2;
                        awayUpdate.push(arr[idx]);
                    }
                }
                homeTeams = homeUpdate;
                awayTeams = awayUpdate;
            }
            if (doubleAllow) {
                for (let j = 0; j < original.length - 1; j ++) {
                    let startWeek = offsetTimeSet(season.startTime) + WEEK_TIME * (j + original.length - 1);
            
                    for (let i = 0; i < original.length / 2; i ++) {
                        let gameStart = copyHour(offsetTimeSet[i].offset + startWeek, startWeek)
                        games.push({
                            homeTeam: awayTeams[i],
                            awayTeam: homeTeams[i],
                            start: gameStart,
                            end: gameStart + timeLimit * MIN_TIME,
                            locationId: offsetTimeSet[i].locationId,
                            groupId: group.id,
                            groupIdx: group.index,
                            divisionId: season.divisionId,
                            organizationId: season.organizationId,
                            seasonId: season.id,
                            subSeasonId: subSeason.id,
                            matchDayIndex: j + original.length - 1
                        })
                    }
            
                    let update = [];
                    let homeUpdate = [];
                    let awayUpdate = [];
            
                    for (let i = 0; i < original.length / 2; i ++) {
                        if (i !== 0) {
                            update.push(homeTeams[i])
                        }
                    }
            
                    for (let i = 0; i < original.length / 2; i ++) {
                        update.push(awayTeams[awayTeams.length - i - 1])
                    }
                    let arr = [];
                    arr.push(update[update.length - 1]);
                    for (let i = 0; i < update.length - 1; i ++) {
                        arr.push(update[i]);
                    }
                    homeUpdate.push(homeTeams[0]);
                    for (let i = 0; i < update.length; i ++) {
                        if (i < homeTeams.length - 1) {
                            homeUpdate.push(arr[i]);
                        } else {
                            let idx = update.length + homeTeams.length - i - 2;
                            awayUpdate.push(arr[idx]);
                        }
                    }
                    homeTeams = homeUpdate;
                    awayTeams = awayUpdate;
                }
            }
            checkMatches(games);
            group.games = games;
            for (const item of games) {
                total.push(item);
            }
    
        }
        if (onGroupEdit != null) {
            onGroupEdit(groupTeams)
        }
        let result = [];
        for (const value of total) {
            initGameLineUp(value, season);
            if (value.homeTeam !== 'None' && value.awayTeam !== 'None') {
                result.push(value);
            }
        }
        
        if (onCompleteDeploy != null) {
            onCompleteDeploy(result);
        }
    }



    checklocationTimes(locationTimeMap) {
        let arr = locationTimeMap;
        
        for (const value of locationTimeMap) {
            value.overlapped = false
        }
        for(let i = 0; i < arr.length; i ++) {
            for (let j = i; j < arr.length; j ++) {
                if (i !== j && arr[j].offset != null && arr[i].offset != null && arr[i].locationId === arr[j].locationId && (
                    (arr[j].offset >= arr[i].offset && arr[j].offset <= arr[i].offsetEnd) ||
                    (arr[j].offsetEnd >= arr[i].offset && arr[j].offsetEnd <= arr[i].offsetEnd)
                    )) {
                    arr[j].overlapped = true;
                    arr[i].overlapped = true;
                }
            }
        }
    }

    calcTimes(locationTimeMap) {
        const {division} = this.props;
        let timeLimit = division != null && division.category != null && division.category.timeLimit != null ? division.category.timeLimit : 60;

        for (const value of locationTimeMap) {
            if (value.start != null && value.week != null) {
                let offset = value.start;
                offset += value.week * DAY_TIME;

                value.offset = offset;
                value.offsetEnd = offset + timeLimit * MIN_TIME;
            }
        }
    }

    checklocationTimesArr(locationTimeMaps) {
        for (const locationTimeMap of Object.values(locationTimeMaps)) {
            let arr = locationTimeMap;
            
            for (const value of locationTimeMap) {
                value.overlapped = false
            }
            for(let i = 0; i < arr.length; i ++) {
                for (let j = i; j < arr.length; j ++) {
                    if (i !== j && arr[j].offset != null && arr[i].offset != null && (
                        (arr[j].offset >= arr[i].offset && arr[j].offset <= arr[i].offsetEnd) ||
                        (arr[j].offsetEnd >= arr[i].offset && arr[j].offsetEnd <= arr[i].offsetEnd)
                        )) {
                        arr[j].overlapped = true;
                        arr[i].overlapped = true;
                    }
                }
            }
        }
    }

    calcTimesArr(locationTimeMaps) {
        const {division} = this.props;
        let timeLimit = division != null && division.category != null && division.category.timeLimit != null ? division.category.timeLimit : 60;
        for (const locationTimeMap of Object.values(locationTimeMaps)) {
            for (const value of locationTimeMap) {
                if (value.start != null && value.week != null) {
                    let offset = value.start;
                    offset += value.week * DAY_TIME;
    
                    value.offset = offset;
                    value.offsetEnd = offset + timeLimit * MIN_TIME;
                }
            }
        }

    }

    onGroupEdit(groupTeams, result) {
        const { onGroupEdit } = this.props;
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                groupTeams[parseInt(source.droppableId)].teams,
                source.index,
                destination.index
            );

            groupTeams[parseInt(source.droppableId)].teams = items;

        } else {
            const result = move(
                groupTeams[parseInt(source.droppableId)].teams,
                groupTeams[parseInt(destination.droppableId)].teams,
                source,
                destination
            );
            groupTeams[parseInt(source.droppableId)].teams = result[parseInt(source.droppableId)];
            groupTeams[parseInt(destination.droppableId)].teams = result[parseInt(destination.droppableId)];

        }
        if (onGroupEdit != null) {
            onGroupEdit(groupTeams)
        }
    }

    getGroupId(groupTeams, team) {
        for (const item of groupTeams) {
            if (item.teams) {
                for (const value of item.teams) {
                    if (value.id === team.id) {
                        return item.id;
                    }
                }
            }
        }
        return null;
    }

    renderGroupingView() {
        const {subSeason, teams, groupTeams, onGroupEdit, locations, division} = this.props;
        const {locationTimeSets} = this.state;

        let dayStart = getDayStart(new Date().valueOf());

        const locationPick = {}
        for (const [key, value] of Object.entries(locations)) {
            if (division.locationIds && Object.values(division.locationIds).includes(key)) {
                locationPick[key] = value;
            }
        }

        let remainingTeams = [];
        if (subSeason.teamIds) {
            for (const teamId of Object.values(subSeason.teamIds)) {
                const team = teams[teamId];
                if (team && !this.getGroupId(groupTeams, team)) {
                    remainingTeams.push(team);
                }
            }
        }
        sortByTitle(remainingTeams);

        return (
            <div style={{margin: '20px 0'}}>
                <div className="shadow-tile-small">
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Unassigned Teams {remainingTeams.length} members
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {remainingTeams.length > 0 ? remainingTeams.map((team, index) => (
                    <Row key={team.id}>
                        <Col xs={12} sm={6} className="vertical-center">
                            <div className='wrap-content-parent'>
                                <CardImg
                                    className="wrap-content-wrap-child row-badge-icon"
                                    src={team.iconUri != null ? team.iconUri : teamIcon} />
                                <h4 className='wrap-content-fill-child row-badge-title'>
                                {team.title}
                                </h4>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="vertical-center">
                            <Input type="select" name="select" value={this.getGroupId(groupTeams, team)} onChange={e=> {
                                const updateId = e.target.value;
                                
                                if (updateId) {
                                    for (const group of groupTeams) {
                                        if (group.id === updateId) {
                                            group.teams.push(team);
                                        }
                                    }
                                }
                                if (onGroupEdit) {
                                    onGroupEdit(groupTeams);
                                }
                            }}>
                                <option value={null}>{t('no_choice')}</option>
                                {groupTeams.map((group, idx) => (
                                <option value={group.id} key={idx}>Group {group.index + 1}</option>
                                ))}
                            </Input>
                        </Col>
                        <Col xs={12} className="divider" />
                    </Row>
                    )) : (
                    <div style={{height: 80, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_items_to_show')}
                    </div>
                    )}
                </div>
                <h3 className='grouping-title'>Grouping Teams</h3>
                {groupTeams.map((item, idx)=>(
                <Row style={{margin: '10px 0px'}}>
                    <Col lg={6} sm={12} style={{padding: 0}}>
                        <div className="shadow-tile-small">
                            <Row style={{backgroundColor: '#19447F'}}>
                                <Col xs={12} className="vertical-center">
                                    <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                        Group {item.index + 1}: {item.teams.length} members
                                    </h3>
                                </Col>
                            </Row>
                            <div className='divider' />
                            <div className='divider' />
                            {item.teams.map((team, index) => (
                            <Row key={team.id}>
                                <Col xs={12} sm={6} className="vertical-center">
                                    <div className='wrap-content-parent'>
                                        <CardImg
                                            className="wrap-content-wrap-child row-badge-icon"
                                            src={team.iconUri != null ? team.iconUri : teamIcon} />
                                        <h4 className='wrap-content-fill-child row-badge-title'>
                                        {team.title}
                                        </h4>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} className="vertical-center">
                                    <Input type="select" name="select" value={this.getGroupId(groupTeams, team)} onChange={e=> {
                                        const updateId = e.target.value;
                                        for (const group of groupTeams) {
                                            if (group.id === item.id) {
                                                removeFromArr(group.teams, team);
                                            }
                                        }
                                        if (updateId) {
                                            for (const group of groupTeams) {
                                                if (group.id === updateId && !item.teams.includes(team)) {
                                                    group.teams.push(team);
                                                }
                                            }
                                        }
                                        if (onGroupEdit) {
                                            onGroupEdit(groupTeams);
                                        }
                                    }}>
                                        <option value={null}>{t('no_choice')}</option>
                                        {groupTeams.map((group, idx) => (
                                        <option value={group.id} key={idx}>Group {group.index + 1}</option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col xs={12} className="divider" />
                            </Row>
                            ))}
                        </div>
                    </Col>
                    <Col lg={6} sm={12} style={{padding: 0}}>
                        <div className="shadow-tile-small">
                            {locationTimeSets[item.id].map((value, idx)=>(
                            <Row style={{padding: "6px 5px"}}>
                                <Col sm={6} xs={12} style={{padding: '10px 15px'}}>
                                    <Input type="select" name="select" value={value.week} id="typeChoice" onChange={e=> {
                                        locationTimeSets[item.id][idx].week = parseInt(e.target.value);
                                        this.calcTimes(locationTimeSets[item.id]);
                                        this.checklocationTimes(locationTimeSets[item.id]);

                                        this.setState({locationTimeSets: locationTimeSets});
                                    }}>
                                        <option value={0}>{t('sunday')}</option>
                                        <option value={1}>{t('monday')}</option>
                                        <option value={2}>{t('tuesday')}</option>
                                        <option value={3}>{t('wednesday')}</option>
                                        <option value={4}>{t('thursday')}</option>
                                        <option value={5}>{t('friday')}</option>
                                        <option value={6}>{t('saturday')}</option>
                                    </Input>
                                </Col>
                                <Col sm={6} xs={12} style={{padding: '10px 15px'}}>
                                    <Input type="select" name="typeChoice" value={value.locationId} id="typeChoice" onChange={e=> {
                                        locationTimeSets[item.id][idx].locationId = e.target.value;
                                        this.calcTimes(locationTimeSets[item.id]);
                                        this.checklocationTimes(locationTimeSets[item.id]);

                                        this.setState({locationTimeSets: locationTimeSets});
                                    }}>
                                        <option key="null" value={null}></option>
                                        {Object.values(locationPick).map((val,idx)=>(
                                            <option key={idx} value={val.id}>{val.title}</option>
                                        ))}
                                    </Input>
                                </Col>
                                <Col xs={12}>
                                    <div className="wrap-content-parent">
                                        <CustomTimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Time picker"
                                            value={new Date(value.start ? dayStart + value.start : dayStart)}
                                            onChange={ date => {
                                                locationTimeSets[item.id][idx].start= date.valueOf() - dayStart;
                                                this.calcTimes(locationTimeSets[item.id]);
                                                this.checklocationTimes(locationTimeSets[item.id]);

                                                this.setState({locationTimeSets: locationTimeSets});
                                            }} />
                                        <div className='wrap-content-fill-child' />
                                        <div className='wrap-content-wrap-child vertical-center-spacing'>
                                            <Button className="btn-edit" onClick={e=> {
                                                removeArrIdx(locationTimeSets[item.id], idx);
                                                this.calcTimes(locationTimeSets[item.id]);
                                                this.checklocationTimes(locationTimeSets[item.id]);

                                                this.setState({locationTimeSets: locationTimeSets});
                                            }}><MdRemove/></Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    {value.overlapped && (
                                        <div style={{color: 'red', fontSize: 12}}>
                                        <MdWarning/> {t('timeline_overlap')}
                                        </div>
                                    )}
                                </div>
                                </Col>
                            </Row>
                            ))}
                        </div>
                    </Col>
                </Row>
                ))}
            </div>
        )
    }

    render() {
        const {subSeason} = this.props;
        const {doubleAllow} = this.state;
        const teamCount = subSeason.teamIds != null ? Object.values(subSeason.teamIds).length : 0;
        
        return (
            <div style={{marginTop: 10, marginBottom: 20}} >
                {this.renderGroupingView()}
                <div className='divider' />
                <h4 className='wrap-content-fill-child' style={{marginTop: 10, marginBottom: 10}}>
                {t('group_games_deployment')}, {t('teams')}: {teamCount}
                </h4>
                <div className='player-form-item'>
                    <div className='player-form-title'>
                        {t('double_game_allow')}
                    </div>
                    <div className='player-form-value form-setting-control'>
                        <Switch
                            checked={doubleAllow}
                            onChange={e=>{
                                this.setState({doubleAllow: e.target.checked});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                <Button className='btn-submit' onClick={ e => {
                    this.deployGames();
                }}>{t('deploy')}</Button>
            </div>
        );
    }
}

export default GroupSetupDeploy;