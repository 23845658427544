import React, { Component } from 'react';
import { t } from "i18next";
import { connect } from 'react-redux';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { userActions } from 'redux/_actions';

import { 
    GiSoccerKick
} from 'react-icons/gi';
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import LoadingView from 'components/detail/loading/LoadingView';
import { 
    Row, Col, Input, Button
} from 'reactstrap';

import { toSimpleTime } from 'utils/Utils';

import { MdAccessTime } from 'react-icons/md';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import { STATUS_FINISHED, STATUS_ONGOING, STATUS_PENDING } from 'utils/constants';

class ClaimsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            claims: {},
            teams: {},
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        this.setState({loading: true});
        invokeHttpsApi('clubQuery-loadClaims', {}, (data) => {
            const claims = {};
            const teams = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.claims)) {
                    claims[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
            }

            this.setState({
                claims: claims,
                teams: teams,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
            })
        });
    }

    renderContents() {
        const { user } = this.props;
        const { claims, statusChoice, searchStr } = this.state;
        
        const result = [];
        const list = [];
        
        if (user == null) {
            return <div/>
        }
        
        for (const[, value] of Object.entries(claims)) {
            if (searchStr != null && searchStr.length > 0 && !value.text.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (!statusChoice || value.status === statusChoice) {
                result.push(value);
            }
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t1 - t2;
        })
        
        for (const value of result) {
            list.push(
                this.renderClaimItem(value)
            );
        }

        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('claims')}
                        icon={<GiSoccerKick/>}
                        detailOpen={false}
                        editOpen={false}
                        totalCount={list.length}
                        detailItem={{}} />
                </div>
                <Row>
                    <Col lg={9} sm={7} xs={12}>
                        <Input type="text" name="search" id="search" placeholder='Search by Schedule Title ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </Col>
                    <Col lg = {3} sm={5} xs={12}>
                        <Input 
                            type="select" 
                            name="select" 
                            value={statusChoice} 
                            id="typeChoice"
                            onChange={e=> {
                                this.setState({statusChoice: e.target.value});
                            }}>
                            <option value={null}>{t('select_all')}</option>
                            <option value={STATUS_PENDING}>{t('pending')}</option>
                            <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                            <option value={STATUS_FINISHED}>{t('finished')}</option>
                        </Input>
                    </Col>
                </Row>

                {list}
            </div>
        );
    }

    renderClaimItem(item) {
        const { claims, teams } = this.state;

        const team = teams[item.teamId] ? teams[item.teamId] : {};

        const icon = team.iconUri != null ? team.iconUri : defaultImage;

        const date = new Date(item.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        
        const from = toSimpleTime(item.timestamp);
        
        return (
            <div className="shadow-tile wrap-content-parent">
                <div className="date-header wrap-content-wrap-child">
                    <div className="date-month">
                        {day}
                    </div>
                    <div className="date-day">
                        {month}
                    </div>
                </div>
                <div className='wrap-content-fill-child' style={{padding: '5px 10px'}}>
                    <Row>
                        <Col nm={8} xs={12} sm={6} className="info-col">
                            <h4 className='label label-default'>
                            {item.text}
                            </h4>
                            <MdAccessTime/> {from}
                        </Col>
                        <Col nm={4} xs={12} sm={6} className="info-col">
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child'>
                                </div>
                                <div className="wrap-content-wrap-child">
                                    <img alt="icon" src={icon} style={{width: 20, height: 20, marginRight: 10}} />
                                    {team.title}
                                </div>
                            </div>
                            <div className='wrap-content-parent' style={{marginTop: 5}}>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child">
                                    <Input 
                                        type="select" 
                                        name="select" 
                                        value={item.status} 
                                        id="typeChoice" 
                                        style={{
                                            display: 'inline-block',
                                            width: 110,
                                            marginRight: 10
                                        }}
                                        onChange={e=> {
                                            item.status = e.target.value;
                                            claims[item.id] = item;
                                            this.setState({claims: claims});
                                        }}>
                                        <option value={STATUS_PENDING}>{t('pending')}</option>
                                        <option value={STATUS_ONGOING}>{t('ongoing')}</option>
                                        <option value={STATUS_FINISHED}>{t('finished')}</option>
                                    </Input>
                                    <Button className="btn-edit" onClick={e => {
                                        this.setState({loading: true});
                                        invokeHttpsApi('clubQuery-submitClaimStatus', {
                                            claim: item,
                                            status: item.status,
                                        }, (data) => {
                                            if (data && data.success) {
                                                this.loadContents();
                                            } else {
                                                this.setState({loading: false});
                                            }
                                        }, (error) => {
                                            console.log('saveContents error', error);
                                            this.setState({loading: false})
                                        });
                                    }}>{t('submit')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
    
    render() {
        const {loading} = this.state;

        return (
            <div>
                
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(ClaimsPage));
export { connectedApp as ClaimsPage };
