import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import TeamsView from 'components/members/TeamsView';

import CustomDatePicker from "components/template/CustomDatePicker";
import { generateId, writeDb } from "utils/API";

export class OrganizationScheduleEdit extends Component {
    constructor(props) {
        super(props);
        const schedule = {};
        if (props.schedule != null) {
            for (const [key, value] of Object.entries(props.schedule)) {
                schedule[key] = value;
            }
        }
        
        this.state = {
            schedule: schedule,
            progress: false,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, schedule} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.schedule !== schedule)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(schedule)) {
                challengeVal[key] = value;
            }
            this.setState({
                schedule: challengeVal
            })
        }
    }

    onSaveClick(schedule) {
        const {organization, uid, onCloseClick} = this.props;
        if (schedule.title == null || schedule.title === '') {
            alert("You must input title");
        } else if (schedule.location == null || schedule.location === '') {
            alert("You must input location");
        } else if (schedule.teamIds == null || Object.values(schedule.teamIds).length <= 0) {
            alert("You must choose at least 1 team");
        } else if (schedule.deadline == null || schedule.timestamp == null || 
            schedule.timestamp <= 0 || schedule.timestamp <= new Date().valueOf() || schedule.deadline <= schedule.timestamp ) {
            alert("You must input valid start time and duration.");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (schedule.id == null || schedule.id === '') {
                schedule.id = generateId("/orgSchedule");
                schedule.owner = uid != null ? uid : "free";
                schedule.organizationId = organization.id;
            }
            
            this.saveChange(schedule, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick();
                }
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        }
    }

    saveChange(schedule, success, failure) {
        writeDb('/orgSchedule/' + schedule.id, schedule, error => {
            if (error) {
                failure(error);
            } else {
                success(schedule);
            }
        })
    }

    render() {
        const {organization, locations, teams, onCloseClick} = this.props;
        const {schedule, newImage, progress} = this.state;

        const start = schedule.timestamp != null ? new Date(schedule.timestamp) : new Date();
        const teamSet = {};
        for (const [key, value] of Object.entries(teams)) {
            if (organization != null && organization.teamIds != null && Object.values(organization.teamIds).includes(key)) {
                teamSet[key] = value;
            }
        }
        
        if (schedule != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            schedule.title = e.target.value;
                            this.setState({schedule: schedule});
                        }} value={schedule.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={schedule.description} onChange={e => {
                            schedule.description = e.target.value;
                            this.setState({organizationLesson: schedule});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="title">Location</Label>
                        <Input 
                            type="select" 
                            name="select" 
                            value={schedule.location} 
                            id="typeChoice" 
                            style={{marginTop: 20}}
                            onChange={e=> {
                                schedule.location = e.target.value;
                                this.setState({schedule: schedule});
                            }}>
                            <option key="null" value={null}></option>
                            {Object.values(locations).map((val,idx)=>(
                                <option key={idx} value={val.id}>{val.title}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <div>{t('teams')}: {schedule.teamIds != null ? Object.values(schedule.teamIds).length : 0}</div>
                        <div className='divider'/>
                        <TeamsView
                            available={organization.teamIds != null ? organization.teamIds : {}}
                            teamIds={schedule.teamIds}
                            clubs={teams}
                            onSaveClick={(teamIds)=> {
                                schedule.teamIds = {};
                                if (teamIds != null) {
                                    for (const[key, value] of Object.entries(teamIds)) {
                                        schedule.teamIds[key] = value;
                                    }
                                }
                                this.setState({schedule: schedule});
                            }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="duration_limit">{t('start_time')}</Label>
                        <CustomDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date picker dialog"
                            format="MM/DD/YYYY"
                            value={start}
                            onChange={ date => {
                                schedule.timestamp = date.valueOf();
                                this.setState({schedule: schedule});
                            }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="duration_limit">{t('duration_min')}</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            if (schedule.timestamp != null) {
                                schedule.deadline = (e.target.value | 0) * 60000 + schedule.timestamp;
                                this.setState({schedule: schedule});
                            }
                        }} value={
                            schedule.deadline != null && schedule.deadline > 0 && schedule.timestamp != null ? 
                            (schedule.deadline - schedule.timestamp) / 60000 : 0} />
                    </FormGroup>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(schedule, newImage);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(OrganizationScheduleEdit);