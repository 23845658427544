import React, { Component } from "react";
import {
    CardImg, Row, Col,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import {
    HiBookmark
} from 'react-icons/hi';

import playersIcon from 'assets/img/icons/players_icon.png';
import { getPlayerSubSeasonStatisticsList } from 'utils/statistics';

export class OrganizationSeasonStatistics extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    renderScoreBadge(user, rank, score, assist) {
        let thumbImage = user && user.photoUri != null ? user.photoUri : playersIcon;

        return (
            <Row>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {score ? score : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {assist ? assist : 0}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderFoulBadge(user, rank, foul, red, yellow) {
        let thumbImage = user && user.photoUri != null ? user.photoUri : playersIcon;

        return (
            <Row>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {foul ? foul : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {red ? red : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {yellow ? yellow : 0}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderScorers(subSeason) {
        const {users, divisions} = this.props;

        let statistics = getPlayerSubSeasonStatisticsList(subSeason, null, divisions);
        let topScore = [];
        for (const val of Object.values(statistics)) {
            topScore.push(val);
        }
        topScore.sort((a,b)=> {
            return b.score - a.score
        });
        
        let items = [];
        let rank = 0;
        for (const item of topScore) {
            rank +=1 ;
            if (users[item.uid]) {
                items.push(
                    this.renderScoreBadge(users[item.uid], rank, item.score, item.assist)
                );
            }
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                Top Scorers / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={5} xs={5} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('goal')}
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('assist')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderFouls(subSeason) {
        const {users, divisions} = this.props;

        let statistics = getPlayerSubSeasonStatisticsList(subSeason, null, divisions);
        let topScore = [];
        for (const val of Object.values(statistics)) {
            topScore.push(val);
        }
        topScore.sort((a,b)=> {
            return b.red - a.red
        });
        
        let items = [];
        let rank = 0;
        for (const item of topScore) {
            rank +=1 ;
            if (users[item.uid]) {
                items.push(
                    this.renderFoulBadge(users[item.uid], rank, item.foul, item.red, item.yellow)
                );
            }
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {t('top_red')} / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={5} xs={5} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('foul')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: '#BE4534' }}><HiBookmark/></span>
                            </h3>
                        </Col>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: '#f0cb5b'}}><HiBookmark/></span>
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const {seasonChoice} = this.props;
        
        let rounds = seasonChoice.subSeasons ? Object.values(seasonChoice.subSeasons) : []

        return (
            <div className="">
                {rounds.map((value,idx)=>(
                <Row style={{marginLeft: 0, marginRight: 0}}>
                    <Col sm={6} xs={12}>
                        {this.renderScorers(value)}
                    </Col>
                    <Col sm={6} xs={12}>
                        {this.renderFouls(value)}
                    </Col>
                </Row>
                ))}
            </div>
        );
    }
}

export default withTranslation()(OrganizationSeasonStatistics);