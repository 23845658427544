import React, { Component } from 'react';
import {TeamSlides} from 'components/landing/team/TeamSlides';
import BluePostHeader from 'components/detail/community/BluePostHeader';
import BlueHighlightHeader from 'components/detail/community/BlueHighlightHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import Pagination from 'react-responsive-pagination';
import {
    Modal, ModalBody
} from 'reactstrap';
import { t } from 'i18next';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import ClubChatView from 'components/chat/ClubChatView';

import { getOrgId, getTeamDomain } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { TeamEmpty } from 'components/landing/team/TeamEmpty';
import { withRouter } from 'utils/Routher';

class TeamCommunity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            page: 1,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const clubSchedules = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                clubSchedules: clubSchedules,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    renderHighlights() {
        const { team, posts, users, user, loading, searchStr, page} = this.state;
        const homeLink = getTeamDomain(team);
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((posts ? Object.values(posts).length : 0) / 3);
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.gameInfo && (value.gameInfo.homeTeam === team.id || value.gameInfo.awayTeam === team.id)) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        let idx = (page - 1) * 3;
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        
        for (const value of toShow) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: '0 10px'}}>
                    <BlueHighlightHeader 
                        key={value.id}
                        team={team}
                        post={value}
                        users={users}
                        user={user}
                        onClickDetail={() => {
                            this.props.router.navigate(homeLink + '/post/' + value.id);
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px"}}>
                <h1 className="blue-section-title">Highlights</h1>
                <div className="container">
                    {list.length === 0 ? (
                        <div style={{height: 150, paddingTop: 25, fontSize: 14, color: 'white' , textAlign: 'center'}}>
                        {loading ? "Loading TeamPosts" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px"}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={page}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
            </div>
        );
    }
    
    renderPosts() {
        const { team, posts, users, user, loading, searchStr, page} = this.state;
        const homeLink = getTeamDomain(team);
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((posts ? Object.values(posts).length : 0) / 3);
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.teamId === team.id && !value.gameId) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })

        const toShow = [];
        let idx = (page - 1) * 3;
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        if (result.length > idx) {
            toShow.push(result[idx ++]);
        }
        
        for (const value of toShow) {
            list.push(
                <div key={`${value.id}`} className="col-md-4" style={{margin: 0, padding: '0 10px'}}>
                    <BluePostHeader 
                        key={value.id}
                        team={team}
                        post={value}
                        users={users}
                        user={user}
                        onClickDetail={() => {
                            this.props.router.navigate(homeLink + '/post/' + value.id);
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px"}}>
                <h1 className="blue-section-title">News</h1>
                <div className="container">
                    {list.length === 0 ? (
                        <div style={{height: 150, paddingTop: 25, fontSize: 14, color: 'white' , textAlign: 'center'}}>
                        {loading ? "Loading TeamPosts" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px"}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={page}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
            </div>
        );
    }
    
    render() {
        const {user} = this.props;
        const {team, users, loading, detailChoice} = this.state;
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
        if (!team.available) {
            return (
                <TeamEmpty team={team} />
            )
        }
        
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const uid = user ? user.uid : null;
        var showAccount = team.owner === uid || 
            (team.playerIds && Object.values(team.playerIds).includes(uid)) || 
            (team.managers && Object.values(team.managers).includes(uid));


        return (
            <div>
                <TeamSlides 
                    team={team}
                    loading={loading} />
                <div style={{height: 50}}/>
                <div style={{paddingTop: 20}}>
                {this.renderHighlights()}
                {this.renderPosts()}
                </div>
                {detailChoice && (
                    <Modal isOpen={true} style={{marginTop: 70}}>
                        <ModalBody>
                            <div className="post-detail-modal">
                                <PostDetailView 
                                    team={team}
                                    post={detailChoice} 
                                    user={user}
                                    users={users}
                                    isOpen={true}
                                    onCloseClick={() => {
                                        this.setState({detailChoice: null})
                                    }}
                                    onLikeUpdate={(like, uid) => {
                                        if (uid) {
                                            if (!detailChoice.likeIds) {
                                                detailChoice.likeIds = {};
                                            }
                                            if (like) {
                                                detailChoice.likeIds[uid] = uid;
                                            } else {
                                                delete detailChoice.like[uid];
                                            }
                                            this.setState({detailChoice: detailChoice})
                                        }
                                    }}
                                    onCommentChange={(update) => {
                                        if (update) {
                                            if (!detailChoice.comments) {
                                                detailChoice.comments = {};
                                            }
                                            detailChoice.comments[update.id] = update;

                                            this.setState({detailChoice: detailChoice})
                                        }
                                    }} />
                            </div>
                        </ModalBody>
                    </Modal>
                )}
                {showAccount && (
                <ClubChatView
                    uid={uid}
                    user={user}
                    users={users}
                    club={team} />
                )}
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamCommunity));
export { connectedApp as TeamCommunity };