import React, { Component } from 'react';
import {OrganizationMemberships} from 'components/membership/OrganizationMemberships';
import { connect } from 'react-redux';
import { OrganizationMembershipBadge } from 'components/membership/OrganizationMembershipBadge';


class MembershipPage extends Component {

    render() {
        return (
            <div>
                <OrganizationMembershipBadge />
                <div className='text-center' style={{marginTop: 20, marginBottom: 30}}>
                    <h2 className='label label-default'>
                    SPORTS MANAGEMENT PLATFORM
                    </h2>
                    <h3 className='label label-default'>
                    FACILITIES | COACHES | ORGANIZATIONS | REFEREES | PLAYERS
                    </h3>
                </div>
                <OrganizationMemberships />
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { primarySubscription } = state.subscriptions;
    return { alert, loggingIn, user, primarySubscription };
}

const connectedApp = connect(mapState)(MembershipPage);
export { connectedApp as MembershipPage };
