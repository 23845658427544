import React, { Component } from "react";
import {
    Button, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';

import HorizontalAttachImageEdit from 'components/edit/HorizontalAttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";

export class ClubEditView extends Component {
    constructor(props) {
        super(props);
        const squad = {};
        if (props.squad != null) {
            for (const [key, value] of Object.entries(props.squad)) {
                squad[key] = value;
            }
        }
        
        this.state = {
            squad: squad,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, squad} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.squad !== squad)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(squad)) {
                challengeVal[key] = value;
            }
            this.setState({
                squad: challengeVal
            })
        }
    }

    onSaveClick(squad, newImage) {
        const {uid, onCloseClick, onChanged} = this.props;
        const objRef = this;
        if (squad.title == null || squad.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (squad.id == null || squad.id === '') {
                squad.id = generateId("/squad");
                squad.owner = uid != null ? uid : "free";
            }
            squad.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(objRef, squad, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
                if (onChanged != null) {
                    onChanged(squad)
                }
            }, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick()
                }
                if (onChanged != null) {
                    onChanged(squad)
                }
            })
        }
    }

    saveChangeWithImage(objRef, squad, newImage, success, failure) {
        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(squad.id, 'squad', newImage, (url) => {
                squad.iconUri = url;
                //success(squad);
                objRef.saveChange(squad, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(squad, success, failure)
        }
    }

    saveChange(squad, success, failure) {
        const {organizationId, divisionId, seasonId} = this.props;

        const data = {club: squad}
        if (organizationId) {
            data.organizationId = organizationId
        }
        if (divisionId) {
            data.divisionId = divisionId
        }
        if (seasonId) {
            data.seasonId = seasonId
        }
        invokeHttpsApi('clubQuery-saveClubEdit', data, (data) => {
            if (data && data.success) {
                success(squad);
            } else {
                success(squad);
            }
        }, (error) => {
            console.log('save error', error);
            failure(error);
        });
    }

    render() {
        const {createMode, onCloseClick} = this.props;
        const {squad, newImage, imageProgress, progress} = this.state;
        
        if (squad != null) {
            return (
                <div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Title
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    squad.title = e.target.value;
                                    this.setState({squad: squad});
                                }} value={squad.title} />
                            </div>
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Image
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <HorizontalAttachImageEdit
                                imageUrl={squad.iconUri}
                                defaultImage={defaultImage}
                                imageWidth={80}
                                imageHeight={60}
                                imageClass='task-image'
                                label='Thumb Image'
                                allowProgress={progress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({newImage: file});
                                }} />
                        </div>
                    </div>
                    <div className='form-edit-row row'>
                        <div className='form-edit-label col-12 col-sm-5'>
                        Code to join
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <input type="text" className='form-control' name="text" id="title" onChange={e => {
                                    squad.code = e.target.value;
                                    this.setState({squad: squad});
                                }} value={squad.code} />
                            </div>
                        </div>
                    </div>
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <div className='row' style={{textAlign: 'center'}}>
                                <button className='btn btn-secondary btn-edit' style={{margin: '0px auto'}} onClick={e => {
                                this.onSaveClick(squad, newImage);
                                }}>{t('create')}</button>
                            </div>
                        ) : (
                            <div className='form-edit-row row'>
                                <div className='form-edit-label col-12 col-sm-5' style={{marginTop: 0}} >
                                    <button className='btn btn-secondary btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(squad, newImage);
                                    }}>{t('save')}</button>
                                </div>
                                <div className='form-edit-entry col-12 col-sm-7' style={{marginTop: 0}} >
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </div>
                            </div>
                        )}
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubEditView);