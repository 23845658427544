import React, { Component } from 'react';
import { connect } from 'react-redux';


class FacebookPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };

        this.options = {
            domain: 'connect.facebook.net',
            version: 'v3.2',
            cookie: false,
            status: false,
            xfbml: false,
            language: 'en_US',
            frictionlessRequests: false,
            debug: false,
            chatSupport: false,
        };
        const objRef = this;
    
        this.loadingPromise = new Promise((resolve) => {
            window.fbAsyncInit = () => {
                window.FB.init({
                    appId: '520872656108078',
                    version: 'v3.2',
                    xfbml: true,
                });

                var my_video_player;
                window.FB.Event.subscribe('xfbml.ready', function(msg) {
                    if (msg.type === 'video') {
                        my_video_player = msg.instance;
                        if (props.onPlayerReady) {
                            props.onPlayerReady(my_video_player);
                        }

                        my_video_player.subscribe('startedPlaying', function(e) {
                            objRef.startedPlaying(e);
                        });
                        my_video_player.subscribe('paused', function(e) {
                            objRef.paused(e);
                        });
                        my_video_player.subscribe('finishedPlaying', function(e) {
                            objRef.finishedPlaying(e);
                        });
                        my_video_player.subscribe('error', function(e) {
                            objRef.error(e);
                        });
                        objRef.setState({my_video_player: msg.instance});
                    }
                });
                resolve(window.FB);
            }
            
            if (window.document.getElementById('facebook-jssdk')) {
                return resolve(window.FB);
            }

            const js = window.document.createElement('script');
            js.id = 'facebook-jssdk';
            js.async = true;
            js.defer = true;
            js.src = `https://connect.facebook.net/en_US/sdk.js`;
    
            window.document.body.appendChild(js);
        })

        this.init();
    }
    
    async init() {
        if (this.loadingPromise) {
            return this.loadingPromise;
        }
    }

    startedPlaying(e) {
        const {startedPlaying} = this.props;
        const {my_video_player} = this.state;
        if (startedPlaying) {
            startedPlaying(my_video_player);
        }
    }

    paused(e) {
        const {paused} = this.props;
        const {my_video_player} = this.state;
        if (paused) {
            paused(my_video_player);
        }
    }

    finishedPlaying(e) {
        const {finishedPlaying} = this.props;
        const {my_video_player} = this.state;
        if (finishedPlaying) {
            finishedPlaying(my_video_player);
        }
    }

    error(e) {
        const {error} = this.props;
        const {my_video_player} = this.state;
        if (error) {
            error(my_video_player);
        }
    }

    render() {
        const {url, fullscreen, width, autoplay} = this.props;
        return (
            <div style={{overflowY: 'auto', height: '98vh'}}>
                <div  
                    style={fullscreen ? {position: 'relative', top: '50%', transform: 'translateY(-50%)'} : {}}
                    className="fb-video" 
                    data-href={url}
                    data-width={width}
                    data-autoplay={autoplay}
                    data-show-captions="false"
                    data-allowfullscreen="false"></div>
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { primarySubscription } = state.subscriptions;
    return { alert, loggingIn, user, primarySubscription };
}

const connectedApp = connect(mapState)(FacebookPlayer);
export { connectedApp as FacebookPlayer };
