import React, { Component } from "react";
import {
    CardImg, Col, Row
} from 'reactstrap';
import {
    getPlayerData,
} from 'utils/Utils';

import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import defaultUserImage from 'assets/img/icons/player_photo_default.png';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';

export class SeasonPlayerRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, season, uid, teamId, teams, users, profileData } = this.props;
        const userData = getPlayerData(organization, season, users, uid, teamId);
        const team = teams && teams[teamId] ? teams[teamId] : {};
        const teamIcon = team.iconUri ? team.iconUri : defaultTeamImage;
        
        if (organization != null) {
            return (
                <Row>
                    <Col lg={7} xs={5} sm={7} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={userData.icon ? userData.icon : defaultUserImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {userData.username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={3} xs={3} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={teamIcon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {team.title}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="row-badge-icon-small" style={{margin: 'auto'}}
                                src={profileData.verified != null ? (profileData.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="row-badge-icon-small" style={{margin: 'auto'}}
                                src={season.policyFlag && season.policyFlag[uid] != null ? (season.policyFlag[uid] ? verifiedIcon : deniedIcon) : pendingIcon} />
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default SeasonPlayerRow;