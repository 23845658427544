import React, { Component } from 'react';
import ClubPlanHeader from 'components/detail/club/ClubPlanHeader';
import ClubPlanDetailsView from 'components/detail/club/ClubPlanDetailsView';
import ClubPlanEdit from 'components/edit/club/ClubPlanEdit';

import { 
    Button,
    Modal, 
    ModalBody,
} from 'reactstrap';
import { readDb } from 'utils/API';

export class ClubPlansView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            users: {},
            clubPlans: {},
            clubPlanChoice: {},
            clubWorkouts: {},
            detailOpen: false,
            editOpen: false,
        };
    }

    componentDidMount() {
        const { uid } = this.props;
        readDb('/clubPlan', data => {
            var clubPlans = {};
            var {clubPlanChoice} = this.state;

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (uid != null && (value.owner === uid || 
                        (value.managers != null && Object.values(value.managers).includes(uid)))) {
                        clubPlans[key] = value;
                    }
                }
            }
            if (clubPlanChoice.id != null) {
                clubPlanChoice = clubPlans[clubPlanChoice.id];
            }

            this.setState({
                clubPlans: clubPlans,
                clubPlanChoice: clubPlanChoice
            });
        });
        readDb('/clubWorkout', data => {
            var clubWorkouts = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (uid != null && (value.owner === uid || 
                        (value.managers != null && Object.values(value.managers).includes(uid)))) {
                        clubWorkouts[key] = value;
                    }
                }
            }

            this.setState({
                clubWorkouts: clubWorkouts,
            });
        });
    }

    renderContents() {
        const { uid, club, user } = this.props;
        const { clubPlans, clubWorkouts, detailOpen, clubPlanChoice } = this.state;

        if (detailOpen && clubPlanChoice.id != null) {
            return (
                <ClubPlanDetailsView
                    user={user}
                    clubPlan={clubPlanChoice}
                    clubWorkouts={clubWorkouts} />
                )
        } else {
            const result = [];
    
            for (const[key, value] of Object.entries(clubPlans)) {
        
                if (value.clubId === club.id) {
                    result.push(
                        <ClubPlanHeader 
                            key={key}
                            uid={uid}
                            clubPlan={value}
                            onClickDetails={() => {
                                this.setState({
                                    clubPlanChoice: value,
                                    detailOpen: true
                                })
                            }}
                            onClickEdit={() => {
                                this.setState({
                                    clubPlanChoice: value,
                                    editOpen: true
                                })
                            }} />
                    );
                }
            }
    
            return result;
        }
    }

    render() {
        const { club, uid } = this.props;
        const {clubPlanChoice, clubWorkouts, editOpen, detailOpen} = this.state;
        return (
            <div>
                <div className='wrap-content-parent' style={{marginTop: 20}}>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <button className='detail-nav-btn' onClick={e=> {
                            this.setState({clubPlanChoice: {}, detailOpen: false})
                        }}>
                            <h3 style={{display: 'inline-block', fontSize: 16}}>
                            Competitions
                            </h3>
                        </button>
                        {detailOpen && clubPlanChoice.id !== null && (
                            <div style={{display: 'inline-block'}}>
                                <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                                <button className='detail-nav-btn'>
                                    <h3 style={{display: 'inline-block', fontSize: 16}}>
                                    {clubPlanChoice.title}
                                    </h3>
                                </button>
                            </div>
                        )}
                    </div>
                    
                    <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            this.setState({clubPlanChoice: {}, editOpen: true});
                    }}>+</Button>
                </div>
                {this.renderContents()}
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubPlanEdit 
                            uid={uid}
                            club={club}
                            clubPlan={clubPlanChoice} 
                            clubWorkouts={clubWorkouts}
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ClubPlansView;