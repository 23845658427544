import React, { Component } from "react";
import { Button, Spinner } from 'reactstrap';
import { t } from 'i18next';
import { MdCheckCircle } from 'react-icons/md';
import { 
    toDateTimeString 
} from 'utils/Utils';

// function NewlineText(props) {
//     const text = props.text != null ? props.text : '';
//     const newText = text.split('\\n').map(str => <p>{str}</p>);
    
//     return newText;
//   }
  
export class CoachMembershipView extends Component {
    renderPrice(price, priceId, onSubscribe) {
        const { mainColor } = this.props;
        return (
            this.props.progress ? (
                <div>
                    <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                        <Spinner color="primary" />
                    </div>
                </div>
            ) : (
                <div className="org-membership-price-layout">
                    
                    <div className="wrap-content-parent">
                        <div className="org-membership-price wrap-content-wrap-child" style={mainColor != null ? {color: mainColor} : {}}>
                        ${price.unit_amount / 100}
                        </div>
                        {price.recurring && (
                        <div className="wrap-content-fill-child">
                            <div className="org-membership-price-info">per</div>
                            <div className="org-membership-price-info">{price.recurring.interval_count > 1 ? price.recurring.interval_count + " " : ""}{price.recurring.interval}</div>
                        </div>
                        )}
                    </div>
                    <Button  style={mainColor != null ? {backgroundColor: mainColor} : {}}
                        className='btn-org-membership' onClick={e=> {
                            if (onSubscribe != null && priceId != null) {
                                onSubscribe(priceId);
                            }
                        }}>Get Plan</Button>
                </div>
            )
        );
    }

    renderPrices() {
        const { prices, onSubscribe } = this.props;

        const priceItems = [];

        for (const[key, value] of Object.entries(prices)) {
            priceItems.push(
                this.renderPrice(value, key, onSubscribe)
            )
        }

        return (
            <div>
                {priceItems}
            </div>
        )
    }

    renderBenefits(Benefits) {
        const { mainColor } = this.props;
        const texts = Benefits != null ? Benefits.split('\\n') : [];

        const priceItems = [];

        for (const item of texts) {
            if (item != null && item.length > 0) {
                priceItems.push(
                    <div className='membership-benefit'>
                        <div style={mainColor != null ? 
                            {display: 'inline-block', fontWeight: 1800, color: mainColor} : 
                            {display: 'inline-block', fontWeight: 1800}}>
                            <MdCheckCircle/>
                        </div> 
                        {item}
                    </div>
                )
            }
        }

        return (
            <div>
                {priceItems}
            </div>
        )
    }

    renderSubscribedStatus() {
        const { mainColor, subscribedStatus, onCancelClicked } = this.props;

        const created = toDateTimeString(subscribedStatus.created._seconds * 1000);
        const trialEnd = subscribedStatus.trial_end != null ? toDateTimeString(subscribedStatus.trial_end._seconds * 1000) : "No trial";
        const current_period_start = toDateTimeString(subscribedStatus.current_period_start._seconds * 1000);
        const current_period_end = toDateTimeString(subscribedStatus.current_period_end._seconds * 1000);
        const price = subscribedStatus.items[0].price;

        return (
            <div>
                {this.props.progress ? (
                    <div>
                        <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                            <Spinner color="primary" />
                        </div>
                    </div>
                ) : (
                    <div className="org-membership-price-layout">
                        <div className="wrap-content-parent">
                            <div className="org-membership-price wrap-content-wrap-child" style={mainColor != null ? {color: mainColor} : {}}>
                            ${price.unit_amount / 100}
                            </div>
                            {price.recurring && (
                            <div className="wrap-content-fill-child">
                                <div className="org-membership-price-info">per</div>
                                <div className="org-membership-price-info">{price.recurring.interval_count > 1 ? price.recurring.interval_count + " " : ""}{price.recurring.interval}</div>
                            </div>
                            )}
                        </div>
                        <Button  style={mainColor != null ? {backgroundColor: mainColor} : {}}
                            className='btn-org-membership' onClick={e=> {
                                if (onCancelClicked != null) {
                                    onCancelClicked();
                                }
                            }}>{t('cancel')}</Button>
                    </div>
                )}
                {subscribedStatus.status === 'trialing' && (
                    <div className='membership-status' >
                        Trial ends on {trialEnd}
                    </div>
                )}
                {subscribedStatus.status === 'active' && (
                    <div className='membership-status' >
                        Subscribed on {created}
                    </div>
                )}
                {subscribedStatus.status === 'active' && (
                    <div className='membership-status' >
                        Current period from {current_period_start} to {current_period_end}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const { membership, subscribedStatus } = this.props;
        return (
            <div className='membership-tile' style={{height: 'auto'}}>
                <div 
                    className='org-membership-title'>
                    {membership.name}
                </div>
                <div className='org-membership-desc'>
                    {membership.description}
                </div>
                {subscribedStatus.stripeLink != null ? this.renderSubscribedStatus() : this.renderPrices()}
                <div className='membership-benefits'>
                    <p>This includes:</p>
                    {this.renderBenefits(membership.metadata.Benefits)}
                </div>
            </div>
        );
    }
}

export default CoachMembershipView;
