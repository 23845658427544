import React, { Component } from "react";
import {
    Button, Input, ListGroupItem, Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { MdRemove, MdWarning } from 'react-icons/md';

import {HOUR_TIME, MIN_TIME, DAY_TIME} from 'utils/Utils';

export class SubSeasonDeploy extends Component {
    constructor(props) {
        super(props);
        const subSeason = {};
        if (props.subSeason != null) {
            for (const [key, value] of Object.entries(props.subSeason)) {
                subSeason[key] = value;
            }
        }
        
        this.state = {
            subSeason: subSeason,
            progress: false,
            locationTimeMap: {},
            startTime: 0,
            newImage: {},
            imageProgress: 0
        }
    }

    renderEvents() {
    }

    checklocationTimes(locationTimeMap) {
        let arr = Object.values(locationTimeMap);
        arr.sort(function(a, b) {
            if (a.offset != null && b.offset != null) {
                return a.offset - b.offset;
            } else {
                return -1;
            }
        })
        for (const [, value] of Object.entries(locationTimeMap)) {
            value.overlapped = false
        }
        for(let i = 0; i < arr.length; i ++) {
            for (let j = i; j < arr.length; j ++) {
                if (i !== j && arr[j].offset != null && arr[i].offset != null && arr[i].locationId === arr[j].locationId && (
                    (arr[j].offset >= arr[i].offset && arr[j].offset <= arr[i].offsetEnd) ||
                    (arr[j].offsetEnd >= arr[i].offset && arr[j].offsetEnd <= arr[i].offsetEnd)
                    )) {
                    arr[j].overlapped = true;
                    arr[i].overlapped = true;
                }
            }
        }
    }

    calcTimes(locationTimeMap) {
        for (const [, value] of Object.entries(locationTimeMap)) {
            if (value.start != null && value.end != null && value.week != null) {
                let offset = parseInt(value.start.split(":")[0]) * HOUR_TIME + parseInt(value.start.split(":")[1]) * MIN_TIME;
                offset += value.week * DAY_TIME;
                let offsetEnd = parseInt(value.end.split(":")[0]) * HOUR_TIME + parseInt(value.end.split(":")[1]) * MIN_TIME;
                offsetEnd += value.week * DAY_TIME;
                if (offsetEnd < offset) {
                    offsetEnd += DAY_TIME;
                }

                value.offset = offset;
                value.offsetEnd = offsetEnd;
            }
        }
    }

    render() {
        const { location, onUpdateTime } = this.props;
        const { locationTimeMap } = this.state;

        let items = [];

        for (const [key, value] of Object.entries(locationTimeMap)) {
            items.push(
                <ListGroupItem key={key}>
                    <Row>
                        <Col sm={7}>
                            <TimeRangePicker
                                onChange={(times)=> {
                                    if (times[0] != null) {
                                        value.start = times[0];
                                    }
                                    if (times[1] != null) {
                                        value.end = times[1];
                                    }
                                    locationTimeMap[key] = value;
                                    this.calcTimes(locationTimeMap);
                                    this.checklocationTimes(locationTimeMap);
                                    if (onUpdateTime != null) {
                                        onUpdateTime(locationTimeMap);
                                    }
                                    this.setState({locationTimeMap: locationTimeMap});
                                }} value={[value.start, value.end]} />
                        </Col>
                        <Col sm={4}>
                            <Input type="select" name="select" id="typeChoice" onChange={e=> {
                                value.week = parseInt(e.target.value);
                                locationTimeMap[key] = value;
                                this.calcTimes(locationTimeMap);
                                this.checklocationTimes(locationTimeMap);
                                if (onUpdateTime != null) {
                                    onUpdateTime(locationTimeMap);
                                }
                                this.setState({locationTimeMap: locationTimeMap});
                            }}>
                                <option value={0}>{t('sunday')}</option>
                                <option value={1}>{t('monday')}</option>
                                <option value={2}>{t('tuesday')}</option>
                                <option value={3}>{t('wednesday')}</option>
                                <option value={4}>{t('thursday')}</option>
                                <option value={5}>{t('friday')}</option>
                                <option value={6}>{t('saturday')}</option>
                            </Input>
                        </Col>
                        <Col sm={1}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-edit" onClick={e=> {
                                        delete locationTimeMap[key]
                                        if (onUpdateTime != null) {
                                            onUpdateTime(locationTimeMap);
                                        }
                                        this.setState({locationTimeMap: locationTimeMap});
                                    }}><MdRemove/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {value.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                </ListGroupItem>
            );
        }

        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <div className='wrap-content-parent' style={{marginBottom: 20}}>
                <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                Deploy Times for location: {location.title}
                </h4>
                
                <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                    for (const [,value] of Object.entries(locationTimeMap)) {
                        if (value.start == null || value.end == null || value.week == null || value.overlapped) {
                            alert("You should fill out image and link for existing one first");
                            return;
                        }
                    }
                    const newValue = {week: 0};
                    const vals = Object.values(locationTimeMap)
                    const lastId = vals.length > 0 ? vals[vals.length - 1].id : "business0";
                    const lastIndex = parseInt(lastId.substring(8));
                    newValue.id = 'business' + (lastIndex + 1);
                    locationTimeMap[newValue.id] = newValue;
                    this.setState({locationTimeMap: locationTimeMap});
                }}>+</Button>
            </div>
            {items}
        </div>
        );
    }
}

export default SubSeasonDeploy;