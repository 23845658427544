import React, { Component } from 'react';
import {TeamSlides} from 'components/landing/team/TeamSlides';
import { CardImg } from 'reactstrap';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import { aboutUs } from 'utils/landing';
import { getOrgId } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';
import { TeamEmpty } from 'components/landing/team/TeamEmpty';

class TeamAbout extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            detailMode: 'list'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    renderContents() {
        const { team } = this.state;
        const landingData = (team && team.landingData) ? team.landingData : {};
        const aboutData = landingData.aboutUs ? landingData.aboutUs : aboutUs;

        const itemsArr = Object.values(aboutData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];

        const bgClass = 'org-landing-contents-bg-blue';
        for (const item of itemsArr) {
            items.push((
                <div>
                    <h4 className="org-landing-contents-subtitle">
                    {item.subtitle}
                    </h4>
                    {item.bg != null && item.bg !== '' && (
                    <div className="post-media-wrapper">
                    <div className="post-media-child">
                        <CardImg 
                            className='task-image'
                            src={item.bg} />
                    </div>
                    </div>
                    )}
                    <div className="org-landing-contents-text">
                    {item.text}
                    </div>
                </div>
            ))
        }

        return (
            <div className='container'>
                <div className={bgClass}>
                    <h2 className="org-landing-contents-title">
                    {aboutData.title}
                    </h2>
                    {items}
                </div>
            </div>
        )
    }

    render() {
        const {team, loading} = this.state;
        
        if (loading && !team.id) {
            return (
                <LoadingScreen />
            )
        }
        
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        if (!team.available) {
            return (
                <TeamEmpty team={team}/>
            )
        }

        return (
            <div>
                <TeamSlides 
                    team={team}
                    loading={loading} />
                <div style={{height: 50}}/>
                <div style={{paddingTop: 20}}>
                {this.renderContents()}
                </div>
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamAbout));
export { connectedApp as TeamAbout };