import React, { Component } from "react";
import ReactPlayer from 'react-player';
import {
    Card, CardBody, CardImg, Button, Form, FormGroup, Spinner
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//import SegmentedPicker from 'react-segmented-picker';
import defaultImage from 'assets/img/icons/video_default.png';
import { readDb, writeDb } from "utils/API";

export class ChallengeReviewView extends Component {
    constructor(props) {
        super(props);
        
        const {submission} = props;

        const update = {};
        if (submission != null) {
            for (const [key, value] of Object.entries(submission)) {
                update[key] = value;
            }
        }
        this.state = {
            videoChoice: 'Challenge',
            submission: update,
            submissionVideo: {},
            progress: false
        };
    }

    componentDidMount() {
        const {submission} = this.props;
        if (submission.videoId != null) {
            readDb("/challengeVideos/" + submission.videoId, (data)=>{
                const video = {};
                if (data != null) {
                    for (const [key, value] of Object.entries(data)) {
                        video[key] = value;
                    }
                }
                this.setState({submissionVideo: video})
            });
        }
    }

    componentDidUpdate(prevPros) {
        const {submission} = this.props;
        if (prevPros.submission !== submission) {
            if (submission.videoId != null) {
                readDb("/videos/" + submission.videoId, (data)=>{
                    const video = {};
                    if (data != null) {
                        for (const [key, value] of Object.entries(data)) {
                            video[key] = value;
                        }
                    }
                    this.setState({submissionVideo: video, videoChoice: 'Challenge', progress: false})
                });
            }
        }
    }
    
    onSaveClick(submission) {
        if (submission.status === 'passed' || submission.status === 'failed') {
            this.setState({progress: true});
            writeDb("/submissions/" + submission.id, error => {
                if (error) {
                    alert('Saving status failed.\n' + error);
                } else {
                    alert('Saving status success');
                }
                this.setState({progress: false});
            })
        } else {
            alert("You must input checked or failed");
        }
    }

    render() {
        const {onCloseClick, challenge} = this.props;
        const { submission, submissionVideo, progress } = this.state;
        
        let challengeImage = challenge.thumbUri != null ? challenge.thumbUri : defaultImage;
        const {videoChoice} = this.state;
        
        if (challenge != null) {
            return (
                <Form>
                    <Card className="flex-row">
                        <CardImg
                            className="profile-thumb vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {challenge.title}
                            </h4>
                            <div>{challenge.description}</div>
                            <div>Points: {challenge.points}</div>
                        </CardBody>
                    </Card>
                    {challenge != null && challenge.videoUri != null && submissionVideo != null && submissionVideo.videoUri != null && (
                        {/* <SegmentedPicker
                            options={options}
                            selection={videoChoice === 'Challenge' ? 0 : 1}
                            onSelectionChange={(newSelection) => {
                                this.setState({videoChoice: options[newSelection]})
                            }}
                        /> */}
                    )}
                    {challenge.videoUri != null && challenge.videoUri !== '' && (
                    <div>
                        <ReactPlayer 
                            className="default-player"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={videoChoice === 'Challenge' ? challenge.videoUri : submissionVideo.videoUri}
                            width={'80%'}
                            height={'auto'} />

                    </div>
                    )}
                    <FormGroup>
                        Status: {submission.status}
                        <Switch
                            checked={submission.status === 'passed'}
                            onChange={e=>{
                                submission.status = e.target.checked ? 'passed' : 'failed';
                                this.setState({submission: submission});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </FormGroup>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(submission);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ChallengeReviewView);