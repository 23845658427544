import React, { Component } from "react";
import logo200Image from 'assets/img/logo/live_192.png';

export default class OrganizerHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        }
    }

    render() {
        const {organization, email, invite} = this.props;
        const title = organization != null && organization.title != null ? organization.title : 'No Organization Yet';
        var status = "Existing";
        var statusColor ="#38be55"
        if (invite && invite.status === 'invited') {
            status = "Invited";
            statusColor ="#3592dd";
        } else if (invite && invite.status === 'registered') {
            status = "Registered";
            statusColor ="#F0D11F";
        }

        const icon = organization && organization.thumbUri != null ? organization.thumbUri : logo200Image;

        return (
            <div className="shadow-tile">
                <div className='wrap-content-parent'>
                    <div className='wrap-content-wrap-child'>
                        <img src={icon} alt='logo' className='invite-icon'/>
                    </div>
                    <div className='wrap-content-fill-child'>
                        <h1 className="invite-title">
                            {title}
                        </h1>
                        <div className='wrap-content-parent'>
                            <h3 className="invite-email wrap-content-fill-child">
                                {email}
                            </h3>
                            <h3 className="invite-status wrap-content-wrap-child" style={{color: statusColor}}>
                                {status}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
