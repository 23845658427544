import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import CoachMembershipView from 'components/membership/CoachMembershipView';
import { connect } from 'react-redux';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';

import { 
    PREMIUM_SUBSCRIPTION_PRODUCT_ID, BASIC_SUBSCRIPTION_PRODUCT_ID, PLUS_SUBSCRIPTION_PRODUCT_ID
} from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';

class ClubMembershipsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            basicSubscription: {},
            basicPrices: {},
            premiumSubscription: {},
            premiumPrices: {},
            plusSubscription: {},
            plusPrices: {},
            activeSubscriptions: {},
            premiumStatus: {},
            basicStatus: {},
            plusStatus: {},
            progress: false,
        };
    }
    
    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.loadContents();
        }
    }

    loadContents() {
        this.setState({loading: true})
        invokeHttpsApi('membership-loadMembershipInfo', {}, (data) => {
            const basicSubscription = {};
            const basicPrices = {};
            const plusSubscription = {};
            const plusPrices = {};
            const premiumSubscription = [];
            const premiumPrices = [];
            const activeSubscriptions = {};
            var premiumStatus = {};
            var basicStatus = {};
            var plusStatus = {};
        
            if (data.success) {
                for (const [key, value] of Object.entries(data.basicSubscription)) {
                    basicSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.basicPrices)) {
                    if (value.active === true) {
                        basicPrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.plusSubscription)) {
                    plusSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.plusPrices)) {
                    if (value.active === true) {
                        plusPrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.premiumSubscription)) {
                    premiumSubscription[key] = value;
                }
                for (const [key, value] of Object.entries(data.premiumPrices)) {
                    if (value.active === true) {
                        premiumPrices[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.activeSubscriptions)) {
                    activeSubscriptions[key] = value;
                }
                for (const [key, value] of Object.entries(data.premiumStatus)) {
                    premiumStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.basicStatus)) {
                    basicStatus[key] = value;
                }
                for (const [key, value] of Object.entries(data.plusStatus)) {
                    plusStatus[key] = value;
                }
            }

            this.setState({
                basicSubscription: basicSubscription,
                basicPrices: basicPrices,
                plusSubscription: plusSubscription,
                plusPrices: plusPrices,
                premiumSubscription: premiumSubscription,
                premiumPrices: premiumPrices,
                activeSubscriptions: activeSubscriptions,
                premiumStatus: premiumStatus,
                basicStatus: basicStatus,
                plusStatus: plusStatus,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    async subscribeProduct(prices, priceId) {
        const {user} = this.props;
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        const {premiumStatus, basicStatus, plusStatus,} = this.state;
        if (premiumStatus.stripeLink != null || basicStatus.stripeLink != null || plusStatus.stripeLink != null) {
            alert("You are already subscribed to other membership. You should cancel it first before you try this one.");
            return;
        }
        const selectedPrice = prices[priceId];

        if (selectedPrice != null) {
            this.setState({progress: true});
            invokeHttpsApi("membership-generateSubscriptionSession", {
                priceId: priceId,
                successUrl: window.location.origin + '/my_account',
                cancelUrl: window.location.origin + '/my_account',
            }, (data) => {
                if (data && data.sessionId) {
                    invokeHttpsApi("membership-getSubscriptionUrl", {
                        sessionId: data.sessionId,
                    }, (data) => {
                        if (data && data.url) {
                            window.location.assign(data.url);
                        }
                        this.setState({progress: false});
                    }, (error) => {
                        console.log("Function error", error)
                        this.setState({progress: false});
                    })
                }
            }, (error) => {
                console.log("Function error", error)
                this.setState({progress: false});
            })
        }
        
    }

    cancelSubscriptions() {
        const {user} = this.props;
        if (user == null || user.uid == null) {
            alert("User Credential Not loaded. You should login first.");
            return;
        }
        if (window.confirm('Are you sure you want to cancel this subscription?')) {
            invokeHttpsApi('clubQuery-cancelCoachSubscription', {}, (data) => {
                this.loadContents();
            }, (error) => {

            });
        }
    }

    renderBasicMembership() {
        const {basicSubscription, basicPrices, basicStatus, progress} = this.state;
        if (basicSubscription.name != null) {
            return (
                <CoachMembershipView
                    mainColor='#2668C2'
                    key={BASIC_SUBSCRIPTION_PRODUCT_ID}
                    membership={basicSubscription}
                    progress={progress}
                    prices={basicPrices}
                    subscribedStatus={basicStatus}
                    onSubscribe={(priceId)=> {
                        this.subscribeProduct(basicPrices, priceId);
                    }}
                    onCancelClicked={e=> {
                        this.cancelSubscriptions();
                    }} />
            );
        } else {
            return (
                <div/>
            );
        }
    }

    renderPlusMembership() {
        const {plusSubscription, plusPrices, plusStatus, progress} = this.state;
        if (plusSubscription.name != null) {
            return (
                <CoachMembershipView
                    mainColor='#16457F'
                    key={PLUS_SUBSCRIPTION_PRODUCT_ID}
                    membership={plusSubscription}
                    progress={progress}
                    prices={plusPrices}
                    subscribedStatus={plusStatus}
                    onSubscribe={(priceId)=> {
                        this.subscribeProduct(plusPrices, priceId);
                    }}
                    onCancelClicked={e=> {
                        this.cancelSubscriptions();
                    }} />
            );
        } else {
            return (
                <div/>
            );
        }
    }

    renderPremiumMembership() {
        const {premiumSubscription, premiumPrices, premiumStatus, progress} = this.state;
        if (premiumSubscription.name != null) {
            return (
                <CoachMembershipView
                    mainColor='#ED1C22'
                    key={PREMIUM_SUBSCRIPTION_PRODUCT_ID}
                    membership={premiumSubscription}
                    progress={progress}
                    prices={premiumPrices}
                    subscribedStatus={premiumStatus}
                    onSubscribe={(priceId)=> {
                        this.subscribeProduct(premiumPrices, priceId);
                    }}
                    onCancelClicked={e=> {
                        this.cancelSubscriptions();
                    }} />
            );
        } else {
            return (
                <div/>
            );
        }
    }

    render() {
        const { productId } = this.props;
        const { loading } = this.state;
        if (!loading && productId && productId === BASIC_SUBSCRIPTION_PRODUCT_ID) {
            return (
                <div className='container' style={{marginTop: 20}}>
                    <Row>
                        <Col sm={4} xs={12}/>
                        <Col sm={4} xs={12}>
                            {this.renderBasicMembership()}
                        </Col>
                        <Col sm={4} xs={12}/>
                    </Row>
                </div>
            )
        }
        if (!loading && productId && productId === PLUS_SUBSCRIPTION_PRODUCT_ID) {
            return (
                <div className='container' style={{marginTop: 20}}>
                    <Row>
                        <Col sm={4} xs={12}/>
                        <Col sm={4} xs={12}>
                            {this.renderPlusMembership()}
                        </Col>
                        <Col sm={4} xs={12}/>
                    </Row>
                </div>
            )
        }
        if (!loading && productId && productId === PREMIUM_SUBSCRIPTION_PRODUCT_ID) {
            return (
                <div className='container' style={{marginTop: 20}}>
                    <Row>
                        <Col sm={4} xs={12}/>
                        <Col sm={4} xs={12}>
                            {this.renderPremiumMembership()}
                        </Col>
                        <Col sm={4} xs={12}/>
                    </Row>
                </div>
            )
        }

        return (
            <div>
                {loading ? (
                    <SmallLoadingView />
                ) : (
                <div className='container' style={{marginTop: 20}}>
                    <Row>
                        <Col sm={4} xs={12}>
                            {this.renderBasicMembership()}
                        </Col>
                        <Col sm={4} xs={12}>
                            {this.renderPlusMembership()}
                        </Col>
                        <Col sm={4} xs={12}>
                            {this.renderPremiumMembership()}
                        </Col>
                    </Row>
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { primarySubscription } = state.subscriptions;
    return { alert, loggingIn, user, primarySubscription };
}

const connectedApp = connect(mapState)(ClubMembershipsView);
export { connectedApp as ClubMembershipsView };
