import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Row, Col
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import MembersView from 'components/members/MembersView';

import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import { copyHour, DAY_TIME, getWeekStartDay, MIN_TIME, WEEK_TIME } from "utils/Utils";
import { generateId, invokeHttpsApi } from "utils/API";

export class ScheduleEdit extends Component {
    constructor(props) {
        super(props);
        const schedule = {};
        if (props.schedule != null) {
            for (const [key, value] of Object.entries(props.schedule)) {
                schedule[key] = value;
            }
        }
        let duration = 15;
        if (schedule.timestamp && schedule.deadline) {
            duration = (schedule.timestamp - schedule.deadline) / MIN_TIME;
        }
        
        this.state = {
            schedule: schedule,
            duration: duration,
            repeating: {
                enabled: true,
                end: (schedule.timestamp ? schedule.timestamp : new Date().valueOf()) + WEEK_TIME,
                sunday: false,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
            },
            progress: false,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, schedule} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.schedule !== schedule)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(schedule)) {
                challengeVal[key] = value;
            }
            this.setState({
                schedule: challengeVal
            })
        }
    }

    onSaveClick(schedule) {
        const {program, uid, onChanged} = this.props;
        if (schedule.title == null || schedule.title === '') {
            alert("You must input title");
        } else if (schedule.location == null || schedule.location === '') {
            alert("You must input location");
        } else if (schedule.deadline == null || schedule.timestamp == null || 
            schedule.timestamp <= 0 || schedule.timestamp <= new Date().valueOf() || schedule.deadline <= schedule.timestamp ) {
            alert("You must input valid start time and duration.");
        } else {
            if (schedule.everyone) {
                schedule.members = {};
                for (const [k, v] of Object.entries(program.playerIds ? program.playerIds : {})) {
                    schedule.members[k] = v;
                }
            }
            schedule.owner = uid != null ? uid : "free";
            schedule.programId = program.id;
            schedule.organizationId = program.organizationId;
            let array = this.getScheduleArray(schedule);
        
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            this.saveChange(array, (update) => {
                alert("Your Change subimitted successfully.");
                if (onChanged != null) {
                    onChanged(array);
                }
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
            })
        }
    }

    getScheduleArray(schedule) {
        const {repeating} = this.state;

        const result = [];
        if (repeating.enabled && !schedule.id) {
            const start = schedule.timestamp;
            const end = repeating.end;

            let startTime = getWeekStartDay(schedule.timestamp);
            while (startTime < end + WEEK_TIME) {
                if (repeating.sunday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.monday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.tuesday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.wednesday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.thursday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.friday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
                if (repeating.saturday && startTime >= start && startTime <= end) {
                    result.push(this.copySchedule(schedule, startTime))
                }
                startTime += DAY_TIME;
            }
        } else if (!schedule.id) {
            schedule.id = generateId("/schedule");
            result.push(schedule);
        } else {
            result.push(schedule);
        }

        return result;
    }

    copySchedule(schedule, time) {
        const { duration } = this.state;
        const result = {};
        for (const [k,v] of Object.entries(schedule)) {
            result[k] = v;
        }
        result.id = generateId("/programSchedule");
        result.timestamp = copyHour(time, schedule.timestamp);
        result.deadline = duration * MIN_TIME + result.timestamp;
        
        return result;
    }

    saveChange(array, success, failure) {
        const {program} = this.props;
        const map = {};
        for (const item of array) {
            map[item.id] = item;
        }
        invokeHttpsApi('organizerQuery-submitProgramSchedules', {
            programId: program.id,
            schedules: map,
        }, (data) => {
            if (data && data.success) {
                success(map);
            } else {
                failure(null);
            }
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
            failure(error);
        });
    }

    render() {
        const {program, onCloseClick} = this.props;
        const {schedule, repeating, duration, newImage, progress} = this.state;

        const start = schedule.timestamp != null ? new Date(schedule.timestamp) : new Date(new Date().valueOf() + 5 * MIN_TIME);
        
        if (schedule != null) {
            return (
                <Form className="mail-box-detail">
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            schedule.title = e.target.value;
                            this.setState({schedule: schedule});
                        }} value={schedule.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={schedule.description} onChange={e => {
                            schedule.description = e.target.value;
                            this.setState({schedule: schedule});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="title">Location</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            schedule.location = e.target.value;
                            this.setState({schedule: schedule});
                        }} value={schedule.location} />
                    </FormGroup>
                    <FormGroup>
                        <Label style={{marginTop: 10}}>{t('everyone')}</Label>
                        <Switch
                            checked={schedule.everyone}
                            onChange={e=>{
                                schedule.everyone = e.target.checked;
                                this.setState({schedule: schedule});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </FormGroup>
                    {!schedule.everyone && (
                    <FormGroup>
                        <div>Members: {schedule.members != null ? Object.values(schedule.members).length : 0}</div>
                        <div className='divider'/>
                        <MembersView
                            available={program.playerIds}
                            members={schedule.members}
                            onSaveClick={(members)=> {
                                schedule.members = {};
                                if (members != null) {
                                    for (const[key, value] of Object.entries(members)) {
                                        schedule.members[key] = value;
                                    }
                                }
                                this.setState({schedule: schedule});
                            }} />
                    </FormGroup>
                    )}
                    <FormGroup>
                        <Label for="timestamp">{t('start_time')}</Label>
                        <CustomDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MM/DD/YYYY hh:mm"
                            value={start}
                            onChange={ val => {
                                schedule.timestamp = val.valueOf();
                                schedule.deadline = duration * MIN_TIME + schedule.timestamp;
                                this.setState({schedule: schedule});
                            }} />
                    </FormGroup>
                    {!schedule.id && repeating.enabled && (
                    <FormGroup>
                        <Label for="timestamp">{t('end_time')}</Label>
                        <CustomDateTimePicker
                            margin="normal"
                            id="date-picker-dialog"
                            format="MM/DD/YYYY hh:mm"
                            value={repeating.end}
                            onChange={ val => {
                                repeating.end = val.valueOf();
                                this.setState({repeating: repeating});
                            }} />
                    </FormGroup>
                    )}
                    <FormGroup>
                        <Label for="duration_limit">{t('duration_min')}</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            const update = parseFloat(e.target.value);
                            this.setState({duration: update});
                            if (schedule.timestamp != null) {
                                schedule.deadline = update * MIN_TIME + schedule.timestamp;
                                this.setState({schedule: schedule});
                            }
                        }} value={duration} />
                    </FormGroup>
                    {!schedule.id && (
                    <FormGroup>
                        <Label style={{marginTop: 10}}>{t('repeat')}</Label>
                        <Switch
                            checked={repeating.enabled}
                            onChange={e=>{
                                repeating.enabled = e.target.checked;
                                this.setState({repeating: repeating});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </FormGroup>
                    )}
                    {!schedule.id && repeating.enabled && (
                    <Row>
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('sunday')}</Label>
                            <Switch
                                checked={repeating.sunday}
                                onChange={e=>{
                                    repeating.sunday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('monday')}</Label>
                            <Switch
                                checked={repeating.monday}
                                onChange={e=>{
                                    repeating.monday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('tuesday')}</Label>
                            <Switch
                                checked={repeating.tuesday}
                                onChange={e=>{
                                    repeating.tuesday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('wednesday')}</Label>
                            <Switch
                                checked={repeating.wednesday}
                                onChange={e=>{
                                    repeating.wednesday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('thursday')}</Label>
                            <Switch
                                checked={repeating.thursday}
                                onChange={e=>{
                                    repeating.thursday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('friday')}</Label>
                            <Switch
                                checked={repeating.friday}
                                onChange={e=>{
                                    repeating.friday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                        {repeating.enabled && (
                        <Col xs={4} lg={3}>
                            <Label style={{minWidth: 60}}>{t('saturday')}</Label>
                            <Switch
                                checked={repeating.saturday}
                                onChange={e=>{
                                    repeating.saturday = e.target.checked;
                                    this.setState({repeating: repeating});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                        )}
                    </Row>
                    )}
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='btn-submit' onClick={e => {
                                this.onSaveClick(schedule, newImage);
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ScheduleEdit);