import React, { Component } from "react";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import {
    Card, Row, Col, FormGroup, Input, Button, CardImg, Modal, ModalBody, ModalFooter, Label, Spinner
} from 'reactstrap';
import { t } from 'i18next';

import { 
    STRIPE_PUBLISHABLE_KEY,
} from 'utils/Utils';

import { loadStripe } from '@stripe/stripe-js';

import SubInfoView from 'components/detail/SubInfoView';
import lineupImage from 'assets/img/icons/lineup.png';
import gameImage from 'assets/img/icons/league_default.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import TopPlayer from 'components/detail/organization/landing/TopPlayer';
import DivisionLocationsView from 'components/detail/organization/DivisionLocationsView';
import ClubEditView from 'components/edit/club/ClubEditView';
import { getPlayerStatisticsList } from 'utils/statistics';
import { toDateString, renderHtmlString } from 'utils/Utils';
import divisionIcon from 'assets/img/logo/live_192.png';
import emailIcon from 'assets/img/icons/ic_email.png';
import phoneIcon from 'assets/img/icons/ic_phone.png';
import calendarIcon from 'assets/img/icons/games_icon.png';
import deadlineIcon from 'assets/img/icons/time_icon.png';
import genderIcon from 'assets/img/icons/ic_gender.png';
import typeIcon from 'assets/img/icons/instruction_3.png';
import userImage from 'assets/img/icons/player_photo_default.png';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import OrganizationProfileEdit from 'components/edit/organization/OrganizationProfileEdit';
import Switch from '@mui/material/Switch';
import AttachItemView from 'components/detail/AttachItemView';
import { getOrgDomain } from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";
import { STATUS_FINISHED } from "utils/constants";
import { withRouter } from "utils/Routher";

class OrganizationDivisionOverview extends Component {
    constructor(props) {
        super(props);
        let seasonChoice = {};
        if (props.seasons != null) {
            if (props.seasonId) {
                for (const val of Object.values(props.seasons)) {
                    if (props.seasonId === val.id) {
                        seasonChoice = val;
                        break;
                    }
                }
            } else if (props.seasonChoice && props.seasonChoice.id) {
                seasonChoice = props.seasonChoice;
            } else {
                for (const val of Object.values(props.seasons)) {
                    if (val.divisionId === props.division.id) {
                        seasonChoice = val;
                        if (val.status !== STATUS_FINISHED) {
                            break;
                        }
                    }
                }
            }
        }
        props.onSeasonChoice(seasonChoice);
        this.state = {
            detailChoice: {},
            checked: {},
            editOpen: false,
            saveProgress: false,
        }
    }
    
    componentDidUpdate(prevProps) {
        const { division, seasons, seasonId, seasonChoice } = this.props;
        
        if (division !== prevProps.division) {
            let seasonUpdate = {};
            for (const val of Object.values(seasons)) {
                if (seasonChoice) {
                    seasonUpdate = seasonChoice;
                    break;
                } else if (val.divisionId === division.id && seasonId && seasonId === val.id) {
                    seasonUpdate = val;
                    break;
                } else if (val.divisionId === division.id) {
                    seasonUpdate = val;
                    if (val.status && val.status !== STATUS_FINISHED) {
                        break;
                    }
                }
            }
            const subSeasons = seasonUpdate.subSeasons != null ? seasonUpdate.subSeasons : {};
            const choice = Object.values(subSeasons).length > 0 ? Object.values(subSeasons)[0] : {};
            this.props.onSeasonChoice(seasonUpdate);
            this.setState({
                gameChoice: {},
                gamesChoice: choice,
                matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1},
                editTeamData: false,
                editLocation: false,
                progress: false,
            });
        }
    }

    renderDivision() {
        const {division, locations} = this.props;
        
        return (
            <DivisionLocationsView 
                division={division}
                locations={locations}  />
        );
    }

    renderCoachRegistration() {
        const { organization, seasonChoice, myTeams, user, onChanged } = this.props;
        
        const teamsChoice = [];
        for (const team of Object.values(myTeams)) {
            teamsChoice.push(team)
        }
        teamsChoice.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        })

        if (!user || !user.uid) {
            return (
                <div className='wrap-content-parent'>
                    <Button className='wrap-content-fill-child btn-submit' onClick={e=> {
                        let path = this.props.router.location;
                        if (path.includes('championships')) {
                            path = `${getOrgDomain(organization)}/championships/${seasonChoice.id}`
                        } else if (path.includes('register')) {
                            path = `${getOrgDomain(organization)}/register/${seasonChoice.id}`
                        }
                        this.props.setAuthRedirect(path);
                        this.props.router.navigate('/login');
                    }}>
                    {t('go_login')}
                    </Button>
                </div>
            )
        } else if ((seasonChoice.status === "registration" || !seasonChoice.status) && teamsChoice.length > 0) {
            return (
                <div>
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                    <h3 style={{fontSize: 20}}>{t('team_reg_section')}</h3>
                    <h4 style={{fontSize: 12}}>{t('reg_until')} {toDateString(seasonChoice.regDeadline)}</h4>
                    
                    {seasonChoice && seasonChoice.enrollFee && seasonChoice.enrollFee.teamFee && (
                    <div className='wrap-content-parent'>
                        <b className='reg-price-name'>
                        {t('team_fee')}:
                        </b>
                        <h4 className='reg-price-label'>
                        $ {seasonChoice.enrollFee.teamFee}
                        </h4>
                    </div>
                    )}
                    {teamsChoice.map((team)=> (
                    <div key={team.id}>
                        {this.renderTeamSeasonRequest(seasonChoice, team)}
                    </div>
                    )) }
                </div>
            )
        } else if ((seasonChoice.status === "registration" || !seasonChoice.status) && teamsChoice.length === 0) {
            return (
                <div>
                    <h3 style={{fontSize: 15}}>{t('create_team')}</h3>
                    <div className="text-center" style={{marginBottom: 15}}>
                        <ClubEditView 
                            uid={user ? user.uid : null}
                            squad={{}} 
                            createMode={true}
                            onCloseClick={() => {
                                this.setState({createTeam: false})
                            }}
                            onChanged={(squad)=>{
                                if (onChanged) {
                                    onChanged();
                                }
                            }} />
                    </div>
                </div>
            )
        }

        return <div/>
    }
    
    renderPlayerRegistration() {
        const { seasonChoice, teams, user, users } = this.props;
        
        if (!user || !user.uid) {
            return <div/>
        }
        
        var childList = [];
        var cihldIds = [];
        childList.push(user);
        cihldIds.push(user.uid);
        for (const val of Object.values(users)) {
            if (user && user.uid === val.parentId) {
                childList.push(val);
                cihldIds.push(val.uid);
            }
        }
        childList.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        let list = [];
        
        if (seasonChoice && seasonChoice.teamData) {
            for (const childId of cihldIds) {
                for (const [teamId, teamData] of Object.entries(seasonChoice.teamData)) {

                    const team = teams[teamId];
                    if (team) {
                        if (teamData.roaster && Object.values(teamData.roaster).includes(childId)) {
                            list.push(
                                <div>{this.renderPlayerSeasonRoster(team, childId)}</div>
                            )
                        }
                    }
                }
            }
        }
        
        if (!user || !user.uid) {
            return (
                <div />
            )
        } else if ((seasonChoice.status === "registration" || !seasonChoice.status) && seasonChoice.individualReg) {
            return (
                <div>
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                    <h3 style={{fontSize: 20}}>{t('player_reg_section')}</h3>
                    {seasonChoice && seasonChoice.enrollFee && seasonChoice.enrollFee.playerFee && (
                    <div className='wrap-content-parent'>
                        <b className='reg-price-name'>
                        {t('player_fee')}:
                        </b>
                        <h4 className='reg-price-label'>
                        $ {seasonChoice.enrollFee.playerFee}
                        </h4>
                    </div>
                    )}
                    {seasonChoice && seasonChoice.enrollFee && seasonChoice.enrollFee.managerFee && (
                    <div className='wrap-content-parent'>
                        <b className='reg-price-name'>
                        {t('staff_fee')}:
                        </b>
                        <h4 className='reg-price-label'>
                        $ {seasonChoice.enrollFee.managerFee}
                        </h4>
                    </div>
                    )}
                    {childList.map((child, idx)=> (
                    <div key={idx}>
                        {this.renderPlayerSeasonRequest(seasonChoice, child)}
                    </div>
                    )) }
                </div>
            )
        } else if (!seasonChoice.individualReg && list.length > 0) {

            return (
                <div>
                    <h3 style={{fontSize: 15}}>{t('roster_reg')}</h3>                    
                    {list}
                </div>
            )
        }

        return <div/>
    }

    renderPlayers(season) {

    }

    renderTeamSeasonRequest(season, team) {
        var {organization} = this.props;
        var {teamMethods, teamNotes} = this.state;

        if (!teamMethods) {
            teamMethods = {};
        }

        if (!teamNotes) {
            teamNotes = {};
        }
        const teamMethod = teamMethods[team.id] ? teamMethods[team.id] : 'direct';
        const teamNote = teamNotes[team.id] ? teamNotes[team.id] : null;

        if (team) {
            const teamIcon = team.iconUri ? team.iconUri : defaultImage;
            
            if (season.teamRegisters && Object.keys(season.teamRegisters).includes(team.id)) {
                let register = season.teamRegisters[team.id];
                
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <b className='wrap-content-wrap-child'>
                            {t('team')}:
                            </b>
                            <img className='reg-icon-team' alt="logo" src={teamIcon} />
                            <div className='wrap-content-fill-child'>
                            {team.title}
                            </div>
                        </div>
                        <div>
                            <h3 style={{fontSize: 15, marginRight: 10}}>
                            {t('enrolled')}
                            </h3>
                            <div className='wrap-content-fill-child reg-info-label'>
                                <span>{t('method')}: <b>{register.method}</b> Role: <b>{register.role}</b></span>
                                <div>{t('note')}: {register.note}</div>
                            </div>
                        </div>
                    </div>
                )
            } else if (season.teamPending && Object.keys(season.teamPending).includes(team.id)) {
                let register = season.teamPending[team.id];
                
                let enroll = <div/>;
                if (register.method === 'wallet' && season.enrollFee && season.enrollFee.teamFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestTeamChargeRegister(season, team, register.method, register.note, season.enrollFee.teamFee)
                        }}>
                        {t('pay_on_stripe')}:( ${season.enrollFee.teamFee} {t('enroll')})
                        </Button>
                    )
                } else if (register.method === 'wallet' && season.enrollFee && season.enrollFee.teamFee) {
                    <Button className='btn-submit' onClick={e=> {
                        this.requestTeamRegisterWallet(season, team, register.method, register.note, season.enrollFee.teamFee)
                    }}>
                    {t('pay_on_wallet')}:( ${season.enrollFee.teamFee} {t('enroll')})
                    </Button>
                } else {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            if (window.confirm('Are you sure you want to change enroll request?')) {
                                this.requestTeamRegister(season, team, register.method, register.note);
                            }
                        }}>
                        {t('update_request')}:
                        </Button>
                    )
                }
                
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <b className='wrap-content-wrap-child'>
                            {t('team')}:
                            </b>
                            <img className='reg-icon-team' alt="logo" src={teamIcon} />
                            <div className='wrap-content-fill-child'>
                            {team.title}
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup style={{margin: '5px 10px 5px 0px'}}>
                                    <Input type="select" name="select" 
                                    value={register.method === 'direct' || register.method === 'wallet' ? register.method : null} id="challengeChoice" 
                                    onChange={e=>{
                                            season.teamPending[team.id].method = e.target.value;
                                            
                                            this.props.onSeasonChoice(season);
                                        }}>
                                        <option value={null}>{t('pay_in_person')}:</option>
                                        {organization.stripeAccountId && (
                                        <option value="direct">{t('pay_on_stripe')}:</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        {register.method === 'direct' ? (
                            <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                        ) : (register.method === 'wallet' ? (
                            <div className="reg-method-info-label">You can pay now with money from your wallet balance.</div>
                        ) : (
                            <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                        ))}
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup>
                                    <Label for="playerName" style={{marginRight: 15}}>Note</Label>
                                    <Input type="text" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                        season.teamPending[team.id].note = e.target.value;
                                            this.props.onSeasonChoice(season);
                                    }} value={register.note} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                            {register.method !== 'direct' && (
                                <FormGroup style={{margin: '0px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={register.method} id="challengeChoice" onChange={e=>{
                                            season.teamPending[team.id].method = e.target.value;
                                            
                                            this.props.onSeasonChoice(season);
                                        }}>
                                        <option value={null}>{t('choose_payment_method')}:</option>
                                        <option value="cash">{t('cash')}</option>
                                        <option value="online">{t('online')}</option>
                                        <option value="eTransfer">{t('e_transfer')}</option>
                                    </Input>
                                </FormGroup>
                            )}
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 2}}>
                                {enroll}
                            </div>
                        </div>
                    </div>
                )

            } else if (season.teamIds && Object.values(season.teamIds).includes(team.id)) {
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <b className='wrap-content-wrap-child'>
                            {t('team')}:
                            </b>
                            <img className='reg-icon-team' alt="logo" src={teamIcon} />
                            <div className='wrap-content-fill-child'>
                            {team.title}
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <h3 style={{fontSize: 15, marginRight: 10}}>
                            Member
                            </h3>
                            <div className='wrap-content-fill-child' style={{fontSize: 11}}>
                                <div>{t('assigned_team_directly')}</div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                let enroll = <div/>;
                if (teamMethod === 'direct' && season.enrollFee && season.enrollFee.teamFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestTeamChargeRegister(season, team, teamMethod, teamNote, season.enrollFee.teamFee)
                        }}>
                        {t('pay_on_stripe')}:( ${season.enrollFee.teamFee} {t('enroll')})
                        </Button>
                    )
                } else if (teamMethod === 'wallet' && season.enrollFee && season.enrollFee.teamFee) {
                    <Button className='btn-submit' onClick={e=> {
                        this.requestTeamRegisterWallet(season, team, teamMethod, teamNote, season.enrollFee.teamFee)
                    }}>
                    {t('pay_on_wallet')}:( ${season.enrollFee.teamFee} {t('enroll')})
                    </Button>
                } else {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestTeamRegister(season, team, teamMethod, teamNote)
                        }}>
                        {t('enroll')}
                        </Button>
                    )
                }
                
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <b className='wrap-content-wrap-child'>
                            {t('team')}:
                            </b>
                            <img className='reg-icon-team' alt="logo" src={teamIcon} />
                            <div className='wrap-content-fill-child'>
                            {team.title}
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup style={{margin: '5px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={teamMethod === 'direct' ? 'direct' : null} id="challengeChoice" onChange={e=>{
                                            const update = e.target.value;
                                            teamMethods[team.id] = update;
                                            this.setState({teamMethods: teamMethods});
                                        }}>
                                        <option value={null}>{t('pay_in_person')}:</option>
                                        {organization.stripeAccountId && (
                                        <option value="direct">{t('pay_on_stripe')}:</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        {teamMethod === 'direct' ? (
                            <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                        ) : (
                            <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                        )}
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup>
                                    <Label for="playerName" style={{marginRight: 15}}>Note</Label>
                                    <Input type="text" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                            const update = e.target.value;
                                            teamNotes[team.id] = update;
                                        this.setState({teamNotes: teamNotes});
                                    }} value={teamNote} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                            {teamMethod !== 'direct' && (
                                <FormGroup style={{margin: '0px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={teamMethod} id="challengeChoice" onChange={e=>{
                                            const update = e.target.value;
                                            teamMethods[team.id] = update;
                                            this.setState({teamMethods: teamMethods});
                                        }}>
                                        <option value={null}>{t('choose_payment_method')}:</option>
                                        <option value="cash">{t('cash')}</option>
                                        <option value="online">{t('online')}</option>
                                        <option value="eTransfer">{t('e_transfer')}</option>
                                        <option value="wallet">{t('pay_on_wallet')}:</option>
                                    </Input>
                                </FormGroup>
                            )}
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 2}}>
                                {enroll}
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return <div/>
        }
    }
    
    renderPlayerSeasonRequest(season, user) {
        const {seasonChoice, profileData, organization} = this.props;
        var {methodChoices, roleChoices, notes} = this.state;

        if (!methodChoices) {
            methodChoices = {};
        }
        if (!roleChoices) {
            roleChoices = {};
        }
        if (!notes) {
            notes = {};
        }
        const methodChoice = methodChoices[user.uid] ? methodChoices[user.uid] : 'direct';
        const roleChoice = roleChoices[user.uid] ? roleChoices[user.uid] : null;
        const note = notes[user.uid] ? notes[user.uid] : null;

        if (user) {
            const userIcon = user.photoUri ? user.photoUri : userImage;
            let profile = {
                uid: user.uid,
                parentId: user.parentId,
                playerName: user.username,
                photoUri: user.photoUri,
            };
            profile.organizationId = organization.id;
            profile.owner = organization.owner;
            
            for (const item of Object.values(profileData)) {
                if (item.uid === user.uid && item.organizationId === seasonChoice.organizationId) {
                    profile = item;
                }
            }
            const submitClass = 'wrap-content-fill-child btn-submit';
            const greenClass = 'wrap-content-fill-child btn-green';
    
            const policyFlag = seasonChoice.policyFlag && seasonChoice.policyFlag[user.uid];
    
    
            if (season.registers && Object.keys(season.registers).includes(user.uid)) {
                let register = season.registers[user.uid];
                
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <img className='reg-icon-user' alt="logo" src={userIcon} />
                            <div className='wrap-content-fill-child'>
                            {user.username}
                            </div>
                        </div>
                        <div>
                            <h3 style={{fontSize: 15, marginRight: 10}}>
                            {t('enrolled')}
                            </h3>
                            <div className='wrap-content-fill-child reg-info-label'>
                                <span>{t('method')}: <b>{register.method}</b> Role: <b>{register.role}</b></span>
                                <div>{t('note')}: {register.note}</div>
                            </div>
                        </div>
                        <div className='wrap-content-parent' style={{margin: '5px 0px'}}>
                            <Button className={profile.id ? greenClass : submitClass} onClick={e=> {
                                this.setState({profileChoice: profile, userChoice: user})
                            }}>
                            {profile.id ? t('view_profile') : t('submit_profile')}
                            </Button>
                            <Button className={policyFlag ? greenClass : submitClass} onClick={e=> {
                                this.setState({userChoice: user, showPolicy: true})
                            }}>
                            {policyFlag ? t('show_policy') : t('ack_policy')}
                            </Button>
                        </div>
                    </div>
                )
            } else if (season.pending && Object.keys(season.pending).includes(user.uid)) {
                let register = season.pending[user.uid];
                
                let enroll = <div/>;
                if (register.method === 'direct' && register.role === 'player' && season.enrollFee && season.enrollFee.playerFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestDirectChargeRegister(season, user, register.method, register.role, register.note, season.enrollFee.playerFee)
                        }}>
                        {t('pay_on_stripe')}:( ${season.enrollFee.playerFee} {t('enroll')})
                        </Button>
                    )
                } else if (register.method === 'direct' && register.role === 'manager' && season.enrollFee && season.enrollFee.managerFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestDirectChargeRegister(season, user, register.method, register.role, register.note, season.enrollFee.managerFee)
                        }}>
                        {t('pay_on_stripe')}:(${season.enrollFee.managerFee} {t('enroll')})
                        </Button>
                    )
                } else if (register.method === 'wallet' && register.role === 'player' && season.enrollFee && season.enrollFee.playerFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestWalletRegister(season, user, register.method, register.role, register.note, season.enrollFee.playerFee)
                        }}>
                        {t('pay_on_wallet')}:( ${season.enrollFee.playerFee} {t('enroll')})
                        </Button>
                    )
                } else if (register.method === 'wallet' && register.role === 'manager' && season.enrollFee && season.enrollFee.managerFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestWalletRegister(season, user, register.method, register.role, register.note, season.enrollFee.managerFee)
                        }}>
                        {t('pay_on_wallet')}:(${season.enrollFee.managerFee} {t('enroll')})
                        </Button>
                    )
                } else {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            if (window.confirm('Are you sure you want to change enroll request?')) {
                                this.requestRegister(season, user, register.method, register.role, register.note);
                            }
                        }}>
                        {t('update_request')}:
                        </Button>
                    )
                }
                
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <img className='reg-icon-user' alt="logo" src={userIcon} />
                            <div className='wrap-content-fill-child'>
                            {user.username}
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup style={{margin: '5px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={register.role} id="challengeChoice" onChange={e=>{
                                            season.pending[user.uid].role = e.target.value;
                                            
                                            this.props.onSeasonChoice(season);
                                        }}>
                                        <option value={null}>{t('choose_enroll_type')}</option>
                                        <option value="player">{t('player')}</option>
                                        <option value="manager">{t('staff')}</option>
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className='wrap-content-fill-child'>
                                <FormGroup style={{margin: '5px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={register.method === 'direct' ? 'direct' : null} id="challengeChoice" onChange={e=>{
                                            season.pending[user.uid].method = e.target.value;
                                            
                                            this.props.onSeasonChoice(season);
                                        }}>
                                        <option value={null}>{t('pay_in_person')}:</option>
                                        {organization.stripeAccountId && (
                                        <option value="direct">{t('pay_on_stripe')}:</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        {register.method === 'direct' ? (
                            <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                        ) : (
                            <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                        )}
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup>
                                    <Label for="playerName" style={{marginRight: 15}}>Note</Label>
                                    <Input type="text" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                        season.pending[user.uid].note = e.target.value;
                                        this.props.onSeasonChoice(season);
                                    }} value={register.note} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                            {register.method !== 'direct' && (
                                <FormGroup style={{margin: '0px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={register.method} id="challengeChoice" onChange={e=>{
                                            season.pending[user.uid].method = e.target.value;
                                            
                                            this.props.onSeasonChoice(season);
                                        }}>
                                        <option value={null}>{t('choose_payment_method')}:</option>
                                        <option value="cash">{t('cash')}</option>
                                        <option value="online">{t('online')}</option>
                                        <option value="eTransfer">{t('e_transfer')}</option>
                                        <option value="wallet">{t('pay_on_wallet')}:</option>
                                    </Input>
                                </FormGroup>
                            )}
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 2}}>
                                {enroll}
                            </div>
                        </div>
                    </div>
                )

            } else {
                let enroll = <div/>;
                if (methodChoice === 'direct' && roleChoice === 'player' && season.enrollFee && season.enrollFee.playerFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestDirectChargeRegister(season, user, methodChoice, roleChoice, note, season.enrollFee.playerFee)
                        }}>
                        ${season.enrollFee.playerFee} {t('enroll')}
                        </Button>
                    )
                } else if (methodChoice === 'direct' && roleChoice === 'manager' && season.enrollFee && season.enrollFee.managerFee) {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestDirectChargeRegister(season, user, methodChoice, roleChoice, note, season.enrollFee.managerFee)
                        }}>
                        ${season.enrollFee.managerFee} {t('enroll')}
                        </Button>
                    )
                } else {
                    enroll = (
                        <Button className='btn-submit' onClick={e=> {
                            this.requestRegister(season, user, methodChoice, roleChoice, note)
                        }}>
                        {t('enroll')}
                        </Button>
                    )
                }
                
                return (
                    <div className="shadow-tile" style={{padding: '7px 10px', margin: '5px 0px'}}>
                        <div className='wrap-content-parent' style={{fontSize: 14, marginTop: 10, marginBottom: 10}}>
                            <img className='reg-icon-user' alt="logo" src={userIcon} />
                            <div className='wrap-content-fill-child'>
                            {user.username}
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup style={{margin: '5px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={roleChoice} id="challengeChoice" onChange={e=>{
                                            const update = e.target.value;
                                            roleChoices[user.uid] = update;
                                            this.setState({roleChoices: roleChoices});
                                        }}>
                                        <option value={null}>{t('choose_enroll_type')}</option>
                                        <option value="player">{t('player')}</option>
                                        <option value="manager">{t('staff')}</option>
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className='wrap-content-fill-child'>
                                <FormGroup style={{margin: '5px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={methodChoice === 'direct' ? 'direct' : null} id="challengeChoice" onChange={e=>{
                                            const update = e.target.value;
                                            methodChoices[user.uid] = update;
                                            this.setState({methodChoices: methodChoices});
                                        }}>
                                        <option value={null}>{t('pay_in_person')}:</option>
                                        {organization.stripeAccountId && (
                                        <option value="direct">{t('pay_on_stripe')}:</option>
                                        )}
                                        <option value="wallet">{t('pay_on_wallet')}:</option>
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        {methodChoice === 'direct' ? (
                            <div className="reg-method-info-label">{t('reg_pay_card')}</div>
                        ) : (
                            <div className="reg-method-info-label">{t('reg_pending_organizer')}</div>
                        )}
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                                <FormGroup>
                                    <Label for="playerName" style={{marginRight: 15}}>Note</Label>
                                    <Input type="text" name="playerName" id="playerName" style={{marginRight: 15}} onChange={e => {
                                        const update = e.target.value;
                                        notes[user.uid] = update;
                                        this.setState({notes: notes});
                                    }} value={note} />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                            {methodChoice !== 'direct' && (
                                <FormGroup style={{margin: '0px 10px 5px 0px'}}>
                                    <Input type="select" name="select" value={methodChoice} id="challengeChoice" onChange={e=>{
                                            const update = e.target.value;
                                            methodChoices[user.uid] = update;
                                            this.setState({methodChoices: methodChoices});
                                        }}>
                                        <option value={null}>{t('choose_payment_method')}:</option>
                                        <option value="cash">{t('cash')}</option>
                                        <option value="online">{t('online')}</option>
                                        <option value="eTransfer">{t('e_transfer')}</option>
                                        <option value="wallet">{t('pay_on_wallet')}:</option>
                                    </Input>
                                </FormGroup>
                            )}
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 2}}>
                                {enroll}
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            return <div/>
        }
    }
    
    renderPlayerSeasonRoster(team, childId) {
        const {seasonChoice, users, profileData} = this.props;

        const submitClass = 'wrap-content-fill-child btn-submit';
        const greenClass = 'wrap-content-fill-child btn-green';

        const child = users[childId];
        const policyFlag = seasonChoice.policyFlag && seasonChoice.policyFlag[childId];

        let profile = {
            uid: childId,
            parentId: child.parentId,
            playerName: child.username,
            photoUri: child.photoUri,
        };
        const childIcon = child.photoUri ? child.photoUri : userImage;
        const teamIcon = team && team.iconUri ? team.iconUri : defaultImage;

        for (const item of Object.values(profileData)) {
            if (item.uid === childId && item.organizationId === seasonChoice.organizationId) {
                profile = item;
            }
        }

        return (
            <div style={{marginBottom: 10, borderBottom: '1px solid #777'}}>
                {team && (
                    <div className='wrap-content-parent' style={{fontSize: 12, marginBottom: -5}}>
                        <b className='wrap-content-fill-child' style={{textAlign: 'end'}}>
                        {t('team')}:
                        </b>
                        <img className='reg-icon-team' alt="logo" src={teamIcon} />
                        <div className='wrap-content-wrap-child'>
                        {team.title}
                        </div>
                    </div>
                )}
                <div className='wrap-content-parent' style={{fontSize: 14, marginTop: team ? 0 : 10, marginBottom: 10}}>
                    <img className='reg-icon-user' alt="logo" src={childIcon} />
                    <div className='wrap-content-fill-child'>
                    {child.username}
                    </div>
                </div>
                <div className='wrap-content-parent' style={{marginBottom: 5}}>
                    <Button className={profile.id ? greenClass : submitClass} onClick={e=> {
                        this.setState({profileChoice: profile, userChoice: child})
                    }}>
                    {profile.id ? t('view_profile') : t('submit_profile')}
                    </Button>
                    <Button className={policyFlag ? greenClass : submitClass} onClick={e=> {
                        this.setState({userChoice: child, showPolicy: true})
                    }}>
                    {policyFlag ? t('show_policy') : t('ack_policy')}
                    </Button>
                </div>
            </div>
        )
    }
    
    renderRegistration() {
        const { seasonChoice, division } = this.props;

        let icon = division.thumbUri != null ? division.thumbUri : divisionIcon;

        return (
            <Card className="shadow-tile-i" style={{padding: '25px 30px'}}>
                <h3 className=''>{division.title}</h3>
                <h3 style={{fontSize: 15}}>{seasonChoice.title}</h3>
                <p className='' style={{fontSize: 14}}>
                {t('team_reg_text_1')}
                </p>
                <p className='' style={{fontSize: 14}}>
                {t('team_reg_text_2')}
                </p>
                <p className='' style={{fontSize: 14}}>
                {t('team_reg_text_3')}
                </p>
                
                {this.renderCoachRegistration()}
                {this.renderPlayerRegistration()}
                <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                <div style={{fontSize: 14, marginBottom: 15}}>Contact Information:</div>
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={icon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <h3 style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {division.title}
                        </h3>
                    </div>
                </div>
                {division.contactEmail && (
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={emailIcon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <div style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {division.contactEmail}
                        </div>
                    </div>
                </div>
                )}
                {division.contactPhone && (
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={phoneIcon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <div style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {division.contactPhone}
                        </div>
                    </div>
                </div>
                )}
            </Card>
        );
    }

    renderStatistics() {
        const {organization, division, seasons, divisions, users} = this.props;

        let statistics = getPlayerStatisticsList(seasons, division != null ? division.id : null, organization != null ? organization.id : null, null, divisions);
        let topScore = [];
        for (const val of Object.values(statistics)) {
            topScore.push(val);
        }
        topScore.sort((a,b)=> {
            return b.score - a.score
        });
        let topAssist = [];
        for (const val of Object.values(statistics)) {
            topAssist.push(val);
        }
        topAssist.sort((a,b)=> {
            return b.assist - a.assist
        });

        let topScoreOne = topScore.length > 0 ? topScore[0] : {};
        let topAssistOne = topAssist.length > 0 ? topAssist[0] : {};
        
        let items = [];
        
        return (
            <div>
                <div className='shadow-tile-i' style={{marginBottom: 20}}>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12}>
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {t('trophy')}
                            </h3>
                        </Col>
                    </Row>
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
                <TopPlayer
                    users={users}
                    uid={topScoreOne.uid}
                    number={topScoreOne.score}
                    title={t('top_socorer')}
                    subtitle={t('goals')} />
                <TopPlayer
                    users={users}
                    uid={topAssistOne.uid}
                    number={topAssistOne.assist}
                    title={t('top_assist')}
                    subtitle={t('assists')} />
            </div>
        );
    }

    requestTeamChargeRegister(season, team, methodChoice, note, price) {
        const {organization} = this.props;
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!team || !team.id || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        
        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            teamId: team.id,
            seasonId: season.id,
            price: price,
            returnURL: pref + '/championships/' + season.id
        }
        if (note) {
            data.note = note;
        } else {
            data.note = '';
        }
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-createSeasonPaymentIntentTeam', data, async (data) => {
            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

            if (data.success && data.session) {
                stripe.redirectToCheckout({ sessionId: data.session.id });

                if (!season.teamPending) {
                    season.teamPending = {};
                }
                season.teamPending[team.id] = data.pending;
                this.props.onSeasonChoice(season);

                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    requestTeamRegisterWallet(season, team, methodChoice, note, price) {
        const {wallet} = this.props;
        if (!wallet.balance || (parseFloat(wallet.balance) / 100) < price) {
            alert("Wallet balance is $" + wallet.balance + ". Not sufficent.");
            return;
        }
        if (!team || !team.id || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        
        const data = {
            register: {
                teamId: team.id,
                note: note,
                method: 'wallet',
                price: price
            },
            seasonId: season.id,
        }
        data.wallet = wallet;
        invokeHttpsApi('purchase-requestSeasonTeamRegisterWallet', data, (data) => {

            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.teamPending) {
                season.teamPending = {};
            }
            season.teamPending[team.id] = {
                teamId: team.id,
                note: note,
                method: methodChoice,
            }
            this.props.onSeasonChoice(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    requestTeamRegister(season, team, methodChoice, note) {
        if (!team || !team.id || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        
        const data = {
            register: {
                teamId: team.id,
                note: note,
                method: methodChoice,
            },
            seasonId: season.id,
        }
        invokeHttpsApi('clubQuery-requestSeasonTeamRegister', data, (data) => {
            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.teamPending) {
                season.teamPending = {};
            }
            season.teamPending[team.id] = {
                teamId: team.id,
                note: note,
                method: methodChoice,
            }
            this.props.onSeasonChoice(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    requestDirectChargeRegister(season, user, methodChoice, roleChoice, note, price) {
        const {organization} = this.props;
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!user || !user.uid || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        if (!user || !user.uid || !roleChoice) {
            alert(t('choose_method_role'));
            return;
        }

        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            parentId: user.parentId ? user.parentId : user.uid,
            userId: user.uid,
            role: roleChoice,
            seasonId: season.id,
            price: price,
            returnURL: pref + '/championships/' + season.id
        }
        if (note) {
            data.note = note;
        } else {
            data.note = '';
        }
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-createSeasonPaymentIntent', data, async (data) => {
            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

            if (data.success && data.session) {
                stripe.redirectToCheckout({ sessionId: data.session.id });

                if (!season.pending) {
                    season.pending = {};
                }
                season.pending[user.uid] = data.pending;
                this.props.onSeasonChoice(season);

                this.setState({
                    checkoutProgress: true,
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: true});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    requestWalletRegister(season, user, methodChoice, roleChoice, note, price) {
        const {wallet} = this.props;
        if (!wallet.balance || (parseFloat(wallet.balance) / 100) < price) {
            alert("Wallet balance is $" + wallet.balance + ". Not sufficent.");
            return;
        }
        if (!user || !user.uid || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        if (!user || !user.uid || !roleChoice) {
            alert(t('choose_method_role'));
            return;
        }
        
        let userId = user.uid;
        if (user.parentId) {
            userId = user.parentId;
        }
        if (user.owner) {
            userId = user.owner;
        }

        const data = {
            register: {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
                price: price,
            },
            parentId: user.parentId,
            owner: user.owner,
            seasonId: season.id,
            wallet: wallet,
        }
        invokeHttpsApi('purchase-requestSeasonRegisterWallet', data, (data) => {
            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.registers) {
                season.registers = {};
            }
            season.registers[user.uid] = {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
            }
            this.props.onSeasonChoice(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    requestRegister(season, user, methodChoice, roleChoice, note) {
        if (!user || !user.uid || !methodChoice) {
            alert(t('choose_method_prompt'));
            return;
        }
        if (!user || !user.uid || !roleChoice) {
            alert(t('choose_method_role'));
            return;
        }
        let userId = user.uid;
        if (user.parentId) {
            userId = user.parentId;
        }
        if (user.owner) {
            userId = user.owner;
        }
        
        const data = {
            register: {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
            },
            parentId: user.parentId ? user.parentId : user.uid,
            seasonId: season.id,
        }
        invokeHttpsApi('clubQuery-requestSeasonRegister', data, (data) => {
            if (data.success) {
                alert(t('request_success'));
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false});
            }
            if (!season.pending) {
                season.pending = {};
            }
            season.pending[user.uid] = {
                uid: userId,
                userId: user.uid,
                role: roleChoice,
                method: methodChoice,
                note: note,
            }
            this.props.onSeasonChoice(season);
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    onRequest(teamId, label) {
        const {seasonChoice} = this.props;
        
        const data = {
            teamId: teamId,
            label: label,
            season: seasonChoice,
        }
        invokeHttpsApi('clubQuery-requestSeasonClub', data, (data) => {
            if (data.success) {
                alert(t('saved_successfully'))
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('save_failed'));
                this.setState({progress: false});
            }
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderSeasonPolicy(user) {
        const { seasonChoice, division, onChanged } = this.props;
        const { checked, progress } = this.state;

        if (division.policy && user && user.uid) {
            
            let items = [];
            let allCheck = true;
            for (const [key, value] of Object.entries(division.policy)) {
                if (!checked[key]) {
                    checked[key] = {};
                }
                if (!checked[key][user.uid]) {
                    allCheck = false;
                }
                items.push(value);
            }

            return (
                <div>
                    <h2 className="post-detail-title">
                    t('privacy_policy')
                    </h2>
                    <div style={{marginTop: 30}}>
                        {items.map((value, idx)=>(
                            <div key={idx}>
                                <h2 className="post-detail-title">
                                {value.title}
                                </h2>
                                <div className='app'>
                                    {renderHtmlString(value.body ? value.body : "")}
                                </div>
                                <div className='player-form-item'>
                                    <div className='player-form-title'>
                                        {t('ack_agree')}
                                    </div>
                                    <div className='player-form-value form-setting-control'>
                                        <Switch
                                            checked={checked[value.id] && Object.values(checked[value.id]).includes(user.uid)}
                                            onChange={e=>{
                                                const update = e.target.checked;
                                                if (update) {
                                                    checked[value.id][user.uid] = user.uid;
                                                } else {
                                                    delete checked[value.id][user.uid]
                                                }
                                                this.setState({checked: checked});
                                            }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                    <div>
                        {allCheck && (
                            <Button className='btn-submit' onClick={e => {
                                this.setState({progress: true})
                                invokeHttpsApi('userQuery-submitSeasonAgree', {
                                    agree: true,
                                    seasonId: seasonChoice.id,
                                    userId: user.uid,
                                }, (data) => {
                                    // Read result of the Cloud Function.
                                    if (!seasonChoice.policyFlag) {
                                        seasonChoice.policyFlag = {};
                                    }
                                    seasonChoice.policyFlag[user.uid] = true;
                                    this.setState({progress: false})
                                    this.props.onSeasonChoice(seasonChoice);
                                    if (onChanged != null) {
                                        onChanged();
                                    }
                                }, (error) => {
                                    console.log('saveContents error', error);
                                    this.setState({progress: false})
                                });
                            }}>{t('agree')}</Button>
                        )}
                        {seasonChoice.policyFlag && seasonChoice.policyFlag[user.uid] && (
                            <Button className='btn-cancel' onClick={e => {
                                this.setState({progress: true})
                                invokeHttpsApi('userQuery-submitSeasonAgree', {
                                    agree: false,
                                    seasonId: seasonChoice.id,
                                    userId: user.uid,
                                }, (data) => {
                                    // Read result of the Cloud Function.
                                    if (!seasonChoice.policyFlag) {
                                        seasonChoice.policyFlag = {};
                                    }
                                    seasonChoice.policyFlag[user.uid] = false;
                                    this.setState({progress: false})
                                    this.props.onSeasonChoice(seasonChoice);
                                    if (onChanged != null) {
                                        onChanged();
                                    }
                                }, (error) => {
                                    console.log('saveContents error', error);
                                    this.setState({progress: false})
                                });
                            }}>{t('decline')}</Button>
                        )}
                        <Button className='btn-cancel' onClick={e => {
                            this.setState({showPolicy: false});
                        }}>{t('close')}</Button>
                    </div>
                    )}
                </div>
            )
        } else {
            return <div />
        }
    }

    render() {
        const {seasonChoice, organization, division, seasons } = this.props;
        const {checkoutProgress, profileChoice, userChoice, showPolicy} = this.state;
        
        let teamCount = division.teamIds == null ? 0 : Object.values(division.teamIds).length;
        let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
        let playerCount = 0;
        let gameCount = 0;
        let playerIds = [];
        if (seasonChoice && seasonChoice.teamData) {
            for (const teamData of Object.values(seasonChoice.teamData)) {
                if (teamData.roaster) {
                    for (const uid of Object.values(teamData.roaster)) {
                        if (!playerIds.includes(uid)) {
                            playerIds.push(uid);
                        }
                    } 
                }
            }
        }
        playerCount = playerIds.length;
        const seasonsChoice = [];
        for (const season of Object.values(seasons)) {
            if (season.divisionId === division.id) {
                seasonsChoice.push(season)
            }
        }
        seasonsChoice.sort(function(a, b) {
            return b.startTime - a.startTime;
        })

        for (const season of seasonsChoice) {
            if (season.subSeasons != null) {
                for (const subSeason of Object.values(season.subSeasons)) {
                    gameCount += subSeason.games != null ? Object.values(subSeason.games).length : 0;
                }
            }
        }
        const playerLimit = division != null && division.category != null && division.category.playerCount != null ? division.category.playerCount : 10;
        
        if (division != null) {
            return (
                <div className="org-div-wrapper">
                    <Row style={{margin: 0}}>
                        <Col sm={8} xs={12} className="org-div-left">
                            <div style={{position: 'relative'}}>
                                <h4 className='grid-item-title' style={{position: "absolute", backgroundColor: '#19447f', padding: '5px 10px' , margin: '-5px 5px 0px', color: 'white', zIndex: 500}}>
                                {division.title}
                                </h4>
                                <h3 className='grid-item-title' style={{
                                    position: "absolute", 
                                    right: 0, 
                                    color: '#28CF14', 
                                    textTransform: 'uppercase',
                                    backgroundColor: '#D0F5CC', 
                                    padding: '5px 10px' , 
                                    margin: '-5px 5px 0px', 
                                    zIndex: 500}}>
                                {seasonChoice.status != null ? seasonChoice.status : 'Registration'}
                                </h3>
                                <Card className="shadow-tile-i grid-badge" style={{margin: '10px 20px'}}>
                                    <Row style={{color: '#3D5C97', margin: 10}}>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <div className='grid-item-subInfo' style={{ flex: '0 0 auto'}}>
                                                <FormGroup className='wrap-content-fill-child' style={{margin: '5px 10px 5px 0px'}}>
                                                    <Input type="select" name="select" value={seasonChoice.id} id="challengeChoice" onChange={e=>{
                                                            const id = e.target.value;
                                                            const update = seasons[id];
                                                            this.props.onSeasonChoice(update == null ? {} : update);
                                                        }}>
                                                        {seasonsChoice.map((season)=> (
                                                            <option value={season.id}>{season.title}</option>
                                                        )) }
                                                    </Input>
                                                </FormGroup>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={genderIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <div className='grid-item-subInfo'>
                                                {division.gender != null ? division.gender : 'No Gender'} -
                                                {division.category != null ? division.category.title : 'No Cat'} -
                                                {division.ageTitle != null ? division.ageTitle : 'None'} 
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            {seasonChoice.seasonDoc && (
                                            <div className='wrap-content-parent'>
                                                <AttachItemView label="Season Document" attachFile={seasonChoice.seasonDoc} />
                                            </div>
                                            )}
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={deadlineIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <h4 className='grid-item-subInfo' style={{ flex: '0 0 auto', backgroundColor: '#FFD2D2', padding: '5px 10px' , color: '#FF6E6E'}}>
                                                {toDateString(seasonChoice.regDeadline)}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={calendarIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <h4 className='grid-item-subInfo' style={{ flex: '0 0 auto', backgroundColor: '#D3E0F1', padding: '5px 10px' , color: '#19447f'}}>
                                                {toDateString(seasonChoice.startTime)}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={typeIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <div className='grid-item-subInfo' style={{textTransform: 'uppercase'}}>
                                                {seasonChoice.type != null ? seasonChoice.type : 'None'}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div style={{marginTop: 10}}/>
                            <div className='text-center'>
                                <SubInfoView fixed title='Teams' icon={teamIcon} number={teamCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Players' icon={playersIcon} number={playerCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Format' icon={lineupImage} subText={playerLimit.toString() + ' v ' + playerLimit.toString()} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Venues' icon={venueIcon} number={locationCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Games' icon={gameImage} number={gameCount} />                            
                            </div>
                            {this.renderDivision()}
                        </Col>
                        <Col sm={4} xs={12} className="org-div-right">
                            {this.renderRegistration()}
                        </Col>
                    </Row>
                    <Modal isOpen={profileChoice}>
                        <ModalBody style={{marginTop: 30}}>
                            <OrganizationProfileEdit
                                organization={organization}
                                user={userChoice}
                                profileData={profileChoice}
                                onSaveDone={()=>{
                                    this.loadContents();
                                }}
                                onCloseClick={()=>{
                                    this.setState({profileChoice: null});
                                }} />
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={userChoice && userChoice.uid && showPolicy}>
                        <ModalBody style={{marginTop: 30, maxHeight: 500, overflowY: 'scroll'}}>
                            {this.renderSeasonPolicy(userChoice)}
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={checkoutProgress}>
                        <ModalBody>
                            <div style={{width: '100%', height: '80vh'}}>
                                <div className="center text-center">
                                    <h4>Redirecting to checkout screen. Please wait ...</h4>
                                    <Spinner color="primary" />
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = withRouter(connect(mapState, actionCreators)(OrganizationDivisionOverview));
export { connectedNavigation as OrganizationDivisionOverview };