import React, { Component } from "react";
import {
    Spinner
} from 'reactstrap';

export class LoadingView extends Component {
    
    render() {
        return (
        <div style={{width: '100%', height: '80vh'}}>
            <div style={{
                position: 'relative',
                width: 40,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <Spinner color="primary" />
            </div>
        </div>
        );
    }
}

export default LoadingView;