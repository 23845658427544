import React from 'react';
import Papa from "papaparse";
//import ReactTable from "react-table-6";
import {
    FormGroup, Label, Input,
} from 'reactstrap';

export default class CsvViewer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            columns: [],
        };
    }

    renderInput(){
        const { label, onChange } = this.props;


        return (
            <FormGroup>
                <Label for="videoFile">{label != null ? label : 'CSV File'}</Label>
                <Input type="file" name="file" id="videoFile" accept=".csv" onChange={e => {                            
                    if (e.target.files != null && e.target.files.length > 0) {
                        const fileChoice = e.target.files[0];
                        Papa.parse(fileChoice, {
                            header: true,
                            dynamicTyping: true,
                            complete: function(file) {
                                const columns = [];

                                for (const column of file.meta.fields) {
                                    columns.push({Header: column, accessor: column})
                                }
                                if (onChange) {
                                    onChange(file.data, columns)
                                }
                            }
                        });
                    }
                }} />
            </FormGroup>
        );
    }
  
    renderViewer(){
        //const { data, columns } = this.state;

        return (
            <div>
            {/* {data.length && columns.length && (
                <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={10}
                    className="-striped -highlight" />
            )} */}
            </div>
        );
    }
  
    render(){

        return (
            <div>
                {this.renderInput()}
                {this.renderViewer()}
            </div>
        );
    }
}