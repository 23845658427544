import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {
    CardImg, Row, Col, Button, Card, Modal, ModalBody, Spinner
} from 'reactstrap';
import { t } from 'i18next';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import ScheduleHeader from 'components/detail/organization/program/ScheduleHeader';
import ScheduleEdit from 'components/detail/organization/program/ScheduleEdit';
import ScheduleDetail from 'components/detail/organization/program/ScheduleDetail';
import ProgramLocationsView from 'components/detail/organization/program/ProgramLocationsView';
import SubInfoView from 'components/detail/SubInfoView';
import AttachItemView from 'components/detail/AttachItemView';
import OrganizationProfileEdit from 'components/edit/organization/OrganizationProfileEdit';

import { 
    FcTimeline
} from 'react-icons/fc';

import { 
    toDateString,
    MIN_TIME
} from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import emailIcon from 'assets/img/icons/ic_email.png';
import divisionIcon from 'assets/img/logo/live_192.png';
import phoneIcon from 'assets/img/icons/ic_phone.png';
import calendarIcon from 'assets/img/icons/games_icon.png';
import deadlineIcon from 'assets/img/icons/time_icon.png';
import genderIcon from 'assets/img/icons/ic_gender.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';
import { withRouter } from "utils/Routher";
import { PlayerEnrollOverlay } from "components/detail/payment/PlayerEnrollOverlay";

class ProgramLandingOverview extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            checked: {},
            mainTab: 0,
            editOpen: false,
            saveProgress: false,
        }
    }
    
    renderDivision() {
        const {program, locations} = this.props;
        
        return (
            <ProgramLocationsView 
                program={program}
                locations={locations}  />
        );
    }

    renderRegistration() {
        const { program, organization, profileData, users } = this.props;
        const { playerRegister } = this.state;

        let icon = program.iconUri != null ? program.iconUri : divisionIcon;

        return (
            <Card className="shadow-tile-i" style={{padding: '25px 30px'}}>
                <h3 className=''>{program.title}</h3>
                
                <p className='' style={{fontSize: 14}}>
                1- By Registering you will be added as a program member of the organization.
                </p>
                <p className='' style={{fontSize: 14}}>
                2- Once accepted provide roster and game detault lineup under settings tab.
                </p>
                <p className='' style={{fontSize: 14}}>
                3- Every player must submit registration form to be eligible.
                </p>
                
                <div className='wrap-content-parent'>
                    <div className='reg-price-name'>
                    {t('player_fee')}:
                    </div>
                    <h4 className='reg-price-label'>
                    $ {program && program.playerFee ? program.playerFee : 0}
                    </h4>
                </div>
                <div className='wrap-content-parent'>
                    <Button className='wrap-content-fill-child btn-submit' onClick={e=> {
                        this.setState({playerRegister: true});
                    }}>
                    {t('enroll_now')}
                    </Button>
                </div>
                {playerRegister && (
                <PlayerEnrollOverlay
                    organization={organization}
                    program={program}
                    profileData={profileData}
                    users={users}
                    onCloseClick={e => {
                        this.setState({playerRegister: false});
                    }} />
                )}
                <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                <div style={{fontSize: 14, marginBottom: 15}}>Contact Information:</div>
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={icon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <h3 style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {program.title}
                        </h3>
                    </div>
                </div>
                {program.contactEmail && (
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={emailIcon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <div style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {program.contactEmail}
                        </div>
                    </div>
                </div>
                )}
                {program.contactPhone && (
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={phoneIcon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <div style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {program.contactPhone}
                        </div>
                    </div>
                </div>
                )}
            </Card>
        );
    }

    renderSchedules() {
        const {program, schedules, user, users} = this.props;
        const {editSchedule, detailSchedule, scheduleChoice} = this.state;
        
        const result = [];
        let editable = user && (user.uid === program.owner || (program.managers && Object.values(program.managers).includes(user.uid)))
    
        for (const[key, value] of Object.entries(schedules)) {
    
            if (value.programId === program.id) {
                result.push(
                    <ScheduleHeader 
                        key={key}
                        schedule={value}
                        onClickDetail={() => {
                            this.setState({
                                scheduleChoice: value,
                                detailSchedule: true
                            })
                        }}
                        blockEdit={!editable}
                        onClickEdit={() => {
                            this.setState({
                                scheduleChoice: value,
                                editSchedule: true
                            })
                        }} />
                );
            }
        }


        return (
            <div style={{marginTop: 10}}>
                <ItemsNavigation
                    title={t('schedules')}
                    icon={<FcTimeline/>}
                    detailOpen={false}
                    editOpen={editSchedule}
                    totalCount={result.length}
                    detailItem={scheduleChoice}
                    blockEdit={!editable}
                    onClickBack={()=>{
                        this.setState({scheduleChoice: {}, editSchedule: false, detailSchedule: false})
                    }} 
                    onClickAdd={()=>{
                        this.setState({scheduleChoice: {
                            timestamp: new Date().valueOf() + 5 * MIN_TIME,
                            deadline: new Date().valueOf() + 20 * MIN_TIME,
                        }, editSchedule: true, detailSchedule: false})
                    }} />

                {editSchedule && scheduleChoice != null && (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    <ScheduleEdit 
                        uid={user.uid}
                        program={program}
                        schedule={scheduleChoice} 
                        onChanged={(schedules) => {
                            for (const [k,v] of Object.entries(schedules)) {
                                schedules[k] = v;
                                if (scheduleChoice && scheduleChoice.id === k) {
                                    this.setState({scheduleChoice: v});
                                }
                            }
                            this.setState({schedules: schedules});
                        }}
                        onCloseClick={() => {
                            this.setState({scheduleChoice: null, editSchedule: false})
                        }} />
                </div>
                )}
                {detailSchedule && scheduleChoice != null && (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    <ScheduleDetail 
                        uid={user.uid}
                        program={program}
                        schedule={scheduleChoice} 
                        users={users}
                        onCloseClick={() => {
                            this.setState({scheduleChoice: null, detailSchedule: false})
                        }} />
                </div>
                )}
                {!editSchedule && !detailSchedule && (
                <div style={{marginTop: 30}}>
                {result}
                </div>
                )}
            </div>
        );
    }

    renderRegister() {
        const {program} = this.props;
        
        let scheduleCount = program.scheduleIds == null ? 0 : Object.values(program.scheduleIds).length;
        let locationCount = program.locationIds == null ? 0 : Object.values(program.locationIds).length;
        let playerCount = 0;
        
        let playerIds = [];
        if (program && program.register) {
            for (const registerData of Object.values(program.register)) {
                if (!playerIds.includes(registerData.uid)) {
                    playerIds.push(registerData.uid);
                }
            }
        }
        playerCount = playerIds.length;
        
        if (program != null) {
            return (
                <div className="org-div-wrapper">
                    <Row style={{margin: 0}}>
                        <Col sm={8} xs={12} className="org-div-left">
                            <div style={{position: 'relative'}}>
                                <h4 className='grid-item-title' style={{position: "absolute", backgroundColor: '#19447f', padding: '5px 10px' , margin: '-5px 5px 0px', color: 'white', zIndex: 500}}>
                                {program.title}
                                </h4>
                                <h3 className='grid-item-title' style={{
                                    position: "absolute", 
                                    right: 0, 
                                    color: '#28CF14', 
                                    textTransform: 'uppercase',
                                    backgroundColor: '#D0F5CC', 
                                    padding: '5px 10px' , 
                                    margin: '-5px 5px 0px', 
                                    zIndex: 500}}>
                                {program.status != null ? program.status : 'Registration'}
                                </h3>
                                <Card className="shadow-tile-i grid-badge" style={{margin: '10px 20px'}}>
                                    <Row style={{color: '#3D5C97', margin: 10}}>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <div className='grid-item-subInfo' style={{ flex: '0 0 auto'}}>
                                                {program.title}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={genderIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <div className='grid-item-subInfo'>
                                                {program.gender != null ? program.gender : 'No Gender'} -
                                                {program.category != null ? program.category.title : 'No Cat'} -
                                                {program.ageTitle != null ? program.ageTitle : 'None'} 
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            {program.programDoc && (
                                            <div className='wrap-content-parent'>
                                                <AttachItemView label="Program Document" attachFile={program.programDoc} />
                                            </div>
                                            )}
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={deadlineIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <h4 className='grid-item-subInfo' style={{ flex: '0 0 auto', backgroundColor: '#FFD2D2', padding: '5px 10px' , color: '#FF6E6E'}}>
                                                {toDateString(program.regDeadline)}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={calendarIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <h4 className='grid-item-subInfo' style={{ flex: '0 0 auto', backgroundColor: '#D3E0F1', padding: '5px 10px' , color: '#19447f'}}>
                                                {toDateString(program.startTime)}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            {/* <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={typeIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <div className='grid-item-subInfo' style={{textTransform: 'uppercase'}}>
                                                {program.type != null ? program.type : 'None'}
                                                </div>
                                            </div> */}
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div style={{marginTop: 10}}/>
                            <div className='text-center'>
                                <SubInfoView fixed title='Schedules' icon={teamIcon} number={scheduleCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Players' icon={playersIcon} number={playerCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Venues' icon={venueIcon} number={locationCount} />                
                            </div>
                            {this.renderDivision()}
                        </Col>
                        <Col sm={4} xs={12} className="org-div-right">
                            {this.renderRegistration()}
                        </Col>
                    </Row>
                    
                </div>
            );
        } else {
            return <div/>;
        }
    }

    renderContents() {
        const {mainTab} = this.state;

        switch (mainTab) {
            case 0:
                return (
                <div>
                    {this.renderRegister()}
                </div>
                )
            case 1:
                return (
                <div>
                    {this.renderSchedules()}
                </div>
                )
            default:
                return (<div/>)
        }
    }
    
    render() {
        const {organization, program} = this.props;
        const {mainTab, profileChoice, checkoutProgress, userChoice} = this.state;
        
        let challengeImage = program.iconUri != null ? program.iconUri : defaultImage;
        
        if (program != null) {
            return (
                <div className="">
                    <div className='wrap-contents-parent shadow-tile' style={{padding: 0}}>
                        <CardImg
                            className="wrap-content-wrap-child team-thumb"
                            src={challengeImage}/>
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label team-detail-header-title'>
                            {program.title}
                            </h4>
                            <div className='team-detail-header-info'>
                            </div>
                            
                        </div>
                        <div className="wrap-content-wrap-child vertical-center-spacing">
                            
                        </div>
                    </div>
                    <div className="shadow-tile">
                        <Box sx={mainTabStyle}>
                            <Tabs
                                value={mainTab}
                                onChange={(e,val)=> {
                                    this.setState({mainTab: val})
                                }}
                                variant="scrollable"
                                indicatorColor="secondary"
                                textColor="secondary" >

                                <Tab label="Register" />
                                <Tab label={t('schedules')} />
                            </Tabs>
                        </Box>
                    </div>
                    <div >
                    {this.renderContents()}
                    </div>
                    <Modal isOpen={profileChoice}>
                        <ModalBody style={{marginTop: 30}}>
                            <OrganizationProfileEdit
                                organization={organization}
                                user={userChoice}
                                profileData={profileChoice}
                                onSaveDone={()=>{
                                    if (this.props.onChanged) {
                                        this.props.onChanged();
                                    }
                                }}
                                onCloseClick={()=>{
                                    this.setState({profileChoice: null});
                                }} />
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={checkoutProgress}>
                        <ModalBody>
                            <div style={{width: '100%', height: '80vh'}}>
                                <div className="center text-center">
                                    <h4>Redirecting to checkout screen. Please wait ...</h4>
                                    <Spinner color="primary" />
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(ProgramLandingOverview));
export { connectedApp as ProgramLandingOverview };
