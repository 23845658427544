import React, { Component } from "react";
import {
    CardImg, Button, Row, Col, Input, Form, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import TableRowTinay from 'components/detail/header/TableRowTinay';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import defaultImage from 'assets/img/icons/video_default.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import ScheduleHeader from 'components/detail/organization/program/ScheduleHeader';
import ScheduleEdit from 'components/detail/organization/program/ScheduleEdit';
import ScheduleDetail from 'components/detail/organization/program/ScheduleDetail';
import { 
    GrOverview,
} from 'react-icons/gr';
import { 
    RiTeamFill,
} from 'react-icons/ri';
import { 
    MdEmail,
    MdPolicy,
} from 'react-icons/md';
import { 
    FcTimeline
} from 'react-icons/fc';

import { 
    GiMale,
    GiFemale,
    GiSoccerKick,
} from 'react-icons/gi';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import ProgramBadge from 'components/detail/organization/ProgramBadge';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';
import { ProgramPolicy } from "components/detail/organization/program/ProgramPolicy";
import { invokeHttpsApi } from "utils/API";
import HorizontalAvatarList from "components/members/HorizontalAvatarList";
import Switch from '@mui/material/Switch';
import { MIN_TIME } from "utils/Utils";
import CustomRichTextEditor from "components/template/CustomRichTextEditor";
import { STATUS_REGISTER } from "utils/constants";

export class ProgramDetails extends Component {
    constructor(props) {
        super(props);
        const program = {};
        if (props.program != null) {
            for (const [key,val] of Object.entries(props.program)) {
                program[key] = val;
            }
        }
        
        this.state = {
            program: program,
            mainTab: 0,
            configMode: true,
            editSeason: false,
            playersPicked: false,
            coachesPicked: false,
            scheduleToEdit: {},
            email: {},
            windowWidth: window.innerWidth,
        };
        this.handleChange = this.handleChange.bind(this);
        window.addEventListener('resize', this.getDimensions); 
    }

    componentDidUpdate(prevProps) {
        const {program} = this.props;
        if (prevProps.program !== program) {
            const update = {};
            if (program != null) {
                for (const [key,val] of Object.entries(program)) {
                    update[key] = val;
                }
            }
            this.setState({
                program: update,
                subSeasonChoice: {},
                teamChoice: {},
                detailOpen: false,
                editOpen: false,
                mainTab: 0
            });
        } 
    }
    
    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleChange(e, value) {
        this.setState({mainTab: value});
    }

    sendEmails(email, uidPicked) {
        const {program, users} = this.props;

        const usersChoice = {};
        const uidList = {};
        
        for (const uid of uidPicked) {
            if (users[uid]) {
                usersChoice[uid] = users[uid].email;
                uidList[uid] = uid;
            }
        }
        
        if (Object.values(usersChoice).length <= 0) {
            alert("You should Pick at least 1 user")
            return;
        }
        
        if (!email.title || email.title === "") {
            alert("You should input email title")
            return;
        }
        
        if (!email.text || email.text === "") {
            alert("You should input email text")
            return;
        }
        
        const data = {
            organizationId: program.organizationId, 
            uidList: uidList, 
            emailList: usersChoice, 
            title: email.title,
            text: email.text,
            htmlText: email.body,
            replyTo: email.replyTo
        };

        this.setState({progress: true});
        invokeHttpsApi('invite-sendOrganizationEmails', data, ((data)=> {
            if (data && data.success) {
                alert("Your emails sent successfully.")
            }
            this.setState({progress: false})
        }), ((error)=> {
            console.log('loadContents error', error);
            alert("Your emails did not go successfully\n" + error)
            this.setState({progress: false})
        }))
    }

    saveUpdate(program) {
        this.setState({progress: true});
        const {onChanged} = this.props;

        invokeHttpsApi('organizerQuery-submitProgramChange', {program: program}, ((data)=> {
            this.setState({program: program})
            if (onChanged != null) {
                onChanged(program);
            }
            this.setState({progress: false});
        }), ((error)=> {
            console.log('saveContents error', error);
            this.setState({progress: false});
        }))
    }

    renderContents() {
        const {windowWidth, mainTab} = this.state;

        return (
            <div>
                <Box sx={mainTabStyle}>
                    <Tabs
                        value={mainTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant={windowWidth > 460 ? "fullWidth" : "scrollable"}
                        onChange={this.handleChange} >
                        <Tab icon={<GrOverview />} label={t('overview')} />
                        <Tab icon={<RiTeamFill />} label={t('members')} />
                        <Tab icon={<FcTimeline />} label={t('schedules')} />
                        <Tab icon={<MdPolicy />} label="Privacy Policy" />
                        <Tab icon={<MdEmail />} label={t('email')} />
                    </Tabs>
                </Box>
                {this.renderConfigContent()}
            </div>
        );
    }

    renderCompose() {
        const { user, program, users } = this.props;
        const { email, playersPicked, coachesPicked, progress } = this.state;
        
        let usersPicked = [];
        
        if (playersPicked && program.playerIds) {
            for (const uid of Object.values(program.playerIds)) {
                usersPicked.push(uid);
            }
        }
        if (coachesPicked && program.managers) {
            for (const uid of Object.values(program.managers)) {
                usersPicked.push(uid);
            }
        }

        const avatars = [];

        if (usersPicked) {
            for (const uid of usersPicked){
                avatars.push(
                    {avatar: users[uid].photoUri, name: users[uid].username}
                )
            }
        }

    
        if (user == null) {
            return <div/>
        }
        
        return (
            <div>

                <div style={{height: 20}} />

                <ItemsNavigation
                    title="Users To Send"
                    icon={<GiSoccerKick/>}
                    detailOpen={false}
                    editOpen={false}
                    totalCount={avatars.length}
                    detailItem={{}}
                    style={{margin: 0}} />
                    
                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        All Players
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <Switch
                                checked={playersPicked}
                                onChange={e=>{
                                    this.setState({playersPicked: e.target.checked});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        All Coaches
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <Switch
                                checked={coachesPicked}
                                onChange={e=>{
                                    this.setState({coachesPicked: e.target.checked});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('users')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                        {avatars.length > 0 && (
                            <HorizontalAvatarList
                                avatars={avatars}
                                avatarProps={{ size: 50 }}
                                />
                        )}
                        <Button
                            className='btn-submit'
                            onClick={(e)=> {
                                this.setState({usersOpen: true});
                            }}>{t('pick')}</Button>
                        </Col>
                    </Row>
                </Form>
                <div className="shadow-tile-normal" style={{padding: 20, backgroundColor: 'white'}}>
                    <div style={{height: 10}}/>
                    <Input 
                        style={{marginTop: 20}}
                        type="text" 
                        name="search" 
                        id="search" 
                        placeholder='Input Title ...' 
                        onChange={e => {
                            email.title = e.target.value;
                            this.setState({email: email});
                        }} value={email.title} />

                    <div style={{height: 10}}/>
                    <Input 
                        style={{marginTop: 20}}
                        type="text" 
                        name="replyTo" 
                        id="replyTo" 
                        placeholder='Reply to ...' 
                        onChange={e => {
                            email.replyTo = e.target.value;
                            this.setState({email: email});
                        }} value={email.replyTo} />

                    <div style={{height: 10}}/>
                    
                    <CustomRichTextEditor
                        placeholderText='Edit Your Content Here!'
                        value={email.body}
                        onChange={(update, text)=> {
                            email.body = update
                            email.text = text
                            this.setState({email: email})
                        }} />
                    <div style={{height: 15}} />
                    {progress ? (
                    <Spinner />
                    ) : (
                    <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                        this.sendEmails(email, usersPicked);
                    }}>Send Emails</Button>
                    )}
                </div>
            </div>
        );
    }

    renderProgramOverview() {
        const {program, schedules, user, progress} = this.props;

        return (
            <div>
                <ProgramBadge
                    uid={user.uid}
                    user={user}
                    program={program}
                    schedules={schedules}
                    blockEdit={true}
                    showStatus={true}
                    progress={progress}
                    onStatusUpdatePrompt={update=> {
                        this.saveUpdate(update);
                    }} />

                {(!program.status || program.status === STATUS_REGISTER) && (
                    this.renderMembers()
                )}
                {program.status && program.status !== STATUS_REGISTER && (
                    this.renderProgramSchedules()
                )}
            </div>
        );
    }

    renderConfigContent() {
        const {program, user, onChanged} = this.props;
        const {mainTab} = this.state;

        switch (mainTab) {
            case 0:
                return (
                    <div>
                    {this.renderProgramOverview()}
                    </div>
                );
            case 1:
                return (
                    <div>
                    {this.renderMembers()}
                    </div>
                );
            case 2:
                return (
                    <div>
                    {this.renderProgramSchedules()}
                    </div>
                );
            case 3:
                return (
                    <ProgramPolicy 
                        program={program}
                        uid={user.uid}
                        onChanged={(update)=> {
                            if (onChanged != null) {
                                onChanged(update);
                            }
                        }} />
                );
            case 4:
                return (
                    <div>
                    {this.renderCompose()}
                    </div>
                );
            default:
                return (
                    <div />
                );
        }
    }

    renderMembers() {
        const {users} = this.props;
        const {program, searchStr} = this.state;

        const pendingArr = [];

        if (program.pending) {
            for (const [key, item] of Object.entries(program.pending)) {
                if (item.role && item.role !== 'player') {
                    continue;
                }
                const user = users[item.uid];

                pendingArr.push(
                    this.renderPendingItem(key, user, program, item)
                )
                pendingArr.push(
                    <div className='divider'/>
                )
            }
        }
        const playersArr = [];
        const managerArr = [];
        const toAddArr = [];

        if (program.registers) {
            for (const [, item] of Object.entries(program.registers)) {
                const user = users[item.uid];

                const choiceView = (
                    <div>
                        <div className='wrap-content-parent' style={{marginRight: 15}}>
                            <div className='wrap-content-child-wrap' style={{fontSize: 13, width: 180, margin: 'auto 0px'}}>
                                <span>{t('method')}: <b>{item.method}</b></span>
                            </div>
                        </div>
                        <div className='wrap-content-child-wrap' style={{fontSize: 13}}>
                            {t('note')}: {item.note}
                        </div>
                    </div>

                )

                const viewItem = (
                    <TableRowTinay
                        title={user.username}
                        icon={user.photoUri ? user.photoUri : playersIcon}
                        subView={choiceView}/>
                )
                if (item.role == null || item.role === 'player') {
                    playersArr.push(viewItem);
                    playersArr.push(
                        <div className='divider'/>
                    )
                }/* else {
                    managerArr.push(viewItem);
                    managerArr.push(
                        <div className='divider'/>
                    )
                }*/
            }
        }
        let managers = [];
        let toAssign = [];

        for (const user of Object.values(users)) {
            if (program.managers && Object.values(program.managers).includes(user.uid)) {
                managers.push(user);
            } else {
                toAssign.push(user);
            }
        }
        managers.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        toAssign.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        for (const user of Object.values(managers)) {
            managerArr.push(
                <TableRowSmall
                    key={user.uid}
                    title={user.username}
                    icon={user.photoUri ? user.photoUri : playersIcon}
                    detailLabel={"Remove"}
                    onClickDetails={()=> {
                        if (window.confirm('Are you sure you want to remove this coach?')) {
                            delete program[user.uid];
                            this.setState({program: program})
                            this.saveUpdate(program);
                        }
                    }} />
            )
        }
        for (const user of Object.values(toAssign)) {
            if (!searchStr || searchStr === '') {
                continue;
            }
            if (user.username && user.username.toLowerCase().includes(searchStr.toLowerCase())) {
                toAddArr.push(
                    <TableRowSmall
                        key={user.uid}
                        title={user.username}
                        icon={user.photoUri ? user.photoUri : playersIcon}
                        detailLabel={"Assign As a Coach"}
                        editLabel={"Reject"}
                        onClickDetails={()=> {
                            if (window.confirm('Are you sure you want to assign this one as a coach?')) {
                                program.managers[user.uid] = user.uid;
                                this.setState({program: program})
                                this.saveUpdate(program);
                            }
                        }} />
                )
            }
        }

        
        return (
            <Row>
                <Col lg={6} xs={12}>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Pending Requests ({pendingArr.length / 2})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {pendingArr.length > 0 ? pendingArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Players
                            </div>
                        )}
                    </div>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Players To Assign ({playersArr.length / 2})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {playersArr.length > 0 ? playersArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Players
                            </div>
                        )}
                    </div>
                </Col>
                <Col lg={6} xs={12}>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Coach Registered ({managerArr.length / 2})
                            </h3>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {managerArr.length > 0 ? managerArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Coach
                            </div>
                        )}
                    </div>
                    <div className='shadow-tile-table'>
                        <Row style={{margin: 0}}>
                            <h3 className='row-table-title'>
                            Coach To Add ({toAddArr.length / 2})
                            </h3>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                <Input type="text" name="search" id="search" placeholder='Search by User Name ...' onChange={e => {
                                    this.setState({searchStr: e.target.value});
                                }} value={searchStr} />
                            </div>                        
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {toAddArr.length > 0 ? toAddArr : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Users To Add
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        )
    }

    renderPendingItem(key, user, program, item) {
        return (
            <TableRowSmall
                key={key}
                title={user.username}
                icon={user.photoUri ? user.photoUri : playersIcon}
                subView={
                <div style={{fontSize: 13}}>
                    <span>{t('method')}: <b>{item.method}</b> Role: <b>{item.role}</b></span>
                    <div>{t('note')}: {item.note}</div>
                </div>
                }
                detailLabel={"Accept"}
                editLabel={"Reject"}
                onClickDetails={()=> {
                    if (window.confirm('Are you sure you want to accept this request?')) {
                        if (!program.registers) {
                            program.registers = {}
                        }
                        if (item.role === 'player') {
                            program.playerIds[item.uid] = item.uid;
                        }
                        program.registers[key] = item;
                        delete program.pending[key];
                        this.setState({program: program})
                        this.saveUpdate(program);
                    }
                }}
                onClickEdit={()=> {
                    if (window.confirm('Are you sure you want to reject this request?')) {
                        delete program.pending[key];
                        this.setState({program: program})
                        this.saveUpdate(program);
                    }
                }}/>
        )
    }

    renderProgramSchedules() {
        const {program, schedules, user, users} = this.props;
        const {editSchedule, detailSchedule, scheduleChoice} = this.state;
        
        const result = [];
    
        for (const[key, value] of Object.entries(schedules)) {
    
            if (value.programId === program.id) {
                result.push(
                    <ScheduleHeader 
                        key={key}
                        schedule={value}
                        onClickDetail={() => {
                            this.setState({
                                scheduleChoice: value,
                                detailSchedule: true
                            })
                        }}
                        onClickEdit={() => {
                            this.setState({
                                scheduleChoice: value,
                                editSchedule: true
                            })
                        }} />
                );
            }
        }


        return (
            <div style={{marginTop: 10}}>
                <ItemsNavigation
                    title={t('schedules')}
                    icon={<FcTimeline/>}
                    detailOpen={false}
                    editOpen={editSchedule}
                    totalCount={result.length}
                    detailItem={scheduleChoice}
                    onClickBack={()=>{
                        this.setState({scheduleChoice: {}, editSchedule: false, detailSchedule: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({scheduleChoice: {
                            timestamp: new Date().valueOf() + 5 * MIN_TIME,
                            deadline: new Date().valueOf() + 20 * MIN_TIME,
                        }, editSchedule: true});
                    }} />

                {editSchedule && scheduleChoice != null && (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    <ScheduleEdit 
                        uid={user.uid}
                        program={program}
                        schedule={scheduleChoice} 
                        onChanged={(schedules) => {
                            for (const [k,v] of Object.entries(schedules)) {
                                schedules[k] = v;
                                if (scheduleChoice && scheduleChoice.id === k) {
                                    this.setState({scheduleChoice: v});
                                }
                            }
                            this.setState({schedules: schedules});
                        }}
                        onCloseClick={() => {
                            this.setState({scheduleChoice: null, editSchedule: false})
                        }} />
                </div>
                )}
                {detailSchedule && scheduleChoice != null && (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    <ScheduleDetail 
                        uid={user.uid}
                        program={program}
                        schedule={scheduleChoice} 
                        users={users}
                        onCloseClick={() => {
                            this.setState({scheduleChoice: null, detailSchedule: false})
                        }} />
                </div>
                )}
                {!editSchedule && !detailSchedule && (
                <div style={{marginTop: 30}}>
                {result}
                </div>
                )}
            </div>
        );
    }

    render() {
        const {program, onClickEdit} = this.props;
        
        let challengeImage = program.thumbUri != null ? program.thumbUri : defaultImage;
        
        if (program != null) {
            return (
                <div className=''>
                    <div className='division-detail-header'>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child division-detail-header-thumb"
                            src={challengeImage} />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label division-detail-header-title'>
                            {program.title}
                            </h4>
                            <div className='division-detail-header-info'>
                            {t('teams')}: {program.teamIds != null ? Object.values(program.teamIds).length : 0}, 
                            {t('gender')}: {program.gender === "Female" ? <GiFemale/> : <GiMale/>},
                            {t('age')}: {program.ageTitle} ({program.ageFrom} - {program.ageTo})
                            </div>

                        </div>
                        <div className="wrap-content-wrap-child vertical-center-spacing">
                            
                            <Button className="btn-edit" onClick={e => {
                                if (onClickEdit != null) {
                                    onClickEdit();
                                }
                            }}>{t('edit')}</Button>
                        </div>
                    </div>
                    
                    {this.renderContents()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ProgramDetails);