import React, { Component } from "react";

import Avatar from './Avatar';

export class UserBadge extends Component {
    render() {
        const {user, subInfo, customClass} = this.props;
        
        if (user != null) {
            return (
                <div style={{display: 'flex', flexFlow: 'row'}} className={customClass != null ? customClass : ''}>
                    <Avatar src={user.photoUri} 
                        style={{
                            width: 30,
                            height: 30,
                            border: '3px solid #fff',
                        }} 
                        className='wrap-content-wrap-child vertical-center-spacing' />
                    <div className='wrap-content-wrap-child vertical-center-spacing'>
                        <h4 className='label label-default' style={{fontSize: 12, marginBottom: 2}}>
                            {user.username}
                        </h4>
                        {subInfo != null && (
                            <p style={{fontSize: 12, marginBottom: 0}}>{subInfo}</p>
                        )}
                    </div>

                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default UserBadge;