import React, { Component } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {
    Modal, ModalBody, Button
} from 'reactstrap';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import { FcConferenceCall, FcManager } from "react-icons/fc";
import teamIcon from 'assets/img/icons/club_icon_default.png';

import {TeamSeasons} from 'components/landing/team/TeamSeasons';
import ClubBattlesView from 'components/detail/club/ClubBattlesView';
import ClubFieldsView from 'components/detail/club/ClubFieldsView';
import ClubPlansView from 'components/detail/club/ClubPlansView';
import ClubMembersView from 'components/detail/club/ClubMembersView';
import ClubManagersView from 'components/detail/club/ClubManagersView';
import ClubSchedulesView from 'components/detail/club/ClubSchedulesView';
import {ClubMembershipsView} from 'components/membership/ClubMembershipsView';
import { ClubSettingsView } from 'components/detail/club/ClubSettingsView';
import ClubEditView from 'components/edit/club/ClubEditView';
import ClubChatView from 'components/chat/ClubChatView';
import { getOrgId, replaceUrl, getTeamDomain } from 'utils/Utils';
import OrganizationPlayerCard from 'components/detail/organization/landing/OrganizationPlayerCard';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import { TeamEmpty } from 'components/landing/team/TeamEmpty';
import CustomTabView from 'components/template/CustomTabView';

class TeamCoach extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            seasonChoice: {},
            clubs: {},
            users: {},
            invites: {},
            managerInvites: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            mainTab: 0,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            familyOpened: false,
            detailMode: 'list',
            balanceInput: 0,
            balanceType: 'direct'
        }
    }
    
    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        this.setState({
            loading: true,
        })
        invokeHttpsApi('clubQuery-teamLandingCoachQuery', data, (data) => {
            
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};
            const clubSchedules = {};
            const wallets = {};
            const walletPayments = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallets)) {
                    wallets[key] = value;
                }
                for (const [key, value] of Object.entries(data.walletPayments)) {
                    walletPayments[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
                clubSchedules: clubSchedules,
                wallets: wallets,
                walletPayments: walletPayments,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    toggleTeamPremium(update) {
        const {team} = this.state;
        this.setState({toggleProgress: true});
        invokeHttpsApi('membership-toggleTeamPremium', {
            teamId: team.id,
            update: update,
        }, (data) => {
            if (data && data.success && data.team && data.team.id) {
                if (data.team.available && (data.teamCountLimit === -1 || data.teamCountLimit <= data.curPremiumCount)) {
                    alert(t('submited_successfully'));
                } else if (!update) {
                    alert(t('submited_successfully'));
                } else {
                    alert(t('submit_failed'));
                }
                this.setState({team: data.team, toggleProgress: false});
            } else {
                alert(t('submit_failed'));
                this.setState({toggleProgress: false});
            }
        }, (error) => {
            console.log('save error', error);
            alert(t('submit_failed'));
            this.setState({toggleProgress: false});
        });
    }

    renderPlayerCard() {
        const {team, playerChoice, organization, organizations, seasons, divisions, posts, users, teams, cards, locations} = this.state;
        
        return (
            <OrganizationPlayerCard
                team={team}
                organization={organization}
                organizations={organizations}
                divisions={divisions}
                posts={posts}
                users={users}
                teams={teams}
                cards={cards}
                seasons={seasons}
                locations={locations}
                player={playerChoice} 
                onClickClose={() => {
                    this.setState({playerChoice: null})
                }} />
        )
    }

    renderContents() {
        const {user, primarySubscription, onChanged} = this.props;
        const {organizations, seasons, divisions, profileData, teams, cards, locations, playerChoice, seasonChoice, category, invites, managerInvites, team, mainTab, users} = this.state;
        if (!user) {
            return <div/>;
        }
        const seasonId = this.props.router.params ? this.props.router.params.seasonId : null;

        if (!primarySubscription || !team.available) {
            switch (mainTab) {
                case 0:
                    return (
                        <TeamSeasons
                            uid={user ? user.uid : null}
                            user={user}
                            team={team}
                            organizations={organizations}
                            seasons={seasons}
                            seasonChoice={seasonChoice}
                            divisions={divisions}
                            profileData={profileData}
                            teams={teams}
                            users={users}
                            category={category}
                            cards={cards}
                            seasonId={seasonId}
                            locations={locations} />
                    );
                case 1:
                    return playerChoice ? this.renderPlayerCard() : (
                        <ClubMembersView
                            uid={user ? user.uid : null}
                            user={user}
                            invites={invites}
                            club={team}
                            users={users}
                            primarySubscription={primarySubscription}
                            onClickView={(item)=> {
                                this.setState({playerChoice: item});
                            }}
                            onChanged={()=> {
                                this.loadContents();
                            }} />
                    );
                case 2:
                    return (
                        <ClubSchedulesView
                            uid={user ? user.uid : null}
                            user={user}
                            club={team}
                            users={users}
                            onUpdate={() => {
                                this.loadContents();
                            }}
                            primarySubscription={primarySubscription} />
                    );
                case 3:
                    return (
                        <ClubMembershipsView />
                    );
                case 4:
                    return (
                        <ClubManagersView
                            uid={user.uid}
                            user={user}
                            club={team}
                            invites={managerInvites}
                            users={users}
                            primarySubscription={primarySubscription}
                            onChanged={()=> {
                                if (onChanged) {
                                    onChanged();
                                }
                            }} />
                    );
                default:
                    return (
                        <div />
                    );
            }
        } else {
            switch (mainTab) {
                case 0:
                    return (
                        <TeamSeasons
                            uid={user ? user.uid : null}
                            user={user}
                            team={team}
                            organizations={organizations}
                            seasons={seasons}
                            seasonChoice={seasonChoice}
                            divisions={divisions}
                            profileData={profileData}
                            teams={teams}
                            users={users}
                            category={category}
                            cards={cards}
                            seasonId={seasonId}
                            locations={locations} />
                    );
                case 1:
                    return (
                        <ClubBattlesView
                            uid={user.uid}
                            user={user}
                            club={team} />
                    );
                case 2:
                    return (
                        <ClubPlansView
                            uid={user.uid}
                            user={user}
                            club={team} />
                    );
                case 3:
                    return (
                        <ClubFieldsView
                            uid={user.uid}
                            user={user}
                            club={team} />
                    );
                case 4:
                    return playerChoice ? this.renderPlayerCard() : (
                        <ClubMembersView
                            uid={user.uid}
                            user={user}
                            club={team}
                            invites={invites}
                            users={users}
                            primarySubscription={primarySubscription} 
                            onClickView={(item)=> {
                                this.setState({playerChoice: item});
                            }}
                            onChanged={()=> {
                                this.loadContents();
                            }} />
                    );
                case 5:
                    return (
                        <ClubSchedulesView
                            uid={user.uid}
                            user={user}
                            club={team}
                            users={users}
                            primarySubscription={primarySubscription} />
                    );
                case 6:
                    return (
                        <ClubSettingsView
                            uid={user.uid}
                            user={user}
                            club={team}
                            users={users}
                            primarySubscription={primarySubscription} />
                    );
                case 7:
                    return (
                        <ClubMembershipsView />
                    );
                case 8:
                    return (
                        <ClubManagersView
                            uid={user.uid}
                            user={user}
                            club={team}
                            invites={managerInvites}
                            users={users}
                            primarySubscription={primarySubscription}
                            onChanged={()=> {
                                if (onChanged) {
                                    onChanged();
                                }
                            }} />
                    );
                default:
                    return (
                        <div />
                    );
            }
        }
    }

    render() {
        const {user, primarySubscription} = this.props;
        const {team, users, mainTab, loading, toggleProgress, editTeam} = this.state;
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
        
        if (!user || 
            ((!team.managers || !Object.values(team.managers).includes(user.uid)) && 
            team.owner !== user.uid)
        ) {
            return (
                <TeamEmpty team={team} />
            )
        }
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const actionBg = team.landingData && team.landingData.actionBg ? team.landingData.actionBg : null;
        const actionColor = team.landingData && team.landingData.actionColor ? team.landingData.actionColor : null;        
        
        let icon = team.iconUri != null ? team.iconUri : teamIcon;

        return (
            <div className='landing-dashboard'>
                <div className='landing-dashboard-header' style={{marginTop: 20, marginBottom: 20}}>
                    <img alt='team' className='home-header-icon' src={icon} />
                    <div className="wrap-content-fill-child">
                        <h1 className="home-header-title-small">
                            {team.title}
                            <span></span>
                        </h1>
                        <div className='wrap-content-parent'>
                            <h3 className="home-header-sub-title" style={{display: 'inline-block'}}>
                                <FcConferenceCall/> {team.playerIds ? Object.values(team.playerIds).length : 0}
                                <FcManager/> {team.managers ? Object.values(team.managers).length : 0}
                                <Button
                                    className="btn btn-team-main btn-lg page-scroll"
                                    style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                    onClick={e=>{
                                        this.setState({editTeam: true});
                                    }}
                                >{t('edit')}</Button>
                                <Button
                                    className="btn btn-team-main btn-lg page-scroll"
                                    style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                    onClick={e=>{
                                        window.location = "/my_account";
                                    }}
                                >{t('my_page')}</Button>
                                {!toggleProgress && team.owner === user.uid && (
                                <Button
                                    className="btn btn-team-main btn-lg page-scroll"
                                    style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                    onClick={e=>{
                                        if (!primarySubscription) {
                                            alert('club_membership_prompt');
                                        } else if (window.confirm(t('toggle_team_premium_prompt'))) {
                                            const cur = team.available ? true : false;
                                            this.toggleTeamPremium(!cur);
                                        }
                                    }}
                                >{t(team.available ? 'premium' : 'freemium')}</Button>
                                )}
                            </h3>
                        </div>
                    </div>
                </div>
                
                <div className='landing-dashboard-contents'>
                    
                    <CustomTabView
                        value={mainTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        onChange={(e, value)=> {
                            this.setState({mainTab: value})
                            if (value !== 0) {
                                replaceUrl(`${getTeamDomain(team)}/coach`)
                            }
                        }}
                        aria-label="disabled tabs example"
                        items={(!primarySubscription || !team.available) ? (team.owner === user.uid ? [
                            { label: t('seasons') },
                            { label: t('members') },
                            { label: t('schedule') },
                            { label: t('membership') },
                            { label: t('managers') },
                        ] : [
                            { label: t('seasons') },
                            { label: t('members') },
                            { label: t('schedule') },
                            { label: t('membership') },
                        ]) : (team.owner === user.uid ? [
                            { label: t('seasons') },
                            { label: t('competitions') },
                            { label: t('workout') },
                            { label: t('cardio_set') },
                            { label: t('members') },
                            { label: t('schedule') },
                            { label: t('settings') },
                            { label: t('membership') },
                            { label: t('managers') },
                        ] : [
                            { label: t('seasons') },
                            { label: t('competitions') },
                            { label: t('workout') },
                            { label: t('cardio_set') },
                            { label: t('members') },
                            { label: t('schedule') },
                            { label: t('settings') },
                            { label: t('membership') },
                        ])} />
                    <div className='landing-dashboard-contents-wrapper'>
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderContents()}
                        </div>
                    </div>
                </div>
                <Modal isOpen={editTeam}>
                    <ModalBody>
                        <ClubEditView 
                            uid={user.uid}
                            squad={team} 
                            onCloseClick={() => {
                                this.setState({editTeam: false})
                            }}
                            onChanged={(squad)=>{
                                this.setState({team: squad});
                            }} />
                    </ModalBody>
                </Modal>
                <ClubChatView
                    uid={user ? user.uid : null}
                    user={user}
                    users={users}
                    club={team} />
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { primarySubscription } = state.subscriptions;
    const { loggingIn, user, child } = state.authentication;
    return { alert, loggingIn, user, child, primarySubscription };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamCoach));
export { connectedApp as TeamCoach };
