import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    CardImg,
    Row,
    Col,
    Button,
    Spinner,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { dataActions } from 'redux/_actions';
import { toTimeString } from 'utils/Utils';
import teamIcon from 'assets/img/icons/shield_icon.png';
import { invokeHttpsApi } from "utils/API";

class PostCategory extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            progress: false,
            organizations: {},
            seasons: {},
            teams: {},
            opened: {

            },
        }
    }

    componentDidMount() {
        this.loadContents();
    }
    
    loadContents() {
        const {organization} = this.props;
        this.setState({loading: true});
        invokeHttpsApi('community-postCategoryQuery', organization ? {organizationId: organization.id} : {}, (data) => {
            const organizations = {};
            const teams = {};
            const seasons = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
            }

            this.setState({
                organizations: organizations,
                teams: teams,
                seasons: seasons,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    getSeasonGameList(season) {
        let games = [];

        if (season.subSeasons) {
            for (const subSeason of Object.values(season.subSeasons)) {
                if (subSeason.games) {
                    for (const game of Object.values(subSeason.games)) {
                        if (game.homeTeam && game.awayTeam && game.start > 0) {
                            games.push(game);
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=> {
            return a.start - b.start;
        })

        return games;
    }

    renderSeasonGameItem(game) {
        const { onCloseClick } = this.props;
        const { teams } = this.state;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : teamIcon;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : teamIcon;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toTimeString(game.start);
        
        const highlight = false;
        
        return (
            <Row className="post-game-item" onClick={e=> {
                if (onCloseClick) {
                    onCloseClick(null, game, null)
                }
            }}>
                <Col lg={5} xs={4} sm={4} className="text-center col-no-padding">
                    <CardImg
                        className="org-game-team-icon"
                        src={homeThumb} />
                    <div className={highlight ? 'org-game-team-title-highlight' : 'org-game-team-title'}>
                    {homeTeam.title}
                    </div>
                </Col>
                <Col lg={2} xs={4} sm={4} className="text-center game-badge-time col-no-padding" style={{padding: 0}}>
                <div className='game-badge-calendar' style={{margin: '0px auto'}}>
                    <div className='game-badge-day'>
                        {day}
                    </div>
                    <div className='game-badge-month'>
                        {month}
                    </div>
                </div>
                <b style={{color: highlight ? 'white' : 'black'}}>{timeStr}</b>
                {game.homeScore != null && game.awayScore != null && (
                    <p style={{color: highlight ? 'white' : 'black'}}><b>{game.homeScore !== -1 ? game.homeScore : "_"} - {game.awayScore !== -1 ? game.awayScore : "_"}</b></p>
                )}
                </Col>
                <Col lg={5} xs={4} sm={4} className="text-center col-no-padding">
                    <CardImg
                        className="org-game-team-icon"
                        src={awayThumb} />
                    <div className={highlight ? 'org-game-team-title-highlight' : 'org-game-team-title'}>
                    {awayTeam.title}
                    </div>
                </Col>
            </Row>
        );
    }

    render() {
        const {onCloseClick} = this.props;
        const {loading, organizations, teams, seasons, opened} = this.state;

        let orgArr = [];
        for (const item of Object.values(organizations)) {
            orgArr.push(item);
        }
        
        let seasonArr = [];
        for (const item of Object.values(seasons)) {
            seasonArr.push(item);
        }
        
        let teamArr = [];
        for (const item of Object.values(teams)) {
            teamArr.push(item);
        }
        
        return (
            <div className="community-pick-category">
                <h4 className="community-pick-header">
                    {t('pick_post_category')}
                </h4>
                
                {loading ? (
                    <div className="text-center">
                        <div style={{height: 50}} />
                        <Spinner color="primary" />
                        <div style={{height: 50}} />
                    </div>
                ) : (
                <div>
                    <h4 className="community-pick-class" onClick={e=> {
                        if (!opened.organization) {
                            opened.organization = true;
                        } else {
                            opened.organization = false;
                        }
                        this.setState({opened: opened});
                    }}>
                        {t('organizations')}
                    </h4>
                    {opened.organization && (
                    orgArr.map((item, idx)=>(
                        <div key={idx} className="community-pick-subclass" onClick={e=> {
                            if (onCloseClick) {
                                onCloseClick(item)
                            }
                        }}>{item.title}</div>
                    )) )}

                    <div className='community-picker-divider'/>

                    <h4 className="community-pick-class" onClick={e=> {
                        if (!opened.seasons) {
                            opened.seasons = true;
                        } else {
                            opened.seasons = false;
                        }
                        this.setState({opened: opened});
                    }}>
                        {t('seasons')}
                    </h4>
                    {opened.seasons && (
                    seasonArr.map((item, idx)=>(
                        <div key={idx} onClick={e=> {
                            if (!item.opened) {
                                item.opened = true;
                            } else {
                                item.opened = false;
                            }
                            seasons[item.id] = item;
                            this.setState({seasons: seasons});
                        }}>
                            <div className="community-pick-subclass">{item.title} ({t('games')}: {this.getSeasonGameList(item).length})</div>
                            {item.opened && this.getSeasonGameList(item).map((game) => (
                                this.renderSeasonGameItem(game)
                            ))}
                        </div>
                    )) )}
                    
                    <div className='community-picker-divider'/>
                    
                    <h4 className="community-pick-class" onClick={e=> {
                        if (!opened.teams) {
                            opened.teams = true;
                        } else {
                            opened.teams = false;
                        }
                        this.setState({opened: opened});
                    }}>
                        {t('teams')}
                    </h4>
                    {opened.teams && (
                    teamArr.map((item, idx)=>(
                        <div key={idx} className="community-pick-subclass" onClick={e=> {
                            if (onCloseClick) {
                                onCloseClick(null, null, item)
                            }
                        }}>{item.title}</div>
                    )) )}
                </div>
                )}

                <div className="wrap-content-parent">
                    <div className="wrap-content-fill-child" />
                    <Button className="btn-cancel" onClick={e=> {
                        if (onCloseClick) {
                            onCloseClick();
                        }
                    }}>{t('cancel')}</Button>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { loggingIn, user, progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(PostCategory));
export { connectedApp as PostCategory };
