import React, { Component } from 'react';
import { 
    Button,
    Modal, 
    ModalBody,
} from 'reactstrap';
import ClubWorkoutHeader from 'components/detail/club/ClubWorkoutHeader';
import ClubWorkoutDetailsView from 'components/detail/club/ClubWorkoutDetailsView';
import ClubWorkoutEditView from 'components/edit/club/ClubWorkoutEditView';
import { readDb } from 'utils/API';

export class ClubWorkoutsCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            categorys: {},
            clubWorkouts: {},
            clubLessons: {},
            clubWorkoutChoice: {},
            workoutEditOpen: false,
            workoutDetailOpen: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;
        readDb('/clubWorkout', data => {
            var clubWorkouts = {};
            var {clubWorkoutChoice} = this.state;

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (value.owner === user.uid || (value.managers != null && Object.values(value.managers).includes(user.uid))) {
                        clubWorkouts[key] = value;
                    }
                }
            }
            if (clubWorkoutChoice.id != null) {
                clubWorkoutChoice = clubWorkouts[clubWorkoutChoice.id];
            }

            this.setState({
                clubWorkouts: clubWorkouts,
                clubWorkoutChoice: clubWorkoutChoice
            });
        });
        readDb('/clubLesson', data => {
            var clubLessons = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (value.owner === "free" || value.owner === user.uid) {
                        clubLessons[key] = value;
                    }
                }
            }

            this.setState({
                clubLessons: clubLessons,
            });
        });
    }

    renderContents() {
        const { user, clubPlan } = this.props;
        const { clubWorkouts } = this.state;
        const result = [];

        for (const[key, value] of Object.entries(clubWorkouts)) {
    
            if ((clubPlan != null && (clubPlan.workoutIds != null && Object.values(clubPlan.workoutIds).includes(key)))
                ||
                (clubPlan == null && (value.owner === user.uid || (value.sharedIds != null && Object.values(value.sharedIds).includes(user.uid))
                ))
             ) {
                result.push(
                    <ClubWorkoutHeader 
                        key={key}
                        uid={user.uid}
                        clubWorkout={value}
                        onClickDetails={() => {
                            this.setState({
                                clubWorkoutChoice: value,
                                workoutDetailOpen: true
                            })
                        }}
                        onClickEdit={() => {
                            this.setState({
                                clubWorkoutChoice: value,
                                workoutEditOpen: true
                            })
                        }} />
                )
            }
        }

        return result;
    }

    render() {
        const { user, clubPlan } = this.props;
        const {categorys, workoutDetailOpen, workoutEditOpen, clubWorkoutChoice, clubLessons} = this.state;

        return (
            <div>
                <div style={{marginTop: 10}}>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                        Club Workouts
                        </h3>
                        
                        <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            this.setState({clubWorkoutChoice: {}, workoutEditOpen: true});
                        }}>+</Button>
                    </div>
                    {this.renderContents()}
                </div>
                <Modal isOpen={workoutEditOpen}>
                    <ModalBody>
                        <ClubWorkoutEditView 
                            categorys={categorys} 
                            uid={user.uid}
                            clubWorkout={clubWorkoutChoice} 
                            clubLessons={clubLessons}
                            clubPlan={clubPlan}
                            isOpen={workoutEditOpen}
                            onCloseClick={() => {
                                this.setState({workoutEditOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={workoutDetailOpen}>
                    <ModalBody>
                        <ClubWorkoutDetailsView 
                            categorys={categorys} 
                            user={user}
                            clubWorkout={clubWorkoutChoice} 
                            clubLessons={clubLessons}
                            onCloseClick={() => {
                                this.setState({workoutDetailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ClubWorkoutsCollection;