import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import Avatar from 'components/detail/Avatar';
import { TeamSlides } from 'components/landing/team/TeamSlides';
import { TeamGames } from 'components/landing/team/TeamGames';
import { TeamServices } from 'components/landing/team/TeamServices';
import { TeamPosts } from 'components/landing/team/TeamPosts';
import { BlueVideo } from 'components/landing/blue/BlueVideo';
import { TeamCount } from 'components/landing/team/TeamCount';
import JsonData from 'components/landing/data/data.json';
import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import userIcon from 'assets/img/icons/player_photo_default.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';
import { 
    FcConferenceCall,
    FcManager,
} from 'react-icons/fc';
import { getOrgId, getTeamDomain } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';

class TeamHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            mergeUid: 'null',
            landingPageData: {},
            team: {},
            organizations: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            posts: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
        }
    }

    getlandingPageData() {
        this.setState({landingPageData : JsonData})
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        this.getlandingPageData();
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    renderEmptyHeader() {
        const {team} = this.state;
        let icon = team.iconUri != null ? team.iconUri : teamIcon;

        return (
            <div className='landing-dashboard-header' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {team.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        <FcConferenceCall/> {team.playerIds ? Object.values(team.playerIds).length : 0}
                        <FcManager/> {team.managers ? Object.values(team.managers).length : 0}
                        <div style={{display: 'inline-block', width: 20}}></div>
                        
                    </h3>
                </div>
            </div>
        )
    }

    renderPlayerInvite() {
        const {user} = this.props;
        const {team, users} = this.state;
        if (!user) {
            return <div/>;
        }
        if (!this.props.router || !this.props.router.params) {
            return <div/>;
        }
        if (!this.props.router.location.pathname.includes('assignPlayer/') && 
            !this.props.router.location.pathname.includes('assignPlayerEmail/')) {
            return <div/>;
        }
        const email = this.props.router.params.email;
        const userId = this.props.router.params.userId;
        let icon = team.iconUri != null ? team.iconUri : teamIcon;
        const targetUser = users[userId];

        if (user.uid === userId || 
            (email && user.email.toLowerCase() === email.toLowerCase()) || 
            (targetUser && targetUser.parentId === user.uid)) {
            
            return (
                <div className='team-auth'>
    
                    <div className='team-auth-wrapper' style={{textAlign: 'left'}}>
                        <div className="text-center pb-4">
                            <img
                                src={icon}
                                className="rounded"
                                style={{ width: 60, height: 60, cursor: 'pointer' }}
                                alt="logo"
                            />
                            <h3 className='text-center'>{team.title}</h3>
                        </div>
                        <p className='team-auth-msg'>{t('player_merge_msg')}</p>
                        <p className='team-auth-msg'>{t('accpet_invite_prompt')}</p>
                        
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 10}}>
                                <Button className='btn-cancel' onClick={e=> {             
                                    const data = {
                                        teamId: team.id
                                    };
                                    if (targetUser && targetUser.parentId === user.uid) {
                                        data.userId = userId;
                                    }      
                                    invokeHttpsApi('invite-acceptPlayer', data, (data) => {
                                        if (data.success) {
                                            alert('Assigning temparary user became your child');
                                            if (data.seasonId) {
                                                window.location = getTeamDomain(team) + "/account/" + data.seasonId;
                                            } else {
                                                window.location = getTeamDomain(team) + "/account";
                                            }
                                        } else {
                                            alert(t('save_failed'));
                                        }
                                    }, (error) => {
                                        console.log('loadContents error', error);
                                        this.setState({progress: false})
                                    });
                                }}>{t('accept_invite')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <div/>;
    }

    renderCoachInvite() {
        const {user} = this.props;
        const {team} = this.state;
        if (!user) {
            return <div/>;
        }
        if (!this.props.router || !this.props.router.params) {
            return <div/>;
        }
        if (!this.props.router.location.pathname.includes('assignManager/') && 
            !this.props.router.location.pathname.includes('assignManagerEmail/')) {
            return <div/>;
        }
        const email = this.props.router.params.email;
        const userId = this.props.router.params.userId;
        let icon = team.iconUri != null ? team.iconUri : teamIcon;

        if (user && userId && user.uid !== userId) {
            return <div/>;
        } else if (user && email && user.email.toLowerCase() !== email.toLowerCase()) {
            return <div/>;
        } else if (user.uid === userId || (email && user.email.toLowerCase() === email.toLowerCase())) {
            
            return (
                <div className='team-auth'>
    
                    <div className='team-auth-wrapper' style={{textAlign: 'left'}}>
                        <div className="text-center pb-4">
                            <img
                                src={icon}
                                className="rounded"
                                style={{ width: 60, height: 60, cursor: 'pointer' }}
                                alt="logo"
                            />
                            <h3 className='text-center'>{team.title}</h3>
                        </div>
                        <p className='team-auth-msg'>{t('coach_merge_msg')}</p>
                        <p className='team-auth-msg'>{t('accpet_invite_prompt')}</p>
                        
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'>
                            </div>
                            <div className='wrap-content-wrap-child' style={{marginTop: 10}}>
                                <Button className='btn-cancel' onClick={e=> {                            
                                    invokeHttpsApi('invite-acceptTeamManager', {
                                        teamId: team.id
                                    }, (data) => {
                                        if (data.success) {
                                            alert('Assigning temparary user became your child');
                                            window.location = getTeamDomain(team) + "/coach";
                                        } else {
                                            alert(t('save_failed'));
                                        }
                                    }, (error) => {
                                        console.log('loadContents error', error);
                                        alert(t('save_failed') + error);
                                        this.setState({progress: false})
                                    });
                                }}>{t('accept_invite')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <div/>;
    }

    renderInvite() {
        const {user} = this.props;
        const {team, users, mergeUid} = this.state;
        
        const targetId = this.props.router.params.targetId;
        const email = this.props.router.params.email;
        let icon = team.iconUri != null ? team.iconUri : teamIcon;

        const childArr = [];

        for (const item of Object.values(users)) {
            if (item.parentId === user.uid) {
                childArr.push(item);
            }
        }
        childArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        const mergeUser = mergeUid !== "null" ? users[mergeUid] : {};

        if (targetId && users[targetId]) {
            const targetUser = users[targetId];

            if (this.props.router.location.pathname.includes('assignChild/') || 
                (this.props.router.location.pathname.includes('assignEmailChild/') 
                && email && user && user.email.toLowerCase() === email.toLowerCase())) {

                return (
                    <div className='team-auth'>
        
                        <div className='team-auth-wrapper' style={{textAlign: 'left'}}>
                            <div className="text-center pb-4">
                                <img
                                    src={icon}
                                    className="rounded"
                                    style={{ width: 60, height: 60, cursor: 'pointer' }}
                                    alt="logo"
                                />
                                <h3 className='text-center'>{team.title}</h3>
                            </div>
                            <p className='team-auth-msg'>{t('merge_child_profile_msg')}</p>
                            <p className='team-auth-msg'>{t('merge_confirm_prompt')}</p>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-wrap-child team-auth-msg'>
                                {t('user_to_assign_as_child')}: 
                                </div>
                                <Avatar src={targetUser.photoUri} 
                                    style={{
                                        width: 30,
                                        height: 30,
                                        border: '3px solid #fff',
                                    }} 
                                    className='wrap-content-wrap-child vertical-center-spacing' />
                                <b className='wrap-content-fill-child team-auth-msg'>
                                    {targetUser.username}
                                </b>
                            </div>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child'>
                                </div>
                                <div className='wrap-content-wrap-child' style={{marginTop: 10}}>
                                    <Button className='btn-cancel' onClick={e=> {                            
                                        invokeHttpsApi('invite-acceptTeamChildInvite', {
                                            targetId: targetId
                                        }, (data) => {
                                            if (data.success) {
                                                alert('Assigning temparary user became your child');
                                                if (targetUser.owner) {
                                                    const child = {};
                                                    for (const[k,v] of Object.values(targetUser)) {
                                                        child[k] = v;
                                                    }
                                                    delete child.owner;
                                                    delete child.teamId;
                                                    child.parentId = user.uid;
                                                    if (this.props.setChild) {
                                                        this.props.setChild(child);
                                                    }
                                                }
                                                window.location = getTeamDomain(team);
                                            } else {
                                                alert(t('save_failed'));
                                            }
                                        }, (error) => {
                                            console.log('loadContents error', error);
                                            this.setState({progress: false})
                                        });
                                    }}>{t('accept_merge')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                
            } else if (this.props.router.location.pathname.includes('assign/') || 
                (this.props.router.location.pathname.includes('assignEmail/') 
                && email && user && user.email.toLowerCase() === email.toLowerCase())) {
                let userThumb = targetUser.photoUri != null ? targetUser.photoUri : userIcon;
                return (
                    <div className='team-auth'>
        
                        <div className='team-auth-wrapper' style={{textAlign: 'left'}}>
                            <div className="text-center pb-4">
                                <img
                                    src={icon}
                                    className="rounded"
                                    style={{ width: 60, height: 60, cursor: 'pointer' }}
                                    alt="logo"
                                />
                                <h3 className='text-center'>{team.title}</h3>
                            </div>
                            <p className='team-auth-msg'>{t('merge_user_profile_msg')}</p>
                            <p className='team-auth-msg'>{t('merge_confirm_prompt')}</p>
                            
                            <div className="text-center pb-4">
                                <img
                                    src={userThumb}
                                    className="rounded"
                                    style={{ width: 40, height: 40, cursor: 'pointer', marginBottom: 10 }}
                                    alt="logo"
                                />
                                <h3 className='text-center' style={{fontSize: 12, marginBottom: 8}}>{targetUser.username}</h3>
                            </div>
                            <div className='wrap-content-parent'>
                                <Avatar src={mergeUser.photoUri} 
                                    style={{
                                        width: 30,
                                        height: 30,
                                        border: '3px solid #fff',
                                    }}
                                    className='wrap-content-wrap-child vertical-center-spacing' />
                                <select className='form-control' name="text" id="title" onChange={e => {
                                    this.setState({mergeUid: e.target.value});
                                }} value={mergeUid}>
                                    <option value="null">{t('accept_merge_child')}</option>
                                    <option value={user.uid}>{t('accept_merge')} {user.username}</option>
                                    {childArr.map((val,idx)=>(
                                    <option key={idx} value={val.uid}>{t('accept_merge')} {val.username}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='wrap-content-parent' style={{marginTop: 20}}>
                                <div className='wrap-content-fill-child'>
                                </div>
                                <div className='wrap-content-wrap-child'>
                                    <Button className='btn-cancel' onClick={e=> {
                                        if (mergeUid === "null") {
                                            invokeHttpsApi('invite-acceptTeamChildInvite', {
                                                targetId: targetId
                                            }, (data) => {
                                                if (data.success) {
                                                    alert('Temparary user became your child');
                                                    if (targetUser.owner) {
                                                        const child = {};
                                                        for (const[k,v] of Object.values(targetUser)) {
                                                            child[k] = v;
                                                        }
                                                        delete child.owner;
                                                        delete child.teamId;
                                                        child.parentId = user.uid;
                                                        if (this.props.setChild) {
                                                            this.props.setChild(child);
                                                        }
                                                    }
                                                    window.location = getTeamDomain(team);
                                                } else {
                                                    alert(t('save_failed'));
                                                }
                                            }, (error) => {
                                                console.log('loadContents error', error);
                                                this.setState({progress: false})
                                            });
                                        } else {
                                            invokeHttpsApi('invite-acceptTeamPlayerInvite', {
                                                targetId: targetId,
                                                userId: mergeUid,
                                            }, (data) => {
                                                const child = mergeUid !== user.uid ? users[mergeUid] : null;
                                                
                                                if (child && this.props.setChild) {
                                                    this.props.setChild(child);
                                                }
                
                                                if (data.success) {
                                                    alert('Assigning temparary user data to your profile succeded');
                                                    window.location = getTeamDomain(team);
                                                } else {
                                                    alert(t('save_failed'));
                                                }
                                            }, (error) => {
                                                console.log('loadContents error', error);
                                                this.setState({progress: false})
                                            });
                                        }
                                    }}>{t('accept')}</Button>
                                    <Button className='btn-cancel' onClick={e=> {
                                        window.location = getTeamDomain(team);
                                    }}>{t('reject')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return (
            <div/>
        )
    }

    render() {
        const {user} = this.props;
        const {team, users, teams, locations, posts, organizations, seasons, divisions, loading, cards, profileData} = this.state;
        
        
        let icon = team.iconUri != null ? team.iconUri : teamIcon;
        const uid = user ? user.uid : null;
        const mail = user ? user.email : "";
        const userId = this.props.router ? this.props.router.params.userId : null;
        const targetId = this.props.router ? this.props.router.params.targetId : null;
        const email = this.props.router ? this.props.router.params.email : null;

        let message = null;
        if (this.props.router.location.pathname.includes('assignManager/') || this.props.router.location.pathname.includes('assignManagerEmail/')) {

            if (userId && uid !== userId && users[userId]) {
                message = (
                    <div className='text-center'>
                        <p>{t('coach_merge_msg')}</p>
                        <p>{t('change_login_prompt') + users[userId].email}</p>
                    </div>
                )
            } else if (email && email.toLowerCase() !== mail.toLowerCase()) {
                message = (
                    <div className='text-center'>
                        <p>{t('coach_merge_msg')}</p>
                        <p>{t('change_login_prompt') + email}</p>
                    </div>
                )
            }
        } else if (this.props.router.location.pathname.includes('assignPlayer/') || this.props.router.location.pathname.includes('assignPlayerEmail/')) {

            if (userId && uid !== userId && users[userId]) {
                message = (
                    <div className='text-center'>
                        <p>{t('player_merge_msg')}</p>
                        <p>{t('change_login_prompt') + users[userId].email}</p>
                    </div>
                )
            } else if (email && email.toLowerCase() !== mail.toLowerCase()) {
                message = (
                    <div className='text-center'>
                        <p>{t('player_merge_msg')}</p>
                        <p>{t('change_login_prompt') + email}</p>
                    </div>
                )
            }
        } else if (targetId && users[targetId]) {
            let forChild = this.props.router.location.pathname.includes('assignChild/') || this.props.router.location.pathname.includes('assignEmailChild/');

            if (userId && uid !== userId && users[userId]) {
                message = (
                    <div className='text-center'>
                        <p>{t(forChild ? 'merge_child_profile_msg' : 'merge_user_profile_msg')}</p>
                        <p>{t('change_login_prompt') + users[userId].email}</p>
                    </div>
                )
            } else if (email && email.toLowerCase() !== mail.toLowerCase()) {
                message = (
                    <div className='text-center'>
                        <p>{t(forChild ? 'merge_child_profile_msg' : 'merge_user_profile_msg')}</p>
                        <p>{t('change_login_prompt') + email}</p>
                    </div>
                )
            }
        }

        if (loading && !team.id) {
            return (
                <LoadingScreen />
            )
        }
        
        
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        
        if (!team.available) {
            return (
                <div>
                    <div className='landing-dashboard-header' style={{marginTop: 80, marginBottom: 80}}>
                        <img alt='team' className='home-header-icon' src={icon} />
                        <div className="wrap-content-fill-child">
                            <h1 className="home-header-title-small">
                                {team.title}
                                <span></span>
                            </h1>
                            <h3 className="home-header-sub-title">
                                <FcConferenceCall/> {team.playerIds ? Object.values(team.playerIds).length : 0}
                                <FcManager/> {team.managers ? Object.values(team.managers).length : 0}
                                <div style={{display: 'inline-block', width: 20}}></div>
                                
                            </h3>
                        </div>
                    </div>
                    <TeamCount 
                        team={team}
                        profileData={profileData}
                        seasons={seasons} />
                    <TeamGames 
                        team={team}
                        loading={loading}
                        user={user}
                        users={users}
                        organizations={organizations}
                        seasons={seasons}
                        divisions={divisions}
                        teams={teams}
                        posts={posts}
                        cards={cards}
                        locations={locations}
                        onChanged={()=> {
                            this.loadContents();
                        }} />
                    <BlueVideo 
                        loading={loading}
                        history={this.props.router}
                        video={team && team.landingData && team.landingData.video ? team.landingData.video : null} />
                    <TeamPosts 
                        loading={loading}
                        team={team}
                        user={user}
                        users={users}
                        seasons={seasons}
                        divisions={divisions}
                        teams={teams}
                        posts={posts}
                        locations={locations}
                        history={this.props.router}
                        onChanged={()=> {
                            this.loadContents();
                        }} />
                    <div id="footer" style={{backgroundColor: '#3684bb', color: 'white'}}>
                        
                    <div className="container text-center">
                        <p style={{color: '#ddd'}}>
                        &copy; {t('copyright_text')}
                        <a href="/terms-of-services" rel="nofollow" style={{color: 'white'}}>
                            Live! Inc
                        </a>
                        </p>
                    </div>
                    </div>
                    {targetId && users[targetId] && !message && (
                    <div>{this.renderInvite()}</div>
                    )}
                    {this.renderCoachInvite()}
                    {this.renderPlayerInvite()}
                </div>
            )
        }
        return (
            <div>
                <TeamSlides 
                    history={this.props.router}
                    team={team}
                    loading={loading} />
                <TeamCount 
                    team={team}
                    profileData={profileData}
                    seasons={seasons} />
                <TeamGames 
                    team={team}
                    loading={loading}
                    user={user}
                    users={users}
                    organizations={organizations}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    cards={cards}
                    locations={locations}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                <TeamServices 
                    loading={loading}
                    history={this.props.router}
                    team={team} />
                <TeamPosts 
                    loading={loading}
                    team={team}
                    user={user}
                    users={users}
                    seasons={seasons}
                    divisions={divisions}
                    teams={teams}
                    posts={posts}
                    locations={locations}
                    history={this.props.router}
                    onChanged={()=> {
                        this.loadContents();
                    }} />
                <BlueVideo 
                    loading={loading}
                    history={this.props.router}
                    video={team && team.landingData && team.landingData.video ? team.landingData.video : null} />
                <div id="footer" style={{backgroundColor: '#3684bb', color: 'white'}}>
                    
                <div className="container text-center">
                    <p style={{color: '#ddd'}}>
                    &copy; {t('copyright_text')}
                    <a href="/terms-of-services" rel="nofollow" style={{color: 'white'}}>
                        Live! Inc
                    </a>
                    </p>
                </div>
                </div>
                {targetId && users[targetId] && !message && (
                <div>{this.renderInvite()}</div>
                )}
                {this.renderCoachInvite()}
                {this.renderPlayerInvite()}
            </div>
        )
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    return { alert, loggingIn, user };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    setChild: userActions.setChild,
    logout: userActions.logout
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamHome));
export { connectedApp as TeamHome };
