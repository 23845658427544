import React, { Component } from "react";
import {
    //CardImg,
    Progress
} from 'reactstrap';
// import logo200Image from 'assets/img/logo/live_192.png';

export class LoadingScreen extends Component {
    
    render() {
        return (
        <div style={{width: '100vw', height: '100vh', backgroundColor: 'black'}}>
            <div className='text-center' style={{position: 'relative', top: '50vh', left: '30vw', width: '40vw'}}>
                {/* <CardImg 
                    style={{width: 60, height: 60, marginBottom: 20}}
                    src={logo200Image} /> */}
                <Progress animated color="info" value={100} />
            </div>
        </div>
        );
    }
}

export default LoadingScreen;