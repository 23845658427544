import React, { Component } from "react";
import {
    Card, CardBody, Button, Row, Col, CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import UserBadge from 'components/detail/UserBadge';
//import attCanceled from 'assets/img/icons/attendance_canceled.png';
import attGoing from 'assets/img/icons/attendance_going.png';
import attNotGoing from 'assets/img/icons/attendance_not_going.png';

import { toDateTimeString, toTimeString } from 'utils/Utils';

import {
    MdAccessTime,
    MdLocationOn
} from 'react-icons/md';

export class OrganizationScheduleDetailView extends Component {
    renderMembers() {
        const {users, schedule} = this.props;

        let result = [];
        if (schedule.fieldVerificationList != null) {
            for (const [key, value] of Object.entries(schedule.fieldVerificationList)) {
                let member = users[key];
                if (member != null) {
                    result.push(
                        this.renderMember(member, value)
                    )
                }
            }
        }

        return result;
    }

    renderMember(member, availablity) {
        const {users} = this.props;
        var title;
        switch (availablity) {
            case 0:
                title = "Spectator";
                break;
            case 1:
                title = "Member";
                break;
            case 2:
                title = t('staff');
                break;
            case 3:
                title = "Location Staff";
                break;
            default:
                title = "Spectator";
                break;
        }
        var icon = availablity.verified ? attGoing : attNotGoing;
        const checker = users[availablity.fieldStuffId] != null ? users[availablity.fieldStuffId] : {username: 'Unknown'}

        return(
            <Card className="flex-row shadow-tile">
                <Row>
                    <Col sm={8} xs={8}>
                        <UserBadge customClass='wrap-content-wrap-child' user={member}/>
                    </Col>
                    <Col sm={4} xs={4}>
                        <CardImg
                            className="profile-thumb"
                            src={icon}
                            style={{ width: 20, height: 20, marginRight: 10 }}
                            />
                        <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{title}</div>
                        <div style={{fontSize: 10}}>Checked by {checker.username} on {toDateTimeString(availablity.timestamp)}</div>
                    </Col>
                </Row>
            </Card>
        )
    }

    render() {
        const {onCloseClick, locations, schedule} = this.props;
        const location = locations[schedule.location] != null ? locations[schedule.location] : {};
        const icon = location.thumbUri != null ? location.thumbUri : defaultImage;
        
        const date = new Date(schedule.timestamp);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();

        const from = toTimeString(schedule.timestamp);
        const to = toTimeString(schedule.deadline);
        
        if (schedule != null) {
            return (
                <div>
                    <Card className="flex-row shadow-tile">
                        <div style={{
                            width: 90, 
                            height: 90, 
                            marginLeft: 20, 
                            marginTop: 15, 
                            borderRadius: 10,
                            backgroundColor: '#F6F7FB'}}>
                            <div style={{
                                fontSize: 40,
                                fontWeight: 1000,
                                textAlign: 'center',
                                color: '#788492',
                            }}>
                                {day}
                            </div>
                            <div style={{
                                fontSize: 20,
                                fontWeight: 600,
                                textAlign: 'center',
                                color: '#B7BFC8',
                                marginTop: -5
                            }}>
                                {month}
                            </div>
                        </div>
                        <CardBody>
                            <h4 className='label label-default'>
                            {schedule.title}
                            </h4>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            <MdAccessTime/> {from} {" ~ "} {to} <MdLocationOn/> {"Health Room"}
                            </div>
                            <div className="challenge-progress wrap-content-wrap-child">
                                <img alt="icon" src={icon} style={{width: 20, height: 20, marginRight: 10}} />
                                {location.title}
                            </div>
                            <div className='wrap-content-parent' style={{marginTop: 5}}>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child">
                                    <Button className="btn-submit" onClick={e => {
                                        this.setState({detailOpen: true})
                                    }}>Details</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    <div style={{marginTop: 10, marginBottom: 10}}>
                        {this.renderMembers()}
                    </div>
                    <Button className='modal-button' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(OrganizationScheduleDetailView);