import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable, resetServerContext } from "react-beautiful-dnd";
import { 
    Button, 
    Modal, 
    ModalBody,
} from 'reactstrap';
import TutorialHeader from 'components/detail/TutorialHeader';
import TutorialDetails from 'components/detail/TutorialDetails';
import TutorialEdit from 'components/edit/TutorialEdit';
import { getItemStyle, getListStyle } from 'utils/DragableUtils';
import { listenDb, writeDb } from 'utils/API';
import { withRouter } from 'utils/Routher';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    
    for (var i = 0; i < result.length; i ++) {
        result[i].tutorial.index = i;
    }

    return result;
};

class AdminTutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            tutorials: {},
            tutorialsChoice: {},
            tutorialsItems: [],
            tutorialChoice: {},
            editOpen: false,
            detailOpen: false
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.saveIndexChange = this.saveIndexChange.bind(this);
        resetServerContext();
    }

    componentDidMount() {
        const id = this.props.router.params.id;
        this.setState({id: id});
        listenDb('tutorials', data => {
                var tutorials = {};
                var tutorialsChoice = {};
                const { leagueChoice } = this.state;

                if (data != null) {
                    for (const[key, value] of Object.entries(data)) {
                        tutorials[key] = value;
                        if (leagueChoice == null || leagueChoice.id == null || value.leagueId === leagueChoice || 
                            (id != null && id !== '' && value.leagueId === id)) {
                            tutorialsChoice[key] = value;
                        }
                    }
                }

                this.updateTutorialList(tutorialsChoice);
                
                this.setState({
                    tutorials: tutorials,
                    tutorialsChoice: tutorialsChoice,
                });
            });        
    }

    toggle(tab) {
        if (tab !== this.state.activeTab) {
            this.setState({activeTab: tab});
        }
    }

    updateTutorialList(tutorialsChoice) {
        
        var tutorialsNoIndex = [];
        var tutorialsIndex = [];
        var tutorialsArray = [];
        var tutorialsItems = [];

        if (tutorialsChoice != null) {
            for (const [, value] of Object.entries(tutorialsChoice)) {
                if (value.index == null) {
                    tutorialsNoIndex.push(value);
                } else {
                    tutorialsIndex.push(value);
                }
            }

            tutorialsIndex.sort((a, b) => {
                return a.index - b.index;
            });
            
            tutorialsNoIndex = tutorialsNoIndex.sort((a, b) => {
                if (a.title === b.title) 
                    return 0;
    
                return a.title < b.title ? -1 : 1;
            });
            
            for (var i = 0; i < tutorialsIndex.length; i ++) {
                tutorialsArray.push(tutorialsIndex[i]);
            }
            
            for (i = 0; i < tutorialsNoIndex.length; i ++) {
                tutorialsArray.push(tutorialsNoIndex[i]);
            }
            
            for (i = 0; i < tutorialsArray.length; i ++) {
                var item = tutorialsArray[i];
                tutorialsItems.push({id: item.tutorialId, tutorial: item});
            }

        }
                
        this.setState({
            tutorialsItems: tutorialsItems,
        });
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.tutorialsItems,
            result.source.index,
            result.destination.index
        );

        this.setState({
            tutorialsItems: items
        });
    }

    saveIndexChange(e) {
        const {tutorialsItems} = this.state;

        if (tutorialsItems == null || tutorialsItems.length === 0) {
            alert("No Tutorial Pick");
        } else {
            for (var i = 0; i < tutorialsItems.length; i ++) {
                const tutorial = tutorialsItems[i].tutorial;
                if (tutorial != null && tutorial.index != null) {
                    writeDb('/tutorials/' + tutorial.id + '/index', tutorial.index);
                }
            }
            alert("New order of tutorials has been saved successfully.");
        }

    }

    renderTutorials(tutorialsItems) {
        return (
            <div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {tutorialsItems.map((item, index) => (
                                    <Draggable key={item.tutorial.id} draggableId={item.tutorial.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <TutorialHeader 
                                                    tutorial={item.tutorial}
                                                    onClickDetails={() => {
                                                        this.setState({
                                                            tutorialChoice: item.tutorial,
                                                            detailOpen: true
                                                        })
                                                    }}
                                                    onClickEdit={() => {
                                                        this.setState({
                                                            tutorialChoice: item.tutorial,
                                                            editOpen: true
                                                        })
                                                    }} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Button onClick={e=>{
                    this.saveIndexChange(e);
                }}>Save Order</Button>
            </div>
        );
    }

    render() {
        const {tutorialsItems, detailOpen, editOpen, tutorialChoice} = this.state;

        return (
            <div className='wrap-content-parent' style={{height: '100vh'}}>
                <div className='wrap-content-fill-child main-page'>
                    <div className='wrap-content-parent' style={{marginBottom: 20}}>
                        <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                        Tutorials
                        </h3>
                        
                        <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                            this.setState({tutorialChoice: {}, editOpen: true});
                        }}>+</Button>
                    </div>

                    {this.renderTutorials(tutorialsItems)}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <TutorialEdit 
                            tutorial={tutorialChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <TutorialDetails 
                            tutorial={tutorialChoice} 
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


const connected = withRouter(AdminTutorial);
export { connected as AdminTutorial };