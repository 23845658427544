import React, { Component } from "react";
import HorizontalAvatarList from 'components/members/HorizontalAvatarList';
import { TeamsEditView } from 'components/members/TeamsEditView';

import { Card, CardBody, Modal, ModalBody, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import teamImage from 'assets/img/icons/club_icon_default.png';

export class TeamsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clubs: {},
            editOpen: false
        }
    }

    render() {
        const { teamIds, clubs, available, onlyAvaliable, gradeStr, onSaveClick, noTeamsTxt } = this.props;
        const { editOpen } = this.state;
        if (available != null && Object.values(available).length === 0) {
            for (const [key, val] of Object.entries(clubs)) {
                if (val.parentId == null) {
                    available[key] = key;
                }
            }
        }

        const avatars = [];

        if (clubs != null && teamIds != null) {
            for (const [key, ] of Object.entries(teamIds)){
                const item = clubs[key];
                if (item != null) {
                    avatars.push(
                        {avatar: item.iconUri != null ? item.iconUri : teamImage, name: item.title}
                    )
                }
            }
        }

        return (
            <Card>
                <CardBody style={{paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>
                    {avatars.length > 0 ? (
                        <HorizontalAvatarList
                            avatars={avatars}
                            teamMode={true}
                            avatarProps={{ size: 50 }}
                            />
                    ) : (
                        <div>{noTeamsTxt ? noTeamsTxt : t('no_teams_selected')}</div>
                    )}
                    <div style={{height: 10}}/>
                    <Button className='btn-submit' onClick={e=> {this.setState({editOpen: true})}}>{t('edit')}</Button>
                </CardBody>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <TeamsEditView
                            onlyAvaliable={onlyAvaliable}
                            available={available}
                            clubs={clubs}
                            gradeStr={gradeStr}
                            teamIds={teamIds}
                            onSaveClick={(teamIds)=> {
                                if (onSaveClick != null) {
                                    onSaveClick(teamIds);
                                }
                                this.setState({editOpen: false});
                            }}
                            onCloseClick={(e)=> {
                                this.setState({editOpen: false});
                            }} />
                    </ModalBody>
                </Modal>
            </Card>
        )
    }
}

export default withTranslation()(TeamsView)