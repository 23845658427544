import React, { Component } from "react";
import { Button, Spinner } from 'reactstrap';
import { t } from 'i18next';
import { MdCheckCircle } from 'react-icons/md';
import { 
    toDateTimeString 
} from 'utils/Utils';

export class OrganizationMembershipView extends Component {
    renderPrice(price, priceId, onSubscribe, discount, code) {
        return (
            this.props.progress ? (
                <div>
                    <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                        <Spinner color="primary" />
                    </div>
                </div>
            ) : (
                <div className="org-membership-price-layout">
                    
                    <div className="wrap-content-parent">
                        <div className="org-membership-price wrap-content-wrap-child">
                        ${price.unit_amount / 100}
                        </div>
                        {price.recurring && (
                        <div className="wrap-content-fill-child">
                            <div className="org-membership-price-info">per</div>
                            <div className="org-membership-price-info">{price.recurring.interval_count > 1 ? price.recurring.interval_count + " " : ""}{price.recurring.interval}</div>
                        </div>
                        )}
                    </div>
                    {discount != null && code != null && (
                    <div className="wrap-content-parent">
                        <div className="org-membership-price-info wrap-content-wrap-child">
                            Promotion code
                        </div>
                        <div className="org-membership-price wrap-content-wrap-child">
                        {code}
                        </div>
                    </div>
                    )}
                    <Button 
                        className='btn-org-membership' onClick={e=> {
                            if (onSubscribe != null && priceId != null) {
                                onSubscribe(priceId);
                            }
                        }}>Get Plan</Button>
                </div>
            )
        );
    }

    renderPrices() {
        const { price, priceId, prices, onSubscribe, discount, code } = this.props;

        if (price) {
            return (
                <div>
                    {this.renderPrice(price, priceId, onSubscribe, discount, code)}
                </div>
            )
        }
        const priceItems = [];
        const priceValues = [];

        for (const[key,value] of Object.entries(prices)) {
            value.id = key;
            priceValues.push(value)
        }
        priceValues.sort((a, b) => {
            return a.unit_amount - b.unit_amount;
        });

        for (const value of priceValues) {
            priceItems.push(
                this.renderPrice(value, value.id, onSubscribe, discount, code)
            )
        }

        return (
            <div>
                {priceItems}
            </div>
        )
    }

    renderBenefits(Benefits) {
        const texts = Benefits != null ? Benefits.split(';') : [];

        const priceItems = [];

        for (const item of texts) {
            if (item != null && item.length > 0) {
                priceItems.push(
                    <div className='membership-benefit'>
                        <div style={{display: 'inline-block', fontWeight: 1800}}>
                            <span style={{marginRight: 10}}><MdCheckCircle/></span>
                        </div> 
                        {item}
                    </div>
                )
            }
        }

        return (
            <div>
                {priceItems}
            </div>
        )
    }

    renderSubscribedStatus() {
        const { subscribedStatus, onCancelClicked } = this.props;

        const created = toDateTimeString(subscribedStatus.created._seconds * 1000);
        const trialEnd = subscribedStatus.trial_end != null ? toDateTimeString(subscribedStatus.trial_end._seconds * 1000) : "No trial";
        const current_period_start = toDateTimeString(subscribedStatus.current_period_start._seconds * 1000);
        const current_period_end = toDateTimeString(subscribedStatus.current_period_end._seconds * 1000);
        const price = subscribedStatus.items[0].price;

        return (
            <div>
                {this.props.progress ? (
                    <div>
                        <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                            <Spinner color="primary" />
                        </div>
                    </div>
                ) : (
                    <div className="org-membership-price-layout">
                        
                        <div className="wrap-content-parent">
                            <div className="org-membership-price wrap-content-wrap-child">
                            ${price.unit_amount / 100}
                            </div>
                            {price.recurring && (
                            <div className="wrap-content-fill-child">
                                <div className="org-membership-price-info">per</div>
                                <div className="org-membership-price-info">{price.recurring.interval_count > 1 ? price.recurring.interval_count + " " : ""}{price.recurring.interval}</div>
                            </div>
                            )}
                        </div>
                        <Button 
                            className='btn-org-membership' onClick={e=> {
                                if (onCancelClicked != null) {
                                    onCancelClicked();
                                }
                            }}>{t('cancel')}</Button>
                    </div>
                )}
                {subscribedStatus.status === 'trialing' && (
                    <div className='membership-status' >
                        Trial ends on {trialEnd}
                    </div>
                )}
                {subscribedStatus.status === 'active' && (
                    <div className='membership-status' >
                        Subscribed on {created}
                    </div>
                )}
                {subscribedStatus.status === 'active' && (
                    <div className='membership-status' >
                        Current period from {current_period_start} to {current_period_end}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const { title, membership, subscribedStatus } = this.props;
        return (
            <div className='org-membership-tile' style={{height: 'auto'}}>
                <div 
                    className='org-membership-title'>
                    {title ? title : membership.name}
                </div>
                <div className='org-membership-desc'>
                    {membership.description}
                </div>
                {subscribedStatus.stripeLink != null ? this.renderSubscribedStatus() : this.renderPrices()}
                {!this.props.forOverlay && <div className='membership-benefits'>
                    <p>This includes:</p>
                    {this.renderBenefits(membership.metadata.Benefits)}
                </div>}
            </div>
        );
    }
}

export default OrganizationMembershipView;
