import { t } from 'i18next';
import React, { Component } from 'react';
import { MdExpandLess, MdExpandMore, MdLogout } from 'react-icons/md';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/Routher';
import { getRemainingTime, removeFromArr } from 'utils/Utils';
  
class SideMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openedLinks: []
        }
    }

    renderMainMenuItem() {
        const { mainItem } = this.props;
        const path = this.props.router.location.pathname;

        return (
            <div className={`side-menu-item-main ${path === mainItem.link ? "selected" : ""}`} onClick={e=> {
                this.props.router.navigate(mainItem.link);
            }}>
                {mainItem.icon &&
                <div className='side-menu-item-icon'>
                    {mainItem.icon}
                </div>
                }
                <div className='side-menu-item-title'>
                    {mainItem.title}
                </div>
            </div>
        )
    }

    renderMenuItem(item) {
        const { mainItem } = this.props;
        const { openedLinks } = this.state;
        const path = this.props.router.location.pathname;
        const linkTo = mainItem.link + item.link;
        const selected = path.startsWith(linkTo);
        const opened = openedLinks.includes(item.link);

        return (
            <div key={item.link}>
                <div className={`side-menu-item ${selected ? "selected" : ""}`} onClick={e=> {
                    if (item.children) {
                        if (!openedLinks.includes(item.link)) {
                            openedLinks.push(item.link);
                        } else {
                            removeFromArr(openedLinks, item.link)
                        }
                        this.setState({openedLinks: openedLinks});
                    } else {
                        this.props.router.navigate(linkTo);
                    }
                }}>
                    {item.icon &&
                    <div className='side-menu-item-icon'>
                        {item.icon}
                    </div>
                    }
                    <div className='side-menu-item-title'>
                        {item.title}
                    </div>
                    {item.children &&
                    <div className='side-menu-item-icon'>
                        {selected || opened ? <MdExpandMore/> : <MdExpandLess/>}
                    </div>
                    }
                </div>
                {item.children && (selected || opened) && (
                    <div key="children">
                    {item.children.map((child, idx) => (
                        this.renderMenuItemChild(item, child)
                    ))}
                    </div>
                )}
            </div>
        )
    }

    renderLogoutBtn() {

        return (
            <div key="logout">
                <div className={`side-menu-item`} onClick={e=> {
                    this.props.logout();
                    window.location = "/";
                }}>
                    <div className='side-menu-item-icon'>
                        <MdLogout />
                    </div>
                    <div className='side-menu-item-title'>
                        {t('logout')}
                    </div>
                </div>
            </div>
        )
    }

    renderMenuItemChild(item, child) {
        const { mainItem } = this.props;
        const path = this.props.router.location.pathname;
        const linkTo = mainItem.link + item.link + child.link;
        const selected = path.startsWith(linkTo);

        return (
            <div key={child.link}>
                <div className={`side-menu-item-child ${selected ? "selected" : ""}`} onClick={e=> {
                    this.props.router.navigate(linkTo);
                }}>
                    {child.icon &&
                    <div className='side-menu-item-icon'>
                        {child.icon}
                    </div>
                    }
                    <div className='side-menu-item-title'>
                        {child.title}
                    </div>
                </div>
            </div>
        )
    }

    renderMembershipItem() {
        const { mainItem, membershipItem } = this.props;
        const path = this.props.router.location.pathname;
        const linkTo = mainItem.link + membershipItem.link;
        const selected = path.startsWith(linkTo);

        let title = t('no_membership')
        let dateStr = t('no_membership_prompt')
        const membership = membershipItem.curPlan;
        
        if (membership && membershipItem && membershipItem.main.status === 'trialing') {
            title = membership.name;
            dateStr = t('trial_until') + " " + getRemainingTime(membershipItem.main.trial_end.seconds * 1000)
        } else if (membership && membershipItem && membershipItem.main.status === 'active') {
            title = membership.name;
            dateStr = t('renew_within') + " " + getRemainingTime(membershipItem.main.current_period_end.seconds * 1000)
        } else if (membershipItem && membershipItem.free && membershipItem.free.current_period_end > new Date().valueOf()) {
            title = t('free_trial');
            dateStr = t('ends_in') + " " + getRemainingTime(membershipItem.free.current_period_end)
        }

        return (
            <div>
                <div className={`side-menu-membership ${selected ? "selected" : ""}`}>
                    <div className='side-menu-membership-info'>
                        <div className='side-menu-membership-title'>
                            {title}
                        </div>
                        <div className='side-menu-membership-date'>
                            {dateStr}
                        </div>
                    </div>
                    <div className='side-menu-membership-action' onClick={e=> {
                        this.props.router.navigate(linkTo);
                    }}>
                        {t('modify')}
                    </div>
                </div>
            </div>
        )
    }

    renderNormalMenu() {
        const { icon, title, menuItems, membershipItem } = this.props;

        return (
            <div className='side-menu-contents'>
                <div className='side-menu-header' onClick={e=> {this.props.router.navigate("/")}}>
                    {icon && <img className='side-menu-header-icon' src={icon} alt='icon' />}
                    <div className='side-menu-header-title'>
                        {title}
                    </div>
                </div>

                {this.renderMainMenuItem()}
                <div className='side-menu-items'>
                    {menuItems.map((item, idx) => (
                        this.renderMenuItem(item)
                    ))}
                    {this.renderLogoutBtn()}
                </div>
                {membershipItem && (
                    this.renderMembershipItem()
                )}
            </div>
        )
    }

    render() {
        const { isOpened, floating, onClose } = this.props;

        if (floating && isOpened) {
            return (
                <div className='side-menu-floating'>
                    <div className='side-menu-pane'>
                        {this.renderNormalMenu()}
                    </div>
                    <div className='wrap-content-fill-child'  onClick={e => {
                        e.stopPropagation();
                        if (onClose != null) {
                            onClose();
                        }
                    }}/>
                </div>
            );
        }
        if (!floating) {
            return (
                <div className='side-menu-pane'>
                    {this.renderNormalMenu()}
                </div>
            );
        }
        return <div/>;
    }
}

function mapState(state) {
    const { alert } = state;
    const { primarySubscription } = state.subscriptions;
    return { alert, primarySubscription };
}

const actionCreators = {
    logout: userActions.logout,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(SideMenu));
export { connectedApp as SideMenu };
