import React, { Component } from "react";
import { connect } from 'react-redux';
import { FacebookPlayer } from 'components/video/FacebookPlayer';
import GameBadge from 'components/detail/organization/landing/GameBadge';
import GameHighlights from 'components/detail/organization/landing/GameHighlights';
import GameEventEdit from 'components/edit/organization/GameEventEdit';
import UserChoice from 'components/edit/UserChoice';
import Switch from '@mui/material/Switch';

import * as YoutubePlayer from 'react-player/youtube';
import {
    Button, Spinner, Row, Col, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { userActions } from 'redux/_actions';
import { generateId, invokeHttpsApi } from "utils/API";
import { getStorageUrl } from "utils/StorageAPI";
import { ROUND_TYPE_KNOCKOUT, STATUS_FINISHED, STATUS_ONGOING } from "utils/constants";

class GameEdit extends Component {
    constructor(props) {
        super(props);
        this.timer = 0;
        const game = {};
        const division = {};

        if (props.game != null) {
            for (const [key, value] of Object.entries(props.game)) {
                game[key] = value;
            }

        }
        if (props.division) {
            for (const [key, value] of Object.entries(props.division)) {
                division[key] = value;
            }
        }
        if (props.divisions != null && props.divisions[game.divisionId] != null) {
            for (const [key, value] of Object.entries(props.divisions[game.divisionId])) {
                division[key] = value;
            }
        }
        if (game.roundData == null && division.category != null && division.category.roundData != null) {
            game.roundData = {}
            for (const [key, value] of Object.entries(division.category.roundData)) {
                game.roundData[key] = value;
            }
        }
        const season = props.season ? props.season : {};
        const homeData = season.teamData != null && season.teamData[game.homeTeam] ? season.teamData[game.homeTeam] : {};
        const awayData = season.teamData != null && season.teamData[game.awayTeam] ? season.teamData[game.awayTeam] : {};
        const homeGameList = homeData.gameList != null ? homeData.gameList : {};
        const awayGameList = awayData.gameList != null ? awayData.gameList : {};
        const homeBenchList = homeData.benchList != null ? homeData.benchList : {};
        const awayBenchList = awayData.benchList != null ? awayData.benchList : {};

        if (game.gameList == null) {
            game.gameList = {};
        }
        if (game.benchList == null) {
            game.benchList = {};
        }
        if (!game.gameList[game.homeTeam]) {
            game.gameList[game.homeTeam] = homeGameList;
        }
        if (!game.gameList[game.awayTeam]) {
            game.gameList[game.awayTeam] = awayGameList;
        }
        if (!game.benchList[game.homeTeam]) {
            game.benchList[game.homeTeam] = homeBenchList;
        }
        if (!game.benchList[game.awayTeam]) {
            game.benchList[game.awayTeam] = awayBenchList;
        }
        if (game.homeScore == null || game.awayScore == null || game.winner == null) {
            this.updateGameScores(game);
        }
        
        this.state = {
            game: game,
            division: division,
            progress: false,
            roundChoiceId: null,
            newImage: {},
            imageProgress: 0,
            mainTab: 0,
            editOpen: false,
            saveProgress: false,
        }
        this.onChangeEvent = this.onChangeEvent.bind(this);
        this.onDeleteEvent = this.onDeleteEvent.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, game} = this.props;
        
        if ((prevProps.isOpen === false && isOpen === true)) {
            const update = {};
            for (const [key, value] of Object.entries(game)) {
                update[key] = value;
            }
            this.setState({
                game: update,
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }

        if ((prevProps.game !== game)) {
            const update = {};
            for (const [key, value] of Object.entries(game)) {
                update[key] = value;
            }
            this.setState({
                game: update,
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
    }

    updateGameScores(game) {
        if (game.roundData == null) {
            return;
        }
        let rounds = Object.values(game.roundData);
        rounds.sort((a, b)=> {
            return a.number - b.number;
        })
        let homeScore = 0;
        let awayScore = 0;
        if (game.scores != null) {
            for (const val of Object.values(game.scores)) {
                if (val.number <= 0) {
                    val.number = 1;
                }
            }
            for (const val of Object.values(game.scores)) {
                if (val.teamId === game.homeTeam) {
                    homeScore += val.number;
                }
                if (val.teamId === game.awayTeam) {
                    awayScore += val.number;
                }
            }
        }
        game.homeScore = homeScore;
        game.awayScore = awayScore;
        if (homeScore > awayScore) {
            game.winner = game.homeTeam;
        } else if (homeScore < awayScore) {
            game.winner = game.awayTeam;
        } else {
            game.winner = 'draw';
        }
    }

    renderHeader() {
        const { organization, teams, locations } = this.props;
        const { game, } = this.state;

        return (
            <GameBadge
                game={game}
                teams={teams}
                organization={organization}
                wideMode={false}
                locations={locations}
                detailMode={true}/>
        );
    }

    renderResult() {
        const { users, teams } = this.props;
        const { game } = this.state;
        
        if (game != null) {
            return (
                <GameHighlights
                    users={users}
                    teams={teams}
                    game={game}
                    refereeMode={true}
                    onItemClick={(item) => {
                        this.setState({eventChoice: item})
                    }} 
                    onAddClick={(teamId, round) => {
                        if (teamId) {
                            this.setState({eventChoice: {
                                teamId: teamId,
                                roundId: round.id,
                                roundTitle: round.title,
                                type: 'score'
                            }})
                        }
                    }} />
            );
        } else {
            return <div/>;
        }
    }

    generateSheet() {
        const {organization, seasons, season, locations, teams} = this.props;
        const {game, division} = this.state;
        
        this.setState({
            url: null,
            progress: true
        });
        const seasonChoice = season ? season : seasons[game.seasonId];
        invokeHttpsApi('gamePdf-generateSheetHtml', {
            game: game,
            organization: organization,
            division: division,
            season: seasonChoice,
            location: locations[game.locationId],
            homeTeam: teams[game.homeTeam] ? teams[game.homeTeam] : {},
            awayTeam: teams[game.awayTeam] ? teams[game.awayTeam] : {}
        }, (data) => {

            if (data && data.succuess) {
                getStorageUrl('pdf/' + game.id + '-sheet.pdf', (url)=> {
                    this.setState({
                        url: url
                    });
                })
            }
            
            this.setState({
                progress: false
            });
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderSheet() {
        const { organization, user } = this.props;
        const {progress, url} = this.state;

        if (!user || !user.uid !== organization.owner) {
            return <div/>
        }
        return (
            <div style={{marginBottom: 20}}>
                <div className='wrap-content-parent' style={{marginBottom: 5}}>
                    <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                    {t('game_sheet_doc')}
                    </h4>
                    <div className='wrap-content-wrap-child'>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <Button className="btn-edit" onClick={e=> {
                                this.generateSheet();
                            }}>{t('generate')}</Button>
                        )}
                        {url && (
                        <a href={url} target="_blank" rel="noopener noreferrer">Open</a>
                        )}
                    </div>
                </div>
                <div className="divider"/>
            </div>
        )
    }
    
    onChangeEvent(event, round) {
        const { game } = this.state;

        event.roundId = round.id;
        
        switch (event.type) {
            case "event":
                if (event.id == null) {
                    event.id = generateId('/season/' + game.seasonId + '/subSeasons/' + game.subSeasonId + '/games/' + game.id + '/events');
                }
                if (game.events == null) {
                    game.events = {};
                }
                game.events[event.id] = event;
                break;
            case "score":
                if (event.id == null) {
                    event.id = generateId('/season/' + game.seasonId + '/subSeasons/' + game.subSeasonId + '/games/' + game.id + '/scores');
                }
                if (game.scores == null) {
                    game.scores = {};
                }
                game.scores[event.id] = event;
                break;
            case "foul":
                if (event.id == null) {
                    event.id = generateId('/season/' + game.seasonId + '/subSeasons/' + game.subSeasonId + '/games/' + game.id + '/fouls');
                }
                if (game.fouls == null) {
                    game.fouls = {};
                }
                game.fouls[event.id] = event;
                /*if (event.level === 'red') {
                    game.gameList[event.teamId][event.uid] = 'rejected';
                }*/
                break;
            case "substitute":
                if (event.id == null) {
                    event.id = generateId('/season/' + game.seasonId + '/subSeasons/' + game.subSeasonId + '/games/' + game.id + '/substitutes');
                }
                if (game.substitutes == null) {
                    game.substitutes = {};
                }
                game.substitutes[event.id] = event;
                delete game.gameList[event.teamId][event.uid];
                delete game.benchList[event.teamId][event.secUid];
                game.gameList[event.teamId][event.secUid] = event.secUid;
                game.benchList[event.teamId][event.uid] = event.uid;
                break;
            default:
                break;
        }
        this.updateGameScores(game);
        this.setState({game: game, eventChoice: null});
    }

    onDeleteEvent(event, round) {
        const { game } = this.state;

        event.roundId = round.id;
        
        switch (event.type) {
            case "event":
                delete game.events[event.id];
                break;
            case "score":
                delete game.scores[event.id];
                break;
            case "foul":
                delete game.fouls[event.id];
                if (event.level === 'red') {
                    game.gameList[event.teamId][event.uid] = event.uid;
                }
                break;
            case "substitute":
                delete game.substitutes[event.id];
                
                delete game.benchList[event.teamId][event.uid];
                delete game.gameList[event.teamId][event.secUid];
                game.benchList[event.teamId][event.secUid] = event.secUid;
                game.gameList[event.teamId][event.uid] = event.uid;
                break;
            default:
                break;
        }
        this.updateGameScores(game);
        this.setState({game: game, eventChoice: null});
    }

    onSaveClick() {
        const {season, onChanged} = this.props;
        const {game} = this.state;
        
        const data = {
            season: season,
            game: game,
        }
        this.setState({progress: true});
        invokeHttpsApi('clubQuery-saveGameUpdate', data, (data) => {
            if (data.success) {
                alert(t('saved_successfully'))
            } else {
                alert(t('save_failed'));
            }
            if (onChanged) {
                onChanged(game);
            }
            this.setState({progress: false});
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    render() {
        const {organization, season, seasons, division, users, teams, onClickClose} = this.props;
        const {game, eventChoice} = this.state;
        const view = document.getElementById('youtube-view')
        const width = view ? view.clientWidth : -1;        
        const category = division.category ? division.category : {};
        const team = eventChoice && teams[eventChoice.teamId] ? teams[eventChoice.teamId] : {};
        const round = eventChoice && game.roundData && game.roundData[eventChoice.roundId] ? game.roundData[eventChoice.roundId] : {};
        const seasonChoice = season ? season : seasons[game.seasonId];
        const homeTeam = teams[game.homeTeam] != null ? teams[game.homeTeam] : {};
        const awayTeam = teams[game.awayTeam] != null ? teams[game.awayTeam] : {};
        let subSeason = {};
        try {
            subSeason = season.subSeasons[game.subSeasonId];
        } catch (err) {
            console.log(err);
        }
        const homePlayers = {};
        const awayPlayers = {};
        if (game.gameList != null && game.gameList[game.homeTeam] != null) {
            for (const uid of Object.values(game.gameList[game.homeTeam])) {
                if (users[uid] != null) {
                    homePlayers[uid] = users[uid]
                }
            }
        }
        if (game.benchList != null && game.benchList[game.homeTeam] != null) {
            for (const uid of Object.values(game.benchList[game.homeTeam])) {
                if (users[uid] != null) {
                    homePlayers[uid] = users[uid]
                }
            }
        }
        if (game.gameList != null && game.gameList[game.awayTeam] != null) {
            for (const uid of Object.values(game.gameList[game.awayTeam])) {
                if (users[uid] != null) {
                    awayPlayers[uid] = users[uid]
                }
            }
        }
        if (game.benchList != null && game.benchList[game.awayTeam] != null) {
            for (const uid of Object.values(game.benchList[game.awayTeam])) {
                if (users[uid] != null) {
                    awayPlayers[uid] = users[uid]
                }
            }
        }
        
        const homeMom = game.mom != null && game.mom[game.homeTeam] != null ? game.mom[game.homeTeam].uid : null;
        const awayMom = game.mom != null && game.mom[game.awayTeam] != null ? game.mom[game.awayTeam].uid : null;
        

        return (
            <div>
                {this.renderHeader()}
                {game.youtubeId && (
                    <div className="post-media-wrapper" style={{marginLeft: 10}} id='youtube-view'>
                    <div className="post-media-child">
                        <YoutubePlayer 
                            className='post-video-player'
                            width="100%"
                            height="100%"
                            playing 
                            stopOnUnmount 
                            controls 
                            url={'https://www.youtube.com/watch?v=' + game.youtubeId} />
                    </div>
                    </div>
                )}
                {game.facebook && (
                    <div className="post-media-wrapper" style={{marginLeft: 10}} id='youtube-view'>
                    <div className="post-media-child">
                        <FacebookPlayer  
                            className='post-video-player'
                            url={game.facebook}
                            width={width === -1 ? 350 : width - 20}
                            autoplay={true}
                            onPlayerReady={(player)=> {
                                this.setState({player: player})
                            }}
                            startedPlaying={(player)=> {
                                
                            }}
                            finishedPlaying={(player)=> {
                                
                            }}
                            paused={(player)=> {
                                
                            }}
                            error={(player)=> {
                                
                            }} />
                    </div>
                    </div>
                )}
                {this.renderSheet()}
                
                {eventChoice ? (
                    <GameEventEdit
                        event={eventChoice}
                        organization={organization}
                        division={division}
                        game={game}
                        category={category}
                        round={round}
                        team={team}
                        users={users}
                        season={seasonChoice}
                        onChangeEvent={this.onChangeEvent}
                        onDeleteEvent={this.onDeleteEvent}
                        onCloseClick={()=> {
                            this.setState({eventChoice: null});
                        }} />
                ) : (
                    <div>{this.renderResult()}</div>
                )}
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('winner')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="select" name="gender" id="gender" value={game.winner} onChange={e => {
                                game.winner = e.target.value;
                                this.setState({game: game});
                            }}>
                                <option value={null}></option>
                                <option value={game.homeTeam}>{homeTeam.title}</option>
                                <option value={game.awayTeam}>{awayTeam.title}</option>
                                {subSeason.type !== ROUND_TYPE_KNOCKOUT && (
                                <option value='draw'>{t('draw')}</option>
                                )}
                            </Input>
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('mom_label')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input' style={{margin: '5px 0px'}}>
                            <UserChoice
                                title="Home Team MOM"
                                users={homePlayers}
                                smallMode={true}
                                emptyTitle="Pick Home Team MOM"
                                userChoice={homePlayers[homeMom]}
                                onItemChoice={(item) => {
                                    if (game.mom == null) {
                                        game.mom = {};
                                    }
                                    if (game.mom[game.homeTeam] == null) {
                                        game.mom[game.homeTeam] = {teamId: game.homeTeam}
                                    }
                                    game.mom[game.homeTeam].uid = item.uid;
                                    this.setState({game: game});
                                }} />
                        </div>
                        <div className='form-edit-input' style={{margin: '5px 0px'}}>
                            <UserChoice
                                title="Away Team MOM"
                                users={awayPlayers}
                                smallMode={true}
                                emptyTitle="Pick Away Team MOM"
                                userChoice={awayPlayers[awayMom]}
                                onItemChoice={(item) => {
                                    if (game.mom == null) {
                                        game.mom = {};
                                    }
                                    if (game.mom[game.awayTeam] == null) {
                                        game.mom[game.awayTeam] = {teamId: game.awayTeam}
                                    }
                                    game.mom[game.awayTeam].uid = item.uid;
                                    this.setState({game: game});
                                }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('referee_feedback')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="textarea" name="feedback" id="feedback" value={game.feedback} onChange={e => {
                                game.feedback = e.target.value;
                                this.setState({game: game});
                            }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Youtube Id
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="text" name="feedback" id="feedback" value={game.youtubeId} onChange={e => {
                                game.youtubeId = e.target.value;
                                if (!game.youtubeId) {
                                    game.facebook = null;
                                }
                                this.setState({game: game});
                            }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Facebook Url
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                            <Input type="text" name="feedback" id="feedback" value={game.facebook} onChange={e => {
                                game.facebook = e.target.value;
                                if (!game.facebook) {
                                    game.youtubeId = null;
                                }
                                this.setState({game: game});
                            }} />
                        </div>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Game Status
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-wrap-child'>
                                <Switch
                                    checked={game.status === STATUS_FINISHED}
                                    onChange={e=>{
                                        game.status = e.target.checked ? STATUS_FINISHED : STATUS_ONGOING;
                                        this.setState({game: game});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                            <h4 className='wrap-content-fill-child' style={{margin: '10px 5px', width: 'auto'}}>
                            {game.status}
                            </h4>
                        </div>
                    </Col>
                </Row>
                {onClickClose && (
                <div className="text-center">
                    <Button className='btn-edit' onClick={e => {
                        if (this.props.saveGame) {
                            this.props.saveGame(game)
                        } else {
                            this.onSaveClick();
                        }
                    }}>{t('save')}</Button>
                    <Button className="btn-cancel" onClick={(e) => {
                        onClickClose()
                    }}>{t('close')}</Button>
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { primarySubscription, organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, primarySubscription, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(GameEdit));
export { connectedApp as GameEdit };
