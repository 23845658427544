import React, { Component } from "react";
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { timeAgo, renderHtmlString } from 'utils/Utils';

import {
    MdAccessTime,
    MdEdit
} from 'react-icons/md';

import {
    GiHearts,
} from 'react-icons/gi';

import {
    FaComments,
} from 'react-icons/fa';

export class CommentHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {users, user, comment, onClickDetail, onClickEdit } = this.props;
        const creator = users[comment.uid] != null ? users[comment.uid] : {};
        
        const icon = creator.photoUri != null ? creator.photoUri : defaultImage;

        const from = timeAgo(comment.timestamp);
        const uid = user != null ? user.uid : null;
        const likeIds = comment.likeIds != null ? Object.values(comment.likeIds) : [];
        const replies = comment.replies != null ? Object.values(comment.replies) : [];
        
        
        
        if (comment != null) {
            return (
                <div className='comment-item-wrapper'>
                    <img className='comment-item-icon' src={icon} alt='icon' />
                    <div className='comment-item-info'>
                        <div className='post-item-header'>
                            <h4 className='comment-item-username'>
                            {creator.username}
                            </h4>
                            <div className='comment-item-date' style={{marginEnd: 20}}>
                            <MdAccessTime/> {from}
                            </div>
                            {onClickEdit && (
                            <div className='comment-item-action' onClick={e=> {
                                onClickEdit()
                            }}>
                            <MdEdit/> {t('edit')}
                            </div>
                            )}
                        </div>
                        
                        <div onClick={e=> {
                            if (onClickDetail != null) {
                                onClickDetail()
                            }
                        }}>
                            <div className='comment-item-info-html'>
                                {renderHtmlString(comment.body ? comment.body : "")}
                            </div>
                        </div>
                        <div className='post-item-footer'>
                            <div className='comment-item-footer-section'>
                                <span style={likeIds.includes(uid) ? {color: 'red'} : {}}><GiHearts/></span> {likeIds.length}
                            </div>
                            <div className='comment-item-footer-section'>
                                <FaComments/> {replies.length}
                            </div>
                            <div className='comment-item-footer-section'>
                            </div>
                            <div className='comment-item-footer-section'>
                            </div>
                            <div className='comment-item-footer-section'>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(CommentHeader);