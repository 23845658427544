import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, ListGroupItem, Row, Col, CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { MdEdit, MdClose, MdWarning } from 'react-icons/md';
import { 
    RiDeleteBin2Line
} from 'react-icons/ri';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import SubSeasonTeams from 'components/edit/organization/SubSeasonTeams';
import LocationBasedDeploy from 'components/detail/organization/LocationBasedDeploy';
import WeeklyBasedDeploy from 'components/detail/organization/WeeklyBasedDeploy';
import { getWeekStartDay, checkMatches, WEEK_TIME } from 'utils/Utils';
import UserChoice from 'components/edit/UserChoice';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';
import defaultLocationImage from 'assets/img/icons/league_default.png';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";
import { STATUS_FINISHED } from "utils/constants";

export class SubSeasonEdit extends Component {
    constructor(props) {
        super(props);
        const subSeason = {};
        if (props.subSeason != null) {
            for (const [key, value] of Object.entries(props.subSeason)) {
                subSeason[key] = value;
            }
        }

        const games = subSeason.games != null ? Object.values(subSeason.games) : [];
        const shouldOpen = !props.season || !props.season.teamIds || Object.values(props.season.teamIds) === 0;
        
        this.state = {
            subSeason: subSeason,
            progress: false,
            pickTeams: shouldOpen,
            newImage: {},
            deployType: 'weekBased',
            games: games,
            imageProgress: 0,
            locationTimes: {},
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {season, isOpen, subSeason} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            const shouldOpen = !season || !season.teamIds || Object.values(season.teamIds) === 0;
            
            this.setState({
                progress: false,
                pickTeams: shouldOpen,
                newImage: {},
                locationTimes: {},
                imageProgress: 0
            })
        }
        if ((prevProps.subSeason !== subSeason)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(subSeason)) {
                challengeVal[key] = value;
            }
            const games = subSeason.games != null ? Object.values(subSeason.games) : [];
            this.setState({
                subSeason: challengeVal,
                games: games,
            })
        }
    }

    onSaveClick(subSeason) {
        const {season, onSaveDone, onCloseClick} = this.props;
        const {games} = this.state;
        if (subSeason.title == null || subSeason.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (subSeason.id == null || subSeason.id === '') {
                subSeason.id = generateId('/season/' + season.id + '/subSeasons');
            }
            subSeason.owner = season.owner;
            subSeason.organizationId = season.organizationId;
            subSeason.divisionId = season.divisionId;
            subSeason.seasonId = season.id;
            
            subSeason.timestamp = dbTimestamp();

            if (games != null && games.length > 0) {
                subSeason.games = {};
                for (const game of games) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                    subSeason.games[game.id] = game;
                }
            }
            
            const data = {subSeason: subSeason}
            invokeHttpsApi('clubQuery-saveSubSeason', data, (data) => {
                if (onSaveDone != null) {
                    onSaveDone(subSeason);
                }
    
                if (data && data.success) {
                    alert(t('saved_successfully'))
                } else {
                    alert(t('save_failed'));
                    if (onCloseClick != null) {
                        onCloseClick();
                    }
                }
                this.setState({progress: false});
            }, (error) => {
                console.log('save error', error);
                alert(t('save_failed') + '\n' + error);
                this.setState({progress: false});
            });
        }
    }

    renderLeagueGames() {
        const {season, division, locations, organization} = this.props;
        const {subSeason, games, deployType} = this.state;
        if (games != null && games.length > 0) {
            return (
                <div style={{marginTop: 10, marginBottom: 20}} >
                    <h4 className='wrap-content-fill-child' style={{marginTop: 10, marginBottom: 10}}>
                    {t('location_games_deployment')} : {Object.values(locations).length}, Games: {games.length}
                    </h4>
                    <div className='divider' />
                    {this.renderLeagueGameItems()}
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                    <Button className='btn-cancel' onClick={ e => {
                        this.setState({games: []});
                    }}>{t('clear_redeploy')}</Button>
                </div>
            );
        } else {
            if (deployType === 'weekBased') {
                return (
                    <WeeklyBasedDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        division={division}
                        locations={locations}
                        onCompleteDeploy={(games, doubleAllow, matchCount)=>{
                            subSeason.doubleAllow = doubleAllow
                            subSeason.matchCount = matchCount
                            this.setState({subSeason: subSeason, games: games});
                        }} />
                );
            } else {
                return (
                    <LocationBasedDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        division={division}
                        locations={locations}
                        onCompleteDeploy={(games)=>{
                            this.setState({games: games});
                        }} />
                );
            }
        }
    }

    getTeamIcon(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].iconUri != null) {
            return teams[teamId].iconUri;
        }
        let teamData = organization.teamData && organization.teamData[teamId] ? organization.teamData[teamId] : {};
        if (teamData.thumbUri != null) {
            return teamData.thumbUri;
        }

        return defaultTeamImage;
    }

    getTeamTitle(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].title != null) {
            return teams[teamId].title;
        }
        let teamData = organization.teamData && organization.teamData[teamId] ? organization.teamData[teamId] : {};
        if (teamData.nickname != null) {
            return teamData.nickname;
        }

        return 'Unknown';
    }

    getLocation(locationId, locations) {
        const result = {title: 'Unknown', thumbUri: defaultLocationImage};
        if (locations[locationId] != null && locations[locationId].title != null) {
            result.title = locations[locationId].title;
        }
        if (locations[locationId] != null && locations[locationId].thumbUri != null) {
            result.thumbUri = locations[locationId].thumbUri;
        }

        return result;
    }

    renderLeagueGameItems() {
        const {locations, teams, organization, division, season, users} = this.props;
        const {subSeason, games} = this.state;

        const referees = {};
        for (const [key, value] of Object.entries(users)) {
            if (organization.refereeIds != null && Object.values(organization.refereeIds).includes(key)) {
                referees[key] = value;
            }
        }
        let startWeek = getWeekStartDay(season.startTime);

        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            {games.map((value, idx)=>(
                <ListGroupItem key={idx}>
                    <Row>
                        <Col lg={1} sm={12} xs={2} className="text-center">
                            <div className='game-badge-calendar'>
                                <div className='game-badge-weekday'>
                                    {new Date(value.start).toLocaleString('default', { weekday: 'short' })}
                                </div>
                                <div className='game-badge-week'>
                                    Wk. {parseInt((value.start - startWeek) / WEEK_TIME) + 1}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(value.homeTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={value.homeTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (value.status != null && value.status === STATUS_FINISHED) {
                                        alert(t('game_change_not_allowed'))
                                        return;
                                    }
                                    value.homeTeam = e.target.value;
                                    checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                    this.setState({games: games});
                                }}>
                                    {Object.values(division.teamIds).map((teamId,i)=>(
                                        <option value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}>{t('start')} </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        onChange={(val) => {
                                            if (value.status != null && value.status === STATUS_FINISHED) {
                                                alert(t('game_change_not_allowed'))
                                                return;
                                            }
                                            value.start = val.valueOf();
                                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                            this.setState({games: games});
                                        }}
                                        value={new Date(value.start)} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(value.awayTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={value.awayTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (value.status != null && value.status === STATUS_FINISHED) {
                                        alert(t('game_change_not_allowed'))
                                        return;
                                    }
                                    value.awayTeam = e.target.value;
                                    checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                    this.setState({games: games});
                                }}>
                                    {Object.values(division.teamIds).map((teamId,i)=>(
                                        <option value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}>{t('end')} </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        onChange={(val) => {
                                            if (value.status != null && value.status === STATUS_FINISHED) {
                                                alert(t('game_change_not_allowed'))
                                                return;
                                            }
                                            value.end = val.valueOf();
                                            checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                            this.setState({games: games});
                                        }}
                                        value={new Date(value.end)} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getLocation(value.locationId, locations).thumbUri}
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}/>
                                <Input type="select" name="select" id="homeChoice" value={value.locationId} className='wrap-content-fill-child' onChange={e=> {
                                    if (value.status != null && value.status === STATUS_FINISHED) {
                                        alert(t('game_change_not_allowed'))
                                        return;
                                    }
                                    value.locationId = e.target.value;
                                    checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                    this.setState({games: games});
                                }}>
                                    <option value={null}></option>
                                    {Object.values(division.locationIds != null ? division.locationIds : {}).map((locationId,i)=>(
                                        <option value={locationId}>{this.getLocation(locationId, locations).title}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <UserChoice
                                    title={t('referee_choice')}
                                    users={referees}
                                    smallMode={true}
                                    emptyTitle={t('pick_referee')}
                                    userChoice={referees[value.refereeId]}
                                    onItemChoice={(item) => {
                                        if (value.status != null && value.status === STATUS_FINISHED) {
                                            alert(t('game_change_not_allowed'))
                                            return;
                                        }
                                        value.refereeId = item.uid;
                                        checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                        this.setState({games: games});
                                    }} />
                            </div>
                        </Col>
                        <Col lg={1} sm={6} xs={12}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (value.status != null && value.status === STATUS_FINISHED) {
                                            alert(t('game_change_not_allowed'))
                                            return;
                                        }
                                        games.splice(idx, 1);
                                        checkMatches(games, subSeason.doubleAllow, subSeason.matchCount);
                                        this.setState({games: games});
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {value.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                    {value.refereeOverlap && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('referee_overlap')}
                        </div>
                    )}
                    {!value.valid && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {subSeason.matchCount != null && subSeason.matchCount !== -1 ? t('home_invalid_count') : t('home_away_duplicated')}
                        </div>
                    )}
                    {subSeason.matchCount != null && subSeason.matchCount !== -1 && value.awayValid === false && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('away_invalid_count')}
                        </div>
                    )}
                </ListGroupItem>
            ))}
        </div>
        )
    }

    renderTeams() {
        const {teams} = this.props;
        const {subSeason, pickTeams} = this.state;

        const teamCount = subSeason.teamIds != null ? Object.values(subSeason.teamIds).length : 0;
        const totalCount = teams != null ? Object.values(teams).length : 0;
        const startCount = subSeason.startTeams != null ? subSeason.startTeams : 0;

        if (pickTeams) {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 30}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {t('teams')}: {teamCount}/{startCount} Picked, {totalCount} Total
                        </h4>
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                this.setState({pickTeams: false})
                            }}><MdClose/></Button>
                        </div>
                    </div>
                    <div className='divider' />
                    <SubSeasonTeams
                        teams={teams}
                        subSeason={subSeason}
                        onAdded={(teamId)=> {
                            if (subSeason.teamIds == null) {
                                subSeason.teamIds = {};
                            }
                            subSeason.teamIds[teamId] = teamId;
                            this.setState({subSeason: subSeason});
                        }}
                        onRemoved={(teamId)=> {
                            if (subSeason.teamIds == null) {
                                subSeason.teamIds = {};
                            }
                            delete subSeason.teamIds[teamId]
                            this.setState({subSeason: subSeason});
                        }} />
                    <div className='divider' style={{marginBottom: 20}} />
                </div>
            );
        } else {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 5}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {t('teams')}: {teamCount}/{startCount} Picked, {totalCount} Total
                        </h4>
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                this.setState({pickTeams: true})
                            }}><MdEdit/></Button>
                        </div>
                    </div>
                    <div className='divider' style={{marginBottom: 20}} />
                </div>
            )
        }
    }

    render() {
        const {onCloseClick, createMode} = this.props;
        const {subSeason, progress, deployType} = this.state;
        
        if (subSeason != null) {
            return (
                <Form style={createMode ? {width: '100%'} : {}}>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            subSeason.title = e.target.value;
                            this.setState({subSeason: subSeason});
                        }} value={subSeason.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="duration_limit">{t('winner_team_count')}</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            subSeason.winnerCount = parseInt(e.target.value);
                            this.setState({subSeason: subSeason});
                        }} value={subSeason.winnerCount} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="gender">{t('deploy_type')}</Label>
                        <Input type="select" name="gender" id="gender" value={deployType} onChange={e => {
                            this.setState({deployType: e.target.value});
                        }}>
                            <option value="weekBased">{t('weekly_based')}</option>
                            <option value="locationBased">{t('location_based')}</option>
                        </Input>
                    </FormGroup>
                    {this.renderTeams()}
                    {this.renderLeagueGames()}
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(subSeason);
                                }}>{t('create')}</Button>
                            </div>
                        ) : (
                            <div>
                            <Button className='btn-edit' onClick={e => {
                                this.onSaveClick(subSeason);
                            }}>{t('save')}</Button>
                            <Button className='btn-edit' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                            </div>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SubSeasonEdit);