import React, { Component } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
  
class CustomTimePicker extends Component {

    render() {
        const { format, label, value, onChange } = this.props;

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                    <TimePicker
                        margin="normal"
                        format={format}
                        label={label}
                        value={dayjs(value)}
                        onChange={update => {
                            onChange(update.toDate())
                        }}/>
                </DemoContainer>
            </LocalizationProvider>
        );
    }
}

export default CustomTimePicker;
