import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {
    Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import PostHeader from 'components/detail/community/PostHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import {PostEdit} from 'components/edit/community/PostEdit';
import { PiSoccerBallFill } from "react-icons/pi";
import { LiaChartBarSolid } from "react-icons/lia";
import { TfiCup } from "react-icons/tfi";
import { FaStar } from "react-icons/fa6";
import { PiCardsBold } from "react-icons/pi";
import { GiWhistle } from "react-icons/gi";

import { 
    GrArticle,
    GrTrophy
} from 'react-icons/gr';

//import defaultImage from 'assets/img/icons/club_icon_default.png';
import CustomTabView from "components/template/CustomTabView";
import { getOrganizationTeamStatistics, sortByNumber } from "utils/Utils";
import { SambaOrgTeamSquad } from "./SambaOrgTeamSquad";
import SambaGameBadge from "./SambaGameBadge";
import { SambaOrgTeamStatistics } from "./SambaOrgTeamStatistics";
import { SambaOrgTeamGames } from "./SambaOrgTeamGames";

class SambaOrgTeamDetails extends Component {
    constructor(props) {
        super(props);
        let seasonChoice = {};
        if (props.seasons != null) {
            for (const val of Object.values(props.seasons)) {
                if (val.teamChoiceId === props.team.id) {
                    seasonChoice = val;
                    if (val.status !== 'Finished') {
                        break;
                    }
                }
            }
        }
        this.state = {
            seasonChoice: seasonChoice,
            mainTab: 0,
            editOpen: false,
            saveProgress: false,
        }
    }
    
    renderNewsFeed() {
        const {newsFeed} = this.props;

        let items = [];
        let count = newsFeed != null ? Object.values(newsFeed).length : 0;
        
        return (
            <div>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12}>
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {t('news_feed')} {count}
                            </h3>
                        </Col>
                    </Row>
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderStandings() {
        const {organization, team, divisions, seasons, users, teams, posts, locations, cards} = this.props;

        return (
            <SambaOrgTeamGames
                organization={organization}
                divisions={divisions}
                seasons={seasons}
                team={team}
                teams={teams}
                users={users}
                posts={posts}
                cards={cards}
                locations={locations} />
        );
    }

    renderSquad() {
        const {organization, team, divisions, seasons, users, teams, posts, locations, cards, onTeamUpdate} = this.props;

        return (
            <SambaOrgTeamSquad
                organization={organization}
                divisions={divisions}
                seasons={seasons}
                team={team}
                teams={teams}
                users={users}
                posts={posts}
                cards={cards}
                locations={locations}
                onTeamUpdate={onTeamUpdate} />
        );
    }

    renderStatistics() {
        const {organization, team, divisions, seasons, users, teams, posts, locations, cards} = this.props;

        return (
            <SambaOrgTeamStatistics
                organization={organization}
                divisions={divisions}
                seasons={seasons}
                team={team}
                teams={teams}
                users={users}
                posts={posts}
                cards={cards}
                locations={locations} />
        );
    }

    renderTrophy() {
        let items = [];
        
        return (
            <div style={{marginTop: 20}}>
                <div className='shadow-tile' style={{marginBottom: 20}}>
                    <Row>
                        <Col xs={12}>
                            <h3 className='stat-table-title'>
                                <GrTrophy/> {t('trophy')}
                            </h3>
                        </Col>
                    </Row>
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPosts() {
        const { organization, team, teams, posts, users, user } = this.props;
        const { detailChoice, editOpen, detailOpen, searchStr} = this.state;
        
        const result = [];
        const list = [];

        const uid = user ? user.uid : "";
        const editable = team.owner === uid || (team.managers && Object.values(team.managers).includes(uid))
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.teamId === team.id || (value.gameInfo && (value.gameInfo.homeTeam === team.id || value.gameInfo.awayTeam === team.id))) {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })
        
        for (const value of result) {
            list.push(
                <PostHeader 
                    key={value.id}
                    organization={organization}
                    post={value}
                    users={users}
                    user={user}
                    onClickEdit={uid === value.uid ? ()=>{
                        this.setState({
                            detailChoice: value,
                            detailOpen: false,
                            editOpen: true
                        })
                    } : null}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: value,
                            detailOpen: true
                        })
                    }} />
            );
        }

        return (
            <div style={{marginBottom: 20}}>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('news_feed')}
                        icon={<GrArticle/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={list.length}
                        detailItem={detailChoice}
                        onClickAdd={editable ? ()=>{
                            this.setState({detailChoice: {}, editOpen: true, detailOpen: false});
                        } : null}
                        onClickBack={()=>{
                            this.setState({detailChoice: {}, editOpen: false, detailOpen: false});
                        }} />
                </div>

                <div style={{margin: '0px 10px'}}>
                {!editOpen && !detailOpen && (
                <div>
                    {list}
                </div>
                )}

                {detailOpen && detailChoice.id != null && (
                    <PostDetailView 
                        organization={organization}
                        post={detailChoice} 
                        user={user}
                        users={users}
                        teams={teams}
                        isOpen={detailOpen}
                        onCloseClick={() => {
                            this.setState({detailOpen: false})
                        }} />
                )}
                {editOpen && (
                    <PostEdit 
                        uid={user.uid}
                        organization={organization}
                        team={team}
                        teams={teams}
                        post={detailChoice} 
                        isOpen={editOpen}
                        onChanged={() => {
                            this.loadContents()
                        }} 
                        onCloseClick={() => {
                            this.setState({editOpen: false})
                        }} />
                )}
                </div>
            </div>
        );
    }

    renderGeneral() {
        return (
            <Row style={{margin: 0, backgroundColor: 'white'}}>
                <Col sm={8} xs={12} style={{padding: 0}}>
                    {this.renderGeneralStatistics()}
                </Col>
                <Col sm={4} xs={12} style={{padding: 0}}>
                    {this.renderUpcomingGame()}
                </Col>
                <Col sm={8} xs={12} style={{padding: 0}}>
                    {this.renderPosts()}
                </Col>
                <Col sm={4} xs={12} style={{padding: 0}}>
                    {this.renderTrophy()}
                </Col>
            </Row>
        )
    }

    renderGeneralStatistics() {
        const {organization, seasons, filter, team} = this.props;

        let statistics = getOrganizationTeamStatistics(organization, seasons, filter, team);
        let seasonChoice = filter && filter.seasonId && seasons[filter.seasonId] ? seasons[filter.seasonId] : null;

        return (
            <div className="samba-statistics-wrapper">
                <h4 className="samba-statistics-title">{seasonChoice ? t('team_season_statistics') + seasonChoice.title : t('team_organization_statistics')}</h4>
                <Row className="samba-statistics-line">
                    <Col xs={8} className="samba-statistics-cell right-border">
                        <span className="samba-statistics-cell-icon"><PiSoccerBallFill /></span>
                        <div className="samba-statistics-cell-title">{t('matches_played')}</div>
                        <div className="samba-statistics-cell-number">{statistics.played}</div>
                    </Col>
                    <Col xs={4} className="samba-statistics-cell">
                        <div className="samba-statistics-cell-title">{t('to_play')}</div>
                        <div className="samba-statistics-cell-number">{statistics.gameCount - statistics.played}</div>
                    </Col>
                </Row>
                <Row className="samba-statistics-line">
                    <Col xs={6} className="samba-statistics-cell right-border">
                        <span className="samba-statistics-cell-icon"><TfiCup /></span>
                        <div className="samba-statistics-cell-title">{t('wins')}</div>
                        <div className="samba-statistics-cell-number">{statistics.wins}</div>
                    </Col>
                    <Col xs={6} className="samba-statistics-cell">
                        <span className="samba-statistics-cell-icon"><LiaChartBarSolid /></span>
                        <div className="samba-statistics-cell-title">{t('ties')}</div>
                        <div className="samba-statistics-cell-number">{statistics.ties}</div>
                    </Col>
                </Row>
                <Row className="samba-statistics-line">
                    <Col sm={3} xs={6} className="samba-statistics-cell">
                        <span className="samba-statistics-cell-icon"><PiSoccerBallFill /></span>
                        <div className="samba-statistics-cell-title">{t('goals')}</div>
                        <div className="samba-statistics-cell-number">{statistics.goals}</div>
                    </Col>
                    <Col sm={3} xs={6} className="samba-statistics-cell right-border">
                        <span className="samba-statistics-cell-icon"><FaStar /></span>
                        <div className="samba-statistics-cell-title">{t('assists')}</div>
                        <div className="samba-statistics-cell-number">{statistics.assists}</div>
                    </Col>
                    <Col sm={3} xs={6} className="samba-statistics-cell">
                        <span className="samba-statistics-cell-icon"><PiCardsBold /></span>
                        <div className="samba-statistics-cell-title">{t('cards')}</div>
                        <div className="samba-statistics-cell-number">{statistics.yellow}</div>
                    </Col>
                    <Col sm={3} xs={6} className="samba-statistics-cell">
                        <span className="samba-statistics-cell-icon"><GiWhistle /></span>
                        <div className="samba-statistics-cell-title">{t('suspended')}</div>
                        <div className="samba-statistics-cell-number">{statistics.red}</div>
                    </Col>
                </Row>
            </div>
        )
    }

    renderUpcomingGame() {
        const {organization, seasons, filter, team, dict} = this.props;
        let upcoming = [];
        const now = new Date().valueOf();
        for (const season of Object.values(seasons)) {
            if (!filter || !filter.seasonId || season.id === filter.seasonId) {
                if (season.subSeasons) {
                    for (const subSeason of Object.values(season.subSeasons)) {
                        if (subSeason.games) {
                            for (const game of Object.values(subSeason.games)) {
                                if ((game.homeTeam === team.id || game.awayTeam === team.id) && now < game.start) {
                                    upcoming.push(game);
                                }
                            }
                        }
                    }
                }
            }
        }
        sortByNumber(upcoming, 'start');


        return (
            <div>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <h3 className='items-navigation-title'>
                            {t('upcoming_game')}
                            </h3>
                        </div>
                    </div>
                </div>
                {upcoming.length > 0 ? (
                    <SambaGameBadge
                        game={upcoming[0]}
                        seasons={dict.seasons}
                        divisions={dict.divisions}
                        teams={dict.teams}
                        locations={dict.locations}
                        organization={organization}
                        detailMode={true}/>
                ) : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_upcoming_game')}
                    </div>
                )}
                
            </div>
        );
    }

    renderContents() {
        const {mainTab} = this.state;

        switch (mainTab) {
            case 0:
                return (
                <div>
                    {this.renderGeneral()}
                </div>
                )
            case 1:
                return (
                <div>
                    {this.renderSquad()}
                </div>
                )
            case 2:
                return (
                <div>
                    {this.renderStandings()}
                </div>
                )
            case 3:
                return (
                <div>
                    {this.renderStatistics()}
                </div>
                )
            default:
                return (<div/>)
        }
    }
    
    render() {
        const {organization, team} = this.props;
        const {mainTab} = this.state;
        
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        
        if (team != null) {
            return (
                <div className="">
                    <CustomTabView
                        value={mainTab}
                        actionBg={actionBg}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                        onChange={(e,val)=> {
                            this.setState({mainTab: val})
                        }}
                        items={[
                            {
                                label: t('general')
                            },
                            {
                                label: t('squad')
                            },
                            {
                                label: t('games')
                            },
                            {
                                label: t('statistics')
                            },
                        ]} />

                    <div >
                    {this.renderContents()}
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { filter, dict } = state.filterData;
    return { alert, loggingIn, user, filter, dict };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(SambaOrgTeamDetails));
export { connectedApp as SambaOrgTeamDetails };
