import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { FcConferenceCall, FcManager } from "react-icons/fc";
import teamIcon from 'assets/img/icons/club_icon_default.png';

export class TeamEmpty extends Component {
    
    render() {
        const {team} = this.props;
        let icon = team.iconUri != null ? team.iconUri : teamIcon;
        return (
            <div className='landing-dashboard'>
                <div className='landing-dashboard-header' style={{marginTop: 20, marginBottom: 20}}>
                    <img alt='team' className='home-header-icon' src={icon} />
                    <div className="wrap-content-fill-child">
                        <h1 className="home-header-title-small">
                            {team.title}
                            <span></span>
                        </h1>
                        <h3 className="home-header-sub-title">
                            <FcConferenceCall/> {team.playerIds ? Object.values(team.playerIds).length : 0}
                            <FcManager/> {team.managers ? Object.values(team.managers).length : 0}
                            <div style={{display: 'inline-block', width: 20}}></div>
                            
                        </h3>
                    </div>
                </div>
                
                <div className='landing-dashboard-contents'>
                    
                    <div className='landing-dashboard-contents-wrapper'>
                        
                        <h2 className='text-center' style={{marginTop: 40}}>{t('team_not_allowed')}</h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TeamEmpty);
