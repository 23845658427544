import React, { Component } from "react";
import {
    Progress
} from 'reactstrap';

export class LargeLoading extends Component {
    
    render() {
        return (
        <div style={{width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.03)'}}>
            <div className='text-center' style={{position: 'relative', top: '50%', left: '50%', width: '40%', transform: 'translate(-50%,-50%)'}}>
                <Progress animated color="info" value={100} />
            </div>
        </div>
        );
    }
}

export default LargeLoading;