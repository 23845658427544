import React, { Component } from "react";
import { connect } from 'react-redux';
import defaultBg from 'assets/img/bg/header-parallax-no-filter.jpg'
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';

import { homeServices } from 'utils/landing';
import { t } from "i18next";

class SambaServices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: {},
            activeIndex: 0,
            animating: false,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
    }

    componentDidMount() {
        const {organization} = this.props;
        const services = {};
        
        if (organization && organization.landingData && organization.landingData.services) {
            for (const[key, value] of Object.entries(organization.landingData.services)) {
                services[key] = value;
            }
        }

        this.setState({
            services: services,
        });
    }

    componentDidUpdate(prevProps) {
        const {organization} = this.props;
        if (organization !== prevProps.organization) {
            const services = {};
            
            if (organization && organization.landingData && organization.landingData.services) {
                for (const[key, value] of Object.entries(organization.landingData.services)) {
                    services[key] = value;
                }
            }

            this.setState({
                services: services,
            });
        }
    }

    next() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === homeServices.length - 1 ? 0 : activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === 0 ? homeServices.length - 1 : activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(nextIndex) {
        const {animating} = this.state;
        if (animating) {
            return;
        }
        this.setState({activeIndex: nextIndex});
    }

    renderServices() {
        const {organization, loading} = this.props;
        const {services} = this.state;

        const actionBg = organization && organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;
        return homeServices.map((value) => {
            const item = services[value.id] != null ? services[value.id] : value;
            return loading ? (
                <CarouselItem
                    onExiting={() => this.setState({animating: true})}
                    onExited={() => this.setState({animating: false})}
                    key={item.id} >
                    <img alt="samba-bg" className="samba-nav-image" src={defaultBg} />
                    <div className='samba-nav-placeholder' />
                    <div className="samba-cover">
                        <div className="samba-wrapper">
                            <h1 className="home-nav-placeholder-title">
                                Live Teams Organizations ....
                                <span></span>
                            </h1>
                        </div>
                    </div>
                </CarouselItem>
            ) :(
            <CarouselItem
                onExiting={() => this.setState({animating: true})}
                onExited={() => this.setState({animating: false})}
                key={item.id} >
                <img alt="samba-bg" className="samba-nav-image" src={defaultBg} />
                <div className='samba-nav-placeholder' />
                <div className="samba-cover">
                    <div className="samba-wrapper">
                        {item.name && (
                            <h1 className="samba-header-title">
                                {item.name}
                                <span></span>
                            </h1>
                        )}
                        <ul style={{textAlign: 'left'}}>
                            {item ? item.items.map((d, i) => <li className='home-service-texts' key={`${d}-${i}`}>{d}</li>) : 'loading'}
                        </ul>
                        <a
                            href={item.link} 
                            className="btn btn-home-main btn-lg page-scroll"
                            style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                        >
                            {t('view')}
                        </a>
                    </div>
                </div>
            </CarouselItem>
            )
        })
    }

    render() {
        const {activeIndex} = this.state;
        return (
            <header id="overview" style={{position: 'relative'}}>
                <div style={{position: 'relative'}}>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}>
                        <CarouselIndicators items={homeServices} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {this.renderServices()}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </div>
            </header>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    return { isAdmin, loggingIn, user };
}

const connectedNavigation = connect(mapState)(SambaServices);
export { connectedNavigation as SambaServices };
  
