import React, { Component } from 'react'
import JsonData from 'components/landing/data/data.json';
import {ContactUs} from 'components/landing/globe/ContactUs';

export class PolicyPage extends Component {
    state = {
        landingPageData: {},
    }
    getlandingPageData() {
        this.setState({landingPageData : JsonData})
    }
  
    componentDidMount() {
        this.getlandingPageData();
    }
  
    render() {
        if (this.state.landingPageData.About != null) {
            return (
            <div className='main-info-area'>
                <div className='main-info-area-bg'/>
                <div className='main-info-area-content'>
                    <div className='container info'>
                        <h1>Privacy Policy</h1>
                        <p>
                        Effective Date: March 18, 2021
                        </p>
                        <p>
                        Live! Inc. ("Live! Inc," "we," "our," or "us") is committed to protecting the privacy and personal information of our users. This Privacy Policy outlines how we collect, use, disclose, and protect personal information when you access or use our software applications, websites, and related services (collectively referred to as the "Services").
                        </p>
                        <p>
                        By using our Services, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy. Please read this policy carefully to understand our practices regarding your personal information.
                        </p>


                        <br/>
                        <br/>
                        
                        <h3>1. Information We Collect</h3>

                        <br/>
                        <br/>

                        <h4>1.1 Personal Information</h4>
                        <p>
                        We may collect personal information from you when you use our Services, such as:
                        </p>
                        
                        <ul>
                            <li>
                            Contact information (e.g., name, email address, phone number)
                            </li>
                            <li>
                            User credentials (e.g., username, password)
                            </li>
                            <li>
                            Payment information (e.g., credit card details)
                            </li>
                            <li>
                            Any other information you choose to provide to us voluntarily
                            </li>
                        </ul>
                        
                        <br/>
                        <br/>
                        
                        <h4>1.2 Non-Personal Information</h4>

                        <p>
                        We may also collect non-personal information automatically when you use our Services, such as:
                        </p>
                        
                        <ul>
                            <li>
                            Device information (e.g., IP address, operating system)
                            </li>
                            <li>
                            Usage information (e.g., pages visited, time spent on each page)
                            </li>
                            <li>
                            Log data (e.g., access times, referring websites)
                            </li>
                            <li>
                            Aggregated information that does not directly identify you
                            </li>
                        </ul>

                        <br/>
                        <br/>
                        
                        <h3>2. Use of Personal Information</h3>

                        <p>
                        We may use your personal information for the following purposes:
                        </p>
                        
                        <ul>
                            <li>
                            To provide and improve our Services
                            </li>
                            <li>
                            To respond to your inquiries and provide customer support
                            </li>
                            <li>
                            To process payments and fulfill your orders
                            </li>
                            <li>
                            To personalize and customize your experience with our Services
                            </li>
                            <li>
                            To communicate with you about updates, offers, and promotions
                            </li>
                            <li>
                            To enforce our terms of service and other agreements
                            </li>
                            <li>
                            To comply with legal obligations
                            </li>
                            <li>
                            To protect the rights, property, or safety of our users, employees, or others
                            </li>
                            <li>
                            For any other purpose with your consent
                            </li>
                        </ul>
                        
                        <br/>
                        <br/>

                        <h3>3. Data Sharing and Disclosure</h3>


                        <br/>
                        <br/>

                        <h4>3.1 Service Providers</h4>
                        <p>
                        We may engage third-party service providers to assist us in providing and improving our Services. These service providers may have access to your personal information and are contractually obligated to handle it securely and confidentially, and are restricted from using it for any other purpose.
                        </p>

                        <h4>3.2 Business Transfers</h4>
                        <p>
                        In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email or prominent notice on our website of any such change in ownership or control of your personal information.
                        </p>

                        <h4>3.3 Legal Requirements</h4>
                        <p>
                        We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., law enforcement agencies).
                        </p>
                        
                        <br/>
                        <br/>

                        <h3>4. Data Retention</h3>

                        <p>
                        We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. When your personal information is no longer required, we will securely delete or anonymize it.
                        </p>

                        <br/>
                        <br/>

                        <h3>5. Data Security</h3>


                        <br/>
                        <br/>

                        <p>
                        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your information.
                        </p>


                        <br/>
                        <br/>
                        
                        <h3>6. Liability Limitation</h3>

                        <br/>
                        <br/>

                        <p>
                        To the maximum extent permitted by law, we exclude and disclaim all warranties, representations, and liabilities in connection with the use of our Services and the handling of your personal information. We shall not be liable for any indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with this Privacy Policy or your use of our Services.
                        </p>


                        <br/>
                        <br/>

                        <h3>7. Use of Data for Underage Users</h3>


                        <br/>
                        <br/>

                        <p>
                        We do not knowingly collect personal information from individuals under the age of 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately. We will take steps to promptly remove such information from our records.
                        </p>


                        <br/>
                        <br/>
                        
                        <h3>8. Changes to this Privacy Policy</h3>


                        <br/>
                        <br/>

                        <p>
                        We may update this Privacy Policy from time to time, and the updated version will be posted on our website. We encourage you to review this Privacy Policy periodically to stay informed about our practices.
                        </p>


                        <br/>
                        <br/>

                        <h3>9. Contact Us</h3>


                        <br/>
                        <br/>

                        <p>
                        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                        </p>
                        <p>
                        Live! Inc
                        </p>
                        <p>
                        St. Catharines, Ontario
                        </p>
                        <p>
                        L2M-7P9
                        </p>
                        <p>
                        support@liveteams.co
                        </p>
                        <p>
                        By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to its terms and conditions.
                        </p>


                        <br/>
                        <br/>
                    </div>
                    <ContactUs data={this.state.landingPageData.Contact} />
                </div>
            </div>
            )
        } else {
            return <div/>
        }
    }
}

export default PolicyPage;
