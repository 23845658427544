import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {
    Button, Input, CardImg, Spinner, 
} from 'reactstrap';
import { t } from 'i18next';
import playerIcon from 'assets/img/icons/player_photo_default.png';
import { MdCheckBox, MdOutlineCheckBox } from "react-icons/md";
import Pagination from 'react-responsive-pagination';
import CustomDatePicker from "components/template/CustomDatePicker";
import { Switch } from "@mui/material";
import { invokeHttpsApi } from "utils/API";

class UserSearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: {},
            choice: {},
            birthdayCheck: false,
            page: 0,
        }
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        this.setState({
            loading: true,
        })
        invokeHttpsApi('userQuery-usersQuery', {}, (data) => {
            const users = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
            }

            this.setState({
                users: users,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
            })
        });
    }

    render() {
        const {forSingle, onCloseClick} = this.props;
        const {loading, users, searchStr, choice, start, end, progress, birthdayCheck, page} = this.state;

        let userArr = [];
        for (const item of Object.values(users)) {
            if (!searchStr || searchStr === '' || (item.username && item.username.toLowerCase().includes(searchStr.toLowerCase()))) {
                if (!start && !end) {
                    userArr.push(item);
                } else if (start && !end && item.birthday >= start) {
                    userArr.push(item);
                } else if (!start && end && item.birthday <= end) {
                    userArr.push(item);
                } else if (start && end && item.birthday >= start && item.birthday <= end) {
                    userArr.push(item);
                }
            }
        }
        userArr.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        let list = [];

        const pageCount = Math.ceil((userArr ? Object.values(userArr).length : 0) / 10);
        let pageChoice = page + 1;
        
        let idx = (pageChoice - 1) * 10;
        for (let i = 0; i < 10; i ++) {
            if (userArr.length > idx) {
                const item = userArr[idx ++];
                
                list.push(
                    <div key={idx}>
                        <div className="wrap-content-parent" onClick={(e)=> {
                            if (forSingle) {
                                let update = {}
                                update[item.uid] = item.uid;
                                this.setState({choice: update});
                            } else {
                                if (Object.values(choice).includes(item.uid)) {
                                    delete choice[item.uid];
                                } else {
                                    choice[item.uid] = item.uid;
                                }
                                this.setState({choice: choice});
                            }
                        }}>
                            <div style={{color: Object.values(choice).includes(item.uid) ? '#F86053' : '#666', fontSize: 21, margin: 'auto 2px'}}>
                                {Object.values(choice).includes(item.uid) ? <MdCheckBox /> : <MdOutlineCheckBox />}
                            </div>
                            <CardImg 
                                className='event-badge-icon' 
                                src={item.photoUri ? item.photoUri : playerIcon} />
                            <div className="event-badge-title">{item.username}<br/> {item.email}</div>
                        </div>
                        <div className="divider" />
                    </div>
                );
            }
        }
        
        return (
            <div>
                <h2 className="course-title">{t('user_choice')}</h2>
                <div className='wrap-content-parent'>
                    <Input type="text" name="search" id="search" placeholder='Search by email ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>
                <div style={{height: 15}} />
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Show Birthday Filter </b> | Birthday Range
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={birthdayCheck}
                            onChange={e=>{
                                this.setState({birthdayCheck: e.target.checked});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                {birthdayCheck && (
                <div className="form-setting-wrapper">
                    <div className='form-setting-title'>
                    {t('birthday_start')}
                    </div>
                    <div className='form-setting-control'>
                        <CustomDatePicker
                            margin="normal"
                            format="MMM YYYY"
                            onChange={ date => {
                                this.setState({start: date.valueOf()});
                            }} />
                    </div>
                </div>
                )}
                {birthdayCheck && (
                <div className="form-setting-wrapper">
                    <div className='form-setting-title'>
                    {t('birthday_end')}
                    </div>
                    <div className='form-setting-control'>
                        <CustomDatePicker
                            margin="normal"
                            format="MMM YYYY"
                            onChange={ date => {
                                this.setState({end: date.valueOf()});
                            }} />
                    </div>
                </div>
                )}
                <div style={{height: 15}} />
                {loading && (
                <div className="text-center">
                    <Spinner />
                </div>
                )}
                {!loading && list.length === 0 ? (
                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_data')}
                </div>
                ) :  (
                <div>
                    {list}
                    
                    <div style={{height: 25}} />
                    <Pagination
                        current={page}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
                )}
                <div style={{height: 20}} />
                {progress ? (
                <div className="wrap-content-parent">
                    <div className="wrap-content-fill-child" />
                    <Spinner color="primary" />
                </div>
                ) : (
                <div className="wrap-content-parent">
                    <div className="wrap-content-fill-child" />
                    {Object.values(choice).length > 0 && (
                    <Button className="btn-submit" onClick={e=> {
                        if (onCloseClick)  {
                            if (forSingle) {
                                onCloseClick(Object.values(choice)[0], users);
                            } else {
                                onCloseClick(Object.values(choice), users);
                            }
                        }
                    }}>{t('ok')}</Button>
                    )}
                    <Button className="btn-cancel" onClick={e=> {
                        if (onCloseClick)  {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = connect(mapState, actionCreators)(UserSearch);
export { connectedApp as UserSearch };
