import React, { Component } from 'react';
import { 
    Row,
    Col,
    Input,
    Modal,
    ModalBody
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import teamIcon from 'assets/img/icons/shield_icon.png';
import SmallInfoBadge from 'components/detail/SmallInfoBadge';
import TeamUserBadge from 'components/detail/organization/TeamUserBadge';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import TeamPlayerEdit from 'components/edit/TeamPlayerEdit';
import { generateId } from 'utils/API';
import ProfileEdit from 'components/edit/ProfileEdit';

export class OrganizationTeamDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisionChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }

    checkSeason(season, uid) {
        if (season) {
            if (season.teamData) {
                for (const teamData of Object.values(season.teamData)) {
                    if (teamData.roaster && Object.values(teamData.roaster).includes(uid)) {
                        return true;
                    }
                }
            }
            return false;
        }
        return true;
    } 
    
    renderContents() {
        const {organization, divisions, seasons, team, user, profileData, users, onClickDetail, onEditClick, onInviteClick, inviteCount} = this.props;
        const {userEdit, seasonChoice} = this.state;

        const seasonItems = [];
        for (const season of Object.values(seasons)) {
            const divisionTitle = divisions && divisions[season.divisionId] ? divisions[season.divisionId].title : "";
            season.divTitle = divisionTitle + "/" + season.title;
            seasonItems.push(season);
        }
        seasonItems.sort(function(a, b) {
            if (a.divTitle === b.divTitle) 
                return 0;

            return a.divTitle < b.divTitle ? -1 : 1;
        });

        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;

        let items = [];
        let members = [];
        for (const [key, value] of Object.entries(users)) {
            const isMember = team.playerIds != null && Object.values(team.playerIds).includes(key);
            
            if (isMember && this.checkSeason(seasonChoice, key)) {
                members.push(value);
            }
        }
        members.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        
        for (const value of members) {
            items.push(
                <TeamUserBadge 
                    key={value.uid}
                    organization={organization}
                    team={team}
                    user={value}
                    users={users}
                    profileData={profileData}
                    onClickDetail={(user)=> {
                        if (onClickDetail != null) {
                            onClickDetail(user);
                        }
                    }} />
            );
            items.push(<div className='divider' />);
        }
        
        return (
        <div>
            <div style={{marginTop: 30, marginBottom: 30}}>
                {user && user.uid === team.owner ? (
                    <SmallInfoBadge
                        title={team.title}
                        icon={thumbImage}
                        actionTitle1={t('edit')}
                        actionCallback1={()=> {
                            if (onEditClick) {
                                onEditClick();
                            }
                        }}
                        actionTitle2={inviteCount != null && inviteCount === 0 ? "Transfer Ownership" : `(${inviteCount} transfer invited)`}
                        actionCallback2={()=> {
                            if (onInviteClick) {
                                onInviteClick();
                            }
                        }} />
                ) : (
                    <SmallInfoBadge
                        title={team.title}
                        icon={thumbImage} />
                )}
            </div>
            <Row className='form-edit-row'>
                <Col className='form-edit-label' sm={5} xs={12}>
                Season Choice
                </Col>
                <Col className='form-edit-entry' sm={7} xs={12}>
                    {<div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            const id = e.target.value;
                            this.setState({seasonChoice: seasons[id]});
                        }} value={seasonChoice ? seasonChoice.id : null}>
                                <option key="null" value={null}></option>
                                {seasonItems.map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.divTitle}</option>
                                ))}
                        </Input>
                    </div>}
                </Col>
            </Row>
            <div style={{marginTop: 20}}>
                <ItemsNavigation
                    title={t('players')}
                    icon={<img src={teamIcon} style={{width: 25, height: 25}} alt='icon'/>}
                    detailOpen={false}
                    editOpen={false}
                    totalCount={members.length}
                    detailItem={userEdit ? userEdit : {}}
                    onClickAdd={e=>{
                                
                        this.setState({userEdit: {
                            uid: generateId("/user"),
                            owner: team.owner,
                        }});
                    }} />
            </div>
            {userEdit && (
            <Modal isOpen={true}>
                <ModalBody>
                    <TeamPlayerEdit 
                        profile={userEdit}
                        teamId={team.id}
                        onSaveSuccess={() => {
                            alert("Saving user update success.");
                        }}
                        onSavePrompt={(profile) => {
                            if (!team.playerIds) {
                                team.playerIds = {};
                            }
                            team.playerIds[profile.uid] = profile.uid;
                            
                            if (this.props.onUserUpdate) {
                                this.props.onUserUpdate(profile, team);
                            }
                            this.setState({userEdit: profile});
                        }}
                        onCloseClick={() => {
                            this.setState({userEdit: null})
                        }} />
                    <ProfileEdit 
                        user={user}
                        profile={userEdit}
                        teamId={team.id}
                        onSaveSuccess={(profile) => {
                            if (!team.playerIds) {
                                team.playerIds = {};
                            }
                            team.playerIds[profile.uid] = profile.uid;
                            
                            if (this.props.onUserUpdate) {
                                this.props.onUserUpdate(profile, team);
                            }
                            this.setState({userEdit: profile});
                        }}
                        onCloseClick={() => {
                            this.setState({userEdit: null})
                        }} />
                </ModalBody>
            </Modal>
            )}
            <div className='shadow-tile'>
                <Row>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <h3 className='row-table-title'>
                            Name
                        </h3>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            #
                        </h3>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center">
                        <h3 className='row-table-title'>
                            Position
                        </h3>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            {t('age')}
                        </h3>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <h3 className='row-table-title'>
                            Verified
                        </h3>
                    </Col>
                    <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Players
                    </div>
                )}
            </div>
        </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderContents()}
            </div>
        );
    }
}

export default withTranslation()(OrganizationTeamDetails);
