import React, { Component } from 'react';

import LoadingView from 'components/detail/loading/LoadingView';
import SlidesEdit from 'components/edit/organization/landing/SlidesEdit';
import VideoEdit from 'components/edit/organization/landing/VideoEdit';
import ServicesEdit from 'components/edit/organization/landing/ServicesEdit';
import { connect } from 'react-redux';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import OrganizationPaymentBadge from 'components/detail/organization/OrganizationPaymentBadge';
import userIcon from 'assets/img/icons/player_photo_default.png';
import appIcon from 'assets/img/logo/live_192.png';
import { toDateTimeString } from 'utils/Utils';
import AttachImageEdit from 'components/edit/AttachImageEdit';
import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import ItemsNavigation from 'components/detail/ItemsNavigation';

import RequiredOption from 'components/detail/RequiredOption';
import { SketchPicker } from 'react-color';
import Switch from '@mui/material/Switch';
import { 
    Form,
    Row,
    Col,
    Input,
    Button,
    Modal, 
    ModalBody,
    Spinner,
    ListGroupItem
} from 'reactstrap';

import { t } from 'i18next';
import { 
    FaWpforms
} from 'react-icons/fa';
import { 
    FcBusiness
} from 'react-icons/fc';

import { userActions } from 'redux/_actions';
import { aboutUs, contact } from 'utils/landing';
import { LoadScript, Autocomplete } from '@react-google-maps/api';

import { OrganizationClaimsView } from 'components/detail/organization/OrganizationClaimsView';
import { OrganizationSupportView } from 'components/detail/organization/OrganizationSupportView';
import { withRouter } from 'utils/Routher';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';
import { generateId, invokeHttpsApi, writeDb } from 'utils/API';
import CustomRichTextEditor from 'components/template/CustomRichTextEditor';
  
class SettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainTab: 0,
            organizations: {},
            organization: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            stripeUser: {},
            setupLink: null,
            category: {},
            detailOpen: false,
            editOpen: false,
            editProfile: false,
            editOrganization: false,
            loading: true,
        };
        this.autocomplete = null
        this.loadContents = this.loadContents.bind(this);
        this.onLoadMap = this.onLoadMap.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizerDashboardQuery', {}, (data) => {
            const organizations = {};
            const others = {};
            const divisions = {};
            const refereeIds = [];
            const managerIds = [];
            const seasons = {};
            const users = {};
            const category = {};
            const stripeUser = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.others)) {
                    others[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const value of data.refereeIds) {
                    refereeIds.push(value);
                }
                for (const value of data.managerIds) {
                    managerIds.push(value);
                }
                for (const [key, value] of Object.entries(data.stripeUser)) {
                    stripeUser[key] = value;
                }
            }
            let organization = Object.values(organizations)[0];
            if (organization == null) {
                organization = {};
            }

            this.setState({
                organizations: organizations,
                others: others,
                organization: organization,
                divisions: divisions,
                refereeIds: refereeIds,
                managerIds: managerIds,
                seasons: seasons,
                users: users,
                category: category,
                stripeUser: stripeUser,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderPlayerFormSettings(organization) {
        if (organization.formSetting == null) {
            organization.formSetting = {};
        }
        if (organization.formSetting.playerName == null) {
            organization.formSetting.playerName = {option: 'Required', switch: true};
        }
        if (organization.formSetting.playerLastName == null) {
            organization.formSetting.playerLastName = {option: 'Required', switch: true};
        }
        if (organization.formSetting.email == null) {
            organization.formSetting.email = {option: 'Required', switch: true};
        }
        if (organization.formSetting.birthday == null) {
            organization.formSetting.birthday = {option: 'Optional', switch: true};
        }
        if (organization.formSetting.mobile == null) {
            organization.formSetting.mobile = {option: 'Optional', switch: false};
        }
        if (organization.formSetting.healthDoc == null) {
            organization.formSetting.healthDoc = {option: 'Optional', switch: false};
        }
        if (organization.formSetting.playerIDNumber == null) {
            organization.formSetting.playerIDNumber = {option: 'Optional', switch: false};
        }
        if (organization.formSetting.playerIDAttatch == null) {
            organization.formSetting.playerIDAttatch = {option: 'Optional', switch: false};
        }
        if (organization.formSetting.position == null) {
            organization.formSetting.position = {option: 'Optional', switch: false};
        }
        if (organization.formSetting.jersey == null) {
            organization.formSetting.jersey = {option: 'Optional', switch: false};
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>First Name </b> | Field to enter the player name in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.playerName.option}
                            onChanged={update=>{
                                organization.formSetting.playerName.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.playerName.switch}
                            onChange={e=>{
                                organization.formSetting.playerName.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Last name </b> | Field to enter the player last name in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.playerLastName.option}
                            onChanged={update=>{
                                organization.formSetting.playerLastName.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.playerLastName.switch}
                            onChange={e=>{
                                organization.formSetting.playerLastName.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Email </b> | Field to enter the player email in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.email.option}
                            onChanged={update=>{
                                organization.formSetting.email.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.email.switch}
                            onChange={e=>{
                                organization.formSetting.email.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Date of birth </b> | Field to enter the player date of birth.
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.birthday.option}
                            onChanged={update=>{
                                organization.formSetting.birthday.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.birthday.switch}
                            onChange={e=>{
                                organization.formSetting.birthday.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Mobile </b> | Field to enter the player mobile number in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.mobile.option}
                            onChanged={update=>{
                                organization.formSetting.mobile.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.mobile.switch}
                            onChange={e=>{
                                organization.formSetting.mobile.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Health attachment </b> | Field to upload the player health insurance ID in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.healthDoc.option}
                            onChanged={update=>{
                                organization.formSetting.healthDoc.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.healthDoc.switch}
                            onChange={e=>{
                                organization.formSetting.healthDoc.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Profile ID number </b> | Field to enter the user ID number in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.playerIDNumber.option}
                            onChanged={update=>{
                                organization.formSetting.playerIDNumber.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.playerIDNumber.switch}
                            onChange={e=>{
                                organization.formSetting.playerIDNumber.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Profile ID attachment </b> | Field to upload the user ID in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.playerIDAttatch.option}
                            onChanged={update=>{
                                organization.formSetting.playerIDAttatch.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.playerIDAttatch.switch}
                            onChange={e=>{
                                organization.formSetting.playerIDAttatch.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Position </b> | Field to enter the player position in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.position.option}
                            onChanged={update=>{
                                organization.formSetting.position.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.position.switch}
                            onChange={e=>{
                                organization.formSetting.position.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Jersey </b> | Field to enter the player jersey number in the registration form
                    </div>
                    <div className='form-setting-control'>
                        <RequiredOption 
                            value={organization.formSetting.jersey.option}
                            onChanged={update=>{
                                organization.formSetting.jersey.option = update;
                                this.setState({organization: organization});
                            }}/>
                    </div>
                    <div className='form-setting-control'>
                        <Switch
                            checked={organization.formSetting.jersey.switch}
                            onChange={e=>{
                                organization.formSetting.jersey.switch = e.target.checked;
                                this.setState({organization: organization});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
            </div>
        );
    }

    async loadTransactions() {
        const { user } = this.props;
        
        const data = {}
        if (user.stripeAccountId) {
            data.stripeAccountId = user.stripeAccountId
        }
        
        this.setState({progress: true});
        invokeHttpsApi('purchase-queryOrganizationTransactions', data, (data) => {

            const income = {};
            const customers = {};
            const seasonPayments = {};
            const users = {};
            if (data.success) {
                if (data.income) {
                    for (const [k,v] of Object.entries(data.income)) {
                        income[k] = v;
                    }
                }
                if (data.seasonPayments) {
                    for (const [k,v] of Object.entries(data.seasonPayments)) {
                        seasonPayments[k] = v;
                    }
                }
                if (data.customers) {
                    for (const [k,v] of Object.entries(data.customers)) {
                        customers[k] = v;
                    }
                }
                if (data.users) {
                    for (const [key, value] of Object.entries(data.users)) {
                        users[key] = value;
                    }
                }
            }

            this.setState({
                income: income, 
                customers: customers, 
                seasonPayments: seasonPayments, 
                users: users, 
                progress: false
            });
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderPaymentSettings() {
        const {user} = this.props;
        const {organization, stripeUser} = this.state;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        
        return (
        <div style={{marginTop: 30, marginBottom: 30}}>
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <div className='detail-nav-btn'>
                        <div className='items-navigation-count'><FaWpforms/></div>
                        <h3 className='items-navigation-title'>
                        Settings
                        </h3>
                        <div className='items-navigation-count'>| Organization Payment Method</div>
                    </div>
                </div>
                
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    this.setState({details: null})
                }}>{t('back')}</Button>
            </div>
            <OrganizationPaymentBadge
                organization={organization}
                user={user}
                stripeUser={stripeUser}
                onChanged={(update)=> {
                    this.setState({organization: update});
                }} />
            {this.renderTransactions()}
        </div>
        );
    }

    renderTransactions() {
        const {progress, income} = this.state;

        const incomeArr = [];
        if (income) {
            for (const item of Object.values(income)) {
                incomeArr.push(item);
            }
        }
        incomeArr.sort((a, b) => {
            return a.created - b.created;
        })

        const items = [];
        for (const item of incomeArr) {
            items.push(<div>{this.renderTransactionItem(item)}</div>)
        }

        return (
            <div>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Transactions
                            </h3>
                            <div className='items-navigation-count'>| Organization Payment Method</div>
                        </div>
                    </div>
                    
                    {progress ? 
                    <Spinner className='' /> : 
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.loadTransactions();
                    }}>Load Transactions</Button>
                    }
                </div>
                <div className='shadow-tile-table'>
                    <Row style={{margin: 0}}>
                        <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Payment
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Date
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {income ? t('no_items_to_show') : "Not Loaded Payments"}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderTransactionItem(transaction) {
        const {seasons, divisions, seasonPayments, users} = this.state;

        let user = {};
        let season = {};
        let division = {};
        let seasonPayment = {};
        for (const item of Object.values(seasonPayments)) {
            if (item.id === transaction.id) {
                seasonPayment = item;
                season = seasons[item.seasonId];
                if (season) {
                    division = divisions[season.divisionId];
                }
                user = users[item.userId];
                break;
            }
        }
        const userThumb = user.photoUri ? user.photoUri : userIcon;
        const seasonThumb = division.thumbUri ? division.thumbUri : appIcon;
        const seasonTitle = season.title + " - " + division.title;

        const subView = (
            <div className='wrap-content-parent'>
                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                <h4 className='label label-default one-line-title'>
                {seasonPayment.role.toUpperCase()}
                </h4>
                </div>
                <img className="wrap-content-wrap-child" alt='season-icon' src={seasonThumb} style={{width: 20, height: 20, borderRadius: 10, marginRight: 5}}/>
                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                <div className='label label-default one-line-title'>
                {seasonTitle}
                </div>
                </div>
            </div>
        )
        const subView2 = (
            <div className='wrap-content-parent'>
                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                <div className='label label-default one-line-title'>
                {toDateTimeString(transaction.created * 1000)}
                </div>
                </div>
            </div>
        )
        return (
            <TableRowSmall 
                key={transaction.id}
                title={user.username + '$' + (transaction.amount / 100) + " / App Fee: $" + (transaction.application_fee_amount / 100)}
                icon={userThumb}
                subView={subView}
                subView2={subView2} />
        );
    }

    renderFormSettings() {
        const {organization, editOpen} = this.state;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        return (
        <div>
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Settings
                            </h3>
                            <div className='items-navigation-count'>| Organization Registration Form</div>
                        </div>
                    </div>
                    
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({details: null})
                    }}>{t('back')}</Button>
                </div>
                {this.renderPlayerFormSettings(organization)}
                <div style={{textAlign: 'right'}}>
                    <Button className="btn-submit" onClick={e => {
                        writeDb('/organization/' + organization.id + '/formSetting', organization.formSetting, error => {
                            if (error) {
                                alert("Updating Change failed\n", error);
                            } else {
                                alert("Form Setting Saved Successfully.");
                                this.loadContents();
                            }
                        })
                    }}>Update Form Settings</Button>
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                    
                    </ModalBody>
                </Modal>
            </div>
        </div>
        );
    }

    renderOtherSettings() {
        const {organization} = this.state;

        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        const enrollFee = organization.enrollFee ? organization.enrollFee : {}

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Settings
                            </h3>
                            <div className='items-navigation-count'>| Organization Enroll</div>
                        </div>
                    </div>
                    
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({details: null})
                    }}>{t('back')}</Button>
                </div>
                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Player Fee
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!organization.enrollFee) {
                                        organization.enrollFee = {}
                                    }
                                    organization.enrollFee.playerFee = parseInt(e.target.value);
                                    this.setState({organization: organization});
                                }} value={enrollFee.playerFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Coach Fee
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!organization.enrollFee) {
                                        organization.enrollFee = {}
                                    }
                                    organization.enrollFee.coachFee = parseInt(e.target.value);
                                    this.setState({organization: organization});
                                }} value={enrollFee.coachFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Manager Fee
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!organization.enrollFee) {
                                        organization.enrollFee = {}
                                    }
                                    organization.enrollFee.managerFee = parseInt(e.target.value);
                                    this.setState({organization: organization});
                                }} value={enrollFee.managerFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                            <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                writeDb('/organization/' + organization.id + '/enrollFee', enrollFee, error => {
                                    if (error) {
                                        alert("Updating Change failed\n", error);
                                    } else {
                                        this.loadContents();
                                    }
                                })
                            }}>{t('save')}</Button>
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={7}>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    renderPolicySettings() {
        const {organization} = this.state;
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        return (
        <div>
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Settings
                            </h3>
                            <div className='items-navigation-count'>| Organization Policy</div>
                        </div>
                    </div>
                    
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({details: null})
                    }}>{t('back')}</Button>
                </div>
                
                <CustomRichTextEditor
                    placeholderText='Edit Your Content Here!'
                    value={organization.policy}
                    onChange={(update)=> {
                        organization.policy = update
                        this.setState({organization: organization})
                    }} />
                
                <div style={{textAlign: 'right'}}>
                    <Button className="btn-submit" onClick={e => {
                        writeDb('/organization/' + organization.id + '/policy', organization.policy, error => {
                            if (error) {
                                alert("Updating Change failed\n", error);
                            } else {
                                this.loadContents();
                            }
                        })
                    }}>Update Policy Settings</Button>
                </div>
            </div>
        </div>
        );
    }

    renderLandingSettings() {
        const {organization, others, domainProgress, imageProgress, landingEdit, customDomain} = this.state;

        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        const theme = organization.landingData ? organization.landingData.theme : null;
        const blueUrl = organization.landingData ? organization.landingData.blueUrl : null;
        const sambaUrl = organization.landingData ? organization.landingData.sambaUrl : null;
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : "#C1E729";
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : "#000000";
        const followingOrgIds = organization.landingData && organization.landingData.followingOrgIds ? organization.landingData.followingOrgIds : {};
        const added = [];
        const nonMember = [];
        for (const item of Object.values(others)) {
            if (Object.values(followingOrgIds).includes(item.id)) {
                added.push(item);
            } else {
                nonMember.push(item);
            }
        }
        added.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        nonMember.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Settings
                            </h3>
                            <div className='items-navigation-count'>| Organization Landing Page</div>
                        </div>
                    </div>
                    
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({details: null})
                    }}>{t('back')}</Button>
                </div>
                <Row>
                    <Col xs={3}>
                        <ListGroupItem style={!landingEdit ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: null})
                        }}>General</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'slides' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'slides'})
                        }}>Landing Slides</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'services' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'services'})
                        }}>Services</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'video' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'video'})
                        }}>Video</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'about' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'about'})
                        }}>About Us</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'contact' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'contact'})
                        }}>{t('contact_us')}</ListGroupItem>
                    </Col>
                    {!landingEdit && (
                    <Col xs={9}>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Hide Organization Landing Page </b> | Hide Out Landing page
                            </div>
                            <div className='form-setting-control'>
                                <Switch
                                    checked={organization.landingData && organization.landingData.hideLanding}
                                    onChange={e=>{
                                        if (!organization.landingData) {
                                            organization.landingData = {};
                                        }
                                        organization.landingData.hideLanding = e.target.checked;
                                        this.saveLandingChange(organization);
                                        this.setState({organization: organization});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </div>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Custom Domain </b> | Domain attached to organization url
                            </div>
                            <div className='form-setting-input'>
                                <Input type="text" name="text" id="customDomain" onChange={e => {
                                    const update = e.target.value ? e.target.value.toLowerCase() : null;
                                    
                                    this.setState({customDomain: update});
                                }} value={customDomain ? customDomain : organization.customDomain} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    this.saveDomainChange(organization, customDomain);
                                }}>Update Custom Domain</Button>
                                }
                            </div>
                        </div>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Landing Page Theme </b> | Landing page type
                            </div>
                            <div className='form-setting-input'>
                                <Input type="select" name="text" id="customDomain" onChange={e => {
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
                                    organization.landingData.theme = e.target.value;
                                    
                                    this.setState({organization: organization});
                                }} value={theme}>
                                    <option value={null}></option>
                                    <option value="normal">{t('normal')}</option>
                                    <option value="blue">{t('blue')}</option>
                                    <option value="samba">{t('samba')}</option>
                                </Input>
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
        
                                    this.saveChange(organization, organization.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Custom Action Color </b> | Custom color set for action button all over the theme
                            </div>
                            <div className='form-setting-input'>
                                <SketchPicker color={actionBg} onChange={(e)=> {
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
                                    organization.landingData.actionBg = e.hex;
                                    this.setState({organization: organization});
                                }} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    this.saveChange(organization, organization.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Custom Action Font Color </b> | Custom color set for action button all over the theme
                            </div>
                            <div className='form-setting-input'>
                                <SketchPicker color={actionColor} onChange={(e)=> {
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
                                    organization.landingData.actionColor = e.hex;
                                    this.setState({organization: organization});
                                }} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    this.saveChange(organization, organization.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                        {theme === 'blue' && (
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Blue Theme Background</b>
                            </div>
                            <div className='form-setting-input'>
                                <AttachImageEdit
                                    imageUrl={blueUrl}
                                    imageClass='home-edit-thumb'
                                    label='Blue Image Url'
                                    maxHeight={150}
                                    allowProgress={domainProgress}
                                    progress={imageProgress}
                                    onChange={(file)=> {
                                        this.setState({domainProgress: true});
                                        uploadImageFileToStorage(organization.id, 'organization-blue-theme', file, (url) => {
                                            if (!organization.landingData) {
                                                organization.landingData = {};
                                            }
                                            organization.landingData.blueUrl = url;
                                            this.saveChange(organization, organization.landingData);
                                        }, (state, progress) => {
                                            if (state === 'running') {
                                                this.setState({imageProgress: progress});
                                            } else {
                                                this.setState({imageProgress: 0});
                                            }
                                        }, error => {
                                            alert("Saving Image File Failed.\n" + error);
                                            this.setState({
                                                progress: false
                                            });
                                        })
                                    }} />
                            </div>
                        </div>
                        )}
                        {theme === 'samba' && (
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Samba Theme Background</b>
                            </div>
                            <div className='form-setting-input'>
                                <AttachImageEdit
                                    imageUrl={sambaUrl}
                                    imageClass='home-edit-thumb'
                                    label='Samba Image Url'
                                    maxHeight={150}
                                    allowProgress={domainProgress}
                                    progress={imageProgress}
                                    onChange={(file)=> {
                                        this.setState({domainProgress: true});
                                        uploadImageFileToStorage(organization.id, 'organization-samba-theme', file, (url) => {
                                            if (!organization.landingData) {
                                                organization.landingData = {};
                                            }
                                            organization.landingData.sambaUrl = url;
                                            this.saveChange(organization, organization.landingData);
                                        }, (state, progress) => {
                                            if (state === 'running') {
                                                this.setState({imageProgress: progress});
                                            } else {
                                                this.setState({imageProgress: 0});
                                            }
                                        }, error => {
                                            alert("Saving Image File Failed.\n" + error);
                                            this.setState({
                                                progress: false
                                            });
                                        })
                                    }} />
                            </div>
                        </div>
                        )}
                        {theme === 'samba' && (
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Samba Ads Section </b> | Samba Ads Title & Subtitle
                            </div>
                            <div className='form-setting-input'>
                                <Input type="text" name="text" id="customDomain" placeholder="Samba Ads Title" onChange={e => {
                                    const update = e.target.value ? e.target.value.toLowerCase() : null;
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
                                    organization.landingData.sambaAdsTitle = update;
                                    
                                    this.setState({organization: organization});
                                }} value={organization.landingData ? organization.landingData.sambaAdsTitle : null} />
                                <div style={{marginBottom: 10}}/>
                                <Input type="text" name="text" id="customDomain" placeholder="Samba Ads Subtitle" onChange={e => {
                                    const update = e.target.value ? e.target.value.toLowerCase() : null;
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
                                    organization.landingData.sambaAdsSubtitle = update;
                                    
                                    this.setState({organization: organization});
                                }} value={organization.landingData ? organization.landingData.sambaAdsSubtitle : null} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    this.saveDomainChange(organization, customDomain);
                                }}>Update Custom Domain</Button>
                                }
                            </div>
                        </div>
                        )}
                        {theme === 'samba' && (
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Samba Following Organizations</b>
                            </div>
                            <div className='form-setting-input'>
                                {added.map((val,idx)=>(
                                <TableRowSmall
                                    key={idx}
                                    title={val.title}
                                    icon={val.thumbUri ? val.thumbUri : appIcon}
                                    subView={
                                        <div>
                                            <b style={{marginRight: 10}}>{t('theme')}</b>
                                            {val.landingData && val.landingData.theme ? val.landingData.theme : t('normal')}
                                        </div>
                                    }
                                    detailLabel={t('remove')}
                                    onClickDetails={()=> {
                                        delete organization.landingData.followingOrgIds[val.id];
                                        this.setState({organization: organization});
                                    }} />
                                ))}
                                {nonMember.map((val,idx)=>(
                                <TableRowSmall
                                    key={idx}
                                    title={val.title}
                                    icon={val.thumbUri ? val.thumbUri : appIcon}
                                    subView={
                                        <div>
                                            <b style={{marginRight: 10}}>{t('theme')}</b>
                                            {val.landingData && val.landingData.theme ? val.landingData.theme : t('normal')}
                                        </div>
                                    }
                                    detailLabel={t('add')}
                                    onClickDetails={()=> {
                                        if (!organization.landingData) {
                                            organization.landingData = {};
                                        }
                                        if (!organization.landingData.followingOrgIds) {
                                            organization.landingData.followingOrgIds = {};
                                        }
                                        organization.landingData.followingOrgIds[val.id] = val.id;
                                        this.setState({organization: organization});
                                    }} />
                                ))}
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    if (!organization.landingData) {
                                        organization.landingData = {};
                                    }
        
                                    this.saveChange(organization, organization.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                        )}
        
                        {this.renderSponsers()}
                    </Col>
                    )}
                    {landingEdit === 'slides' && (
                    <Col xs={9}>
                        <SlidesEdit 
                            organization={organization}
                            onUpdate={(update)=> {
                                organization.landingData = {};
                                for (const [k, v] of Object.entries(update)) {
                                    organization.landingData[k] = v;
                                }
                                this.setState({organization: organization})
                            }} />
                    </Col>
                    )}
                    {landingEdit === 'services' && (
                    <Col xs={9}>
                        <ServicesEdit 
                            organization={organization}
                            onUpdate={(update)=> {
                                organization.landingData = update;

                                this.setState({organization: organization})
                            }} />
                    </Col>
                    )}
                    {landingEdit === 'video' && (
                    <Col xs={9}>
                        <VideoEdit 
                            organization={organization}
                            onUpdate={(update)=> {
                                organization.landingData.video = {};
                                for (const [k, v] of Object.entries(update)) {
                                    organization.landingData.video[k] = v;
                                }
                                this.setState({organization: organization})
                            }} />
                    </Col>
                    )}
                    {landingEdit === 'about' && (
                    <Col xs={9}>
                        {this.renderAboutUs()}
                    </Col>
                    )}
                    {landingEdit === 'contact' && (
                    <Col xs={9}>
                        {this.renderContactUs()}
                    </Col>
                    )}
                </Row>
            </div>
        )
    }

    renderAboutUs() {
        const {organization, domainProgress, imageProgress} = this.state;
        const landingData = (organization && organization.landingData) ? organization.landingData : {};
        const aboutData = landingData.aboutUs ? landingData.aboutUs : aboutUs;

        const itemsArr = Object.values(aboutData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];
        for (const item of itemsArr) {
            items.push((
                <div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Subtitle </b> | About Us Item Subtitle
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!organization.landingData.aboutUs) {
                                    organization.landingData.aboutUs = {};
                                    for (const [k, v] of Object.entries(aboutUs)) {
                                        organization.landingData.aboutUs[k] = v;
                                    }
                                }

                                organization.landingData.aboutUs.items[item.id].subtitle = e.target.value;
                                
                                this.setState({organization: organization});
                            }} value={item.subtitle} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Text </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!organization.landingData.aboutUs) {
                                    organization.landingData.aboutUs = {};
                                    for (const [k, v] of Object.entries(aboutUs)) {
                                        organization.landingData.aboutUs[k] = v;
                                    }
                                }

                                organization.landingData.aboutUs.items[item.id].text = e.target.value;
                                
                                this.setState({organization: organization});
                            }} value={item.text} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Index </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="number" name="text" id="customDomain" onChange={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!organization.landingData.aboutUs) {
                                    organization.landingData.aboutUs = {};
                                    for (const [k, v] of Object.entries(aboutUs)) {
                                        organization.landingData.aboutUs[k] = v;
                                    }
                                }

                                organization.landingData.aboutUs.items[item.id].index = e.target.value;
                                
                                this.setState({organization: organization});
                            }} value={item.index} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Image</b>
                        </div>
                        <div className='form-setting-input'>
                            <AttachImageEdit
                                imageUrl={item.bg}
                                imageClass='home-edit-thumb'
                                label='Blue Image Url'
                                maxHeight={150}
                                allowProgress={domainProgress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({domainProgress: true});
                                    uploadImageFileToStorage(item.id, 'organization-about-us/' + organization.id + '/image', file, (url) => {
                                        if (!organization.landingData) {
                                            organization.landingData = {};
                                        }
                                        if (!organization.landingData.aboutUs) {
                                            organization.landingData.aboutUs = {};
                                            for (const [k, v] of Object.entries(aboutUs)) {
                                                organization.landingData.aboutUs[k] = v;
                                            }
                                        }
        
                                        organization.landingData.aboutUs.items[item.id].bg = url;
                                        
                                        this.saveChange(organization, organization.landingData);
                                    }, (state, progress) => {
                                        if (state === 'running') {
                                            this.setState({imageProgress: progress});
                                        } else {
                                            this.setState({imageProgress: 0});
                                        }
                                    }, error => {
                                        alert("Saving Image File Failed.\n" + error);
                                        this.setState({
                                            progress: false
                                        });
                                    })
                                }} />
                        </div>
                    </div>
                </div>
            ))
        }

        return (
            <div className=''>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    About Us
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Title </b> | About Us Section Title
                    </div>
                    <div className='form-setting-input'>
                        <Input type="text" name="text" id="customDomain" onChange={e => {
                            if (!organization.landingData) {
                                organization.landingData = {};
                            }
                            if (!organization.landingData.aboutUs) {
                                organization.landingData.aboutUs = {};
                                for (const [k, v] of Object.entries(aboutUs)) {
                                    organization.landingData.aboutUs[k] = v;
                                }
                            }

                            organization.landingData.aboutUs.title = e.target.value;
                            
                            this.setState({organization: organization});
                        }} value={aboutData.title} />
                    </div>
                </div>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    About Us Items
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        <Button className='btn-submit wrap-content-wrap-child' onClick={ e => {
                            if (!organization.landingData) {
                                organization.landingData = {};
                            }
                            if (!organization.landingData.aboutUs) {
                                organization.landingData.aboutUs = {};
                                for (const [k, v] of Object.entries(aboutUs)) {
                                    organization.landingData.aboutUs[k] = v;
                                }
                            }

                            const item = {};
                            item.id = generateId('/organization/' + organization.id + '/landingData/aboutUs/items');
                            item.subtitle = "Subtitle";
                            item.index = Object.values(organization.landingData.aboutUs.items).length + 1;

                            organization.landingData.aboutUs.items[item.id] = item;
                            this.setState({organization: organization});
                        }}>+</Button>
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#A6DC34'}} />
                {items}
                <div className='wrap-content-parent' style={{marginBottom: 20}}>
                    <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                        
                    </h4>
                    
                    {domainProgress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div style={{textAlign: 'end'}}>
                            <Button className='btn-submit' onClick={e => {
                                this.saveChange(organization, organization.landingData);
                            }}>Update</Button>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    onLoadMap (autocomplete) {
        this.autocomplete = autocomplete
    }

    onPlaceChanged () {
        const {organization} = this.state;
        
        if (!organization.landingData) {
            organization.landingData = {};
        }
        if (!organization.landingData.contact) {
            organization.landingData.contact = {};
            for (const [k, v] of Object.entries(contact)) {
                organization.landingData.contact[k] = v;
            }
        }

        if (this.autocomplete !== null) {
            const place = this.autocomplete.getPlace();
            
            if (place != null) {
                organization.landingData.contact.address = {};
                
                organization.landingData.contact.address.place_id = place.place_id;
                if (place.formatted_address != null) {
                    organization.landingData.contact.address.formatted_address = place.formatted_address;
                }
                if (place.formatted_phone_number != null) {
                    organization.landingData.contact.address.formatted_phone_number = place.formatted_phone_number;
                }
                if (place.icon != null) {
                    organization.landingData.contact.address.placeIcon = place.icon;
                }
                if (place.name != null) {
                    organization.landingData.contact.address.placeName = place.name;
                }
                organization.landingData.contact.address.lat = place.geometry.location.lat();
                organization.landingData.contact.address.lng = place.geometry.location.lng();
                this.setState({organization: organization});
            } else {
                organization.landingData.contact.address = null;
                this.setState({organization: organization});
            }
        } else {
            organization.landingData.contact.address = null;
            this.setState({organization: organization});
            console.log('Autocomplete is not loaded yet!')
        }
    }

    renderContactUs() {
        const {organization, domainProgress, imageProgress} = this.state;
        const landingData = (organization && organization.landingData) ? organization.landingData : {};
        const contactData = landingData.contact ? landingData.contact : contact;

        const itemsArr = Object.values(contactData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];
        for (const item of itemsArr) {
            items.push((
                <div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Subtitle </b> | About Us Item Subtitle
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!organization.landingData.contact) {
                                    organization.landingData.contact = {};
                                    for (const [k, v] of Object.entries(contact)) {
                                        organization.landingData.contact[k] = v;
                                    }
                                }

                                organization.landingData.contact.items[item.id].subtitle = e.target.value;
                                
                                this.setState({organization: organization});
                            }} value={item.subtitle} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Text </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!organization.landingData.contact) {
                                    organization.landingData.contact = {};
                                    for (const [k, v] of Object.entries(contact)) {
                                        organization.landingData.contact[k] = v;
                                    }
                                }

                                organization.landingData.contact.items[item.id].text = e.target.value;
                                
                                this.setState({organization: organization});
                            }} value={item.text} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Index </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="number" name="text" id="customDomain" onChange={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!organization.landingData.contact) {
                                    organization.landingData.contact = {};
                                    for (const [k, v] of Object.entries(contact)) {
                                        organization.landingData.contact[k] = v;
                                    }
                                }

                                organization.landingData.contact.items[item.id].index = e.target.value;
                                
                                this.setState({organization: organization});
                            }} value={item.index} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Image</b>
                        </div>
                        <div className='form-setting-input'>
                            <AttachImageEdit
                                imageUrl={item.bg}
                                imageClass='home-edit-thumb'
                                label='Blue Image Url'
                                maxHeight={150}
                                allowProgress={domainProgress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({domainProgress: true});
                                    uploadImageFileToStorage(item.id, 'organization-contact/' + organization.id + '/image', file, (url) => {
                                        if (!organization.landingData) {
                                            organization.landingData = {};
                                        }
                                        if (!organization.landingData.contact) {
                                            organization.landingData.contact = {};
                                            for (const [k, v] of Object.entries(contact)) {
                                                organization.landingData.contact[k] = v;
                                            }
                                        }
        
                                        organization.landingData.contact.items[item.id].bg = url;
                                        
                                        this.saveChange(organization, organization.landingData);
                                    }, (state, progress) => {
                                        if (state === 'running') {
                                            this.setState({imageProgress: progress});
                                        } else {
                                            this.setState({imageProgress: 0});
                                        }
                                    }, error => {
                                        alert("Saving Image File Failed.\n" + error);
                                        this.setState({
                                            progress: false
                                        });
                                    })
                                }} />
                        </div>
                    </div>
                </div>
            ))
        }
        
        const address = contactData.address ? contactData.address : {};
        const locationTxt = address.formatted_address != null ? address.formatted_address : "Input place name for location";

        return (
            <div className=''>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    {t('contact_us')}
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Title </b> | {t('contact_us_title')}
                    </div>
                    <div className='form-setting-input'>
                        <Input type="text" name="text" id="customDomain" onChange={e => {
                            if (!organization.landingData) {
                                organization.landingData = {};
                            }
                            if (!organization.landingData.contact) {
                                organization.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    organization.landingData.contact[k] = v;
                                }
                            }

                            organization.landingData.contact.title = e.target.value;
                            
                            this.setState({organization: organization});
                        }} value={contactData.title} />
                    </div>
                </div>

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Email </b> | Contact email
                    </div>
                    <div className='form-setting-input'>
                        <Input type="email" name="text" id="customDomain" onChange={e => {
                            if (!organization.landingData) {
                                organization.landingData = {};
                            }
                            if (!organization.landingData.contact) {
                                organization.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    organization.landingData.contact[k] = v;
                                }
                            }

                            organization.landingData.contact.email = e.target.value;
                            
                            this.setState({organization: organization});
                        }} value={contactData.email} />
                    </div>
                </div>

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Phone </b> | Contact phone number
                    </div>
                    <div className='form-setting-input'>
                        <Input type="text" name="text" id="customDomain" onChange={e => {
                            if (!organization.landingData) {
                                organization.landingData = {};
                            }
                            if (!organization.landingData.contact) {
                                organization.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    organization.landingData.contact[k] = v;
                                }
                            }

                            organization.landingData.contact.phone = e.target.value;
                            
                            this.setState({organization: organization});
                        }} value={contactData.phone} />
                    </div>
                </div>

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Address </b> | Contact address
                    </div>
                    <div className='form-setting-input'>
                        <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            libraries={["places"]} >
                            <Autocomplete
                                onLoad={this.onLoadMap}
                                onPlaceChanged={this.onPlaceChanged} >
                                <input
                                type="text"
                                placeholder={locationTxt}
                                className="location-input"
                                />
                            </Autocomplete>
                        </LoadScript>
                        <b>{locationTxt}</b>
                    </div>
                </div>

                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    {t('contact_us_items')}
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        <Button className='btn-submit wrap-content-wrap-child' onClick={ e => {
                            if (!organization.landingData) {
                                organization.landingData = {};
                            }
                            if (!organization.landingData.contact) {
                                organization.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    organization.landingData.contact[k] = v;
                                }
                            }

                            const item = {};
                            item.id = generateId('/organization/' + organization.id + '/landingData/contact/items');
                            item.subtitle = "Subtitle";
                            item.index = Object.values(organization.landingData.contact.items).length + 1;

                            organization.landingData.contact.items[item.id] = item;
                            this.setState({organization: organization});
                        }}>+</Button>
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#A6DC34'}} />
                {items}
                <div className='wrap-content-parent' style={{marginBottom: 20}}>
                    <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                        
                    </h4>
                    
                    {domainProgress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div style={{textAlign: 'end'}}>
                            <Button className='btn-submit' onClick={e => {
                                this.saveChange(organization, organization.landingData);
                            }}>Update</Button>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderSponsers() {
        const {organization, sponserChoice, sponserImage, sponserProgress, sponserImageProgress} = this.state;
        const landingData = (organization && organization.landingData) ? organization.landingData : {};

        let items = landingData.sponsers ? Object.values(landingData.sponsers) : [];

        let list = [];

        for (const item of items) {
            list.push(
                <Col sm={4} style={{backgroundColor: 'white'}}>
                    <div className='wrap-content-parent'>
                        <img className='wrap-content-wrap-child' alt="icon" style={{width: 40, height: 30}} src={item.url} />
                        <div className='wrap-content-fill-child'>
                            <h4>{item.title}</h4>
                            <a href={item.link}>Target Site Link</a>
                        </div>
                        <Button className="btn-submit wrap-content-wrap-child" onClick={e => {
                            this.setState({sponserChoice: item});
                        }}>{t('edit')}</Button>
                    </div>
                </Col>
            )
        }

        
        if (sponserChoice) {
            return (
                <div>
                    <ItemsNavigation
                        title="Sponsers"
                        icon={<FcBusiness/>}
                        detailOpen={false}
                        editOpen={true}
                        totalCount={items.length}
                        detailItem={sponserChoice}
                        onClickBack={()=>{
                        this.setState({sponserChoice: null, sponserImage: null});
                        }} />
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Sponser Item</b>
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" placeholder='Sponser Title' onChange={e => {
                                sponserChoice.title = e.target.value;
                                this.setState({sponserChoice: sponserChoice});
                            }} value={sponserChoice.title} />
                            <Input type="text" name="text" id="customDomain" placeholder='Link to site' onChange={e => {
                                sponserChoice.link = e.target.value;
                                this.setState({sponserChoice: sponserChoice});
                            }} value={sponserChoice.link} />
                            <AttachImageEdit
                                imageUrl={sponserChoice.url}
                                imageClass='home-edit-thumb'
                                label='Blue Image Url'
                                maxHeight={150}
                                allowProgress={sponserProgress}
                                progress={sponserImageProgress}
                                onChange={(file)=> {
                                    this.setState({sponserImage: file});
                                }} />
                        </div>
                        <div className='form-setting-control'>
                            {sponserProgress ? 
                            <Spinner className='' /> : 
                            <Button className="btn-submit" onClick={e => {
                                if (!organization.landingData) {
                                    organization.landingData = {};
                                }
                                if (!sponserChoice.url && !sponserImage) {
                                    alert("You have to pick valid image");
                                    return;
                                }
                                this.setState({sponserProgress: true});
                                if (!sponserChoice.id) {
                                    sponserChoice.id = generateId('/organization/' + organization.id + '/landingData/sponsers');
                                }
                                if (sponserImage) {
                                    uploadImageFileToStorage(sponserChoice.id, 'organization/' + organization.id + '/landingData/sponsers', sponserImage, (url) => {
                                        if (!landingData.sponsers) {
                                            landingData.sponsers = {};
                                        }
                                        sponserChoice.url = url;
                                        landingData.sponsers[sponserChoice.id] = sponserChoice;
    
                                        this.saveChange(organization, landingData);
                                    }, (state, progress) => {
                                        if (state === 'running') {
                                            this.setState({sponserImageProgress: progress});
                                        } else {
                                            this.setState({sponserImageProgress: 0});
                                        }
                                    }, error => {
                                        alert("Saving Image File Failed.\n" + error);
                                        this.setState({
                                            sponserProgress: false
                                        });
                                    })
                                } else {
                                    landingData.sponsers[sponserChoice.id] = sponserChoice;

                                    this.saveChange(organization, landingData);
                                }
                            }}>{t('save')}</Button>
                            }
                        </div>
                        <div className='form-setting-control'>
                            <Button className="btn-cancel" onClick={e => {
                                this.setState({sponserChoice: null, sponserImage: null});
                            }}>{t('close')}</Button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <ItemsNavigation
                        title="Sponsers"
                        icon={<FcBusiness/>}
                        detailOpen={false}
                        editOpen={false}
                        totalCount={items.length}
                        detailItem={{}}
                        onClickAdd={()=>{
                            this.setState({sponserChoice: {}, sponserImage: null});
                        }}
                        onClickBack={()=>{
                            this.setState({sponserChoice: null, sponserImage: null});
                        }} />
                    <Row>
                    {list}
                    </Row>
                </div>
            );
        }
    }

    saveLandingChange(organization) {
        const landingData = (organization && organization.landingData) ? organization.landingData : {};

        if (!landingData.services) {
            landingData.services = {};
        }
        
        invokeHttpsApi('organizerQuery-submitOrganizationLandingData', {
            organizationId: organization.id,
            landingData: landingData,
        }, (data) => {
            this.loadContents();
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
        });
    }

    saveDomainChange(organization, customDomain) {
        this.setState({domainProgress: true});
        invokeHttpsApi('organizerQuery-submitOrganizationCustomDomain', {
            organizationId: organization.id,
            customDomain: customDomain,
        }, (data) => {
            if (data && data.success) {
                organization.customDomain = customDomain;
                alert("Saving custom domain update success.");
                this.setState({organization: organization, domainProgress: false});
            } else {
                alert("Saving custom domain update failed. Please type valid one.");
                this.setState({domainProgress: false});
            }
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
            this.setState({domainProgress: false});
        });
    }

    saveChange(organization, landingData) {
        this.setState({domainProgress: true});
        invokeHttpsApi('organizerQuery-submitOrganizationLandingData', {
            organizationId: organization.id,
            landingData: landingData,
        }, (data) => {
            if (data && data.success) {
                alert("Saving custom domain update success.");
                organization.landingData = landingData;
                this.setState({organization: organization, domainProgress: false, sponserProgress: false});
            } else {
                alert("Saving custom domain update failed. Please type valid one.");
                this.setState({domainProgress: false, sponserProgress: false});
            }
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
            this.setState({domainProgress: false, sponserProgress: false});
        });
    }

    renderContents() {
        const {details} = this.state;
        if (details == null) {
            return (
                <div>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaWpforms/></div>
                                <h3 className='items-navigation-title'>
                                Organization Payment Method Settings
                                </h3>
                            </div>
                        </div>
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.setState({details: 'payment'})
                        }}>Details</Button>
                    </div>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaWpforms/></div>
                                <h3 className='items-navigation-title'>
                                Organization Registration Form Settings
                                </h3>
                            </div>
                        </div>
                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.setState({details: 'form'})
                        }}>Details</Button>
                    </div>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaWpforms/></div>
                                <h3 className='items-navigation-title'>
                                Organization Policy Settings
                                </h3>
                            </div>
                        </div>
                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.setState({details: 'policy'})
                        }}>Details</Button>
                    </div>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaWpforms/></div>
                                <h3 className='items-navigation-title'>
                                Organization Enroll Settings
                                </h3>
                            </div>
                        </div>
                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.setState({details: 'enroll'})
                        }}>Details</Button>
                    </div>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaWpforms/></div>
                                <h3 className='items-navigation-title'>
                                Organization Landing Page Settings
                                </h3>
                            </div>
                        </div>
                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.setState({details: 'landing'})
                        }}>Details</Button>
                    </div>
                </div>
            )
        } else if (details === 'policy') {
            return (
                <div>
                    {this.renderPolicySettings()}
                </div>
            )
        } else if (details === 'payment') {
            return (
                <div>
                    {this.renderPaymentSettings()}
                </div>
            )
        } else if (details === 'form') {
            return (
                <div>
                    {this.renderFormSettings()}
                </div>
            )
        } else if (details === 'enroll') {
            return (
                <div>
                    {this.renderOtherSettings()}
                </div>
            )
        } else if (details === 'landing') {
            return (
                <div>
                    {this.renderLandingSettings()}
                </div>
            )
        } else {
            return <div />
        }
    }

    render() {
        const {organization, loading, mainTab} = this.state;

        return (
            <div>
                <Box sx={mainTabStyle}>
                    <Tabs
                        value={mainTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                        onChange={(e, value)=> {
                            this.setState({mainTab: value})
                        }}
                        aria-label="disabled tabs example"
                    >
                        <Tab label={t('general')} />
                        <Tab label={t('claims')} />
                        <Tab label={t('support')} />
                    </Tabs>
                </Box>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                    {mainTab === 0 && (
                        this.renderContents()
                    )}
                    {mainTab === 1 && (
                        <OrganizationClaimsView
                            organization={organization} />
                    )}
                    {mainTab === 2 && (
                        <OrganizationSupportView
                            organization={organization} />
                    )}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizerSubscription } = state.subscriptions;
    return { alert, loggingIn, user, organizerSubscription };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(SettingsPage));
export { connectedApp as SettingsPage };
