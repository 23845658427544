import React, { Component } from "react";
import {
    CardImg, Row, Col, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import {
    GrCalendar
} from 'react-icons/gr'
import { getSeasonRoundsArr, getSubSeasonTable } from 'utils/Utils';

import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import playersIcon from 'assets/img/icons/players_icon.png';
import GameBadge from 'components/detail/organization/landing/GameBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import { STATUS_FINISHED, STATUS_ONGOING } from "utils/constants";

export class OrganizationTeamStandings extends Component {
    constructor(props) {
        super(props);
        let seasonChoice = {};
        if (props.seasons != null) {
            for (const val of Object.values(props.seasons)) {
                if ((!props.organization || val.organizationId === props.organization.id) && val.teamIds != null && Object.values(val.teamIds).includes(props.team.id)) {
                    seasonChoice = val;
                    if (val.status !== STATUS_FINISHED) {
                        break;
                    }
                }
            }
        }
        
        const subSeasons = seasonChoice.subSeasons != null ? seasonChoice.subSeasons : {};
        let choice = {};
        for (const item of Object.values(subSeasons)) {
            if (item.status === STATUS_ONGOING) {
                choice = item;
                break;
            }
        }
        if (!choice.id && getSeasonRoundsArr(seasonChoice).length > 0) {
            choice = getSeasonRoundsArr(seasonChoice)[0];
        }
        
        this.state = {
            subSeasonChoice: choice,
            matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1},
            seasonChoice: seasonChoice,
            gameChoice: {},
            editOpen: false,
            saveProgress: false,
        }
    }
    
    renderBadge(user, rank, num1, num2, num3) {
        let thumbImage = user && user.photoUri != null ? user.photoUri : playersIcon;

        return (
            <Row>
                <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num1 ? num1 : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num2 ? num2 : 0}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {num3 ? num3 : 0}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderStandings() {
        const {seasonChoice} = this.state;

        let items = [];
        for (const value of Object.values(seasonChoice.subSeasons != null ? seasonChoice.subSeasons : {})) {
            
            items.push(
                this.renderSubSeasonStandings(value)
            );
        }
        return (
        <div>
            {items}
        </div>
        );
    }

    renderSubSeasonStandings(subSeason) {
        const {divisions, teams} = this.props;
        const division = divisions[subSeason.divisionId] ? divisions[subSeason.divisionId] : {};

        let pointTable = getSubSeasonTable(division, subSeason);
        let pointItems = Object.values(pointTable);
        pointItems.sort((x,y)=>{
            const a = teams[x.id] != null ? teams[x.id] : {};
            const b = teams[y.id] != null ? teams[y.id] : {};
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        })
        pointItems.sort((a,b)=> {
            return b.points - a.points;
        })

        let items = [];
        let rank = 0;
        for (const val of pointItems) {
            rank ++;
            items.push(
                this.renderStandingItem(val, rank)
            )
            items.push(<div className='divider' />);
        }
        
        return (
            <div style={{marginTop: 20, marginBottom: 20}}>
                <div className='shadow-tile'>
                    <Row>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                Standing of {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <Col lg={2} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={5} xs={5} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                {t('team')}
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Pts
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                W
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                L
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                G
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                GP
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderStandingItem(item, rank) {
        const {teams} = this.props;
        const team = teams[item.id] != null ? teams[item.id] : {};
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;

        return (
            <Row style={{margin: 0}}>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {rank}
                        </div>
                    </div>
                </Col>
                <Col lg={5} xs={5} sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.points}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.win}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.loss}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.scores}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {item.gamePlayed}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderGames() {
        const {organization, organizations, divisions, seasons, teams, users, cards, team, locations, posts, onChanged} = this.props;
        const {seasonChoice, gameChoice, subSeasonChoice, matchDayChoice} = this.state;

        let gameArr = [];
        
        if (subSeasonChoice.games != null) {
            for (const game of Object.values(subSeasonChoice.games)) {
                if (matchDayChoice.value === -1 || matchDayChoice.value === game.matchDayIndex) {
                    if (team.id === game.homeTeam || team.id === game.awayTeam) {
                        gameArr.push(game)
                    }
                }
            }
        }
        
        const gameDays = {};
        if (subSeasonChoice.matchDayCount != null) {
            const val = {id: t('all_matchdays'), title: t('all_matchdays'), value: -1}
            gameDays[t('all_matchdays')] = val;
            for (var i = 0; i < subSeasonChoice.matchDayCount; i ++) {
                const title = t('matchday') + " " +(i + 1).toString();
                const day = {id: title, title: title, value: i}
                gameDays[title] = day;
            }
        }

        gameArr.sort(function(a, b) {
            return a.start - b.start;
        })
        
        return (
            <div style={{marginTop: 20}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><GrCalendar/></div>
                            <h3 className='items-navigation-title'>
                            Games
                            </h3>
                            <div className='items-navigation-count'>| {gameArr.length}</div>
                        </div>
                    </div>
                    {gameChoice.id && (
                    <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({gameChoice: {}})
                    }}>{t('back')}</Button>
                    )}
                    
                </div>
                {!gameChoice.id && (
                <div className='shadow-tile' style={{overflow: 'visible'}}>
                    <ItemsDrowpdownNav
                        choiceTitle={t('choose_round')}
                        title={t('season_rounds')}
                        dropDownArray={getSeasonRoundsArr(seasonChoice)}
                        editOpen={false}
                        detailOpen={false}
                        detailItem={subSeasonChoice}
                        onItemChoice={(gamesChoice)=>{
                            this.setState({subSeasonChoice: gamesChoice, detailOpen: false, editOpen: false})
                        }} />
                    {Object.values(gameDays).length > 0 && (
                    <ItemsDrowpdownNav
                        choiceTitle="Choose a Matchday"
                        title="Matchday"
                        dropdownItems={gameDays}
                        detailOpen={matchDayChoice.value === -1}
                        detailItem={matchDayChoice}
                        onItemChoice={(update)=>{
                            this.setState({matchDayChoice: update})
                        }} />
                    )}
                    <div className='divider' />
                    {gameArr.length > 0 ? (
                        <div>
                        {gameArr.map((game,idx)=>(
                            <div key={idx} onClick={e=>{
                                this.setState({gameChoice: game})
                            }}>
                                <GameBadge
                                    game={game}
                                    teams={teams}
                                    locations={locations}
                                    organization={organization ? organization : organizations[game.organizationId]}
                                    detailMode={true}/>
                            </div>
                        ))}
                        </div>
                    ) : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
                )}
                {gameChoice.id && (
                    <GameDetails 
                        game={gameChoice}
                        organization={organization ? organization : organizations[gameChoice.organizationId]}
                        locations={locations}
                        divisions={divisions}
                        seasons={seasons}
                        cards={cards}
                        teams={teams}
                        posts={posts}
                        users={users} 
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }}
                        onClickClose={()=>{
                            this.setState({gameChoice: {}, detailOpen: false, editOpen: false});
                        }} />
                )}
            </div>
        );
    }

    render() {
        const {organization, seasons, team} = this.props;
        const {seasonChoice} = this.state;
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if ((!organization || value.organizationId === organization.id) && value.teamIds != null && Object.values(value.teamIds).includes(team.id)) {
                seasonsChoice[key] = value;
            }
        }
        return (
            <div className="">
                <ItemsDrowpdownNav
                    choiceTitle={t('choose_season')}
                    title={t('seasons')}
                    dropdownItems={seasonsChoice}
                    editOpen={false}
                    detailOpen={true}
                    detailItem={seasonChoice}
                    onItemChoice={(update)=>{
                        this.setState({seasonChoice: update})
                    }} />
                {seasonChoice && seasonChoice.id && (
                <Row style={{margin: 0}}>
                    <Col sm={6} xs={12} style={{padding: 0}}>
                        {this.renderStandings()}
                    </Col>
                    <Col sm={6} xs={12} style={{padding: 0}}>
                        {this.renderGames()}
                    </Col>
                </Row>
                )}
            </div>
        );
    }
}

export default withTranslation()(OrganizationTeamStandings);