import React, { Component } from "react";
import {
    Card, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { toDateTimeString } from 'utils/Utils';

export class SeasonBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {season, onClickDetails, onClickEdit, allowDetail } = this.props;
        let teamCount = season.teamIds == null ? 0 : Object.values(season.teamIds).length;
        let roundCount = season.subSeasons == null ? 0 : Object.values(season.subSeasons).length;
        let startDate = toDateTimeString(season.startDate);
        let regDeadline = toDateTimeString(season.regDeadline);
        
        if (season != null) {
            return (
                <Card className="flex-row shadow-tile">
                    <Row className='season-badge'>
                        <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                            <div className='wrap-content-parent' style={{marginLeft: 20}}>
                                <h4 className='wrap-content-wrap-child one-line-title' style={{marginRight: 15}}>
                                {season.title}
                                </h4>
                                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                <b>{t('teams')}:</b> {teamCount},
                                </div>
                                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                <b>{t('season_rounds')}:</b> {roundCount},
                                </div>
                                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                <b>Start:</b> {startDate},
                                </div>
                                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                <b>Registragion:</b> {regDeadline},
                                </div>
                            </div>
                        </Col>
                        <Col nm={4} xs={12} sm={6}>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                    {allowDetail && (
                                        <Button className="btn-submit" onClick={e => {
                                            if (onClickDetails != null) {
                                                onClickDetails();
                                            }
                                        }}>Details</Button>
                                    )}
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SeasonBadge);