import React, { Component } from "react";
import { t } from "i18next";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { STATE_LOGIN } from 'components/auth/AuthForm';
import { getOrgDomain, getOrgLocation } from 'utils/Utils';
import userIcon from 'assets/img/icons/player_photo_default.png';
import { CardImg } from 'reactstrap';
import { withRouter } from "utils/Routher";

class SambaNavigation extends Component {
    
    render() {
        const {organization, user, child} = this.props;
        
        const homeLink = getOrgDomain(organization);
        const location = getOrgLocation(this.props);
        const servicesLink = homeLink + '/services';
        const championsLink = homeLink + '/championships';
        const accountLink = homeLink + '/account';
        // const eventsLink = homeLink + '/events';
        // const programsLink = homeLink + '/programs';
        const teamsLink = homeLink + '/teams';
        const newsLink = homeLink + '/news';
        // const fundLink = homeLink + '/fund';
        // const aboutLink = homeLink + '/about';
        // const contactLink = homeLink + '/contact';
        const managerLink = homeLink + '/manager';
        
        const actionBg = organization && organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : 'white';

        // const isContents = location === 'champions' || location === 'program' || location === 'events' || location === 'teams' || location === 'fundraises';
        // const isMore = location === 'about' || location === 'contact';
        const isAccount = location === 'account';

        // const contentsClass = "dropdown-toggle " + (isContents ? "org-a-active" : "org-a");
        // const moreClass = "dropdown-toggle " + (isMore ? "org-a-active" : "org-a");
        const accountClass = "dropdown-toggle " + (isAccount ? "org-a-active" : "org-a");
        
        const choice = child ? child : user;

        const userThumb = choice && choice.photoUri ? choice.photoUri : userIcon;
        /*let title = t('contents');
        if (location === 'champions') {
            title = t('championships');
        } else if (location === 'program') {
            title = t('programs');
        } else if (location === 'events') {
            title = t('events');
        } else if (location === 'teams') {
            title = t('teams');
        } else if (location === 'fundraises') {
            title = t('fundraises');
        }
        let moreTitle = t('more');
        if (location === 'about') {
            moreTitle = t('about_us');
        } else if (location === 'contact') {
            moreTitle = t('contact_us');
        }*/

        return (
            <div style={{backgroundColor: actionBg, padding: '0px 10px'}}>
                <div className='samba-landing-header'>
                    <ul className='nav samba-landing-links'>
                        <li className='samba-landing-li'>
                            <button 
                            onClick={e=>{this.props.router.navigate(homeLink)}} 
                            style={location === 'home' ? {borderColor: actionColor} : {}}
                            className={location === 'home' ? "org-a-active" : "org-a"}>
                            {t('home')}
                            </button>
                        </li>
                        <li className='samba-landing-li'>
                            <button 
                            onClick={e=>{this.props.router.navigate(championsLink)}} 
                            style={location === 'champions' ? {borderColor: actionColor} : {}}
                            className={location === 'champions' ? "org-a-active" : "org-a"}>
                            {t('championships')}
                            </button>
                        </li>
                        {choice && (
                        <li className='samba-landing-li'>
                            <button 
                            onClick={e=>{this.props.router.navigate(servicesLink)}} 
                            style={location === 'services' ? {borderColor: actionColor} : {}}
                            className={location === 'services' ? "org-a-active" : "org-a"}>
                                {t('registration')}
                            </button>
                        </li>
                        )}
                        <li className='samba-landing-li'>
                            <button 
                            onClick={e=>{this.props.router.navigate(newsLink)}} 
                            style={location === 'news' ? {borderColor: actionColor} : {}}
                            className={location === 'news' ? "org-a-active" : "org-a"}>
                            {t('news')}
                            </button>
                        </li>
                        <li className='samba-landing-li'>
                            <button 
                            onClick={e=>{this.props.router.navigate(teamsLink)}} 
                            style={location === 'teams' ? {borderColor: actionColor} : {}}
                            className={location === 'teams' ? "org-a-active" : "org-a"}>
                            {t('teams')}
                            </button>
                        </li>
                        
                        {user && organization && organization.managers && Object.values(organization.managers).includes(user.uid) && (
                        <li className='samba-landing-li'>
                            <button 
                            onClick={e=>{this.props.router.navigate(managerLink)}} 
                            style={location === 'manager' ? {borderColor: actionColor} : {}}
                            className={location === 'manager' ? "org-a-active" : "org-a"}>
                            {t('manager')}
                            </button>
                        </li>
                        )}
                        {choice && (
                        <li className='samba-landing-li'>
                            <div className="dropdown">
                                <button 
                                    className={accountClass} 
                                    data-bs-toggle="dropdown" 
                                    style={isAccount ? {borderColor: actionColor} : {}}
                                    href={accountLink}>
                                    <CardImg
                                    style={{width: 24, height: 24, marginRight: 10}}
                                    src={userThumb} />
                                    {choice.username}
                                </button>
                                <ul className="dropdown-menu" role="menu" aria-labelledby="dLabel" 
                                    style={{backgroundColor: actionBg ? actionBg : 'rgba(0,0,0,.5)', color: actionColor ? actionColor : 'white'}}>       
                                    <li className="dropdown-li">
                                        <button 
                                        onClick={e=>{this.props.router.navigate(accountLink)}} 
                                        style={location === 'account' && actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'white'} : {}}
                                        className={location === 'account' ? "org-a-active" : "org-a"}>
                                            {t('my_account')}
                                        </button>
                                    </li>
                                    {user && user.uid === organization.owner && (
                                    <li className="dropdown-li">
                                        <button 
                                        onClick={e=>{this.props.router.navigate('/organizer')}} 
                                        className="org-a">
                                            {t('dashboard')}
                                        </button>
                                    </li>
                                    )}
                                    <button style={{background: 'transparent', border: 'none', color: actionColor ? actionColor : 'white'}} className={""} onClick={e=> {
                                        this.props.logout();
                                    }}>
                                        Logout
                                    </button>
                                </ul>
                            </div>   
                        </li>
                        )}
                        {(!user || !user.username) && (
                            <li className="samba-landing-li">
                                <button className="auth-btn" style={actionBg ? {backgroundColor: actionBg} : {}} onClick={e=> {
                                    if (this.props.onAuthUpdate) {
                                        this.props.onAuthUpdate(true, STATE_LOGIN);
                                    }
                                }}>
                                    {t('login')}
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, child, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, child, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = withRouter(connect(mapState, actionCreators)(SambaNavigation));
export { connectedNavigation as SambaNavigation };
