import React, { Component } from "react";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { DAY_TIME, getDayStart } from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";

class TeamToDo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            wallet: {},
            walletPayments: {},
            users: {},
            refreshTime: 0,
            balanceType: 'direct'
        }
        this.timer = 0;
        this.countDown = this.countDown.bind(this);
    }

    componentDidMount() {
        const { user, team } = this.props;
        this.timer = setInterval(this.countDown, 1000);
        const uid = user ? user.uid : null;
        if (team.owner === uid || (team.managers && Object.values(team.managers).includes(uid))) {
            this.loadCoachContents();
        } else if (team.playerIds && Object.values(team.playerIds).includes(uid)) {
            this.loadPlayerContents();
        }
    }

    componentDidUpdate(prevProps) {
        const { user, team, loggingIn } = this.props;
        if (user && user.uid && loggingIn === false && prevProps.loggingIn === true) {
            const uid = user ? user.uid : null;
            if (team.owner === uid || (team.managers && Object.values(team.managers).includes(uid))) {
                this.loadCoachContents();
            } else if (team.playerIds && Object.values(team.playerIds).includes(uid)) {
                this.loadPlayerContents();
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    countDown() {
        this.setState({curTime: (new Date().valueOf())})
    }
    
    loadPlayerContents() {
        invokeHttpsApi("clubQuery-playerToDoQuery", {}, (data) => {
            const invites = {};
            const clubSchedules = {};
            const managerInvites = {};
            const clubBattles = {};
            const clubChallenges = {};
            const clubChallengeSubmissions = {};
            const clubFields = {};
            const clubTracks = {};
            const clubTrackSubmissions = {};
            const clubGames = {};
            const friendlyGames = {};
            const organizations = {};
            const divisions = {};
            const seasons = {};
            const locations = {};
            const profileData = {};
            const users = {};
            const category = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.invites)) {
                    invites[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.managerInvites)) {
                    managerInvites[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubBattles)) {
                    clubBattles[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubChallenges)) {
                    clubChallenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubChallengeSubmissions)) {
                    clubChallengeSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubFields)) {
                    clubFields[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubTracks)) {
                    clubTracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubTrackSubmissions)) {
                    clubTrackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubGames)) {
                    clubGames[key] = value;
                }
                for (const [key, value] of Object.entries(data.friendlyGames)) {
                    friendlyGames[key] = value;
                }
            }

            this.setState({
                refreshTime: new Date().valueOf(),
                invites: invites,
                managerInvites: managerInvites,
                clubSchedules: clubSchedules,
                clubBattles: clubBattles,
                clubChallenges: clubChallenges,
                clubChallengeSubmissions: clubChallengeSubmissions,
                clubFields: clubFields,
                clubTracks: clubTracks,
                clubTrackSubmissions: clubTrackSubmissions,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                clubGames: clubGames,
                friendlyGames: friendlyGames,
                profileData: profileData,
                locations: locations,
                users: users,
                category: category,
                loading: false
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    loadCoachContents() {
        invokeHttpsApi('clubQuery-coachToDoQuery', {}, (data) => {
            const invites = {};
            const clubSchedules = {};
            const managerInvites = {};
            const clubBattles = {};
            const clubChallenges = {};
            const clubChallengeSubmissions = {};
            const clubFields = {};
            const clubTracks = {};
            const clubTrackSubmissions = {};
            const clubGames = {};
            const friendlyGames = {};
            const organizations = {};
            const divisions = {};
            const seasons = {};
            const locations = {};
            const profileData = {};
            const users = {};
            const category = {};
            const games = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.invites)) {
                    invites[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.managerInvites)) {
                    managerInvites[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubBattles)) {
                    clubBattles[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubChallenges)) {
                    clubChallenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubChallengeSubmissions)) {
                    clubChallengeSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubFields)) {
                    clubFields[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubTracks)) {
                    clubTracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubTrackSubmissions)) {
                    clubTrackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubGames)) {
                    clubGames[key] = value;
                }
                for (const [key, value] of Object.entries(data.friendlyGames)) {
                    friendlyGames[key] = value;
                }
            }

            this.setState({
                refreshTime: new Date().valueOf(),
                invites: invites,
                managerInvites: managerInvites,
                clubSchedules: clubSchedules,
                clubBattles: clubBattles,
                clubChallenges: clubChallenges,
                clubChallengeSubmissions: clubChallengeSubmissions,
                clubFields: clubFields,
                clubTracks: clubTracks,
                clubTrackSubmissions: clubTrackSubmissions,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                games: games,
                clubGames: clubGames,
                friendlyGames: friendlyGames,
                profileData: profileData,
                locations: locations,
                users: users,
                category: category,
                loading: false
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    renderCoach() {
        const { team } = this.props;
        const { clubChallengeSubmissions, clubTrackSubmissions, clubSchedules, clubGames, friendlyGames, games} = this.state;

        var totalCount = 0;
        var teamPendingCount = 0;
        if (team.pending) {
            totalCount += Object.values(team.pending).length;
            teamPendingCount += Object.values(team.pending).length;
        }
        if (team.managerPending) {
            totalCount += Object.values(team.managerPending).length;
            teamPendingCount += Object.values(team.managerPending).length;
        }
        const dayStart = getDayStart(new Date().valueOf());
        const dayEnd = dayStart + DAY_TIME;

        
        var todayScheduleCount = 0;
        if (clubSchedules) {
            for (const value of Object.values(clubSchedules)) {
                if (value.deadline >= dayStart && value.deadline <= dayEnd && value.clubId === team.id) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }
        if (clubGames) {
            for (const value of Object.values(clubGames)) {
                if (value.start >= dayStart && value.start <= dayEnd && (value.homeTeam === team.id || value.awayTeam === team.id)) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }
        if (friendlyGames) {
            for (const value of Object.values(friendlyGames)) {
                if (value.start >= dayStart && value.start <= dayEnd && (value.homeTeam === team.id || value.awayTeam === team.id)) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }
        if (games) {
            for (const value of Object.values(games)) {
                if (value.start >= dayStart && value.start <= dayEnd && (value.homeTeam === team.id || value.awayTeam === team.id)) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }

        var trainingCount = 0;
        if (clubChallengeSubmissions) {
            for (const value of Object.values(clubChallengeSubmissions)) {
                if (value.clubId === team.id && (value.rating === -1 || !value.rating)) {
                    totalCount ++;
                    trainingCount ++;
                }
            }
        }
        if (clubTrackSubmissions) {
            for (const value of Object.values(clubChallengeSubmissions)) {
                if (value.clubId === team.id && (value.rating === -1 || !value.rating)) {
                    totalCount ++;
                    trainingCount ++;
                }
            }
        }

        return (
            <div>
                <div className="to-do-title">
                    <span className="to-do-num">{totalCount}</span>{t('to_do_title')}
                </div>
                {teamPendingCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{teamPendingCount}</span>{t('to_do_team_pending')}
                </div>
                )}
                {todayScheduleCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{todayScheduleCount}</span>{t('to_do_team_today_schedule')}
                </div>
                )}
                {trainingCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{trainingCount}</span>{t('to_do_team_training')}
                </div>
                )}
            </div>
        )
    }

    checkProfile(orgId, uid) {
        const { profileData } = this.state;
        if (profileData) {
            for (const item of Object.values(profileData)) {
                if (item.uid === uid && item.organizationId === orgId) {
                    return true;
                }
            }
        }
        return false;
    }

    checkMissing(value, uid) {
        const { clubChallengeSubmissions } = this.state;

        if (clubChallengeSubmissions && value.challengeIds) {
            for (const item of Object.values(clubChallengeSubmissions)) {
                if (item.uid === uid && Object.values(value.challengeIds).includes(item.challengeId)) {
                    return true;
                }
            }
        }
        return false;
    }

    checkTrackMissing(value, uid) {
        const { clubTrackSubmissions } = this.state;

        if (clubTrackSubmissions && value.trackIds) {
            for (const item of Object.values(clubTrackSubmissions)) {
                if (item.uid === uid && Object.values(value.trackIds).includes(item.trackId)) {
                    return true;
                }
            }
        }
        return false;
    }

    renderPlayer() {
        const { team, user, child } = this.props;
        const { invites, organizations, seasons, clubChallengeSubmissions, clubTrackSubmissions, clubSchedules, clubGames, friendlyGames, games} = this.state;

        const uid = child ? child.uid : (user ? user.uid : null);
        var totalCount = 0;
        var inviteCount = 0;
        if (invites) {
            for (const value of Object.values(invites)) {
                if (value.uid === uid && value.teamId === team.id) {
                    totalCount ++;
                    inviteCount ++;
                }
            }
        }
        var profileCount = 0;
        if (organizations) {
            for (const value of Object.values(organizations)) {
                if (value.teamData && value.teamData[team.id] && value.teamData[team.id].roaster && value.teamData[team.id].roaster[uid] && !this.checkProfile(value.id, uid)) {
                    totalCount ++;
                    profileCount ++;
                }
            }
        }
        if (seasons) {
            for (const value of Object.values(seasons)) {
                if (value.teamData && value.teamData[team.id] && value.teamData[team.id].roaster && value.teamData[team.id].roaster[uid] && !this.checkProfile(value.organizationId, uid)) {
                    totalCount ++;
                    profileCount ++;
                }
            }
        }
        
        const dayStart = getDayStart(new Date().valueOf());
        const dayEnd = dayStart + DAY_TIME;

        
        var todayScheduleCount = 0;
        if (clubSchedules) {
            for (const value of Object.values(clubSchedules)) {
                if (value.deadline >= dayStart && value.deadline <= dayEnd && value.clubId === team.id) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }
        if (clubGames) {
            for (const value of Object.values(clubGames)) {
                if (value.start >= dayStart && value.start <= dayEnd && (value.homeTeam === team.id || value.awayTeam === team.id)) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }
        if (friendlyGames) {
            for (const value of Object.values(friendlyGames)) {
                if (value.start >= dayStart && value.start <= dayEnd && (value.homeTeam === team.id || value.awayTeam === team.id)) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }
        if (games) {
            for (const value of Object.values(games)) {
                if (value.start >= dayStart && value.start <= dayEnd && (value.homeTeam === team.id || value.awayTeam === team.id)) {
                    totalCount ++;
                    todayScheduleCount ++;
                }
            }
        }

        var trainingCount = 0;
        if (clubChallengeSubmissions) {
            for (const value of Object.values(clubChallengeSubmissions)) {
                if (value.clubId === team.id && !this.checkMissing(value, uid)) {
                    totalCount ++;
                    trainingCount ++;
                }
            }
        }
        if (clubTrackSubmissions) {
            for (const value of Object.values(clubChallengeSubmissions)) {
                if (value.clubId === team.id && !this.checkTrackMissing(value, uid)) {
                    totalCount ++;
                    trainingCount ++;
                }
            }
        }

        return (
            <div>
                <div className="to-do-title">
                    <span className="to-do-num">{totalCount}</span>{t('to_do_title')}
                </div>
                {inviteCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{inviteCount}</span>{t('to_do_team_invites')}
                </div>
                )}
                {profileCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{profileCount}</span>{t('to_do_team_profile')}
                </div>
                )}
                {todayScheduleCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{todayScheduleCount}</span>{t('to_do_team_today_schedule')}
                </div>
                )}
                {trainingCount > 0 && (
                <div className="to-do-item">
                    <span className="to-do-num">{trainingCount}</span>{t('to_do_team_training')}
                </div>
                )}
            </div>
        )
    }

    render() {
        const {user, team, loading, onClickCoach, onClickPlayer } = this.props;
        const {curTime, refreshTime} = this.state;

        const uid = user ? user.uid : null;
        if (loading) {
            return <div/>
        }
        if (curTime - refreshTime > 15000) {
            return <div/>
        }
        
        if (team.owner === uid || (team.managers && Object.values(team.managers).includes(uid))) {
            return (
                <div className="org-register-layout text-center" onClick={e=> {
                    if (onClickCoach) {
                        onClickCoach();
                    }
                }}>
                    {this.renderCoach()}
                </div>
            );
        } else if (team.playerIds && Object.values(team.playerIds).includes(uid)) {
            return (
                <div className="org-register-layout text-center" onClick={e=> {
                    if (onClickPlayer) {
                        onClickPlayer();
                    }
                }}>
                    {this.renderPlayer()}
                </div>
            );
        } else {
            return <div/>
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = connect(mapState, actionCreators)(TeamToDo);
export { connectedNavigation as TeamToDo };
