import { 
    getStorage, 
    getDownloadURL,
    ref
} from "firebase/storage";

export const getStorageUrl = (path, success, failure) => {
    getDownloadURL(ref(getStorage(), path))
    .then((url)=> {
        success(url);
    })
    .catch((error) => {
        if (failure) {
            failure(error);
        }
    });
}
