import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';
import ClubTracksCollection from 'components/detail/club/ClubTracksCollection';
import ClubTrackReviewCollection from 'components/detail/club/ClubTrackReviewCollection';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';

export class ClubFieldDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, value) {
        this.setState({value: value});
    }

    renderContents() {
        const {user, clubField} = this.props;
        const {value} = this.state;

        switch (value) {
            case 0:
                return (
                    <ClubTracksCollection
                        user={user}
                        clubField={clubField} />
                );
            default:
                return (
                    <ClubTrackReviewCollection 
                        uid={user.uid}
                        clubField={clubField} />
                );
        }
    }

    render() {
        const {clubField} = this.props;
        const {value} = this.state;
        
        let trackImage = clubField.iconUri != null ? clubField.iconUri : defaultImage;
        
        if (clubField != null) {
            return (
                <div>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={trackImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {clubField.title}
                            </h4>
                            <div>Members: {clubField.playerIds != null ? Object.values(clubField.playerIds).length : 0}</div>
                        </div>
                    </div>
                    
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={value}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                            onChange={this.handleChange} >

                            <Tab label={t('tracks')} />
                            <Tab label={t('review')} />
                        </Tabs>
                    </Box>

                    {this.renderContents()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ClubFieldDetailView;