import React, { Component } from "react";
import {
    Button, Input, Row, Col, CardImg
} from 'reactstrap';
import { t } from 'i18next';
import { MdWarning } from 'react-icons/md';
import { 
    RiDeleteBin2Line
} from 'react-icons/ri';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import { getWeekStartDay, WEEK_TIME } from 'utils/Utils';
import UserChoice from 'components/edit/UserChoice';
import MembersChoice from 'components/members/MembersChoice';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';
import defaultLocationImage from 'assets/img/icons/league_default.png';

export class GameScheduleBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    getTeamIcon(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].iconUri != null) {
            return teams[teamId].iconUri;
        }
        let teamData = organization.teamData != null && organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        if (teamData.thumbUri != null) {
            return teamData.thumbUri;
        }

        return defaultTeamImage;
    }

    getTeamTitle(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].title != null) {
            return teams[teamId].title;
        }
        let teamData = organization.teamData != null && organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        if (teamData.nickname != null) {
            return teamData.nickname;
        }

        return 'Unknown';
    }

    getLocation(locationId, locations) {
        const result = {title: 'Unknown', thumbUri: defaultLocationImage};
        if (locations[locationId] != null && locations[locationId].title != null) {
            result.title = locations[locationId].title;
        }
        if (locations[locationId] != null && locations[locationId].thumbUri != null) {
            result.thumbUri = locations[locationId].thumbUri;
        }

        return result;
    }

    render() {
        const {locations, game, teams, organization, group, division, season, 
            subSeason, users, onHomeUpdate, onAwayUpdate, gameTimeUpdate, 
            locationUpdate, refereeUpdate, removeClicked} = this.props;

        const referees = {};
        for (const [key, value] of Object.entries(users)) {
            if (organization.refereeIds != null && Object.values(organization.refereeIds).includes(key)) {
                referees[key] = value;
            }
        }
        let startWeek = getWeekStartDay(season.startTime);
        let teamIds = group != null ? group.teamIds : season.teamIds;
        
        if (game != null) {
            return (
                <div>
                    <Row>
                        <Col lg={1} sm={12} xs={2} className="text-center">
                            <div className='game-badge-calendar'>
                                <div className='game-badge-weekday'>
                                    {new Date(game.start).toLocaleString('default', { weekday: 'short' })}
                                </div>
                                <div className='game-badge-week'>
                                    Wk. {parseInt((game.start - startWeek) / WEEK_TIME) + 1}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(game.homeTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.homeTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (onHomeUpdate != null) {
                                        onHomeUpdate(e.target.value)
                                    }
                                }}>
                                    {Object.values(teamIds).map((teamId,i)=>(
                                        <option key={teamId} value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}>{t('start')} </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        onChange={(val) => {
                                            if (gameTimeUpdate != null) {
                                                gameTimeUpdate(val.valueOf(), null)
                                            }
                                        }}
                                        value={new Date(game.start)} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(game.awayTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.awayTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (onAwayUpdate != null) {
                                        onAwayUpdate(e.target.value)
                                    }
                                }}>
                                    {Object.values(teamIds).map((teamId,i)=>(
                                        <option key={teamId} value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent text-center' style={{marginTop: 5}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}>{t('end')} </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        onChange={(val) => {
                                            if (gameTimeUpdate != null) {
                                                gameTimeUpdate(null, val.valueOf())
                                            }
                                        }}
                                        value={new Date(game.end)} />
                                </div>
                            </div>
                        </Col>
                        <Col lg={2} sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getLocation(game.locationId, locations).thumbUri}
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.locationId} className='wrap-content-fill-child' onChange={e=> {
                                    if (locationUpdate != null) {
                                        locationUpdate(e.target.value)
                                    }
                                }}>
                                    <option value={null}></option>
                                    {Object.values(division.locationIds != null ? division.locationIds : {}).map((locationId,i)=>(
                                        <option key={locationId} value={locationId}>{this.getLocation(locationId, locations).title}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='text-center' style={{marginTop: 5}}>
                                <div style={{fontSize: 12, textAlign: 'left'}}>{t('referee')}</div>
                                <UserChoice
                                    title={t('referee_choice')}
                                    users={referees}
                                    smallMode={true}
                                    emptyTitle={t('pick_referee')}
                                    userChoice={referees[game.refereeId]}
                                    onItemChoice={(item) => {
                                        if (refereeUpdate != null) {
                                            refereeUpdate(item.uid)
                                        }
                                    }} />
                                <div style={{fontSize: 12, textAlign: 'left'}}>{t('assist_referee')}</div>
                                <MembersChoice
                                    noMembersTxt={t('no_assist_referee')}
                                    available={organization.refereeIds}
                                    members={game.referees}
                                    users={referees}
                                    onSaveClick={(members)=> {
                                        if (refereeUpdate != null) {
                                            refereeUpdate(game.refereeId, members)
                                        }
                                    }} />
                            </div>
                        </Col>
                        <Col lg={1} sm={6} xs={12}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (removeClicked != null) {
                                            removeClicked()
                                        }
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {game.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                    {game.refereeOverlap && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('referee_overlap')}
                        </div>
                    )}
                    {!game.valid && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {subSeason.matchCount != null && subSeason.matchCount !== -1 ? t('home_invalid_count') : t('home_away_duplicated')}
                        </div>
                    )}
                    {subSeason.matchCount != null && subSeason.matchCount !== -1 && game.awayValid === false && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('away_invalid_count')}
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default GameScheduleBadge;