import React, { Component } from 'react';

import LoadingView from 'components/detail/loading/LoadingView';
import SlidesEdit from 'components/edit/club/landing/SlidesEdit';
import VideoEdit from 'components/edit/club/landing/VideoEdit';
import ServicesEdit from 'components/edit/club/landing/ServicesEdit';
import { connect } from 'react-redux';
import AttachImageEdit from 'components/edit/AttachImageEdit';
import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';

import { SketchPicker } from 'react-color';
import Switch from '@mui/material/Switch';
import { 
    Row,
    Col,
    Input,
    Button,
    Spinner,
    ListGroupItem
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    FaWpforms
} from 'react-icons/fa';

import { userActions } from 'redux/_actions';
import { aboutUs, contact } from 'utils/landing';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { generateId, invokeHttpsApi } from 'utils/API';
  
class ClubSettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: {},
            users: {},
            detailOpen: false,
            editOpen: false,
            editProfile: false,
            editTeam: false,
            loading: true,
        };
        this.autocomplete = null
        this.loadContents = this.loadContents.bind(this);
        this.onLoadMap = this.onLoadMap.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        const { club } = this.props;
        invokeHttpsApi('clubQuery-settingsQuery', {
            teamId: club ? club.id : null
        }, (data) => {
            const team = {};
            const users = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
            }
            
            this.setState({
                team: team,
                users: users,
                loading: false,
                domainProgress: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                domainProgress: false,
            })
        });
    }
    
    saveDomainChange(team, customDomain) {

        const params = {
            teamId: team.id,
            customDomain: customDomain,
        }
        this.setState({domainProgress: true});
        invokeHttpsApi('clubQuery-submitTeamCustomDomain', params, ((data)=> {
            if (data && data.success) {
                team.customDomain = customDomain;
                alert("Saving custom domain update success.");
                this.setState({team: team, domainProgress: false});
            } else {
                alert("Saving custom domain update failed. Please type valid one.");
                this.setState({domainProgress: false});
            }
        }), ((error)=> {
            console.log('saveContents error', error);
            alert(error);
            this.setState({domainProgress: false});
        }))
    }

    renderLandingSettings() {
        const {user} = this.props;
        const {team, domainProgress, imageProgress, landingEdit, customDomain} = this.state;

        if (team == null || team.id == null) {
            return (
                <div>You should create Team first</div>
            )
        }
        //const theme = team.landingData ? team.landingData.theme : null;
        const blueUrl = team.landingData ? team.landingData.blueUrl : null;
        const actionBg = team.landingData && team.landingData.actionBg ? team.landingData.actionBg : "#C1E729"
        const actionColor = team.landingData && team.landingData.actionColor ? team.landingData.actionColor : "#000000";

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Settings
                            </h3>
                            <div className='items-navigation-count'>| Team Landing Page</div>
                        </div>
                    </div>
                    
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.setState({details: null})
                    }}>{t('back')}</Button>
                </div>
                <Row>
                    <Col xs={3}>
                        <ListGroupItem style={!landingEdit ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: null})
                        }}>General</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'slides' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'slides'})
                        }}>Landing Slides</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'services' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'services'})
                        }}>Services</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'video' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'video'})
                        }}>Video</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'about' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'about'})
                        }}>About Us</ListGroupItem>
                        <ListGroupItem style={landingEdit === 'contact' ? {color: '#ED1D24'} : {}} onClick={(e)=> {
                            this.setState({landingEdit: 'contact'})
                        }}>{t('contact_us')}</ListGroupItem>
                    </Col>
                    {!landingEdit && (
                    <Col xs={9}>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Hide Team Landing Page </b> | Hide Out Landing page
                            </div>
                            <div className='form-setting-control'>
                                <Switch
                                    checked={team.landingData && team.landingData.hideLanding}
                                    onChange={e=>{
                                        if (!team.landingData) {
                                            team.landingData = {};
                                        }
                                        team.landingData.hideLanding = e.target.checked;
                                        this.saveChange(team);
                                        this.setState({team: team});
                                    }}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }} />
                            </div>
                        </div>
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Custom Domain </b> | Domain attached to team url
                            </div>
                            <div className='form-setting-input'>
                                <Input type="text" name="text" id="customDomain" onChange={e => {
                                    const update = e.target.value ? e.target.value.toLowerCase() : null;
                                    
                                    this.setState({customDomain: update});
                                }} value={customDomain ? customDomain : team.customDomain} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    if (team.owner !== user.uid) {
                                        alert('You are not owner of team, only owner can update domain.');
                                        return;
                                    }
                                    this.saveDomainChange(team, customDomain);
                                }}>Update Custom Domain</Button>
                                }
                            </div>
                        </div>
 
                        {/*<div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Landing Page Theme </b> | Landing page type
                            </div>
                            <div className='form-setting-input'>
                                <Input type="select" name="text" id="customDomain" onChange={e => {
                                    if (!team.landingData) {
                                        team.landingData = {};
                                    }
                                    team.landingData.theme = e.target.value;
                                    
                                    this.setState({team: team});
                                }} value={theme}>
                                    <option value={null}></option>
                                    <option value="normal">{t('normal')}</option>
                                    <option value="blue">Blue</option>
                                </Input>
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    if (!team.landingData) {
                                        team.landingData = {};
                                    }
        
                                    this.saveChange(team, team.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                         */}
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Custom Action Color </b> | Custom color set for action button all over the theme
                            </div>
                            <div className='form-setting-input'>
                                <SketchPicker color={actionBg} onChange={(e)=> {
                                    if (!team.landingData) {
                                        team.landingData = {};
                                    }
                                    team.landingData.actionBg = e.hex;
                                    this.setState({team: team});
                                }} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    this.saveChange(team, team.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                        
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Custom Action Font Color </b> | Custom color set for action button all over the theme
                            </div>
                            <div className='form-setting-input'>
                                <SketchPicker color={actionColor} onChange={(e)=> {
                                    if (!team.landingData) {
                                        team.landingData = {};
                                    }
                                    team.landingData.actionColor = e.hex;
                                    this.setState({team: team});
                                }} />
                            </div>
                            <div className='form-setting-control'>
                                {domainProgress ? 
                                <Spinner className='' /> : 
                                <Button className="btn-submit" onClick={e => {
                                    this.saveChange(team, team.landingData);
                                }}>Update Change</Button>
                                }
                            </div>
                        </div>
                        
                        <div className='form-setting-wrapper'>
                            <div className='form-setting-title'>
                                <b>Theme Background</b>
                            </div>
                            <div className='form-setting-input'>
                                <AttachImageEdit
                                    imageUrl={blueUrl}
                                    imageClass='home-edit-thumb'
                                    label='Blue Image Url'
                                    maxHeight={150}
                                    allowProgress={domainProgress}
                                    progress={imageProgress}
                                    onChange={(file)=> {
                                        this.setState({domainProgress: true});
                                        uploadImageFileToStorage(team.id, 'team-blue-theme', file, (url) => {
                                            if (!team.landingData) {
                                                team.landingData = {};
                                            }
                                            team.landingData.blueUrl = url;
                                            this.saveChange(team, team.landingData);
                                        }, (state, progress) => {
                                            if (state === 'running') {
                                                this.setState({imageProgress: progress});
                                            } else {
                                                this.setState({imageProgress: 0});
                                            }
                                        }, error => {
                                            alert("Saving Image File Failed.\n" + error);
                                            this.setState({
                                                progress: false
                                            });
                                        })
                                    }} />
                            </div>
                        </div>
        
                    </Col>
                    )}
                    {landingEdit === 'slides' && (
                    <Col xs={9}>
                        <SlidesEdit 
                            team={team}
                            onUpdate={(update)=> {
                                team.landingData = {};
                                for (const [k, v] of Object.entries(update)) {
                                    team.landingData[k] = v;
                                }
                                this.setState({team: team})
                            }} />
                    </Col>
                    )}
                    {landingEdit === 'services' && (
                    <Col xs={9}>
                        <ServicesEdit 
                            team={team}
                            onClickSave={(update)=> {
                                team.landingData = {};
                                for (const [k, v] of Object.entries(update)) {
                                    team.landingData[k] = v;
                                }
                                this.setState({team: team})
                            }} />
                    </Col>
                    )}
                    {landingEdit === 'video' && (
                    <Col xs={9}>
                        <VideoEdit 
                            team={team}
                            onUpdate={(update)=> {
                                team.landingData.video = {};
                                for (const [k, v] of Object.entries(update)) {
                                    team.landingData.video[k] = v;
                                }
                                this.setState({team: team})
                            }} />
                    </Col>
                    )}
                    {landingEdit === 'about' && (
                    <Col xs={9}>
                        {this.renderAboutUs()}
                    </Col>
                    )}
                    {landingEdit === 'contact' && (
                    <Col xs={9}>
                        {this.renderContactUs()}
                    </Col>
                    )}
                </Row>
            </div>
        )
    }

    renderAboutUs() {
        const {team, domainProgress, imageProgress} = this.state;
        const landingData = (team && team.landingData) ? team.landingData : {};
        const aboutData = landingData.aboutUs ? landingData.aboutUs : aboutUs;

        const itemsArr = Object.values(aboutData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];
        for (const item of itemsArr) {
            items.push((
                <div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Subtitle </b> | About Us Item Subtitle
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!team.landingData) {
                                    team.landingData = {};
                                }
                                if (!team.landingData.aboutUs) {
                                    team.landingData.aboutUs = {};
                                    for (const [k, v] of Object.entries(aboutUs)) {
                                        team.landingData.aboutUs[k] = v;
                                    }
                                }

                                team.landingData.aboutUs.items[item.id].subtitle = e.target.value;
                                
                                this.setState({team: team});
                            }} value={item.subtitle} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Text </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!team.landingData) {
                                    team.landingData = {};
                                }
                                if (!team.landingData.aboutUs) {
                                    team.landingData.aboutUs = {};
                                    for (const [k, v] of Object.entries(aboutUs)) {
                                        team.landingData.aboutUs[k] = v;
                                    }
                                }

                                team.landingData.aboutUs.items[item.id].text = e.target.value;
                                
                                this.setState({team: team});
                            }} value={item.text} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Index </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="number" name="text" id="customDomain" onChange={e => {
                                if (!team.landingData) {
                                    team.landingData = {};
                                }
                                if (!team.landingData.aboutUs) {
                                    team.landingData.aboutUs = {};
                                    for (const [k, v] of Object.entries(aboutUs)) {
                                        team.landingData.aboutUs[k] = v;
                                    }
                                }

                                team.landingData.aboutUs.items[item.id].index = e.target.value;
                                
                                this.setState({team: team});
                            }} value={item.index} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Blue Theme Background</b>
                        </div>
                        <div className='form-setting-input'>
                            <AttachImageEdit
                                imageUrl={item.bg}
                                imageClass='home-edit-thumb'
                                label='Blue Image Url'
                                maxHeight={150}
                                allowProgress={domainProgress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({domainProgress: true});
                                    uploadImageFileToStorage(item.id, 'team-about-us/' + team.id + '/image', file, (url) => {
                                        if (!team.landingData) {
                                            team.landingData = {};
                                        }
                                        if (!team.landingData.aboutUs) {
                                            team.landingData.aboutUs = {};
                                            for (const [k, v] of Object.entries(aboutUs)) {
                                                team.landingData.aboutUs[k] = v;
                                            }
                                        }
        
                                        team.landingData.aboutUs.items[item.id].bg = url;
                                        
                                        this.saveChange(team, team.landingData);
                                    }, (state, progress) => {
                                        if (state === 'running') {
                                            this.setState({imageProgress: progress});
                                        } else {
                                            this.setState({imageProgress: 0});
                                        }
                                    }, error => {
                                        alert("Saving Image File Failed.\n" + error);
                                        this.setState({
                                            progress: false
                                        });
                                    })
                                }} />
                        </div>
                    </div>
                </div>
            ))
        }

        return (
            <div className=''>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    About Us
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />
                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Title </b> | About Us Section Title
                    </div>
                    <div className='form-setting-input'>
                        <Input type="text" name="text" id="customDomain" onChange={e => {
                            if (!team.landingData) {
                                team.landingData = {};
                            }
                            if (!team.landingData.aboutUs) {
                                team.landingData.aboutUs = {};
                                for (const [k, v] of Object.entries(aboutUs)) {
                                    team.landingData.aboutUs[k] = v;
                                }
                            }

                            team.landingData.aboutUs.title = e.target.value;
                            
                            this.setState({team: team});
                        }} value={aboutData.title} />
                    </div>
                </div>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    About Us Items
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        <Button className='btn-submit wrap-content-wrap-child' onClick={ e => {
                            if (!team.landingData) {
                                team.landingData = {};
                            }
                            if (!team.landingData.aboutUs) {
                                team.landingData.aboutUs = {};
                                for (const [k, v] of Object.entries(aboutUs)) {
                                    team.landingData.aboutUs[k] = v;
                                }
                            }

                            const item = {};
                            item.id = generateId('/team/' + team.id + '/landingData/aboutUs/items');
                            item.subtitle = "Subtitle";
                            item.index = Object.values(team.landingData.aboutUs.items).length + 1;

                            team.landingData.aboutUs.items[item.id] = item;
                            this.setState({team: team});
                        }}>+</Button>
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#A6DC34'}} />
                {items}
                <div className='wrap-content-parent' style={{marginBottom: 20}}>
                    <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                        
                    </h4>
                    
                    {domainProgress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div style={{textAlign: 'end'}}>
                            <Button className='btn-submit' onClick={e => {
                                this.saveChange(team, team.landingData);
                            }}>Update</Button>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    onLoadMap (autocomplete) {
        this.autocomplete = autocomplete
    }

    onPlaceChanged () {
        const {team} = this.state;
        
        if (!team.landingData) {
            team.landingData = {};
        }
        if (!team.landingData.contact) {
            team.landingData.contact = {};
            for (const [k, v] of Object.entries(contact)) {
                team.landingData.contact[k] = v;
            }
        }

        if (this.autocomplete !== null) {
            const place = this.autocomplete.getPlace();
            
            if (place != null) {
                team.landingData.contact.address = {};
                
                team.landingData.contact.address.place_id = place.place_id;
                if (place.formatted_address != null) {
                    team.landingData.contact.address.formatted_address = place.formatted_address;
                }
                if (place.formatted_phone_number != null) {
                    team.landingData.contact.address.formatted_phone_number = place.formatted_phone_number;
                }
                if (place.icon != null) {
                    team.landingData.contact.address.placeIcon = place.icon;
                }
                if (place.name != null) {
                    team.landingData.contact.address.placeName = place.name;
                }
                team.landingData.contact.address.lat = place.geometry.location.lat();
                team.landingData.contact.address.lng = place.geometry.location.lng();
                this.setState({team: team});
            } else {
                team.landingData.contact.address = null;
                this.setState({team: team});
            }
        } else {
            team.landingData.contact.address = null;
            this.setState({team: team});
            console.log('Autocomplete is not loaded yet!')
        }
    }

    renderContactUs() {
        const {team, domainProgress, imageProgress} = this.state;
        const landingData = (team && team.landingData) ? team.landingData : {};
        const contactData = landingData.contact ? landingData.contact : contact;

        const itemsArr = Object.values(contactData.items);
        itemsArr.sort((a,b) => {
            const i1 = a.index ? a.index : 0;
            const i2 = b.index ? b.index : 0;
            return i1 - i2;
        });

        const items = [];
        for (const item of itemsArr) {
            items.push((
                <div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Subtitle </b> | About Us Item Subtitle
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!team.landingData) {
                                    team.landingData = {};
                                }
                                if (!team.landingData.contact) {
                                    team.landingData.contact = {};
                                    for (const [k, v] of Object.entries(contact)) {
                                        team.landingData.contact[k] = v;
                                    }
                                }

                                team.landingData.contact.items[item.id].subtitle = e.target.value;
                                
                                this.setState({team: team});
                            }} value={item.subtitle} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Text </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="text" name="text" id="customDomain" onChange={e => {
                                if (!team.landingData) {
                                    team.landingData = {};
                                }
                                if (!team.landingData.contact) {
                                    team.landingData.contact = {};
                                    for (const [k, v] of Object.entries(contact)) {
                                        team.landingData.contact[k] = v;
                                    }
                                }

                                team.landingData.contact.items[item.id].text = e.target.value;
                                
                                this.setState({team: team});
                            }} value={item.text} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Index </b> | About Us Item Text
                        </div>
                        <div className='form-setting-input'>
                            <Input type="number" name="text" id="customDomain" onChange={e => {
                                if (!team.landingData) {
                                    team.landingData = {};
                                }
                                if (!team.landingData.contact) {
                                    team.landingData.contact = {};
                                    for (const [k, v] of Object.entries(contact)) {
                                        team.landingData.contact[k] = v;
                                    }
                                }

                                team.landingData.contact.items[item.id].index = e.target.value;
                                
                                this.setState({team: team});
                            }} value={item.index} />
                        </div>
                    </div>
                    <div className='form-setting-wrapper'>
                        <div className='form-setting-title'>
                            <b>Image</b>
                        </div>
                        <div className='form-setting-input'>
                            <AttachImageEdit
                                imageUrl={item.bg}
                                imageClass='home-edit-thumb'
                                label='Blue Image Url'
                                maxHeight={150}
                                allowProgress={domainProgress}
                                progress={imageProgress}
                                onChange={(file)=> {
                                    this.setState({domainProgress: true});
                                    
                                    uploadImageFileToStorage(item.id, 'team-contact/' + team.id + '/image', file, (url) => {
                                        if (!team.landingData) {
                                            team.landingData = {};
                                        }
                                        if (!team.landingData.contact) {
                                            team.landingData.contact = {};
                                            for (const [k, v] of Object.entries(contact)) {
                                                team.landingData.contact[k] = v;
                                            }
                                        }
        
                                        team.landingData.contact.items[item.id].bg = url;
                                        
                                        this.saveChange(team, team.landingData);
                                    }, (state, progress) => {
                                        if (state === 'running') {
                                            this.setState({imageProgress: progress});
                                        } else {
                                            this.setState({imageProgress: 0});
                                        }
                                    }, error => {
                                        alert("Saving Image File Failed.\n" + error);
                                        this.setState({
                                            progress: false
                                        });
                                    })
                                }} />
                        </div>
                    </div>
                </div>
            ))
        }
        
        const address = contactData.address ? contactData.address : {};
        const locationTxt = address.formatted_address != null ? address.formatted_address : "Input place name for location";

        return (
            <div className=''>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    {t('contact_us')}
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Title </b> | {t('contact_us_title')}
                    </div>
                    <div className='form-setting-input'>
                        <Input type="text" name="text" id="customDomain" onChange={e => {
                            if (!team.landingData) {
                                team.landingData = {};
                            }
                            if (!team.landingData.contact) {
                                team.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    team.landingData.contact[k] = v;
                                }
                            }

                            team.landingData.contact.title = e.target.value;
                            
                            this.setState({team: team});
                        }} value={contactData.title} />
                    </div>
                </div>

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Email </b> | Contact email
                    </div>
                    <div className='form-setting-input'>
                        <Input type="email" name="text" id="customDomain" onChange={e => {
                            if (!team.landingData) {
                                team.landingData = {};
                            }
                            if (!team.landingData.contact) {
                                team.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    team.landingData.contact[k] = v;
                                }
                            }

                            team.landingData.contact.email = e.target.value;
                            
                            this.setState({team: team});
                        }} value={contactData.email} />
                    </div>
                </div>

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Phone </b> | Contact phone number
                    </div>
                    <div className='form-setting-input'>
                        <Input type="text" name="text" id="customDomain" onChange={e => {
                            if (!team.landingData) {
                                team.landingData = {};
                            }
                            if (!team.landingData.contact) {
                                team.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    team.landingData.contact[k] = v;
                                }
                            }

                            team.landingData.contact.phone = e.target.value;
                            
                            this.setState({team: team});
                        }} value={contactData.phone} />
                    </div>
                </div>

                <div className='form-setting-wrapper'>
                    <div className='form-setting-title'>
                        <b>Address </b> | Contact address
                    </div>
                    <div className='form-setting-input'>
                        <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                            libraries={["places"]} >
                            <Autocomplete
                                onLoad={this.onLoadMap}
                                onPlaceChanged={this.onPlaceChanged} >
                                <input
                                type="text"
                                placeholder={locationTxt}
                                className="location-input"
                                />
                            </Autocomplete>
                        </LoadScript>
                        <b>{locationTxt}</b>
                    </div>
                </div>

                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    {t('contact_us_items')}
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        <Button className='btn-submit wrap-content-wrap-child' onClick={ e => {
                            if (!team.landingData) {
                                team.landingData = {};
                            }
                            if (!team.landingData.contact) {
                                team.landingData.contact = {};
                                for (const [k, v] of Object.entries(contact)) {
                                    team.landingData.contact[k] = v;
                                }
                            }

                            const item = {};
                            item.id = generateId('/team/' + team.id + '/landingData/contact/items');
                            item.subtitle = "Subtitle";
                            item.index = Object.values(team.landingData.contact.items).length + 1;

                            team.landingData.contact.items[item.id] = item;
                            this.setState({team: team});
                        }}>+</Button>
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#A6DC34'}} />
                {items}
                <div className='wrap-content-parent' style={{marginBottom: 20}}>
                    <h4 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 15}}>
                        
                    </h4>
                    
                    {domainProgress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div style={{textAlign: 'end'}}>
                            <Button className='btn-submit' onClick={e => {
                                this.saveChange(team, team.landingData);
                            }}>Update</Button>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    saveChange(team, landingData) {
        this.setState({domainProgress: true});
        team.landingData = {};
        for (const [k,v] of Object.entries(landingData)) {
            team.landingData[k] = v;
        }

        invokeHttpsApi('clubQuery-saveClubEdit', {
            club: team
        }, (data) => {

            // Read result of the Cloud Function.
            this.loadContents();
        }, (error) => {
            console.log('saveContents error', error);
            alert(error);
        });
    }

    renderContents() {
        const {details} = this.state;
        if (details == null) {
            return (
                <div>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaWpforms/></div>
                                <h3 className='items-navigation-title'>
                                Team Landing Page Settings
                                </h3>
                            </div>
                        </div>
                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.setState({details: 'landing'})
                        }}>Details</Button>
                    </div>
                </div>
            )
        } else if (details === 'landing') {
            return (
                <div>
                    {this.renderLandingSettings()}
                </div>
            )
        } else {
            return <div />
        }
    }

    render() {
        const {loading} = this.state;

        return (
            <div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                    {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizerSubscription } = state.subscriptions;
    return { alert, loggingIn, user, organizerSubscription };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(ClubSettingsView));
export { connectedApp as ClubSettingsView };
