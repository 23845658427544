import React, { Component } from 'react';
import { connect } from 'react-redux';
import CsvViewer from 'components/utils/CsvViewer';
import InviteHeader from 'components/detail/invite/InviteHeader';
import userImage from 'assets/img/icons/player_photo_default.png';

import { 
    Row,
    Col,
    Input,
    Form,
    Button
} from 'reactstrap';

import { t } from 'i18next';
import { validateEmail, validateEmailStr } from 'utils/Utils';

import { userActions } from 'redux/_actions';
import { 
    GiSoccerKick
} from 'react-icons/gi';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import LoadingView from 'components/detail/loading/LoadingView';

import { generateId, invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';

class ImportsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: {},
            organizations: {},
            organization: {},
            profileData: {},
            teams: {},
            teamsToPick: {},
            users: {},
            category: {},
            profileChoice: {},
            data: [],
            columns: [],
            imports: {},
            detailOpen: false,
            importOpen: false,
            loading: true,
            searchStr: null,
            teamFilter: null,
            statusFilter: null,
            pickTeam: false,
            pickStatus: false
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        if (this.props.user) {
            this.loadContents(id);
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        const id = this.props.router.params.id;
        if (!prevUid && uid) {
            this.loadContents(id);
        }
    }

    loadContents(id) {
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizationContentsQuery', {}, (data) => {

            const locations = {};
            const organizations = {};
            let organization = {};
            const profileData = {};
            const profileChoice = {};
            let detailOpen = false;
            const teams = {};
            const teamsToPick = {};
            const users = {};
            const category = {};
            const imports = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    if (value.organizationId === organization.id) {
                        profileData[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                    if (organization != null && organization.teamIds != null && Object.values(organization.teamIds).includes(key)) {
                        teamsToPick[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.imports)) {
                    if (organization && organization.id === value.organizationId) {
                        imports[key] = value;
                    }
                }
            }
            if (id != null && profileData[id] != null) {
                for (const [key, value] of Object.entries(profileData[id])) {
                    profileChoice[key] = value;
                }
                detailOpen = true;
            }

            this.setState({
                locations: locations,
                organizations: organizations,
                organization: organization,
                profileData: profileData,
                profileChoice: profileChoice,
                detailOpen: detailOpen,
                teams: teams,
                teamsToPick: teamsToPick,
                users: users,
                category: category,
                imports: imports,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    performInvite(data, teamData) {
        const {organization} = this.state;
        const imports = {};
        let index = 0;
        for (const item of data) {
            imports["id_" + index.toString()] = item;
            index ++;
        }
        const items = {imports: imports, teamData: teamData, organizationId: organization.id};


        this.setState({progress: true})
        invokeHttpsApi('invite-invitePlayerToOrganzation', items, (data) => {
            alert("An invitation emails to be your players has been sent. It will be sent every 2 days");
            this.loadContents();
        }, (error) => {
            console.log('invite error', error);
            this.setState({progress: false})
        });
    }

    getInviteImports() {
        const {data, emailChoice, firstnameChoice, lastnameChoice, birthdayChoice, mobileChoice, 
            teamChoice, userTypeChoice, playerType, coachType, managerType} = this.state;
        const teamData = this.prepareTeamData();

        if (!teamChoice || !userTypeChoice) {
            alert('Team Assigning Information not choice. Pls try again after inputing correct value')
            return;
        }
        if (!playerType && !window.confirm('Player Type not chosen. All users will be assigned as player. Are you sure you want to procceed?')) {
            return;
        }
        if (!coachType && !window.confirm('Coach Type not chosen. Users may not be assigned as coaches. Are you sure you want to procceed?')) {
            return;
        }
        if (!managerType && !window.confirm('Coach Type not chosen. Users may not be assigned as managers. Are you sure you want to procceed?')) {
            return;
        }
        if (emailChoice && firstnameChoice && lastnameChoice && birthdayChoice && mobileChoice) {
            const result = [];
            for (const item of data) {
                const email = item[emailChoice] ? item[emailChoice].replace(/\s/g, '') : "";
                const teamTitle = item[teamChoice] ? item[teamChoice].replace(/\s/g, '') : "";
                const type = item[userTypeChoice] ? item[userTypeChoice].replace(/\s/g, '') : "";

                if (email === "") {
                    continue;
                }
                if (!validateEmail(email)) {
                    alert('You have an invalid email on your csv import');
                    return;
                }

                const userItem = {
                    email: email,
                    firstname: item[firstnameChoice],
                    lastname: item[lastnameChoice],
                    birthday: Date.parse(item[birthdayChoice]),
                    mobile: item[mobileChoice],
                }
                if (teamTitle !== "") {
                    userItem.teamId = teamData[teamTitle].id;
                }
                if (!playerType && !coachType && !managerType) {
                    userItem.type = "player";
                } else if (type === playerType) {
                    userItem.type = "player";
                } else if (type === coachType) {
                    userItem.type = "coach";
                } else if (type === managerType) {
                    userItem.type = "manager";
                }
                result.push(userItem);
            }

            this.performInvite(result, teamData);
        } else {
            alert("You should pick columns for the csv information importing.")
        }
    }

    getTeamData() {
        const {data, teamChoice, userTypeChoice, playerType, coachType, managerType} = this.state;
        const teamData = {};
        const userTypes = [];
        if (teamChoice) {
            for (const item of data) {
                const teamTitle = item[teamChoice] ? item[teamChoice].replace(/\s/g, '') : "";
                const type = item[userTypeChoice] ? item[userTypeChoice].replace(/\s/g, '') : "";

                if (teamTitle && teamTitle !== "") {
                    if (!Object.keys(teamData).includes(teamTitle)) {
                        teamData[teamTitle] = {title: teamTitle, players: 0, coaches: 0, managers: 0};
                    }
                    if (!playerType && !coachType && !managerType) {
                        teamData[teamTitle].players += 1;
                    } else if (type === playerType) {
                        teamData[teamTitle].players += 1;
                    } else if (type === coachType) {
                        teamData[teamTitle].coaches += 1;
                    } else if (type === managerType) {
                        teamData[teamTitle].managers += 1;
                    }
                }
                if (!userTypes.includes(type)) {
                    userTypes.push(type);
                }
            }
        }

        return {teamData: teamData, userTypes: userTypes}
    }

    prepareTeamData() {
        const teamData = this.getTeamData().teamData;

        for (const item of Object.values(teamData)) {
            if (item.id == null || item.id === '') {
                item.id = generateId("/squad");
            }
        }
        return teamData
    }

    renderTeamImports() {
        const {columns, teamChoice, userTypeChoice, playerType, coachType, managerType} = this.state;
        const teamAnalysis = this.getTeamData();

        const teamData = teamAnalysis.teamData;
        const userTypes = teamAnalysis.userTypes;

        return (
            <div>

                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Team Column
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({teamChoice: e.target.value});
                                }} value={teamChoice}>
                                    <option value={null}></option>
                                    {columns.map((val,idx)=>(
                                    <option key={idx} value={val.accessor}>{val.Header}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('user_type_column')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({userTypeChoice: e.target.value});
                                }} value={userTypeChoice}>
                                    <option value={null}></option>
                                    {columns.map((val,idx)=>(
                                    <option key={idx} value={val.accessor}>{val.Header}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Player Type Choice
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({playerType: e.target.value});
                                }} value={playerType}>
                                    <option value={null}></option>
                                    {userTypes.map((val,idx)=>(
                                    <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Coach Type Choice
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({coachType: e.target.value});
                                }} value={coachType}>
                                    <option value={null}></option>
                                    {userTypes.map((val,idx)=>(
                                    <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Manager Type Choice
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({managerType: e.target.value});
                                }} value={managerType}>
                                    <option value={null}></option>
                                    {userTypes.map((val,idx)=>(
                                    <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Imported Team Titles
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            {Object.values(teamData).map((v, index)=>(
                                <div>
                                    <b>{v.title}</b> : Players <span>{v.players}</span>, Coaches <span>{v.coaches}</span>, Managers <span>{v.managers}</span>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    renderImports() {
        const {columns, emailChoice, firstnameChoice, lastnameChoice, birthdayChoice, mobileChoice} = this.state;
        
        return (
            <div>
                <div style={{margin: '10px 10px'}}>
                    <CsvViewer 
                        label="Oragnization Player Import CSV File"
                        onChange={(data, columns) => {
                            this.setState({data: data, columns: columns})
                        }} />
                </div>

                <Row>
                    <Col lg={6} sm={12}>
                        {this.renderTeamImports()}
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form className='form-edit'>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Email Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({emailChoice: e.target.value});
                                        }} value={emailChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                First Name Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({firstnameChoice: e.target.value});
                                        }} value={firstnameChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Last Name Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({lastnameChoice: e.target.value});
                                        }} value={lastnameChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Birthday Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({birthdayChoice: e.target.value});
                                        }} value={birthdayChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Mobile Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({mobileChoice: e.target.value});
                                        }} value={mobileChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                    <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (window.confirm('Are you sure you want to perform import? This will create a number of teams on your own and send invite emails to your email list. If you have imported before, this can cause duplicated teams creating and some invalid information.')) {
                                            this.getInviteImports();
                                        }
                                    }}>Import</Button>
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={7}>
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.setState({profileChoice: {}, detailOpen: false, importOpen: false})
                                    }}>{t('close')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    renderInvites() {
        const { imports, organization, profileData, searchStr } = this.state;

        const invited = [];
        const pending = [];
        const players = [];
        const clubPending = [];

        for (const val of Object.values(imports)) {
            if (val.organizationId !== organization.id) {
                continue
            }
            if (!searchStr || (val.email && val.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                if (val.status === 'invited') {
                    invited.push(val);
                } else if (val.status === 'registered') {
                    pending.push(val);
                }
            }
        }
        for (const user of Object.values(profileData)) {
            if (!searchStr || (user.email && user.email.toLowerCase().includes(searchStr.toLowerCase()))) {
                if (user.verified === true) {
                    players.push(user);
                } else {
                    clubPending.push(user);
                }
            }
        }
    
        players.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
    
        clubPending.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        return (
            <div>
                <div style={{marginTop: 10}} className='wrap-content-fill-child'>
                    <div className='items-navigation'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <Input type="text" name="search" id="search" placeholder='Search by email ...' onChange={e => {
                                this.setState({searchStr: e.target.value});
                            }} value={searchStr} />
                        </div>                        
                        <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                            this.sendInvite(validateEmailStr(searchStr));
                        }}>{t('invite')}</Button>
                    </div>
                    
                    <div>
                        {invited.map((val) => (
                            <InviteHeader
                                key={val.email}
                                title="No user for this email yet"
                                defaultIcon={userImage}
                                email={val.email}
                                state="invited" />
                        ))}
                    </div>
                    <div>
                        {pending.map((val) => (
                            <InviteHeader
                                key={val.email}
                                title="No user for this email yet"
                                defaultIcon={userImage}
                                email={val.email}
                                state="registered" />
                        ))}
                    </div>
                    <div>
                        {clubPending.map((val) => (
                            <InviteHeader
                                key={val.uid}
                                title={val.playerName + val.playerLastName}
                                defaultIcon={userImage}
                                thumbUri={val.photoUri}
                                email={val.email}
                                state="clubPending" />
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {user} = this.props;
        const {imports, detailOpen, importOpen, profileChoice, loading} = this.state;

        if (user == null) {
            return <div/>
        }
        const curConetntCount = Object.values(imports).length;
        const choice = {};
        if (profileChoice.uid != null) {
            choice.id = profileChoice.uid;
            choice.title = profileChoice.username;
        }

        return (
            <div>
                
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title="Player Import Items"
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={importOpen}
                        totalCount={curConetntCount}
                        detailItem={choice}
                        onClickImport={()=>{
                            this.setState({profileChoice: {}, detailOpen: false, importOpen: true})
                        }}
                        onClickBack={()=>{
                            this.setState({profileChoice: {}, detailOpen: false, importOpen: false})
                        }} />
                </div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {importOpen && this.renderImports()}
                        {!importOpen && this.renderInvites()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(ImportsPage));
export { connectedApp as ImportsPage };
