import React, { Component } from "react";
import {
    CardImg, Button, Col, Row, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class TableRowSmall extends Component {
    
    render() {
        const {title, subView, subView2, icon, progress, onClickView, onClickDetails, onClickEdit, detailLabel, editLabel } = this.props;
        
        if (title != null) {
            return (
                <Row style={{margin: 0}} onClick={e=>{
                    if (onClickView) {
                        onClickView();
                    }
                }}>
                    <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={icon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {title}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                        {subView}
                        </div>
                    </Col>
                    <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                        {subView2 ? subView2 : (
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                <div className='wrap-content-parent'>
                                {progress && (
                                    <div className='wrap-content-parent'>
                                        <Spinner color="primary" />
                                    </div>
                                )}
                                {!progress && onClickDetails && (
                                    <div className='wrap-content-parent'>
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        e.stopPropagation();
                                        if (onClickDetails != null) {
                                            onClickDetails();
                                        }
                                    }}>{detailLabel ? detailLabel : t('details')}</Button>
                                    </div>
                                )}
                                {!progress && onClickEdit && (
                                    <div className='wrap-content-parent'>
                                    <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                        e.stopPropagation();
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{editLabel ? editLabel : t('edit')}</Button>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                        )}
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TableRowSmall);