import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    uploadVideoToStorage, 
} from 'utils/FirebaseUtils';

import AttachVideoEdit from 'components/edit/AttachVideoEdit';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class VideoEditView extends Component {
    constructor(props) {
        super(props);
        const video = {};
        if (props.video != null) {
            for (const [key, value] of Object.entries(props.video)) {
                video[key] = value;
            }
        }
        
        this.state = {
            video: video,
            progress: false,
            newVideo: {},
            videoProgress: 0,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, video} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newVideo: {},
                videoProgress: 0,
            })
        }
        if ((prevProps.video !== video)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(video)) {
                challengeVal[key] = value;
            }
            this.setState({
                video: challengeVal
            })
        }
    }

    onSaveClick(video, newVideo) {
        const objRef = this;
        if (video.title == null || video.title === '') {
            alert("You must input title");
        } else if (video.type == null || video.type === '') {
            alert("You must input type");
        } else if (video.duration_limit == null || video.duration_limit < 5) {
            alert("You must input valid duration limit");
        } else if ((video.id == null || video.id === '') && (newVideo == null || newVideo.name == null)) {
            alert("You must input video");
        } else {
            this.setState({progress: true, videoProgress: 0});
            if (video.id == null || video.id === '') {
                video.id = generateId("videos");
            }
            video.timestamp = dbTimestamp();
            
            if (newVideo != null && newVideo.name != null) {
                this.saveChangeWithVideo(video, newVideo, (update) => {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        video: update,
                        progress: false
                    })
                })
            } else {
                this.saveChange(video, (update) => {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        video: update,
                        progress: false
                    })
                })
            }
        }
    }

    saveChangeWithVideo(video, newVideo, sucess) {
        const objRef = this;

        uploadVideoToStorage(video.id, newVideo, video.type, url => {
            video.videoUri = url;
    
            sucess(video);
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({videoProgress: progress});
            } else {
                this.setState({videoProgress: 0});
            }
        }, error => {
            alert("Saving Video File Failed.\n" + error);
            objRef.setState({
                progress: false
            })
        })
    }

    saveChange(video, success, failure) {
        writeDb('/videos/' + video.id, video, error => {
            if (error) {
                failure(error);
            } else {
                success(video);
            }
        })
    }

    render() {
        const {onCloseClick, league} = this.props;
        const {video, newImage, newVideo, progress, videoProgress} = this.state;
        
        if (video != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            video.title = e.target.value;
                            this.setState({video: video});
                        }} value={video.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="typeChoice">Select Video Type: {video.type}</Label>
                        <Input type="select" name="select" id="typeChoice" onChange={e=> {
                            video.type = e.target.value;
                            this.setState({video: video});
                        }}>
                            <option value='Trial'>Trial</option>
                            <option value='TrialChallenge'>Trial Challenge</option>
                        </Input>
                    </FormGroup>
                    <AttachVideoEdit
                        videoUrl={video.videoUri}
                        videoClass='default-player'
                        label='Challenge Video'
                        allowProgress={progress}
                        progress={videoProgress}
                        onChange={(file)=> {
                            this.setState({newVideo: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(video, league, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(VideoEditView);