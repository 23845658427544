import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Card, CardBody, Button, Spinner, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import { Doughnut } from 'react-chartjs-2';
import { Slider } from "@mui/material";

import SurveyCheckQuestion from 'components/detail/survey/SurveyCheckQuestion';
import SurveyOptionQuestion from 'components/detail/survey/SurveyOptionQuestion';
import SurveyTextQuestion from 'components/detail/survey/SurveyTextQuestion';
import SurveyRatingQuestion from 'components/detail/survey/SurveyRatingQuestion';
import { getSortedArray, toDateTimeString } from 'utils/Utils';
import { MdCheckCircle, MdRadioButtonChecked, MdTextFields, MdStarHalf } from 'react-icons/md';

import {
    QUESTION_TYPE_OPTION,
    QUESTION_TYPE_CHECK,
    QUESTION_TYPE_TEXT,
    QUESTION_TYPE_RATING,
} from 'utils/constants';
import SurveyOptionQuestionEdit from "components/edit/survey/SurveyOptionQuestionEdit";
import SurveyCheckQuestionEdit from "components/edit/survey/SurveyCheckQuestionEdit";
import SurveyTextQuestionEdit from "components/edit/survey/SurveyTextQuestionEdit";
import SurveyRatingQuestionEdit from "components/edit/survey/SurveyRatingQuestionEdit";

import MembersView from 'components/members/MembersView';
import { generateId, listenDb, writeDb } from "utils/API";

export class SurveyEditView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            survey: {
                members: {},
                redValue: 10,
                warningValue: 20,
                yellowValue: 40,
                goodValue: 67,
            },
            progress: false,
            addOpen: false,
            available: null
        }
        this.submitChange = this.submitChange.bind(this);
    }

    componentDidMount() {
        const {survey, user} = this.props;
        var update = {
            members: {},
            redValue: 10,
            warningValue: 20,
            yellowValue: 40,
            goodValue: 67,
        };
        if (survey != null) {
            for (const[key, value] of Object.entries(survey)) {
                update[key] = value;
            }
        }
        this.setState({survey: update});

        if (user != null && user.type === 'coach') {
            listenDb('/coach/' + user.uid, (data) => {
                const available = {};
                if (data != null && data.followers != null) {
                    for (const [key, value] of Object.entries(data.followers)){
                        available[key] = value;
                    }
                }
                this.setState({available: available});
            })
        } else {
            this.setState({available: null})
        }
    }

    componentDidUpdate(prevProps) {
        const {survey, isOpen, user} = this.props;
        if (prevProps.survey !== survey) {
            var update = {
                members: {},
                redValue: 10,
                warningValue: 20,
                yellowValue: 40,
                goodValue: 67,
            };
            if (survey != null) {
                for (const[key, value] of Object.entries(survey)) {
                    update[key] = value;
                }
            }
            this.setState({survey: update, progress: false});
        }
        if (prevProps.isOpen !== isOpen) {
            this.setState({progress: false});
        }
        if (prevProps.user !== user) {
            if (user != null && user.type === 'coach') {
                listenDb('/coach/' + user.uid, (data) => {
                    const available = {};
                    if (data != null && data.followers != null) {
                        for (const [key, value] of Object.entries(data.followers)){
                            available[key] = value;
                        }
                    }
                    this.setState({available: available});
                })
            } else {
                this.setState({available: null})
            }
        }
    }

    submitChange() {
        const {onCloseClick} = this.props;
        const {survey} = this.state;

        if (survey.title == null || survey.title === '') {
            alert("You should input valid title.");
            return;
        }
        if (survey.text == null || survey.text === '') {
            alert("You should input valid text.");
            return;
        }
        if (survey.startTime === 0 || survey.dueTime === 0 || survey.startTime > survey.dueTime) {
            alert("You should input valid start and end time.");
            return;
        }
        if (survey.questions == null || Object.values(survey.questions).length <= 0) {
            alert("You should input at least 1 question.");
            return;
        }
        if (survey.members == null || Object.values(survey.members).length === 0) {
            alert("You should input at least 1 member.");
            return;
        }
        
        writeDb('/survey/' + survey.id, survey, error => {
            if (error) {
                alert("Survey update failed.\n" + error);
            } else {
                alert("Survey update success.");
            }
            if (onCloseClick != null) {
                onCloseClick();
            }
        })
    }

    renderAddView() {
        const { questionToAdd, survey, addOpen } = this.state;

        if (questionToAdd == null) {
            return <div/>;
        }
        
        switch (questionToAdd.type) {
            case QUESTION_TYPE_OPTION:
                return (
                    <SurveyOptionQuestionEdit 
                        survey={survey}
                        question={questionToAdd}
                        isOpen={addOpen}
                        onSaveClick={(question) => {
                            if (survey.questions == null) {
                                survey.questions = {};
                            }
                            survey.questions[question.id] = question;
                            this.setState({survey: survey, addOpen: false});
                        }}
                        onCloseClick={e=>{
                            this.setState({addOpen: false});
                        }} />
                );
            case QUESTION_TYPE_CHECK:
                return (
                    <SurveyCheckQuestionEdit 
                        survey={survey}
                        question={questionToAdd}
                        isOpen={addOpen}
                        onSaveClick={(question) => {
                            if (survey.questions == null) {
                                survey.questions = {};
                            }
                            survey.questions[question.id] = question;
                            this.setState({survey: survey, addOpen: false});
                        }}
                        onCloseClick={e=>{
                            this.setState({addOpen: false});
                        }} />
                );
            case QUESTION_TYPE_TEXT:
                return (
                    <SurveyTextQuestionEdit 
                        survey={survey}
                        question={questionToAdd}
                        isOpen={addOpen}
                        onSaveClick={(question) => {
                            if (survey.questions == null) {
                                survey.questions = {};
                            }
                            survey.questions[question.id] = question;
                            this.setState({survey: survey, addOpen: false});
                        }}
                        onCloseClick={e=>{
                            this.setState({addOpen: false});
                        }} />
                );
            case QUESTION_TYPE_RATING:
                return (
                    <SurveyRatingQuestionEdit 
                        survey={survey}
                        question={questionToAdd}
                        isOpen={addOpen}
                        onSaveClick={(question) => {
                            if (survey.questions == null) {
                                survey.questions = {};
                            }
                            survey.questions[question.id] = question;
                            this.setState({survey: survey, addOpen: false});
                        }}
                        onCloseClick={e=>{
                            this.setState({addOpen: false});
                        }} />
                );
            default:
                return (
                    <div className='divider'/>);
        }

    }
    
    render() {
        const {user, onCloseClick, onSaveClick, forCreate} = this.props;
        const {survey, available, progress, addOpen} = this.state;
        const questions = [];
        const isReview = true;

        const defaultSliderValues = [
            survey.redValue != null ? survey.redValue : 10,
            survey.warningValue != null ? survey.warningValue : 20,
            survey.yellowValue != null ? survey.yellowValue : 40,
            survey.goodValue != null ? survey.goodValue : 67,
        ]

        if (survey.questions != null) {
            
            for (const [key, value] of Object.entries(survey.questions)) {
                
                switch (value.type) {
                    case QUESTION_TYPE_OPTION:
                        questions.push(
                            <SurveyOptionQuestion 
                                key={key}
                                survey={survey}
                                allowEdit={true}
                                isReview={isReview}
                                question={value} />
                        );
                        questions.push(
                            <div className='divider'/>);
                        break;
                    case QUESTION_TYPE_CHECK:
                        questions.push(
                            <SurveyCheckQuestion 
                                key={key}
                                survey={survey}
                                allowEdit={true}
                                isReview={isReview}
                                question={value} />
                        );
                        questions.push(
                            <div className='divider'/>);
                        break;
                    case QUESTION_TYPE_TEXT:
                        questions.push(
                            <SurveyTextQuestion 
                                key={key}
                                survey={survey}
                                allowEdit={true}
                                isReview={isReview}
                                question={value} />
                        );
                        questions.push(
                            <div className='divider'/>);
                        break;
                    case QUESTION_TYPE_RATING:
                        questions.push(
                            <SurveyRatingQuestion 
                                key={key}
                                survey={survey}
                                allowEdit={true}
                                isReview={isReview}
                                question={value} />
                        );
                        questions.push(
                            <div className='divider'/>);
                        break;
                    default:
                        break;
                }

            }
        }
        
        
        if (survey != null) {
            let dueTime = survey.dueTime == null ? new Date() : new Date(survey.dueTime);
            let startTime = survey.startTime == null ? new Date() : new Date(survey.startTime);
            return (
                <Card>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="title">{t('title')}</Label>
                                <Input type="text" name="title" id="title" onChange={e => {
                                    survey.title = e.target.value;
                                    this.setState({survey: survey});
                                }} value={survey.title} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="text">Text</Label>
                                <Input type="text" name="text" id="text" onChange={e => {
                                    survey.text = e.target.value;
                                    this.setState({survey: survey});
                                }} value={survey.text} />
                            </FormGroup>
                            <FormGroup style={{maxWidth: 400}}>
                                <Slider
                                    id="sectionWeights"
                                    track="inverted"
                                    aria-labelledby="track-inverted-range-slider"
                                    defaultValue={defaultSliderValues}
                                    onChange={ (e, val) => {
                                        let sorted = getSortedArray(val);
                                        survey.redValue = sorted[0];
                                        survey.warningValue = sorted[1];
                                        survey.yellowValue = sorted[2];
                                        survey.goodValue = sorted[3];
                                        
                                        this.setState({weights: survey});
                                    }}
                                />
                                
                                <Doughnut type='line' data={
                                    {
                                        datasets: [
                                            {
                                                data: [
                                                    survey.redValue, 
                                                    survey.warningValue - survey.redValue, 
                                                    survey.yellowValue - survey.warningValue, 
                                                    survey.goodValue - survey.yellowValue, 
                                                    100 - survey.goodValue
                                                ],
                                                backgroundColor: [
                                                    '#ea3f4f',
                                                    '#f6803c',
                                                    '#fcf232',
                                                    '#42cf3c',
                                                    '#57C9EE',
                                                ],
                                                label: 'Threshold Values'
                                            }
                                        ],
                                        labels: [
                                            'Danger: 0 ~ ' + survey.redValue, 
                                            'Worse: ' + survey.redValue + ' ~ ' + survey.warningValue, 
                                            'Bad: ' + survey.warningValue + ' ~ ' + survey.yellowValue, 
                                            'Good: ' + survey.yellowValue + ' ~ ' + survey.goodValue, 
                                            'Excellent: ' + survey.goodValue + ' ~ 100'
                                        ]
                                    }
                                } />

                            </FormGroup>
                            {!forCreate && (
                                <div>
                                    <FormGroup>
                                        <p for="startTime" >Start: {toDateTimeString(startTime)}</p>
                                        <div id="startTime">
                                            <CustomDateTimePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="MM/DD/YYYY hh:mm"
                                                onChange={(value) => {
                                                    survey.startTime = value.valueOf();
                                                    this.setState({survey: survey});
                                                }}
                                                value={startTime} />
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                        <p for="dueTime" >Due: {toDateTimeString(dueTime)}</p>
                                        <div id="dueTime">
                                            <CustomDateTimePicker
                                                margin="normal"
                                                id="date-picker-dialog"
                                                format="MM/DD/YYYY hh:mm"
                                                onChange={(value) => {
                                                    survey.dueTime = value.valueOf();
                                                    this.setState({survey: survey});
                                                }}
                                                value={dueTime} />
                                        </div>
                                    </FormGroup>
                                </div>
                            )}
                            <FormGroup>
                                <div className='wrap-content-parent'>
                                    <div className='wrap-content-fill-child'>
                                        Questions
                                    </div>
                                    <div className='wrap-content-wrap-child'>
                                        +|
                                        <MdCheckCircle onClick={e=>{
                                            const id = generateId('/survey/' + (survey.id != null ? survey.id : 'tmp') + '/questions');

                                            const update = {
                                                id: id,
                                                text: '',
                                                type: QUESTION_TYPE_CHECK,
                                                providerId: user.uid,
                                                providerType: user.type,
                                                surveyId: survey.id,
                                                surveyType: survey.type,
                                            }
                                            this.setState({questionToAdd: update, addOpen: true});
                                        }}/>
                                        <MdRadioButtonChecked onClick={e=>{
                                            const id = generateId('/survey/' + (survey.id != null ? survey.id : 'tmp') + '/questions');

                                            const update = {
                                                id: id,
                                                text: '',
                                                type: QUESTION_TYPE_OPTION,
                                                providerId: user.uid,
                                                providerType: user.type,
                                                surveyId: survey.id,
                                                surveyType: survey.type,
                                            }
                                            this.setState({questionToAdd: update, addOpen: true});
                                        }}/>
                                        <MdTextFields onClick={e=>{
                                            const id = generateId('/survey/' + (survey.id != null ? survey.id : 'tmp') + '/questions');

                                            const update = {
                                                id: id,
                                                text: '',
                                                type: QUESTION_TYPE_TEXT,
                                                providerId: user.uid,
                                                providerType: user.type,
                                                surveyId: survey.id,
                                                surveyType: survey.type,
                                            }
                                            this.setState({questionToAdd: update, addOpen: true});
                                        }}/>
                                        <MdStarHalf onClick={e=>{
                                            const id = generateId('/survey/' + (survey.id != null ? survey.id : 'tmp') + '/questions');

                                            const update = {
                                                id: id,
                                                text: '',
                                                type: QUESTION_TYPE_RATING,
                                                providerId: user.uid,
                                                providerType: user.type,
                                                surveyId: survey.id,
                                                surveyType: survey.type,
                                            }
                                            this.setState({questionToAdd: update, addOpen: true});
                                        }}/>
                                    </div>
                                </div>
                                <div className='divider'/>
                                {questions}
                            </FormGroup>
                            <FormGroup>
                                <div>Members</div>
                                <div className='divider'/>
                                <MembersView
                                    available={available}
                                    members={survey.members}
                                    onSaveClick={(members)=> {
                                        survey.members = members
                                        this.setState({survey: survey});
                                    }} />
                            </FormGroup>
                            {progress ? (
                                <Spinner color="primary" />
                            ) : (
                                <div>
                                    <Button className='modal-button btn-submit' onClick={e => {
                                        if (onSaveClick != null) {
                                            onSaveClick(survey);
                                        } else {
                                            this.submitChange();
                                        }
                                    }}>{t('save')}</Button>
                                    <Button className='modal-button btn-cancel' onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </div>
                            )}
                        </Form>
                    </CardBody>
                    <Modal isOpen={addOpen}>
                        <ModalBody>
                            {this.renderAddView()}
                        </ModalBody>
                    </Modal>
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SurveyEditView);