import React, { Component } from "react";
import { t } from 'i18next';

import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import SeasonStanding from "components/detail/organization/SeasonStanding";
import { STATUS_REGISTER } from "utils/constants";

export class OrganizationDivisionStanding extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            editTeamData: false,
            editLocation: false,
            progress: false,
            imageProgress: 0,
            windowWidth: window.innerWidth,
        };
    }

    componentDidUpdate(prevProps) {
        const {organization} = this.props;
        if (organization !== prevProps.organization) {
            for (const [key, value] of Object.entries(organization)) {
                organization[key] = value;
            }
            this.setState({
                organization: organization,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }


    render() {
        const {organization, seasonChoice, division, seasons, teams, users, locations} = this.props;
        
        const seasonsChoice = [];
        for (const season of Object.values(seasons)) {
            if (season.divisionId === division.id && season.status && season.status !== STATUS_REGISTER) {
                seasonsChoice.push(season)
            }
        }
        seasonsChoice.sort(function(a, b) {
            return b.startTime - a.startTime;
        })
        
        return (
            <div>
                <ItemsDrowpdownNav
                    choiceTitle={t('choose_season')}
                    title={t('seasons')}
                    dropdownItems={seasonsChoice}
                    editOpen={false}
                    detailOpen={true}
                    detailItem={seasonChoice}
                    onItemChoice={(update)=>{
                        this.props.onSeasonChoice(update);
                    }} />
                <SeasonStanding
                    hideTitle={true}
                    organization={organization}
                    season={seasonChoice}
                    teams={teams}
                    division={division}
                    users={users}
                    locations={locations} />
                <div style={{height: 10}}/>
            </div>
        );
    }
}

export default OrganizationDivisionStanding;