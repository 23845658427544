import React, { Component } from 'react';
import { Col, Row, CardImg } from 'reactstrap';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import LoadingScreen from 'components/detail/loading/LoadingScreen';
import EmptyScreen from 'components/detail/loading/EmptyScreen';

import { 
    FcConferenceCall,
    FcManager,
} from 'react-icons/fc';
import { 
    getOrgId,
} from 'utils/Utils';

import userIcon from 'assets/img/icons/player_photo_default.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';
import OrganizationPlayerCard from 'components/detail/organization/landing/OrganizationPlayerCard';
import OrganizationTeamStandings from 'components/detail/organization/landing/OrganizationTeamStandings';
import OrganizationTeamStatistics from 'components/detail/organization/landing/OrganizationTeamStatistics';
import ClubChatView from 'components/chat/ClubChatView';

import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import CustomTabView from 'components/template/CustomTabView';

class TeamMembers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            team: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            mainTab: 0,
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            gamePast: false,
            schedulePast: false,
            dateChoice: new Date().valueOf(),
            forSignUp: false,
            familyOpened: false,
            detailMode: 'list',
            page: 1,
            upcomingPage: 1,
            balanceInput: 0,
            balanceType: 'direct'
        }
    }
    
    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            this.setState({id: id});
        }
        const seasonId = this.props.router.params ? this.props.router.params.seasonId : null;
        if (seasonId) {
            this.setState({mainTab: 1})
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let customDomain = null;
        let memberId = null;
        if (this.props.router != null && this.props.router.params != null) {
            id = this.props.router.params.id;
            customDomain = this.props.router.params.customDomain;
            memberId = this.props.router.params.memberId;
        }
        const data = !customDomain ? {teamId: id} : {customDomain: customDomain};
        const organizationId = getOrgId();
        if (organizationId) {
            data.organizationId = organizationId;
        }
        invokeHttpsApi('clubQuery-teamLandingQuery', data, (data) => {
            const organizations = {};
            const team = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const submissions = {};
            const trackSubmissions = {};
            const battles = {};
            const challenges = {};
            const fields = {};
            const tracks = {};
            const plans = {};
            const workouts = {};
            const lessons = {};
            const clubSchedules = {};
            const wallets = {};
            const walletPayments = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.team)) {
                    team[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.submissions)) {
                    submissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.trackSubmissions)) {
                    trackSubmissions[key] = value;
                }
                for (const [key, value] of Object.entries(data.battles)) {
                    battles[key] = value;
                }
                for (const [key, value] of Object.entries(data.challenges)) {
                    challenges[key] = value;
                }
                for (const [key, value] of Object.entries(data.tracks)) {
                    tracks[key] = value;
                }
                for (const [key, value] of Object.entries(data.fields)) {
                    fields[key] = value;
                }
                for (const [key, value] of Object.entries(data.plans)) {
                    plans[key] = value;
                }
                for (const [key, value] of Object.entries(data.workouts)) {
                    workouts[key] = value;
                }
                for (const [key, value] of Object.entries(data.lessons)) {
                    lessons[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubSchedules)) {
                    clubSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.wallets)) {
                    wallets[key] = value;
                }
                for (const [key, value] of Object.entries(data.walletPayments)) {
                    walletPayments[key] = value;
                }
            }

            this.setState({
                team: team,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                loading: false,
                battles: battles,
                challenges: challenges,
                submissions: submissions,
                trackSubmissions: trackSubmissions,
                playerChoice: memberId && users[memberId] ? users[memberId] : null,
                tracks: tracks,
                fields: fields,
                plans: plans,
                workouts: workouts,
                lessons: lessons,
                clubSchedules: clubSchedules,
                wallets: wallets,
                walletPayments: walletPayments,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderStanding() {
        const {organizations, seasons, divisions, team, teams, users, cards, posts, locations} = this.state;

        return (
            <div style={{marginTop: 20}}>
                
                <OrganizationTeamStandings
                    organizations={organizations}
                    divisions={divisions}
                    seasons={seasons}
                    team={team}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations} />
            </div>
        );
    }

    renderStatistics() {
        const {organizations, seasons, divisions, team, teams, users, cards, posts, locations} = this.state;

        return (
            <div style={{marginTop: 20}}>
                <OrganizationTeamStatistics
                    organizations={organizations}
                    divisions={divisions}
                    seasons={seasons}
                    team={team}
                    teams={teams}
                    users={users}
                    posts={posts}
                    cards={cards}
                    locations={locations} />
            </div>
        );
    }

    renderPlayers() {
        const {team, users, clubSchedules, battles, fields, plans} = this.state;
        
        let topScore = [];
        for (const val of Object.values(team.playerIds ? team.playerIds : {})) {
            if (users[val]) {

                let item = users[val];
                item.statistics = {
                    uid: val,
                    schedules: 0,
                    battles: 0,
                    fields: 0,
                    plans: 0,
                    training: 0,
                }
                
                for (const item of Object.values(clubSchedules)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.schedules += 1;
                        item.training += 1;
                    }
                }
                
                for (const item of Object.values(battles)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.battles += 1;
                        item.training += 1;
                    }
                }
                
                for (const item of Object.values(fields)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.fields += 1;
                        item.training += 1;
                    }
                }
                
                for (const item of Object.values(plans)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.plans += 1;
                        item.training += 1;
                    }
                }

                topScore.push(item);
            }
        }
        topScore.sort((a,b)=> {
            return b.schedules - a.schedules
        });
        
        let items = [];
        
        for (const item of topScore) {
            
            let thumbImage = item && item.photoUri != null ? item.photoUri : userIcon;
            items.push(
                <Row onClick={e=>{this.setState({playerChoice: item})}}>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {item.username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.schedules}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.training}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        return (
            <div style={{marginTop: 20, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: 'white'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {t('players')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('player')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('schedules')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('training')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderCoaches() {
        const {team, users, clubSchedules, battles, fields, plans} = this.state;
        
        let topScore = [];
        for (const val of Object.values(team.managers ? team.managers : {})) {
            if (users[val]) {

                let item = users[val];
                item.statistics = {
                    uid: val,
                    schedules: 0,
                    training: 0,
                    battles: 0,
                    fields: 0,
                    plans: 0,
                }
                
                for (const item of Object.values(clubSchedules)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.schedules += 1;
                    }
                }
                
                for (const item of Object.values(battles)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.training += 1;
                        item.battles += 1;
                    }
                }
                
                for (const item of Object.values(fields)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.training += 1;
                        item.fields += 1;
                    }
                }
                
                for (const item of Object.values(plans)) {
                    if (item.members && Object.values(item.members).includes(val)) {
                        item.training += 1;
                        item.plans += 1;
                    }
                }

                topScore.push(item);
            }
        }
        topScore.sort((a,b)=> {
            return b.schedules - a.schedules
        });
        
        let items = [];
        
        for (const item of topScore) {
            
            let thumbImage = item && item.photoUri != null ? item.photoUri : userIcon;
            items.push(
                <Row onClick={e=>{this.setState({coachChoice: item})}}>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {item.username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.schedules}
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.statistics.training}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: 'white'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {t('coaches')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB'}}>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('coach')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('schedules')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={4} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('training')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderPlayerCard() {
        const {team, playerChoice, organization, organizations, seasons, divisions, posts, users, teams, cards, locations} = this.state;
        
        return (
            <OrganizationPlayerCard
                team={team}
                organization={organization}
                organizations={organizations}
                divisions={divisions}
                posts={posts}
                users={users}
                teams={teams}
                cards={cards}
                seasons={seasons}
                locations={locations}
                player={playerChoice} 
                onClickClose={() => {
                    this.setState({playerChoice: null})
                }} />
        )
    }

    renderMembers() {
        const {playerChoice} = this.state;

        if (playerChoice) {

            return (
                <div>
                    {this.renderPlayerCard()}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderPlayers()}
                    {this.renderCoaches()}
                </div>
            )
        }
    }

    getUid() {
        const {user, child} = this.props;

        return child ? child.uid : (user ? user.uid : null);
    }

    render() {
        const {user} = this.props;
        const {team, users, mainTab, loading} = this.state;
        if (loading && !team.id) {
            return (
                <LoadingScreen />
            )
        }
        if (team.landingData && team.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        
        let icon = team.iconUri != null ? team.iconUri : teamIcon;

        return (
            <div className='landing-dashboard'>
                <div className='landing-dashboard-header' style={{marginTop: 20, marginBottom: 20}}>
                    <img alt='team' className='home-header-icon' src={icon} />
                    <div className="wrap-content-fill-child">
                        <h1 className="home-header-title-small">
                            {team.title}
                            <span></span>
                        </h1>
                        <div className='wrap-content-parent'>
                            <h3 className="home-header-sub-title" style={{display: 'inline-block'}}>
                                <FcConferenceCall/> {team.playerIds ? Object.values(team.playerIds).length : 0}
                                <FcManager/> {team.managers ? Object.values(team.managers).length : 0}
                            </h3>
                        </div>
                    </div>
                </div>
                
                <div className='landing-dashboard-contents'>
                    
                    <CustomTabView
                        value={mainTab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="scrollable"
                        onChange={(e, value)=> {
                            this.setState({mainTab: value})
                        }}
                        items={[
                            { label: t('general') },
                            { label: t('standing') },
                            { label: t('statistics') },
                        ]} />
                    <div className='landing-dashboard-contents-wrapper'>
                        {mainTab === 0 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderMembers()}
                        </div>
                        )}
                        {mainTab === 1 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderStanding()}
                        </div>
                        )}
                        {mainTab === 2 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderStatistics()}
                        </div>
                        )}
                        {((!team.available && mainTab === 4) || (team.available && mainTab === 5)) && (
                        <div className='landing-dashboard-contents-scroll'>  
                            <Row style={{margin: 0}}>
                                <Col sm={6} xs={12}>
                                    {this.renderGames()}
                                </Col>
                                <Col sm={6} xs={12}>
                                    {this.renderSchedules()}
                                </Col>
                            </Row>
                        </div>
                        )}
                        {/* {mainTab === 6 && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderTransactions()}
                        </div>
                        )} */}
                        {((!team.available && mainTab === 5) || (team.available && mainTab === 6)) && (
                        <div className='landing-dashboard-contents-scroll'>  
                            {this.renderFamily()}
                        </div>
                        )}
                        
                    </div>
                </div>
                <ClubChatView
                    uid={user ? user.uid : null}
                    user={user}
                    users={users}
                    club={team} />
            </div>
        )

    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user, child } = state.authentication;
    return { alert, loggingIn, user, child };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
    login: userActions.login,
    logout: userActions.logout,
    setChild: userActions.setChild,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamMembers));
export { connectedApp as TeamMembers };