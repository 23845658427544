import React, { Component } from "react";
import {
    Card, Row, Col
} from 'reactstrap';

import SubInfoView from 'components/detail/SubInfoView';
import globeIcon from 'assets/img/icons/location_globe.png'
import defaultBg from 'assets/img/icons/stadium-header.jpg';
import defaultImage from 'assets/img/icons/shield_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/leagues_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import { getOrgDomain } from "utils/Utils";

export class OrganizationBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, seasons, profileData,  } = this.props;
        const homeLink = getOrgDomain(organization);
        let challengeImage = organization.thumbUri != null ? organization.thumbUri : defaultImage;
        let teamCount = organization.teamIds == null ? 0 : Object.values(organization.teamIds).length;
        let seasonCount = 0;
        let playerCount = 0;
        if (profileData != null) {
            for (const value of Object.values(profileData)) {
                if (value.teamId != null && organization.teamIds != null && Object.values(organization.teamIds).includes(value.teamId) 
                && value.organizationId === organization.id) {
                    playerCount ++;
                }
            }
        }
        if (seasons != null) {
            for (const value of Object.values(seasons)) {
                if (value.organizationId === organization.id) {
                    seasonCount ++;
                }
            }
        }
        
        if (organization != null) {
            return (
                <div className="">
                    <Card className="shadow-tile">
                        <img src={defaultBg} alt='bg' style={{width: '100%', height: 90, objectFit: 'cover'}}/>
                        <img src={challengeImage} alt='bg' style={{width: 70, height: 70, objectFit: 'cover', marginLeft: 20, marginTop: -50, backgroundColor: 'white', border: '1px solid #ccc', borderRadius: 35}}/>
                        <div style={{margin: '5px 20px', textAlign: 'left'}}>
                            <h4 className='grid-item-title'>
                            {organization.title}
                            </h4>
                            <div className="grid-item-info" style={{minHeight: 50}}>{organization.description}</div>
                        </div>
                        <div className='grid-item-row' style={{margin: '0px 20px'}}>
                            <img src={globeIcon} alt='globe' style={{width: 30, height: 30}} />
                            <h4 className='grid-item-title' style={{margin: 'auto 10px'}}>
                            Locations: {organization.locationIds ? Object.values(organization.locationIds).length : 0}
                            </h4>
                        </div>
                        <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div>
                                    <SubInfoView title='Seasons' icon={venueIcon} number={seasonCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div>
                                    <SubInfoView title='Teams' icon={teamIcon} number={teamCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div>
                                    <SubInfoView title='Players' icon={playersIcon} number={playerCount} />
                                </div>
                            </Col>
                        </Row>
                        <a
                            href={homeLink} 
                            className="btn-coral"
                            style={{margin: '0px 30px 30px'}}
                        >
                            View Website
                        </a>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default OrganizationBadge;