import React, { Component } from "react";
import {
    CardImg, Button
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import * as YoutubePlayer from 'react-player/youtube';
import { renderHtmlString } from 'utils/Utils';
import { 
    MdClose
} from 'react-icons/md';

export class FaqDetails extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const { faq, onCloseClick, onClose } = this.props;
        
        if (faq != null) {
            return (
                <div className="faq-detail-wrapper">
                    <div className="container">
                        <div className="wrap-content-parent">
                            <h2 className="wrap-content-fill-child">
                            {faq.title}
                            </h2>
                            {onClose && (
                            <Button style={{width: 42, height: 42}} className='btn-submit faq-comment-btn wrap-content-wrap-child' onClick={e => {
                                onClose();
                            }}><MdClose/></Button>
                            )}
                        </div>
                    </div>
                    <div id='detail-header' className="container faq-content-wrapper">
                        {faq.photoItem != null && faq.photoItem.url != null && faq.photoItem.url !== '' && (
                        <div className="faq-media-wrapper">
                        <div className="faq-media-child">
                            <CardImg 
                                className='task-image'
                                src={faq.photoItem.url} />
                        </div>
                        </div>
                        )}
                        
                        {faq.youtube != null && faq.youtube !== '' && (
                        <div className="faq-media-wrapper">
                        <div className="faq-media-child">
                            <YoutubePlayer 
                                width="100%"
                                height="100%"
                                playing 
                                stopOnUnmount 
                                controls 
                                url={faq.youtube} />
                        </div>
                        </div>
                        )}
                        <div style={{marginTop: 20}}/>
                        <div className='app'>
                            {renderHtmlString(faq.body ? faq.body : "")}
                        </div>
                        
                    </div>
                    {onCloseClick && (
                    <div className="faq-detail-input" style={{paddingTop: 5}}>
                        <div className="wrap-content-parent">
                            <div className="wrap-content-fill-child"/>
                            <Button className='btn-submit faq-comment-btn wrap-content-wrap-child' onClick={e => {
                                if (onCloseClick) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(FaqDetails);