import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AttachVideoEdit from 'components/edit/AttachVideoEdit';

import { 
    uploadVideoToStorage, 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class TutorialEdit extends Component {
    constructor(props) {
        super(props);
        const tutorial = {};
        if (props.tutorial != null) {
            for (const [key, value] of Object.entries(props.tutorial)) {
                tutorial[key] = value;
            }
        }
        
        this.state = {
            tutorial: tutorial,
            progress: false,
            newImage: {},
            newVideo: {},
            videoProgress: 0,
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, tutorial} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                newFile: {},
                newVideo: {},
                videoProgress: 0,
                imageProgress: 0
            })
        }
        if ((prevProps.tutorial !== tutorial)) {
            const tutorialVal = {};
            for (const [key, value] of Object.entries(tutorial)) {
                tutorialVal[key] = value;
            }
            this.setState({
                tutorial: tutorialVal
            })
        }
    }

    onSaveClick(tutorial, newImage, newVideo) {
        const objRef = this;
        if (tutorial.title == null || tutorial.title === '') {
            alert("You must input title");
        } else if (tutorial.description == null || tutorial.description === '') {
            alert("You must input description");
        } else if ((tutorial.id == null || tutorial.id === '') && (newVideo == null || newVideo.name == null)) {
            alert("You must input video");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (tutorial.id == null || tutorial.id === '') {
                tutorial.id = generateId("/tutorials");
            }
            tutorial.timestamp = dbTimestamp();
            
            if (newVideo != null && newVideo.name != null) {
                this.saveChangeWithVideo(tutorial, newVideo, (tutorial, tutorialVideo) => {
                    this.saveChangeWithImage(tutorial, newImage, (update) => {
                        alert("Your Change subimitted successfully.");
                        objRef.setState({
                            tutorial: update,
                            tutorialVideo: tutorialVideo,
                            progress: false
                        })
                    })
                })
            } else {
                this.saveChangeWithImage(tutorial, newImage, (update) => {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        tutorial: update,
                        progress: false
                    })
                })
            }
        }
    }

    saveChangeWithVideo(tutorial, newVideo, sucess) {
        const objRef = this;

        uploadVideoToStorage(tutorial.id, newVideo, 'Tutorial', url => {
            tutorial.videoUri = url;
    
            sucess(tutorial);
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({videoProgress: progress});
            } else {
                this.setState({videoProgress: 0});
            }
        }, error => {
            alert("Saving Video File Failed.\n" + error);
            objRef.setState({
                progress: false
            })
        })
    }

    saveChangeWithImage(tutorial, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(tutorial.id, 'tutorials', newImage, (url) => {
                tutorial.thumbUri = url;
                objRef.saveChange(tutorial, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(tutorial, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    tutorial: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(tutorial, success, failure) {
        writeDb('/tutorials/' + tutorial.id, tutorial, error => {
            if (error) {
                failure(error);
            } else {
                success(tutorial);
            }
        })
    }

    render() {
        const {onCloseClick} = this.props;
        const {tutorial, newImage, newVideo, progress, imageProgress, videoProgress} = this.state;
        
        if (tutorial != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            tutorial.title = e.target.value;
                            this.setState({tutorial: tutorial});
                        }} value={tutorial.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={tutorial.description} onChange={e => {
                            tutorial.description = e.target.value;
                            this.setState({tutorial: tutorial});
                        }} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={tutorial.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    <AttachVideoEdit
                        videoUrl={tutorial.videoUri}
                        videoClass='default-player'
                        label='Tutorial Video'
                        allowProgress={progress}
                        progress={videoProgress}
                        onChange={(file)=> {
                            this.setState({newVideo: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(tutorial, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TutorialEdit);