import React, { Component } from "react";
import {
    Button, Form, Col, Row, CardImg, Modal, ModalBody, Spinner, Input
} from 'reactstrap';
import { t } from 'i18next';

import { dbTimestamp, generateId, saveSeason } from "utils/API";
import { withRouter } from "utils/Routher";
import { sortByTitle } from "utils/Utils";
import teamIcon from 'assets/img/icons/club_icon_default.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import { SeasonTeamDetails } from "components/detail/organization/SeasonTeamDetails";
import { LeagueRoundSetup } from "components/edit/organization/setup/LeagueRoundSetup";
import { GroupRoundSetup } from "components/edit/organization/setup/GroupRoundSetup";
import { KnockoutRoundSetup } from "components/edit/organization/setup/KnockoutRoundSetup";
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, ROUND_TYPE_LEAGUE, SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT, 
    STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from "utils/constants";
import { getSeasonRoundArray } from "utils/statistics";

export class SeasonScheduleView extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            step: 1,
            progress: false,
            divisionChoice: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    onSaveClick(season, toScheduling) {
        const {organization, onSaveDone, onContinue} = this.props;
        const {divisionChoice} = this.state;
        
        if (season.title == null || season.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (season.id == null || season.id === '') {
                season.id = generateId("/season");
            }
            season.owner = organization.owner;
            season.organizationId = organization.id;
            season.divisionId = divisionChoice.id;
            season.category = {}
            if (divisionChoice.category != null) {
                for (const [key, value] of Object.entries(divisionChoice.category)) {
                    season.category[key] = value;
                }
            }
            season.timestamp = dbTimestamp();
            
            this.saveChange(season, (update) => {
                alert("Your Change subimitted successfully.");
                if (toScheduling && onSaveDone != null) {
                    onSaveDone(update);
                }
                if (toScheduling && onContinue != null) {
                    onContinue(update);
                }
                this.setState({
                    progress: false
                });
            }, (error) => {
                alert(t("request_failed"));
            })
        }
    }
    
    saveChange(season, success, failure) {
        saveSeason(season, success, failure);
    }

    renderStepTitle(num, title) {
        return (
        <div className="season-create-step-header">
            <div className="season-create-step-num">
                {num}
            </div>
            <div className="season-create-step-title">
                {title}
            </div>
        </div>
        )
    }

    renderTeamItem(team, status) {
        const { season, divisions, onSeasonUpdate } = this.props;
        let thumbImage = team.iconUri != null ? team.iconUri : teamIcon;
        let playerCount = team.playerIds == null ? 0 : Object.values(team.playerIds).length;
        const division = divisions[season.divisionId] ? divisions[season.divisionId] : {};

        
        return (
            <Row key={team.id}>
                <Col lg={5} xs={8} sm={5} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={thumbImage} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {team.title}
                        </h4>
                    </div>
                </Col>
                <Col lg={2} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon-small"
                            src={playersIcon} />
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {playerCount}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={2} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {status === 0 && t('member')}
                        {status === 1 && t('division')}
                        {status === 2 && t('organization')}
                        </div>
                    </div>
                </Col>
                <Col lg={4} xs={12} sm={3} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                        </div>
                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                            {status === 0 && (
                                <div className='wrap-content-parent'>
                                <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                    this.setState({teamChoice: team});
                                }}>{t('view')}</Button>
                                <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                    if (season.teamIds && season.teamIds[team.id]) {
                                        delete season.teamIds[team.id];
                                    }
                                    
                                    onSeasonUpdate(season);
                                }}>{t('remove')}</Button>
                                </div>
                            )}
                            {status === 1 && (
                                <div className='wrap-content-parent'>
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        if (season.pendingIds && season.pendingIds[team.id]) {
                                            delete season.pendingIds[team.id];
                                        }
                                        if (!season.teamIds) {
                                            season.teamIds = {};
                                        }
                                        season.teamIds[team.id] = team.id;
                                        onSeasonUpdate(season);
                                    }}>{t('add')}</Button>
                                </div>
                            )}
                            {status === 2 && (
                                <div className='wrap-content-parent'>
                                    <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                        if (season.pendingIds && season.pendingIds[team.id]) {
                                            delete season.pendingIds[team.id];
                                        }
                                        if (!season.teamIds) {
                                            season.teamIds = {};
                                        }
                                        season.teamIds[team.id] = team.id;
                                        if (!division.teamIds) {
                                            division.teamIds = {};
                                        }
                                        division.teamIds[team.id] = team.id;
                                        onSeasonUpdate(season, division);
                                    }}>{t('add')}</Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }

    renderTeams() {
        const { season, divisions, onSeasonUpdate, organization, teams, onCloseClick } = this.props;
        const { progress } = this.state;
        
        const division = divisions[season.divisionId] ? divisions[season.divisionId] : {};
        let seasonTeams = [];
        let divisionTeams = [];
        let organizationTeams = [];
        if (season.teamIds) {
            for (const key of Object.values(season.teamIds)) {
                const team = teams[key];
                if (team) {
                    seasonTeams.push(team);
                }
            }
        }
        if (division.teamIds) {
            for (const key of Object.values(division.teamIds)) {
                const team = teams[key];
                if (team && !seasonTeams.includes(team)) {
                    divisionTeams.push(team);
                }
            }
        }
        if (organization && organization.teamIds) {
            for (const key of Object.values(organization.teamIds)) {
                const team = teams[key];
                if (team && !seasonTeams.includes(team) && !divisionTeams.includes(team)) {
                    organizationTeams.push(team);
                }
            }
        }
        sortByTitle(seasonTeams);
        sortByTitle(divisionTeams);
        sortByTitle(organizationTeams);

        let items = [];
        for (const val of seasonTeams) {
            items.push(
                this.renderTeamItem(val, 0)
            )
            items.push(<div className='divider' />);
        }
        for (const val of divisionTeams) {
            items.push(
                this.renderTeamItem(val, 1)
            )
            items.push(<div className='divider' />);
        }
        for (const val of organizationTeams) {
            items.push(
                this.renderTeamItem(val, 2)
            )
            items.push(<div className='divider' />);
        }
        return (
            <div>
                {this.renderStepTitle(1, t('season_schedule_step_1'))}
                
                <div className="overlay-limited-height">
                    <div className='shadow-tile'>
                        <Row style={{backgroundColor: '#19447F'}}>
                            <Col xs={12} className="vertical-center">
                                <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                    {t('teams')}: {seasonTeams.length} members
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        <Row style={{backgroundColor: '#85CEEB'}}>
                            <Col lg={5} xs={7} sm={5} className="vertical-center">
                                <h3 className='row-table-title'>
                                    {t('team')}
                                </h3>
                            </Col>
                            <Col lg={2} xs={3} sm={2} className="vertical-center">
                                <h3 className='row-table-title'>
                                    {t('players')}
                                </h3>
                            </Col>
                            <Col lg={1} xs={2} sm={2} className="vertical-center">
                                <h3 className='row-table-title'>
                                    {t('status')}
                                </h3>
                            </Col>
                            <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items}
                    </div>
                </div>
                <div style={{height: 30}}/>
                <Row className='form-edit-row text-center'>
                    <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                        {progress ? (<Spinner />) : (
                        <Button className='btn-blue' onClick={e => {
                            if (!season.teamIds || Object.values(season.teamIds).length < 3) {
                                alert("no_required_team_prompt");
                                return;
                            }
                            this.setState({progress: true});
                            saveSeason(season, (update) => {
                                onSeasonUpdate(season);
                                this.setState({progress: false});
                            }, (error) => {
                                this.setState({progress: false});
                            })
                        }}>{t('save')}</Button>
                        )}
                    </Col>
                    <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                        <Button className='btn-blue' onClick={e => {
                            if (!season.teamIds || Object.values(season.teamIds).length < 3) {
                                alert("no_required_team_prompt");
                                return;
                            }
                            season.status = STATUS_SCHEDULE;
                            onSeasonUpdate(season);
                        }}>{t('next')}</Button>
                        <div className="wrap-content-fill-child"/>
                        <Button className='btn-cancel-blue' onClick={e => {
                            if (onCloseClick != null) {
                                onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderRoundSetupItem(round, onClickBack) {
        const { season, divisions, locations, organization, teams, users, onSeasonUpdate } = this.props;
        const division = divisions[season.divisionId] ? divisions[season.divisionId] : {};
        if (round.type === ROUND_TYPE_GROUP) {
            return (
                <GroupRoundSetup
                    season={season}
                    teams={teams}
                    users={users}
                    division={division}
                    locations={locations}
                    organization={organization}
                    onClickBack={onClickBack}
                    subSeason={round}
                    onSeasonUpdate={onSeasonUpdate}
                    onCloseClick={()=> {
                        this.setState({roundChoice: null});
                    }}
                    onTeamChoice={team => {
                        this.setState({teamChoice: team});
                    }} />
            )
        }
        if (round.type === ROUND_TYPE_KNOCKOUT) {
            return (
                <KnockoutRoundSetup
                    season={season}
                    teams={teams}
                    users={users}
                    division={division}
                    locations={locations}
                    organization={organization}
                    onClickBack={onClickBack}
                    subSeason={round}
                    onSeasonUpdate={onSeasonUpdate}
                    onCloseClick={()=> {
                        this.setState({roundChoice: null});
                    }}
                    onTeamChoice={team => {
                        this.setState({teamChoice: team});
                    }} />
            )
        }

        return (
            <LeagueRoundSetup
                season={season}
                teams={teams}
                users={users}
                division={division}
                locations={locations}
                organization={organization}
                onClickBack={onClickBack}
                subSeason={round}
                onSeasonUpdate={onSeasonUpdate}
                onCloseClick={()=> {
                    this.setState({roundChoice: null});
                }}
                onTeamChoice={team => {
                    this.setState({teamChoice: team});
                }} />
        )
    }

    renderSchedule() {
        const { season, onSeasonUpdate, onCloseClick } = this.props;
        const { roundChoice, customType } = this.state;

        let setupView = <div />;
        let controlView = (
            <Row className='form-edit-row text-center'>
                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                    <Button className='btn-cancel-blue' onClick={e => {
                        season.status = STATUS_REGISTER;
                        onSeasonUpdate(season);
                    }}>{t('back')}</Button>
                </Col>
                <Col className='form-edit-entry wrap-content-parent-c' sm={7} xs={7}>
                    <Button className='btn-blue' onClick={e => {
                        if (!season.startTime) {
                            alert("no_start_time_prompt");
                            return;
                        }
                        if (!season.regDeadline) {
                            alert("no_register_time_prompt");
                            return;
                        }
                        if (!season.endTime || season.endTime < season.startTime) {
                            alert("no_end_time_prompt");
                            return;
                        }
                        season.status = STATUS_ONGOING;
                        onSeasonUpdate(season);
                    }}>{t('finish')}</Button>
                    <div className="wrap-content-fill-child"/>
                    <Button className='btn-cancel-blue' onClick={e => {
                        if (onCloseClick != null) {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </Col>
            </Row>
        );

        const round = {
            seasonId: season.id,
            divisionId: season.divisionId,
            organizationId: season.organizationId,
            owner: season.owner,
        }

        let addView = <div/>;

        if (!roundChoice && !season.subSeasons) {
            if (season.type === SEASON_TYPE_LEAGUE) {
                round.id = ROUND_TYPE_LEAGUE;
                round.type = ROUND_TYPE_LEAGUE;
                round.title = "League";
            } else if (season.type === SEASON_TYPE_TOURNAMENT) {
                round.id = ROUND_TYPE_GROUP;
                round.type = ROUND_TYPE_GROUP;
                round.title = "Group Round";
                round.startTeams = season.startTeams;
                round.winnerCount = season.knockoutTeamCount;
                round.groupCount = season.groupCount;
            } else if (season.type === SEASON_TYPE_PLAYOFF) {
                round.id = ROUND_TYPE_LEAGUE;
                round.type = ROUND_TYPE_LEAGUE;
                round.title = "League Round";
                round.winnerCount = season.knockoutTeamCount;
            } else if (season.type === SEASON_TYPE_CUSTOM) {
                round.id = season.qualification;
                round.type = season.qualification;
                round.title = "Qualification Round";
            }

            setupView =  (
                <div>
                    {this.renderRoundSetupItem(round, e => {
                        season.status = STATUS_REGISTER;
                        onSeasonUpdate(season);
                    })}
                </div>
            )
            controlView = <div />

        } else if (!roundChoice && season.subSeasons && season.type !== SEASON_TYPE_LEAGUE && (!season.subSeasons.knockout || !season.subSeasons.knockout.games)) {
            round.id = ROUND_TYPE_KNOCKOUT;
            round.type = ROUND_TYPE_KNOCKOUT;
            round.startTeams = season.knockoutTeamCount;
            round.title = "Knockout Round";

            setupView =  (
                <div>
                    {this.renderRoundSetupItem(round, e => {
                        season.status = STATUS_REGISTER;
                        onSeasonUpdate(season);
                    })}
                </div>
            )
            controlView = <div />

        } else if (roundChoice) {
            setupView =  (
                <div>
                    {this.renderRoundSetupItem(roundChoice, e => {
                        season.status = STATUS_REGISTER;
                        onSeasonUpdate(season);
                    })}
                </div>
            )
            controlView = <div />
        } else {
            setupView = this.renderRoundList();
            addView = (
                <div style={{margin: '10px 0px'}}>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('add_custom_round')}
                        </Col>
                        <Col className='form-edit-entry' sm={4} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                this.setState({customType: e.target.value});
                            }} value={customType}>
                                <option value={null}></option>
                                <option value={ROUND_TYPE_LEAGUE}>{t('league')}</option>
                                <option value={ROUND_TYPE_GROUP}>{t('group')}</option>
                                <option value={ROUND_TYPE_KNOCKOUT}>{t('knockout')}</option>
                            </Input>
                            </div>
                        </Col>
                        <Col className='text-center' sm={3} xs={12}>
                            <Button className="btn-blue" onClick={e => {
                                if (!customType || customType === "") {
                                    alert(t('invalid_round_type'));
                                    return;
                                }
                                const round = {
                                    seasonId: season.id,
                                    divisionId: season.divisionId,
                                    organizationId: season.organizationId,
                                    owner: season.owner,
                                    id: generateId('/season/' + season.id + '/subSeasons/')
                                }
                        
                                if (customType === ROUND_TYPE_LEAGUE) {
                                    round.type = ROUND_TYPE_LEAGUE;
                                    round.title = "League";
                                } else if (customType === ROUND_TYPE_GROUP) {
                                    round.type = ROUND_TYPE_GROUP;
                                    round.title = "Group Round";
                                    round.startTeams = season.startTeams;
                                    round.winnerCount = season.knockoutTeamCount;
                                    round.groupCount = season.groupCount;
                                } else if (season.type === ROUND_TYPE_KNOCKOUT) {
                                    round.type = ROUND_TYPE_KNOCKOUT;
                                    round.startTeams = season.knockoutTeamCount;
                                    round.title = "Knockout Round";
                                }
                                
                                this.setState({roundChoice: round});
                            }}>{t('add')}</Button>
                        </Col>
                    </Row>
                </div>
            )
        }

        return (
            <div>
                {this.renderStepTitle(2, t('season_schedule_step_2'))}
                
                {addView}
                {setupView}
                <div style={{height: 30}}/>
                {controlView}
            </div>
        )
    }

    renderRoundList() {
        const { season } = this.props;

        let subSeasonArr = getSeasonRoundArray(season);

        return (
            <div className='shadow-tile'>
                <Row style={{backgroundColor: '#19447F'}}>
                    <Col xs={12} className="vertical-center">
                        <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {t('season_rounds')}: {subSeasonArr.length}
                        </h3>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                <Row style={{backgroundColor: '#85CEEB'}}>
                    <Col lg={5} xs={7} sm={5} className="vertical-center">
                        <h3 className='row-table-title' style={{textAlign: 'left'}}>
                            {t('team')}
                        </h3>
                    </Col>
                    <Col lg={2} xs={3} sm={2} className="vertical-center">
                        <h3 className='row-table-title'>
                            {t('round_type')}
                        </h3>
                    </Col>
                    <Col lg={1} xs={2} sm={2} className="vertical-center">
                        <h3 className='row-table-title'>
                            
                        </h3>
                    </Col>
                    <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {subSeasonArr.map((round, idx) => (
                <Row key={idx}>
                    <Col lg={5} xs={6} sm={5} className="vertical-center">
                        <h3 className='row-table-title' style={{textAlign: 'left'}}>
                            {round.title}
                        </h3>
                    </Col>
                    <Col lg={2} xs={3} sm={3} className="vertical-center">
                        <h3 className='row-table-title'>
                            {round.type ? round.type.toUpperCase() : ""}
                        </h3>
                    </Col>
                    <Col lg={5} xs={3} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child'/>
                            <Button className="btn-blue" style={{fontSize: 12}} onClick={e => {
                                this.setState({roundChoice: round});
                            }}>{t('setup')}</Button>
                        </div>
                    </Col>
                    <Col xs={12} className="divider" />
                </Row>
                ))}
            </div>
        );
    }

    renderContents() {
        const { season } = this.props;
        
        let status = season.status ? season.status : STATUS_REGISTER;

        return (
            <div>
                {status === STATUS_REGISTER && this.renderTeams()}
                {status === STATUS_SCHEDULE && this.renderSchedule()}
            </div>
        )
    }

    render() {        
        const { season, users, teams, locations, onSeasonUpdate, onUserUpdate, onSaveDone } = this.props;
        const { teamChoice } = this.state;

        return (
            <Form className='form-edit'>
                <Row className='form-edit-row'>
                    <h3 className="text-center">{t("setup_season")}</h3>
                </Row>
                {this.renderContents()}
                {teamChoice && (
                <Modal isOpen={teamChoice != null}>
                    <ModalBody>
                    <SeasonTeamDetails
                        season={season}
                        users={users} 
                        teams={teams} 
                        locations={locations} 
                        teamChoice={teamChoice}
                        onChanged={()=> {
                            if (onSaveDone) {
                                onSaveDone(season);
                            }
                        }}
                        onClickClose={()=> {
                            this.setState({teamChoice: null})
                        }}
                        onSeasonUpdate={(update, teamUpdate)=> {
                            if (onSeasonUpdate) {
                                onSeasonUpdate(update);
                            }
                            if (teamUpdate) {
                                this.setState({teamChoice: teamUpdate})
                            }
                        }}
                        onUserUpdate={onUserUpdate} />
                    </ModalBody>
                </Modal>
                )}
            </Form>
        );
    }
}

export default withRouter(SeasonScheduleView);