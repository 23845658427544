import React, { Component } from "react";
import {
    Col, Row, CardImg
} from 'reactstrap';
import { t } from "i18next";
import userImage from 'assets/img/icons/player_photo_default.png';
import { getSanctionGameIds } from 'utils/Utils';

export default class GameLineUp extends Component {
    renderLineUp(team) {
        const {season, game, users} = this.props;

        let gameList = [];
        if (game.gameList && game.gameList[team.id]) {
            for (const uid of Object.values(game.gameList[team.id])) {
                if (users[uid]) {
                    gameList.push(users[uid]);
                }
            }
        }
        if (gameList.length === 0 && season && season.teamData && season.teamData[team.id]) {
            let teamData = season.teamData[team.id];
            if (teamData.gameList) {
                for (const uid of Object.values(teamData.gameList)) {
                    if (users[uid]) {
                        gameList.push(users[uid]);
                    }
                }
            }
        }
        let benchList = [];
        if (game.benchList && game.benchList[team.id]) {
            for (const uid of Object.values(game.benchList[team.id])) {
                if (users[uid]) {
                    benchList.push(users[uid]);
                }
            }
        }
        if (benchList.length === 0 && season && season.teamData && season.teamData[team.id]) {
            let teamData = season.teamData[team.id];
            if (teamData.benchList) {
                for (const uid of Object.values(teamData.benchList)) {
                    if (users[uid]) {
                        benchList.push(users[uid]);
                    }
                }
            }
        }

        let gameItems = [];
        for (const val of gameList) {
            gameItems.push(
               <div> {this.renderItem(val, team)}</div>
            )
        }
        let benchItems = [];
        for (const val of benchList) {
            benchItems.push(
                <div> {this.renderItem(val, team)}</div>
            )
        }
        const teamData = season.teamData != null && season.teamData[team.id] ? season.teamData[team.id] : {};
        let staffList = [];
        if (teamData.staff) {
            for (const uid of Object.values(teamData.staff)) {
                if (users[uid]) {
                    staffList.push(users[uid]);
                }
            }
        }
        let staffItems = [];
        for (const val of staffList) {
            staffItems.push(
                <div> {this.renderItem(val, team)}</div>
            )
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className="shadow-tile" style={{marginLeft: 0, marginRight: 0}}>
                    <Row>
                        <Col xs={12} className="vertical-center">
                            <h3 className='stat-table-title'>
                                {team.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                        {t('players')}
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {gameItems.length > 0 ? gameItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                        {t('substitute')}
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {benchItems.length > 0 ? benchItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                    <Row style={{backgroundColor: '#85CEEB', margin: 0}}>
                        <h3 className='row-table-title'>
                        {t('coaches')}
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {staffItems.length > 0 ? staffItems : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderItem(item, team) {
        const {season, game, cards} = this.props;
        let thumbImage = item.photoUri != null ? item.photoUri : userImage;
        const gameIds = getSanctionGameIds(season, cards, item.uid, team.id);
        
        const suspended = gameIds.includes(game.id);

        return (
            <Row>
                <div className='wrap-content-parent' style={suspended ? {background: '#ff857c', padding: '0px 10px'} : {padding: '0px 10px'}}>
                    <CardImg
                        className="wrap-content-wrap-child row-badge-icon"
                        src={thumbImage} />
                    <h4 className='wrap-content-fill-child row-badge-title'>
                    {item.username}
                    </h4>
                    {suspended && (
                    <h4 className='wrap-content-wrap-child row-badge-title' style={{color: 'red', fontSize: '7px'}}>
                    Suspended
                    </h4>
                    )}
                </div>
            </Row>
        );
    }

    render() {
        const { teams, game } = this.props;

        const homeTeam = teams[game.homeTeam] != null ? teams[game.homeTeam] : {};
        const awayTeam = teams[game.awayTeam] != null ? teams[game.awayTeam] : {};
        
        
        if (game != null) {
            return (
                <Row style={{margin: 0}}>
                {homeTeam && (
                    <Col sm={6} xs={12} style={{padding: 0}}>
                    {this.renderLineUp(homeTeam)}
                    </Col>
                )}
                {awayTeam && (
                    <Col sm={6} xs={12} style={{padding: 0}}>
                    {this.renderLineUp(awayTeam)}
                    </Col>
                )}
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}
