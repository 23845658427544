import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Countdown from 'react-countdown';

import { 
    CUR_ORG_MEMBERSHIP_DISCOUNT
} from 'utils/Utils';
import { 
    IoMdCloseCircle
} from 'react-icons/io';
import { withRouter } from 'utils/Routher';

class OrganizationPopUp extends Component {
    
    render() {
        const { countDown, onClickClose } = this.props;

        return (
            <div className='popup-bg' style={{margin: 0, padding: '10px 20px'}}>
                <div style={{textAlign: 'right', fontSize: 24}}>
                    <IoMdCloseCircle onClick={e=> {
                        if (onClickClose != null) {
                            onClickClose()
                        }
                    }}/>
                </div>
                <h3 className='popup-title' style={{textAlign: 'left', marginTop: 0}}>New business Offer</h3>
                {CUR_ORG_MEMBERSHIP_DISCOUNT != null && CUR_ORG_MEMBERSHIP_DISCOUNT.discount != null && (
                <div>
                    <h3 className='popup-title' style={{textAlign: 'left', color: '#007AFF'}}>{100 - CUR_ORG_MEMBERSHIP_DISCOUNT.discount}% OFF</h3>
                    <div className='popup-description' style={{textAlign: 'left'}}>On either anual or monthly plans</div>
                    <h4 className='popup-subtitle' style={{textAlign: 'left'}}>CODE: <span style={{color: '#007AFF'}}>{CUR_ORG_MEMBERSHIP_DISCOUNT.code}</span></h4>
                </div>
                )}
                <div style={{fontSize: 10, marginLeft: 5}}>Expires in:</div>
                <div className='popup-countdown'>
                    <Countdown date={countDown}/>
                </div>       
                <div className='popup-countdown-label'>
                DAY HUR  MIN  SEC
                </div>       
                <Button className='btn-pick popup-btn' style={{display: 'inline'}} onClick={ e => {
                    this.props.router.navigate('/organizer/membership');
                }}> Go Premium</Button>
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, redirectTo };
}

const connectedNavigation = withRouter(connect(mapState)(OrganizationPopUp));
export { connectedNavigation as OrganizationPopUp };