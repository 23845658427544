import React, { Component } from 'react';

import DivisionLocationBadge from 'components/detail/organization/DivisionLocationBadge';

export class DivisionLocations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisionChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }
    
    renderContents() {
        const {division, locations} = this.props;

        let items = [];
        let members = [];
        let others = [];
        for (const [key, value] of Object.entries(locations)) {
            const isMember = division.locationIds != null && Object.values(division.locationIds).includes(key);
            
            if (isMember) {
                members.push(value);
            }
            if (!isMember) {
                others.push(value);
            }
        }
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        others.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        for (const value of members) {
            items.push(
                this.renderBadge(division, value)
            );
        }
        for (const value of others) {
            items.push(
                this.renderBadge(division, value)
            );
        }
        return (
        <div>
            {items}
        </div>
        );
    }

    renderBadge(division, location) {
        const {onChanged} = this.props;
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <DivisionLocationBadge 
                    key={location.id}
                    location={location}
                    division={division}
                    allowDetail={true}
                    onChanged={()=> {
                        if (onChanged != null) {
                            onChanged();
                        }
                    }} />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderContents()}
            </div>
        );
    }
}

export default DivisionLocations;
