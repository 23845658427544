import React, { Component } from "react";
import {
    Modal, ModalBody, ModalFooter, Button, ListGroupItem, Form, Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { smallTabBoxStyle, smallTabItemStyle, smallTabStyle } from 'style/styles';
import { 
    GrOverview,
    GrSchedules
} from 'react-icons/gr';
import { 
    FcImport,
    FcTodoList,
} from 'react-icons/fc';
import { 
    GiChampions,
    GiCardPlay,
    GiStairsGoal
} from 'react-icons/gi';
import { 
    MdError,
    MdWarning,
    MdOutlineIntegrationInstructions,
} from 'react-icons/md';
import { getDivisionDomain, getSeasonRoundsArr } from 'utils/Utils';

import ItemsDrowpdownNav from 'components/detail/ItemsDrowpdownNav';
import SeasonOverview from "components/detail/organization/SeasonOverview";
import SeasonTeams from "components/detail/organization/SeasonTeams";
import SeasonStanding from "components/detail/organization/SeasonStanding";
import SeasonGoals from "components/detail/organization/SeasonGoals";
import SeasonSanctions from "components/detail/organization/SeasonSanctions";
import { SeasonImport } from "components/detail/organization/SeasonImport";
import SubSeasonEdit from "components/edit/organization/SubSeasonEdit";
import SeasonGameBadge from "components/detail/organization/SeasonGameBadge";
import {KnockoutRound} from "components/edit/organization/KnockoutRound";
import {GroupRound} from "components/edit/organization/GroupRound";
import {LeagueRound} from "components/edit/organization/LeagueRound";
import {GroupRoundGames} from "components/detail/organization/games/GroupRoundGames";
import {LeagueRoundGames} from "components/detail/organization/games/LeagueRoundGames";
import {KnockoutRoundGames} from "components/detail/organization/games/KnockoutRoundGames";
import { checkProfileData } from 'utils/Utils';
import { mainRedColor } from 'style/styles';
import { invokeHttpsApi } from "utils/API";
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, ROUND_TYPE_LEAGUE, STATUS_FINISHED, STATUS_ONGOING, STATUS_REGISTER, STATUS_SCHEDULE } from "utils/constants";
import { SeasonTeamDetails } from './SeasonTeamDetails';

export class SeasonDetail extends Component {
    constructor(props) {
        super(props);
        const seasonChoice = props.season;
        
        const subSeasons = seasonChoice.subSeasons != null ? seasonChoice.subSeasons : {};
        let choice = null;
        for (const item of Object.values(subSeasons)) {
            if (item.status === STATUS_ONGOING) {
                choice = item;
                break;
            }
        }
        if (choice == null && getSeasonRoundsArr(seasonChoice).length > 0) {
            choice = getSeasonRoundsArr(seasonChoice)[0];
        }
        if (choice == null) {
            choice = {};
        }
        this.state = {
            gamesChoice: choice,
            editOpen: false,
            detailOpen: false,
            pickSeason: false,
            subSeasonChoice: {},
            pickRound: false,
            mainTab: 0,
            windowWidth: window.innerWidth,
            saveProgress: false,
            matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1}
        }
        this.handleChange = this.handleChange.bind(this);
        window.addEventListener('resize', this.getDimensions); 
    }

    componentDidMount() {
        const {organization, season} = this.props;
        const subSeasons = season.subSeasons != null ? season.subSeasons : {};
        let choice = null;
        for (const item of Object.values(subSeasons)) {
            if (item.status === STATUS_ONGOING) {
                choice = item;
                break;
            }
        }
        if (choice == null && getSeasonRoundsArr(season).length > 0) {
            choice = getSeasonRoundsArr(season)[0];
        }
        if (choice == null) {
            choice = {};
        }
        let href = getDivisionDomain(organization, season.divisionId, season.id) + "?tab=game";
        this.setState({
            gamesChoice: choice,
            editOpen: false,
            detailOpen: false,
            pickSeason: false,
            subSeasonChoice: {},
            pickRound: false,
            mainTab: window.location.href.includes(href) ? 1 : 0,
            windowWidth: window.innerWidth,
            saveProgress: false,
            matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1}
        })
    }

    componentDidUpdate(prevProps) {
        const {season} = this.props;
        if (season !== prevProps.season) {
            const subSeasons = season.subSeasons != null ? season.subSeasons : {};
            const choice = Object.values(subSeasons).length > 0 ? Object.values(subSeasons)[0] : {};
            this.setState({
                editOpen: false,
                detailOpen: false,
                pickSeason: false,
                subSeasonChoice: {},
                gamesChoice: choice,
                pickRound: false,
                windowWidth: window.innerWidth,
                saveProgress: false,
                matchDayChoice: {id: t('all_matchdays'), title: t('all_matchdays'), value: -1}
            })
        }
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleChange(e, value) {
        this.setState({mainTab: value});
    }

    renderOverview() {
        const {organization, division, season, seasons, trophies, locations, profileData, onChanged, 
            onSeasonDeleted, teams, users, user, onUserUpdate, wallets } = this.props;

        return (
            <SeasonOverview
                organization={organization}
                division={division}
                locations={locations}
                season={season}
                seasons={seasons}
                trophies={trophies}
                teams={teams}
                users={users}
                user={user}
                wallets={wallets}
                profileData={profileData}
                onSeasonDeleted={()=> {
                    if (onSeasonDeleted != null) {
                        onSeasonDeleted();
                    }
                }}
                onChanged={(update)=> {
                    if (onChanged != null) {
                        onChanged(update);
                    }
                }}
                onSaveClicked={(update)=> {
                    if (onChanged != null) {
                        onChanged(update);
                    }
                }}
                onUserUpdate={(update, team)=> {
                    if (onUserUpdate != null) {
                        onUserUpdate(update, team);
                    }
                }} />
        )
    }

    renderGameWarning(game) {
        const {division, profileData, users, teams} = this.props;

        if (game.gameList == null || Object.values(game.gameList).length < 2) {
            let str =' ' + t('for_both_of_teams')
            if (game.gameList != null) {
                let homeReady = game.gameList[game.homeTeam] != null;
                let awayReady = game.gameList[game.awayTeam] != null;
                if (homeReady) {
                    str = teams[game.awayTeam] != null ? 'for ' + teams[game.awayTeam].title : ' for away team.';
                }
                if (awayReady) {
                    str = teams[game.homeTeam] != null ? 'for ' + teams[game.homeTeam].title : ' for home team.';
                }
            }
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdError/> </span>
                    {t('player_list_not_ready')} {str}
                </div>
            )
        }
        let homeValid = true;
        for (const [key,] of Object.entries(game.gameList[game.homeTeam])) {
            const user = users[key];
            const team = teams[game.homeTeam];
            if (user == null || checkProfileData(division, user, team, profileData) !== 'verified') {
                homeValid = false;
                break;
            }
        }
        let awayValid = true;
        for (const [key,] of Object.entries(game.gameList[game.awayTeam])) {
            const user = users[key];
            const team = teams[game.awayTeam];
            if (user == null || checkProfileData(division, user, team, profileData) !== 'verified') {
                awayValid = false;
                break;
            }
        }
        if (homeValid && awayValid) {
            return <div/>;
        } else {
            let str =' ' + t('for_both_of_teams')
            if (homeValid) {
                str = teams[game.awayTeam] != null ? 'for ' + teams[game.awayTeam].title : ' for away team.';
            }
            if (awayValid) {
                str = teams[game.homeTeam] != null ? 'for ' + teams[game.homeTeam].title : ' for home team.';
            }
            return (
                <div className='club-member-status'>
                    <span style={{color: mainRedColor, marginRight: 10}}><MdWarning/> </span>
                    {t('player_list_not_verified')} {str}
                </div>
            )
        }
    }

    saveGame(game) {
        const {organization, season, onChanged} = this.props;
        
        const data = {
            season: season,
            game: game,
        }
        invokeHttpsApi('clubQuery-saveGameUpdate', data, (data) => {
            if (data.success) {
                alert('Saved Game Successfully');
            } else {
                alert(t('save_failed'));
            }
            season.subSeasons[game.subSeasonId].games[game.id] = game;
            console.log("222222222", season);
            if (onChanged) {
                onChanged(season);
            } else {
                window.location = `${getDivisionDomain(organization, season.divisionId, season.id)}?tab=game`;
            }
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderGames() {
        const {division, organization, season, onChanged, teams, users, locations, cards} = this.props;
        const {gamesChoice, editOpen, detailOpen, matchDayChoice} = this.state;
        
        let gameArr = [];
        if (gamesChoice.games != null) {
            for (const item of Object.values(gamesChoice.games)) {
                if (matchDayChoice.value === -1 || matchDayChoice.value === item.matchDayIndex) {
                    gameArr.push(item);
                }
            }
        }
        let gameDays = {};
        if (gamesChoice.matchDayCount != null) {
            const val = {id: t('all_matchdays'), title: t('all_matchdays'), value: -1}
            gameDays[t('all_matchdays')] = val;
            for (var i = 0; i < gamesChoice.matchDayCount; i ++) {
                const title = t('matchday') + " " +(i + 1).toString();
                const day = {id: title, title: title, value: i}
                gameDays[title] = day;
            }
        }

        gameArr.sort(function(a, b) {
            return a.start - b.start;
        })
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        let editContent = (<div/>)
        if (gamesChoice.type === ROUND_TYPE_KNOCKOUT) {
            editContent = (
                <KnockoutRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    allowEdit={true}
                    users={users}
                    onClickTeam={(team) => {
                        this.setState({teamChoice: team});
                    }}
                    onSaveDone={(update)=> {
                        if (onChanged != null) {
                            season.subSeasons[update.id] = update;
                            onChanged(season);
                        }
                    }} />
            )
        } else if (gamesChoice.type === ROUND_TYPE_GROUP) {
            editContent = (
                <GroupRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    users={users}
                    onClickTeam={(team) => {
                        this.setState({teamChoice: team});
                    }}
                    onSaveDone={(update)=> {
                        if (onChanged != null) {
                            season.subSeasons[update.id] = update;
                            onChanged(season);
                        }
                    }} />
            )
        } else if (gamesChoice.type === ROUND_TYPE_LEAGUE) {
            editContent = (
                <LeagueRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    allowEdit={true}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChanged != null) {
                            season.subSeasons[update.id] = update;
                            onChanged(season);
                        }
                    }} />
            )
        } else {
            editContent = (
                <SubSeasonEdit
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChanged != null) {
                            season.subSeasons[update.id] = update;
                            onChanged(season);
                        }
                    }}
                    onCloseClick={()=>{
                        if (season.subSeasons != null) {
                            for (const [key,value] of Object.entries(season.subSeasons)) {
                                if (value.title == null) {
                                    delete season.subSeasons[key];
                                }
                            }
                        }
                        this.setState({subSeasonChoice: {}, season: season, editOpen: false})
                    }} />
            )
        }

        let roundContent = (<div/>)
        if (gamesChoice.type === ROUND_TYPE_KNOCKOUT) {
            roundContent = (
                <KnockoutRoundGames
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    gameDays={gameDays}
                    users={users}
                    cards={cards}
                    detailMode={false}
                    onClickTeam={(team) => {
                        this.setState({teamChoice: team});
                    }}
                    onClickEdit={()=> {
                        this.setState({editOpen: true})
                    }}
                    onChanged={onChanged}
                    saveGame={(game)=> {
                        this.saveGame(game);
                    }} />
            )
        } else if (gamesChoice.type === ROUND_TYPE_GROUP) {
            roundContent = (
                <GroupRoundGames
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    users={users}
                    cards={cards}
                    detailMode={false}
                    onClickTeam={(team) => {
                        this.setState({teamChoice: team});
                    }}
                    onClickEdit={()=> {
                        this.setState({editOpen: true})
                    }}
                    onChanged={onChanged}
                    saveGame={(game)=> {
                        this.saveGame(game);
                    }}  />
            )
        } else if (gamesChoice.type === ROUND_TYPE_LEAGUE) {
            roundContent = (
                <LeagueRoundGames
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={gamesChoice}
                    gameDays={gameDays}
                    users={users}
                    cards={cards}
                    detailMode={false}
                    onClickTeam={(team) => {
                        this.setState({teamChoice: team});
                    }}
                    onClickEdit={()=> {
                        this.setState({editOpen: true})
                    }}
                    onChanged={onChanged}
                    saveGame={(game)=> {
                        this.saveGame(game);
                    }}  />
            )
        } else {
            roundContent = (
                <div>
                    {Object.values(gameDays).length > 0 && (
                        <ItemsDrowpdownNav
                            choiceTitle="Choose a Matchday"
                            title="Matchday"
                            dropdownItems={gameDays}
                            detailOpen={matchDayChoice.value === -1}
                            detailItem={matchDayChoice}
                            onItemChoice={(update)=>{
                                this.setState({matchDayChoice: update})
                            }} />
                    )}
                    {gameArr.map((game,idx)=>(
                        <div key={idx}>
                            <SeasonGameBadge
                                key={idx}
                                season={season}
                                game={game}
                                teams={teams}
                                locations={locations}
                                users={users}
                                warningView={this.renderGameWarning(game)}
                                onClickTeam={(team) => {
                                    this.setState({teamChoice: team});
                                }}
                                onClickEdit={()=>{
                                    if (game.status != null && game.status !== 'Pending') {
                                        alert(t('game_change_not_allowed'))
                                        return;
                                    }
                                    this.setState({subSeasonChoice: gamesChoice, editOpen: true})
                                }} />
                        </div>
                    ))}
                </div>
            )
        }

        return (
        <div>
            <div style={{marginTop: 20}}>
                <ItemsDrowpdownNav
                    choiceTitle={t('choose_round')}
                    title={t('season_rounds')}
                    dropDownArray={getSeasonRoundsArr(season)}
                    editOpen={editOpen}
                    detailOpen={detailOpen}
                    detailItem={gamesChoice}
                    onItemChoice={(gamesChoice)=>{
                        this.setState({gamesChoice: gamesChoice, detailOpen: false, editOpen: false})
                    }}
                    onClickBack={()=>{
                        this.setState({subSeasonChoice: {}, detailOpen: false, editOpen: false})
                    }} />
            </div>
            {gamesChoice.id != null && editOpen ? (
                <div>
                    {editContent}
                </div>
            ) : (
                <div>
                    {roundContent}
                </div>
            )}
        </div>);
    }

    renderTeams() {
        const {organization, season, onChanged, onUserUpdate, teams, locations, users } = this.props;

        return (
            <SeasonTeams
                organization={organization}
                season={season}
                teams={teams}
                locations={locations}
                users={users}
                onSeasonUpdate={(season)=> {
                    if (onChanged != null) {
                        onChanged(season);
                    }
                }}
                onChanged={()=> {
                    if (onChanged != null) {
                        onChanged();
                    }
                }}
                onUserUpdate={(update, team)=> {
                    if (onUserUpdate != null) {
                        onUserUpdate(update, team);
                    }
                }} />
        )
    }

    renderStanding() {
        const {organization, season, onChanged, teams, users, division, locations } = this.props;

        return (
            <SeasonStanding
                season={season}
                organization={organization}
                teams={teams}
                users={users}
                division={division}
                locations={locations}
                onChanged={()=> {
                    if (onChanged != null) {
                        onChanged();
                    }
                }} />
        )
    }

    renderGoals() {
        const {season, onChanged, teams, users } = this.props;

        return (
            <SeasonGoals
                season={season}
                teams={teams}
                users={users}
                onChanged={()=> {
                    if (onChanged != null) {
                        onChanged();
                    }
                }} />
        )
    }

    renderSanctions() {
        const {season, division, cards, onChanged, teams, users } = this.props;

        return (
            <SeasonSanctions
                season={season}
                teams={teams}
                users={users}
                cards={cards}
                division={division}
                onChanged={()=> {
                    if (onChanged != null) {
                        onChanged();
                    }
                }} />
        )
    }

    renderImport() {
        const {season, organization, teams, users, onChanged } = this.props;

        return (
            <SeasonImport
                season={season}
                teams={teams}
                users={users}
                organization={organization}
                onChanged={()=> {
                    if (onChanged != null) {
                        onChanged();
                    }
                }} />
        )
    }

    renderContents() {
        const {mainTab} = this.state;

        switch (mainTab) {
            case 0:
                return (this.renderOverview());
            case 1:
                return (this.renderGames());
            case 2:
                return (this.renderTeams());
            case 3:
                return (this.renderGoals());
            case 4:
                return (this.renderSanctions());
            case 5:
                return (this.renderStanding());
            case 6:
                return (this.renderInstructions());
            case 7:
                return (this.renderImport());
            default:
                return (
                    <div />
                );
        }
    }

    renderInstructionBtn(val) {
        if (val === 0) {
            return (
                <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                }}>Completed</Button>
            )
        } else if (val === 1) {
            return (
                <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                }}>In progress</Button>
            )
        } else {
            return (
                <Button className='btn-submit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                }}>Pending</Button>
            )
        }
    }

    renderInstructions() {
        const {season} = this.props;
        let step1 = 0;
        let step2 = 0;
        let step3 = 0;
        let step4 = 0;
        switch (season.status) {
            case undefined:
            case null:
            case STATUS_REGISTER:
                step1 = 1;
                step2 = 2;
                step3 = 2;
                step4 = 2;
                break;
            case STATUS_SCHEDULE:
                step1 = 0;
                step2 = 1;
                step3 = 2;
                step4 = 2;
                break;
            case STATUS_ONGOING:
                step1 = 0;
                step2 = 0;
                step3 = 1;
                step4 = 2;
                break;
            case STATUS_FINISHED:
                step1 = 0;
                step2 = 0;
                step3 = 0;
                step4 = 1;
                break;
            default:
                step1 = 0;
                step2 = 0;
                step3 = 0;
                step4 = 1;
                break;
        }

        return (
            <Form className='form-edit' style={{paddingLeft: 20, paddingRight:20}}>
                <Row className='form-edit-row'>
                    <h1 className='form-edit-subtitle'>
                    Guide to manage a season
                    </h1>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 1: Registration phase
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    In this phase you will need to accept/manage registrations, you can also add manually teams from the teams tab. Select all the teams for the season and assign all registered players to the teams, then start scheduling phase
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                    {this.renderInstructionBtn(step1)}
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 2: Scheduling phase
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    We will now start the scheduling, we will need to select the type of scheduling depending on the type of division example: tournaments and leagues.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    <b>Settings:</b> Select teams count, games count (choose one: double header or single header or weeks count), time slots and locations.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    <b>Deployment:</b> Select referees for each game and perform schedules check for duplicates and double booking referee.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    <b>Finish scheduling:</b> Our system will check for referee availability, field time slots not overlapping and once all items are checked we can now publish schedule.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                    {this.renderInstructionBtn(step2)}
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 3: On-Going season phase
                    </h4>
                    <h4 className='form-edit-label'>
                    Types:
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    In this phase we will see the progress of the season, team statistics and leaderboard. We will be able to reschedule games that have been cancelled, input scores and manage suspensions and sanctions.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    We will now start the scheduling, we will need to select the type of scheduling depending on the type of division example: tournaments and leagues.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    <b>Leagues:</b> After the regular seasons is completed then this will move into finished phase
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    <b>Leagues + playoffs:</b> After the league step completed , the playoffs step will be set and need games to be editted to the correct location and time, after playoffs completed the season will move into finished phase.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    <b>Tournaments:</b> After the group stage completed you can automatically or manually select the teams to move to next stage , the knockout stage will be set and need games to be edited to the correct location and time, after the knockout stage completed the season will move into finished phase.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                    {this.renderInstructionBtn(step3)}
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 4: Finishing season phase
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    The final phase of season where we can see the final standings from the leagues and tournaments and will be able to move into the awards and trophies feature.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    Select automatically from preset statistics or manually select by toggling on the switch to manually drag the teams and players into positions.
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    Trophies and awards:
                    </div>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    - Season team winner, player of the year award and top goalscorer.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                    {this.renderInstructionBtn(step4)}
                    </Col>
                </Row>
            </Form>
        )
    }

    renderTeamPopup() {
        const {season, users, teams, locations, onSeasonUpdate, onUserUpdate, onChanged} = this.props;
        const {teamChoice} = this.state;
        
        return (
            <SeasonTeamDetails
                season={season}
                users={users} 
                teams={teams} 
                locations={locations} 
                teamChoice={teamChoice}
                onChanged={()=> {
                    if (onChanged) {
                        onChanged();
                    }
                }}
                onClickClose={()=> {
                    this.setState({teamChoice: null})
                }}
                onSeasonUpdate={(update, teamUpdate)=> {
                    if (onSeasonUpdate) {
                        onSeasonUpdate(update);
                    }
                    if (teamUpdate) {
                        this.setState({teamChoice: teamUpdate})
                    }
                }}
                onUserUpdate={(user, team)=> {
                    if (onUserUpdate) {
                        onUserUpdate(user, team);
                    }
                }} />
        );
    }

    render() {
        const {season, seasons} = this.props;
        const {mainTab, windowWidth, pickRound, teamChoice } = this.state;
        let seasonArr = Object.values(seasons);
        seasonArr.sort((a, b) => {
            return b.startTime - a.startTime;
        })

        if (season != null) {
            return (
                <div>
                    
                    <Box sx={smallTabBoxStyle}>
                        <Tabs
                            value={mainTab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant={windowWidth > 760 ? "fullWidth" : "scrollable"}
                            sx={smallTabStyle}
                            onChange={this.handleChange} >
                            <Tab icon={<GrOverview />} label={t('overview')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<GrSchedules />} label={t('games')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<FcTodoList />} label={t('teams')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<GiStairsGoal />} label={t('goals')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<GiCardPlay />} label={t('sanctions')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<GiChampions />} label={t('standings')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<MdOutlineIntegrationInstructions />} label={t('instructions')} sx={smallTabItemStyle} iconPosition="start" />
                            {(!season.status || season.status === STATUS_REGISTER) && (
                            <Tab icon={<FcImport />} label={t('import')} sx={smallTabItemStyle} iconPosition="start" />
                            )}
                        </Tabs>
                    </Box>
                    
                    <div style={{marginTop: 10}}/>

                    {this.renderContents()}
                    <Modal isOpen={pickRound}>
                        <ModalBody>
                            {Object.values(season.subSeasons != null ? season.subSeasons : {}).map((value, idx)=>(
                                <ListGroupItem key={idx} onClick={e=>{
                                    this.setState({gamesChoice: value, pickRound: false})
                                }}>{value.title}</ListGroupItem>
                            ))
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-cancel' onClick={e => {
                                this.setState({pickRound: false})
                            }}>{t('cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                    {teamChoice && (
                    <Modal isOpen={true}>
                        <ModalBody>
                        {this.renderTeamPopup()}
                        </ModalBody>
                    </Modal>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default SeasonDetail;