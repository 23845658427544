import React, { Component } from "react";
import {
    CardImg, Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    AiFillVideoCamera
} from 'react-icons/ai';

import defaultImage from 'assets/img/icons/video_default.png';

export class ClubWorkoutHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {clubWorkout, onClickDetails, onClickEdit, uid } = this.props;
        let workoutImage = clubWorkout.thumbUri != null ? clubWorkout.thumbUri : defaultImage;
        let allowEdit = (uid == null && clubWorkout.owner === 'free') || uid === clubWorkout.owner;
        
        if (clubWorkout != null) {
            return (
                <div key={clubWorkout.id} className="info-badge-small">
                    <div className="divider"/>
                    <div className="wrap-content-parent">
                        <CardImg
                            className="wrap-content-wrap-child info-badge-icon-small"
                            src={workoutImage} />
                        <div className="wrap-content-fill-child info-badge-body">
                            <h4 className='label label-default one-line-title' style={{marginTop: 5}}>
                            {clubWorkout.title}
                            </h4>
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                    <AiFillVideoCamera/> Lessons: <div className="info-badge-info">{clubWorkout.lessonIds != null ? Object.values(clubWorkout.lessonIds).length : 0}</div>
                                </div>
                                <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                    {onClickDetails && (
                                        <Button className="btn-submit" onClick={e => {
                                            if (onClickDetails != null) {
                                                onClickDetails();
                                            }
                                        }}>{t('details')}</Button>
                                    )}
                                    {allowEdit && onClickEdit && (
                                        <Button className="btn-edit" onClick={e => {
                                            if (onClickEdit != null) {
                                                onClickEdit();
                                            }
                                        }}>{t('edit')}</Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubWorkoutHeader);