import React, { Component } from 'react';
import { connect } from 'react-redux';
import PostHeader from 'components/detail/community/PostHeader';
import PostDetailView from 'components/detail/community/PostDetailView';
import {PostEdit} from 'components/edit/community/PostEdit';
import { userActions } from 'redux/_actions';

import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import { 
    GiSoccerKick
} from 'react-icons/gi';
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import LoadingView from 'components/detail/loading/LoadingView';
import { 
    Input,
    Row,
    Col,
} from 'reactstrap';
import { t } from 'i18next';
import { getDayStart, DAY_TIME } from 'utils/Utils';
import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
} from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';

class CommunityPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            organizations: {},
            posts: {},
            users: {},
            teams: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf()
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        const {user} = this.props;
        this.setState({loading: true});
        invokeHttpsApi('community-postDashboardQuery', {}, (data) => {
            const organization = {};
            const organizations = {};
            const users = {};
            const teams = {};
            const posts = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (user != null && value.owner === user.uid) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                teams: teams,
                posts: posts,
                users: users,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderContents() {
        const { user, organizationSubscription, freeOrganization } = this.props;
        const { organizations, users, dateFilter, dateChoice, teams, posts , detailChoice, editOpen, detailOpen, searchStr} = this.state;
        
        const organization = {};
        for (const [, value] of Object.entries(organizations)) {
            
            if (user != null && value.owner === user.uid) {
                for (const [k,v] of Object.entries(value)) {
                    organization[k] = v;
                }
            }
        }
        const result = [];
        const list = [];
        const dayStart = getDayStart(dateChoice);
    
        if (user == null) {
            return <div/>
        }
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        
        for (const[, value] of Object.entries(posts)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.organizationId === organization.id) {
                if (!dateFilter || (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME)) {
                    result.push(value);
                }
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t2 - t1;
        })
        
        for (const value of result) {
            list.push(
                <Col xs={12} sm={6} lg={4} style={{padding: '10px 10px'}}>
                    <PostHeader 
                        key={value.id}
                        uid={user.uid}
                        organization={organization}
                        post={value}
                        className="post-tile-fixed"
                        users={users}
                        onClickDetail={() => {
                            this.setState({
                                detailChoice: value,
                                detailOpen: true
                            })
                        }}
                        onClickEdit={() => {
                            this.setState({
                                detailChoice: value,
                                editOpen: true
                            })
                        }} />
                </Col>
            );
        }

        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('news_feed')}
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={list.length}
                        detailItem={detailChoice}
                        style={{margin: 0}}
                        onClickAdd={()=>{
                            this.setState({detailChoice: {}, editOpen: true, detailOpen: false});
                        }}
                        onClickBack={()=>{
                            this.setState({detailChoice: {}, detailOpen: false, editOpen: false})
                        }} />
                </div>

                {!editOpen && !detailOpen && (
                <div>
                    <Input 
                        style={{marginTop: 20}}
                        type="text" 
                        name="search" 
                        id="search" 
                        placeholder='Search by Title ...' 
                        onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                    <div style={{height: 10}}/>
                    <Row style={{margin: 0}}>
                        {list}
                    </Row>
                </div>
                )}

                {detailOpen && detailChoice.id != null && (
                    <PostDetailView 
                        uid={user.uid}
                        organization={organization}
                        post={detailChoice} 
                        users={users}
                        teams={teams}
                        isOpen={detailOpen}
                        onCloseClick={() => {
                            this.setState({detailOpen: false})
                        }} />
                )}

                {editOpen && (
                    <div className='container'>
                        <PostEdit 
                            uid={user.uid}
                            organization={organization}
                            teams={teams}
                            post={detailChoice} 
                            isOpen={editOpen}
                            onChanged={() => {
                                this.loadContents()
                            }} 
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </div>
                )}
            </div>
        );
    }
    
    render() {
        const {loading} = this.state;

        return (
            <div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(CommunityPage));
export { connectedApp as CommunityPage };
