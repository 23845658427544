import React, { Component } from "react";
import SlideItemEdit from 'components/edit/organization/landing/SlideItemEdit';
import SlideTextEdit from 'components/edit/organization/landing/SlideTextEdit';
import { homeSlides } from 'utils/landing';

export class SlidesEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slides: {},
            isOpen: false
        }
    }

    componentDidMount() {
        const {organization} = this.props;
        const slides = {};
        
        if (organization && organization.landingData && organization.landingData.slides) {
            for (const[key, value] of Object.entries(organization.landingData.slides)) {
                slides[key] = value;
            }
        }

        this.setState({
            slides: slides,
        });
    }

    componentDidUpdate(prevProps) {
        const {organization} = this.props;
        if (organization !== prevProps.organization) {
            const slides = {};
            
            if (organization && organization.landingData && organization.landingData.slides) {
                for (const[key, value] of Object.entries(organization.landingData.slides)) {
                    slides[key] = value;
                }
            }

            this.setState({
                slides: slides,
            });
        }
    }

    renderTextInfo(slideText) {
        const {organization, onUpdate} = this.props;
        return (
            <SlideTextEdit
                organization={organization}
                slideText={slideText}
                onUpdate={(slideText) => {                    
                    const landingData = (organization && organization.landingData) ? organization.landingData : {};

                    landingData.slideText = {};
        
                    for (const [key, value] of Object.entries(slideText)) {
                        landingData.slideText[key] = value;
                    }

                    if (onUpdate) {
                        onUpdate(landingData);
                    }
                }} />
        );
    }
    
    renderSlideItem(slideItem) {
        const {organization, onUpdate} = this.props;
        return (
            <SlideItemEdit
                organization={organization}
                slideItem={slideItem} 
                onUpdate={(slideItem) => {
                    
                    const landingData = (organization && organization.landingData) ? organization.landingData : {};

                    if (!landingData.slides) {
                        landingData.slides = {};
                    }
                    landingData.slides[slideItem.altText] = {};

                    for (const [key, value] of Object.entries(slideItem)) {
                        landingData.slides[slideItem.altText][key] = value;
                    }
                    if (onUpdate) {
                        onUpdate(landingData);
                    }
                }} />
        );
    }

    render() {
        const {slides} = this.state;

        const slide1 = slides.Slide1 != null ? slides.Slide1 : homeSlides[0];
        const slide2 = slides.Slide2 != null ? slides.Slide2 : homeSlides[1];
        const slide3 = slides.Slide3 != null ? slides.Slide3 : homeSlides[2];

        //const slideText = slides.slideText != null && slides.slideText.main != null ? slides.slideText : homeHeaderText;
        
        return (
            <div>
                <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 10}}>
                    <h3 className='vertical-center-spacing wrap-content-fill-child' style={{display: 'inline-block', fontSize: 18}}>
                    Slides Section
                    </h3>
                    <div className='wrap-content-wrap-child'>
                        
                    </div>
                </div>
                <div style={{height: 1, width: '100%', backgroundColor: '#EFBB20'}} />
                <div>
                    {this.renderSlideItem(slide1)}
                    {this.renderSlideItem(slide2)}
                    {this.renderSlideItem(slide3)}
                </div>
            </div>
        );
    }
}

export default SlidesEdit;