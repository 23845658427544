import React, { Component } from "react";
import { FormGroup, Modal, ModalBody } from 'reactstrap';
import ReactStars from "react-rating-stars-component";
import { MdEdit, MdRemoveCircle } from 'react-icons/md';
import SurveyRatingQuestionEdit from 'components/edit/survey/SurveyRatingQuestionEdit';
import { writeDb } from "utils/API";

export class SurveyRatingQuestion extends Component {
    constructor(props) {
        super(props);
        const {submission} = this.props;
        this.state = {
            rating: submission != null ? submission.rating : 0,
            editOpen: false,
        }
    }
    
    componentDidUpdate(prevProps) {
        const {submission} = this.props;
        if (prevProps.submission !== submission) {
            this.setState({
                rating: submission != null ? submission.rating : 0,
                editOpen: false,
            });
        }
    }
    
    render() {
        const {survey, question, onChange, isReview, allowEdit} = this.props;
        const {rating, editOpen} = this.state;
        
        if (question != null && question.id != null) {
            return (
                <FormGroup key={question.id}>
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-fill-child'>
                            <h4 className='survey-question-text'>{question.text != null ? question.text : 'Rate your Answer'}</h4>
                        </div>
                        {allowEdit && (
                            <div className='wrap-content-wrap-child'>
                                <MdEdit onClick={e=>{
                                    this.setState({editOpen: true});
                                }}/>
                                <MdRemoveCircle onClick={e=>{
                                    if (window.confirm("Are you sure to want to remove this question, it is permanent operation can not be undone?")) {
                                        writeDb('/survey/' + survey.id + '/questions/' + question.id, null);
                                    }
                                }}/>
                            </div>
                        )}
                    </div>
                    <ReactStars
                        count={5}
                        onChange={(newRating) => {
                            if (!isReview) {
                                this.setState({rating: newRating});
                                if (onChange != null) {
                                    onChange(newRating);
                                }
                            }
                        }}
                        size={24}
                        value={rating}
                        isHalf={true}
                        activeColor="#ffd700" />
                    {allowEdit && (
                        <Modal isOpen={editOpen}>
                            <ModalBody>
                                <SurveyRatingQuestionEdit 
                                    survey={survey}
                                    question={question}
                                    onSaveClick={(update)=> {
                                        if (this.props.onSaveClick != null) {
                                            this.props.onSaveClick(update)
                                        }
                                    }}
                                    onCloseClick={e=>{
                                        this.setState({editOpen: false});
                                    }} />
                            </ModalBody>
                        </Modal>
                    )}
                </FormGroup>
            );
        } else {
            return <div/>;
        }
    }
}

export default SurveyRatingQuestion;