import React, { PureComponent } from 'react';
import { t } from "i18next";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { Outlet } from "react-router-dom";
import { SideMenu } from 'components/SideMenu';
import { withRouter } from 'utils/Routher';

import { 
    MdDashboard,
} from 'react-icons/md';
import { 
    FaUserLock,
    FaChalkboardTeacher,
} from 'react-icons/fa';
import { 
    FcInvite,
} from 'react-icons/fc';
import { getLogo, getTitle } from 'utils/Utils';
import { listenDb } from 'utils/API';
import { IoMdMenu } from 'react-icons/io';
import LargeLoading from 'components/detail/loading/LargeLoading';

const floatingThres = 875;

class AdminPage extends PureComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            loading: false,
            isOpened: false,
            windowWidth: window.innerWidth,
        };
        window.addEventListener('resize', this.getDimensions); 
    }

    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        this.setState({loading: true});
        listenDb("/adminUid", (data) => {
            this.setState({adminUid: data, loading: false});
        })
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    renderSideMenu() {
        const { windowWidth, isOpened } = this.state;

        const mainMenuItem = 
        {
            icon: <MdDashboard />,
            title: t('dashboard'),
            link: "/admin"
        }
    
        const menuItems = [
            {
                icon: <FaUserLock />,
                title: t('promo_codes'),
                link: "/codes"
            },
            {
                icon: <FaChalkboardTeacher />,
                title: t('admin_contents'),
                link: "/contents",
                children: [
                    {
                        title: t('policy'),
                        link: "/policy"
                    },
                    {
                        title: t('tutorial'),
                        link: "/tutorial"
                    },
                    {
                        title: t('videos'),
                        link: "/videos"
                    },
                    /* {
                        title: t('trials'),
                        link: "/trials"
                    }, */
                    {
                        title: t('lessons'),
                        link: "/lessons"
                    },
                    {
                        title: t('challenges'),
                        link: "/challenges"
                    },
                    {
                        title: t('reivew'),
                        link: "/reivew"
                    },
                ]
            },
            {
                icon: <FaChalkboardTeacher />,
                title: t('security'),
                link: "/security"
            },
            {
                icon: <FcInvite />,
                title: t('invite'),
                link: "/invite"
            },
        ]
    
        return (
            <SideMenu
                icon={getLogo()}
                mainItem={mainMenuItem}
                menuItems={menuItems}
                title={getTitle()}
                floating={windowWidth < floatingThres}
                isOpened={isOpened}
                onClose={e=> {
                    this.setState({isOpened: false});
                }} />
        );
    }

    renderHeader() {
        const { user } = this.props;
        const { windowWidth } = this.state;

        return (
            <div className='organizer-header'>
                {windowWidth < floatingThres && (
                <div className='organizer-header-settings'
                    onClick={e=> {
                        this.setState({isOpened: true});
                    }} >
                    <IoMdMenu />
                </div>
                )}
                <img 
                    className='organizer-header-icon' 
                    src={getLogo()} 
                    alt="logo" />
                <div className='organizer-header-title'>
                    {t('admin_panel')}
                </div>
                
                <img 
                    className='organizer-header-user' 
                    src={user && user.photoUri ? user.photoUri : getLogo()} 
                    alt="user"
                    onClick={e=> {
                        this.props.router.navigate("/my_account");
                    }} />
            </div>
        )
    }

    render() {
        const { user } = this.props;
        const { adminUid } = this.state;
        
        return (
            <div className='wrap-content-parent-fixed'>
                <div className='wrap-content-wrap-child-fixed'>
                    {this.renderSideMenu()}
                </div>
                {adminUid && user && adminUid === user.uid ? (
                <div className='wrap-content-fill-child-fixed' style={{paddingLeft: 10, paddingRight: 10}}>
                    {this.renderHeader()}
                    <Outlet/>
                </div>
                ) : (
                <div className='wrap-content-fill-child-fixed' style={{paddingLeft: 10, paddingRight: 10}}>
                    <LargeLoading/>
                </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (AdminPage));
export { connectedPage as AdminPage };
