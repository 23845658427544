import React, { Component } from "react";
import {
    Button, CardImg, Row, Col
} from 'reactstrap';
import { t } from 'i18next';
import SmallInfoBadge from 'components/detail/SmallInfoBadge';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import defaultPlayerImage from 'assets/img/icons/player_photo_default.png';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import { 
    FaUsers
} from 'react-icons/fa';

import { MdSave } from 'react-icons/md';
import { checkProfileData, renderRegisterStatus } from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";

export class ClubSeasonDetail extends Component {
    constructor(props) {
        super(props);
        const season = {};
        if (props.season != null) {
            for (const [key, value] of Object.entries(props.season)) {
                season[key] = value;
            }

        }
        this.state = {
            season: season,
            editTeamData: false,
            editLocation: false,
            progress: false,
            imageProgress: 0,
            windowWidth: window.innerWidth,
        };
        this.saveTeamUpdate = this.saveTeamUpdate.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {isOpen, season} = this.props;
        
        if ((prevProps.isOpen === false && isOpen === true)) {
            const update = {};
            for (const [key, value] of Object.entries(season)) {
                update[key] = value;
            }
            this.setState({
                season: update,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
            })
        }

        if ((prevProps.season !== season)) {
            const update = {};
            for (const [key, value] of Object.entries(season)) {
                update[key] = value;
            }
            this.setState({
                season: update,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
            })
        }
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    renderProfileItem() {
    }

    sendRosterEmail() {
        const {team} = this.props;
        const {season} = this.state;
        
        const teamData = season.teamData != null && season.teamData[team.id] ? season.teamData[team.id] : {};
        const userIds = teamData.roaster != null ? teamData.roaster : {};

        const emailText = t('roster_email_text').replace("{team_title}", team.title).replace("{season_title}", season.title);
        const emailLink = `team/${team.id}/account/${season.id}`;

        const emailLinkPath = window.location.origin;
        this.setState({rosterEmailProgress: true})
        invokeHttpsApi('invite-sendCustomEmails', {
            userIds: userIds,
            teamId: team.id,
            emailText: emailText,
            emailLink: emailLink,
            emailLinkPath: emailLinkPath,
        }, (data) => {
            if (data && data.success) {
                alert(t('email_sent'));
            } else {
                alert(t('email_sending_failed'));
            }
            this.setState({rosterEmailProgress: false});
        }, (error) => {
            console.log('saveContents error', error);
            alert(t('email_sending_failed') + "/n" + error);
            this.setState({rosterEmailProgress: false});
        });
    }

    saveTeamUpdate(teamData) {
        const {team, onChanged, users, profileData} = this.props;
        const {season} = this.state;

        const gameList = teamData.gameList != null ? teamData.gameList : {};
        const benchList = teamData.benchList != null ? teamData.benchList : {};
        for (const [key,] of Object.entries(gameList)) {
            const user = users[key];
            if (checkProfileData(season, user, team, profileData) !== 'verified') {
                alert("You have players not verified on player list.");
                return;
            }
        }
        for (const [key,] of Object.entries(benchList)) {
            const user = users[key];
            if (checkProfileData(season, user, team, profileData) !== 'verified') {
                alert("You have players not verified on bench list.");
                return;
            }
        }
        invokeHttpsApi('clubQuery-saveSeasonTeamDataChange', {
            seasonId: season.id,
            teamId: team.id,
            teamData: teamData,
        }, (data) => {
            if (data && data.success) {
                if (onChanged != null) {
                    onChanged();
                }
            }
            this.setState({progress: false, fileProgress: 0});
        }, (error) => {
            console.log('saveContents error', error);
            this.setState({progress: false, fileProgress: 0});
        });
    }

    renderTeamList() {
        const {division, team, users, profileData} = this.props;
        const {season, rosterEmailProgress} = this.state;

        const members = [];
        const bench = [];
        const ready = [];
        const players = [];
        const coaches = [];
        const managers = [];

        const playerCount = division.category != null && division.category.playerCount != null ? division.category.playerCount : 0;
        const benchCount = division.category != null && division.category.benchCount != null ? division.category.benchCount : 0;
        const teamData = season.teamData != null && season.teamData[team.id] ? season.teamData[team.id] : {};
        const gameList = teamData.gameList != null ? teamData.gameList : {};
        const benchList = teamData.benchList != null ? teamData.benchList : {};
        const roaster = teamData.roaster != null ? teamData.roaster : {};
        const staffList = teamData.staff != null ? teamData.staff : {};
        for (const [key, value] of Object.entries(users)) {
            if (team.playerIds != null && Object.values(team.playerIds).includes(key)) {
                value.registerStatus = checkProfileData(division, value, team, profileData);
                if (Object.values(gameList).includes(key)) {
                    members.push(value);
                } else if (Object.values(benchList).includes(key)) {
                    bench.push(value);
                } else if (Object.values(roaster).includes(key)) {
                    ready.push(value);
                } else {
                    players.push(value);
                }
            }
            if (team.managers != null && Object.values(team.managers).includes(key)) {
                value.registerStatus = checkProfileData(division, value, team, profileData);
                if (Object.values(staffList).includes(key)) {
                    coaches.push(value);
                } else {
                    managers.push(value);
                }
            }
        }
        members.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        bench.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        ready.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        players.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        managers.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        coaches.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        const lienUp = [];
        const inSub = [];
        const roster = [];
        const toAdd = [];
        const coachList = [];

        lienUp.push(
            <ItemsNavigation
                title={t('lineup')}
                icon={<FaUsers/>}
                totalCount={members.length + "/" + playerCount} />
        )
        for (const value of members) {
            lienUp.push(
                this.renderUserBadge(value, teamData)
            );
        }
        inSub.push(
            <ItemsNavigation
                title={t('substitute')}
                icon={<FaUsers/>}
                totalCount={bench.length + "/" + benchCount} />
        )
        for (const value of bench) {
            inSub.push(
                this.renderUserBadge(value, teamData)
            );
        }
        roster.push(
            <ItemsNavigation
                title={t('roster')}
                icon={<FaUsers/>}
                totalCount={ready.length}
                customLabel={t('roster_email')}
                onClickCustom={()=> {
                    if (!rosterEmailProgress) {
                        this.sendRosterEmail();
                    }
                }} />
        )
        for (const value of ready) {
            roster.push(
                this.renderUserBadge(value, teamData)
            );
        }
        toAdd.push(
            <ItemsNavigation
                title={t('other_players')}
                icon={<FaUsers/>}
                totalCount={players.length} />
        )
        for (const value of players) {
            toAdd.push(
                this.renderRosterBadge(value, teamData)
            );
        }
        coachList.push(
            <ItemsNavigation
                title={t('coaches')}
                icon={<FaUsers/>}
                totalCount={managers.length + coaches.length} />
        )
        for (const value of coaches) {
            coachList.push(
                this.renderCoachBadge(value, teamData)
            );
        }
        for (const value of managers) {
            coachList.push(
                this.renderCoachBadge(value, teamData)
            );
        }
        return (
            <Row style={{margin: 0}}>
                <Col sm={6} xs={12} style={{padding: 0}}>
                    {roster}
                    {toAdd}
                </Col>
                <Col sm={6} xs={12} style={{padding: 0}}>
                    {lienUp}
                    {inSub}
                    {coachList}
                </Col>
            </Row>
        );
    }

    checkGamePlayerList(user, teamData) {
        const {division} = this.props;

        const playerCount = division.category != null && division.category.playerCount != null ? division.category.playerCount : 0;
        const curPlayers = teamData.gameList != null ? teamData.gameList : {};

        if (playerCount !== 0 && Object.values(curPlayers).length >= playerCount) {
            return false;
        }
        return true;
    }

    checkGameBenchList(user, teamData) {
        const {division} = this.props;

        const benchCount = division.category != null && division.category.benchCount != null ? division.category.benchCount : 0;
        const curPlayers = teamData.benchList != null ? teamData.benchList : {};

        if (benchCount !== 0 && Object.values(curPlayers).length >= benchCount) {
            return false;
        }
        return true;
    }

    renderCoachBadge(user, teamData) {
        const {organization, team, profileData} = this.props;
        const {season} = this.state;

        const clubData = organization != null && 
            organization.teamData && 
            organization.teamData[team.id] != null ? organization.teamData[team.id] : {};
        
        const userProfile = clubData.profileData != null && clubData.profileData[user.uid] ? clubData.profileData[user.uid] : {};

        let thumbImage = user.photoUri != null ? user.photoUri : defaultPlayerImage;
        let userName = user.username;
        const isMember = teamData.stuff != null ? Object.values(teamData.stuff).includes(user.uid) : false;
        
        if (userProfile.photoUri != null) {
            thumbImage = userProfile.photoUri;
        }
        if (userProfile.nickname != null) {
            userName = userProfile.nickname;
        }
        if (userProfile.number != null) {
            userName += ", No." + userProfile.number;
        }

        if (!isMember) {
            return (
                <SmallInfoBadge
                    title={userName}
                    subtitle={renderRegisterStatus(season, user, team, profileData)}
                    icon={thumbImage}
                    actionTitle1={t('to_coach')}
                    actionCallback1={()=>{
                        if (!this.checkGamePlayerList(user,teamData)) {
                            alert('error occured');
                            return;
                        }
                        
                        if (season.teamData == null) {
                            season.teamData = {};
                        }
                        if (season.teamData[team.id] == null) {
                            season.teamData[team.id] = {};
                            season.teamData[team.id].teamId = team.id;
                            season.teamData[team.id].teamTitle = team.title;
                        }
                        if (season.teamData[team.id].stuff == null) {
                            season.teamData[team.id].stuff = {};
                        }
                        season.teamData[team.id].stuff[user.uid] = user.uid;
                        this.setState({season: season});
                    }} />
            );
        } else {
            return (
                <SmallInfoBadge
                    title={userName}
                    subtitle={renderRegisterStatus(season, user, team, profileData)}
                    icon={thumbImage}
                    actionTitle1={t('remove')}
                    actionCallback1={()=>{
                        delete season.teamData[team.id].stuff[user.uid];
                        this.setState({season: season});
                    }} />
            );
        }
    }

    renderUserBadge(user, teamData) {
        const {organization, team, profileData} = this.props;
        const {season} = this.state;

        const clubData = organization != null && 
            organization.teamData && 
            organization.teamData[team.id] != null ? organization.teamData[team.id] : {};
        
        const userProfile = clubData.profileData != null && clubData.profileData[user.uid] ? clubData.profileData[user.uid] : {};

        let thumbImage = user.photoUri != null ? user.photoUri : defaultPlayerImage;
        let userName = user.username;
        const isMember = teamData.gameList != null ? Object.values(teamData.gameList).includes(user.uid) : false;
        const isBench = teamData.benchList != null ? Object.values(teamData.benchList).includes(user.uid) : false;
        if (userProfile.photoUri != null) {
            thumbImage = userProfile.photoUri;
        }
        if (userProfile.nickname != null) {
            userName = userProfile.nickname;
        }
        if (userProfile.number != null) {
            userName += ", No." + userProfile.number;
        }

        if (!isMember && !isBench) {
            return (
                <SmallInfoBadge
                    title={userName}
                    subtitle={renderRegisterStatus(season, user, team, profileData)}
                    icon={thumbImage}
                    actionTitle1={t('to_lineup')}
                    actionCallback1={()=>{
                        if (!this.checkGamePlayerList(user,teamData)) {
                            alert('error occured');
                            return;
                        }
                        if (!season.skipProfileCheck && checkProfileData(season, user, team, profileData) !== 'verified') {
                            alert(t('no_profile_prompt'));
                            return;
                        }
                        if (season.teamData == null) {
                            season.teamData = {};
                        }
                        if (season.teamData[team.id] == null) {
                            season.teamData[team.id] = {};
                            season.teamData[team.id].teamId = team.id;
                            season.teamData[team.id].teamTitle = team.title;
                        }
                        if (season.teamData[team.id].gameList == null) {
                            season.teamData[team.id].gameList = {};
                        }
                        season.teamData[team.id].gameList[user.uid] = user.uid;
                        this.setState({season: season});
                    }}
                    actionTitle2={t('to_sub')}
                    actionCallback2={()=>{
                        if (!this.checkGameBenchList(user,teamData)) {
                            alert('error occured');
                            return;
                        }
                        if (!season.skipProfileCheck && checkProfileData(season, user, team, profileData) !== 'verified') {
                            alert(t('no_profile_prompt'));
                            return;
                        }
                        if (season.teamData == null) {
                            season.teamData = {};
                        }
                        if (season.teamData[team.id] == null) {
                            season.teamData[team.id] = {};
                            season.teamData[team.id].teamId = team.id;
                            season.teamData[team.id].teamTitle = team.title;
                        }
                        if (season.teamData[team.id].benchList == null) {
                            season.teamData[team.id].benchList = {};
                        }
                        season.teamData[team.id].benchList[user.uid] = user.uid;
                        this.setState({season: season});
                    }} />
            );
        } else if (isMember) {
            return (
                <SmallInfoBadge
                    title={userName}
                    subtitle={renderRegisterStatus(season, user, team, profileData)}
                    icon={thumbImage}
                    actionTitle1={t('remove')}
                    actionCallback1={()=>{
                        delete season.teamData[team.id].gameList[user.uid];
                        this.setState({season: season});
                    }} />
            );
        } else if (isBench) {
            return (
                <SmallInfoBadge
                    title={userName}
                    subtitle={renderRegisterStatus(season, user, team, profileData)}
                    icon={thumbImage}
                    actionTitle1={t('remove')}
                    actionCallback1={()=>{
                        delete season.teamData[team.id].benchList[user.uid];
                        this.setState({season: season});
                    }} />
            );
        } else {
            return <div/>;
        }
    }

    renderRosterBadge(user, teamData) {
        const {organization, team, profileData} = this.props;
        const {season} = this.state;

        const clubData = organization != null && 
            organization.teamData && 
            organization.teamData[team.id] != null ? organization.teamData[team.id] : {};
        
        const userProfile = clubData.profileData != null && clubData.profileData[user.uid] ? clubData.profileData[user.uid] : {};

        let thumbImage = user.photoUri != null ? user.photoUri : defaultPlayerImage;
        let userName = user.username;
        
        if (userProfile.photoUri != null) {
            thumbImage = userProfile.photoUri;
        }
        if (userProfile.nickname != null) {
            userName = userProfile.nickname;
        }
        if (userProfile.number != null) {
            userName += ", No." + userProfile.number;
        }

        return (
            <SmallInfoBadge
                title={userName}
                subtitle={renderRegisterStatus(season, user, team, profileData)}
                icon={thumbImage}
                actionTitle1={t('to_roster')}
                actionCallback1={()=>{
                    if (!this.checkGamePlayerList(user,teamData)) {
                        alert('error occured');
                        return;
                    }
                    if (season.teamData == null) {
                        season.teamData = {};
                    }
                    if (season.teamData[team.id] == null) {
                        season.teamData[team.id] = {};
                        season.teamData[team.id].teamId = team.id;
                        season.teamData[team.id].teamTitle = team.title;
                    }
                    if (season.teamData[team.id].roaster == null) {
                        season.teamData[team.id].roaster = {};
                    }
                    season.teamData[team.id].roaster[user.uid] = user.uid;
                    this.setState({season: season});
                }} />
        );
    }

    render() {
        const {division, hideHeader, team} = this.props;
        const {season} = this.state;
        
        let challengeImage = division.thumbUri != null ? division.thumbUri : defaultImage;
        let teamData = season.teamData != null ? season.teamData[team.id] : null;        
        
        if (division != null) {
            return (
                <div>
                    {!hideHeader && (
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                                src={challengeImage}
                                style={{ width: 60, height: 60, marginRight: 20 }}
                                />
                            <div className="wrap-content-fill-child vertical-center-spacing">
                                <h4 className='label label-default'>
                                {season.title + "/" + division.title}
                                </h4>
                                <div>{division.description}</div>
                            </div>
                        </div>
                    )}
                    <div>
                        <div className='wrap-content-parent' style={{marginTop: 30}}>
                            <h4 className='label label-default wrap-content-fill-child vertical-center-spacing'>
                            {t('roster')} & {t('lineup')} {season.skipProfileCheck ? "(Skip Profile Check)" : "(Checks Profile)"}
                            </h4>
                            <div className="wrap-content-wrap-child vertical-center-spacing">
                                <Button className='btn-submit' onClick={e=>{
                                    this.saveTeamUpdate(teamData);
                                }}><MdSave/></Button>
                            </div>
                        </div>
                        <div className='divider' style={{marginTop: 10, marginBottom: 10}}/>
                        {this.renderTeamList()}
                    </div>
                    
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ClubSeasonDetail;