import React, { Component } from "react";
import {
    Card, CardImg, Row, Col, Button, Modal, ModalBody
} from 'reactstrap';
import UserBadge from 'components/detail/UserBadge';
import TrialReviewView from 'components/detail/trial/TrialReviewView';
import { toDateTimeString } from 'utils/Utils';

import defaultImage from 'assets/img/icons/video_default.png';

export class TrialSubmissionBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
        }
    }
    
    render() {
        const {trial, submission, user, challenges, challengeSubmissions, challengeVideos } = this.props;
        const {detailOpen} = this.state;
        let trialImage = trial.thumbUri != null ? trial.thumbUri : defaultImage;
        let challengeCount = trial.challengeIds != null ? Object.values(trial.challengeIds).length : 0;
        let submissionCount = submission.challengeSubmissionIds != null ? Object.values(submission.challengeSubmissionIds).length : 0;
        let dateStr = toDateTimeString(submission.timestamp);
        let status = 'In Review';
        let statusColor = '#F0D11F';
        if (submission.status === 'passed') {
            status = 'PASSED';
            statusColor = '#6ec447';
        } else if (submission.status === 'failed') {
            status = 'FAILED';
            statusColor = '#f87668';
        } else {
            status = 'IN REVIEW';
            statusColor = '#F0D11F';
        }
        
        
        if (trial != null) {
            return (
                <div className='shadow-tile'>
                    <Card>
                        <div className='wrap-content-parent' style={{marginTop: 10}}>
                            <div className='wrap-content-fill-child vertical-center-spacing' style={{marginLeft: 20}}>
                                <Row>
                                    <Col sm={4}>
                                        <UserBadge customClass='wrap-content-wrap-child' user={user}/>
                                    </Col>
                                    <Col sm={8}>
                                        <CardImg
                                            className="profile-thumb"
                                            src={trialImage}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            />
                                        <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{trial.title}</div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='wrap-content-wrap-child' style={{marginLeft: 10, marginRight: 20, fontSize: 12, fontWeight: 1000}}>{dateStr}</div>
                        </div>
                        <div className='wrap-content-parent' style={{marginTop: 10, marginBottom: 10}}>
                            <div className='wrap-content-fill-child vertical-center-spacing' style={{marginLeft: 20}}>
                                <Row>
                                    <Col sm={4}>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000, color: statusColor}}>
                                            {status}
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12}}>
                                            CHALLENGE
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            STATUS
                                        </div>
                                        
                                    </Col>
                                    <Col sm={4}>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            {submissionCount} / {challengeCount}
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12}}>
                                            TOTAL
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            CHALLENGES
                                        </div>
                                        
                                    </Col>
                                    <Col sm={4}>
                                    
                                    </Col>
                                </Row>
                            </div>

                            <div className='wrap-content-wrap-child' style={{marginLeft: 10, marginTop: 15, marginRight: 10}}>
                                <Button className="btn-submit" onClick={e => {
                                    this.setState({detailOpen: true})
                                }}>Review</Button>
                            </div>
                        </div>
                    </Card>
                    <Modal isOpen={detailOpen}>
                        <ModalBody>
                            <TrialReviewView 
                                trial={trial} 
                                submission={submission} 
                                challenges={challenges} 
                                challengeSubmissions={challengeSubmissions} 
                                challengeVideos={challengeVideos} 
                                onCloseClick={() => {
                                    this.setState({detailOpen: false})
                                }} />
                        </ModalBody>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default TrialSubmissionBadge;