import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";

export class OrganizationEdit extends Component {
    constructor(props) {
        super(props);
        const organization = {};
        if (props.organization != null) {
            for (const [key, value] of Object.entries(props.organization)) {
                organization[key] = value;
            }
        }
        
        this.state = {
            organization: organization,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, organization} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.organization !== organization)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(organization)) {
                challengeVal[key] = value;
            }
            this.setState({
                organization: challengeVal
            })
        }
    }

    onSaveClick(organization, newImage) {
        const {uid, onSaveDone} = this.props;
        const objRef = this;
        if (organization.title == null || organization.title === '') {
            alert("You must input title");
        } else if (organization.description == null || organization.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (organization.id == null || organization.id === '') {
                organization.id = generateId("/organization");
                organization.owner = uid != null ? uid : "free";
            }
            organization.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(organization, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone();
                }
                objRef.setState({
                    organization: update,
                    progress: false
                });
            })
        }
    }

    saveChangeWithImage(organization, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(organization.id, 'organizationThumb', newImage, (url) => {
                organization.thumbUri = url;
                objRef.saveChange(organization, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(organization, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(organization, success, failure) {
        invokeHttpsApi('organizerQuery-submitOrganizationUpdate', {
            organization: organization,
        }, (data) => {
            if (data && data.success) {
                success(organization);
            } else {
                failure('error');
            }
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    render() {
        const {onCloseClick, createMode} = this.props;
        const {organization, newImage, newVideo, progress, imageProgress} = this.state;
        
        if (organization != null) {
            return (
                <Form style={createMode ? {width: '100%'} : {}}>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            organization.title = e.target.value;
                            this.setState({organization: organization});
                        }} value={organization.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={organization.description} onChange={e => {
                            organization.description = e.target.value;
                            this.setState({organization: organization});
                        }} />
                    </FormGroup>
                    
                    <AttachImageEdit
                        imageUrl={organization.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(organization, newImage, newVideo);
                                }}>{t('create')}</Button>
                            </div>
                        ) : (
                            <div>
                            <Button className='btn-edit' onClick={e => {
                                this.onSaveClick(organization, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='btn-edit' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                            </div>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(OrganizationEdit);