import React, { Component } from 'react';
import { 
    Button,
    Modal, 
    ModalBody,
    ModalFooter,
    ModalHeader,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { getCurrentPlan } from 'utils/Utils';
import ClubChallengeHeader from 'components/detail/club/ClubChallengeHeader';
import ClubChallengeDetailsView from 'components/detail/club/ClubChallengeDetailsView';
import ClubChallengeEditView from 'components/edit/club/ClubChallengeEditView';
import { listenDb, writeDb } from 'utils/API';

export class ClubChallengesCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            categorys: {},
            clubChallenges: {},
            clubChallengeChoice: {},
            editOpen: false,
            detailOpen: false,
            addOpen: false,
            pickOpen: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;
        listenDb("/category", data => {
            var categorys = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    categorys[key] = value;
                }
            }

            this.setState({
                categorys: categorys,
            });
        });
        listenDb("/clubChallenge", data => {
            var clubChallenges = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (value.owner === "free" || value.owner === user.uid) {
                        clubChallenges[key] = value;
                    }
                }
            }

            this.setState({
                clubChallenges: clubChallenges,
            });
        });
    }

    toggleContentItem(value) {
        const { user, primarySubscription } = this.props;
        const { clubChallenges } = this.state;
        let curConetntCount = 0;
        for (const[, value] of Object.entries(clubChallenges)) {
            if (value.owner === user.uid || (value.sharedIds != null && Object.values(value.sharedIds).includes(user.uid))) {
                curConetntCount += 1;
            }
        }
        let contentLimit = getCurrentPlan(primarySubscription).challengeLimit;

        let available = value.available == null || value.available;
        if (value.owner !== user.uid) {
            writeDb("/clubChallenge/" + value.id + "/sharedIds/" + user.uid, null);
        } else {
            if (available || (contentLimit === -1 || contentLimit > curConetntCount)) {
                writeDb("/clubChallenge/" + value.id + "/available", !available);
            } else {
                alert('You have reached content count limit');
            }
        }
    }

    renderContents() {
        const { user, clubBattle } = this.props;
        const { clubChallenges } = this.state;
        const result = [];

        for (const[key, value] of Object.entries(clubChallenges)) {
    
            if ((clubBattle != null && (clubBattle.challengeIds != null && Object.values(clubBattle.challengeIds).includes(key)))
                ||
                (clubBattle == null && (value.owner === user.uid || (value.sharedIds != null && Object.values(value.sharedIds).includes(user.uid))
                ))
             ) {
                result.push(
                    <ClubChallengeHeader 
                        key={key}
                        uid={user.uid}
                        clubChallenge={value}
                        onClickToggle={() => {
                            this.toggleContentItem(value);
                        }}
                        onClickDetails={() => {
                            this.setState({
                                clubChallengeChoice: value,
                                detailOpen: true
                            })
                        }}
                        onClickEdit={() => {
                            this.setState({
                                clubChallengeChoice: value,
                                editOpen: true
                            })
                        }} />
                )
            }
        }

        return result;
    }

    renderItemChoice() {
        const { user } = this.props;
        const { clubChallenges } = this.state;

        var items = [];
        for (const [key, value] of Object.entries(clubChallenges)) {
            if (value.owner === "free" && (value.sharedIds == null || !Object.values(value.sharedIds).includes(user.uid))) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        writeDb("/clubChallenge/" + value.id + "/sharedIds/" + user.uid, user.uid);
                    }}>{value.title}</ListGroupItem>
                )
            }
        }
        return (
            <ListGroup>
                {items}
            </ListGroup>
        );
    }
    
    render() {
        const { user, primarySubscription, clubBattle } = this.props;
        const {categorys, detailOpen, editOpen, addOpen, pickOpen, clubChallengeChoice, clubChallenges} = this.state;
        let curConetntCount = 0;
        for (const[, value] of Object.entries(clubChallenges)) {
            if (value.owner === user.uid || (value.sharedIds != null && Object.values(value.sharedIds).includes(user.uid))) {
                curConetntCount += 1;
            }
        }
        let contentLimit = getCurrentPlan(primarySubscription).challengeLimit;

        return (
            <div>
                <div style={{marginTop: 10}}>
                    {clubBattle == null && (
                        <div className='wrap-content-parent' style={{marginBottom: 20}}>
                            <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                            Club Challenges ({curConetntCount} / {contentLimit === -1 ? "Unlimited" : contentLimit})
                            </h3>
                            
                            <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                                this.setState({addOpen: true});
                            }}>+</Button>
                        </div>
                    )}
                    {this.renderContents()}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubChallengeEditView 
                            categorys={categorys} 
                            uid={user.uid}
                            clubChallenge={clubChallengeChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <ClubChallengeDetailsView 
                            categorys={categorys} 
                            clubChallenge={clubChallengeChoice} 
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={addOpen}>
                    <ModalHeader>Add New Content</ModalHeader>
                    <ModalBody>
                        <ListGroupItem key='pick_free' onClick={e => {
                            this.setState({pickOpen: true});
                        }}>Pick From Free Contents</ListGroupItem>
                        <ListGroupItem key='create_new' onClick={e => {
                            this.setState({clubChallengeChoice: {}, editOpen: true});
                        }}>Create New Content</ListGroupItem>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-pick" onClick={e => {
                            this.setState({addOpen: false})
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={pickOpen}>
                    <ModalHeader>Pick Free One</ModalHeader>
                    <ModalBody>
                        {this.renderItemChoice()}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-pick" onClick={e => {
                            this.setState({pickOpen: false})
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(ClubChallengesCollection);