import React, { Component } from "react";
import {
    Card, CardImg, Button, Row, Col
} from 'reactstrap';
import { t } from 'i18next';

import SubInfoView from 'components/detail/SubInfoView';
import defaultImage from 'assets/img/icons/shield_icon.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import ReactReadMoreReadLess from "react-read-more-read-less";

import { getSeasonStatusDict, toDateString } from 'utils/Utils';

export class SeasonBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {season, divisions, onClickDetails } = this.props;
        const division = divisions[season.divisionId];
        
        if (division != null) {
            
            let divisionIcon = division.thumbUri != null ? division.thumbUri : defaultImage;
            let teamCount = season.teamIds == null ? 0 : Object.values(season.teamIds).length;
            let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
            let playerCount = 0;
            let playerIds = [];
            if (season && season.teamData) {
                for (const teamData of Object.values(season.teamData)) {
                    if (teamData.roaster) {
                        for (const uid of Object.values(teamData.roaster)) {
                            if (!playerIds.includes(uid)) {
                                playerIds.push(uid);
                            }
                        } 
                    }
                }
            }
            playerCount = playerIds.length;
            
            let dict = getSeasonStatusDict(season);
            const str = dict.str;
            const bgColor = dict.bgColor;
            const fColor = dict.fColor;

            return (
                <div className="">
                    <Card className="shadow-tile grid-badge">
                        <h4 className='grid-item-subInfo' 
                            style={{ 
                                display: 'inline', 
                                position: 'absolute', 
                                top: 0, 
                                right: 0, 
                                backgroundColor: bgColor, 
                                padding: '5px 20px', 
                                marginRight: -5,
                                color: fColor}}>
                        {str}
                        </h4>
                        <div className='grid-item-row'>
                            <CardImg
                                className="grid-item-icon"
                                src={divisionIcon} />
                            <div className='grid-item-info-fill'>
                                <h4 className='grid-item-title'>
                                {season.title} / {division.title}
                                </h4>
                                <div className="grid-item-info">
                                    <ReactReadMoreReadLess
                                        charLimit={80}
                                        readMoreText={"More  ▼"}
                                        readLessText={"Less  ▲"}
                                    >
                                        {division.description ? division.description : ""}
                                    </ReactReadMoreReadLess>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col xs={6} className='text-center'>
                                <h4 className='grid-item-subtitle' style={{margin: '5px 0px'}}>
                                {t('reg_date')}
                                </h4>
                                <h4 className='grid-item-subInfo' style={{ display: 'inline', backgroundColor: '#FFD2D2', padding: '2px 10px', color: '#FF6E6E'}}>
                                {toDateString(season.regDeadline)}
                                </h4>
                            </Col>
                            <Col xs={6} className='text-center'>
                                <h4 className='grid-item-subtitle' style={{margin: '5px 0px'}}>
                                {t('start_date')}
                                </h4>
                                <h4 className='grid-item-subInfo' style={{ display: 'inline', backgroundColor: '#D3E0F1', padding: '2px 10px' , color: '#19447f'}}>
                                {toDateString(season.startTime)}
                                </h4>
                            </Col>
                        </Row>
                        <Row className='grid-item-row' style={{color: '#3D5C97'}}>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('sport')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {t('soccer')}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Venues' icon={venueIcon} number={locationCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('gender')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.gender != null ? division.gender : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Teams' icon={teamIcon} number={teamCount} />
                                </div>
                            </Col>
                            <Col xs={4} style={{padding: '0px 5px'}}>
                                <div className='wrap-content-parent'>
                                    <h4 className='grid-item-subtitle'>
                                    {t('age')}:
                                    </h4>
                                    <div className="grid-item-subInfo">
                                    {division.ageTitle != null ? division.ageTitle : 'None'}
                                    </div>
                                </div>
                                <div>
                                    <SubInfoView title='Players' icon={playersIcon} number={playerCount} />
                                </div>
                            </Col>
                        </Row>
                        <div className='grid-item-row'>
                            <Button className="grid-action-btn grid-item-info-fill" onClick={e => {
                                if (onClickDetails != null) {
                                    onClickDetails(division);
                                }
                            }}>{t('view')}</Button>
                        </div>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default SeasonBadge;