import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { getTeamDomain } from 'utils/Utils';

export const userActions = {
    setAuthRedirect,
    setChild,
    login,
    checkLogin,
    logout,
    register,
    sendResetEmail,
    getAll,
    delete: _delete
};

function checkLogin(userId) {
    return dispatch => {

        userService.checkLogin(userId)
            .then(
                user => { 
                    dispatch(check(user));
                },
                error => {
                    dispatch(failed(null));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function check(user) { return { type: userConstants.CHECK_USER, user } }
    function failed() { return { type: userConstants.CHECK_USER_FAILURE } }
}

function setAuthRedirect(redirectTo) {
    return dispatch => {
        dispatch(request(redirectTo));
    };

    function request(redirectTo) { return { type: userConstants.REDIRECT_TO, redirectTo } }
}

function setChild(child) {
    return dispatch => {
        dispatch(request(child));
    };

    function request(child) { return { type: userConstants.CHILD_CHOICE, child } }
}

function login(email, password, navigate, forMembership, redirectLink, redirectTeam) {
    return dispatch => {
        dispatch(request({ email: email }));

        userService.login(email, password)
            .then(
                user => { 
                    dispatch(success(user));
                    if (redirectTeam && redirectTeam.available && redirectTeam.landingData && 
                        (redirectTeam.owner === user.uid || (redirectTeam.managers && Object.values(redirectTeam.managers).includes(user.uid)))) {
                        navigate(`${getTeamDomain(redirectTeam)}/coach`);
                    } else if (redirectTeam && redirectTeam.available && redirectTeam.landingData && 
                        redirectTeam.playerIds && Object.values(redirectTeam.playerIds).includes(user.uid)) {
                        navigate(`${getTeamDomain(redirectTeam)}/account`);
                    } else if (redirectLink != null) {
                        navigate(redirectLink);
                    } else if (user.type === 'organizer') {
                        navigate(forMembership ? '/organizer/membership' : '/organizer');
                    } else if (user.type === 'coach') {
                        navigate(forMembership ? '/coach/membership' : '/coach');
                    } else {
                        navigate('/');
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function sendResetEmail(email) {
    return dispatch => {
        dispatch(request());

        userService.sendResetEmail(email)
        .then(
            () => { 
                dispatch(success());
            },
            error => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };

    function request() { return { type: userConstants.RESET_REQUEST } }
    function success() { return { type: userConstants.RESET_SUCCESS, } }
    function failure(error) { return { type: userConstants.RESET_FAILURE, error } }
}

function register(user, navigate, redirectLink) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    if (redirectLink != null) {
                        let link = redirectLink.replace(":userId", user.uid);
                        navigate(link);
                    } else if (user != null && user.type === 'organizer') {
                        navigate('/organizer');
                    } else if (user != null && user.type === 'coach') {
                        navigate('/coach');
                    } else {
                        navigate('/');
                    }
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => { 
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}