import React, { Component } from "react";
import { homeVideo } from 'utils/landing';
import * as YoutubePlayer from 'react-player/youtube';

export class BlueVideo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            video: {},
            businesses: {},
            activeIndex: 0,
            animating: false,
        }
    }

    render() {
        const {video} = this.props;
        
        const link = video && video.link ? video.link : homeVideo.link;

        const view = document.getElementById('video-header')
        const width = view ? view.clientWidth : -1;
        const height = width !== -1 ? (width / 16 * 9) : 360;
        
        return (
            <div>
                <div className="home-video-wrapper" style={{height: height + 100}}>
                    <div className="blue-video-overlay">
                        <h1 className="blue-section-title">Tutorial</h1>
                        <YoutubePlayer 
                            id='video-header'
                            className='home-video-player'
                            width="100%"
                            height={height}
                            stopOnUnmount 
                            controls 
                            url={link} />
                    </div>
                </div>
            </div>
        );
    }
}

export default BlueVideo;
