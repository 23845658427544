import React, { Component } from "react";
import {
    Card, CardImg, Col, Row
} from 'reactstrap';

import { 
    getProfileData,
    getTeamData,
    getTimeString,
} from 'utils/Utils';
import {
    HiSwitchHorizontal,
    HiBookmark
} from 'react-icons/hi';

export class EventBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, event, icon, users, teams, hideTeam} = this.props;
        let user = getProfileData(organization, users, event.uid, event.teamId);
        let secUser = getProfileData(organization, users, event.secUid, event.teamId);
        let team = getTeamData(organization, teams, event.teamId);
        
        if (event != null) {
            return (
                <Card className="event-badge-card">
                    <Row>
                        {event.type === "event" && (
                        <Col xs={10} className="event-badge-info">
                            <div className='event-info-group'>
                                <b className="event-info-text">{user.number}</b>
                                <CardImg
                                    className="event-info-icon"
                                    src={user.icon} />
                                <div className="event-info-text">{user.username}</div>
                            </div>
                            {!hideTeam && (
                            <div className='event-info-group'>
                                <CardImg
                                    className="event-info-icon"
                                    src={team.icon} />
                                <div className="event-info-text">{team.title}</div>
                            </div>
                            )}
                            <div className='event-info-group'>
                                <div className="event-info-text">{event.roundTitle}:</div>
                                <b className="event-info-text">{getTimeString(event.roundTime)}</b>
                            </div>
                        </Col>
                        )}
                        {event.type === "score" && (
                        <Col xs={10} className="event-badge-info">
                            <div className='event-info-group'>
                                <b className="event-info-text">{user.number}</b>
                                <CardImg
                                    className="event-info-icon"
                                    src={user.icon} />
                                <div className="event-info-text">{user.username}</div>
                            </div>
                            {!hideTeam && (
                            <div className='event-info-group'>
                                <CardImg
                                    className="event-info-icon"
                                    src={team.icon} />
                                <div className="event-info-text">{team.title}</div>
                            </div>
                            )}
                            <div className='event-info-group'>
                                <div className="event-info-text">{event.roundTitle}:</div>
                                <b className="event-info-text">{getTimeString(event.roundTime)}</b>
                            </div>
                        </Col>
                        )}
                        {event.type === "foul" && (
                        <Col xs={10} className="event-badge-info">
                            <div className='event-info-group'>
                                {event.level !== 'normal' && (
                                    <span className="event-info-text" style={{color: event.level === 'yellow' ? '#f0cb5b' : '#BE4534'}}>
                                        <HiBookmark/>
                                    </span>
                                )}
                                <b className="event-info-text">{user.number}</b>
                                <CardImg
                                    className="event-info-icon"
                                    src={user.icon} />
                                <div className="event-info-text">{user.username}</div>
                            </div>
                            {!hideTeam && (
                            <div className='event-info-group'>
                                <CardImg
                                    className="event-info-icon"
                                    src={team.icon} />
                                <div className="event-info-text">{team.title}</div>
                            </div>
                            )}
                            <div className='event-info-group'>
                                <div className="event-info-text">{event.roundTitle}:</div>
                                <b className="event-info-text">{getTimeString(event.roundTime)}</b>
                            </div>
                        </Col>
                        )}
                        {event.type === "substitute" && (
                        <Col xs={10} className="event-badge-info">
                            <div className='event-info-group'>
                                <b className="event-info-text">{user.number}</b>
                                <div className="event-info-text">{user.username}</div>
                                <b className="event-info-text"><HiSwitchHorizontal/></b>
                                <b className="event-info-text">{secUser.number}</b>
                                <div className="event-info-text">{secUser.username}</div>
                            </div>
                            {!hideTeam && (
                            <div className='event-info-group'>
                                <CardImg
                                    className="event-info-icon"
                                    src={team.icon} />
                                <div className="event-info-text">{team.title}</div>
                            </div>
                            )}
                            <div className='event-info-group'>
                                <div className="event-info-text">{event.roundTitle}:</div>
                                <b className="event-info-text">{getTimeString(event.roundTime)}</b>
                            </div>
                        </Col>
                        )}
                        <Col xs={2} className='event-badge-type'>
                            <h4 className='event-badge-title'>
                            {event.title}
                            </h4>
                            <CardImg
                                className="event-badge-icon"
                                src={icon} />
                        </Col>
                    </Row>
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default EventBadge;