import { coachConstants } from '../_constants';
import { coachService } from '../_services';
import { alertActions } from './';

import { 
    BASIC_SUBSCRIPTION_PRODUCT_ID,
    PLUS_SUBSCRIPTION_PRODUCT_ID,
    PREMIUM_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_SUBSCRIPTION_PRODUCT_ID,
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    FREE_ORG_SUBSCRIPTION_PRODUCT_ID
} from 'utils/Utils';

import { 
    onSnapshot,
 } from 'firebase/firestore';

export const coachActions = {
    checkMembership,
    delete: _delete
};

function checkMembership(coachId) {
    return dispatch => {
        
        onSnapshot(coachService.checkMembership(coachId), snapshot => {
            var subscriptions = {};
            for (const item of snapshot.docs) {
                if (item.data().product.id === BASIC_SUBSCRIPTION_PRODUCT_ID || 
                    item.data().product.id === PLUS_SUBSCRIPTION_PRODUCT_ID || 
                    item.data().product.id === PREMIUM_SUBSCRIPTION_PRODUCT_ID) {
                    subscriptions.primary = item.data();
                }
                if (item.data().product.id === LEAGUE_SUBSCRIPTION_PRODUCT_ID || 
                    item.data().product.id === LOCATION_SUBSCRIPTION_PRODUCT_ID|| 
                    item.data().product.id === LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID) {
                    subscriptions.organization = item.data();
                    //subscriptions.primary = item.data(); /// tmp
                }
                if (item.data().productId === FREE_ORG_SUBSCRIPTION_PRODUCT_ID) {
                    subscriptions.freeOrganization = item.data();
                }
                if (coachId === "lsLqD67LKDSNTV2LsB4NclAjeXe2" || coachId === "PZ5ofM1pmldGmbRFO4fBaz3y0bg2" || coachId === "oyAORKncz6dHWwTBbbK0GlhnkSj2") {
                    if (!subscriptions.primary) {
                        subscriptions.primary = {
                            product: {
                                id: PREMIUM_SUBSCRIPTION_PRODUCT_ID,
                            },
                            current_period_start: 1650560917000,
                            current_period_end: 1770820117000,
                            productId: PREMIUM_SUBSCRIPTION_PRODUCT_ID,
                            status: "active",
                        }
                    }
                }
            }
            
            dispatch(check(subscriptions));
        }, err => {
            console.log(`Encountered error: ${err}`);
            dispatch(failed(null));
            dispatch(alertActions.error(err));
        });
    };

    function check(subscriptions) { return { type: coachConstants.COACH_SUBSCRIPTION, subscriptions } }
    function failed() { return { type: coachConstants.COACH_SUBSCRIPTION_FAILURE } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        coachService.delete(id)
            .then(
                coach => { 
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: coachConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: coachConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: coachConstants.DELETE_FAILURE, id, error } }
}