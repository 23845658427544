import React, { Component } from "react";

import {
    CardImg, Row, Col, Button, FormGroup, Input, Modal, ModalBody
} from 'reactstrap';
import { t } from 'i18next';

import playersIcon from 'assets/img/icons/players_icon.png';
import starIcon from 'assets/img/icons/badge_flower_star.png';
import { getTeamPlayerStatisticsList } from 'utils/statistics';
import OrgGameBadge from 'components/detail/organization/landing/OrgGameBadge';
import {GameDetails} from 'components/detail/organization/landing/GameDetails';
import Pagination from 'react-responsive-pagination';
import { toSimpleDate } from 'utils/Utils';
import { MdContacts, MdEmail, MdLocationCity, MdPhone } from "react-icons/md";
import { BsGenderAmbiguous } from "react-icons/bs";
import { GiWeight } from "react-icons/gi";
import { FaBirthdayCake } from "react-icons/fa";

const numInvalid = 999999999;


export default class OrganizationPlayerCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        }
    }

    renderGames(uid) {
        const { organization, organizations, seasons, divisions, posts, users, teams, cards, locations, loading } = this.props;
        const { gameChoice, locationChoice, page} = this.state;
        
        const seasonsChoice = {};
        for (const [key, value] of Object.entries(seasons)) {
            if (!organization || value.organizationId === organization.id)  {
                seasonsChoice[key] = value;
            }
        }

        let games = [];

        for (const [, value] of Object.entries(seasonsChoice)) {
            if (value.subSeasons != null) {
                for (const [, val] of Object.entries(value.subSeasons)) {
                    if (val.games != null)  {
                        for (const game of Object.values(val.games)) {
                            if (locationChoice == null || locationChoice.id == null || locationChoice.id === game.locationId) {
                                if ((game.scores && game.scores[uid]) || 
                                (game.fouls && game.fouls[uid]) || 
                                (game.events && game.events[uid]) || 
                                (game.gameList && game.gameList[game.homeTeam] && Object.values(game.gameList[game.homeTeam]).includes(uid)) || 
                                (game.gameList && game.gameList[game.awayTeam] && Object.values(game.gameList[game.awayTeam]).includes(uid))) {
                                    games.push(game)
                                }
                            }
                        }
                    }
                }
            }
        }
        
        games.sort((a,b)=>{
            return b.start - a.start;
        })
        
        const result = [];
        const list = [];
        const pageCount = Math.ceil((games ? Object.values(games).length : 0) / 3);
        
        for (const[, value] of Object.entries(games)) {
            if (!organization || value.organizationId === organization.id)  {
                result.push(value);
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.start;            
            let t2 = b.start;

            return t1 - t2;
        })
        const now = new Date().valueOf();
        let pageChoice = page;
        if (page === 0) {
            var index = 0;
            for (const item of result) {
                if (item.start > now) {
                    break;
                }
                index ++;
            }
            pageChoice = parseInt(index / 3) + 1;
        }
        if (pageChoice === result.length / 3 + 1 && pageChoice > 0) {
            pageChoice -= 1;
        }

        let idx = pageChoice > 0 ? (pageChoice - 1) * 3 : 0;
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
            list.push(<div style={{marginLeft: -1, width: 1, heigh: '100%', backgroundColor: '#333'}} />)
        }
        if (result.length > idx) {
            const value = result[idx ++];
            const org = organization ? organization : organizations[value.organizationId];
            list.push(
                <div key={`${value.id}`} className="col-md-4">
                    <OrgGameBadge
                        game={value}
                        teams={teams}
                        organization={org}
                        wideMode={false}
                        locations={locations}
                        users={users}
                        detailMode={true}
                        onClickDetail={e=>{
                            this.setState({gameChoice: value})
                        }} />
                </div>
            );
        }

        return (
            <div id="posts" className="text-center" style={{padding: "25px 0px", backgroundColor: '#eee'}}>
                <div className="container">
                    <FormGroup style={{margin: '5px auto', maxWidth: 360}}>
                        <Input type="select" name="select" value={locationChoice != null ? locationChoice.id : null} id="challengeChoice" onChange={e=>{
                                const id = e.target.value;
                                this.setState({locationChoice: id == null ? {} : locations[id]});
                            }}>
                            <option value={null}></option>
                            {Object.values(locations).map((location)=> (
                                <option value={location.id}>{location.title}</option>
                            )) }
                        </Input>
                    </FormGroup>
                    <div style={{height: 25}} />
                    {list.length === 0 ? (
                        <div style={{height: 145, paddingTop: 25, fontSize: 14, textAlign: 'center', backgroundColor: '#fff'}}>
                        {loading ? "Loading Games" : t('no_items_to_show')}
                        </div>
                    ) : (
                        <div className="row" style={{padding: "25px 0px", backgroundColor: '#fff'}}>
                            {list}
                        </div>
                    )}
                    <div style={{height: 25}} />
                    <Pagination
                        current={pageChoice}
                        total={pageCount}
                        onPageChange={(update) => {
                            this.setState({page: update})
                        }} />
                    <div style={{height: 25}} />
                </div>
                {gameChoice && (
                    <Modal isOpen={true} style={{marginTop: 70}}>
                        <ModalBody>
                            <GameDetails 
                                game={gameChoice}
                                organization={organization ? organization : organizations[gameChoice.organizationId]}
                                locations={locations}
                                divisions={divisions}
                                seasons={seasons}
                                cards={cards}
                                teams={teams}
                                posts={posts}
                                users={users} 
                                onChanged={()=> {
                                }}
                                onClickClose={()=>{
                                    this.setState({gameChoice: null});
                                }} />
                        </ModalBody>
                    </Modal>
                )}
            </div>
        );
    }

    render() {
        const {organization, team, season, seasons, player, onClickClose} = this.props;
        
        let choice = {
            uid: player.uid,
            score: 0,
            assist: 0,
            foul: 0,
            yellow: 0,
            red: 0,
            games: 0,
        }
        if (season) {
            let statistics = getTeamPlayerStatisticsList(season, team != null ? team.id : null);

            if (statistics[player.uid]) {
                choice.score += statistics[player.uid].score;
                choice.assist += statistics[player.uid].assist;
                choice.foul += statistics[player.uid].foul;
                choice.yellow += statistics[player.uid].yellow;
                choice.red += statistics[player.uid].red;
                choice.games += statistics[player.uid].games;
            }
        } else if (seasons) {
            for (const val of Object.values(seasons)) {
                let statistics = getTeamPlayerStatisticsList(val, team != null ? team.id : null);
    
                if (statistics[player.uid]) {
                    choice.score += statistics[player.uid].score;
                    choice.assist += statistics[player.uid].assist;
                    choice.foul += statistics[player.uid].foul;
                    choice.yellow += statistics[player.uid].yellow;
                    choice.red += statistics[player.uid].red;
                    choice.games += statistics[player.uid].games;
                }
            }
        }

        let thumbImage = player && player.photoUri != null ? player.photoUri : playersIcon;
        let teamData = season && season.teamData && season.teamData[team.id] ? season.teamData[team.id] : {};

        let item = null;
        if (teamData.profileData && teamData.profileData[player.uid]) {
            item = teamData.profileData[player.uid];
            item.username = player.username;
            item.photoUri = player.photoUri;
            item.email = player.email;
            item.bio = player.bio;
            item.phoneNumber = player.phoneNumber;
            item.gender = player.gender;
            item.country = player.country;
            item.weight = player.weight;
            item.birthday = player.birthday;

        } else {
            item = {
                uid: player.uid,
                number: numInvalid,
                photoUri: player.photoUri,
                username: player.username,
                bio: player.bio,
                email: player.email,
                phoneNumber: player.phoneNumber,
                gender: player.gender,
                country: player.country,
                weight: player.weight,
                birthday: player.birthday,
            };

        }
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        return (
            <div className="shadow-tile-normal" style={{backgroundColor: 'rgb(238, 238, 238)'}}>
                <Row>
                    <Col className="samba-player-header" sm={4} xs={12} style={actionBg ? {minHeight: 540, backgroundColor: actionBg} : {minHeight: 540}}>
                        <div className="org-player-card-bg-overlay">
                            <CardImg className="org-player-card-photo" src={thumbImage} />
                            <h2 style={{color: 'white'}}>{item.number && item.number !== numInvalid ? "No." + item.number + " " : ""} {item.username}</h2>
                            <div style={{color: 'white', fontSize: 14}}>{item.bio ? item.bio : t('no_bio')}</div>
                        </div>
                    </Col>
                    <Col sm={8} xs={12}>
                        <Row className="statistics-badge" style={actionBg ? {backgroundColor: actionBg} : {}}>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-number">{choice.games}</h3>
                                <h3 className="statistics-badge-title">{t('games_played')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                <h3 className="statistics-badge-number">{choice.score}</h3>
                                <h3 className="statistics-badge-title">{t('scores_label')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                {/* <h3 className="statistics-badge-number"><GiCardPlay style={{color: 'red'}}/>{choice.red}</h3> */}
                                <h3 className="statistics-badge-number">
                                    <div style={{
                                        display: 'inline-block', 
                                        marginRight: 6,
                                        backgroundColor: 'red', 
                                        width: 16, 
                                        height: 19, 
                                        borderRadius: 4}}/>
                                    {choice.red}
                                </h3>
                                <h3 className="statistics-badge-title">{t('red_card')}</h3>
                            </Col>
                            <Col sm={3} xs={6}>
                                {/* <h3 className="statistics-badge-number"><GiCardPlay style={{color: 'yellow'}}/>{choice.yellow}</h3> */}
                                <h3 className="statistics-badge-number">
                                    <div style={{
                                        display: 'inline-block', 
                                        marginRight: 6,
                                        backgroundColor: 'yellow', 
                                        width: 16, 
                                        height: 19, 
                                        borderRadius: 4}}/>
                                    {choice.yellow}
                                </h3>
                                <h3 className="statistics-badge-title">{t('yellow_card')}</h3>
                            </Col>
                        </Row>
                        <div style={{display: "none"}}>
                            <div className='card-contact-item'>
                                <div className="card-contact-title-l">
                                <MdContacts/> {t('contacts')}
                                </div>
                                <div className="card-contact-text">
                                    
                                </div>
                            </div>
                            <div className='card-contact-item'>
                                <div className="card-contact-title">
                                <MdEmail/> {t('email')}
                                </div>
                                <div className="card-contact-text">
                                {item.email}
                                </div>
                            </div>
                            <div className='card-contact-item'>
                                <div className="card-contact-title">
                                <MdPhone/> {t('phone')}
                                </div>
                                <div className="card-contact-text">
                                {item.phoneNumber}
                                </div>
                            </div>
                            {item.gender && (
                            <div className='card-contact-item'>
                                <div className="card-contact-title">
                                <BsGenderAmbiguous /> {t('gender')}
                                </div>
                                <div className="card-contact-text">
                                {item.gender ? item.gender : t('no_gender')}
                                </div>
                            </div>
                            )}
                            {item.country && (
                            <div className='card-contact-item'>
                                <div className="card-contact-title">
                                <MdLocationCity /> {t('country')}
                                </div>
                                <div className="card-contact-text">
                                {item.country ? item.country : t('no_gender')}
                                </div>
                            </div>
                            )}
                            {(item.weight != null && item.weight > 0) && (
                            <div className='card-contact-item'>
                                <div className="card-contact-title">
                                <GiWeight /> {t('weight')}
                                </div>
                                <div className="card-contact-text">
                                {item.weight ? item.weight : t('no_weight')}
                                </div>
                            </div>
                            )}
                            {item.birthday != null && item.birthday > 0 && (
                            <div className='card-contact-item'>
                                <div className="card-contact-title">
                                <FaBirthdayCake /> {t('birthday')}
                                </div>
                                <div className="card-contact-text">
                                {toSimpleDate(item.birthday)}
                                </div>
                            </div>
                            )}
                        </div>
                        
                        <div className="statistics-badge"  style={actionBg ? {marginTop: 25, backgroundColor: actionBg} : {marginTop: 25}}>
                            <img alt='' src={starIcon} style={{width: 24, height: 24, margin: '10px 8px'}} />
                            <h3 style={{display: 'inline-block', color: 'white', fontSize: 20, margin: 'auto 8px'}}>{t('mom_label')}</h3>
                            <h3 style={{display: 'inline-block', color: 'white', fontSize: 20, margin: 'auto 8px'}}>{choice.mom}</h3>
                        </div>
                        
                        {this.renderGames(player.uid)}
                        <div className="text-center" style={{marginBottom: 20}}>
                            {onClickClose && (
                            <Button className="btn-cancel" onClick={e=>{
                                if (onClickClose) {
                                    onClickClose();
                                }
                            }}>{t('close')}</Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
