import React, { Component } from "react";
import {
    CardImg, Button, Col, Row
} from 'reactstrap';
import {
    getProfileData,
    YEAR_TIME
} from 'utils/Utils';

import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';

export class TeamUserBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, team, user, profileData, users, onClickDetail } = this.props;
        const userData = getProfileData(organization, users, user.uid, team.id, profileData);
        const age = userData.birthday !== -1 ? parseInt((new Date().valueOf() - userData.birthday) / YEAR_TIME).toString() : "-";
        let profileItem = {};
        for (const val of Object.values(profileData)) {
            if (val.teamId === team.id && val.uid === user.uid) {
                profileItem = val;
            }
        }
        
        if (organization != null) {
            return (
                <Row>
                    <Col lg={4} xs={4} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={userData.icon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {userData.username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {userData.number}
                            </div>
                        </div>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {userData.position}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {age}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon-small"
                                src={profileItem.verified != null ? (profileItem.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                        </div>
                    </Col>
                    <Col lg={3} xs={12} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                    if (onClickDetail != null) {
                                        onClickDetail(user);
                                    }
                                }}>
                                View
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default TeamUserBadge;