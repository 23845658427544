import React, { Component } from "react";
import {
    Card, CardImg, Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';
import { 
    GiSoccerField,
    GiWhistle,
} from 'react-icons/gi';
import { 
    IoIosListBox
} from 'react-icons/io';
import { toTimeString } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import lineupImage from 'assets/img/icons/lineup.png';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_maybe.png';

export class CoachGameBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    renderLineupBadge() {
        const { division, game, team } = this.props;
        let lineupIcon = verifiedIcon;
        const playerCount = division != null && division.category != null && division.category.playerCount != null ? division.category.playerCount : 10;
        if (game.gameList == null || game.gameList[team.id] == null) {
            lineupIcon = deniedIcon;
        } else if (Object.values(game.gameList[team.id]).length < playerCount) {
            lineupIcon = pendingIcon;
        }

        return (
            <div style={{display: 'inline-block', width: 100, height: 40}}>
                <img src={lineupImage} style={{position: 'absolute', width: 40, height: 40}} alt='status'/>
                <img src={lineupIcon} style={{position: 'absolute', width: 15, height: 15}} alt='status'/>
                <div style={{
                    width: 80, 
                    height: 30, 
                    marginTop: 5, 
                    marginLeft: 20,
                    padding: '3px 5px',
                    backgroundColor: '#19447F', 
                    fontWeight: 600,
                    fontSize: 15,
                    color: 'white', 
                    textAlign: 'right'}}>
                Lineup
                </div>
            </div>
        );
    }
    
    render() {
        const { game, teams, locations, users, onClickDetail } = this.props;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toTimeString(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }
        
        if (game != null) {
            return (
                <Card className="game-badge">
                    <Row>
                        <Col nm={2} xs={2} sm={2} className="text-center">
                            <div className='game-badge-calendar'>
                                <div className='game-badge-day'>
                                    {day}
                                </div>
                                <div className='game-badge-month'>
                                    {month}
                                </div>
                            </div>
                        </Col>
                        <Col nm={8} xs={8} sm={8} className="text-center">
                            <Row>
                                <Col nm={5} xs={12} sm={5} className="text-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        {homeTeam.title}
                                        </div>
                                        <div className="wrap-content-wrap-child">
                                            <CardImg
                                                className="profile-thumb game-team-icon"
                                                src={homeThumb} />
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={2} xs={12} sm={2} className="text-center game-badge-time">
                                <b>{timeStr}</b>
                                {game.homeScore != null && game.awayScore != null && (
                                    <p><b>{game.homeScore} - {game.awayScore}</b></p>
                                )}
                                </Col>
                                <Col nm={5} xs={12} sm={5} className="text-center">
                                    <div className='wrap-content-parent'>
                                        <div className="wrap-content-wrap-child">
                                            <CardImg
                                                className="profile-thumb game-team-icon"
                                                src={awayThumb} />
                                        </div>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        {awayTeam.title}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col nm={6} xs={6} sm={6} className="text-center">
                                    <div>
                                        <div className="vertical-center-spacing">
                                            <GiSoccerField/>
                                        </div>
                                        <div className=' vertical-center-spacing'>
                                        {location.title}
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={6} xs={6} sm={6} className="text-center">
                                    <div>
                                        <div className="vertical-center-spacing">
                                            <GiWhistle/>
                                        </div>
                                        <div className=' vertical-center-spacing'>
                                        {referee.uid != null ? referee.username : t('no_referee')}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col nm={2} xs={2} sm={2} className="game-badge-action">
                            <div className='wrap-content-parent'>
                                <div className='wrap-content-fill-child vertical-center-spacing'>
                                </div>
                                <div className="wrap-content-wrap-child vertical-center-spacing">
                                {this.renderLineupBadge()}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Button style={{margin: '0px auto'}} className="btn-green" onClick={e => {
                            if (onClickDetail != null) {
                                onClickDetail();
                            }
                        }}><IoIosListBox/> {t('detail')}</Button>
                    </Row>
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default CoachGameBadge;