import { dataConstants } from '../_constants';

export function dataCollection(state = {
    data: {},
    progress: false,
    imageProgress: 0,
    videoProgress: 0,
}, action) {
    switch (action.type) {
        case dataConstants.UPDATE_DATA_REQUEST:
            return {
                progress: true,
            };
        case dataConstants.UPDATE_DATA_VIDEO_REQUEST:
            return {
                progress: true,
                imageProgress: 0,
            };
        case dataConstants.UPDATE_DATA_IMAGE_REQUEST:
            return {
                progress: true,
                videoProgress: 0,
            };
        case dataConstants.UPDATE_SUCCESS:
            return {
                data: action.data,
                progress: false,
                imageProgress: 0,
                videoProgress: 0,
            };
        case dataConstants.UPDATE_IMAGE_PROGRESS:
            return {
                imageProgress: action.imageProgress
            };
        case dataConstants.UPDATE_VIDEO_PROGRESS:
            return {
                videoProgress: action.videoProgress
            };
        case dataConstants.UPDATE_FAILURE:
            return { 
                error: action.error,
                progress: false,
                imageProgress: 0,
                videoProgress: 0,
            };
        default:
            return state
    }
}