import React, { Component } from "react";
import {
    Button, Row, Col, Input, ListGroup, ListGroupItem
} from 'reactstrap';
import { t } from 'i18next';

import { getWeekStartDayTime, copyHour, shuffle, removeArrIdx, MIN_TIME, DAY_TIME, WEEK_TIME, checkMatches, initGameLineUp, toDateString, getDayStart } from 'utils/Utils';

import {
    MdRemove, MdWarning
} from 'react-icons/md';

import CustomTimePicker from "components/template/CustomTimePicker";

export class WeeklyBasedDeploy extends Component {
    constructor(props) {
        super(props);
        const locationTimeSet = [];
        if (props.subSeason != null && props.subSeason.teamIds != null) {
            const teamCount = Object.values(props.subSeason.teamIds).length;
            const slotCount = Math.ceil(teamCount / 2);
            for (let i = 0; i < slotCount; i ++) {
                locationTimeSet.push(
                    {week: 0, start: 0, locationId: null}
                );
            }
        }
        this.calcTimes(locationTimeSet);
        this.checklocationTimes(locationTimeSet);
        this.state = {
            editOpen: false,
            doubleAllow: false,
            matchCount: -1,
            saveProgress: false,
            locationTimeSet: locationTimeSet,
        }
        this.deployLeagueGames = this.deployLeagueGames.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {subSeason} = this.props;
        const {locationTimeSet} = this.state;

        if (subSeason != null && prevProps.subSeason != null) {
            const update = [];
            if (subSeason != null && subSeason.teamIds != null) {
                const teamCount = Object.values(subSeason.teamIds).length;
                const slotCount = Math.ceil(teamCount / 2);
                for (let i = 0; i < slotCount; i ++) {
                    update.push(
                        {week: 0, start: 0, locationId: null}
                    );
                }
            }
            if (locationTimeSet.length !== update.length) {
                this.calcTimes(update);
                this.checklocationTimes(update);
                this.setState({
                    locationTimeSet: update,
                })
            }
        }
    }
    
    getTeamIds(excludeId) {
        const homeTeams = [];
        const {subSeason} = this.props;
        if (subSeason != null && subSeason.teamIds != null) {
            for (const [key, ] of Object.entries(subSeason.teamIds)) {
                if (excludeId !== key) {
                    homeTeams.push(key);
                }
            }
        }
        return homeTeams;
    }

    deployLeagueGames() {
        const {season, subSeason, division, onCompleteDeploy} = this.props;
        const {doubleAllow, matchCount, locationTimeSet} = this.state;
        if (!season.teamIds || Object.values(season.teamIds).length === 0) {
            alert("You should pick teams to attend this season.");
            return;
        }
        let timeLimit = division != null && division.category != null && division.category.timeLimit != null ? division.category.timeLimit : 60;
        const games = [];
        let original = this.getTeamIds();
        const teamCount = original.length;
        if (teamCount % 2 === 1) {
            original.push('None');
        }
        original = shuffle(original);
        original = shuffle(original);
        original = shuffle(original);

        let homeTeams = [];
        let awayTeams = [];

        for (let i = 0; i < original.length / 2; i ++) {
            homeTeams.push(original[i]);
            awayTeams.push(original[i + original.length / 2]);
        }

        let matchLength = (matchCount != null && matchCount !== -1) ? matchCount : original.length -1;

        var offsetTimeSet = [];
        for (const val of locationTimeSet) {
            offsetTimeSet.push({
                locationId: val.locationId,
                offset: val.offset,
                offsetEnd: val.offsetEnd,
                start: val.start,
                week: val.week,
                overlapped: val.overlapped,
            });
        }
        offsetTimeSet.sort((a, b) => {
            return a.offset - b.offset;
        });

        let startOffset = season.startTime - getWeekStartDayTime(season.startTime);

        var idx = 0;
        for (var i = 0; i < offsetTimeSet.length; i++) {

            if (offsetTimeSet[i].offset > startOffset) {
                idx = i;
                break;
            }
        }
        for (i = 0; i < offsetTimeSet.length; i++) {

            if (i < idx) {
                offsetTimeSet[i].offset += (WEEK_TIME)
                offsetTimeSet[i].offsetEnd += (WEEK_TIME)
            }
        }

        offsetTimeSet.sort((a, b) => {
            return a.offset - b.offset;
        });

        for (let j = 0; j < matchLength; j ++) {
            let startWeek = getWeekStartDayTime(season.startTime) + WEEK_TIME * (j);
    
            for (let i = 0; i < original.length / 2; i ++) {
                let gameStart = copyHour(offsetTimeSet[i].offset + startWeek, startWeek - WEEK_TIME * (j))
                games.push({
                    homeTeam: homeTeams[i],
                    awayTeam: awayTeams[i],
                    start: gameStart,
                    end: gameStart + timeLimit * MIN_TIME,
                    locationId: offsetTimeSet[i].locationId,
                    divisionId: season.divisionId,
                    organizationId: season.organizationId,
                    seasonId: season.id,
                    subSeasonId: subSeason.id,
                    matchDayIndex: j
                })
            }
    
            let update = [];
            let homeUpdate = [];
            let awayUpdate = [];
    
            for (let i = 0; i < original.length / 2; i ++) {
                if (i !== 0) {
                    update.push(homeTeams[i])
                }
            }
    
            for (let i = 0; i < original.length / 2; i ++) {
                update.push(awayTeams[awayTeams.length - i - 1])
            }
            let arr = [];
            arr.push(update[update.length - 1]);
            for (let i = 0; i < update.length - 1; i ++) {
                arr.push(update[i]);
            }
            homeUpdate.push(homeTeams[0]);
            for (let i = 0; i < update.length; i ++) {
                if (i < homeTeams.length - 1) {
                    homeUpdate.push(arr[i]);
                } else {
                    let idx = update.length + homeTeams.length - i - 2;
                    awayUpdate.push(arr[idx]);
                }
            }
            homeTeams = homeUpdate;
            awayTeams = awayUpdate;
        }
        if (doubleAllow) {
            for (let j = 0; j < original.length - 1; j ++) {
                let startWeek = getWeekStartDayTime(season.startTime) + WEEK_TIME * (j + original.length - 1);
        
                for (let i = 0; i < original.length / 2; i ++) {
                    let gameStart = copyHour(offsetTimeSet[i].offset + startWeek, startWeek)
                    games.push({
                        homeTeam: awayTeams[i],
                        awayTeam: homeTeams[i],
                        start: gameStart,
                        end: gameStart + timeLimit * MIN_TIME,
                        locationId: offsetTimeSet[i].locationId,
                        divisionId: season.divisionId,
                        organizationId: season.organizationId,
                        seasonId: season.id,
                        subSeasonId: subSeason.id,
                        matchDayIndex: j + original.length - 1
                    })
                }
        
                let update = [];
                let homeUpdate = [];
                let awayUpdate = [];
        
                for (let i = 0; i < original.length / 2; i ++) {
                    if (i !== 0) {
                        update.push(homeTeams[i])
                    }
                }
        
                for (let i = 0; i < original.length / 2; i ++) {
                    update.push(awayTeams[awayTeams.length - i - 1])
                }
                let arr = [];
                arr.push(update[update.length - 1]);
                for (let i = 0; i < update.length - 1; i ++) {
                    arr.push(update[i]);
                }
                homeUpdate.push(homeTeams[0]);
                for (let i = 0; i < update.length; i ++) {
                    if (i < homeTeams.length - 1) {
                        homeUpdate.push(arr[i]);
                    } else {
                        let idx = update.length + homeTeams.length - i - 2;
                        awayUpdate.push(arr[idx]);
                    }
                }
                homeTeams = homeUpdate;
                awayTeams = awayUpdate;
            }
        }
        checkMatches(games, doubleAllow, matchCount);

        let result = [];
        for (const value of games) {
            initGameLineUp(value, season);
            if (value.homeTeam !== 'None' && value.awayTeam !== 'None') {
                result.push(value);
            }
        }
        
        if (onCompleteDeploy != null) {
            onCompleteDeploy(result, doubleAllow, matchCount);
        }
    }



    checklocationTimes(locationTimeMap) {
        let arr = locationTimeMap;
        
        for (const value of locationTimeMap) {
            value.overlapped = false
        }
        for(let i = 0; i < arr.length; i ++) {
            for (let j = i; j < arr.length; j ++) {
                if (i !== j && arr[j].offset != null && arr[i].offset != null && arr[i].locationId === arr[j].locationId && (
                    (arr[j].offset >= arr[i].offset && arr[j].offset <= arr[i].offsetEnd) ||
                    (arr[j].offsetEnd >= arr[i].offset && arr[j].offsetEnd <= arr[i].offsetEnd)
                    )) {
                    arr[j].overlapped = true;
                    arr[i].overlapped = true;
                }
            }
        }
    }

    calcTimes(locationTimeMap) {
        const {division} = this.props;
        let timeLimit = division != null && division.category != null && division.category.timeLimit != null ? division.category.timeLimit : 60;

        for (const value of locationTimeMap) {
            if (value.start != null && value.week != null) {
                let offset = value.start;
                offset += value.week * DAY_TIME;

                value.offset = offset;
                value.offsetEnd = offset + timeLimit * MIN_TIME;
            }
        }
    }

    renderLeagueGameDeploy() {
        const {locations} = this.props;
        const {locationTimeSet} = this.state;
        let dayStart = getDayStart(new Date().valueOf());

        return (
            <ListGroup>
            {
            locationTimeSet.map((value, idx)=>(
                <ListGroupItem key={idx}>
                    <Row>
                        <Col sm={4}>
                            <CustomTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time picker"
                                value={new Date(value.start ? dayStart + value.start : dayStart)}
                                onChange={ date => {
                                    locationTimeSet[idx].start= date.valueOf() - dayStart;
                                    this.calcTimes(locationTimeSet);
                                    this.checklocationTimes(locationTimeSet);
                                    this.setState({locationTimeSet: locationTimeSet});
                                }} />
                        </Col>
                        <Col style={{margin: 'auto 0px'}} sm={3}>
                            <Input type="select" name="week" value={locationTimeSet[idx].week} id="week" onChange={e=> {
                                locationTimeSet[idx].week = parseInt(e.target.value);
                                this.calcTimes(locationTimeSet);
                                this.checklocationTimes(locationTimeSet);
                                this.setState({locationTimeSet: locationTimeSet});
                            }}>
                                <option value={0}>{t('sunday')}</option>
                                <option value={1}>{t('monday')}</option>
                                <option value={2}>{t('tuesday')}</option>
                                <option value={3}>{t('wednesday')}</option>
                                <option value={4}>{t('thursday')}</option>
                                <option value={5}>{t('friday')}</option>
                                <option value={6}>{t('saturday')}</option>
                            </Input>
                        </Col>
                        <Col style={{margin: 'auto 0px'}} sm={4}>
                            <Input type="select" name="typeChoice" value={locationTimeSet[idx].locationId} id="typeChoice" onChange={e=> {
                                locationTimeSet[idx].locationId = e.target.value;
                                this.calcTimes(locationTimeSet);
                                this.checklocationTimes(locationTimeSet);
                                this.setState({locationTimeSet: locationTimeSet});
                            }}>
                                <option key="null" value={null}></option>
                                {Object.values(locations).map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.title}</option>
                                ))}
                            </Input>
                        </Col>
                        <Col sm={1}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-edit" onClick={e=> {
                                        removeArrIdx(locationTimeSet, idx);
                                        this.calcTimes(locationTimeSet);
                                        this.checklocationTimes(locationTimeSet);
                                        this.setState({locationTimeSet: locationTimeSet});
                                    }}><MdRemove/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {value.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                </ListGroupItem>
            ))}
            </ListGroup>
        );
    }

    render() {
        const {season, subSeason} = this.props;
        const {doubleAllow, matchCount} = this.state;
        const teamCount = subSeason.teamIds != null ? Object.values(subSeason.teamIds).length : 0;

        
        return (
            <div style={{marginTop: 10, marginBottom: 20}} >
                <h4 className='wrap-content-fill-child' style={{marginTop: 10, marginBottom: 10}}>
                {t('week_games_deployment')}, {t('teams')}: {teamCount}, Season Starts: {toDateString(season.startTime)}
                </h4>
                <div className='player-form-item'>
                    <div className='player-form-title'>
                        Match Count Mode
                    </div>
                    <div className='player-form-value form-setting-control'>
                        <div>
                            <input className='survey-option-icon' type="radio" name='single mode' checked={doubleAllow === false && matchCount === -1} onChange={e => {
                                if (e.target.checked) {
                                    this.setState({doubleAllow: false, matchCount: -1});
                                }
                            }}/>{' '}
                            <div className='survey-option-item'>Every team plays with each team once</div>
                        </div>
                        <div>
                            <input className='survey-option-icon' type="radio" name='single mode' checked={doubleAllow === true && matchCount === -1} onChange={e => {
                                if (e.target.checked) {
                                    this.setState({doubleAllow: true, matchCount: -1});
                                }
                            }}/>{' '}
                            <div className='survey-option-item'>Every team plays with each team twice</div>
                        </div>
                        <div>
                            <input className='survey-option-icon' type="radio" name='single mode' checked={matchCount !== -1} onChange={e => {
                                if (e.target.checked) {
                                    this.setState({matchCount: teamCount});
                                }
                            }}/>{' '}
                            <div className='survey-option-item'>Every team plays 

                            <input type="number" value={matchCount} onChange={e=> {
                                this.setState({matchCount: parseInt(e.target.value)})
                            }}/></div>
                        </div>
                    </div>
                </div>
                <div className='divider' />
                {this.renderLeagueGameDeploy()}
                <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                <Button className='btn-submit' onClick={ e => {
                    this.deployLeagueGames();
                }}>{t('deploy')}</Button>
            </div>
        );
    }
}

export default WeeklyBasedDeploy;