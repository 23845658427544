import React, { Component } from "react";
import { getFileExtension } from 'utils/FirebaseUtils';
import {
    FormText, Input, CardImg, Progress
} from 'reactstrap';

export class HorizontalAttachImageEdit extends Component {
    render() {
        const {imageUrl, imageClass, allowProgress, progress, onChange, imageWidth, imageHeight, defaultImage, instruction} = this.props;
        let image = imageUrl != null && imageUrl !== '' ? imageUrl : defaultImage;
        
        return (
            <div className='wrap-content-parent'>
                {image != null && (
                <div className='wrap-content-wrap-child' style={{marginRight: 20}}>
                    <CardImg 
                        className={imageClass} 
                        width={imageWidth}
                        height={imageHeight}
                        src={image} />

                </div>
                )}
                <div className='wrap-content-fill-child'>
                    <Input type="file" name="file" id="imageFile" onChange={e => {                                
                        if (onChange != null && e.target.files != null && e.target.files.length > 0) {
                            let file = e.target.files[0];
                            let extension = getFileExtension(file.name);
                            if (extension === 'png' || extension === 'jpg') {
                                onChange(file);
                            } else {
                                alert("Only jpg or png files are allowed here. This file will be ignored.");
                            }
                        }
                    }} />
                    <FormText color="muted">
                    You can {imageUrl != null && imageUrl !== '' ? 'update the current' : 'upload a new'} image 
                    </FormText>
                    {instruction && (
                    <FormText color="muted">
                    {instruction}
                    </FormText>
                    )}
                    {allowProgress && (
                        <Progress className='progress-style-file' color="warning" value={progress} />
                    )}
                </div>
            </div>
        );
    }
}

export default HorizontalAttachImageEdit;