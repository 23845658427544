import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Col, Row, Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultLeauge from 'assets/img/icons/league_default.png';
import defaultChallenge from 'assets/img/icons/video_default.png';
import defaultPlayer from 'assets/img/icons/player_photo_default.png';

export class ChallengeReviewFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaguesOpen: false,
            challengesOpen: false,
            usersOpen: false,
            saveProgress: false,
            filterOpen: false,
        }
    }

    renderLeagueChoice() {
        const { leagues, challengeChoice, uidChoice, onFilterChoice } = this.props;

        if (leagues != null && Object.values(leagues).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onFilterChoice != null) {
                        onFilterChoice({}, challengeChoice, uidChoice)
                    }
                    this.setState({leaguesOpen: false});
                }}>All Playlists</ListGroupItem>
            )
            for (const[key, value] of Object.entries(leagues)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onFilterChoice != null) {
                            onFilterChoice(value, challengeChoice, uidChoice)
                        }
                        this.setState({leaguesOpen: false});
                    }}>{value.title}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Playlist Present</div>
        }
    }
    
    renderChallengeChoice() {
        const { challenges, leagueChoice, uidChoice, onFilterChoice } = this.props;

        if (challenges != null && Object.values(challenges).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onFilterChoice != null) {
                        onFilterChoice(leagueChoice, {}, uidChoice)
                    }
                    this.setState({challengesOpen: false});
                }}>All Challenges</ListGroupItem>
            )
            for (const[key, value] of Object.entries(challenges)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onFilterChoice != null) {
                            onFilterChoice(leagueChoice, value, uidChoice)
                        }
                        this.setState({challengesOpen: false});
                    }}>{value.title}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Challenges Present</div>
        }
    }
    
    renderUserChoice() {
        const { users, submissions, leagueChoice, challengeChoice, onFilterChoice } = this.props;

        const challengeUsers = {};
        if (submissions != null) {
            for (const[, value] of Object.entries(submissions)) {
                if (users[value.uid] != null) {
                    challengeUsers[value.uid] = users[value.uid];
                }
            }
        }

        if (challengeUsers != null && Object.values(challengeUsers).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onFilterChoice != null) {
                        onFilterChoice(leagueChoice, challengeChoice, null)
                    }
                    this.setState({usersOpen: false});
                }}>All Users</ListGroupItem>
            )
            for (const[key, value] of Object.entries(challengeUsers)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onFilterChoice != null) {
                            onFilterChoice(leagueChoice, challengeChoice, value.uid)
                        }
                        this.setState({usersOpen: false});
                    }}>{value.username}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>No Users Present</div>
        }
    }
    
    render() {
        const { leagues, challenges, users, leagueChoice, challengeChoice, uidChoice, globalMode } = this.props;
        const { leaguesOpen, challengesOpen, usersOpen, filterOpen } = this.state;
        const userChoice = users[uidChoice];

        let leagueImage = leagueChoice != null && leagueChoice.iconUri != null ? leagueChoice.iconUri : defaultLeauge;
        let challengeImage = challengeChoice != null && challengeChoice.thumbUri != null ? challengeChoice.thumbUri : defaultChallenge;
        let userImage = userChoice != null && userChoice.photoUri != null ? userChoice.photoUri : defaultPlayer;
        
        if (leagues != null) {
            return (
                <div>
                    <h3 style={{fontSize: 12}}>Review Filter</h3>
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <Row>
                                {globalMode && (
                                    <Col sm={4}>
                                        <CardImg
                                            className="profile-thumb vertical-center-spacing"
                                            src={leagueImage}
                                            style={{ width: 25, height: 25, marginLeft:5, marginRight: 10 }}
                                            />
                                        
                                        <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>
                                        {leagueChoice.id != null ? leagueChoice.title : 'No Playlist Choice'}
                                        </div>
                                        
                                    </Col>
                                )}
                                <Col sm={4}>
                                    <CardImg
                                        className="profile-thumb vertical-center-spacing"
                                        src={challengeImage}
                                        style={{ width: 25, height: 25, marginLeft:5, marginRight: 10 }}
                                        />
                                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>
                                    {challengeChoice.id != null ? challengeChoice.title : 'No Challenge Choice'}
                                    </div>
                                    
                                </Col>
                                <Col sm={4}>
                                    <CardImg
                                        className="profile-thumb vertical-center-spacing"
                                        src={userImage}
                                        style={{ width: 25, height: 25, marginLeft:5, marginRight: 10 }}
                                        />
                                    <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>
                                    {userChoice != null && userChoice.uid != null ? userChoice.username : 'No User Choice'}
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Button className='wrap-content-wrap-child' onClick={e => {
                            this.setState({filterOpen: true})
                        }}>Change Filter</Button>
                    </div>
                    <Modal isOpen={filterOpen}>
                        <ModalBody>
                        
                            {globalMode && (
                                <Card style={{marginBottom: 20}}>
                                    <CardBody>
                                        <div className='wrap-content-parent'>
                                            <div className='wrap-content-fill-child'>
                                                <div style={{fontSize: 12}}>
                                                    Playlist Filter: {Object.values(leagues).length} Playlists
                                                </div>
                                                {leagueChoice.id != null ? (
                                                    <div className='wrap-content-parent'>
                                                        <CardImg
                                                            className="profile-thumb flex-row wrap-content-wrap-child vertical-center-spacing"
                                                            src={leagueImage}
                                                            style={{ width: 25, height: 25 }}
                                                            />
                                                        <h3 className='wrap-content-fill-child vertical-center-spacing'>{leagueChoice.title}</h3>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        No Playlist Choice
                                                    </div>
                                                )}
                                            </div>
                                            <Button className='wrap-content-wrap-child' onClick={e => {
                                                this.setState({leaguesOpen: true})
                                            }}>Pick Playlist</Button>

                                        </div>
                                    </CardBody>
                                </Card>
                            )}
                            <Card style={{marginBottom: 20}}>
                                <CardBody>
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child'>
                                            <div style={{fontSize: 12}}>
                                                Challenge Filter: {Object.values(challenges).length} Challenge
                                            </div>
                                            {challengeChoice.id != null ? (
                                                <div className='wrap-content-parent'>
                                                    <CardImg
                                                        className="profile-thumb flex-row wrap-content-wrap-child vertical-center-spacing"
                                                        src={challengeImage}
                                                        style={{ width: 25, height: 25 }}
                                                        />
                                                    <h3 className='wrap-content-fill-child vertical-center-spacing'>{challengeChoice.title}</h3>
                                                </div>
                                            ) : (
                                                <div>
                                                    No Challenge Choice
                                                </div>
                                            )}
                                        </div>
                                        <Button className='wrap-content-wrap-child' onClick={e => {
                                            this.setState({challengesOpen: true})
                                        }}>Pick Challenge</Button>

                                    </div>
                                </CardBody>
                            </Card>
                            <Card style={{marginBottom: 20}}>
                                <CardBody>
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child'>
                                            <div style={{fontSize: 12}}>
                                                Users Filter: {Object.values(challenges).length} User
                                            </div>
                                            {userChoice != null && userChoice.uid != null ? (
                                                <div className='wrap-content-parent'>
                                                    <CardImg
                                                        className="profile-thumb flex-row wrap-content-wrap-child vertical-center-spacing"
                                                        src={userImage}
                                                        style={{ width: 25, height: 25 }}
                                                        />
                                                    <h3 className='wrap-content-fill-child vertical-center-spacing'>{userChoice.username}</h3>
                                                </div>
                                            ) : (
                                                <div>
                                                    No User Choice
                                                </div>
                                            )}
                                        </div>
                                        <Button className='wrap-content-wrap-child' onClick={e => {
                                            this.setState({usersOpen: true})
                                        }}>Pick User</Button>

                                    </div>
                                </CardBody>
                            </Card>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({filterOpen: false})
                            }}>{t('close')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={leaguesOpen}>
                        <ModalBody>
                            {this.renderLeagueChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({leaguesOpen: false})
                            }}>{t('cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={challengesOpen}>
                        <ModalBody>
                            {this.renderChallengeChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({challengesOpen: false})
                            }}>{t('cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={usersOpen}>
                        <ModalBody>
                            {this.renderUserChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={e => {
                                this.setState({usersOpen: false})
                            }}>{t('cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ChallengeReviewFilter);