import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Card, CardImg, Modal, ModalBody, ModalFooter, ListGroupItem
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Grid, Slider } from "@mui/material";
import { Add, RemoveCircle } from "@mui/icons-material";

import {
    GiSoccerKick,
    GiSoccerBall,
    GiCardPlay,
} from 'react-icons/gi';
import {
    HiSwitchHorizontal,
    HiBookmark
} from 'react-icons/hi';
import {
    getPlayerData,
    getTimeString,
    MIN_TIME,
} from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';

export class GameEventEdit extends Component {
    constructor(props) {
        super(props);
        const { team, round } = this.props;
        const event = {};
        if (props.event != null) {
            for (const [key, value] of Object.entries(props.event)) {
                event[key] = value;
            }
        }
        event.teamId = team.id;
        event.roundTitle = round.title;
        
        this.state = {
            event: event,
            type: event.type,
            eventType: {},
            progress: false,
            editTime: 0,
            pickUids: [],
            pickPlayer: false,
            pickSecPlayer: false,
            newImage: {},
            imageProgress: 0
        }
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, event} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                pickUids: [],
                pickPlayer: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.event !== event)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(event)) {
                challengeVal[key] = value;
            }
            this.setState({
                event: challengeVal,
                type: challengeVal.type,
            })
        }
    }

    renderHeader() {
        const {team, round} = this.props;
        const {event, type} = this.state;
        let teamThumb = team.iconUri != null ? team.iconUri : defaultImage;

        return(
            <div className='round-team-events-header'>
                <div className="wrap-content-wrap-child">
                    <CardImg
                        className="profile-thumb game-team-icon"
                        src={teamThumb} />
                </div>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                {team.title}
                </div>
                {!event.id && (
                <div className="wrap-content-wrap-child">
                    <div className="round-event-icon" style={type === "score" ? {color: '#3684BB'} : {}} onClick={e=>{
                            this.setState({type: "score", event: {teamId: team.id, roundTitle: round.title, roundId: round.id}})
                    }}>
                        <GiSoccerBall />
                    </div>
                    <div className="round-event-icon" style={type === "event" ? {color: '#3684BB'} : {}} onClick={e=>{
                            this.setState({type: "event", event: {teamId: team.id, roundTitle: round.title, roundId: round.id}})
                    }}>
                        <GiSoccerKick />
                    </div>
                    <div className="round-event-icon" style={type === "foul" ? {color: '#3684BB'} : {}} onClick={e=>{
                        this.setState({type: "foul", event: {teamId: team.id, roundTitle: round.title, roundId: round.id}})
                    }}>
                        <GiCardPlay />
                    </div>
                    <div className="round-event-icon" style={type === "substitute" ? {color: '#3684BB'} : {}} onClick={e=>{
                        this.setState({type: "substitute", event: {teamId: team.id, roundTitle: round.title, roundId: round.id}})
                    }}>
                        <HiSwitchHorizontal />
                    </div>
                </div>
                )}
            </div>
        )
    }

    renderEditView() {
        const {type} = this.state;

        switch (type) {
            case "event":
                return(
                    this.renderEventEditView()
                )
            case "score":
                return(
                    this.renderScoreEditView()
                )
            case "foul":
                return(
                    this.renderFoulEditView()
                )
            case "substitute":
                return(
                    this.renderSubstituteView()
                )
            default:
                return <div/>
        }
    }

    renderEventEditView() {
        const {category, organization, season, round, game, users, team, onChangeEvent, onDeleteEvent} = this.props;
        const {event, progress} = this.state;
        let player = event.uid != null ? getPlayerData(organization, season, users, event.uid, team.id) : {};
        const eventType = category.eventData[event.eventTypeId] != null ? category.eventData[event.eventTypeId] : {};

        return(
            <Form>
                <FormGroup>
                    <Label for="typeChoice">{t('event_type')}: {eventType.title}</Label>
                    <Input className='round-team-events-header' type="select" name="select" id="typeChoice" onChange={e=> {
                        const choice = category.eventData[e.target.value];
                        event.title = choice.title;
                        event.type = choice.type;
                        event.eventTypeId = choice.id;
                        this.setState({event: event});
                    }} value={event.eventTypeId}>
                        <option value={null}></option>
                    {Object.values(category.eventData).map((val,idx)=>(
                        <option key={idx} value={val.id}>{val.title}</option>
                    ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <div className='round-team-events-header' style={{padding: 0}} onClick={e=>{
                        let pickUids = [];
                        if (game.gameList != null && game.gameList[team.id] != null) {
                            for (const[key] of Object.entries(game.gameList[team.id])) {
                                pickUids.push(key);
                            }
                        }
                        this.setState({pickUids: pickUids, pickPlayer: true, pickSecPlayer: false});
                    }}>
                        {player.username != null ? (
                            <div className='event-info-group'>
                                <b className="event-info-text">{player.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={player.icon} />
                                <div className="event-info-text">{player.username}</div>
                            </div>
                        ) : (
                            <div className='event-info-group'>
                                <div className="event-info-text">{t('player_choice')}</div>
                            </div>
                        )}
                    </div>
                </FormGroup>
                {this.renderGameTimeLine()}
                {progress ? (
                    <Spinner color="primary" />
                ) : (
                    <div>
                        <Button className='btn-edit' onClick={e => {
                            if (event.type == null) {
                                alert(t('pick_event_type'));
                                return;
                            }
                            if (event.uid == null) {
                                alert(t('pick_player_prompt'));
                                return;
                            }
                            if (onChangeEvent != null) {
                                onChangeEvent(event, round);
                            }
                        }}>{event.id ? t('save') : t('add')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (onDeleteEvent != null) {
                                onDeleteEvent(event, round);
                            }
                        }}>{t('delete')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (this.props.onCloseClick) {
                                this.props.onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </div>
                )}
            </Form>
        )
    }

    renderScoreEditView() {
        const {category, organization, season, round, game, users, team, onChangeEvent, onDeleteEvent} = this.props;
        const {event, progress} = this.state;
        let player = event.uid != null ? getPlayerData(organization, season, users, event.uid, team.id) : {};
        let assist = event.assist != null ? getPlayerData(organization, season, users, event.assist, team.id) : {};
        const eventType = category.scoreData[event.eventTypeId] != null ? category.scoreData[event.eventTypeId] : {};

        return(
            <Form>
                <FormGroup>
                    <Label for="typeChoice">{t('score_type')}: {eventType.title}</Label>
                    <Input className='round-team-events-header' type="select" name="select" id="typeChoice" onChange={e=> {
                        const choice = category.scoreData[e.target.value];
                        event.title = choice.title;
                        event.type = choice.type;
                        event.eventTypeId = choice.id;
                        event.number = choice.number ? choice.number : 1;
                        this.setState({event: event});
                    }} value={event.eventTypeId}>
                        <option value={null}></option>
                    {Object.values(category.scoreData).map((val,idx)=>(
                        <option key={idx} value={val.id}>{val.title}</option>
                    ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <div className='round-team-events-header' style={{padding: 0}} onClick={e=>{
                        let pickUids = [];
                        if (game.gameList != null && game.gameList[team.id] != null) {
                            for (const[key] of Object.entries(game.gameList[team.id])) {
                                pickUids.push(key);
                            }
                        }
                        this.setState({pickUids: pickUids, pickPlayer: true, pickSecPlayer: false});
                    }}>
                        {player.username != null ? (
                            <div className='event-info-group'>
                                <b className="event-info-text">{player.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={player.icon} />
                                <div className="event-info-text">{player.username}</div>
                            </div>
                        ) : (
                            <div className='event-info-group'>
                                <div className="event-info-text">{t('player_choice')}</div>
                            </div>
                        )}
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className='round-team-events-header' style={{padding: 0}} onClick={e=>{
                        let pickUids = [];
                        if (game.gameList != null && game.gameList[team.id] != null) {
                            for (const[key] of Object.entries(game.gameList[team.id])) {
                                pickUids.push(key);
                            }
                        }
                        this.setState({pickUids: pickUids, pickPlayer: true, pickSecPlayer: true});
                    }}>
                        {assist.username != null ? (
                            <div className='event-info-group'>
                                <b className="event-info-text">In: {assist.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={assist.icon} />
                                <div className="event-info-text">{assist.username}</div>
                            </div>
                        ) : (
                            <div className='event-info-group'>
                                <div className="event-info-text">Assist Choice</div>
                            </div>
                        )}
                    </div>
                </FormGroup>
                {this.renderGameTimeLine()}
                
                {progress ? (
                    <Spinner color="primary" />
                ) : (
                    <div>
                        <Button className='btn-edit' onClick={e => {
                            if (event.type == null) {
                                alert(t('pick_score_type'));
                                return;
                            }
                            if (event.uid == null) {
                                alert(t('pick_player_prompt'));
                                return;
                            }
                            if (onChangeEvent != null) {
                                onChangeEvent(event, round);
                            }
                        }}>{event.id ? t('save') : t('add')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (onDeleteEvent != null) {
                                onDeleteEvent(event, round);
                            }
                        }}>{t('delete')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (this.props.onCloseClick) {
                                this.props.onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </div>
                )}
            </Form>
        )
    }

    renderFoulEditView() {
        const {category, organization, season, round, game, users, team, onChangeEvent, onDeleteEvent} = this.props;
        const {event, progress} = this.state;
        let player = event.uid != null ? getPlayerData(organization, season, users, event.uid, team.id) : {};
        const eventType = category.foulData[event.eventTypeId] != null ? category.foulData[event.eventTypeId] : {};

        return(
            <Form>
                <FormGroup>
                    <Label for="typeChoice">{t('foul_type')}: {eventType.title}</Label>
                    <Input className='round-team-events-header' type="select" name="select" id="typeChoice" onChange={e=> {
                        const choice = category.foulData[e.target.value];
                        event.title = choice.title;
                        event.type = choice.type;
                        event.eventTypeId = choice.id;
                        this.setState({event: event});
                    }} value={event.eventTypeId}>
                        <option value={null}></option>
                    {Object.values(category.foulData).map((val,idx)=>(
                        <option key={idx} value={val.id}>{val.title}</option>
                    ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <div className='round-team-events-header' style={{padding: 0}} onClick={e=>{
                        let pickUids = [];
                        if (game.gameList != null && game.gameList[team.id] != null) {
                            for (const[key] of Object.entries(game.gameList[team.id])) {
                                pickUids.push(key);
                            }
                        }
                        this.setState({pickUids: pickUids, pickPlayer: true, pickSecPlayer: false});
                    }}>
                        {player.username != null ? (
                            <div className='event-info-group'>
                                <b className="event-info-text">{player.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={player.icon} />
                                <div className="event-info-text">{player.username}</div>
                            </div>
                        ) : (
                            <div className='event-info-group'>
                                <div className="event-info-text">{t('player_choice')}</div>
                            </div>
                        )}
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label for="typeChoice">Foul Level {event.level != null && event.level !== 'normal' && (
                        <span style={{color: event.level === 'yellow' ? '#f0cb5b' : '#BE4534'}}><HiBookmark/></span>
                    )}</Label>
                    <Input className='round-team-events-header' type="select" name="select" id="typeChoice" onChange={e=> {
                        event.level = e.target.value;
                        this.setState({event: event});
                    }} value={event.level}>
                        <option value='normal'>{t('normal')}</option>
                        <option value='yellow'>{t('yellow_card')}</option>
                        <option value='red'>{t('red_card')}</option>
                    </Input>
                </FormGroup>
                {this.renderGameTimeLine()}
                
                {progress ? (
                    <Spinner color="primary" />
                ) : (
                    <div>
                        <Button className='btn-edit' onClick={e => {
                            if (event.type == null) {
                                alert(t('pick_foul_type'));
                                return;
                            }
                            if (event.uid == null) {
                                alert(t('pick_player_prompt'));
                                return;
                            }
                            if (event.level == null) {
                                alert(t('pick_foul_level'));
                                return;
                            }
                            if (onChangeEvent != null) {
                                onChangeEvent(event, round);
                            }
                        }}>{event.id ? t('save') : t('add')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (onDeleteEvent != null) {
                                onDeleteEvent(event, round);
                            }
                        }}>{t('delete')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (this.props.onCloseClick) {
                                this.props.onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </div>
                )}
            </Form>
        )
    }

    renderSubstituteView() {
        const {organization, season, round, game, users, team, onChangeEvent, onDeleteEvent} = this.props;
        const {event, progress} = this.state;
        let player = event.uid != null ? getPlayerData(organization, season, users, event.uid, team.id) : {};
        let secPlayer = event.secUid != null ? getPlayerData(organization, season, users, event.secUid, team.id) : {};

        return(
            <Form>
                <FormGroup>
                    <div className='round-team-events-header' style={{padding: 0}} onClick={e=>{
                        let pickUids = [];
                        if (game.gameList != null && game.gameList[team.id] != null) {
                            for (const[key] of Object.entries(game.gameList[team.id])) {
                                pickUids.push(key);
                            }
                        }
                        this.setState({pickUids: pickUids, pickPlayer: true, pickSecPlayer: false});
                    }}>
                        {player.username != null ? (
                            <div className='event-info-group'>
                                <b className="event-info-text">Out: {player.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={player.icon} />
                                <div className="event-info-text">{player.username}</div>
                            </div>
                        ) : (
                            <div className='event-info-group'>
                                <div className="event-info-text">{t('player_choice')}</div>
                            </div>
                        )}
                    </div>
                </FormGroup>
                <FormGroup>
                    <div className='round-team-events-header' style={{padding: 0}} onClick={e=>{
                        let pickUids = [];
                        if (game.benchList != null && game.benchList[team.id] != null) {
                            for (const[key] of Object.entries(game.benchList[team.id])) {
                                pickUids.push(key);
                            }
                        }
                        event.type = "substitute";
                        this.setState({event: event, pickUids: pickUids, pickPlayer: true, pickSecPlayer: true});
                    }}>
                        {secPlayer.username != null ? (
                            <div className='event-info-group'>
                                <b className="event-info-text">In: {secPlayer.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={secPlayer.icon} />
                                <div className="event-info-text">{secPlayer.username}</div>
                            </div>
                        ) : (
                            <div className='event-info-group'>
                                <div className="event-info-text">{t('substitute_choice')}</div>
                            </div>
                        )}
                    </div>
                </FormGroup>
                {this.renderGameTimeLine()}
                {progress ? (
                    <Spinner color="primary" />
                ) : (
                    <div>
                        <Button className='btn-edit' onClick={e => {
                            if (event.uid == null) {
                                alert(t('pick_player_prompt'));
                                return;
                            }
                            if (event.secUid == null) {
                                alert("You should pick bench player");
                                return;
                            }
                            if (onChangeEvent != null) {
                                onChangeEvent(event, round);
                            }
                        }}>{event.id ? t('save') : t('add')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (onDeleteEvent != null) {
                                onDeleteEvent(event, round);
                            }
                        }}>{t('delete')}</Button>
                        <Button className='btn-cancel' onClick={e=>{
                            if (this.props.onCloseClick) {
                                this.props.onCloseClick();
                            }
                        }}>{t('close')}</Button>
                    </div>
                )}
            </Form>
        )
    }

    renderGameTimeLine() {
        const {round} = this.props;
        const {event} = this.state;

        let length = round.time != null ? round.time * MIN_TIME : 0;
        let editTime = event.roundTime ? event.roundTime : 0;

        let timeStr = getTimeString(editTime);

        let progress = length !== 0 ? editTime / length * 100 : 0;
        return (
            <FormGroup>
                <Label for="typeChoice">Game Time: {timeStr}</Label>
                <Grid container spacing={2}>
                    <Grid item>
                        <RemoveCircle onClick={e=>{
                            event.roundTime = editTime - 1000;
                            this.setState({event: event});
                        }} />
                    </Grid>
                    <Grid item xs>
                        <Slider value={progress} onChange={(e,update)=>{
                            event.roundTime = length * update / 100;
                            this.setState({event: event});
                        }} aria-labelledby="continuous-slider" />
                    </Grid>
                    <Grid item>
                        <Add onClick={e=>{
                            event.roundTime = editTime + 1000;
                            this.setState({event: event});
                        }} />
                    </Grid>
                </Grid>
            </FormGroup>
        );
    }

    render() {
        const {organization, season, users, team} = this.props;
        const {event, type, pickPlayer, pickUids, pickSecPlayer} = this.state;
        const usersToPick = [];
        for (const uid of pickUids) {
            let user = getPlayerData(organization, season, users, uid, team.id);
            usersToPick.push(user);
        }
        usersToPick.sort((a, b)=> {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })

        if (event != null) {
            return (
                <div>
                {this.renderHeader()}
                <Card className='event-edit-wrapper'>
                {this.renderEditView()}
                </Card>
                <Modal isOpen={pickPlayer}>
                    <ModalBody>
                    {usersToPick.map((val,idx)=>(
                        <ListGroupItem key={idx} onClick={e=>{
                            if (type === "substitute" && pickSecPlayer) {
                                event.secUid = val.uid;
                            } else if (type === "score" && pickSecPlayer) {
                                event.assist = val.uid;
                            } else {
                                event.uid = val.uid;
                            }
                            this.setState({event: event, pickPlayer: false, pickSecPlayer: false});
                        }}>
                            <div className='event-info-group'>
                                <b className="event-info-text">{val.number}</b>
                                <CardImg
                                    className="profile-thumb event-info-icon"
                                    src={val.icon} />
                                <div className="event-info-text">{val.username}</div>
                            </div>
                        </ListGroupItem>
                    ))}
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-cancel' onClick={e=>{
                            this.setState({pickPlayer: false, pickSecPlayer: false});
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(GameEventEdit);