import React, { Component } from "react";
import {
    Card, CardImg, Row, Col, Button, Modal, ModalBody
} from 'reactstrap';
import UserBadge from 'components/detail/UserBadge';
import ChallengeReviewView from 'components/detail/ChallengeReviewView';
import { toDateTimeString } from 'utils/Utils';

import defaultChallenge from 'assets/img/icons/video_icon.png';

export class ChallengeReviewBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
        }
    }
    
    render() {
        const {challenge, submission, user } = this.props;
        const {detailOpen} = this.state;
        let dateStr = toDateTimeString(submission.timestamp);
        let challengeImage = challenge.thumbUri != null ? challenge.thumbUri : defaultChallenge;
        let status = 'In Review';
        let statusColor = '#F0D11F';
        if (submission.status === 'passed') {
            status = 'PASSED';
            statusColor = '#6ec447';
        } else if (submission.status === 'failed') {
            status = 'FAILED';
            statusColor = '#f87668';
        } else {
            status = 'IN REVIEW';
            statusColor = '#F0D11F';
        }
        let totalAttempts = submission.attempt_count;
        let totalPoints = challenge.points;
        
        if (challenge != null) {
            return (
                <div className='shadow-tile'>
                    <Card>
                        <div className='wrap-content-parent' style={{marginTop: 10}}>
                            <div className='wrap-content-fill-child vertical-center-spacing' style={{marginLeft: 20}}>
                                <Row>
                                    <Col sm={4}>
                                        <UserBadge customClass='wrap-content-wrap-child' user={user}/>
                                    </Col>
                                    <Col sm={8}>
                                        <CardImg
                                            className="profile-thumb"
                                            src={challengeImage}
                                            style={{ width: 20, height: 20, marginRight: 10 }}
                                            />
                                        <div style={{display:'inline-block', fontSize: 12, fontWeight: 1000}}>{challenge.title}</div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='wrap-content-wrap-child' style={{marginLeft: 10, marginRight: 20, fontSize: 12, fontWeight: 1000}}>{dateStr}</div>
                        </div>
                        <div className='wrap-content-parent' style={{marginTop: 10, marginBottom: 10}}>
                            <div className='wrap-content-fill-child vertical-center-spacing' style={{marginLeft: 20}}>
                                <Row>
                                    <Col sm={4}>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000, color: statusColor}}>
                                            {status}
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12}}>
                                            CHALLENGE
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            STATUS
                                        </div>
                                        
                                    </Col>
                                    <Col sm={4}>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            {totalAttempts}
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12}}>
                                            TOTAL
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            ATTEMPTS
                                        </div>
                                        
                                    </Col>
                                    <Col sm={4}>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            {totalPoints}
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12}}>
                                        TOTAL
                                        </div>
                                        <div style={{width: '100%', textAlign: 'center', fontSize: 12, fontWeight: 1000}}>
                                            POINTS
                                        </div>
                                        
                                    </Col>
                                </Row>
                            </div>

                            <div className='wrap-content-wrap-child' style={{marginLeft: 10, marginTop: 15, marginRight: 10}}>
                                <Button className="btn-submit" onClick={e => {
                                    this.setState({detailOpen: true})
                                }}>Review</Button>
                            </div>
                        </div>
                    </Card>
                    <Modal isOpen={detailOpen}>
                        <ModalBody>
                            <ChallengeReviewView 
                                challenge={challenge} 
                                submission={submission} 
                                onCloseClick={() => {
                                    this.setState({detailOpen: false})
                                }} />
                        </ModalBody>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default ChallengeReviewBadge;