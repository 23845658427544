import React, { Component } from 'react';

import SubSeasonTeamBadge from 'components/detail/organization/SubSeasonTeamBadge';

export class SubSeasonTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subSeasonChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }
    
    renderContents() {
        const {subSeason, teams} = this.props;

        let items = [];
        let members = [];
        let others = [];
        for (const [key, value] of Object.entries(teams)) {
            const isMember = subSeason.teamIds != null && Object.values(subSeason.teamIds).includes(key);
            
            if (isMember) {
                members.push(value);
            }
            if (!isMember) {
                others.push(value);
            }
        }
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        others.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        for (const value of members) {
            items.push(
                this.renderBadge(subSeason, value)
            );
        }
        for (const value of others) {
            items.push(
                this.renderBadge(subSeason, value)
            );
        }
        return (
        <div>
            {items}
        </div>
        );
    }

    renderBadge(subSeason, team) {
        const {onAdded, onRemoved} = this.props;
        return (
            <div key={team.id} style={{marginTop: 10, marginBottom: 10}}>
                <SubSeasonTeamBadge 
                    key={team.id}
                    team={team}
                    subSeason={subSeason}
                    onAdded={(teamId)=> {
                        if (onAdded != null) {
                            onAdded(teamId);
                        }
                    }}
                    onRemoved={(teamId)=> {
                        if (onRemoved != null) {
                            onRemoved(teamId);
                        }
                    }} />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderContents()}
            </div>
        );
    }
}

export default SubSeasonTeams;
