import React, { Component } from 'react';
import { filterActions } from 'redux/_actions/filter.actions';
import { connect } from 'react-redux';
import {OrganizationTeamOverview} from 'components/detail/organization/landing/OrganizationTeamOverview';

import { 
    FcConferenceCall,
    FcManager,
} from 'react-icons/fc';
import { 
    Input,
    Row,
    Col,
    Button,
} from 'reactstrap';
import { t } from 'i18next';

import JsonData from 'components/landing/data/data.json';
import LoadingView from 'components/detail/loading/LoadingView';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import logo200Image from 'assets/img/logo/live_192.png';
import { getOrgData, getOrgDomain, getOrganizationPoints, getTeamDomain, replaceUrl, sortByNumberInverse } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';
import { SambaOrgTeamDetails } from 'components/detail/organization/landing/samba/SambaOrgTeamDetails';

class OrgTeams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            teamChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            posts: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            mainTab: 0,
        }
    }

    getlandingPageData() {
        this.setState({landingPageData : JsonData})
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const teamId = this.props.router.params.teamId;
            this.setState({id: id, teamId: teamId});
        }
        this.getlandingPageData();
        this.loadContents();
    }

    loadContents() {
        let teamId = '';
        if (this.props.router != null && this.props.router.params != null) {
            teamId = this.props.router.params.teamId;
        }
        const queryData = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizationLandingQuery', queryData, ((data)=>{

            const organizations = {};
            const organization = {};
            const divisions = {};
            const teamChoice = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};

            if (data.success) {
                this.props.updateDict(data);
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (key === queryData.organizationId || value.customDomain === queryData.customDomain) {
                        for (const [k, v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                if (!data.customDomain && organizations[data.organizationId] != null) {
                    for (const [key, value] of Object.entries(organizations[data.organizationId])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    if (value.organizationId === organization.id) {
                        seasons[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                    if (teams[teamId]) {
                        for (const [k,v] of Object.entries(teams[teamId])) {
                            teamChoice[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                teamChoice: teamChoice,
                loading: false,
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderTeamTiles() {
        const {organization, teams, seasons, searchStr} = this.state;
        const teamItems = [];
        for (const [key, val] of Object.entries(teams)) {
            if (searchStr != null && searchStr.length > 0 && !val.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            const isMember = organization.teamIds != null && Object.values(organization.teamIds).includes(key);
            
            if (isMember) {
                val.points = getOrganizationPoints(organization, seasons, null, val.id);
                teamItems.push(val);
            }
        }
        sortByNumberInverse(teamItems, "points");

        let items = [];
        for (const val of teamItems) {
            items.push(
                <Col key={val.id} xs={6} sm={4} lg={3} xl={2} style={{padding: 0}}>
                    <div className='samba-team-tile' onClick={e=>{
                        replaceUrl(`${getOrgDomain(organization)}/teams/${val.id}`);
                        this.setState({teamChoice: val});
                    }}>
                        <div className='samba-team-tile-title'>{val.title}</div>
                        <img className='samba-team-tile-icon' src={val.iconUri ? val.iconUri : defaultImage} alt={val.title} />
                        <div className='samba-team-tile-score'>{val.points}</div>
                    </div>
                </Col>
            )
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                {items.length > 0 ? (
                    <Row style={{margin: 0}}>
                        {items}
                    </Row>
                ) : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_teams')}
                    </div>
                )}
                
            </div>
        );
    }

    renderTeamHeader() {
        const {organization, teamChoice} = this.state;

        let icon = teamChoice.iconUri != null ? teamChoice.iconUri : defaultImage;
        
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';
        
        return (
            <div className='samba-team-header'>
                <div className='container wrap-content-parent'>
                    <img alt='team' className='home-header-icon' src={icon} />
                    <div className="wrap-content-fill-child">
                        <h1 className="home-header-title-small">
                            {teamChoice.title}
                            <span></span>
                        </h1>
                        {sambaTheme ? (
                        <h3 className="home-header-sub-title">
                            <Button
                                className="btn btn-team-main btn-lg page-scroll"
                                style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                onClick={e=>{
                                    replaceUrl(`${getOrgDomain(organization)}/teams`);
                                    this.setState({teamChoice: {}});
                                }}
                            >{t('back')}</Button>
                        </h3>
                        ) : (
                        <h3 className="home-header-sub-title">
                            <FcConferenceCall/> {teamChoice.playerIds ? Object.values(teamChoice.playerIds).length : 0}
                            <FcManager/> {teamChoice.managers ? Object.values(teamChoice.managers).length : 0}
                            <div style={{display: 'inline-block', width: 20}}></div>
                            <a
                                href={getTeamDomain(teamChoice)}
                                className="btn btn-team-main btn-lg page-scroll"
                                style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            >
                                {t('official_site')}
                            </a>
                            <Button
                                className="btn btn-team-main btn-lg page-scroll"
                                style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                                onClick={e=>{
                                    replaceUrl(`${getOrgDomain(organization)}/teams`);
                                    this.setState({teamChoice: {}});
                                }}
                            >{t('back')}</Button>
                        </h3>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    renderDetails() {
        const {organization, teamChoice, seasons, divisions, posts, users, teams, locations, cards} = this.state;
        const theme = organization.landingData && organization.landingData.theme ? organization.landingData.theme : null;

        if (theme === 'samba') {
            return (
                <SambaOrgTeamDetails
                    organization={organization}
                    team={teamChoice}
                    divisions={divisions}
                    seasons={seasons}
                    users={users}
                    posts={posts}
                    cards={cards}
                    teams={teams}
                    locations={locations}
                    onTeamUpdate={(update) => {
                        teams[update.id] = update;
                        this.setState({teams: teams});
                    }} />
            );
        }

        return (
            <OrganizationTeamOverview
                organization={organization}
                team={teamChoice}
                divisions={divisions}
                seasons={seasons}
                users={users}
                posts={posts}
                cards={cards}
                teams={teams}
                locations={locations} />
        );
    }

    renderContents() {
        const {teamChoice} = this.state;

        if (teamChoice.id != null) {
            return (
            <div>
                {this.renderDetails()}
            </div>
            )
        } else {
            return (
            <div>
                {this.renderTeamTiles()}
            </div>
            )
        }
    }

    render() {
        const {organization, teamChoice, loading, searchStr} = this.state;
        
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const theme = organization.landingData && organization.landingData.theme ? organization.landingData.theme : null;

        return (
            <div>
                
            {teamChoice.id != null ? (
                <div>{this.renderTeamHeader()}</div>
            ) : (
                <div>{theme !== 'samba' && this.renderOrganizationHeader()}</div>
            )}
                {!teamChoice.id && (
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="search" id="search" placeholder='Search by Team Title ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </div>                        
                </div>
                )}
                <div style={{marginTop: 20}} />
                <div className='container'>
                    {this.renderContents()}
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { filter, dict } = state.filterData;
    return { loggingIn, user, filter, dict };
}

const actionCreators = {
    updateDict: filterActions.updateDict,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgTeams));
export { connectedPage as OrgTeams };
