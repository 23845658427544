import React, { Component } from "react";
import CountUp from 'react-countup';
import {
    Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { STATUS_FINISHED } from "utils/constants";

export class TeamCount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: {},
            businesses: {},
            activeIndex: 0,
            animating: false,
        }
    }

    render() {
        const {team, seasons, profileData} = this.props;
        
        const bgClass = team.landingData && team.landingData.theme === 'blue' ? 'org-landing-contents-bg-blue' : 'org-landing-contents-bg';

        let playerIds = [];
        let goalCount = 0;
        let gameCount = 0;

        for (const profile of Object.values(profileData)) {
            if (profile.organizationId === team.id) {
                if (!playerIds.includes(profile.uid)) {
                    playerIds.push(profile.uid);
                }
            }
        }
        for (const season of Object.values(seasons)) {
            if (season.subSeasons) {
                for (const subSeason of Object.values(season.subSeasons)) {
                    if (subSeason.games != null) {
                        for (const game of Object.values(subSeason.games)) {
                            if (game.homeTeam === team.id || game.awayTeam === team.id) {
                                if (game.status === STATUS_FINISHED) {
                                    gameCount += 1;
                                }
                                if (game.scores != null) {
                                    for (const score of Object.values(game.scores)) {
                                        if (score.number) {
                                            goalCount += score.number;
                                        } else {
                                            goalCount += 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
        const headerStyle = {
            color: '#333',
            fontWeight: 500,
            fontSize: 18,
        }

        const style = {
            color: '#333',
            fontWeight: 700,
            fontSize: 40,
        }
        return (
            <div className={bgClass} style={{marginBottom: 20, paddingBottom: 20}}>
                <Row>
                    <Col sm={4} className='text-center'>
                        <h4 style={headerStyle}>{t('players_registered')}</h4>
                        <CountUp style={style} end={playerIds.length}/>
                    </Col>
                    <Col sm={4} className='text-center'>
                        <h4 style={headerStyle}>{t('games_played')}</h4>
                        <CountUp style={style} end={gameCount}/>
                    </Col>
                    <Col sm={4} className='text-center'>
                        <h4 style={headerStyle}>{t('goals')}</h4>
                        <CountUp style={style} end={goalCount}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(TeamCount);
