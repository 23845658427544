import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { 
    GiBabyfootPlayers,
} from 'react-icons/gi';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import { saveDivision } from "utils/API";
import { t } from 'i18next';

export class DivisionTeamBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {division, team, onChanged } = this.props;
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;
        let playerCount = team.playerIds == null ? 0 : Object.values(team.playerIds).length;
        const isMember = division.teamIds != null && Object.values(division.teamIds).includes(team.id);

        if (division != null) {
            return (
                <div className="info-badge-small">
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb info-badge-icon-small"
                            src={thumbImage} />
                        <CardBody className="info-badge-body-small">
                            <Row>
                                <Col nm={8} xs={12} sm={6} className="vertical-center">
                                    <h4 className='label label-default one-line-title'>
                                    {team.title}
                                    </h4>
                                    <div className='wrap-content-parent'>
                                        <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                        <GiBabyfootPlayers/> {playerCount}
                                        </div>
                                    </div>
                                </Col>
                                <Col nm={4} xs={12} sm={6} className="vertical-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: -10}}>
                                            {!isMember && (
                                                <Button className="btn-submit" onClick={e => {
                                                    if (!division.teamIds) {
                                                        division.teamIds = {};
                                                    }
                                                    division.teamIds[team.id] = team.id;
                                                    saveDivision(division, () => {
                                                        onChanged();
                                                    }, (error) => {
                                                        console.log(error);
                                                    });
                                                }}>{t('pick_to_division')}</Button>
                                            )}
                                            {isMember && (
                                                <Button className="btn-cancel" onClick={e => {
                                                    if (division.teamIds && division.teamIds[team.id]) {
                                                        delete division.teamIds[team.id];
                                                    }
                                                    saveDivision(division, () => {
                                                        onChanged();
                                                    }, (error) => {
                                                        console.log(error);
                                                    });
                                                }}>{t('remove_from_division')}</Button>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default DivisionTeamBadge;