import React, { PureComponent } from 'react';
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/Routher';
import { t } from 'i18next';

import { getOrgData, getOrgDomain, replaceUrl } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import {FundraisePrompt} from 'components/fundraise/FundraisePrompt';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import { 
    RiTeamFill,
} from 'react-icons/ri';
import { 
    FcMoneyTransfer,
    FcDonate,
} from 'react-icons/fc';
import { 
    Input,
    Row,
    Col,
} from 'reactstrap';

import logo200Image from 'assets/img/logo/live_192.png';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import fundIcon from 'assets/img/icons/fundraise.png';
import LoadingView from 'components/detail/loading/LoadingView';
import { EmptyScreen } from 'components/detail/loading/EmptyScreen';

class OrgFundraises extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            seasonChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            mainTab: 0,
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const teamId = this.props.router.params.teamId;
            this.setState({id: id, teamId: teamId});
        }
        this.loadContents();
    }

    loadContents() {
        let id = '';
        let fundId = '';
        if (this.props.router != null && this.props.router.params != null) {
            fundId = this.props.router.params.fundId;
        }
        this.setState({loading: true});
        const queryData = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-organizationLandingQuery', queryData, ((data)=>{

            const organizations = {};
            const organization = {};
            const divisions = {};
            const seasons = {};
            const profileData = {};
            const newsFeed = {};
            const teams = {};
            const locations = {};
            const users = {};
            const category = {};
            const posts = {};
            const cards = {};
            const fundraises = {};
            const fundraisePayments = {};
            let fundChoice = null;

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    id = key;
                }
                if (organizations[id] != null) {
                    for (const [key, value] of Object.entries(organizations[id])) {
                        organization[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraises)) {
                    fundraises[key] = value;
                    if (key === fundId) {
                        fundChoice = value;
                    }
                }
                for (const [key, value] of Object.entries(data.fundraisePayments)) {
                    fundraisePayments[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                divisions: divisions,
                seasons: seasons,
                profileData: profileData,
                newsFeed: newsFeed,
                teams: teams,
                locations: locations,
                users: users,
                category: category,
                posts: posts,
                cards: cards,
                fundraises: fundraises,
                fundraisePayments: fundraisePayments,
                fundChoice: fundChoice,
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    renderDetails() {
        const {user} = this.props;
        const {organization, users, fundraises, fundraisePayments, fundChoice} = this.state;

        return (
            <FundraisePrompt
                fundraise={fundChoice}
                fundraisePayments={fundraisePayments}
                user={user}
                users={users}
                organization={organization}
                onChanged={(update)=> {
                    fundraises[update.id] = update;
                    this.setState({fundraises: fundraises, fundChoice: update});
                }}
                onCloseClick={()=> {
                    this.setState({fundChoice: null});
                }} />
        );
    }

    getFundraises() {
        const {organization, fundraises, searchStr} = this.state;

        const fundraiseItems = [];
        for (const [, val] of Object.entries(fundraises)) {
            if (searchStr && searchStr.length > 0 && !val.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            const isMember = val.organizationId === organization.id;
            
            if (isMember) {
                fundraiseItems.push(val);
            }
        }
        return fundraiseItems;
    }

    renderTable() {
        const {searchStr} = this.state;
        const fundraiseItems = this.getFundraises();

        let items = [];
        for (const fundraise of fundraiseItems) {
            const donateCount = fundraise.donate ? Object.values(fundraise.donate).length : 0;
            
            items.push(
                <TableRowSmall 
                    key={fundraise.id}
                    title={fundraise.title}
                    icon={fundIcon}
                    subView={
                        <div className='wrap-content-parent'>
                            <h4 style={{marginBottom: 0, marginRight: 15, fontSize: 15}}>
                            <FcDonate /> {t('goal')} ${fundraise.donateGoal}, {t('collected')}: ${0}
                            </h4>
                            <div style={{marginBottom: 0, fontSize: 15}}>
                            <FcMoneyTransfer /> {t('donated')}: {donateCount}, {t('bingo')}: {0}
                            </div>
                        </div>
                    }
                    onClickDetails={() => {
                        this.setState({fundChoice: fundraise})
                    }} />
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="search" id="search" placeholder='Search by Team Title ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </div>                        
                </div>
                <div className='shadow-tile'>
                    <Row style={{margin: 0}}>
                        <Col lg={5} xs={7} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Team
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Player
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Season
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderContents() {
        const {fundChoice} = this.state;

        if (fundChoice) {
            return (
            <div>
                {this.renderDetails()}
            </div>
            )
        } else {
            return (
            <div>
                {this.renderTable()}
            </div>
            )
        }
    }

    render() {
        const {organization, fundraises, fundChoice, loading} = this.state;
        
        const fundCount = fundraises ? Object.values(fundraises).length : 0;
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';

        if (loading || !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        
        return (
            <div>
                {!sambaTheme && this.renderOrganizationHeader()}
                <div style={{marginTop: 20}} />
                <div className='org-landing-contents-bg-blue'>
                    <ItemsNavigation
                        title={t('fundraises')}
                        subtitle={t('members')}
                        icon={<RiTeamFill/>}
                        detailOpen={fundChoice && fundChoice.id}
                        editOpen={false}
                        totalCount={fundCount}
                        detailItem={fundChoice}
                        onClickBack={()=>{
                            replaceUrl(`${getOrgDomain(organization)}/fund`);
                            this.setState({fundChoice: null});
                        }} />
                    {this.renderContents()}
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgFundraises));
export { connectedPage as OrgFundraises };
