import React, { Component } from "react";

import Avatar from './Avatar';

export class AvatarBadge extends Component {
    render() {
        const {user, subInfo, customClass, textColor, showDetail, greetingMode} = this.props;
        
        if (user != null) {
            if (greetingMode) {
                return (
                    <div style={{display: 'flex', flexFlow: 'row'}} className={customClass != null ? customClass : ''}>
                        <div className='wrap-content-wrap-child vertical-center-spacing'>
                            <h4 className='label label-default' 
                                style={
                                    textColor ? 
                                    {display: 'inline-block', fontSize: 14, marginRight: 5, marginBottom: 2, color: textColor} : 
                                    {display: 'inline-block', fontSize: 14, marginRight: 5, marginBottom: 2, color: '#fff'}}>
                                Hi, {user.username}
                            </h4>
                            {showDetail && (
                                <div className='label label-default' 
                                    style={
                                        textColor ? 
                                        {display: 'inline-block', fontSize: 16, marginLeft: 15, marginBottom: 2, color: textColor} : 
                                        {display: 'inline-block', fontSize: 16, marginLeft: 15, marginBottom: 2, color: '#fff'}}>
                                    Location: {user.location}
                                </div>
                            )}
                            {subInfo != null && (
                                <p style={{fontSize: 12, marginBottom: 0}}>{subInfo}</p>
                            )}
                        </div>

                        <Avatar src={user.photoUri} 
                            style={{
                                width: 30,
                                height: 30,
                                border: textColor ? ( '2px solid ' + textColor) : '2px solid #000',
                                backgroundColor: '#fff'
                            }} 
                            className='wrap-content-wrap-child vertical-center-spacing' />
                    </div>
                );
            } else {
                return (
                    <div style={{display: 'flex', flexFlow: 'row'}} className={customClass != null ? customClass : ''}>
                        <Avatar src={user.photoUri} 
                            style={{
                                width: 30,
                                height: 30,
                                border: textColor ? ( '2px solid ' + textColor) : '2px solid #000',
                                backgroundColor: '#fff'
                            }} 
                            className='wrap-content-wrap-child vertical-center-spacing' />
                        <div className='wrap-content-wrap-child vertical-center-spacing'>
                            <h4 className='label label-default' 
                                style={
                                    textColor ? 
                                    {display: 'inline-block', fontSize: 16, marginLeft: 5, marginBottom: 2, color: textColor} : 
                                    {display: 'inline-block', fontSize: 16, marginLeft: 5, marginBottom: 2, color: '#fff'}}>
                                {user.username}
                            </h4>
                            {showDetail && (
                                <div className='label label-default' 
                                    style={
                                        textColor ? 
                                        {display: 'inline-block', fontSize: 16, marginLeft: 15, marginBottom: 2, color: textColor} : 
                                        {display: 'inline-block', fontSize: 16, marginLeft: 15, marginBottom: 2, color: '#fff'}}>
                                    Location: {user.location}
                                </div>
                            )}
                            {subInfo != null && (
                                <p style={{fontSize: 12, marginBottom: 0}}>{subInfo}</p>
                            )}
                        </div>
    
                    </div>
                );
            }
        } else {
            return <div/>;
        }
    }
}

export default AvatarBadge;