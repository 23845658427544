import React, { Component } from "react";
import {
    CardImg, Button, Col, Row
} from 'reactstrap';
import { t } from 'i18next';

import playerIcon from 'assets/img/icons/player_photo_default.png';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import { writeDb } from "utils/API";

export class OrganizationRefereeRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, refereeData, referee, onClickDetail, onChanged } = this.props;
        const userIcon = refereeData != null && refereeData.photoUri != null ? refereeData.photoUri : 
                (referee != null && referee.photoUri != null ? referee.photoUri : playerIcon);
        const username = refereeData != null ? refereeData.playerName : referee.username;

        const isMember = organization.refereeIds != null && Object.values(organization.refereeIds).includes(referee.uid);
        const isPending = organization.refereePendingIds != null && Object.values(organization.refereePendingIds).includes(referee.uid);
        const isInvited = organization.refereeInvitedIds != null && Object.values(organization.refereeInvitedIds).includes(referee.uid);
        
        
        if (organization != null) {
            return (
                <Row>
                    <Col lg={4} xs={5} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={userIcon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {username}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={4} xs={5} sm={4} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {t('referee')}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={1} xs={2} sm={1} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="row-badge-icon-small" style={{margin: 'auto'}}
                                src={refereeData != null && refereeData.verified != null ? (refereeData.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                        </div>
                    </Col>
                    <Col lg={3} xs={12} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                                {isMember && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" onClick={e => {
                                            writeDb('/organization/' + organization.id + '/refereeIds/' + referee.uid, null, error => {
                                                if (error) {
                                                    console.log(error);
                                                } else if (onChanged != null) {
                                                    onChanged();
                                                }
                                            })
                                        }}>{t('remove')}</Button>
                                        {refereeData && (
                                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                            if (onClickDetail != null) {
                                                onClickDetail();
                                            }
                                        }}>
                                        View
                                        </Button>
                                        )}
                                        {!refereeData && (
                                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                            alert(referee.username + " has not submitted registration form yet.")
                                        }}>
                                        {t('no_data')}
                                        </Button>
                                        )}
                                    </div>
                                )}
                                {isPending && (
                                    <div className='wrap-content-parent'>
                                        <Button className="btn-submit" onClick={e => {
                                            writeDb('/organization/' + organization.id + '/refereeIds/' + referee.uid, referee.uid, error => {
                                                if (error) {
                                                    console.log(error);
                                                } else {
                                                    writeDb('/organization/' + organization.id + '/refereePendingIds/' + referee.uid, null);
                                                    if (onChanged != null) {
                                                        onChanged();
                                                    }
                                                }
                                            })
                                        }}>{t('accept')}</Button>
                                        <Button className="btn-cancel" onClick={e => {
                                            writeDb('/organization/' + organization.id + '/refereePendingIds/' + referee.uid, null, error => {
                                                if (error) {
                                                    console.log(error);
                                                } else if (onChanged != null) {
                                                    onChanged();
                                                }
                                            })
                                        }}>{t('reject')}</Button>
                                    </div>
                                )}
                                {isInvited && (
                                    <Button className="btn-submit" onClick={e => {
                                        writeDb('/organization/' + organization.id + '/refereeInvitedIds/' + referee.uid, null, error => {
                                            if (error) {
                                                console.log(error);
                                            } else if (onChanged != null) {
                                                onChanged();
                                            }
                                        })
                                    }}>{t('cancel_invite')}</Button>
                                )}
                                {!isInvited && !isMember && !isPending && (
                                    <Button className="btn-submit" onClick={e => {
                                        writeDb('/organization/' + organization.id + '/refereeInvitedIds/' + referee.uid, referee.uid, error => {
                                            if (error) {
                                                console.log(error);
                                            } else if (onChanged != null) {
                                                onChanged();
                                            }
                                        })
                                    }}>{t('invite')}</Button>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return <div/>;
        }
    }
}

export default OrganizationRefereeRow;