import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import ProgramBadge from 'components/detail/organization/ProgramBadge';
import {ProgramLandingOverview} from 'components/detail/organization/program/ProgramLandingOverview';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import { 
    RiTeamFill,
} from 'react-icons/ri';
import { 
    Row,
    Col,
} from 'reactstrap';
import { t } from 'i18next';

import LoadingView from 'components/detail/loading/LoadingView';
import EmptyScreen from 'components/detail/loading/EmptyScreen';
import logo200Image from 'assets/img/logo/live_192.png';
import { getOrgData, getOrgDomain, replaceUrl } from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import SmallLoadingView from 'components/detail/loading/SmallLoadingView';
import { withRouter } from 'utils/Routher';

class OrgPrograms extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            id: '',
            landingPageData: {},
            organization: {},
            organizations: {},
            programChoice: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            schedules: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            posts: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            mainTab: 0,
        }
    }

    componentDidMount() {
        if (this.props.router != null && this.props.router.params != null) {
            const id = this.props.router.params.id;
            const seasonId = this.props.router.params.seasonId;
            this.setState({id: id, seasonId: seasonId});
        }
        if (window.location.search && window.location.search.includes('payment_result=true')) {
            alert("Payment sent to owner sccuessfully. You will get into season once he accepts it.");
        } else if (window.location.search && window.location.search.includes('payment_result=false')) {
            alert("Payment not sent to owner sccuessfully. You should retry later.");
        }
        this.loadContents();
    }

    loadContents() {
        let programId = '';
        if (this.props.router != null && this.props.router.params != null) {
            programId = this.props.router.params.programId;
        }
        
        const {programChoice} = this.state;
        const params = getOrgData(this.props);
        invokeHttpsApi('organizerQuery-programsQuery', params, ((data)=>{
            
            const programs = {};
            const organizations = {};
            let organization = {};
            const programPayments = {};
            const profileData = {};
            const users = {};
            const programSchedules = {};
            const locations = {};
            const category = {};
            const cards = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.programs)) {
                    programs[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.programPayments)) {
                    programPayments[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.programSchedules)) {
                    programSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }
            let choiceId = programId != null ? programId : programChoice.id;
            let update = programs[choiceId];
            if (update != null) {
                for (const [key, value] of Object.entries(update)) {
                    programChoice[key] = value;
                }
            }

            this.setState({
                programs: programs,
                organizations: organizations,
                organization: organization,
                programPayments: programPayments,
                schedules: programSchedules,
                locations: locations,
                profileData: profileData,
                programChoice: programChoice,
                users: users,
                category: category,
                cards: cards,
                loading: false,
                detailOpen: programChoice.id != null
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderDetails() {
        const {user, history} = this.props;
        const {organization, profileData, programChoice, schedules, users, locations} = this.state;

        return (
            <ProgramLandingOverview
                organization={organization}
                program={programChoice}
                schedules={schedules}
                uid={user ? user.uid : null}
                profileData={profileData}
                users={users}
                locations={locations}
                history={history}
                onProgramChoice={(update)=> {
                    this.setState({programChoice: update})
                }}
                onChanged={e=> {
                    this.loadContents();
                }} />
        );
    }

    renderTable() {
        const {user} = this.props;
        const {organization, profileData, programs, schedules} = this.state;
        let items = [];
        for (const [key, value] of Object.entries(programs)) {
            items.push(
                <Col xl={4} lg={6} xs={12} key={key} className='grid-item-wrapper'>
                    <ProgramBadge 
                        key={key}
                        program={value}
                        schedules={schedules}
                        uid={user ? user.uid : null}
                        profileData={profileData}
                        allowDetail={true}
                        blockEdit={true}
                        onClickDetails={()=> {
                            this.setState(
                            {
                                detailOpen: true,
                                programChoice: value
                            })
                            replaceUrl(`${getOrgDomain(organization)}/programs/${value.id}`);
                        }}
                        onSaveDone={()=> {
                            this.loadContents()
                        }} />
                </Col>
            );
        }
        return (
            <Row style={{marginTop: 30, marginBottom: 30}}>
                {items}
            </Row>
        );
    }

    renderContents() {
        const {programChoice, detailOpen} = this.state;

        if (programChoice.id != null && detailOpen) {
            return (
            <div>
                {this.renderDetails()}
            </div>
            )
        } else {
            return (
            <div>
                {this.renderTable()}
            </div>
            )
        }
    }

    renderOrganizationHeader() {
        const {organization} = this.state;

        let icon = organization.thumbUri != null ? organization.thumbUri : logo200Image;
        
        return (
            <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 20}}>
                <img alt='team' className='home-header-icon' src={icon} />
                <div className="wrap-content-fill-child">
                    <h1 className="home-header-title-small">
                        {organization.title}
                        <span></span>
                    </h1>
                    <h3 className="home-header-sub-title">
                        {organization.description}
                    </h3>
                    
                </div>
            </div>
        )
    }

    render() {
        const {organization, programs, programChoice, loading, detailOpen} = this.state;
        const programCount = programs ? Object.values(programs).length : 0;
        
        if (loading && !organization.id) {
            return (
                <LoadingView />
            )
        }
        if (organization.landingData && organization.landingData.hideLanding) {
            return (
                <EmptyScreen />
            )
        }
        const sambaTheme = organization.landingData && organization.landingData.theme === 'samba';

        if (loading || !organization.id) {
            return (
                <SmallLoadingView />
            )
        }
        
        return (
            <div>
                {!sambaTheme && this.renderOrganizationHeader()}
                <div style={{paddingTop: 20, backgroundColor: 'white'}}>
                    <ItemsNavigation
                        title="Programs"
                        subtitle={t('members')}
                        icon={<RiTeamFill/>}
                        detailOpen={detailOpen}
                        editOpen={false}
                        totalCount={programCount}
                        detailItem={programChoice}
                        onClickBack={()=>{
                            this.setState(
                            {
                                detailOpen: false,
                                programChoice: {}
                            })
                            replaceUrl(`${getOrgDomain(organization)}/programs`);
                        }} />
                    <div>
                    {this.renderContents()}
                    </div>
                </div>
            </div>
        )
    }

}

function mapState(state) {
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    checkLogin: userActions.checkLogin,
};

const connectedPage = withRouter(connect(mapState, actionCreators) (OrgPrograms));
export { connectedPage as OrgPrograms };
