import { t } from "i18next";
import React, { Component } from "react";
import { MdCheck } from "react-icons/md";
import mobileImage from 'assets/img/mockup-celular-sobre.png'
import playIcon from 'assets/img/icons/play_coral.png'

export class MainFeature extends Component {
    render() {
        return (
            <div className="main-feature">
                <div className="container">
                    <div className="main-feature-texts">
                        <div className="main-feature-title">
                            {t('main_feature_title')}
                        </div>
                        <div className="main-feature-text">
                            {t('main_feature_text')}
                        </div>
                        <ul>
                            <li>
                                <span className="main-feature-item-check"><MdCheck/></span>
                                {t('main_feature_text_01')}
                            </li>
                            <li>
                                <span className="main-feature-item-check"><MdCheck/></span>
                                {t('main_feature_text_02')}
                            </li>
                            <li>
                                <span className="main-feature-item-check"><MdCheck/></span>
                                {t('main_feature_text_03')}
                            </li>
                            <li>
                                <span className="main-feature-item-check"><MdCheck/></span>
                                {t('main_feature_text_04')}
                            </li>
                            <li>
                                <span className="main-feature-item-check"><MdCheck/></span>
                                {t('main_feature_text_05')}
                            </li>
                            <li>
                                <span className="main-feature-item-check"><MdCheck/></span>
                                {t('main_feature_text_06')}
                            </li>
                        </ul>
                    </div>
                    <div className="main-feature-thumbnail">
                        <img alt="mobile-phone" src={mobileImage}/>
                        <div class="main-video-link" onClick={e => {
                            if (this.props.onPlayTapped) {
                                this.props.onPlayTapped("https://www.youtube.com/embed/oI90sfKe0Zg")
                            }
                        }} data-fancybox="">
                            <img alt="play" src={playIcon} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainFeature;
