import React, { Component } from "react";

import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import {
    Button, Spinner, Row, Col, Input
} from 'reactstrap';
import { t } from 'i18next';
import Switch from '@mui/material/Switch';
import LeagueRoundOngoing from "components/detail/organization/LeagueRoundOngoing";
import GroupRoundOngoing from "components/detail/organization/GroupRoundOngoing";
import KnockoutRoundOngoing from "components/detail/organization/KnockoutRoundOngoing";
import {LeagueEdit} from "components/edit/organization/LeagueEdit";
import {GroupRoundEdit} from "components/edit/organization/GroupRoundEdit";
import {KnockoutRoundEdit} from "components/edit/organization/KnockoutRoundEdit";
import { dbTimestamp, generateId, invokeHttpsApi, saveSeason } from "utils/API";
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT, ROUND_TYPE_LEAGUE, SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT, STATUS_FINISHED } from "utils/constants";
import CustomTabView from "components/template/CustomTabView";

class SeasonOngoingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rankingData: [],
            groupRankings: {},
            editOngoing: false,
            customTab: 0,
        }
    }

    saveCustomRound() {
        const {season, onSaveDone} = this.props;
        const {customRound} = this.state;
        if (customRound.title == null || customRound.title === '') {
            alert("You must input title");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (customRound.id == null || customRound.id === '') {
                customRound.id = generateId('/season/' + season.id + '/subSeasons');
            }
            customRound.owner = season.owner;
            customRound.organizationId = season.organizationId;
            customRound.divisionId = season.divisionId;
            customRound.seasonId = season.id;
            
            customRound.timestamp = dbTimestamp();

            const data = {
                subSeason: customRound,
                season: season
            }
            invokeHttpsApi('clubQuery-saveCustomSubSeason', data, (data) => {    
                if (data && data.success) {
                    alert(t('saved_successfully'))
                } else {
                    alert(t('save_failed'));
                }
                season.subSeasons[customRound.id] = customRound;
                if (onSaveDone) {
                    onSaveDone(season);
                }
                this.setState({progress: false});
            }, (error) => {
                console.log('save error', error);
                alert(t('save_failed') + '\n' + error);
                this.setState({progress: false});
            });
        }
    }

    saveSeasonUpdate(season) {
        const {onSaveClicked} = this.props;
        this.setState({progress: true});
        saveSeason(season, () => {
            this.setState({progress: false})
            if (onSaveClicked != null) {
                onSaveClicked(season);
            }
        }, (error) => {
            this.setState({progress: false})
        })
    }

    renderLeagueSeason() {
        const {division, season, teams } = this.props;
        const {editOngoing} = this.state;

        return (
            <div>
                <div className='season-ongoing-header'>
                    <div className='season-ongoing-desc'>
                    {t('league_settings')}
                    </div>
                    <div className='wrap-content-wrap-child'>
                        <Switch
                            checked={editOngoing}
                            onChange={e=>{
                                this.setState({editOngoing: !editOngoing});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <LeagueRoundOngoing
                    season={season}
                    division={division}
                    subSeason={season.subSeasons.league}
                    teams={teams}
                    editOngoing={editOngoing}
                    onSaveClicked={(update)=> {
                        this.setState({rankingData: update})
                    }} />
            </div>
        );
    }

    renderTournamentSeason() {
        const {organization, division, locations, users, season, teams, onSaveClicked} = this.props;
        const {rankingData, groupRankings, editOngoing} = this.state;

        return (
            <div>
                <div className='season-ongoing-header'>
                    <div className='season-ongoing-desc'>
                    {t('tournament_settings')}
                    </div>
                    <div className='wrap-content-wrap-child'>
                        <Switch
                            checked={editOngoing}
                            onChange={e=>{
                                this.setState({editOngoing: !editOngoing});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <GroupRoundOngoing
                    season={season}
                    division={division}
                    subSeason={season.subSeasons.group}
                    teams={teams}
                    editOngoing={editOngoing}
                    onSaveClicked={(update)=> {
                        this.setState({groupRankings: update})
                    }} />
                <KnockoutRoundOngoing
                    organization={organization}
                    division={division}
                    locations={locations}
                    users={users}
                    season={season}
                    subSeason={season.subSeasons.knockout}
                    teams={teams}
                    editOngoing={editOngoing}
                    rankingData={rankingData}
                    groupRankings={groupRankings}
                    onSaveClicked={()=> {
                        if (onSaveClicked != null) {
                            onSaveClicked();
                        }
                    }} />
            </div>
        );
    }

    renderPlayoffSeason() {
        const {organization, division, locations, users, season, teams, onSaveClicked} = this.props;
        const {rankingData, groupRankings, editOngoing} = this.state;

        return (
            <div>
                <div className='season-ongoing-header'>
                    <div className='season-ongoing-desc'>
                    {t('playoff_settings')}
                    </div>
                    <div className='wrap-content-wrap-child'>
                        <Switch
                            checked={editOngoing}
                            onChange={e=>{
                                this.setState({editOngoing: !editOngoing});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </div>
                </div>
                <LeagueRoundOngoing
                    season={season}
                    division={division}
                    subSeason={season.subSeasons.league}
                    teams={teams}
                    editOngoing={editOngoing}
                    onSaveClicked={(update)=> {
                        this.setState({rankingData: update})
                    }} />
                <KnockoutRoundOngoing
                    organization={organization}
                    division={division}
                    locations={locations}
                    users={users}
                    season={season}
                    subSeason={season.subSeasons.knockout}
                    teams={teams}
                    editOngoing={editOngoing}
                    rankingData={rankingData}
                    groupRankings={groupRankings}
                    onSaveClicked={()=> {
                        if (onSaveClicked != null) {
                            onSaveClicked();
                        }
                    }} />
            </div>
        );
    }

    renderCustomSeason() {
        const {organization, division, locations, users, season, teams, onSaveClicked} = this.props;
        const {rankingData, groupRankings, editOngoing, customTab} = this.state;

        return (
            <div>
                <CustomTabView
                    value={customTab}
                    variant={"fullWidth"}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={(e, value) => {
                        this.setState({customTab: value})
                    }}
                    items={
                    [
                        {
                            label: t('default_rounds')
                        },
                        {
                            label: t('custom_rounds')
                        },
                    ]} />
                {customTab === 1 ? (
                    this.renderCustomRounds()
                ) : (
                <div>
                    <div className='season-ongoing-header'>
                        <div className='season-ongoing-desc'>
                        {t('custom_default_settings')}
                        </div>
                        <div className='wrap-content-wrap-child'>
                            <Switch
                                checked={editOngoing}
                                onChange={e=>{
                                    this.setState({editOngoing: !editOngoing});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </div>
                    </div>
                    {season.qualification === ROUND_TYPE_GROUP ? (
                    <GroupRoundOngoing
                        season={season}
                        division={division}
                        subSeason={season.subSeasons.group}
                        teams={teams}
                        editOngoing={editOngoing}
                        onSaveClicked={(update)=> {
                            this.setState({groupRankings: update})
                        }} />
                    ) : (
                    <LeagueRoundOngoing
                        season={season}
                        division={division}
                        subSeason={season.subSeasons.league}
                        teams={teams}
                        editOngoing={editOngoing}
                        onSaveClicked={(update)=> {
                            this.setState({rankingData: update})
                        }} />
                    )}
                    <KnockoutRoundOngoing
                        organization={organization}
                        division={division}
                        locations={locations}
                        users={users}
                        season={season}
                        subSeason={season.subSeasons.knockout}
                        teams={teams}
                        editOngoing={editOngoing}
                        rankingData={rankingData}
                        groupRankings={groupRankings}
                        onSaveClicked={()=> {
                            if (onSaveClicked != null) {
                                onSaveClicked();
                            }
                        }} />
                </div>
                )}
            </div>
        );
    }

    renderCustomRoundDeploy() {
        const {season} = this.props;
        const {customRound} = this.state;

        const type = customRound && customRound.type ? customRound.type : ROUND_TYPE_KNOCKOUT;

        if (!customRound) {
            return <div/>;
        }
        return (
            <div>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    {t('custom_round_type')}
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        {<div className='form-edit-input'>
                            <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                customRound.type = e.target.value;

                                delete customRound.startTeams;
                                delete customRound.winnerCount;
                                delete customRound.groupCount;

                                if (customRound.type === ROUND_TYPE_LEAGUE) {
                                    customRound.title = 'Extra League Round';
                                } else if (customRound.type === ROUND_TYPE_GROUP) {
                                    customRound.title = 'Extra Group Round';
                                    customRound.startTeams = season.startTeams;
                                    customRound.winnerCount = season.knockoutTeamCount;
                                    customRound.groupCount = season.groupCount;
                                } else {
                                    customRound.title = 'Extra Knockout Round';
                                    customRound.startTeams = season.knockoutTeamCount;
                                }
                                this.setState({customRound: customRound});
                            }} value={customRound.type}>
                                <option value={null}></option>
                                <option value={ROUND_TYPE_LEAGUE}>{t('league')}</option>
                                <option value={ROUND_TYPE_GROUP}>{t('group')}</option>
                                <option value={ROUND_TYPE_KNOCKOUT}>{t('knockout')}</option>
                            </Input>
                        </div>}
                    </Col>
                </Row>
                {customRound && customRound.type === ROUND_TYPE_KNOCKOUT && (
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={5} xs={12}>
                    Start Team Count
                    </Col>
                    <Col className='form-edit-entry' sm={7} xs={12}>
                        <div className='form-edit-input'>
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            customRound.startTeams = e.target.value != null ? parseInt(e.target.value) : 8;
                            this.setState({customRound: customRound});
                        }} value={customRound.startTeams}>
                            <option value={null}></option>
                            <option value={2}>2</option>
                            <option value={4}>4</option>
                            <option value={8}>8</option>
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                        </Input>
                        </div>
                    </Col>
                </Row>
                )}
                {type === ROUND_TYPE_LEAGUE && this.renderLeagueDeploy()}
                {type === ROUND_TYPE_GROUP && this.renderGroupDeploy()}
                {type === ROUND_TYPE_KNOCKOUT && this.renderKnockoutDeploy()}
                <div className='season-ongoing-header'>
                    <div className='wrap-content-fill-child'>
                    
                    </div>
                    <div className='wrap-content-wrap-child'>
                        <Button className="btn-submit" onClick={e => {
                            this.saveCustomRound();
                        }}>{t('save_custom_round')}</Button>
                        <Button className="btn-cancel" onClick={e => {
                            this.setState({customRound: null});
                        }}>{t('close')}</Button>
                    </div>
                </div>
            </div>
        )
    }

    renderLeagueDeploy() {
        const {season, teams, locations, division, organization, users } = this.props;
        let {customRound} = this.state;
        
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <LeagueEdit
                season={season}
                teams={teamsChoice}
                organization={organization}
                division={division}
                locations={locations}
                subSeason={customRound}
                users={users}
                forCustom={true}
                onValueUpdate={(update)=> {
                    this.setState({customRound: update});
                }} />
        );
    }

    renderGroupDeploy() {
        const {season, teams, locations, division, organization, users } = this.props;
        let {customRound} = this.state;
        
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <GroupRoundEdit
                season={season}
                teams={teamsChoice}
                organization={organization}
                division={division}
                locations={locations}
                subSeason={customRound}
                users={users}
                onValueUpdate={(update)=> {
                    this.setState({customRound: update});
                }} />
        );
    }

    renderKnockoutDeploy() {
        const {season, teams, locations, division, organization, users } = this.props;
        let {customRound} = this.state;
        
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }

        return (
            <div>
                <KnockoutRoundEdit
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={customRound}
                    startTeams={customRound.startTeams}
                    allowEdit={true}
                    users={users}
                    onValueUpdate={(update)=> {
                        this.setState({customRound: update});
                    }} />
            </div>
        );
    }

    renderCustomRoundHeader(round) {
        const {season, onSaveClicked} = this.props;
        
        return (
            <Row className='form-edit-row'>
                <Col className='form-edit-label' sm={2} xs={12}>
                Title
                </Col>
                <Col className='form-edit-entry' sm={7} xs={12}>
                    <div className='form-edit-input'>
                    <Input type="text" name="title" id="title" onChange={e => {
                        season.subSeasons[round.id].title = e.target.value;
                        if (onSaveClicked) {
                            onSaveClicked(season);
                        }
                    }} value={round.title} />
                    </div>
                </Col>
                <Col className='form-edit-entry' sm={3} xs={12}>
                    <div className="text-center">
                        <Button className='btn-edit' onClick={e => {
                            this.setState({customRound: round});
                        }}>{t('redeploy')}</Button>
                        <Button className='btn-edit' onClick={e => {
                            const data = {subSeason: round}
                            invokeHttpsApi('clubQuery-saveSubSeason', data, (data) => {                    
                                if (data && data.success) {
                                    alert(t('saved_successfully'))
                                } else {
                                    alert(t('save_failed'));
                                }
                                if (onSaveClicked) {
                                    onSaveClicked(season);
                                }
                                this.setState({progress: false});
                            }, (error) => {
                                console.log('save error', error);
                                alert(t('save_failed') + '\n' + error);
                                this.setState({progress: false});
                            });
                        }}>{t('save')}</Button>
                        <Button className="btn-cancel" onClick={(e) => {
                            if (!window.confirm("Are you suer you want to remove this custom round?")) {
                                return;
                            }
                            const data = {subSeason: round}
                            invokeHttpsApi('clubQuery-deleteSubSeason', data, (data) => {                    
                                if (data.success) {
                                    alert(t('saved_successfully'))
                                } else {
                                    alert(t('save_failed'));
                                }
                                if (onSaveClicked) {
                                    onSaveClicked(season);
                                }
                                this.setState({progress: false});
                            }, (error) => {
                                console.log('save error', error);
                                alert(t('save_failed') + '\n' + error);
                                this.setState({progress: false});
                            });
                        }}>{t('delete')}</Button>
                    </div>
                </Col>
            </Row>
        )
    }

    renderCustomRounds() {
        const {organization, division, season, teams, locations, users, onSaveClicked} = this.props;
        const {customRound} = this.state;

        let roundViews = [];
        if (season.subSeasons) {
            for (const round of Object.values(season.subSeasons)) {
                if (round.id === ROUND_TYPE_LEAGUE || round.id === ROUND_TYPE_GROUP || round.id === ROUND_TYPE_KNOCKOUT) {
                    continue;
                }
                roundViews.push(
                    <div>{this.renderCustomRoundHeader(round)}</div>
                )
                if (round.type === ROUND_TYPE_LEAGUE) {
                    roundViews.push(
                        <LeagueRoundOngoing
                            season={season}
                            division={division}
                            subSeason={round}
                            teams={teams}
                            editOngoing={false}
                            onSaveClicked={(update)=> {
                                this.setState({rankingData: update})
                            }} />
                    );
                } else if (round.type === ROUND_TYPE_GROUP) {
                    roundViews.push(
                        <GroupRoundOngoing
                            season={season}
                            division={division}
                            subSeason={round}
                            teams={teams}
                            editOngoing={false}
                            onSaveClicked={(update)=> {
                                this.setState({groupRankings: update})
                            }} />
                    );
                } else if (round.type === ROUND_TYPE_KNOCKOUT) {
                    roundViews.push(
                        <KnockoutRoundOngoing
                            organization={organization}
                            division={division}
                            locations={locations}
                            users={users}
                            season={season}
                            subSeason={round}
                            teams={teams}
                            editOngoing={false}
                            rankingData={false}
                            onSaveClicked={()=> {
                                if (onSaveClicked != null) {
                                    onSaveClicked();
                                }
                            }} />
                    );
                }
            }
            
        }
        return (
            <div>
                <div className='season-ongoing-header'>
                    <div className='season-ongoing-desc'>
                    {t('custom_deploy_settings')}
                    </div>
                    <div className='wrap-content-wrap-child'>
                        <Button className="btn-submit" onClick={e => {
                            const update = {
                                seasonId: season.id,
                                divisionId: season.divisionId,
                                organizationId: season.organizationId,
                                type: ROUND_TYPE_KNOCKOUT,
                                title: 'Extra Knockout Round',
                                startTeams: season.knockoutTeamCount,
                                custom: true
                            }
                            update.id = generateId('/season/' + season.id + '/subSeasons');
                            this.setState({customRound: update})
                        }}>{t('add_custom_round')}</Button>
                    </div>
                </div>
                
                {customRound ? this.renderCustomRoundDeploy() : roundViews}
            </div>
        );
    }

    render() {
        const {season, progress} = this.props;
        const {rankingData} = this.state;

        return (
            <div>
                <div className='wrap-content-parent'>
                    <h3 className='wrap-content-wrap-child vertical-center' style={{fontSize: 18}}>
                        Season Status: 
                    </h3>
                    <div className='wrap-content-fill-child'>
                        <CustomTabView
                            value={2}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            items={
                            [
                                {
                                    label: t('registration')
                                },
                                {
                                    label: t('schedule')
                                },
                                {
                                    label: t('ongoing')
                                },
                                {
                                    label: t('finihsed')
                                },
                            ]} />
                    </div>
                    <div className='wrap-content-wrap-child vertical-center'>
                        {progress ? (
                            <Spinner color="primary" />
                        ) : (
                            <Button className="btn-submit" onClick={e => {
                                let gamesReady = season.subSeasons.league != null && rankingData.length > 0;
                                if (!gamesReady) {
                                    alert('You have to decide league ranking before procceed');
                                } else if (rankingData.length === 0) {
                                    alert('You have to activate Edit Ranking and submit ranking data.');
                                } else if (window.confirm('Are you sure you want to finish this season?')) {
                                    const ranking = {};
                                    for (const val of rankingData) {
                                        ranking[val.id] = {id: val.id, points: val.points, ranking: val.ranking}
                                        if (val.ranking === 0) {
                                            season.winner = val.id;
                                            season.subSeasons.league.winner = val.id;
                                            season.winnerDate = dbTimestamp();
                                        }
                                    }
                                    
                                    season.subSeasons.league.status = STATUS_FINISHED;
                                    season.status = STATUS_FINISHED;
                                    season.ranking = ranking;
                                    this.saveSeasonUpdate(season);
                                }
                            }}>Finish Season</Button>
                        )}
                    </div>
                </div>
                {season.type === SEASON_TYPE_LEAGUE && this.renderLeagueSeason()}
                {season.type === SEASON_TYPE_TOURNAMENT && this.renderTournamentSeason()}
                {season.type === SEASON_TYPE_PLAYOFF && this.renderPlayoffSeason()}
                {season.type === SEASON_TYPE_CUSTOM && this.renderCustomSeason()}
            </div>
        )
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = connect(mapState, actionCreators)(SeasonOngoingView);
export { connectedApp as SeasonOngoingView };
