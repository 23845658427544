import React, { Component } from "react";
import ReactPlayer from 'react-player';
import {
    Card, CardBody, CardImg, Button, Form, FormGroup, Spinner, Label, Input
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';
import defaultTrial from 'assets/img/icons/trial_icon.png';
import { writeDb } from "utils/API";

export class TrialReviewView extends Component {
    constructor(props) {
        super(props);
        
        const {submission} = props;

        const update = {};
        if (submission != null) {
            for (const [key, value] of Object.entries(submission)) {
                update[key] = value;
            }
        }
        this.state = {
            videoChoice: 'Challenge',
            submission: update,
            challenge: {},
            challengeVideo: {},
            submissionChoice: {},
            progress: false
        };
    }

    componentDidMount() {
        const {submission, challenges, challengeSubmissions, challengeVideos} = this.props;
        if (submission.challengeSubmissionIds != null && Object.values(submission.challengeSubmissionIds).length > 0) {
            let submissionChoice = challengeSubmissions[Object.values(submission.challengeSubmissionIds)[0]];
            let challenge = challenges[submissionChoice.challengeId];
            let challengeVideo = challengeVideos[submissionChoice.challengeVideoId];
            challengeVideo = challengeVideo != null ? challengeVideo : {};

            if (challenge != null) {
                this.setState({
                    challenge: challenge,
                    challengeVideo: challengeVideo,
                    submissionChoice: submissionChoice,
                    progress: false
                });
            }
        }
    }

    componentDidUpdate(prevPros) {
        const {submission, challenges, challengeSubmissions, challengeVideos} = this.props;
        if (prevPros.submission !== submission) {
            if (submission.challengeSubmissionIds != null && Object.values(submission.challengeSubmissionIds).length > 0) {
                let submissionChoice = challengeSubmissions[Object.values(submission.challengeSubmissionIds)[0]];
                let challenge = challenges[submissionChoice.challengeId];
                let challengeVideo = challengeVideos[submissionChoice.challengeVideoId];
                challengeVideo = challengeVideo != null ? challengeVideo : {};
    
                if (challenge != null) {
                    this.setState({
                        challenge: challenge,
                        challengeVideo: challengeVideo,
                        submissionChoice: submissionChoice,
                        progress: false
                    });
                }
            } else {
                this.setState({
                    challenge: {},
                    challengeVideo: {},
                    submissionChoice: {},
                    progress: false
                });
            }
        }
    }
    
    onSaveClick(submission) {
        if (submission.status === 'passed' || submission.status === 'failed') {
            this.setState({progress: true});
            writeDb('/trial_submissions/' + submission.id + '/status', submission.status, error => {
                if (error) {
                    alert('Saving status failed.\n' + error);
                } else {
                    alert('Saving status success');
                }
                this.setState({progress: false});
            })
        } else {
            alert("You must input checked or failed");
        }
    }

    render() {
        const { trial, submission, challenges, challengeSubmissions, challengeVideos, onCloseClick } = this.props;
        const { challenge, challengeVideo, submissionChoice, progress } = this.state;
        
        let trialImage = trial.thumbUri != null ? trial.thumbUri : defaultTrial;
        let challengeImage = challenge.thumbUri != null ? challenge.thumbUri : defaultImage;
        let challengeCount = trial.challengeIds != null ? Object.values(trial.challengeIds).length : 0;
        let submissionCount = submission.challengeSubmissionIds != null ? Object.values(submission.challengeSubmissionIds).length : 0;
        let submissions = {};
        if (submission.challengeSubmissionIds != null) {
            for (const[key, value] of Object.entries(submission.challengeSubmissionIds)) {
                let item = challengeSubmissions[value];
                if (item != null) {
                    submissions[key] = item;
                }
            }
        }
        const {videoChoice} = this.state;
        
        if (challenge != null) {
            return (
                <Form>
                    <Card className="flex-row">
                        <CardImg
                            className="profile-thumb vertical-center-spacing"
                            src={trialImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {trial.title}
                            </h4>
                            <div>{trial.description}</div>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                                Status {submission.status}, Attempt Count: {submission.attempt_count}, Completed: {submissionCount}/{challengeCount}
                            </div>
                        </CardBody>
                    </Card>
                    <FormGroup>
                        <Label for="challengeChoice">Select Challenge</Label>
                        <Input type="select" name="select" id="challengeChoice">
                            {Object.values(submissions).map((challengeSubmission)=> (
                                <option value={challengeSubmission.id} onClick={e=>{
                                    let challengeUpdate = challenges[challengeSubmission.challengeId];
                                    let challengeVideoUpdate = challengeVideos[challengeSubmission.challengeVideoId];
                                    challengeVideoUpdate = challengeVideoUpdate != null ? challengeVideoUpdate : {};
                        
                                    if (challenge != null) {
                                        this.setState({
                                            challenge: challengeUpdate,
                                            challengeVideo: challengeVideoUpdate,
                                            submissionChoice: submission,
                                            progress: false
                                        });
                                    }
                                }}>{challengeSubmission.challengeTitle}</option>
                            )) }
                        </Input>
                    </FormGroup>
                    <Card className="flex-row">
                        <CardImg
                            className="profile-thumb vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {challenge.title}
                            </h4>
                            <div>{challenge.description}</div>
                            <div>Attempt Count: {submissionChoice.attempt_count}</div>
                        </CardBody>
                    </Card>

                    {challengeVideo.videoUri != null && challengeVideo.videoUri !== '' ? (
                        <div>
                            <h4 className='label label-default'>
                            Challenge Video
                            </h4>
                            <ReactPlayer 
                                className="default-player"
                                stopOnUnmount 
                                controls 
                                url={videoChoice === 'Challenge' ? challengeVideo.videoUri : submissionChoice.videoUri}
                                width={'80%'}
                                height={'auto'} />

                        </div>
                    ) : (
                        <div>Invalid Challenge Video</div>
                    )}
                    {submissionChoice != null && submissionChoice.videoUri !== '' ? (
                        <div>
                            <h4 className='label label-default'>
                            Submission Video
                            </h4>
                            <ReactPlayer 
                                className="default-player"
                                playing 
                                stopOnUnmount 
                                controls 
                                url={submissionChoice.videoUri}
                                width={'80%'}
                                height={'auto'} />

                        </div>
                    ) : (
                        <div>No Result Submitted for this Challenge</div>
                    )}
                    <FormGroup>
                        Status: {submission.status}
                        <Switch
                            checked={submission.status === 'passed'}
                            onChange={e=>{
                                submission.status = e.target.checked ? 'passed' : 'failed';
                                this.setState({submission: submission});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                    </FormGroup>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(submission);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(TrialReviewView);