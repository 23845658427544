import { authHeader } from '../_helpers';
import { 
    getFirestore,
    collection,
    doc,
    where,
    query,
 } from 'firebase/firestore';

export const coachService = {
    checkMembership,
    delete: _delete
};

function checkMembership(userId) {

    if (userId != null) {
        const db = getFirestore();
        const customersRef = collection(db, "customers");
        const userRef = doc(customersRef, userId);
        const subscriptionsRef = collection(userRef, "subscriptions");

        return query(subscriptionsRef, 
            where('status', 'in', ['trialing', 'active']));
    } else {
        return Promise.reject(null);
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}