import React, { Component } from "react";
import teamIcon from 'assets/img/icons/club_icon_default.png';
import { t } from 'i18next';
import { connect } from "react-redux";
import { getOrganizationPoints, sortByNumberInverse } from "utils/Utils";

class SambaRanking extends Component {
    
    renderTeamRanking(team, index) {
        const { organization } = this.props;
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : "#06620F";
        
        let teamThumb = team.iconUri != null ? team.iconUri : teamIcon;

        return (
            <div key={team.id} className="samba-ranking-badge" style={ index < 3 ? {borderLeft: "4px solid " + (index === 0 ? actionBg : "#CCC")} : {}}>
                <div className="samba-ranking-score" style={{color: actionBg}}>{index + 1}</div>
                <img alt="home-icon" className="samba-ranking-icon" src={teamThumb} />
                <div className="samba-ranking-team">{team.title}</div>
                <div className="samba-ranking-points" style={{color: actionBg}}>{team.points}</div>
            </div>
        )
    }

    render() {
        const { organization, teams, seasons, filter } = this.props;
        
        const actionBg = organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;

        let seasonChoice = seasons && seasons[filter.seasonId] ? seasons[filter.seasonId] : null;

        let actionStyle = {};
        if (actionBg && actionColor) {
            actionStyle = {
                backgroundColor: actionBg,
                color: actionColor,
            }
        } else if (actionBg) {
            actionStyle = {
                backgroundColor: actionBg,
            }
        } else if (actionColor) {
            actionStyle = {
                color: actionColor,
            }
        }

        let teamIds = seasonChoice ? seasonChoice.teamIds : organization.teamIds;

        const teamsChoice = [];
        for (const value of Object.values(teams)) {
            if (teamIds && Object.values(teamIds).includes(value.id)) {
                value.points = getOrganizationPoints(organization, seasons, filter, value.id);
                teamsChoice.push(value);
            }
        }
        sortByNumberInverse(teamsChoice, "points");

        const list = [];
        
        for (let i = 0; i < teamsChoice.length; i ++) {
            if (list.length >= 15) {
                break;
            }
            list.push(
                this.renderTeamRanking(teamsChoice[i], i)
            );
        }

        return (
            <div>
                <div className="samba-header-section-title" style={actionStyle}>
                    {t('ranking')}
                </div>
                {list}
                <div style={{height: 25}} />
            </div>
        );
    }

}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    const { filter, dict } = state.filterData;
    return { isAdmin, loggingIn, user, filter, dict };
}

const connectedNavigation = connect(mapState)(SambaRanking);
export { connectedNavigation as SambaRanking };