import React, { Component } from "react";
import HorizontalAvatarList from 'components/members/HorizontalAvatarList';
import { ManagersEditView } from 'components/members/ManagersEditView';
import { t } from 'i18next';

import { Card, CardBody, Modal, ModalBody, Button } from 'reactstrap';
import { readDb } from "utils/API";

export default class ManagersView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: {},
            editOpen: false
        }
    }

    componentDidMount() {

        readDb("/user", data => {
            const users = {};
            if (data != null) {
                for (const [key, value] of Object.entries(data)){
                    users[key] = value;
                }
            }
            this.setState({users: users})
        })
    }
    
    render() {
        const { managerIds, available, onlyAvaliable, gradeStr, onSaveClick, noMembersTxt } = this.props;
        const { users, editOpen } = this.state;
        if (available != null && Object.values(available).length === 0) {
            for (const [key, val] of Object.entries(users)) {
                if (val.parentId == null) {
                    available[key] = key;
                }
            }
        }

        const avatars = [];

        if (users != null && managerIds != null) {
            for (const [key, ] of Object.entries(managerIds)){
                const item = users[key];
                if (item != null) {
                    avatars.push(
                        {avatar: item.photoUri, name: item.username}
                    )
                }
            }
        }

        return (
            <Card>
                <CardBody style={{paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5}}>
                    {avatars.length > 0 ? (
                        <HorizontalAvatarList
                            avatars={avatars}
                            avatarProps={{ size: 50 }}
                            />
                    ) : (
                        <div>{noMembersTxt ? noMembersTxt : "No Members Present"}</div>
                    )}
                    <div style={{height: 10}}/>
                    {onSaveClick && (
                    <Button className='btn-submit' onClick={e=> {this.setState({editOpen: true})}}>{t('edit')}</Button>
                    )}
                </CardBody>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ManagersEditView
                            onlyAvaliable={onlyAvaliable}
                            available={available}
                            users={users}
                            gradeStr={gradeStr}
                            managerIds={managerIds}
                            onSaveClick={(managerIds)=> {
                                if (onSaveClick != null) {
                                    onSaveClick(managerIds);
                                }
                                this.setState({editOpen: false});
                            }}
                            onCloseClick={(e)=> {
                                this.setState({editOpen: false});
                            }} />
                    </ModalBody>
                </Modal>
            </Card>
        )
    }
}