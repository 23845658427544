import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import {
    IoLogoWechat
} from 'react-icons/io5';
import {
    MdClose
} from 'react-icons/md';

import Identicon from 'identicon.js';
import userImage from 'assets/img/icons/player_photo_default.png';
import {
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
} from "@chatscope/chat-ui-kit-react";
import { timeAgo } from 'utils/Utils';
import { readDb, writeDb, dbTimestamp, generateId } from 'utils/API';

export class ClubChatView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            list: 'chat',
            chat: {},
            messageList: [],
        };

        this.addMessage = this.addMessage.bind(this);
        this.loadContents = this.loadContents.bind(this);
        this.dbref = null;
    }

    componentDidMount() {
        this.loadContents();
    }

    componentWillUnmount() {
        if (this.dbref != null) {
            this.dbref.off();
            this.dbref = null;
        }
    }

    componentDidUpdate(prevProps) {
        const { club } = this.props;
        if (club.id !== prevProps.club.id) {
            this.dbref.off();
            this.dbref = null;
            this.loadContents();
        }
    }

    loadContents() {
        const { users, club, uid } = this.props;
        readDb('/chat/' + club.id, ((data)=>{
            const chat = {};
            const messages = [];
            const messageList = [];

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    chat[key] = value;
                }
            } else {
                const chatUsers = {};
                for (const [key, value] of Object.entries(users)) {
                    if (club.playerIds != null && Object.values(club.playerIds).includes(key)) {
                        chatUsers[key] = {
                            id: value.uid,
                            name: value.username,
                            timestamp: dbTimestamp()
                        }
                        if (value.photoUri != null) {
                            chatUsers[key].avatar = value.photoUri;
                        }
                    }
                }

                writeDb('/chat/' + club.id, {
                    id: club.id,
                    users: chatUsers,
                    teamChat: true
                });
            }

            if (chat.messages != null) {
                for (const [, value] of Object.entries(chat.messages)) {
                    messages.push(value);
                }
            }
            messages.sort((a, b) => {
                return a.createdAt - b.createdAt;
            })
            for (const value of messages) {
                messageList.push(this.getMessage(value, users[value.user_id], uid))
            }

            this.setState({
                chat: chat,
                messageList: messageList,
            });
        }));
    }

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    token() {
        return (parseInt(Math.random() * 10 % 8));
    }

    photo(size) {
        return new Identicon(String(Math.random()) + String(Math.random()), {
            margin: 0,
            size: size || 20,
        }).toString()
    }

    getMessage(message, user, uid) {
        let photoUri = user != null ? user.photoUri : message.avatar;
        let photo = photoUri != null ? user.photoUri : userImage;
        return {
            id: String(Math.random()),
            avatar: photo,
            avatarFlexible: true,
            avatarType: 'rounded',
            statusColor: 'lightgreen',
            statusColorType: parseInt(Math.random() * 100 % 2) === 1 ? 'encircle' : undefined,
            title: user != null ? user.username : message.user_name,
            message: message.text,
            date: message.createdAt,
            sentTime: timeAgo(message.createdAt),
            sender: user != null ? user.username : message.user_name,
            unread: 0,
            direction: message.user_id === uid ? "outgoing" : "incoming"
        };
    }

    addMessage(text) {
        const { user, club } = this.props;
        const { chat } = this.state;

        if (text == null || text === '') {
            return;
        }
        
        const message = {
            text: text,
            user_id: user.uid,
            user_name: user.username,
            createdAt: dbTimestamp()
        };

        message.id = generateId('/chat/' + club.id + '/messages/');
        if (user.photoUri != null) {
            message.user_avatar = user.photoUri;
        }
        writeDb('/chat/' + club.id + '/messages/' + message.id, message);
        writeDb('/chat/' + club.id + '/lastMessage/', message);
        if (chat.users == null || chat.users[user.uid] == null) {
            const chatUser = {
                id: user.uid,
                name: user.username,
                timestamp: dbTimestamp()
            }
            if (user.photoUri != null) {
                chatUser.avatar = user.photoUri;
            }
            writeDb('/chat/' + club.id + '/users/' + chatUser.id, chatUser);
        }
    }

    render() {
        const {club} = this.props;
        const { show, messageList } = this.state;
        
        var arr = [];
        for (const item of messageList) {
            arr.push(item);
        }
        arr.sort(function(a, b) {
            let t1 = a.date;            
            let t2 = b.date;

            return t1 - t2;
        })

        const actionBg = club.landingData && club.landingData.actionBg ? club.landingData.actionBg : null;
        const actionColor = club.landingData && club.landingData.actionColor ? club.landingData.actionColor : null;
        
        return (
            <div className={show ? 'chat-layout' : 'chat-layout-hidden'}>
                <div className='chat-btn' 
                style={{
                    display: show ? 'none' : 'inline-block',
                    backgroundColor: actionBg ? actionBg : '#4169E1',
                    color: actionColor ? actionColor : 'white',
                }} 
                onClick={e=>{
                    this.setState({show: !show});
                }}>
                <IoLogoWechat />
                </div>

                <div className='chat-wrapper' style={{display: !show ? 'none' : 'inline-block'}}>
                    <div className='chat-title wrap-content-parent' 
                        style={{
                            backgroundColor: actionBg ? actionBg : '#4169E1',
                            color: actionColor ? actionColor : 'white',
                        }} >
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                        {club.title} {t('chat_room')}
                        </div>
                        <div className="challenge-progress wrap-content-wrap-child" onClick={e=>{
                            this.setState({show: !show});
                        }}>
                            <MdClose />
                        </div>
                    </div>
                    <ChatContainer className='chat-messages'>

                        <MessageList>
                            {arr.map((val,idx)=>(
                            <Message
                                key={idx}
                                avatarPosition='center-left'
                                model={{
                                    message: val.message,
                                    direction: val.direction,
                                    position: "single",
                                }}>
                                <Avatar src={val.avatar} name={val.sender} />
                                <Message.Footer sender={val.sender} sentTime={val.sentTime} />
                            </Message>
                            ))}
                        </MessageList>

                        <MessageInput placeholder="Type message here" onSend={text=> {
                            this.addMessage(text)
                        }} />
                        
                    </ChatContainer>

                </div>

            </div>
        );
    }
}

export default withTranslation()(ClubChatView);