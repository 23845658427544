import React, { Component } from "react";
import {
    Button, Row, Col, Input, ListGroup, ListGroupItem
} from 'reactstrap';
import { t } from 'i18next';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GroupTeamBadge from 'components/edit/organization/GroupTeamBadge';
import {KnockoutRound} from "components/edit/organization/KnockoutRound";

import { removeArrIdx } from 'utils/Utils';

import {
    MdRemove, MdWarning
} from 'react-icons/md';

import CustomTimePicker from "components/template/CustomTimePicker";
import { ROUND_TYPE_LEAGUE } from "utils/constants";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

export class KnockoutRoundOngoing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subSeason: props.subSeason,
            startGroup: []
        }
    }

    componentDidMount() {
        const { subSeason, groupRankings, rankingData} = this.props;
        
        let startGroup = [];
        if (subSeason != null) {
            for (const [key, value] of Object.entries(subSeason)) {
                subSeason[key] = value;
            }
        }
        if (subSeason != null && groupRankings != null) {
            startGroup = this.reinitTeamArray(groupRankings, rankingData)
        }
        this.setState({subSeason: subSeason, startGroup: startGroup});
    }

    componentDidUpdate(prevProps) {
        const {subSeason, groupRankings, rankingData} = this.props;

        if (groupRankings != null && prevProps.groupRankings !== groupRankings) {
            let startGroup = [];
            if (groupRankings != null) {
                startGroup = this.reinitTeamArray(groupRankings, rankingData)
            }
            this.setState({startGroup: startGroup});
        }
        if (rankingData != null && prevProps.rankingData !== rankingData) {
            let startGroup = [];
            if (rankingData != null) {
                startGroup = this.reinitTeamArray(groupRankings, rankingData)
            }
            this.setState({startGroup: startGroup});
        }
        if ((prevProps.subSeason !== subSeason)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(subSeason)) {
                challengeVal[key] = value;
            }
            this.setState({
                subSeason: challengeVal,
            })
        }
    }
    
    reinitTeamArray(groupRankings, rankingData) {
        const {teams, season} = this.props;

        var i = 0;
        if (season.qualification === ROUND_TYPE_LEAGUE) {
            const rankingArr = rankingData != null ? rankingData : [];
    
            const teamCount = parseInt(season.knockoutTeamCount);
            
            let groupTeams = [];
            
            if (rankingArr.length > 0) {
                for (i = 0; i < teamCount / 2; i ++) {
                    groupTeams.push({
                        id: 'knockout-' + i.toString(),
                        index: i,
                        teams: []
                    });
                    try {
                        const teamId = rankingArr[i].id; 
                        groupTeams[i].teams.push(teams[teamId]);
                    } catch (err) {
                        console.log(err);
                    }
                    try {
                        const teamId = rankingArr[teamCount - 1 - i].id; 
                        groupTeams[i].teams.push(teams[teamId]);
                    } catch (err) {
                        console.log(err, i);
                    }
                }
            }
    
            return groupTeams;
        } else {
            const groupArr = groupRankings != null ? Object.values(groupRankings) : [];
    
            const groupCount = groupArr.length;
            const teamCount = parseInt(season.knockoutTeamCount);
            
            let groupTeams = [];
            let count = Math.round(teamCount / groupCount);
            
            if (count === 2) {
                for (i = 0; i < groupArr.length; i ++) {
                    groupTeams.push({
                        id: 'knockout-' + i.toString(),
                        index: i,
                        teams: []
                    });
                    try {
                        const teamId = groupArr[i][0].id; 
                        groupTeams[i].teams.push(teams[teamId]);
                    } catch (err) {
                        console.log(err);
                    }
                    try {
                        const teamId = groupArr[groupArr.length - 1 - i][1].id; 
                        groupTeams[i].teams.push(teams[teamId]);
                    } catch (err) {
                        console.log(err);
                    }
                }
            } else if (teamCount === 16 && groupCount === 5) {
                for (i = 0; i < 8; i ++) {
                    groupTeams.push({
                        id: 'knockout-' + i.toString(),
                        index: i,
                        teams: []
                    });
                }
                    
                try {
                    groupTeams[0].teams.push(teams[groupArr[0][0].id]);
                    groupTeams[0].teams.push(teams[groupArr[0][1].id]);
                } catch (err) {
                    console.log(err);
                }
                    
                try {
                    groupTeams[1].teams.push(teams[groupArr[1][0].id]);
                    groupTeams[1].teams.push(teams[groupArr[1][1].id]);
                } catch (err) {
                    console.log(err);
                }
                    
                try {
                    groupTeams[2].teams.push(teams[groupArr[2][0].id]);
                    groupTeams[3].teams.push(teams[groupArr[2][1].id]);
                    groupTeams[4].teams.push(teams[groupArr[3][0].id]);
                    groupTeams[5].teams.push(teams[groupArr[3][1].id]);
                    groupTeams[6].teams.push(teams[groupArr[4][0].id]);
                    groupTeams[7].teams.push(teams[groupArr[4][1].id]);
                } catch (err) {
                    console.log(err);
                }
            } else {
                for (i = 0; i < groupArr.length; i ++) {
                    groupTeams.push({
                        id: 'knockout-' + i.toString(),
                        index: i,
                        teams: []
                    });
                    try {
                        const teamId = groupArr[i][0].id; 
                        groupTeams[i].teams.push(teams[teamId]);
                    } catch (err) {
                        console.log(err);
                    }
                    try {
                        const teamId = groupArr[groupArr.length - 1 - i][1].id; 
                        groupTeams[i].teams.push(teams[teamId]);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
    
            return groupTeams;
        }
    }

    onGroupEdit(groupTeams, result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                groupTeams[parseInt(source.droppableId)].teams,
                source.index,
                destination.index
            );

            groupTeams[parseInt(source.droppableId)].teams = items;

        } else {
            const result = move(
                groupTeams[parseInt(source.droppableId)].teams,
                groupTeams[parseInt(destination.droppableId)].teams,
                source,
                destination
            );
            groupTeams[parseInt(source.droppableId)].teams = result[parseInt(source.droppableId)];
            groupTeams[parseInt(destination.droppableId)].teams = result[parseInt(destination.droppableId)];

        }
        
        this.setState({startGroup: groupTeams})
    }

    deployGames() {
        const {subSeason, startGroup} = this.state;
        if (subSeason.status === 'started') {
            alert('You can not change ranking of finished group round');
            return;
        }

        let ready = true;
        for (const item of startGroup) {
            if (item.teams.length <= 0 || item.teams.length > 2) {
                ready = false;
            }
        }

        if (!ready) {
            alert('You have to pick correct number of teams.');
            return;
        }
        if (subSeason.rounds != null && subSeason.rounds.round_0 != null) {
            let startRound = subSeason.rounds.round_0;
            let gameIds = startRound.gameIds != null ? Object.values(startRound.gameIds) : [];
            if (subSeason.teamIds == null) {
                subSeason.teamIds = {};
            }
            for (var i = 0; i < gameIds.length; i ++) {
                subSeason.games[gameIds[i]].homeTeam = startGroup[i].teams[0].id;
                subSeason.games[gameIds[i]].awayTeam = startGroup[i].teams[1].id;
                subSeason.teamIds[startGroup[i].teams[0].id] = startGroup[i].teams[0].id;
                subSeason.teamIds[startGroup[i].teams[1].id] = startGroup[i].teams[1].id;
            }
        }
        this.setState({subSeason: subSeason});
    }

    renderGroupingView() {
        const {startGroup} = this.state;

        return (
            <div className='text-center' style={{margin: '20px 0'}}>
                <h3 className='grouping-title'>Grouping Teams</h3>
                <DragDropContext onDragEnd={(result)=> {
                    this.onGroupEdit(startGroup, result)
                }}>
                    {startGroup.map((item, idx)=>(
                    <Droppable className='col-sm-3' droppableId={idx.toString()}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'transparent',
                                    display: 'inline-block',
                                    border: '1px solid rgb(87, 87, 87,0.15)',
                                    boxShadow: '0 0 5px rgba(0,0,0,0.15)',
                                    padding: '0px 5px',
                                    margin: '0px 10px',
                                    width: 250
                                }}
                            >
                                <h3 className='group-title'>Game {idx + 1}</h3>
                                {item.teams.map((team, index) => (
                                    <Draggable key={team.id} draggableId={team.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style=
                                                {{
                                                    userSelect: 'none',
                                                    padding: 2,
                                                    margin: '0 5px',
                                                    background: snapshot.isDragging ? 'lightgreen' : 'transparent',
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <GroupTeamBadge 
                                                    key={index}
                                                    team={team} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                    ))}
                </DragDropContext>
                <div style={{margin: '10px 5px'}}>
                    <div className='text-center row-badge-info' style={{marginBottom: 10}}>
                        You can edit Knockout Round's game matches. Be sure every team match is 1 vs 1.
                    </div>
                    <div className='text-center' style={{margin: '10px 5px'}}>
                        <Button className='btn-submit' onClick={ e => {
                            this.deployGames();
                        }}>{t('deploy')}</Button>
                    </div>
                </div>
            </div>
        )
    }

    renderGameDeploy() {
        const {locationTimeSet} = this.state;

        return (
            <ListGroup>
            {
            locationTimeSet.map((value, idx)=>(
                <ListGroupItem>
                    <Row>
                        <Col sm={5}>
                            <CustomTimePicker
                                margin="normal"
                                id="time-picker"
                                label="Time picker"
                                value={value.start != null ? new Date(value.start) : new Date()}
                                onChange={ date => {
                                    locationTimeSet[idx].start= date.valueOf();
                                    this.calcTimes(locationTimeSet);
                                    this.checklocationTimes(locationTimeSet);
                                    this.setState({locationTimeSet: locationTimeSet});
                                }} />
                        </Col>
                        <Col style={{margin: 'auto 0px'}} sm={4}>
                            <Input type="select" name="select" value={locationTimeSet[idx].week} id="typeChoice" onChange={e=> {
                                locationTimeSet[idx].week = parseInt(e.target.value);
                                this.calcTimes(locationTimeSet);
                                this.checklocationTimes(locationTimeSet);
                                this.setState({locationTimeSet: locationTimeSet});
                            }}>
                                <option value={0}>{t('sunday')}</option>
                                <option value={1}>{t('monday')}</option>
                                <option value={2}>{t('tuesday')}</option>
                                <option value={3}>{t('wednesday')}</option>
                                <option value={4}>{t('thursday')}</option>
                                <option value={5}>{t('friday')}</option>
                                <option value={6}>{t('saturday')}</option>
                            </Input>
                        </Col>
                        <Col sm={3}>
                            <div className='wrap-content-parent' style={{height: '100%'}}>
                                <div className='wrap-content-fill-child'/>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-edit" onClick={e=> {
                                        removeArrIdx(locationTimeSet, idx);
                                        this.calcTimes(locationTimeSet);
                                        this.checklocationTimes(locationTimeSet);
                                        this.setState({locationTimeSet: locationTimeSet});
                                    }}><MdRemove/></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {value.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                </ListGroupItem>
            ))}
            </ListGroup>
        );
    }

    render() {
        const {editOngoing, organization, division, locations, users, season, teams, onChange} = this.props;
        const {subSeason} = this.state;
        const teamsChoice = {};
        for (const[key,value] of Object.entries(teams)) {
            if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                teamsChoice[key] = value;
            }
        }
        
        return (
            <div style={{marginTop: 10, marginBottom: 20}} >
                {editOngoing && this.renderGroupingView()}
                <div className='divider' />
                <KnockoutRound
                    season={season}
                    teams={teamsChoice}
                    organization={organization}
                    division={division}
                    locations={locations}
                    subSeason={subSeason}
                    allowEdit={true}
                    users={users}
                    onSaveDone={(update)=> {
                        if (onChange != null) {
                            onChange();
                        }
                        season.subSeasons[update.id] = {};
                        for (const [key, value] of Object.entries(update)) {
                            season.subSeasons[update.id][key] = value;
                        }
                        this.setState({season: season, subSeasonChoice: update});
                    }} />
            </div>
        );
    }
}

export default KnockoutRoundOngoing;