import React, { Component } from "react";

import SeasonStanding from "components/detail/organization/SeasonStanding";

export class OrganizationSeasonStanding extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            editTeamData: false,
            editLocation: false,
            progress: false,
            imageProgress: 0,
            windowWidth: window.innerWidth,
        };
    }

    componentDidUpdate(prevProps) {
        const {organization} = this.props;
        if (organization !== prevProps.organization) {
            for (const [key, value] of Object.entries(organization)) {
                organization[key] = value;
            }
            this.setState({
                organization: organization,
                editTeamData: false,
                editLocation: false,
                progress: false,
                imageProgress: 0,
                windowWidth: window.innerWidth,
            });
        }
    }


    render() {
        const {organization, seasonChoice, divisions, teams, users, locations} = this.props;
        const division = divisions[seasonChoice.divisionId];
        
        return (
            <div>
                <SeasonStanding
                    hideTitle={true}
                    organization={organization}
                    season={seasonChoice}
                    teams={teams}
                    division={division}
                    users={users}
                    locations={locations} />
                <div style={{height: 10}}/>
            </div>
        );
    }
}

export default OrganizationSeasonStanding;