import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';
import { t } from 'i18next';

import { getOrgDomain } from 'utils/Utils';
import defaultImage from 'assets/img/icons/fund_image.png';

class FundraiseLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fundArr: [],
            activeIndex: 0,
            animating: false,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
    }

    componentDidMount() {
        const {fundraises} = this.props;
        const fundArr = [];
        
        if (fundraises) {
            for (const[, value] of Object.entries(fundraises)) {
                fundArr.push(value);
            }
        }

        this.setState({
            fundArr: fundArr,
        });
    }

    componentDidUpdate(prevProps) {
        const {fundraises} = this.props;
        if (fundraises !== prevProps.fundraises) {
            const fundArr = [];
            
            if (fundraises) {
                for (const[, value] of Object.entries(fundraises)) {
                    fundArr.push(value);
                }
            }
    
            this.setState({
                fundArr: fundArr,
            });
        }
    }

    next() {
        const {activeIndex, animating, fundArr} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === fundArr.length - 1 ? 0 : activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        const {activeIndex, animating, fundArr} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === 0 ? fundArr.length - 1 : activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(nextIndex) {
        const {animating} = this.state;
        if (animating) {
            return;
        }
        this.setState({activeIndex: nextIndex});
    }

    renderItem(fundraise) {
        const {organization} = this.props;
        const homeLink = getOrgDomain(organization);
        
        const actionBg = organization && organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;
        const thumb = fundraise.thumbUri ? fundraise.thumbUri : defaultImage;

        let balance = 0;
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                balance += item.price;
            }
        }
        balance /= 100;
        return (
            <div className="fund-landing container">
                <img className="fund-landing-thumb" alt="No Thumb" src={thumb} />
                <div className="fund-landing-info">
                    <h1 className="fund-landing-title">
                        {fundraise.title}
                        <span></span>
                    </h1>
                    <div className="wrap-content-parent fundraise-margin" style={{marginTop: 10}}>
                        <h1 className="wrap-content-wrap-child" style={{color: "white", fontSize: 24}}>{t('collected')}:</h1>
                        <h1 className="wrap-content-fill-child" style={{color: "#EEB825", fontSize: 24}}>${balance}</h1>
                    </div>
                    <h3 className="fund-landing-sub-title">
                        {fundraise.description}
                        <span></span>
                    </h3>
                <a
                    href={homeLink + "/fund/" + fundraise.id} 
                    className="btn btn-home-main btn-lg page-scroll"
                    style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black', marginBottom: 40} : {marginBottom: 40}}
                >
                    {t('go_to')}
                </a>
                </div>
            </div>
        )
    }

    render() {
        const {fundArr, activeIndex} = this.state;

        if (fundArr.length === 0) {
            return <div/>;
        }
        
        return (
            <div className="fund-landing-overlay">
                <h1 className="blue-section-title">{t('fundraises')}</h1>
                <div style={{position: 'relative'}}>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}>
                        <CarouselIndicators items={fundArr} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {fundArr.map((val,idx)=>(
                        <CarouselItem
                            onExiting={() => this.setState({animating: true})}
                            onExited={() => this.setState({animating: false})}
                            key={val.id} >
                            {this.renderItem(val)}
                        </CarouselItem>
                        ))}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    return { isAdmin, loggingIn, user };
}

const connectedNavigation = connect(mapState)(FundraiseLanding);
export { connectedNavigation as FundraiseLanding };

