import { coachConstants } from '../_constants';

let primarySubscription = JSON.parse(localStorage.getItem('primarySubscription'));
let organizationSubscription = JSON.parse(localStorage.getItem('organizationSubscription'));
let freeOrganization = JSON.parse(localStorage.getItem('freeOrganization'));
const initialState = {
  primarySubscription: primarySubscription ? primarySubscription : null,
  organizationSubscription: organizationSubscription ? organizationSubscription : null,
  freeOrganization: freeOrganization ? freeOrganization : null,
};

export function subscriptions(state = initialState, action) {
  switch (action.type) {
    case coachConstants.COACH_SUBSCRIPTION:
      return {
        primarySubscription: action.subscriptions.primary,
        organizationSubscription: action.subscriptions.organization,
        freeOrganization: action.subscriptions.freeOrganization,
      };
    case coachConstants.COACH_SUBSCRIPTION_FAILURE:
      return {
        primarySubscription: {}
      };
    default:
      return state
  }
}