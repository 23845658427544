import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LocationEdit } from 'components/edit/organization/LocationEdit';
import { userActions } from 'redux/_actions';
import { MdLocationCity } from 'react-icons/md';
import { 
    Input
} from 'reactstrap';
import { t } from "i18next";
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import InfoBadgeSmall from 'components/detail/header/InfoBadgeSmall';
import LocationDetail from 'components/detail/organization/LocationDetail';
import LoadingView from 'components/detail/loading/LoadingView';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';

import defaultImage from 'assets/img/icons/shield_icon.png';
import { 
    FcManager
} from 'react-icons/fc';
import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
} from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';

class LocationsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: {},
            organizations: {},
            organization: {},
            teams: {},
            users: {},
            invites: {},
            category: {},
            locationChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizationContentsQuery', {}, (data) => {
            const locations = {};
            const organizations = {};
            let organization = {};
            const teams = {};
            const users = {};
            const invites = {};
            const category = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.locationInvites)) {
                    invites[key] = value;
                }
            }

            this.setState({
                locations: locations,
                organizations: organizations,
                organization: organization,
                teams: teams,
                users: users,
                invites: invites,
                category: category,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderFilter() {
        const {searchStr} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Input type="text" name="search" id="search" placeholder='Search by Location Title ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>                        
            </div>
        )
    }

    renderContents() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {invites, users, organization, locations, locationChoice, detailOpen, editOpen, searchStr} = this.state;

        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        let curConetntCount = 0;
        for (const[, value] of Object.entries(locations)) {
            if (value.owner === user.uid) {
                curConetntCount += 1;
            }
        }

        if (Object.values(locations).length === 0 || editOpen) {
            return (
            <div>
                <ItemsNavigation
                    title={t('locations')}
                    icon={<MdLocationCity/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={locationChoice}
                    onClickBack={()=>{
                        this.setState({locationChoice: {}, editOpen: false, detailOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({locationChoice: {}, editOpen: true});
                    }} />
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <LocationEdit 
                        location={locationChoice}
                        organization={organization}
                        createMode={Object.values(locations).length === 0 || !locationChoice.id} 
                        uid={user.uid}
                        onCloseClick={()=>{
                            this.setState({editOpen: false, locationChoice: {}})
                        }}
                        onSaveDone={(update)=> {
                            if (update && update.id) {
                                locations[update.id] = update;
                            }
                            this.setState({locations: locations, editOpen: false, locationChoice: {}})
                        }} />
                </div>
            </div>
            );
        } else if (detailOpen) {
            return (
                <div>
                    <ItemsNavigation
                        title={t('locations')}
                        icon={<MdLocationCity/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={curConetntCount}
                        detailItem={locationChoice}
                        onClickBack={()=>{
                            this.setState({locationChoice: {}, detailOpen: false})
                        }}
                        onClickAdd={()=>{
                            this.setState({locationChoice: {}, editOpen: true});
                        }} />
                    <LocationDetail 
                        location={locationChoice}
                        organization={organization}
                        uid={user.uid}
                        user={user}
                        invites={invites}
                        users={users}
                        onCloseClick={()=>{
                            this.setState({detailOpen: false, locationChoice: {}})
                        }}
                        onChanged={()=> {
                            this.loadContents()
                        }}
                        onSaveDone={()=> {
                            this.loadContents()
                        }} />
                </div>
            );
        } else {
            let items = [];
            for (const [key, value] of Object.entries(locations)) {
                if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                    continue;
                }
                let challengeImage = value.thumbUri != null ? value.thumbUri : defaultImage;
                let managerCount = value.managerIds == null ? 0 : Object.values(value.managerIds).length;
                items.push(
                    <InfoBadgeSmall 
                        key={key}
                        title={value.title}
                        uid={user.uid}
                        icon={challengeImage}
                        subtitle={<div><FcManager/> {managerCount} Managers</div>}
                        allowDetail={true}
                        onClickDetails={()=> this.setState(
                            {
                                detailOpen: true,
                                locationChoice: value
                            })}
                        onClickEdit={()=> this.setState(
                            {
                                editOpen: true,
                                locationChoice: value
                            })}
                        onSaveDone={()=> {
                            this.loadContents()
                        }} />
                );
            }
            return (
            <div>
                <ItemsNavigation
                    title={t('locations')}
                    icon={<MdLocationCity/>}
                    detailOpen={detailOpen}
                    editOpen={editOpen}
                    totalCount={curConetntCount}
                    detailItem={locationChoice}
                    onClickBack={()=>{
                        this.setState({locationChoice: {}, detailOpen: false})
                    }}
                    onClickAdd={()=>{
                        this.setState({locationChoice: {}, editOpen: true});
                    }} />
                {this.renderFilter()}
                {items}
            </div>
            );
        }
    }

    render() {
        const {user} = this.props;
        const {loading} = this.state;

        if (user == null) {
            return <div/>
        }

        return (
            <div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(LocationsPage));
export { connectedApp as LocationsPage };
