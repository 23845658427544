import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';
import ClubChallengesCollection from 'components/detail/club/ClubChallengesCollection';
import ClubChallengeReviewCollection from 'components/detail/club/ClubChallengeReviewCollection';
import CustomTabView from "components/template/CustomTabView";

export class ClubBattleDetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, value) {
        this.setState({value: value});
    }

    renderContents() {
        const {user, clubBattle} = this.props;
        const {value} = this.state;

        switch (value) {
            case 0:
                return (
                    <ClubChallengesCollection
                        user={user}
                        clubBattle={clubBattle} />
                );
            default:
                return (
                    <ClubChallengeReviewCollection 
                        uid={user.uid}
                        clubBattle={clubBattle} />
                );
        }
    }

    render() {
        const {clubBattle} = this.props;
        const {value} = this.state;
        
        let challengeImage = clubBattle.iconUri != null ? clubBattle.iconUri : defaultImage;
        
        if (clubBattle != null) {
            return (
                <div>
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={challengeImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {clubBattle.title}
                            </h4>
                            <div>{t('members')}: {clubBattle.playerIds != null ? Object.values(clubBattle.playerIds).length : 0}</div>
                        </div>
                    </div>
                    
                    <CustomTabView
                        value={value}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                        onChange={this.handleChange}
                        items={[
                            {
                                label: t('challenges')
                            },
                            {
                                label: t('review')
                            },
                        ]} />

                    {this.renderContents()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubBattleDetailView);