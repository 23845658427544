import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';

import { homeSlides } from 'utils/landing';

class BlueNavHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slides: {},
            activeIndex: 0,
            animating: false,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
    }

    componentDidMount() {
        const {organization} = this.props;
        const slides = {};
        
        if (organization && organization.landingData && organization.landingData.slides) {
            for (const[key, value] of Object.entries(organization.landingData.slides)) {
                slides[key] = value;
            }
        }

        this.setState({
            slides: slides,
        });
    }

    componentDidUpdate(prevProps) {
        const {organization} = this.props;
        if (organization !== prevProps.organization) {
            const slides = {};
            
            if (organization && organization.landingData && organization.landingData.slides) {
                for (const[key, value] of Object.entries(organization.landingData.slides)) {
                    slides[key] = value;
                }
            }

            this.setState({
                slides: slides,
            });
        }
    }

    next() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === homeSlides.length - 1 ? 0 : activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === 0 ? homeSlides.length - 1 : activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(nextIndex) {
        const {animating} = this.state;
        if (animating) {
            return;
        }
        this.setState({activeIndex: nextIndex});
    }

    renderSlides() {
        const {organization, loading} = this.props;
        const {slides} = this.state;

        const actionBg = organization && organization.landingData && organization.landingData.actionBg ? organization.landingData.actionBg : null;
        const actionColor = organization.landingData && organization.landingData.actionColor ? organization.landingData.actionColor : null;
        return homeSlides.map((value) => {
            const item = slides[value.altText] != null ? slides[value.altText] : value;
            return loading ? (
                <CarouselItem
                    onExiting={() => this.setState({animating: true})}
                    onExited={() => this.setState({animating: false})}
                    key={item.src} >
                    <div className='blue-nav-placeholder' />
                    <div className="blue-cover">
                        <div className="home-wrapper">
                            <h1 className="home-nav-placeholder-title">
                                Live Teams Organizations ....
                                <span></span>
                            </h1>
                        </div>
                    </div>
                </CarouselItem>
            ) :(
            <CarouselItem
                onExiting={() => this.setState({animating: true})}
                onExited={() => this.setState({animating: false})}
                key={item.src} >
                <div className='blue-nav-placeholder' />
                <div className="blue-cover">
                    <div className="home-wrapper">
                        {item.title && (
                            <h1 className="home-header-title">
                                {item.title}
                                <span></span>
                            </h1>
                        )}
                        {item.caption && (
                            <h3 className="home-header-sub-title">
                                {item.caption}
                                <span></span>
                            </h3>
                        )}
                        {item.btnText && (
                            <a
                                href={item.btnLink} 
                                className="btn btn-home-main btn-lg page-scroll"
                                style={actionBg ? {backgroundColor: actionBg, color: actionColor ? actionColor : 'black'} : {}}
                            >
                                {item.btnText}
                            </a>
                        )}
                        {" "}
                        {item.btnTextSec && (
                            <a
                                href={item.btnLinkSec} 
                                className="btn btn-home-sec btn-lg page-scroll"
                            >
                                {item.btnTextSec}
                            </a>
                        )}
                    </div>
                </div>
            </CarouselItem>
            )
        })
    }

    render() {
        const {activeIndex} = this.state;
        return (
            <header id="overview" style={{position: 'relative'}}>
                <div style={{position: 'relative'}}>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}>
                        <CarouselIndicators items={homeSlides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {this.renderSlides()}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </div>
            </header>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    return { isAdmin, loggingIn, user };
  }
  
  const connectedNavigation = connect(mapState)(BlueNavHeader);
  export { connectedNavigation as BlueNavHeader };
  
