import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    Row,
    Col,
    Input,
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import { t } from 'i18next';
import { 
    RiTeamFill,
} from 'react-icons/ri';

import { userActions } from 'redux/_actions';
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import OrganizationTeamRow from 'components/detail/organization/OrganizationTeamRow';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import OrganizationTeamDetails from 'components/detail/organization/OrganizationTeamDetails';
import ClubEditView from 'components/edit/club/ClubEditView';
import InviteHeader from 'components/detail/invite/InviteHeader';
import LoadingView from 'components/detail/loading/LoadingView';

import { 
    LEAGUE_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    validateEmail,
    validateEmailStr 
} from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';
import userImage from 'assets/img/icons/player_photo_default.png';

class TeamsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            divisions: {},
            organization: {},
            organizations: {},
            teams: {},
            users: {},
            seasons: {},
            profileData: {},
            category: {},
            teamChoice: {},
            invites: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            mainTab: 0,
            tab: 0,
            searchStr: null
        };
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        this.loadContents(id);
    }

    loadContents(id) {
        this.setState({loading: true})
        invokeHttpsApi("organizerQuery-organizationContentsQuery", {}, (data) => {
            // Read result of the Cloud Function.
            const divisions = {};
            const organizations = {};
            let organization = {};
            const invites = {};
            const teams = {};
            const profileData = {};
            const seasons = {};
            const locations = {};
            const users = {};
            const category = {};
            const teamChoice = {};
            let detailOpen = false;

            if (data.success) {
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.invites)) {
                    invites[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }
            if (id != null && teams[id] != null) {
                for (const [key, value] of Object.entries(teams[id])) {
                    teamChoice[key] = value;
                }
                detailOpen = true;
            }

            this.setState({
                divisions: divisions,
                organizations: organizations,
                organization: organization,
                invites: invites,
                teams: teams,
                locations: locations,
                seasons: seasons,
                profileData: profileData,
                teamChoice: teamChoice,
                detailOpen: detailOpen,
                users: users,
                category: category,
                loading: false,
                inviteOpen: false
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false})
        });
    }

    handleChange(e, value) {
        this.setState({mainTab: value});
    }

    renderContents() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {organization, teams, searchStr} = this.state;
        if (user == null) {
            return (
                <div/>
            );
        }
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LEAGUE_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        let pending = [];
        let invited = [];
        let members = [];
        let owned = [];

        const free = [];
        for (const [key, val] of Object.entries(teams)) {
            if (searchStr != null && searchStr.length > 0 && !val.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            const isMember = organization.teamIds != null && Object.values(organization.teamIds).includes(key);
            const isPending = organization.pendingIds != null && Object.values(organization.pendingIds).includes(key);
            const isInvited = organization.invitedIds != null && Object.values(organization.invitedIds).includes(key);
            
            if (isMember) {
                if (val.owner === user.uid) {
                    owned.push(val);
                } else {
                    members.push(val);
                }
            }
            if (isPending) {
                pending.push(val);
            }
            if (isInvited) {
                invited.push(val);
            }
            if (!isMember && !isPending && !isInvited) {
                free.push(val);
            }
        }
        pending.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        invited.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        owned.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });
        members.sort(function(a, b) {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });

        return (
            <div>
                {this.renderFilter()}
                {this.renderItems("Pending Aproval", pending)}
                {this.renderItems("Owned Teams, Transfer Ownership", owned)}
                {this.renderItems("Member Teams", members)}
                {this.renderItems("Invited", invited)}
                {this.renderItems("Available Teams", free)}
            </div>
        );
    }

    renderFilter() {
        const {searchStr} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Input type="text" name="search" id="search" placeholder='Search by Team Title ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>                        
            </div>
        )
    }

    checkEmail(email) {
        const {organization, invites, users, teamChoice} = this.state;

        if (!validateEmail(email)) {
            return -1;
        }

        for (const val of Object.values(invites)) {
            if (val.organizationId !== organization.id) {
                continue
            }
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                if (val.status === 'invited' && val.teamId === teamChoice.id) {
                    return 0;
                } else if (val.status === 'registered' && val.teamId === teamChoice.id) {
                    return 1;
                }
            }
        }
        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return 3;
            }
        }
        return 4;
    }

    getUid(email) {
        const {users} = this.state;

        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return val.uid;
            }
        }
        return null;
    }

    renderInvite() {
        const { invites, users, teamChoice, userSearch } = this.state;

        const invited = [];
        const pending = [];
        const pendingEmails = [];
        const players = [];

        for (const val of Object.values(invites)) {
            if (!userSearch || (val.email && val.email.toLowerCase().includes(userSearch.toLowerCase()))) {
                if (val.status === 'invited' && val.teamId === teamChoice.id) {
                    invited.push(val);
                } else if (val.status === 'registered' && val.teamId === teamChoice.id) {
                    pending.push(val);
                    pendingEmails.push(val.email);
                }
            }
        }
        for (const user of Object.values(users)) {
            if (!userSearch || (user.email && user.email.toLowerCase().includes(userSearch.toLowerCase()))) {
                if (!pendingEmails.includes(user.email) && teamChoice.owner !== user.uid && user.parentId == null) {
                    players.push(user);
                }
            }
        }
        
        players.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
    
        return (
            <div style={{maxHeight: 360, overflowY: 'scroll'}}>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="search" id="search" placeholder='Search by email ...' onChange={e => {
                            this.setState({userSearch: e.target.value});
                        }} value={userSearch} />
                    </div>                        
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.sendInvite(validateEmailStr(userSearch));
                    }}>{t('invite')}</Button>
                </div>
                
                <div>
                    {invited.map((val) => (
                        <InviteHeader
                            key={val.email}
                            title="No user for this email yet"
                            defaultIcon={userImage}
                            email={val.email}
                            state="invited" />
                    ))}
                </div>
                <div>
                    {pending.map((val) => (
                        <InviteHeader
                            key={val.email}
                            title="No user for this email yet"
                            defaultIcon={userImage}
                            email={val.email}
                            state="pending" />
                    ))}
                </div>
                <div>
                    {players.map((val) => (
                        <InviteHeader
                            key={val.uid}
                            title={val.username}
                            defaultIcon={userImage}
                            thumbUri={val.photoUri}
                            email={val.email} />
                    ))}
                </div>
            </div>
        );
    }

    sendInvite(email) {
        const { teamChoice } = this.state;
        var status = this.checkEmail(email)

        if (status === -1) {
            alert("This email address is invalid.")
            return;
        }
        
        if (status === 2) {
            alert("This email is already registered.")
            return;
        }
        
        if (status === 5) {
            alert("This email is already pending.")
            return;
        }
        
        if (status === 1) {
            alert("This email is already invited for creating organization.")
            return;
        }
        
        const data = {email: email, teamId: teamChoice.id, teamTitle: teamChoice.title};
        if (status === 3) {
            data.uid = this.getUid(email);
        }

        invokeHttpsApi('invite-inviteCoach', (data) => {
            this.loadContents();
            if (status === 3) {
                alert("An invitation to resiger and coach this team has been sent. It will be sent every 2 days");
            } else {
                alert("An invitation to coach this team has been sent. It will be sent every 2 days");
            }
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    renderItems(title, teamItems) {
        const {organizations, seasons} = this.state;
        let organization = Object.values(organizations)[0];

        let items = [];
        for (const val of teamItems) {
            items.push(
                <OrganizationTeamRow
                    organization={organization}
                    team={val}
                    seasons={seasons}
                    onClickDetail={()=>{
                        this.setState({teamChoice: val, detailOpen: true})
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title'>
                    {title}: {items.length}
                </h3>
                <div className='shadow-tile'>
                    <Row>
                        <Col lg={5} xs={7} sm={5} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('teams')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('players')}
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('seasons')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_teams')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderTeamDetail() {
        const { user } = this.props;
        const {organization, divisions, seasons, teamChoice, profileData, invites, users, teams} = this.state;

        let count = 0;
        for (const val of Object.values(invites)) {
            if (val.teamId === teamChoice.id) {
                count ++;
            }
        }

        return (
            <OrganizationTeamDetails
                organization={organization}
                team={teamChoice}
                divisions={divisions}
                seasons={seasons}
                users={users}
                user={user}
                profileData={profileData}
                onUserUpdate={(userUpdate, teamUpdate)=>{
                    users[userUpdate.uid] = userUpdate;
                    teams[teamUpdate.id] = teamUpdate;
                    this.setState({users: users, teams: teams});
                }}
                onEditClick={()=>{
                    this.setState({editOpen: true, detailOpen: false})
                }}
                inviteCount={count}
                onInviteClick={()=>{
                    this.setState({inviteOpen: true})
                }}
                onClickDetail={(user)=> {
                    let profileItem = {};
                    for (const val of Object.values(profileData)) {
                        if (val.teamId === teamChoice.id && val.uid === user.uid) {
                            profileItem = val;
                        }
                    }
                    if (profileItem.id != null) {
                        this.props.router.navigate('/organizer/register/player/' + profileItem.id);
                    } else {
                        alert('We do not have ' + user.username + '`s profile registration form for ' + teamChoice.title);
                    }
                }} />
        );
    }

    render() {
        const {user} = this.props;
        const {organization, teams, loading, teamChoice, detailOpen, inviteOpen, editOpen} = this.state;
        let memberCount = organization.teamIds != null ? Object.values(organization.teamIds).length : 0;

        if (user == null) {
            return <div/>
        }
    
        return (
            <div>
                
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('teams')}
                        subtitle={t('members')}
                        icon={<RiTeamFill/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={memberCount}
                        detailItem={teamChoice}
                        onClickAdd={()=>{
                            this.setState({teamChoice: {}, detailOpen: false, editOpen: true})
                        }}
                        onClickBack={()=>{
                            this.setState({teamChoice: {}, detailOpen: false, editOpen: false})
                        }} />
                </div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {editOpen && (
                            <ClubEditView 
                                uid={user.uid}
                                squad={teamChoice.id == null ? {}: teamChoice} 
                                isOpen={editOpen}
                                organizationId={organization.id}
                                onCloseClick={() => {
                                    this.setState({teamChoice: {}, editOpen: false})
                                }}
                                onChanged={(squad)=>{
                                    if (teams[squad.id]) {
                                        for (const [key,val] of Object.entries(squad)) {
                                            teams[squad.id][key] = val
                                        }
                                    } else {
                                        teams[squad.id] = squad;
                                    }
                                    if (!organization.teamIds) {
                                        organization.teamIds = {};
                                    }
                                    organization.teamIds[squad.id] = squad.id;
                                    this.setState({teams: teams, organization: organization});
                                }} />
                        )}
                        {teamChoice.id != null && detailOpen && (
                            this.renderTeamDetail()
                        )}
                        {!editOpen && !detailOpen && (
                            this.renderContents()
                        )}
                    </div>
                )}
                <Modal isOpen={inviteOpen}>
                    <ModalBody>
                        {this.renderInvite()}
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-submit' onClick={e=>{
                            this.setState({inviteOpen: false})
                        }}>{t('close')}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(TeamsPage));
export { connectedApp as TeamsPage };
