import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { generateId, writeDb } from "utils/API";

export const uploadNewVideo = (title, type, file, sucess, stateChanged, failed) => {
    let id = generateId("/videos");
    uploadVideoToStorage(id, file, type, url => {
        let data = {
            id: id,
            title: title,
            videoUri: url,
            type: type
        }
        writeDb('/videos/' + id, data, error => {
            if (error) {
                failed(error);
            } else {
                sucess(data);
            }
        });
    }, stateChanged, failed)
}

export const updateVideo = (videoData, file, sucess, stateChanged, failed) => {
    uploadVideoToStorage(videoData.id, file, videoData.type, url => {
        videoData.videoUri = url;

        writeDb('/videos/' + videoData.id, videoData, error => {
            if (error) {
                failed(error);
            } else {
                sucess(videoData);
            }
        });
    }, stateChanged, failed)
}

export const uploadVideoToStorage = (id, file, type, sucess, stateChanged, failed) => {
    let extension = getFileExtension(file.name);
    const storage = getStorage();
    const storageRef = ref(storage, '/videos/' + type + '/' + id + '.' + extension);
    
    let uploadTask = uploadBytesResumable(storageRef, file, {});
    uploadTask.on('state_changed', 
        snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            stateChanged(snapshot.state, progress);
        }, error => {
            console.log('firebase upload filed', error);
            failed(error) ;
        }, function() {
            
            getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
                sucess(downloadURL);
            }).catch(function(error) {
                console.log('firebase retreive download url failed', error);
                failed(error) ;
            });
        });
}

export const uploadFileToStorage = (id, file, type, sucess, stateChanged, failed) => {
    const storage = getStorage();
    const storageRef = ref(storage, '/' + type + '/' + id + '.attach');
    
    let uploadTask = uploadBytesResumable(storageRef, file, {});
    uploadTask.on('state_changed', 
        snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            stateChanged(snapshot.state, progress);
        }, error => {
            console.log('firebase upload filed', error);
            failed(error) ;
        }, function() {
            
            getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
                sucess(downloadURL);
            }).catch(function(error) {
                console.log('firebase retreive download url failed', error);
                failed(error) ;
            });
        });
}

export const uploadImageFileToStorage = (id, type, file, sucess, stateChanged, failed) => {
    const storage = getStorage();
    const storageRef = ref(storage, '/image/' + type + '/' + id + '.image');
    const metadata = {
        contentType: 'image/png',
    };
    let uploadTask = uploadBytesResumable(storageRef, file, metadata);
    uploadTask.on('state_changed', 
        snapshot => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            stateChanged(snapshot.state, progress);
        }, error => {
            console.log('firebase upload filed', error);
            failed(error) ;
        }, function() {
            
            getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
                sucess(downloadURL);
            }).catch(function(error) {
                console.log('firebase retreive download url failed', error);
                failed(error) ;
            });
        });
}

export const uploadNewAttachFile = (file, type, sucess, stateChanged, failed) => {
    let id = generateId(type);
    uploadFileToStorage(id, file, type, url => {
        let data = {
            id: id,
            fileName: file.name,
            fileUri: url,
            fileSize: file.size
        }
        writeDb('/' + type + '/' + id, data, error => {
            if (error) {
                failed(error);
            } else {
                sucess(data);
            }
        });
    }, stateChanged, failed)
}

export const updateAttachFile = (attachFile, file, type, sucess, stateChanged, failed) => {
    uploadFileToStorage(attachFile.id, file, type, url => {
        let data = {
            id: attachFile.id,
            fileName: file.name,
            fileUri: url,
            fileSize: file.size
        }
        writeDb('/' + type + '/' + attachFile.id, data, error => {
            if (error) {
                failed(error);
            } else {
                sucess(data);
            }
        });
    }, stateChanged, failed)
}

export const getFileExtension = (filename) => {
    try {
        return filename.split('.').pop().toLowerCase();
    } catch (error) {
        console.log("File Extension Failed", error);
        return null;
    }
}