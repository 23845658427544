import React, { Component } from "react";
import {
    CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import ReactPlayer from 'react-player';
import * as YoutubePlayer from 'react-player/youtube';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import imagesIcon from 'assets/img/icons/images.png';
import teamIcon from 'assets/img/icons/club_icon_default.png';

import { timeAgo, renderHtmlString } from 'utils/Utils';

import {
    MdAccessTime,
    MdEdit
} from 'react-icons/md';

import {
    GiHearts,
} from 'react-icons/gi';

import {
    FaComments,
} from 'react-icons/fa';

export class PostHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false,
            saveProgress: false,
        }
    }
    
    renderImageCountOverlay() {
        const {post} = this.props;

        const imageCount = post.galleryItem ? Object.values(post.galleryItem).length : 0;

        if (imageCount > 0) {
            return (
                <h3 style={{
                    position: 'absolute', 
                    top: 5, 
                    left: 5, 
                    borderRadius: 5, 
                    backgroundColor: 'rgba(0,0,0,.5)',
                    fontSize: 12,
                    padding: '2px 4px',
                    color: 'white'}}>
                    <img alt='icon' src={imagesIcon} style={{width: 20, height: 20}} /> {imageCount}
                </h3>
            )
        } else {
            return <div/>;
        }
    }
    
    renderGameOverlay() {
        const {post} = this.props;

        if (post.gameInfo) {
            return (
                <div style={{
                    position: 'absolute', 
                    bottom: 5, 
                    right: 5,
                    width: 180,
                    height: 70, 
                    backgroundColor: 'rgba(0,0,0,.5)',
                    fontSize: 20,
                    color: 'white'}}>
                    <img 
                        alt='home' 
                        src={post.gameInfo.homeIcon ? post.gameInfo.homeIcon : teamIcon} 
                        style={{width: 50, height: 50, marginTop: 10, marginLeft: 10, borderRadius: 25}} /> 
                    <h3 style={{marginLeft: 5, marginRight: 5, marginTop: 18, width: 50, fontSize: 20, color: 'white', display: 'inline-block', textAlign: 'center'}}>
                        {post.gameInfo.homeScore ? post.gameInfo.homeScore : 0} - {post.gameInfo.awayScore ? post.gameInfo.awayScore : 0}
                    </h3>
                    <img 
                        alt='away' 
                        src={post.gameInfo.awayIcon ? post.gameInfo.awayIcon : teamIcon} 
                        style={{width: 50, height: 50, marginTop: 10, marginRight: 10, borderRadius: 25}} /> 
                </div>
            )
        } else {
            return <div/>;
        }
    }
    
    render() {
        const {users, className, user, post, onClickDetail, onClickEdit } = this.props;
        const creator = users[post.uid] != null ? users[post.uid] : {};
        
        const icon = creator.photoUri != null ? creator.photoUri : defaultImage;

        const from = timeAgo(post.timestamp);
        const uid = user != null ? user.uid : null;
        const likeIds = post.likeIds != null ? Object.values(post.likeIds) : [];
        const comments = post.comments != null ? Object.values(post.comments) : [];
        
        
        if (post != null) {
            return (
                <div className={'post-item-wrapper ' + (className ? className : '')}>
                    <img className='post-item-icon' src={icon} alt='icon' />
                    <div className='post-item-info'>
                        <div className='post-item-header'>
                            <h4 className='post-item-username'>
                            {creator.username}
                            </h4>
                            <div className='post-item-date' style={{marginEnd: 20}}>
                            <MdAccessTime/> {from}
                            </div>
                            {onClickEdit && (
                            <div className='post-item-action' onClick={e=> {
                                onClickEdit()
                            }}>
                            <MdEdit/> {t('edit')}
                            </div>
                            )}
                        </div>
                        
                        <div id='post-header' onClick={e=> {
                            if (onClickDetail != null) {
                                onClickDetail()
                            }
                        }}>
                            <h4 className='post-item-title'>
                            {post.title}
                            </h4>
                            <div className='post-item-info-html'>
                                {renderHtmlString(post.body ? post.body : "")}
                            </div>
                            {post.type === 'photo' && post.photoItem != null && post.photoItem.url != null && post.photoItem.url !== '' && (
                            <div className="post-media-wrapper">
                            <div className="post-media-child">
                                <CardImg 
                                    className='post-thumb'
                                    src={post.photoItem.url} />
                            </div>
                            {this.renderImageCountOverlay()}
                            {this.renderGameOverlay()}
                            </div>
                            )}
                            {post.type === 'video' && post.videoItem != null && post.videoItem.url != null && post.videoItem.url !== '' && (
                            <div className="post-media-wrapper">
                            <div className="post-media-child">
                                <ReactPlayer 
                                    className='post-thumb-player'
                                    width="100%"
                                    height="100%"
                                    playing 
                                    stopOnUnmount 
                                    controls 
                                    url={post.videoItem.url} />
                            </div>
                            {this.renderImageCountOverlay()}
                            {this.renderGameOverlay()}
                            </div>
                            )}
                            {post.type === 'youtube' && post.youtube != null && post.youtube !== '' && (
                            <div className="post-media-wrapper">
                            <div className="post-media-child">
                                <YoutubePlayer 
                                    className='post-thumb-player'
                                    width="100%"
                                    height="100%"
                                    stopOnUnmount 
                                    controls 
                                    url={post.youtube} />
                            </div>
                            {this.renderImageCountOverlay()}
                            {this.renderGameOverlay()}
                            </div>
                            )}
                        </div>
                        <div style={{marginTop: 10}}/>
                        <div className='post-item-footer'>
                            <div className='post-item-footer-section'>
                                <span style={likeIds.includes(uid) ? {color: 'red'} : {}}><GiHearts/></span> {likeIds.length}
                            </div>
                            <div className='post-item-footer-section'>
                                <FaComments/> {comments.length}
                            </div>
                            <div className='post-item-footer-section'>
                            </div>
                            <div className='post-item-footer-section'>
                            </div>
                            <div className='post-item-footer-section'>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(PostHeader);