import logo200Image from '../../assets/img/logo/live_192.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label, Spinner } from 'reactstrap';
import { connect } from 'react-redux';

import { userActions } from '../../redux/_actions';
import { t } from 'i18next';
import { withRouter } from 'utils/Routher';

class InviteForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            userType: 'coach',
            submitted: false,
            progress: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange(e) {
        const { name, value } = e.target;
        if (name !== 'email') {
            this.setState({ [name]: value });
        }
    }

    changeAuthState = authState => event => {
        event.preventDefault();

        this.props.onChangeAuthState(authState);
    };

    handleSubmit(e) {
        const { email, type, redirectLink } = this.props;
        e.preventDefault();

        this.setState({ submitted: true });
        const { username, password, confirmPassword } = this.state;
        if (username == null || username === '') {
            alert(t('username_empty'));
            return;
        }
        if (email == null || email === '') {
            alert(t('email_empty'));
            return;
        }
        if (password == null || password === '') {
            alert(t('password_empty'));
            return;
        }
        if (password !== confirmPassword) {
            alert(t('password_not_match'));
            return;
        }
        const user = {
            username: username,
            password: password,
            email: email,
            type: type,
            goldenCoin: 0
        }

        this.setState({ progress: true });
        this.props.register(user, this.props.router.navigate, redirectLink);

    }

    renderButtonText() {

        return t('sign_up');
    }

    render() {
        const {
            showLogo,
            usernameLabel,
            usernameInputProps,
            emailLabel,
            emailInputProps,
            passwordLabel,
            passwordInputProps,
            confirmPasswordLabel,
            confirmPasswordInputProps,
            children,
            onLogoClick,
        } = this.props;
        const { email } = this.props;

        const { username, password, confirmPassword, submitted } = this.state;
        return (
            <Form onSubmit={this.handleSubmit}>
                {showLogo && (
                    <div className="text-center pb-4">
                        <img
                            src={logo200Image}
                            className="rounded"
                            style={{ width: 60, height: 60, cursor: 'pointer' }}
                            alt="logo"
                            onClick={onLogoClick}
                        />
                    </div>
                )}
                <FormGroup>
                    <Label for={usernameLabel}>{usernameLabel}</Label>
                    <Input {...usernameInputProps} name="username_1" value={username} onChange={this.handleChange}/>
                    {submitted && !username &&
                        <div className="help-block">{t('username_empty')}</div>
                    }
                </FormGroup>
                <FormGroup>
                    <Label for={emailLabel}>{emailLabel}</Label>
                    <Input {...emailInputProps} name="email" value={email} onChange={this.handleChange}/>
                    {submitted && !email &&
                        <div className="help-block">{t('email_empty')}</div>
                    }
                </FormGroup>
                <FormGroup>
                    <Label for={passwordLabel}>{passwordLabel}</Label>
                    <Input {...passwordInputProps} name="password" value={password} onChange={this.handleChange}/>
                    {submitted && !password &&
                        <div className="help-block">{t('password_empty')}</div>
                    }
                </FormGroup>
                <FormGroup>
                    <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
                    <Input {...confirmPasswordInputProps} name="confirmPassword_1" value={confirmPassword} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" />{' '}
                        {t('agree_policy')}
                    </Label>
                </FormGroup>
                <hr />
                {this.props.loggingIn || this.props.registering ? (
                    <div>
                        <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                            <Spinner color="primary" />
                        </div>
                    </div>
                ) : (
                    <Button
                        size="lg"
                        className="bg-gradient-theme-left border-0"
                        block
                        onClick={this.handleSubmit}>
                        {this.renderButtonText()}
                    </Button>
                ) }

                {children}
            </Form>
        );
    }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

InviteForm.propTypes = {
    authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
    showLogo: PropTypes.bool,
    usernameLabel: PropTypes.string,
    usernameInputProps: PropTypes.object,
    emailLabel: PropTypes.string,
    emailInputProps: PropTypes.object,
    passwordLabel: PropTypes.string,
    passwordInputProps: PropTypes.object,
    confirmPasswordLabel: PropTypes.string,
    confirmPasswordInputProps: PropTypes.object,
    onLogoClick: PropTypes.func,
};

InviteForm.defaultProps = {
    authState: 'LOGIN',
    showLogo: true,
    usernameLabel: t('full_name'),
    usernameInputProps: {
        type: 'text',
        placeholder: t('your_name'),
    },
    emailLabel: t('email'),
    emailInputProps: {
        type: 'email',
        placeholder: 'your@email.com',
    },
    passwordLabel: t('password'),
    passwordInputProps: {
        type: 'password',
        autoComplete: 'on',
        placeholder: t('password'),
    },
    confirmPasswordLabel: t('confirm_password'),
    confirmPasswordInputProps: {
        type: 'password',
        autoComplete: 'on',
        placeholder: t('confirm_your_password'),
    },
    onLogoClick: () => {},
};

function mapState(state) {
    const { loggingIn } = state.authentication;
    const { registering } = state.registration;
    return { loggingIn, registering };
}

const actionCreators = {
    login: userActions.login,
    register: userActions.register,
    logout: userActions.logout
};

const connectedLoginPage = withRouter(connect(mapState, actionCreators)(InviteForm));
export { connectedLoginPage as InviteForm };