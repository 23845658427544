import React, { Component } from "react";
import { connect } from 'react-redux';

import mobileImage from 'assets/img/mockup-celular-topo.png'
import appstoreIcon from 'assets/img/icons/app_store_icon.png';
import playstoreIcon from 'assets/img/icons/play_store_icon.png';

class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            animating: false,
        }
    }

    render() {
        const {title} = this.props;

        return (
            <div className="main-page-header">
                <div className="main-page-header-bg"/>
                <div className="main-intro">
                    <div className="overlay">
                        <div className="container main-page-columns">
                            <div className="main-page-column-1">
                                <h1 className="landing-header-title">
                                    {title ? title : (this.props.data ? this.props.data.title : "Loading")}
                                    <span></span>
                                </h1>
                                <h3 className="landing-header-sub-title">
                                    {this.props.data ? this.props.data.subtitle : "Loading"}
                                    <span></span>
                                </h3>
                                <p className="landing-header-description">
                                    {this.props.data ? this.props.data.paragraph : "Loading"}
                                </p>
                                <div className='text-center' style={{marginBottom: 20}}>
                                    <a href='https://apps.apple.com/ca/app/live/id1559363576' style={{width: 160, marginRight: 10}}>
                                        <img alt="ios" src={appstoreIcon} style={{width: 160, height: 48}} />
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=com.scrimit.live&hl=en_CA&gl=US' style={{width: 160}}>
                                        <img alt="ios" src={playstoreIcon} style={{width: 160, height: 48}} />
                                    </a>
                                </div>
                            </div>
                            <div className="main-page-column-2">
                                <img alt="mobile-phone" src={mobileImage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user } = state.authentication;
    return { isAdmin, loggingIn, user };
  }
  
  const connectedNavigation = connect(mapState)(HomeHeader);
  export { connectedNavigation as HomeHeader };
  
