import React, { Component } from 'react';
import ClubChallengeReviewBadge from 'components/detail/club/ClubChallengeReviewBadge';
import { listenDb } from 'utils/API';

export class ClubChallengeReviewCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            users: {},
            submissions: {},
            clubChallenges: {},
            clubChallengeChoice: {},
            editOpen: false,
            detailOpen: false,
            addOpen: false,
            pickOpen: false,
        };
    }

    componentDidMount() {
        const { uid, clubId } = this.props;
        listenDb("user", data => {
            var users = {};
            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    users[key] = value;
                }
            }

            this.setState({
                users: users,
            });
        })
        listenDb("clubChallengeSubmission", data => {
            var submissions = {};
            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (clubId != null && value.clubId === clubId) {
                        submissions[key] = value;
                    } else if (clubId == null && uid != null && (value.coachId === uid || 
                        (value.managers != null && Object.values(value.managers).includes(uid)))) {
                        submissions[key] = value;
                    }
                }
            }

            this.setState({
                submissions: submissions,
            });
        })
        listenDb("clubChallenge", data => {
            var clubChallenges = {};
            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (uid != null && (value.owner === uid || 
                        (value.managers != null && Object.values(value.managers).includes(uid)))) {
                        clubChallenges[key] = value;
                    }
                }
            }

            this.setState({
                clubChallenges: clubChallenges,
            });
        })
    }

    renderContents() {
        const { uid } = this.props;
        const { users, clubChallenges, submissions } = this.state;
        const result = [];

        for (const[key, value] of Object.entries(submissions)) {
    
            const challenge = clubChallenges[value.challengeId];
            const user = users[value.uid];
            if (challenge != null && user != null) {
                result.push(
                    <ClubChallengeReviewBadge 
                        key={key}
                        user={user}
                        uid={uid}
                        clubChallenge={challenge}
                        submission={value} />
                )
            }
        }

        return result;
    }

    render() {
        return (
            <div>
                <div className='wrap-content-parent' style={{marginBottom: 20}}>
                    
                </div>
                {this.renderContents()}
            </div>
        );
    }
}

export default ClubChallengeReviewCollection;