import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    Card, CardImg, Button, Col, Row, Spinner,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import YouTube from 'react-youtube';
import { FacebookPlayer } from 'components/video/FacebookPlayer';
import { 
    HiBookmark,
} from 'react-icons/hi';

import { 
    GiSoccerField,
    GiWhistle,
} from 'react-icons/gi';
import { toTimeString, checkProfileData, renderRegisterStatus } from 'utils/Utils';
import EventBadge from 'components/detail/organization/EventBadge';
import {
    getProfileData, YEAR_TIME
} from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import verifiedIcon from 'assets/img/icons/attendance_going.png';
import deniedIcon from 'assets/img/icons/attendance_not_going.png';
import pendingIcon from 'assets/img/icons/attendance_pending.png';
import defaultEventImage from 'assets/img/icons/soccer-kick.png';
import defaultScoreImage from 'assets/img/icons/soccer-ball.png';
import defaultFoulImage from 'assets/img/icons/whistle.png';
import defaultSubImage from 'assets/img/icons/switch-player.png';
import { dataActions } from 'redux/_actions';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';

class ClubGameView extends Component {
    constructor(props) {
        super(props);
        this.timer = 0;
        const game = {};
        const division = {};

        if (props.game != null) {
            for (const [key, value] of Object.entries(props.game)) {
                game[key] = value;
            }

        }
        if (props.division != null) {
            for (const [key, value] of Object.entries(props.division)) {
                division[key] = value;
            }
        }
        if (game.roundData == null && division.category != null && division.category.roundData != null) {
            game.roundData = {}
            for (const [key, value] of Object.entries(division.category.roundData)) {
                game.roundData[key] = value;
            }
        }
        if (game.gameList == null && game.benchList == null) {
            game.gameList = {};
            game.benchList = {};
            const homeData = division.teamData != null && division.teamData[game.homeTeam] ? division.teamData[game.homeTeam] : {};
            const awayData = division.teamData != null && division.teamData[game.awayTeam] ? division.teamData[game.awayTeam] : {};
            const homeGameList = homeData.gameList != null ? homeData.gameList : {};
            const awayGameList = awayData.gameList != null ? awayData.gameList : {};
            const homeBenchList = homeData.benchList != null ? homeData.benchList : {};
            const awayBenchList = awayData.benchList != null ? awayData.benchList : {};
            game.gameList[game.homeTeam] = homeGameList;
            game.gameList[game.awayTeam] = awayGameList;
            game.benchList[game.homeTeam] = homeBenchList;
            game.benchList[game.awayTeam] = awayBenchList;
        }
        
        this.state = {
            game: game,
            division: division,
            progress: false,
            roundChoiceId: null,
            mainTab: 0,
            newImage: {},
            imageProgress: 0,
            editOpen: false,
            saveProgress: false,
            editTeamData: false,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, game} = this.props;
        
        if ((prevProps.isOpen === false && isOpen === true)) {
            const update = {};
            for (const [key, value] of Object.entries(game)) {
                update[key] = value;
            }
            this.setState({
                game: update,
                progress: false,
                newImage: {},
                editTeamData: false,
                imageProgress: 0
            })
        }

        if ((prevProps.game !== game)) {
            const update = {};
            for (const [key, value] of Object.entries(game)) {
                update[key] = value;
            }
            this.setState({
                game: update,
                progress: false,
                newImage: {},
                editTeamData: false,
                imageProgress: 0
            })
        }
    }

    onSaveClick(game) {
        const {division, team, users, profileData} = this.props;

        const gameList = game.gameList[team.id];
        const benchList = game.benchList[team.id];
        for (const [key,] of Object.entries(gameList)) {
            const user = users[key];
            if (checkProfileData(division, user, team, profileData) !== 'verified') {
                alert("You have players not verified on player list.");
                return;
            }
        }
        for (const [key,] of Object.entries(benchList)) {
            const user = users[key];
            if (checkProfileData(division, user, team, profileData) !== 'verified') {
                alert("You have players not verified on bench list.");
                return;
            }
        }
        // if (game.title == null || game.title === '') {
        //     alert("You must input title");
        // } else 
        if (window.confirm('Are you sure you want to save this game result?')) {

            const path = '/season/' + game.seasonId + '/subSeasons/' + game.subSeasonId + '/games/' + game.id;
            
            this.props.saveChange(game, path);
        }
    }

    renderTeamList(team, editalble) {
        const {organization, division, users, profileData, progress} = this.props;
        const {game} = this.state;

        const members = [];
        const bench = [];
        const ready = [];
        const players = [];
        const coaches = [];

        const playerCount = division.category != null && division.category.playerCount != null ? division.category.playerCount : 0;
        const benchCount = division.category != null && division.category.benchCount != null ? division.category.benchCount : 0;
        const teamData = division.teamData != null && division.teamData[team.id] ? division.teamData[team.id] : {};
        const gameList = game.gameList != null && game.gameList[team.id] ? game.gameList[team.id] : {};
        const benchList = game.benchList != null && game.benchList[team.id] ? game.benchList[team.id] : {};
        const staffList = teamData.staff != null ? teamData.staff : {};
        for (const [key, value] of Object.entries(users)) {
            if (team.playerIds != null && Object.values(team.playerIds).includes(key)) {
                value.registerStatus = checkProfileData(division, value, team, profileData);
                if (Object.values(gameList).includes(key)) {
                    members.push(value);
                } else if (Object.values(benchList).includes(key)) {
                    bench.push(value);
                } else if (value.registerStatus === 'verified') {
                    ready.push(value);
                } else {
                    players.push(value);
                }
            }
            if (team.managers != null && Object.values(team.managers).includes(key)) {
                value.registerStatus = checkProfileData(division, value, team, profileData);
                if (Object.values(staffList).includes(key)) {
                    coaches.push(value);
                }
            }
        }
        
        members.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        bench.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        ready.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        players.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        coaches.sort((a,b)=>{
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        let result = [];
        let items = [];
        for (const value of members) {
            items.push(
                this.renderUserBadge(value, teamData, team, editalble)
            );
            items.push(
                <div>{renderRegisterStatus(division, value, team, profileData, true)}</div>
            );
            items.push(
                <div className='divider' />
            );
        }
        result.push(
            this.renderUsersTable(t('starting_lineup') + " (" + members.length.toString() + "/" + playerCount.toString() + ")", items, '#19447F')
        )

        items = [];
        for (const value of coaches) {
            const userData = getProfileData(organization, users, value.uid, team.id, profileData);
            items.push(
                <Row>
                    <Col lg={6} xs={6} sm={6} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={userData.icon} />
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {userData.username}
                            </h4>
                        </div>
                    </Col>
                </Row>
            )
            items.push(
                <div className='divider' />
            );
        }
        result.push(
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#5ca9fb'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {t('coaches')}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        )

        items = [];
        for (const value of bench) {
            items.push(
                this.renderUserBadge(value, teamData, team, editalble)
            );
            items.push(
                <div>{renderRegisterStatus(division, value, team, profileData, true)}</div>
            );
            items.push(
                <div className='divider' />
            );
        }
        result.push(
            this.renderUsersTable(t('substitute') + " (" + bench.length.toString() + "/" + benchCount.toString() + ")", items, '#19447F')
        )
        items = [];
        for (const value of ready) {
            items.push(
                this.renderUserBadge(value, teamData, team, editalble)
            );
            items.push(
                <div>{renderRegisterStatus(division, value, team, profileData, true)}</div>
            );
            items.push(
                <div className='divider' />
            );
        }
        result.push(
            this.renderUsersTable(t('available_players'), items, '#4A4A4A')
        )
        items = [];
        for (const value of players) {
            items.push(
                this.renderUserBadge(value, teamData, team, editalble)
            );
            items.push(
                <div>{renderRegisterStatus(division, value, team, profileData, true)}</div>
            );
            items.push(
                <div className='divider' />
            );
        }
        result.push(
            this.renderUsersTable(t('not_available_players'), items, '#979797')
        )
        if (editalble) {
            result.push(
                <div className='wrap-content-parent'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                    </div>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div className="wrap-content-wrap-child vertical-center-spacing">
                            <Button className="btn-submit" onClick={e => {
                                this.onSaveClick(game);
                            }}>{t('save')}</Button>
                        </div>
                    )}
                </div>
            )
        }
        return result;
    }

    checkGamePlayerList(user, teamData) {
        const {division} = this.state;

        const playerCount = division.category != null && division.category.playerCount != null ? division.category.playerCount : 0;
        const curPlayers = teamData.gameList != null ? teamData.gameList : {};

        if (playerCount !== 0 && Object.values(curPlayers).length >= playerCount) {
            return false;
        }
        return true;
    }

    checkGameBenchList(user, teamData) {
        const {division} = this.state;

        const benchCount = division.category != null && division.category.benchCount != null ? division.category.benchCount : 0;
        const curPlayers = teamData.benchList != null ? teamData.benchList : {};

        if (benchCount !== 0 && Object.values(curPlayers).length >= benchCount) {
            return false;
        }
        return true;
    }

    renderUsersTable(title, items, bgColor) {
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: bgColor}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <Row>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                #
                            </h3>
                        </Col>
                        <Col lg={3} xs={3} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={1} xs={1} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('age')}
                            </h3>
                        </Col>
                        <Col lg={2} xs={3} sm={2} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('position')}
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                <span style={{color: '#BE4534' }}><HiBookmark/></span>
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                {t('elig')}
                            </h3>
                        </Col>
                        <Col lg={3} xs={12} sm={3} className="vertical-center">
                            <h3 className='row-table-title'>
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    renderUserBadge(user, teamData, team, editalble) {
        const {organization, division, profileData, users } = this.props;
        const {game} = this.state;
        const userData = getProfileData(organization, users, user.uid, team.id, profileData);
        const suspended = 0;
        
        const gameList = game.gameList != null && game.gameList[team.id] ? game.gameList[team.id] : {};
        const benchList = game.benchList != null && game.benchList[team.id] ? game.benchList[team.id] : {};
        const isMember = Object.values(gameList).includes(user.uid);
        const isBench = Object.values(benchList).includes(user.uid);
        const age = userData.birthday !== -1 ? parseInt((new Date().valueOf() - userData.birthday) / YEAR_TIME).toString() : "-";

        return (
            <Row>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {userData.number}
                        </div>
                    </div>
                </Col>
                <Col lg={3} xs={3} sm={3} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="wrap-content-wrap-child row-badge-icon"
                            src={userData.icon} />
                        <h4 className='wrap-content-fill-child row-badge-title'>
                        {userData.username}
                        </h4>
                    </div>
                </Col>
                <Col lg={1} xs={1} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {age}
                        </div>
                    </div>
                </Col>
                <Col lg={2} xs={3} sm={2} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {userData.position}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={2} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                        {suspended}
                        </div>
                    </div>
                </Col>
                <Col lg={1} xs={2} sm={1} className="vertical-center">
                    <div className='wrap-content-parent'>
                        <CardImg
                            className="row-badge-icon-small" style={{margin: 'auto'}}
                            src={userData.verified != null ? (userData.verified ? verifiedIcon : deniedIcon) : pendingIcon} />
                    </div>
                </Col>
                <Col lg={3} xs={12} sm={3} className="vertical-center">
                {editalble && (
                    <div className='wrap-content-parent'>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                        </div>
                        {!isMember && !isBench && (
                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                            <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                if (!this.checkGamePlayerList(user,teamData)) {
                                    alert('You can not add more players');
                                    return;
                                }
                                if (checkProfileData(division, user, team, profileData) !== 'verified') {
                                    alert(t('no_profile_prompt'));
                                    return;
                                }
                                if (game.gameList == null) {
                                    game.gameList = {};
                                }
                                if (game.gameList[team.id] == null) {
                                    game.gameList[team.id] = {};
                                }
                                game.gameList[team.id][user.uid] = user.uid;
                                this.setState({game: game});
                            }}>
                            {t('player')}
                            </Button>
                            <Button className="btn-submit" style={{fontSize: 12}} onClick={e => {
                                if (!this.checkGameBenchList(user,teamData)) {
                                    alert('You can not add more substitute');
                                    return;
                                }
                                if (checkProfileData(division, user, team, profileData) !== 'verified') {
                                    alert(t('no_profile_prompt'));
                                    return;
                                }
                                if (game.benchList == null) {
                                    game.benchList = {};
                                }
                                if (game.benchList[team.id] == null) {
                                    game.benchList[team.id] = {};
                                }
                                game.benchList[team.id][user.uid] = user.uid;
                                this.setState({game: game});
                            }}>
                            {t('substitute')}
                            </Button>
                        </div>
                        )}
                        {isMember && (
                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                            <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                delete game.gameList[team.id][user.uid];
                                this.setState({game: game});
                            }}>
                            {t('remove')}
                            </Button>
                        </div>
                        )}
                        {isBench && (
                        <div className="vertical-center-spacing wrap-content-wrap-child" style={{marginRight: 5}}>
                            <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                delete game.benchList[team.id][user.uid];
                                this.setState({game: game});
                            }}>
                            {t('remove')}
                            </Button>
                        </div>
                        )}
                    </div>
                )}
                </Col>
            </Row>
        );
    }

    renderHeader() {
        const { teams, locations, users, } = this.props;
        const { game, } = this.state;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toTimeString(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }

        return (
            <Card className="game-badge">
                <Row>
                    <Col nm={5} xs={12} sm={5} className="text-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            {homeTeam.title}
                            </div>
                            <div className="wrap-content-wrap-child">
                                <CardImg
                                    className="profile-thumb game-team-icon"
                                    src={homeThumb} />
                            </div>
                        </div>
                    </Col>
                    <Col nm={2} xs={12} sm={2} className="text-center game-badge-time">
                    <div className='game-badge-calendar' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                        <div className='game-badge-day'>
                            {day}
                        </div>
                        <div className='game-badge-month'>
                            {month}
                        </div>
                    </div>
                    <b>{timeStr}</b>
                    </Col>
                    <Col nm={5} xs={12} sm={5} className="text-center">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-wrap-child">
                                <CardImg
                                    className="profile-thumb game-team-icon"
                                    src={awayThumb} />
                            </div>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            {awayTeam.title}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col nm={6} xs={6} sm={6} className="text-center">
                        <div>
                            <div className="vertical-center-spacing">
                                <GiSoccerField/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {location.title}
                            </div>
                        </div>
                    </Col>
                    <Col nm={6} xs={6} sm={6} className="text-center">
                        <div>
                            <div className="vertical-center-spacing">
                                <GiWhistle/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {referee.uid != null ? referee.username : t('no_referee')}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    }

    renderGameHeader() {
        const { teams, locations, users, } = this.props;
        const { game, } = this.state;
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        let homeThumb = homeTeam.iconUri != null ? homeTeam.iconUri : defaultImage;
        let awayThumb = awayTeam.iconUri != null ? awayTeam.iconUri : defaultImage;
        
        const date = new Date(game.start);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        const timeStr = toTimeString(game.start);

        let location = locations[game.locationId] != null ? locations[game.locationId] : {};
        let referee = users != null ? users[game.refereeId] : {};
        if (referee == null) {
            referee = {};
        }

        return (
            <Card className="game-badge">
                <Row>
                    <Col nm={4} xs={12} sm={4} className="text-center">
                        <CardImg
                            className="game-team-icon-big"
                            src={homeThumb} />
                        <div className="game-team-title-big">
                        {homeTeam.title}
                        </div>
                    </Col>
                    <Col nm={4} xs={12} sm={4} className="text-center game-badge-time">
                        <div className='game-badge-calendar' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <div className='game-badge-day'>
                                {day}
                            </div>
                            <div className='game-badge-month'>
                                {month}
                            </div>
                        </div>
                        <b>{timeStr}</b>
                        <div className='text-center'>
                            <h4 className='game-score-item'>{game.homeScore != null ? game.homeScore : 0}</h4>
                            <div style={{display: 'inline-block', width: 40}}/>
                            <h4 className='game-score-item'>{game.awayScore != null ? game.awayScore : 0}</h4>
                        </div>
                        <div>
                            <div className="vertical-center-spacing">
                                <GiSoccerField/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {location.title}
                            </div>
                        </div>
                        <div>
                            <div className="vertical-center-spacing">
                                <GiWhistle/>
                            </div>
                            <div className=' vertical-center-spacing'>
                            {referee.uid != null ? referee.username : t('no_referee')}
                            </div>
                        </div>
                    </Col>
                    <Col nm={4} xs={12} sm={4} className="text-center">
                        <CardImg
                            className="game-team-icon-big"
                            src={awayThumb} />
                        <div className="game-team-title-big">
                        {awayTeam.title}
                        </div>
                    </Col>
                </Row>
            </Card>
        );
    }

    renderEvents(round, team) {
        return (
            <div>
                {this.renderGameEvents(round, team)}
                {this.renderGameScores(round, team)}
                {this.renderGameFouls(round, team)}
                {this.renderGameSubstituts(round, team)}
            </div>
        )
    }

    renderGameEvents(round, team) {
        const { users, organization, teams } = this.props;
        const { game } = this.state;

        if (game.events != null) {
            let items = [];

            let events = [];
            for (const val of Object.values(game.events)) {
                if (val.roundId === round.id && val.teamId === team.id) {
                    events.push(val);
                }
            }
            events.sort((a,b)=>{return a.roundTime - b.roundTime});

            
            for (const val of events) {
                items.push(
                    <EventBadge 
                        event={val} 
                        organization={organization} 
                        users={users} 
                        icon={defaultEventImage}
                        teams={teams}
                        hideTeam={team != null} />
                );
            }
            if (items.length > 0) {
                return (
                    <div style={{margin: '20px 10px'}}>
                        <h5 className='round-badge-subtitle'>{t('events')}</h5>
                        {items}
                    </div>
                )
            }
        }
        return <div/>
    }

    renderGameScores(round, team) {
        const { users, organization, teams } = this.props;
        const { game } = this.state;

        if (game.scores != null) {
            let items = [];

            let scores = [];
            for (const val of Object.values(game.scores)) {
                if (val.roundId === round.id && val.teamId === team.id) {
                    scores.push(val);
                }
            }
            scores.sort((a,b)=>{return a.roundTime - b.roundTime});

            
            for (const val of scores) {
                items.push(
                    <EventBadge 
                        event={val} 
                        organization={organization} 
                        users={users} 
                        icon={defaultScoreImage}
                        teams={teams}
                        hideTeam={team != null} />
                );
            }
            
            if (items.length > 0) {
                return (
                    <div style={{margin: '20px 10px'}}>
                        <h5 className='round-badge-subtitle'>Score</h5>
                        {items}
                    </div>
                )
            }
        }
        return <div/>
    }

    renderGameFouls(round, team) {
        const { users, organization, teams } = this.props;
        const { game } = this.state;

        if (game.fouls != null) {
            let items = [];

            let fouls = [];
            for (const val of Object.values(game.fouls)) {
                if (val.roundId === round.id && val.teamId === team.id) {
                    fouls.push(val);
                }
            }
            fouls.sort((a,b)=>{return a.roundTime - b.roundTime});

            
            for (const val of fouls) {
                items.push(
                    <EventBadge 
                        event={val} 
                        organization={organization} 
                        users={users} 
                        icon={defaultFoulImage}
                        teams={teams}
                        hideTeam={team != null} />
                );
            }
            if (items.length > 0) {
                return (
                    <div style={{margin: '20px 10px'}}>
                        <h5 className='round-badge-subtitle'>Foul</h5>
                        {items}
                    </div>
                )
            }
        }
        return <div/>
    }

    renderGameSubstituts(round, team) {
        const { users, organization, teams } = this.props;
        const { game } = this.state;

        if (game.substitutes != null) {
            let items = [];

            let substitutes = [];
            for (const val of Object.values(game.substitutes)) {
                if (val.roundId === round.id && val.teamId === team.id) {
                    substitutes.push(val);
                }
            }
            substitutes.sort((a,b)=>{return a.roundTime - b.roundTime});

            
            for (const val of substitutes) {
                items.push(
                    <EventBadge 
                        event={val} 
                        organization={organization} 
                        users={users} 
                        icon={defaultSubImage}
                        teams={teams}
                        hideTeam={team != null} />
                );
            }
            if (items.length > 0) {
                return (
                    <div style={{margin: '20px 10px'}}>
                        <h5 className='round-badge-subtitle'>{t('substitute')}</h5>
                        {items}
                    </div>
                )
            }
        }
        return <div/>
    }

    renderRound(round) {
        const { teams } = this.props;
        const { game } = this.state;
        
        let homeTeam = teams[game.homeTeam]        
        let awayTeam = teams[game.awayTeam]     
        if (homeTeam == null) {
            homeTeam = {}
        }
        if (awayTeam == null) {
            awayTeam = {}
        }
        
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                            {round.title}
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {round.status === "Finished" || round.status === "Started" ? (
                    <Row>
                        <Col sm={6}>
                            {this.renderEvents(round, homeTeam)}
                        </Col>
                        <Col sm={6}>
                            {this.renderEvents(round, awayTeam)}
                        </Col>
                    </Row>
                    ) : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>                        
                        {t('no_data')}
                        </div>
                    )}
                </div>
            </div>
        )
    }
    
    render() {
        const { team, teams } = this.props;
        const { game, mainTab } = this.state;
        const rounds = game.roundData != null ? Object.values(game.roundData) : [];
        rounds.sort((a, b) => {return a.number - b.number});

        const homeTeam = teams[game.homeTeam];
        const awayTeam = teams[game.awayTeam];
        const view = document.getElementById('youtube-view')
        const width = view ? view.clientWidth : -1;
        
        if (game != null) {
            return (
                <div>
                    {/* {this.renderHeader()} */}
                    {this.renderGameHeader()}
                    
                    {game.youtubeId && (
                        <div id='youtube-view'>
                            <YouTube  
                                className='post-video-player'
                                playing 
                                stopOnUnmount 
                                controls 
                                videoId={game.youtubeId} />

                        </div>
                    )}
                    {game.facebook && (
                        <div id='youtube-view'>
                            <FacebookPlayer  
                                url={game.facebook}
                                width={width === -1 ? 350 : width - 20}
                                autoplay={true}
                                onPlayerReady={(player)=> {
                                    this.setState({player: player})
                                }}
                                startedPlaying={(player)=> {
                                    
                                }}
                                finishedPlaying={(player)=> {
                                    
                                }}
                                paused={(player)=> {
                                    
                                }}
                                error={(player)=> {
                                    
                                }} />

                        </div>
                    )}
                    <div>
                        <Box sx={mainTabStyle}>
                            <Tabs
                                value={mainTab}
                                onChange={(e, val)=> {
                                    this.setState({mainTab: val})
                                }}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                textColor="secondary" >

                                <Tab label={t('lineups')} />
                                <Tab label={t('summary')} />
                            </Tabs>
                        </Box>
                        {mainTab === 0 && (
                        <Row>
                            <Col sm={6}>
                            {this.renderTeamList(homeTeam, homeTeam.id === team.id)}
                            </Col>
                            <Col sm={6}>
                            {this.renderTeamList(awayTeam, awayTeam.id === team.id)}
                            </Col>
                        </Row>
                        )}
                        {mainTab === 1 && (
                        <div>
                            {rounds.map((round,idx)=>(
                                this.renderRound(round)
                            ))}
                        </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(ClubGameView));
export { connectedApp as ClubGameView };
