import { cartConstants } from '../_constants';
import { cartService } from '../_services';
import { alertActions } from './';

export const cartActions = {
    checkoutCartItems,
    addToCart,
    removeFromCart,
};

function checkoutCartItems(organizationId, items, note, price, url, sucess, failure) {
    return dispatch => {
        dispatch(request());

        cartService.checkoutCartItems(organizationId, items, note, price, url, sucess, failure)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failed(null));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: cartConstants.CHECKOUT_SESSION_REQUEST} }
    function success(data) { return { type: cartConstants.CHECKOUT_SESSION_SUCCESS, data } }
    function failed() { return { type: cartConstants.CHECKOUT_SESSION_FAILURE } }
}

function addToCart(item) {
    return dispatch => {
        cartService.addToCart(item)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                }
            );
    };

    function success(data) { return { type: cartConstants.CART_ADD, data } }
}

function removeFromCart(item) {
    return dispatch => {
        cartService.addToCart(item)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                }
            );
    };

    function success(data) { return { type: cartConstants.CART_REMOVE, data } }
}
