import React, { Component } from "react";
import {
    Row,
    Col,
    Button,
} from 'reactstrap';
import { t } from 'i18next';

import { 
    toDateTimeString
} from 'utils/Utils';
import { 
    FcDonate,
    FcCircuit,
} from 'react-icons/fc';
import TableRowSmall from 'components/detail/header/TableRowSmall';

import fundraiseIcon from 'assets/img/icons/ic_wallet.png';
import bingoIcon from 'assets/img/icons/ic_bingo.png';
import productIcon from 'assets/img/icons/ic_bingo.png';
import BingoEdit from "components/fundraise/BingoEdit";
import ProductEdit from "components/fundraise/ProductEdit";
import { invokeHttpsApi } from "utils/API";

export default class FundraiserDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            users: {},
            balanceInput: 0,
        }
    }

    renderDonateItem(item) {
        const {users} = this.props;

        const price = parseFloat(item.price) / 100;

        let msg = "";
        if (item.uid && users[item.uid]) {
            msg = users[item.uid].username;
        }
        let timestamp = item.timestamp;
        if (!timestamp) {
            timestamp = 0;
        }

        const subView = (
            <div className='wrap-content-parent'>
                <b style={{color: '#38be55', marginRight: 15}}>${price}</b> {toDateTimeString(timestamp)}
            </div>
        )
        
        return (
            <TableRowSmall 
                key={item.id}
                title={msg}
                subView={subView}
                icon={bingoIcon} />
        );
    }

    renderDonates() {
        const {fundraise} = this.props;
        
        let paymentsArr = [];
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (item.fundraiseId === fundraise.id) {
                    paymentsArr.push(item);
                }
            }
        }

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        const income = [];
        
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            income.push(
                <div>{this.renderDonateItem(item)}</div>
            );
        }

        const items = [];
        for (const item of income) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div className='wallet-info-tile'>
                <Row style={{margin: 0, height: 40}}>
                    <Col sm={3} className="vertical-center" style={{padding: 5}}>
                        <h4 className='fundraise-table-title'>
                        {t('donations')}
                        </h4>
                    </Col>
                    <Col sm={9} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <h4 className='fundraise-table-title'>
                            {t('price')}
                            </h4>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                )}
            </div>
        )
    }

    renderPendingItem(item) {
        const {users} = this.props;
        const {checkoutProgress} = this.state;

        const price = parseFloat(item.price) / 100;

        let msg = "";
        if (item.uid && users[item.uid]) {
            msg = users[item.uid].username;
        }
        let timestamp = item.timestamp;
        if (!timestamp) {
            timestamp = 0;
        }

        const subView = (
            <div className='wrap-content-parent'>
                <b style={{color: '#38be55', marginRight: 15}}>${price}</b> {toDateTimeString(timestamp)}
            </div>
        )
        
        return (
            <TableRowSmall 
                key={item.id}
                title={msg}
                subView={subView}
                icon={bingoIcon}
                progress={checkoutProgress}
                detailLabel={t('accept')}
                onClickDetails={e=> {
                    this.accpetPayment(item.id, item.fundraiseId, true)
                }}
                editLabel={t('reject')}
                onClickEdit={e=> {
                    this.accpetPayment(item.id, item.fundraiseId, false)
                }} />
        );
    }

    accpetPayment(pendingId, fundraiseId, accept) {
        const {fundraise} = this.props;

        const data = {
            pendingId: pendingId,
            fundraiseId: fundraiseId,
            accept: accept,
        };
        
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-acceptFundraisePayment', data, async (data) => {
            if (data.success) {
                if (accept) {
                    if (!fundraise.donate) {
                        fundraise.donate = {};
                    }
                    fundraise.donate[pendingId] = {};
                    for (const [k, v] of Object.entries(fundraise.pending[pendingId])) {
                        fundraise.donate[pendingId][k] = v;
                    }
                }
                delete fundraise.pending[pendingId];

                if (this.props.onChanged) {
                    this.props.onChanged(fundraise);
                }
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, error => {
            console.log('function error', error);
            this.setState({progress: false, checkoutProgress: false});
        })
    }

    renderPendings() {
        const {fundraise} = this.props;
        
        let paymentsArr = [];
        if (fundraise.pending) {
            for (const item of Object.values(fundraise.pending)) {
                if (item.fundraiseId === fundraise.id) {
                    paymentsArr.push(item);
                }
            }
        }

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        const income = [];
        
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            income.push(
                <div>{this.renderPendingItem(item)}</div>
            );
        }

        const items = [];
        for (const item of income) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        return (
            <div className='wallet-info-tile'>
                <Row style={{margin: 0, height: 40}}>
                    <Col sm={3} className="vertical-center" style={{padding: 5}}>
                        <h4 className='fundraise-table-title'>
                        {t('fund_waiting_to_accept')}
                        </h4>
                    </Col>
                    <Col sm={9} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <h4 className='fundraise-table-title'>
                            {t('price')}
                            </h4>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                )}
            </div>
        )
    }

    renderBingoItem(item) {
        
        const subView = (
            <div className='wrap-content-parent'>
                <h4 style={{marginBottom: 0, marginRight: 15, fontSize: 15}}>
                <FcDonate /> {t('price')} ${item.price}
                </h4>
                <div style={{marginBottom: 0, fontSize: 15}}>
                <FcCircuit /> {t('bingo')}: {item.num1}/{item.num2}/{item.num3}/{item.num4}
                </div>
            </div>
        )
        
        return (
            <TableRowSmall 
                key={item.id}
                title={item.title}
                subView={subView}
                icon={bingoIcon}
                onClickEdit={() => {
                    this.setState({bingoChoice: item})
                }} />
        );
    }

    renderBingo() {
        const {fundraise, users, onChanged} = this.props;
        const {bingoChoice} = this.state;

        if (bingoChoice) {
            return (
                <div className='wallet-info-tile'>
                    <h4 className='fundraise-table-title text-center' style={{marginTop: 10}}>
                    {t('bingo_details')}
                    </h4>
                    
                    <BingoEdit
                        bingo={bingoChoice}
                        fundraise={fundraise}
                        users={users}
                        onChanged={(update)=> {
                            if (!fundraise.bingo) {
                                fundraise.bingo = {};
                            }
                            fundraise.bingo[update.id] = update;
                            if (onChanged) {
                                onChanged(fundraise);
                            }
                        }}
                        onCloseClick={()=> {
                            this.setState({bingoChoice: null});
                        }} />
                </div>
            )
        }
        const bingoArr = [];
        if (fundraise.bingo)
        for (const item of Object.values(fundraise.bingo)) {
            bingoArr.push(
                <div>{this.renderBingoItem(item)}</div>
            );
            bingoArr.push(<div className='divider' />);
        }
        
        return (
            <div className='wallet-info-tile'>
                <Row style={{margin: 0, height: 40}}>
                    <Col sm={3} className="vertical-center" style={{padding: 5}}>
                        <h4 className='fundraise-table-title'>
                        Bingo
                        </h4>
                    </Col>
                    <Col sm={9} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <h4 className='fundraise-table-title'>
                            Price/Number
                            </h4>
                            <div className="wrap-content-fill-child" style={{marginRight: 5}} />
                            <Button className="btn-cancel" onClick={e=> {
                                this.setState({bingoChoice: {}})
                            }}>{t('create')}</Button>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {bingoArr.length > 0 ? bingoArr : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Data
                    </div>
                )}
            </div>
        )
    }

    renderProductItem(item) {
        
        const subView = (
            <div className='wrap-content-parent'>
                <h4 style={{marginBottom: 0, marginRight: 15, fontSize: 15}}>
                <FcDonate /> {t('price')} ${item.price}
                </h4>
                <div style={{marginBottom: 0, fontSize: 15}}>
                <FcCircuit /> {t('product')}: {item.num1}/{item.num2}/{item.num3}/{item.num4}
                </div>
            </div>
        )
        
        return (
            <TableRowSmall 
                key={item.id}
                title={item.title}
                subView={subView}
                icon={productIcon}
                onClickEdit={() => {
                    this.setState({productChoice: item})
                }} />
        );
    }

    renderProduct() {
        const {fundraise, users, onChanged} = this.props;
        const {productChoice} = this.state;

        if (productChoice) {
            return (
                <div className='wallet-info-tile'>
                    <h4 className='fundraise-table-title text-center' style={{marginTop: 10}}>
                    {t('product_details')}
                    </h4>
                    
                    <ProductEdit
                        product={productChoice}
                        fundraise={fundraise}
                        users={users}
                        onChanged={(update)=> {
                            if (!fundraise.product) {
                                fundraise.product = {};
                            }
                            fundraise.product[update.id] = update;
                            if (onChanged) {
                                onChanged(fundraise);
                            }
                        }}
                        onCloseClick={()=> {
                            this.setState({productChoice: null});
                        }} />
                </div>
            )
        }
        const productArr = [];
        if (fundraise.product)
        for (const item of Object.values(fundraise.product)) {
            productArr.push(
                <div>{this.renderProductItem(item)}</div>
            );
            productArr.push(<div className='divider' />);
        }
        
        return (
            <div className='wallet-info-tile'>
                <Row style={{margin: 0, height: 40}}>
                    <Col sm={3} className="vertical-center" style={{padding: 5}}>
                        <h4 className='fundraise-table-title'>
                        Product
                        </h4>
                    </Col>
                    <Col sm={9} className="vertical-center" style={{padding: 5}}>
                        <div className='wrap-content-parent'>
                            <h4 className='fundraise-table-title'>
                            Price/Number
                            </h4>
                            <div className="wrap-content-fill-child" style={{marginRight: 5}} />
                            <Button className="btn-cancel" onClick={e=> {
                                this.setState({productChoice: {}})
                            }}>{t('create')}</Button>
                        </div>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {productArr.length > 0 ? productArr : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    No Data
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {fundraise, user} = this.props;
        const {bingoChoice, productChoice} = this.state;
        
        if (user != null) {
            let balance = 0;
            if (fundraise.donate) {
                for (const item of Object.values(fundraise.donate)) {
                    balance += item.price;
                }
            }
            balance /= 100;
            return (
                <div className='shadow-tile-table' style={{padding: '10px 5px'}}>
                    <div className="wallet-title-bar">
                        <img className="wallet-title-icon" src={fundraiseIcon} alt="icon" />
                        <h3 className="wallet-title">{fundraise.title}</h3>
                    </div>
                    <div className="wrap-content-parent fundraise-margin" style={{marginTop: 10}}>
                        <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24}}>{t('collected')}:</h1>
                        <h1 className="wrap-content-fill-child" style={{color: "#EEB825", fontSize: 24}}>${balance}</h1>
                    </div>
                    
                    <div className="wallet-transactions-list">
                        {this.renderBingo()}
                    </div>
                    
                    <div className="wallet-transactions-list">
                        {this.renderProduct()}
                    </div>
                    {!bingoChoice && !productChoice && (
                    <div className="wallet-transactions-list">
                        {this.renderDonates()}
                    </div>
                    )}
                    {!bingoChoice && !productChoice && (
                    <div className="wallet-transactions-list">
                        {this.renderPendings()}
                    </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}