import React, { Component } from "react";
import {
    CardImg, Button, Form, Row, Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';
import { 
    GrOverview,
    GrMapLocation
} from 'react-icons/gr';
import { 
    FcRules,
    FcTimeline,
    FcTodoList,
} from 'react-icons/fc';
import { 
    RiTeamFill,
} from 'react-icons/ri';
import { 
    GiMale,
    GiFemale,
    GiTrophyCup
} from 'react-icons/gi';
import { 
    MdPolicy
} from 'react-icons/md';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import DivisionTeams from 'components/detail/organization/DivisionTeams';
import DivisionTrophy from 'components/detail/organization/DivisionTrophy';
import DivisionCong from 'components/edit/organization/DivisionCong';
import DivisionLocations from 'components/detail/organization/DivisionLocations';
import {DivisionRules} from 'components/edit/organization/DivisionRules';
import {DivisionPolicy} from 'components/edit/organization/DivisionPolicy';
import {SeasonDetail} from 'components/detail/organization/SeasonDetail';
import SeasonBadge from 'components/detail/organization/SeasonBadge';
import SeasonEdit from 'components/edit/organization/SeasonEdit';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { smallTabBoxStyle, smallTabItemStyle, smallTabStyle } from 'style/styles';
import { getDivisionDomain, replaceUrl } from "utils/Utils";
import { saveDivision } from "utils/API";
import { STATUS_REGISTER, STATUS_SCHEDULE, devThreshold } from "utils/constants";

export class DivisionDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainTab: 0,
            configMode: true,
            editSeason: false,
            dropdown: false,
            seasonChoice: {},
            seasonToEdit: {},
            windowWidth: window.innerWidth,
        };
        this.handleChange = this.handleChange.bind(this);
        window.addEventListener('resize', this.getDimensions); 
    }

    componentDidMount() {
        const {division, seasons, seasonId} = this.props;

        if (seasonId && seasons[seasonId]) {
            this.setState({
                seasonChoice: seasons[seasonId],
                configMode: false
            })
        } else if (division != null && seasons != null) {
            for (const val of Object.values(seasons)) {
                if (val.divisionId === division.id) {
                    this.setState({
                        seasonChoice: val
                    })
                    break;
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {seasons} = this.props;
        const {seasonChoice} = this.state;

        if (seasonChoice.id != null) {
            const update = seasons[seasonChoice.id];
            if (seasonChoice !== update) {
                this.setState({seasonChoice: update});
            }
        }
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleChange(e, value) {
        this.setState({mainTab: value});
    }

    renderContents() {
        const {organization, division, teams, seasons, cards, trophies, user, locations, profileData, users, wallets, onChanged, onSaveDone, onUserUpdate} = this.props;
        const {windowWidth, mainTab, configMode, seasonChoice} = this.state;

        if (configMode) {
            return (
                <div>
                    <Box sx={smallTabBoxStyle}>
                        <Tabs
                            value={mainTab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant={windowWidth > 460 ? "fullWidth" : "scrollable"}
                            sx={smallTabStyle}
                            onChange={this.handleChange} >
                            <Tab icon={<GrOverview />} label={t('overview')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<GiTrophyCup />} label="Trophy" sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<FcRules />} label="Rules" sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<FcTimeline />} label={t('seasons')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<RiTeamFill />} label="Member Teams" sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<GrMapLocation />} label={t('locations')} sx={smallTabItemStyle} iconPosition="start" />
                            <Tab icon={<MdPolicy />} label="Policy" sx={smallTabItemStyle} iconPosition="start" />
                        </Tabs>
                    </Box>
                    {this.renderConfigContent()}
                </div>
            );
        } else {
            const divisionSeasons = {};
            for (const [key, value] of Object.entries(seasons)) {
                if (division.id === value.divisionId) {
                    divisionSeasons[key] = value;
                }
            }
            const seasonConfig = new Date().valueOf() > devThreshold ? division.season.config : {};
            return (
                <SeasonDetail
                    season={seasonChoice}
                    seasonConfig={seasonConfig}
                    division={division}
                    organization={organization}
                    teams={teams}
                    trophies={trophies}
                    locations={locations}
                    profileData={profileData}
                    seasons={divisionSeasons}
                    uid={user.uid}
                    user={user}
                    users={users}
                    wallets={wallets}
                    cards={cards}
                    onSeasonDeleted={(update)=> {
                        if (update && update.id && seasons) {
                            for (const item of Object.values(seasons)) {
                                if (item.id !== update.id) {
                                    this.setState({seasonChoice: item});
                                    break;
                                }
                            }
                        } else {
                            this.setState({seasonChoice: {}, configMode: true});
                        }
                        if (onSaveDone != null) {
                            onSaveDone();
                        }
                    }}
                    onChanged={(update)=> {
                        if (onChanged != null) {
                            onChanged(update);
                        }
                        if (update) {
                            this.setState({seasonChoice: update})
                        }
                    }}
                    onPickSeason={(update)=>{
                        this.setState({seasonChoice: update})
                        replaceUrl(getDivisionDomain(organization, division.id, update.id))
                    }}
                    onSaveDone={()=> {
                        if (onSaveDone != null) {
                            onSaveDone();
                        }
                    }} 
                    onUserUpdate={(update, team)=> {
                        if (onUserUpdate != null) {
                            onUserUpdate(update, team);
                        }
                    }}
                />
            );
        }
    }

    renderInstructions() {
        return (
            <Form className='form-edit' style={{paddingLeft: 20, paddingRight:20}}>
                <Row className='form-edit-row'>
                    <h1 className='form-edit-subtitle'>
                    Guide to Setup Your Division
                    </h1>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 1: Add a venue to your division
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    In order for your division to be ready to launch your first season you will need a venue. Create a new venue under locations tab inside venue feature.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                        <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                            this.setState({mainTab: 5})
                        }}>Setup</Button>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 2: Invite a referee to your orgnization
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    To create schedules and input scores we will need referees to manage the data, you can invite a referee by username or with an email invite.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                        <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                            if (this.props.onPressReferee) {
                                this.props.onPressReferee();
                            }
                        }}>Setup</Button>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 3: Invite teams to join and follow division
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    In this step we can accept teams that have requested to join division, as well as invite and add teams manually. These teams can be used later to setup your season, they will also be able to follow and view division news feed.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                        <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                            this.setState({mainTab: 4})
                        }}>Setup</Button>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 4: Selecting division rules
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    Setup your rules for the division, you will need to select: statistics, score system, lineups, match settings, game times configuration, foul rules and event rules.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                        <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                            this.setState({mainTab: 2})
                        }}>Setup</Button>
                    </Col>
                </Row>
                <Row className='form-edit-row'>
                    <h4 className='form-edit-subtitle'>
                    Step 5: Create your season
                    </h4>
                    <div className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    Select the settings for your season: Name, registration dates, fees for players and teams, type of registration, team count and visibility.
                    </div>
                </Row>
                <Row className='form-edit-row'>
                    <Col className='form-edit-label' sm={9} xs={12} style={{textAlign: 'left'}}>
                    </Col>
                    <Col className='form-edit-entry' sm={3} xs={12}>
                        <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                            this.setState({configMode: false});
                        }}>Setup</Button>
                    </Col>
                </Row>
            </Form>
        )
    }

    renderConfigContent() {
        const {organization, division, teams, locations, seasons, trophies, user, onChanged, onDivisionChanged} = this.props;
        const {mainTab} = this.state;

        switch (mainTab) {
            case 0:
                return (
                    <div>
                        {this.renderDivisionHeader()}

                        <Row style={{margin: 0}}>
                            <Col lg={6} sm={12}>
                                {this.renderInstructions()}
                            </Col>
                            <Col lg={6} sm={12}>
                                <DivisionCong
                                    division={division}
                                    organization={organization}
                                    uid={user.uid}
                                    onCloseClick={()=> {
                                        this.setState({editOpen: false});
                                    }}
                                    onSaveDone={()=> {
                                        if (onChanged != null) {
                                            onChanged();
                                        }
                                    }} />
                            </Col>
                        </Row>
                    </div>
                );
            case 1:
                return (
                    <DivisionTrophy 
                        category={division.category} 
                        forDivision={true}
                        division={division}
                        organization={organization}
                        uid={user.uid}
                        teams={teams}
                        seasons={seasons}
                        trophies={trophies}
                        onSeasonChoice={(season)=> {
                            this.setState({configMode: false, seasonChoice: season});
                            replaceUrl(getDivisionDomain(organization, season.divisionId, season.id))
                        }}
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }} />
                );
            case 2:
                return (
                    <DivisionRules 
                        category={division.category} 
                        forDivision={true}
                        division={division}
                        organization={organization}
                        uid={user.uid}
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }}
                        onChangeClick={(category) => {
                            if (division.category == null) {
                                division.category = {};
                            }
                            for (const [key, value] of Object.entries(category)) {
                                division.category[key] = value;
                            }
                            saveDivision(division, () => {
                                this.setState({division: division});
                            }, (error) => {

                            });
                        }} />
                );
            case 3:
                return (
                    this.renderDivsionSeasons()
                );
            case 4:
                return (
                    <DivisionTeams
                        teams={teams}
                        division={division}
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }} />
                );
            case 5:
                return (
                    <DivisionLocations
                        locations={locations}
                        division={division}
                        onChanged={()=> {
                            if (onChanged != null) {
                                onChanged();
                            }
                        }} />
                );
            case 6:
                return (
                    <DivisionPolicy 
                        division={division}
                        uid={user.uid}
                        onChanged={(update)=> {
                            if (onDivisionChanged != null) {
                                onDivisionChanged(update);
                            }
                        }} />
                );
            default:
                return (
                    <div />
                );
        }
    }

    renderDivsionSeasons() {
        const {organization, division, seasons, teams, trophies, user, locations, onSaveDone} = this.props;
        const {editSeason, seasonToEdit} = this.state;
        let seasonArr = [];
        if (division != null && seasons != null) {
            for (const val of Object.values(seasons)) {
                if (val.divisionId === division.id) {
                    seasonArr.push(val);
                }
            }
        }

        return (
            <div style={{marginTop: 10}}>
                <ItemsNavigation
                    title={t('seasons')}
                    icon={<FcTimeline/>}
                    detailOpen={false}
                    editOpen={editSeason}
                    totalCount={seasonArr.length}
                    detailItem={seasonToEdit}
                    onClickBack={()=>{
                        this.setState({seasonToEdit: {}, editSeason: false});
                    }}
                    onClickAdd={()=>{
                        this.setState({seasonToEdit: {}, editSeason: true});
                    }} />

                {editSeason && seasonToEdit != null ? (
                <div style={{marginTop: 30, marginBottom: 10}}>
                    <SeasonEdit 
                        division={division}
                        organization={organization}
                        season={seasonToEdit}
                        locations={locations}
                        teams={teams}
                        trophies={trophies}
                        createMode={seasonArr.length === 0} 
                        uid={user.uid}
                        onCloseClick={()=> {
                            this.setState({seasonToEdit: {}, editSeason: false})
                        }}
                        onSaveDone={()=> {
                            if (onSaveDone != null) {
                                onSaveDone();
                            }
                            this.setState({seasonToEdit: {}, editSeason: false});
                        }} />
                </div>
                ) : (
                <div style={{marginTop: 30}}>
                    {seasonArr.map((value, idx)=>(
                        <SeasonBadge 
                            key={value.id}
                            season={value}
                            division={division}
                            allowDetail={true}
                            onClickEdit={()=> {
                                this.setState({seasonToEdit: value, editSeason: true})
                            }}
                            onClickDetails={()=> {
                                this.setState({seasonChoice: value, configMode: false})
                                replaceUrl(getDivisionDomain(organization, value.divisionId, value.id))
                            }} />
                    ))}
                </div>
                )}
            </div>
        );
    }

    renderConfigChoice() {
        const {organization, division, seasons, onSetup} = this.props;
        const {configMode, seasonChoice, dropdown} = this.state;
        let seasonArr = [];
        if (division != null && seasons != null) {
            for (const val of Object.values(seasons)) {
                if (val.divisionId === division.id) {
                    seasonArr.push(val);
                }
            }
        }
        seasonArr.sort((a, b) => {
            return b.startTime - a.startTime;
        })
        
        return (
            <div className='items-navigation'>
                <Button 
                    className={configMode ? "division-mode-btn" : "division-mode-btn-inactive"} 
                    onClick={e => {
                    this.setState({configMode: true});
                    replaceUrl(getDivisionDomain(organization, division.id))
                }}>Configuration</Button>
                {configMode ? (
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Button 
                        className={!configMode ? "division-mode-btn" : "division-mode-btn-inactive"} 
                        onClick={e => {
                        this.setState({configMode: false});
                        replaceUrl(getDivisionDomain(organization, division.id, seasonChoice.id))
                    }}>Seasons {">"} {seasonChoice.title}</Button>
                </div>
                ) : (
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <div className='detail-nav-btn'>
                        <div className='items-navigation-count'><FcTodoList/></div>
                        <h3 className='items-navigation-title'>
                        {t('seasons')}
                        </h3>
                        <div className='items-navigation-count'>| {seasonArr.length} </div>
                    </div>
                    <div style={{display: 'inline-block'}}>
                        <h3 style={{display: 'inline-block', fontSize: 16, marginLeft: 10, marginRight: 10}}>{" > "}</h3>
                        <div className='detail-nav-btn'>
                        <Dropdown addonType="prepend" isOpen={dropdown} toggle={()=>{
                            this.setState({dropdown: !dropdown})
                        }}>
                            <Button outline>{seasonChoice.title}</Button>
                            <DropdownToggle split outline />
                            <DropdownMenu>
                                <DropdownItem header>{t('choose_items')}</DropdownItem>
                                {seasonArr.map((value, idx)=>(
                                    <DropdownItem key={idx} onClick={e=>{
                                        this.setState({seasonChoice: value})
                                        replaceUrl(getDivisionDomain(organization, division.id, value.id))
                                    }}>{value.title}</DropdownItem>
                                ))
                                }
                                <DropdownItem divider />
                                <DropdownItem onClick={e=>{
                                    this.setState({dropdown: false})
                                }}>{t('cancel')}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        </div>
                    </div>
                </div>
                )}
                
                <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    window.location = "/organizer/mailbox";
                }}>{t('contact_email')}</Button>
                {!configMode && seasonChoice != null && seasonChoice.id != null && 
                    (!seasonChoice.status || seasonChoice.status === STATUS_REGISTER || seasonChoice.status === STATUS_SCHEDULE) && (
                <Button className='btn-cancel wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                    if (onSetup) {
                        onSetup(seasonChoice)
                    }
                }}>{t('setup')}</Button>
                )}
            </div>
        );
    }

    renderDivisionHeader() {
        const {division, onClickEdit} = this.props;
        
        let icon = division.thumbUri != null ? division.thumbUri : defaultImage;
        
        return (
            <div className='division-detail-header'>
                <CardImg
                    className="profile-thumb wrap-content-wrap-child division-detail-header-thumb"
                    src={icon} />
                <div className="wrap-content-fill-child vertical-center-spacing">
                    <h4 className='label division-detail-header-title'>
                    {division.title}
                    </h4>
                    <div className='division-detail-header-info'>
                    {t('teams')}: {division.teamIds != null ? Object.values(division.teamIds).length : 0}, 
                    {t('gender')}: {division.gender === "Female" ? <GiFemale/> : <GiMale/>},
                    {t('age')}: {division.ageTitle} ({division.ageFrom} - {division.ageTo})
                    </div>

                </div>
                <div className="wrap-content-wrap-child vertical-center-spacing">
                    
                    <Button className="btn-edit" onClick={e => {
                        if (onClickEdit != null) {
                            onClickEdit();
                        }
                    }}>{t('edit')}</Button>
                </div>
            </div>
        );
    }

    render() {
        const {division} = this.props;
        if (division != null) {
            return (
                <div className=''>
                    {this.renderConfigChoice()}
                    
                    {this.renderContents()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(DivisionDetail);