import React, { Component } from "react";
import { connect } from 'react-redux';
import { cartActions } from 'redux/_actions';
import {
    Row,
    Col,
    Button,
    Input,
    Progress,
    CardImg,
    Spinner,
} from 'reactstrap';
import { t } from 'i18next';
import { 
    getOrgDomain,
    toDateTimeString,
    STRIPE_PUBLISHABLE_KEY,
} from 'utils/Utils';
import defaultBg from 'assets/img/icons/stadium-header.jpg';

import BingoNumbers, { getBingoArr } from "components/fundraise/BingoNumbers";
import { getMatchingCount } from "components/fundraise/BingoNumbers";
import Switch from '@mui/material/Switch';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
} from 'reactstrap';

import { loadStripe } from '@stripe/stripe-js';

import {
    BiDonateHeart
} from 'react-icons/bi';

import {
    MdBusiness
} from 'react-icons/md';

import defaultImage from 'assets/img/icons/fund_image_land.jpg';
import bingoIcon from 'assets/img/icons/ic_bingo.png';
import productIcon from 'assets/img/icons/ic_bingo.png';
import userIcon from 'assets/img/icons/player_photo_default.png';
import flowerIcon from 'assets/img/icons/flower_yellow.png';
import usersIcon from 'assets/img/icons/users_yellow.png';
import { FaUser } from "react-icons/fa";
import { generateId, invokeHttpsApi } from "utils/API";
import { STATUS_ONGOING } from "utils/constants";

export default class FundraisePrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            showAll: false,
            users: {},
            sponser: {},
            sponserCheck: false,
            activeIndex: 0,
            quantity: 1,
            toDonate: 10.01,
            balanceInput: 0,
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
    }

    renderDonateItem(item) {
        const {users} = this.props;

        const price = parseFloat(item.price) / 100;

        let msg = "";
        if (item.uid && users[item.uid]) {
            msg = users[item.uid].username;
        }
        let timestamp = item.timestamp;
        if (!timestamp) {
            timestamp = 0;
        }

        return (
            <div className="wrap-content-parent" style={{margin: 0, padding: 5}}>
                <span className="wrap-content-wrap-child fund-table-info" style={{fontSize: 20, margin: '5px 15px'}}>{item.sponser ? <MdBusiness/> : <BiDonateHeart/>}</span>
                <div className="wrap-content-fill-child">
                    <h4 className='fund-table-info'>
                    {msg} {item.sponser && <span style={{color: '#38be55'}}>{item.sponser.name}</span>}
                    </h4>
                    <div className="fund-table-info">
                    <b style={{color: '#38be55', marginRight: 15}}>${price}</b> {toDateTimeString(timestamp)}
                    </div>
                </div>
            </div>
        );
    }

    renderDonates() {
        const {user, fundraise} = this.props;
        const {showAll} = this.state;
        let paymentsArr = [];
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (!item.bingoId && !item.productId && item.fundraiseId === fundraise.id) {
                    paymentsArr.push(item);
                }
            }
        }

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        const income = [];
        
        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });

        for (const item of paymentsArr) {
            if (!showAll || paymentsArr.length < 10) {
                income.push(
                    <div>{this.renderDonateItem(item)}</div>
                );
            }
        }

        const items = [];
        for (const item of income) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        if (!user) {
            return (
                <div>
                    <div className="fund-table-info" style={{marginBottom: 25}}>{paymentsArr.length} {t('donations')}</div>
                    <h4 style={{margin: '5px 0px', fontSize: 12, marginBottom: 5}}>{t('price_to_donate')} ($)</h4>
                    
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_donate')}
                        </div>
                    )}
                    <div className="wrap-content-parent" style={{marginTop: 15, marginBottom: 25}}>
                        <Button className="btn-cancel" onClick={e=> {
                            this.setState({showAll: !showAll});
                        }}>{!showAll ? (t('see_all') + " (" + paymentsArr.length + ")") : (t('see_less') + " <<")}</Button>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <div className="fund-table-info" style={{marginBottom: 25}}>{paymentsArr.length} {t('donations')}</div>
                
                <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                    this.setState({donateOverlay: true});
                }}>{t('donate_now')}</Button>
                
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                )}
                <div className="wrap-content-parent" style={{marginTop: 15, marginBottom: 25}}>
                    <Button className="btn-cancel" onClick={e=> {
                        this.setState({showAll: !showAll});
                    }}>{!showAll ? (t('see_all') + " (" + paymentsArr.length + ")") : (t('see_less') + " <<")}</Button>
                </div>
            </div>
        )
    }

    renderBingoItem(bingo) {
        const {user, fundraise} = this.props;

        const icon = bingo.thumbUri ? bingo.thumbUri : bingoIcon;
        
        let payments = [];
        
        var count = 0;
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (item.bingoId === bingo.id) {
                    if (user && item.uid === user.uid) {
                        payments.push(item);
                    }
                    count ++;
                }
            }
        }
        if (payments.length === 0) {
            payments = [
                {bingoStr: ""}
            ];
        }
        
        var matching = 0;
        let items = [];
        for (const payment of payments) {
            matching += getMatchingCount(bingo.numArr, payment.bingoStr);
            items.push(
                <BingoNumbers
                    title={t('your_number')}
                    numStr={bingo.numArr}
                    bingoStr={payment.bingoStr}
                    indexChoice={-1} />
            )
        }

        return (
            <div className="shadow-tile-container" style={{padding: 0, backgroundColor: 'white'}}>
                <div className="wrap-content-parent" style={{margin: 0}}>
                    <img 
                        alt="bingo" 
                        src={icon} 
                        style={{
                            width: 96, 
                            height: 96, 
                            backgroundColor: '#C1E729', 
                            borderRadius: 5,
                            marginRight: 15,
                        }} />
                    <div className="wrap-content-fill-child">
                        <h4 className="fund-table-title" style={{margin: '7px 0px'}}>{bingo.title}</h4>
                        <div className="fund-table-info">{bingo.description}</div>                        
                        <div className="fund-table-info">{t('price')}: <b style={{color: '#38be55', marginRight: 15}}>${bingo.price}</b></div>
                    </div>
                </div>
                {user && items}
                <Row style={{marginLeft: 0, marginRight: 0, marginBottom: 15}}>
                    <Col xs={4} style={{marginTop: 5}}>
                        <img alt="icon" className="fund-result-icon" src={usersIcon} />
                        <h4 className="fund-table-result">
                            {count}
                        </h4>
                    </Col>
                    <Col xs={4} style={{marginTop: 5}}>
                        <img alt="icon" className="fund-result-icon" src={flowerIcon} />
                        <h4 className="fund-table-result">
                            {!bingo.status ? "-" : matching}
                        </h4>
                    </Col>
                    <Col xs={4} className="text-center">
                        <Button 
                            className="btn-submit"
                            style={{margin: 0}}
                            onClick={e=> {
                                if (!user) {
                                    alert(t('login_needed'));
                                    return;
                                }
                                this.setState({bingoChoice: bingo, idxChoice: 0})
                            }}>
                            {t('donate') + "($" + bingo.price + ")"}
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    

    renderBingoOverlay() {
        const {fundraise, user, users} = this.props;
        const {checkoutProgress, bingoChoice, bingoStr, idxChoice} = this.state;
        if (!bingoChoice) {
            return <div/>
        }

        var userArr = [];
        let bingoArr = getBingoArr(bingoStr);
        
        var matching = 0;
        if (fundraise.donate) {
            for (const payment of Object.values(fundraise.donate)) {
                if (payment.bingoId === bingoChoice.id) {
                    
                    matching = getMatchingCount(bingoChoice.numArr, payment.bingoStr);
                    userArr.push(
                        {
                            matching: matching,
                            view: (
                                <BingoNumbers
                                    numStr={bingoChoice.numArr}
                                    bingoStr={payment.bingoStr}
                                    indexChoice={-1} />
                            ),
                            user: users[payment.uid],
                        }
                    );
                }
            }
        }
        var pending = false;
        if (fundraise.pending) {
            for (const item of Object.values(fundraise.pending)) {
                if (!item.bingoId && item.fundraiseId === fundraise.id && item.uid === user.uid) {
                    pending = true;
                }
            }
        }

        var tableArr = [];

        var actionView;

        if (!bingoChoice.status) {
            userArr = userArr.sort((a, b) => {
                if (a.user.username === b.user.username) 
                    return 0;
    
                return a.user.username < b.user.username ? -1 : 1;
            });
            
            let numberArr = [];
            for (let i = 1; i < 50; i ++) {
                if (!bingoArr.includes(i)) {
                    numberArr.push(i);
                }
            }
            
            
    
            actionView = (
                <div>
                    <div className='form-edit-row row'>
                        <div className="fund-landing-text">{t('bingo_num_summary')}</div>
                        
                        <BingoNumbers
                            title={t('your_number')}
                            numStr={bingoChoice.numArr}
                            bingoStr={bingoStr}
                            indexChoice={idxChoice}
                            onIndexChange={i=> {
                                this.setState({idxChoice: i});
                            }} />

                        <div className='form-edit-label col-12 col-sm-5'>
                            {t('bingo_number')}
                        </div>
                        <div className='form-edit-entry col-12 col-sm-7'>
                            <div className='form-edit-input'>
                                <Input type="select" className='form-control bingo-num-edit' name="text" id="title" onChange={e => {
                                    bingoArr[idxChoice] = parseInt(e.target.value);
                                    var update = ""
                                    for (let i = 0; i < 7; i ++) {
                                        update += (i === 0 ? "" : ",") + bingoArr[i];
                                    }
                                    this.setState({bingoStr: update});
                                }} value={bingoArr[idxChoice]}>
                                <option key={-1} value={-1}>-</option>
                                {numberArr.map((val,idx)=>(
                                <option key={idx} value={val}>{val}</option>
                                ))}
                                </Input>
                            </div>
                        </div>
                    </div>
                    {checkoutProgress ? (
                        <Spinner color="grow" />
                    ) : (
                        <div>
                            <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                                if (!bingoArr.includes(-1)) {
                                    this.requestCharge(fundraise, bingoChoice, null, null, bingoStr);
                                } else {
                                    alert(t('invalid_bingo_num'));
                                }
                            }}>{t('donate_now')}</Button>
                            <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                                if (!bingoArr.includes(-1) && !pending) {
                                    this.requestFundraisePayment(fundraise, bingoChoice, null, null, bingoStr);
                                } else if (!pending) {
                                    alert(t('invalid_bingo_num'));
                                }
                            }}>{t(pending ? 'register_donate' : 'donate_requested')}</Button>
                        </div>
                    )}
                </div>
            )
        } else if (bingoChoice.status === STATUS_ONGOING) {
            userArr = userArr.sort((a, b) => {
                if (a.user.username === b.user.username) 
                    return 0;
    
                return a.user.username < b.user.username ? -1 : 1;
            });
            
            let payments = [];
            
            if (fundraise.donate) {
                for (const item of Object.values(fundraise.donate)) {
                    if (item.bingoId === bingoChoice.id) {
                        if (item.uid === user.uid) {
                            payments.push(item);
                        }
                    }
                }
            }
            if (payments.length === 0) {
                payments = [
                    {bingoStr: ""}
                ];
            }
            
            let items = [];
            for (const payment of payments) {
                matching = getMatchingCount(bingoChoice.numArr, payment.bingoStr);
                items.push(
                    <BingoNumbers
                        title={{matching}/7}
                        numStr={bingoChoice.numArr}
                        bingoStr={payment.bingoStr}
                        indexChoice={-1} />
                )
            }

            actionView = (
                <div>
                    <div className='form-edit-row row'>
                        <div className="fund-landing-text">{t('bingo_ongoing_summary')}</div>
                        <BingoNumbers
                            title={t('bingo_numbers')}
                            numStr={bingoChoice.numArr}
                            indexChoice={-1} />
                        <div className="divider" />
                        <h4 className="fund-landing-text">{t('your_numbers')}</h4>
                        {items}
                    </div>
                </div>
            )
        } else {
            userArr.sort((a, b) => {
                return a.matching - b.matching;
            });
            
            let payments = [];
            
            if (fundraise.donate) {
                for (const item of Object.values(fundraise.donate)) {
                    if (item.bingoId === bingoChoice.id) {
                        if (item.uid === user.uid) {
                            payments.push(item);
                        }
                    }
                }
            }
            if (payments.length === 0) {
                payments = [
                    {bingoStr: ""}
                ];
            }
            
            matching = 0;
            let items = [];
            for (const payment of payments) {
                matching = getMatchingCount(bingoChoice.numArr, payment.bingoStr);
                if (matching > 0) {
                    items.push(
                        <BingoNumbers
                            title={{matching}/7}
                            numStr={bingoChoice.numArr}
                            bingoStr={payment.bingoStr}
                            indexChoice={-1} />
                    )
                    if (bingoChoice.prizeDescriptions && bingoChoice.prizeDescriptions["match_" + matching]) {
                        items.push(
                            <div className="fund-landing-text">{bingoChoice.prizeDescriptions["match_" + matching]}</div>
                        )
                    }
                }
            }

            actionView = (
                <div>
                    <div className='form-edit-row row'>
                        <div className="fund-landing-text">{t('bingo_ongoing_summary')}</div>
                        <BingoNumbers
                            title={t('bingo_numbers')}
                            numStr={bingoChoice.numArr}
                            indexChoice={-1} />
                        <div className="divider" />
                        <h4 className="fund-landing-text">{t('your_matches')}</h4>
                        {items.length > 0 ? items : (
                        <div className="fund-landing-text">{t('no_matches')}</div>
                        )}
                    </div>
                </div>
            )
        }

        for (const item of userArr) {
            const thumb = item.user.photoUri ? item.user.photoUri : userIcon;
            tableArr.push(
                <Row className='row-no-margin'>
                    <Col lg={4} xs={5} sm={4} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div style={{margin: 'auto 0px', fontSize: 14}}>
                            {item.matching === 0 ? "-" : item.matching}
                            </div>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumb} />
                            <div className='wrap-content-fill-child row-badge-title'>
                            {item.user.username}
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} xs={7} sm={8} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            {item.view}
                        </div>
                    </Col>
                </Row>
            );
            tableArr.push(<div className="divider" />);
        }
        const icon = bingoChoice.thumbUri ? bingoChoice.thumbUri : bingoIcon;

        return (
            <div className="bingo-overlay">
                <div className="bingo-overlay-wrapper">
                    <div className="wrap-content-parent" style={{margin: 0, textAlign: 'left'}}>
                        <img 
                            alt="bingo" 
                            src={icon} 
                            style={{
                                width: 96, 
                                height: 96, 
                                backgroundColor: '#C1E729', 
                                borderRadius: 5,
                                marginRight: 15,
                            }} />
                        <div className="wrap-content-fill-child">
                            <h4 className="fund-table-title" style={{margin: '7px 0px'}}>{bingoChoice.title}</h4>
                            <div className="fund-table-info">{bingoChoice.description}</div>
                            <div className="fund-table-info">{t('price')}: <b style={{color: '#38be55', marginRight: 15}}>${bingoChoice.price}</b></div>
                        </div>
                    </div>
                    <div className="divider" style={{margin: '5px 0px'}} />
                    {actionView}
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{margin: '10px 0px'}}>
                        {t('leaderboard')}
                        </div>
                    </div>
                    <div className="divider" />
                    {tableArr.length > 0 ? tableArr : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                    )}
                    <div className="wrap-content-parent">
                        <div className="wrap-content-fill-child" />
                        <Button className="btn-cancel" onClick={e=>{
                            this.setState({bingoChoice: null});
                        }}>{t('close')}</Button>
                    </div>
                </div>
            </div>
        )
    }

    renderBingo() {
        const {fundraise} = this.props;
        
        const bingoArr = fundraise.bingo ? Object.values(fundraise.bingo) : [];
        const productArr = fundraise.product ? Object.values(fundraise.product) : [];
        let items = [];
        

        for(const item of bingoArr) {
            items.push(
                <Col sm={9} xs={12} lg={6}>{this.renderBingoItem(item)}</Col>
            )
        }

        for(const item of productArr) {
            items.push(
                <Col sm={9} xs={12} lg={6}>{this.renderProductItem(item)}</Col>
            )
        }

        return (
            <Row style={{marginLeft: 0, marginRight: 0, marginBottom: 30}}>
                {items}
            </Row>
        )
    }

    renderProductItem(product) {
        const {user, fundraise} = this.props;

        const icon = product.thumbUri ? product.thumbUri : productIcon;
        
        let payments = [];
        
        var count = 0;
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (item.productId === product.id) {
                    if (user && item.uid === user.uid) {
                        payments.push(item);
                    }
                    count ++;
                }
            }
        }
        
        let items = [];
        for (const payment of payments) {
            const price = payment.price / 100;
            items.push(
                <div className="wrap-content-parent" style={{margin: 0, padding: 5}}>
                    <span className="wrap-content-wrap-child fund-table-info" style={{fontSize: 20, margin: '5px 15px'}}><BiDonateHeart/></span>
                    <div className="wrap-content-fill-child">
                        <h4 className='fund-table-info'>
                        {t('purchased_product')} {product.title}
                        </h4>
                        <div className="fund-table-info">
                        <b style={{color: '#38be55', marginRight: 15}}>${price}</b> {toDateTimeString(payment.timestamp)}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="shadow-tile-container" style={{padding: 0, backgroundColor: 'white'}}>
                <div className="wrap-content-parent" style={{margin: 0}}>
                    <img 
                        alt="product" 
                        src={icon} 
                        style={{
                            width: 96, 
                            height: 96, 
                            backgroundColor: '#C1E729', 
                            borderRadius: 5,
                            marginRight: 15,
                        }} />
                    <div className="wrap-content-fill-child">
                        <h4 className="fund-table-title" style={{margin: '7px 0px'}}>{product.title}</h4>
                        <div className="fund-table-info">{product.description}</div>                        
                        <div className="fund-table-info">{t('price')}: <b style={{color: '#38be55', marginRight: 15}}>${product.price}</b></div>
                    </div>
                </div>
                {user && items}
                <Row style={{marginLeft: 0, marginRight: 0, marginTop: 10, marginBottom: 15}}>
                    <Col xs={4} style={{marginTop: 5}}>
                        <img alt="icon" className="fund-result-icon" src={usersIcon} />
                        <h4 className="fund-table-result">
                            {count}
                        </h4>
                    </Col>
                    <Col xs={4} style={{marginTop: 5}}>
                        
                    </Col>
                    <Col xs={4} className="text-center">
                        <Button 
                            className="btn-submit"
                            style={{margin: 0}}
                            onClick={e=> {
                                if (!user) {
                                    alert(t('login_needed'));
                                    return;
                                }
                                this.setState({productChoice: product, idxChoice: 0, quantity: 1})
                            }}>
                            {t('donate') + "($" + product.price + ")"}
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    

    renderProductOverlay() {
        const {fundraise, user, users} = this.props;
        const {checkoutProgress, productChoice, quantity} = this.state;
        if (!productChoice) {
            return <div/>
        }

        var userArr = [];
        
        var matching = 0;
        if (fundraise.donate) {
            for (const payment of Object.values(fundraise.donate)) {
                if (payment.productId === productChoice.id) {
                    
                    const price = payment.price / 100;
                    userArr.push(
                        {
                            matching: matching,
                            view: (
                                <div className="fund-table-info">
                                <b style={{color: '#38be55', marginRight: 15}}>${price}</b> / Qty:{payment.quantity ? payment.quantity : 1} {toDateTimeString(payment.timestamp)}
                                </div>
                            ),
                            user: users[payment.uid],
                        }
                    );
                }
            }
        }
        var pending = false;
        if (fundraise.pending) {
            for (const item of Object.values(fundraise.pending)) {
                if (!item.productId && item.fundraiseId === fundraise.id && item.uid === user.uid) {
                    pending = true;
                }
            }
        }

        var tableArr = [];

        var actionView;

        userArr = userArr.sort((a, b) => {
            if (a.user.username === b.user.username) 
                return 0;

            return a.user.username < b.user.username ? -1 : 1;
        });
        
        actionView = (
            <div>
                {checkoutProgress ? (
                    <Spinner color="grow" />
                ) : (
                    <div>
                        <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                            this.requestProductCharge(fundraise, productChoice, quantity);
                        }}>{t('donate_now')}</Button>
                        <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                            this.requestProductFundraisePayment(fundraise, productChoice, quantity);
                        }}>{t(pending ? 'register_donate' : 'donate_requested')}</Button>
                    </div>
                )}
            </div>
        )
        for (const item of userArr) {
            const thumb = item.user.photoUri ? item.user.photoUri : userIcon;
            tableArr.push(
                <Row className='row-no-margin'>
                    <Col lg={4} xs={5} sm={4} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div style={{margin: 'auto 0px', fontSize: 14}}>
                            {item.matching === 0 ? "-" : item.matching}
                            </div>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumb} />
                            <div className='wrap-content-fill-child row-badge-title'>
                            {item.user.username}
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} xs={7} sm={8} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            {item.view}
                        </div>
                    </Col>
                </Row>
            );
            tableArr.push(<div className="divider" />);
        }
        const icon = productChoice.thumbUri ? productChoice.thumbUri : productIcon;

        return (
            <div className="bingo-overlay">
                <div className="bingo-overlay-wrapper">
                    <div className="wrap-content-parent" style={{margin: 0, textAlign: 'left'}}>
                        <img 
                            alt="product" 
                            src={icon} 
                            style={{
                                width: 96, 
                                height: 96, 
                                backgroundColor: '#C1E729', 
                                borderRadius: 5,
                                marginRight: 15,
                            }} />
                        <div className="wrap-content-fill-child">
                            <h4 className="fund-table-title" style={{margin: '7px 0px'}}>{productChoice.title}</h4>
                            <div className="fund-table-info">{productChoice.description}</div>
                            <div className="fund-table-info">{t('price')}: <b style={{color: '#38be55', marginRight: 15}}>${productChoice.price}</b></div>
                        </div>
                    </div>
                    <div className="divider" style={{margin: '5px 0px'}} />
                    <h4 style={{margin: '5px 0px', fontSize: 12, marginBottom: 5, textAlign: 'left'}}>{t('quantity')}</h4>
                    <div className="wrap-content-parent">
                        <h4 style={{position: 'absolute', width: 20, textAlign: 'center', margin: '6px 4px', fontSize: 10}}>Total: $({productChoice.price * quantity})</h4>
                        <Input type="number" step={1} className='form-control' style={{paddingLeft: 100, textAlign: 'right'}} name="text" id="title" onChange={e => {
                            this.setState({quantity: parseInt(e.target.value)});
                        }} value={quantity} />
                    </div>
                    {actionView}
                    <div className='wrap-content-parent'>
                        <div className="wrap-content-fill-child row-badge-info" style={{margin: '10px 0px'}}>
                        {t('donations')}
                        </div>
                    </div>
                    <div className="divider" />
                    {tableArr.length > 0 ? tableArr : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_donate')}
                    </div>
                    )}
                    <div className="wrap-content-parent">
                        <div className="wrap-content-fill-child" />
                        <Button className="btn-cancel" onClick={e=>{
                            this.setState({productChoice: null, quantity: 1});
                        }}>{t('close')}</Button>
                    </div>
                </div>
            </div>
        )
    }

    getEnrollCart(donate) {
        const result = {
            uid: donate.uid,
            fundraiseId: donate.fundraiseId,
            note: donate.note,
            price: donate.price,
        };
        if (donate.productId) {
            result.productId = donate.productId;
        }
        if (donate.quantity) {
            result.quantity = donate.quantity;
        }
        if (donate.bingoId) {
            result.bingoId = donate.bingoId;
        }
        if (donate.bingoStr) {
            result.bingoStr = donate.bingoStr;
        }
        if (donate.sponser) {
            result.sponser = donate.sponser;
        }
        result.id = generateId("/order/newPath/dict");

        return result;
    }

    requestCharge(fundraise, bingo, note, price, bingoStr) {
        const {organization, user, checkoutCartItems} = this.props;
        const {sponserCheck, sponser} = this.state;
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!bingo && price < 10) {
            alert(t('invalid_price_prompt'));
            return;
        }
        
        if (bingo && (!bingoStr || bingoStr.split(',').length < 7)) {
            alert(t('invalid_bingo_prompt'));
            return;
        }
        
        if (!bingo && sponserCheck && (!sponser.name || !sponser.email)) {
            alert(t('spnser_info_needed'));
            return;
        }
        
        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        const priceVal = price ? price : (bingo ? bingo.price : 0);
        if (priceVal <= 0) {
            alert(t('invalid_price_prompt'));
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            fundraiseId: fundraise.id,
            price: priceVal,
            uid: user.uid,
            returnURL: pref + '/fund/' + fundraise.id
        }
        if (bingo) {
            data.bingoId = bingo.id;
            data.bingoStr = bingoStr;
        } else if (sponserCheck) {
            data.sponser = sponser;
        }
        if (note) {
            data.note = note;
        } else {
            data.note = '';
        }
        
        const item = this.getEnrollCart(data);
        const dict = {};
        dict[item.id] = item;
        checkoutCartItems(organization.id, dict, note, price, pref + '/fund/' + fundraise.id, (async (session) => {
            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
            stripe.redirectToCheckout({ sessionId: session.id });
            this.setState({
                checkoutProgress: false,
                redirecting: true,
                progress: false,
            });
        }), (() => {
            alert(t('request_failed'));
        }))
    }

    requestProductCharge(fundraise, product, quantity) {
        const {organization, user, checkoutCartItems} = this.props;
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!product || quantity < 1) {
            alert(t('invalid_price_prompt'));
            return;
        }
        
        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        const priceVal = product ? product.price : 0;
        if (priceVal <= 0) {
            alert(t('invalid_price_prompt'));
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            fundraiseId: fundraise.id,
            quantity: quantity,
            price: priceVal * quantity,
            uid: user.uid,
            productId: product.id,
            note: "",
            returnURL: pref + '/fund/' + fundraise.id
        }
        
        const item = this.getEnrollCart(data);
        const dict = {};
        dict[item.id] = item;
        checkoutCartItems(organization.id, dict, "", priceVal, pref + '/fund/' + fundraise.id, (async (session) => {
            const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
            stripe.redirectToCheckout({ sessionId: session.id });
            this.setState({
                redirecting: true,
                progress: false,
            });
        }), (() => {
            alert(t('request_failed'));
        }))
    }

    requestFundraisePayment(fundraise, bingo, note, price, bingoStr) {
        const {organization, user} = this.props;
        const {sponserCheck, sponser} = this.state;
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!bingo && price < 10) {
            alert(t('invalid_price_prompt'));
            return;
        }
        
        if (bingo && (!bingoStr || bingoStr.split(',').length < 7)) {
            alert(t('invalid_bingo_prompt'));
            return;
        }
        
        if (!bingo && sponserCheck && (!sponser.name || !sponser.email)) {
            alert(t('spnser_info_needed'));
            return;
        }
        
        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            fundraiseId: fundraise.id,
            price: price,
            userId: user.uid,
            returnURL: pref + '/fund/' + fundraise.id
        }
        if (bingo) {
            data.bingoId = bingo.id;
            data.bingoStr = bingoStr;
            data.price = bingo.price;
        } else if (sponserCheck) {
            data.sponser = sponser;
        }
        if (note) {
            data.note = note;
        } else {
            data.note = '';
        }
        
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-requestFundraisePayment', data, async (data) => {
            if (data.success) {
                if (data.pending) {
                    if (!fundraise.pending) {
                        fundraise.pending = {};
                    }
                    fundraise.pending[data.pending.id] = data.pending;
                    if (this.props.onChanged) {
                        this.props.onChanged(fundraise)
                    }
                }
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
                alert(t('donate_request_success'));
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    requestProductFundraisePayment(fundraise, product, quantity) {
        const {organization, user} = this.props;
        if (!organization.stripeAccountId) {
            alert('Organization payment setup not done yet.');
            return;
        }
        if (!product || quantity < 1) {
            alert(t('invalid_price_prompt'));
            return;
        }
        
        if (!window.confirm("Are you sure you want to purchase this price and procceed?")) {
            return;
        }
        
        let pref = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
        pref += getOrgDomain(organization);
        const data = {
            fundraiseId: fundraise.id,
            productId: product.id,
            quantity: quantity,
            price: product.price * quantity,
            userId: user.uid,
            note: "",
            returnURL: pref + '/fund/' + fundraise.id
        }
        
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-requestFundraisePayment', data, async (data) => {
            if (data.success) {
                if (data.pending) {
                    if (!fundraise.pending) {
                        fundraise.pending = {};
                    }
                    fundraise.pending[data.pending.id] = data.pending;
                    if (this.props.onChanged) {
                        this.props.onChanged(fundraise)
                    }
                }
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
                alert(t('donate_request_success'));
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }, (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    renderDonateOverlay() {
        const {user, fundraise} = this.props;
        const {checkoutProgress, toDonate, sponserCheck, sponser, note} = this.state;
        let paymentsArr = [];
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                if (!item.bingoId && !item.productId && item.fundraiseId === fundraise.id) {
                    paymentsArr.push(item);
                }
            }
        }
        var pending = false;
        if (fundraise.pending) {
            for (const item of Object.values(fundraise.pending)) {
                if (!item.bingoId && !item.productId && item.fundraiseId === fundraise.id && user && item.uid === user.uid) {
                    pending = true;
                }
            }
        }

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        
        return (
            <div className="bingo-overlay">
                <div className="bingo-overlay-wrapper" style={{textAlign: 'left'}}>
                    <div className="fund-table-info" style={{marginBottom: 25}}>{paymentsArr.length} {t('donations')}</div>
                    <h4 style={{margin: '5px 0px', fontSize: 12, marginBottom: 5}}>{t('price_to_donate')} ($)</h4>
                    <div className="wrap-content-parent">
                        <h4 style={{position: 'absolute', width: 20, textAlign: 'center', margin: '6px 4px', fontSize: 10}}>$ <br/> CAD</h4>
                        <Input type="number" step={0.01} className='form-control' style={{paddingLeft: 30, textAlign: 'right'}} name="text" id="title" onChange={e => {
                            this.setState({toDonate: parseFloat(e.target.value)});
                        }} value={toDonate} />
                    </div>
                    <div className='player-form-item'>
                        <div className='player-form-title'>
                            {t('donate_sponser_switch')}
                        </div>
                        <div className='player-form-value form-setting-control'>
                            <Switch
                                checked={sponserCheck}
                                onChange={e=>{
                                    this.setState({sponserCheck: e.target.checked});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </div>
                    </div>
                {sponserCheck && (
                    <div>
                        <h4 style={{margin: '5px 0px', fontSize: 16, marginBottom: 5}}>{t('spnser_info')}</h4>
                        <h4 style={{margin: '5px 0px', fontSize: 12, marginBottom: 5}}>{t('name')}</h4>
                        <Input type="text" className='form-control' name="name" id="name" onChange={e => {
                            sponser.name = e.target.value;
                            this.setState({sponser: sponser});
                        }} value={sponser.name} />
                        <h4 style={{margin: '5px 0px', fontSize: 12, marginBottom: 5}}>{t('email')}</h4>
                        <Input type="email" className='form-control' name="email" id="email" onChange={e => {
                            sponser.email = e.target.value;
                            this.setState({sponser: sponser});
                        }} value={sponser.email} />
                        <h4 style={{margin: '5px 0px', fontSize: 12, marginBottom: 5}}>{t('phone_number')}</h4>
                        <Input type="text" className='form-control' name="mobile" id="mobile" onChange={e => {
                            sponser.mobile = e.target.value;
                            this.setState({sponser: sponser});
                        }} value={sponser.mobile} />
                    </div>
                )}
                {toDonate < 10 && (
                    <div className="fund-table-info" style={{color: 'red', marginTop: 5}}>{t('donate_min_price_prompt')}</div>
                )}
                {checkoutProgress || this.props.checkoutProgress ? (
                    <Spinner color="grow" />
                ) : (
                    <div>
                        <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 10}} onClick={e=> {
                            this.requestCharge(fundraise, null, note, toDonate);
                        }}>{t('donate_now')}</Button>
                        <Button className="btn-submit" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                            if (!pending) {
                                this.requestFundraisePayment(fundraise, null, note, toDonate);
                            }
                        }}>{t(pending ? 'register_donate' : 'donate_requested')}</Button>
                        <Button className="btn-cancel" style={{marginTop: 10, width: '100%', marginRight: 0, marginBottom: 25}} onClick={e=> {
                            this.setState({donateOverlay: false, sponserCheck: false, sponser: {}})
                        }}>{t('close')}</Button>
                    </div>
                )}
                
                </div>
            </div>
        )
    }

    renderCheckoutOverlay() {
        const {redirecting} = this.state;

        if (redirecting) {
            return (
                <div className="bingo-overlay">
                    <div className="bingo-overlay-wrapper">
                        <Spinner color="grow" style={{marginBottom: 25}} />
                        <h4 style={{margin: '5px 0px', fontSize: 16, marginBottom: 5}}>{t('redirect_to_checkout')}</h4>
                    </div>
                </div>
            )
        } else {
            return <div/>
        }
    }

    next() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === this.getGallerySlides().length - 1 ? 0 : activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        const {activeIndex, animating} = this.state;
        if (animating) {
            return;
        }
        const nextIndex = activeIndex === 0 ? this.getGallerySlides().length - 1 : activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(nextIndex) {
        const {animating} = this.state;
        if (animating) {
            return;
        }
        this.setState({activeIndex: nextIndex});
    }

    getGallerySlides() {
        const {fundraise, users} = this.props;

        const slides = [];
        if (fundraise.userIds) {
            for (const item of Object.values(users)) {
                if (Object.values(fundraise.userIds).includes(item.uid)) {
                    slides.push(item);
                }
            }
            slides.sort((a, b) => {
                if (a.username === b.username) 
                    return 0;
    
                return a.username < b.username ? -1 : 1;
            });
        }
        return slides;
    }

    renderPlayers() {
        const {fundraise} = this.props;
        const {activeIndex} = this.state;

        if (fundraise.userIds) {
            const slides = this.getGallerySlides();
            
            return (
                <div>
                    <div className='items-navigation' style={{marginLeft: 0, marginRight: 0}}>
                        <div className='wrap-content-fill-child vertical-center-spacing'>
                            <div className='detail-nav-btn'>
                                <div className='items-navigation-count'><FaUser /></div>
                                <h3 className='items-navigation-title'>
                                {t('featured_players')}
                                </h3>
                                <div className='items-navigation-count'>| {slides.length} {t('total')}</div>
                            </div>
                        </div>
                    </div>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}>
                        <CarouselIndicators items={slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slides.map((item) => (
                            <CarouselItem
                                onExiting={() => this.setState({animating: true})}
                                onExited={() => this.setState({animating: false})}
                                key={item.uid} >
                                <div className="text-center" style={{width: '100%', height: 320}}>
                                    <img alt='' src={defaultBg} className="org-player-card-bg-photo" />
                                    <div className="org-player-card-bg-overlay">
                                        <CardImg className="fund-player-card-photo" src={item && item.photoUri != null ? item.photoUri : userIcon} />
                                        <h2 style={{color: 'white', fontSize: 16}}>{item.username}</h2>
                                        <div style={{color: 'white', fontSize: 12}}>{item.bio ? item.bio : t('no_bio')}</div>
                                    </div>
                                </div>
                            </CarouselItem>
                        ))}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </div>
            )
        }
        return <div />;
    }

    render() {
        const {fundraise, user} = this.props;
        const {donateOverlay} = this.state;
        
        let balance = 0;
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                balance += item.price;
            }
        }
        balance /= 100;
        const thumb = fundraise.thumbUri ? fundraise.thumbUri : defaultImage;
        const progress = balance / fundraise.donateGoal * 100;
        
        return (
            <div>
                <div className='container shadow-tile-container' style={{padding: '10px 5px'}}>
                    <Row style={{margin: 0}}>
                        <Col sm={12} lg={8}>
                            <h1 className="fund-landing-title" style={{color: "black"}}>
                                {fundraise.title}
                                <span></span>
                            </h1>
                            <img className="fund-landing-thumb-full" alt="No Thumb" src={thumb} />
                            <div className="fund-landing-text" style={{margin: '20px 0px', color: "black"}}>
                                {fundraise.description}
                                <span></span>
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className="shadow-tile-container" style={{padding: '5px 10px', backgroundColor: 'white', marginTop: '20px'}}>
                                <div className="wrap-content-parent fundraise-margin" style={{marginTop: 10}}>
                                    <h1 className="wrap-content-wrap-child" style={{color: "black", fontSize: 24, marginBottom: 0, marginLeft: 10}}>${balance} </h1>
                                    <div className="wrap-content-fill-child fund-table-info" style={{marginTop: 'auto'}}>{t('collected_of_goal')} ${fundraise.donateGoal} {t('goal')}</div>
                                </div>
                                <Progress multi style={{height: 6, marginTop: 5, marginBottom: 10}} >
                                    <Progress bar color="warning"  value={progress} />
                                </Progress>
                                <div className="wallet-transactions-list">
                                    {this.renderDonates()}
                                </div>
                            </div>
                            {this.renderPlayers()}
                        </Col>
                    </Row>
                </div>
                <div className="container">
                    <h1 className="fund-landing-title" style={{marginTop: 30, marginBottom: 10, fontSize: 24, color: "black"}}>
                        {t('games_products')}
                    </h1>
                    <div className="divider" />
                    {this.renderBingo()}
                </div>
                {user && (
                <div>
                    {this.renderBingoOverlay()}
                    {this.renderProductOverlay()}
                    {donateOverlay && this.renderDonateOverlay()}
                </div>
                )}
                {this.renderCheckoutOverlay()}
            </div>
        );
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { checkoutProgress, cart, session } = state.cartData;
    return { user, checkoutProgress, cart, session };
}

const actionCreators = {
    checkoutCartItems: cartActions.checkoutCartItems,
    addToCart: cartActions.addToCart,
    removeFromCart: cartActions.removeFromCart
};

const connected = connect(mapState, actionCreators)(FundraisePrompt);
export { connected as FundraisePrompt };
