import React, { Component } from "react";
import {
    Button, Form, Input, Spinner, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import Switch from '@mui/material/Switch';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class DivisionCong extends Component {
    constructor(props) {
        super(props);
        const division = {};
        if (props.division != null) {
            for (const [key, value] of Object.entries(props.division)) {
                division[key] = value;
            }
        }
        if (division.winPoint == null) {
            division.winPoint = 3;
        }
        if (division.tiePoint == null) {
            division.tiePoint = 1;
        }
        if (division.lossPoint == null) {
            division.lossPoint = 0;
        }
        if (division.roundResetYellow == null) {
            division.roundResetYellow = true;
        }
        if (division.yellowNumber == null) {
            division.yellowNumber = 4;
        }
        if (division.redSanctionGames == null) {
            division.redSanctionGames = 2;
        }
        
        this.state = {
            division: division,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, division} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.division !== division)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(division)) {
                challengeVal[key] = value;
            }
            this.setState({
                division: challengeVal
            })
        }
    }

    onSaveClick(division) {
        const {organization, onSaveDone} = this.props;
        const objRef = this;
        if (division.winPoint == null && division.winPoint > -1) {
            alert("You must input valid value for Points for a win");
        } else if (division.tiePoint == null && division.tiePoint > -1) {
            alert("You must input valid value for Points for a tie");
        } else if (division.lossPoint == null && division.lossPoint > -1) {
            alert("You must input valid value for Points for a loss");
        } else if (division.roundResetYellow == null) {
            alert("You must input valid value for Round Change resets yellow cards");
        } else if (division.yellowNumber == null && division.yellowNumber > -1) {
            alert("You must input valid value for Yellow Cards for a sanction");
        } else if (division.redSanctionGames == null && division.redSanctionGames > -1) {
            alert("You must input valid value for Red card Sanctioned Games");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (division.id == null || division.id === '') {
                division.id = generateId('/division');
            }
            division.owner = organization.owner;
            division.organizationId = organization.id;
            division.timestamp = dbTimestamp();
            
            this.saveChange(division, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone();
                }
                objRef.setState({
                    division: update,
                    progress: false
                });
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(division, success, failure) {
        writeDb('/division/' + division.id, division, error => {
            if (error) {
                failure(error);
            } else {
                success(division);
            }
        })
    }

    render() {
        const {onCloseClick, createMode} = this.props;
        const {division, progress} = this.state;
        
        if (division != null) {
            return (
                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <h4 className='form-edit-subtitle'>
                        Point System Configuration
                        </h4>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Points for a win
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    division.winPoint = e.target.value;
                                    this.setState({division: division});
                                }} value={division.winPoint} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Points for a tie
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    division.tiePoint = e.target.value;
                                    this.setState({division: division});
                                }} value={division.tiePoint} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Points for a loss
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    division.lossPoint = e.target.value;
                                    this.setState({division: division});
                                }} value={division.lossPoint} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <h4 className='form-edit-subtitle'>
                        Cards & Sanctions Configuration
                        </h4>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Round Change resets yellow cards
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={division.roundResetYellow}
                                onChange={e=>{
                                    division.roundResetYellow = e.target.checked;
                                    this.setState({division: division});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Yellow Cards for a sanction
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    division.yellowNumber = e.target.value;
                                    this.setState({division: division});
                                }} value={division.yellowNumber} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Red card Sanctioned Games
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    division.redSanctionGames = e.target.value;
                                    this.setState({division: division});
                                }} value={division.redSanctionGames} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <h4 className='form-edit-subtitle'>
                        Visibility
                        </h4>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Division Visibility
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={division.visibility == null || division.visibility}
                                onChange={e=>{
                                    division.visibility = e.target.checked;
                                    this.setState({division: division});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Statistics Swich
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={division.statSwitch == null || division.statSwitch}
                                onChange={e=>{
                                    division.statSwitch = e.target.checked;
                                    this.setState({division: division});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <Row style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(division);
                                }}>{t('create')}</Button>
                            </Row>
                        ) : (
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                    <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        this.onSaveClick(division);
                                    }}>{t('save')}</Button>
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={7}>
                                    <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (onCloseClick != null) {
                                            onCloseClick();
                                        }
                                    }}>{t('close')}</Button>
                                </Col>
                            </Row>
                        )}
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(DivisionCong);