import React, { Component } from "react";
import {
    CardImg, Button, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import { MdWarning, MdError, MdCheck } from 'react-icons/md';
import AttachFileView from 'components/detail/AttachFileView';
import Switch from '@mui/material/Switch';
import { toDateString } from 'utils/Utils';

import defaultImage from 'assets/img/icons/player_photo_default.png';
import { writeDb } from "utils/API";

export class RefereeFormDetail extends Component {
    constructor(props) {
        super(props);
        const refereeData = {};
        if (props.refereeData != null) {
            for (const [key, value] of Object.entries(props.refereeData)) {
                refereeData[key] = value;
            }
        }
        this.state = {
            value: 0,
            progress: false,
            refereeData: refereeData,
            windowWidth: window.innerWidth,
        };
        this.onSaveClicked = this.onSaveClicked.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {refereeData} = this.props;
        if (prevProps.refereeData !== refereeData) {
            const update = {};
            if (refereeData != null) {
                for (const [key, value] of Object.entries(refereeData)) {
                    update[key] = value;
                }
            }
            this.setState({refereeData: update});
        }
    }

    onSaveClicked() {
        const {refereeData, onSaveDone} = this.state;
        this.setState({progress: true});
        writeDb('/refereeData/' + refereeData.id, refereeData, error => {
            this.setState({progress: false});
            if (error) {
                alert("Updating Change failed\n", error);
            } else {
                if (onSaveDone != null) {
                    onSaveDone();
                }
            }
        })
    }

    getDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    render() {
        const {organization, healthDocs, users, onCloseClick} = this.props;
        const {refereeData, progress} = this.props;
        
        const user = users[refereeData.uid];
        let thumbImage = user != null && user.photoUri != null ? user.photoUri : defaultImage;
        const formSetting = organization.formSetting != null ? organization.formSetting : {};
        const healthDoc = healthDocs != null ? healthDocs[refereeData.healthDoc] : null;
        
        if (refereeData != null) {
            return (
                <div>
                    <div className='shadow-tile wrap-content-parent' style={{padding: '10px 20px'}}>
                        <CardImg
                            className="profile-thumb wrap-content-wrap-child vertical-center-spacing"
                            src={thumbImage}
                            style={{ width: 60, height: 60, marginRight: 20 }}
                            />
                        <div className="wrap-content-fill-child vertical-center-spacing">
                            <h4 className='label label-default'>
                            {refereeData.playerName} {refereeData.playerLastName}
                            </h4>
                        </div>
                        <div className="wrap-content-wrap-child vertical-center-spacing">
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    {refereeData.verified != null ? (refereeData.verified ? "Verified" : "Denied") : "Need To Check"}
                                </div>
                                <div className='player-form-value form-setting-control'>
                                    <Switch
                                        checked={refereeData.verified}
                                        onChange={e=>{
                                            refereeData.verified = e.target.checked;
                                            this.setState({refereeData: refereeData});
                                        }}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='main-page shadow-tile'>
                    {formSetting.playerName != null && formSetting.playerName.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Name:
                                </div>
                                <div className='player-form-value'>
                                    {refereeData.playerName}
                                </div>
                                {refereeData.playerName != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerName.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.playerLastName != null && formSetting.playerLastName.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Last Name:
                                </div>
                                <div className='player-form-value'>
                                    {refereeData.playerLastName}
                                </div>
                                {refereeData.playerLastName != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerLastName.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.email != null && formSetting.email.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Email:
                                </div>
                                <div className='player-form-value'>
                                    {refereeData.email}
                                </div>
                                {refereeData.email != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.email.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.birthday != null && formSetting.birthday.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Day of Birth:
                                </div>
                                <div className='player-form-value'>
                                    {toDateString(refereeData.birthday)}
                                </div>
                                {refereeData.birthday != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.birthday.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.mobile != null && formSetting.mobile.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Mobile:
                                </div>
                                <div className='player-form-value'>
                                    {refereeData.mobile}
                                </div>
                                {refereeData.mobile != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.mobile.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.healthDoc != null && formSetting.healthDoc.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Position:
                                </div>
                                <div className='player-form-value'>
                                    <AttachFileView attachFile={healthDoc} />
                                </div>
                                {healthDoc != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.healthDoc.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.playerIDNumber != null && formSetting.playerIDNumber.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    ID Number:
                                </div>
                                <div className='player-form-value'>
                                    {refereeData.playerIDNumber}
                                </div>
                                {refereeData.playerIDNumber != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerIDNumber.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    {formSetting.playerIDAttatch != null && formSetting.playerIDAttatch.switch && (
                        <div>
                            <div className='player-form-item'>
                                <div className='player-form-title'>
                                    Position:
                                </div>
                                <div className='player-form-value'>
                                    <img className='player-form-image' src={refereeData.playerIDAttatch} alt="iD" />
                                </div>
                                {refereeData.playerIDAttatch != null ? (
                                    <div className='player-form-status-filled'>
                                        <MdCheck/>
                                    </div>
                                ) : (
                                    formSetting.playerIDAttatch.option === 'Required' ? (
                                        <div className='player-form-status-error'>
                                            <MdError/>
                                        </div>
                                    ) : (
                                        <div className='player-form-status-warning'>
                                            <MdWarning/>
                                        </div>
                                    ))}
                            </div>
                            <div className='divider' style={{marginBottom: 20}} />
                        </div>
                    )}
                    
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div className='wrap-content-parent'>
                            <Button className='btn-submit' onClick={e => {
                                this.onSaveClicked();
                            }}>{t('save')}</Button>
                            <Button className='btn-cancel' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}

                    </div>
                    
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(RefereeFormDetail);