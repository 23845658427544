import React, { Component } from "react";
import {
    Button, Spinner, 
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import KnockoutGameHeader from 'components/detail/organization/games/KnockoutGameHeader';
import { Tree, TreeNode } from 'react-organizational-chart';
import { buildEmptySchedule } from 'utils/statistics';

class KnockoutDiagram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pickTeams: false,
            newImage: {},
            deployType: 'weekBased',
            imageProgress: 0,
            locationTimes: {},
            gameTree: {},
        }
    }

    componentDidMount() {
        const {season, subSeason} = this.props;
        const update = {};
        if (subSeason != null) {
            for (const [key, value] of Object.entries(subSeason)) {
                update[key] = value;
            }
        }

        const games = update.games != null ? Object.values(update.games) : [];
        
        const data = buildEmptySchedule(update, games, season);
        this.setState({
            subSeason: subSeason,
            gameTree: data.gameTree,
            rounds: data.rounds,
            games: data.games,
            pickTeams: false,
            newImage: {},
            deployType: 'weekBased',
            imageProgress: 0,
            locationTimes: {},
        })
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, season, subSeason} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            
            this.setState({
                progress: false,
                pickTeams: false,
                newImage: {},
                locationTimes: {},
                imageProgress: 0
            })
        }
        if ((prevProps.subSeason !== subSeason)) {
            const update = {};
            for (const [key, value] of Object.entries(subSeason)) {
                update[key] = value;
            }
            const games = subSeason.games != null ? Object.values(subSeason.games) : [];
            const data = buildEmptySchedule(update, games, season);
            this.setState({
                subSeason: update,
                gameTree: data.gameTree,
                rounds: data.rounds,
                games: data.games,
            })
        }
    }

    renderRoundGames() {
        const {gameTree} = this.state;
        
        if (gameTree != null ) {
            return (
                <div style={{overflowX: 'auto', padding: '20px 0px'}}>
                    <div style={{display: 'inline', margin: '0px auto'}}>
                    {this.renderKnockoutGame(gameTree)}
                    </div>
                </div>
            );
        } else {
            return <div/>;
        }
    }

    renderKnockoutGame(item) {
        const {games} = this.state;
        if (item.children != null) {
            if (item.attributes.nextId == null) {
                let items = [];
                items.push(this.renderKnockoutGame(item.children[0]));
                items.push(this.renderKnockoutGame(item.children[1]));

                let final = null;
                let final34 = null;
                for (const item of games) {
                    if (item.final && item.gameIdx === 0) {
                        final = item;
                    }
                    if (item.final && item.gameIdx === 1) {
                        final34 = item;
                    }
                }
                return (
                    <Tree 
                        lineWidth={'2px'}
                        lineColor={'green'}
                        lineBorderRadius={'10px'}
                        label={
                            <div>
                                {final != null && final34 != null ? (
                                    <div>
                                    {this.renderGameBade(final)}
                                    {this.renderGameBade(final34)}
                                    </div>
                                ) : (
                                    <div>
                                    {this.renderGameBade(item.attributes)}
                                    </div>
                                )}
                            </div>
                        }>
                        {items}
                    </Tree>
                )
            } else {
                let items = [];
                items.push(this.renderKnockoutGame(item.children[0]));
                items.push(this.renderKnockoutGame(item.children[1]));
                return (
                    <TreeNode 
                        label={
                            this.renderGameBade(item.attributes)
                        }>
                        {items}
                    </TreeNode>
                )
            }
        } else {
            return (
            <TreeNode label={
                this.renderGameBade(item.attributes)
            } />
            );
        }
    }

    renderGameBade(game) {
        const {teams, locations, users, division} = this.props;

        return (
            <KnockoutGameHeader
                game={game}
                division={division}
                teams={teams}
                users={users}
                locations={locations}
                outline={true} />
        )
    }

    render() {
        const {onCloseClick, progress} = this.props;
        const {subSeason} = this.state;

        if (subSeason != null) {
            return (
                <div className='shadow-tile' style={{padding: '30px 20px'}}>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {subSeason.title}
                        </h4>
                        <h5 className='season-round-subtitle wrap-content-wrap-child'>
                            {t('season_winners')}: {subSeason.winnerCount}
                        </h5>
                    </div>
                    {this.renderRoundGames()}
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div style={{textAlign: 'center'}}>
                        {onCloseClick && (
                            <Button className='btn-edit' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        )}
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(KnockoutDiagram));
export { connectedApp as KnockoutDiagram };
