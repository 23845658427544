import React, { Component } from "react";
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';
import {
    Button, CardImg, 
} from 'reactstrap';
import { t } from 'i18next';
import playerIcon from 'assets/img/icons/player_photo_default.png';
import { removeFromArr } from "utils/Utils";

class UsersEdit extends Component {
    constructor(props) {
        super(props)

        const usersPicked = [];
        if (props.usersPicked) {
            for (const item of props.usersPicked) {
                usersPicked.push(item);
            }
        }
        this.state = {
            users: {},
            choice: {},
            usersPicked: usersPicked,
            removeIds: [],
            birthdayCheck: false,
            page: 0,
        }
    }

    render() {
        const {onCloseClick} = this.props;
        const {usersPicked, removeIds} = this.state;

        let list = [];

        for (const item of usersPicked) {
            list.push(
                <div key={item.uid}>
                    <div className="wrap-content-parent">
                        <CardImg 
                            className='event-badge-icon' 
                            src={item.photoUri ? item.photoUri : playerIcon} />
                        <div className="event-badge-title wrap-content-fill-child">{item.username}<br/> {item.email}</div>
                        <Button className="btn-cancel" style={{margin: '5px 2px'}} onClick={e=> {
                            if (removeIds.includes(item.uid)) {
                                removeFromArr(removeIds, item.uid);
                            } else {
                                removeIds.push(item.uid);
                            }
                            this.setState({removeIds: removeIds})
                        }}>{t( !removeIds.includes(item.uid) ? 'remove' : 'add')}</Button>
                    </div>
                    <div className="divider" />
                </div>
            );
        }
        
        return (
            <div>
                <h2 className="course-title">{t('user_choice')}</h2>
                <div style={{height: 15}} />
                {list.length === 0 ? (
                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_data')}
                </div>
                ) :  (
                <div>
                    {list}
                    
                </div>
                )}
                
                <div style={{height: 20}} />
                <div className="wrap-content-parent">
                    <div className="wrap-content-fill-child" />
                    <Button className="btn-submit" onClick={e=> {
                        let update = [];

                        for (const item of usersPicked) {
                            if (!removeIds.includes(item.uid)) {
                                update.push(item);
                            }
                        }
                        if (onCloseClick)  {
                            onCloseClick(update);
                        }
                    }}>{t('ok')}</Button>
                    <Button className="btn-cancel" onClick={e=> {
                        if (onCloseClick)  {
                            onCloseClick();
                        }
                    }}>{t('close')}</Button>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = connect(mapState, actionCreators)(UsersEdit);
export { connectedApp as UsersEdit };
