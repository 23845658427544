import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner, Card, CardBody, Row, Col
} from 'reactstrap';

import AttachImageEdit from 'components/edit/AttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, invokeHttpsApi } from "utils/API";

export class SlideItemEdit extends Component {
    constructor(props) {
        super(props);
        const slideItem = {};
        if (props.slideItem != null) {
            for (const [key, value] of Object.entries(props.slideItem)) {
                slideItem[key] = value;
            }
        }

        this.state = {
            slideItem: slideItem,
            progress: false,
            landingPageData: {},
            newImage: {},
            imageProgress: 0,
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, slideItem} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        
        if ((prevProps.slideItem !== slideItem)) {
            const categoryVal = {};
            for (const [key, value] of Object.entries(slideItem)) {
                categoryVal[key] = value;
            }
            this.setState({
                slideItem: categoryVal,
            })
        }
    }

    onSaveClick(slideItem, newImage) {
        const {team, onUpdate} = this.props;
        const objRef = this;
        if (slideItem.caption == null || slideItem.caption === '') {
            alert("You must input caption");
            return;
        } else if (slideItem.src == null && newImage.name === null) {
            alert("You must input slide image");
            return;
        } else {
            this.setState({progress: true, imageProgress: 0});
            slideItem.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(team, onUpdate, slideItem, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    slideItem: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(team, onUpdate, slideItem, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(slideItem.altText, 'team-landing-video-slideItem' + team.id, newImage, (url) => {
                slideItem.src = url;
                objRef.saveChange(team, onUpdate, slideItem, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(team, onUpdate, slideItem, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    slideItem: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(team, onUpdate, slideItem, success, failure) {
        const landingData = (team && team.landingData) ? team.landingData : {};

        if (!landingData.slides) {
            landingData.slides = {};
        }
        landingData.slides[slideItem.altText] = {};
        
        for (const [key, value] of Object.entries(slideItem)) {
            landingData.slides[slideItem.altText][key] = value;
        }
        team.landingData = {};
        for (const [k,v] of Object.entries(landingData)) {
            team.landingData[k] = v;
        }

        invokeHttpsApi('clubQuery-saveClubEdit', {
            club: team
        }, (data) => {
            if (onUpdate != null) {
                onUpdate(slideItem);
            }
            success(slideItem);
        }, (error) => {
            console.log('saveContents error', error);
            failure(error);
        });
    }

    handleChange(update) {
        this.setState(update);
    }

    render() {
        const {slideItem, newImage, progress, imageProgress} = this.state;
        
        if (slideItem != null) {
            return (
                <Card style={{marginBottom: 15}}>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label for="caption">Slide Title</Label>
                                <Input type="textarea" name="text" id="title" value={slideItem.title} onChange={e => {
                                    slideItem.title = e.target.value;
                                    this.handleChange({slideItem: slideItem});
                                }} />
                            </FormGroup>
                            <Row>
                                <Col sm={4} xs={12}>
                                    <AttachImageEdit
                                        imageUrl={slideItem.src}
                                        imageClass='home-edit-thumb'
                                        label='Slide Item Image'
                                        maxHeight={150}
                                        allowProgress={progress}
                                        progress={imageProgress}
                                        onChange={(file)=> {
                                            this.handleChange({newImage: file});
                                        }} />
                                </Col>
                                <Col sm={8} xs={12}>
                                    <FormGroup>
                                        <Label for="caption">Slide Caption</Label>
                                        <Input type="textarea" name="text" id="caption" value={slideItem.caption} onChange={e => {
                                            slideItem.caption = e.target.value;
                                            this.handleChange({slideItem: slideItem});
                                        }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="btnText">Action Button Text</Label>
                                        <Input type="text" name="text" id="btnText" onChange={e => {
                                            slideItem.btnText = e.target.value;
                                            this.handleChange({slideItem: slideItem});
                                        }} value={slideItem.btnText} />
                                    </FormGroup>
                                    {slideItem.btnText && (
                                    <FormGroup>
                                        <Label for="btnLink">Action Button Link</Label>
                                        <Input type="text" name="text" id="btnLink" onChange={e => {
                                            slideItem.btnLink = e.target.value;
                                            this.handleChange({slideItem: slideItem});
                                        }} value={slideItem.btnLink} />
                                    </FormGroup>
                                    )}
                                </Col>
                            </Row>
                            {progress ? (
                                <Spinner color="primary" />
                            ) : (
                                <div style={{textAlign: 'end'}}>
                                    <Button className='btn-submit' onClick={e => {
                                        this.onSaveClick(slideItem, newImage);
                                    }}>Update</Button>
                                </div>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            );
        } else {
            return <div/>;
        }
    }
}

export default SlideItemEdit;