import React, { Component } from "react";
import {
    Button, 
} from 'reactstrap';

export const getMatchingCount = (numStr, bingoStr) => {
    var matching = 0;
    let strArr = bingoStr ? bingoStr.split(',') : [];
    const bingoArr = getBingoArr(bingoStr);
    for (let i = 0; i < 7; i ++) {
        if (strArr.length > i && parseInt(strArr[i])) {
            bingoArr.push(parseInt(strArr[i]))
        } else {
            bingoArr.push(-1);
        }
    }
    let numArr = numStr ? numStr.split(',') : [0,0,0,0,0,0,0];

    for (let i = 0; i < 7; i ++) {
        if (numArr.includes(bingoArr[i])) {
            matching ++;
        }
    }
    return matching;

}

export const getBingoArr = (bingoStr) => {
    let strArr = bingoStr ? bingoStr.split(',') : [];
    var bingoArr = [];
    for (let i = 0; i < 7; i ++) {
        if (strArr.length > i && parseInt(strArr[i])) {
            bingoArr.push(parseInt(strArr[i]))
        } else {
            bingoArr.push(-1);
        }
    }
    
    return bingoArr;

}

export default class BingoNumbers extends Component {
    
    render() {
        const {title, numStr, bingoStr, indexChoice, fundraise, bingo } = this.props;
        
        if (fundraise && bingo) {
            let numArr = bingo.numArr ? bingo.numArr.split(',') : [0,0,0,0,0,0,0];
    
            let items = [];
            for (let i = 0; i < 7; i ++) {
                var matching = 0;
                
                if (fundraise.donate) {
                    for (const item of Object.values(fundraise.donate)) {
                        if (item.bingoId === bingo.id && item.bingoStr) {
                            for (const numStr of item.bingoStr.split(',')) {
                                if (numArr.includes(parseInt(numStr))) {
                                    matching ++;
                                }
                            }
                        }
                    }
                }
                const curNum = numArr.length > i ? parseInt(numArr[i]) : 0;
                
                let className = "btn-cancel bingo-num";
                if (i === indexChoice) {
                    className = "btn-green bingo-num"
                } else if (curNum !== 0) {
                    className = "btn-submit bingo-num"
                }
                
                items.push(
                    <div className="wrap-content-fill-child text-center" style={{margin: 0}}>
                        <Button className={className} onClick={e=> {
                            if (this.props.onIndexChange) {
                                this.props.onIndexChange(i);
                            }
                        }}>
                            {curNum === 0 ? "-" : curNum}
                        </Button>
                        {curNum > 0 && (
                        <div className="text-center" style={{fontSize: 10, fontWeight: 700, marginTop: 5}}>{matching}</div>
                        )}
                    </div>
                )
            }

            return (
                <div className='wrap-content-parent' style={{padding: '10px 15px'}}>
                    {title && (
                    <div className="wrap-content-wrap-child fund-table-info" style={{margin: '8px 0px'}}>{title}</div>
                    )}
                    {items}
                </div>
            );
        } else {
            let strArr = bingoStr ? bingoStr.split(',') : [];
            var bingoArr = [];
            for (let i = 0; i < 7; i ++) {
                if (strArr.length > i && parseInt(strArr[i])) {
                    bingoArr.push(parseInt(strArr[i]))
                } else {
                    bingoArr.push(-1);
                }
            }
            let numArr = numStr ? numStr.split(',') : [0,0,0,0,0,0,0];
    
            let items = [];
            
            for (let i = 0; i < 7; i ++) {
                let className;
                if (i === indexChoice) {
                    className = "btn-green bingo-num";
                } else if (bingoArr[i] === -1 || !numArr.includes(bingoArr[i])) {
                    className = "btn-cancel bingo-num";
                } else {
                    className = "btn-submit bingo-num";
                }
                items.push(
                    <div className="wrap-content-fill-child text-center" style={{margin: 0}}>
                        <Button className={className} onClick={e=> {
                            if (this.props.onIndexChange) {
                                this.props.onIndexChange(i);
                            }
                        }}>
                            {bingoArr[i] === -1 ? "-" : bingoArr[i]}
                        </Button>
                    </div>
                )
            }
    
            return (
                <div className='wrap-content-parent' style={{padding: '10px 15px'}}>
                    {title && (
                    <div className="wrap-content-wrap-child fund-table-info" style={{margin: '8px 0px'}}>{title}</div>
                    )}
                    {items}
                </div>
            );
        }
    }
}