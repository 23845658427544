import React, { Component } from "react";
import { getFileExtension } from 'utils/FirebaseUtils';
import {
    FormGroup, FormText, Input, CardImg, Label, Progress
} from 'reactstrap';

export class AttachImageEdit extends Component {
    render() {
        const {imageUrl, imageClass, label, allowProgress, progress, onChange} = this.props;
        
        return (
            <FormGroup>
                {imageUrl != null && imageUrl !== '' && (
                <div>
                    <CardImg 
                        className={imageClass} 
                        src={imageUrl} />

                </div>
                )}
                <Label for="imageFile">{label != null ? label : 'Thumb Image'}</Label>
                <Input type="file" name="file" id="imageFile" onChange={e => {                                
                    if (onChange != null && e.target.files != null && e.target.files.length > 0) {
                        let file = e.target.files[0];
                        let extension = getFileExtension(file.name);
                        if (extension === 'png' || extension === 'jpg') {
                            onChange(file);
                        } else {
                            alert("Only jpg or png files are allowed here. This file will be ignored.");
                        }
                    }
                }} />
                <FormText color="muted">
                You can {imageUrl != null && imageUrl !== '' ? 'update the current' : 'upload a new'} image 
                </FormText>
                {allowProgress && (
                    <Progress className='progress-style-file' color="warning" value={progress} />
                )}
            </FormGroup>
        );
    }
}

export default AttachImageEdit;