import React, { Component } from 'react';
import { 
    Row,
    Col,
    CardImg
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import {KnockoutDiagram} from "components/detail/organization/games/KnockoutDiagram";
import { getSubSeasonTable } from 'utils/Utils';
import { getOrgDomain } from 'utils/Utils';

import defaultImage from 'assets/img/icons/club_icon_default.png';
import { ROUND_TYPE_GROUP, ROUND_TYPE_KNOCKOUT } from 'utils/constants';

export class SeasonStanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams: {},
            users: {},
            category: {},
            divisionChoice: {},
            detailOpen: false,
            editOpen: false,
            mainTab: 0
        };
    }
    
    renderContents() {
        const {season} = this.props;

        let orignial = Object.values(season.subSeasons != null ? season.subSeasons : {});
        let seasonArr = [];
        if (season.subSeasons && season.subSeasons.league) {
            seasonArr.push(season.subSeasons.league);
        }
        if (season.subSeasons && season.subSeasons.group) {
            seasonArr.push(season.subSeasons.group);
        }
        if (season.subSeasons && season.subSeasons.knockout) {
            seasonArr.push(season.subSeasons.knockout);
        }
        if (seasonArr.length !== orignial.length) {
            seasonArr = orignial;
        }
        let items = [];
        for (const value of seasonArr) {
            
            items.push(
                this.renderItems(value)
            );
        }
        return (
        <div>
            {items}
        </div>
        );
    }

    renderItems(subSeason) {
        const {season, smallMode, teams, division, locations, users} = this.props;

        if (subSeason.type === ROUND_TYPE_KNOCKOUT) {

            const teamsChoice = {};
            for (const[key,value] of Object.entries(teams)) {
                if (season.teamIds != null && Object.values(season.teamIds).includes(key)) {
                    teamsChoice[key] = value;
                }
            }
            return (
                <div className='shadow-tile'>
                    <Row className='row-no-margin' style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center col-no-padding">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                STANDINGS / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <KnockoutDiagram
                        season={season}
                        subSeason={subSeason}
                        teams={teamsChoice}
                        division={division}
                        locations={locations}
                        users={users} />
                </div>
            )
        } else if (subSeason.type === ROUND_TYPE_GROUP) {
            return (
                <div>{this.renderGroupsStanding(subSeason)}</div>
            )
        }
        
        let pointTable = getSubSeasonTable(division, subSeason);
        let pointItems = Object.values(pointTable);
        pointItems.sort((x,y)=>{
            const a = teams[x.id] != null ? teams[x.id] : {};
            const b = teams[y.id] != null ? teams[y.id] : {};
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        })
        pointItems.sort((a,b)=> {
            return b.points - a.points;
        })

        let items = [];
        let rank = 0;
        for (const val of pointItems) {
            rank ++;
            items.push(
                this.renderBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        if (smallMode) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <div className='shadow-tile'>
                        <Row className='row-no-margin' style={{backgroundColor: '#19447F'}}>
                            <Col xs={12} className="vertical-center col-no-padding">
                                <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                    STANDINGS / {subSeason.title}
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        <Row className='row-no-margin' style={{backgroundColor: '#85CEEB'}}>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    #
                                </h3>
                            </Col>
                            <Col lg={7} xs={7} sm={7} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Team
                                </h3>
                            </Col>
                            <Col lg={2} xs={2} sm={2} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Pts
                                </h3>
                            </Col>
                            <Col lg={2} xs={2} sm={2} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    G
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <div className='shadow-tile'>
                        <Row className='row-no-margin' style={{backgroundColor: '#19447F'}}>
                            <Col xs={12} className="vertical-center col-no-padding">
                                <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                    STANDINGS / {subSeason.title}
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        <Row className='row-no-margin' style={{backgroundColor: '#85CEEB'}}>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    #
                                </h3>
                            </Col>
                            <Col lg={5} xs={5} sm={5} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Team
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Pts
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    W
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    L
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    T
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    GD
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    GP
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }

    renderGroupsStanding(subSeason) {
        let groups = subSeason.groups != null ? Object.values(subSeason.groups) : [];
        groups.sort((a,b)=> {
            return a.index - b.index;
        })

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <div className='shadow-tile'>
                    <Row className='row-no-margin' style={{backgroundColor: '#19447F'}}>
                        <Col xs={12} className="vertical-center col-no-padding">
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                STANDINGS / {subSeason.title}
                            </h3>
                        </Col>
                    </Row>
                    <Row className='row-no-margin' style={{marginLeft: 0, marginRight: 0}}>
                        {groups.map((value, idx)=>(
                            <Col key={idx} lg={6} xs={12}>
                                {this.renderGroupStanding(subSeason, value)}
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            )
    }

    renderGroupStanding(subSeason, group) {
        const {division, smallMode, teams} = this.props;
        
        let pointTable = getSubSeasonTable(division, subSeason);
        let pointItems = [];
        for (const val of Object.values(pointTable)) {
            if (group && group.teamIds && Object.values(group.teamIds).includes(val.id)) {
                pointItems.push(val)
            }
        }
        pointItems.sort((x,y)=>{
            const a = teams[x.id] != null ? teams[x.id] : {};
            const b = teams[y.id] != null ? teams[y.id] : {};
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        })
        pointItems.sort((a,b)=> {
            return b.points - a.points;
        })

        let items = [];
        let rank = 0;
        for (const val of pointItems) {
            rank ++;
            items.push(
                this.renderBadge(val, rank)
            )
            items.push(<div className='divider' />);
        }
        if (smallMode) {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <div className='shadow-tile'>
                        <Row className='row-no-margin'>
                            <Col xs={12} className="vertical-center col-no-padding">
                                <h3 className='stat-table-title'>
                                    Group {(group.index + 1).toString()}
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        <Row  className='row-no-margin'style={{backgroundColor: '#85CEEB'}}>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    #
                                </h3>
                            </Col>
                            <Col lg={7} xs={7} sm={7} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Team
                                </h3>
                            </Col>
                            <Col lg={2} xs={2} sm={2} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Pts
                                </h3>
                            </Col>
                            <Col lg={2} xs={2} sm={2} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    G
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{marginTop: 30, marginBottom: 30}}>
                    <div className='shadow-tile'>
                        <Row className='row-no-margin'>
                            <Col xs={12} className="vertical-center col-no-padding">
                                <h3 className='stat-table-title'>
                                    Group {(group.index + 1).toString()}
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        <Row className='row-no-margin' style={{backgroundColor: '#85CEEB'}}>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    #
                                </h3>
                            </Col>
                            <Col lg={5} xs={5} sm={5} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Team
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    Pts
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    W
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    L
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    T
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    GD
                                </h3>
                            </Col>
                            <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                                <h3 className='row-table-title'>
                                    GP
                                </h3>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            {t('no_data')}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
    }

    renderBadge(item, rank) {
        const {organization, smallMode, teams} = this.props;
        const team = teams[item.id] != null ? teams[item.id] : {};
        let thumbImage = team.iconUri != null ? team.iconUri : defaultImage;

        const link = `${getOrgDomain(organization)}/teams/${item.id}`;

        if (smallMode) {
            return (
                <Row className='row-no-margin'>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {rank}
                            </div>
                        </div>
                    </Col>
                    <Col lg={7} xs={7} sm={7} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <a className='wrap-content-fill-child row-badge-title' href={link}>
                            {team.title}
                            </a>
                        </div>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.points}
                            </div>
                        </div>
                    </Col>
                    <Col lg={2} xs={2} sm={2} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.scores}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className='row-no-margin'>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {rank}
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} xs={5} sm={5} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <CardImg
                                className="wrap-content-wrap-child row-badge-icon"
                                src={thumbImage} />
                            <a className='wrap-content-fill-child row-badge-title' href={link}>
                            {team.title}
                            </a>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.points}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.win}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.loss}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.draw}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.scoreDiff}
                            </div>
                        </div>
                    </Col>
                    <Col lg={1} xs={1} sm={1} className="vertical-center col-no-padding">
                        <div className='wrap-content-parent'>
                            <div className="wrap-content-fill-child row-badge-info" style={{marginRight: 5}}>
                            {item.gamePlayed}
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        }
    }

    render() {
        const {hideTitle, season} = this.props;
        
        return (
            <div>
            {!hideTitle && (
                <h3 style={{marginTop: 30}}>{season.title} Standings</h3>
            )}
                {this.renderContents()}
            </div>
        );
    }
}

export default withTranslation()(SeasonStanding)
