import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/_actions';

import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import { 
    GiSoccerKick
} from 'react-icons/gi';
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import { 
    Button, Form, Col, Row, Input, Modal, ModalBody, Spinner
} from 'reactstrap';
import { t } from 'i18next';
import HorizontalAvatarList from 'components/members/HorizontalAvatarList';
import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    toSimpleDateTimeString,
    renderHtmlString,
} from 'utils/Utils';

import MembersView from 'components/members/MembersView';
import { UserSearch } from 'components/detail/user/UserSearch';
import { MdAdd } from 'react-icons/md';
import { UsersEdit } from 'components/detail/user/UsersEdit';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';
import CustomRichTextEditor from 'components/template/CustomRichTextEditor';


class MailBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizations: {},
            organization: {},
            divisions: {},
            refereeIds: [],
            managerIds: [],
            seasons: {},
            users: {},
            category: {},
            profileData: {},
            teams: {},
            locations: {},
            detailOpen: false,
            editOpen: false,
            editOrganization: false,
            loading: true,
            email: {
                playerIds: [],
                refereeIds: [],
            },
        };
    }

    componentDidMount() {
        this.loadContents();
    }

    loadContents() {
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizerMailboxQuery', {}, (data) => {
            const organization = {};
            const divisions = {};
            const refereeIds = [];
            const managerIds = [];
            const seasons = {};
            const profileData = {};
            const orgEmails = {};
            const users = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organization)) {
                    organization[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.orgEmails)) {
                    orgEmails[key] = value;
                }
                for (const value of data.refereeIds) {
                    refereeIds.push(value);
                }
                for (const value of data.managerIds) {
                    managerIds.push(value);
                }
            }

            this.setState({
                organization: organization,
                divisions: divisions,
                refereeIds: refereeIds,
                managerIds: managerIds,
                seasons: seasons,
                profileData: profileData,
                orgEmails: orgEmails,
                users: users,
                loading: false,
                email: {
                    playerIds: [],
                    refereeIds: [],
                },
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    sendEmails() {
        const {organization, users, email, usersPicked} = this.state;

        const usersChoice = {};
        const uidList = {};
        if (usersPicked) {
            for (const user of usersPicked) {
                usersChoice[user.uid] = user;
                uidList[user.uid] = user.uid;
            }
        }
        for (const uid of email.playerIds) {
            if (users[uid]) {
                usersChoice[uid] = users[uid].email;
                uidList[uid] = uid;
            }
        }
        for (const uid of email.refereeIds) {
            if (users[uid]) {
                usersChoice[uid] = users[uid].email;
                uidList[uid] = uid;
            }
        }
        
        if (Object.values(usersChoice).length <= 0) {
            alert("You should Pick at least 1 user")
            return;
        }
        
        if (!email.title || email.title === "") {
            alert("You should input email title")
            return;
        }
        
        if (!email.text || email.text === "") {
            alert("You should input email text")
            return;
        }
        
        const data = {
            organizationId: organization.id, 
            uidList: uidList, 
            emailList: usersChoice, 
            title: email.title,
            text: email.text,
            htmlText: email.body,
            replyTo: email.replyTo
        };

        this.setState({progress: true});
        invokeHttpsApi('invite-sendOrganizationEmails', data, (data) => {
            alert("Your emails sent successfully");
            if (data && data.success) {
                this.loadContents();
            }
            this.setState({progress: false})
        })
        .catch((error) => {
            console.log('loadContents error', error);
            alert("Your emails did not go successfully\n" + error)
            this.setState({progress: false})
        });
    }

    renderCompose() {
        const { user, organizationSubscription, freeOrganization } = this.props;
        const { organization, profileData, users, email, detailChoice, usersChoice, editOpen, usersOpen, removeOpen, usersPicked, progress } = this.state;
        
        const playerList = {};
        const playersAdded = {};
        if (profileData) {
            for (const item of Object.values(profileData)) {
                if (item.organizationId === organization.id && !Object.values(playerList).includes(item.uid)) {
                    playerList[item.uid] = item.uid;
                    if (email.playerIds.includes(item.uid) && !Object.values(playersAdded).includes(item.uid)) {
                        playersAdded[item.uid] = item.uid;
                    }
                }
            }
        }
        
        const refereeAdded = {};
        for (const item of email.refereeIds) {
            refereeAdded[item] = item;
        }
        
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        const avatars = [];

        if (usersPicked) {
            for (const value of usersPicked){
                avatars.push(
                    {avatar: value.photoUri, name: value.username}
                )
            }
        }

    
        if (user == null) {
            return <div/>
        }
        
        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title="Users To Send"
                        icon={<GiSoccerKick/>}
                        detailOpen={detailChoice != null}
                        editOpen={editOpen}
                        totalCount={avatars.length + email.playerIds.length + email.refereeIds.length}
                        detailItem={usersChoice}
                        style={{margin: 0}}
                        onClickEdit={()=>{
                            this.setState({usersChoice: {}, editOpen: true});
                        }}
                        onClickBack={()=>{
                            this.setState({usersChoice: {}, editOpen: false})
                        }} />
                </div>

                {!editOpen && (
                    <div>
                        <div style={{height: 10}}/>
                        <Input 
                            style={{marginTop: 20}}
                            type="text" 
                            name="search" 
                            id="search" 
                            placeholder='Input Title ...' 
                            onChange={e => {
                                email.title = e.target.value;
                                this.setState({email: email});
                            }} value={email.title} />

                        <div style={{height: 10}}/>
                        <Input 
                            style={{marginTop: 20}}
                            type="text" 
                            name="replyTo" 
                            id="replyTo" 
                            placeholder='Reply to ...' 
                            onChange={e => {
                                email.replyTo = e.target.value;
                                this.setState({email: email});
                            }} value={email.replyTo} />

                        <div style={{height: 10}}/>
                        
                        <CustomRichTextEditor
                            placeholderText='Edit Your Content Here!'
                            placeholder="Type your email body ... "
                            value={email.body}
                            onChange={(update, text)=> {
                                email.body = update
                                email.text = text
                                this.setState({email: email, richEditValue: update})
                            }} />
                        <div style={{height: 15}} />
                        {progress ? (
                        <Spinner />
                        ) : (
                        <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                            this.sendEmails();
                        }}>Send Emails</Button>
                        )}
                    </div>
                )}

                {editOpen && (
                    <Form className='form-edit'>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            Users To Pick
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('users')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                            {avatars.length > 0 && (
                                <HorizontalAvatarList
                                    avatars={avatars}
                                    avatarProps={{ size: 50 }}
                                    />
                            )}
                                <div>
                                    <Button
                                        className='btn-submit'
                                        onClick={(e)=> {
                                            this.setState({usersOpen: true});
                                        }}>{t('add')}</Button>
                                    <Button
                                        className='btn-submit'
                                        onClick={(e)=> {
                                            this.setState({removeOpen: true});
                                        }}>{t('remove')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            Organization Players
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                            <MembersView
                                available={playerList}
                                members={playersAdded}
                                users={users}
                                onSaveClick={(members)=> {
                                    email.playerIds = [];
                                    if (members != null) {
                                        for (const key of Object.values(members)) {
                                            if (!email.playerIds.includes(key)) {
                                                email.playerIds.push(key)
                                            }
                                        }
                                    }
                                    this.setState({email: email});
                                }} />
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            Organization Refreees
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                            <MembersView
                                available={organization.refereeIds}
                                members={refereeAdded}
                                users={users}
                                onSaveClick={(members)=> {
                                    email.refereeIds = [];
                                    if (members != null) {
                                        for (const key of Object.values(members)) {
                                            if (!email.refereeIds.includes(key)) {
                                                email.refereeIds.push(key)
                                            }
                                        }
                                    }
                                    this.setState({email: email});
                                }} />
                            </Col>
                        </Row>
                    </Form>
                )}
                {usersOpen && (
                <Modal isOpen={true}>
                    <ModalBody>
                        <UserSearch
                            onCloseClick={(uids, users) => {
                                if (uids) {
                                    let update = [];
                                    for (const uid of uids) {
                                        if (users[uid]) {
                                            update.push(users[uid]);
                                        }
                                    }
                                    this.setState({usersPicked: update})
                                }
                                this.setState({usersOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                )}
                {removeOpen && (
                <Modal isOpen={true}>
                    <ModalBody>
                        <UsersEdit
                            usersPicked={usersPicked}
                            onCloseClick={(update) => {
                                if (update) {
                                    this.setState({usersPicked: update})
                                }
                                this.setState({removeOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                )}
            </div>
        );
    }

    renderDetails() {
        const {detailChoice, users} = this.state;

        const avatars = [];

        if (detailChoice.uidList) {
            for (const uid of Object.values(detailChoice.uidList)){
                if (users[uid]) {
                    avatars.push(
                        {avatar: users[uid].photoUri, name: users[uid].username}
                    )
                }
            }
        }
        

        const emailList = [];

        if (detailChoice.emailList) {
            for (const user of Object.values(detailChoice.emailList)){
                emailList.push(
                    <div className='post-item-date'>{user.email}</div>
                )
            }
        }
        
        return (
            <div className='mail-box-detail'>
                <h2 className="post-detail-title">
                {detailChoice.title}
                </h2>
                <HorizontalAvatarList
                    avatars={avatars}
                    avatarProps={{ size: 50 }}
                    />
                {emailList}
                <div className='post-item-info'>
                    <h4 className='post-item-username'>
                    Reply to 
                    </h4>
                    <div className='post-item-date'>
                    {detailChoice.replyTo}
                    </div>
                </div>
                
                <div style={{marginTop: 20}}/>
                <div className='app'>
                    {renderHtmlString(detailChoice.htmlText ? detailChoice.htmlText : "")}
                </div>
                <div style={{height: 50}}/>
            </div>
        )
    }
    
    render() {
        const {loading, orgEmails, detailChoice} = this.state;

        let emails = [];
        if (orgEmails) {
            for (const v of Object.values(orgEmails)) {
                emails.push(v);
            }
        }

        emails.sort((a, b) => {
            return a.timestamp - b.timestamp;
        })

        let items = [];
        for (const item of emails) {
            items.push(
                <div className={detailChoice && detailChoice.id === item.id ? "mail-menu-item-selected" : "mail-menu-item"}
                    onClick={e=> {
                        this.setState({detailChoice: item});
                    }}>
                    {item.title}<br/>
                    {toSimpleDateTimeString(item.timestamp)}
                </div>
            )
        }


        return (
            <div>                
                <div className='wrap-content-parent'>
                    <div className='mail-menu'>
                        <div className={!detailChoice ? "mail-menu-item-selected" : "mail-menu-item"}
                            onClick={e=> {
                                this.setState({detailChoice: null});
                            }}>
                            <MdAdd /> {t('compose_email')}
                        </div>
                        {loading ? (
                            <div className='text-center' style={{margin: '20px 0px'}}>
                                <Spinner />
                            </div>
                        ) : items}
                    </div>
                    <div className='wrap-content-fill-child'>
                        {detailChoice ? this.renderDetails() : this.renderCompose()}
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(MailBox));
export { connectedApp as MailBox };
