import React, { Component } from "react";
import {
    Button, Spinner, ListGroupItem, 
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { dataActions } from 'redux/_actions';
import { MdEdit, MdClose } from 'react-icons/md';
import SubSeasonTeams from 'components/edit/organization/SubSeasonTeams';
import GameScheduleBadge from 'components/edit/organization/GameScheduleBadge';
import GroupDeploy from 'components/edit/organization/GroupDeploy';

import { shuffle, checkMatches } from 'utils/Utils';
import { dbTimestamp, generateId, invokeHttpsApi } from "utils/API";
import { STATUS_FINISHED } from "utils/constants";

class GroupRoundEdit extends Component {
    constructor(props) {
        super(props);
        const subSeason = {};
        if (props.subSeason != null) {
            for (const [key, value] of Object.entries(props.subSeason)) {
                subSeason[key] = value;
            }
        }

        const games = subSeason.games != null ? Object.values(subSeason.games) : [];
        const shouldOpen = !props.season || !props.season.teamIds || Object.values(props.season.teamIds) === 0;
        
        this.state = {
            subSeason: subSeason,
            groupTeams: this.reinitTeamArray(subSeason),
            pickTeams: shouldOpen,
            newImage: {},
            deployType: 'weekBased',
            games: games,
            imageProgress: 0,
            locationTimes: {},
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onGroupUpdate = this.onGroupUpdate.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {season, isOpen, subSeason} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            const shouldOpen = !season || !season.teamIds || Object.values(season.teamIds) === 0;
            
            this.setState({
                pickTeams: shouldOpen,
                newImage: {},
                locationTimes: {},
                imageProgress: 0
            })
        }
        if ((prevProps.subSeason !== subSeason)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(subSeason)) {
                challengeVal[key] = value;
            }
            const games = subSeason.games != null ? Object.values(subSeason.games) : [];
            
            this.setState({
                subSeason: challengeVal,
                games: games,
            })
        }
    }

    onSaveClick(subSeason) {
        const {season, onSaveDone} = this.props;
        const {games} = this.state;
        if (subSeason.title == null || subSeason.title === '') {
            alert("You must input title");
        } else if (subSeason.winnerCount == null || subSeason.winnerCount === 0) {
            alert("You must input winner count");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (subSeason.id == null || subSeason.id === '') {
                subSeason.id = generateId('/season/' + season.id + '/subSeasons');
            }
            subSeason.owner = season.owner;
            subSeason.organizationId = season.organizationId;
            subSeason.divisionId = season.divisionId;
            subSeason.seasonId = season.id;
            
            subSeason.timestamp = dbTimestamp();
            let maxMatchDay = 0;

            if (games != null && games.length > 0) {
                subSeason.games = {};
                for (const game of games) {
                    game.id = generateId('/season/' + season.id + '/subSeasons/' + subSeason.id + '/games');
                    subSeason.games[game.id] = game;
                    if (game.matchDayIndex != null && game.matchDayIndex > maxMatchDay) {
                        maxMatchDay = game.matchDayIndex;
                    }
                }
            }
            subSeason.matchDayCount = maxMatchDay !== 0 ? maxMatchDay + 1 : 0;
            for (const [key,] of Object.entries(subSeason.groups)) {
                subSeason.groups[key].gameIds = {};
                for (const game of games) {
                    if (game.groupId === key) {
                        subSeason.groups[key].gameIds[game.id] = game.id;
                    }
                }
            }
            
            const data = {subSeason: subSeason}
            invokeHttpsApi('clubQuery-saveSubSeason', data, (data) => {
                if (data.success) {
                    alert(t('saved_successfully'))
                } else {
                    alert(t('save_failed'));
                }
                if (onSaveDone) {
                    onSaveDone(subSeason);
                }
                this.setState({progress: false});
            }, (error) => {
                console.log('save error', error);
                alert(t('save_failed') + '\n' + error);
                this.setState({progress: false});
            });
        }
    }

    onGroupUpdate(subSeason, update) {

        subSeason.groups = {};
        for (const item of update) {
            subSeason.groups[item.id] = {
                id: item.id,
                index: item.index,
                teamIds: {},
            }
            if (item.teams != null) {
                for (const team of item.teams) {
                    subSeason.groups[item.id].teamIds[team.id] = team.id;
                }
            }
        }
        this.setState({subSeason: subSeason, groupTeams: update});
    }

    renderLeagueGames() {
        const {season, division, locations, organization} = this.props;
        const {subSeason, games, groupTeams} = this.state;
        if (games != null && games.length > 0) {
            return (
                <div style={{marginTop: 10, marginBottom: 20}} >
                    <h5 className='season-round-subtitle wrap-content-fill-child' style={{marginTop: 10, marginBottom: 10}}>
                    {t('round_games')}: {games.length} (Locations : {Object.values(locations).length})
                    </h5>
                    <div className='divider' />
                    {this.renderGroups()}
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}} />
                    <Button className='btn-cancel' onClick={ e => {
                        this.setState({games: []});
                    }}>{t('clear_redeploy')}</Button>
                </div>
            );
        } else {
            return (
                <div>
                    <GroupDeploy
                        organization={organization}
                        subSeason={subSeason}
                        season={season}
                        groupTeams={groupTeams}
                        division={division}
                        locations={locations}
                        onGroupEdit={(update)=>{
                            this.onGroupUpdate(subSeason, update);
                        }}
                        onCompleteDeploy={(games)=>{
                            this.setState({games: games});
                        }} />
                </div>
            )
        }
    }

    renderGroups() {
        const {subSeason} = this.state;
        let groups = subSeason.groups != null ? Object.values(subSeason.groups) : [];
        groups.sort((a,b)=> {
            return a.index - b.index;
        })
        
        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            {groups.map((value, idx)=>(
                <div key={idx}>
                    {this.renderGroupGames(value)}
                </div>
            ))}
        </div>
        )
    }

    renderGroupGames(group) {
        const {games} = this.state;

        let groupGames = [];
        for (const item of games) {
            if (item.groupId === group.id) {
                groupGames.push(item);
            }
        }
        
        return (
        <div style={{marginTop: 20, marginBottom: 30}}>
            <h5 className='season-round-subtitle'>Group {(group.index + 1).toString()}</h5>
            {groupGames.map((value, idx)=>(
                <ListGroupItem key={idx}>
                    {this.renderGameSchedule(group, value, idx)}
                </ListGroupItem>
            ))}
        </div>
        )
    }

    renderGameSchedule(group, value, idx) {
        const {locations, teams, organization, division, season, users} = this.props;
        const {subSeason, games} = this.state;
        
        return (
            <GameScheduleBadge
                season={season}
                subSeason={subSeason}
                game={value}
                teams={teams}
                organization={organization}
                division={division}
                group={group}
                locations={locations}
                users={users}
                onHomeUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.homeTeam = update;
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}
                onAwayUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.awayTeam = update;
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}
                gameTimeUpdate={(start, end)=>{
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    if (start != null) {
                        value.start = start;
                    }
                    if (end != null) {
                        value.end = end;
                    }
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}
                locationUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.locationId = update;
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}
                dayUpdate={(update)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.matchDayIndex = update;
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}
                refereeUpdate={(update, assists)=> {
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    value.refereeId = update;
                    value.referees = assists;
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}
                removeClicked={()=> {
                    
                    if (value.status != null && value.status === STATUS_FINISHED) {
                        alert(t('game_change_not_allowed'))
                        return;
                    }
                    games.splice(idx, 1);
                    checkMatches(games, subSeason.doubleAllow);
                    this.setState({games: games});
                }}/>
        )
    }

    renderTeams() {
        const {teams} = this.props;
        const {subSeason, pickTeams} = this.state;

        const teamCount = subSeason.teamIds != null ? Object.values(subSeason.teamIds).length : 0;
        const totalCount = teams != null ? Object.values(teams).length : 0;

        if (pickTeams) {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 30, marginBottom: 30}}>
                        <h5 className='season-round-subtitle wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {t('teams')}: {teamCount} {t('picked')}, {totalCount} {t('total')}
                        </h5>
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                this.setState({pickTeams: false})
                            }}><MdClose/></Button>
                        </div>
                    </div>
                    <div className='divider' />
                    <SubSeasonTeams
                        teams={teams}
                        subSeason={subSeason}
                        onAdded={(teamId)=> {
                            if (subSeason.teamIds == null) {
                                subSeason.teamIds = {};
                            }
                            subSeason.teamIds[teamId] = teamId;
                            this.onGroupUpdate(subSeason, this.reinitTeamArray(subSeason));
                        }}
                        onRemoved={(teamId)=> {
                            if (subSeason.teamIds == null) {
                                subSeason.teamIds = {};
                            }
                            delete subSeason.teamIds[teamId]
                            this.onGroupUpdate(subSeason, this.reinitTeamArray(subSeason));
                        }} />
                    <div className='divider' style={{marginBottom: 20}} />
                </div>
            );
        } else {
            return (
                <div>
                    <div className='wrap-content-parent' style={{marginTop: 20, marginBottom: 5}}>
                        <h5 className='season-round-subtitle wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {t('teams')}: {teamCount} {t('picked')}, {totalCount} {t('total')}
                        </h5>
                        <div className='wrap-content-wrap-child'>
                            <Button className="btn-edit" onClick={e=> {
                                this.setState({pickTeams: true})
                            }}><MdEdit/></Button>
                        </div>
                    </div>
                    <div className='divider' style={{marginBottom: 20}} />
                </div>
            )
        }
    }

    reinitTeamArray(subSeason) {
        const {season, teams} = this.props;

        const groupCount = season.groupCount != null ? season.groupCount : 1;
        let teamIds = subSeason.teamIds != null ? Object.values(subSeason.teamIds) : [];
        
        teamIds = shuffle(teamIds);
        teamIds = shuffle(teamIds);
        teamIds = shuffle(teamIds);

        let groupTeams = [];
        let count = parseInt(Math.round(teamIds.length / groupCount));
        let items = [];
        for (var i = 0; i < teamIds.length; i ++) {
            if (i % count === 0) {
                items = [];
                groupTeams.push({
                    id: 'group-' + parseInt(i / groupCount).toString(),
                    index: parseInt(i / groupCount),
                    teams: items
                });
            }
            let item = teams[teamIds[i]]
            items.push(item)
        }

        return groupTeams;
    }

    render() {
        const {onCloseClick, createMode} = this.props;
        const {subSeason, progress} = this.state;

        if (subSeason != null) {
            return (
                <div className='shadow-tile' style={{padding: '30px 20px'}}>
                    <div className='wrap-content-parent' style={{marginBottom: 30}}>
                        <h4 className='wrap-content-fill-child' style={{marginTop: 5, marginBottom: 0}}>
                        {subSeason.title}
                        </h4>
                        <h5 className='season-round-subtitle wrap-content-wrap-child'>
                            {t('season_winners')}: {subSeason.winnerCount}
                        </h5>
                    </div>
                    {this.renderTeams()}
                    {this.renderLeagueGames()}
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <div>
                        {createMode ? (
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-edit' onClick={e => {
                                    this.onSaveClick(subSeason);
                                }}>{t('create')}</Button>
                            </div>
                        ) : (
                            <div style={{textAlign: 'center'}}>
                            <Button className='btn-edit' onClick={e => {
                                this.onSaveClick(subSeason);
                            }}>{t('save')}</Button>
                            {onCloseClick && (
                                <Button className='btn-edit' onClick={e => {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                }}>{t('close')}</Button>
                            )}
                            </div>
                        )}
                        </div>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { progress, imageProgress, data, videoProgress, error } = state.dataCollection;
    return { progress, imageProgress, data, videoProgress, error };
}

const actionCreators = {
    saveChangeWithImage: dataActions.saveChangeWithImage,
    saveChange: dataActions.saveChange
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(GroupRoundEdit));
export { connectedApp as GroupRoundEdit };
