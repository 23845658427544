import React, { Component } from 'react';
import LoadingView from 'components/detail/loading/LoadingView';
import { connect } from 'react-redux';
import TableRowSmall from 'components/detail/header/TableRowSmall';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import OrganizationPaymentBadge from 'components/detail/organization/OrganizationPaymentBadge';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import WalletDetail from 'components/wallet/WalletDetail';
import FundraiseEdit from 'components/fundraise/FundraiseEdit';
import FundraiserDetails from 'components/fundraise/FundraiserDetails';
import userIcon from 'assets/img/icons/player_photo_default.png';
import appIcon from 'assets/img/logo/live_192.png';
import { toDateTimeString } from 'utils/Utils';
import { userActions } from 'redux/_actions';
import {
    RiMoneyDollarBoxFill,
} from 'react-icons/ri';
import userImage from 'assets/img/icons/player_photo_default.png';
import fundIcon from 'assets/img/icons/fundraise.png';

import { 
    Row,
    Col,
    Input,
    Button,
    Spinner
} from 'reactstrap';

import { t } from 'i18next';
import { 
    FaWpforms,
} from 'react-icons/fa';
import { 
    FcMoneyTransfer,
    FcDonate,
} from 'react-icons/fc';
import { generateId, invokeHttpsApi } from 'utils/API';

import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
} from 'utils/Utils';
import { withRouter } from 'utils/Routher';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';

class FinancePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            organization: {},
            income: {},
            seasonPayments: {},
            users: {},
            customers: {},
            wallets: {},
            walletPayments: {},
            stripeUser: {},
            fundraises: {},
            loading: true,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        this.loadTransactions();
    }

    loadTransactions() {
        const { user } = this.props;
        
        const data = {}
        if (user && user.stripeAccountId) {
            data.stripeAccountId = user.stripeAccountId
        }
        
        this.setState({loading: true});

        invokeHttpsApi('purchase-queryOrganizationTransactions', data, 
        ((data)=> {
            const income = {};
            const customers = {};
            const seasonPayments = {};
            const users = {};
            const organization = {};
            const wallets = {};
            const walletPayments = {};
            const fundraisePayments = {};
            const stripeUser = {};
            const fundraises = {};

            if (data && data.success) {
                if (data.income) {
                    for (const [k,v] of Object.entries(data.income)) {
                        income[k] = v;
                    }
                }
                if (data.seasonPayments) {
                    for (const [k,v] of Object.entries(data.seasonPayments)) {
                        seasonPayments[k] = v;
                    }
                }
                if (data.customers) {
                    for (const [k,v] of Object.entries(data.customers)) {
                        customers[k] = v;
                    }
                }
                if (data.users) {
                    for (const [key, value] of Object.entries(data.users)) {
                        users[key] = value;
                    }
                }
                if (data.organization) {
                    for (const [key, value] of Object.entries(data.organization)) {
                        organization[key] = value;
                    }
                }
                if (data.wallets) {
                    for (const [key, value] of Object.entries(data.wallets)) {
                        wallets[key] = value;
                    }
                }
                if (data.walletPayments) {
                    for (const [key, value] of Object.entries(data.walletPayments)) {
                        walletPayments[key] = value;
                    }
                }
                if (data.fundraisePayments) {
                    for (const [key, value] of Object.entries(data.fundraisePayments)) {
                        fundraisePayments[key] = value;
                    }
                }
                if (data.stripeUser) {
                    for (const [key, value] of Object.entries(data.stripeUser)) {
                        stripeUser[key] = value;
                    }
                }
                if (data.fundraises) {
                    for (const [key, value] of Object.entries(data.fundraises)) {
                        fundraises[key] = value;
                    }
                }
            }
            this.setState({
                income: income, 
                customers: customers, 
                seasonPayments: seasonPayments, 
                users: users, 
                organization: organization,
                wallets: wallets,
                walletPayments: walletPayments,
                fundraisePayments: fundraisePayments,
                stripeUser: stripeUser,
                fundraises: fundraises,
                loading: false
            });
        }), ((error)=> {
            alert(t('request_failed') + '\n' + error);
            this.setState({loading: false});
        }));
    }

    renderFilter() {
        const {searchStr} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Input type="text" name="search" id="search" placeholder='Search by Location Title ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>                        
            </div>
        )
    }

    renderPaymentSettings() {
        const {user} = this.props;
        const {organization, stripeUser} = this.state;

        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }
        
        return (
        <div style={{marginTop: 30, marginBottom: 30}}>
        
            <OrganizationPaymentBadge
                organization={organization}
                user={user}
                stripeUser={stripeUser}
                onChanged={(update)=> {
                    this.setState({organization: update});
                }} />
            {this.renderTransactions()}
        </div>
        );
    }

    renderTransactions() {
        const {income} = this.state;

        const incomeArr = [];
        if (income) {
            for (const item of Object.values(income)) {
                incomeArr.push(item);
            }
        }
        incomeArr.sort((a, b) => {
            return a.created - b.created;
        })

        const items = [];
        for (const item of incomeArr) {
            items.push(<div>{this.renderTransactionItem(item)}</div>)
        }

        return (
            <div>
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><FaWpforms/></div>
                            <h3 className='items-navigation-title'>
                            Transactions
                            </h3>
                            <div className='items-navigation-count'>| Organization Payment Method</div>
                        </div>
                    </div>
                </div>
                <div className='shadow-tile-table'>
                    <Row style={{margin: 0}}>
                        <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                {t('user')}
                            </h3>
                        </Col>
                        <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Payment
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                Date
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {income ? t('no_items_to_show') : "Not Loaded Payments"}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    renderTransactionItem(transaction) {
        const {seasons, divisions, seasonPayments, users} = this.state;

        let user = {};
        let season = {};
        let division = {};
        let seasonPayment = {};
        for (const item of Object.values(seasonPayments)) {
            if (item.id === transaction.id) {
                seasonPayment = item;
                season = seasons[item.seasonId];
                if (season) {
                    division = divisions[season.divisionId];
                }
                user = users[item.userId];
                break;
            }
        }
        const userThumb = user.photoUri ? user.photoUri : userIcon;
        const seasonThumb = division.thumbUri ? division.thumbUri : appIcon;
        const seasonTitle = season.title + " - " + division.title;

        const subView = (
            <div className='wrap-content-parent'>
                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                <h4 className='label label-default one-line-title'>
                {seasonPayment.role ? seasonPayment.role.toUpperCase() : ""}
                </h4>
                </div>
                <img className="wrap-content-wrap-child" alt='season-icon' src={seasonThumb} style={{width: 20, height: 20, borderRadius: 10, marginRight: 5}}/>
                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                <div className='label label-default one-line-title'>
                {seasonTitle}
                </div>
                </div>
            </div>
        )
        const subView2 = (
            <div className='wrap-content-parent'>
                <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                <div className='label label-default one-line-title'>
                {toDateTimeString(transaction.created * 1000)}
                </div>
                </div>
            </div>
        )
        return (
            <TableRowSmall 
                key={transaction.id}
                title={user.username + '$' + (transaction.amount / 100) + " / App Fee: $" + (transaction.application_fee_amount / 100)}
                icon={userThumb}
                subView={subView}
                subView2={subView2} />
        );
    }

    submitPaymentAction(item, accept) {
        
        this.setState({checkoutProgress: true});
        invokeHttpsApi(accept ? 'purchase-acceptWalletPending' : 'purchase-rejectWalletPending', {
            walletPayment: item
        }, (async (result) => {
            const data = result.data;

            if (data.success) {
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
                this.loadTransactions();
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }), (error) => {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        });
    }

    createWallet(user) {
        const { organization, wallets } = this.state;

        for (const v of Object.values(wallets)) {
            if (user.parentId && user.uid === v.parentId) {
                alert(t('existing_wallet_parent') + v.username);
                return;
            }
            if (user.owner && user.uid === v.owner) {
                alert(t('existing_wallet_parent') + v.username);
                return;
            }
        }

        let uid = user.uid;
        if (user.parentId) {
            uid = user.uid;
        }
        if (user.owner) {
            uid = user.owner;
        }
        const wallet = {
            id: generateId("/wallet"),
            organizationId: organization.id,
            balance: 0,
            uid: uid
        };
        
        this.setState({checkoutProgress: true});
        invokeHttpsApi('purchase-submitWallet', {
            wallet: wallet
        }, 
        ((data)=> {
            if (data && data.success) {
                this.setState({
                    checkoutProgress: false,
                    progress: false,
                });
                this.loadTransactions();
            } else {
                alert(t('request_failed'));
                this.setState({progress: false, checkoutProgress: false});
            }
        }),
        ((error)=> {
            console.log('Request error', error);
            alert(t('request_failed') + '\n' + error);
            this.setState({progress: false, checkoutProgress: false});
        }))
    }

    renderWalletItem(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.price) / 100);
        const subtitle = "+";

        let user = item.uid && users[item.uid] ? item.uid && users[item.uid] : {};
        let userIcon = user.photoUri ? user.photoUri : userImage;

        return (
            <Row style={{margin: 0}}>
                <Col lg={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {toDateTimeString(item.timestamp)}
                    </h4>
                </Col>
                <Col sm={2} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {subtitle} {price}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <img src={userIcon} className="wrap-content-wrap-child" style={{width: 24, height: 24, margin: 'auto 0px', borderRadius: 12, backgroundColor: 'white'}} alt="icon" />
                        
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col sm={4} className="vertical-center" style={{padding: 0}}>
                    {item.success ? (
                        <div className='wrap-content-parent'>
                            <h4 className='wallet-table-info wrap-content-fill-child'>
                            {item.type === 'direct' ? "Paid Online" : "Paid In Person"}
                            </h4>
                            {item.type !== 'direct' && (
                            <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                if (window.confirm("Are you sure you want to remove this payment?")) {
                                    this.submitPaymentAction(item, false);
                                }
                            }}>{t('delete')}</Button>
                            )}
                        </div>
                    ) : (
                        <div className='wrap-content-parent'>
                            <h4 className='wallet-table-info wrap-content-fill-child'>
                            Waiting for your decision
                            </h4>
                            <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                this.submitPaymentAction(item, true);
                            }}>{t('accept')}</Button>
                            <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                                this.submitPaymentAction(item, false);
                            }}>{t('reject')}</Button>
                        </div>
                    )}
                </Col>
            </Row>
        );
    }

    renderWalletDetail(item) {
        const {users} = this.state;

        const price = "$" + (parseFloat(item.balance) / 100);

        let user = item.uid && users[item.uid] ? item.uid && users[item.uid] : {};
        let userIcon = user.photoUri ? user.photoUri : userImage;

        return (
            <Row style={{margin: 0}}>
                <Col sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <img src={userIcon} className="wrap-content-wrap-child" style={{width: 24, height: 24, margin: 'auto 0px', borderRadius: 12, backgroundColor: 'white'}} alt="icon" />
                        
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col sm={2} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {price}
                    </h4>
                </Col>
                <Col sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        
                        </h4>
                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                            this.setState({walletChoice: item});            
                        }}>{t('detail')}</Button>
                    </div>
                </Col>
            </Row>
        );
    }

    renderPlayerItem(user) {
        const {checkoutProgress} = this.state;

        return (
            <Row style={{margin: 0}}>
                <Col sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <img src={userIcon} className="wrap-content-wrap-child" style={{width: 24, height: 24, margin: 'auto 0px', borderRadius: 12, backgroundColor: 'white'}} alt="icon" />
                        
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        {user.username}
                        </h4>
                    </div>
                </Col>
                <Col sm={2} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {t('no_wallet')}
                    </h4>
                </Col>
                <Col sm={5} className="vertical-center" style={{padding: 0}}>
                    <div className='wrap-content-parent'>
                        <h4 className='wallet-table-info wrap-content-fill-child'>
                        
                        </h4>
                        {checkoutProgress ? <Spinner color='primary'/> : 
                        <Button className="grid-edit-btn" style={{fontSize: 12}} onClick={e => {
                            this.createWallet(user);
                        }}>{t('create')}</Button>
                        }
                    </div>
                </Col>
            </Row>
        );
    }

    renderWallets() {
        const {users, walletChoice, wallets, walletPayments} = this.state;

        let walletUserIds = [];
        const walletArr = Object.values(wallets);
        walletArr.sort((o1, o2)=> {
            const a = users[o1.uid] ? users[o1.uid] : {};
            const b = users[o2.uid] ? users[o2.uid] : {};
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        const paymentsArr = Object.values(walletPayments);

        paymentsArr.sort((a, b)=> {
            return a.timestamp - b.timestamp;
        });
        const pending = [];
        const waiting = [];
        const success = [];
        const walletItems = [];

        for (const item of paymentsArr) {
            if (item.success) {
                success.push(
                    <div>{this.renderWalletItem(item)}</div>
                );
            } else if (item.type !== 'direct')  {
                pending.push(
                    <div>{this.renderWalletItem(item)}</div>
                );
            }
        }

        for (const item of walletArr) {
            if (item.uid && !walletUserIds.includes(item.uid)) {
                walletUserIds.push(item.uid);
            }
            walletItems.push(
                <div>{this.renderWalletDetail(item)}</div>
            );
            walletItems.push(<div className='divider' />);
        }

        const items = [];
        for (const item of pending) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of waiting) {
            items.push(item);
            items.push(<div className='divider' />);
        }
        for (const item of success) {
            items.push(item);
            items.push(<div className='divider' />);
        }

        const userItems = [];
        for (const val of Object.values(users)) {
            if (val.uid && !walletUserIds.includes(val.uid) && !val.parentId && !val.owner) {
                userItems.push(val);
            }
        }
        userItems.sort((a, b)=> {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        return (
            <Row style={{padding: 0}}>
                <Col sm={6} xs={12}>
                    <div className='wallet-info-tile'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                            
                            <h3 className='items-navigation-title'>
                            Wallets
                            </h3>
                        </div>
                        <Row style={{margin: 0}}>
                            <Col sm={5} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                {t('user')}
                                </h4>
                            </Col>
                            <Col lg={2} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Balance
                                </h4>
                            </Col>
                            <Col sm={5} className="vertical-center" style={{padding: 0}}>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {walletChoice ? (
                            <WalletDetail
                                wallet={walletChoice}
                                user={users[walletChoice.uid]}
                                onChange={(wallet, payment) => {
                                    wallets[walletChoice.id] = wallet;
                                    if (payment) {
                                        walletPayments[payment.id] = payment;
                                    }
                                    this.setState({wallets: wallets, walletPayments: walletPayments, walletChoice: wallet});            
                                }}
                                onCloseClick={() => {
                                    this.setState({walletChoice: null});            
                                }} />
                        ) : (
                            <div>
                            {walletItems.length > 0 ? walletItems : (
                                <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                No Wallets To Show
                                </div>
                            )}
                            </div>
                        )}
                    </div>
                    
                    <div className='wallet-info-tile'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                            
                            <h3 className='items-navigation-title'>
                            Members
                            </h3>
                        </div>
                        <Row style={{margin: 0}}>
                            <Col sm={5} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                {t('user')}
                                </h4>
                            </Col>
                            <Col lg={2} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Status
                                </h4>
                            </Col>
                            <Col sm={5} className="vertical-center" style={{padding: 0}}>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {userItems.length > 0 ? userItems.map((val,idx) => (
                            <div key={idx}>
                                {this.renderPlayerItem(val)}
                                <div className='divider' />
                            </div>
                        )) : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Members Left
                            </div>
                        )}
                    </div>
                </Col>
                <Col sm={6} xs={12}>
                    <div className='wallet-info-tile'>
                        <div className='detail-nav-btn'>
                            <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                            
                            <h3 className='items-navigation-title'>
                            Wallet Transactions
                            </h3>
                        </div>
                        <Row style={{margin: 0}}>
                            <Col sm={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Date
                                </h4>
                            </Col>
                            <Col sm={2} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                Money
                                </h4>
                            </Col>
                            <Col lg={3} className="vertical-center" style={{padding: 0}}>
                                <h4 className='wallet-table-title'>
                                {t('user')}
                                </h4>
                            </Col>
                            <Col sm={4} className="vertical-center" style={{padding: 0}}>
                            </Col>
                        </Row>
                        <div className='divider' />
                        <div className='divider' />
                        {items.length > 0 ? items : (
                            <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                            No Transactions To Show
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        )
    }

    renderFundraise() {
        const {user} = this.props;
        const {fundraises, users, fundChoice, fundDetail, organization} = this.state;

        let fundArr = [];

        for (const item of Object.values(fundraises)) {
            fundArr.push(item)
        }
        fundArr.sort((a, b) => {
            if (a.title === b.title) 
                return 0;

            return a.title < b.title ? -1 : 1;
        });


        let contents = <div />;
        if (fundDetail) {
            contents = (
                <FundraiserDetails
                    fundraise={fundDetail}
                    user={user}
                    users={users}
                    organization={organization}
                    onChanged={(update)=> {
                        fundraises[update.id] = update;
                        this.setState({fundraises: fundraises, fundDetail: update});
                    }}
                    onCloseClick={()=> {
                        this.setState({fundDetail: null});
                    }} />

            )
        } else if (fundChoice) {
            contents = (
                <FundraiseEdit
                    fundraise={fundChoice}
                    organization={organization}
                    users={users}
                    onChanged={(update, usersUpdate)=> {
                        fundraises[update.id] = update;
                        if (usersUpdate) {
                            for (const val of usersUpdate) {
                                if (!users[val.uid]) {
                                    users[val.uid] = val;
                                }
                                this.setState({users: users});
                            }
                        }
                        this.setState({fundraises: fundraises});
                    }}
                    onCloseClick={()=> {
                        this.setState({fundChoice: null});
                    }} />
            )
        } else {
            let items = [];
    
            for (const item of fundArr) {
                items.push(
                    <div>{this.renderFundraiseItem(item)}</div>
                )
            }
            contents = (
                <div className='shadow-tile-table'>
                    <Row style={{margin: 0}}>
                        <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                {t('fundraises')}
                            </h3>
                        </Col>
                        <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                {t('donate')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                            <h3 className='row-table-title'>
                                
                            </h3>
                        </Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
            )
        }
        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('fundraises')}
                        icon={<FcDonate/>}
                        totalCount={fundArr.length}
                        detailItem={fundDetail ? fundDetail : fundChoice}
                        detailOpen={fundDetail != null}
                        editOpen={fundChoice != null}
                        onClickBack={()=> {
                            this.setState({fundDetail: null, fundChoice: null})
                        }} 
                        onClickAdd={()=> {
                            this.setState({fundChoice: {}})
                        }} />
                </div>
                {contents}
            </div>
        );
    }

    renderFundraiseItem(fundraise) {
        const donateCount = fundraise.donate ? Object.values(fundraise.donate).length : 0;
        let balance = 0;
        if (fundraise.donate) {
            for (const item of Object.values(fundraise.donate)) {
                balance += item.price;
            }
        }
        balance /= 100;
        const subView = (
            <div className='wrap-content-parent'>
                <h4 style={{marginBottom: 0, marginRight: 15, fontSize: 15}}>
                <FcDonate /> {t('goal')} ${fundraise.donateGoal}, {t('collected')}: ${balance}
                </h4>
                <div style={{marginBottom: 0, fontSize: 15}}>
                <FcMoneyTransfer /> {t('donated')}: {donateCount}
                </div>
            </div>
        )
        
        return (
            <TableRowSmall 
                key={fundraise.id}
                title={fundraise.title}
                icon={fundIcon}
                subView={subView}
                onClickDetails={() => {
                    this.setState({fundDetail: fundraise})
                }} 
                onClickEdit={() => {
                    this.setState({fundChoice: fundraise})
                }} />
        );
    }

    renderContents() {
        const {organizationSubscription, freeOrganization} = this.props;
        const {tab} = this.state;

        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        
        if (tab === 0) {
            return (
                <div>
                    {this.renderPaymentSettings()}
                </div>
            )
        } else if (tab === 1) {
            return (
                <div>
                    {this.renderWallets()}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderFundraise()}
                </div>
            )
        }
    }

    render() {
        const {user} = this.props;
        const {loading, tab} = this.state;

        if (user == null) {
            return <div/>
        }

        return (
            <div>
                <Box sx={mainTabStyle}>
                    <Tabs
                        value={tab}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                        onChange={(e, value)=> {
                            this.setState({tab: value});
                        }}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="Payment Method" />
                        <Tab label="Wallets" />
                        <Tab label="Fundraiser" />
                    </Tabs>
                </Box>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(FinancePage));
export { connectedApp as FinancePage };
