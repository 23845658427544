import React, { Component } from "react";
import {
    CardImg, Button,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

export class InfoBadgeFixed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {title, subView, icon, key, onClickDetails, onClickEdit } = this.props;
        
        return (
            <div key={key} className="info-badge-small">
                <div className="divider"/>
                <div className="wrap-content-parent">
                    <CardImg
                        className="wrap-content-wrap-child info-badge-icon-small"
                        src={icon} />
                    <div className="wrap-content-fill-child info-badge-body">
                        <h4 className='label label-default one-line-title' style={{marginTop: 5}}>
                        {title}
                        </h4>
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            {subView}
                            </div>
                            <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                {onClickDetails && (
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickDetails != null) {
                                            onClickDetails();
                                        }
                                    }}>{t('details')}</Button>
                                )}
                                {onClickEdit && (
                                    <Button className="btn-edit" onClick={e => {
                                        if (onClickEdit != null) {
                                            onClickEdit();
                                        }
                                    }}>{t('edit')}</Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="divider"/>
            </div>
        );
    }
}

export default withTranslation()(InfoBadgeFixed);