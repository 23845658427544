import React, { Component } from "react";
import {
    Button, Form, Input, Spinner, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import Switch from '@mui/material/Switch';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, saveSeason } from "utils/API";
import { ROUND_TYPE_GROUP, SEASON_TYPE_CUSTOM, SEASON_TYPE_LEAGUE, SEASON_TYPE_PLAYOFF, SEASON_TYPE_TOURNAMENT } from "utils/constants";

export class SeasonEdit extends Component {
    constructor(props) {
        super(props);
        const season = {};
        if (props.season != null) {
            for (const [key, value] of Object.entries(props.season)) {
                season[key] = value;
            }
        }
        
        this.state = {
            season: season,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, season} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.season !== season)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(season)) {
                challengeVal[key] = value;
            }
            this.setState({
                season: challengeVal
            })
        }
    }

    onSaveClick(season, newImage) {
        const {organization, division, onSaveDone} = this.props;
        const objRef = this;
        if (season.title == null || season.title === '') {
            alert("You must input title");
        } else if (season.type == null) {
            alert("You must input season type");
        } else if (season.type === SEASON_TYPE_LEAGUE && (season.startTeams == null || season.startTeams < 3)) {
            alert("You must input valid team count to start a league season");
        } else if (season.type === SEASON_TYPE_TOURNAMENT && season.startTeams == null) {
            alert("You must input valid team count to start a tournament season");
        } else if (season.type === SEASON_TYPE_TOURNAMENT && season.groupCount == null) {
            alert("You must input valid group count to start a tournament season");
        } else if (season.type === SEASON_TYPE_TOURNAMENT && season.knockoutTeamCount == null) {
            alert("You must input valid team count to start a knockout round");
        } else if (season.type === SEASON_TYPE_TOURNAMENT && season.startTeams / season.groupCount < 3) {
            alert("Start Team Count must be larger than group count * 3.");
        } else if (season.type === SEASON_TYPE_TOURNAMENT && season.startTeams < season.knockoutTeamCount) {
            alert("Start Team Count must be larger than knockout team count");
        } else if (season.type === SEASON_TYPE_PLAYOFF && (season.startTeams == null || season.startTeams < 3)) {
            alert("You must input valid team count to start a playoff season");
        } else if (season.type === SEASON_TYPE_PLAYOFF && season.knockoutTeamCount == null) {
            alert("You must input valid team count to start a knockout round");
        } else if (season.type === SEASON_TYPE_PLAYOFF && season.startTeams < season.knockoutTeamCount) {
            alert("Start Team Count must be larger than knockout team count");
        } else if (season.type === SEASON_TYPE_CUSTOM && (season.startTeams == null || season.startTeams < 3)) {
            alert("You must input valid team count to start a qualification round");
        } else if (season.type === SEASON_TYPE_CUSTOM && season.knockoutTeamCount == null) {
            alert("You must input valid team count to start a knockout round");
        } else if (season.type === SEASON_TYPE_CUSTOM && !season.qualification) {
            alert("You must input qualification type for custom season.");
        } else if (season.type === SEASON_TYPE_CUSTOM && season.qualification === ROUND_TYPE_GROUP && season.startTeams / season.groupCount < 3) {
            alert("Start Team Count must be larger than group count * 3.");
        } else if (season.type === SEASON_TYPE_CUSTOM && season.startTeams < season.knockoutTeamCount) {
            alert("Start Team Count must be larger than knockout team count");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (season.id == null || season.id === '') {
                season.id = generateId("/season");
            }
            season.owner = organization.owner;
            season.organizationId = organization.id;
            season.divisionId = division.id;
            season.category = {}
            if (division.category != null) {
                for (const [key, value] of Object.entries(division.category)) {
                    season.category[key] = value;
                }
            }
            season.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(season, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onSaveDone != null) {
                    onSaveDone();
                }
                objRef.setState({
                    season: update,
                    progress: false
                });
            })
        }
    }

    saveChangeWithImage(season, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(season.id, 'seasonThumb', newImage, (url) => {
                season.thumbUri = url;
                objRef.saveChange(season, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(season, (update) => {
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(season, success, failure) {
        saveSeason(season, success, failure);
    }

    render() {
        const {onCloseClick, createMode, trophies} = this.props;
        const {season, newImage, newVideo, progress} = this.state;
        const startTeams = season.startTeams != null ? season.startTeams : 0;
        const groupCount = season.groupCount != null ? season.groupCount : 0;
        const knockoutTeamCount = season.knockoutTeamCount != null ? season.knockoutTeamCount : 0;
        const enrollFee = season.enrollFee ? season.enrollFee : {}
        
        if (season != null) {
            return (
                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Season Edit
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('season_title')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                            <Input type="text" name="text" id="title" onChange={e => {
                                season.title = e.target.value;
                                this.setState({season: season});
                            }} value={season.title} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('start_date')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <CustomDateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="MM/DD/YYYY hh:mm"
                                    value={season.startTime != null ? new Date(season.startTime) : new Date()}
                                    onChange={ val => {
                                        season.startTime = val.valueOf();
                                        this.setState({season: season});
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('reg_date')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <CustomDateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="MM/DD/YYYY hh:mm"
                                    value={season.regDeadline != null ? new Date(season.regDeadline) : new Date()}
                                    onChange={ val => {
                                        season.regDeadline = val.valueOf();
                                        this.setState({season: season});
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('end_date')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <CustomDateTimePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    format="MM/DD/YYYY hh:mm"
                                    value={season.endTime != null ? new Date(season.endTime) : new Date()}
                                    onChange={ val => {
                                        season.endTime = val.valueOf();
                                        this.setState({season: season});
                                    }} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('team_register_fee')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!season.enrollFee) {
                                        season.enrollFee = {}
                                    }
                                    season.enrollFee.teamFee = parseInt(e.target.value);
                                    this.setState({season: season});
                                }} value={enrollFee.teamFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('player_register_fee')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!season.enrollFee) {
                                        season.enrollFee = {}
                                    }
                                    season.enrollFee.playerFee = parseInt(e.target.value);
                                    this.setState({season: season});
                                }} value={enrollFee.playerFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('staff_register_fee')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="number" name="number" id="title" onChange={e => {
                                    if (!season.enrollFee) {
                                        season.enrollFee = {}
                                    }
                                    season.enrollFee.managerFee = parseInt(e.target.value);
                                    this.setState({season: season});
                                }} value={enrollFee.managerFee} />
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('trophy_choice')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.trophyId = e.target.value;
                                    this.setState({season: season});
                                }} value={season.trophyId}>
                                    <option value={null}></option>
                                    {Object.values(trophies).map((val,idx)=>(
                                    <option key={idx} value={val.id}>{val.title}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('individual_register_switch')}
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={season.individualReg}
                                onChange={e=>{
                                    season.individualReg = e.target.checked;
                                    this.setState({season: season});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('block_offline_payment')}
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={season.blockOfflinePayment}
                                onChange={e=>{
                                    season.blockOfflinePayment = e.target.checked;
                                    this.setState({season: season});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('skip_profile_check')}
                        </Col>
                        <Col sm={7} xs={12} className='form-edit-entry'>
                            <Switch
                                checked={season.skipProfileCheck}
                                onChange={e=>{
                                    season.skipProfileCheck = e.target.checked;
                                    this.setState({season: season});
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('season_type')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.type = e.target.value;
                                    this.setState({season: season});
                                }} value={season.type}>
                                    <option value={null}></option>
                                    <option value={SEASON_TYPE_LEAGUE}>{t('league')}</option>
                                    <option value={SEASON_TYPE_TOURNAMENT}>{t('tournament')}</option>
                                    <option value={SEASON_TYPE_PLAYOFF}>{t('playoff')}</option>
                                    <option value={SEASON_TYPE_CUSTOM}>{t('custom')}</option>
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    {(season.type == null || season.type === SEASON_TYPE_LEAGUE) && (
                    <div>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('start_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={startTeams} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )}
                    {season.type === SEASON_TYPE_TOURNAMENT && (
                    <div>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('start_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={startTeams} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('group_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.groupCount = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={groupCount}>
                                    <option value={null}></option>
                                    <option value={2}>2</option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                </Input>
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('knockout_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={knockoutTeamCount}>
                                    <option value={null}></option>
                                    <option value={2}>2</option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={16}>16</option>
                                    <option value={32}>32</option>
                                </Input>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )}
                    {(season.type === SEASON_TYPE_PLAYOFF) && (
                    <div>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('start_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={startTeams} />
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('knockout_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={knockoutTeamCount}>
                                    <option value={null}></option>
                                    <option value={2}>2</option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={16}>16</option>
                                    <option value={32}>32</option>
                                </Input>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )}
                    {(season.type === SEASON_TYPE_CUSTOM) && (
                    <div>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('qualification_stage_type')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                    <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                        season.qualification = e.target.value;
                                        this.setState({season: season});
                                    }} value={season.qualification}>
                                        <option value={null}></option>
                                        <option value={SEASON_TYPE_LEAGUE}>{t('league')}</option>
                                        <option value={ROUND_TYPE_GROUP}>{t('group')}</option>
                                    </Input>
                                </div>
                            </Col>
                        </Row>
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('start_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="number" name="text" id="title" onChange={e => {
                                    season.startTeams = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={startTeams} />
                                </div>
                            </Col>
                        </Row>
                        {(season.qualification === ROUND_TYPE_GROUP) && (
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('group_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.groupCount = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={groupCount}>
                                    <option value={null}></option>
                                    <option value={2}>2</option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                </Input>
                                </div>
                            </Col>
                        </Row>
                        )}
                        <Row className='form-edit-row'>
                            <Col className='form-edit-label' sm={5} xs={12}>
                            {t('knockout_team_count')}
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={12}>
                                <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    season.knockoutTeamCount = e.target.value != null ? parseInt(e.target.value) : null;
                                    this.setState({season: season});
                                }} value={knockoutTeamCount}>
                                    <option value={null}></option>
                                    <option value={2}>2</option>
                                    <option value={4}>4</option>
                                    <option value={8}>8</option>
                                    <option value={16}>16</option>
                                    <option value={32}>32</option>
                                </Input>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )}
                    
                    {progress ? (
                        <div>
                            <div style={{display: 'inline-block', marginLeft: 'calc(50% - 16px)'}}>
                                <Spinner color="primary" />
                            </div>
                        </div>
                    ) : (
                        <Row className='form-edit-row text-center'>
                            <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                <Button className='btn-edit' style={{marginLeft: 'auto', marginRight: 'auto'}} onClick={e => {
                                    this.onSaveClick(season, newImage, newVideo);
                                }}>{createMode ? "Create" : "Save"}</Button>
                            </Col>
                            <Col className='form-edit-entry' sm={7} xs={7}>
                                <Button className='btn-cancel' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                    if (onCloseClick != null) {
                                        onCloseClick();
                                    }
                                }}>{t('close')}</Button>
                            </Col>
                        </Row>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(SeasonEdit);