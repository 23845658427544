import React, { Component } from "react";
import {
    CardImg, Button, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/player_photo_default.png';

export class UserChoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    renderItemChoice() {
        const { users, onItemChoice, emptyTitle } = this.props;

        if (users != null && Object.values(users).length > 0) {
            var items = [];

            items.push(
                <ListGroupItem key='cancel_id' onClick={e => {
                    if (onItemChoice != null) {
                        onItemChoice({})
                    }
                    this.setState({editOpen: false});
                }}>Pick None</ListGroupItem>
            )
            for (const[key, value] of Object.entries(users)) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        if (onItemChoice != null) {
                            onItemChoice(value)
                        }
                        this.setState({editOpen: false});
                    }}>{value.username}</ListGroupItem>
                )
            }

            return (
                <ListGroup>
                    {items}
                </ListGroup>
            );
        } else {
            return <div>{emptyTitle}</div>
        }
    }
    
    render() {
        const { users, userChoice, emptyTitle, smallMode } = this.props;
        const { editOpen } = this.state;
        let challengeImage = userChoice != null && userChoice.photoUri != null ? userChoice.photoUri : defaultImage;
        
        if (users != null) {
            return (
                <div>
                    <div>
                        <div className='wrap-content-parent'>
                            <div className=' wrap-content-fill-child vertical-center-spacing' onClick={e=>{
                                if (smallMode) {
                                    this.setState({editOpen: true})
                                }
                            }}>
                                {userChoice != null && userChoice.uid != null ? (
                                    <div className='wrap-content-parent'>
                                        <CardImg
                                            className="profile-thumb"
                                            src={challengeImage}
                                            style={{ width: 18, height: 15, marginRight: 10, marginTop: 2 }}
                                            />
                                        <h4 className='wrap-content-fill-child vertical-center-spacing' style={{fontSize: smallMode ? 12 : 16, marginBottom: 0}}>{userChoice.username}</h4>
                                    </div>
                                ) : (
                                    <div className='wrap-content-fill-child vertical-center-spacing btn btn-secondary btn-submit'>
                                        {emptyTitle}
                                    </div>
                                )}
                            </div>
                            {!smallMode && (
                                <Button className='wrap-content-wrap-child btn-pick' onClick={e => {
                                    this.setState({editOpen: true})
                                }}>Change</Button>
                            )}

                        </div>

                    </div>
                    <Modal isOpen={editOpen}>
                        <ModalBody>
                            {this.renderItemChoice()}
                        </ModalBody>
                        <ModalFooter>
                            <Button className="btn-pick" onClick={e => {
                                this.setState({editOpen: false})
                            }}>{t('close')}</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(UserChoice);