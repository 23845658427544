import React, { Component } from 'react';
import { 
    Button,
    Modal, 
    ModalBody,
    ListGroup,
    ListGroupItem
} from 'reactstrap';
import ClubTrackHeader from 'components/detail/club/ClubTrackHeader';
import ClubTrackDetailsView from 'components/detail/club/ClubTrackDetailsView';
import ClubTrackEditView from 'components/edit/club/ClubTrackEditView';
import { readDb, writeDb } from 'utils/API';

export class ClubTracksCollection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            categorys: {},
            clubTracks: {},
            clubTrackChoice: {},
            editOpen: false,
            detailOpen: false,
            addOpen: false,
            pickOpen: false,
        };
    }

    componentDidMount() {
        const { user } = this.props;
        readDb('/category', data => {
            var categorys = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    categorys[key] = value;
                }
            }

            this.setState({
                categorys: categorys,
            });
        });
        readDb('/clubTrack', data => {
            var clubTracks = {};

            if (data != null) {
                for (const[key, value] of Object.entries(data)) {
                    if (value.owner === "free" || value.owner === user.uid) {
                        clubTracks[key] = value;
                    }
                }
            }

            this.setState({
                clubTracks: clubTracks,
            });
        });
    }

    renderContents() {
        const { user, clubField } = this.props;
        const { clubTracks } = this.state;
        const result = [];

        for (const[key, value] of Object.entries(clubTracks)) {
    
            if ((clubField != null && (clubField.trackIds != null && Object.values(clubField.trackIds).includes(key)))
                ||
                (clubField == null && (value.owner === user.uid || (value.sharedIds != null && Object.values(value.sharedIds).includes(user.uid))
                ))
             ) {
                result.push(
                    <ClubTrackHeader 
                        key={key}
                        uid={user.uid}
                        clubTrack={value}
                        onClickDetails={() => {
                            this.setState({
                                clubTrackChoice: value,
                                detailOpen: true
                            })
                        }}
                        onClickEdit={() => {
                            this.setState({
                                clubTrackChoice: value,
                                editOpen: true
                            })
                        }} />
                )
            }
        }

        return result;
    }

    renderItemChoice() {
        const { user } = this.props;
        const { clubTracks } = this.state;

        var items = [];
        for (const [key, value] of Object.entries(clubTracks)) {
            if (value.owner === "free" && (value.sharedIds == null || !Object.values(value.sharedIds).includes(user.uid))) {
                items.push(
                    <ListGroupItem key={key} onClick={e => {
                        writeDb('/clubTrack/' + key + '/sharedIds/' + user.uid, user.uid);
                    }}>{value.title}</ListGroupItem>
                )
            }
        }
        return (
            <ListGroup>
                {items}
            </ListGroup>
        );
    }
    
    render() {
        const { user, clubField } = this.props;
        const {categorys, detailOpen, editOpen, clubTrackChoice} = this.state;

        return (
            <div>
                <div style={{marginTop: 10}}>
                    {clubField == null && (
                        <div className='wrap-content-parent' style={{marginBottom: 20}}>
                            <h3 className='wrap-content-fill-child vertical-center-spacing'  style={{display: 'inline-block', fontSize: 16}}>
                            Club Tracks
                            </h3>
                            
                            <Button className='title-action-button wrap-content-wrap-child' onClick={ e => {
                                this.setState({clubTrackChoice: {}, editOpen: true});
                            }}>+</Button>
                        </div>
                    )}
                    {this.renderContents()}
                </div>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <ClubTrackEditView 
                            categorys={categorys} 
                            uid={user.uid}
                            clubTrack={clubTrackChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <ClubTrackDetailsView 
                            categorys={categorys} 
                            clubTrack={clubTrackChoice} 
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ClubTracksCollection;