import React, { Component } from 'react';
import { connect } from 'react-redux';
import CsvViewer from 'components/utils/CsvViewer';

import { 
    Row,
    Col,
    Input,
    Form,
    Button,
    Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { validateEmail } from 'utils/Utils';

import { userActions } from 'redux/_actions';
import { 
    GiSoccerKick
} from 'react-icons/gi';

import ItemsNavigation from 'components/detail/ItemsNavigation';
import LoadingView from 'components/detail/loading/LoadingView';
import { generateId, invokeHttpsApi } from 'utils/API';

class SeasonImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userImports: {},
            teamImports: {},
            teams: {},
            users: {},
            imports: {},
            data: [],
            columns: [],
            profileChoice: {},
            detailOpen: false,
            importOpen: false,
            loading: true,
            searchStr: null,
            teamFilter: null,
            statusFilter: null,
            pickTeam: false,
            pickStatus: false
        };
    }
    
    componentDidMount() {
        const id = this.props.organization.id;
        this.loadContents(id);
    }

    loadContents(id) {
        invokeHttpsApi('organizerQuery-seasonImportQuery', {
            organizationId: id
        }, (data) => {

            const userImports = {};
            const teamImports = {};
            const teams = {};
            const users = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.userImports)) {
                    userImports[key] = value;
                }
                for (const [key, value] of Object.entries(data.teamImports)) {
                    teamImports[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
            }

            this.setState({
                userImports: userImports,
                teamImports: teamImports,
                teams: teams,
                users: users,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
        });
    }

    performInvite(teamData, userItems) {
        const {organization, season} = this.props;
        const imports = {};
        
        for (const item of userItems) {
            imports[item.uid] = item;
        }
        const data = {imports: imports, teamData: teamData, organizationId: organization.id, seasonId: season.id};

        this.setState({progress: true})
        invokeHttpsApi('invite-invitePlayerToSeason', data, (data) => {
            alert("An invitation emails to be your players has been sent. It will be sent every 2 days");
            this.loadContents();
            this.setState({progress: false});
        }, (error) => {
            console.log('invite error', error);
            this.setState({progress: false});
        });
    }

    getOriginalUserId(email) {
        const {users, userImports} = this.state;

        for (const item of Object.values(userImports)) {
            if (email && item.email && item.email.toLowerCase() === email.toLowerCase()) {
                return item.uid;
            }
        }
        for (const item of Object.values(users)) {
            if (email && item.email && item.email.toLowerCase() === email.toLowerCase()) {
                return item.uid;
            }
        }
        return null;
    }

    getMatchinglUserId(firstname, lastname) {
        const {userImports} = this.state;

        for (const item of Object.values(userImports)) {
            if (item.firstname === lastname) {
                return item.uid;
            }
        }
        return null;
    }

    getOriginalTeamId(title) {
        const {teamImports} = this.state;
        for (const item of Object.values(teamImports)) {
            if (title && item.title === title) {
                return item.id;
            }
        }
        
        return null;
    };    

    getInviteImports() {
        const {user} = this.props;
        const {data, emailChoice, firstnameChoice, lastnameChoice, birthdayChoice, mobileChoice, 
            teamChoice, userTypeChoice, numChoice, playerType, coachType, managerType} = this.state;
        const teamData = {};
        const userTypes = [];
        const result = [];
        if (teamChoice) {
            for (const item of data) {
                const teamTitle = item[teamChoice] ? item[teamChoice].replace(/\s/g, '') : "";
                const type = item[userTypeChoice] ? item[userTypeChoice].replace(/\s/g, '') : "";

                if (teamTitle && teamTitle !== "") {
                    if (!Object.keys(teamData).includes(teamTitle)) {
                        teamData[teamTitle] = {
                            id: this.getOriginalTeamId(teamTitle),
                            title: teamTitle, 
                            players: 0, 
                            coaches: 0, 
                            managers: 0
                        };
                    }
                    if (!playerType && !coachType && !managerType) {
                        teamData[teamTitle].players += 1;
                    } else if (type === playerType) {
                        teamData[teamTitle].players += 1;
                    } else if (type === coachType) {
                        teamData[teamTitle].coaches += 1;
                    } else if (type === managerType) {
                        teamData[teamTitle].managers += 1;
                    }
                }
                if (!userTypes.includes(type)) {
                    userTypes.push(type);
                }
            }
        }

        for (const item of Object.values(teamData)) {
            if (item.id == null || item.id === '') {
                item.id = generateId("/squad");
            }
        }

        if (emailChoice && firstnameChoice && lastnameChoice && birthdayChoice && mobileChoice) {
            for (const item of data) {
                let email = item[emailChoice] ? item[emailChoice].replace(/\s/g, '') : "";
                const teamTitle = item[teamChoice] ? item[teamChoice].replace(/\s/g, '') : "";
                const type = userTypeChoice ? (item[userTypeChoice] ? item[userTypeChoice].replace(/\s/g, '') : "") : null;

                if (email === "") {
                    email = user.email;
                }
                if (!validateEmail(email)) {
                    continue;
                }

                const userItem = {
                    uid: email === user.email ? 
                        this.getMatchinglUserId(item[firstnameChoice], item[lastnameChoice]) : 
                        this.getOriginalUserId(email),
                    email: email,
                    firstname: item[firstnameChoice],
                    lastname: item[lastnameChoice],
                    birthday: Date.parse(item[birthdayChoice]),
                }
                if (email === user.email) {
                    userItem.noEmail = true;
                }
                if (!userItem.uid) {
                    userItem.uid = generateId("/user");
                    userItem.imported = true;
                }
                if (mobileChoice && item[mobileChoice]) {
                    userItem.mobile = item[mobileChoice].toString();
                }
                if (numChoice) {
                    userItem.number = item[numChoice].toString();
                }
                if (teamTitle && teamTitle !== "") {
                    userItem.teamId = teamData[teamTitle].id;
                    userItem.teamTitle = teamTitle;
                }
                if (!type) {
                    userItem.type = "player";
                } else if (!playerType && !coachType && !managerType) {
                    userItem.type = "player";
                } else if (type === playerType) {
                    userItem.type = "player";
                } else if (type === coachType) {
                    userItem.type = "coach";
                } else if (type === managerType) {
                    userItem.type = "manager";
                }
                if (userItem.type === "player") {
                    if (teamTitle && teamData[teamTitle]) {
                        if (!teamData[teamTitle].playerIds) {
                            teamData[teamTitle].playerIds = {};
                        }
                        teamData[teamTitle].playerIds[userItem.uid] = userItem.uid;
                    }
                } else if (userItem.type === "coach") {
                    if (teamTitle && teamData[teamTitle]) {
                        if (!teamData[teamTitle].playerIds) {
                            teamData[teamTitle].playerIds = {};
                        }
                        teamData[teamTitle].owner = userItem.uid;
                    }
                } else if (userItem.type === "manager") {
                    if (teamTitle && teamData[teamTitle]) {
                        if (!teamData[teamTitle].managers) {
                            teamData[teamTitle].managers = {};
                        }
                        teamData[teamTitle].managers[userItem.uid] = userItem.uid;
                    }
                }
                result.push(userItem);
            }

        }
        
        return {teamData: teamData, userTypes: userTypes, userItems: result};
    }

    renderTeamImports(teamData, userTypes) {
        const {columns, teamChoice, userTypeChoice, playerType, coachType, managerType} = this.state;

        return (
            <div>

                <Form className='form-edit'>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Team Column
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({teamChoice: e.target.value});
                                }} value={teamChoice}>
                                    <option value={null}></option>
                                    {columns.map((val,idx)=>(
                                    <option key={idx} value={val.accessor}>{val.Header}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        {t('user_type_column')}
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({userTypeChoice: e.target.value});
                                }} value={userTypeChoice}>
                                    <option value={null}></option>
                                    {columns.map((val,idx)=>(
                                    <option key={idx} value={val.accessor}>{val.Header}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Player Type Choice
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({playerType: e.target.value});
                                }} value={playerType}>
                                    <option value={null}></option>
                                    {userTypes.map((val,idx)=>(
                                    <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Coach Type Choice
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({coachType: e.target.value});
                                }} value={coachType}>
                                    <option value={null}></option>
                                    {userTypes.map((val,idx)=>(
                                    <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Manager Type Choice
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            <div className='form-edit-input'>
                                <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                    this.setState({managerType: e.target.value});
                                }} value={managerType}>
                                    <option value={null}></option>
                                    {userTypes.map((val,idx)=>(
                                    <option key={idx} value={val}>{val}</option>
                                    ))}
                                </Input>
                            </div>
                        </Col>
                    </Row>
                    <Row className='form-edit-row'>
                        <Col className='form-edit-label' sm={5} xs={12}>
                        Imported Team Titles
                        </Col>
                        <Col className='form-edit-entry' sm={7} xs={12}>
                            {Object.values(teamData).map((v, index)=>(
                                <div>
                                    <b>{v.title}</b> : Players <span>{v.players}</span>, Coaches <span>{v.coaches}</span>, Managers <span>{v.managers}</span>
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }

    renderImports() {
        const {progress, columns, emailChoice, firstnameChoice, lastnameChoice, birthdayChoice, mobileChoice, numChoice} = this.state;
        
        const data = this.getInviteImports();

        return (
            <div>
                <div style={{margin: '10px 10px'}}>
                    <CsvViewer 
                        label="Season Team Player Import CSV File"
                        onChange={(data, columns) => {
                            this.setState({data: data, columns: columns})
                        }} />
                </div>

                <Row>
                    <Col lg={6} sm={12}>
                        {this.renderTeamImports(data.teamData, data.userTypes)}
                    </Col>
                    <Col lg={6} sm={12}>
                        <Form className='form-edit'>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Email Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({emailChoice: e.target.value});
                                        }} value={emailChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                First Name Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({firstnameChoice: e.target.value});
                                        }} value={firstnameChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Last Name Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({lastnameChoice: e.target.value});
                                        }} value={lastnameChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Birthday Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({birthdayChoice: e.target.value});
                                        }} value={birthdayChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Mobile Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({mobileChoice: e.target.value});
                                        }} value={mobileChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' sm={5} xs={12}>
                                Player Number Column
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={12}>
                                    <div className='form-edit-input'>
                                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                                            this.setState({numChoice: e.target.value});
                                        }} value={numChoice}>
                                            <option value={null}></option>
                                            {columns.map((val,idx)=>(
                                            <option key={idx} value={val.accessor}>{val.Header}</option>
                                            ))}
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='form-edit-row'>
                                <Col className='form-edit-label' style={{marginTop: 0}} sm={5} xs={4}>
                                    {progress ? (
                                    <Spinner color="primary" />
                                    ) : (
                                    <Button className='btn-edit' style={{minWidth: 120, marginRight: 0}} onClick={e => {
                                        if (data.userItems.length > 0 && window.confirm('Are you sure you want to perform import? This will create a number of teams on your own and send invite emails to your email list. If you have imported before, this can cause duplicated teams creating and some invalid information.')) {
                                            this.performInvite(data.teamData, data.userItems);
                                        }
                                    }}>{t('import')}</Button>
                                    )}
                                </Col>
                                <Col className='form-edit-entry' sm={7} xs={7}>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                {this.renderInvites(data.userItems)}
            </div>
        )
    }

    renderInvites(userItems) {
        const { searchStr } = this.state;

        if (userItems.length <= 0) {
            return <div/>;
        }

        
        return (
            <div className='shadow-tile-table'>
                <Row style={{margin: 0}}>
                    <h3 className='row-table-title'>
                    Users Imported ({userItems.length})
                    </h3>
                </Row>
                <div className='divider' />
                <div className='divider' />
                <div className='items-navigation'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="search" id="search" placeholder='Search by email or username ...' onChange={e => {
                            this.setState({searchStr: e.target.value});
                        }} value={searchStr} />
                    </div>
                </div>
                <div className='divider' />
                <div className='divider' />
                
                {userItems.map((val) => (
                <Row style={{margin: 0}}>
                    <Col lg={3} xs={8} sm={5} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                            <h4 className='wrap-content-fill-child row-badge-title'>
                            {val.email}
                            </h4>
                        </div>
                    </Col>
                    <Col lg={5} xs={4} sm={4} className="vertical-center" style={{padding: 0}}>
                        <div className='wrap-content-parent'>
                        <b style={{marginLeft: 10, marginRight: 10}}> {t('name')} </b> {val.firstname} {val.lastname} <b style={{marginLeft: 10, marginRight: 10}}> {t('mobile')} </b> {val.mobile}
                        </div>
                    </Col>
                    <Col lg={4} xs={12} sm={3} className="vertical-center" style={{padding: 0}}>
                        <div style={{fontSize: 11}}>
                            <div>{t('number')}: {val.number}</div>
                            <span>{t('team')}: <b>{val.teamTitle}</b></span>
                        </div>
                    </Col>
                </Row>
                ))}
            </div>
        );
    }

    render() {
        const {user} = this.props;
        const {imports, detailOpen, profileChoice, loading} = this.state;

        if (user == null) {
            return <div/>
        }
        const curConetntCount = Object.values(imports).length;
        const choice = {};
        if (profileChoice.uid != null) {
            choice.id = profileChoice.uid;
            choice.title = profileChoice.username;
        }

        return (
            <div>
                
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title="Player Import Items"
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={true}
                        totalCount={curConetntCount}
                        detailItem={choice} />
                </div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderImports()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withTranslation()(connect(mapState, actionCreators)(SeasonImport));
export { connectedApp as SeasonImport };
