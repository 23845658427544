import React, { Component } from "react";
import {
    Card, CardImg, Button, Row, Col
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import { 
    MdEdit
} from 'react-icons/md';
import { toDateString } from 'utils/Utils';

import defaultImage from 'assets/img/icons/leagues_icon.png';
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';

export class DivisionTrophyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    renderWinners() {
        const {trophy} = this.props;

        let items = [];
        if (trophy.winnerIds != null) {
            for (const winner of Object.values(trophy.winnerIds)) {
                items.push(
                    this.renderWinnerBadge(winner)
                )
            }
        }

        return (
            <div className='shadow-tile'>
                <Row style={{backgroundColor: '#19447F'}}>
                    <Col xs={12} className="vertical-center">
                        <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                        Season/Winners ({items.length})
                        </h3>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                <Row>
                    <Col lg={3} xs={4} sm={3} className="vertical-center">
                        <h3 className='row-table-title'>
                            Season
                        </h3>
                    </Col>
                    <Col lg={3} xs={4} sm={3} className="vertical-center">
                        <h3 className='row-table-title'>
                        {t('winner')}
                        </h3>
                    </Col>
                    <Col lg={3} xs={4} sm={3} className="vertical-center">
                        <h3 className='row-table-title'>
                            Reward Date
                        </h3>
                    </Col>
                    <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t('no_data')}
                    </div>
                )}
            </div>
        )
    }

    renderWinnerBadge(winner) {
        const {seasons, teams, onSeasonChoice} = this.props;

        const team = teams[winner.teamId];
        const season = seasons[winner.seasonId];
        if (season != null) {
            const icon = team != null && team.iconUri != null ? team.iconUri : defaultTeamImage;
            return (
                <Row>
                    <Col lg={3} xs={4} sm={3} className="vertical-center">
                        <h3 className='row-badge-title'>
                        {season.title}
                        </h3>
                    </Col>
                    <Col lg={3} xs={4} sm={3} className="vertical-center">
                        {team == null ? (
                        <h3 className='row-badge-info'>
                            <img alt="icon" src={icon} style={{width: 20, height: 20, margin: 10}} />
                            No Winner 
                        </h3>
                        ) : (
                        <h3 className='row-badge-info'>
                            <img alt="icon" src={icon} style={{width: 20, height: 20, margin: 10}} />
                            {team.title}
                        </h3>
                        )}
                    </Col>
                    <Col lg={3} xs={4} sm={3} className="vertical-center">
                        {team == null ? (
                        <h3 className='row-badge-info'>
                            Not Rewarded Yet 
                        </h3>
                        ) : (
                        <h3 className='row-badge-info'>
                            {toDateString(winner.winnerDate)}
                        </h3>
                        )}
                    </Col>
                    <Col nm={3} xs={12} sm={3} className="vertical-center">
                        <div className='wrap-content-parent'>
                            <div className='wrap-content-fill-child vertical-center-spacing'>
                            </div>
                            <div className="vertical-center-spacing wrap-content-wrap-child">
                                    <Button className="btn-submit" onClick={e => {
                                        if (onSeasonChoice != null) {
                                            onSeasonChoice(season);
                                        }
                                    }}>Details</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            );
        } else {
            return (
                <div/>
            );
        }
    }
    
    render() {
        const {trophy, blockEdit, onClickEdit } = this.props;
        let thumbImage = trophy.thumbUri != null ? trophy.thumbUri : defaultImage;

        if (trophy != null) {
            return (
                <div className="">
                    <Card className="shadow-tile grid-badge">
                        <div className='grid-item-row'>
                            <CardImg
                                className="grid-item-icon"
                                src={thumbImage} />
                            <div className='grid-item-info-fill'>
                                <h4 className='grid-item-title'>
                                {trophy.title}
                                </h4>
                                <div className="grid-item-info">{trophy.description}</div>
                            </div>
                            <div className='grid-item-info-wrap'>
                            {!blockEdit && (
                                <Button className="grid-edit-btn" onClick={e => {
                                    if (onClickEdit != null) {
                                        onClickEdit();
                                    }
                                }}><MdEdit/></Button>
                            )}
                            </div>
                        </div>
                    </Card>
                    <div style={{marginTop: 20}}/>
                    {this.renderWinners()}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(DivisionTrophyDetail);