import React, { Component } from "react";
import {
    Spinner
} from 'reactstrap';

export class SmallLoadingView extends Component {
    
    render() {
        const { height } = this.props;

        return (
        <div style={{width: '100%', height: height ? height: 380}}>
            <div className="text-center" style={{marginTop: 150}}>
                <Spinner color="primary" />
            </div>
        </div>
        );
    }
}

export default SmallLoadingView;