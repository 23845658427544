import React, { Component } from "react";
import {
    Button, Input, Row, Col, CardImg, Modal, ModalBody
} from 'reactstrap';
import { t } from 'i18next';
import { MdWarning } from 'react-icons/md';
import { 
    RiDeleteBin2Line
} from 'react-icons/ri';
import CustomDateTimePicker from "components/template/CustomDateTimePicker";
import defaultTeamImage from 'assets/img/icons/club_icon_default.png';
import defaultLocationImage from 'assets/img/icons/league_default.png';
import { WEEK_TIME, getWeekStartDay } from "utils/Utils";
import HorizontalAvatarList from 'components/members/HorizontalAvatarList';
import Avatar from "components/detail/Avatar";
import userImage from 'assets/img/icons/player_photo_default.png';

export class GameSetupBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }

    getTeamIcon(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].iconUri != null) {
            return teams[teamId].iconUri;
        }
        let teamData = organization.teamData != null && organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        if (teamData.thumbUri != null) {
            return teamData.thumbUri;
        }

        return defaultTeamImage;
    }

    getTeamTitle(teamId, teams, organization) {
        if (teams[teamId] != null && teams[teamId].title != null) {
            return teams[teamId].title;
        }
        let teamData = organization.teamData != null && organization.teamData[teamId] != null ? organization.teamData[teamId] : {};
        if (teamData.nickname != null) {
            return teamData.nickname;
        }

        return 'Unknown';
    }

    getLocation(locationId, locations) {
        const result = {title: 'Unknown', thumbUri: defaultLocationImage};
        if (locations[locationId] != null && locations[locationId].title != null) {
            result.title = locations[locationId].title;
        }
        if (locations[locationId] != null && locations[locationId].thumbUri != null) {
            result.thumbUri = locations[locationId].thumbUri;
        }

        return result;
    }

    render() {
        const {locations, game, teams, organization, group, division, season, 
            subSeason, users, onHomeUpdate, onAwayUpdate, gameTimeUpdate, 
            locationUpdate, refereeUpdate, removeClicked} = this.props;

        const {pickReferee} = this.state;

        const referees = [];
        for (const [key, value] of Object.entries(users)) {
            //if (referees.length < 5) {
            if (organization.refereeIds != null && Object.values(organization.refereeIds).includes(key)) {
                referees.push(value);
            }
        }
        let startWeek = getWeekStartDay(season.startTime);
        let teamIds = group != null ? group.teamIds : season.teamIds;
        
        const refereeAvatar = game.refereeId && users[game.refereeId] ? {avatar: users[game.refereeId].photoUri, name: users[game.refereeId].username} : null;

        const avatars = [];

        if (users && game.referees) {
            for (const [key, ] of Object.entries(game.referees)){
                const item = users[key];
                if (item != null) {
                    avatars.push(
                        {avatar: item.photoUri, name: item.username}
                    )
                }
            }
        }

        if (game != null) {
            return (
                <div>
                    <Row className="shadow-tile-fixed">
                        <Col sm={12} style={{margin: '10px 15px'}}>
                            <div className='wrap-content-parent'>
                                <div className="wrap-content-fill-child" style={{fontSize: 20, fontWeight: 600}}>
                                    Wk. {parseInt((game.start - startWeek) / WEEK_TIME) + 1} {new Date(game.start).toLocaleString('default', { weekday: 'short' })}
                                </div>
                                <div className='wrap-content-parent' style={{width: 200, margin: '0px 20px'}}>
                                    <CardImg 
                                        className='wrap-content-wrap-child'
                                        src={this.getLocation(game.locationId, locations).thumbUri}
                                        style={{width: 25, height: 20, marginRight: 15, marginTop: 5}}/>
                                    <Input type="select" name="select" id="homeChoice" value={game.locationId} className='wrap-content-fill-child' onChange={e=> {
                                        if (locationUpdate != null) {
                                            locationUpdate(e.target.value)
                                        }
                                    }}>
                                        <option value={null}></option>
                                        {Object.values(division.locationIds != null ? division.locationIds : {}).map((locationId,i)=>(
                                            <option key={locationId} value={locationId}>{this.getLocation(locationId, locations).title}</option>
                                        ))
                                        }
                                    </Input>
                                </div>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        if (removeClicked != null) {
                                            removeClicked()
                                        }
                                    }}><RiDeleteBin2Line/></Button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(game.homeTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.homeTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (onHomeUpdate != null) {
                                        onHomeUpdate(e.target.value)
                                    }
                                }}>
                                    <option value={null}></option>
                                    {Object.values(teamIds).map((teamId,i)=>(
                                        <option key={teamId} value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent text-center' style={{margin: '10px 0px'}}>
                                <div 
                                    className='wrap-content-wrap-child'
                                    style={{width: 25, height: 20, marginRight: 15, marginTop: 'auto', marginBottom: 'auto'}}>
                                    {t('start')} 
                                </div>
                                <div className='wrap-content-wrap-child'>
                                    <CustomDateTimePicker
                                        margin="normal"
                                        id="date-picker-dialog"
                                        format="MM/DD/YYYY hh:mm"
                                        onChange={(val) => {
                                            if (gameTimeUpdate != null) {
                                                gameTimeUpdate(val.valueOf(), null)
                                            }
                                        }}
                                        value={new Date(game.start)} />
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} xs={12}>
                            <div className='wrap-content-parent'>
                                <CardImg 
                                    className='wrap-content-wrap-child'
                                    src={this.getTeamIcon(game.awayTeam, teams, organization)}
                                    style={{width: 25, height: 25, marginTop: 5, marginRight: 15}}/>
                                <Input type="select" name="select" id="homeChoice" value={game.awayTeam} className='wrap-content-fill-child' onChange={e=> {
                                    if (onAwayUpdate != null) {
                                        onAwayUpdate(e.target.value)
                                    }
                                }}>
                                    <option value={null}></option>
                                    {Object.values(teamIds).map((teamId,i)=>(
                                        <option key={teamId} value={teamId}>{this.getTeamTitle(teamId, teams, organization)}</option>
                                    ))
                                    }
                                </Input>
                            </div>
                            <div className='wrap-content-parent' style={{marginTop: 20}}>
                                <div className="wrap-content-wrap-child">
                                {refereeAvatar ? (
                                    <Avatar src={refereeAvatar.avatar} name={refereeAvatar.name} />
                                ) : (
                                    <div>{t('no_referee')}</div>
                                )}
                                </div>
                                <div className="wrap-content-fill-child">
                                {avatars.length > 0 && (
                                    <HorizontalAvatarList avatars={avatars} />
                                )}
                                </div>
                                <div className='wrap-content-wrap-child vertical-center-spacing'>
                                    <Button className="btn-cancel" onClick={e=> {
                                        this.setState({pickReferee: true});
                                    }}>{t('pick_referee')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {game.overlapped && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('timeline_overlap')}
                        </div>
                    )}
                    {game.refereeOverlap && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('referee_overlap')}
                        </div>
                    )}
                    {!game.valid && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {subSeason.matchCount != null && subSeason.matchCount !== -1 ? t('home_invalid_count') : t('home_away_duplicated')}
                        </div>
                    )}
                    {subSeason.matchCount != null && subSeason.matchCount !== -1 && game.awayValid === false && (
                        <div style={{color: 'red', fontSize: 12}}>
                        <MdWarning/> {t('away_invalid_count')}
                        </div>
                    )}
                    {pickReferee && (
                    <Modal isOpen={true}>
                        <ModalBody>
                            <h3>{t('pick_referee')}</h3>
                            <div className="shadow-tile-small">
                                <Row style={{backgroundColor: '#19447F'}}>
                                    <Col xs={12} className="vertical-center">
                                        <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                            {t('referees')}
                                        </h3>
                                    </Col>
                                </Row>
                                <div className='divider' />
                                <div className='divider' />
                                {referees.length > 0 ? referees.map((user, index) => (
                                <Row key={index}>
                                    <Col xs={12} sm={6} className="vertical-center">
                                        <div className='wrap-content-parent'>
                                            <CardImg
                                                className="wrap-content-wrap-child row-badge-icon"
                                                src={user.photoUri != null ? user.photoUri : userImage} />
                                            <h4 className='wrap-content-fill-child row-badge-title'>
                                            {user.username}
                                            </h4>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} className="vertical-center">
                                        <Input type="select" name="select" value={game.refereeId === user.uid ? 1 : (game.referees && Object.values(game.referees).includes(user.uid) ? 2 : 0)} onChange={e=> {
                                            const update = parseInt(e.target.value);
                                            
                                            let refereeId = game.refereeId;
                                            let referees = game.referees;
                                            if (update === 1) {
                                                refereeId = user.uid;
                                            } else if (update === 2) {
                                                if (!referees) {
                                                    referees = {};
                                                }
                                                referees[user.uid] = user.uid;
                                            } else if (refereeId === user.uid) {
                                                refereeId = null;
                                            } else if (referees) {
                                                delete referees[user.uid];
                                            }
                                            refereeUpdate(refereeId, referees);
                                        }}>
                                            <option value={0}></option>
                                            <option value={1}>{t('referee')}</option>
                                            <option value={2}>{t('assist_referee')}</option>
                                        </Input>
                                    </Col>
                                    <Col xs={12} className="divider" />
                                </Row>
                                )) : (
                                <div style={{height: 80, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                                {t('no_items_to_show')}
                                </div>
                                )}
                            </div>
                            <Button className="btn-cancel" onClick={e => {
                                this.setState({pickReferee: false});
                            }}>{t('close')}</Button>
                        </ModalBody>
                    </Modal>
                    )}
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default GameSetupBadge;