import React, { Component } from "react";
import { userActions } from 'redux/_actions';
import { connect } from 'react-redux';
import {
    Card, Row, Col, FormGroup, Input, Button, CardImg, Modal, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import { t } from 'i18next';

import { 
    sortByTitle,
} from 'utils/Utils';

import SubInfoView from 'components/detail/SubInfoView';
import lineupImage from 'assets/img/icons/lineup.png';
import gameImage from 'assets/img/icons/league_default.png';
import teamIcon from 'assets/img/icons/shield_icon.png';
import venueIcon from 'assets/img/icons/venue_icon.png';
import playersIcon from 'assets/img/icons/players_icon.png';
import TopPlayer from 'components/detail/organization/landing/TopPlayer';
import DivisionLocationsView from 'components/detail/organization/DivisionLocationsView';
import ClubEditView from 'components/edit/club/ClubEditView';
import { getPlayerStatisticsList } from 'utils/statistics';
import { toDateString, renderHtmlString } from 'utils/Utils';
import divisionIcon from 'assets/img/logo/live_192.png';
import emailIcon from 'assets/img/icons/ic_email.png';
import phoneIcon from 'assets/img/icons/ic_phone.png';
import calendarIcon from 'assets/img/icons/games_icon.png';
import deadlineIcon from 'assets/img/icons/time_icon.png';
import genderIcon from 'assets/img/icons/ic_gender.png';
import typeIcon from 'assets/img/icons/instruction_3.png';
import userImage from 'assets/img/icons/player_photo_default.png';
import defaultImage from 'assets/img/icons/club_icon_default.png';
import OrganizationProfileEdit from 'components/edit/organization/OrganizationProfileEdit';
import Switch from '@mui/material/Switch';
import AttachItemView from 'components/detail/AttachItemView';
import { getOrgDomain } from 'utils/Utils';
import { invokeHttpsApi } from "utils/API";
import { withRouter } from "utils/Routher";
import { CoachSeasonRegister } from "components/detail/organization/landing/register/CoachSeasonRegister";
import { PlayerSeasonRegister } from "components/detail/organization/landing/register/PlayerSeasonRegister";
import { PlayerEnrollOverlay } from "components/detail/payment/PlayerEnrollOverlay";
import { TeamEnrollOverlay } from "components/detail/payment/TeamEnrollOverlay";

class OrganizationSeasonOverview extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            detailChoice: {},
            checked: {},
            editOpen: false,
            saveProgress: false,
            playerEnroll: false,
            coachEnroll: false,
        }
    }
    
    componentDidMount() {
        const searchParams = this.props.router.searchParams;
        const playerId = searchParams.get("playerId");
        const teamId = searchParams.get("teamId");
        if (playerId) {
            this.setState({playerRegister: true});
        }
        if (teamId) {
            this.setState({teamRegister: true});
        }
    }

    
    renderDivision() {
        const {divisions, seasonChoice, locations} = this.props;
        const division = divisions[seasonChoice.divisionId];
        
        return (
            <DivisionLocationsView 
                division={division}
                locations={locations}  />
        );
    }

    renderCoachRegistration() {
        const { organization, seasonChoice, divisions, myTeams, user, onChanged } = this.props;
        const { teamRegister } = this.state;
        
        const teamsChoice = [];
        for (const team of Object.values(myTeams)) {
            teamsChoice.push(team)
        }
        sortByTitle(teamsChoice)

        if (!user || !user.uid) {
            return (
                <div className='wrap-content-parent'>
                    <Button className='wrap-content-fill-child btn-submit' onClick={e=> {
                        let path = this.props.router.location;
                        if (path.includes('championships')) {
                            path = `${getOrgDomain(organization)}/championships/${seasonChoice.id}`
                        } else if (path.includes('register')) {
                            path = `${getOrgDomain(organization)}/register/${seasonChoice.id}`
                        }
                        this.props.setAuthRedirect(path);
                        this.props.router.navigate('/login');
                    }}>
                    {t('go_login')}
                    </Button>
                </div>
            )
        } else if ((seasonChoice.status === "registration" || !seasonChoice.status) && teamsChoice.length > 0) {
            return (
                <div>
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                    <h3 style={{fontSize: 20}}>{t('team_reg_section')}</h3>
                    <h4 style={{fontSize: 12}}>{t('reg_until')} {toDateString(seasonChoice.regDeadline)}</h4>
                    
                    {seasonChoice && seasonChoice.enrollFee && seasonChoice.enrollFee.teamFee && (
                    <div className='wrap-content-parent'>
                        <div className='reg-price-name'>
                        {t('team_fee')}:
                        </div>
                        <h4 className='reg-price-label'>
                        $ {seasonChoice.enrollFee.teamFee}
                        </h4>
                    </div>
                    )}
                    <div className='wrap-content-parent'>
                        <Button className='wrap-content-fill-child btn-submit' onClick={e=> {
                            this.setState({coachEnroll: true});
                        }}>
                        {t('enroll_now')}
                        </Button>
                    </div>
                    {teamRegister && (
                    <CoachSeasonRegister
                        organization={organization}
                        season={seasonChoice}
                        divisions={divisions}
                        user={user}
                        myTeams={myTeams}
                        onSeasonUpdate={(update) => {
                            this.props.onSeasonChoice(update);
                        }}
                        onCloseClick={() => {
                            this.setState({teamRegister: false});
                        }} />
                    )}
                </div>
            )
        } else if ((seasonChoice.status === "registration" || !seasonChoice.status) && teamsChoice.length === 0) {
            return (
                <div>
                    <h3 style={{fontSize: 15}}>{t('create_team')}</h3>
                    <div className="text-center" style={{marginBottom: 15}}>
                        <ClubEditView 
                            uid={user ? user.uid : null}
                            squad={{}} 
                            createMode={true}
                            onCloseClick={() => {
                                this.setState({createTeam: false})
                            }}
                            onChanged={(squad)=>{
                                if (onChanged) {
                                    onChanged();
                                }
                            }} />
                    </div>
                </div>
            )
        }

        return <div/>
    }
    
    renderPlayerRegistration() {
        const { organization, divisions, seasonChoice, teams, user, users } = this.props;
        const { playerRegister } = this.state;
        
        if (!user || !user.uid) {
            return <div/>
        }
        
        var childList = [];
        var cihldIds = [];
        childList.push(user);
        cihldIds.push(user.uid);
        for (const val of Object.values(users)) {
            if (user && user.uid === val.parentId) {
                childList.push(val);
                cihldIds.push(val.uid);
            }
        }
        childList.sort(function(a, b) {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        })
        let list = [];
        
        if (seasonChoice && seasonChoice.teamData) {
            for (const childId of cihldIds) {
                for (const [teamId, teamData] of Object.entries(seasonChoice.teamData)) {

                    const team = teams[teamId];
                    if (team) {
                        if (teamData.roaster && Object.values(teamData.roaster).includes(childId)) {
                            list.push(
                                <div>{this.renderPlayerSeasonRoster(team, childId)}</div>
                            )
                        }
                    }
                }
            }
        }
        
        if (!user || !user.uid) {
            return (
                <div />
            )
        } else if ((seasonChoice.status === "registration" || !seasonChoice.status) && seasonChoice.individualReg) {
            return (
                <div>
                    <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                    <h3 style={{fontSize: 20}}>{t('player_reg_section')}</h3>
                    {seasonChoice && seasonChoice.enrollFee && seasonChoice.enrollFee.playerFee && (
                    <div className='wrap-content-parent'>
                        <div className='reg-price-name'>
                        {t('player_fee')}:
                        </div>
                        <h4 className='reg-price-label'>
                        $ {seasonChoice.enrollFee.playerFee}
                        </h4>
                    </div>
                    )}
                    {seasonChoice && seasonChoice.enrollFee && seasonChoice.enrollFee.managerFee && (
                    <div className='wrap-content-parent'>
                        <div className='reg-price-name'>
                        {t('staff_fee')}:
                        </div>
                        <h4 className='reg-price-label'>
                        $ {seasonChoice.enrollFee.managerFee}
                        </h4>
                    </div>
                    )}
                    
                    <div className='wrap-content-parent'>
                        <Button className='wrap-content-fill-child btn-submit' onClick={e=> {
                            this.setState({playerEnroll: true});
                        }}>
                        {t('enroll_now')}
                        </Button>
                    </div>
                    {playerRegister && (
                    <PlayerSeasonRegister
                        organization={organization}
                        season={seasonChoice}
                        divisions={divisions}
                        user={user}
                        users={users}
                        onSeasonUpdate={(update) => {
                            this.props.onSeasonChoice(update);
                        }}
                        onCloseClick={() => {
                            this.setState({playerRegister: false});
                        }} />
                    )}
                </div>
            )
        } else if (!seasonChoice.individualReg && list.length > 0) {

            return (
                <div>
                    <h3 style={{fontSize: 15}}>{t('roster_reg')}</h3>                    
                    {list}
                </div>
            )
        }

        return <div/>
    }

    renderPlayerSeasonRoster(team, childId) {
        const {seasonChoice, users, profileData} = this.props;

        const submitClass = 'wrap-content-fill-child btn-submit';
        const greenClass = 'wrap-content-fill-child btn-green';

        const child = users[childId];
        const policyFlag = seasonChoice.policyFlag && seasonChoice.policyFlag[childId];

        let profile = {
            uid: childId,
            parentId: child.parentId,
            playerName: child.username,
            photoUri: child.photoUri,
        };
        const childIcon = child.photoUri ? child.photoUri : userImage;
        const teamIcon = team && team.iconUri ? team.iconUri : defaultImage;

        for (const item of Object.values(profileData)) {
            if (item.uid === childId && item.organizationId === seasonChoice.organizationId) {
                profile = item;
            }
        }

        return (
            <div style={{marginBottom: 10, borderBottom: '1px solid #777'}}>
                {team && (
                    <div className='wrap-content-parent' style={{fontSize: 12, marginBottom: -5}}>
                        <b className='wrap-content-fill-child' style={{textAlign: 'end'}}>
                        {t('team')}:
                        </b>
                        <img className='reg-icon-team' alt="logo" src={teamIcon} />
                        <div className='wrap-content-wrap-child'>
                        {team.title}
                        </div>
                    </div>
                )}
                <div className='wrap-content-parent' style={{fontSize: 14, marginTop: team ? 0 : 10, marginBottom: 10}}>
                    <img className='reg-icon-user' alt="logo" src={childIcon} />
                    <div className='wrap-content-fill-child'>
                    {child.username}
                    </div>
                </div>
                <div className='wrap-content-parent' style={{marginBottom: 5}}>
                    <Button className={profile.id ? greenClass : submitClass} onClick={e=> {
                        this.setState({profileChoice: profile, userChoice: child})
                    }}>
                    {profile.id ? t('view_profile') : t('submit_profile')}
                    </Button>
                    <Button className={policyFlag ? greenClass : submitClass} onClick={e=> {
                        this.setState({userChoice: child, showPolicy: true})
                    }}>
                    {policyFlag ? t('show_policy') : t('ack_policy')}
                    </Button>
                </div>
            </div>
        )
    }
    
    renderRegistration() {
        const { seasonChoice, divisions } = this.props;
        const division = divisions[seasonChoice.divisionId];

        let icon = division.thumbUri != null ? division.thumbUri : divisionIcon;

        return (
            <Card className="shadow-tile-i" style={{padding: '25px 30px'}}>
                <h3 className=''>{division.title}</h3>
                <h3 style={{fontSize: 15}}>{seasonChoice.title}</h3>
                <p className='' style={{fontSize: 14}}>
                {t('team_reg_text_1')}
                </p>
                <p className='' style={{fontSize: 14}}>
                {t('team_reg_text_2')}
                </p>
                <p className='' style={{fontSize: 14}}>
                {t('team_reg_text_3')}
                </p>
                
                {this.renderCoachRegistration()}
                {this.renderPlayerRegistration()}
                <div className='divider' style={{marginTop: 10, marginBottom: 20}}/>
                <div style={{fontSize: 14, marginBottom: 15}}>Contact Information:</div>
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={icon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <h3 style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {division.title}
                        </h3>
                    </div>
                </div>
                {division.contactEmail && (
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={emailIcon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <div style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {division.contactEmail}
                        </div>
                    </div>
                </div>
                )}
                {division.contactPhone && (
                <div className='text-center'>
                    <div style={{display: 'inline', margin: '5px auto'}}>
                        <CardImg
                            src={phoneIcon}
                            style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                        <div style={{display: 'inline', fontSize: 18, marginTop: 5}}>
                        {division.contactPhone}
                        </div>
                    </div>
                </div>
                )}
            </Card>
        );
    }

    renderStatistics() {
        const {organization, seasonChoice, seasons, divisions, users} = this.props;
        const division = divisions[seasonChoice.divisionId];

        let statistics = getPlayerStatisticsList(seasons, division != null ? division.id : null, organization != null ? organization.id : null, null, divisions);
        let topScore = [];
        for (const val of Object.values(statistics)) {
            topScore.push(val);
        }
        topScore.sort((a,b)=> {
            return b.score - a.score
        });
        let topAssist = [];
        for (const val of Object.values(statistics)) {
            topAssist.push(val);
        }
        topAssist.sort((a,b)=> {
            return b.assist - a.assist
        });

        let topScoreOne = topScore.length > 0 ? topScore[0] : {};
        let topAssistOne = topAssist.length > 0 ? topAssist[0] : {};
        
        let items = [];
        
        return (
            <div>
                <div className='shadow-tile-i' style={{marginBottom: 20}}>
                    <Row style={{backgroundColor: '#19447F'}}>
                        <Col xs={12}>
                            <h3 className='row-table-title' style={{color: 'white', textAlign: 'left', fontSize: 18, padding: '5px 20px'}}>
                                {t('trophy')}
                            </h3>
                        </Col>
                    </Row>
                    {items.length > 0 ? items : (
                        <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                        {t('no_data')}
                        </div>
                    )}
                </div>
                <TopPlayer
                    users={users}
                    uid={topScoreOne.uid}
                    number={topScoreOne.score}
                    title={t('top_socorer')}
                    subtitle={t('goals')} />
                <TopPlayer
                    users={users}
                    uid={topAssistOne.uid}
                    number={topAssistOne.assist}
                    title={t('top_assist')}
                    subtitle={t('assists')} />
            </div>
        );
    }

    onRequest(teamId, label) {
        const {seasonChoice} = this.props;
        
        const data = {
            teamId: teamId,
            label: label,
            season: seasonChoice,
        }
        invokeHttpsApi('clubQuery-requestSeasonClub', data, (data) => {

            if (data.success) {
                alert(t('saved_successfully'))
                this.setState({
                    progress: false,
                });
            } else {
                alert(t('save_failed'));
                this.setState({progress: false});
            }
        }, (error) => {
            console.log('save error', error);
            alert(t('save_failed') + '\n' + error);
            this.setState({progress: false});
        });
    }

    renderSeasonPolicy(user) {
        const { seasonChoice, divisions, onChanged } = this.props;
        const { checked, progress } = this.state;
        const division = divisions[seasonChoice.divisionId];

        if (division.policy && user && user.uid) {
            
            let items = [];
            let allCheck = true;
            for (const [key, value] of Object.entries(division.policy)) {
                if (!checked[key]) {
                    checked[key] = {};
                }
                if (!checked[key][user.uid]) {
                    allCheck = false;
                }
                items.push(value);
            }

            return (
                <div>
                    <h2 className="post-detail-title">
                    {t('privacy_policy')}
                    </h2>
                    <div style={{marginTop: 30}}>
                        {items.map((value, idx)=>(
                            <div key={idx}>
                                <h2 className="post-detail-title">
                                {value.title}
                                </h2>
                                <div className='app'>
                                    {renderHtmlString(value.body ? value.body : "")}
                                </div>
                                <div className='player-form-item'>
                                    <div className='player-form-title'>
                                        {t('ack_agree')}
                                    </div>
                                    <div className='player-form-value form-setting-control'>
                                        <Switch
                                            checked={checked[value.id] && Object.values(checked[value.id]).includes(user.uid)}
                                            onChange={e=>{
                                                const update = e.target.checked;
                                                if (update) {
                                                    checked[value.id][user.uid] = user.uid;
                                                } else {
                                                    delete checked[value.id][user.uid]
                                                }
                                                this.setState({checked: checked});
                                            }}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                    <div>
                        {allCheck && (
                            <Button className='btn-submit' onClick={e => {
                                this.setState({progress: true})
                                invokeHttpsApi('userQuery-submitSeasonAgree', {
                                    agree: true,
                                    seasonId: seasonChoice.id,
                                    userId: user.uid,
                                }, (data) => {
                                    // Read result of the Cloud Function.
                                    if (!seasonChoice.policyFlag) {
                                        seasonChoice.policyFlag = {};
                                    }
                                    seasonChoice.policyFlag[user.uid] = true;
                                    this.setState({progress: false})
                                    this.props.onSeasonChoice(seasonChoice);
                                    if (onChanged != null) {
                                        onChanged();
                                    }
                                }, (error) => {
                                    console.log('saveContents error', error);
                                    this.setState({progress: false})
                                });
                            }}>{t('agree')}</Button>
                        )}
                        {seasonChoice.policyFlag && seasonChoice.policyFlag[user.uid] && (
                            <Button className='btn-cancel' onClick={e => {
                                this.setState({progress: true})
                                invokeHttpsApi('userQuery-submitSeasonAgree', {
                                    agree: false,
                                    seasonId: seasonChoice.id,
                                    userId: user.uid,
                                }, (data) => {
                                    // Read result of the Cloud Function.
                                    if (!seasonChoice.policyFlag) {
                                        seasonChoice.policyFlag = {};
                                    }
                                    seasonChoice.policyFlag[user.uid] = false;
                                    this.setState({progress: false})
                                    this.props.onSeasonChoice(seasonChoice);
                                    if (onChanged != null) {
                                        onChanged();
                                    }
                                }, (error) => {
                                    console.log('saveContents error', error);
                                    this.setState({progress: false})
                                });
                            }}>{t('decline')}</Button>
                        )}
                        <Button className='btn-cancel' onClick={e => {
                            this.setState({showPolicy: false});
                        }}>{t('close')}</Button>
                    </div>
                    )}
                </div>
            )
        } else {
            return <div />
        }
    }

    render() {
        const {seasonChoice, organization, divisions, seasons, users, myTeams, profileData, onChanged } = this.props;
        const {playerEnroll, coachEnroll, checkoutProgress, profileChoice, userChoice, showPolicy} = this.state;
        const division = divisions[seasonChoice.divisionId];
        
        let teamCount = division.teamIds == null ? 0 : Object.values(division.teamIds).length;
        let locationCount = division.locationIds == null ? 0 : Object.values(division.locationIds).length;
        let playerCount = 0;
        let gameCount = 0;
        let playerIds = [];
        if (seasonChoice && seasonChoice.teamData) {
            for (const teamData of Object.values(seasonChoice.teamData)) {
                if (teamData.roaster) {
                    for (const uid of Object.values(teamData.roaster)) {
                        if (!playerIds.includes(uid)) {
                            playerIds.push(uid);
                        }
                    } 
                }
            }
        }
        playerCount = playerIds.length;
        const seasonsChoice = [];
        for (const season of Object.values(seasons)) {
            if (season.divisionId === division.id) {
                seasonsChoice.push(season)
            }
        }
        seasonsChoice.sort(function(a, b) {
            return b.startTime - a.startTime;
        })

        for (const season of seasonsChoice) {
            if (season.subSeasons != null) {
                for (const subSeason of Object.values(season.subSeasons)) {
                    gameCount += subSeason.games != null ? Object.values(subSeason.games).length : 0;
                }
            }
        }
        const playerLimit = division != null && division.category != null && division.category.playerCount != null ? division.category.playerCount : 10;
        
        if (division != null) {
            return (
                <div className="org-div-wrapper">
                    <Row style={{margin: 0}}>
                        <Col sm={8} xs={12} className="org-div-left">
                            <div style={{position: 'relative'}}>
                                <h4 className='grid-item-title' style={{position: "absolute", backgroundColor: '#19447f', padding: '5px 10px' , margin: '-5px 5px 0px', color: 'white', zIndex: 500}}>
                                {division.title}
                                </h4>
                                <h3 className='grid-item-title' style={{
                                    position: "absolute", 
                                    right: 0, 
                                    color: '#28CF14', 
                                    textTransform: 'uppercase',
                                    backgroundColor: '#D0F5CC', 
                                    padding: '5px 10px' , 
                                    margin: '-5px 5px 0px', 
                                    zIndex: 500}}>
                                {seasonChoice.status != null ? seasonChoice.status : 'Registration'}
                                </h3>
                                <Card className="shadow-tile-i grid-badge" style={{margin: '10px 20px'}}>
                                    <Row style={{color: '#3D5C97', margin: 10}}>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <div className='grid-item-subInfo' style={{ flex: '0 0 auto'}}>
                                                <FormGroup className='wrap-content-fill-child' style={{margin: '5px 10px 5px 0px'}}>
                                                    <Input type="select" name="select" value={seasonChoice.id} id="challengeChoice" onChange={e=>{
                                                            const id = e.target.value;
                                                            const update = seasons[id];
                                                            this.props.onSeasonChoice(update == null ? {} : update);
                                                        }}>
                                                        {seasonsChoice.map((season)=> (
                                                            <option key={season.id} value={season.id}>{season.title}</option>
                                                        )) }
                                                    </Input>
                                                </FormGroup>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={genderIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <div className='grid-item-subInfo'>
                                                {division.gender != null ? division.gender : 'No Gender'} -
                                                {division.category != null ? division.category.title : 'No Cat'} -
                                                {division.ageTitle != null ? division.ageTitle : 'None'} 
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            {seasonChoice.seasonDoc && (
                                            <div className='wrap-content-parent'>
                                                <AttachItemView label="Season Document" attachFile={seasonChoice.seasonDoc} />
                                            </div>
                                            )}
                                        </Col>
                                        <Col lg={4} sm={4} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={deadlineIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <h4 className='grid-item-subInfo' style={{ flex: '0 0 auto', backgroundColor: '#FFD2D2', padding: '5px 10px' , color: '#FF6E6E'}}>
                                                {toDateString(seasonChoice.regDeadline)}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={calendarIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <h4 className='grid-item-subInfo' style={{ flex: '0 0 auto', backgroundColor: '#D3E0F1', padding: '5px 10px' , color: '#19447f'}}>
                                                {toDateString(seasonChoice.startTime)}
                                                </h4>
                                            </div>
                                        </Col>
                                        <Col xl={4} lg={4} sm={6} xs={12} style={{padding: '5px 0px', margin: 'auto 0px'}}>
                                            <div className='wrap-content-parent'>
                                                <CardImg
                                                    src={typeIcon}
                                                    style={{display: 'inline', width: 25, height: 25, objectFit: 'contain', marginRight: 10}} />
                                                <div className='grid-item-subInfo' style={{textTransform: 'uppercase'}}>
                                                {seasonChoice.type != null ? seasonChoice.type : 'None'}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div style={{marginTop: 10}}/>
                            <div className='text-center'>
                                <SubInfoView fixed title='Teams' icon={teamIcon} number={teamCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Players' icon={playersIcon} number={playerCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Format' icon={lineupImage} subText={playerLimit.toString() + ' v ' + playerLimit.toString()} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Venues' icon={venueIcon} number={locationCount} />
                                <div style={{display: 'inline-block',padding: '0px 10px'}}/>
                                <SubInfoView fixed title='Games' icon={gameImage} number={gameCount} />                            
                            </div>
                            {this.renderDivision()}
                        </Col>
                        <Col sm={4} xs={12} className="org-div-right">
                            {this.renderRegistration()}
                        </Col>
                    </Row>
                    <Modal isOpen={profileChoice}>
                        <ModalBody style={{marginTop: 30}}>
                            <OrganizationProfileEdit
                                organization={organization}
                                user={userChoice}
                                profileData={profileChoice}
                                onSaveDone={()=>{
                                    if (onChanged) {
                                        onChanged()
                                    }
                                }}
                                onCloseClick={()=>{
                                    this.setState({profileChoice: null});
                                }} />
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={userChoice && userChoice.uid && showPolicy}>
                        <ModalBody style={{marginTop: 30, maxHeight: 500, overflowY: 'scroll'}}>
                            {this.renderSeasonPolicy(userChoice)}
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={checkoutProgress}>
                        <ModalBody>
                            <div style={{width: '100%', height: '80vh'}}>
                                <div className="center text-center">
                                    <h4>Redirecting to checkout screen. Please wait ...</h4>
                                    <Spinner color="primary" />
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    {playerEnroll && 
                    <PlayerEnrollOverlay
                        organization={organization}
                        season={seasonChoice}
                        profileData={profileData}
                        users={users}
                        onCloseClick={e => {
                            this.setState({playerEnroll: false});
                        }} />
                    }
                    {coachEnroll && 
                    <TeamEnrollOverlay
                        organization={organization}
                        season={seasonChoice}
                        profileData={profileData}
                        teams={myTeams}
                        onCloseClick={e => {
                            this.setState({coachEnroll: false});
                        }} />
                    }
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { isAdmin, loggingIn, user, redirectTo } = state.authentication;
    return { isAdmin, loggingIn, user, redirectTo };
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    setAuthRedirect: userActions.setAuthRedirect,
};

const connectedNavigation = withRouter(connect(mapState, actionCreators)(OrganizationSeasonOverview));
export { connectedNavigation as OrganizationSeasonOverview };