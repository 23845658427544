import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AttachVideoEdit from 'components/edit/AttachVideoEdit';
import CategoryChoice from 'components/edit/CategoryChoice';
import CategoryTags from 'components/edit/CategoryTags';

import { 
    uploadVideoToStorage, 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class ClubLessonEditView extends Component {
    constructor(props) {
        super(props);
        const clubLesson = {};
        if (props.clubLesson != null) {
            for (const [key, value] of Object.entries(props.clubLesson)) {
                clubLesson[key] = value;
            }
        }
        
        this.state = {
            clubLesson: clubLesson,
            progress: false,
            newImage: {},
            newVideo: {},
            videoProgress: 0,
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubLesson} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                newFile: {},
                newVideo: {},
                videoProgress: 0,
                imageProgress: 0
            })
        }
        if ((prevProps.clubLesson !== clubLesson)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(clubLesson)) {
                challengeVal[key] = value;
            }
            this.setState({
                clubLesson: challengeVal
            })
        }
    }

    onSaveClick(clubLesson, newImage, newVideo) {
        const {uid} = this.props;
        const objRef = this;
        if (clubLesson.title == null || clubLesson.title === '') {
            alert("You must input title");
        } else if (clubLesson.description == null || clubLesson.description === '') {
            alert("You must input description");
        } else if ((clubLesson.id == null || clubLesson.id === '') && (newVideo == null || newVideo.name == null)) {
            alert("You must input video");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubLesson.id == null || clubLesson.id === '') {
                clubLesson.id = generateId("/clubLesson");
                clubLesson.owner = uid != null ? uid : "free";
            }
            clubLesson.timestamp = dbTimestamp();
            
            if (newVideo != null && newVideo.name != null) {
                this.saveChangeWithVideo(clubLesson, newVideo, (clubLesson, challengeVideo) => {
                    this.saveChangeWithImage(clubLesson, newImage, (update) => {
                        alert("Your Change subimitted successfully.");
                        objRef.setState({
                            clubLesson: update,
                            challengeVideo: challengeVideo,
                            progress: false
                        })
                    })
                })
            } else {
                this.saveChangeWithImage(clubLesson, newImage, (update) => {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        clubLesson: update,
                        progress: false
                    })
                })
            }
        }
    }

    saveChangeWithVideo(clubLesson, newVideo, sucess) {
        const objRef = this;

        uploadVideoToStorage(clubLesson.id, newVideo, 'ClubLesson', url => {
            clubLesson.videoUri = url;
    
            sucess(clubLesson);
        }, (state, progress) => {
            if (state === 'running') {
                this.setState({videoProgress: progress});
            } else {
                this.setState({videoProgress: 0});
            }
        }, error => {
            alert("Saving Video File Failed.\n" + error);
            objRef.setState({
                progress: false
            })
        })
    }

    saveChangeWithImage(clubLesson, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubLesson.id, 'ClubLesson', newImage, (url) => {
                clubLesson.thumbUri = url;
                objRef.saveChange(clubLesson, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(clubLesson, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    clubLesson: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(clubLesson, success, failure) {
        writeDb('/clubLesson/' + clubLesson.id, clubLesson, error => {
            if (error) {
                failure(error);
            } else {
                success(clubLesson);
            }
        })
    }

    render() {
        const {categorys, onCloseClick} = this.props;
        const {clubLesson, newImage, newVideo, progress, imageProgress, videoProgress} = this.state;
        
        let categoryChoice = clubLesson.categoryId != null ? categorys[clubLesson.categoryId] : {};
        if (categoryChoice == null) {
            categoryChoice = {};
        }
        
        let tagStrs = clubLesson.tags != null ? clubLesson.tags.split(',') : [];  
        let tags = [];
        for (const txt of tagStrs) {
            tags.push({id: txt, text: txt});
        }
        if (clubLesson != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubLesson.title = e.target.value;
                            this.setState({clubLesson: clubLesson});
                        }} value={clubLesson.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubLesson.description} onChange={e => {
                            clubLesson.description = e.target.value;
                            this.setState({clubLesson: clubLesson});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <CategoryChoice
                            title="Club Lesson Category"
                            categorys={categorys}
                            categoryChoice={categoryChoice}
                            onItemChoice={(item) => {
                                clubLesson.categoryId = item.id;
                                clubLesson.categoryTitle = item.title;
                                this.setState({clubLesson: clubLesson});
                            }} />
                        <CategoryTags
                            tags={tags}
                            category={categoryChoice}
                            onTagUpdate={(update) => {
                                let tagStr = "";
                                if (update != null) {
                                    for (var i = 0; i < update.length; i ++) {
                                        tagStr += (tagStr === "" ? "" : ",") + update[i];
                                    }
                                }
                                if (update != null) {
                                    clubLesson.tags = tagStr
                                } else {
                                    delete clubLesson.tags
                                }
                                this.setState({clubLesson: clubLesson});
                            }} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubLesson.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    <AttachVideoEdit
                        videoUrl={clubLesson.videoUri}
                        videoClass='default-player'
                        label='Lesson Video'
                        allowProgress={progress}
                        progress={videoProgress}
                        onChange={(file)=> {
                            this.setState({newVideo: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubLesson, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubLessonEditView);