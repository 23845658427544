import { getFunctions, httpsCallable } from 'firebase/functions';
import { 
    getDatabase, 
    ref, 
    onValue, 
    get, 
    set, 
    push, 
    query, 
    orderByChild, 
    equalTo, 
    serverTimestamp,
} from "firebase/database";

export const generateId = (path) => {
    return push(ref(getDatabase(), path)).key;
}

export const listenDbQuery = (path, id, value, onChange) => {
    onValue(query(ref(getDatabase(), path), orderByChild(id), equalTo(value)), (snapshot) => {
        const data = snapshot.val();

        onChange(data);
    })
}

export const listenDb = (path, onChange) => {
    return onValue(ref(getDatabase(), path), (snapshot) => {
        const data = snapshot.val();

        onChange(data);
    })
}

export const readDb = (path, onChange) => {
    get(ref(getDatabase(), path), (snapshot) => {
        const data = snapshot.val();

        onChange(data);
    })
}

export const writeDb = (path, data, finished) => {
    set(ref(getDatabase(), path), data)
    .then(() => {
        if (finished) {
            finished(null);
        }
    })
    .catch((error) => {
        if (finished) {
            finished(error);
        }
    });
}

export const invokeHttpsApi = (functionName, params, success, failure) => {
    const functions = getFunctions();
    const func = httpsCallable(functions, functionName);
    func(params)
    .then((result) => {
        const data = result.data;

        success(data);
    })
    .catch((error) => {
        console.log('https api error', error);
        failure(error);
    });
}

export const dbTimestamp = () => {
    return serverTimestamp();
}

export const saveOrganization = (organization, success, failure) => {
    invokeHttpsApi('organizerQuery-submitOrganizationUpdate', {
        organization: organization,
    }, (data) => {

        if (data && data.success) {
            if (success) {
                success(organization);
            }
        } else {
            if (failure) {
                failure("failed");
            }
        }
    }, (error) => {
        console.log('save contents error', error);
        if (failure) {
            failure(error);
        }
    });
}

export const saveDivision = (division, success, failure) => {
    invokeHttpsApi('organizerQuery-submitDivisionUpdate', {
        division: division,
    }, (data) => {

        if (data && data.success) {
            if (success) {
                success(division);
            }
        } else {
            if (failure) {
                failure("failed");
            }
        }
    }, (error) => {
        console.log('save contents error', error);
        if (failure) {
            failure(error);
        }
    });
}

export const saveSeason = (season, success, failure) => {
    invokeHttpsApi('organizerQuery-submitSeasonUpdate', {
        season: season,
    }, (data) => {

        if (data && data.success) {
            if (success) {
                success(season);
            }
        } else {
            if (failure) {
                failure("failed");
            }
        }
    }, (error) => {
        console.log('save contents error', error);
        if (failure) {
            failure(error);
        }
    });
}
