import React, { Component } from "react";
import {
    Card, CardBody, CardImg, Button, Col, Row
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import defaultImage from 'assets/img/icons/shield_icon.png';
import defaultLogo from 'assets/img/icons/website_icon.png';

export class OrganizationBadge extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editOpen: false,
            saveProgress: false,
        }
    }
    
    render() {
        const {organization, onClickDetails, onClickEdit, onClickView } = this.props;
        let challengeImage = organization.thumbUri != null ? organization.thumbUri : defaultImage;
        
        if (organization != null) {
            return (
                <div className="info-badge-medium">
                    <Card className="flex-row shadow-tile">
                        <CardImg
                            className="profile-thumb info-badge-icon-medium"
                            src={challengeImage} />
                        <CardBody className="info-badge-body-small">
                            <Row>
                                <Col nm={8} xs={12} sm={6} className="vertical-center">
                                    <h4 className='label label-default one-line-title'>
                                    {organization.title}
                                    </h4>
                                    <div className="one-line-div">{organization.description}</div>
                                </Col>
                                <Col nm={8} xs={12} sm={6} className="vertical-center">
                                    <div className='wrap-content-parent'>
                                        <div className='wrap-content-fill-child vertical-center-spacing'>
                                        </div>
                                        <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                            {onClickDetails != null && (
                                                <Button className="btn-submit" onClick={e => {
                                                    if (onClickDetails != null) {
                                                        onClickDetails();
                                                    }
                                                }}>Details</Button>
                                            )}
                                            {onClickView != null && (
                                                <Button className="btn-pick" onClick={e => {
                                                    if (onClickView != null) {
                                                        onClickView();
                                                    }
                                                }}> <img src={defaultLogo} style={{width: 20, height: 20, margin: 'auto 10px'}} alt='logo'/> View Website</Button>
                                            )}
                                            <Button className="btn-edit" onClick={e => {
                                                if (onClickEdit != null) {
                                                    onClickEdit();
                                                }
                                            }}>{t('edit')}</Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div style={{marginTop: 10}}/>
                </div>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(OrganizationBadge);