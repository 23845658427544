import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";

export class ClubTrackEditView extends Component {
    constructor(props) {
        super(props);
        const clubTrack = {};
        if (props.clubTrack != null) {
            for (const [key, value] of Object.entries(props.clubTrack)) {
                clubTrack[key] = value;
            }
        }
        
        this.state = {
            clubTrack: clubTrack,
            progress: false,
            newImage: {},
            imageProgress: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubTrack} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.clubTrack !== clubTrack)) {
            const challengeVal = {};
            for (const [key, value] of Object.entries(clubTrack)) {
                challengeVal[key] = value;
            }
            this.setState({
                clubTrack: challengeVal
            })
        }
    }

    onSaveClick(clubTrack, newImage) {
        const {uid} = this.props;
        const objRef = this;
        if (clubTrack.title == null || clubTrack.title === '') {
            alert("You must input title");
        } else if (clubTrack.description == null || clubTrack.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubTrack.id == null || clubTrack.id === '') {
                clubTrack.id = generateId("/clubTrack");
                clubTrack.owner = uid != null ? uid : "free";
            }
            clubTrack.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(clubTrack, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    clubTrack: update,
                    progress: false
                })
            })
        }
    }

    saveChangeWithImage(clubTrack, newImage, success) {
        const objRef = this;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubTrack.id, 'ClubTrack', newImage, (url) => {
                clubTrack.thumbUri = url;
                objRef.saveChange(clubTrack, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        } else {
            this.saveChange(clubTrack, (update) => {
                alert("Your Change subimitted successfully.");
                objRef.setState({
                    clubTrack: update,
                    progress: false
                })
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                objRef.setState({
                    progress: false
                })
            })
        }
    }

    saveChange(clubTrack, success, failure) {
        writeDb('/clubTrack/' + clubTrack.id, clubTrack, error => {
            if (error) {
                failure(error);
            } else {
                success(clubTrack);
            }
        })
    }

    render() {
        const {onCloseClick} = this.props;
        const {clubTrack, newImage, newVideo, progress, imageProgress} = this.state;
        var timeMode = clubTrack.timeMode != null ? clubTrack.timeMode : false;
        var limitLabel = timeMode ? "Time" : "Distance";
        var limitEditLabel = timeMode ? "Duration Limit (min)" : "Distance Limit (km)";
        
        if (clubTrack != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubTrack.title = e.target.value;
                            this.setState({clubTrack: clubTrack});
                        }} value={clubTrack.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubTrack.description} onChange={e => {
                            clubTrack.description = e.target.value;
                            this.setState({clubTrack: clubTrack});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="backdrop">Limit Choice: {limitLabel}</Label>{' '}
                        <Input type="select" name="backdrop" id="backdrop" onChange={e => {
                            clubTrack.timeMode = e.target.value === "true";
                            this.setState({clubTrack: clubTrack});
                        }}>
                            <option value="false">Distance</option>
                            <option value="true">Time</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{limitEditLabel}</Label>
                        <Input type="number" name="text" id="duration_limit" onChange={e => {
                            if (timeMode) {
                                clubTrack.duration_limit = (e.target.value | 0) * 60;
                            } else {
                                clubTrack.distance = (e.target.value | 0) * 1000;
                            }
                            this.setState({clubTrack: clubTrack});
                        }} value={timeMode ? (clubTrack.duration_limit / 60) : (clubTrack.distance / 1000)} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubTrack.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubTrack, newImage, newVideo);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubTrackEditView);