import React, { Component } from "react";
import { cartActions } from 'redux/_actions';
import { connect } from 'react-redux';
import {
    Button,
    Spinner,
    Row,
    Col
} from 'reactstrap';
import { t } from 'i18next';
import {
    MdClose,
} from 'react-icons/md';
import {
    RiMoneyDollarBoxFill,
} from 'react-icons/ri';
import { loadStripe } from '@stripe/stripe-js';
import { 
    STRIPE_PUBLISHABLE_KEY,
    getOrgDomain,
} from 'utils/Utils';

import { invokeHttpsApi } from "utils/API";
import { BsCart } from "react-icons/bs";

class OrganizationCart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opend: false,
            wallet: {},
            walletPayments: {},
            users: {},
            balanceInput: 0,
            balanceType: 'direct'
        }
    }

    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        
    }

    loadContents() {
        const {organization} = this.props;
        this.setState({loading: true});
            
        invokeHttpsApi('playerQuery-seasonsQuery', {
            organizationId: organization ? organization.id : null
        }, ((data)=>{
            
            const programs = {};
            const organization = {};
            const programPayments = {};
            const profileData = {};
            const users = {};
            const programSchedules = {};
            const locations = {};
            const category = {};
            const cards = {};
            const clubs = {};
            const divisions = {};
            const seasons = {};
            const posts = {};
            const fundraises = {};
            const fundraisePayments = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.programs)) {
                    programs[key] = value;
                }
                for (const [key, value] of Object.entries(data.organization)) {
                    organization[key] = value;
                }
                for (const [key, value] of Object.entries(data.programPayments)) {
                    programPayments[key] = value;
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.profileData)) {
                    profileData[key] = value;
                }
                for (const [key, value] of Object.entries(data.programSchedules)) {
                    programSchedules[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
                for (const [key, value] of Object.entries(data.clubs)) {
                    clubs[key] = value;
                }
                for (const [key, value] of Object.entries(data.divisions)) {
                    divisions[key] = value;
                }
                for (const [key, value] of Object.entries(data.seasons)) {
                    seasons[key] = value;
                }
                for (const [key, value] of Object.entries(data.cards)) {
                    cards[key] = value;
                }
                for (const [key, value] of Object.entries(data.posts)) {
                    posts[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraises)) {
                    fundraises[key] = value;
                }
                for (const [key, value] of Object.entries(data.fundraisePayments)) {
                    fundraisePayments[key] = value;
                }
            }
            
            this.setState({
                programs: programs,
                clubs: clubs,
                organization: organization,
                programPayments: programPayments,
                schedules: programSchedules,
                locations: locations,
                profileData: profileData,
                divisions: divisions,
                seasons: seasons,
                users: users,
                cards: cards,
                category: category,
                fundraises: fundraises,
                fundraisePayments: fundraisePayments,
                loading: false,
                posts: posts
            })
        }), ((error)=> {
            console.log('loadContents error', error);
            this.setState({
                loading: false,
                refreshTime: new Date().valueOf(),
            })
        }));
    }

    renderCartItem(item) {
        const {users, seasons, programs, clubs} = this.state;

        let msg = t("player_reg_season_label");
        if (item.seasonId && item.playerId) {
            msg = t("player_reg_program_label");
            if (seasons[item.seasonId]) {
                msg += ": " + seasons[item.seasonId].title;
            }
        } else if (item.seasonId && item.teamId) {
            msg = t("team_reg_season_label");
            if (seasons[item.seasonId]) {
                msg += ": " + seasons[item.seasonId].title;
            }
        } else if (item.programId) {
            msg = t("player_reg_program_label");
            if (programs[item.programId]) {
                msg += ": " + programs[item.programId].title;
            }
        }
        let title = "Cart Item";
        if (clubs[item.teamId]) {
            title = clubs[item.teamId].title;
        } else if (users[item.userId]) {
            title = users[item.userId].username;
        }

        return (
            <Row style={{margin: 0}}>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info' style={{color: '#38be55'}}>
                    {item.price}
                    </h4>
                </Col>
                <Col sm={6} className="vertical-center" style={{textAlign: 'left', padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {msg}
                    </h4>
                </Col>
                <Col sm={3} className="vertical-center" style={{padding: 0}}>
                    <h4 className='wallet-table-info'>
                    {title}
                    </h4>
                </Col>
            </Row>
        );
    }
    renderTransactions() {
        const {cart} = this.props;

        const paymentsArr = Object.values(cart);

        
        const items = [];
        for (const item of paymentsArr) {
            items.push(this.renderCartItem(item));
            items.push(<div className='divider' />);
        }

        return (
            <div className='wallet-info-tile'>
                <div className='detail-nav-btn'>
                    <div className='items-navigation-count'><RiMoneyDollarBoxFill/></div>
                    
                    <h3 className='items-navigation-title'>
                    Cart Items
                    </h3>
                </div>
                <Row style={{margin: 0}}>
                    <Col sm={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Price
                        </h4>
                    </Col>
                    <Col sm={6} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        Title
                        </h4>
                    </Col>
                    <Col lg={3} className="vertical-center" style={{padding: 0}}>
                        <h4 className='wallet-table-title'>
                        To Regiser
                        </h4>
                    </Col>
                </Row>
                <div className='divider' />
                <div className='divider' />
                {items.length > 0 ? items : (
                    <div style={{height: 70, paddingTop: 25, fontSize: 14, textAlign: 'center'}}>
                    {t("no_cart_items")}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const {user, organization, cart} = this.props;
        const {loading, opened} = this.state;
        
        if (user != null) {
            if (opened) {
                return (
                    <div className="wallet-tile">
                        <div className="wallet-title-bar">
                            <h3 className="wallet-title">{t('my_cart')}</h3>
                            <Button className="btn-submit wallet-title-action" onClick={e=> {
                                this.setState({opened: false})
                            }}>
                                <MdClose />
                            </Button>
                        </div>
                        
                        <div className="wallet-transactions-list">
                            {loading ? 
                            <div className="text-center">
                                <Spinner color='primary'/>
                            </div>
                             : 
                            this.renderTransactions()
                            }
                        </div>
                        <div className="wrap-content-parent" style={{marginTop: 10, marginBottom: 10}}>
                            <div className="wrap-content-fill-child" />
                            <Button className="btn-blue" onClick={e=>{
                                if (Object.values(cart).length > 0) {
                                    let price = 0;
                                    let url = window.location.origin.startsWith('https') ? window.location.origin : 'https://liveteams.co';
                                    url += getOrgDomain(organization);
                                    for (const item of Object.values(cart)) {
                                        price += item.price;
                                    } 
                                    this.props.checkoutCartItems(organization.id, cart, "", price, url, (async (session) => {
                                        const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);
                                        stripe.redirectToCheckout({ sessionId: session.id });
                                    }), (() => {
                                        alert(t("checkout_session_faild"))
                                    }))
                                }
                            }}>{t("checkout_now")}</Button>
                        </div>
                    </div>
                );
            } else {
                const count = Object.values(cart).length;
                return (
                    <div className="wallet-layout" style={{right: 60}}>
                        <div className="wallet-icon" style={{borderRadius: 23, backgroundColor: '#3063FF', color: 'white', textAlign: 'center', paddingTop: 5, fontSize: 18}} alt="icon"  onClick={e=> {
                            this.setState({opened: true})
                        }}> <BsCart/> </div>    
                        {count > 0 && (
                        <div className="wallet-unread">{count > 9 ? "9+" : count}</div>
                        )}
                    </div>
                );
            }
        } else {
            return <div/>;
        }
    }
}

function mapState(state) {
    const { user } = state.authentication;
    const { checkoutProgress, cart, session } = state.cartData;
    return { user, checkoutProgress, cart, session };
}

const actionCreators = {
    checkoutCartItems: cartActions.checkoutCartItems,
    addToCart: cartActions.addToCart,
    removeFromCart: cartActions.removeFromCart
};

const connected = connect(mapState, actionCreators)(OrganizationCart);
export { connected as OrganizationCart };
