import React, { Component } from "react";
import { Card, CardImg, CardBody, Row, Col, Button } from 'reactstrap';

export class SmallInfoBadge extends Component {
    render() {
        const {title, icon, subtitle, subView, actionTitle1, actionCallback1, actionTitle2, actionCallback2, actionTitle3, actionCallback3 } = this.props;
        
        return (
            <div className="info-badge-small">
                <Card className="flex-row shadow-tile">
                    <CardImg
                        className="profile-thumb info-badge-icon-small"
                        src={icon} />
                    <CardBody className="info-badge-body">
                        <Row>
                            <Col nm={8} xs={12} sm={6} className="info-col one-line-div">
                                <div className='wrap-content-parent'>
                                    <div className="wrap-content-wrap-child" style={{marginRight: 5}}>
                                    <h4 className='label label-default one-line-title'>
                                    {title}
                                    </h4>
                                    {subtitle && (
                                    <div className='label label-default'>
                                    {subtitle}
                                    </div>
                                    )}
                                    </div>
                                </div>
                            </Col>
                            <Col nm={4} xs={12} sm={6}>
                                <div className='wrap-content-parent'>
                                    <div className='wrap-content-fill-child vertical-center-spacing'>
                                    </div>
                                    <div className="challenge-progress wrap-content-wrap-child" style={{marginRight: -10}}>
                                        {subView && subView}
                                        {actionTitle1 && (
                                            <Button className="btn-submit" onClick={e => {
                                                if (actionCallback1 != null) {
                                                    actionCallback1();
                                                }
                                            }}>{actionTitle1}</Button>
                                        )}
                                        {actionTitle2 && (
                                            <Button className="btn-submit" onClick={e => {
                                                if (actionCallback2 != null) {
                                                    actionCallback2();
                                                }
                                            }}>{actionTitle2}</Button>
                                        )}
                                        {actionTitle3 && (
                                            <Button className="btn-submit" onClick={e => {
                                                if (actionCallback3 != null) {
                                                    actionCallback3();
                                                }
                                            }}>{actionTitle3}</Button>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <div style={{marginTop: 10}}/>
            </div>
        );
    }
}

export default SmallInfoBadge;