import React, { Component } from "react";
import {
    Button, Form, FormGroup, Label, Input, Spinner
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';

import AttachImageEdit from 'components/edit/AttachImageEdit';
import AddContentBadge from 'components/detail/AddContentBadge';
import MembersView from 'components/members/MembersView';
import defaultImage from 'assets/img/icons/video_default.png';

import { 
    uploadImageFileToStorage 
} from 'utils/FirebaseUtils';
import { dbTimestamp, generateId, writeDb } from "utils/API";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTabStyle } from 'style/styles';

export class ClubFieldEdit extends Component {
    constructor(props) {
        super(props);
        const clubField = {};
        if (props.clubField != null) {
            for (const [key, value] of Object.entries(props.clubField)) {
                clubField[key] = value;
            }
        }
        
        this.state = {
            clubField: clubField,
            progress: false,
            newImage: {},
            imageProgress: 0,
            value: 0
        }
        this.onSaveClick = this.onSaveClick.bind(this);
        this.saveChange = this.saveChange.bind(this);
        this.saveChangeWithImage = this.saveChangeWithImage.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        const {isOpen, clubField} = this.props;
        if ((prevProps.isOpen === false && isOpen === true)) {
            this.setState({
                progress: false,
                newImage: {},
                imageProgress: 0
            })
        }
        if ((prevProps.clubField !== clubField)) {
            const trackVal = {};
            for (const [key, value] of Object.entries(clubField)) {
                trackVal[key] = value;
            }
            this.setState({
                clubField: trackVal
            })
        }
    }

    onSaveClick(clubField, newImage) {
        const {uid, onCloseClick} = this.props;
        if (clubField.title == null || clubField.title === '') {
            alert("You must input title");
        } else if (clubField.description == null || clubField.description === '') {
            alert("You must input description");
        } else {
            this.setState({progress: true, videoProgress: 0, imageProgress: 0});
            if (clubField.id == null || clubField.id === '') {
                clubField.id = generateId("/clubField");
                clubField.owner = uid != null ? uid : "free";
            }
            clubField.timestamp = dbTimestamp();
            
            this.saveChangeWithImage(clubField, newImage, (update) => {
                alert("Your Change subimitted successfully.");
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        }
    }

    saveChangeWithImage(clubField, newImage, success) {
        const objRef = this;
        const {onCloseClick} = this.props;

        if (newImage != null && newImage.name != null) {
            uploadImageFileToStorage(clubField.id, 'clubField', newImage, (url) => {
                clubField.thumbUri = url;
                objRef.saveChange(clubField, (update) => {
                    success(update);
                }, error => {
                    alert("Saving Change Details Failed.\n" + error);
                    if (onCloseClick != null) {
                        onCloseClick();
                    }
                })
            }, (state, progress) => {
                if (state === 'running') {
                    this.setState({imageProgress: progress});
                } else {
                    this.setState({imageProgress: 0});
                }
            }, error => {
                alert("Saving Image File Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        } else {
            this.saveChange(clubField, (update) => {
                alert("Your Change subimitted successfully.");
                success(update);
            }, error => {
                alert("Saving Change Details Failed.\n" + error);
                if (onCloseClick != null) {
                    onCloseClick();
                }
            })
        }
    }

    saveChange(clubField, success, failure) {
        writeDb('/clubField/' + clubField.id, clubField, error => {
            if (error) {
                failure(error);
            } else {
                success(clubField);
            }
        })
    }

    renderContents() {
        const { clubField, clubTracks } = this.props;
        const { value } = this.state;

        const result = [];
    
        for (const[key, item] of Object.entries(clubTracks)) {
    
            if ((value === 0 && clubField.trackIds != null && Object.values(clubField.trackIds).includes(item.id)) ||
            (value === 1 && (clubField.trackIds == null || !Object.values(clubField.trackIds).includes(item.id)))) {
                result.push(
                    <AddContentBadge 
                        key={key}
                        title={item.title}
                        thumbUri={item.thumbUri}
                        defaultImage={defaultImage}
                        toAdd={value === 1}
                        onClickAdd={() => {
                            writeDb('/clubField/' + clubField.id + '/trackIds/' + key, key);
                        }}
                        onClickRemove={() => {
                            writeDb('/clubField/' + clubField.id + '/trackIds/' + key, null);
                        }} />
                );
            }
        }

        return result;
    }

    render() {
        const {club, onCloseClick} = this.props;
        const {clubField, newImage, imageProgress, progress, value} = this.state;
        
        if (clubField != null) {
            return (
                <Form>
                    <FormGroup>
                        <Label for="title">{t('title')}</Label>
                        <Input type="text" name="text" id="title" onChange={e => {
                            clubField.title = e.target.value;
                            this.setState({clubField: clubField});
                        }} value={clubField.title} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">{t('description')}</Label>
                        <Input type="textarea" name="text" id="description" value={clubField.description} onChange={e => {
                            clubField.description = e.target.value;
                            this.setState({clubField: clubField});
                        }} />
                    </FormGroup>
                    <FormGroup>
                        <div>Members: {clubField.members != null ? Object.values(clubField.members).length : 0}</div>
                        <div className='divider'/>
                        <MembersView
                            available={club.playerIds}
                            members={clubField.members}
                            onSaveClick={(members)=> {
                                clubField.members = {};
                                if (members != null) {
                                    for (const[key, value] of Object.entries(members)) {
                                        clubField.members[key] = value;
                                    }
                                }
                                this.setState({clubField: clubField});
                            }} />
                    </FormGroup>
                    <AttachImageEdit
                        imageUrl={clubField.thumbUri}
                        imageClass='task-image'
                        label='Thumb Image'
                        allowProgress={progress}
                        progress={imageProgress}
                        onChange={(file)=> {
                            this.setState({newImage: file});
                        }} />
                    <Box sx={mainTabStyle}>
                        <Tabs
                            value={value}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                            onChange={(e, value) => {
                                this.setState({value: value});
                            }} >

                            <Tab label={t('tracks')} />
                            <Tab label="To Add" />
                        </Tabs>
                    </Box>
                    {this.renderContents()}

                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(clubField, newImage);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubFieldEdit);