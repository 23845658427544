import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
// import logo200Image from 'assets/img/logo/live_192.png';

export class EmptyScreen extends Component {
    
    render() {
        return (
        <div style={{width: '100vw', height: '100vh'}}>
            <h3 className='text-center' style={{position: 'relative', top: '50vh', left: '30vw', width: '40vw'}}>
                {t('blocked_landing_message')}
            </h3>
        </div>
        );
    }
}

export default withTranslation()(EmptyScreen);