import React, { Component } from "react";
import { GoogleMap, LoadScript, Marker, Polyline, } from '@react-google-maps/api';
import { Bar } from 'react-chartjs-2';
import ReactStars from "react-rating-stars-component";
import {
    Card, CardBody, CardImg, Button, Form, FormGroup, Spinner, Label, Input
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
import defaultImage from 'assets/img/icons/video_default.png';
import { getLocationRecords, get2Decimal } from 'utils/Utils';
import { writeDb } from "utils/API";

const containerStyle = {
    width: '100%',
    height: '400px'
};
  
const polylineOptions = {
    strokeColor: "#3592dd",
    strokeOpacity: 1.0,
    strokeWeight: 2,
};
  
export class ClubTrackReviewView extends Component {
    constructor(props) {
        super(props);
        
        const {submission} = props;

        const update = {};
        if (submission != null) {
            for (const [key, value] of Object.entries(submission)) {
                update[key] = value;
            }
        }
        this.state = {
            videoChoice: 'Track',
            submission: update,
            progress: false
        };
    }

    onSaveClick(submission) {
        const objRef = this;
        if (submission.rating == null || submission.rating <= 0) {
            alert("You must input valid rating");
        } else if (submission.feedback == null || submission.feedback === '') {
            alert("You must input feedback");
        } else {
            this.setState({progress: true});
            
            writeDb('/clubTrackSubmission/' + submission.id, submission, error => {
                if (error) {
                    alert("Saving Review Failed.\n" + error);
                    objRef.setState({
                        progress: false
                    })
                } else {
                    alert("Your Change subimitted successfully.");
                    objRef.setState({
                        progress: false
                    })
                }
            })
        }
    }

    getStartLatLng() {
        const {submission} = this.props;

        const records = getLocationRecords(submission);

        if (records.length > 0) {
            let start = records[0];

            return {lat: start.start_lat, lng: start.start_lng};
        }
        return {lat: 0, lng: 0};
    }

    getPath() {
        const {submission} = this.props;

        let result = [];
        const records = getLocationRecords(submission);

        if (records.length > 0) {
            for (let i = 0; i < records.length; i ++) {
                let start = records[i];
                result.push({lat: start.start_lat, lng: start.start_lng});
            }
            result.push({lat: records[records.length - 1].end_lat, lng: records[records.length - 1].end_lng});

        }
        return result;
    }

    getLapsRecords() {
        const {submission} = this.props;

        let result = [];
        const records = getLocationRecords(submission);

        let i = 0;
        let deltaDistance = 0;
        let deltaDiff = 0;

        while (i < records.length) {
            let record = {};
            
            record.start_lat = records[i].start_lat;
            record.start_lng = records[i].start_lng;
            record.start_alt = records[i].start_alt;

            record.timeDiff = deltaDiff;
            record.distance = deltaDistance;

            while (i < records.length && record.distance < 1000) {
                record.distance += records[i].distance;
                record.timeDiff += records[i].timeDiff;
                if (i === records.length - 1 || record.distance >= 1000) {
                    record.end_lat = records[i].end_lat;
                    record.end_lng = records[i].end_lng;
                    record.end_alt = records[i].end_alt;
                    record.timestamp = records[i].timestamp;
                }
                deltaDiff = records[i].timeDiff;
                i ++;
            }
            deltaDistance = record.distance % 1000;
            deltaDiff = parseInt(deltaDiff * deltaDistance / 1000);
            if (record.distance > 1000 && i < records.length) {
                record.distance -= deltaDistance;
                record.timeDiff -= deltaDiff;
            }
            result.push(record);
        }

        return result;
    }

    renderDistanceChart() {
        
        const data = {
            labels: [],
            datasets: [
                {
                    label: '',
                    data: [],
                    backgroundColor: [
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderColor: [
                        'rgba(54, 162, 235, 0.2)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
        
        const options = {
            indexAxis: 'y',
            elements: {
                bar: {
                borderWidth: 2,
                },
            },
            legend: {
                display: true, 
                position: "right",
            },
            responsive: true,
            scales: {
                xAxes: {
                    display: false
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
                datalabels: {
                    color: 'red'
                }
            }
        };
        
        const records = this.getLapsRecords();

        let distance = 0;
        for (let i = 0; i < records.length; i ++) {
            distance += records[i].distance;
            data.labels.push(get2Decimal((records[i].distance >= 1000 ? distance : records[i].distance) / 1000) + "km")
            data.datasets[0].data.push(records[i].timeDiff);
        }

        return (
            <Bar data={data} options={options} />
        );
    }

    render() {
        const {onCloseClick, track, submission} = this.props;
        const {progress} = this.state;
        
        let trackImage = track.thumbUri != null ? track.thumbUri : defaultImage;
        const startPoint = this.getStartLatLng();
        
        if (track != null) {
            return (
                <Form>
                    <Card className="flex-row">
                        <CardImg
                            className="profile-thumb vertical-center-spacing"
                            src={trackImage}
                            style={{ width: 60, height: 60 }}
                            />
                        <CardBody>
                            <h4 className='label label-default'>
                            {track.title}
                            </h4>
                            <div>{track.description}</div>
                            <div>Points: {track.points}</div>
                        </CardBody>
                    </Card>
                    <div>
                        <LoadScript
                            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} >
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={startPoint}
                                zoom={15}
                                >
                                <Marker position={startPoint} />
                                <Polyline path={this.getPath()} options={polylineOptions} />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                    {this.renderDistanceChart()}
                    <FormGroup>
                        <legend>Rating: {submission.rating}</legend>
                        <ReactStars
                            id="rating"
                            count={5}
                            onChange={(newRating) => {
                                submission.rating = newRating;
                                this.setState({submission: submission});
                            }}
                            size={24}
                            value={submission.rating}
                            isHalf={true}
                            activeColor="#ffd700" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="feedback">Feedback</Label>
                        <Input type="textarea" name="text" id="feedback" value={submission.feedback} onChange={e => {
                            submission.feedback = e.target.value;
                            this.setState({submission: submission});
                        }} />
                    </FormGroup>
                    {progress ? (
                        <Spinner color="primary" />
                    ) : (
                        <div>
                            <Button className='modal-button' onClick={e => {
                                this.onSaveClick(submission);
                            }}>{t('save')}</Button>
                            <Button className='modal-button' onClick={e => {
                                if (onCloseClick != null) {
                                    onCloseClick();
                                }
                            }}>{t('close')}</Button>
                        </div>
                    )}
                </Form>
            );
        } else {
            return <div/>;
        }
    }
}

export default withTranslation()(ClubTrackReviewView);