import React, { Component } from 'react';
import { connect } from 'react-redux';

import { 
    Row,
    Col,
    Input, 
    Button
} from 'reactstrap';
import { t } from "i18next";

import { userActions } from 'redux/_actions';
import { 
    GiSoccerKick
} from 'react-icons/gi';
  
import OrganizationRefereeRow from 'components/detail/organization/OrganizationRefereeRow';
import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import ItemsNavigation from 'components/detail/ItemsNavigation';
import RefereeFormDetail from 'components/detail/organization/RefereeFormDetail';
import LoadingView from 'components/detail/loading/LoadingView';
import userImage from 'assets/img/icons/player_photo_default.png';
import InviteHeader from 'components/detail/invite/InviteHeader';

import { 
    LEAGUE_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
    validateEmail,
    validateEmailStr
} from 'utils/Utils';
import { invokeHttpsApi } from 'utils/API';
import { withRouter } from 'utils/Routher';

class RefereesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locations: {},
            organizations: {},
            organization: {},
            refereeData: {},
            teams: {},
            users: {},
            invites: {},
            category: {},
            profileChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            tab: 2,
        };
    }
    
    handleClick = (value) => {
        window.location.href = `#${value}`;
        this.setState({ activeItem: value });
    }
    
    componentDidMount() {
        const id = this.props.router.params.id;
        if (this.props.user) {
            this.loadContents(id);
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        const id = this.props.router.params.id;
        if (!prevUid && uid) {
            this.loadContents(id);
        }
    }

    loadContents(id) {
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizationContentsQuery', {}, (data) => {

            const locations = {};
            const organizations = {};
            let organization = {};
            const refereeData = {};
            const profileChoice = {};
            let detailOpen = false;
            const teams = {};
            const users = {};
            const invites = {};
            const category = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    organization = value;
                }
                for (const [key, value] of Object.entries(data.refereeData)) {
                    refereeData[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.invites)) {
                    if (value.organizationId === organization.id) {
                        invites[key] = value;
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.category)) {
                    category[key] = value;
                }
            }
            if (id != null && refereeData[id] != null) {
                for (const [key, value] of Object.entries(refereeData[id])) {
                    profileChoice[key] = value;
                }
                detailOpen = true;
            }

            this.setState({
                locations: locations,
                organizations: organizations,
                organization: organization,
                refereeData: refereeData,
                profileChoice: profileChoice,
                detailOpen: detailOpen,
                teams: teams,
                users: users,
                invites: invites,
                category: category,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderClubSchedules() {

    }

    renderMembers() {
        const {organization, refereeData, users, teams, searchStr} = this.state;
        
        let pending = [];
        let denied = [];
        let members = [];
        let noProfile = [];
        let memberIds = [];
        for (const val of Object.values(refereeData)) {
            if (searchStr != null && searchStr.length > 0 && !val.playerName.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (val.verified == null) {
                pending.push(val)
            }
            if (val.verified === false) {
                denied.push(val)
            }
            if (val.verified === true) {
                members.push(val)
                memberIds.push(val.uid)
            }
        }
        pending.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        denied.sort(function(a, b) {
            return b.playerName - a.playerName;
        });
        members.sort(function(a, b) {
            return b.playerName - a.playerName;
        });

        if (organization.refereeIds) {
            for (const uid of Object.values(organization.refereeIds)) {
                if (!memberIds.includes(uid) && users[uid]) {
                    noProfile.push(users[uid]);
                }
            }
        }
        noProfile.sort(function(a, b) {
            return b.username - a.username;
        });

        let items = [];
        for (const val of pending) {
            let referee = users[val.uid];
            items.push(
                <OrganizationRefereeRow
                    organization={organization}
                    refereeData={val}
                    teams={teams}
                    users={users}
                    referee={referee}
                    onClickDetail={()=>{
                        this.setState({detailOpen: true, profileChoice: val})
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of denied) {
            let referee = users[val.uid];
            items.push(
                <OrganizationRefereeRow
                    organization={organization}
                    refereeData={val}
                    teams={teams}
                    users={users}
                    referee={referee}
                    onClickDetail={()=>{
                        this.setState({detailOpen: true, profileChoice: val})
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of members) {
            let referee = users[val.uid];
            items.push(
                <OrganizationRefereeRow
                    organization={organization}
                    refereeData={val}
                    teams={teams}
                    users={users}
                    referee={referee}
                    onClickDetail={()=>{
                        this.setState({detailOpen: true, profileChoice: val})
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }
        for (const val of noProfile) {
            items.push(
                <OrganizationRefereeRow
                    organization={organization}
                    teams={teams}
                    users={users}
                    referee={val}
                    onClickDetail={()=>{
                        this.setState({detailOpen: true, profileChoice: val})
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            )
            items.push(<div className='divider' />);
        }

        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title' style={{marginTop: 30, marginBottom: 10}}>
                Members: {items.length}
                </h3>
                <div className='shadow-tile'>
                    <Row>
                        <Col lg={4} xs={5} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('referee')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={5} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Eligibility
                            </h3>
                        </Col>
                        <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {items}
                </div>
            </div>
        );
    }

    renderPending() {
        const {organization, users, teams, searchStr} = this.state;

        let items = [];
        let pending = [];
        let invited = [];
        let free = [];
        for (const [key, value] of Object.entries(users)) {
            if (searchStr != null && searchStr.length > 0 && !value.username.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            if (value.parentId != null) {
                continue;
            }
            const isMember = organization.refereeIds != null && Object.values(organization.refereeIds).includes(key);
            const isPending = organization.refereePendingIds != null && Object.values(organization.refereePendingIds).includes(key);
            const isInvited = organization.refereeInvitedIds != null && Object.values(organization.refereeInvitedIds).includes(key);
            
            if (isPending) {
                pending.push(
                    value
                );
            }
            if (isInvited) {
                invited.push(
                    value
                );
            }
            if (!isMember && !isPending && !isInvited) {
                free.push(
                    value
                );
            }
        }
        pending.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        invited.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        free.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
        for (const item of pending) {
            items.push(item);
        }
        for (const item of invited) {
            items.push(item);
        }
        for (const item of free) {
            items.push(item);
        }
        let result = [];
        for (const value of items) {
            result.push(
                <OrganizationRefereeRow
                    organization={organization}
                    teams={teams}
                    users={users}
                    referee={value}
                    onClickDetail={()=>{
                        this.setState({detailOpen: true, profileChoice: value})
                    }}
                    onChanged={()=>{
                        this.loadContents();
                    }} />
            );
            result.push(
                <div className='divider' />
            );
        }
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title' style={{marginTop: 30, marginBottom: 10}}>
                Pending: {pending.length}, Invited: {invited.length}, Free: {free.length}
                </h3>
                <div className='shadow-tile'>
                    <Row>
                        <Col lg={4} xs={5} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                            {t('referee')}
                            </h3>
                        </Col>
                        <Col lg={4} xs={5} sm={4} className="vertical-center">
                            <h3 className='row-table-title'>
                                
                            </h3>
                        </Col>
                        <Col lg={1} xs={2} sm={1} className="vertical-center">
                            <h3 className='row-table-title'>
                                Eligibility
                            </h3>
                        </Col>
                        <Col lg={3} xs={12} sm={3} className="vertical-center"></Col>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    {result}
                </div>
            </div>
        );
    }

    renderFilter() {
        const {searchStr} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Input type="text" name="search" id="search" placeholder='Search by Referee Name ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>                        
            </div>
        )
    }

    renderContents() {
        const {user, organizationSubscription, freeOrganization} = this.props;
        const {organization, users, teams, profileChoice, detailOpen} = this.state;
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LEAGUE_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        if (organization == null || organization.id == null) {
            return (
                <div>{t("no_organization_prompt")}</div>
            )
        }

        if (detailOpen) {
            return (
                <RefereeFormDetail 
                    refereeData={profileChoice}
                    organization={organization}
                    uid={user.uid}
                    users={users}
                    teams={teams}
                    onCloseClick={()=>{
                        this.setState({detailOpen: false, profileChoice: {}})
                    }}
                    onSaveDone={()=> {
                        this.loadContents()
                    }} />
            );
        } else {
            return (
            <div>
                {this.renderInvite()}
                {this.renderFilter()}
                {this.renderMembers()}
                {this.renderPending()}
            </div>
            );
        }
    }

    renderInvite() {
        const { invites, organization, users, inviteSearch } = this.state;

        const invited = [];
        const pending = [];
        const players = [];
        const clubPending = [];
        const add = [];

        for (const val of Object.values(invites)) {
            if (val.organizationId !== organization.id) {
                continue
            }
            if (!inviteSearch || (val.email && val.email.toLowerCase().includes(inviteSearch.toLowerCase()))) {
                if (val.status === 'invited') {
                    invited.push(val);
                } else if (val.status === 'registered') {
                    pending.push(val);
                }
            }
        }
        if (organization.refereeIds) {
            for (const val of Object.values(organization.refereeIds)) {
                const user = users[val] ? users[val] : {};
                if (inviteSearch && user.email && user.email.toLowerCase().includes(inviteSearch.toLowerCase())) {
                    players.push(user);
                }
            }
        }
        if (organization.refereeInvitedIds) {
            for (const val of Object.values(organization.refereeInvitedIds)) {
                const user = users[val] ? users[val] : {};
                if (!inviteSearch || (user.email && user.email.toLowerCase().includes(inviteSearch.toLowerCase()))) {
                    clubPending.push(user);
                }
            }
        }
        for (const user of Object.values(users)) {
            if (inviteSearch != null && inviteSearch !== "" && user.email != null && user.email.toLowerCase().includes(inviteSearch.toLowerCase())) {
                add.push(user);
            }
        }
        players.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });
    
        clubPending.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

        add.sort((a, b) => {
            if (a.username === b.username) 
                return 0;

            return a.username < b.username ? -1 : 1;
        });

    
        return (
            <div style={{marginTop: 30, marginBottom: 30}}>
                <h3 className='row-table-title' style={{marginTop: 30, marginBottom: 10}}>
                Invites: {invited.length + pending.length}
                </h3>
                <div className='wrap-content-parent'>
                    <div className='wrap-content-fill-child vertical-center-spacing'>
                        <Input type="text" name="inviteSearch" id="inviteSearch" placeholder='Search by email ...' onChange={e => {
                            this.setState({inviteSearch: e.target.value});
                        }} value={inviteSearch} />
                    </div>                        
                    <Button className='grid-edit-btn wrap-content-wrap-child' style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={ e => {
                        this.sendInvite(validateEmailStr(inviteSearch));
                    }}>{t('invite')}</Button>
                </div>
                <div className='shadow-tile' style={{margin: '10px 0px'}}>
                    <Row style={{margin: 0}}>
                        <h3 className='row-table-title' style={{margin: 0, padding: '10px 15px'}}>
                            Referee Invites
                        </h3>
                    </Row>
                    <div className='divider' />
                    <div className='divider' />
                    <div>
                        {invited.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title="No user for this email yet"
                                defaultIcon={userImage}
                                email={val.email}
                                state="invited" />
                        ))}
                    </div>
                    <div>
                        {pending.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title="No user for this email yet"
                                defaultIcon={userImage}
                                email={val.email}
                                state="registered" />
                        ))}
                    </div>
                    <div>
                        {clubPending.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title={val.username}
                                defaultIcon={userImage}
                                thumbUri={val.photoUri}
                                email={val.email}
                                state="clubPending" />
                        ))}
                    </div>
                    <div>
                        {players.map((val) => (
                            <InviteHeader
                                forTable={true}
                                key={val.email}
                                title={val.username}
                                defaultIcon={userImage}
                                thumbUri={val.photoUri}
                                email={val.email}
                                memberLabel={t('referee')}
                                state="member" />
                        ))}
                    </div>
                    <div className='divider' />
                    <div className='divider' />
                </div>
            </div>
        );
    }

    checkEmail(email) {
        const {invites, organization, users} = this.state;

        if (!validateEmail(email)) {
            return -1;
        }

        for (const val of Object.values(invites)) {
            if (val.organizationId !== organization.id) {
                continue
            }
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                if (val.status === 'invited') {
                    return 0;
                } else if (val.status === 'registered') {
                    return 1;
                }
            }
        }
        if (organization.refereeIds) {
            for (const val of Object.values(organization.refereeIds)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 2;
                }
            }
        }
        if (organization.refereePendingIds) {
            for (const val of Object.values(organization.refereePendingIds)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 5;
                }
            }
        }
        if (organization.refereeInvitedIds) {
            for (const val of Object.values(organization.refereeInvitedIds)) {
                const user = users[val] ? users[val] : {};
                if (!email || (user.email && user.email.toLowerCase() === email.toLowerCase())) {
                    return 6;
                }
            }
        }
        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return 3;
            }
        }
        return 4;
    }

    getUid(email) {
        const {users} = this.state;

        for (const val of Object.values(users)) {
            if (val.email && email && val.email.toLowerCase() === email.toLowerCase()) {
                return val.uid;
            }
        }
        return null;
    }

    sendInvite(email) {
        const id = this.props.router.params.id;
        const { organization } = this.state;
        var status = this.checkEmail(email)

        if (status === -1) {
            alert("This email address is invalid.")
            return;
        }
        
        if (status === 2) {
            alert("This email is already registered.")
            return;
        }
        
        if (status === 5) {
            alert("This email is already pending.")
            return;
        }
        
        if (status === 6) {
            alert("This email is already invited on offline mode.")
            return;
        }
        
        if (status === 1) {
            alert("This email is already invited for creating organization.")
            return;
        }
        
        const data = {email: email, organizationId: organization.id, organizationTitle: organization.title};
        if (status === 3) {
            data.uid = this.getUid(email);
        }

        invokeHttpsApi('invite-inviteReferee', data, (data) => {
            if (status === 3) {
                alert("An invitation email to resiger and be a referee has been sent. It will be sent every 2 days");
            } else {
                alert("An invitation email to be a referee has been sent. It will be sent every 2 days");
            }
            this.loadContents(id);
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({progress: false})
        });
    }

    render() {
        const {user} = this.props;
        const {detailOpen, profileChoice, refereeData, loading} = this.state;

        if (user == null) {
            return <div/>
        }
        const curConetntCount = Object.values(refereeData).length;
        const choice = {};
        if (profileChoice.uid != null) {
            choice.id = profileChoice.uid;
            choice.title = profileChoice.username;
        }

        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title={t('referee_forms')}
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={false}
                        totalCount={curConetntCount}
                        detailItem={choice}
                        onClickBack={()=>{
                            this.setState({profileChoice: {}, detailOpen: false})
                        }} />
                </div>
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(RefereesPage));
export { connectedApp as RefereesPage };
