import React, { Component } from 'react';
// import { t } from "i18next";
import { connect } from 'react-redux';
import OrganizationScheduleHeader from 'components/detail/organization/OrganizationScheduleHeader';
import OrganizationScheduleDetailView from 'components/detail/organization/OrganizationScheduleDetailView';
import OrganizationScheduleEdit from 'components/edit/organization/OrganizationScheduleEdit';
import { userActions } from 'redux/_actions';

import {OrganizationMembershipPrompt} from 'components/membership/OrganizationMembershipPrompt';
import { 
    GiSoccerKick
} from 'react-icons/gi';
  
import ItemsNavigation from 'components/detail/ItemsNavigation';
import LoadingView from 'components/detail/loading/LoadingView';
import { 
    Modal, 
    ModalBody, Row, Col, Input
} from 'reactstrap';
import Switch from '@mui/material/Switch';
import CustomDatePicker from 'components/template/CustomDatePicker';
import { getDayStart, DAY_TIME } from 'utils/Utils';

import { 
    LOCATION_SUBSCRIPTION_PRODUCT_ID,
    LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID,
} from 'utils/Utils';
import { withRouter } from 'utils/Routher';
import { invokeHttpsApi } from 'utils/API';

class SchedulesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            activeTab: '1',
            organization: {},
            organizations: {},
            locations: {},
            users: {},
            schedules: {},
            orgSchedules: {},
            detailChoice: {},
            detailOpen: false,
            editOpen: false,
            loading: true,
            locationChoice: {},
            dateFilter: false,
            dateChoice: new Date().valueOf()
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.loadContents();
        }
    }

    componentDidUpdate(prevPros) {
        const {user} = this.props;
        const prevUid = prevPros && prevPros.user ? prevPros.user.uid : null;
        const uid = user ? user.uid : null;
        if (!prevUid && uid) {
            this.loadContents();
        }
    }

    loadContents() {
        const {user} = this.props;
        this.setState({loading: true});
        invokeHttpsApi('organizerQuery-organizerDashboardQuery', {}, (data) => {
            const organization = {};
            const organizations = {};
            const users = {};
            const teams = {};
            const locations = {};
            const orgSchedules = {};

            if (data.success) {
                for (const [key, value] of Object.entries(data.organizations)) {
                    organizations[key] = value;
                    if (user != null && value.owner === user.uid) {
                        for (const [k,v] of Object.entries(value)) {
                            organization[k] = v;
                        }
                    }
                }
                for (const [key, value] of Object.entries(data.users)) {
                    users[key] = value;
                }
                for (const [key, value] of Object.entries(data.teams)) {
                    teams[key] = value;
                }
                for (const [key, value] of Object.entries(data.locations)) {
                    locations[key] = value;
                }
                for (const [key, value] of Object.entries(data.orgSchedules)) {
                    orgSchedules[key] = value;
                }
            }

            this.setState({
                organization: organization,
                organizations: organizations,
                teams: teams,
                locations: locations,
                users: users,
                orgSchedules: orgSchedules,
                loading: false,
            })
        }, (error) => {
            console.log('loadContents error', error);
            this.setState({loading: false});
        });
    }

    renderFilter() {
        const {searchStr} = this.state;
        return (
            <div className='items-navigation'>
                <div className='wrap-content-fill-child vertical-center-spacing'>
                    <Input type="text" name="search" id="search" placeholder='Search by Schedule Title ...' onChange={e => {
                        this.setState({searchStr: e.target.value});
                    }} value={searchStr} />
                </div>                        
            </div>
        )
    }

    renderContents() {
        const { user, organizationSubscription, freeOrganization } = this.props;
        const { organization, locations, locationChoice, dateFilter, dateChoice, orgSchedules, teams, users , detailChoice, editOpen, detailOpen, searchStr} = this.state;
        
        const result = [];
        const list = [];
        const dayStart = getDayStart(dateChoice);
    
        if (user == null) {
            return <div/>
        }
        const now = new Date().valueOf();
        if ((organizationSubscription == null || organizationSubscription.product == null || 
            (organizationSubscription.product.id !== LOCATION_SUBSCRIPTION_PRODUCT_ID && 
                organizationSubscription.product.id !== LEAGUE_LOCATION_SUBSCRIPTION_PRODUCT_ID)) && 
                (freeOrganization == null || freeOrganization.current_period_end < now)) {
            return (
                <OrganizationMembershipPrompt 
                    forLeague={true}/>
            );
        }
        
        for (const[, value] of Object.entries(orgSchedules)) {
            if (searchStr != null && searchStr.length > 0 && !value.title.toLowerCase().includes(searchStr.toLowerCase())) {
                continue;
            }
            let location = locations[value.location];
            if (location != null && (locationChoice.id == null || location.id === locationChoice.id)) {
                if (value.organizationId === organization.id) {
                    if (!dateFilter || (value.timestamp >= dayStart && value.timestamp < dayStart + DAY_TIME)) {
                        result.push(value);
                    }
                }
            }
    
        }

        result.sort(function(a, b) {
            let t1 = a.timestamp;            
            let t2 = b.timestamp;

            return t1 - t2;
        })
        
        for (const value of result) {
            list.push(
                <OrganizationScheduleHeader 
                    key={value.id}
                    uid={user.uid}
                    organization={organization}
                    locations={locations}
                    schedule={value}
                    onClickDetail={() => {
                        this.setState({
                            detailChoice: value,
                            detailOpen: true
                        })
                    }}
                    onClickEdit={() => {
                        this.setState({
                            detailChoice: value,
                            editOpen: true
                        })
                    }} />
            );
        }

        return (
            <div>
                <div style={{marginTop: 20}}>
                    <ItemsNavigation
                        title="Organization Schedules"
                        icon={<GiSoccerKick/>}
                        detailOpen={detailOpen}
                        editOpen={editOpen}
                        totalCount={list.length}
                        detailItem={detailChoice}
                        onClickAdd={()=>{
                            this.setState({detailChoice: {}, editOpen: true, detailOpen: false});
                        }}
                        onClickBack={()=>{
                            this.setState({profileChoice: {}, detailOpen: false, editOpen: false})
                        }} />
                </div>
                {this.renderFilter()}
                <Row>
                    <Col sm={8}>
                        <Input 
                            type="select" 
                            name="select" 
                            value={locationChoice.id} 
                            id="typeChoice" 
                            style={{marginTop: 20}}
                            onChange={e=> {
                                let update = locations[e.target.value];
                                this.setState({locationChoice: update != null ? update : {}});
                            }}>
                            <option key="null" value={null}></option>
                            {Object.values(locations).map((val,idx)=>(
                                <option key={idx} value={val.id}>{val.title}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col style={{margin: 'auto 0px'}} sm={4}>
                        <Switch
                            checked={dateFilter}
                            onChange={e=>{
                                this.setState({dateFilter: e.target.checked});
                            }}
                            inputProps={{ 'aria-label': 'secondary checkbox' }} />
                        <div style={{display: 'inline-block', fontSize: 10, marginRight: 20}}>Show by Date</div>
                        <div style={{display: 'inline-block'}}>
                            <CustomDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date picker dialog"
                                format="MM/DD/YYYY"
                                value={dateChoice}
                                onChange={ date => {
                                    this.setState({dateChoice: date.valueOf()});
                                }} />
                        </div>
                    </Col>
                </Row>

                {list}
                <Modal isOpen={detailOpen}>
                    <ModalBody>
                        <OrganizationScheduleDetailView 
                            uid={user.uid}
                            organization={organization}
                            locations={locations}
                            schedule={detailChoice} 
                            users={users}
                            teams={teams}
                            isOpen={detailOpen}
                            onCloseClick={() => {
                                this.setState({detailOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
                <Modal isOpen={editOpen}>
                    <ModalBody>
                        <OrganizationScheduleEdit 
                            uid={user.uid}
                            organization={organization}
                            locations={locations}
                            teams={teams}
                            schedule={detailChoice} 
                            isOpen={editOpen}
                            onCloseClick={() => {
                                this.setState({editOpen: false})
                            }} />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
    
    render() {
        const {loading} = this.state;

        return (
            <div>
                
                {loading ? (
                    <LoadingView/>
                ) : (
                    <div>
                        {this.renderContents()}
                    </div>
                )}
            </div>
        );
    }
}

function mapState(state) {
    const { alert } = state;
    const { loggingIn, user } = state.authentication;
    const { organizationSubscription, freeOrganization } = state.subscriptions;
    return { alert, loggingIn, user, organizationSubscription, freeOrganization };
}

const actionCreators = {
    checkLogin: userActions.checkLogin,
};

const connectedApp = withRouter(connect(mapState, actionCreators)(SchedulesPage));
export { connectedApp as SchedulesPage };
